<span [class.hidden]="!formState?.user && field?.props?.showForLoggedInUsersOnly" class="relative">
  <button
    *ngIf="formState?.isEditing"
    #btn
    aria-label="Edit field"
    label="Edit field"
    type="button"
    pButton
    icon="pi pi-ellipsis-v"
    (click)="menu.toggle($event)"
    class="p-button-rounded p-button-text p-button-secondary absolute top-0 left-100 ml-4"
  ></button>
  <p-tieredMenu #menu [model]="items" [popup]="true" appendTo="body"></p-tieredMenu>

  <div class="mb-4 flex justify-content-between" [id]="key">
    <label
      *ngIf="props.label && props.hideLabel !== true"
      [attr.id]="key"
      [attr.for]="id"
      class="align-items-center flex font-semibold text-lg"
    >
      {{ props.label }}
      <span *ngIf="props.required && props.hideRequiredMarker !== true" aria-hidden="true">*</span>
    </label>

    <div class="field-wrapper">
      <ng-container #fieldComponent></ng-container>
    </div>
  </div>

  <div>
    <small *ngIf="props?.description && !showError" class="flex gap-1 mt-1 ml-1">
      <i class="pi pi-info-circle"></i>
      <span>{{ props?.description }}</span>
    </small>
    <small *ngIf="showError && props?.required" class="text-error flex gap-1 mt-1 ml-1">
      <i class="pi pi-exclamation-circle"></i>
      <formly-validation-message [field]="field"></formly-validation-message>
    </small>
  </div>
</span>
