import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'ic-global-form-calculation-dialog',
  templateUrl: './global-form-calculation-dialog.component.html',
  styleUrls: ['./global-form-calculation-dialog.component.scss'],
})
export class GlobalFormCalculationDialogComponent {
  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
  ) {}
}
