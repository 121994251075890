import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  CaptureType,
  CollectionAuth,
  CollectionAuthWithId,
  PatientMessage,
  PatientRequestLoginMethod,
} from '@islacare/ic-types';
import firebase from 'firebase/compat/app';
import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LoggingService } from '../../shared/services/logging/logging.service';
import { TeamsService } from '../teams/teams.service';

export interface PartialPatientDetails {
  patientId: string;
  patientFirstName: string;
  patientPhone: string;
  additionalPhoneNumbers: any;
  patientYearOfBirth: string;
}
export interface PartialRequestData {
  formIds: string[];
  reminder: boolean;
  teamId: string;
  keepLinkSevenDays: any;
}

export interface CollectionAuthWithPatientMessages extends CollectionAuthWithId {
  patientMessages: PatientMessage[];
}

const mixInto =
  (collectionAuth: CollectionAuthWithId) =>
  (patientMessages: PatientMessage[]): CollectionAuthWithPatientMessages => ({
    ...collectionAuth,
    patientMessages,
  });
@Injectable({
  providedIn: 'root',
})
export class CollectionAuthsService {
  constructor(
    private db: AngularFirestore,
    private log: LoggingService,
    private teamsService: TeamsService,
  ) {}

  getCollectionAuths$(patientId: string, collectionId: string): Observable<CollectionAuthWithId[]> {
    return this.db
      .collection<CollectionAuth>(`patients/${patientId}/collections/${collectionId}/collectionAuths`, ref =>
        ref.where('createdBy', '!=', 'automatedReminder').orderBy('createdBy', 'desc').orderBy('createdAt', 'desc'),
      )
      .valueChanges({ idField: 'id' })
      .pipe(map(collectionsAuths => collectionsAuths.filter(collectionsAuth => !collectionsAuth?.cancelled)));
  }

  getCollectionAuthDoc(patientId: string, collectionId: string, collectionAuthId: string) {
    return this.db
      .doc<CollectionAuth>(`patients/${patientId}/collections/${collectionId}/collectionAuths/${collectionAuthId}`)
      .ref.get();
  }

  updateAsUsed(patientId: string, collectionId: string, collectionAuthId: string) {
    return this.db
      .doc<CollectionAuth>(`patients/${patientId}/collections/${collectionId}/collectionAuths/${collectionAuthId}`)
      .update({
        used: true,
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .catch(error => {
        throw error;
      });
  }

  updateAsInactive(patientId: string, collectionId: string, collectionAuthId: string) {
    return this.db
      .doc<CollectionAuth>(`patients/${patientId}/collections/${collectionId}/collectionAuths/${collectionAuthId}`)
      .update({
        active: false,
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .catch(error => {
        throw error;
      });
  }

  cancelAuth(patientId: string, collectionId: string, collectionAuthId: string) {
    return this.db
      .doc<CollectionAuth>(`patients/${patientId}/collections/${collectionId}/collectionAuths/${collectionAuthId}`)
      .update({
        active: false,
        cancelled: true,
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      });
  }

  async createPatientCollectionAuth(
    collectionAuthUrl: string,
    collectionAuthId: string,
    collectionId: string,
    requestDialogResult: PartialRequestData,
    patientDetails: PartialPatientDetails,
    createdBy: string,
    createdForOrg: string,
    captureType: string,
    requestId: string,
    isMediaOptional?: boolean,
  ) {
    const requestLoginMethod = (await this.teamsService.getTeamSnapshot(requestDialogResult?.teamId)).data()
      ?.requestLoginMethod;

    if (!patientDetails.patientPhone) patientDetails.patientPhone = ''; //If there's no phone on the patient it causes the QR code auth to trip? But this stops it

    const authDoc: CollectionAuth = {
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      active: true,
      firstName: patientDetails.patientFirstName,
      url: collectionAuthUrl,
      shortUrl: null,
      smsSend: false,
      smsText: null,
      email: null,
      emailSend: false,
      patientPhone: patientDetails.patientPhone,
      templateId: null,
      formIds: requestDialogResult.formIds,
      createdBy: createdBy,
      createdForOrg: createdForOrg,
      yearOfBirth: patientDetails.patientYearOfBirth,
      recipient: null,
      reminder: !!requestDialogResult?.reminder,
      used: false,
      teamId: requestDialogResult.teamId || null,
      keepLinkSevenDays: requestDialogResult.keepLinkSevenDays,
      captureType: captureType,
      requestId: requestId,
      collectionAuthFormatVersion: 2,
      patientId: patientDetails.patientId,
      isMediaOptional: isMediaOptional,
    };

    if (requestLoginMethod === PatientRequestLoginMethod.NHS) {
      authDoc.loginMethod = PatientRequestLoginMethod.NHS;
      authDoc.url = `${environment.url}/sso/nhs/patient/login?state=${requestId}`;
    }

    const collectionDocPath = `patients/${patientDetails.patientId}/collections/${collectionId}/collectionAuths`;

    try {
      await this.db
        .collection(collectionDocPath)
        .doc(collectionAuthId)
        .set(authDoc)
        .catch(error => {
          throw error;
        });
    } catch (err) {
      this.log.consoleError(err);
    }
  }

  async updateCollectionAuth(
    patientId: string,
    collectionId: string,
    collectionAuthId: string,
    updates: Partial<CollectionAuth>,
  ) {
    const update = {
      ...updates,
      lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
    };
    return this.db
      .doc<CollectionAuth>(`patients/${patientId}/collections/${collectionId}/collectionAuths/${collectionAuthId}`)
      .update(update as CollectionAuth);
  }

  createAuthForEnhancedConsent(
    patientId: string,
    collectionAuthUrl: string,
    uid: string,
    organisationId: string,
    combinedArrays: any[],
    ecDialogResultData: any,
    requestId: string,
  ) {
    const enhancedConsentAuthData = {
      isEnhancedConsent: true,
      enhancedConsentSelectedImages: combinedArrays,
      enhancedConsentReasonText: ecDialogResultData.enhancedConsentReason,
    };

    const authDoc: CollectionAuth = {
      patientId,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      active: true,
      firstName: ecDialogResultData.patient.firstName,
      url: collectionAuthUrl,
      shortUrl: null,
      smsSend: false,
      smsText: null,
      patientPhone: ecDialogResultData.enhancedConsentPatientPhone,
      createdBy: uid,
      createdForOrg: organisationId,
      yearOfBirth: ecDialogResultData.patient.dateOfBirth.substring(0, 4),
      recipient: null,
      reminder: false,
      used: false,
      teamId: ecDialogResultData.entryTeamId || '',
      email: ecDialogResultData.enhancedConsentPatientEmail,
      enhancedConsent: enhancedConsentAuthData,
      captureType: CaptureType.ENHANCED_CONSENT,
      requestId,
      formIds: [''],
      lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      keepLinkSevenDays: true,
    };

    return authDoc;
  }

  getCollectionAuthsWithPatientMessages$(
    patientId: string,
    collectionId: string,
  ): Observable<CollectionAuthWithPatientMessages[]> {
    return this.getCollectionAuths$(patientId, collectionId).pipe(
      switchMap(collectionsAuths => {
        if (!collectionsAuths?.length) return of([]);

        return combineLatest(
          collectionsAuths
            .filter(collectionsAuth => collectionsAuth?.collectionAuthFormatVersion === 2)
            .map(collectionAuth =>
              this.getPatientMessages$(patientId, collectionId, collectionAuth.id).pipe(map(mixInto(collectionAuth))),
            ),
        );
      }),
    );
  }

  getPatientMessages$(patientId: string, collectionId: string, collectionAuthId: string): Observable<PatientMessage[]> {
    return (
      this.db
        .collection<PatientMessage>(
          `patients/${patientId}/collections/${collectionId}/collectionAuths/${collectionAuthId}/patientMessages`,
        )
        .valueChanges() || of([])
    );
  }
}
