<ng-container *ngIf="field && control">
  <div>
    <mat-checkbox
      [formControl]="control"
      appearance="fill"
      class="checkbox"
      [ngStyle]="{
        color: control?.touched && control?.invalid ? '#ee7324' : null
      }"
    >
      <!-- prettier-ignore -->
      <p
        id="field-title"
        style="white-space: normal"
        *ngIf="field?.title"
        [ngStyle]="{
          'font-weight': fontWeight(),
          'text-align': textAlign(),
          color: control?.touched && control?.invalid ? '#ee7324' : null
        }"
      >{{field?.title}}{{ field?.validation?.required ? ' *' : '' }}</p>
      <!-- prettier-ignore -->
      <p id="field-subtitle" style="font-size: 14px; white-space: normal" *ngIf="field?.subTitle">{{field?.subTitle}}</p>
    </mat-checkbox>
    <p
      *ngIf="control?.touched && control?.invalid"
      class="mat-error"
      [ngStyle]="{ 'text-align': textAlign() }"
    >
      {{ formService?.getErrorMessage(control) }}
    </p>
  </div>
</ng-container>
