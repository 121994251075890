import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[icElSelected]'
})
export class ElementSelectedDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  private selected = false;

  @HostListener('click') onClick() {
    this.selected = !this.selected;
    if (this.selected) {
      this.renderer.addClass(this.el.nativeElement, 'ic-selected');
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'ic-selected');
    }
  }

  ngAfterViewInit() {
    const container = this.el.nativeElement.parentElement;
    this.renderer.addClass(container, 'ic-element');
  }

  @HostListener('document:click', ['$event.target']) onDocumentClick(target: any) {
    if (!this.el.nativeElement.contains(target)) {
      this.selected = false;
      this.renderer.removeClass(this.el.nativeElement, 'ic-selected');
    }
  }
}
