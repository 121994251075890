export default [
  // CADI
  'CADI',
  'feelingAggressiveFrustratedOrEmbarrassed',
  'acneInterferedWithSocialLifeEventsOrRelationships',
  'avoidedPublicChangingFacilities',
  'feelingsAboutSkinAppearance',
  'howBadIsYourAcneNow',
  'cadiTotalScore',

  // PHQ
  'PHQ',
  'littleInterestOrPleasure',
  'feelingDownDepressedOrHopeless',
  'troubleSleeping',
  'feelingTiredOrLittleEnergy',
  'poorAppetiteOrOvereating',
  'feelingBadAboutYourself',
  'troubleConcentratingOnThings',
  'movingOrSpeakingSlowerOrFasterThanNormal',
  'negativeThoughtsTowardsYourself',
  'howDifficultHaveTheseProblemsMadeThings',
  'phqTotalScore',

  // isotretinoinForm
  'Isotretinoin',
  'areYouTakingIsotretinoin',
  'startedCurrentTreatmentCourse',
  'doseCurrentlyTaking',
  'takingAnyOtherPills',
  'facialMoisturiserBeingUsed',
  'lipBalmBeingUsed',
  'feelLikeAcneImproving',
  'dryFacialSkin',
  'dryLips',
  'dryOrIrritatedEyes',
  'rashOnTheBody',
  'noseBleeds',
  'changesToYourVision',
  'changeToYourBowelHabit',
  'changesToYourMood',
  'muscleAchesOrPain',
  'backPain',
  'erectileDysfunction',
  'areYouFemale',
  'areYouSexuallyActive',
  'areYouOnContraception',
  'contraceptionDetails',
  'doYouUseCondoms'
];
