export default [
  'presentOccupation',
  'previousOccupations',

  //lesion questions
  'lesionQuestions',
  'lesionBodyLocation',
  'howLongHaveYouHadThisLesion',
  'whenDidThisLesionChange',
  'hasTheLesionChangedInTheLastThreeMonths',
  'lesionChangedInSize',
  'lesionChangedInShape',
  'lesionChangedInColour',
  'lesionBeenAnyOfTheFollowing',

  //risk factor
  'riskFactors',
  'previouslyHadSkinCancer',
  'previousCancerDetails',
  'familyMemberPreviouslyHadSkinCancer',
  'doYouSunburnEasily',
  'livedSomewhereHotAndSunny',
  'haveYouEverWorkedOutsideRegularly',
  'hobbiesThatTakePlaceOutside',
  'haveYouEverUsedSunbeds',
  'didYouEverHaveSevereOrBlisteringSunburnAsAChild',
  'doYouHaveAHistoryOfImmunosuppression',
  'immunosuppressionDetails',
  'shieldingDuringCovidPandemic',
  'covidShieldingDetails',

  //surgery risk
  'surgeryRiskFactors',
  'doYouHaveAnyAllergies',
  'doYouHaveAnyAllergiesDetails',
  'doYouHaveAPacemaker',
  'areYouOnAnyBloodThinningAgents',
  'bloodThinningAgentsDetails',
  'anyBloodborneViruses',
  'pregnantOrBreastfeeding',
  'consentToImagesOrRecordingsOnMyMedicalRecord',
  'consentToImagesBeingUsedAnonymously',
  'patientSignature'
];
