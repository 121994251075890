import { Pipe, PipeTransform } from '@angular/core';
import { EntriesService, ResizeName } from '../../services/entries/entries.service';
import { MediaOptions } from '@islacare/ic-types';
import { Observable } from 'rxjs';

@Pipe({
  name: 'resizedImageUrl',
})

/** ResizeImageUrlPipe is designed to be used with MediaOptions interface **/
export class ResizedImageUrlPipe implements PipeTransform {
  constructor(private readonly entriesService: EntriesService) {}
  transform(mediaOptions: MediaOptions): Observable<string> {
    let pathToUse = mediaOptions.mediaPath;
    if (mediaOptions.imageResizedStatus) {
      if (mediaOptions.imageResizedFormat) {
        pathToUse = this.entriesService.reconstructImagePath(
          mediaOptions.mediaPath,
          ResizeName.small,
          mediaOptions.imageResizedFormat,
        );
      } else {
        pathToUse = this.entriesService.reconstructImagePath(mediaOptions.mediaPath, ResizeName.small);
      }
    }
    return this.entriesService.imageUrl(pathToUse);
  }
}
