import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CheckForUpdateService } from '../../../../services/check-for-update/check-for-update.service';
import { TeamsService } from '../../../../services/teams/teams.service';
import { LayoutDefaultNavService } from '../../services/layout-default-nav/layout-default-nav.service';

@UntilDestroy()
@Component({
  selector: 'ic-shell',
  templateUrl: './shell.page.html',
})
export class ShellPageComponent {
  showReleaseNotes$ = this.layoutDefaultNavService.showReleaseNotes$;
  updateAvailable$ = this.checkForUpdateService.updateAvailable$;
  systemError$ = this.checkForUpdateService.systemError$;
  teams$ = this.teamsService.myTeams$;

  isIE11 = /Trident.*rv[ :]*11\./.test(navigator.userAgent);

  constructor(
    private layoutDefaultNavService: LayoutDefaultNavService,
    private checkForUpdateService: CheckForUpdateService,
    private teamsService: TeamsService,
    public afAuth: AngularFireAuth,
  ) {}

  closeReleaseNotes(): void {
    this.layoutDefaultNavService.showReleaseNotes = false;
  }

  reloadPage(): void {
    window.location.reload();
  }
}
