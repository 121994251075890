import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../../shared/components/dialogs/dialog-data';

interface IPatientPhoneEmailEditDialogData extends DialogData {
  data?: Record<string, string>;
}
const { required, email } = Validators;

@Component({
  selector: 'ic-patient-phone-email-edit-dialog',
  templateUrl: './patient-phone-email-edit-dialog.component.html',
  styleUrls: ['./patient-phone-email-edit-dialog.component.scss']
})
export class PatientPhoneEmailEditDialogComponent implements OnInit {
  patientPhoneAndEmailForm: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IPatientPhoneEmailEditDialogData,
    private _fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.patientPhoneAndEmailForm = this._fb.group({
      phone: [this.data?.data?.['phone'], [required]],
      email: [this.data?.data?.['email'] ?? '', [required, email]]
    });
  }
}
