import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'ic-clinical-form-field-slider',
  templateUrl: './clinical-form-field-slider.component.html',
  styleUrls: ['./clinical-form-field-slider.component.scss']
})
export class ClinicalFormFieldSliderComponent extends FieldType<FieldTypeConfig> implements OnInit {
  sliderRange = [];
  showNumberField = false;
  numberPlaceHolder: string;

  ngOnInit(): void {
    this.sliderRange = Array((this.field?.props?.max || 10) + 1 - (this.field?.props?.min || 0))
      .fill(0)
      .map((x, i) => i + (this.field?.props?.min || 0));

    if (this.sliderRange?.length > 11) {
      this.showNumberField = true;
      this.numberPlaceHolder = `${this.field?.props?.min} - ${this.field?.props?.max}`;
    }
  }
}
