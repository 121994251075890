import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { TeamWithId, UserWithId } from '@islacare/ic-types';
import { configureScope } from '@sentry/angular-ivy';
import { environment } from 'apps/frontend/portal/src/environments/environment';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, Observable, combineLatest, from, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../../../../services/auth/auth.service';
import { OrganisationFeatureToggleService } from '../../../../services/organisation-feature-toggle/organisation-feature-toggle.service';
import { OrganisationsService } from '../../../../services/organisations/organisations.service';
import { TeamsService } from '../../../../services/teams/teams.service';
import { UsersService } from '../../../../shared/services/users/users.service';
import { SystemCheckService } from '@ic-monorepo/shared-common';

export interface NavItem {
  label: string;
  url?: string;
  icon?: string;
  menuItems?: MenuItem[];
  tag?: NavItemTag;
}

export interface NavToolItem {
  id: string;
  label: string;
}

interface EmailVerifiedAndMFAEnabled {
  emailVerified: boolean;
  isMFARequired: boolean;
}

interface NavItemTag {
  label: string;
  severity: string;
}

@Injectable({
  providedIn: 'root',
})
export class LayoutDefaultNavService {
  private _showReleaseNotes = new BehaviorSubject<boolean>(false);
  get showReleaseNotes$(): Observable<boolean> {
    return this._showReleaseNotes.asObservable();
  }
  set showReleaseNotes(value: boolean) {
    this._showReleaseNotes.next(value);
  }

  constructor(
    private organisationFeatureToggle: OrganisationFeatureToggleService,
    private organisationsService: OrganisationsService,
    private angularFireAuth: AngularFireAuth,
    private usersService: UsersService,
    private teamsService: TeamsService,
    private authService: AuthService,
    private systemCheckService: SystemCheckService,
  ) {}

  /**
   * ## getNavItems()
   * This method returns an observable of an array of NavItems.
   * The NavItems contents are based on the user's role, organisation, feature toggles and teams that they're in.
   */
  getNavItems(): Observable<NavItem[]> {
    return combineLatest([
      this.isEmailVerifiedAndMFAEnabled$(),
      this.shouldSubmissionsTabBeHidden$(),
      this.organisationFeatureToggle.hideChangeLog$,
      this.usersService.me$,
      this.usersService.iAmAnAdmin$,
      this.teamsService.myTeams$,
    ]).pipe(
      map(([emailMFAStatus, isSubmissionsHidden, hideChangeLog, userDetails, iAmAnAdmin, myTeams]) => {
        return this.buildNavItems(emailMFAStatus, isSubmissionsHidden, hideChangeLog, userDetails, iAmAnAdmin, myTeams);
      }),
    );
  }

  // eslint-disable-next-line max-params-no-constructor/max-params-no-constructor
  private buildNavItems(
    emailMFAStatus: EmailVerifiedAndMFAEnabled,
    isSubmissionsHidden: boolean,
    hideChangeLog: boolean,
    userDetails: UserWithId,
    iAmAnAdmin: boolean,
    myTeams: TeamWithId[],
  ): NavItem[] {
    let navItems: NavItem[] = [];

    if (emailMFAStatus.emailVerified && !emailMFAStatus.isMFARequired) {
      navItems = navItems.concat(this.getStandardNavItems(userDetails, iAmAnAdmin, isSubmissionsHidden));
    }

    if (myTeams && myTeams.length > 0) {
      navItems.push(this.getTeamsNavItem(myTeams));
    }

    if (userDetails.firstName) {
      navItems.push(this.getUserAccountNavItem(userDetails, hideChangeLog));
    }

    return navItems;
  }

  private getStandardNavItems(userDetails: UserWithId, iAmAnAdmin: boolean, isSubmissionsHidden: boolean): NavItem[] {
    const items: NavItem[] = [
      {
        url: '/user-dashboards/teams',
        label: 'Reporting',
      },
    ];

    if (iAmAnAdmin && userDetails.organisationId) {
      items.push({
        url: `/admin/organisations/${userDetails.organisationId}`,
        label: 'Admin',
      });
    }

    items.push({
      url: `/patients`,
      label: 'Patients',
    });

    if (!isSubmissionsHidden) {
      items.push({
        url: '/submissions-dashboard',
        label: 'Submissions',
        tag: { label: 'NEW', severity: 'info' },
      });
    }

    return items;
  }

  private getTeamsNavItem(myTeams: TeamWithId[]): NavItem {
    const myTeamsOrdered = myTeams.sort((a, b) => a.name.localeCompare(b.name));

    return {
      label: 'My teams',
      icon: 'pi pi-angle-down',
      url: '/teams',
      menuItems: myTeamsOrdered.map(team => ({
        label: team.name,
        routerLink: `/teams/${team.id}`,
      })),
    };
  }

  private getUserAccountNavItem(userDetails: UserWithId, hideChangeLog: boolean): NavItem {
    const defaultUserMenuItems: MenuItem[] = [
      { label: 'My profile', routerLink: '/profile' },
      {
        label: 'Accessibility statement',
        routerLink: '/accessibility',
      },
      { label: 'Logout', routerLink: 'auth/logout' },
    ];

    if (!hideChangeLog) {
      defaultUserMenuItems.unshift({ label: 'Updates', command: () => this.openChangeLog() });
    }

    return {
      label: `${userDetails.firstName}`,
      icon: 'pi pi-user',
      url: '/account',
      menuItems: defaultUserMenuItems,
    };
  }

  private isEmailVerifiedAndMFAEnabled$(): Observable<{ emailVerified: boolean; isMFARequired: boolean }> {
    return combineLatest([this.checkIfEmailVerifiedAndConfigureSentry$(), this.checkIfMultiFactorAuthRequired$()]).pipe(
      map(([emailVerified, isMFARequired]) => ({ emailVerified, isMFARequired })),
    );
  }

  getNavToolItems(): Observable<NavToolItem[]> {
    return this.usersService.myOrganisationId$.pipe(
      switchMap(id => this.organisationsService.getOrganisation$(id)),
      map(org => {
        const toolNavItems: NavToolItem[] = [];

        if (org?.featureFlags.functionality) {
          if (org.featureFlags.functionality.news2ButtonOn) {
            toolNavItems.push({ id: 'moa0MHnVlyNZQFT58NUn', label: 'NEWS2' });
          }

          if (org.featureFlags.functionality.showThinkSepsisCalcButton) {
            toolNavItems.push({ id: 'zr1uGrBTK3sFOpGikown', label: 'Think Sepsis' });
          }
        }

        return toolNavItems;
      }),
    );
  }

  private openChangeLog(): void {
    this.showReleaseNotes = true;
  }

  private checkIfEmailVerifiedAndConfigureSentry$(): Observable<boolean> {
    return this.angularFireAuth.authState.pipe(
      tap(user => {
        if (!user) return;

        configureScope(scope => {
          scope.setUser({
            id: user?.uid || null,
          });
        });
      }),
      map(user => user?.emailVerified),
    );
  }

  private checkIfMultiFactorAuthRequired$(): Observable<boolean> {
    return this.angularFireAuth.authState.pipe(
      switchMap(user => {
        if (!user) return of(false);

        return combineLatest([
          this.authService.orgRequires2FA$(),
          from(this.authService.checkIfReverificationIsRequired$()),
        ]).pipe(map(([orgRequires, userRequires]) => orgRequires && userRequires));
      }),
    );
  }

  private shouldSubmissionsTabBeHidden$(): Observable<boolean> {
    const teamIdsWhereSubmissionsTabIsHiddenArr = [
      'GbNyY8OCUNGvKoLQ694u', // South Notts Therapy
      'yo7cnrA0vEggBw3GZiiI', // Rushcliffe
    ];

    const projectsToHideSubmissionPage = ['isla-europe-production', 'isla-usa-production', 'isla-uk-production'];

    return this.usersService.me$.pipe(
      map(user => {
        const teamIdsHidden =
          user?.teamIds?.some(teamId => teamIdsWhereSubmissionsTabIsHiddenArr.includes(teamId)) || false;
        const submissionPageHidden = projectsToHideSubmissionPage.includes(environment.firebase.projectId);
        return teamIdsHidden || submissionPageHidden || this.systemCheckService.isMobileOrTablet;
      }),
    );
  }
}
