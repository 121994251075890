import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClinicianSubmissionService } from 'apps/frontend/portal/src/app/feature-clinician-submission/services/clinician-submission/clinician-submission.service';
import { MenuItem } from 'primeng/api';
import { PrimeNgModule } from '../modules/primeng.module';

@Component({
  standalone: true,
  selector: 'ic-mobile-file-uploader',
  templateUrl: './mobile-file-uploader.component.html',
  imports: [PrimeNgModule],
})
export class MobileFileUploaderComponent {
  @Input() captureMenuItems: MenuItem[];
  @Input() maxFileSize: number;

  @Output() fileUpload = new EventEmitter<void>();

  constructor(private clinicianSubmissionService: ClinicianSubmissionService) {}

  triggerFileUpload(): void {
    this.clinicianSubmissionService.uploaderClick();
  }
}
