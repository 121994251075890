<div class="flex flex-column flex-1 bg-inverse-main-surface-alt">
  <pinch-zoom [limit-zoom]="4" [minPanScale]="0">
    <img
      [src]="entryWithOriginalImage.urlLarge | authImage"
      class="h-full w-full el-bg-disabled object-fit-contain"
      alt="Entry item original image"
    />
  </pinch-zoom>
</div>

<ng-template #dialogFooter>
  <div class="flex gap-1 justify-content-end">
    <button
      (click)="ref.close()"
      class="p-button-outlined"
      aria-label="Close"
      label="Close"
      type="button"
      pButton
    ></button>
  </div>
</ng-template>
