export default [
  'dateOfSurgery',
  'ppad',
  'noPpadReason',
  'dateOfDischarge',
  'ward',
  'category',
  'sutureMaterial',
  'sutureMaterialRemovalDate',
  'sutureRemovalNeededBy',
  'surgicalWoundAssessment',
  'surgicalWoundAssessmentOther',
  'microbioResults',
  'drainSites',
  'drainSitesRemovalDate',
  'drainSitesRemovalNeededBy',
  'additionalResources',
  'responsibleSurgeon',
  'cardiologyConsultant',
  'clinicalNurse',
  'staffProvidingPpad',
  'notes'
];
