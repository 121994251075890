import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'ic-missing-data',
  templateUrl: './missing-data.component.html',
})
export class MissingDataComponent {
  @Input() messageTitle: string;
  @Input() messageBody: string;
  @Input() messageIcon: string;
  @Input() messageLink: string;
  @Input() messageLinkText: string;
}
