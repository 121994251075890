import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'ic-clinical-form-field-datepicker',
  templateUrl: './clinical-form-field-datepicker.component.html'
})
export class ClinicalFormFieldDatepickerComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  ngOnInit() {
    // Convert ISO date string to Date object on init
    const initialValue = this.field.formControl.value;
    if (typeof initialValue === 'string') {
      this.formControl.setValue(new Date(initialValue), { emitEvent: false });
    }
  }
}
