import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';
import { fabric } from 'fabric';

@Component({
  standalone: true,
  selector: 'ic-visual-pain-form',
  templateUrl: './visual-pain-form.component.html',
  styleUrls: ['./visual-pain-form.component.scss'],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class VisualPainFormComponent implements OnInit {
  @Input() data;
  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  urlParams: any;
  canvas: any;
  visualPainForm: UntypedFormGroup;
  isSubmitting = false;
  sliderMin = 0;
  wongBakerSliderMax = 5;
  painSliderMax = 10;
  tickInterval = '1';
  answerList: string[] = [
    'No hurt',
    'Hurts Little Bit',
    'Hurts Little More',
    'Hurts Even More',
    'Hurts Whole Lot',
    'Hurts Worst',
  ];
  emojiList = [
    '&#x1F600',
    '&#x1F642',
    '&#x1F610',
    '&#x1F615',
    '&#x1F61F',
    '&#x1F622',
  ];

  ngOnInit(): void {
    this.visualPainForm = this.fb.group({
      painSeverityScale: [''],
      wongBakerDropdown: ['', [Validators.required]],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.visualPainForm.controls[key])
          this.visualPainForm.controls[key].setValue(formData[key]);
      });
    });

    this.canvas = new fabric.Canvas('sheet');
    this.canvas.isDrawingMode = true;
    this.canvas.freeDrawingBrush.width = 2;
    this.canvas.freeDrawingBrush.color = 'red';
    this.setCanvasImage();
  }

  get wongBakerDropdown() {
    return this.visualPainForm.get('wongBakerDropdown');
  }

  clearMarking(event) {
    event.preventDefault();
    this.canvas.clear();
    this.setCanvasImage();
  }

  setCanvasImage() {
    const self = this;
    this.canvas.setBackgroundColor(
      {
        source:
          'https://firebasestorage.googleapis.com/v0/b/portal-dev-e4b9a.appspot.com/o/bodyMap.png?alt=media&token=51f6ed16-a86c-4c35-a65c-bed540e5705e',
      },
      () => self.canvas.renderAll()
    );
  }

  onSubmit() {
    this.isSubmitting = true;
    const formResponse = {
      ...this.visualPainForm.value,
      bodyMapDataUrl: this.canvas.toDataURL(),
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }
}
