import { FormlyExtension, FormlyFieldConfig } from '@ngx-formly/core';

export const clinicalFormDefaultFormFieldExtension: FormlyExtension = {
  prePopulate(field: FormlyFieldConfig): void {
    if (field?.key) {
      field.props = {
        ...(field.props || {}),
        attributes: {
          'data-cy': field?.key.toString()
        }
      };
    }
    field.expressions = {
      ...(field.expressions || {}),
      'props.required': !field.props?.required ? 'false' : '!field?.props?.hidden',
      'props.calc': (field: FormlyFieldConfig) => {
        if (!field?.key) return;

        const calculationValue = getCalculationValue(field);

        if (field?.options?.formState?.calculationValues)
          field.options.formState.calculationValues[field?.key as string] = calculationValue;

        return calculationValue;
      }
    };
  }
};

function getCalculationValue(field: FormlyFieldConfig) {
  const calculationValue = field?.model?.[field.key as string];

  if (!calculationValue || isNaN(calculationValue)) return 0;

  if (field?.props?.options && Array.isArray(field.props.options)) {
    const calculationValueFromOption = field.props.options.find(
      option => option.value === calculationValue
    )?.calculationValue;
    return calculationValueFromOption || 0;
  }

  return calculationValue;
}
