<ng-container [ngSwitch]="true">
  <ng-container *ngSwitchCase="video" [ngTemplateOutlet]="videoTemplate"></ng-container>
  <ng-container
    *ngSwitchCase="fileType === 'pdf' || fileType === 'application/pdf'"
    [ngTemplateOutlet]="pdf"
  ></ng-container>
  <ng-container *ngSwitchCase="fileType === 'file/e2e' || fileType === 'e2e'" [ngTemplateOutlet]="e2e"></ng-container>
  <ng-container
    *ngSwitchCase="fileType === 'audio/wav' || fileType === 'wav'"
    [ngTemplateOutlet]="audioTemplate"
  ></ng-container>
  <ng-container
    *ngSwitchCase="fileType === 'audio/mp3' || fileType === 'mp3'"
    [ngTemplateOutlet]="audioTemplate"
  ></ng-container>
  <ng-container *ngSwitchDefault [ngTemplateOutlet]="image"></ng-container>
</ng-container>

<ng-template #image>
  <div [ngClass]="{ 'blur-75 blur-0-hover': sensitiveImage && !enableZoom }" class="h-full">
    <div *ngIf="enableZoom && source; else thumbnail" class="pinch-zoom-container">
      <pinch-zoom class="md-zoom-container" [limit-zoom]="4" [minPanScale]="0" [style.minHeight]="minHeight">
        <img [src]="source | authImage" alt="Entry photo" />
      </pinch-zoom>
    </div>
    <ng-template #thumbnail>
      <div
        class="media-display-image-container h-full sm:w-15rem md:w-20rem lg:w-25rem xl:w-30rem flex flex-column alignt-items-center justify-content-center"
      >
        <p-image
          [src]="enableAuthImage ? (source | authImage) : source"
          [preview]="false"
          alt="Entry photo"
          (click)="goToEntry()"
          *ngIf="source"
          styleClass="h-full w-full flex flex-column align-items-center"
          class="h-full w-full flex flex-column align-items-center image-preview-no-click"
          imageClass="h-full el-bg-disabled object-fit-contain"
          width="100%"
        >
        </p-image>
      </div>
    </ng-template>
  </div>
</ng-template>

<ng-template #pdf>
  <div *ngIf="pdfIsLoaded; else pdfPlaceholder" style="text-align: center; vertical-align: text-bottom" id="pdf-viewer">
    <ng-container *ngIf="!hidePdfControls">
      <div class="flex justify-content-center align-items-center mb-2 mt-3 md:mt-0">
        <button
          pButton
          type="button"
          label="Prev"
          aria-label="Prev"
          (click)="prevPage()"
          [disabled]="pdfPage === 1"
          class="p-button-sm"
        ></button>
        <span class="px-3">{{ pdfPage }} / {{ totalPdfPages }}</span>
        <button
          pButton
          type="button"
          label="Next"
          aria-label="Next"
          (click)="nextPage()"
          [disabled]="pdfPage === totalPdfPages"
          class="p-button-sm"
        ></button>
      </div>
    </ng-container>
    <div class="pdf-container">
      <pdf-viewer
        id="pdf-viewer-component"
        [src]="source"
        [render-text]="true"
        [original-size]="false"
        [page]="pdfPage"
        [autoresize]="true"
      ></pdf-viewer>
    </div>
  </div>

  <ng-template #pdfPlaceholder><ic-file-placeholder fileType="PDF"></ic-file-placeholder></ng-template>
</ng-template>

<ng-template #e2e>
  <div [ngClass]="{ 'blur-75 blur-0-hover': sensitiveImage }">
    <ic-file-placeholder fileType="e2e"></ic-file-placeholder>
  </div>
</ng-template>

<ng-template #videoTemplate>
  <div *ngIf="videoConvertStatus === VideoConvertStatusEnum.PROCESSING" class="processing-container">
    <i class="pi pi-spin pi-spinner"></i>
    <div class="processing-text">Processing video...</div>
  </div>
  <img
    *ngIf="videoConvertStatus === VideoConvertStatusEnum.FAILURE"
    mat-card-image
    class="error-image"
    src="assets/error-image.jpeg"
    alt="Video conversion failed"
  />

  <div
    *ngIf="isLargeFile"
    class="h-full sm:w-15rem md:w-20rem lg:w-25rem xl:w-30rem flex flex-column alignt-items-center justify-content-center"
  >
    <span
      pTooltip="File will still be successfully uploaded. If you have any questions, please contact our support team"
      >Unable to generate preview: File size too large</span
    >
  </div>
  <!-- source will be created only if video conversion status is/changed to success or if this flag doesn't exist(for older entries) -->
  <ng-container *ngIf="source && !isLargeFile">
    <vg-player (onPlayerReady)="onPlayerReady($event)">
      <vg-overlay-play></vg-overlay-play>
      <vg-buffering></vg-buffering>

      <video id="review-video" [vgMedia]="$any(media)" [src]="source" type="video" #media></video>
      <vg-controls vgFor="review-video" [vgAutohide]="false" *ngIf="!hideVideoControls">
        <vg-play-pause></vg-play-pause>
        <vg-time-display vgProperty="current" vgFormat="hh:mm:ss"></vg-time-display>
        <vg-playback-button [playbackValues]="['0.25', '0.5', '1.0', '2.0', '5', '10']"></vg-playback-button>

        <vg-scrub-bar>
          <vg-scrub-bar-current-time vgFor="review-video" [vgSlider]="true"></vg-scrub-bar-current-time>
          <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
        </vg-scrub-bar>

        <vg-time-display vgProperty="total" vgFormat="hh:mm:ss"></vg-time-display>
        <vg-mute></vg-mute>

        <vg-fullscreen></vg-fullscreen>
      </vg-controls>
    </vg-player>
  </ng-container>
</ng-template>

<ng-template #audioTemplate>
  <ng-container *ngIf="source">
    <div>
      <i class="pi pi-volume-up"></i>
    </div>
    <audio controls>
      <source [src]="sanitzedSource" type="audio/wav" />
    </audio>
  </ng-container>
</ng-template>

<span class="shadow-pdf">
  <ng-template #shadowHost></ng-template>
</span>
