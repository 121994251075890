<h1 mat-dialog-title>
  {{ data.title }}
</h1>
<div mat-dialog-content>
  <p>{{ data.message }}</p>
  <p class="highlighted-text">
    By checking the box below, you confirm that you are adding the patient to this team to provide
    or support the individual patient’s direct care.
  </p>

  <mat-checkbox
    [checked]="data.isConfirmed"
    (change)="userConfirmation()"
    data-cy="user-confirm-checkbox"
  >
    I confirm I have a reason to access this patient’s information pertaining to direct care of the
    patient.
  </mat-checkbox>
</div>

<div mat-dialog-actions class="dialog-button-group">
  <div *ngIf="data.negativeButton">
    <button mat-button [mat-dialog-close]>
      {{ data.negativeButton }}
    </button>
  </div>

  <div class="submit-button">
    <button
      [mat-dialog-close]="true"
      data-cy="user-confirm"
      mat-button
      [disabled]="!data.isConfirmed"
    >
      {{ data.positiveButton }}
    </button>
  </div>
</div>
