import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { FormService } from '@ic-monorepo/services';
import { FormField, UserInputType } from '@islacare/ic-types';

@Component({
  selector: 'ic-automatic-multi-select',
  templateUrl: './automatic-multi-select.component.html',
  styleUrls: ['./automatic-multi-select.component.scss'],
})
export class AutomaticMultiSelectComponent {
  @Input() field: FormField;
  @Input() control: UntypedFormControl;
  UserInputType = UserInputType;

  /** For testing purposes */
  @ViewChild(MatSelect) matSelect: MatSelect;

  //formService is injected here to call getErrorMessage from FormService in HTML
  constructor(public formService: FormService) {}
}
