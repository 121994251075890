import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { PrimeNgModule } from '../../modules/primeng.module';

@Component({
  standalone: true,
  selector: 'ic-file-uploader-drag-drop',
  templateUrl: './file-uploader-drag-drop.component.html',
  styles: [
    `
      .drag-over {
        border: 1px solid red;
      }
    `,
  ],
  imports: [PrimeNgModule],
})
export class FileUploaderDragDropComponent {
  @Input() captureMenuItems: MenuItem[];
  @Input() maxFileSize: number;

  @Output() fileUpload = new EventEmitter<void>();
  @Output() droppedFiles = new EventEmitter<FileList>();

  onDragOver(event: Event): void {
    event.preventDefault();
  }

  triggerFileUpload(): void {
    this.fileUpload.emit();
  }

  handleDrop(event: DragEvent): void {
    event.preventDefault();
    const fileList: FileList = event.dataTransfer.files;

    this.droppedFiles.emit(fileList);
  }
}
