<div class="field">
  <p>
    We have generated a unique
    <a
      [href]="data.message | devAppendPathToCurrentUrl"
      data-cy="patient-submission-link-email"
      >link</a
    >
    to add to this collection.
  </p>
  <form [formGroup]="emailRequestForm">
    <ng-container *ngIf="recipientKeys">
      <ng-container *ngIf="recipientKeys.length > 1">
        <p *ngIf="messageType === MessageType.MANUAL_REMINDER">
          You can only send reminders to the email that the initial request was
          sent to. If you’d like to send to a different email, please send a new
          request
        </p>
        <p *ngIf="messageType !== MessageType.MANUAL_REMINDER">
          Note, there are multiple email addresses for this patient. Please
          select the person we should contact:
        </p>
      </ng-container>
      <div *ngIf="recipientKeys.length > 1 || !this.data.email" class="field">
        <label class="font-bold block" for="recipientDropdown"
          >Recipient:</label
        >

        <div class="p-fluid flex gap-2 flex-column md:flex-row">
          <p-dropdown
            name="recipientDropdown"
            formControlName="recipient"
            [options]="data.additionalEmailAddresses | keyvalue"
            optionValue="key"
            placeholder="Select a recipient"
            [showClear]="true"
            [disabled]="messageType === MessageType.MANUAL_REMINDER"
          >
            <ng-template let-recipient pTemplate="selectedItem">
              <div>{{ recipient.key }}: ({{ recipient.value }})</div>
            </ng-template>
            <ng-template let-recipient pTemplate="item">
              <div>{{ recipient.key }}: ({{ recipient.value }})</div>
            </ng-template>
          </p-dropdown>
        </div>
      </div>
    </ng-container>

    <p *ngIf="emailRequestForm.value.recipient">
      We will share the link with this email:
      <strong>{{
        data.additionalEmailAddresses[emailRequestForm.value.recipient] ||
          data.email
      }}</strong>
      if it is valid.
    </p>

    <div class="field pt-2">
      <p-checkbox
        ngDefaultControl
        [formControl]="$any(emailRequestForm).controls['reminder']"
        [binary]="true"
        label="Send a reminder to the patient tomorrow morning if they haven't submitted anything."
      ></p-checkbox>
    </div>

    <div class="field pt-1">
      <p-checkbox
        [binary]="true"
        label="Include [Dear Patient]"
        formControlName="includeMessageIntro"
      ></p-checkbox>
    </div>

    <div class="field pt-3">
      <label class="font-bold block" for="emailMessageText"
        >Enter your guidance for the patient below:</label
      >
      <div class="bg-white border-black-alpha-10 border-round px-4 py-3">
        <p class="mt-0 text-sm">
          {{ emailRequestForm.controls.messageIntro.value }}
        </p>
        <textarea
          name="messageBody"
          pInputTextarea
          formControlName="messageBody"
          rows="5"
          class="w-full"
        >
        </textarea>
        <small
          *ngIf="
            !emailRequestForm.get('messageBody').valid &&
            emailRequestForm.get('messageBody').touched
          "
          class="p-invalid text-brand"
        >
          A message is required
        </small>
        <p class="mb-0 text-sm">
          {{ emailRequestForm.controls.messageExpiry.value }}
          <span class="text-link font-bold"
            >[unique link will appear here]</span
          >
        </p>
      </div>
    </div>
  </form>
</div>
<div class="flex gap-2 justify-content-end">
  <button
    (click)="onNoClick()"
    class="p-button-outlined"
    aria-label="Cancel"
    data-cy="emailPatientSubmissionLinkCancel"
    label="Cancel"
    type="button"
    pButton
  ></button>
  <button
    pButton
    aria-label="Send"
    label="Yes, send!"
    (click)="emailRequestSubmit()"
    [disabled]="!emailRequestForm.value.recipient"
  ></button>
</div>
