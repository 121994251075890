<div class="h-full flex flex-column justify-content-between">
  <div class="overflow-y-scroll h-full">
    <div *ngIf="!isEmailView">
      <div class="h-full">
        <div *ngIf="isLoading" class="text-center">
          <p class="m-auto" *ngIf="savedToEpr">Saving to EPR...</p>
          <p class="m-auto" *ngIf="savedToS1">Saving to SystmOne...</p>
          <ic-spinner class="m-auto"></ic-spinner>
        </div>

        <iframe
          [src]="pdfData?.fileURL"
          height="90%"
          width="100%"
          style="height: calc(60vh)"
          id="image-pdf-viewer"
          aria-label="image-pdf-viewer"
          *ngIf="!isPdfLoading && !isMobile && !pdfData?.error && pdfData?.fileURL"
        >
        </iframe>

        <!-- isMobile -->
        <div class="w-full" *ngIf="!isPdfLoading && isMobile && !pdfData?.error">
          <button
            pButton
            class="w-full"
            label="Download PDF"
            icon="pi pi-download"
            (click)="downloadThisFile()"
          ></button>
          <button
            pButton
            class="w-full mt-3"
            (click)="savePdfToEpr()"
            icon="pi pi-save"
            *ngIf="organisation?.featureFlags?.functionality?.savePdfToEprEnabled"
          >
            <ng-container *ngIf="!savedToEpr || !isLoading">Save to EPR</ng-container>
            <ng-container *ngIf="savedToEpr && isLoading">Saving to EPR</ng-container>
          </button>
          <button
            pButton
            class="w-full mt-3"
            (click)="showEmailViewer()"
            *ngIf="organisation?.featureFlags?.functionality?.emailEnabled"
            icon="pi pi-envelope"
            label="Email to patient"
          ></button>
        </div>

        <div *ngIf="pdfData?.error" class="mt-3">
          {{ pdfData?.message }}
        </div>
        <div *ngIf="isPdfLoading" class="text-center">
          <p class="m-auto mb-3">Please wait while we compile the PDF...</p>
          <ic-spinner class="m-auto"></ic-spinner>
        </div>
        <div *ngIf="isPdfLoading"></div>
      </div>
    </div>

    <div *ngIf="isEmailView">
      <div>
        <div class="w-full inline-flex">
          <div class="flex flex-column w-full">
            <p-steps [model]="items" [(activeIndex)]="activeIndex" [readonly]="false"></p-steps>
            <div class="w-full" *ngIf="activeIndex === 0">
              <div class="p-3 border-round-lg">
                <ng-container *ngTemplateOutlet="emailTemplate"></ng-container>
                <div class="flex flex-row justify-content-end">
                  <button
                    pButton
                    *ngIf="patient && (!patient?.email?.length || isPatientPhoneInvalid)"
                    class="m-2"
                    (click)="navigateToPatientEdit()"
                  >
                    Add/ update details
                  </button>

                  <button
                    pButton
                    *ngIf="
                      patient?.email?.length &&
                      !isPatientPhoneInvalid &&
                      (patientContactList?.length === 1 ||
                        (patientContactList?.length > 1 && patientContactPerson?.length))
                    "
                    class="m-2"
                    label="Next"
                    (click)="changeStep(1)"
                  ></button>
                </div>
              </div>
            </div>
            <div class="w-full" *ngIf="activeIndex === 1">
              <div class="flex w-full justify-content-center">
                <ng-container *ngTemplateOutlet="sendEmailTemplate"></ng-container>
              </div>
            </div>
          </div>

          <ng-template #emailTemplate>
            <div *ngIf="patient && (!patient?.email?.length || isPatientPhoneInvalid)">
              The patient's email address and phone number are required to share the PDF and password. Please update
              these details.
            </div>

            <div *ngIf="patientContactList?.length && patientContactList?.length > 1">
              <p>There are multiple phone numbers for this patient. Please select the person we should contact:</p>
              <div class="card p-fluid">
                <p-dropdown
                  class="w-full"
                  [options]="patientContactList | keyvalue"
                  placeholder="Select a recipient"
                  optionValue="value"
                  optionLabel="value"
                  [(ngModel)]="patientContactPerson"
                  (selectionChange)="setPdfSms()"
                ></p-dropdown>
              </div>
            </div>

            <div
              class="py-2"
              *ngIf="patient?.email?.length && (patientContactPerson || patientContactList?.length === 1)"
            >
              <div class="">
                <ul>
                  <li>
                    Email:
                    <strong style="word-break: break-word">{{ patient?.email }}</strong>
                  </li>
                  <li>
                    Phone number:
                    <strong
                      >{{
                        patientNumbersAndSmsIntros?.additionalPhoneNumbers[
                          patientContactPerson || patientContactList[0]
                        ]
                      }}
                    </strong>
                    <p *ngIf="isPatientPhoneInvalid" class="text-error">
                      This is not a valid mobile number. Please update the patient's mobile number to send the PDF.
                    </p>
                  </li>
                </ul>
              </div>

              <p>We will share the PDF password at the end of the SMS as below if the phone number is valid:</p>
              <div class="p-fluid">
                <textarea pInputTextarea [(ngModel)]="pdfPasswordSms"> </textarea>
              </div>
            </div>
          </ng-template>

          <ng-template #sendEmailTemplate>
            <div>
              <div *ngIf="pdfData?.fileURL" class="flex flex-row" [ngClass]="{ 'flex-column': isMobile }">
                <div>
                  <ul>
                    <li>
                      The PDF will be shared to this email:
                      <strong style="word-break: break-word">{{ patient?.email }}</strong
                      >.
                    </li>
                    <li>
                      We have generated a random code of
                      <strong>{{ pdfPassword }}</strong> for the PDF. This will be sent to this phone number:
                      <strong
                        >{{
                          patientContactPerson?.length
                            ? patientNumbersAndSmsIntros?.additionalPhoneNumbers[patientContactPerson] +
                              '
                                      (' +
                              patientContactPerson +
                              ')'
                            : patient?.phone
                        }}.
                      </strong>
                    </li>
                  </ul>
                </div>
                <div [ngClass]="{ 'w-full': isMobile, 'ml-3': !isMobile }">
                  <button
                    [ngClass]="{ 'w-full': isMobile }"
                    pButton
                    icon="pi pi-send"
                    (click)="sendEmailAndSmsToPatient()"
                    [disabled]="isSendingEmail"
                    label="{{ isSendingEmail ? 'Sending...' : 'Send' }}"
                  ></button>
                </div>
              </div>
              <iframe
                [src]="pdfData?.fileURL"
                width="100%"
                id="image-pdf-viewer"
                aria-label="image-pdf-viewer"
                *ngIf="!isPdfLoading && !isMobile && !pdfData?.error && pdfData?.fileURL"
                style="height: calc(50vh)"
              >
              </iframe>
              <div *ngIf="!isPdfLoading && isMobile && !pdfData?.error" class="mt-3 w-full">
                <button
                  pButton
                  class="w-full"
                  icon="pi pi-download"
                  label="Download PDF"
                  (click)="downloadThisFile()"
                ></button>
              </div>
              <div *ngIf="pdfData?.error" class="mt-4">
                {{ pdfData?.message }}
              </div>
              <div *ngIf="isPdfLoading" class="text-center">
                <p class="m-auto">Please wait while we are getting the PDF ready for you...</p>
                <ic-spinner class="m-auto"></ic-spinner>
              </div>
              <div *ngIf="isPdfLoading"></div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="flex justify-content-between pt-2">
    <div class="flex justify-content-starts">
      <button
        (click)="backToPatientRecord()"
        class="p-button-outlined p-button-primary"
        aria-label="Back to patient record"
        label="Back to patient record"
        icon="pi pi-arrow-left"
        pButton
      ></button>
    </div>

    <ng-container *ngIf="!isMobile && !isEmailView">
      <div class="flex flex-row justify-content-end gap-1">
        <button
          *ngIf="!pdfData?.error && organisation?.featureFlags?.functionality?.emailEnabled"
          (click)="showEmailViewer()"
          [disabled]="isPdfLoading || multiCollectionFlag"
          id="showEmailViewer"
          class="p-button-outlined p-button-primary"
          pButton
        >
          <i class="pi pi-envelope mr-2"></i>
          <span>Email to patient <span class="font-normal">(photo/form only)</span></span>
        </button>

        <button
          pButton
          (click)="printThisPdf()"
          id="printFileBtn"
          class="p-button-outlined p-button-primary"
          [disabled]="isPdfLoading"
          *ngIf="!pdfData?.error"
          label="Print PDF"
          icon="pi pi-print"
        ></button>
        <button
          pButton
          (click)="savePdfToEpr()"
          id="savePdfToEprBtn"
          class="p-button-outlined p-button-primary"
          [disabled]="isPdfLoading || savedToEpr || multiCollectionFlag"
          *ngIf="!pdfData?.error && organisation?.featureFlags?.functionality?.savePdfToEprEnabled"
          icon="pi pi-save"
        >
          <span class="pdf-button-text ml-2">
            <ng-container *ngIf="!savedToEpr || !isLoading">Save to EPR</ng-container>
            <ng-container *ngIf="savedToEpr && isLoading">Saving to EPR</ng-container>
          </span>
        </button>
        <button
          pButton
          (click)="downloadThisFile()"
          id="downloadFileBtn"
          class="p-button-primary"
          [disabled]="isPdfLoading"
          *ngIf="!pdfData?.error"
          label="Download PDF"
          icon="pi pi-download"
        ></button>

        <ng-container *ngIf="isPatientLinkedToS1">
          <button
            pButton
            icon="pi pi-save"
            class="p-button-primary s1-button"
            (click)="savePdfToS1()"
            [disabled]="isPdfLoading || savedToS1 || multiCollectionFlag"
          >
            <span class="pdf-button-text ml-2">
              <ng-container *ngIf="!savedToS1 || !isLoading">Save to SystmOne</ng-container>
              <ng-container *ngIf="savedToS1 && isLoading">Saving to SystmOne</ng-container>
            </span>
          </button>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
