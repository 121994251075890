export default [
  'haveYouBeenToldYouHaveMedicalProblems',
  'haveYouBeenToldYouHaveMedicalProblemsFreeText',
  'doYouHaveAnyCardiacDevices',
  'whatMedicationDoYouTake',
  'doYouHaveAnyKnownDrugAllergies',
  'doYouHaveAnyKnownDrugAllergiesFreeText',
  'whatIsYourOccupation',
  'notes'
];
