<!-- UPDATE THIS COMPONENT TO BE SCALABLE -->
<ng-container *ngIf="{ filteredTags: filteredTags$ | async } as manageTagsData">
  <div class="container">
    <div class="header flex justify-content-between align-items-center">
      <p class="font-bold m-0 py-1">Add tags to {{ selectedSubmissionsCount }} submission(s)</p>
      <i class="pi pi-times cursor-pointer" (click)="closePanel()"></i>
    </div>

    <p-divider></p-divider>

    <div>
      <span class="p-input-icon-right w-full">
        <i class="pi pi-search"></i>
        <input type="text" pInputText [formControl]="searchControl" placeholder="Search" class="w-full" />
      </span>
    </div>
    <div class="content-container overflow-y-scroll">
      <ul class="list-none p-0">
        <li *ngFor="let group of manageTagsData.filteredTags">
          <ng-container *ngIf="group?.tags?.length">
            <div class="group-header my-2">
              {{ group.tagGroupDisplayName }}
            </div>
          </ng-container>

          <ul *ngIf="group?.tags?.length" class="list-none p-0">
            <li *ngFor="let tag of group.tags" [ngClass]="{ 'el-bg-primary-subtle-alt': selectedTags.includes(tag) }">
              <div class="tag-item py-2 flex align-items-center">
                <p-checkbox
                  (onChange)="toggleSelectedTag($event, tag)"
                  (click)="$event.stopPropagation()"
                  [binary]="true"
                  [value]="selectedTags"
                ></p-checkbox>
                <span class="ml-2">{{ tag.tagDisplayName }}</span>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="flex pt-2">
      <button
        pButton
        aria-label="Cancel"
        class="p-button-outlined w-full mr-1"
        label="Cancel"
        (click)="closePanel()"
      ></button>
      <button
        pButton
        [disabled]="!selectedTags.length"
        aria-label="Add tags"
        class="w-full ml-1"
        [label]="
          selectedTags.length > 1
            ? 'Add ' + selectedTags.length + ' tags'
            : selectedTags.length === 1
              ? 'Add tag'
              : 'Add tags'
        "
        icon="pi pi-plus-circle"
        (click)="submitTags()"
      ></button>
    </div>
  </div>
</ng-container>
