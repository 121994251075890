import { Injectable } from '@angular/core';
import { AngularFireUploadTask } from '@angular/fire/compat/storage';
import { Reference, UploadTask } from '@angular/fire/compat/storage/interfaces';
import { SafeResourceUrl } from '@angular/platform-browser';
import { format } from 'date-fns';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  BehaviorSubject,
  Observable,
  Observer,
  catchError,
  combineLatest,
  filter,
  finalize,
  map,
  startWith,
  switchMap,
  tap
} from 'rxjs';
import { SystemCheckService } from '../system-check/system-check.service';
import { UUIDService } from '../uuid/uuid.service';

export interface UploadInfo {
  id: string;
  fileName: string;
  status: 'ongoing' | 'complete' | 'cancelled' | 'errored';
  progress$: Observable<any>;
  error?: any;
  uploadTask?: UploadTask | AngularFireUploadTask;
  snapshotRef?: Reference;
  fileUrl?: SafeResourceUrl;
  fileType?: string;
  isHovered?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private _allUploads: UploadInfo[] = [];
  allUploads$: BehaviorSubject<UploadInfo[]> = new BehaviorSubject(
    this._allUploads
  );

  private _processingUpload = false;
  processingUpload$: BehaviorSubject<boolean> = new BehaviorSubject(
    this._processingUpload
  );

  private _panelHidden: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  panelHidden$ = this._panelHidden.asObservable();

  anyUploading$: Observable<boolean> = this.allUploads$.pipe(
    map(uploads => uploads.some(upload => upload.status === 'ongoing'))
  );

  ongoingUploadCount$: Observable<number> = this.allUploads$.pipe(
    map(uploads => uploads.filter(upload => upload.status === 'ongoing').length)
  );

  uploadIcon$: Observable<string> = this.anyUploading$.pipe(
    map(isUploading =>
      isUploading
        ? 'pi pi-spin pi-spinner'
        : this._processingUpload
        ? 'pi pi-spin pi-cog'
        : 'pi pi-upload'
    )
  );

  totalPercentageUploading$: Observable<number> = this.allUploads$.pipe(
    map(uploads => uploads.filter(upload => upload.status === 'ongoing')),
    filter(ongoingUploads => ongoingUploads.length > 0),
    switchMap(ongoingUploads =>
      combineLatest(
        ongoingUploads.map(upload => upload.progress$.pipe(startWith(0)))
      ).pipe(
        map(progresses => {
          const totalProgress = progresses.reduce(
            (acc, progress) => acc + progress,
            0
          );
          return totalProgress / ongoingUploads.length;
        })
      )
    ),
    startWith(0)
  );

  constructor(
    private systemCheckService: SystemCheckService,
    private uuidService: UUIDService
  ) {}

  showPopout(event: any, overlayPanel: OverlayPanel) {
    overlayPanel.toggle(event);
  }

  async addToUploadList(
    uploadTask: UploadTask | AngularFireUploadTask,
    fileName = format(new Date(), 'yyyyMMdd_HHmmss')
  ): Promise<void> {
    const id = await this.uuidService.generateUUID();
    const fileObserver = this.createFileObserver(uploadTask, id);

    const uploadInfo: UploadInfo = {
      id,
      fileName: fileName,
      status: 'ongoing',
      progress$: fileObserver,
      uploadTask: uploadTask,
      error: null
    };

    // Show the panel on desktop only
    if (!this.systemCheckService.isMobile) {
      this.showPanel();
    }

    this._allUploads.push(uploadInfo);
    this.allUploads$.next(this._allUploads);
  }

  createFileObserver(
    uploadTask: UploadTask | AngularFireUploadTask,
    id: string
  ): Observable<number> {
    return new Observable((observer: Observer<number>) => {
      let snapshotSubscription: any;

      if (typeof uploadTask?.['on'] === 'function') {
        snapshotSubscription = (uploadTask as UploadTask).on(
          'state_changed',
          snapshot => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            observer.next(progress);

            if (progress === 100 || isNaN(progress)) {
              this.moveUploadToList(id, 'complete');
            }
          },
          error => {
            this.handleError(id, error);
            observer.error(error);
            observer.complete();
          },
          () => {
            observer.complete();
          }
        );
      } else {
        snapshotSubscription = (uploadTask as AngularFireUploadTask)
          .snapshotChanges()
          .pipe(
            tap(snapshot => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              observer.next(progress);
            }),
            finalize(() => {
              this.moveUploadToList(id, 'complete');
              observer.complete();
            }),
            catchError(error => {
              this.handleError(id, error);
              observer.error(error);
              observer.complete();
              return [];
            })
          )
          .subscribe();
      }

      return () => {
        if (snapshotSubscription?.unsubscribe) {
          snapshotSubscription.unsubscribe();
        }
      };
    });
  }

  handleError(id: string, error: any) {
    if (error.code === 'storage/canceled') {
      this.moveUploadToList(id, 'cancelled');
    } else {
      this.moveUploadToList(id, 'errored', error);
    }
  }

  moveUploadToList(
    id: string,
    status: 'complete' | 'cancelled' | 'errored',
    error?: Error
  ): void {
    const upload = this._allUploads.find(upload => upload.id === id);
    if (upload) {
      upload.status = status;
      if (status === 'errored' && error) {
        upload.error = error;
      }
      this.allUploads$.next(this._allUploads);
    } else {
      console.error(`Upload not found with id: ${id}`);
    }
  }

  removeFromUploadList(id: string): void {
    this._allUploads = this._allUploads.filter(upload => upload.id !== id);
    this.allUploads$.next(this._allUploads);
  }

  removeNonOngoingUploads(): void {
    this._allUploads = this._allUploads.filter(
      upload => upload.status === 'ongoing'
    );
    this.allUploads$.next(this._allUploads);
  }

  showPanel() {
    this._panelHidden.next(false);
  }

  hidePanel() {
    this.removeNonOngoingUploads();
    this._panelHidden.next(true);
  }
}
