import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'ic-phone-validation-errors',
  templateUrl: './phone-validation-errors.component.html',
  styleUrls: ['./phone-validation-errors.component.scss']
})
export class PhoneValidationErrorsComponent {
  @Input() control: UntypedFormControl | AbstractControl;
  @Input() name: string;
  @Input() source: string;
}
