<div class="flex flex-column gap-2">
  <ng-container *ngIf="!audioUrl">
    <div class="flex flex-row w-full gap-2">
      <div class="flex flex-grow-1">
        <button
          (click)="startAudioRecording()"
          [disabled]="isAudioRecording || audioUrl"
          [label]="
            isAudioRecording
              ? 'Recording ' + audioRecordedTime
              : 'Start recording'
          "
          icEventTracker="sf-start-sound-recording"
          aria-label="Start Recording"
          icon="pi pi-microphone"
          class="w-full"
          type="button"
          pButton
        ></button>
      </div>
      <button
        (click)="stopAudioRecording()"
        [disabled]="!isAudioRecording"
        icEventTracker="sf-stop-sound-recording"
        aria-label="Stop recording"
        class="p-button-danger"
        icon="pi pi-stop-circle"
        label="Stop"
        type="button"
        pButton
      ></button>
    </div>
  </ng-container>
  <ng-container *ngIf="!isAudioRecording && audioUrl">
    <div class="flex flex-column">
      <p-divider></p-divider>
      <div class="flex flex-column gap-4">
        <div class="flex align-items-center gap-2">
          <audio [src]="audioUrl" class="w-full" controls>
            <source [src]="audioUrl" type="audio/wav" />
          </audio>
          <button
            (click)="audioUrl = null"
            class="p-button-rounded p-button-danger"
            aria-label="Delete recording"
            icon="pi pi-trash"
            type="button"
            pButton
          ></button>
        </div>
        <div class="flex">
          <button
            (click)="sendSnapshot()"
            aria-label="Done"
            class="p-button-info w-full"
            label="Done"
            icon="pi pi-check"
            type="button"
            pButton
          ></button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
