<p-inputNumber
  [name]="field?.props?.label"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [prefix]="field?.props?.['prefix']"
  [suffix]="field?.props?.['suffix']"
  [min]="field?.props?.min"
  [max]="field?.props?.max"
  [showButtons]="field?.props?.['showButtons']"
  [minFractionDigits]="field?.props?.['minFractionDigits']"
  [maxFractionDigits]="field?.props?.['maxFractionDigits']"
  [placeholder]="field?.props?.placeholder || field?.props?.['prefix'] || field?.props?.['suffix']"
  [step]="field?.props?.step || 1"
  [useGrouping]="field?.props?.['useGrouping']"
  (onInput)="updateInput($event)"
  mode="decimal"
  class="w-full"
></p-inputNumber>
