import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'join'
})
export class JoinPipe implements PipeTransform {
  transform(array: any[], separator: string = ', '): string {
    return Array.isArray(array) ? array.join(separator) : array;
  }
}
