import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-cadi-form',
  templateUrl: './cadi-form.component.html',
  styleUrls: ['./cadi-form.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
})
export class CadiFormComponent implements OnInit {
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  cadiForm: UntypedFormGroup;
  isSubmitting = false;

  ngOnInit(): void {
    this.cadiForm = this.fb.group({
      feelingAggressiveFrustratedOrEmbarrassed: ['', Validators.required],
      acneInterferedWithSocialLifeEventsOrRelationships: [
        '',
        Validators.required,
      ],
      avoidedPublicChangingFacilities: ['', Validators.required],
      feelingsAboutSkinAppearance: ['', Validators.required],
      howBadIsYourAcneNow: ['', Validators.required],
      cadiTotalScore: [''],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.cadiForm.controls[key])
          this.cadiForm.controls[key].setValue(formData[key]);
      });
    });
  }

  // CADI
  get feelingAggressiveFrustratedOrEmbarrassed() {
    return this.cadiForm.get('feelingAggressiveFrustratedOrEmbarrassed');
  }
  get acneInterferedWithSocialLifeEventsOrRelationships() {
    return this.cadiForm.get(
      'acneInterferedWithSocialLifeEventsOrRelationships'
    );
  }
  get avoidedPublicChangingFacilities() {
    return this.cadiForm.get('avoidedPublicChangingFacilities');
  }
  get feelingsAboutSkinAppearance() {
    return this.cadiForm.get('feelingsAboutSkinAppearance');
  }
  get howBadIsYourAcneNow() {
    return this.cadiForm.get('howBadIsYourAcneNow');
  }

  onSubmit() {
    if (!this.cadiFormValid()) return;
    this.setCadiScore();

    this.isSubmitting = true;
    const formResponse = {
      ...this.cadiForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
      showOnChart: true,
    };
    this.form.submit(formResponse);
  }

  cadiFormValid(): boolean {
    return this.cadiForm.valid;
  }

  setCadiScore() {
    const cadiQuestion1 = this.cadiForm.get(
      'feelingAggressiveFrustratedOrEmbarrassed'
    ).value;
    const cadiQuestion2 = this.cadiForm.get(
      'acneInterferedWithSocialLifeEventsOrRelationships'
    ).value;
    const cadiQuestion3 = this.cadiForm.get(
      'avoidedPublicChangingFacilities'
    ).value;
    const cadiQuestion4 = this.cadiForm.get(
      'feelingsAboutSkinAppearance'
    ).value;
    const cadiQuestion5 = this.cadiForm.get('howBadIsYourAcneNow').value;

    const cadiTotalScore =
      +cadiQuestion1.match(/\d+/g) +
      +cadiQuestion2.match(/\d+/g) +
      +cadiQuestion3.match(/\d+/g) +
      +cadiQuestion4.match(/\d+/g) +
      +cadiQuestion5.match(/\d+/g);

    this.cadiForm.get('cadiTotalScore').setValue(cadiTotalScore);
  }
}
