import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isLandlineNumber, phoneInvalid } from '../phoneInvalid';

/**
 * @description Custom validators that check if a phone number is valid or not
 * @param keyMap (Optional)Object that hold key value pair for each available phone number
 * @returns Warnning if a number is in International Format and Errors for landline number and spacial charahter
 */
export function mobilePhoneNumberValidator<T>(keyMap?: T): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let phoneNumber: string = control?.value?.e164Number || control.value;

    if (!phoneNumber) {
      return null;
    }
    if (keyMap) {
      phoneNumber = keyMap[phoneNumber];
    }

    if (isLandlineNumber(phoneNumber)) return { landLine: true };

    if (phoneInvalid(phoneNumber)) return { invalidPhoneNumber: true };

    return null;
  };
}
