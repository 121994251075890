<form [formGroup]="entryForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <p>
      <strong>
        We are monitoring all patients with surgical wounds, to detect patients who develop a wound
        infection after surgery.
      </strong>
    </p>

    <!-- <ng-container *ngIf="form$ | async as form"> -->
    <mat-form-field class="half-width-field" appearance="fill">
      <mat-label>Surgical Category</mat-label>
      <mat-select formControlName="surgicalCategory">
        <!-- <mat-option value="Abdominal hysterectomy">Abdominal hysterectomy</mat-option>
          <mat-option value="Bile duct, liver or pancreatic surgery">Bile duct, liver or pancreatic surgery</mat-option>
          <mat-option value="Breast surgery">Breast surgery</mat-option> -->
        <mat-option value="Cardiac surgery (non-CABG)">Cardiac surgery (non-CABG)</mat-option>
        <!-- <mat-option value="Cholecystectomy">Cholecystectomy</mat-option> -->
        <mat-option value="Coronary artery bypass graft (CABG)"
          >Coronary artery bypass graft (CABG)</mat-option
        >
        <!-- <mat-option value="Cranial surgery">Cranial surgery</mat-option>
          <mat-option value="Gastric Surgery">Gastric Surgery</mat-option>
          <mat-option value="Hip replacement">Hip replacement</mat-option>
          <mat-option value="Knee replacement">Knee replacement</mat-option>
          <mat-option value="Large bowel surgery">Large bowel surgery</mat-option>
          <mat-option value="Limb amputation">Limb amputation</mat-option>
          <mat-option value="Reduction of long bone fracture">Reduction of long bone fracture</mat-option>
          <mat-option value="Repair of neck and femur">Repair of neck and femur</mat-option>
          <mat-option value="Small bowel surgery">Small bowel surgery</mat-option>
          <mat-option value="Vascular surgery">Vascular surgery</mat-option> -->
        <mat-option value="Thoracic">Thoracic</mat-option>
        <!-- <mat-option *ngFor="let surgicalCategory of form.surgicalCategory">
            {{ surgicalCategory }}
          </mat-option> -->
      </mat-select>
    </mat-form-field>
    <!-- </ng-container> -->
    <br />

    <mat-form-field
      class="half-width-field"
      appearance="fill"
      *ngIf="
        entryForm.get('surgicalCategory').value.indexOf('Coronary artery bypass graft (CABG)') !==
        -1
      "
    >
      <mat-label>Please could you tell us which CABG wound(s) had signs of infection?</mat-label>
      <mat-select multiple formControlName="surgicalWound">
        <mat-option value="Chest">Chest</mat-option>
        <mat-option value="Left leg">Left leg</mat-option>
        <mat-option value="Right leg">Right leg</mat-option>
        <mat-option value="Left arm">Left arm</mat-option>
        <mat-option value="Right arm"> Right arm</mat-option>
      </mat-select>
    </mat-form-field>
    <br />

    <!-- CHEST WOUND QUESTION FIELDS START-->
    <div
      class="wound-option-container"
      *ngIf="entryForm.get('surgicalWound').value.indexOf('Chest') !== -1"
    >
      <mat-label
        >Please tell us about <strong>chest</strong> wound <strong>first</strong></mat-label
      >
      <br />

      <!-- Question 1 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Was there redness spreading away from the wound? (erythema/cellulitis)</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="chestWoundRednessSpreading">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 2 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Was the area around the wound warmer than the surrounding skin?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="chestWoundAreaWarmer">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 3 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Has any of the wound leaked clear fluid? (serous exudate)</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="chestWoundLeakedClearFluid">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 4 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Has any part of the wound leaked blood-stained fluid? (haemoserous exudate)</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="chestWoundLeakedBloodstainedFluid">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 5 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Has any part of the wound leaked thick and yellow/green fluid? (pus/purulent exudate)
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="chestWoundLeakedYellowGreenFluid">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 6a -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Have the edges of any part of the wound separated/ gaped open on their own accord?
            (spontaneous dehiscence)
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="chestWoundSeparatedOrGaped">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 6b -->
      <div style="margin: 0 auto">
        <span>
          <strong>
            Please answer the next question only if you have said the edges of the wound
            separated/gaped open:
          </strong>
        </span>
      </div>
      <div class="question-container">
        <div class="half-width-field">
          <p>Did the deeper tissue also separate?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="chestWoundDeeperTissueSeparate">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 7 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Has the area around the wound become swollen?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="chestWoundBecomeSwollen">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 8 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Has the wound been smelly?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="chestWoundBeenSmelly">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 9 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Has the wound been painful to touch?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="chestWoundPainfulToTouch">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 10 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Have you had, or felt like you have had, a raise temperature or fever (fever > 38&#176;
            C)
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="chestWoundRaiseInTemperature">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <br />
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Please tell us the date you noticed these chest wound symptoms</mat-label>
        <input
          matInput
          formControlName="chestWoundObservedDate"
          type="date"
          matTooltip="If you cannot remember the exact date, please give us an approximate date"
        />
      </mat-form-field>

      <mat-divider class="full-width-field"></mat-divider>
    </div>
    <!-- CHEST WOUND QUESTION FIELDS END -->

    <!-- LEFT LEG WOUND QUESTION FIELDS START -->
    <div
      class="wound-option-container"
      *ngIf="entryForm.get('surgicalWound').value.indexOf('Left leg') !== -1"
    >
      <mat-label>Please tell us about your <strong>left leg</strong> wound.</mat-label>

      <!-- Question 1 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Was there redness spreading away from the wound? (erythema/cellulitis)</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="leftLegWoundRednessSpreading">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 2 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Was the area around the wound warmer than the surrounding skin?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="leftLegWoundAreaWarmer">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 3 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Has any of the wound leaked clear fluid? (serous exudate)</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="leftLegWoundLeakedClearFluid">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 4 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Has any part of the wound leaked blood-stained fluid? (haemoserous exudate)</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="leftLegWoundLeakedBloodstainedFluid">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 5 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Has any part of the wound leaked thick and yellow/green fluid? (pus/purulent exudate)
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="leftLegWoundLeakedYellowGreenFluid">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 6a -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Have the edges of any part of the wound separated/ gaped open on their own accord?
            (spontaneous dehiscence)
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="leftLegWoundSeparatedOrGaped">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 6b -->
      <div style="margin: 0 auto">
        <span>
          <strong>
            Please answer the next question only if you have said the edges of the wound
            separated/gaped open:
          </strong>
        </span>
      </div>
      <div class="question-container">
        <div class="half-width-field">
          <p>Did the deeper tissue also separate?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="leftLegWoundDeeperTissueSeparate">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 7 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Has the area around the wound become swollen?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="leftLegWoundBecomeSwollen">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 8 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Has the wound been smelly?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="leftLegWoundBeenSmelly">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 9 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Has the wound been painful to touch?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="leftLegWoundPainfulToTouch">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 10 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Have you had, or felt like you have had, a raise temperature or fever (fever > 38&#176;
            C)
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="leftLegWoundRaiseInTemperature">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <br />
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Please tell us the date you noticed these left leg wound symptoms</mat-label>
        <input
          matInput
          formControlName="leftLegWoundObservedDate"
          type="date"
          matTooltip="If you cannot remember the exact date, please give us an approximate date"
        />
      </mat-form-field>

      <mat-divider class="full-width-field"></mat-divider>
    </div>
    <!-- LEFT LEG WOUND QUESTION FIELDS END -->

    <!-- RIGHT LEG WOUND QUESTION FIELDS START -->
    <div
      class="wound-option-container"
      *ngIf="entryForm.get('surgicalWound').value.indexOf('Right leg') !== -1"
    >
      <mat-label>Please tell us about your <strong>right leg</strong> wound.</mat-label>

      <!-- Question 1 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Was there redness spreading away from the wound? (erythema/cellulitis)</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="rightLegWoundRednessSpreading">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 2 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Was the area around the wound warmer than the surrounding skin?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="rightLegWoundAreaWarmer">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 3 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Has any of the wound leaked clear fluid? (serous exudate)</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="rightLegWoundLeakedClearFluid">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 4 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Has any part of the wound leaked blood-stained fluid? (haemoserous exudate)</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="rightLegWoundLeakedBloodstainedFluid">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 5 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Has any part of the wound leaked thick and yellow/green fluid? (pus/purulent exudate)
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="rightLegWoundLeakedYellowGreenFluid">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 6a -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Have the edges of any part of the wound separated/ gaped open on their own accord?
            (spontaneous dehiscence)
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="rightLegWoundSeparatedOrGaped">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 6b -->
      <div style="margin: 0 auto">
        <span>
          <strong>
            Please answer the next question only if you have said the edges of the wound
            separated/gaped open:
          </strong>
        </span>
      </div>
      <div class="question-container">
        <div class="half-width-field">
          <p>Did the deeper tissue also separate?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="rightLegWoundDeeperTissueSeparate">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 7 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Has the area around the wound become swollen?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="rightLegWoundBecomeSwollen">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 8 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Has the wound been smelly?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="rightLegWoundBeenSmelly">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 9 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Has the wound been painful to touch?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="rightLegWoundPainfulToTouch">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 10 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Have you had, or felt like you have had, a raise temperature or fever (fever > 38&#176;
            C)
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="rightLegWoundRaiseInTemperature">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <br />
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Please tell us the date you noticed these right leg wound symptoms</mat-label>
        <input
          matInput
          formControlName="rightLegWoundObservedDate"
          type="date"
          matTooltip="If you cannot remember the exact date, please give us an approximate date"
        />
      </mat-form-field>

      <mat-divider class="full-width-field"></mat-divider>
    </div>

    <!-- RIGHT LEG WOUND QUESTION FIELDS END -->

    <!-- LEFT ARM WOUND QUESTION FIELDS START -->
    <div
      class="wound-option-container"
      *ngIf="entryForm.get('surgicalWound').value.indexOf('Left arm') !== -1"
    >
      <mat-label>Please tell us about your <strong>left arm</strong> wound.</mat-label>

      <!-- Question 1 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Was there redness spreading away from the wound? (erythema/cellulitis)</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="leftArmWoundRednessSpreading">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 2 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Was the area around the wound warmer than the surrounding skin?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="leftArmWoundAreaWarmer">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 3 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Has any of the wound leaked clear fluid? (serous exudate)</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="leftArmWoundLeakedClearFluid">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 4 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Has any part of the wound leaked blood-stained fluid? (haemoserous exudate)</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="leftArmWoundLeakedBloodstainedFluid">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 5 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Has any part of the wound leaked thick and yellow/green fluid? (pus/purulent exudate)
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="leftArmWoundLeakedYellowGreenFluid">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 6a -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Have the edges of any part of the wound separated/ gaped open on their own accord?
            (spontaneous dehiscence)
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="leftArmWoundSeparatedOrGaped">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 6b -->
      <div style="margin: 0 auto">
        <span>
          <strong>
            Please answer the next question only if you have said the edges of the wound
            separated/gaped open:
          </strong>
        </span>
      </div>
      <div class="question-container">
        <div class="half-width-field">
          <p>Did the deeper tissue also separate?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="leftArmWoundDeeperTissueSeparate">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 7 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Has the area around the wound become swollen?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="leftArmWoundBecomeSwollen">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 8 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Has the wound been smelly?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="leftArmWoundBeenSmelly">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 9 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Has the wound been painful to touch?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="leftArmWoundPainfulToTouch">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 10 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Have you had, or felt like you have had, a raise temperature or fever (fever > 38&#176;
            C)
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="leftArmWoundRaiseInTemperature">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <br />
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Please tell us the date you noticed these left arm wound symptoms</mat-label>
        <input
          matInput
          formControlName="leftArmWoundObservedDate"
          type="date"
          matTooltip="If you cannot remember the exact date, please give us an approximate date"
        />
      </mat-form-field>

      <mat-divider class="full-width-field"></mat-divider>
    </div>

    <!-- LEFT ARM WOUND QUESTION FIELDS END -->

    <!-- RIGHT ARM WOUND QUESTION FIELDS START -->
    <div
      class="wound-option-container"
      *ngIf="entryForm.get('surgicalWound').value.indexOf('Right arm') !== -1"
    >
      <mat-label>Please tell us about your <strong>right arm</strong> wound.</mat-label>

      <!-- Question 1 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Was there redness spreading away from the wound? (erythema/cellulitis)</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="rightArmWoundRednessSpreading">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 2 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Was the area around the wound warmer than the surrounding skin?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="rightArmWoundAreaWarmer">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 3 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Has any of the wound leaked clear fluid? (serous exudate)</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="rightArmWoundLeakedClearFluid">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 4 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Has any part of the wound leaked blood-stained fluid? (haemoserous exudate)</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="rightArmWoundLeakedBloodstainedFluid">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 5 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Has any part of the wound leaked thick and yellow/green fluid? (pus/purulent exudate)
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="rightArmWoundLeakedYellowGreenFluid">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 6a -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Have the edges of any part of the wound separated/ gaped open on their own accord?
            (spontaneous dehiscence)
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="rightArmWoundSeparatedOrGaped">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 6b -->
      <div style="margin: 0 auto">
        <span>
          <strong>
            Please answer the next question only if you have said the edges of the wound
            separated/gaped open:
          </strong>
        </span>
      </div>
      <div class="question-container">
        <div class="half-width-field">
          <p>Did the deeper tissue also separate?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="rightArmWoundDeeperTissueSeparate">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 7 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Has the area around the wound become swollen?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="rightArmWoundBecomeSwollen">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 8 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Has the wound been smelly?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="rightArmWoundBeenSmelly">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 9 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Has the wound been painful to touch?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="rightArmWoundPainfulToTouch">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 10 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Have you had, or felt like you have had, a raise temperature or fever (fever > 38&#176;
            C)
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="rightArmWoundRaiseInTemperature">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <br />
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Please tell us the date you noticed these right arm wound symptoms</mat-label>
        <input
          matInput
          formControlName="rightArmWoundObservedDate"
          type="date"
          matTooltip="If you cannot remember the exact date, please give us an approximate date"
        />
      </mat-form-field>

      <mat-divider class="full-width-field"></mat-divider>
    </div>

    <!-- RIGHT ARM WOUND QUESTION FIELDS END -->

    <!-- HIP WOUND QUESTION FIELDS START -->
    <div
      class="wound-option-container"
      *ngIf="entryForm.get('surgicalCategory').value.indexOf('Hip replacement') !== -1"
    >
      <mat-label>Please tell us about your <strong>Hip</strong> wound(s).</mat-label>
      <br />
      <mat-radio-group class="half-width-field" appearance="fill" formControlName="rightHipWound">
        <mat-label>Have you got problems with the wound on your right hip?</mat-label>
        <mat-radio-button value="Yes">Yes</mat-radio-button>
        <mat-radio-button value="No">No</mat-radio-button>
      </mat-radio-group>
      <br />
      <mat-radio-group class="half-width-field" appearance="fill" formControlName="leftHipWound">
        <mat-label>Have you got problems with the wound on your left hip?</mat-label>
        <mat-radio-button value="Yes">Yes</mat-radio-button>
        <mat-radio-button value="No">No</mat-radio-button>
      </mat-radio-group>
      <br />

      <!-- RIGHT HIP  -->
      <div *ngIf="entryForm.get('rightHipWound').value === 'Yes'">
        <mat-label>Please tell us about your <strong>right side hip wound</strong>.</mat-label>

        <!-- Question 1 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Was there redness spreading away from the wound? (erythema/cellulitis)</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="rightHipWoundRednessSpreading">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 2 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Was the area around the wound warmer than the surrounding skin?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="rightHipWoundAreaWarmer">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 3 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Has any of the wound leaked clear fluid? (serous exudate)</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="rightHipWoundLeakedClearFluid">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 4 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Has any part of the wound leaked blood-stained fluid? (haemoserous exudate)</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="rightHipWoundLeakedBloodstainedFluid">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 5 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Has any part of the wound leaked thick and yellow/green fluid? (pus/purulent exudate)
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="rightHipWoundLeakedYellowGreenFluid">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 6a -->
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Have the edges of any part of the wound separated/ gaped open on their own accord?
              (spontaneous dehiscence)
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="rightHipWoundSeparatedOrGaped">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 6b -->
        <div style="margin: 0 auto">
          <span>
            <strong>
              Please answer the next question only if you have said the edges of the wound
              separated/gaped open:
            </strong>
          </span>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Did the deeper tissue also separate?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="rightHipWoundDeeperTissueSeparate">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 7 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Has the area around the wound become swollen?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="rightHipWoundBecomeSwollen">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 8 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Has the wound been smelly?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="rightHipWoundBeenSmelly">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 9 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Has the wound been painful to touch?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="rightHipWoundPainfulToTouch">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 10 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Have you had, or felt like you have had, a raise temperature or fever (fever >
              38&#176; C)
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="rightHipWoundRaiseInTemperature">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <br />
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Please tell us the date you noticed these right hip wound symptoms</mat-label>
          <input
            matInput
            formControlName="rightHipWoundObservedDate"
            type="date"
            matTooltip="If you cannot remember the exact date, please give us an approximate date"
          />
        </mat-form-field>

        <mat-divider class="full-width-field"></mat-divider>
      </div>
      <!-- RIGHT HIP END -->
      <!-- LEFT HIP START -->

      <div *ngIf="entryForm.get('leftHipWound').value === 'Yes'">
        <mat-label>Please tell us about your <strong>left side hip wound</strong>.</mat-label>

        <!-- Question 1 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Was there redness spreading away from the wound? (erythema/cellulitis)</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="leftHipWoundRednessSpreading">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 2 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Was the area around the wound warmer than the surrounding skin?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="leftHipWoundAreaWarmer">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 3 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Has any of the wound leaked clear fluid? (serous exudate)</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="leftHipWoundLeakedClearFluid">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 4 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Has any part of the wound leaked blood-stained fluid? (haemoserous exudate)</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="leftHipWoundLeakedBloodstainedFluid">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 5 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Has any part of the wound leaked thick and yellow/green fluid? (pus/purulent exudate)
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="leftHipWoundLeakedYellowGreenFluid">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 6a -->
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Have the edges of any part of the wound separated/ gaped open on their own accord?
              (spontaneous dehiscence)
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="leftHipWoundSeparatedOrGaped">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 6b -->
        <div style="margin: 0 auto">
          <span>
            <strong>
              Please answer the next question only if you have said the edges of the wound
              separated/gaped open:
            </strong>
          </span>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Did the deeper tissue also separate?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="leftHipWoundDeeperTissueSeparate">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 7 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Has the area around the wound become swollen?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="leftHipWoundBecomeSwollen">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 8 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Has the wound been smelly?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="leftHipWoundBeenSmelly">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 9 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Has the wound been painful to touch?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="leftHipWoundPainfulToTouch">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 10 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Have you had, or felt like you have had, a raise temperature or fever (fever >
              38&#176; C)
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="leftHipWoundRaiseInTemperature">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <br />
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Please tell us the date you noticed these left hip wound symptoms</mat-label>
          <input
            matInput
            formControlName="leftHipWoundObservedDate"
            type="date"
            matTooltip="If you cannot remember the exact date, please give us an approximate date"
          />
        </mat-form-field>
      </div>

      <!-- LEFT HIP END -->
      <mat-divider class="full-width-field"></mat-divider>
    </div>
    <!-- HIP WOUND QUESTION FIELDS END -->

    <!-- KNEE WOUND QUESTION FIELDS START -->
    <div
      class="wound-option-container"
      *ngIf="entryForm.get('surgicalCategory').value.indexOf('Knee replacement') !== -1"
    >
      <mat-label>Please tell us about your <strong>Knee</strong> wound(s).</mat-label>
      <br />
      <mat-radio-group class="half-width-field" appearance="fill" formControlName="rightKneeWound">
        <mat-label>Have you got problems with the wound on your right knee?</mat-label>
        <mat-radio-button value="Yes">Yes</mat-radio-button>
        <mat-radio-button value="No">No</mat-radio-button>
      </mat-radio-group>
      <br />
      <mat-radio-group class="half-width-field" appearance="fill" formControlName="leftKneeWound">
        <mat-label>Have you got problems with the wound on your left knee?</mat-label>
        <mat-radio-button value="Yes">Yes</mat-radio-button>
        <mat-radio-button value="No">No</mat-radio-button>
      </mat-radio-group>
      <br />

      <!-- RIGHT KNEE  -->

      <div *ngIf="entryForm.get('rightKneeWound').value === 'Yes'">
        <mat-label>Please tell us about your <strong>right knee wound</strong>.</mat-label>

        <!-- Question 1 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Was there redness spreading away from the wound? (erythema/cellulitis)</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="rightKneeWoundRednessSpreading">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 2 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Was the area around the wound warmer than the surrounding skin?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="rightKneeWoundAreaWarmer">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 3 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Has any of the wound leaked clear fluid? (serous exudate)</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="rightKneeWoundLeakedClearFluid">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 4 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Has any part of the wound leaked blood-stained fluid? (haemoserous exudate)</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="rightKneeWoundLeakedBloodstainedFluid">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 5 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Has any part of the wound leaked thick and yellow/green fluid? (pus/purulent exudate)
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="rightKneeWoundLeakedYellowGreenFluid">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 6a -->
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Have the edges of any part of the wound separated/ gaped open on their own accord?
              (spontaneous dehiscence)
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="rightKneeWoundSeparatedOrGaped">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 6b -->
        <div style="margin: 0 auto">
          <span>
            <strong>
              Please answer the next question only if you have said the edges of the wound
              separated/gaped open:
            </strong>
          </span>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Did the deeper tissue also separate?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="rightKneeWoundDeeperTissueSeparate">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 7 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Has the area around the wound become swollen?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="rightKneeWoundBecomeSwollen">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 8 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Has the wound been smelly?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="rightKneeWoundBeenSmelly">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 9 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Has the wound been painful to touch?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="rightKneeWoundPainfulToTouch">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 10 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Have you had, or felt like you have had, a raise temperature or fever (fever >
              38&#176; C)
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="rightKneeWoundRaiseInTemperature">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <br />
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Please tell us the date you noticed these right knee wound symptoms</mat-label>
          <input
            matInput
            formControlName="rightKneeWoundObservedDate"
            type="date"
            matTooltip="If you cannot remember the exact date, please give us an approximate date"
          />
        </mat-form-field>

        <mat-divider class="full-width-field"></mat-divider>
      </div>
      <!-- RIGHT KNEE END -->
      <br />
      <!-- LEFT KNEE START -->

      <div *ngIf="entryForm.get('leftKneeWound').value === 'Yes'">
        <mat-label>Please tell us about your <strong>left knee wound</strong>.</mat-label>

        <!-- Question 1 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Was there redness spreading away from the wound? (erythema/cellulitis)</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="leftKneeWoundRednessSpreading">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 2 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Was the area around the wound warmer than the surrounding skin?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="leftKneeWoundAreaWarmer">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 3 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Has any of the wound leaked clear fluid? (serous exudate)</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="leftKneeWoundLeakedClearFluid">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 4 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Has any part of the wound leaked blood-stained fluid? (haemoserous exudate)</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="leftKneeWoundLeakedBloodstainedFluid">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 5 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Has any part of the wound leaked thick and yellow/green fluid? (pus/purulent exudate)
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="leftKneeWoundLeakedYellowGreenFluid">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 6a -->
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Have the edges of any part of the wound separated/ gaped open on their own accord?
              (spontaneous dehiscence)
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="leftKneeWoundSeparatedOrGaped">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 6b -->
        <div style="margin: 0 auto">
          <span>
            <strong>
              Please answer the next question only if you have said the edges of the wound
              separated/gaped open:
            </strong>
          </span>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Did the deeper tissue also separate?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="leftKneeWoundDeeperTissueSeparate">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 7 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Has the area around the wound become swollen?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="leftKneeWoundBecomeSwollen">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 8 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Has the wound been smelly?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="leftKneeWoundBeenSmelly">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 9 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Has the wound been painful to touch?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="leftKneeWoundPainfulToTouch">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Question 10 -->
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Have you had, or felt like you have had, a raise temperature or fever (fever >
              38&#176; C)
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="leftKneeWoundRaiseInTemperature">
              <mat-option *ngFor="let answer of answerList" [value]="answer">
                {{ answer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <br />
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Please tell us the date you noticed these left knee wound symptoms</mat-label>
          <input
            matInput
            formControlName="leftKneeWoundObservedDate"
            type="date"
            matTooltip="If you cannot remember the exact date, please give us an approximate date"
          />
        </mat-form-field>
      </div>

      <!-- LEFT KNEE END -->
      <mat-divider class="full-width-field"></mat-divider>
    </div>
    <!-- KNEE WOUND QUESTION FIELDS END -->

    <!-- TODO: THIS WORKS ONLY TEMP NEED A CLEANER SOLUTION -->
    <div
      *ngIf="
        entryForm.get('surgicalCategory').value.indexOf('Abdominal hysterectomy') !== -1 ||
        entryForm
          .get('surgicalCategory')
          .value.indexOf('Bile duct, liver or pancreatic surgery') !== -1 ||
        entryForm.get('surgicalCategory').value.indexOf('Breast surgery') !== -1 ||
        entryForm.get('surgicalCategory').value.indexOf('Cardiac surgery (non-CABG)') !== -1 ||
        entryForm.get('surgicalCategory').value.indexOf('Cholecystectomy') !== -1 ||
        entryForm.get('surgicalCategory').value.indexOf('Cranial surgery') !== -1 ||
        entryForm.get('surgicalCategory').value.indexOf('Gastric Surgery') !== -1 ||
        entryForm.get('surgicalCategory').value.indexOf('Large bowel surgery') !== -1 ||
        entryForm.get('surgicalCategory').value.indexOf('Limb amputation') !== -1 ||
        entryForm.get('surgicalCategory').value.indexOf('Reduction of long bone fracture') !== -1 ||
        entryForm.get('surgicalCategory').value.indexOf('Repair of neck and femur') !== -1 ||
        entryForm.get('surgicalCategory').value.indexOf('Small bowel surgery') !== -1
      "
    >
      <!-- <mat-label>Please tell us about your wound(s).</mat-label> -->
      <mat-label
        ><strong>{{
          entryForm.get('surgicalCategory').value.indexOf('Coronary artery bypass graft (CABG)') !==
            -1 ||
          entryForm.get('surgicalCategory').value.indexOf('Hip replacement') !== -1 ||
          entryForm.get('surgicalCategory').value.indexOf('Knee replacement') !== -1
            ? 'Other wounds'
            : 'Wounds'
        }}</strong></mat-label
      >
      <!-- ? 'Other wounds' : 'Wounds'}}</strong></mat-label> -->
      <br />

      <!-- Question 1 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Was there redness spreading away from the wound? (erythema/cellulitis)</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="generalWoundRednessSpreading">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 2 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Was the area around the wound warmer than the surrounding skin?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="generalWoundAreaWarmer">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 3 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Has any of the wound leaked clear fluid? (serous exudate)</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="generalWoundLeakedClearFluid">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 4 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Has any part of the wound leaked blood-stained fluid? (haemoserous exudate)</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="generalWoundLeakedBloodstainedFluid">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 5 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Has any part of the wound leaked thick and yellow/green fluid? (pus/purulent exudate)
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="generalWoundLeakedYellowGreenFluid">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 6a -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Have the edges of any part of the wound separated/ gaped open on their own accord?
            (spontaneous dehiscence)
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="generalWoundSeparatedOrGaped">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 6b -->
      <div style="margin: 0 auto">
        <span>
          <strong>
            Please answer the next question only if you have said the edges of the wound
            separated/gaped open:
          </strong>
        </span>
      </div>
      <div class="question-container">
        <div class="half-width-field">
          <p>Did the deeper tissue also separate?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="generalWoundDeeperTissueSeparate">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 7 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Has the area around the wound become swollen?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="generalWoundBecomeSwollen">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 8 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Has the wound been smelly?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="generalWoundBeenSmelly">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 9 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Has the wound been painful to touch?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="generalWoundPainfulToTouch">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Question 10 -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Have you had, or felt like you have had, a raise temperature or fever (fever > 38&#176;
            C)
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="generalWoundRaiseInTemperature">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <br />
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Please tell us the date you noticed these general wound symptoms</mat-label>
        <input
          matInput
          formControlName="generalWoundObservedDate"
          type="date"
          matTooltip="If you cannot remember the exact date, please give us an approximate date"
        />
      </mat-form-field>

      <mat-divider class="full-width-field"></mat-divider>
    </div>

    <div *ngIf="entryForm.get('surgicalCategory').value.length >= 1">
      <mat-radio-group class="half-width-field" appearance="fill" formControlName="antibiotics">
        <mat-label>Have you been prescribed antibiotics for a problem with the wound?</mat-label>
        <mat-radio-button value="Yes">Yes</mat-radio-button>
        <mat-radio-button value="No">No</mat-radio-button>
      </mat-radio-group>

      <mat-form-field
        class="half-width-field"
        appearance="fill"
        *ngIf="entryForm.get('antibiotics').value === 'Yes'"
      >
        <mat-label>Who prescribed them?</mat-label>
        <mat-select matNativeControl formControlName="prescriber">
          <mat-option value="GP">GP</mat-option>
          <mat-option value="District nurse">District nurse</mat-option>
          <mat-option value="Midwife">Midwife</mat-option>
          <mat-option value="Doctor or nurse at the hospital"
            >Doctor or nurse at the hospital</mat-option
          >
          <mat-option value="Other">Other</mat-option>
          <mat-option value="I didn't see anyone">I didn't see anyone</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        class="half-width-field"
        appearance="fill"
        *ngIf="entryForm.get('prescriber').value === 'Other'"
      >
        <input
          matInput
          formControlName="prescriberOther"
          type="notes"
          placeholder="If 'Other', please specify:"
          autocomplete="off"
        />
      </mat-form-field>

      <br />

      <mat-radio-group class="half-width-field" appearance="fill" formControlName="readmission">
        <mat-label
          >Have you been re-admitted to hospital with a problem with the surgical wound?</mat-label
        >
        <mat-radio-button value="Yes">Yes</mat-radio-button>
        <mat-radio-button value="No">No</mat-radio-button>
      </mat-radio-group>

      <mat-form-field
        *ngIf="entryForm.get('readmission').value === 'Yes'"
        class="half-width-field"
        appearance="fill"
      >
        <mat-label>Where were you readmitted?</mat-label>
        <mat-select matNativeControl formControlName="readmissionLocation">
          <mat-option value="To the hospital at which the operation was carried out"
            >To the hospital at which the operation was carried out</mat-option
          >
          <mat-option value="To another hospital">To another hospital</mat-option>
        </mat-select>
      </mat-form-field>

      <br />
      <mat-form-field class="half-width-field" appearance="fill">
        <input
          matInput
          formControlName="notes"
          placeholder="Other wound comments"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <!-- <mat-slide-toggle
        class="sensitive-image-field"
        matInput
        formControlName = "sensitiveImage"
        value = "sensitiveImage"
        labelPosition = "after"
        color = 'primary'
        (click)="setSensitiveImage($event.checked)"
      >
      Click to mark as sensitive image
    </mat-slide-toggle>
    <div *ngIf="isSensitive">
      <p class="sensitive-text">The image will be blurred unless your clinician is actively reviewing it.</p>
    </div> -->
    <br />

    <div class="button-group">
      <button
        class="positive-button"
        mat-raised-button
        color="accent"
        type="submit"
        [disabled]="isSubmitting"
      >
        {{ isSubmitting ? 'Submitting...' : 'Submit' }}
      </button>
    </div>
  </div>
</form>
