import { Directive, Input } from '@angular/core';
import { MatTable } from '@angular/material/table';

@Directive({
  standalone: true,
  selector: '[icHash]'
})
export class HashDirective {
  @Input() icHash: string;

  constructor(public tableRef: MatTable<any>) {}
}
