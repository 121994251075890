<div class="errors max-w-30rem">
  <div *ngIf="!source">
    <span *ngIf="control.errors?.landLine" class="text-error">
      The {{ name }} phone number appears to be a landline. Please update it or add an additional number for this
      patient.
    </span>
    <span *ngIf="control.errors?.noAvailablePhoneNumber" class="text-error">
      There is no mobile phone number associated with this patient. Please update it or add an additional number for
      this patient.
    </span>
    <span *ngIf="control.errors?.phoneNumber" class="text-error">
      The {{ name }} phone number appears to be invalid. Please update it or add an additional number for this patient.
    </span>
    <span *ngIf="control['warnings']?.internationalFormat" class="text-error">
      The {{ name }} phone number appears to be in an international format.
    </span>
  </div>
  <div *ngIf="source === 'collection-edit'">
    <span
      *ngIf="control.errors?.noAvailablePhoneNumber || control.errors?.landLine || control.errors?.phoneNumber"
      class="text-error"
    >
      This template has a schedule set, and requires further confirmation of some details. Please click 'Show collection
      settings' to expand the schedule settings
    </span>
    <span *ngIf="control.errors?.noAvailablePhoneNumber" class="text-error">
      There is no mobile phone number associated with this patient. Please update it or add an additional number for
      this patient.
    </span>
    <span *ngIf="control.errors?.landLine" class="text-error">
      The {{ name }} phone number appears to be a landline. Please update it or add an additional number for this
      patient.
    </span>
    <span *ngIf="control.errors?.phoneNumber" class="text-error">
      The {{ name }} phone number appears to be invalid. Please update it or add an additional number for this patient.
    </span>
    <span *ngIf="control['warnings']?.internationalFormat" class="text-error">
      The {{ name }} phone number appears to be in an international format.
    </span>
  </div>
</div>
