import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'ic-file-placeholder',
  templateUrl: './file-placeholder.component.html',
  styleUrls: ['./file-placeholder.component.scss'],
  imports: [CommonModule]
})
export class FilePlaceholderComponent {
  @Input() fileType = '';
}
