import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  Patient,
  PatientWithId,
  SearchOptions,
  Team,
  TeamFeatureFlags,
  TeamWithId,
  User,
  UserWithId,
} from '@islacare/ic-types';

import firebase from 'firebase/compat/app';
import { Observable, combineLatest, of } from 'rxjs';
import { map, pluck, publishReplay, refCount, switchMap } from 'rxjs/operators';
import { UsersService } from '../../shared/services/users/users.service';
import { AuditService } from '../audit/audit.service';

const { arrayUnion, arrayRemove } = firebase.firestore.FieldValue;

export interface TeamLabelGroupOptions {
  displayName: string;
  isArchived?: boolean;
}

export interface TeamLabelGroup {
  groupDisplayName: string;
  groupPlaceholder: string;
  groupType: string;
  options: { [key: string]: TeamLabelGroupOptions };
}

export interface TeamLabelGroupMap {
  [key: string]: TeamLabelGroup;
}

@Injectable({
  providedIn: 'root',
})
export class TeamsService {
  teams$: Observable<TeamWithId[]> = this.db.collection<Team>('teams', ref => ref).valueChanges({ idField: 'id' });

  constructor(
    private db: AngularFirestore,
    private users: UsersService,
    private audit: AuditService,
    private analytics: AngularFireAnalytics,
  ) {}

  getOrganisationTeams$(organisationId: string): Observable<TeamWithId[]> {
    return this.db
      .collection<Team>('teams', ref => ref.where('organisationId', '==', organisationId))
      .valueChanges({ idField: 'id' });
  }

  getTeams$(teamIds: string[] | null | undefined) {
    return teamIds && teamIds.length
      ? combineLatest(teamIds.map(this.getTeam$)).pipe(map(teams => teams.filter(Boolean)))
      : of([]);
  }

  get myTeams$(): Observable<TeamWithId[]> {
    return this.users.user$?.pipe(
      switchMap(user =>
        (!user ? of([]) : of(user?.teamIds)).pipe(
          switchMap(teamIds => this.getTeams$(teamIds)),
          map(teams => teams.filter(team => user.organisationIds.includes(team.organisationId) || team?.isPersonal)),
        ),
      ),
    );
  }

  isTeamAdmin$(teamId = ''): Observable<boolean> {
    return this.users.me$.pipe(
      map(user => user?.roles?.teamAdmin?.[teamId] || user?.roles?.islaAdmin),
      switchMap(isAdmin => {
        if (isAdmin) return of(true);

        if (!teamId) return of(false);

        return this.hasTeamAdmins$(teamId).pipe(map(hasTeamAdmins => !hasTeamAdmins));
      }),
    );
  }

  // tslint:disable-next-line:member-ordering
  mySpecialties$: Observable<string[]> = this.myTeams$?.pipe(
    switchMap(teams =>
      combineLatest(teams.map(team => of(team.specialty))).pipe(map(specialties => specialties.flat().filter(Boolean))),
    ),
  );

  getTeam$ = (id: string): Observable<TeamWithId> =>
    this.db
      .doc<Team>(`teams/${id}`)
      .valueChanges()
      .pipe(map(team => team && { ...team, id }));

  getTeamFunction$(id: string): Observable<TeamWithId> {
    return this.db
      .doc<Team>(`teams/${id}`)
      .valueChanges()
      .pipe(map(team => team && { ...team, id }));
  }

  getTeamSnapshot = (id: string) => {
    return this.db.doc<Team>(`teams/${id}`).ref.get();
  };

  getTeamMembers$(teamId: string): Observable<UserWithId[]> {
    return this.db
      .collection<User>('users', ref => ref.where('teamIds', 'array-contains', teamId))
      .valueChanges({ idField: 'id' });
  }

  getResponses = (teamId: string): Observable<string[]> => this.getTeam$(teamId).pipe(pluck('responseTemplates'));

  getTeamPatients$(teamId: string): Observable<PatientWithId[]> {
    return this.db
      .collection<Patient>('patients', ref =>
        ref.where('teamIds', 'array-contains', teamId).where('isArchived', '==', false),
      )
      .valueChanges({ idField: 'id' });
  }

  getTeamPatientsInMyOrg = (teamId: string): Observable<PatientWithId[]> =>
    this.users.myOrganisationId$.pipe(
      switchMap(orgId => {
        return this.db
          .collection<Patient>('patients', ref =>
            ref.where('teamIds', 'array-contains', teamId).where('isArchived', '==', false),
          )
          .valueChanges({ idField: 'id' });
      }),
    );

  // tslint:disable-next-line:member-ordering
  myEnableWoundMeasurements$: Observable<boolean[]> = this.myTeams$?.pipe(
    switchMap(teams => combineLatest(teams.map(team => of(team?.featureFlags?.imageMeasurementEnabled)))),
  );

  getFiltersForTeam$ = (id: string): Observable<SearchOptions> =>
    this.db
      .doc<Team>(`teams/${id}`)
      .valueChanges()
      .pipe(map(team => team?.searchOptions));

  getFiltersForTeams$ = (teamIds: string[] | null | undefined) =>
    teamIds?.length ? combineLatest(teamIds.map(val => this.getFiltersForTeam$(val))) : of([]);

  mergeFiltersAcrossTeams(set: Set<string>, searchOptionsValues: string[]) {
    if (searchOptionsValues) {
      for (const searchOptionsValue of searchOptionsValues) {
        set.add(searchOptionsValue);
      }
    }
    return set;
  }

  unwrapAndMergeFilters(filters: SearchOptions[]) {
    const apptDescriptionsSet: Set<string> = new Set();
    const apptClinicsSet: Set<string> = new Set();
    const apptWardsSet: Set<string> = new Set();
    filters.forEach(filter => {
      this.mergeFiltersAcrossTeams(apptDescriptionsSet, filter?.apptDescriptions);
      this.mergeFiltersAcrossTeams(apptClinicsSet, filter?.apptClinics);
      this.mergeFiltersAcrossTeams(apptWardsSet, filter?.apptWards);
    });
    const apptDescriptions = Array.from(apptDescriptionsSet);
    const apptClinics = Array.from(apptClinicsSet);
    const apptWards = Array.from(apptWardsSet);
    return [
      {
        apptDescriptions: apptDescriptions,
        apptClinics: apptClinics,
        apptWards: apptWards,
      },
    ];
  }

  get myFilters$() {
    return this.users.me$.pipe(
      switchMap(user => (!user ? of([]) : of(user?.teamIds))),
      switchMap(val => this.getFiltersForTeams$(val)),
      map((filters): SearchOptions[] => this.unwrapAndMergeFilters(filters)),
      map(filters => filters[0] as SearchOptions),
      publishReplay(1),
      refCount(),
    );
  }

  async create(team: Team): Promise<void> {
    const featureFlags: TeamFeatureFlags = {
      imageMeasurementEnabled: false,
      mlFeatures: {
        ssiDetectionOptions: [],
      },
    };

    const teamWithFeatureFlags = {
      ...team,
      featureFlags,
    };
    const newTeamId = this.db.createId();
    await this.db
      .collection<Team>('teams')
      .doc(newTeamId)
      .set(teamWithFeatureFlags)
      .catch(error => {
        throw error;
      });
    await this.audit.createTeam(newTeamId);
    await this.analytics.logEvent('create team', { id: newTeamId });
  }

  async addComment(comment: string, teamId: string): Promise<void> {
    const teamDoc = this.db.doc(`teams/${teamId}`);

    await teamDoc.update({ responseTemplates: arrayUnion(comment) });
  }

  async updateComment(initialComment: string, updatedComment: string, teamId: string): Promise<void> {
    const teamDoc = this.db.doc(`teams/${teamId}`);

    await teamDoc.update({ responseTemplates: arrayRemove(initialComment) });
    await teamDoc.update({ responseTemplates: arrayUnion(updatedComment) });
  }

  hasTeamAdmins$(teamId: string): Observable<boolean> {
    return this.getTeam$(teamId).pipe(
      switchMap(team => {
        if ('hasTeamAdmins' in team) return of(!!team['hasTeamAdmins']);

        return this.getTeamMembers$(teamId).pipe(
          map(teamMembers => {
            const hasTeamAdmins = teamMembers.some(teamMember => teamMember?.roles?.teamAdmin?.[teamId]);
            // updates team.hasTeamAdmins
            this.updateConfig(teamId, { hasTeamAdmins });
            return hasTeamAdmins;
          }),
        );
      }),
    );
  }

  updateConfig(teamId: string, config: Record<any, any>): Promise<void> {
    const teamDoc = this.db.doc(`teams/${teamId}`);
    return teamDoc.update(config);
  }

  async teamUpdate(teamId: string, teamName: string): Promise<void> {
    await this.db
      .collection<Team>('teams')
      .doc(teamId)
      .update({ name: teamName })
      .catch(error => {
        throw error;
      });
  }
}
