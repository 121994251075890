<form [formGroup]="entryForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <mat-form-field appearance="fill" class="notes-field half-width-field">
      <input
        matInput
        formControlName="notes"
        type="notes"
        placeholder="Notes"
        autocomplete="off"
        data-cy="defaul-notes-field-fallback"
      />
    </mat-form-field>
    <br />
    <!-- <mat-slide-toggle
      class="sensitive-image-field"
        matInput
        formControlName = "sensitiveImage"
        value = "sensitiveImage"
        labelPosition = "after"
        color = 'primary'
        (click)="setSensitiveImage($event.checked)"
      >
      Obscure image as sensitive
    </mat-slide-toggle>

    <div *ngIf="isSensitive">
      <p class="sensitive-text">The image will be blurred unless your clinician is actively reviewing it.</p>
    </div>
    <br> -->

    <div *ngIf="isDetectingBlur">
      <p class="is-detecting-text">Analysing image quality...</p>
    </div>

    <button
      mat-raised-button
      class="positive-button"
      data-cy="confirm-photo-fallback"
      type="submit"
      [disabled]="isSubmitting || isDetectingBlur"
    >
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
