import { ClinicalFormField } from '@islacare/ic-types';
import { FormlyFieldConfig } from '@ngx-formly/core';

enum SectionEditorField {
  TYPE = 'type',
  PROPS_LABEL = 'props.label',
  PROPS_SUBTITLE = 'props.subtitle',
  EXPRESSIONS_HIDE = 'expressions.hide',
  PROPS_SHOW_FOR_LOGGED_IN_USERS_ONLY = 'props.showForLoggedInUsersOnly',
}

export const sectionEditorForm: FormlyFieldConfig[] = [
  {
    type: ClinicalFormField.TEXT,
    key: SectionEditorField.PROPS_LABEL,
    props: { label: 'Label' },
  },
  {
    type: ClinicalFormField.TEXT,
    key: SectionEditorField.PROPS_SUBTITLE,
    props: { label: 'Subtitle' },
  },
  {
    type: ClinicalFormField.TEXT,
    key: SectionEditorField.EXPRESSIONS_HIDE,
    props: { label: 'Hide expression' },
  },
  {
    type: ClinicalFormField.RADIO,
    key: SectionEditorField.PROPS_SHOW_FOR_LOGGED_IN_USERS_ONLY,
    props: {
      label: 'Show for logged in users only',
      options: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ],
    },
  },
];
