import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,

  selector: 'ic-pain-new-patient-form',
  templateUrl: './pain-new-patient-form.component.html',
  styleUrls: ['./pain-new-patient-form.component.scss'],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class PainNewPatientFormComponent implements OnInit {
  @Input() data;
  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  painMedicationForm: UntypedFormGroup;
  painSummaryForm: UntypedFormGroup;
  dn4ScaleForm: UntypedFormGroup;
  neuropathicPainSymptomInventoryForm: UntypedFormGroup;
  bpiInterferenceScaleForm: UntypedFormGroup;
  isSubmitting = false;
  isSensitive: boolean;
  isLinear = true;
  low = true;
  medium = false;
  high = false;
  painDescriptor: string;
  neuroPainSymptomTotalAnswer: number;
  sliderMin = 0;
  sliderMax = 10;
  step = '1';
  tickInterval = '1';

  ngOnInit(): void {
    this.painMedicationForm = this.fb.group({
      drugsCurrentlyTakingForPain: this.fb.array([]),
      drugsCurrentlyTakingForOtherConditions: this.fb.array([]),
      drugsPreviouslyTakenForPain: this.fb.array([]),
    });

    this.painSummaryForm = this.fb.group({
      bodyAreaWithMostPain: ['', [Validators.required]],
      painIntensityNow: [''],
      painIntensityLastWeek: [''],
    });

    this.dn4ScaleForm = this.fb.group({
      painFeelLikeBurning: ['', Validators.required],
      painFeelLikePainfulCold: ['', Validators.required],
      painFeelLikeElectricShocks: ['', Validators.required],
      painFeelLikeTingling: ['', Validators.required],
      painFeelLikePinsAndNeedles: ['', Validators.required],
      painFeelLikeNumbness: ['', Validators.required],
      painFeelLikeItching: ['', Validators.required],
    });

    this.neuropathicPainSymptomInventoryForm = this.fb.group({
      painFeelLikeBurningSlider: [''],
      painFeelLikeSqueezingSlider: [''],
      painFeelLikePressureSlider: [''],
      painHasBeenPresent: ['', Validators.required],
      painFeelLikeElectricShocksSlider: [''],
      painFeelLikeStabbingSlider: [''],
      painAttacks: ['', Validators.required],
      painIncreasedByBrushingSlider: [''],
      painIncreasedByPressureSlider: [''],
      painIncreasedByContactWithColdSlider: [''],
      painFeelsLikePinsAndNeedlesSlider: [''],
      painFeelsLikeTinglingSlider: [''],
      burningSuperficialSpontaneousPain: [''],
      pressingDeepSpontaneousPain: [''],
      paroxysmalPain: [''],
      evokedPain: [''],
      paresthesiaDysesthesiaPain: [''],
      neuropathicPainSymptomTotalAnswer: [''],
    });

    this.bpiInterferenceScaleForm = this.fb.group({
      generalActivitySlider: [''],
      moodSlider: [''],
      walkingAbilitySlider: [''],
      normalWorkSlider: [''],
      relationshipsWithOtherPeopleSlider: [''],
      sleepSlider: [''],
      enjoymentOfLifeSlider: [''],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.painMedicationForm.controls[key])
          this.painMedicationForm.controls[key].setValue(formData[key]);
        if (this.painSummaryForm.controls[key])
          this.painSummaryForm.controls[key].setValue(formData[key]);
        if (this.dn4ScaleForm.controls[key])
          this.dn4ScaleForm.controls[key].setValue(formData[key]);
        if (this.neuropathicPainSymptomInventoryForm.controls[key])
          this.neuropathicPainSymptomInventoryForm.controls[key].setValue(
            formData[key]
          );
        if (this.bpiInterferenceScaleForm.controls[key])
          this.bpiInterferenceScaleForm.controls[key].setValue(formData[key]);
      });

      let i = 0;
      while (i < formData['drugsCurrentlyTakingForPain'].length) {
        this.addDrugsCurrentlyTakingForPain();
        i++;
      }

      let j = 0;
      while (j < formData['drugsCurrentlyTakingForOtherConditions'].length) {
        this.addDrugsCurrentlyTakingForOtherConditions();
        j++;
      }

      let k = 0;
      while (k < formData['drugsPreviouslyTakenForPain'].length) {
        this.addDrugsPreviouslyTakenForPain();
        k++;
      }

      this.drugsCurrentlyTakingForPainForm.patchValue(
        formData['drugsCurrentlyTakingForPain']
      );
      this.drugsCurrentlyTakingForOtherConditionsForm.patchValue(
        formData['drugsCurrentlyTakingForOtherConditions']
      );
      this.drugsPreviouslyTakenForPainForm.patchValue(
        formData['drugsPreviouslyTakenForPain']
      );
    });
  }

  // Pain summary
  get bodyAreaWithMostPain() {
    return this.painSummaryForm.get('bodyAreaWithMostPain');
  }

  // dn4
  get painFeelLikeBurning() {
    return this.dn4ScaleForm.get('painFeelLikeBurning');
  }

  get painFeelLikePainfulCold() {
    return this.dn4ScaleForm.get('painFeelLikePainfulCold');
  }

  get painFeelLikeElectricShocks() {
    return this.dn4ScaleForm.get('painFeelLikeElectricShocks');
  }

  get painFeelLikeTingling() {
    return this.dn4ScaleForm.get('painFeelLikeTingling');
  }

  get painFeelLikePinsAndNeedles() {
    return this.dn4ScaleForm.get('painFeelLikePinsAndNeedles');
  }

  get painFeelLikeNumbness() {
    return this.dn4ScaleForm.get('painFeelLikeNumbness');
  }

  get painFeelLikeItching() {
    return this.dn4ScaleForm.get('painFeelLikeItching');
  }

  // neuro
  get painHasBeenPresent() {
    return this.neuropathicPainSymptomInventoryForm.get('painHasBeenPresent');
  }

  get painAttacks() {
    return this.neuropathicPainSymptomInventoryForm.get('painAttacks');
  }

  get drugsCurrentlyTakingForPainForm() {
    return this.painMedicationForm.get(
      'drugsCurrentlyTakingForPain'
    ) as UntypedFormArray;
  }
  get drugsCurrentlyTakingForOtherConditionsForm() {
    return this.painMedicationForm.get(
      'drugsCurrentlyTakingForOtherConditions'
    ) as UntypedFormArray;
  }
  get drugsPreviouslyTakenForPainForm() {
    return this.painMedicationForm.get(
      'drugsPreviouslyTakenForPain'
    ) as UntypedFormArray;
  }

  addDrugsCurrentlyTakingForPain() {
    const drugCurrentlyTakingForPain = this.fb.group({
      drugName: [''],
      drugDose: [''],
      drugDailyIntake: [''],
    });
    this.drugsCurrentlyTakingForPainForm.push(drugCurrentlyTakingForPain);
  }
  deleteDrugsCurrentlyTakingForPain(i) {
    this.drugsCurrentlyTakingForPainForm.removeAt(i);
  }

  addDrugsCurrentlyTakingForOtherConditions() {
    const drugCurrentlyTakingForOtherConditions = this.fb.group({
      drugName: [''],
      drugDose: [''],
      drugDailyIntake: [''],
    });
    this.drugsCurrentlyTakingForOtherConditionsForm.push(
      drugCurrentlyTakingForOtherConditions
    );
  }
  deleteDrugsCurrentlyTakingForOtherConditions(i) {
    this.drugsCurrentlyTakingForOtherConditionsForm.removeAt(i);
  }

  addDrugsPreviouslyTakenForPain() {
    const drugPreviouslyTakenForPain = this.fb.group({
      drugName: [''],
      drugDose: [''],
      drugDailyIntake: [''],
    });
    this.drugsPreviouslyTakenForPainForm.push(drugPreviouslyTakenForPain);
  }
  deleteDrugsPreviouslyTakenForPain(i) {
    this.drugsPreviouslyTakenForPainForm.removeAt(i);
  }

  setNeuroPainSymptomScore() {
    const neuroPainSymptomQ1 = this.neuropathicPainSymptomInventoryForm.get(
      'painFeelLikeBurningSlider'
    ).value;
    const neuroPainSymptomQ2 = this.neuropathicPainSymptomInventoryForm.get(
      'painFeelLikeSqueezingSlider'
    ).value;
    const neuroPainSymptomQ3 = this.neuropathicPainSymptomInventoryForm.get(
      'painFeelLikePressureSlider'
    ).value;
    const neuroPainSymptomQ5 = this.neuropathicPainSymptomInventoryForm.get(
      'painFeelLikeElectricShocksSlider'
    ).value;
    const neuroPainSymptomQ6 = this.neuropathicPainSymptomInventoryForm.get(
      'painFeelLikeStabbingSlider'
    ).value;
    const neuroPainSymptomQ8 = this.neuropathicPainSymptomInventoryForm.get(
      'painIncreasedByBrushingSlider'
    ).value;
    const neuroPainSymptomQ9 = this.neuropathicPainSymptomInventoryForm.get(
      'painIncreasedByPressureSlider'
    ).value;
    const neuroPainSymptomQ10 = this.neuropathicPainSymptomInventoryForm.get(
      'painIncreasedByContactWithColdSlider'
    ).value;
    const neuroPainSymptomQ11 = this.neuropathicPainSymptomInventoryForm.get(
      'painFeelsLikePinsAndNeedlesSlider'
    ).value;
    const neuroPainSymptomQ12 = this.neuropathicPainSymptomInventoryForm.get(
      'painFeelsLikeTinglingSlider'
    ).value;

    const neuroPainSymptomTotal1 = neuroPainSymptomQ1;
    const neuroPainSymptomTotal2 =
      (neuroPainSymptomQ2 + neuroPainSymptomQ3) / 2;
    const neuroPainSymptomTotal3 =
      (neuroPainSymptomQ5 + neuroPainSymptomQ6) / 2;
    const neuroPainSymptomTotal4 =
      (neuroPainSymptomQ8 + neuroPainSymptomQ9 + neuroPainSymptomQ10) / 3;
    const neuroPainSymptomTotal5 =
      (neuroPainSymptomQ11 + neuroPainSymptomQ12) / 2;
    const neuroPainSymptomTotalAnswer =
      neuroPainSymptomTotal1 +
      neuroPainSymptomTotal2 +
      neuroPainSymptomTotal3 +
      neuroPainSymptomTotal4 +
      neuroPainSymptomTotal5;

    const neuroPainSymptomTotalAnswerRounded =
      Math.round(neuroPainSymptomTotalAnswer * 10) / 10;

    this.neuropathicPainSymptomInventoryForm
      .get('burningSuperficialSpontaneousPain')
      .setValue(neuroPainSymptomTotal1);
    this.neuropathicPainSymptomInventoryForm
      .get('pressingDeepSpontaneousPain')
      .setValue(neuroPainSymptomTotal2);
    this.neuropathicPainSymptomInventoryForm
      .get('paroxysmalPain')
      .setValue(neuroPainSymptomTotal3);
    this.neuropathicPainSymptomInventoryForm
      .get('evokedPain')
      .setValue(neuroPainSymptomTotal4);
    this.neuropathicPainSymptomInventoryForm
      .get('paresthesiaDysesthesiaPain')
      .setValue(neuroPainSymptomTotal5);
    this.neuropathicPainSymptomInventoryForm
      .get('neuropathicPainSymptomTotalAnswer')
      .setValue(neuroPainSymptomTotalAnswerRounded);
  }

  onSubmit() {
    this.setNeuroPainSymptomScore();
    this.isSubmitting = true;
    const formResponse = {
      ...this.painMedicationForm.value,
      ...this.painSummaryForm.value,
      ...this.dn4ScaleForm.value,
      ...this.neuropathicPainSymptomInventoryForm.value,
      ...this.bpiInterferenceScaleForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  painSummaryFormValid(): boolean {
    return (
      this.painSummaryForm.dirty &&
      !(
        this.painSummaryForm.controls &&
        this.painSummaryForm.controls['bodyAreaWithMostPain'].errors
      )
    );
    // &&!(this.painSummaryForm.controls && this.painSummaryForm.controls.painIntensityNow.errors)
  }

  dn4ScaleFormValid(): boolean {
    return (
      this.dn4ScaleForm.dirty &&
      !(
        this.dn4ScaleForm.controls &&
        this.dn4ScaleForm.controls['painFeelLikeBurning'].errors
      ) &&
      !(
        this.dn4ScaleForm.controls &&
        this.dn4ScaleForm.controls['painFeelLikePainfulCold'].errors
      ) &&
      !(
        this.dn4ScaleForm.controls &&
        this.dn4ScaleForm.controls['painFeelLikeElectricShocks'].errors
      ) &&
      !(
        this.dn4ScaleForm.controls &&
        this.dn4ScaleForm.controls['painFeelLikeTingling'].errors
      ) &&
      !(
        this.dn4ScaleForm.controls &&
        this.dn4ScaleForm.controls['painFeelLikePinsAndNeedles'].errors
      ) &&
      !(
        this.dn4ScaleForm.controls &&
        this.dn4ScaleForm.controls['painFeelLikeNumbness'].errors
      ) &&
      !(
        this.dn4ScaleForm.controls &&
        this.dn4ScaleForm.controls['painFeelLikeItching'].errors
      )
    );
  }

  neuropathicPainSymptomInventoryFormValid(): boolean {
    return (
      this.neuropathicPainSymptomInventoryForm.dirty &&
      !(
        this.neuropathicPainSymptomInventoryForm.controls &&
        this.neuropathicPainSymptomInventoryForm.controls['painHasBeenPresent']
          .errors
      ) &&
      !(
        this.neuropathicPainSymptomInventoryForm.controls &&
        this.neuropathicPainSymptomInventoryForm.controls['painAttacks'].errors
      )
    );
  }
}
