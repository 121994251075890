import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { ContentInputType, FormField, UserInputType } from '@islacare/ic-types';

@Component({
  selector: 'ic-automatic-form-reference',
  templateUrl: './automatic-form-reference.component.html',
  styleUrls: ['./automatic-form-reference.component.scss'],
})
export class AutomaticFormReferenceComponent implements OnInit {
  @Input() field: FormField;
  @Input() formId: string;
  @Input() control: any;
  @Input() automaticForm: UntypedFormGroup;
  @Input() formFields: FormField[] = [];
  @Input() index: number;
  @Input() isFormArray: boolean;
  @Input() referencedFormFields: {
    [key: string]: {
      formFieldsOrderedArray: FormField[];
      automaticForm: UntypedFormGroup;
    };
  };
  @Output() scoresArray = new EventEmitter<any>();
  @Output() bmiResponseChange = new EventEmitter<any>();
  @Output() tallyObject = new EventEmitter<any>();
  ContentInputType = ContentInputType;
  UserInputType = UserInputType;
  tallies = {};
  bmiResponse: boolean;
  addBmiResponse = false;
  constructor(public formService: FormService) {}

  ngOnInit() {
    if (Object.values(UserInputType).includes(this.field?.inputType as any)) {
      this.control = this.control[this.field.controlName];
    }
  }

  getScore(field: FormField): number | '0' {
    let validationCount = 0;
    const calculatedScore = field?.addFieldsScore.reduce((result, item) => {
      this.automaticForm?.controls?.[item]?.value
        ? (validationCount += 1)
        : null;
      const formField = this.formFields.find(
        (fieldItem) => fieldItem.controlName === item
      );
      if (this.showField(formField) && !this.hideField(formField)) {
        if (formField && !this.isScoreType(formField.inputType)) {
          result += +(formField?.options
            ? formField?.options.find(
                (option) =>
                  option.label === this.automaticForm?.controls?.[item]?.value
              )?.value || 0
            : +this.automaticForm?.controls?.[item]?.value > 0
            ? +this.automaticForm?.controls?.[item]?.value
            : 0);
        } else if (formField && this.isScoreType(formField.inputType)) {
          result += +(formField?.value || 0);
        }
      } else result += 0;
      return result;
    }, 0);
    field.value = Math.abs(+calculatedScore.toFixed(2));
    const manipulate = {
      '+': function (x: number, y: number) {
        return x + y;
      },
      '-': function (x: number, y: number) {
        return x - y;
      },
      '*': function (x: number, y: number) {
        return x * y;
      },
      '/': function (x: number, y: number) {
        return x / y;
      },
    };
    let result = field.value;
    if (field?.scoreManipulation) {
      for (const manipulation of field?.scoreManipulation) {
        const operation = manipulation.split(' ');
        operation[1] === 'numberOfFieldsCompleted'
          ? (operation[1] = String(validationCount))
          : null;
        result = manipulate[operation[0]](result, Number(operation[1])).toFixed(
          2
        );
      }
    }
    result = this.formatNumbers(result);
    field.value = result;
    return isNaN(Number(field.value)) ? '0' : result;
  }

  getTally(response: string, field: FormField): number {
    let tally = 0;
    const currentTallies = JSON.stringify(this.tallies);
    field?.addFieldsScore.forEach((item) => {
      if (field?.valueGreaterThan) {
        this.getCurrentFieldValue(item, this.automaticForm, this.formFields) >
        +field?.valueGreaterThan
          ? (tally += 1)
          : null;
      } else if (
        (field?.valueLessThan &&
          this.getCurrentFieldValue(item, this.automaticForm, this.formFields) <
            +field?.valueLessThan) ||
        this.automaticForm?.controls?.[item].value === response
      ) {
        tally = tally + 1;
      }
    });
    this.tallies[response] = tally;
    if (currentTallies !== JSON.stringify(this.tallies))
      this.tallyObject.emit(this.tallies);
    if (field?.valueGreaterThan || field?.valueLessThan) {
      field.value = tally;
    }
    return tally;
  }

  getScaledScore(field: FormField) {
    const scoreField = this.formFields.find(
      (fieldItem) => fieldItem.controlName === field?.scoreFieldControlName
    );

    if (scoreField) {
      const calculatedScore = Number(
        (+scoreField?.value / scoreField?.totalScore) * field.scale
      );
      field.value = this.formatNumbers(Number(calculatedScore));
      return field.value;
    } else {
      field.value = 0;
      return 0;
    }
  }

  getBmi(field: FormField) {
    const input = field?.bmiInput;
    let weight: number = this.automaticForm?.controls?.[input.kilograms]?.value;
    let height: number =
      this.automaticForm?.controls?.[input.centimeters]?.value * 0.01;
    const weightUnits =
      this.automaticForm?.controls?.[input.weightUnits]?.value;
    const heightUnits =
      this.automaticForm?.controls?.[input.heightUnits]?.value;
    if (weightUnits === 'Stones and pounds (st & lb)') {
      const stones: number =
        this.automaticForm?.controls?.[input.stones]?.value || 0;
      const pounds: number =
        this.automaticForm?.controls?.[input.pounds]?.value || 0;
      weight = (+stones + +pounds / 14) * 6.35029318;
    }
    if (heightUnits === 'Feet and inches (ft & in)') {
      const feet = this.automaticForm?.controls?.[input.feet]?.value || 0;
      const inches = this.automaticForm?.controls?.[input.inches]?.value || 0;
      height = (+feet + +inches / 12) / 3.281;
    }
    const calculatedBmi = weight / (height * height);
    const bmiThreshold = field?.bmiThreshold;
    if (bmiThreshold) {
      this.addBmiResponse = true;
    }
    this.bmiResponse = undefined;
    if (weight && height) {
      this.bmiResponse = calculatedBmi <= bmiThreshold;
      this.bmiResponseChange.emit(this.bmiResponse);
    }
    return isNaN(calculatedBmi) || !isFinite(calculatedBmi)
      ? '0'
      : this.formatNumbers(Number(calculatedBmi));
  }

  hideField(field, formId?) {
    if (!field?.hideField) {
      return false;
    }
    const hideField = field?.hideField;
    const automaticForm = this.isReferencedForm(formId);
    const formKeys = hideField && Object.keys(hideField);
    if (typeof hideField === 'boolean') {
      return hideField;
    } else {
      return this.hideOrShow(field, formKeys, automaticForm, hideField, false);
    }
  }

  showField(field, formId?) {
    if (!field?.showField) {
      return true;
    }
    const showField = field?.showField;
    const formKeys = showField && Object.keys(showField);
    const automaticForm = this.isReferencedForm(formId);
    return this.hideOrShow(field, formKeys, automaticForm, showField, true);
  }

  hideOrShow(field, formKeys, automaticForm, targetField, isShowField) {
    let flag = true;
    if (formKeys?.length) {
      formKeys.forEach((key) => {
        const value = automaticForm.controls[key]?.value;
        if (value === null || value === undefined) {
          flag = isShowField ? false : true;
        } else if (
          this.formFields.find((i) => i.controlName === key)?.inputType ===
          UserInputType.MULTI_SELECT
        ) {
          flag =
            flag &&
            automaticForm?.controls?.[key]?.value?.includes(targetField?.[key]);
        } else {
          flag =
            flag &&
            automaticForm?.controls?.[key]?.value === targetField?.[key];
        }
      });
      if ((isShowField && !flag) || (!isShowField && flag)) {
        this.automaticForm?.controls?.[field.controlName]?.setErrors(undefined);
      } else if (
        field.validation &&
        this.automaticForm?.controls?.[field.controlName]?.touched &&
        !this.automaticForm?.controls?.[field.controlName]?.value
      ) {
        this.automaticForm?.controls?.[field.controlName]?.setErrors({
          required: true,
        });
      }
      return flag;
    } else {
      return false;
    }
  }

  isReferencedForm(formId) {
    return formId?.substring(0, 4) === 'ref '
      ? this.referencedFormFields[formId?.substring(4)]?.['automaticForm']
      : this.automaticForm;
  }

  shouldShowContainer(field, formId?) {
    return (
      !this.hideField(field, formId) &&
      this.showField(field, formId) &&
      this.showFieldOnValidation(field)
    );
  }

  showFieldOnValidation(field) {
    const fields = field.showFieldOnValidation;
    if (!fields) {
      return true;
    }
    let valid = true;
    for (const field of fields) {
      if (this.automaticForm?.controls?.[field]?.value === 0) {
        valid = valid && true;
      } else {
        valid = valid && !!this.automaticForm?.controls?.[field]?.value;
      }
    }
    return valid;
  }

  calculate(operandsArray) {
    //calculate using individual operations e.g. ['a', '+', 'b']
    //or using single operator to apply to all e.g. ['+', 'a', 'b', 'c'...]
    //setup mathematical operations
    let value;
    const manipulate = {
      '+': function (x, y) {
        return Number(x) + Number(y);
      },
      '-': function (x, y) {
        return x - y;
      },
      '*': function (x, y) {
        return x * y;
      },
      '/': function (x, y) {
        return x / y;
      },
      '|': function (x, y) {
        return y / x;
      },
      //using | as a reverse divide as backslash is not possible
    };
    const operations = ['+', '-', '*', '/', '|'];
    const operator = operations.includes(operandsArray?.[0])
      ? operandsArray?.[0]
      : null;
    //remove operator if single operation
    if (operator) {
      operandsArray.shift();
    }
    operandsArray.forEach((item, index) => {
      if (item === undefined) {
        item = '0';
        operandsArray[index] = '0';
      }
      if (index === 0) {
        value = Number(item) ? Number(item) : 0;
      } else if (operator) {
        value = manipulate[operator](value, operandsArray?.[index])
          ? manipulate[operator](value, operandsArray?.[index])
          : 0;
      } else if (!operations.includes(operandsArray?.[index])) {
        const operate = operandsArray[index - 1];
        if (operations.includes(operate)) {
          value = manipulate[operate](value, operandsArray?.[index])
            ? manipulate[operate](value, operandsArray?.[index])
            : 0;
        }
      }
    });
    return value;
  }

  applyCalculation(field: FormField) {
    const calculationFields = field.calculationFields;
    let operandsArray = [...calculationFields];
    const operations = ['+', '-', '*', '/', '|'];
    //iterate through calculationFields and assign user input values
    calculationFields.forEach((item, index) => {
      if (Number(item)) {
        operandsArray[index] = item;
      } else if (!operations.includes(item)) {
        if (calculationFields[1] === 'section') {
          this.sectionsCalculation(item, operandsArray);
          operandsArray = operandsArray.filter((e) => e !== item);
        } else if (calculationFields[1] === 'array') {
          this.arrayCalculation(item, operandsArray);
          operandsArray = operandsArray.filter((e) => e !== item);
        } else {
          const value = this.getCurrentFieldValue(
            item,
            this.automaticForm,
            this.formFields
          );
          operandsArray[index] = value;
        }
      }
    });

    //iterate through calculationFields and apply mathematical operations
    const value = this.calculate(operandsArray);
    field.value = this.formatNumbers(value);
    if (this.isFormArray) {
      const scoreFields = {
        [this.index ? this.index : 0]: { [field.controlName]: field.value },
      };
      this.scoresArray.emit(scoreFields);
    }
    return isNaN(Number(value)) ? '0' : field.value;
  }

  sectionsCalculation(item, operandsArray) {
    //remove 'array' identifier from operandsArray
    if (item === 'section') {
      operandsArray = operandsArray.filter((e) => e !== item);
      return;
    }
    const [controlName, sectionId] = item.split('___');
    const section = this.referencedFormFields?.[sectionId];
    const value = this.getCurrentFieldValue(
      controlName,
      section.automaticForm,
      section.formFieldsOrderedArray
    );
    value ? operandsArray.push(value) : null;
  }

  arrayCalculation(item, operandsArray) {
    //form-arrays processing: [operand, 'array', 'controlName']
    //remove 'array' identifier from operandsArray
    if (item === 'array') {
      operandsArray = operandsArray.filter((e) => e !== item);
      return;
    }
    //get values in each row
    for (const row in this.referencedFormFields) {
      if (row.substring(0, 5) === 'array') {
        const value = this.getCurrentFieldValue(
          item,
          this.referencedFormFields[row].automaticForm,
          this.referencedFormFields[row].formFieldsOrderedArray
        );
        value ? operandsArray.push(value) : null;
      }
    }
  }

  thresholdCalculation(field: FormField) {
    const subjectControlName = field.calculationFields[0];
    const currentFieldValue: string = this.getCurrentFieldValue(
      subjectControlName,
      this.automaticForm,
      this.formFields
    );
    let age: string;
    const ranges = field.thresholdArray;
    const compare = {
      '<': function (x, y) {
        return Number(x) < Number(y);
      },
      '<=': function (x, y) {
        return x <= y;
      },
      '>=': function (x, y) {
        return x >= y;
      },
      '>': function (x, y) {
        return x > y;
      },
    };
    for (const rangeCase of ranges) {
      if (rangeCase.isAgeRange) {
        const today = Date.now();
        if (!this.isDateField(subjectControlName)) return;
        const dateArray = currentFieldValue.split('-');
        const DOB = new Date();
        DOB.setUTCFullYear(Number(dateArray[0]));
        DOB.setUTCMonth(Number(dateArray[1]) - 1);
        DOB.setUTCDate(Number(dateArray[2]));
        age = String(
          Math.abs(new Date(today - DOB.getTime()).getUTCFullYear() - 1970)
        );
      }

      const value = age ? age : currentFieldValue;
      if (isNaN(Number(value))) return 0;
      const lowerRangeValue = rangeCase.range[0].replace(/[^a-zA-Z0-9]/g, '');
      const lowerRangeOperator = rangeCase.range[0].replace(/[0-9]/g, '');
      if (rangeCase.range[1]) {
        const upperRangeValue = rangeCase.range[1].replace(/[^a-zA-Z0-9]/g, '');
        const upperRangeOperator = rangeCase.range[1].replace(/[0-9]/g, '');
        if (
          compare[lowerRangeOperator](value, lowerRangeValue) &&
          compare[upperRangeOperator](value, upperRangeValue)
        ) {
          field.value =
            this.isBoolean(rangeCase.value) || isNaN(Number(rangeCase.value))
              ? rangeCase.value
              : this.formatNumbers(Number(rangeCase.value));
          return field.value;
        }
      } else {
        if (compare[lowerRangeOperator](value, lowerRangeValue)) {
          field.value =
            this.isBoolean(rangeCase.value) || isNaN(Number(rangeCase.value))
              ? rangeCase.value
              : this.formatNumbers(Number(rangeCase.value));
          return field.value;
        }
      }
    }
  }

  isDateField(controlName) {
    const formField = this.formFields.find(
      (fieldItem) => fieldItem.controlName === controlName
    );
    if (
      formField?.inputType === UserInputType.DATEPICKER ||
      formField?.inputType === UserInputType.DATE_FIELD
    )
      return true;
    else return false;
  }

  isBoolean(value) {
    return typeof value === 'boolean';
  }

  formatNumbers(value: number) {
    if (isNaN(value) || value === undefined) return 0;
    let result;
    //deconstruct number
    const numSplit = String(value).split('.');
    //insert comma every thousand
    const integer = numSplit[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const decimal = numSplit[1];
    //reconstruct number
    if (decimal?.length === 1) {
      //append trailing zero
      result = integer + '.' + decimal + '0';
    } else if (decimal?.length >= 2) {
      result = integer + '.' + decimal.substring(0, 2);
    } else {
      result = integer;
    }
    return result;
  }

  getLowestScore(field) {
    let score = Infinity;

    field?.compareFieldsScore?.forEach((item) => {
      const currentFieldValue = this.getCurrentFieldValue(
        item,
        this.automaticForm,
        this.formFields
      );
      currentFieldValue === null
        ? delete field?.compareFieldsScore[currentFieldValue]
        : (score =
            Number(currentFieldValue) < Number(score)
              ? currentFieldValue
              : score);
    });
    const result = score;
    field.value = result;
    return result;
  }

  getHighestScore(field) {
    let score = -Infinity;
    field?.compareFieldsScore?.forEach((item) => {
      const currentFieldValue = this.getCurrentFieldValue(
        item,
        this.automaticForm,
        this.formFields
      );
      currentFieldValue === null
        ? delete field?.compareFieldsScore[currentFieldValue]
        : (score =
            Number(currentFieldValue) > Number(score)
              ? currentFieldValue
              : score);
    });
    const result = score;
    field.value = result;
    return result;
  }

  getCurrentFieldValue(controlName: string, automaticForm, formFields) {
    const formField = formFields.find(
      (fieldItem) => fieldItem.controlName === controlName
    );
    let currentFieldValue;
    const controlValue = automaticForm?.controls?.[controlName]?.value;
    if (!this.showField(formField) || this.hideField(formField)) return null;

    if (this.isScoreType(formField?.inputType)) {
      currentFieldValue = formField?.value;
    } else if (
      formField?.inputType === UserInputType.DATEPICKER ||
      formField?.inputType === UserInputType.DATE_FIELD
    ) {
      currentFieldValue = String(controlValue);
    } else if (formField?.inputType === UserInputType.MULTI_SELECT) {
      currentFieldValue = controlValue
        ? this.calculate([
            '+',
            ...controlValue?.map(
              (value) =>
                formField?.options?.find((option) => option.label === value)
                  ?.value
            ),
          ])
        : null;
    } else if (formField?.options) {
      currentFieldValue = formField?.options.find(
        (option) => option.label === controlValue
      )?.value;
    } else {
      currentFieldValue = controlValue;
    }
    if (formField?.offsetSlider && currentFieldValue <= 0)
      currentFieldValue = 0;
    return currentFieldValue;
  }

  isScoreType(inputType) {
    if (
      inputType === ContentInputType.SCORE ||
      inputType === ContentInputType.SCALED_SCORE ||
      inputType === ContentInputType.HIGHEST_SCORE ||
      inputType === ContentInputType.LOWEST_SCORE ||
      inputType === ContentInputType.RESPONSE_TALLY ||
      inputType === ContentInputType.CALCULATION ||
      inputType === ContentInputType.THRESHOLD
    ) {
      return true;
    } else {
      return false;
    }
  }
}
