import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService, UsersService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

const { required } = Validators;

@Component({
  standalone: true,
  selector: 'ic-photo-fallback-form',
  templateUrl: './photo-fallback-form.component.html',
  styleUrls: ['./photo-fallback-form.component.scss'],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class PhotoFallbackFormComponent implements OnInit {
  @Input() data;

  constructor(
    private fb: UntypedFormBuilder,
    private form: FormService,
    private usersService: UsersService
  ) {}

  entryForm: UntypedFormGroup;
  isSubmitting = false;
  isSensitive: boolean;
  isDetectingBlur = true;
  orgId: string;

  async ngOnInit(): Promise<void> {
    this.entryForm = this.fb.group({
      notes: [''],
      sensitiveImage: ['', [required, Boolean]],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.entryForm.controls[key])
          this.entryForm.controls[key].setValue(formData[key]);
      });
      const sensitive = this.entryForm.get('sensitiveImage').value;
      this.setSensitiveImage(sensitive);
    });

    // // ONLY PERFORM THIS IF WE'RE RBHT AND DETECTING BLUR
    this.usersService.myOrganisationId$.subscribe(async (id) => {
      this.orgId = id;

      if (id === 'Psg9GN32OTRyieZLRbx1') {
        this.sleep(6000); // this is roughly how long it takes to get a Blur Score
        this.setBlurDetectionComplete(false);
      } else {
        this.setBlurDetectionComplete(false);
      }
    });
  }

  sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  onSubmit() {
    this.isSubmitting = true;
    const formResponse = {
      ...this.entryForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  setBlurDetectionComplete(status: boolean) {
    this.isDetectingBlur = status;
  }

  setSensitiveImage(status: boolean) {
    if (typeof status === 'string') {
      this.form.setSensitiveImage(false);
      this.isSensitive = false;
    } else if (status || status === false) {
      this.form.setSensitiveImage(status);
      this.isSensitive = status;
    } else {
      this.isSensitive = !this.isSensitive;
      this.form.setSensitiveImage(this.isSensitive);
    }
  }
}
