import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CollectionWithId, PatientWithId, WithId } from '@islacare/ic-types';
import {
  EntriesService,
  EntryWithUrlLarge,
} from '../../../services/entries/entries.service';

@Component({
  selector: 'ic-original-image-dialog',
  templateUrl: './original-image-dialog.component.html',
  styleUrls: ['./original-image-dialog.component.scss'],
})
export class OriginalImageDialogComponent implements OnInit {
  entry: EntryWithUrlLarge & WithId;
  loadingImagePath = 'assets/loading-image.gif';
  loading = true;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patient: PatientWithId;
      collection: CollectionWithId;
      entryId: string;
    },
    public entriesService: EntriesService
  ) {}

  async ngOnInit(): Promise<void> {
    const { patient, collection, entryId } = this.data;
    this.entry = await this.entriesService.getEntryWithUrl(
      patient.id,
      collection.id,
      entryId,
      true
    );
    this.loading = false;
  }
}
