import { Injectable } from '@angular/core';
import { LoggingService } from '@ic-monorepo/services';
import * as RecordRTC from 'recordrtc';

@Injectable({
  providedIn: 'root',
})
export class UserCameraService {
  videoElement: HTMLVideoElement;
  stream: {
    camera: MediaStream;
    destroy(): void;
    startRecording(): void;
    getBlob(): Blob;
    stopRecording: any;
  };

  constructor(private logging: LoggingService) {}

  async startRecording(videoDisplayElement: HTMLVideoElement, audio = true) {
    if (!this.stream) await this.getVideoStream(videoDisplayElement, audio);
    this.stream.startRecording();
    return;
  }

  previewVideoStream(videoDisplayElement: HTMLVideoElement) {
    this.videoElement = videoDisplayElement;
    return navigator.mediaDevices
      .getUserMedia({
        video: { facingMode: 'environment' },
      })
      .then((stream) => {
        this.videoElement.srcObject = stream;
      });
  }

  getVideoStream(videoDisplayElement: HTMLVideoElement, audio = true) {
    this.disconnect();
    this.videoElement = videoDisplayElement;
    return navigator.mediaDevices
      .getUserMedia({
        video: { facingMode: 'environment' },
        audio,
      })
      .then((stream) => {
        this.videoElement.srcObject = stream;
        this.videoElement.autoplay = true;
        this.videoElement.muted = true;
        this.videoElement.controls = false;

        this.stream = RecordRTC(stream, {
          type: 'video',
          mimeType: 'video/webm;codecs=vp8',
        });
        this.stream.camera = stream;
      });
  }

  stopAndGetRecording(videoDisplayElement: HTMLVideoElement): Promise<Blob> {
    this.videoElement = videoDisplayElement;
    return new Promise((resolve) => {
      this.stream.stopRecording(() => {
        this.videoElement.src = this.videoElement.srcObject = null;
        this.videoElement.muted = false;
        this.videoElement.volume = 1;
        this.videoElement.controls = true;
        this.videoElement.autoplay = false;
        this.videoElement.src = URL.createObjectURL(this.stream.getBlob());
        resolve(this.stream.getBlob());
      });
    });
  }

  disconnect() {
    try {
      this.stream?.camera?.getTracks().forEach((track) => track.stop());
    } catch (err) {
      this.logging.consoleError('unable to stop camera', err);
    }
    try {
      this.stream?.destroy();
    } catch (err) {
      this.logging.consoleError('unable to stop camera', err);
    }
    this.stream = null;
  }
}
