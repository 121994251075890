import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, DocumentReference, QueryFn } from '@angular/fire/compat/firestore';
import {
  AuditActionEnum,
  AuditActionRecipient,
  AuditBase as AuditAction,
  AuditResponseEnum,
  EditActionEnum,
  Fresh,
  Team,
  User,
  WithId,
} from '@islacare/ic-types';
import { serverTimestamp } from 'firebase/firestore';
import { Observable } from 'rxjs';
import { first, pluck } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {
  MappedEntryTagsWithDisplayName,
  TagUpdateData,
} from '../../feature-patient-record/redesign/services/rd-patient-record/rd-patient-record.service';

const now = serverTimestamp();

export enum AuditActionStatusEnum {
  BEFORE = 'before',
  AFTER = 'after',
}

@Injectable({
  providedIn: 'root',
})
export class AuditService {
  constructor(
    private db: AngularFirestore,
    private auth: AngularFireAuth,
  ) {}

  getActions$(queryFn?: QueryFn): Observable<(AuditAction & WithId)[]> {
    return this.db.collection<AuditAction>('auditActions', queryFn).valueChanges({ idField: 'id' });
  }

  async createTeam(teamId: string): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.create_team,
      createdAt: now,
      createdBy: await this.uid(),
      teamId,
      organisationId: await this.userOrg(),
    });
  }

  async setMembership(
    userId: string,
    teamId: string,
    hasBeenAdded: boolean,
    teamOrgId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: hasBeenAdded ? AuditActionEnum.add_user_to_team : AuditActionEnum.remove_user_from_team,
      createdAt: now,
      createdBy: await this.uid(),
      userId,
      teamId,
      organisationId: teamOrgId,
    });
  }

  async setPatientMembership(
    patientId: string,
    teamId: string,
    hasBeenAdded: boolean,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: hasBeenAdded ? AuditActionEnum.add_patient_to_team : AuditActionEnum.remove_patient_from_team,
      createdAt: now,
      createdBy: await this.uid(),
      patientId,
      teamId,
      organisationId: await this.userOrg(),
    });
  }

  async logFormResponse(
    patientId: string,
    collectionId: string,
    entryId: string,
    formResponse: any,
    loggingStage: AuditActionStatusEnum,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: `${loggingStage}-form-response` as any,
      createdAt: now,
      createdBy: await this.uid(),
      patientId,
      collectionId,
      entryId,
      organisationId: await this.userOrg(),
      data: formResponse,
    } as any); // This is sloppy, but will be removed soon once we have understood a production bug
  }

  async viewPatient(patientId: string): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.view_patient,
      createdAt: now,
      createdBy: await this.uid(),
      patientId: patientId,
      organisationId: await this.userOrg(),
    });
  }

  async authTokenCreate(patientId: string): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.create_auth_token,
      createdAt: now,
      createdBy: await this.uid(),
      userId: await this.uid(),
      patientId: patientId,
      organisationId: await this.userOrg(),
    });
  }

  async authTokenCancel(patientId: string): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.cancel_auth_token,
      createdAt: now,
      createdBy: await this.uid(),
      userId: await this.uid(),
      patientId: patientId,
      organisationId: await this.userOrg(),
    });
  }

  async patientCreate(patientId: string): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.create_patient,
      createdAt: now,
      createdBy: await this.uid(),
      userId: await this.uid(),
      patientId: patientId,
      organisationId: await this.userOrg(),
    });
  }

  async updatePatientDetails(patientId: string): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.update_patient_details,
      createdAt: now,
      createdBy: await this.uid(),
      userId: await this.uid(),
      patientId: patientId,
      organisationId: await this.userOrg(),
    });
  }

  async sharePatientRecord(patientId: string): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.share_patient_record_with_another_clinician,
      createdAt: now,
      createdBy: await this.uid(),
      userId: await this.uid(),
      patientId: patientId,
      organisationId: await this.userOrg(),
    });
  }

  async createCollection(patientId: string, collectionId: string): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.create_collection,
      createdAt: now,
      createdBy: await this.uid(),
      userId: await this.uid(),
      patientId: patientId,
      organisationId: await this.userOrg(),
      collectionId: collectionId,
    });
  }

  async deleteCollection(
    patientId: string,
    teamId: string,
    collectionId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.delete_collection,
      createdAt: now,
      createdBy: await this.uid(),
      userId: await this.uid(),
      patientId: patientId,
      organisationId: await this.userOrg(),
      teamId: teamId,
      collectionId: collectionId,
    });
  }

  async updateCollection(
    patientId: string,
    collectionId: string,
    teamId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.update_collection,
      createdAt: now,
      createdBy: await this.uid(),
      userId: await this.uid(),
      patientId: patientId,
      organisationId: await this.userOrg(),
      collectionId: collectionId,
      teamId: teamId,
    });
  }

  async addCommentToCollection(
    patientId: string,
    collectionId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.add_comment_to_collection,
      createdAt: now,
      createdBy: await this.uid(),
      userId: await this.uid(),
      patientId: patientId,
      organisationId: await this.userOrg(),
      collectionId: collectionId,
    });
  }

  async editCommentOnCollection(
    patientId: string,
    teamId: string,
    collectionId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.edit_comment_on_collection,
      createdAt: now,
      createdBy: await this.uid(),
      userId: await this.uid(),
      patientId: patientId,
      organisationId: await this.userOrg(),
      teamId: teamId,
      collectionId: collectionId,
    });
  }

  async respondToPatientViaText(
    patientId: string,
    recipient: AuditActionRecipient,
    teamId: string,
    resourceAttached: boolean,
    resourceId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.respond_to_patient_via_text,
      createdAt: now,
      createdBy: await this.uid(),
      userId: await this.uid(),
      patientId: patientId,
      organisationId: await this.userOrg(),
      recipient: recipient,
      teamId: teamId,
      resourceAttached: resourceAttached,
      resourceId: resourceId || '',
    });
  }

  async createSubmission(
    patientId: string,
    entryId: string,
    teamId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.create_submission,
      createdAt: now,
      createdBy: await this.uid(),
      userId: await this.uid(),
      patientId: patientId,
      entryId: entryId,
      organisationId: await this.userOrg(),
      teamId: teamId,
    });
  }

  async deleteSubmission(
    patientId: string,
    entryId: string,
    teamId: string,
    collectionId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.delete_submission,
      createdAt: now,
      createdBy: await this.uid(),
      userId: await this.uid(),
      patientId: patientId,
      entryId: entryId,
      organisationId: await this.userOrg(),
      teamId: teamId,
      collectionId: collectionId,
    });
  }

  async editSubmission(
    patientId: string,
    entryId: string,
    teamId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.edit_submission,
      createdAt: now,
      createdBy: await this.uid(),
      userId: await this.uid(),
      patientId: patientId,
      entryId: entryId,
      organisationId: await this.userOrg(),
      teamId: teamId,
    });
  }

  async viewSubmission(
    patientId: string,
    entryId: string,
    teamId: string,
    collectionId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.view_submission,
      createdAt: now,
      createdBy: await this.uid(),
      userId: await this.uid(),
      patientId: patientId,
      entryId: entryId,
      organisationId: await this.userOrg(),
      teamId: teamId,
      collectionId,
    });
  }

  async markSubmissionAsReviewed(
    patientId: string,
    collectionId: string,
    entryId: string,
    teamId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.mark_submission_as_reviewed,
      createdAt: now,
      createdBy: await this.uid(),
      userId: await this.uid(),
      patientId: patientId,
      organisationId: await this.userOrg(),
      collectionId: collectionId,
      entryId: entryId,
      teamId: teamId,
    });
  }

  async moveEntriesBetweenFolders(
    patientId: string,
    entryId: string,
    targetCollectionId: string,
    originalCollectionId: string,
    newDocId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.update_entry_collection,
      createdAt: now,
      createdBy: await this.uid(),
      userId: await this.uid(),
      patientId: patientId,
      organisationId: await this.userOrg(),
      newCollectionId: targetCollectionId,
      previousCollectionId: originalCollectionId,
      originalDocumentId: entryId,
      newDocumentId: newDocId,
    });
  }

  async markSubmissionAsUnReviewed(
    patientId: string,
    collectionId: string,
    entryId: string,
    teamId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.mark_submission_as_unreviewed,
      createdAt: now,
      createdBy: await this.uid(),
      userId: await this.uid(),
      patientId: patientId,
      organisationId: await this.userOrg(),
      collectionId: collectionId,
      entryId: entryId,
      teamId: teamId,
    });
  }

  async editSubmissionImage(
    patientId: string,
    entryId: string,
    teamId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.successful_login_attempt,
      createdAt: now,
      createdBy: await this.uid(),
      userId: await this.uid(),
      entryId: entryId,
      patientId: patientId,
      organisationId: await this.userOrg(),
      teamId: teamId,
    });
  }

  async measureAnnotationImage(
    patientId: string,
    entryId: string,
    teamId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.measure_submission_image,
      createdAt: now,
      createdBy: await this.uid(),
      userId: await this.uid(),
      patientId: patientId,
      entryId: entryId,
      organisationId: await this.userOrg(),
      teamId: teamId,
    });
  }

  async submissionExportedAsPdf(
    patientId: string,
    entryId: string,
    teamId: string,
    exportAction: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.submission_exported_as_pdf,
      createdAt: now,
      createdBy: await this.uid(),
      userId: await this.uid(),
      patientId: patientId,
      entryId: entryId,
      organisationId: await this.userOrg(),
      teamId: teamId,
      exportAction: exportAction,
    });
  }

  async submissionImageMarkedSensitive(
    patientId: string,
    entryId: string,
    teamId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.submission_image_marked_sensitive,
      createdAt: now,
      createdBy: await this.uid(),
      userId: await this.uid(),
      patientId: patientId,
      entryId: entryId,
      organisationId: await this.userOrg(),
      teamId: teamId,
    });
  }

  async submissionImageMarkedNotSensitive(
    patientId: string,
    entryId: string,
    teamId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.submission_image_marked_not_sensitive,
      createdAt: now,
      createdBy: await this.uid(),
      userId: await this.uid(),
      entryId: entryId,
      patientId: patientId,
      organisationId: await this.userOrg(),
      teamId: teamId,
    });
  }

  async unsuccessfulLoginAttempt(userEmail: string, errorCode: string): Promise<DocumentReference<Fresh<AuditAction>>> {
    if (userEmail) {
      return this.db.collection<Fresh<AuditAction>>('auditActions').add({
        action: AuditActionEnum.unsuccessful_login_attempt,
        createdAt: now,
        createdBy: userEmail,
        appVersion: `v${environment.version}`,
        errorCode: errorCode,
        organisationId: 'unknown',
        authNotRequired: true,
      });
    }
  }

  async successfulLoginAttempt(userEmail: string): Promise<DocumentReference<Fresh<AuditAction>>> {
    const user = await this.auth.currentUser;
    this.db.doc(`users/${user.uid}`).update({
      lastLoggedIn: now,
    });
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.successful_login_attempt,
      createdAt: now,
      createdBy: userEmail,
      appVersion: `v${environment.version}`,
      organisationId: 'unknown',
      authNotRequired: true,
    });
  }

  async addBlurAssessment(blurScore: number, patientId, action): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.imageBlurAssessment,
      createdAt: now,
      createdBy: await this.uid(),
      patientId: patientId,
      blurScore: blurScore,
      response: action,
      organisationId: await this.userOrg(),
    });
  }

  async pdsRequest(
    patientNhs: string,
    response: AuditResponseEnum,
    uuid = '',
    trackingId = '',
    messageId = '',
    asid = '',
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.pds_getPatientDetailsByNHSNumber,
      response: response,
      createdAt: now,
      patientId: patientNhs,
      createdBy: await this.uid(),
      auditIdentityId: uuid,
      trackingId: trackingId,
      messageId: messageId,
      userId: await this.uid(),
      asid: asid,
      organisationId: await this.userOrg(),
    });
  }

  async clinicianToggledConsentConfirmed(patientId: string): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.clinician_toggled_consent_confirmed,
      createdAt: now,
      createdBy: await this.uid(),
      patientId: patientId,
      organisationId: await this.userOrg(),
    });
  }

  async clinicianToggledConsentWithdrawn(patientId: string): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.clinician_toggled_consent_withdrawn,
      createdAt: now,
      createdBy: await this.uid(),
      patientId: patientId,
      organisationId: await this.userOrg(),
    });
  }

  async updateAdminRole(userId: string, hasBeenAdded: boolean): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: hasBeenAdded ? AuditActionEnum.add_admin_role_to_user : AuditActionEnum.remove_admin_role_from_user,
      createdAt: now,
      createdBy: await this.uid(),
      userId,
      organisationId: await this.userOrg(),
    });
  }

  async userRemovedFromOrg(userId: string): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.remove_user_from_organisation,
      createdAt: now,
      createdBy: await this.uid(),
      userId,
      organisationId: await this.userOrg(),
    });
  }

  async addInviteUserToTeam(userEmail: string, teamId: string): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.add_invite_user_to_team,
      inviteeEmail: userEmail.toLowerCase(),
      teamId: teamId,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
    });
  }

  async systmOneLinkAccountSuccess(patientId: string): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.systmone_link_account_success,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
      patientId: patientId,
    });
  }
  async systmOneLinkAccountFailure(patientId: string): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.systmone_link_account_failure,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
      patientId: patientId,
    });
  }

  async legacyScheduleUpdateFailure(): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.legacy_schedule_update_failure,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
    });
  }

  async markPatientAsDeceased(userId: string, patientId: string): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.mark_patient_as_deceased,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
      patientId: patientId,
      userId: userId,
    });
  }

  async cropImage(
    userId: string,
    patientId: string,
    entryId: string,
    blurScore: number,
    teamId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.crop_image,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
      patientId: patientId,
      userId: userId,
      // TODO blurScore: blurScore,
      entryId: entryId,
      teamId: teamId,
    });
  }

  async logoutNative(): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.logout_native,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
      userId: await this.uid(),
    });
  }

  async logoutAuto(): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.logout_auto,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
      userId: await this.uid(),
    });
  }

  async addAdditionalPhoneNumber(
    patientId: string,
    recipient: AuditActionRecipient,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.add_additional_phone_number,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
      userId: await this.uid(),
      patientId: patientId,
      recipient: recipient,
    });
  }
  async removeAdditionalPhoneNumber(
    patientId: string,
    recipient: AuditActionRecipient,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.remove_additional_phone_number,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
      userId: await this.uid(),
      patientId: patientId,
      recipient: recipient,
    });
  }
  async archivePatientManual(patientId: string): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.archive_patient_manual,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
      userId: await this.uid(),
      patientId: patientId,
    });
  }

  async unArchivePatientManual(patientId: string): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.unarchive_patient_manual,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
      userId: await this.uid(),
      patientId: patientId,
    });
  }

  async editFormSubmission(
    patientId: string,
    entryId: string,
    formId: string,
    teamId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.edit_form_submission,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
      userId: await this.uid(),
      patientId: patientId,
      entryId: entryId,
      formId: formId,
      teamId: teamId,
    });
  }

  async addTeamLevelAdminRoleToUser(teamId: string): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.add_team_level_admin_role_to_user,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
      userId: await this.uid(),
      teamId: teamId,
    });
  }

  async responseSent(
    patientId: string,
    teamId: string,
    contactMethod: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.response_sent,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
      userId: await this.uid(),
      patientId: patientId,
      teamId: teamId,
      contactMethod: contactMethod,
    });
  }

  async responsePdfSent(
    patientId: string,
    teamId: string,
    contactMethod: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.response_pdf_sent,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
      userId: await this.uid(),
      patientId: patientId,
      teamId: teamId,
      contactMethod: contactMethod,
    });
  }

  async scheduleCreate(
    patientId: string,
    teamId: string,
    collectionId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.schedule_create,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
      patientId: patientId,
      userId: await this.uid(),
      teamId: teamId,
      collectionId: collectionId,
    });
  }
  async scheduleEdit(
    patientId: string,
    teamId: string,
    collectionId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.schedule_edit,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
      patientId: patientId,
      userId: await this.uid(),
      teamId: teamId,
      collectionId: collectionId,
    });
  }
  async scheduleDisable(
    patientId: string,
    teamId: string,
    collectionId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.schedule_disable,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
      patientId: patientId,
      userId: await this.uid(),
      teamId: teamId,
      collectionId: collectionId,
    });
  }
  async scheduleReminderSet(
    patientId: string,
    teamId: string,
    collectionId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.schedule_reminder_set,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
      patientId: patientId,
      userId: await this.uid(),
      teamId: teamId,
      collectionId: collectionId,
    });
  }

  private uid = (): Promise<string> => this.auth.authState.pipe(first(), pluck('uid')).toPromise();

  private userOrg = async (): Promise<string> => {
    const uid = await this.uid();
    const userDoc = this.db.doc<User>(`users/${uid}`).ref.get();
    return (await userDoc).data().organisationId;
  };

  async uploadResource(
    teamId: string,
    userId: string,
    resourceId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.upload_patient_resource,
      teamId: teamId,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
      userId: userId,
      resourceId: resourceId,
    });
  }

  async editResource(
    teamId: string,
    userId: string,
    resourceId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.edit_patient_resource,
      teamId: teamId,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
      userId: userId,
      resourceId: resourceId,
    });
  }

  async deleteResource(
    teamId: string,
    userId: string,
    resourceId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.delete_patient_resource,
      teamId: teamId,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
      userId: userId,
      resourceId: resourceId,
    });
  }

  async createScheduleTemplate(teamId: string, scheduleTemplateId: string) {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.create_schedule_template,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
      teamId: teamId,
      scheduleTemplateId: scheduleTemplateId,
    });
  }

  async editScheduleTemplate(teamId: string, scheduleTemplateId: string, editAction: EditActionEnum) {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.edit_schedule_template,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
      teamId: teamId,
      scheduleTemplateId: scheduleTemplateId,
      editAction: editAction,
    });
  }

  async viewFolder(
    patientId: string,
    teamId: string,
    collectionId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.view_folder,
      createdAt: now,
      createdBy: await this.uid(),
      userId: await this.uid(),
      patientId: patientId,
      organisationId: await this.userOrg(),
      teamId: teamId,
      collectionId,
    });
  }

  async editScheduleOnCollection(
    patientId: string,
    collectionId: string,
    scheduleItemId: string,
    teamId: string,
    editAction: EditActionEnum,
  ) {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.schedule_edit,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
      teamId: teamId,
      patientId: patientId,
      collectionId: collectionId,
      scheduledItemId: scheduleItemId,
      editAction: editAction,
    });
  }

  async applyScheduleTemplateOnCollection(
    patientId: string,
    collectionId: string,
    scheduleTemplateId: string,
    teamId: string,
  ) {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.schedule_edit,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
      teamId: teamId,
      patientId: patientId,
      collectionId: collectionId,
      scheduleTemplateId: scheduleTemplateId,
      editAction: EditActionEnum.add_template,
    });
  }

  async createScheduleOnCollection(
    patientId: string,
    collectionId: string,
    scheduleTemplateId: string,
    teamId: string,
  ) {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.schedule_create,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
      teamId: teamId,
      patientId: patientId,
      collectionId: collectionId,
      scheduleTemplateId: scheduleTemplateId,
    });
  }

  async sendManualReminder(
    patientId: string,
    collectionId: string,
    requestId: string,
    teamId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.send_manual_reminder,
      teamId,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
      patientId,
      collectionId,
      requestId,
    });
  }

  async viewRequestTimeline(
    patientId: string,
    collectionId: string,
    teamId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.view_request_timeline,
      teamId,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
      patientId,
      collectionId,
    });
  }

  async viewFormGraph(
    patientId: string,
    collectionId: string,
    formId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.view_form_graph,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
      patientId,
      collectionId,
      formId,
    });
  }

  async viewFormTable(
    patientId: string,
    collectionId: string,
    formId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.view_form_table,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
      patientId,
      collectionId,
      formId,
    });
  }

  async addFormToTeam(teamId: string, formId: string): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.add_form_to_team,
      teamId,
      formId,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
    });
  }

  async archiveFormOnTeam(teamId: string, formId: string): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.archive_form_on_team,
      teamId,
      formId,
      createdAt: now,
      createdBy: await this.uid(),
      organisationId: await this.userOrg(),
    });
  }
  async directCompare(patientId: string, collectionId: string): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.direct_compare,
      createdAt: now,
      createdBy: await this.uid(),
      userId: await this.uid(),
      patientId,
      organisationId: await this.userOrg(),
      collectionId,
    });
  }

  async bulkCreateCollection(patientId: string): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.bulk_create_collection,
      createdAt: now,
      createdBy: await this.uid(),
      userId: await this.uid(),
      patientId,
      organisationId: await this.userOrg(),
    });
  }
  async revertImageToOriginal(
    patientId: string,
    collectionId: string,
    entryId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.revert_image_to_original,
      createdAt: now,
      createdBy: await this.uid(),
      userId: await this.uid(),
      organisationId: await this.userOrg(),
      patientId,
      collectionId,
      entryId,
    });
  }
  async patientLabelsChange(newLabels, patientLabels, patientId: string, action: AuditActionEnum, teamId) {
    const team = (await this.db.doc(`/teams/${teamId}`).ref.get()).data() as Team;
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: action,
      createdAt: now,
      createdBy: await this.uid(),
      userId: await this.uid(),
      patientId,
      organisationId: team.organisationId,
      labelsBefore: patientLabels,
      labelsAfter: newLabels,
    });
  }

  async customPatientListDeletion(teamId, teamPatientViewId) {
    const team = (await this.db.doc(`/teams/${teamId}`).ref.get()).data() as Team;
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.custom_patient_list_deleted,
      createdAt: now,
      createdBy: await this.uid(),
      userId: await this.uid(),
      teamId,
      teamPatientViewId,
      organisationId: team.organisationId,
    });
  }

  async customPatientListCreation(teamId: string, teamPatientViewId: string) {
    const team = (await this.db.doc(`/teams/${teamId}`).ref.get()).data() as Team;
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.custom_patient_list_created,
      createdAt: now,
      createdBy: await this.uid(),
      userId: await this.uid(),
      teamId,
      teamPatientViewId,
      organisationId: team.organisationId,
    });
  }

  async exportToCsv(): Promise<DocumentReference<Fresh<AuditAction>>> {
    const userId = await this.uid();
    const user = (await this.db.doc(`/users/${userId}`).ref.get()).data() as User;
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.export_to_csv,
      createdAt: now,
      createdBy: userId,
      userId: userId,
      organisationId: user.organisationId,
    });
  }

  async bulkRemoveSubmissionAlerts(): Promise<DocumentReference<Fresh<AuditAction>>> {
    const userId = await this.uid();
    const user = (await this.db.doc(`/users/${userId}`).ref.get()).data() as User;
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.bulk_remove_submission_alerts,
      createdAt: now,
      createdBy: userId,
      userId: userId,
      organisationId: user.organisationId,
    });
  }

  async userCompletedFormCalculator(
    formId: string,
    auditAction: AuditActionEnum,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    const userId = await this.uid();
    const user = (await this.db.doc(`/users/${userId}`).ref.get()).data() as User;

    return await this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: auditAction,
      createdAt: now,
      createdBy: userId,
      userId: userId,
      organisationId: user.organisationId,
      formId: formId,
    });
  }

  async exportToCsvForm(formId: string): Promise<DocumentReference<Fresh<AuditAction>>> {
    const userId = await this.uid();
    const user = (await this.db.doc(`/users/${userId}`).ref.get()).data() as User;
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.export_to_csv_form,
      createdAt: now,
      createdBy: userId,
      userId: userId,
      organisationId: user.organisationId,
      formId: formId,
    });
  }

  async cancelAutomatedSchedules(
    collectionAutomationId: string,
    entryId: string,
  ): Promise<DocumentReference<Fresh<AuditAction>>> {
    const userId = await this.uid();
    const user = (await this.db.doc(`/users/${userId}`).ref.get()).data() as User;
    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.cancel_automated_schedules,
      createdAt: now,
      createdBy: userId,
      userId: userId,
      organisationId: user.organisationId,
      collectionAutomationId: collectionAutomationId,
      entryId: entryId,
    });
  }

  async addTagToEntry(tagUpdateData: TagUpdateData): Promise<DocumentReference<Fresh<AuditAction>>> {
    const userId = await this.uid();

    const user = (await this.db.doc(`/users/${userId}`).ref.get()).data() as User;

    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.submission_tag_added,
      createdAt: now,
      createdBy: userId,
      userId: userId,
      organisationId: user.organisationId,
      patientId: tagUpdateData.entry.patientId,
      entryId: tagUpdateData.entry.id,
      tags: tagUpdateData.tagsForAuditAction,
    });
  }
  async addTagsToEntries(tagUpdateDataArray: TagUpdateData[]): Promise<DocumentReference<Fresh<AuditAction>>[]> {
    const userId = await this.uid();
    const user = (await this.db.doc(`/users/${userId}`).ref.get()).data() as User;

    const batch = this.db.firestore.batch();

    const auditActions: DocumentReference<Fresh<AuditAction>>[] = [];

    tagUpdateDataArray.forEach(tagUpdateData => {
      const { entry } = tagUpdateData;

      const auditTags: MappedEntryTagsWithDisplayName[] = tagUpdateData.tagsForAuditAction;

      const auditActionRef = this.db.collection<Fresh<AuditAction>>('auditActions').doc().ref;

      batch.set(auditActionRef, {
        action: AuditActionEnum.submission_tag_added,
        createdAt: now,
        createdBy: userId,
        userId: userId,
        organisationId: user.organisationId,
        patientId: entry.patientId,
        entryId: entry.id,
        tags: auditTags,
      });

      auditActions.push(auditActionRef);
    });

    await batch.commit();

    return auditActions;
  }

  async removeTagFromEntry(tagUpdateData: TagUpdateData): Promise<DocumentReference<Fresh<AuditAction>>> {
    const userId = await this.uid();

    const user = (await this.db.doc(`/users/${userId}`).ref.get()).data() as User;

    return this.db.collection<Fresh<AuditAction>>('auditActions').add({
      action: AuditActionEnum.submission_tag_removed,
      createdAt: now,
      createdBy: userId,
      userId: userId,
      organisationId: user.organisationId,
      patientId: tagUpdateData.entry.patientId,
      entryId: tagUpdateData.entry.id,
      tags: tagUpdateData.tagsForAuditAction,
    });
  }
}
