import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-phototherapy-form',
  templateUrl: './phototherapy-form.component.html',
  styleUrls: ['./phototherapy-form.component.scss'],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class PhototherapyFormComponent implements OnInit {
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  phototherapyForm: UntypedFormGroup;
  isSubmitting = false;

  ngOnInit(): void {
    this.phototherapyForm = this.fb.group({
      skinCondition: [''],
      affectedAreas: [''],
      delayedLightTreatment: [''],
      delayedLightTreatmentReason: [''],
      haveYouHadPhototherapyBefore: [''],
      previousPhototherapyDetails: [''],
      burntInSunOrDuringTreatment: [''],
      burntInSunOrDuringTreatmentDetails: [''],
      skinConditionGetBetterOrWorseInSun: [''],
      howLongHaveYouHadTheSkinCondition: [''],
      anyOtherMedicalProblemsOrSkinConditions: [''],
      otherMedicalProblemsOrSkinConditionsDetails: [''],
      doYouTakeAnyMedication: [''],
      medicationDetails: [''],
      haveYouEverHadAColdSore: [''],
      coldSoreDetails: [''],
      hadAnInfectionWhichMadeYourSkinConditionWorse: [''],
      worsenedSkinConditionDetails: [''],
      doYouSmoke: [''],
      smokingDailyAmount: [''],
      doYouDrinkAlcohol: [''],
      alcoholWeeklyAmount: [''],
      doYouLiveAlone: [''],
      livingSituationDetails: [''],
      whatIsYourOccupation: [''],
      doYouThinkYouWorkMakesSkinConditionWorse: [''],
      doesYourSkinConditionAffectYourMood: [''],
      skinConditionAffectingMoodReferral: [''],
      disabilitiesThatMayAffectTreatment: [''],
      disabilityDetails: [''],
      doYouNeedAnInterpreterForFaceToFaceAssessment: [''],
      interpreterLanguage: [''],
      informationLeafletAboutLightTreatment: [''],
      anymoreAdditionalComments: [''],
      notes: [''],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.phototherapyForm.controls[key])
          this.phototherapyForm.controls[key].setValue(formData[key]);
      });
    });
  }

  onSubmit() {
    this.isSubmitting = true;
    const formResponse = {
      ...this.phototherapyForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }
}
