<div class="flex justify-content-start align-items-center">
  <i class="pi pi-exclamation-triangle text-3xl"></i>
  <p class="font-semibold pl-2">
    Are you sure you want to delete this submission from the patient’s record?
  </p>
</div>

<form [formGroup]="deleteReasonForm">
  <p class="mb-2 text-sm">Select a reason for deletion</p>
  <p-dropdown
    [options]="deleteReasons"
    placeholder="Select a reason"
    formControlName="reason"
    data-cy="reasonDropDown"
    appendTo="body"
    styleClass="w-full"
  >
    <ng-template pTemplate="selectedItem">
      {{ selectedReason | camelCaseToTitleCase }}
    </ng-template>
    <ng-template let-option pTemplate="item">
      <span data-cy="reasonDropDown-options">
        {{ option | camelCaseToTitleCase }}
      </span>
    </ng-template>
  </p-dropdown>
</form>

<ng-template #dialogFooter>
  <div class="flex gap-1 justify-content-end">
    <button
      (click)="ref.close()"
      data-cy="delete-entry-cancel"
      class="p-button-outlined"
      aria-label="Cancel"
      label="Cancel"
      type="button"
      pButton
    ></button>
    <button
      [disabled]="!deleteReasonForm.valid"
      (click)="confirmRemove()"
      data-cy="delete-entry-confirm"
      icon="pi pi-trash"
      aria-label="Yes, delete"
      label="Yes, delete"
      pButton
    ></button>
  </div>
</ng-template>
