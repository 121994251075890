import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormService, UsersService } from '@ic-monorepo/services';
import { FormWithId } from '@islacare/ic-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppointmentsService } from 'apps/frontend/portal/src/app/services/appointments.service';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';
import { fabric } from 'fabric';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

const { required } = Validators;

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'ic-wound-assessment-form',
  templateUrl: './wound-assessment-form.component.html',
  styleUrls: ['./wound-assessment-form.component.scss'],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class WoundAssessmentFormComponent implements OnInit {
  @Input() data;
  patientId: string;

  constructor(
    private fb: UntypedFormBuilder,
    private form: FormService,
    private usersService: UsersService,
    private appointmentsService: AppointmentsService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params) => {
      take(1), (this.patientId = params?.['patientId']);
    });
  }

  entryForm: UntypedFormGroup;
  setUpForm: UntypedFormGroup;
  isSubmitting = false;
  isSensitive: boolean;
  isDetectingBlur = true;
  orgId: string;
  form$: Observable<FormWithId>;
  canvas: any;

  formSelectiorsList: string[] = [
    'Basic wound assessment',
    'Dressings and Actions',
    'Health promotion',
    'Actions & investigations',
    'Planning & referrals',
    'Sepsis assessment',
  ];

  consentAndChaperoneList: string[] = [
    'Written consent obtained and placed in medical records',
    'Consent gained from parent or guardian',
    'Consent not possible',
    'Witnessed verbal consent for photo obtained',
    'Verbal consent for photo obtained',
    'Chaperone or companion offered - patient declined',
    'Chaperone or companion present',
    'Safeguarding',
  ];

  medicalHistoryOptionList: string[] = [
    'Diabetes (Diet)',
    'Diabetes (Oral therapy)',
    'Diabetes (Insulin)',
    'Smoker',
    'Vascular disease',
    'Immunosuppressed',
    'Poor nutritional status',
    'Carcinoma',
    'Cardiovascular disease',
    'High BMI',
    'Low BMI',
    'Immobility',
    'Recent surgery',
    'Other',
  ];

  woundList: string[] = [
    'Surgical wound',
    'Lower limb wound',
    'Pressure ulcer',
    'Miscellaneous Wound',
    'Vascular access device site (cannula or line)',
    'Burn or scald',
    'Fungating Wound',
    'Incontinence-associated dermatitis (IAD), moisture lesion',
    'Sinus/Fistula',
    'Stoma',
    'Traumatic/mechanical',
    'Skin Blister',
    'Skin tear(s)',
    'Suture or Clip Removal',
    'Drain site',
    'Rash or inflammatory skin condition',
    'Cut, abrasion, laceration',
    'Implant',
  ];

  surgicalWoundList: string[] = [
    'Clamshell',
    'Sternal',
    'Submammary',
    'Right sided thoracotomy',
    'Left sided thoracotomy',
    'Endoscopic vein harvest (leg or arm)',
    'Open vein harvest (leg or arm)',
    'Laparoscopic',
    'Tracheostomy',
    'VAD/ line',
    'Other',
  ];

  lowerLimbWoundList: string[] = [
    'Venous leg ulcer',
    'Venus foot ulcer',
    'Mixed aetiology',
    'Arterial/Palliative',
    'Chronic oedema',
    'Uncertain Aetiology',
  ];

  pressureUlcerList: string[] = [
    'Category 1',
    'Category 2',
    'Category 3',
    'Category 4',
    'Unstageable',
    'DTI (deep tissue injury)',
    'MDRPU (Medical device related pressure ulcer)',
    'MASD (Moisture associated skin damage)',
  ];

  occurrenceList: string[] = [
    'Admitted with (inpatients only)',
    'Hospital Acquired',
    'Home/nursing home acquired',
    'Unknown',
  ];

  vascularAccessList: string[] = [
    '0: No signs of phlebitis',
    '1: Slight pain or redness near the site',
    '2: Two signs of paleness, redness or swelling near site',
    '3: Pain along IV site, redness and induration',
    '4: Pain along IV site, redness, induration and palpable venous cord',
    '5: Pain along IV site, redness, induration, palpable venous cord and pyrexia',
  ];

  sutureList: string[] = [
    'Non-dissolvable suture(s)',
    'Surgical staples (clips)',
    'Deep tension sutures',
  ];

  bodyList: string[] = [
    'Abdomen',
    'Ankle',
    'Arm',
    'Back',
    'Back of head',
    'Breast',
    'Buttock',
    'Chest',
    'Ear',
    'Foot',
    'Elbow',
    'Finger',
    'Forearm',
    'Genitals',
    'Groin',
    'Hand',
    'Head',
    'Heel',
    'Hip',
    'Knee',
    'Leg',
    'Lip',
    'Lower Leg',
    'Mouth',
    'Nasal Bridge',
    'Neck',
    'Occipital',
    'Other',
    'Perineum',
    'Sacrum',
    'Spine',
    'Thigh',
    'Toe',
    'Under Bust',
    'Upper Arm',
  ];

  sideList: string[] = ['Right', 'Left', 'Other'];

  reasonsList: string[] = [
    'Routine wound care',
    'Wound infection',
    'Surgical wound dehiscence',
    'Management of pressure ulcer',
    'Other',
  ];

  descriptionsList: string[] = [
    'Dehiscence',
    'Discolouration',
    'Red',
    'Radiating Heat',
    'Swelling',
    'Pain',
    'Undermining',
    'Sinus',
    'Slough',
    'Ischaemic, necrotic',
    'Bruising',
    'High temperature (≥38C)',
    'Eschar',
    'Odour',
    'Induration',
    'Positive microbiology from wound',
    'Sternum stable',
    'Unstable sternum',
    'Sucking wound',
    'Not able to assess',
    'Healthy',
    'Unhealthy tissue',
    'Drain in situe',
    'Other',
  ];

  dehiscenceList: string[] = [
    'Dermal layer only involved; no visible subcutaneous fat',
    'Subcutenous layer exposed, facia not visible',
    'Subcutaneous layers and facia exposed',
    'Any area of fascial dehicence with organ space, visera, implant or bone exposed',
    'Unsure',
  ];

  exudateList: string[] = [
    'No exudate',
    'Serous',
    'Haemoserous',
    'Bleeding',
    'Pus',
  ];

  exudateAmountList: string[] = [
    'Minor; or only on dressing',
    'Moderate',
    'Heavy',
    'Very Heavy',
  ];

  analgesiaList: string[] = [
    'No',
    'Simple analgesics +/- adjuvant',
    'Weak opioid',
    'Weak opioid + non-opioid +/- adjuvant',
    'Strong opioid',
    'Strong opioid + non-opioid +/- adjuvant',
    'Patient declined',
  ];

  actionsList: string[] = [
    'Adhesive remover /wipe(s) for dressing removal',
    'Aseptic or ANTT',
    'ANTT, drapes and gowning',
    'Sterile technique',
    'Clean technique',
    'Sharp debridement',
    'Incident report completed',
    'Huddle',
    'Pressure relieving devices',
    'To monitor, no other care at present',
    'Regular turning - every 2 hours',
    'Regular turning - every 4 hours',
    'Regular turning - Other',
    'Referral to TVN',
    'Referral to Doctor',
    'Referral to Microbiologist',
    'No action needed',
  ];

  cleansingList: string[] = [
    'Cleaned aseptically with normal saline',
    'Cleaned aseptically with povidone iodine aqueous solution',
    'Wound bed not cleaned',
    'Other',
  ];

  dressingList: string[] = [
    'No dressing required',
    'Interactive dressing, non-visible',
    'Interactive dressing, visible',
    'Gentle border',
    'Film dressing',
    'Absorbent dressing',
    'Antimicrobial dressing',
    'Tissue adhesive (TOOL TIP: Dermabond)',
    'Surgical tape (TOOL TIP: Steri-strips)',
    'Gauze',
    'Honey',
    'Packed',
    'Topical, incisional negative dressing (TOOL TIP: PICO, PREVENA)',
    'VAC (TOOL TIP: KCI)',
    'Nanova system',
    'Lite bandage',
    'Compression bandage',
    'Other',
  ];

  negativeTherapyList: string[] = [
    'New application',
    'Continuing therapy',
    'End of therapy – removed this care episode',
  ];

  statusList: string[] = ['Intermittent', 'Continuous'];

  vacOptionsList: string[] = [
    'Remove adhesive drape gently with adhesive wipe removal wipe',
    'Ensure that surrounding skin of the wound is clean and dry using ANTT',
    'Apply thin strips around wound edges',
    'Mepitel to wound bed to prevent foam from adhering',
    'VAC care plan to be maintained',
  ];

  healthPromotionList: string[] = [
    'Purpose and timings of antibiotics explained',
    'Emollient provided',
    'Protective film surrounding skin (eg/Cavilon lollipop)',
    'Antimicrobial washes provided',
    'Antimicrobial wipes provided',
    'Community referrals completed',
    'Chest support',
    'Mobilise regularly and leg elevation when resting',
    'Weight done and team informed if not below or at baseline weight',
    'New cannula sited',
  ];

  chestSupportList: string[] = [
    'Cough lock provided',
    'Sternal vest provided',
    'Surgical bra provided',
  ];

  investigationsList: string[] = [
    'Specimen sample (microbiology)',
    'Blood sample',
    'X-ray requested',
    'CT scan requested',
    'MRI',
    'Doppler',
    'Mapping',
  ];

  specimenList: string[] = [
    'Wound swab',
    'Tissue Sample',
    'Fluid',
    'Bone',
    'Other',
  ];

  microbiologyList: string[] = [
    '(-1) Missing/Null',
    '(110) Bacteroides spp.',
    '(113) Bacteroides fragilis group',
    '(130) Burkholderia (Pseudomonas) spp.',
    '(132) Burkholderia cepacia',
    '(160) Chryseomonas spp.',
    '(180) Citrobacter spp.',
    '(182) Citrobacter diversus (koserii)',
    '(184) Citrobacter freundii',
    '(200) Clostridium spp.',
    '(202) Clostridium difficile',
    '(204) Clostridium perfringens',
    '(206) Clostridium septicum',
    '(221) Coliforms (unspecified)',
    '(240) Corynebacterium spp.',
    '(242) Corynebacterium jeikeium',
    '(251) Diptheroids (unspecified)',
    '(270) Enterobacter spp.',
    '(272) Enterobacter aerogenes',
    '(274) Enterobacter agglomerans',
    '(276) Enterobacter cloacae',
    '(290) Enterococcus spp.',
    '(292) Enterococcus faecalis',
    '(294) Enterococcus faecium',
    '(295) Enterococcus faecium (vancomycin -resistant)',
    '(311) Escherichia coli',
    '(330) Flavobacterium spp.',
    '(350) Fusobacterium spp.',
    '(380) Haemophilus spp.',
    '(382) Haemophilus influenzae',
    '(384) Haemophilus parainfluenzae',
    '(400) Hafnia spp.',
    '(420) Klebsiella spp.',
    '(422) Klebsiella pneumoniae (aerogenes)',
    '(424) Klebsiella oxytoca',
    '(450) Legionella spp.',
    '(452) Legionella pneumophila',
    '(470) Listeria spp.',
    '(472) Listeria monocytogenes',
    '(490) Micrococcus spp.',
    '(510) Moraxella spp.',
    '(512) Moraxella (Branhamella) catarrhalis',
    '(531) Morganella morganii',
    '(552) Mycobacterium avium',
    '(554) Mycobacterium chelonae',
    '(556) Mycobacterium fortuitum',
    '(558) Mycobacterium tuberculosis',
    '(559) Mycobacterium - other spp.',
    '(570) Neisseria spp.',
    '(572) Neisseria meningitidis',
    '(590) Nocardia spp.',
    '(592) Nocardia asteroides',
    '(620) Peptococcus spp.',
    '(630) Peptostreptococcus spp.',
    '(640) Prevotella spp.',
    '(650) Proprionibacterium spp.',
    '(670) Proteus spp.',
    '(672) Proteus mirabilis',
    '(674) Proteus vulgaris',
    '(690) Providencia spp.',
    '(692) Providencia alcalifaciens',
    '(694) Providencia rettgeri',
    '(696) Providencia stuartii',
    '(710) Pseudomonas spp.',
    '(712) Pseudomonas aeruginosa',
    '(732) Salmonella enteritidis',
    '(734) Salmonella typhi',
    '(739) Salmonella -other spp.',
    '(750) Serratia spp.',
    '(752) Serratia liquefaciens',
    '(754) Serratia marcescens',
    '(770) S. aureus, methicillin - resistant (MRSA)',
    '(772) S. aureus, methicillin - sensitive (MSSA)',
    '(780) Staphylococcus, coagulase - negative (CNS)',
    '(782) Staphylococcus epidermidis',
    '(783) Staphylococcus haemolyticus',
    '(784) Staphylococcus hominis',
    '(785) Staphylococcus lugdunensis',
    '(786) Staphylococcus saprophyticus',
    '(787) Staphylococcus schleiferi',
    '(788) Staphylococcus capitis',
    '(789) Staphylococcus warneri',
    '(801) Stenotrophomonas (Xanthomonas) maltophilia',
    '(821) Streptococcus agalactiae (group B)',
    '(822) Streptococcus bovis',
    '(823) Streptococcus pneumoniae',
    '(824) Streptococcus pyogenes (group A)',
    '(825) Streptococci, "viridans group"',
    '(829) Streptococcus - other aerobic spp.',
    '(840) Yersinia spp.',
    '(842) Yersinia enterocolitica',
    '(860) Other Gram-negative bacteria',
    '(870) Other Gram-positive bacteria',
    '(880) Other anaerobes',
    '(890) Other bacteria',
    '(910) Aspergillus spp.',
    '(920) Candida spp.',
    '(921) Candida auris',
    '(922) Candida albicans',
    '(924) Candida tropicalis',
    '(940) Other fungi/yeast',
    '(10) Acinetobacter spp.',
    '(12) Acinetobacter baumanii (anitratus)',
    '(14) Acinetobacter Iwoffii',
    '(30) Aeromonas spp.',
    '(50) Alcaligenes spp.',
    '(71) Anaerobic cocci (unspecified)',
    '(90) Bacillus spp.',
  ];

  referralsList: string[] = [
    'Refer to Surgical/ Medical team',
    'Refer to Tissue Viability',
    'Refer to Pain Team',
    'Refer to Dietician',
  ];

  sepsisList: string[] = ['Yes', 'No', 'Unsure', 'Known/ treatment'];

  redFlagList: string[] = [
    'Responds only to voice or pain/unresponsive',
    'Systolic BP <= 90mmHg (or drop >40 from normal)',
    'Heart rate >130 per minute',
    'Respiratory rate ≥25 per minute',
    'Need oxygen to keep SpO2 ≥92%',
    'Non-blanching rash; mottled/ashen/cyanotic',
    'Not passed urine in the last 18 h/UO <0.5ml/kg/hr',
    'Lactate ≥2mmol/L',
    'Recent chemotherapy',
  ];

  async ngOnInit(): Promise<void> {
    this.form$ = this.form.getForm(this.data.teamId, this.data.formId);
    this.entryForm = this.fb.group({
      //formSelectors
      formSelectiors: [''],
      //basic wound assessment
      responsibleClinician: [''],
      staffName: [''],
      currentLocation: [''],
      bedNumber: [''],
      consentAndChaperone: [''],
      allergiesAndSensitivities: [''],
      medicalHistory: [''],
      medicalHistoryOther: [''],
      woundType: ['', required],
      woundTypeDetail: [''],
      pressureUlcerOccurrence: [''],
      miscellaneousWoundDetails: [''],
      sutureMaterialsRemoved: [''],
      sutureMaterialsNotYetRemoved: [''],
      bodyLocation: ['', required],
      bodyLocationSide: [''],
      bodyLocationDetail: [''],
      woundDuration: [''],
      reasonForAssessment: ['', required],
      alternativeReasonForAssessment: [''],
      woundDescription: [''],
      dehiscenceWoundDescription: [''],
      notes: [''],
      exudate: [''],
      exudateAmount: [''],
      lengthMeasurement: [''],
      widthMeasurement: [''],
      depthMeasurement: [''],
      // Wound & Dressing care
      analgesia: [''],
      actions: [''],
      turningFrequency: [''],
      woundCleansing: [''],
      woundCleansingDetail: [''],
      woundDressing: [''],
      woundDressingDetail: [''],
      statusForNegativePressureTherapy: [''],
      VACId: [''],
      intensityOfVAC: ['', []],
      modeOfVAC: [''],
      VACOptions: [''],
      VACPressure: [''],
      VACVolumeForPrevious24Hours: [''],
      additionalDressingInformation: [''],
      // Health Promotion:
      healthPromotion: [''],
      chestSupport: [''],
      // Actions & investigatoins:
      investigations: [''],
      mostRecentBloodTest: [''],
      bloodResultWhiteBloodCellCount: [''],
      bloodResultCReactiveProtein: [''],
      bloodResultHaemaglobin: [''],
      bloodResultIron: [''],
      bloodResultNotes: [''],
      previousSwabs: [''],
      specimenType: [''],
      mostRecentSwabDate: [''],
      previousSwabResult: [''],

      // Planning & referrals
      followUpPlan: [''],
      dateOfNextReview: [''],
      referrals: [''],

      // Sepsis assessment:
      isThisSepsis: [''],
      oneRedFlagPresent: [''],
      sepsisComments: [''],
    });

    this.canvas = new fabric.Canvas('sheet2');
    this.canvas.isDrawingMode = true;
    this.canvas.freeDrawingBrush.width = 2;
    this.canvas.freeDrawingBrush.color = 'red';
    this.setCanvasImage();

    this.form.retreive$.pipe(untilDestroyed(this)).subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.entryForm.controls[key])
          this.entryForm.controls[key].setValue(formData[key]);
      });
    });

    const wards$ = this.appointmentsService.getAppointmentWards$(
      this.patientId
    );

    wards$.pipe(untilDestroyed(this)).subscribe((wards) => {
      if (wards[0]) {
        this.entryForm?.patchValue({ currentLocation: wards[0] });
      }
    });

    this.entryForm?.patchValue({
      formSelectiors: [
        'Basic wound assessment',
        'Dressings and Actions',
        'Health promotion',
        'Actions & investigations',
        'Planning & referrals',
        'Sepsis assessment',
      ],
    });

    // // ONLY PERFORM THIS IF WE'RE RBHT AND DETECTING BLUR
    this.usersService.myOrganisationId$.subscribe(async (id) => {
      this.orgId = id;

      if (id === 'Psg9GN32OTRyieZLRbx1') {
        this.sleep(6000); // this is roughly how long it takes to get a Blur Score
        this.setBlurDetectionComplete(false);
      } else {
        this.setBlurDetectionComplete(false);
      }
    });
  }

  get responsibleClinician() {
    return this.entryForm.get('responsibleClinician');
  }

  get bodyLocation() {
    return this.entryForm.get('bodyLocation');
  }

  get formSelectiors() {
    return this.entryForm.get('formSelectiors');
  }

  sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  isValid(): boolean {
    return !(
      this.entryForm.controls &&
      !!this.entryForm.controls['bodyLocation'].errors
    );
  }

  onSubmit() {
    this.isSubmitting = true;
    const formResponse = {
      ...this.entryForm.value,
      bodyMapDataUrl: this.canvas.toDataURL(),
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  setBlurDetectionComplete(status: boolean) {
    this.isDetectingBlur = status;
  }

  setSensitiveImage(status: boolean) {
    if (typeof status === 'string') {
      this.form.setSensitiveImage(false);
      this.isSensitive = false;
    } else if (status || status === false) {
      this.form.setSensitiveImage(status);
      this.isSensitive = status;
    } else {
      this.isSensitive = !this.isSensitive;
      this.form.setSensitiveImage(this.isSensitive);
    }
  }

  setCanvasImage() {
    this.canvas.setBackgroundColor(
      {
        source:
          'https://firebasestorage.googleapis.com/v0/b/portal-dev-e4b9a.appspot.com/o/bodyMap.png?alt=media&token=51f6ed16-a86c-4c35-a65c-bed540e5705e',
      },
      () => this.canvas.renderAll()
    );
  }

  clearMarking(event) {
    event.preventDefault();
    this.canvas.clear();
    this.setCanvasImage();
  }
}
