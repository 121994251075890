import { Injectable } from '@angular/core';
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from '@angular/fire/compat/storage';
import { FileUploadService } from '@ic-monorepo/shared-common';
import { Observable, catchError, concatMap, from, map, switchMap } from 'rxjs';

export interface StoredFile {
  name: string;
  file: Blob | File;
  fileType: string;
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class BucketStorageService {
  constructor(
    private storage: AngularFireStorage,
    private fileUploadService: FileUploadService
  ) {}

  create$(
    storagePath: string,
    file: Blob | File
  ): Observable<AngularFireUploadTask> {
    const ref = this.storage.ref(storagePath);
    const uploadTask = ref.put(file);

    this.fileUploadService.addToUploadList(
      uploadTask,
      file instanceof File ? file.name : null
    );

    // Return download URL once upload is complete
    return uploadTask.snapshotChanges().pipe(
      switchMap(() => uploadTask.then()),
      switchMap(() => ref.getDownloadURL()),
      catchError((error) => {
        console.error('Error uploading file:', error);
        throw error;
      })
    );
  }

  getFileUrl$(storagePath: string): Observable<string> {
    return this.storage.ref(storagePath).getDownloadURL();
  }

  delete$(storagePath: string) {
    const ref = this.storage.ref(storagePath);
    return from(ref.delete());
  }

  getStoredFile$(storagePath: string): Observable<StoredFile> {
    const ref = this.storage.ref(storagePath);

    return ref.getMetadata().pipe(
      concatMap((metadata) =>
        ref.getDownloadURL().pipe(
          concatMap((url) =>
            from(fetch(url)).pipe(
              concatMap((response) => response.blob()),
              map((blob) => ({
                url,
                fileType: metadata.contentType,
                name: metadata.name,
                file: this.blobToFile(blob, metadata.name),
              }))
            )
          )
        )
      )
    );
  }

  private blobToFile(blob: Blob, filename: string): File {
    const file = new File([blob], filename, { type: blob.type });
    return file;
  }
}
