import { Component, OnInit } from '@angular/core';
import { SystemCheckService } from '@ic-monorepo/shared-common';
import { MediaCardViewType } from '@ic-monorepo/shared-submissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SubmissionFlowMedia } from '../../../feature-patient-submission/store/submission-flow.store';
import {
  ToastService,
  ToastType,
} from '../../../shared/services/toast/toast.service';
import { ClinicianSubmissionStore } from '../../store/clinician-submission.store';

@Component({
  selector: 'ic-clinician-submission-dialog-edit-item',
  templateUrl: './clinician-submission-dialog-edit-item.component.html',
})
export class ClinicianSubmissionDialogEditItemComponent implements OnInit {
  originalData: SubmissionFlowMedia = null;
  data: SubmissionFlowMedia = null;

  isMobile = this.systemCheckService.isMobile;

  get mediaCardViewType(): typeof MediaCardViewType {
    return MediaCardViewType;
  }

  constructor(
    private clinicianSubmissionStore: ClinicianSubmissionStore,
    private systemCheckService: SystemCheckService,
    private config: DynamicDialogConfig,
    private toastService: ToastService,
    private ref: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    this.originalData = this.config.data;
    this.data = { ...this.config.data };
  }

  removeItem(): void {
    this.clinicianSubmissionStore.removeAttachedFile(this.originalData);
    this.toastService.open(
      ToastType.Info,
      `${this.originalData.file?.name} deleted`
    );
    this.ref.close({ deleted: true });
  }

  closeDialog(updated: boolean): void {
    if (updated) {
      this.toastService.open(
        ToastType.Success,
        `${this.originalData.file?.name} updated`
      );
      Object.assign(this.originalData, this.data);
    }
    this.ref.close({ updated: updated });
  }
}
