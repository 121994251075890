import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

const { required } = Validators;

@Component({
  standalone: true,
  selector: 'ic-phototherapy-non-attendance-form',
  templateUrl: './phototherapy-non-attendance-form.component.html',
  styleUrls: ['./phototherapy-non-attendance-form.component.scss'],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class PhototherapyNonAttendanceFormComponent implements OnInit {
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  entryForm: UntypedFormGroup;
  isSubmitting = false;

  ngOnInit(): void {
    this.entryForm = this.fb.group({
      patientName: ['', [required]],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.entryForm.controls[key])
          this.entryForm.controls[key].setValue(formData[key]);
      });
    });
  }

  onSubmit() {
    if (!this.valid()) return;
    this.isSubmitting = true;
    const formResponse = {
      ...this.entryForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  valid(): boolean {
    return this.entryForm.dirty && this.entryForm.valid && !this.isSubmitting;
  }
}
