import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-msk-referral-form',
  templateUrl: './msk-referral-form.component.html',
  styleUrls: ['./msk-referral-form.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
})
export class MskReferralFormComponent implements OnInit {
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  mskReferralForm: UntypedFormGroup;
  isSubmitting = false;

  ngOnInit(): void {
    this.mskReferralForm = this.fb.group({
      historyOfUlcerationOrWound: ['', [Validators.required]],
      existingWoundOrUlcer: ['', [Validators.required]],
      riskFromDiabetesOrOtherComorbidities: ['', [Validators.required]],
      vascularInsufficiencyPvdOrPad: ['', [Validators.required]],
      peripheralOrMotorNeuropathy: ['', [Validators.required]],
      chronicKidney: ['', [Validators.required]],
      amputation: ['', [Validators.required]],
      anatomicalLocation: ['', []],
      footDeformity: ['', [Validators.required]],
      footDeformityType: ['', []],
      footDeformityUlcerationRisk: ['', []],
      increasedRiskFromExistingCallosity: ['', [Validators.required]],

      notes: ['', []],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.mskReferralForm.controls[key]) {
          this.mskReferralForm.controls[key].setValue(formData[key]);
        }
      });
    });
  }

  onSubmit() {
    this.isSubmitting = true;
    const formResponse = {
      ...this.mskReferralForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  valid(): boolean {
    return (
      this.mskReferralForm.dirty &&
      this.mskReferralForm.valid &&
      !this.isSubmitting
    );
  }

  resetDeformityTypeRisk() {
    this.mskReferralForm.get('footDeformityType').setValue('');
    this.mskReferralForm.get('footDeformityUlcerationRisk').setValue('');
  }
  resetAntomical() {
    this.mskReferralForm.get('anatomicalLocation').setValue('');
  }
}
