import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  standalone: true,
  selector: 'p-galleria'
})
export class GalleriaDirective implements AfterViewInit {
  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
  ) { }

  ngAfterViewInit() {
    const galleriaIndicators = this.el.nativeElement.querySelectorAll('button[tabIndex="-1"]');

    galleriaIndicators.forEach((indicator, index) => {
      const ariaLabel = `Item ${index + 1}`;
      this.renderer.setAttribute(indicator, 'aria-label', ariaLabel);
    });
  }
}
