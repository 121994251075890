<div class="flex flex-column align-items-center gap-3">
  <div class="border-circle el-bg-primary-subtle p-3">
    <i class="pi pi-cloud-upload text-5xl"></i>
  </div>

  <div class="flex flex-row">
    <button
      pButton
      (click)="triggerFileUpload()"
      aria-label="Add photos, videos or files"
      class="w-16rem white-space-nowrap"
      label="Add photos, videos or files"
      icon="pi pi-plus"
    ></button>
  </div>
</div>
<p class="text-center">Files must be less than 2 GB</p>
