import {CommonModule} from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {CaptureType} from '@islacare/ic-types';
import {FileTypeHelper} from '@utils/helpers';
import {SubmissionFlowStore} from 'apps/frontend/portal/src/app/feature-patient-submission/store/submission-flow.store';
import {WebcamInitError} from 'ngx-webcam';
import {PhotoCaptureComponent} from '../photo-capture/photo-capture.component';
import {SoundCaptureComponent} from '../sound-capture/sound-capture.component';
import {VideoCaptureComponent} from '../video-capture/video-capture.component';

@Component({
  standalone: true,
  selector: 'ic-capture-container',
  templateUrl: './capture-container.component.html',
  styleUrls: ['./capture-container.component.scss'],
  imports: [
    CommonModule,
    PhotoCaptureComponent,
    VideoCaptureComponent,
    SoundCaptureComponent,
  ],
})
export class CaptureContainerComponent
  extends FileTypeHelper
  implements OnChanges {
  @Output() capture = new EventEmitter<any>();
  @Input() efCaptureType: CaptureType;

  captureReady = false;

  constructor(private sfStore: SubmissionFlowStore) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type) {
      this.captureReady = false;
    }
  }

  setError(error: WebcamInitError): void {
    this.sfStore.setError(`Media capture error: ${error.message}`);
  }
}
