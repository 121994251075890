import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import {
  CaptureType,
  CollectionTemplateType,
  CollectionTemplateWithId,
  FormWithId,
  GroupedTemplatesWithTeamReference,
  MessageMedium,
  PatientWithId,
} from '@islacare/ic-types';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PrimeIcons } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map, share, tap } from 'rxjs/operators';
import { TeamsService } from '../../../services/teams/teams.service';
import { TemplateService } from '../../../services/template/template.service';
import { FormService } from '../../../shared/services/form/form.service';
import { PatientsService } from '../../../shared/services/patients/patients.service';
import { isLandlineNumber, phoneInvalid } from '../../../utils/phoneInvalid';

export interface ValidPatient extends PatientWithId {
  patient: PatientWithId;
  selectedRecipient?: string;
  nowValid?: boolean;
  isEditing?: boolean;
  templates$: Observable<GroupedTemplatesWithTeamReference[]>;
  forms$: Observable<FormWithId[]>;
  subtitle: string;
}

interface SelectedRecipientObj {
  key: string;
  value: string;
}

@UntilDestroy()
@Component({
  selector: 'ic-bulk-create-dialog',
  templateUrl: './bulk-create-dialog.component.html',
  styleUrls: ['./bulk-create-dialog.component.scss'],
})
export class BulkCreateDialogComponent implements OnInit {
  selectedTemplate: CollectionTemplateWithId;
  patients$: Observable<PatientWithId[]>;
  invalidPatients$: Observable<ValidPatient[]>;
  deceasedOrConsentWithdrawnPatients$: Observable<ValidPatient[]>;
  validPatients$: Observable<ValidPatient[]>;
  templates$: Observable<GroupedTemplatesWithTeamReference[]>;
  forms$: Observable<FormWithId[]>;
  minScheduleDate: Date = this.addDayToToday(1);
  MessageMedium = MessageMedium;
  scheduleForm = this.fb.group({
    scheduleTemplateId: [null],
    scheduleStartDate: [this.addDayToToday(1)],
  });
  deceasedPatients: ValidPatient[];
  consentWithdrawnPatients: ValidPatient[];
  hasDeceasedPatientsMessage: string;
  hasConsentWithdrawnPatientsMessage: string;

  patientIds: string[];
  selectedTemplateName: string;
  PrimeIcons = PrimeIcons;
  CaptureType = CaptureType;

  constructor(
    private patientsService: PatientsService,
    private formService: FormService,
    private templateService: TemplateService,
    private router: Router,
    private teamsService: TeamsService,
    private fb: UntypedFormBuilder,
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {}

  ngOnInit(): void {
    this.templates$ = this.templateService.myTemplates$?.pipe(
      map(groupedTemplate =>
        groupedTemplate
          .map(teamTemplates => {
            const templates = teamTemplates.templates.filter(
              template => template.type === CollectionTemplateType.DEFAULT,
            );

            if (!templates?.length) return;

            return {
              ...teamTemplates,
              templates,
            };
          })
          .filter(teamTemplates => !!teamTemplates),
      ),
    );

    this.forms$ = this.formService.myForms$;
    this.patientIds = this.config.data.patientIds;
  }

  goToEditTemplate(teamId: string, templateId: string): void {
    this.router.navigate([`/teams/${teamId}/templates/${templateId}`]);
    this.ref.close();
  }

  setTemplate(collectionTemplate: CollectionTemplateWithId): void {
    if (!collectionTemplate || (collectionTemplate as any) === 'None') {
      this.selectedTemplate = null;
      return;
    }
    this.selectedTemplateName = collectionTemplate.name;
    if (!collectionTemplate?.formIds?.length) {
      collectionTemplate.formIds = collectionTemplate.formId ? [collectionTemplate.formId] : ['default'];
    }

    if (!collectionTemplate?.alertEmail) collectionTemplate.alertEmail = null;
    if (!collectionTemplate?.smsBody) collectionTemplate.smsBody = null;

    this.listenForPatients(collectionTemplate);

    this.selectedTemplate = collectionTemplate;
    this.setScheduleTemplateId(collectionTemplate?.scheduleDetails?.templateId || null);
  }

  async onSubmit(selectedTemplate, patients: ValidPatient[]): Promise<void> {
    const orgId = (await this.teamsService.getTeamSnapshot(selectedTemplate.teamId)).data().organisationId;

    this.ref.close({
      selectedTemplate,
      patients: patients,
      organisationId: orgId,
      scheduleDetails: this.scheduleForm.value,
    });
  }

  openPatientDetails(patientId: string): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/patient/${patientId}/edit`], {
        queryParams: { closeOnSubmit: true },
      }),
    );
    window.open(url, '_blank');
  }

  getSelectedRecipient(selectedRecipient: SelectedRecipientObj): string {
    return selectedRecipient?.key;
  }

  phoneNumberValid(number: string): boolean {
    return !isLandlineNumber(number) && !phoneInvalid(number);
  }

  closeDialog(): void {
    this.ref.close();
  }

  getCaptureTypeIcon(captureType: string): string {
    const iconIndex = {
      [CaptureType.PHOTO]: PrimeIcons.CAMERA,
      [CaptureType.VIDEO]: PrimeIcons.VIDEO,
      [CaptureType.FORM]: PrimeIcons.FILE,
      [CaptureType.FILE_UPLOAD]: PrimeIcons.UPLOAD,
      [CaptureType.SOUND_RECORDING]: PrimeIcons.VOLUME_UP,
    };

    return iconIndex[captureType];
  }

  setScheduleTemplateId(scheduleTemplateId): void {
    this.scheduleForm.get('scheduleTemplateId').setValue(scheduleTemplateId);
  }

  private listenForPatients(collectionTemplate: CollectionTemplateWithId): void {
    if (!this.patientIds?.length) return;

    this.patients$ = combineLatest(this.patientIds.map(patientId => this.patientsService.getPatient$(patientId))).pipe(
      distinctUntilChanged(),
      share(),
    );

    // If the selected template has a schedule this will then sort the patients into valid/invalid arrays
    if (collectionTemplate?.scheduleDetails?.templateId) {
      this.deceasedOrConsentWithdrawnPatients$ = this.patients$.pipe(
        map((patients: ValidPatient[]) =>
          patients.filter((patient: ValidPatient) => patient.deceased || patient.patientContactConsentWithdrawn),
        ),
        tap(patients => this.generateWarningMessageBody(patients)),
      );
      this.invalidPatients$ = this.patients$.pipe(
        map((patients: ValidPatient[]) =>
          patients.filter(
            (patient: ValidPatient) =>
              (!patient.phone || !this.phoneNumberValid(patient.phone)) &&
              this.patientDeceasedAndConsentWithdrawnIsFalse(patient),
          ),
        ),
      );
      this.validPatients$ = this.patients$.pipe(
        map((patients: ValidPatient[]) =>
          patients.filter(
            (patient: ValidPatient) =>
              (this.phoneNumberValid(patient.phone) || patient?.nowValid) &&
              this.patientDeceasedAndConsentWithdrawnIsFalse(patient),
          ),
        ),
      );
      // If the selected template has no schedule (and patients do/don't have a number) then patients added to valid array
    } else {
      this.invalidPatients$ = of([]);
      this.validPatients$ = this.patients$.pipe(
        map((patients: ValidPatient[]) => patients.map(patient => ({ ...patient, selectedRecipient: '' }))),
      );
    }
  }

  private patientDeceasedAndConsentWithdrawnIsFalse(patient: ValidPatient) {
    return !patient?.deceased && !patient?.patientContactConsentWithdrawn;
  }

  private generateWarningMessageBody(patients: ValidPatient[]) {
    this.deceasedPatients = patients.filter(patient => patient.deceased);
    this.consentWithdrawnPatients = patients.filter(
      patient => patient.patientContactConsentWithdrawn && !patient.deceased,
    );
    let deceasedPatientNames = `${this.deceasedPatients[0]?.firstName} ${this.deceasedPatients[0]?.lastName}`;
    let consentWithdrawnPatientNames = `${this.consentWithdrawnPatients[0]?.firstName} ${this.consentWithdrawnPatients[0]?.lastName}`;

    if (this.deceasedPatients.length > 1) {
      deceasedPatientNames = this.configurePatientStrings(this.deceasedPatients);
    }

    if (this.consentWithdrawnPatients.length > 1) {
      consentWithdrawnPatientNames = this.configurePatientStrings(this.consentWithdrawnPatients);
    }

    this.hasDeceasedPatientsMessage = `${deceasedPatientNames} ${
      this.deceasedPatients.length > 1 ? 'are' : 'is'
    } marked as deceased. The collection selected will not be created for ${
      this.deceasedPatients.length > 1 ? 'these patients' : 'this patient'
    } as there are scheduled sms / email requests associated to this template.`;

    this.hasConsentWithdrawnPatientsMessage = `${consentWithdrawnPatientNames} ${
      this.consentWithdrawnPatients.length > 1 ? 'have' : 'has'
    } withdrawn consent to be contacted. The collection selected will not be created for ${
      this.consentWithdrawnPatients.length > 1 ? 'these patients' : 'this patient'
    } as there are scheduled sms / email requests associated to this template.`;
  }

  private configurePatientStrings(patients: ValidPatient[]) {
    const allPatients = patients.map(patient => `${patient.firstName} ${patient.lastName}`);
    const lastPatient = [...allPatients].pop();
    const initialPatients = allPatients.splice(0, allPatients.length - 1);
    return `${initialPatients.join(', ')} and ${lastPatient}`;
  }

  private addDayToToday(numberOfDaysToAdd = 0): Date {
    const date = new Date();
    date.setDate(date.getDate() + numberOfDaysToAdd);
    return date;
  }
}
