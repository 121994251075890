<form [formGroup]="gad7Form" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <div class="full-width-field" style="margin: auto">
      <h3>The Generalized Anxiety Disorder 7-item (GAD-7) Scale</h3>
    </div>
    <div class="full-width-field" style="margin: auto">
      <p>
        Over the <u><strong>last 2 weeks</strong></u
        >, how often have you been bothered by the following problems?
      </p>
    </div>

    <br />

    <!-- QUESTION 1 -->
    <div class="question-container">
      <div class="half-width-field">
        <p>Feeling nervous, anxious, or on edge</p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="feelingNervousAnxiousOrOnEdge">
          <mat-option *ngFor="let option of answerList" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="feelingNervousAnxiousOrOnEdge.touched">Please select an option</mat-error>
      </mat-form-field>
    </div>

    <!-- QUESTION 2 -->
    <div class="question-container">
      <div class="half-width-field">
        <p>Not being able to stop or control worrying</p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="notBeingAbleToStopOrControlWorrying">
          <mat-option *ngFor="let option of answerList" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="notBeingAbleToStopOrControlWorrying.touched"
          >Please select an option</mat-error
        >
      </mat-form-field>
    </div>

    <!-- QUESTION 3 -->
    <div class="question-container">
      <div class="half-width-field">
        <p>Worrying too much about different things</p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="worryingTooMuchAboutDifferentThings">
          <mat-option *ngFor="let option of answerList" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="worryingTooMuchAboutDifferentThings.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <!-- QUESTION 4 -->
    <div class="question-container">
      <div class="half-width-field">
        <p>Trouble relaxing</p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="troubleRelaxing">
          <mat-option *ngFor="let option of answerList" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="troubleRelaxing.touched"> Please select an option </mat-error>
      </mat-form-field>
    </div>

    <!-- QUESTION 5 -->
    <div class="question-container">
      <div class="half-width-field">
        <p>Being so restless that it is hard to sit still</p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="beingSoRestlessThatItIsHardToSitStill">
          <mat-option *ngFor="let option of answerList" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="beingSoRestlessThatItIsHardToSitStill.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <!-- QUESTION 6 -->
    <div class="question-container">
      <div class="half-width-field">
        <p>Becoming easily annoyed or irritable</p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="becomingEasilyAnnoyedOrIrritable">
          <mat-option *ngFor="let option of answerList" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="becomingEasilyAnnoyedOrIrritable.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <!-- QUESTION 7 -->
    <div class="question-container">
      <div class="half-width-field">
        <p>Feeling afraid as if something awful might happen</p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="feelingAfraidAsIfSomethingAwfulMightHappen">
          <mat-option *ngFor="let option of answerList" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="feelingAfraidAsIfSomethingAwfulMightHappen.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <!-- CONDITIONAL QUESTION -->
    <div class="question-container">
      <div class="half-width-field">
        <p>
          If you checked off <u>any</u> problems, how <u>difficult</u> have these problems made it
          for you to do your work, take care of things at home, or get along with others?
        </p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="howDifficultHaveTheseProblemsMadeThings">
          <mat-option value="Not difficult at all">Not difficult at all</mat-option>
          <mat-option value="Somewhat difficult">Somewhat difficult</mat-option>
          <mat-option value="Very difficult">Very difficult</mat-option>
          <mat-option value="Extremely difficult">Extremely difficult</mat-option>
        </mat-select>
        <mat-error *ngIf="howDifficultHaveTheseProblemsMadeThings.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>
    <div class="button-group">
      <button
        class="positive-button"
        mat-raised-button
        color="accent"
        type="submit"
        [disabled]="!gad7FormValid()"
      >
        {{ isSubmitting ? 'Submitting...' : 'Submit' }}
      </button>
    </div>
  </div>
</form>
