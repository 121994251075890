import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'ic-invalid-contacts-request-dialog',
  templateUrl: './invalid-contacts-request-dialog.component.html'
})
export class InvalidContactsRequestDialogComponent {
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  onNoClick(): void {
    this.ref.close();
  }

  onSubmit(): void {
    this.ref.close('edit-patient');
  }
}
