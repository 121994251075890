import { Inject, Injectable } from '@angular/core';
import { UsersService } from '@ic-monorepo/services';
import { WINDOW } from '@ic-monorepo/shared-common';
import { Intercom, IntercomConfig } from '@supy-io/ngx-intercom';
import { switchMap, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { OrganisationsService } from '../organisations/organisations.service';

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  constructor(
    private intercom: Intercom,
    private users: UsersService,
    private organisationsService: OrganisationsService,
    @Inject(WINDOW) private window: Window
  ) {}

  async initialiseIntercom() {
    const user = await this.users.me();

    if (user) {
      const organisation = await this.users.myOrganisationId$
        .pipe(
          switchMap((orgId) =>
            this.organisationsService.getOrganisation$(orgId)
          )
        )
        .pipe(take(1))
        .toPromise();

      this.intercom.boot({
        widget: {
          activator: '#intercom',
        },
        user_id: user.id,
        name: user.firstName,
        email: user.email,
        company: {
          company_id: user.organisationId,
          name: organisation?.name,
        },
        appVersion: environment.version,
      } as IntercomConfig);
    } else {
      this.intercom.boot({
        widget: {
          activator: '#intercom',
        },
        appVersion: environment.version,
      } as IntercomConfig);
    }
  }

  setIntercomProperties(
    props: {
      islaPatientId?: string;
      islaCollectionId?: string;
      islaCollectionAuthId?: string;
      islaRequestId?: string;
    } = {}
  ) {
    this.intercom.boot({
      custom_launcher_selector: '#intercom',
      widget: {
        activator: '#intercom',
      },
      appVersion: environment.version,
      ...props,
    } as IntercomConfig);
  }

  openIntercom(): void {
    this.intercom.show();
  }

  hideIntercom(): void {
    this.intercom.hide();
  }

  shutdownIntercom() {
    this.intercom.shutdown();
  }

  openArticle(articleNumber: number) {
    this.window['Intercom']('showArticle', articleNumber);
  }

  get isAvailable(): boolean {
    return !!environment?.intercomAppId;
  }
}
