<form [formGroup]="patientSpecificFunctionalScaleForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <mat-card class="form-card">
      <mat-card-title>Patient-specific Functional Scale</mat-card-title>
      <mat-card-content>
        <mat-radio-group
          class="full-width-field"
          appearance="fill"
          formControlName="assessmentType"
          (change)="resetBaselineField($event)"
        >
          <mat-radio-button value="Baseline Assessment"
            >Baseline Assessment</mat-radio-button
          >
          <mat-radio-button value="Follow-up Assessment"
            >Follow-up Assessment</mat-radio-button
          >
        </mat-radio-group>
        <p>
          Identify up to 3 important activities that you are unable to do or
          have difficulty with as a result of your problem.
        </p>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card class="form-card">
      <mat-card-title>Activity 1</mat-card-title>
      <mat-card-content>
        <mat-form-field
          appearance="fill"
          required
          class="notes-field half-width-field"
        >
          <input
            matInput
            formControlName="activityOneName"
            type="notes"
            placeholder="Activity 1 Name"
            autocomplete="off"
          />
        </mat-form-field>
        <br />
        <p><strong>Score of activity performed</strong></p>
        <div class="slider-full-width-field verti-center slider-wrapper">
          <div class="slider-label">
            <div>0</div>
            <div>Unable</div>
          </div>
          <mat-slider
            class="slider-width"
            formControlName="activityOneScore"
            thumbLabel
            tickInterval="1"
            min="0"
            max="10"
          >
          </mat-slider>
          <div class="slider-label">
            <div>10</div>
            <div>Able</div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card class="form-card">
      <mat-card-title>Activity 2</mat-card-title>
      <p>Please leave blank if it doesn't apply to you</p>
      <mat-card-content>
        <div>
          <mat-form-field
            appearance="fill"
            required
            class="notes-field half-width-field"
          >
            <input
              matInput
              formControlName="activityTwoName"
              type="notes"
              placeholder="Activity 2"
              autocomplete="off"
            />
          </mat-form-field>
          <br />
          <p><strong>Score of activity performed</strong></p>
          <div class="slider-full-width-field verti-center slider-wrapper">
            <div class="slider-label">
              <div>0</div>
              <div>Unable</div>
            </div>
            <mat-slider
              class="slider-width"
              formControlName="activityTwoScore"
              thumbLabel
              tickInterval="1"
              min="0"
              max="10"
            >
            </mat-slider>
            <div class="slider-label">
              <div>10</div>
              <div>Able</div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card class="form-card">
      <mat-card-title>Activity 3</mat-card-title>
      <p>Please leave blank if it doesn't apply to you</p>
      <mat-card-content>
        <div>
          <mat-form-field
            appearance="fill"
            required
            class="notes-field half-width-field"
          >
            <input
              matInput
              formControlName="activityThreeName"
              type="notes"
              placeholder="Activity 3"
              autocomplete="off"
            />
          </mat-form-field>
          <br />
          <p><strong>Score of activity performed</strong></p>
          <div class="slider-full-width-field verti-center slider-wrapper">
            <div class="slider-label">
              <div>0</div>
              <div>Unable</div>
            </div>
            <mat-slider
              class="slider-width"
              formControlName="activityThreeScore"
              thumbLabel
              tickInterval="1"
              min="0"
              max="10"
            >
            </mat-slider>
            <div class="slider-label">
              <div>10</div>
              <div>Able</div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card class="form-card">
      <mat-card-title *ngIf="assessmentType.value === 'Baseline Assessment'"
        >Scores</mat-card-title
      >
      <mat-card-title *ngIf="assessmentType.value === 'Follow-up Assessment'"
        >PSFS Calculation</mat-card-title
      >
      <p>This section contains your PSFS scores based on your inputs</p>
      <mat-card-content>
        <div *ngIf="assessmentType.value === 'Follow-up Assessment'">
          <p>Please input your baseline score:</p>
          <mat-form-field
            appearance="fill"
            required
            class="notes-field half-width-field"
          >
            <input
              matInput
              formControlName="baselineScore"
              type="number"
              placeholder="Baseline Score"
              autocomplete="off"
            />
          </mat-form-field>
        </div>
        <table id="scoreOutputTable">
          <tr>
            <th>Best Available Score</th>
            <th *ngIf="assessmentType.value === 'Baseline Assessment'">
              Initial Score:
            </th>
            <th *ngIf="assessmentType.value === 'Follow-up Assessment'">
              PSFS Score:
            </th>
          </tr>
          <tr>
            <td>{{ bestAvailableScore }}</td>
            <td *ngIf="assessmentType.value === 'Baseline Assessment'">
              {{ initalScore }}
            </td>
            <td *ngIf="assessmentType.value === 'Follow-up Assessment'">
              {{ psfsScore }}
            </td>
          </tr>
        </table>
      </mat-card-content>
    </mat-card>
    <br />
    <div>
      <button
        mat-raised-button
        class="positive-button"
        type="submit"
        [disabled]="patientSpecificFunctionalScaleForm.invalid || isSubmitting"
        (click)="onSubmit()"
      >
        {{ isSubmitting ? 'Submitting...' : 'Submit' }}
      </button>
    </div>
  </div>
</form>
