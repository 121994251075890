export default [
  'age',
  'sex',
  'satisfiedWithAppointment',
  'treatmentMechanism',
  'satisfiedWithTreatment',
  'wouldUseServiceAgain',
  'clinicStyle',
  'notes'
];
