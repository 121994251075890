<div class="p-dialog-header px-0">
  <span *ngIf="data.file as file" class="p-dialog-title text-overflow-ellipsis overflow-hidden">{{
    file.name
  }}</span>
  <div class="p-dialoger-header-icons">
    <button
      (click)="closeDialog(false)"
      class="p-dialog-header-icon p-dialog-header-close p-button-link"
      aria-label="Close dialog"
      icon="pi pi-times"
      pButton
    ></button>
  </div>
</div>

<div class="flex flex-column gap-3">
  <ic-captured-media-card
    [viewType]="mediaCardViewType.PREVIEW"
    [media]="data"
  ></ic-captured-media-card>

  <div class="flex flex-row justify-content-center gap-2">
    <button
      *ngIf="isMobile"
      (click)="removeItem()"
      class="p-button-outlined p-button-danger w-full"
      pTooltip="Delete"
      type="button"
      pButton
    >
      Delete
    </button>
    <button (click)="closeDialog(true)" class="w-full" pTooltip="Confirm" type="button" pButton>
      Confirm
    </button>
  </div>
</div>
