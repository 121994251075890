<form [formGroup]="entryForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <mat-form-field appearance="fill" class="half-width-field">
      <mat-label>For the attention of:</mat-label>
      <mat-select
        data-cy="for-the-attention-of"
        formControlName="forTheAttentionOf"
      >
        <mat-option value="Dr Singhal">Dr Singhal</mat-option>
        <mat-option data-cy="attention-of-select" value="Dr O’Donoghue"
          >Dr O’Donoghue</mat-option
        >
        <mat-option value="Dr Giavasi">Dr Giavasi</mat-option>
        <mat-option value="Dr Lim">Dr Lim</mat-option>
        <mat-option value="Sarah Pashley">Sarah Pashley</mat-option>
        <mat-option value="Lisa Flinton">Lisa Flinton</mat-option>
        <mat-option value="Catie Picton">Catie Picton</mat-option>
        <mat-option value="Emma Lewington">Emma Lewington</mat-option>
        <mat-option value="Sophie Coleman">Sophie Coleman</mat-option>
        <mat-option value="Claire Hudson">Claire Hudson</mat-option>
      </mat-select>
      <mat-error *ngIf="forTheAttentionOf.invalid && forTheAttentionOf.touched">
        Please select a clinician
      </mat-error>
    </mat-form-field>
    <br />

    <!-- <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Notes</mat-label>
        <textarea matInput formControlName = "clinicianNotes" ></textarea>
      </mat-form-field> -->

    <mat-form-field appearance="fill" class="notes-field half-width-field">
      <input
        matInput
        formControlName="notes"
        type="notes"
        placeholder="Comments"
        autocomplete="off"
        data-cy="neurology-comments"
      />
      <mat-error *ngIf="notes.invalid && notes.touched">
        Please enter a maximum of 400 characters
      </mat-error>
    </mat-form-field>
    <br />

    <button
      mat-raised-button
      class="positive-button"
      data-cy="submit-button-neurology-form"
      type="submit"
      [disabled]="isSubmitting"
    >
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
