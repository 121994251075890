import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-pain-dn4-form.component',
  templateUrl: './pain-dn4-form.component.html',
  styleUrls: ['./pain-dn4-form.component.scss'],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class Dn4FormComponent implements OnInit {
  @Input() data;
  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  dn4ScaleForm: UntypedFormGroup;
  isSubmitting = false;
  isSensitive: boolean;
  isLinear = true;
  low = true;
  medium = false;
  high = false;
  sliderMin = 0;
  sliderMax = 10;
  step = '1';
  tickInterval = '1';

  ngOnInit(): void {
    this.dn4ScaleForm = this.fb.group({
      painFeelLikeBurning: ['', Validators.required],
      painFeelLikePainfulCold: ['', Validators.required],
      painFeelLikeElectricShocks: ['', Validators.required],
      painFeelLikeTingling: ['', Validators.required],
      painFeelLikePinsAndNeedles: ['', Validators.required],
      painFeelLikeNumbness: ['', Validators.required],
      painFeelLikeItching: ['', Validators.required],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.dn4ScaleForm.controls[key])
          this.dn4ScaleForm.controls[key].setValue(formData[key]);
      });
    });
  }

  // dn4
  get painFeelLikeBurning() {
    return this.dn4ScaleForm.get('painFeelLikeBurning');
  }

  get painFeelLikePainfulCold() {
    return this.dn4ScaleForm.get('painFeelLikePainfulCold');
  }

  get painFeelLikeElectricShocks() {
    return this.dn4ScaleForm.get('painFeelLikeElectricShocks');
  }

  get painFeelLikeTingling() {
    return this.dn4ScaleForm.get('painFeelLikeTingling');
  }

  get painFeelLikePinsAndNeedles() {
    return this.dn4ScaleForm.get('painFeelLikePinsAndNeedles');
  }

  get painFeelLikeNumbness() {
    return this.dn4ScaleForm.get('painFeelLikeNumbness');
  }

  get painFeelLikeItching() {
    return this.dn4ScaleForm.get('painFeelLikeItching');
  }

  onSubmit() {
    this.isSubmitting = true;
    const formResponse = {
      ...this.dn4ScaleForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  dn4ScaleFormValid(): boolean {
    return (
      this.dn4ScaleForm.dirty &&
      !(
        this.dn4ScaleForm.controls &&
        this.dn4ScaleForm.controls['painFeelLikeBurning'].errors
      ) &&
      !(
        this.dn4ScaleForm.controls &&
        this.dn4ScaleForm.controls['painFeelLikePainfulCold'].errors
      ) &&
      !(
        this.dn4ScaleForm.controls &&
        this.dn4ScaleForm.controls['painFeelLikeElectricShocks'].errors
      ) &&
      !(
        this.dn4ScaleForm.controls &&
        this.dn4ScaleForm.controls['painFeelLikeTingling'].errors
      ) &&
      !(
        this.dn4ScaleForm.controls &&
        this.dn4ScaleForm.controls['painFeelLikePinsAndNeedles'].errors
      ) &&
      !(
        this.dn4ScaleForm.controls &&
        this.dn4ScaleForm.controls['painFeelLikeNumbness'].errors
      ) &&
      !(
        this.dn4ScaleForm.controls &&
        this.dn4ScaleForm.controls['painFeelLikeItching'].errors
      )
    );
  }
}
