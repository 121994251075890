<div class="flex flex-column gap-2">
  <video class="w-full" controls playsinline #videoElement></video>
  <div *ngIf="captureReady" class="flex flex-row gap-2">
    <div class="flex flex-grow-1">
      <button
        *ngIf="!videoElement.hasAttribute('src')"
        [class]="recording ? 'w-full p-button-outlined' : 'w-full'"
        [label]="recording ? 'Stop recording' : 'Start recording'"
        [icon]="recording ? 'pi pi-stop-circle' : 'pi pi-circle'"
        (click)="recording ? stopRecording() : startRecording()"
        aria-label="Start recording"
        data-cy="capture-video-button"
        type="button"
        pButton
      ></button>
      <button
        *ngIf="videoElement.hasAttribute('src')"
        (click)="captureMedia()"
        class="p-button-success w-full"
        label="Done"
        icon="pi pi-check"
        type="button"
        aria-label="Done"
        data-cy="confirm-capture-video-button"
        pButton
      ></button>
    </div>
    <button
      (click)="clearRecording()"
      [disabled]="!videoElement.hasAttribute('src')"
      [class.pointer-events-none]="!videoElement.hasAttribute('src')"
      class="p-button-danger p-button-outlined"
      icEventTracker="sf-stops-video"
      aria-label="Clear recording"
      icon="pi pi-trash"
      pButton
    ></button>
  </div>
</div>
