<form [formGroup]="entryForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <mat-card>
      <mat-card-title
        >Declaration for female patients opting NOT to follow the Roche®
        Pregnancy Prevention Plan</mat-card-title
      >
      <mat-card-content>
        <ul>
          <li>
            I have received the information about the risks of having an
            affected baby if I should become pregnant whilst taking
            Isotretinoin.
          </li>
          <li>
            I am aware that this risk to a pregnancy persists throughout the
            duration of the treatment with Isotretinoin and during the month
            after finishing treatment.
          </li>
          <li>I believe that I am not pregnant at the moment.</li>
          <li>
            I believe that I am not at risk of becoming pregnant during the
            course of treatment with Isotretinoin or in the month following
            treatment.
          </li>
          <li>
            I have discussed with my Dermatologist the risks to a pregnancy
            during treatment with Isotretinoin and accept these risks if I take
            Isotretinoin.
          </li>
          <li>
            I will continue using contraception (contraceptive pill, implant,
            coil) for the duration of treatment and at least 5 weeks after.
            Additional barrier contraception (condoms) is recommended.
          </li>
          <li>
            I will not be attending the hospital for monthly pregnancy tests.
          </li>
          <li>
            I understand that urine pregnancy tests may give false negative
            results.
          </li>
          <li>
            If I become pregnant whilst taking Isotretinoin or in the month
            after treatment, I will inform and seek advice from my consultant.
          </li>
        </ul>
      </mat-card-content>
    </mat-card>

    <mat-form-field
      appearance="fill"
      required
      class="notes-field half-width-field"
    >
      <mat-label>Your name</mat-label>
      <input
        matInput
        formControlName="patientName"
        type="notes"
        placeholder="Patient name"
        autocomplete="off"
      />
    </mat-form-field>
    <br />
    <mat-form-field
      appearance="fill"
      required
      class="notes-field half-width-field"
    >
      <mat-label>Your clinician's name</mat-label>
      <input
        matInput
        formControlName="consultantName"
        type="notes"
        placeholder="Consultant Dermatologist name"
        autocomplete="off"
      />
    </mat-form-field>
    <br />

    <button
      mat-raised-button
      class="positive-button"
      type="submit"
      [disabled]="!valid()"
    >
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
