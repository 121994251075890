import { CaptureType } from '@islacare/ic-types';

export class FileTypeHelper {
  maxClinicianSizeInBytes = 2 * 1024 * 1024 * 1024; // 2GB in bytes
  maxPatientSizeInBytes = 500 * 1024 * 1024; // 500MB in bytes

  get captureType(): typeof CaptureType {
    return CaptureType;
  }

  acceptableFileTypes(type: CaptureType): string[] {
    switch (type) {
      case CaptureType.PHOTO:
        return ['image/jpeg', 'image/png', 'image/jpg', 'image/tif', 'image/bmp'];
      case CaptureType.VIDEO:
        return ['video/quicktime', 'video/webm', 'video/mp4', 'video/avi'];
      case CaptureType.SOUND_RECORDING:
        return ['audio/mp3', 'audio/wav'];
      case CaptureType.FORM:
      default:
        return [];
    }
  }

  allAcceptableFileTypes(): string[] {
    const allFileTypes: string[] = [
      'image/png',
      'image/jpeg',
      'image/jpg',
      'image/tif',
      'image/bmp',
      'video/mp4',
      'video/quicktime',
      'video/avi',
      'video/webm',
      'audio/mp3',
      'audio/wav',
      'application/pdf',
      'file/e2e',
      '.e2e',
      'text/csv',
    ];

    return allFileTypes;
  }

  allAcceptableFileTypesMin(): string[] {
    const allFileTypes: string[] = [
      'png',
      'jpeg',
      'jpg',
      'tif',
      'bmp',
      'mp4',
      'quicktime',
      'avi',
      'webm',
      'mp3',
      'wav',
      'pdf',
      'e2e',
      '.e2e',
    ];

    return allFileTypes;
  }

  getCaptureTypeFriendly(type: CaptureType): string {
    switch (type) {
      case CaptureType.PHOTO:
        return 'photo';
      case CaptureType.VIDEO:
        return 'video';
      case CaptureType.SOUND_RECORDING:
        return 'sound recording';
      case CaptureType.FORM:
        return 'form';
      case CaptureType.FILE_UPLOAD:
      default:
        return 'file upload';
    }
  }

  getCaptureTypeForFileTypePath(fileType: string): CaptureType {
    switch (fileType) {
      case 'image/png':
      case 'image/jpeg':
      case 'image/jpg':
      case 'image/tif':
      case 'image/bmp':
        return CaptureType.PHOTO;
      case 'video/mp4':
      case 'video/quicktime':
      case 'video/avi':
      case 'video/webm':
      case 'video/webm;codecs=vp8,opus':
        return CaptureType.VIDEO;
      case 'audio/mp3':
      case 'audio/wav':
        return CaptureType.SOUND_RECORDING;
      default:
        return CaptureType.FILE_UPLOAD;
    }
  }

  getCaptureTypeFromFileType(fileType: string): CaptureType {
    switch (fileType) {
      case 'png':
      case 'jpeg':
      case 'jpg':
      case 'tif':
      case 'bmp':
        return CaptureType.PHOTO;
      case 'mp4':
      case 'quicktime':
      case 'avi':
      case 'webm':
      case 'webm;codecs=vp8,opus':
        return CaptureType.VIDEO;
      case 'mp3':
      case 'wav':
        return CaptureType.SOUND_RECORDING;
      default:
        return CaptureType.FILE_UPLOAD;
    }
  }

  fileTypeIsPdf(fileType: string): boolean {
    return fileType === 'application/pdf';
  }
}
