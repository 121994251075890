import { Injectable } from '@angular/core';
import { CollectionAuth, MessageMedium, MessageType } from '@islacare/ic-types';
import { environment } from 'apps/frontend/portal/src/environments/environment';
import firebase from 'firebase/compat/app';
import { DialogService } from 'primeng/dynamicdialog';
import { AuditService } from '../../../services/audit/audit.service';
import { CollectionAuthsService } from '../../../services/collectionsAuths/collectionAuths.service';
import { EmailRequestDialogComponent } from '../../../shared/components';
import { LoggingService } from '../../../shared/services/logging/logging.service';
import { PatientsService } from '../../../shared/services/patients/patients.service';
import {
  ToastService,
  ToastType
} from '../../../shared/services/toast/toast.service';

export interface PartialPatientDetails {
  patientId: string;
  patientFirstName: string;
  patientPhone: string;
  additionalPhoneNumbers: any;
  additionalEmailAddresses: any;
  patientYearOfBirth: string;
  patientEmail: string;
}

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  constructor(
    private logging: LoggingService,
    public dialog: DialogService,
    private audit: AuditService,
    private collectionAuthsService: CollectionAuthsService,
    private patientsService: PatientsService,
    private dialogService: DialogService,
    private toastService: ToastService
  ) { }

  async openEmailDialog(
    collectionAuthUrl: string,
    collectionAuthId: string,
    requestDialogResult,
    collectionId: string,
    loadingRef,
    patientDetails: PartialPatientDetails,
    createdBy: string,
    createdForOrg: string,
    captureType: string,
    requestId: string,
    messageType?: MessageType,
    isMediaOptional?: boolean
  ): Promise<void> {
    const patientId = patientDetails.patientId;
    const patientFirstName = patientDetails.patientFirstName;
    const patientEmail = patientDetails.patientEmail;

    const patientAdditionalEmailAddresses =
      this.patientsService.getPatientEmails(
        patientDetails.additionalEmailAddresses,
        patientEmail
      );

    loadingRef?.close();

    const dialogRef = this.dialogService.open(EmailRequestDialogComponent, {
      width: '80%',
      header: 'Email sending confirmation',
      data: {
        message: collectionAuthUrl,
        email: patientEmail,
        patientFirstName: patientFirstName,
        patientId,
        collectionId,
        messageType,
        preSetRecipient: requestDialogResult.recipient,
        keepLinkSevenDays: requestDialogResult.keepLinkSevenDays,
        additionalEmailAddresses: patientAdditionalEmailAddresses,
        smsIntro: this.patientsService.getPatientNumbersAndSmsIntros(
          patientDetails.additionalPhoneNumbers,
          patientFirstName,
          patientDetails.patientPhone
        )?.smsIntro,
        hideReminderCheckbox: requestDialogResult.hideReminderCheckbox,
        isMediaOptional
      }
    });
    if (requestDialogResult?.messageType !== MessageType.MANUAL_REMINDER) {
      await this.createCollectionAuth({
        collectionAuthUrl,
        collectionAuthId,
        collectionId,
        requestDialogResult,
        patientDetails,
        createdBy,
        createdForOrg,
        captureType,
        requestId,
        isMediaOptional
      });
    }

    dialogRef.onClose.subscribe(async result => {
      if (result) {
        await this.updateCollectionAuthAndSendPatientMessage({
          requestDialogResult,
          result,
          patientId,
          collectionId,
          collectionAuthId,
          createdBy
        });
      } else {
        if (requestDialogResult?.messageType !== MessageType.MANUAL_REMINDER)
          this.cancelEmailSubmission(collectionId, collectionAuthId, patientId);
        await this.audit.authTokenCancel(patientId);
      }
    });
  }

  async cancelEmailSubmission(
    collectionId: string,
    collectionAuthId: string,
    patientId: string
  ): Promise<void> {
    // Mark collectionAuth with cancelled = true, active = false
    this.collectionAuthsService.cancelAuth(
      patientId,
      collectionId,
      collectionAuthId
    );
    const message = 'Request not sent';
    this.toastService.open(ToastType.Error, message);
  }

  async createCollectionAuth({
    collectionAuthUrl,
    collectionAuthId,
    collectionId,
    requestDialogResult,
    patientDetails,
    createdBy,
    createdForOrg,
    captureType,
    requestId,
    isMediaOptional
  }): Promise<void> {
    await this.collectionAuthsService.createPatientCollectionAuth(
      collectionAuthUrl,
      collectionAuthId,
      collectionId,
      requestDialogResult,
      patientDetails,
      createdBy,
      createdForOrg,
      captureType,
      requestId,
      isMediaOptional
    );
  }

  async updateCollectionAuthAndSendPatientMessage({
    requestDialogResult,
    result,
    patientId,
    collectionId,
    collectionAuthId,
    createdBy
  }): Promise<void> {
    const message = 'Sending Email...';
    this.toastService.open(ToastType.Info, message);

    if (requestDialogResult?.messageType !== MessageType.MANUAL_REMINDER) {
      const collectionAuthUpdates: Partial<CollectionAuth> = {
        smsText: result.emailText,
        email: result.recipientEmail,
        emailSend: true,
        manualEmailRequest: true, //need this so it doesn't trigger send sms for updated auth
        messageMedium: MessageMedium.EMAIL,
        reminder: result.reminder,
        recipient: result.emailRecipientName || null
      };
      const successfulMessage = 'Email successfully sent';
      const unsuccessfulMessage = 'Something went wrong. Email was not sent.';
      try {
        await this.collectionAuthsService.updateCollectionAuth(
          patientId,
          collectionId,
          collectionAuthId,
          collectionAuthUpdates
        );
        const sendPatientMessageComplete = await this.sendPatientMessage(
          patientId,
          collectionId,
          collectionAuthId,
          result,
          requestDialogResult,
          createdBy
        );
        if (!sendPatientMessageComplete.data.success) {
          this.toastService.open(ToastType.Error, unsuccessfulMessage);
        } else {
          this.toastService.open(ToastType.Success, successfulMessage);
          await this.audit.authTokenCreate(patientId);
        }
      } catch (error) {
        this.logging.consoleError('sendEmailRequest catch error', error);
        this.toastService.open(ToastType.Error, unsuccessfulMessage);
      }
    } else {
      await this.collectionAuthsService.updateCollectionAuth(
        patientId,
        collectionId,
        collectionAuthId,
        { email: result.recipientEmail }
      );
      const sendPatientReminderComplete = await this.sendManualReminder(
        patientId,
        collectionId,
        collectionAuthId,
        result
      );
      if (!sendPatientReminderComplete?.data.success) {
        this.toastService.open(
          ToastType.Error,
          'Something went wrong. Email reminder was not sent.'
        );
      } else {
        this.toastService.open(
          ToastType.Success,
          'Email reminder successfully sent'
        );
      }
    }
  }

  async sendPatientMessage(
    patientId,
    collectionId,
    collectionAuthId,
    result,
    requestDialogResult,
    createdBy
  ): Promise<firebase.functions.HttpsCallableResult> {
    const sendRequest = firebase
      .app()
      .functions(environment.region)
      .httpsCallable('sendPatientMessage');
    return await sendRequest({
      patientId,
      collectionId,
      collectionAuthId,
      messageBody: result.emailText,
      messageMedium: MessageMedium.EMAIL,
      formIds: requestDialogResult.formIds,
      reminder: requestDialogResult.reminder || false,
      keepLinkSevenDays: requestDialogResult.keepLinkSevenDays,
      createdBy
    }).catch(err => {
      this.logging.consoleError(err);
      throw err;
    });
  }

  async sendManualReminder(
    patientId,
    collectionId,
    collectionAuthId,
    result
  ): Promise<firebase.functions.HttpsCallableResult> {
    const sendManualReminder = firebase
      .app()
      .functions(environment.region)
      .httpsCallable('sendPatientReminder');
    return await sendManualReminder({
      patientId,
      collectionId,
      collectionAuthId,
      messageBody: result.emailText,
      messageMedium: MessageMedium.EMAIL
    }).catch(err => {
      this.logging.consoleError(err);
      throw err;
    });
  }

  async sendEmailRequest(
    patientId,
    collectionAuthId,
    collectionId,
    unsuccessfulMessage,
    successfulMessage,
    requestId,
    teamId
  ) {
    const sendEmailRequest = firebase
      .app()
      .functions(environment.region)
      .httpsCallable('sendEmailRequest');
    const sendEmailForRequestSubmissionCompleted = await sendEmailRequest({
      patientId,
      collectionAuthId,
      collectionId
    });
    if (!sendEmailForRequestSubmissionCompleted.data.success) {
      this.toastService.open(ToastType.Error, unsuccessfulMessage);
    } else {
      this.toastService.open(ToastType.Success, successfulMessage);
      await this.audit.authTokenCreate(patientId);
      await this.audit.sendManualReminder(
        patientId,
        collectionId,
        requestId,
        teamId
      );
    }
  }
}
