export default [
  'DLQI',
  'itchySorePainfulOrStingingSkin',
  'embarrassedOrSelfConscious',
  'interferedWithShoppingHomeOrGarden',
  'skinInfluencedClothesYouWear',
  'skinAffectedSocialOrLeisureActivities',
  'skinAffectedAnySport',
  'skinPreventedWorkingOrStudyingYesNo',
  'skinPreventedWorkingOrStudying',
  'skinCreatedProblemsWithPartnerFriendsOrFamily',
  'skinCausedSexualDifficulties',
  'skinTreatmentBeenAProblem',

  'HADS',
  'stillEnjoyThings',
  'seeFunnySideOfThings',
  'feelCheerful',
  'feelSlowedDown',
  'lostInterestInAppearance',
  'lookForwardWithEnjoyment',
  'enjoyABookRadioOrTv',
  'tenseOrWoundUp',
  'frightenedFeelingAboutSomethingAwful',
  'worryingThoughts',
  'sitAtEaseAndFeelRelaxed',
  'butterfliesInStomachFeeling',
  'feelingRestless',
  'suddenFeelingOfPanic',

  'Additional information',
  'currentTreatmentSideEffects',
  'deliveryIssueWithCurrentTreatment',
  'deliveryIssueWithCurrentTreatmentText',
  'currentWeight',
  'newMedicalIssuesSinceLastAppointment',
  'newMedicalIssuesSinceLastAppointmentText',
  'upToDateWithVaccinations',
  'upToDateWithVaccinationsText',
  'ageAppropriateCancerScreening',
  'ageAppropriateCancerScreeningText',
  'planningOnHavingChildrenInTheNextYear',
  'planningOnHavingChildrenInTheNextYearText',
  'areYouFemale',
  'whatContraceptionAreYouUsing'
];
