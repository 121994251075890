import { InjectionToken, FactoryProvider, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

export const WINDOW = new InjectionToken<ExtendedWindow>('WindowToken');

export interface ExtendedWindow extends Window {
  navigator: Navigator;
  document: Document;
}

export class WindowRef {
  get nativeWindow() {
    return window as any as ExtendedWindow;
  }
}

export function windowFactory(
  windowRef: WindowRef,
  platformId: Record<string, unknown>
): ExtendedWindow | Record<string, unknown> {
  if (isPlatformBrowser(platformId)) {
    return windowRef.nativeWindow;
  }
  return {};
}

const windowProvider: FactoryProvider = {
  provide: WINDOW,
  useFactory: windowFactory,
  deps: [WindowRef, PLATFORM_ID]
};

export const WINDOW_PROVIDER = [windowProvider, WindowRef];
