import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogData } from '../dialog-data';

@Component({
  selector: 'ic-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  data: DialogData;
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    this.data = config.data;
  }

  closeDialog(result: boolean) {
    this.ref.close(result);
  }
}
