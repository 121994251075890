<form [formGroup]="covidVisitorForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <div style="margin: auto; padding: 10px 0px">
      <h2>Covid-19 Patient/Visitor Screening Questionnaire</h2>
    </div>

    <div style="display: flex; justify-content: center; margin-bottom: 6px">
      <div style="text-align: left; width: 50%">
        <p>Have you tested positive for COVID-19 in the last 10 days?</p>
      </div>
      <mat-radio-group
        formControlName="testedPositive"
        style="display: flex; flex-direction: column; width: fit-content"
      >
        <mat-radio-button value="Yes">Yes</mat-radio-button>
        <mat-radio-button value="No">No</mat-radio-button>
      </mat-radio-group>
    </div>

    <div style="display: flex; justify-content: center; margin-bottom: 6px">
      <div style="text-align: left; width: 50%">
        <p>Have you had close contact with a person who had symptoms of COVID-19?</p>
      </div>
      <mat-radio-group
        formControlName="closeContactSymptoms"
        style="display: flex; flex-direction: column; width: fit-content"
      >
        <mat-radio-button value="Yes">Yes</mat-radio-button>
        <mat-radio-button value="No">No</mat-radio-button>
      </mat-radio-group>
    </div>

    <div style="display: flex; justify-content: center; margin-bottom: 6px">
      <div style="text-align: left; width: 50%">
        <p>Have you had close contact with a person declared COVID-19 positive?</p>
      </div>
      <mat-radio-group
        formControlName="closeContactPositive"
        style="display: flex; flex-direction: column; width: fit-content"
      >
        <mat-radio-button value="Yes">Yes</mat-radio-button>
        <mat-radio-button value="No">No</mat-radio-button>
      </mat-radio-group>
    </div>

    <div style="display: flex; justify-content: center; margin-bottom: 6px">
      <div style="text-align: left; width: 50%">
        <p>
          Do you currently have or had a high temperature within the last 10 days? – you feel hot to
          touch on your chest or back.
        </p>
      </div>
      <mat-radio-group
        formControlName="highTemprature"
        style="display: flex; flex-direction: column; width: fit-content"
      >
        <mat-radio-button value="Yes">Yes</mat-radio-button>
        <mat-radio-button value="No">No</mat-radio-button>
      </mat-radio-group>
    </div>

    <div style="display: flex; justify-content: center; margin-bottom: 6px">
      <div style="text-align: left; width: 50%">
        <p>
          Have you experienced a new loss or change to your sense of smell or taste in the last 10
          days?
        </p>
      </div>
      <mat-radio-group
        formControlName="smellTasteLoss"
        style="display: flex; flex-direction: column; width: fit-content"
      >
        <mat-radio-button value="Yes">Yes</mat-radio-button>
        <mat-radio-button value="No">No</mat-radio-button>
      </mat-radio-group>
    </div>

    <div style="display: flex; justify-content: center; margin-bottom: 6px">
      <div style="text-align: left; width: 50%">
        <p>
          Do you have, or have had in the last 10 days, a new continuous cough (this means coughing
          a lot for more than an hour or 3 or more coughing episodes in 24 hours?
        </p>
      </div>
      <mat-radio-group
        formControlName="continuousCough"
        style="display: flex; flex-direction: column; width: fit-content"
      >
        <mat-radio-button value="Yes">Yes</mat-radio-button>
        <mat-radio-button value="No">No</mat-radio-button>
      </mat-radio-group>
    </div>

    <div style="display: flex; justify-content: center; margin-bottom: 6px">
      <div style="text-align: left; width: 50%">
        <p>
          Have you travelled internationally in the last 10 days or are a contact of an individual
          with a travel history to a red list travel ban country within the past 10 days?
        </p>
      </div>
      <mat-radio-group
        formControlName="redListTravel"
        style="display: flex; flex-direction: column; width: fit-content"
      >
        <mat-radio-button value="Yes">Yes</mat-radio-button>
        <mat-radio-button value="No">No</mat-radio-button>
      </mat-radio-group>
    </div>

    <div>
      <mat-form-field class="half-width-field" appearance="outline">
        <mat-label>Name of person completing form</mat-label>
        <input matInput formControlName="visitorName" type="text" />
      </mat-form-field>
      <mat-form-field class="half-width-field" appearance="outline">
        <mat-label>Relation to child</mat-label>
        <input matInput formControlName="visitorRelation" type="text" />
      </mat-form-field>
    </div>

    <div>
      <button
        mat-raised-button
        class="positive-button"
        type="submit"
        [disabled]="!valid()"
        (click)="onSubmit()"
      >
        {{ isSubmitting ? 'Submitting...' : 'Submit' }}
      </button>
    </div>
  </div>
</form>
