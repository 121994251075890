import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ic-systm-one-link-account-help-dialog',
  templateUrl: './systm-one-link-account-help-dialog.component.html',
  styleUrls: ['./systm-one-link-account-help-dialog.component.scss'],
})
export class SystmOneLinkAccountHelpDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SystmOneLinkAccountHelpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
