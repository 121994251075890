import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { FormWithId } from '@islacare/ic-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';
import { Observable } from 'rxjs';

const { required } = Validators;

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'ic-pad-adult-cardiac-wound',
  templateUrl: './pad-adult-cardiac-wound.component.html',
  styleUrls: ['./pad-adult-cardiac-wound.component.scss'],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class PadAdultCardiacWoundComponent implements OnInit {
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  entryForm: UntypedFormGroup;
  isSubmitting = false;
  selectedSuturesValue = '';
  legWoundPresent = false;
  todaysDate = new Date();
  daysDiff: number;
  isSensitive: boolean;
  form$: Observable<FormWithId>;

  ngOnInit(): void {
    this.form$ = this.form.getForm(this.data.teamId, this.data.formId);

    this.entryForm = this.fb.group({
      dateOfSurgery: ['', [Validators.required]],
      daysSinceOp: [''],
      // dateOfPostOpSurgery: [''],
      ward: ['', [Validators.required]],
      surgicalWound: ['', [Validators.required]],
      // block for repeating
      woundClosureMaterial: ['', [Validators.required]],
      dateOfRemoval: [''],
      removalNeededBy: [''],
      surgicalWoundAssessment: ['', [Validators.required]],
      exudateAmount: [''],
      surgicalWoundAssessmentOther: [''],
      microBiologyResults: [''],
      drainSites: ['', [Validators.required]],
      drainSitesRemovalDate: [''],
      drainSitesRemovalNeededBy: [''],
      // to review
      legWoundCabg: [''],
      armWoundCabg: [''],
      groinWoundCabg: [''],
      dateOfLegWoundSiteRemoval: [''],
      dateOfArmWoundSiteRemoval: [''],
      dateOfGroinWoundSiteRemoval: [''],
      notes: ['', [Validators.maxLength(400)]],
      additionalResources: [''],
      responsibleSurgeon: ['', [Validators.required]],
      additionalComments: [''],
      staffProvidingPad: ['', [Validators.required]],
      consent: ['', [required, Boolean]],
      sensitiveImage: ['', [required, Boolean]],
    });

    this.entryForm.get('legWoundCabg').setValue('None'); // sets default to none
    this.entryForm.get('armWoundCabg').setValue('None'); // sets default to none
    this.entryForm.get('groinWoundCabg').setValue('None'); // sets default to none
    this.entryForm.get('microBiologyResults').setValue('Non applicable');

    this.setConditionalValidation();

    this.form.retreive$.pipe(untilDestroyed(this)).subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.entryForm.controls[key])
          this.entryForm.controls[key].setValue(formData[key]);
      });
      const sensitive = this.entryForm.get('sensitiveImage').value;
      this.setSensitiveImage(sensitive);
    });
  }

  get dateOfSurgery() {
    return this.entryForm.get('dateOfSurgery');
  }

  get ward() {
    return this.entryForm.get('ward');
  }
  get surgicalWound() {
    return this.entryForm.get('surgicalWound');
  }
  get woundClosureMaterial() {
    return this.entryForm.get('woundClosureMaterial');
  }
  get dateOfRemoval() {
    return this.entryForm.get('dateOfRemoval');
  }
  get surgicalWoundAssessment() {
    return this.entryForm.get('surgicalWoundAssessment');
  }
  get drainSites() {
    return this.entryForm.get('drainSites');
  }
  get dateOfLegWoundSiteRemoval() {
    return this.entryForm.get('dateOfLegWoundSiteRemoval');
  }

  get notes() {
    return this.entryForm.get('notes');
  }
  get responsibleSurgeon() {
    return this.entryForm.get('responsibleSurgeon');
  }
  get staffProvidingPad() {
    return this.entryForm.get('staffProvidingPad');
  }
  get consent() {
    return this.entryForm.get('consent');
  }

  daysSinceOp() {
    const surgeryDate = new Date(this.dateOfSurgery.value);
    const today = this.todaysDate;

    const timeDiff = today.getTime() - surgeryDate.getTime();

    this.daysDiff = Math.trunc(timeDiff / (1000 * 3600 * 24));
    this.entryForm.get('daysSinceOp').setValue(String(this.daysDiff));
  }

  setLegWoundPresent(legWoundResponse) {
    this.legWoundPresent = legWoundResponse;

    // Sets value back to none and hides the date fields when lag wound response is set back to no
    if (legWoundResponse === false) {
      this.entryForm.get('legWoundCabg').setValue('None'); // sets default to none
      this.entryForm.get('armWoundCabg').setValue('None'); // sets default to none
      this.entryForm.get('groinWoundCabg').setValue('None'); // sets default to none
    }
  }

  setConditionalValidation() {
    // For suture material date validation
    const woundClosureMaterial = this.entryForm.get('woundClosureMaterial');
    const dateOfRemoval = this.entryForm.get('dateOfRemoval');

    const woundClosureMaterialCorrect = [
      'Non-absorbable sutures (dark, thread-like appearance)',
      'Surgical clips',
      'Deep tension sutures',
    ];

    this.entryForm
      .get('woundClosureMaterial')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(() => {
        if (
          woundClosureMaterial.value.some((values) =>
            woundClosureMaterialCorrect.includes(values)
          )
        ) {
          dateOfRemoval.setValidators([Validators.required]);
          const sutureRemovalDate = this.setRemovalDate();
          dateOfRemoval.patchValue(sutureRemovalDate);
        } else {
          dateOfRemoval.clearValidators();
          dateOfRemoval.setValue('');
        }
        dateOfRemoval.updateValueAndValidity();
      });

    // leg wound conditional validation
    const legWoundSite = this.entryForm.get('legWoundCabg');
    const legWoundRemovalDate = this.entryForm.get('dateOfLegWoundSiteRemoval');

    this.entryForm
      .get('legWoundCabg')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(() => {
        if (
          legWoundSite.value ===
          'Surgical clips or non-absorbable sutures need to be removed'
        ) {
          legWoundRemovalDate.setValidators([Validators.required]);
          const sutureRemovalDate = this.setRemovalDate();
          legWoundRemovalDate.patchValue(sutureRemovalDate);
        } else if (
          legWoundSite.value !==
          'Surgical clips or non-absorbable sutures need to be removed'
        ) {
          legWoundRemovalDate.clearValidators();
          legWoundRemovalDate.setValue('');
        }
        legWoundRemovalDate.updateValueAndValidity();
      });

    // arm wound conditional validation
    const armWoundSite = this.entryForm.get('armWoundCabg');
    const armWoundRemovalDate = this.entryForm.get('dateOfArmWoundSiteRemoval');

    this.entryForm
      .get('armWoundCabg')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(() => {
        if (
          armWoundSite.value ===
          'Surgical clips or non-absorbable sutures need to be removed'
        ) {
          armWoundRemovalDate.setValidators([Validators.required]);
          const sutureRemovalDate = this.setRemovalDate();
          armWoundRemovalDate.patchValue(sutureRemovalDate);
        } else if (
          armWoundSite.value !==
          'Surgical clips or non-absorbable sutures need to be removed'
        ) {
          armWoundRemovalDate.clearValidators();
          armWoundRemovalDate.setValue('');
        }
        armWoundRemovalDate.updateValueAndValidity();
      });

    // groin wound conditional validation
    const groinWoundSite = this.entryForm.get('groinWoundCabg');
    const groinWoundRemovalDate = this.entryForm.get(
      'dateOfGroinWoundSiteRemoval'
    );

    this.entryForm
      .get('groinWoundCabg')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(() => {
        if (
          groinWoundSite.value ===
          'Surgical clips or non-absorbable sutures need to be removed'
        ) {
          groinWoundRemovalDate.setValidators([Validators.required]);
          const sutureRemovalDate = this.setRemovalDate();
          groinWoundRemovalDate.patchValue(sutureRemovalDate);
        } else if (
          groinWoundSite.value !==
          'Surgical clips or non-absorbable sutures need to be removed'
        ) {
          groinWoundRemovalDate.clearValidators();
          groinWoundRemovalDate.setValue('');
        }
        groinWoundRemovalDate.updateValueAndValidity();
      });
  }

  resetRemovalValues() {
    this.entryForm.get('dateOfRemoval').setValue('');
    this.entryForm.get('removalNeededBy').setValue('');
  }

  onSubmit() {
    if (!this.valid()) return;

    this.isSubmitting = true;
    const formResponse = {
      ...this.entryForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  setSensitiveImage(status: boolean) {
    if (typeof status === 'string') {
      this.form.setSensitiveImage(false);
      this.isSensitive = false;
    } else if (status || status === false) {
      this.form.setSensitiveImage(status);
      this.isSensitive = status;
    } else {
      this.isSensitive = !this.isSensitive;
      this.form.setSensitiveImage(this.isSensitive);
    }
  }

  valid(): boolean {
    return (
      this.entryForm.dirty &&
      !(this.entryForm.controls && this.entryForm.controls['ward'].errors) &&
      !(
        this.entryForm.controls &&
        this.entryForm.controls['dateOfSurgery'].errors
      ) &&
      !(
        this.entryForm.controls &&
        this.entryForm.controls['surgicalWound'].errors
      ) &&
      !(
        this.entryForm.controls &&
        this.entryForm.controls['woundClosureMaterial'].errors
      ) &&
      !(
        this.entryForm.controls &&
        this.entryForm.controls['dateOfRemoval'].errors
      ) &&
      !(
        this.entryForm.controls &&
        this.entryForm.controls['surgicalWoundAssessment'].errors
      ) &&
      !(
        this.entryForm.controls && this.entryForm.controls['drainSites'].errors
      ) &&
      !(
        this.entryForm.controls &&
        this.entryForm.controls['dateOfLegWoundSiteRemoval'].errors
      ) &&
      !(this.entryForm.controls && this.entryForm.controls['notes'].errors) &&
      !(
        this.entryForm.controls &&
        this.entryForm.controls['responsibleSurgeon'].errors
      ) &&
      !(
        this.entryForm.controls &&
        this.entryForm.controls['staffProvidingPad'].errors
      ) &&
      !(this.entryForm.controls && this.entryForm.controls['consent'].errors) &&
      !this.isSubmitting
    );
  }

  setRemovalDate() {
    const surgeryDate = new Date(this.dateOfSurgery.value);
    const surgeryTime = surgeryDate.getTime();
    const sutureRemovalTime = surgeryTime + 14 * 1000 * 24 * 3600;
    const sutureRemovalIsoDate = new Date(sutureRemovalTime).toISOString();
    return sutureRemovalIsoDate.substring(0, 10);
  }
}
