import { CommonModule } from '@angular/common';
import { Component, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, filter, map } from 'rxjs';
import { FileUploadService } from '../../services/file-upload/file-upload.service';
import { FileUploaderComponent } from '../file-uploader/file-uploader.component';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [CommonModule, FileUploaderComponent],
  selector: 'ic-file-uploader-panel',
  templateUrl: './file-uploader-panel.component.html',
  styleUrls: ['./file-uploader-panel.component.scss'],
})
export class FileUploaderPanelComponent {
  ongoingUploadCount$ = this.fileUploadService.ongoingUploadCount$;
  panelHidden$ = this.fileUploadService.panelHidden$;

  isExpanded: boolean;

  constructor(
    private fileUploadService: FileUploadService,
    private renderer: Renderer2,
    private router: Router
  ) {
    this.subscribeToRouteChanges();
  }

  // This is temporary and can be removed once the clinician submission flow redesign work is complete
  subscribeToRouteChanges() {
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        ),
        map((event: NavigationEnd) => event.urlAfterRedirects),
        distinctUntilChanged()
      )
      .subscribe((currentUrl) => {
        const shouldAddClass = currentUrl.includes(
          'submission/clinician/file-upload'
        );
        if (shouldAddClass) {
          this.renderer.addClass(
            document.querySelector('ic-file-uploader-panel'),
            'file-upload-spacing'
          );
        } else {
          this.renderer.removeClass(
            document.querySelector('ic-file-uploader-panel'),
            'file-upload-spacing'
          );
        }
      });
  }

  togglePanel() {
    this.isExpanded = !this.isExpanded;
  }

  hidePanel() {
    this.fileUploadService.hidePanel();
    this.isExpanded = false;
  }
}
