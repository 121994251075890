<ng-container *ngIf="stateSnapshot">
  <div [innerHTML]="sfTexts.landingFwRequestInfo"></div>
  <div class="flex flex-column gap-3">
    <form [formGroup]="emailForm" class="flex flex-column gap-3">
      <div class="flex flex-column gap-1">
        <label class="flex flex-row justify-content-between">
          <span>Email:</span>
          <div
            *ngIf="email.invalid && (email.dirty || email.touched)"
            class="text-error"
          >
            <span
              *ngIf="email.invalid && email.touched && confirmEmail.touched"
              class="text-sm"
            >
              Please enter a valid email address
            </span>
          </div>
        </label>
        <input id="email" type="email" formControlName="email" pInputText />
      </div>

      <div class="flex flex-column gap-1">
        <label class="flex flex-row justify-content-between">
          Confirm Email:
        </label>
        <input
          id="confirmEmail"
          type="email"
          formControlName="confirmEmail"
          pInputText
        />

        <span *ngIf="emailForm.hasError('mismatch')" class="text-sm text-error">
          The email addresses do not match
        </span>
      </div>

      <div class="flex flex-row justify-content-end gap-2">
        <button
          (click)="ref.close()"
          icEventTracker="sf-return-to-previous-screen"
          data-cy="sfForwardRequestReturn"
          class="p-button-outlined"
          aria-label="Cancel"
          label="Cancel"
          type="button"
          pButton
        ></button>
        <p-button
          [loading]="submitting$ | async"
          [disabled]="emailForm.invalid"
          (click)="forwardRequestToEmail()"
          icEventTracker="sf-confirm-forward-request"
          data-cy="sfForwardRequestFinal"
          styleClass="w-full"
          label="Confirm"
        ></p-button>
      </div>
    </form>
  </div>
</ng-container>
