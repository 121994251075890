<form [formGroup]="boadiceaForm">
  <div class="form-formatting">
    <mat-card>
      <mat-card-title>Sex</mat-card-title>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Are you:</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select
              formControlName="sex"
              data-cy="sex-boadicea-form"
              placeholder="Gender"
              (selectionChange)="genderToggle($event)"
            >
              <mat-option value="M">Male</mat-option>
              <mat-option value="F">Female</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-title>Symptoms</mat-card-title>

      <mat-card-content>
        <div class="question-container">
          <mat-radio-group
            class="full-width-field"
            appearance="fill"
            formControlName="doYouHaveSymptoms"
            (change)="symptomToggle()"
          >
            <mat-label>Do you have any symptoms?</mat-label>
            <br />
            <mat-radio-button data-cy="sym-one-yes-radio" value="yes">Yes</mat-radio-button>
            <mat-radio-button data-cy="sym-one-no-radio" value="no">No</mat-radio-button>
          </mat-radio-group>
        </div>

        <div *ngIf="doYouHaveSymptoms.value === 'yes'">
          <div class="question-container">
            <div class="half-width-field">
              <p>What's your main symptom?</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <mat-select
                formControlName="mainSymptom"
                data-cy="main-symptom-boadicea"
                placeholder="Pick one"
              >
                <mat-option value="pain">Pain</mat-option>
                <mat-option value="breast lump">Breast Lump</mat-option>
                <mat-option value="underarm lump">Underarm Lump</mat-option>
                <mat-option value="nipple discharge">Nipple discharge</mat-option>
                <mat-option value="change in nipple">Change in nipple</mat-option>
                <mat-option value="change in skin/shape">Change in breast skin/shape</mat-option>
                <mat-option value="other">Other</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="mainSymptom.value === 'other'">
            <div class="question-container">
              <div class="half-width-field">
                <p>Please specify</p>
              </div>
              <mat-form-field class="half-width-field" appearance="fill">
                <input
                  matInput
                  type="text"
                  formControlName="mainSymptomOther"
                  placeholder="Input details"
                />
              </mat-form-field>
            </div>
          </div>

          <div class="question-container">
            <mat-radio-group
              class="full-width-field"
              appearance="fill"
              formControlName="firstNoticeMainSymptom"
              (change)="mainSymptomToggle()"
            >
              <mat-label>When did you first notice it? (months or days)</mat-label>
              <br />
              <mat-radio-button value="days">Days</mat-radio-button>
              <mat-radio-button data-cy="sym-one-months-radio" value="months"
                >Months</mat-radio-button
              >
              <mat-radio-button value="longstanding">Longstanding</mat-radio-button>
            </mat-radio-group>
          </div>

          <br />

          <div *ngIf="firstNoticeMainSymptom.value === 'months'">
            <div class="question-container">
              <div class="half-width-field">
                <p>For how many months?</p>
              </div>
              <mat-form-field class="half-width-field" appearance="fill">
                <input
                  matInput
                  type="number"
                  formControlName="mainSymptomLengthInMonths"
                  placeholder="Number of months"
                  data-cy="main-symptom-length-in-months-boadicea"
                />
              </mat-form-field>
            </div>
          </div>

          <div *ngIf="firstNoticeMainSymptom.value === 'days'">
            <div class="question-container">
              <div class="half-width-field">
                <p>For how many days?</p>
              </div>
              <mat-form-field class="half-width-field" appearance="fill">
                <input
                  matInput
                  type="number"
                  formControlName="mainSymptomLengthInDays"
                  placeholder="Number of days"
                />
              </mat-form-field>
            </div>
          </div>

          <div class="question-container">
            <mat-radio-group
              class="full-width-field"
              appearance="fill"
              formControlName="mainSymptomLocation"
            >
              <mat-label>Which side?</mat-label>
              <br />
              <mat-radio-button value="left">Left</mat-radio-button>
              <mat-radio-button data-cy="sym-one-right-radio" value="right">Right</mat-radio-button>
              <mat-radio-button value="both">Both</mat-radio-button>
            </mat-radio-group>
          </div>

          <div *ngIf="mainSymptom.value === 'pain' || mainSymptom.value === 'breast lump'">
            <br />
            <div class="question-container">
              <mat-radio-group
                class="full-width-field"
                appearance="fill"
                formControlName="mainSymptomVariesWithCycle"
              >
                <mat-label>Does it vary with your monthly period cycle?</mat-label>
                <br />
                <mat-radio-button value="yes">Yes</mat-radio-button>
                <mat-radio-button data-cy="sym-one-cycle-no-radio" value="no">No</mat-radio-button>
                <mat-radio-button value="n/a">Non-applicable</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <br />
          <div *ngIf="mainSymptom.value === 'pain'">
            <div class="question-container">
              <mat-radio-group
                class="full-width-field"
                appearance="fill"
                formControlName="doesPainRadiateElsewhere"
              >
                <mat-label>Does it radiate to your nipple/underarm/elsewhere?</mat-label>
                <br />
                <mat-radio-button value="yes">Yes</mat-radio-button>
                <mat-radio-button value="no">No</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div *ngIf="mainSymptom.value === 'breast lump'">
            <div class="question-container">
              <mat-radio-group
                class="full-width-field"
                appearance="fill"
                formControlName="typeOfBreastLump"
              >
                <mat-label>Is it a well defined lump or general lumpiness?</mat-label>
                <br />
                <mat-radio-button data-cy="sym-one-defined-radio" value="defined lump"
                  >Defined lump</mat-radio-button
                >
                <mat-radio-button value="general lump">General lumpiness</mat-radio-button>
              </mat-radio-group>
            </div>
            <br />
            <div class="question-container">
              <mat-radio-group
                class="full-width-field"
                appearance="fill"
                formControlName="isLumpAssociatedWithBreastFeeding"
              >
                <mat-label>Is it associated with breast feeding?</mat-label>
                <br />
                <mat-radio-button value="yes">Yes</mat-radio-button>
                <mat-radio-button data-cy="sym-one-br-feed-no-radio" value="no"
                  >No</mat-radio-button
                >
              </mat-radio-group>
            </div>
            <br />
            <div class="question-container">
              <mat-radio-group
                class="full-width-field"
                appearance="fill"
                formControlName="hadTemperatureWithLump"
              >
                <mat-label>Have you had a temperature with it?</mat-label>
                <br />
                <mat-radio-button value="yes">Yes</mat-radio-button>
                <mat-radio-button data-cy="sym-one-temp-no-radio" value="no">No</mat-radio-button>
              </mat-radio-group>
            </div>
            <br />
            <div class="question-container">
              <mat-radio-group
                class="full-width-field"
                appearance="fill"
                formControlName="areYouASmoker"
              >
                <mat-label>Are you a smoker?</mat-label>
                <br />
                <mat-radio-button value="yes">Yes</mat-radio-button>
                <mat-radio-button data-cy="sym-one-smoke-no-radio" value="no">No</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div *ngIf="mainSymptom.value === 'underarm lump'">
            <div class="question-container">
              <mat-radio-group
                class="full-width-field"
                appearance="fill"
                formControlName="lumpType"
              >
                <mat-label>Is it a well defined lump or a bulge?</mat-label>
                <br />
                <mat-radio-button value="lump">Lump</mat-radio-button>
                <mat-radio-button value="bulge">Bulge</mat-radio-button>
              </mat-radio-group>
            </div>
            <br />
            <div class="question-container">
              <mat-radio-group
                class="full-width-field"
                appearance="fill"
                formControlName="lumpLocation"
              >
                <mat-label>Is it in the skin or deeper inside?</mat-label>
                <br />
                <mat-radio-button value="skin">Skin</mat-radio-button>
                <mat-radio-button value="deep">Deep</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div *ngIf="mainSymptom.value === 'nipple discharge'">
            <div class="question-container">
              <mat-radio-group
                class="full-width-field"
                appearance="fill"
                formControlName="nippleDischargeOccurrence"
              >
                <mat-label>Does it happen by itself or only when you squeeze?</mat-label>
                <br />
                <mat-radio-button value="spontaneous">Spontaneous</mat-radio-button>
                <mat-radio-button value="only on squeezing">Only on squeezing</mat-radio-button>
              </mat-radio-group>
            </div>
            <br />
            <div class="question-container">
              <mat-radio-group
                class="full-width-field"
                appearance="fill"
                formControlName="nippleDischargeAmount"
              >
                <mat-label>How much comes out?</mat-label>
                <br />
                <mat-radio-button value="tiny">Tiny</mat-radio-button>
                <mat-radio-button value="drop">Drop</mat-radio-button>
                <mat-radio-button value="lots">Lots</mat-radio-button>
                <mat-radio-button value="unsure">Unsure</mat-radio-button>
              </mat-radio-group>
            </div>
            <br />
            <div class="question-container">
              <mat-radio-group
                class="full-width-field"
                appearance="fill"
                formControlName="nippleDischargeConsistency"
              >
                <mat-label>Is it sticky of watery?</mat-label>
                <br />
                <mat-radio-button value="sticky">Sticky</mat-radio-button>
                <mat-radio-button value="watery">Watery</mat-radio-button>
              </mat-radio-group>
            </div>
            <br />
            <div class="question-container">
              <mat-radio-group
                class="full-width-field"
                appearance="fill"
                formControlName="nippleDischargeColour"
              >
                <mat-label>What colour is it?</mat-label>
                <br />
                <mat-radio-button value="clear">Clear</mat-radio-button>
                <mat-radio-button value="creamy">Creamy</mat-radio-button>
                <mat-radio-button value="green">Green</mat-radio-button>
                <mat-radio-button value="brown">Brown</mat-radio-button>
                <mat-radio-button value="blood stained">Blood stained</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div *ngIf="mainSymptom.value === 'change in nipple'">
            <div class="question-container">
              <mat-radio-group
                class="full-width-field"
                appearance="fill"
                formControlName="invertedNipple"
              >
                <mat-label>Is it Inverted?</mat-label>
                <br />
                <mat-radio-button value="yes">Yes</mat-radio-button>
                <mat-radio-button value="No">No</mat-radio-button>
              </mat-radio-group>
            </div>
            <br />
            <div class="question-container">
              <mat-radio-group
                class="full-width-field"
                appearance="fill"
                formControlName="isSkinFlakyOrItchy"
              >
                <mat-label>Is the skin Flaky or Itchy?</mat-label>
                <br />
                <mat-radio-button value="yes">Yes</mat-radio-button>
                <mat-radio-button value="no">No</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div *ngIf="mainSymptom.value === 'change in skin/shape'">
            <div class="question-container">
              <mat-radio-group
                class="full-width-field"
                appearance="fill"
                formControlName="changeInSkin"
              >
                <mat-label>Are there skin changes?</mat-label>
                <br />
                <mat-radio-button value="redness">Redness</mat-radio-button>
                <mat-radio-button value="itchy/flaky">Itchy/Flaky</mat-radio-button>
              </mat-radio-group>
            </div>
            <br />
            <div class="question-container">
              <mat-radio-group
                class="full-width-field"
                appearance="fill"
                formControlName="isThereSkinDipling"
              >
                <mat-label>Is there skin dimpling?</mat-label>
                <br />
                <mat-radio-button value="yes">Yes</mat-radio-button>
                <mat-radio-button value="no">No</mat-radio-button>
              </mat-radio-group>
            </div>
            <br />
            <div class="question-container">
              <mat-radio-group
                class="full-width-field"
                appearance="fill"
                formControlName="hasShapeOrSizeChanged"
              >
                <mat-label>Has the shape or size changed?</mat-label>
                <br />
                <mat-radio-button value="yes">Yes</mat-radio-button>
                <mat-radio-button value="no">No</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <br />
          <h3>Other Symptoms</h3>

          <div class="question-container">
            <div class="half-width-field">
              <p>Any other symptoms?</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <mat-select
                formControlName="otherSymptoms"
                data-cy="other-symptoms-boadicea"
                placeholder="Select one"
              >
                <mat-option value="no"> No </mat-option>
                <mat-option value="pain" [disabled]="mainSymptom.value === 'pain'">
                  Pain
                </mat-option>
                <mat-option value="breast lump" [disabled]="mainSymptom.value === 'breast lump'">
                  Breast Lump
                </mat-option>
                <mat-option
                  value="underarm lump"
                  [disabled]="mainSymptom.value === 'underarm lump'"
                >
                  Underarm Lump
                </mat-option>
                <mat-option
                  value="nipple discharge"
                  [disabled]="mainSymptom.value === 'nipple discharge'"
                >
                  Nipple discharge
                </mat-option>
                <mat-option
                  value="change in nipple"
                  [disabled]="mainSymptom.value === 'change in nipple'"
                >
                  Change in nipple
                </mat-option>
                <mat-option
                  value="change in breast skin/shape"
                  [disabled]="mainSymptom.value === 'change in skin/shape'"
                >
                  Change in breast skin/shape
                </mat-option>
                <mat-option value="None of the above"> None of the above </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="otherSymptoms.value !== 'no'">
            <div *ngIf="otherSymptoms.value === 'None of the above'">
              <div class="question-container">
                <div class="half-width-field">
                  <p>Please specify?</p>
                </div>
                <mat-form-field class="half-width-field" appearance="fill">
                  <input
                    matInput
                    type="text"
                    formControlName="specifyOtherSymptoms"
                    data-cy="specify-other-symptoms-boadicea"
                    placeholder="Input details"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="question-container">
              <mat-radio-group
                class="full-width-field"
                appearance="fill"
                formControlName="firstNoticeOtherSymptoms"
                (change)="otherSymptomToggle()"
              >
                <mat-label>When did you first notice it? (months or days)</mat-label>
                <br />
                <mat-radio-button value="days">Days</mat-radio-button>
                <mat-radio-button value="months">Months</mat-radio-button>
                <mat-radio-button value="longstanding">Longstanding</mat-radio-button>
              </mat-radio-group>
            </div>

            <br />

            <div *ngIf="firstNoticeOtherSymptoms.value === 'months'">
              <div class="question-container">
                <div class="half-width-field">
                  <p>For how many months?</p>
                </div>
                <mat-form-field class="half-width-field" appearance="fill">
                  <input
                    matInput
                    type="number"
                    formControlName="otherSymptomsLengthInMonths"
                    placeholder="Number of months"
                  />
                </mat-form-field>
              </div>
            </div>

            <div *ngIf="firstNoticeOtherSymptoms.value === 'days'">
              <div class="question-container">
                <div class="half-width-field">
                  <p>For how many days?</p>
                </div>
                <mat-form-field class="half-width-field" appearance="fill">
                  <input
                    matInput
                    type="number"
                    formControlName="otherSymptomsLengthInDays"
                    data-cy="other-symptoms-length-in-days-boadicea"
                    placeholder="Number of days"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="question-container">
              <mat-radio-group
                class="full-width-field"
                appearance="fill"
                formControlName="otherSymptomLocation"
              >
                <mat-label>Which side?</mat-label>
                <br />
                <mat-radio-button data-cy="sym-two-left-radio" value="left">Left</mat-radio-button>
                <mat-radio-button value="right">Right</mat-radio-button>
                <mat-radio-button value="both">Both</mat-radio-button>
              </mat-radio-group>
            </div>

            <div *ngIf="otherSymptoms.value === 'pain' || otherSymptoms.value === 'breast lump'">
              <br />
              <div class="question-container">
                <mat-radio-group
                  class="full-width-field"
                  appearance="fill"
                  formControlName="otherSymptomVariesWithCycle"
                >
                  <mat-label>Does it vary with your monthly period cycle?</mat-label>
                  <br />
                  <mat-radio-button value="yes">Yes</mat-radio-button>
                  <mat-radio-button value="no">No</mat-radio-button>
                  <mat-radio-button value="n/a">Non-applicable</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <br />
            <div *ngIf="otherSymptoms.value === 'pain'">
              <div class="question-container">
                <mat-radio-group
                  class="full-width-field"
                  appearance="fill"
                  formControlName="doesOtherSymptomPainRadiateElsewhere"
                >
                  <mat-label>Does it radiate to your nipple/underarm/elsewhere?</mat-label>
                  <br />
                  <mat-radio-button value="yes">Yes</mat-radio-button>
                  <mat-radio-button value="no">No</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>

            <div *ngIf="otherSymptoms.value === 'breast lump'">
              <div class="question-container">
                <mat-radio-group
                  class="full-width-field"
                  appearance="fill"
                  formControlName="otherSymptomTypeOfBreastLump"
                >
                  <mat-label>Is it a well defined lump or general lumpiness?</mat-label>
                  <br />
                  <mat-radio-button value="defined lump">Defined lump</mat-radio-button>
                  <mat-radio-button value="general lump">General lumpiness</mat-radio-button>
                </mat-radio-group>
              </div>
              <br />
              <div class="question-container">
                <mat-radio-group
                  class="full-width-field"
                  appearance="fill"
                  formControlName="isOtherSymptomLumpAssociatedWithBreastFeeding"
                >
                  <mat-label>Is it associated with breast feeding?</mat-label>
                  <br />
                  <mat-radio-button value="yes">Yes</mat-radio-button>
                  <mat-radio-button value="no">No</mat-radio-button>
                </mat-radio-group>
              </div>
              <br />
              <div class="question-container">
                <mat-radio-group
                  class="full-width-field"
                  appearance="fill"
                  formControlName="temperatureWithLump"
                >
                  <mat-label>Have you had a temperature with it?</mat-label>
                  <br />
                  <mat-radio-button value="yes">Yes</mat-radio-button>
                  <mat-radio-button value="no">No</mat-radio-button>
                </mat-radio-group>
              </div>
              <br />
              <div class="question-container">
                <mat-radio-group
                  class="full-width-field"
                  appearance="fill"
                  formControlName="haveYouSmoked"
                >
                  <mat-label>Are you a smoker?</mat-label>
                  <br />
                  <mat-radio-button value="yes">Yes</mat-radio-button>
                  <mat-radio-button value="no">No</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>

            <div *ngIf="otherSymptoms.value === 'underarm lump'">
              <div class="question-container">
                <mat-radio-group
                  class="full-width-field"
                  appearance="fill"
                  formControlName="otherSymptomLumpType"
                >
                  <mat-label>Is it a well defined lump or a bulge?</mat-label>
                  <br />
                  <mat-radio-button value="lump">Lump</mat-radio-button>
                  <mat-radio-button value="bulge">Bulge</mat-radio-button>
                </mat-radio-group>
              </div>
              <br />
              <div class="question-container">
                <mat-radio-group
                  class="full-width-field"
                  appearance="fill"
                  formControlName="otherSymptomLumpLocation"
                >
                  <mat-label>Is it in the skin or deeper inside?</mat-label>
                  <br />
                  <mat-radio-button value="skin">Skin</mat-radio-button>
                  <mat-radio-button value="deep">Deep</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>

            <div *ngIf="otherSymptoms.value === 'nipple discharge'">
              <div class="question-container">
                <mat-radio-group
                  class="full-width-field"
                  appearance="fill"
                  formControlName="nippleDischargeFrequency"
                >
                  <mat-label>Does it happen by itself or only when you squeeze?</mat-label>
                  <br />
                  <mat-radio-button value="spontaneous">Spontaneous</mat-radio-button>
                  <mat-radio-button value="only on squeezing">Only on squeezing</mat-radio-button>
                </mat-radio-group>
              </div>
              <br />
              <div class="question-container">
                <mat-radio-group
                  class="full-width-field"
                  appearance="fill"
                  formControlName="otherSymptomNippleDischargeAmount"
                >
                  <mat-label>How much comes out?</mat-label>
                  <br />
                  <mat-radio-button value="tiny">Tiny</mat-radio-button>
                  <mat-radio-button value="drop">Drop</mat-radio-button>
                  <mat-radio-button value="lots">Lots</mat-radio-button>
                  <mat-radio-button value="unsure">Unsure</mat-radio-button>
                </mat-radio-group>
              </div>
              <br />
              <div class="question-container">
                <mat-radio-group
                  class="full-width-field"
                  appearance="fill"
                  formControlName="otherSymptomNippleDischargeConsistency"
                >
                  <mat-label>Is it sticky of watery?</mat-label>
                  <br />
                  <mat-radio-button value="sticky">Sticky</mat-radio-button>
                  <mat-radio-button value="watery">Watery</mat-radio-button>
                </mat-radio-group>
              </div>
              <br />
              <div class="question-container">
                <mat-radio-group
                  class="full-width-field"
                  appearance="fill"
                  formControlName="otherSymptomNippleDischargeColour"
                >
                  <mat-label>What colour is it?</mat-label>
                  <br />
                  <mat-radio-button value="clear">Clear</mat-radio-button>
                  <mat-radio-button value="creamy">Creamy</mat-radio-button>
                  <mat-radio-button value="green">Green</mat-radio-button>
                  <mat-radio-button value="brown">Brown</mat-radio-button>
                  <mat-radio-button value="blood stained">Blood stained</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>

            <div *ngIf="otherSymptoms.value === 'change in nipple'">
              <div class="question-container">
                <mat-radio-group
                  class="full-width-field"
                  appearance="fill"
                  formControlName="isNippleInverted"
                >
                  <mat-label>Is it Inverted?</mat-label>
                  <br />
                  <mat-radio-button value="yes">Yes</mat-radio-button>
                  <mat-radio-button value="No">No</mat-radio-button>
                </mat-radio-group>
              </div>
              <br />
              <div class="question-container">
                <mat-radio-group
                  class="full-width-field"
                  appearance="fill"
                  formControlName="skinFlakyOrItchy"
                >
                  <mat-label>Is the skin Flaky or Itchy?</mat-label>
                  <br />
                  <mat-radio-button value="yes">Yes</mat-radio-button>
                  <mat-radio-button value="no">No</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>

            <div *ngIf="otherSymptoms.value === 'change in skin/shape'">
              <div class="question-container">
                <mat-radio-group
                  class="full-width-field"
                  appearance="fill"
                  formControlName="otherSymptomChangeInSkin"
                >
                  <mat-label>Are there skin changes?</mat-label>
                  <br />
                  <mat-radio-button value="redness">Redness</mat-radio-button>
                  <mat-radio-button value="itchy/flaky">Itchy/Flaky</mat-radio-button>
                </mat-radio-group>
              </div>
              <br />
              <div class="question-container">
                <mat-radio-group
                  class="full-width-field"
                  appearance="fill"
                  formControlName="skinDipling"
                >
                  <mat-label>Is there skin dimpling?</mat-label>
                  <br />
                  <mat-radio-button value="yes">Yes</mat-radio-button>
                  <mat-radio-button value="no">No</mat-radio-button>
                </mat-radio-group>
              </div>
              <br />
              <div class="question-container">
                <mat-radio-group
                  class="full-width-field"
                  appearance="fill"
                  formControlName="shapeOrSizeChanged"
                >
                  <mat-label>Has the shape or size changed?</mat-label>
                  <br />
                  <mat-radio-button value="yes">Yes</mat-radio-button>
                  <mat-radio-button value="no">No</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-title>BMI</mat-card-title>

      <mat-card-content>
        <p>What is your weight?</p>
        <div class="question-container">
          <mat-radio-group
            class="full-width-field"
            appearance="fill"
            formControlName="weightImperialOrMetric"
            (change)="weightToggle($event)"
          >
            <mat-radio-button data-cy="weight-imperial-radio" value="imperial"
              >Imperial</mat-radio-button
            >
            <mat-radio-button value="metric">Metric</mat-radio-button>
          </mat-radio-group>
        </div>
        <div *ngIf="weightImperialOrMetric.value === 'imperial'">
          <mat-form-field class="third-width-field" appearance="fill">
            <input matInput type="number" formControlName="weightSt" data-cy="weight-st-boadicea" />
          </mat-form-field>
          <span>st</span>
          <mat-form-field class="third-width-field" appearance="fill">
            <input
              matInput
              type="number"
              formControlName="weightLbs"
              data-cy="weight-lbs-boadicea"
            />
          </mat-form-field>
          <span>lbs</span>
        </div>
        <div *ngIf="weightImperialOrMetric.value === 'metric'">
          <mat-form-field class="third-width-field" appearance="fill">
            <input matInput type="number" formControlName="weightMetric" />
          </mat-form-field>
          <span>kg</span>
        </div>
        <br />
        <p>What is your height?</p>
        <div class="question-container">
          <mat-radio-group
            class="full-width-field"
            appearance="fill"
            formControlName="heightImperialOrMetric"
            (change)="heightToggle($event)"
          >
            <mat-radio-button value="imperial">Imperial</mat-radio-button>
            <mat-radio-button data-cy="height-metric-radio" value="metric">Metric</mat-radio-button>
          </mat-radio-group>
        </div>
        <div *ngIf="heightImperialOrMetric.value === 'metric'">
          <mat-form-field class="third-width-field" appearance="fill">
            <input
              matInput
              type="number"
              formControlName="heightMetric"
              data-cy="height-metric-boadicea"
            />
          </mat-form-field>
          <span>cm</span>
        </div>
        <div *ngIf="heightImperialOrMetric.value === 'imperial'">
          <mat-form-field class="third-width-field" appearance="fill">
            <input matInput type="number" formControlName="heightFt" />
          </mat-form-field>
          <span>ft</span>
          <mat-form-field class="third-width-field" appearance="fill">
            <input matInput type="number" formControlName="heightIns" />
          </mat-form-field>
          <span>ins</span>
        </div>
        <br />
        <span data-cy="bmi-output" *ngIf="bmi">
          <strong> BMI: {{ bmi }} </strong>
        </span>
        <br />
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-title>Lifestyle</mat-card-title>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Do you drink alcohol?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="drinkAlcohol" data-cy="drink-alcohol-boadicea">
              <mat-option data-cy="alcohol-yes-dropdown" value="yes">Yes</mat-option>
              <mat-option data-cy="alcohol-no-dropdown" value="no">No</mat-option>
              <mat-option value="unknown">Unknown</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="drinkAlcohol.value === 'yes'">
          <div class="question-container">
            <div class="half-width-field">
              <p>Glasses of wine per week:</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <input
                matInput
                type="number"
                formControlName="glassesOfWinePerWeek"
                data-cy="glasses-of-wine-per-week-boadicea"
              />
            </mat-form-field>
          </div>
          <div class="question-container">
            <div class="half-width-field">
              <p>Pints of beer per week:</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <input
                matInput
                type="number"
                formControlName="pintsOfBeerPerWeek"
                data-cy="pints-of-beer-per-week-boadicea"
              />
            </mat-form-field>
          </div>
          <div class="question-container">
            <div class="half-width-field">
              <p>Bottles of beer/cider/alcopop per week:</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <input
                matInput
                type="number"
                formControlName="bottlesOfBeerCiderOrAlcopopPerWeek"
                data-cy="bottles-of-beer-cider-or-alcopop-per-week-boadicea"
              />
            </mat-form-field>
          </div>
          <div class="question-container">
            <div class="half-width-field">
              <p>Shots of spirit per week:</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <input
                matInput
                type="number"
                formControlName="shotsOfSpiritPerWeek"
                data-cy="shots-of-spirit-per-week-boadicea"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Do you smoke?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="doYouSmoke" data-cy="do-you-smoke-boadicea">
              <mat-option value="yes">Yes</mat-option>
              <mat-option data-cy="smoker-no-dropdown" value="no">No/Never</mat-option>
              <mat-option value="occasionally">Occasionally</mat-option>
              <mat-option value="former smoker">Former Smoker</mat-option>
              <mat-option value="vape">Vape</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <div *ngIf="sex.value === 'F'">
      <br />
      <mat-card>
        <mat-card-title>Woman's Health</mat-card-title>
        <mat-card-content>
          <h3>Periods</h3>
          <div class="question-container">
            <div class="half-width-field">
              <p>
                How old were you when you had your first period? (if unknown please leave blank):
              </p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <input
                matInput
                type="number"
                formControlName="ageOfFirstPeriod"
                data-cy="age-of-first-period"
              />
            </mat-form-field>
          </div>
          <div class="question-container">
            <div class="half-width-field">
              <p>Have your periods now stopped completely?</p>
              <p>
                That is, have you now gone at least 6 months without having a period and you are not
                pregnant or using any form of hormonal contraception.
              </p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <mat-select
                formControlName="hasYourPeriodsStopped"
                data-cy="has-your-periods-stopped"
                (selectionChange)="periodStoppedToggle($event)"
              >
                <mat-option value="yes">Yes</mat-option>
                <mat-option data-cy="period-no-dropdown" value="no">No</mat-option>
                <mat-option value="unknown">Unknown</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="hasYourPeriodsStopped.value === 'yes'">
            <div class="question-container">
              <div class="half-width-field">
                <p>How old were you when your periods stopped completely?</p>
              </div>
              <mat-form-field class="half-width-field" appearance="fill">
                <input matInput type="number" formControlName="periodsStoppedAge" />
              </mat-form-field>
            </div>
          </div>

          <h3>Oral Contraceptive</h3>
          <div class="question-container">
            <div class="half-width-field">
              <p>Have you ever taken the oral contraceptive pill?</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <mat-select
                formControlName="takenContraceptivePill"
                data-cy="taken-contraceptive-pill"
                (selectionChange)="ocPillToggle($event)"
              >
                <mat-option data-cy="oc-yes-dropdown" value="yes">Yes</mat-option>
                <mat-option value="no">No</mat-option>
                <mat-option value="unknown">Unknown</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="takenContraceptivePill.value === 'yes'">
            <div class="question-container">
              <div class="half-width-field">
                <p>How many years in total?</p>
              </div>
              <mat-form-field class="half-width-field" appearance="fill">
                <input
                  matInput
                  type="number"
                  formControlName="yearsTakingContraceptivePills"
                  data-cy="years-taking-contraceptive-pills"
                />
              </mat-form-field>
            </div>
            <div class="question-container">
              <mat-radio-group
                class="full-width-field"
                appearance="fill"
                formControlName="takenContraceptivePillLastTwoYears"
                data-cy="taken-contraceptive-pill-last-two-years"
              >
                <mat-label>Have you taken the pill in the last two years?</mat-label>
                <br />
                <mat-radio-button data-cy="taken-Oc-yes-dropdown" value="yes">Yes</mat-radio-button>
                <mat-radio-button value="no">No</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <h3>HRT</h3>
          <div class="question-container">
            <div class="half-width-field">
              <p>Have you ever used hormone replacement therapy (HRT) for menopause?</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <mat-select
                formControlName="haveYouUsedHrt"
                data-cy="have-you-used-hrt"
                (selectionChange)="hrtUsedToggle($event)"
              >
                <mat-option value="yes">Yes</mat-option>
                <mat-option data-cy="hrt-no-dropdown" value="no">No</mat-option>
                <mat-option value="unknown">Unknown</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="haveYouUsedHrt.value === 'yes'">
            <div class="question-container">
              <div class="half-width-field">
                <p>For about how many years in total have you used HRT?</p>
              </div>
              <mat-form-field class="half-width-field" appearance="fill">
                <input matInput type="number" formControlName="yearsUsingHrt" />
              </mat-form-field>
            </div>
            <div class="question-container">
              <mat-radio-group
                class="full-width-field"
                appearance="fill"
                formControlName="usedHrtInLast5Years"
              >
                <mat-label>Have you used HRT in the last 5 years?</mat-label>
                <br />
                <mat-radio-button value="yes">Yes</mat-radio-button>
                <mat-radio-button value="no">No</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <br />
    </div>

    <div *ngIf="sex.value === 'F'">
      <mat-card>
        <mat-card-title>Children</mat-card-title>
        <mat-card-content>
          <div class="question-container">
            <div class="half-width-field">
              <p>Have you ever had any children?</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <mat-select
                formControlName="hadChildren"
                data-cy="had-children"
                (selectionChange)="clearChildList($event)"
              >
                <mat-option data-cy="yes-child-dropdown" value="yes">Yes</mat-option>
                <mat-option value="no">No</mat-option>
                <mat-option value="unknown">Unknown</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="hadChildren.value === 'yes'">
            <ng-container
              *ngFor="
                let userFormGroup of boadiceaForm.get('childrenList')['controls'];
                let i = index
              "
            >
              <div [formGroup]="userFormGroup">
                <div class="question-container">
                  <div class="half-width-field">
                    <p *ngIf="i === 0">Firstborn year of birth:</p>
                    <p *ngIf="i > 0">Child {{ i + 1 }} year of birth:</p>
                  </div>
                  <mat-form-field class="half-width-field" appearance="fill">
                    <input
                      matInput
                      type="number"
                      formControlName="childYOB"
                      data-cy="child-yob-boadicea"
                      min="1900"
                    />
                    <mat-error> Please enter a valid year of birth. </mat-error>
                  </mat-form-field>
                </div>
                <div class="question-container">
                  <div class="half-width-field">
                    <p *ngIf="i === 0">Firstborn sex:</p>
                    <p *ngIf="i > 0">Child {{ i + 1 }} sex:</p>
                  </div>
                  <mat-form-field class="half-width-field" appearance="fill">
                    <mat-select formControlName="childSex" data-cy="child-sex-boadicea">
                      <mat-option value="male">Male</mat-option>
                      <mat-option data-cy="child-female-dropdown" value="female">Female</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <label>
                  <button (click)="removeChild(i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </label>
                <br /><br />
              </div>
            </ng-container>

            <button
              mat-raised-button
              class="positive-button"
              data-cy="add-child-btn"
              (click)="addChild()"
            >
              Add child
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div *ngIf="sex.value === 'F'">
      <br />
      <mat-card>
        <mat-card-title>Breast Screening</mat-card-title>
        <mat-card-content>
          <div class="question-container">
            <mat-radio-group
              class="full-width-field"
              appearance="fill"
              formControlName="haveYouHadAMammogram"
              (change)="hadMammogramToggle()"
            >
              <mat-label>Have you ever had a mammogram?</mat-label>
              <br />
              <mat-radio-button data-cy="mammogram-yes-radio" value="yes">Yes</mat-radio-button>
              <mat-radio-button value="no">No</mat-radio-button>
              <mat-radio-button value="na">Unknown</mat-radio-button>
            </mat-radio-group>
          </div>
          <div *ngIf="haveYouHadAMammogram.value === 'yes'">
            <div class="question-container">
              <mat-radio-group
                class="full-width-field"
                appearance="fill"
                formControlName="doYouKnowYourBreastDensity"
              >
                <mat-label
                  >Some women may have been told about their breast density. Were you?</mat-label
                >
                <br />
                <mat-radio-button data-cy="br-density-yes-radio" value="yes">Yes</mat-radio-button>
                <mat-radio-button value="no">No</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div *ngIf="doYouKnowYourBreastDensity.value === 'yes'">
            <div class="question-container">
              <mat-radio-group
                class="full-width-field"
                appearance="fill"
                formControlName="breastDensityBiRads"
              >
                <mat-label>Was breast density measured using BI-RADS?</mat-label>
                <br />
                <mat-radio-button data-cy="bi-rads-yes-radio" value="yes">Yes</mat-radio-button>
                <mat-radio-button value="no">No</mat-radio-button>
                <mat-radio-button value="na">Unknown</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div *ngIf="breastDensityBiRads.value === 'yes'">
            <div class="question-container">
              <div class="half-width-field">
                <p>What was the result?</p>
              </div>
              <mat-form-field class="half-width-field" appearance="fill">
                <mat-select formControlName="biRadsCategory" data-cy="bi-rads-category">
                  <mat-option value="a">BI-RADS a</mat-option>
                  <mat-option value="b">BI-RADS b</mat-option>
                  <mat-option value="c">BI-RADS c</mat-option>
                  <mat-option value="d">BI-RADS d</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <br />
    <mat-card>
      <mat-card-title>Medical History</mat-card-title>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Have you ever been diagnosed with endometriosis?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="endometriosisHistory">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="unknown">Unknown</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Have you had your tubes tied (tubal ligation)?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="tubalLigationHistory" data-cy="tubal-ligation-history">
              <mat-option data-cy="tl-yes-dropdown" value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="unknown">Unknown</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <br />

        <h3>Have you ever had any of the following operations?</h3>
        <div class="question-container">
          <mat-radio-group
            class="full-width-field"
            appearance="fill"
            formControlName="oophorectomy"
          >
            <mat-label>Both ovaries removed (Oophorectomy)</mat-label>
            <br />
            <mat-radio-button value="yes">Yes</mat-radio-button>
            <mat-radio-button value="no">No</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="question-container">
          <mat-radio-group class="full-width-field" appearance="fill" formControlName="mastectomy">
            <mat-label>Both breasts removed (Mastectomy)</mat-label>
            <br />
            <mat-radio-button value="yes">Yes</mat-radio-button>
            <mat-radio-button value="no">No</mat-radio-button>
          </mat-radio-group>
        </div>

        <h3>Have you ever had any of these cancers?</h3>
        <div class="question-container">
          <div class="half-width-field">
            <p><strong>Breast cancer in one breast</strong></p>
            <p>Please exclude Ductal Carcinoma-In-Situ (DCIS)</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select
              formControlName="breastCancerFirstBreast"
              (selectionChange)="clearBc1AgeField()"
            >
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="breastCancerFirstBreast.value === 'yes'">
          <div class="question-container">
            <div class="half-width-field">
              <p>Age of diagnosis:</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <input matInput type="number" formControlName="firstBreastCancerDiagnosisAge" />
            </mat-form-field>
          </div>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>
              <b
                >Breast cancer in the second breast (contralateral breast cancer) each breast had
                its own cancer</b
              >
            </p>
            <p>Please exclude Ductal Carcinoma-In-Situ (DCIS)</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select
              formControlName="breastCancerSecondBreast"
              (selectionChange)="clearBc2AgeField()"
            >
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="breastCancerSecondBreast.value === 'yes'">
          <div class="question-container">
            <div class="half-width-field">
              <p>Age of diagnosis:</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <input matInput type="number" formControlName="secondBreastCancerDiagnosisAge" />
            </mat-form-field>
          </div>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p><strong>Ovarian cancer</strong></p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="ovarianCancer" (selectionChange)="clearOvcAgeField()">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="ovarianCancer.value === 'yes'">
          <div class="question-container">
            <div class="half-width-field">
              <p>Age of diagnosis:</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <input matInput type="number" formControlName="ovarianCancerDiagnosisAge" />
            </mat-form-field>
          </div>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p><strong>Pancreatic cancer</strong></p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="pancreaticCancer" (selectionChange)="clearPancAgeField()">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="pancreaticCancer.value === 'yes'">
          <div class="question-container">
            <div class="half-width-field">
              <p>Age of diagnosis:</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <input matInput type="number" formControlName="pancreaticCancerDiagnosisAge" />
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-title>Family History</mat-card-title>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Have you had a family history of breast cancer?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select
              formControlName="familyHistoryOfCancer"
              data-cy="family-history-of-cancer"
              (selectionChange)="familyToggle($event)"
            >
              <mat-option data-cy="first-deg-fam-history-yes" value="yes">Yes</mat-option>
              <mat-option data-cy="first-deg-fam-history-no" value="no">No</mat-option>
              <mat-option value="unknown">Unknown</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <div *ngIf="familyHistoryOfCancer.value === 'yes'">
      <br />
      <mat-card>
        <mat-card-title>Immediate Family (blood relatives only)</mat-card-title>
        <mat-card-content>
          <ng-container
            *ngFor="
              let userFormGroup of boadiceaForm.get('immediateFamily')['controls'];
              let i = index
            "
          >
            <div [formGroup]="userFormGroup">
              <br />
              <h3 *ngIf="userFormGroup.get('relation').value === 'mother'">Mother</h3>
              <h3 *ngIf="userFormGroup.get('relation').value === 'father'">Father</h3>
              <h3 *ngIf="userFormGroup.get('relation').value === 'sister'">Sister</h3>
              <h3 *ngIf="userFormGroup.get('relation').value === 'brother'">Brother</h3>
              <h3 *ngIf="userFormGroup.get('relation').value === 'daughter'">Daughter</h3>
              <h3 *ngIf="userFormGroup.get('relation').value === 'son'">Son</h3>
              <div class="question-container">
                <div class="half-width-field">
                  <p>
                    Has your
                    <strong>
                      <span *ngIf="userFormGroup.get('relation').value === 'mother'">Mother </span>
                      <span *ngIf="userFormGroup.get('relation').value === 'father'">Father </span>
                      <span *ngIf="userFormGroup.get('relation').value === 'sister'">Sister </span>
                      <span *ngIf="userFormGroup.get('relation').value === 'brother'"
                        >Brother
                      </span>
                      <span *ngIf="userFormGroup.get('relation').value === 'daughter'"
                        >Daughter
                      </span>
                      <span *ngIf="userFormGroup.get('relation').value === 'son'"
                        >Son
                      </span> </strong
                    >had cancer?
                  </p>
                </div>
                <mat-form-field class="half-width-field" appearance="fill">
                  <mat-select
                    formControlName="hadCancer"
                    (selectionChange)="setValidatorForMember($event, userFormGroup)"
                  >
                    <mat-option data-cy="had-cancer-yes-dropdown" value="yes">Yes</mat-option>
                    <mat-option value="no">No</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="userFormGroup.get('hadCancer').value === 'yes'">
                <div class="question-container">
                  <div class="half-width-field">
                    <p>
                      <strong>
                        <span *ngIf="userFormGroup.get('relation').value === 'mother'"
                          >Mother
                        </span>
                        <span *ngIf="userFormGroup.get('relation').value === 'father'"
                          >Father
                        </span>
                        <span *ngIf="userFormGroup.get('relation').value === 'sister'"
                          >Sister
                        </span>
                        <span *ngIf="userFormGroup.get('relation').value === 'brother'"
                          >Brother
                        </span>
                        <span *ngIf="userFormGroup.get('relation').value === 'daughter'"
                          >Daughter
                        </span>
                        <span *ngIf="userFormGroup.get('relation').value === 'son'"
                          >Son
                        </span></strong
                      >
                      's year of birth:
                    </p>
                  </div>
                  <mat-form-field class="half-width-field" appearance="fill">
                    <input
                      matInput
                      type="number"
                      formControlName="YOB"
                      placeholder="YYYY"
                      min="1900"
                    />
                    <mat-error> Please enter a valid year of birth. </mat-error>
                  </mat-form-field>
                </div>
                <div class="question-container">
                  <div class="half-width-field">
                    <p>Alive or deceased?:</p>
                  </div>
                  <mat-radio-group
                    class="half-width-field"
                    appearance="fill"
                    formControlName="livingStatus"
                    [ngStyle]="{
                      color:
                        userFormGroup.controls.livingStatus.touched &&
                        userFormGroup.controls.livingStatus.invalid
                          ? '#ee7324'
                          : null
                    }"
                  >
                    <mat-radio-button data-cy="alive-radio" value="alive">Alive</mat-radio-button>
                    <mat-radio-button value="deceased">Deceased</mat-radio-button>
                    <p
                      *ngIf="
                        userFormGroup.controls.livingStatus.touched &&
                        userFormGroup.controls.livingStatus.invalid
                      "
                      class="mat-error"
                    >
                      Please make a selection
                    </p>
                  </mat-radio-group>
                </div>
                <div class="question-container">
                  <div class="half-width-field">
                    <p>Do they have any Ashkenazi Jewish ancestors?:</p>
                  </div>
                  <mat-radio-group
                    class="half-width-field"
                    appearance="fill"
                    formControlName="ashkn"
                    [ngStyle]="{
                      color:
                        userFormGroup.controls.ashkn.touched && userFormGroup.controls.ashkn.invalid
                          ? '#ee7324'
                          : null
                    }"
                  >
                    <mat-radio-button value="yes">Ashkenazi</mat-radio-button>
                    <mat-radio-button data-cy="non-ashken-radio" value="no"
                      >Not Ashkenazi</mat-radio-button
                    >
                    <p
                      *ngIf="
                        userFormGroup.controls.livingStatus.touched &&
                        userFormGroup.controls.livingStatus.invalid
                      "
                      class="mat-error"
                    >
                      Please make a selection
                    </p>
                  </mat-radio-group>
                </div>

                <h4>Types of cancers</h4>
                <div class="question-container">
                  <div class="half-width-field">
                    <p>
                      Did your
                      <strong>
                        <span *ngIf="userFormGroup.get('relation').value === 'mother'"
                          >Mother
                        </span>
                        <span *ngIf="userFormGroup.get('relation').value === 'father'"
                          >Father
                        </span>
                        <span *ngIf="userFormGroup.get('relation').value === 'sister'"
                          >Sister
                        </span>
                        <span *ngIf="userFormGroup.get('relation').value === 'brother'"
                          >Brother
                        </span>
                        <span *ngIf="userFormGroup.get('relation').value === 'daughter'"
                          >Daughter
                        </span>
                        <span *ngIf="userFormGroup.get('relation').value === 'son'">Son </span>
                      </strong>
                      have breast cancer?:
                    </p>
                  </div>
                  <mat-radio-group
                    class="half-width-field"
                    appearance="fill"
                    formControlName="hadBreastCancer"
                  >
                    <mat-radio-button data-cy="yes-breast-cancer-radio" value="yes"
                      >Yes</mat-radio-button
                    >
                    <mat-radio-button value="no">No</mat-radio-button>
                  </mat-radio-group>
                </div>
                <div *ngIf="userFormGroup.get('hadBreastCancer').value === 'yes'">
                  <div class="question-container">
                    <div class="half-width-field">
                      <p>Breast cancer diagnosis age:</p>
                    </div>
                    <mat-form-field class="half-width-field" appearance="fill">
                      <input
                        matInput
                        type="number"
                        formControlName="breastCancerAge"
                        placeholder="Age of diagnosis"
                      />
                    </mat-form-field>
                  </div>
                  <div class="question-container">
                    <div class="half-width-field">
                      <p>
                        Did your
                        <strong>
                          <span *ngIf="userFormGroup.get('relation').value === 'mother'"
                            >Mother
                          </span>
                          <span *ngIf="userFormGroup.get('relation').value === 'father'"
                            >Father
                          </span>
                          <span *ngIf="userFormGroup.get('relation').value === 'sister'"
                            >Sister
                          </span>
                          <span *ngIf="userFormGroup.get('relation').value === 'brother'"
                            >Brother
                          </span>
                          <span *ngIf="userFormGroup.get('relation').value === 'daughter'"
                            >Daughter
                          </span>
                          <span *ngIf="userFormGroup.get('relation').value === 'son'">Son </span>
                        </strong>
                        have cancer in the other breast?:
                      </p>
                    </div>
                    <mat-radio-group
                      class="half-width-field"
                      appearance="fill"
                      formControlName="hadCancerInOtherBreast"
                    >
                      <mat-radio-button value="yes">Yes</mat-radio-button>
                      <mat-radio-button value="no">No</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div *ngIf="userFormGroup.get('hadCancerInOtherBreast').value === 'yes'">
                    <div class="question-container">
                      <div class="half-width-field">
                        <p>Contralateral Breast cancer diagnosis age:</p>
                      </div>
                      <mat-form-field class="half-width-field" appearance="fill">
                        <input
                          matInput
                          type="number"
                          formControlName="otherBreastCancerAge"
                          placeholder="Age of diagnosis"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="question-container">
                  <div class="half-width-field">
                    <p>
                      Did your
                      <strong>
                        <span *ngIf="userFormGroup.get('relation').value === 'mother'"
                          >Mother
                        </span>
                        <span *ngIf="userFormGroup.get('relation').value === 'father'"
                          >Father
                        </span>
                        <span *ngIf="userFormGroup.get('relation').value === 'sister'"
                          >Sister
                        </span>
                        <span *ngIf="userFormGroup.get('relation').value === 'brother'"
                          >Brother
                        </span>
                        <span *ngIf="userFormGroup.get('relation').value === 'daughter'"
                          >Daughter
                        </span>
                        <span *ngIf="userFormGroup.get('relation').value === 'son'">Son </span>
                      </strong>
                      have Ovarian cancer?:
                    </p>
                  </div>
                  <mat-radio-group
                    class="half-width-field"
                    appearance="fill"
                    formControlName="hadOvarianCancer"
                  >
                    <mat-radio-button data-cy="yes-ov-cancer-radio" value="yes"
                      >Yes</mat-radio-button
                    >
                    <mat-radio-button value="no">No</mat-radio-button>
                  </mat-radio-group>
                </div>
                <div *ngIf="userFormGroup.get('hadOvarianCancer').value === 'yes'">
                  <div class="question-container">
                    <div class="half-width-field">
                      <p>Ovarian cancer diagnosis age:</p>
                    </div>
                    <mat-form-field class="half-width-field" appearance="fill">
                      <input
                        matInput
                        type="number"
                        formControlName="ovarianCancerAge"
                        placeholder="Age of diagnosis"
                      />
                    </mat-form-field>
                  </div>
                </div>
                <div class="question-container">
                  <div class="half-width-field">
                    <p>
                      Did your
                      <strong>
                        <span *ngIf="userFormGroup.get('relation').value === 'mother'"
                          >Mother
                        </span>
                        <span *ngIf="userFormGroup.get('relation').value === 'father'"
                          >Father
                        </span>
                        <span *ngIf="userFormGroup.get('relation').value === 'sister'"
                          >Sister
                        </span>
                        <span *ngIf="userFormGroup.get('relation').value === 'brother'"
                          >Brother
                        </span>
                        <span *ngIf="userFormGroup.get('relation').value === 'daughter'"
                          >Daughter
                        </span>
                        <span *ngIf="userFormGroup.get('relation').value === 'son'">Son</span>
                      </strong>
                      have Pancreatic cancer?:
                    </p>
                  </div>
                  <mat-radio-group
                    class="half-width-field"
                    appearance="fill"
                    formControlName="hadPancreaCancer"
                  >
                    <mat-radio-button data-cy="yesPancCancerRadio" value="yes"
                      >Yes</mat-radio-button
                    >
                    <mat-radio-button value="no">No</mat-radio-button>
                  </mat-radio-group>
                </div>
                <div *ngIf="userFormGroup.get('hadPancreaCancer').value === 'yes'">
                  <div class="question-container">
                    <div class="half-width-field">
                      <p>Pancreatic cancer diagnosis age:</p>
                    </div>
                    <mat-form-field class="half-width-field" appearance="fill">
                      <input
                        matInput
                        type="number"
                        formControlName="pancreaCancerAge"
                        placeholder="Age of diagnosis"
                      />
                    </mat-form-field>
                  </div>
                </div>
                <div
                  *ngIf="
                    userFormGroup.get('relation').value === 'father' ||
                    userFormGroup.get('relation').value === 'brother' ||
                    userFormGroup.get('relation').value === 'son'
                  "
                >
                  <div class="question-container">
                    <div class="half-width-field">
                      <p>
                        Did your
                        <strong>
                          <span *ngIf="userFormGroup.get('relation').value === 'father'"
                            >Father
                          </span>
                          <span *ngIf="userFormGroup.get('relation').value === 'brother'"
                            >Brother
                          </span>
                          <span *ngIf="userFormGroup.get('relation').value === 'son'">Son </span>
                        </strong>
                        have Prostate cancer?:
                      </p>
                    </div>
                    <mat-radio-group
                      class="half-width-field"
                      appearance="fill"
                      formControlName="hadProstateCancer"
                    >
                      <mat-radio-button value="yes">Yes</mat-radio-button>
                      <mat-radio-button value="no">No</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div *ngIf="userFormGroup.get('hadProstateCancer').value === 'yes'">
                    <div class="question-container">
                      <div class="half-width-field">
                        <p>Prostate cancer diagnosis age:</p>
                      </div>
                      <mat-form-field class="half-width-field" appearance="fill">
                        <input
                          matInput
                          type="number"
                          formControlName="prostateCancerAge"
                          placeholder="Age of diagnosis"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div class="question-container">
                  <div class="half-width-field">
                    <p>
                      Has your
                      <strong>
                        <span *ngIf="userFormGroup.get('relation').value === 'mother'"
                          >Mother
                        </span>
                        <span *ngIf="userFormGroup.get('relation').value === 'father'"
                          >Father
                        </span>
                        <span *ngIf="userFormGroup.get('relation').value === 'sister'"
                          >Sister
                        </span>
                        <span *ngIf="userFormGroup.get('relation').value === 'brother'"
                          >Brother
                        </span>
                        <span *ngIf="userFormGroup.get('relation').value === 'daughter'"
                          >Daughter
                        </span>
                        <span *ngIf="userFormGroup.get('relation').value === 'son'">Son </span>
                      </strong>
                      had a genetic test?
                    </p>
                  </div>
                  <mat-form-field class="half-width-field" appearance="fill">
                    <mat-select formControlName="geneTest">
                      <mat-option data-cy="gene-test-yes-dropdown" value="yes">Yes</mat-option>
                      <mat-option value="no">No</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div *ngIf="userFormGroup.get('geneTest').value === 'yes'">
                  <br />
                  <h3>Gene Tests (Leave result blank if it doesn't apply to you)</h3>
                  <div class="question-container">
                    <div class="half-width-field">
                      <p>BRCA1 Test Type:</p>
                    </div>
                    <div class="half-width-field">
                      <p>BRCA1 Result:</p>
                    </div>
                    <mat-form-field class="half-width-field" appearance="fill">
                      <mat-select formControlName="brca1TestType">
                        <mat-option value="untested">Untested</mat-option>
                        <mat-option value="mutation">Mutation search</mat-option>
                        <mat-option value="direct">Direct Gene Test</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="half-width-field" appearance="fill">
                      <mat-select formControlName="brca1Result" placeholder="Result">
                        <mat-option value="unknown">Unknown</mat-option>
                        <mat-option data-cy="brac-1-positive-dropdown" value="mutPresent"
                          >Positive / Mutation present</mat-option
                        >
                        <mat-option value="mutAbsent">Negative / Mutation absent</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="question-container">
                    <div class="half-width-field">
                      <p>BRCA2 Test Type:</p>
                    </div>
                    <div class="half-width-field">
                      <p>BRCA2 Result:</p>
                    </div>
                    <mat-form-field class="half-width-field" appearance="fill">
                      <mat-select formControlName="brca2TestType">
                        <mat-option value="untested">Untested</mat-option>
                        <mat-option value="mutation">Mutation search</mat-option>
                        <mat-option value="direct">Direct Gene Test</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="half-width-field" appearance="fill">
                      <mat-select formControlName="brca2Result" placeholder="Result">
                        <mat-option value="unknown">Unknown</mat-option>
                        <mat-option value="mutPresent">Positive / Mutation present</mat-option>
                        <mat-option data-cy="brac-2-neg-dropdown" value="mutAbsent"
                          >Negative / Mutation absent</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="question-container">
                    <div class="half-width-field">
                      <p>PALB2 Test Type:</p>
                    </div>
                    <div class="half-width-field">
                      <p>PALB2 Result:</p>
                    </div>
                    <mat-form-field class="half-width-field" appearance="fill">
                      <mat-select formControlName="palb2TestType">
                        <mat-option value="untested">Untested</mat-option>
                        <mat-option value="mutation">Mutation search</mat-option>
                        <mat-option value="direct">Direct Gene Test</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="half-width-field" appearance="fill">
                      <mat-select formControlName="palb2Result" placeholder="Result">
                        <mat-option value="unknown">Unknown</mat-option>
                        <mat-option value="mutPresent">Positive / Mutation present</mat-option>
                        <mat-option value="mutAbsent">Negative / Mutation absent</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="question-container">
                    <div class="half-width-field">
                      <p>CHEK2 Test Type:</p>
                    </div>
                    <div class="half-width-field">
                      <p>CHEK2 Result:</p>
                    </div>
                    <mat-form-field class="half-width-field" appearance="fill">
                      <mat-select formControlName="chek2TestType">
                        <mat-option value="untested">Untested</mat-option>
                        <mat-option value="mutation">Mutation search</mat-option>
                        <mat-option value="direct">Direct Gene Test</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="half-width-field" appearance="fill">
                      <mat-select formControlName="chek2Result" placeholder="Result">
                        <mat-option value="unknown">Unknown</mat-option>
                        <mat-option value="mutPresent">Positive / Mutation present</mat-option>
                        <mat-option value="mutAbsent">Negative / Mutation absent</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="question-container">
                    <div class="half-width-field">
                      <p>ATM Test Type:</p>
                    </div>
                    <div class="half-width-field">
                      <p>ATM Result:</p>
                    </div>
                    <mat-form-field class="half-width-field" appearance="fill">
                      <mat-select formControlName="atmTestType">
                        <mat-option value="untested">Untested</mat-option>
                        <mat-option value="mutation">Mutation search</mat-option>
                        <mat-option value="direct">Direct Gene Test</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="half-width-field" appearance="fill">
                      <mat-select formControlName="atmResult" placeholder="Result">
                        <mat-option value="unknown">Unknown</mat-option>
                        <mat-option data-cy="atm-positive-dropdown" value="mutPresent"
                          >Positive / Mutation present</mat-option
                        >
                        <mat-option value="mutAbsent">Negative / Mutation absent</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="question-container">
                    <div class="half-width-field">
                      <p>RAD51D Test Type:</p>
                    </div>
                    <div class="half-width-field">
                      <p>RAD51D Result:</p>
                    </div>
                    <mat-form-field class="half-width-field" appearance="fill">
                      <mat-select formControlName="rad51dTestType">
                        <mat-option value="untested">Untested</mat-option>
                        <mat-option value="mutation">Mutation search</mat-option>
                        <mat-option value="direct">Direct Gene Test</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="half-width-field" appearance="fill">
                      <mat-select formControlName="rad51dResult" placeholder="Result">
                        <mat-option value="unknown">Unknown</mat-option>
                        <mat-option value="mutPresent">Positive / Mutation present</mat-option>
                        <mat-option value="mutAbsent">Negative / Mutation absent</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="question-container">
                    <div class="half-width-field">
                      <p>RAD51C Test Type:</p>
                    </div>
                    <div class="half-width-field">
                      <p>RAD51C Result:</p>
                    </div>
                    <mat-form-field class="half-width-field" appearance="fill">
                      <mat-select formControlName="rad51cTestType">
                        <mat-option value="untested">Untested</mat-option>
                        <mat-option value="mutation">Mutation search</mat-option>
                        <mat-option value="direct">Direct Gene Test</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="half-width-field" appearance="fill">
                      <mat-select formControlName="rad51cResult" placeholder="Result">
                        <mat-option value="unknown">Unknown</mat-option>
                        <mat-option value="mutPresent">Positive / Mutation present</mat-option>
                        <mat-option value="mutAbsent">Negative / Mutation absent</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="question-container">
                    <div class="half-width-field">
                      <p>BRIP1 Test Type:</p>
                    </div>
                    <div class="half-width-field">
                      <p>BRIP1 Result:</p>
                    </div>
                    <mat-form-field class="half-width-field" appearance="fill">
                      <mat-select formControlName="brip1TestType">
                        <mat-option value="untested">Untested</mat-option>
                        <mat-option value="mutation">Mutation search</mat-option>
                        <mat-option value="direct">Direct Gene Test</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="half-width-field" appearance="fill">
                      <mat-select formControlName="brip1Result" placeholder="Result">
                        <mat-option value="unknown">Unknown</mat-option>
                        <mat-option value="mutPresent">Positive / Mutation present</mat-option>
                        <mat-option value="mutAbsent">Negative / Mutation absent</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div *ngIf="i > 3">
                <br />
                <label>
                  <button (click)="removeImmediateFamilyMember(i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </label>
                <br /><br />
              </div>
            </div>
          </ng-container>
          <br />
          <hr class="rounded" />
          <br />
          <div class="question-container">
            <div class="full-width-field">
              <h3>Has another immediate family member been diagnosed with cancer?</h3>
            </div>
            <mat-form-field class="full-width-field" appearance="fill">
              <mat-select formControlName="addRelation">
                <mat-option value="no">No</mat-option>
                <mat-option data-cy="add-sis-dropdown" value="2">Sister</mat-option>
                <mat-option value="3">Brother</mat-option>
                <mat-option *ngIf="hadChildren.value === 'yes'" value="4">Daughter</mat-option>
                <mat-option *ngIf="hadChildren.value === 'yes'" value="5">Son</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="addRelation.value !== 'no'">
            <button
              mat-raised-button
              class="positive-button"
              data-cy="add-relation-btn"
              (click)="addImmediateFamily(addRelation.value)"
            >
              Add
            </button>
          </div>
          <br />
        </mat-card-content>
      </mat-card>
      <br />
      <mat-card>
        <mat-card-title>Extended Family (blood relatives only)</mat-card-title>
        <mat-card-content>
          <div class="question-container">
            <div class="half-width-field">
              <p>Is there a history of cancer within your extended family?</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <mat-select
                formControlName="extendedFamilyHistory"
                (selectionChange)="extendedFamilyToggle($event)"
              >
                <mat-option data-cy="secDegFamHistoryYes" value="yes">Yes</mat-option>
                <mat-option value="no">No</mat-option>
                <mat-option value="unknown">Unknown</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="extendedFamilyHistory.value === 'yes'">
            <ng-container
              *ngFor="
                let userFormGroup of boadiceaForm.get('extendedFamily')['controls'];
                let i = index
              "
            >
              <div [formGroup]="userFormGroup">
                <h3
                  *ngIf="
                    userFormGroup.get('relation').value === 'grandmother' &&
                    userFormGroup.get('sideOfFamily').value === 'mother'
                  "
                >
                  Grandmother (Mother side)
                </h3>
                <h3
                  *ngIf="
                    userFormGroup.get('relation').value === 'grandmother' &&
                    userFormGroup.get('sideOfFamily').value === 'father'
                  "
                >
                  Grandmother (Father side)
                </h3>
                <h3
                  *ngIf="
                    userFormGroup.get('relation').value === 'grandfather' &&
                    userFormGroup.get('sideOfFamily').value === 'mother'
                  "
                >
                  Grandfather (Mother side)
                </h3>
                <h3
                  *ngIf="
                    userFormGroup.get('relation').value === 'grandfather' &&
                    userFormGroup.get('sideOfFamily').value === 'father'
                  "
                >
                  Grandfather (Father side)
                </h3>
                <h3 *ngIf="userFormGroup.get('relation').value === 'aunt'">Aunt</h3>
                <h3 *ngIf="userFormGroup.get('relation').value === 'uncle'">Uncle</h3>
                <h3 *ngIf="userFormGroup.get('relation').value === 'cousin'">Cousin</h3>
                <div class="question-container">
                  <div class="half-width-field">
                    <p>
                      Has your
                      <strong>
                        <span *ngIf="userFormGroup.get('relation').value === 'grandmother'"
                          >Grandmother
                        </span>
                        <span *ngIf="userFormGroup.get('relation').value === 'grandfather'"
                          >Grandfather
                        </span>
                        <span *ngIf="userFormGroup.get('relation').value === 'aunt'">Aunt </span>
                        <span *ngIf="userFormGroup.get('relation').value === 'uncle'">Uncle </span>
                        <span *ngIf="userFormGroup.get('relation').value === 'cousin'"
                          >Cousin
                        </span> </strong
                      >had cancer?
                    </p>
                  </div>
                  <mat-form-field class="half-width-field" appearance="fill">
                    <mat-select
                      formControlName="hadCancer"
                      (selectionChange)="setValidatorForMember($event, userFormGroup)"
                    >
                      <mat-option data-cy="had-cancer-yes-dropdown" value="yes">Yes</mat-option>
                      <mat-option value="no">No</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div *ngIf="userFormGroup.get('hadCancer').value === 'yes'">
                  <div *ngIf="userFormGroup.get('relation').value === 'cousin'">
                    <div class="question-container">
                      <div class="half-width-field">
                        <p>Gender</p>
                      </div>
                      <mat-form-field class="half-width-field" appearance="fill">
                        <mat-select formControlName="gender" placeholder="Gender">
                          <mat-option value="male">Male</mat-option>
                          <mat-option value="female">Female</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div
                    *ngIf="
                      userFormGroup.get('relation').value !== 'grandmother' &&
                      userFormGroup.get('relation').value !== 'grandfather'
                    "
                  >
                    <div class="question-container">
                      <div class="half-width-field">
                        <p>Which side of the family?:</p>
                      </div>
                      <mat-radio-group
                        class="half-width-field"
                        appearance="fill"
                        formControlName="sideOfFamily"
                      >
                        <mat-radio-button data-cy="mother-side-radio" value="mother"
                          >Mother's Side</mat-radio-button
                        >
                        <mat-radio-button value="father">Father's Side</mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                  <div class="question-container">
                    <div class="half-width-field">
                      <p>
                        <strong>
                          <span *ngIf="userFormGroup.get('relation').value === 'grandmother'"
                            >Grandmother
                          </span>
                          <span *ngIf="userFormGroup.get('relation').value === 'grandfather'"
                            >Grandfather
                          </span>
                          <span *ngIf="userFormGroup.get('relation').value === 'aunt'">Aunt </span>
                          <span *ngIf="userFormGroup.get('relation').value === 'uncle'"
                            >Uncle
                          </span>
                          <span *ngIf="userFormGroup.get('relation').value === 'cousin'"
                            >Cousin
                          </span>
                        </strong>
                        's year of birth:
                      </p>
                    </div>
                    <mat-form-field class="half-width-field" appearance="fill">
                      <input
                        matInput
                        type="number"
                        formControlName="YOB"
                        placeholder="YYYY"
                        min="1900"
                      />
                      <mat-error> Please enter a valid year of birth. </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="question-container">
                    <div class="half-width-field">
                      <p>Alive or deceased?:</p>
                    </div>
                    <mat-radio-group
                      class="half-width-field"
                      appearance="fill"
                      formControlName="livingStatus"
                    >
                      <mat-radio-button data-cy="alive-radio" value="alive">Alive</mat-radio-button>
                      <mat-radio-button data-cy="deadRadio" value="deceased"
                        >Deceased</mat-radio-button
                      >
                    </mat-radio-group>
                  </div>
                  <div class="question-container">
                    <div class="half-width-field">
                      <p>Do they have any Ashkenazi Jewish ancestors?:</p>
                    </div>
                    <mat-radio-group
                      class="half-width-field"
                      appearance="fill"
                      formControlName="ashkn"
                    >
                      <mat-radio-button value="yes">Ashkenazi</mat-radio-button>
                      <mat-radio-button data-cy="non-ashken-radio" value="no"
                        >Not Ashkenazi</mat-radio-button
                      >
                    </mat-radio-group>
                  </div>

                  <h4>Types of cancers</h4>
                  <div class="question-container">
                    <div class="half-width-field">
                      <p>
                        Did your
                        <strong>
                          <span *ngIf="userFormGroup.get('relation').value === 'grandmother'"
                            >Grandmother
                          </span>
                          <span *ngIf="userFormGroup.get('relation').value === 'grandfather'"
                            >Grandfather
                          </span>
                          <span *ngIf="userFormGroup.get('relation').value === 'aunt'">Aunt </span>
                          <span *ngIf="userFormGroup.get('relation').value === 'uncle'"
                            >Uncle
                          </span>
                          <span *ngIf="userFormGroup.get('relation').value === 'cousin'"
                            >Cousin
                          </span>
                        </strong>
                        have breast cancer?:
                      </p>
                    </div>
                    <mat-radio-group
                      class="half-width-field"
                      appearance="fill"
                      formControlName="hadBreastCancer"
                    >
                      <mat-radio-button data-cy="yes-breast-cancer-radio" value="yes"
                        >Yes</mat-radio-button
                      >
                      <mat-radio-button value="no">No</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div *ngIf="userFormGroup.get('hadBreastCancer').value === 'yes'">
                    <div class="question-container">
                      <div class="half-width-field">
                        <p>Breast cancer diagnosis age:</p>
                      </div>
                      <mat-form-field class="half-width-field" appearance="fill">
                        <input
                          matInput
                          type="number"
                          formControlName="breastCancerAge"
                          placeholder="Age of diagnosis"
                        />
                      </mat-form-field>
                    </div>
                    <div class="question-container">
                      <div class="half-width-field">
                        <p>
                          Did your
                          <strong>
                            <span *ngIf="userFormGroup.get('relation').value === 'grandmother'"
                              >Grandmother
                            </span>
                            <span *ngIf="userFormGroup.get('relation').value === 'grandfather'"
                              >Grandfather
                            </span>
                            <span *ngIf="userFormGroup.get('relation').value === 'aunt'"
                              >Aunt
                            </span>
                            <span *ngIf="userFormGroup.get('relation').value === 'uncle'"
                              >Uncle
                            </span>
                            <span *ngIf="userFormGroup.get('relation').value === 'cousin'"
                              >Cousin
                            </span>
                          </strong>
                          have cancer in the other breast?:
                        </p>
                      </div>
                      <mat-radio-group
                        class="half-width-field"
                        appearance="fill"
                        formControlName="hadCancerInOtherBreast"
                      >
                        <mat-radio-button value="yes">Yes</mat-radio-button>
                        <mat-radio-button value="no">No</mat-radio-button>
                      </mat-radio-group>
                    </div>
                    <div *ngIf="userFormGroup.get('hadCancerInOtherBreast').value === 'yes'">
                      <div class="question-container">
                        <div class="half-width-field">
                          <p>Contralateral Breast cancer diagnosis age:</p>
                        </div>
                        <mat-form-field class="half-width-field" appearance="fill">
                          <input
                            matInput
                            type="number"
                            formControlName="otherBreastCancerAge"
                            placeholder="Age of diagnosis"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="question-container">
                    <div class="half-width-field">
                      <p>
                        Did your
                        <strong>
                          <span *ngIf="userFormGroup.get('relation').value === 'grandmother'"
                            >Grandmother
                          </span>
                          <span *ngIf="userFormGroup.get('relation').value === 'grandfather'"
                            >Grandfather
                          </span>
                          <span *ngIf="userFormGroup.get('relation').value === 'aunt'">Aunt </span>
                          <span *ngIf="userFormGroup.get('relation').value === 'uncle'"
                            >Uncle
                          </span>
                          <span *ngIf="userFormGroup.get('relation').value === 'cousin'"
                            >Cousin
                          </span>
                        </strong>
                        have Ovarian cancer?:
                      </p>
                    </div>
                    <mat-radio-group
                      class="half-width-field"
                      appearance="fill"
                      formControlName="hadOvarianCancer"
                    >
                      <mat-radio-button data-cy="yes-ov-cancer-radio" value="yes"
                        >Yes</mat-radio-button
                      >
                      <mat-radio-button value="no">No</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div *ngIf="userFormGroup.get('hadOvarianCancer').value === 'yes'">
                    <div class="question-container">
                      <div class="half-width-field">
                        <p>Ovarian cancer diagnosis age:</p>
                      </div>
                      <mat-form-field class="half-width-field" appearance="fill">
                        <input
                          matInput
                          type="number"
                          formControlName="ovarianCancerAge"
                          placeholder="Age of diagnosis"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="question-container">
                    <div class="half-width-field">
                      <p>
                        Did your
                        <strong>
                          <span *ngIf="userFormGroup.get('relation').value === 'grandmother'"
                            >Grandmother
                          </span>
                          <span *ngIf="userFormGroup.get('relation').value === 'grandfather'"
                            >Grandfather
                          </span>
                          <span *ngIf="userFormGroup.get('relation').value === 'aunt'">Aunt </span>
                          <span *ngIf="userFormGroup.get('relation').value === 'uncle'"
                            >Uncle
                          </span>
                          <span *ngIf="userFormGroup.get('relation').value === 'cousin'"
                            >Cousin
                          </span>
                        </strong>
                        have Pancreatic cancer?:
                      </p>
                    </div>
                    <mat-radio-group
                      class="half-width-field"
                      appearance="fill"
                      formControlName="hadPancreaCancer"
                    >
                      <mat-radio-button value="yes">Yes</mat-radio-button>
                      <mat-radio-button value="no">No</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div *ngIf="userFormGroup.get('hadPancreaCancer').value === 'yes'">
                    <div class="question-container">
                      <div class="half-width-field">
                        <p>Pancreatic cancer diagnosis age:</p>
                      </div>
                      <mat-form-field class="half-width-field" appearance="fill">
                        <input
                          matInput
                          type="number"
                          formControlName="pancreaCancerAge"
                          placeholder="Age of diagnosis"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                  <div *ngIf="userFormGroup.get('gender').value === 'male'">
                    <div class="question-container">
                      <div class="half-width-field">
                        <p>
                          Did your
                          <strong>
                            <span *ngIf="userFormGroup.get('relation').value === 'grandmother'"
                              >Grandmother
                            </span>
                            <span *ngIf="userFormGroup.get('relation').value === 'grandfather'"
                              >Grandfather
                            </span>
                            <span *ngIf="userFormGroup.get('relation').value === 'aunt'"
                              >Aunt
                            </span>
                            <span *ngIf="userFormGroup.get('relation').value === 'uncle'"
                              >Uncle
                            </span>
                            <span *ngIf="userFormGroup.get('relation').value === 'cousin'"
                              >Cousin
                            </span>
                          </strong>
                          have Prostate cancer?:
                        </p>
                      </div>
                      <mat-radio-group
                        class="half-width-field"
                        appearance="fill"
                        formControlName="hadProstateCancer"
                      >
                        <mat-radio-button data-cy="yesProsCancerRadio" value="yes"
                          >Yes</mat-radio-button
                        >
                        <mat-radio-button value="no">No</mat-radio-button>
                      </mat-radio-group>
                    </div>
                    <div *ngIf="userFormGroup.get('hadProstateCancer').value === 'yes'">
                      <div class="question-container">
                        <div class="half-width-field">
                          <p>Prostate cancer diagnosis age:</p>
                        </div>
                        <mat-form-field class="half-width-field" appearance="fill">
                          <input
                            matInput
                            type="number"
                            formControlName="prostateCancerAge"
                            placeholder="Age of diagnosis"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div class="question-container">
                    <div class="half-width-field">
                      <p>
                        Has your
                        <strong>
                          <span *ngIf="userFormGroup.get('relation').value === 'grandmother'"
                            >Grandmother
                          </span>
                          <span *ngIf="userFormGroup.get('relation').value === 'grandfather'"
                            >Grandfather
                          </span>
                          <span *ngIf="userFormGroup.get('relation').value === 'aunt'">Aunt </span>
                          <span *ngIf="userFormGroup.get('relation').value === 'uncle'"
                            >Uncle
                          </span>
                          <span *ngIf="userFormGroup.get('relation').value === 'cousin'"
                            >Cousin
                          </span>
                        </strong>
                        had a genetic test?
                      </p>
                    </div>
                    <mat-form-field class="half-width-field" appearance="fill">
                      <mat-select formControlName="geneTest">
                        <mat-option value="yes">Yes</mat-option>
                        <mat-option value="no">No</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div *ngIf="userFormGroup.get('geneTest').value === 'yes'">
                    <br />
                    <h3>Gene Tests (Leave result blank if it doesn't apply to you)</h3>
                    <div class="question-container">
                      <div class="half-width-field">
                        <p>BRCA1 Test Type:</p>
                      </div>
                      <div class="half-width-field">
                        <p>BRCA1 Result:</p>
                      </div>
                      <mat-form-field class="half-width-field" appearance="fill">
                        <mat-select formControlName="brca1TestType">
                          <mat-option value="untested">Untested</mat-option>
                          <mat-option value="mutation">Mutation search</mat-option>
                          <mat-option value="direct">Direct Gene Test</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="half-width-field" appearance="fill">
                        <mat-select formControlName="brca1Result" placeholder="Result">
                          <mat-option value="unknown">Unknown</mat-option>
                          <mat-option value="mutPresent">Positive / Mutation present</mat-option>
                          <mat-option value="mutAbsent">Negative / Mutation absent</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="question-container">
                      <div class="half-width-field">
                        <p>BRCA2 Test Type:</p>
                      </div>
                      <div class="half-width-field">
                        <p>BRCA2 Result:</p>
                      </div>
                      <mat-form-field class="half-width-field" appearance="fill">
                        <mat-select formControlName="brca2TestType">
                          <mat-option value="untested">Untested</mat-option>
                          <mat-option value="mutation">Mutation search</mat-option>
                          <mat-option value="direct">Direct Gene Test</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="half-width-field" appearance="fill">
                        <mat-select formControlName="brca2Result" placeholder="Result">
                          <mat-option value="unknown">Unknown</mat-option>
                          <mat-option value="mutPresent">Positive / Mutation present</mat-option>
                          <mat-option value="mutAbsent">Negative / Mutation absent</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="question-container">
                      <div class="half-width-field">
                        <p>PALB2 Test Type:</p>
                      </div>
                      <div class="half-width-field">
                        <p>PALB2 Result:</p>
                      </div>
                      <mat-form-field class="half-width-field" appearance="fill">
                        <mat-select formControlName="palb2TestType">
                          <mat-option value="untested">Untested</mat-option>
                          <mat-option value="mutation">Mutation search</mat-option>
                          <mat-option value="direct">Direct Gene Test</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="half-width-field" appearance="fill">
                        <mat-select formControlName="palb2Result" placeholder="Result">
                          <mat-option value="unknown">Unknown</mat-option>
                          <mat-option value="mutPresent">Positive / Mutation present</mat-option>
                          <mat-option value="mutAbsent">Negative / Mutation absent</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="question-container">
                      <div class="half-width-field">
                        <p>CHEK2 Test Type:</p>
                      </div>
                      <div class="half-width-field">
                        <p>CHEK2 Result:</p>
                      </div>
                      <mat-form-field class="half-width-field" appearance="fill">
                        <mat-select formControlName="chek2TestType">
                          <mat-option value="untested">Untested</mat-option>
                          <mat-option value="mutation">Mutation search</mat-option>
                          <mat-option value="direct">Direct Gene Test</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="half-width-field" appearance="fill">
                        <mat-select formControlName="chek2Result" placeholder="Result">
                          <mat-option value="unknown">Unknown</mat-option>
                          <mat-option value="mutPresent">Positive / Mutation present</mat-option>
                          <mat-option value="mutAbsent">Negative / Mutation absent</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="question-container">
                      <div class="half-width-field">
                        <p>ATM Test Type:</p>
                      </div>
                      <div class="half-width-field">
                        <p>ATM Result:</p>
                      </div>
                      <mat-form-field class="half-width-field" appearance="fill">
                        <mat-select formControlName="atmTestType">
                          <mat-option value="untested">Untested</mat-option>
                          <mat-option value="mutation">Mutation search</mat-option>
                          <mat-option value="direct">Direct Gene Test</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="half-width-field" appearance="fill">
                        <mat-select formControlName="atmResult" placeholder="Result">
                          <mat-option value="unknown">Unknown</mat-option>
                          <mat-option value="mutPresent">Positive / Mutation present</mat-option>
                          <mat-option value="mutAbsent">Negative / Mutation absent</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="question-container">
                      <div class="half-width-field">
                        <p>RAD51D Test Type:</p>
                      </div>
                      <div class="half-width-field">
                        <p>RAD51D Result:</p>
                      </div>
                      <mat-form-field class="half-width-field" appearance="fill">
                        <mat-select formControlName="rad51dTestType">
                          <mat-option value="untested">Untested</mat-option>
                          <mat-option value="mutation">Mutation search</mat-option>
                          <mat-option value="direct">Direct Gene Test</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="half-width-field" appearance="fill">
                        <mat-select formControlName="rad51dResult" placeholder="Result">
                          <mat-option value="unknown">Unknown</mat-option>
                          <mat-option value="mutPresent">Positive / Mutation present</mat-option>
                          <mat-option value="mutAbsent">Negative / Mutation absent</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="question-container">
                      <div class="half-width-field">
                        <p>RAD51C Test Type:</p>
                      </div>
                      <div class="half-width-field">
                        <p>RAD51C Result:</p>
                      </div>
                      <mat-form-field class="half-width-field" appearance="fill">
                        <mat-select formControlName="rad51cTestType">
                          <mat-option value="untested">Untested</mat-option>
                          <mat-option value="mutation">Mutation search</mat-option>
                          <mat-option value="direct">Direct Gene Test</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="half-width-field" appearance="fill">
                        <mat-select formControlName="rad51cResult" placeholder="Result">
                          <mat-option value="unknown">Unknown</mat-option>
                          <mat-option value="mutPresent">Positive / Mutation present</mat-option>
                          <mat-option value="mutAbsent">Negative / Mutation absent</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="question-container">
                      <div class="half-width-field">
                        <p>BRIP1 Test Type:</p>
                      </div>
                      <div class="half-width-field">
                        <p>BRIP1 Result:</p>
                      </div>
                      <mat-form-field class="half-width-field" appearance="fill">
                        <mat-select formControlName="brip1TestType">
                          <mat-option value="untested">Untested</mat-option>
                          <mat-option value="mutation">Mutation search</mat-option>
                          <mat-option value="direct">Direct Gene Test</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="half-width-field" appearance="fill">
                        <mat-select formControlName="brip1Result" placeholder="Result">
                          <mat-option value="unknown">Unknown</mat-option>
                          <mat-option value="mutPresent">Positive / Mutation present</mat-option>
                          <mat-option value="mutAbsent">Negative / Mutation absent</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div *ngIf="i > 5">
                  <br />
                  <label>
                    <button (click)="removeExtendedFamilyMember(i)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </label>
                  <br /><br />
                </div>
              </div>
            </ng-container>
            <br />
            <hr class="rounded" />
            <br />
            <div class="question-container">
              <div class="full-width-field">
                <h3>Has another extended family member been diagnosed with cancer?</h3>
              </div>
              <mat-form-field class="full-width-field" appearance="fill">
                <mat-select formControlName="addRelation">
                  <mat-option value="no">No</mat-option>
                  <mat-option data-cy="add-aunt-dropdown" value="2">Aunt</mat-option>
                  <mat-option value="3">Uncle</mat-option>
                  <!-- <mat-option value="4">Cousin</mat-option> -->
                </mat-select>
              </mat-form-field>
            </div>
            <div *ngIf="addRelation.value !== 'no'">
              <button
                mat-raised-button
                class="positive-button"
                data-cy="add-relation-btn"
                (click)="addExtendedFamily(addRelation.value)"
              >
                Add
              </button>
            </div>
            <br />
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <br />
    <mat-form-field class="full-width-field" appearance="fill">
      <mat-label>Any other comments?</mat-label>
      <textarea matInput formControlName="notes" type="notes"> </textarea>
    </mat-form-field>
    <br />
    <button
      mat-raised-button
      class="positive-button"
      data-cy="boadicea-submit-btn"
      type="submit"
      [disabled]="isSubmitting"
      [ngStyle]="{ backgroundColor: boadiceaForm.valid ? '#EE7325' : '#CFD4D4' }"
      (click)="onSubmit()"
    >
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
