<div *ngIf="user$ | async" class="flex flex-1 align-items-center justify-content-between p-2" data-cy="app-header">
  <div class="flex align-items-center gap-2">
    <p-button
      *ngIf="showMenuButton$ | async"
      (click)="layoutDefaultSidebarService.showSidebar = true"
      [attr.aria-label]="'Open sidebar button'"
      [rounded]="true"
      [text]="true"
      styleClass="text-main"
      icon="pi pi-bars"
    ></p-button>

    <div class="flex align-items-center gap-2 pl-2">
      <img
        [routerLink]="['/']"
        src="/assets/isla-logo-full.svg"
        class="h-2rem cursor-pointer"
        pTooltip="Homepage"
        data-cy="isla-logo"
        alt="Isla logo"
      />
      <img *ngIf="orgLogoUrl$ | async as orgLogoUrl" [src]="orgLogoUrl" class="h-3rem" alt="Organisation logo" />
    </div>
  </div>

  <ng-container *ngIf="(showMenuButton$ | async) === false">
    <nav class="flex align-items-center gap-1">
      <!-- Navigation tools (see getNavToolItems()) -->
      <ng-container *ngIf="navToolItems$ | async as navToolItems">
        <ng-container *ngFor="let item of navToolItems">
          <p-button
            (click)="navToolAction(item)"
            [pTooltip]="item.label + ' calculator'"
            [label]="item.label"
            [outlined]="true"
            [text]="true"
            tooltipPosition="bottom"
            class="p-button-nav"
          >
          </p-button>
        </ng-container>
      </ng-container>

      <!-- Navigation Items (see getNavItems()) -->
      <ng-container *ngIf="navItems$ | async as navItems">
        <ng-container *ngFor="let item of navItems">
          <p-button
            (click)="!!item.menuItems ? menu.toggle($event) : navigateByUrl(item.url)"
            [class.nav-active]="isActiveRoute(item.url)"
            [attr.data-cy]="item.url"
            [icon]="item.icon ?? null"
            [label]="item.label"
            [plain]="true"
            [text]="true"
            class="p-button-nav"
            iconPos="right"
          >
          </p-button>
          <div class="flex align-items-center" *ngIf="!!item.tag">
            <p-tag
              styleClass="text-xs font-semibold text-link el-bg-action-subtle"
              [value]="item.tag.label"
              [rounded]="true"
            ></p-tag>
          </div>
          <p-menu #menu [model]="item.menuItems" [popup]="true" [attr.data-cy]="item.url + '-nav-menu'"></p-menu>
        </ng-container>
      </ng-container></nav
  ></ng-container>
</div>

<ng-template #calculators> </ng-template>
