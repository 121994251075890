import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import {
  ClinicalFormResponse,
  Collection,
  CollectionWithId,
  Entry,
  EntryWithId,
  MlFeatureDetections,
  SsiMlOptions,
  UserWithId,
  WithId,
} from '@islacare/ic-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import { cloneDeep, intersection } from 'lodash-es';
import { Observable, OperatorFunction, combineLatest, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LoggingService } from '../../shared/services/logging/logging.service';
import { UsersService } from '../../shared/services/users/users.service';
import { AuditService } from '../audit/audit.service';
import { CollectionAuthsService } from '../collectionsAuths/collectionAuths.service';

export interface EntryWithUrl extends Entry {
  formResponse: any;
  url: string;
}

export interface EntryWithUrlLarge extends EntryWithUrl {
  urlLarge: string;
}

export interface EntryWithUneditedImageUrl extends EntryWithUrl {
  uneditedImageUrl: string;
}

export interface EntryWithUrlMedium extends EntryWithUrl {
  urlMedium: string;
}

export interface EntryWithIdUrl extends EntryWithId {
  url: string;
}
export enum ResizeName {
  thumb = 'thumb',
  review = 'review',
  fullSize = 'fullSize',
  small = 'small',
  medium = 'medium',
  large = 'large',
}

@Injectable({
  providedIn: 'root',
})
export class EntriesService {
  colorMap: {
    wound: string;
    sutureMaterial: string;
    slough: string;
    blood: string;
    dryBlood: string;
    localisedRedness: string;
    woundGaping: string;
    drainSiteGaping: string;
    exudate: string;
  };

  constructor(
    private auth: AngularFireAuth,
    public db: AngularFirestore,
    private storage: AngularFireStorage,
    private audit: AuditService,
    private log: LoggingService,
    private collectionAuthService: CollectionAuthsService,
    private usersService: UsersService,
  ) {
    //used for tag colors for MLDetectedFeatures
    this.colorMap = {
      [SsiMlOptions.wound]: '#405ba3',
      [SsiMlOptions.suture]: '#533483',
      [SsiMlOptions.slough]: '#FF884B',
      [SsiMlOptions.blood]: '#EB1D36',
      [SsiMlOptions.dryBlood]: '#FA9494',
      [SsiMlOptions.localisedRedness]: '#CC3636',
      [SsiMlOptions.woundGaping]: '#FD841F',
      [SsiMlOptions.drainSiteGaping]: '#FFD372',
      [SsiMlOptions.exudate]: '#367E18',
    };
  }

  async getEntriesByTeamIdsFilter(fromDate: Date, toDate: Date, formId?: string) {
    const sendEntriesListForEntrySubmission = firebase
      .app()
      .functions(environment.region)
      .httpsCallable('sendEntriesListForEntrySubmission');

    try {
      const result = await sendEntriesListForEntrySubmission({
        fromDate,
        toDate,
        formId,
      });
      if (result.data.entryList) {
        const entryList: Entry[] = JSON.parse(result.data.entryList);

        return entryList.length === 0
          ? of([])
          : of(entryList).pipe(
              switchMap(entries => combineLatest(entries.map(entry => this.includeUrl(entry, 'small')))),
              switchMap(entries => combineLatest(entries.map(entry => this.includeUrlMedium(entry)))),
            );
      } else {
        this.log.consoleLog('error on getEntries: ', result.data['error']);
        return of([]);
      }
    } catch (err) {
      this.log.consoleLog('error on getEntries: ', err);
      return of([]);
    }
  }

  private includeUrls = (imageSize: string): OperatorFunction<EntryWithId[], EntryWithUrl[]> =>
    switchMap(entries =>
      entries.length === 0 ? of([]) : combineLatest(entries.map(entry => this.includeUrl(entry, imageSize))),
    );

  getCollections(patientId: string): Observable<CollectionWithId[]> {
    return this.db
      .collection<Collection>(`patients/${patientId}/collections`, ref => ref.where('deleted', '==', false))
      .valueChanges({ idField: 'id' });
  }

  async getCollection(patientId: string, collectionId: string): Promise<Collection> {
    return (
      await this.db.doc<Collection>(`patients/${patientId}/collections/${collectionId}`).get().toPromise()
    ).data();
  }

  getEntries(patientId: string, collectionId: string): Observable<EntryWithId[]> {
    return this.db
      .collection<Entry>(`patients/${patientId}/collections/${collectionId}/entries`, ref =>
        ref.where('deleted', '==', false),
      )
      .valueChanges({ idField: 'id' });
  }

  getEntry(patientId: string, collectionId: string, entryId: string, imageSize: string): Observable<EntryWithUrl> {
    return this.db
      .doc<Entry>(`patients/${patientId}/collections/${collectionId}/entries/${entryId}`)
      .valueChanges()
      .pipe(switchMap(entry => this.includeUrl(entry, imageSize)));
  }

  getEntryForReview(patientId: string, collectionId: string, entryId: string): Observable<EntryWithUrl> {
    return this.db
      .doc<Entry>(`patients/${patientId}/collections/${collectionId}/entries/${entryId}`)
      .valueChanges()
      .pipe(switchMap(entry => this.includeUrl(entry, 'medium')));
  }

  getEntryForReviewLarge(patientId: string, collectionId: string, entryId: string): Observable<EntryWithUrlLarge> {
    return this.db
      .doc<EntryWithUrl>(`patients/${patientId}/collections/${collectionId}/entries/${entryId}`)
      .valueChanges()
      .pipe(switchMap(entry => this.includeUrlLarge(entry)));
  }

  getEntryWithUneditedImage(
    patientId: string,
    collectionId: string,
    entryId: string,
    imageSize: string,
  ): Observable<EntryWithUneditedImageUrl> {
    return this.db
      .doc<Entry>(`patients/${patientId}/collections/${collectionId}/entries/${entryId}`)
      .valueChanges()
      .pipe(
        switchMap(entry => this.includeUrl(entry, imageSize)),
        switchMap(this.includeUneditedImageUrl),
      );
  }

  getEntryDoc = (patientId: string, collectionId: string, entryId: string) => {
    return this.db.doc<Entry>(`patients/${patientId}/collections/${collectionId}/entries/${entryId}`).ref.get();
  };

  getEntriesWithUrls = (patientId: string, collectionId: string, imageSize: string): Observable<EntryWithUrl[]> => {
    return this.getEntries(patientId, collectionId).pipe(this.includeUrls(imageSize));
  };

  async createEntry(
    patientId: string,
    collectionId: string,
    entryId: string,
    imagePath: string,
    fileType: string,
    video: boolean,
    formOnly: boolean,
    sensitiveImage: boolean,
    formResponse: FormData | ClinicalFormResponse,
    formIds: string[],
    submitter,
    consentBestInterest?: boolean,
    collectionAuthId?: string,
    dateTime?: Date,
    teamId?: string,
  ) {
    const { email, uid } = (await this.auth.currentUser) || {
      email: submitter,
      uid: null,
    };
    let submissionTeamId: string;
    const formNames = [];

    //if entry is a patient submission
    if (!teamId) {
      if (collectionAuthId) {
        submissionTeamId = (
          await this.collectionAuthService.getCollectionAuthDoc(patientId, collectionId, collectionAuthId)
        )?.data()?.teamId;
      } else {
        //else if entry is user submission
        submissionTeamId = (await this.getCollection(patientId, collectionId))?.submission?.teamId;
      }
    }

    for (const formId of formIds) {
      if (formId === 'default') {
        formNames.push('Default notes form');
      } else {
        const formDoc = await this.db.collection('formData').doc(formId).ref.get();
        formNames.push(formDoc.get('name'));
      }
    }

    if (!dateTime) dateTime = new Date();

    const entryData: Entry = {
      patientId,
      collectionId,
      createdAt: firebase.firestore.Timestamp.fromDate(dateTime),
      imagePath,
      video,
      formOnly,
      capturedByEmail: email,
      capturedByUid: uid,
      sensitiveImage,
      fileType,
      deleted: false,
      formResponse,
      formIds: formIds || [],
      customOrderImageIndex: -1,
      imageResized: false,
      resizedFormat: '',
      consentBestInterest,
      entryTeamId: teamId || submissionTeamId,
      requiresAttention: true,
      collectionAuthId: collectionAuthId || '',
      formNames: formNames || [],
      lastUpdated: firebase.firestore.Timestamp.fromDate(dateTime),
      requestedBy: null,
      reviewedBy: null,
    };

    try {
      await this.db.doc<Entry>(`patients/${patientId}/collections/${collectionId}/entries/${entryId}`).set(entryData);
      if (uid) this.audit.createSubmission(patientId, entryId, teamId || submissionTeamId);
    } catch (error) {
      this.log.consoleLog(error);
      throw error;
    }
  }

  async updateEntry(patientId: string, collectionId: string, entryId: string, updates: Partial<Entry>) {
    return this.db
      .doc<Entry>(`patients/${patientId}/collections/${collectionId}/entries/${entryId}`)
      .update(updates)
      .catch(error => {
        throw error;
      });
  }

  updateEntryOrderIndex(patientId: string, collectionId: string, entryId: string, index: number) {
    return this.db.doc<Entry>(`patients/${patientId}/collections/${collectionId}/entries/${entryId}`).update({
      customOrderImageIndex: index,
    });
  }

  async updateEntryFormResponse(
    patientId: string,
    collectionId: string,
    entryId: string,
    formId: string,
    updates: FormData | ClinicalFormResponse,
  ) {
    const entryPath = `patients/${patientId}/collections/${collectionId}/entries/${entryId}`;
    const entryDoc = await this.getEntryDoc(patientId, collectionId, entryId);
    const formResponse = entryDoc.get('formResponse');

    formResponse[formId] = updates;
    const lastUpdated = firebase.firestore.FieldValue.serverTimestamp();

    return (
      this.db
        .doc<Entry>(entryPath)
        .update({
          formResponse: formResponse,
          lastUpdated: lastUpdated,
        })
        .catch(error => {
          throw error;
        }),
      { merge: true }
    );
  }

  activeEntries(patientId: string, collectionId: string): Observable<boolean> {
    return this.db
      .collection<Entry>(`patients/${patientId}/collections/${collectionId}/entries`, ref =>
        ref.where('deleted', '==', false),
      )
      .valueChanges()
      .pipe(
        switchMap(entries => {
          return entries.length === 0 ? of(false) : of(true);
        }),
      );
  }

  includeUrl(entry: Entry | EntryWithId, fileSize: string): Observable<EntryWithUrl | EntryWithIdUrl | null> {
    let pathToUse = entry.imagePath;
    if (entry.imageResized) {
      if (entry.resizedFormat) {
        pathToUse = this.reconstructImagePath(entry.imagePath, fileSize, entry.resizedFormat);
      } else {
        pathToUse = this.reconstructImagePath(entry.imagePath, fileSize);
      }
    }
    return entry
      ? this.imageUrl(pathToUse).pipe(
          catchError(() => of(null)),
          map(url => ({
            ...entry,
            url,
          })),
        )
      : of(null);
  }

  includeUrlLarge(entry: EntryWithUrl): Observable<EntryWithUrlLarge | null> {
    let pathToUse = entry.imagePath;
    if (entry.imageResized) {
      if (entry.resizedFormat) {
        pathToUse = this.reconstructImagePath(entry.imagePath, 'large', entry.resizedFormat);
      } else {
        pathToUse = this.reconstructImagePath(entry.imagePath, 'large');
      }
    }
    return entry
      ? this.imageUrl(pathToUse).pipe(
          map(urlLarge => ({
            ...entry,
            urlLarge,
          })),
        )
      : of(null);
  }

  reconstructImagePath(originalImagePath: string, fileSize: string, resizedFormat?: string): string {
    let reconstructedImagePath = originalImagePath;
    let prefixString;
    let fileName;

    if (resizedFormat && resizedFormat === 'jpeg') {
      // jpeg prefixes
      if (fileSize === ResizeName.small) {
        prefixString = '/' + ResizeName.small + '_';
      } else if (fileSize === ResizeName.medium) {
        prefixString = '/' + ResizeName.medium + '_';
      } else if (fileSize === ResizeName.large) {
        prefixString = '/' + ResizeName.large + '_';
      }
    } else {
      // png prefixes
      if (fileSize === ResizeName.small) {
        prefixString = '/' + ResizeName.thumb + '_';
      } else if (fileSize === ResizeName.medium) {
        prefixString = '/' + ResizeName.review + '_';
      } else if (fileSize === ResizeName.large) {
        prefixString = '/' + ResizeName.fullSize + '_';
      }
    }

    if (originalImagePath !== 'form-image.png' && fileSize !== 'default' && fileSize !== ResizeName.fullSize) {
      const filePath = originalImagePath;
      const fileSplit = filePath?.split('/');
      fileName = fileSplit.pop();
      const fileNameWithoutExt = fileName.split('.')[0];
      if (resizedFormat) {
        reconstructedImagePath = fileSplit.join('/');
        reconstructedImagePath = reconstructedImagePath + prefixString + fileNameWithoutExt + '.' + resizedFormat;
      } else if (fileSize !== ResizeName.large && fileSize !== ResizeName.small) {
        reconstructedImagePath = fileSplit.join('/');
        reconstructedImagePath = reconstructedImagePath + prefixString + fileNameWithoutExt + '.png';
      }
    }
    return reconstructedImagePath;
  }

  private includeUneditedImageUrl = (entry: EntryWithUrl): Observable<EntryWithUneditedImageUrl | null> => {
    return entry
      ? this.imageUrl(entry.imagePathUnedited).pipe(
          map(uneditedImageUrl => ({
            ...entry,
            uneditedImageUrl,
          })),
        )
      : of(null);
  };

  imageUrl(path: string): Observable<string | null> {
    if (!path || path.includes('form-image.png')) return of(null);

    return this.storage.ref(path).getDownloadURL();
  }

  async deleteEntry(patientId: string, collectionId: string, entryId: string, reason: string) {
    this.log.consoleLog('delete entry started');

    const entryDocUrl: string = [`patients/${patientId}`, `collections/${collectionId}`, 'entries'].join('/');

    try {
      await this.db
        .collection(entryDocUrl)
        .doc(entryId)
        .update({
          reason: reason,
          deleted: true,
        })
        .catch(error => {
          throw error;
        });
      const submissionTeamId = (await this.getCollection(patientId, collectionId)).submission.teamId;
      await this.audit.deleteSubmission(patientId, entryId, submissionTeamId, collectionId);
      this.log.consoleLog('Doc deleted! ');
    } catch (err) {
      this.log.consoleError(err);
    }
  }

  getCollectionDoc = (patientId: string, collectionId: string) => {
    return this.db.doc<Collection>(`patients/${patientId}/collections/${collectionId}`).ref.get();
  };

  private includeUrlMedium = (entry: EntryWithUrl): Observable<EntryWithUrlMedium | null> => {
    let pathToUse = entry.imagePath;
    if (entry.imageResized) {
      if (entry.resizedFormat) {
        pathToUse = this.reconstructImagePath(entry.imagePath, 'medium', entry.resizedFormat);
      } else {
        pathToUse = this.reconstructImagePath(entry.imagePath, 'medium');
      }
    }
    return entry
      ? this.imageUrl(pathToUse).pipe(
          map(urlMedium => ({
            ...entry,
            urlMedium,
          })),
        )
      : of(null);
  };

  async setRequiresAttentionFalseOnEntries(patientId, allEntries) {
    const me = await this.usersService.me();
    await Promise.all(
      allEntries.map(async (entry: EntryWithId) => {
        const reviewedByArr = Object.assign([], entry.reviewedBy);

        reviewedByArr.push({
          id: me.id,
          name: `${me.firstName} ${me.lastName}`,
          email: me.email,
          createdAt: new Date().getTime(),
        });

        const entryDocPath = `patients/${patientId}/collections/${entry.collectionId}/entries/${entry.id}`;
        await this.db.doc(entryDocPath).set(
          {
            requiresAttention: false,
            reviewedBy: reviewedByArr,
          },
          { merge: true },
        );
        const teamId = (await this.getCollection(patientId, entry.collectionId))?.teamId;
        await this.audit.markSubmissionAsReviewed(patientId, entry.collectionId, entry.id, teamId);
      }),
    );
  }

  async setRequiresAttentionTrueOnEntries(patientId, allEntries) {
    await Promise.all(
      allEntries.map(async (entry: EntryWithId) => {
        const entryDocPath = `patients/${patientId}/collections/${entry.collectionId}/entries/${entry.id}`;
        try {
          await this.db.doc(entryDocPath).update({
            requiresAttention: true,
          });
          const teamId = (await this.getCollection(patientId, entry.collectionId))?.teamId;
          await this.audit.markSubmissionAsUnReviewed(patientId, entry.collectionId, entry.id, teamId);
        } catch (err) {
          this.log.consoleError(`Error in setting requiresAttention to true for ${entryDocPath}. Error: `, err);
        }
      }),
    );
  }

  async getEntryUrl(entry: Entry, fileSize: string, originalEntryImagePath?: string): Promise<string> {
    if (!entry) return null;

    let pathToUse = originalEntryImagePath || entry.imagePath;

    if (entry.imageResized) {
      if (entry.resizedFormat) {
        pathToUse = this.reconstructImagePath(pathToUse, fileSize, entry.resizedFormat);
      } else {
        pathToUse = this.reconstructImagePath(pathToUse, fileSize);
      }
    }

    const filesTypesThatWillUseAuth = ['png', 'jpeg', 'jpg'];

    if (filesTypesThatWillUseAuth.includes(entry.fileType.toLowerCase()))
      return `https://firebasestorage.googleapis.com/v0/b/${
        environment.firebase.projectId
      }.appspot.com/o/${encodeURIComponent(pathToUse)}?alt=media`;

    return await this.imageUrl(pathToUse).pipe(take(1)).toPromise();
  }

  async getMyTeamEntriesWithRequiresAttentionTrue(patientId: string, teamIds: string[]): Promise<EntryWithId[]> {
    const allCollections = await this.getCollections(patientId).pipe(take(1)).toPromise();
    const user = await this.usersService.me();

    let allEntries = (
      await Promise.all(
        allCollections
          .filter(
            collection =>
              user && !this.isCollectionLocked(collection, user) && !this.isCollectionReadOnly(collection, user),
          )
          .map(async collection => await this.getEntries(patientId, collection.id).pipe(take(1)).toPromise()),
      )
    ).flat();

    allEntries = allEntries.filter(
      entry => !entry.deleted && entry.requiresAttention && teamIds.indexOf(entry.entryTeamId) > -1,
    );

    return cloneDeep(allEntries);
  }

  //copied here from collection service to avoid circular dependency
  isCollectionReadOnly(collection: Collection, user: UserWithId) {
    return (
      !user?.organisationIds?.includes(collection?.organisationId) &&
      !user?.teamIds?.includes(collection?.teamId) &&
      (intersection(collection?.readOnlyTeamIds, user?.teamIds).length > 0 ||
        user?.patientShareOrganisationIds?.includes(collection?.organisationId))
    );
  }

  isCollectionLocked(collection: Collection, user: UserWithId) {
    return (
      !user?.organisationIds?.includes(collection?.organisationId) &&
      !user?.patientShareOrganisationIds?.includes(collection?.organisationId) &&
      !user?.teamIds?.includes(collection?.teamId) &&
      intersection(collection?.readOnlyTeamIds || [], user?.teamIds).length === 0
    );
  }

  getEntry$(patientId: string, collectionId: string, id: string): Observable<EntryWithId> {
    return this.db
      .doc<Entry>(`patients/${patientId}/collections/${collectionId}/entries/${id}`)
      .valueChanges()
      .pipe(
        map(entry => ({
          ...entry,
          id,
        })),
      );
  }

  async getEntryWithUrl(
    patientId: string,
    collectionId: string,
    entryId: string,
    isOriginal = false,
  ): Promise<EntryWithUrlLarge & WithId> {
    const entrySnap = await this.db
      .doc<Entry>(`patients/${patientId}/collections/${collectionId}/entries/${entryId}`)
      .ref.get();

    const entryImagePath = `patients/${patientId}/collections/${collectionId}/${entryId}`;

    const url = await this.getEntryUrl(entrySnap.data(), 'large', isOriginal ? entryImagePath : '');
    return { ...entrySnap.data(), id: entrySnap.id, urlLarge: url, url: url };
  }

  returnUrl(entry: Entry | EntryWithId, fileSize: string): Observable<string> {
    let pathToUse = entry.imagePath;
    if (entry.imageResized) {
      if (entry.resizedFormat) {
        pathToUse = this.reconstructImagePath(entry.imagePath, fileSize, entry.resizedFormat);
      } else {
        pathToUse = this.reconstructImagePath(entry.imagePath, fileSize);
      }
    }
    return entry ? this.imageUrl(pathToUse) : of(null);
  }

  async getEntryMLFeatureDetections(patientId: string, collectionId: string, entryId: string) {
    return (
      await this.db
        .collection<MlFeatureDetections>(
          `patients/${patientId}/collections/${collectionId}/entries/${entryId}/MlFeatureDetections`,
        )
        .ref.get()
    ).docs[0].data();
  }

  async removeUneditedImageAndUpdateImagePath(
    entry: EntryWithUneditedImageUrl,
    patientId: string,
    collectionId: string,
    entryId: string,
  ) {
    const entryObj = {};
    const entryImagePath = `patients/${patientId}/collections/${collectionId}/${entryId}`;

    if (entry.maState) entryObj['maState'] = '';

    if (entry.imagePathUnedited) entryObj['imagePathUnedited'] = '';

    if (entry.imagePath !== entryImagePath) entryObj['imagePath'] = entryImagePath;

    if (Object.keys(entryObj).length) {
      await this.db
        .doc<Entry>(`patients/${patientId}/collections/${collectionId}/entries/${entryId}`)
        .update(entryObj)
        .catch(error => {
          throw error;
        });
    }
  }
}
