import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { SystemCheckService } from '@ic-monorepo/shared-common';
import { FormField } from '@islacare/ic-types';
import { cloneDeep } from 'lodash-es';
import { Table } from 'primeng/table';

@Component({
  selector: 'ic-automatic-form-array',
  templateUrl: './automatic-form-array.component.html',
  styleUrls: ['./automatic-form-array.component.scss'],
})
export class AutomaticFormArrayComponent implements OnInit, AfterViewInit {
  @Input() field: FormField;
  @Input() control: UntypedFormArray;
  @Input() formGroup: UntypedFormGroup;
  @ViewChild('primeTable') table: Table;
  @ViewChild('arrayHeightRef', { read: ElementRef }) arrayHeightRef: ElementRef;

  @Input() setData;
  tableData = {
    columns: [],
    fields: {},
  };
  scores = {};
  @Output() formArrayScores = new EventEmitter<any>();
  @Output() arrayHeight = new EventEmitter<any>();
  form: UntypedFormGroup;
  constructor(public formService: FormService, public sc: SystemCheckService) {}

  ngOnInit(): void {
    this.setTableColumnsFields();
    this.form = this.formService.buildForm(this.field?.formArrayFields);
    if (!this.setData) this.addForm();
  }

  ngAfterViewInit(): void {
    if (this.setData?.length) {
      while (this.control?.value?.length < this.setData.length && this.table) {
        this.addForm();
      }
      this.control.patchValue(this.setData);
    }
    this.arrayHeight.emit(this.arrayHeightRef?.nativeElement?.offsetHeight);
  }

  private setTableColumnsFields(): void {
    const columns = [];
    const fields = {};
    this.field?.formArrayFields?.forEach((field: FormField) => {
      //columns definition for the mat-table
      columns.push(field?.title || field?.subTitle || field?.controlName);
      //fields in table should not display title and subtitle(we have already displayed them as columns)
      fields[field.controlName] = cloneDeep(field) as FormField;
      fields[field.controlName]['title'] = '';
      fields[field.controlName]['subTitle'] = '';
    });
    // Add 'Delete' column
    columns.push('delete');

    // Set tableData to reformatted columns, fields
    this.tableData['columns'] = columns;
    this.tableData['fields'] = fields;
  }

  addForm(): void {
    const newForm = cloneDeep(
      this.formService.buildForm(this.field?.formArrayFields)
    );
    this.control?.push(newForm);
    this.arrayHeight.emit(this.arrayHeightRef?.nativeElement?.offsetHeight);
  }

  deleteForm(index: number): void {
    this.control.removeAt(index);
    this.scores[index] = null;
    for (let i = index; i < Object.keys(this.scores).length - 1; i++) {
      this.scores[i] = this.scores[i + 1];
      this.scores[i + 1] = null;
    }
    this.arrayHeight.emit(this.arrayHeightRef?.nativeElement?.offsetHeight);
    this.formArrayScores.emit(this.scores);
  }

  getFormGroup(index) {
    return (
      index < this.control?.value?.length &&
      (this.control.at(index) as UntypedFormGroup)
    );
  }

  addScores(scoresArray): void {
    const currentScores = JSON.stringify(this.scores);
    for (const row in scoresArray) {
      for (const scoreField in scoresArray[row]) {
        //new or deleted row? create new empty row
        if (!this.scores[row] || this.scores[row] === null) {
          this.scores[row] = {};
        }
        //populate row
        this.scores[row][scoreField] = scoresArray[row][scoreField];
      }
    }
    //only emit if this.scores changes
    if (currentScores !== JSON.stringify(this.scores)) {
      this.formArrayScores.emit(this.scores);
    }
  }
}
