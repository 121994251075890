import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IntercomService } from 'apps/frontend/portal/src/app/services/intercom/intercom.service';
import { UserCameraService } from 'apps/frontend/portal/src/app/services/user-camera/user-camera.service';

@Component({
  standalone: true,
  selector: 'ic-video-record',
  templateUrl: './video-record.component.html',
  styleUrls: ['./video-record.component.scss'],
  imports: [CommonModule, MatIconModule, MatButtonModule],
})
export class VideoRecordComponent implements AfterViewInit, OnDestroy {
  @Input() entry: boolean;
  @Output() fileSnapshotEvent = new EventEmitter<File>();
  @Output() srcSnapshotEvent = new EventEmitter<SafeResourceUrl>();
  @Output() typeSnapshotEvent = new EventEmitter<string>();
  @Output() fileOverrideSnapshotEvent = new EventEmitter<boolean>();
  @Output() redirectToUpload = new EventEmitter();
  @Output() hideToggle = new EventEmitter<boolean>();

  confirmed = false;
  isVideoing = false;
  isVideoed = false;
  showWebcam = true;
  file: Blob;
  srcData: SafeResourceUrl;
  fileType: string;
  uploadRedirect = false;

  constructor(
    private sanitizer: DomSanitizer,
    private intercom: IntercomService,
    private userCamera: UserCameraService
  ) {}

  @ViewChild('video') video: any;

  @HostListener('window:beforeunload')
  handleUnload() {
    this.userCamera.disconnect();
  }

  ngAfterViewInit(): void {
    this.intercom.shutdownIntercom();
    this.userCamera.getVideoStream(this.video?.nativeElement);
  }

  ngOnDestroy(): void {
    this.userCamera.disconnect();
    this.intercom.initialiseIntercom();
  }

  async startRecording() {
    await this.userCamera.startRecording(this.video?.nativeElement);
    this.isVideoing = true;
    this.hideToggle.emit(false);
  }

  async stopRecording() {
    this.file = await this.userCamera.stopAndGetRecording(
      this.video?.nativeElement
    );
    this.isVideoing = false;
    this.isVideoed = true;
    return;
  }

  restartRecording() {
    this.isVideoed = false;
    this.video.nativeElement.removeAttribute('src');
    this.userCamera.getVideoStream(this.video.nativeElement);
    this.hideToggle.emit(true);
  }

  triggerConfirm(): void {
    this.confirmed = true;
    this.sendFile();
    this.userCamera.disconnect();
  }

  sendFile() {
    this.fileType = this.file.type;
    const fileUrl = URL.createObjectURL(this.file);
    this.srcData = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl);
    this.sendSnapshot();
  }

  sendSnapshot() {
    this.srcSnapshotEvent.emit(this.srcData);
    this.typeSnapshotEvent.emit(this.fileType);
    this.fileSnapshotEvent.emit(this.file as File);
    this.fileOverrideSnapshotEvent.emit(this.uploadRedirect);
  }
}
