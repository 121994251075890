<p-treeTable
  [value]="formattedData"
  [columns]="columns"
  [autoLayout]="true"
  [rowHover]="true"
  data-cy="formResponse-table-results"
>
  <!-- Table Title -->
  <ng-template pTemplate="caption">
    <div *ngIf="tableTitle" class="table-header">{{ tableTitle }}</div>
  </ng-template>

  <!-- Columns -->
  <ng-template pTemplate="header" let-columns *ngIf="showColumnHeaders">
    <tr>
      <th *ngFor="let col of columns" id="column-header">
        {{ col.header }}
      </th>
    </tr>
  </ng-template>

  <!-- Rows -->
  <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
    <ng-template #defaultTemplate>
      <tr
        [id]="rowData.questionId ?? ''"
        [ngClass]="{
          'tree-node-first': rowNode.level === 0,
          'tree-node-first-expanded': rowNode.level === 0 && rowNode.node.expanded,
          'tree-node-child': rowNode.level > 0,
          'tree-node-child-last': !!rowNode.node.parent && !rowNode.node.children,
          'important-question': rowData['unexpectedAnswer']
        }"
        [style.background-color]="rowData['responseHighlightColour'] ?? ''"
        [class.border-alt]="!!rowData['responseHighlightColour']"
        class="tree-node"
      >
        <!-- Question -->
        <td [class.pl-0]="rowNode.node.children" class="tree-question-col text-sm">
          <div class="flex flex-row align-items-center">
            <p-treeTableToggler
              *ngIf="rowNode['node']['children'] || rowNode['node']['parent']"
              [rowNode]="rowNode"
            ></p-treeTableToggler>
            <span>{{ rowData['question'] | camelCaseToTitleCase }}</span>
          </div>
        </td>
        <!-- Answer -->
        <td class="tree-answer-col text-sm">
          <ng-container [ngSwitch]="true">
            <!-- Date row -->
            <span
              *ngSwitchCase="
                rowData['inputType'] === dateField ||
                rowData['inputType'] === UserInputType.DATE_FIELD ||
                rowData['inputType'] === ClinicalFormInputType.DATE
              "
            >
              {{ rowData['answer'] | date: 'dd-MMM-yyyy' }}
            </span>
            <!-- Form-array row -->
            <span *ngSwitchCase="rowData['inputType'] === UserInputType.FORM_ARRAY">
              <ng-container *ngFor="let formArrayAnswer of rowData['answer']; let i = index">
                <ul class="bulleted-list">
                  <li class="bulleted-item">
                    <span>{{ formArrayAnswer['data']['question'] }}: </span>
                    <ng-container
                      *ngIf="formArrayAnswer['data']['inputType'] === UserInputType.DATE_FIELD"
                    >
                      {{ formArrayAnswer['data']['answer'] | date: 'dd-MMM-yyyy' }}
                    </ng-container>
                    <ng-container
                      *ngIf="formArrayAnswer['data']['inputType'] !== UserInputType.DATE_FIELD"
                    >
                      {{ formArrayAnswer['data']['answer'] }}
                    </ng-container>
                  </li>
                </ul>
              </ng-container>
            </span>
            <!-- Multi-select row -->
            <span *ngSwitchCase="rowData['inputType'] === UserInputType.MULTI_SELECT">
              <ng-container *ngFor="let data of rowData['answer'] | keyvalue; let i = index">
                <ul class="bulleted-list">
                  <li class="bulleted-item">
                    {{ data.value }}
                  </li>
                </ul>
              </ng-container>
            </span>
            <!-- Image row -->
            <span
              *ngSwitchCase="
                rowData['inputType'] === ClinicalFormInputType.IMAGEMAP ||
                rowData['inputType'] === ClinicalFormInputType.IMAGE ||
                rowData['inputType'] === UserInputType.IMAGE_MAP ||
                rowData['inputType'] === UserInputType.BODY_MAP
              "
            >
              <img
                alt="image upload thumbnail"
                [src]="rowData['answer'] | bodyMapUrl | async"
                class="w-full"
              />
            </span>
            <!-- Table row -->
            <span *ngSwitchCase="rowData['inputType'] === ClinicalFormInputType.TABLE">
              <ng-container *ngFor="let tableAnswers of rowData['answer']; let i = index">
                <ul class="bulleted-list">
                  <li class="bulleted-item">
                    <ng-container *ngFor="let answerRow of tableAnswers; let i = index">
                      <span class="font-bold">{{ answerRow['data']['question'] }}: </span>
                      <ng-container
                        *ngIf="answerRow['data']['inputType'] === ClinicalFormInputType.DATE"
                      >
                        {{ answerRow['data']['answer'] | date: 'dd-MMM-yyyy' }}
                      </ng-container>
                      <ng-container
                        *ngIf="answerRow['data']['inputType'] !== ClinicalFormInputType.DATE"
                      >
                        {{ answerRow['data']['answer'] }}
                      </ng-container>
                    </ng-container>
                  </li>
                </ul>
              </ng-container>
            </span>
            <span
              *ngSwitchCase="
                rowData['inputType'] === UserInputType.CHECKBOX &&
                (rowData['answer'] === true || rowData['answer'] === false)
              "
            >
              <ng-container *ngIf="rowData['answer'] === true; else falseCheckbox">
                <i class="pi pi-check-circle text-link"></i>
                Yes
              </ng-container>
              <ng-template #falseCheckbox>
                <i class="pi pi-times-circle text-secondary"></i>
                No
              </ng-template>
            </span>
            <!-- Default row -->
            <span *ngSwitchDefault>
              <ng-container *ngIf="rowData['answer'] as answer">
                <i
                  *ngIf="answer === 'No' || answer === 'Yes' || answer === true || answer === false"
                  [ngClass]="{
                    'pi-times-circle text-secondary': answer === 'No' || answer === false,
                    'pi-check-circle text-link': answer === 'Yes' || answer === true
                  }"
                  class="pi"
                ></i>
                <!-- Data with subtitle_HEADER does not represent a question/answer row. It is meant to display a subtitle -->
                <span
                  [ngClass]="{
                    hide:
                      answer === 'subtitle_HEADER' ||
                      rowData['inputType'] === ContentInputType.SUBHEADING
                  }"
                >
                  {{ answer }}
                </span>
              </ng-container>
            </span>
          </ng-container>
        </td>
        <!-- Score -->
        <td class="tree-score-col text-sm">
          <ng-container *ngIf="rowData['fieldScoreValue']">
            <p-badge
              *ngIf="rowData['fieldScoreValue']['value']"
              [value]="rowData['fieldScoreValue']['value']"
              styleClass="mr-2"
              [pTooltip]="rowData['fieldScoreValue']['scoreKey'] | scoreKeyText"
            >
            </p-badge>
          </ng-container>
        </td>
      </tr>
    </ng-template>

    <!-- Custom Template -->
    <ng-container
      *ngTemplateOutlet="
        customBodyTemplate ? customBodyTemplate : defaultTemplate;
        context: { $implicit: rowData }
      "
    ></ng-container>
  </ng-template>
</p-treeTable>
