import { Injectable } from '@angular/core';
import { ClinicalFormField } from '@islacare/ic-types';
import { ComponentStore } from '@ngrx/component-store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { cloneDeep } from 'lodash-es';

interface ClinicalFormsEditorState {
  fieldConfigs: FormlyFieldConfig[];
  fieldBeingEdited: FormlyFieldConfig | null;
  targetObject: FormlyFieldConfig | null;
  resetState: FormlyFieldConfig | null;
}

@Injectable()
export class ClinicalFormEditorStore extends ComponentStore<ClinicalFormsEditorState> {
  constructor() {
    super({
      fieldConfigs: [],
      fieldBeingEdited: {},
      targetObject: {},
      resetState: {}
    });
  }

  readonly fieldConfigs$ = this.select(state => state.fieldConfigs);
  readonly fieldBeingEdited$ = this.select(state => state.fieldBeingEdited);
  readonly resetState$ = this.select(state => state.resetState);
  readonly targetObject$ = this.select(state => state.targetObject);

  readonly updateFieldConfig = this.updater(
    (
      state,
      payload: {
        fieldConfig: FormlyFieldConfig;
      }
    ) => {
      const updatedFieldConfigs = this.updateFieldConfigInArray(
        state.fieldConfigs,
        state.targetObject,
        payload.fieldConfig
      );

      this.setFieldBeingEdited({
        ...state.fieldBeingEdited,
        ...payload.fieldConfig
      });

      return {
        ...state,
        fieldConfigs: cloneDeep(updatedFieldConfigs)
      };
    }
  );

  readonly addField = this.updater(
    (
      state,
      payload: {
        targetObject: FormlyFieldConfig;
        insertBefore: boolean;
        objectToInsert: FormlyFieldConfig;
      }
    ) => {
      const updatedFieldConfigs = this.addFieldConfigInArray(
        state.fieldConfigs,
        payload.targetObject,
        payload.objectToInsert,
        payload.insertBefore
      );
      return {
        ...state,
        fieldConfigs: cloneDeep(updatedFieldConfigs)
      };
    }
  );

  readonly addDuplicateField = this.updater(
    (
      state,
      payload: {
        targetObject: FormlyFieldConfig;
        insertBefore: boolean;
        objectToInsert: FormlyFieldConfig;
      }
    ) => {
      const updatedFieldConfigs = this.addFieldConfigInArray(
        state.fieldConfigs,
        payload.targetObject,
        payload.objectToInsert,
        payload.insertBefore
      );
      return {
        ...state,
        fieldConfigs: cloneDeep(updatedFieldConfigs)
      };
    }
  );

  readonly deleteField = this.updater(
    (state, payload: { fieldToDelete: FormlyFieldConfig }) => {
      const updatedFieldConfigs = this.deleteFieldConfigInArray(
        state.fieldConfigs,
        payload.fieldToDelete
      );
      this.setFieldBeingEdited({});
      return {
        ...state,
        fieldConfigs: cloneDeep(updatedFieldConfigs)
      };
    }
  );

  setFieldConfigs(fieldConfigs) {
    this.setState(state => ({
      ...state,
      fieldConfigs: cloneDeep(fieldConfigs)
    }));
  }

  setFieldBeingEdited(fieldBeingEdited) {
    this.setState(state => ({
      ...state,
      fieldBeingEdited: cloneDeep(fieldBeingEdited)
    }));
  }

  setResetState(resetState) {
    this.setState(state => ({ ...state, resetState: cloneDeep(resetState) }));
  }

  setTargetObject(targetObject) {
    this.setState(state => ({
      ...state,
      targetObject: cloneDeep(targetObject)
    }));
  }

  clearFieldBeingEdited() {
    this.setState(state => ({ ...state, fieldBeingEdited: null }));
  }

  private deleteFieldConfigInArray(
    fieldArray: FormlyFieldConfig[],
    targetObject: FormlyFieldConfig
  ): FormlyFieldConfig[] {
    if (!targetObject?.id) return fieldArray;

    const deleteFieldConfigRecursive = (
      fieldArray: FormlyFieldConfig[],
      targetObject: FormlyFieldConfig
    ): FormlyFieldConfig[] | null => {
      for (let i = 0; i < fieldArray?.length; i++) {
        const currentFieldConfig = fieldArray[i];

        if (currentFieldConfig?.id === targetObject?.id) {
          if (fieldArray?.length > 1) {
            fieldArray.splice(i, 1);
          }
          return fieldArray;
        }

        if (currentFieldConfig?.fieldGroup?.length) {
          const updatedSubFields = deleteFieldConfigRecursive(
            currentFieldConfig.fieldGroup,
            targetObject
          );

          if (updatedSubFields) {
            currentFieldConfig.fieldGroup = updatedSubFields;
            return fieldArray;
          }
        }
      }

      return null;
    };

    return deleteFieldConfigRecursive(fieldArray, targetObject) || fieldArray;
  }

  private addFieldConfigInArray(
    fieldArray: FormlyFieldConfig[],
    targetObject: FormlyFieldConfig | null,
    newFieldConfig: FormlyFieldConfig,
    insertBefore = true
  ): FormlyFieldConfig[] {
    const addFieldConfigRecursive = (
      fieldArray: FormlyFieldConfig[],
      targetObject: FormlyFieldConfig | null,
      newFieldConfig: FormlyFieldConfig,
      insertBefore: boolean
    ): FormlyFieldConfig[] | null => {
      for (let i = 0; i < fieldArray.length; i++) {
        const currentFieldConfig = fieldArray[i];

        if (currentFieldConfig?.id === targetObject?.id) {
          if (insertBefore) {
            fieldArray.splice(i, 0, newFieldConfig);
          } else {
            fieldArray.splice(i + 1, 0, newFieldConfig);
          }
          return fieldArray;
        }

        if (currentFieldConfig?.fieldGroup?.length) {
          const updatedSubFields = addFieldConfigRecursive(
            currentFieldConfig.fieldGroup,
            targetObject,
            newFieldConfig,
            insertBefore
          );

          if (updatedSubFields) {
            currentFieldConfig.fieldGroup = updatedSubFields;
            return fieldArray;
          }
        }
      }

      return null;
    };

    return (
      addFieldConfigRecursive(
        fieldArray,
        targetObject,
        newFieldConfig,
        insertBefore
      ) || fieldArray
    );
  }

  private updateFieldConfigInArray(
    fieldArray: FormlyFieldConfig[],
    targetObject: FormlyFieldConfig,
    updatedFieldConfig: FormlyFieldConfig
  ): FormlyFieldConfig[] {
    return fieldArray.map(field => {
      if (field.type === ClinicalFormField.TABLE) {
        field.defaultValue = [{}];
        delete field.fieldGroup;
      }
      if (field.id === targetObject.id) {
        const updatedConfig = this.mapExpressionsForStore(updatedFieldConfig);
        return { ...field, ...updatedConfig };
      } else if (field?.fieldGroup?.length) {
        const updatedFieldGroup = this.updateFieldConfigInArray(
          field.fieldGroup,
          targetObject,
          {
            ...updatedFieldConfig
          }
        );
        return { ...field, fieldGroup: updatedFieldGroup };
      } else if (field?.fieldArray?.['fieldGroup']?.length) {
        const updatedFieldArrayGroup = this.updateFieldConfigInArray(
          field?.fieldArray?.['fieldGroup'],
          targetObject,
          updatedFieldConfig
        );
        return {
          ...field,
          fieldArray: {
            ...field.fieldArray,
            fieldGroup: updatedFieldArrayGroup
          }
        };
      }
      return field;
    });
  }

  private mapExpressionsForStore(field: FormlyFieldConfig): FormlyFieldConfig {
    const { expressions } = field as any;

    if (expressions?.props?.calculationValue) {
      field.expressions['props.calculationValue'] =
        expressions?.props?.calculationValue;
    }

    return field;
  }
}
