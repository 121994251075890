<p data-cy="confirmation-dialog-msg">{{ data.message }}</p>
<div class="flex justify-content-end gap-2 mt-3 w-full">
  <button
    *ngIf="data.negativeButton"
    (click)="closeDialog(false)"
    [label]="data.negativeButton"
    class="p-button-outlined"
    aria-label="Close"
    type="button"
    pButton
  ></button>
  <button
    [label]="data.positiveButton"
    (click)="closeDialog(true)"
    data-cy="confirmationConfirm"
    aria-label="Confirm"
    pButton
  ></button>
</div>
