import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClinicalForm, ClinicalFormResponse } from '@islacare/ic-types';

import { FormService, UsersService } from '@ic-monorepo/services';
import { FormlyFormOptions } from '@ngx-formly/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'ic-clinical-form',
  templateUrl: './clinical-form.component.html',
  styleUrls: ['./clinical-form.component.scss'],
})
export class ClinicalFormComponent implements OnInit {
  @Input() clinicalForm: ClinicalForm;
  @Input() clinicalFormResponse: ClinicalFormResponse;
  @Input() dialogRef: DynamicDialogRef;

  form = new FormGroup({});
  options$: Observable<FormlyFormOptions>;

  constructor(
    private formService: FormService,
    private usersService: UsersService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.options$ = this.getClinicalFormOptions$();
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { step: 0 },
      queryParamsHandling: 'merge',
    });
  }

  getClinicalFormOptions$(): Observable<FormlyFormOptions> {
    return this.usersService.me$.pipe(
      map((user) => ({
        formState: {
          model: this.clinicalFormResponse?.formResponse || {},
          calculationValues: {},
          user,
        },
      }))
    );
  }

  onSubmit(formResponse): void {
    const convertedFormResponse = this.convertDatesToISO(formResponse);
    this.formService.submit({
      isFormlyForm: true,
      formId: this.clinicalForm.id,
      formResponse: convertedFormResponse,
    } as ClinicalFormResponse);

    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  convertDatesToISO(obj: any): any {
    const convertDates = (value: any) => {
      if (value instanceof Date) {
        return value.toISOString();
      } else if (Array.isArray(value)) {
        return value.map(convertDates);
      } else if (typeof value === 'object' && value !== null) {
        for (const prop in value) {
          value[prop] = convertDates(value[prop]);
        }
        return value;
      } else {
        return value;
      }
    };

    return convertDates(obj);
  }
}
