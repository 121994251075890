import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'ic-qr-code-dialog',
  templateUrl: './qr-code-dialog.component.html'
})
export class QrCodeDialogComponent implements OnInit {
  qrCodeLink: string;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.qrCodeLink = this.config.data.link;
  }

  closeDialog(): void {
    this.ref.close();
  }

  done() {
    this.dialogService.dialogComponentRefMap.forEach(dialog => {
      dialog.destroy();
    });
  }
}
