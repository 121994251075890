export default [
  'sex',

  //symptoms
  'symptoms',
  'mainSymptom',
  'mainSymptomOther',
  'firstNoticeMainSymptom',
  'mainSymptomLengthInMonths',
  'mainSymptomLengthInDays',
  'mainSymptomLocation',
  'mainSymptomVariesWithCycle',
  'doesPainRadiateElsewhere',
  'typeOfBreastLump',
  'isLumpAssociatedWithBreastFeeding',
  'hadTemperatureWithLump',
  'areYouASmoker',
  'lumpType',
  'lumpLocation',
  'nippleDischargeOccurrence',
  'nippleDischargeAmount',
  'nippleDischargeConsistency',
  'nippleDischargeColour',
  'invertedNipple',
  'isSkinFlakyOrItchy',
  'changeInSkin',
  'isThereSkinDipling',
  'hasShapeOrSizeChanged',
  'otherSymptoms',
  'firstNoticeOtherSymptoms',
  'otherSymptomsLengthInMonths',
  'otherSymptomsLengthInDays',

  'specifyOtherSymptoms',
  'otherSymptomLocation',
  'otherSymptomVariesWithCycle',
  'doesOtherSymptomPainRadiateElsewhere',
  'otherSymptomTypeOfBreastLump',
  'isOtherSymptomLumpAssociatedWithBreastFeeding',
  'temperatureWithLump',
  'haveYouSmoked',
  'otherSymptomLumpType',
  'otherSymptomLumpLocation',
  'nippleDischargeFrequency',
  'otherSymptomNippleDischargeAmount',
  'otherSymptomNippleDischargeConsistency',
  'otherSymptomNippleDischargeColour',
  'isNippleInverted',
  'skinFlakyOrItchy',
  'otherSymptomChangeInSkin',
  'skinDipling',
  'shapeOrSizeChanged',

  //bmi
  'bodyMassIndex',
  'weightKg',
  'heightCm',
  'bmi',

  //lifestyle
  'lifestyle',
  'drinkAlcohol',
  'glassesOfWinePerWeek',
  'pintsOfBeerPerWeek',
  'bottlesOfBeerCiderOrAlcopopPerWeek',
  'shotsOfSpiritPerWeek',
  'weeklyAlcoholIntakeInGrams',
  'doYouSmoke',

  //menstruation
  'menstruation',
  'ageOfFirstPeriod',
  'hasYourPeriodsStopped',
  'periodsStoppedAge',

  //contraceptives
  'contraceptives',
  'takenContraceptivePill',
  'yearsTakingContraceptivePills',
  'takenContraceptivePillLastTwoYears',

  //hrt
  'hormoneReplacementTherapy',
  'haveYouUsedHrt',
  'yearsUsingHrt',
  'usedHrtInLast5Years',

  //children
  'childrenInfo',
  'hadChildren',
  'ageAtFirstLiveBirth',
  'childrenList',
  'childSex',
  'childYOB',

  //breast screening
  'breastScreening',
  'haveYouHadAMammogram',
  'doYouKnowYourBreastDensity',
  'breastDensityBiRads',
  'biRadsCategory',

  //medical history
  'medicalHistory',
  'endometriosisHistory',
  'tubalLigationHistory',
  'oophorectomy',
  'mastectomy',

  //cancerhistory
  'cancerHistory',
  'breastCancerFirstBreast',
  'firstBreastCancerDiagnosisAge',
  'breastCancerSecondBreast',
  'secondBreastCancerDiagnosisAge',
  'ovarianCancer',
  'ovarianCancerDiagnosisAge',
  'pancreaticCancer',
  'pancreaticCancerDiagnosisAge',

  //family
  'familyHistory',
  'familyHistoryOfCancer',
  'extendedFamilyHistory'
];
