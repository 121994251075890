import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { FormService } from '@ic-monorepo/services';
import { FormField } from '@islacare/ic-types';

@Component({
  selector: 'ic-automatic-single-select',
  templateUrl: './automatic-single-select.component.html',
  styleUrls: ['./automatic-single-select.component.scss'],
})
export class AutomaticSingleSelectComponent {
  @Input() field: FormField;
  @Input() control: UntypedFormControl;

  /** For testing purposes */
  @ViewChild(MatSelect) matSelect: MatSelect;

  constructor(public formService: FormService) {}

  justifyContent() {
    return this.field?.justifyContent || 'center';
  }
}
