<div class="flex justify-content-center w-full text-left">
  <div class="clinical-form-wrapper w-full">
    <ng-container
      *ngIf="form && clinicalForm?.fieldConfig && options$ | async as options; else loading"
    >
      <form [formGroup]="form" (ngSubmit)="onSubmit(options?.formState?.model)">
        <formly-form
          [model]="options?.formState?.model"
          [fields]="clinicalForm.fieldConfig"
          [form]="form"
          [options]="options"
        ></formly-form>
      </form>
    </ng-container>
  </div>
</div>

<ng-template #loading></ng-template>
