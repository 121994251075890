import { Component } from '@angular/core';
import { SystemCheckService } from '@ic-monorepo/shared-common';
import { QrCodeDialogData, QrCodeService } from 'apps/frontend/portal/src/app/services/qr-code/qr-code.service';
import { environment } from 'apps/frontend/portal/src/environments/environment';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

interface RequestResponse {
  alertEmail: string;
  capture: string;
  captureType: string;
  collectionAuthId: string;
  contactMethod: string;
  keepLinkSevenDays: boolean;
  reminder: boolean;
  requestId: string;
  smsBody: string;
  formIds: string[];
  teamId: string;
  isMediaOptional: boolean;
}
@Component({
  selector: 'ic-request-dialog',
  templateUrl: './request-dialog.component.html',
})
export class RequestDialogComponent {
  confirmRequestValid = false;
  requestResponse: RequestResponse;
  isMobile = this.systemCheck.isMobile;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private systemCheck: SystemCheckService,
    private qrCodeService: QrCodeService,
  ) {
    if (this.isMobile) {
      this.config.width = '90%';
    }
  }

  getRequestResponse(response: RequestResponse): void {
    this.confirmRequestValid = !!(
      response.formIds.length > 0 &&
      (response.teamId || response.teamId === '') &&
      response.captureType
    );
    this.requestResponse = response;
  }

  async onSubmit(requestResponse) {
    if (requestResponse.contactMethod === 'qrCode') {
      return this.openQrCode(requestResponse);
    }
    return this.ref.close(requestResponse);
  }

  private openQrCode(requestResponse) {
    const partialRequestData = {
      formIds: requestResponse.formIds,
      reminder: requestResponse.reminder,
      teamId: requestResponse.teamId,
      keepLinkSevenDays: requestResponse.keepLinkSevenDays,
    };
    const partialPatientData = {
      patientId: this.config.data.patient.id,
      patientFirstName: this.config.data.patient.firstName,
      patientPhone: this.config.data.patient.phone,
      patientYearOfBirth: this.config.data.patient.dateOfBirth.substring(0, 4),
      additionalPhoneNumbers: [],
    };
    const dialogData: QrCodeDialogData = {
      collectionId: this.config.data.collection.id,
      collectionAuthId: requestResponse.collectionAuthId,
      collectionAuthUrl: `${environment.url}/entry/new/identity?requestId=${requestResponse.requestId}`,
      partialRequestData,
      partialPatientData,
      createdBy: this.config.data.createdBy,
      createdForOrg: this.config.data.createdForOrg,
      captureType: requestResponse.captureType,
      requestId: requestResponse.requestId,
      isMediaOptional: requestResponse.isMediaOptional,
    };

    this.qrCodeService.openQrCodeDialog(dialogData);
  }

  closeDialog(): void {
    this.ref.close();
  }
}
