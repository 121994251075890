import { map, of } from 'rxjs';
// This function works in conjunction with the return value from multiple-emails.validator.ts file
export function multipleEmailValidityLogs(emailValidityLogs: { [key: string]: boolean }[] | []) {
  if (emailValidityLogs) {
    return of(emailValidityLogs).pipe(
      map(arr => {
        return arr
          .map(email => {
            if (!email[`${Object.keys(email)}`]) return Object.keys(email);
          })
          .flat()
          .filter(email => {
            // removes undefined values from map
            return !!email;
          });
      })
    );
  } else {
    return of([]);
  }
}
