<ng-container *ngIf="{ scheduledItems: scheduledItems$ | async, forms: forms$ | async } as items">
  <ng-container *ngIf="items.scheduledItems && items.forms; else loading">
    <div class="h-full flex flex-column" [ngClass]="{ 'h-full': !isMobile }">
      <div
        class="dialog-container fadein gap-3 flex flex-column-reverse md:flex-row max-h-full"
        [ngClass]="{ 'flex-1 overflow-hidden': !isMobile }"
      >
        <div *ngIf="!isMobile || (!isEditing && !showApplyScheduleTemplateForm)" class="h-full md:w-5 w-full">
          <ic-schedule-timeline
            [forms]="items.forms"
            [scheduledItems]="items.scheduledItems"
            [isEditable]="!isLoading && !isEditing"
            (editScheduledItemClick)="editScheduledItem($event)"
            (deleteScheduledItemClick)="deleteScheduledItem($event)"
            (openScheduledItem)="previewScheduledItem($event)"
            (addItemToScheduleEvent)="openScheduledItemForm(ScheduledItemType.SUBMISSION_REQUEST)"
            (applyScheduleTemplateEvent)="toggleScheduleTemplateForm()"
            (clearScheduleEvent)="clearAllScheduleItems()"
          ></ic-schedule-timeline>
        </div>
        <div class="md:w-7 w-full">
          <ng-container *ngIf="currentItemForm$ | async as currentItemForm; else noScheduleItemFormSelected">
            <ng-container *ngIf="user$ | async as user">
              <ng-container [ngSwitch]="currentItemForm">
                <ng-container *ngSwitchCase="ScheduledItemType.SUBMISSION_REQUEST">
                  <ic-submission-request-form
                    [collection]="dialogData.collection"
                    [patient]="dialogData.patient"
                    [smsRecipients]="smsRecipients"
                    [emailRecipients]="emailRecipients"
                    [user]="user"
                    [formOptions]="items.forms"
                    [scheduledItem]="selectedItem"
                    [previewOnly]="previewOnly"
                    (addSubmissionRequests)="addNewScheduledItems($event)"
                    (cancel)="closeForm()"
                  ></ic-submission-request-form>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="dialog-footer justify-content-end" [ngClass]="{ hidden: !isMobile, flex: isMobile }">
        <div *ngIf="!isEditing && !showApplyScheduleTemplateForm" [ngClass]="{ 'mt-5 w-full': isMobile }">
          <button
            label="Confirm schedule"
            aria-label="Confirm schedule"
            pButton
            (click)="closeDialog()"
            class="p-button-success"
            [ngClass]="{ 'w-full': isMobile }"
          ></button>
        </div>
      </div>
    </div>

    <ng-template #noScheduleItemFormSelected>
      <ng-container *ngTemplateOutlet="noSchedule; context: { forms: items.forms }"></ng-container>
    </ng-template>
  </ng-container>

  <ng-template #loading>
    <p-progressSpinner></p-progressSpinner>
  </ng-template>

  <ng-template #noSchedule let-forms="forms">
    <ng-container *ngIf="!showApplyScheduleTemplateForm; else applyScheduleTemplateForm">
      <div class="flex flex-column h-full max-h-full">
        <div class="flex-grow-1 overflow-scroll">
          <p-panel
            header="Schedule guidance"
            [toggleable]="isMobile"
            [toggler]="'header'"
            *ngIf="items?.scheduledItems?.length"
            [collapsed]="isMobile"
          >
            <ng-container>
              <h4>To continue, either:</h4>
              <p>1. Add more items to the timeline</p>
              <h4>or</h4>
              <p>2. Begin this schedule</p>
              <h4>You can come back and amend this schedule at any time.</h4>
            </ng-container>
          </p-panel>
          <br />
          <p-panel
            [header]="items?.scheduledItems?.length ? 'More help' : 'Guidance'"
            [toggleable]="true"
            [toggler]="'header'"
            [collapsed]="items?.scheduledItems?.length > 0"
          >
            <div [ngClass]="items?.scheduledItems?.length && isMobile ? 'overflow-scroll h-14rem' : ''">
              <h4>Use the schedule builder to create a request timeline for a patient.</h4>
              <h4>
                The schedule builder allows you to plan and preview when this patient will receive requests for a
                submission, thoughout their episode of care.
              </h4>

              <p>
                1. Apply a pre-defined schedule template to your patient. Schedule templates are set by your team, and
                are visible in your
                <a (click)="ref.close()" [routerLink]="['/teams', dialogData.collection.teamId, 'schedule-templates']"
                  >team page</a
                >
                in the <strong>'Schedule templates'</strong> section
              </p>

              <h4>or</h4>

              <p>
                2. Build or edit a schedule manually, by adding individual item(s) to the timeline one-by-one. Note, you
                <strong>can</strong> add individual items on top of your schedule templates.
              </p>
            </div>
          </p-panel>
        </div>
        <div class="dialog-footer justify-content-end" [ngClass]="{ hidden: isMobile, flex: !isMobile }">
          <div *ngIf="!isEditing && !showApplyScheduleTemplateForm" [ngClass]="{ 'mt-5 w-full': isMobile }">
            <button
              label="Confirm schedule"
              aria-label="Confirm schedule"
              pButton
              (click)="closeDialog()"
              class="p-button-success"
              [ngClass]="{ 'w-full': isMobile }"
            ></button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #applyScheduleTemplateForm>
      <ic-apply-schedule-template-form
        [collection]="dialogData.collection"
        [patient]="dialogData.patient"
        [recipients]="smsRecipients"
        [forms]="forms"
        [hasExistingScheduledItems]="items.scheduledItems.length > 0"
        (cancelForm)="toggleScheduleTemplateForm(false)"
        (submitForm)="applyScheduleTemplate($event)"
        [emailRecipients]="emailRecipients"
      ></ic-apply-schedule-template-form>
    </ng-template>
  </ng-template>
</ng-container>
