import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FormField } from '@islacare/ic-types';

@Component({
  selector: 'ic-automatic-toggle',
  templateUrl: './automatic-toggle.component.html',
  styleUrls: ['./automatic-toggle.component.scss'],
})
export class AutomaticToggleComponent {
  @Input() field: FormField;
  @Input() control: UntypedFormControl;
  checked = false;
  @ViewChild('fieldTitle') fieldTitle: ElementRef;

  fontWeight() {
    if (this.field.boldTitle === true) {
      return 'bold';
    }
  }
  textAlign() {
    return this.field.alignment || 'center';
  }
  toggle() {
    this.checked = !this.checked;
  }
}
