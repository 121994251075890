<ng-container *ngIf="field && control">
  <div *ngIf="field?.title" style="width: 100%">
    <p #fieldTitle [ngStyle]="{ 'font-weight': fontWeight(), 'text-align': textAlign() }">
      {{ field?.title }}
    </p>
  </div>
  <div class="question-container">
    <div class="toggle-label">
      <p
        *ngIf="checked === false"
        [ngStyle]="{ 'font-weight': fontWeight(), 'text-align': textAlign() }"
      >
        {{ field?.options[0]?.label }}
      </p>
    </div>
    <mat-slide-toggle class="toggle" (click)="toggle()" [formControl]="control"></mat-slide-toggle>
    <div class="toggle-label">
      <p
        *ngIf="checked === true"
        [ngStyle]="{ 'font-weight': fontWeight(), 'text-align': textAlign() }"
      >
        {{ field?.options[1]?.label }}
      </p>
    </div>
  </div>
</ng-container>
