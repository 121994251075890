import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import firebase from 'firebase/compat/app';
import { from, Observable } from 'rxjs';

@Component({
  selector: 'ic-clinical-form-field-image',
  templateUrl: './clinical-form-field-image.component.html',
  styleUrls: []
})
export class ClinicalFormFieldImageComponent extends FieldType<FieldTypeConfig> implements OnInit {
  imageURL$: Observable<string>;
  constructor() {
    super();
  }
  ngOnInit(): void {
    const storageRef: firebase.storage.Reference = firebase
      .storage()
      .ref(this.field?.props.storageURL);
    this.imageURL$ = from(storageRef.getDownloadURL());
  }
}
