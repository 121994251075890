import { EventEmitter, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UploadTask } from '@angular/fire/compat/storage/interfaces';
import { LoggingService } from '@ic-monorepo/services';
import {
  ClinicalFormService,
  FileUploadService,
} from '@ic-monorepo/shared-common';
import { SoundObject, VideoObject } from '@ic-monorepo/shared-submissions';
import { ClinicalFormResponse } from '@islacare/ic-types';
import { EntriesService } from 'apps/frontend/portal/src/app/services/entries/entries.service';
import { TemplateService } from 'apps/frontend/portal/src/app/services/template/template.service';
import firebase from 'firebase/compat/app';
import { WebcamImage } from 'ngx-webcam';

interface FileData {
  file: File;
  fileType: string;
  isVideo: boolean;
  fileUrl: string;
  srcData: string;
  fileUpload: boolean;
}

interface FileObject extends FileData {
  formResponse: null;
  fileName: string;
  fileUpload: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  uploadList: any[] = [];
  getUploadList = new EventEmitter<any[]>();

  storage = firebase.storage();

  constructor(
    private db: AngularFirestore,
    private entriesService: EntriesService,
    private templateService: TemplateService,
    private log: LoggingService,
    private clinicalFormService: ClinicalFormService,
    private fileUploadService: FileUploadService
  ) {}

  async storeBodyMap(bodyMapUrl, patientId, collectionId, entryId) {
    const storagePath = `patients/${patientId}/collections/${collectionId}/${entryId}/bodyMap`;
    const storageRef = this.storage.ref(storagePath);
    await storageRef.putString(bodyMapUrl, 'data_url', {
      contentType: 'image/png',
    });
    return storagePath;
  }

  async storeMedia(
    patientId: string,
    collectionId: string,
    file: Blob | WebcamImage | VideoObject | SoundObject | FileObject | File,
    formOnly: boolean,
    video: boolean,
    sensitiveImage: boolean,
    webcamImage: boolean,
    formResponse: ClinicalFormResponse,
    formIds: string[],
    submitter,
    consentBestInterest?: boolean,
    collectionAuthId?: string,
    dateTime?: Date,
    teamId?: string
  ) {
    const entryId = this.db.createId();

    let storagePath;

    if (formIds.includes('nMeulaCjfiMlj7VzWHfX')) {
      const { bodyMapDataUrl } = formResponse['nMeulaCjfiMlj7VzWHfX'];
      const bodyMap = await this.storeBodyMap(
        bodyMapDataUrl,
        patientId,
        collectionId,
        entryId
      );
      delete formResponse['nMeulaCjfiMlj7VzWHfX'].bodyMapDataUrl;
      formResponse['nMeulaCjfiMlj7VzWHfX'].bodyMap = bodyMap;
    }

    if (
      formIds.includes('9T7kspSeqvivkXIKAsnJ') &&
      typeof formResponse['9T7kspSeqvivkXIKAsnJ'].bodyMapDataUrl !== 'undefined'
    ) {
      const { bodyMapDataUrl } = formResponse['9T7kspSeqvivkXIKAsnJ'];
      const bodyMap = await this.storeBodyMap(
        bodyMapDataUrl,
        patientId,
        collectionId,
        entryId
      );
      delete formResponse['9T7kspSeqvivkXIKAsnJ'].bodyMapDataUrl;
      formResponse['9T7kspSeqvivkXIKAsnJ'].bodyMap = bodyMap;
    }

    Object.keys(formResponse).forEach(async (formId) => {
      if (formResponse[formId]?.isFormlyForm) {
        formResponse[formId] = this.clinicalFormService.uploadFormFiles({
          formData: formResponse[formId],
          patientId,
          collectionId,
          entryId,
        });
      }
    });

    // This only applies to forms w/ bodymaps OR imagemap
    await Promise.all(
      Object.keys(formResponse).map(async (formId) => {
        if (formResponse[formId]?.bodyMapDataUrl?.length) {
          const { bodyMapDataUrl } = formResponse[formId];
          const bodyMap = await this.storeBodyMap(
            bodyMapDataUrl,
            patientId,
            collectionId,
            entryId
          );
          delete formResponse[formId].bodyMapDataUrl;
          formResponse[formId].bodyMap = bodyMap;
        }

        // stores image from form ???
        if (formResponse[formId]?.['imageMapDataUrlArray']?.length) {
          for (
            let i = 0;
            i < formResponse[formId]?.['imageMapDataUrlArray']?.length;
            i++
          ) {
            const imageMapData =
              formResponse[formId]?.['imageMapDataUrlArray'][i];
            const imageMap = await this.storeImageMap(
              imageMapData,
              patientId,
              collectionId,
              entryId
            );
            delete imageMapData['imageURL'];
            imageMapData['imageMap'] = imageMap;
          }
        }
        return formResponse[formId];
      })
    );

    const fileType: string =
      webcamImage || formOnly
        ? 'png'
        : (file as File | Blob).type.split('/')[1];
    //if you want extension to be added to storage path, append extention here.
    const fileExtentionsOnStorage = ['e2e', 'wav', 'mp3'];

    if (formOnly) {
      storagePath = 'form-image.png';
    } else if (fileExtentionsOnStorage.includes(fileType.toLowerCase())) {
      storagePath = `patients/${patientId}/collections/${collectionId}/${entryId}.${fileType}`;
    } else {
      storagePath = `patients/${patientId}/collections/${collectionId}/${entryId}`;
    }

    const storageRef = this.storage.ref(storagePath);
    let fileUpload: UploadTask;
    if (webcamImage) {
      const metadata = {
        contentType: 'image/png',
      };
      fileUpload = storageRef.putString(
        (file as WebcamImage).imageAsBase64,
        'base64',
        metadata
      );
    } else if (!formOnly) {
      fileUpload = storageRef.put(file as File | Blob);
    }
    if (fileUpload) {
      this.fileUploadService.addToUploadList(
        fileUpload,
        file instanceof File ? file.name : null
      );
      await fileUpload;
    }

    await this.entriesService.createEntry(
      patientId,
      collectionId,
      entryId,
      storagePath,
      fileType,
      video,
      formOnly,
      sensitiveImage,
      formResponse,
      formIds,
      submitter,
      consentBestInterest,
      collectionAuthId,
      dateTime,
      teamId
    );
    return 'success';
  }

  async storeTemplateMedia(teamId: string, templateId: string, file: Blob) {
    const basePath = `teams/${teamId}/collectionTemplates/${templateId}`;
    const fileName = this.db.createId();
    const storageRef = this.storage.ref();

    return await storageRef
      .child(`${basePath}/${fileName}`)
      .put(file as Blob)
      .then(async (d) => {
        this.log.consoleLog(d);
        return await this.templateService
          .updateVideoPath(basePath, fileName, file.type)
          .then(() => {
            this.log.consoleLog('added updated routing');
          });
      });
  }

  async storeImageMap(imageMapData, patientId, collectionId, entryId) {
    const storagePath = `patients/${patientId}/collections/${collectionId}/${entryId}/${imageMapData?.controlName}`;
    const storageRef = this.storage.ref(storagePath);
    const type = imageMapData?.imageURL?.split(';')[0]?.split(':')[1];
    await storageRef.putString(imageMapData?.imageURL, 'data_url', {
      contentType: type,
    });
    return storagePath;
  }

  async uploadFileToStorage(
    storagePath: string,
    blobUrl: string,
    fileType?: string
  ) {
    const storageRef = this.storage.ref(storagePath);
    const type = fileType || blobUrl?.split(';')[0]?.split(':')[1];
    await storageRef.putString(blobUrl, 'data_url', { contentType: type });
  }
}
