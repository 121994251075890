import { ComponentRef, Injectable } from '@angular/core';
import { DialogService, DynamicDialogComponent } from 'primeng/dynamicdialog';

@Injectable({
  providedIn: 'root',
})
export class DialogRefService {
  constructor(private dialogService: DialogService) {}

  closeAllDialogs(): void {
    const dialogComponentRefMap = this.dialogService.dialogComponentRefMap;

    dialogComponentRefMap.forEach(
      (componentRef: ComponentRef<DynamicDialogComponent>) => {
        componentRef.instance.close();
      }
    );
  }
}
