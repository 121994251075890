<ng-container *ngIf="field && control">
  <div class="question-container">
    <div *ngIf="field?.title" style="width: 100%">
      <!-- prettier-ignore -->
      <p
        #fieldTitle
        [ngStyle]="{
          'font-weight': fontWeight(),
          'text-align': textAlign(),
          color: control?.touched && control?.invalid ? '#ee7324' : null
        }"
        class="radio-title"
      >{{field?.title}}{{ field?.validation?.required ? ' *' : '' }}</p>
    </div>

    <mat-radio-group
      class="full-width-field"
      appearance="fill"
      [formControl]="control"
      [ngStyle]="{ color: control?.touched && control?.invalid ? '#ee7324' : null }"
    >
      <mat-label *ngIf="field?.subTitle">{{ field?.subTitle }}</mat-label>
      <mat-radio-button
        *ngFor="let option of field?.options"
        [value]="option?.label"
        [ngStyle]="
          field.newLine
            ? { display: 'flex', 'flex-wrap': 'wrap', 'justify-content': 'center' }
            : null
        "
      >
        <p class="wrap-radio-label">{{ option?.label | camelCaseToTitleCase }}</p>
      </mat-radio-button>
      <p
        *ngIf="control?.touched && control?.invalid"
        class="mat-error"
        [ngStyle]="{ 'text-align': textAlign() }"
      >
        {{ formService?.getErrorMessage(control) }}
      </p>
    </mat-radio-group>
  </div>
</ng-container>
