<form [formGroup]="burnsScarForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <div style="margin: 0 auto">
      <h3>Burns scar assessment form</h3>
      <p>
        Please mark the scales below to show how intense your scarring is. A zero (0) means normal
        skin, and ten (10) means worst imaginable scar.
      </p>
    </div>
    <div>
      <p>Is the scar painful?</p>
      <div class="full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>0</div>
          <div>No pain</div>
        </div>
        <mat-slider
          class="full-width-field"
          formControlName="scarPainful"
          value="scarPainful"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>10</div>
          <div>Extreme pain</div>
        </div>
      </div>
    </div>
    <div>
      <p>Is the scar itching?</p>
      <div class="full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>0</div>
          <div>No itching</div>
        </div>
        <mat-slider
          class="full-width-field"
          formControlName="scarItching"
          value="scarItching"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>10</div>
          <div>Extreme itching</div>
        </div>
      </div>
    </div>
    <div>
      <p>Is the colour of the scar different?</p>
      <div class="full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>0</div>
          <div>No different colour</div>
        </div>
        <mat-slider
          class="full-width-field"
          formControlName="scarDifferentColour"
          value="scarDifferentColour"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>10</div>
          <div>Really different colour</div>
        </div>
      </div>
    </div>
    <div>
      <p>Is the scar more stiff?</p>
      <div class="full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>0</div>
          <div>No stiffness</div>
        </div>
        <mat-slider
          class="full-width-field"
          formControlName="scarStiffness"
          value="scarStiffness"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>10</div>
          <div>Extremely stiff</div>
        </div>
      </div>
    </div>
    <div>
      <p>Is the thickness of the scar different?</p>
      <div class="full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>0</div>
          <div>No thickness</div>
        </div>
        <mat-slider
          class="full-width-field"
          formControlName="scarThickness"
          value="scarThickness"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>10</div>
          <div>Extreme thickness</div>
        </div>
      </div>
    </div>
    <div>
      <p>Is the scar irregular?</p>
      <div class="full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>0</div>
          <div>Not irregular</div>
        </div>
        <mat-slider
          class="full-width-field"
          formControlName="scarIrregular"
          value="scarIrregular"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>10</div>
          <div>Extremely irregular</div>
        </div>
      </div>
    </div>
    <div>
      <p>Overall opinion of scar</p>
      <div class="full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>0</div>
          <div>As normal skin</div>
        </div>
        <mat-slider
          class="full-width-field"
          formControlName="scarOpinion"
          value="scarOpinion"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>10</div>
          <div>Worst imaginable scar</div>
        </div>
      </div>
    </div>
    <button mat-raised-button class="positive-button" type="submit" [disabled]="isSubmitting">
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
