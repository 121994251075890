import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Timestamp } from '@firebase/firestore-types';
import { Collection, Entry, Patient, PatientWithId, SystmOneTeamConfigs } from '@islacare/ic-types';
import { environment } from 'apps/frontend/portal/src/environments/environment';
import firebase from 'firebase/compat/app';
import { switchMap, take } from 'rxjs/operators';
import { AuditService } from '../../../../services/audit/audit.service';
import { EntriesService } from '../../../../services/entries/entries.service';
import { LoggingService } from '../../../services/logging/logging.service';
import { SystmOneLinkAccountDialogComponent } from '../../dialogs/systm-one-link-account-dialog/systm-one-link-account-dialog.component';

interface SystmOneSavedStatus {
  data: {
    systmOneStatus: {
      saved: boolean;
      timeOfFunctionCall: Timestamp;
    };
  };
}

@Injectable({
  providedIn: 'root',
})
export class SystmOneService {
  constructor(
    private loggingService: LoggingService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private auditService: AuditService,
    private entriesService: EntriesService,
  ) {}

  async callLinkAcc$(patient: PatientWithId, s1TeamConfigs: SystmOneTeamConfigs) {
    const s1InstanceName = s1TeamConfigs.instanceName;

    const dialogRef = this.dialog.open(SystmOneLinkAccountDialogComponent, {
      width: '50%',
      data: {
        s1InstanceName,
        patientNhsNumber: patient.nhs,
      },
      hasBackdrop: true,
    });

    return dialogRef.afterClosed().pipe(
      take(1),
      switchMap(async result => {
        if (result) {
          this.snackBar.open('Linking patient to SystmOne...');
          const systmOnePatientAccountId = result.systmOnePatientAccountId;
          const systmOnePatientPassphrase = result.systmOnePatientPassphrase;

          const linkPatientAccountWithSystmOneResult = firebase
            .app()
            .functions(environment.region)
            .httpsCallable('linkPatientAccountWithSystmOne');

          const odsCode = s1TeamConfigs.odsCode;
          const patientId = patient.id;

          const finalLinkResult = await linkPatientAccountWithSystmOneResult({
            patientId,
            odsCode,
            systmOnePatientAccountId,
            systmOnePatientPassphrase,
          });

          if (finalLinkResult.data.success) {
            this.snackBar.dismiss();
            this.loggingService.consoleLog('finalLinkResult success', finalLinkResult);
            const snackBarMessage = finalLinkResult.data.message;
            this.snackBar.open(snackBarMessage, 'Got it!', { duration: 10000 });
            await this.auditService.systmOneLinkAccountSuccess(patientId);
            return { success: true, odsCode: s1TeamConfigs.odsCode };
          } else {
            this.snackBar.dismiss();
            this.loggingService.consoleLog('finalLinkResult error', finalLinkResult);
            const snackBarMessage = finalLinkResult.data.message;
            this.snackBar.open(snackBarMessage, 'Got it!', { duration: 10000 });
            await this.auditService.systmOneLinkAccountFailure(patientId);
            return { success: false };
          }
        } else {
          return { success: false };
        }
      }),
    );
  }

  getNonConnectedS1Instances(patientId, overlappingPatientAndUserTeamIds) {
    const getNonConnectedS1InstancesFBFunction = firebase
      .app()
      .functions(environment.region)
      .httpsCallable('getNonConnectedS1Instances');

    return getNonConnectedS1InstancesFBFunction({
      patientId,
      overlappingPatientAndUserTeamIds,
    });
  }

  async isPatientConnectedWithS1(userTeamIds: string[], patientTeamIds: string[], patientId: string) {
    const userPatientTeamOverlap = patientTeamIds.filter(teamId => userTeamIds.includes(teamId));
    const isPatientConnectedWithS1 = await this.isPatientConnectedWithTeamS1Instance(
      patientId,
      userPatientTeamOverlap[0],
    );

    return isPatientConnectedWithS1.data;
  }

  isPatientConnectedWithTeamS1Instance(patientId, teamId) {
    const isPatientConnectedWithTeamS1InstanceFBFunction = firebase
      .app()
      .functions(environment.region)
      .httpsCallable('isPatientConnectedWithTeamS1Instance');

    return isPatientConnectedWithTeamS1InstanceFBFunction({
      patientId,
      teamId,
    });
  }

  async callSendMessageToSystmOneTrigger(
    entry: Entry,
    patientId: string,
    collectionId: string,
    entryId: string,
    teamId: string,
    orgId: string,
    patient: Patient,
    collection: Collection,
  ): Promise<{
    success: boolean;
  }> {
    const sendMessageToSystmOneResult = firebase
      .app()
      .functions('europe-west2')
      .httpsCallable('sendMessageToSystmOneTrigger');

    const sendMessageResult = (await sendMessageToSystmOneResult({
      entry,
      patientId,
      collectionId,
      entryId,
      teamId,
      orgId,
      patient,
      collection,
    })) as SystmOneSavedStatus;

    if (sendMessageResult && sendMessageResult?.data?.systmOneStatus?.saved) {
      const update: Partial<Entry> = {
        systmOneStatus: sendMessageResult.data.systmOneStatus,
      };
      await this.entriesService.updateEntry(patientId, collectionId, entryId, update);
      return { success: true };
    } else {
      return { success: false };
    }
  }
}
