import { Component } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Patient } from '@islacare/ic-types';
import { AuditService } from 'apps/frontend/portal/src/app/services/audit/audit.service';
import firebase from 'firebase/compat/app';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogData } from '../dialog-data';

interface DialogDataWithPatientIds extends DialogData {
  patientIds: any[];
  action: string;
  isArchived: boolean;
}

@Component({
  selector: 'ic-confirm-dialog',
  templateUrl: './confirm-dialog-png.component.html',
  styleUrls: ['./confirm-dialog-png.component.scss'],
  providers: [ConfirmationService],
})
export class ConfirmNgDialogComponent {
  get data(): DialogDataWithPatientIds {
    return this.config.data;
  }
  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private analytics: AngularFireAnalytics,
    private audit: AuditService,
    private db: AngularFirestore,
    private messageService: MessageService,
    public confirmationService: ConfirmationService
  ) {}

  confirm() {
    if (
      this.config.data.patientIds.length > 0 &&
      this.config.data.action == 'archive'
    ) {
      this.analytics.logEvent('pv-confirm-bulk-archive-patients');
      this.config.data.patientIds.map(async (patientId) => {
        this.audit.archivePatientManual(patientId);
        await this.db
          .doc<Patient>(`patients/${patientId}`)
          .update({ isArchived: true });
      });
      this.messageService.add({
        severity: 'success',
        summary: `${
          this.config.data.patientIds.length > 1 ? 'Patients' : 'Patient'
        } archived successfully.`,
        life: 1000,
      });
    } else if (
      this.config.data.patientIds.length > 0 &&
      this.config.data.action == 'Unarchive'
    ) {
      const payload = {
        isArchived: false,
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      };
      this.config.data.patientIds.forEach((patientId) =>
        this.audit.unArchivePatientManual(patientId)
      );
      this.config.data.patientIds.map(
        async (patientId) =>
          await this.db
            .doc<Patient>(`patients/${patientId}`)
            .update(payload as Partial<Patient>)
      );
      this.messageService.add({
        severity: 'success',
        summary: `${
          this.config.data.patientIds.length > 1 ? 'Patients' : 'Patient'
        } unarchived successfully.`,
        life: 1000,
      });
    }
    this.ref.close();
  }
}
