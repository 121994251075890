import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { FormWithId } from '@islacare/ic-types';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'ic-ssi-surveillance-form',
  templateUrl: './ssi-surveillance-form.component.html',
  styleUrls: ['./ssi-surveillance-form.component.scss'],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class SsiSurveillanceFormComponent implements OnInit {
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  entryForm: UntypedFormGroup;
  isSubmitting = false;
  discharge = false;
  antibiotics = false;
  readmission = false;
  surgicalWoundHealing: string;
  isSensitive: boolean;
  form$: Observable<FormWithId>;

  observationList: string[] = [
    'Pain or soreness in addition to the discomfort experienced following the operation',
    'Redness or inflammation spreading from the edges of the wound',
    'The area around the wound felt warmer/hotter than the surrounding skin',
    'The area around the wound became swollen',
    'The edges of any part of the wound separated or gaped open',
  ];

  answerList: string[] = ['Not at all', 'A little', 'Quite a bit', 'A lot'];

  async ngOnInit(): Promise<void> {
    this.form$ = this.form.getForm(this.data.teamId, this.data.formId);

    this.entryForm = this.fb.group({
      surgicalWoundHealingProblems: [''],
      surgicalCategory: [''],
      surgicalWound: [''],
      // chest
      chestWoundRednessSpreading: [''],
      chestWoundAreaWarmer: [''],
      chestWoundLeakedClearFluid: [''],
      chestWoundLeakedBloodstainedFluid: [''],
      chestWoundLeakedYellowGreenFluid: [''],
      chestWoundSeparatedOrGaped: [''],
      chestWoundDeeperTissueSeparate: [''],
      chestWoundBecomeSwollen: [''],
      chestWoundBeenSmelly: [''],
      chestWoundPainfulToTouch: [''],
      chestWoundRaiseInTemperature: [''],
      chestWoundObservedDate: [''],

      // left leg
      leftLegWoundRednessSpreading: [''],
      leftLegWoundAreaWarmer: [''],
      leftLegWoundLeakedClearFluid: [''],
      leftLegWoundLeakedBloodstainedFluid: [''],
      leftLegWoundLeakedYellowGreenFluid: [''],
      leftLegWoundSeparatedOrGaped: [''],
      leftLegWoundDeeperTissueSeparate: [''],
      leftLegWoundBecomeSwollen: [''],
      leftLegWoundBeenSmelly: [''],
      leftLegWoundPainfulToTouch: [''],
      leftLegWoundRaiseInTemperature: [''],
      leftLegWoundObservedDate: [''],

      // right leg
      rightLegWoundRednessSpreading: [''],
      rightLegWoundAreaWarmer: [''],
      rightLegWoundLeakedClearFluid: [''],
      rightLegWoundLeakedBloodstainedFluid: [''],
      rightLegWoundLeakedYellowGreenFluid: [''],
      rightLegWoundSeparatedOrGaped: [''],
      rightLegWoundDeeperTissueSeparate: [''],
      rightLegWoundBecomeSwollen: [''],
      rightLegWoundBeenSmelly: [''],
      rightLegWoundPainfulToTouch: [''],
      rightLegWoundRaiseInTemperature: [''],
      rightLegWoundObservedDate: [''],

      // left arm
      leftArmWoundRednessSpreading: [''],
      leftArmWoundAreaWarmer: [''],
      leftArmWoundLeakedClearFluid: [''],
      leftArmWoundLeakedBloodstainedFluid: [''],
      leftArmWoundLeakedYellowGreenFluid: [''],
      leftArmWoundSeparatedOrGaped: [''],
      leftArmWoundDeeperTissueSeparate: [''],
      leftArmWoundBecomeSwollen: [''],
      leftArmWoundBeenSmelly: [''],
      leftArmWoundPainfulToTouch: [''],
      leftArmWoundRaiseInTemperature: [''],
      leftArmWoundObservedDate: [''],

      // right arm
      rightArmWoundRednessSpreading: [''],
      rightArmWoundAreaWarmer: [''],
      rightArmWoundLeakedClearFluid: [''],
      rightArmWoundLeakedBloodstainedFluid: [''],
      rightArmWoundLeakedYellowGreenFluid: [''],
      rightArmWoundSeparatedOrGaped: [''],
      rightArmWoundDeeperTissueSeparate: [''],
      rightArmWoundBecomeSwollen: [''],
      rightArmWoundBeenSmelly: [''],
      rightArmWoundPainfulToTouch: [''],
      rightArmWoundRaiseInTemperature: [''],
      rightArmWoundObservedDate: [''],

      // right hip
      rightHipWound: [''],
      rightHipWoundRednessSpreading: [''],
      rightHipWoundAreaWarmer: [''],
      rightHipWoundLeakedClearFluid: [''],
      rightHipWoundLeakedBloodstainedFluid: [''],
      rightHipWoundLeakedYellowGreenFluid: [''],
      rightHipWoundSeparatedOrGaped: [''],
      rightHipWoundDeeperTissueSeparate: [''],
      rightHipWoundBecomeSwollen: [''],
      rightHipWoundBeenSmelly: [''],
      rightHipWoundPainfulToTouch: [''],
      rightHipWoundRaiseInTemperature: [''],
      rightHipWoundObservedDate: [''],

      // left hip
      leftHipWound: [''],
      leftHipWoundRednessSpreading: [''],
      leftHipWoundAreaWarmer: [''],
      leftHipWoundLeakedClearFluid: [''],
      leftHipWoundLeakedBloodstainedFluid: [''],
      leftHipWoundLeakedYellowGreenFluid: [''],
      leftHipWoundSeparatedOrGaped: [''],
      leftHipWoundDeeperTissueSeparate: [''],
      leftHipWoundBecomeSwollen: [''],
      leftHipWoundBeenSmelly: [''],
      leftHipWoundPainfulToTouch: [''],
      leftHipWoundRaiseInTemperature: [''],
      leftHipWoundObservedDate: [''],

      // right knee
      rightKneeWound: [''],
      rightKneeWoundRednessSpreading: [''],
      rightKneeWoundAreaWarmer: [''],
      rightKneeWoundLeakedClearFluid: [''],
      rightKneeWoundLeakedBloodstainedFluid: [''],
      rightKneeWoundLeakedYellowGreenFluid: [''],
      rightKneeWoundSeparatedOrGaped: [''],
      rightKneeWoundDeeperTissueSeparate: [''],
      rightKneeWoundBecomeSwollen: [''],
      rightKneeWoundBeenSmelly: [''],
      rightKneeWoundPainfulToTouch: [''],
      rightKneeWoundRaiseInTemperature: [''],
      rightKneeWoundObservedDate: [''],

      // left knee
      leftKneeWound: [''],
      leftKneeWoundRednessSpreading: [''],
      leftKneeWoundAreaWarmer: [''],
      leftKneeWoundLeakedClearFluid: [''],
      leftKneeWoundLeakedBloodstainedFluid: [''],
      leftKneeWoundLeakedYellowGreenFluid: [''],
      leftKneeWoundSeparatedOrGaped: [''],
      leftKneeWoundDeeperTissueSeparate: [''],
      leftKneeWoundBecomeSwollen: [''],
      leftKneeWoundBeenSmelly: [''],
      leftKneeWoundPainfulToTouch: [''],
      leftKneeWoundRaiseInTemperature: [''],
      leftKneeWoundObservedDate: [''],

      // general wound
      generalWoundRednessSpreading: [''],
      generalWoundAreaWarmer: [''],
      generalWoundLeakedClearFluid: [''],
      generalWoundLeakedBloodstainedFluid: [''],
      generalWoundLeakedYellowGreenFluid: [''],
      generalWoundSeparatedOrGaped: [''],
      generalWoundDeeperTissueSeparate: [''],
      generalWoundBecomeSwollen: [''],
      generalWoundBeenSmelly: [''],
      generalWoundPainfulToTouch: [''],
      generalWoundRaiseInTemperature: [''],
      generalWoundObservedDate: [''],

      antibiotics: ['', Boolean],
      prescriber: [''],
      prescriberOther: [''],
      readmission: ['', Boolean],
      readmissionLocation: [''],
      notes: [''],
      sensitiveImage: ['', [Validators.required, Boolean]],
    });

    this.form.retreive$.pipe(take(1)).subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.entryForm.controls[key])
          this.entryForm.controls[key].setValue(formData[key]);
      });
      const sensitive = this.entryForm.get('sensitiveImage').value;
      this.setSensitiveImage(sensitive);
    });
  }

  get surgicalCategory() {
    return this.entryForm.get('surgicalCategory');
  }

  setDischarge() {
    this.discharge = !this.discharge;
  }

  setAntibiotics() {
    this.antibiotics = !this.antibiotics;
  }

  setReadmission() {
    this.readmission = !this.readmission;
  }

  setAntibioticsFalse(response) {
    if (response === false) {
      this.entryForm.get('prescriber').setValue('');
    }
  }

  onSubmit() {
    this.isSubmitting = true;
    const formResponse = {
      ...this.entryForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  setSensitiveImage(status: boolean) {
    if (typeof status === 'string') {
      this.form.setSensitiveImage(false);
      this.isSensitive = false;
    } else if (status || status === false) {
      this.form.setSensitiveImage(status);
      this.isSensitive = status;
    } else {
      this.isSensitive = !this.isSensitive;
      this.form.setSensitiveImage(this.isSensitive);
    }
  }
}
