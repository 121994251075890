import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, isDevMode } from '@angular/core';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire/compat/';
import {
  APP_NAME,
  APP_VERSION,
  AngularFireAnalyticsModule,
  UserTrackingService,
} from '@angular/fire/compat//analytics';
import { AngularFireAuthModule } from '@angular/fire/compat//auth';
import { AngularFirestoreModule } from '@angular/fire/compat//firestore';
import { AngularFireStorageModule } from '@angular/fire/compat//storage';
import { AngularFirePerformanceModule, PerformanceMonitoringService } from '@angular/fire/compat/performance';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ClinicalFormEditorStore } from '@ic-monorepo/feature-clinical-forms';
import {
  CamelCaseToTitleCasePipe,
  FileUploadService,
  FileUploaderPanelComponent,
  PrimeNgModule,
  StringToCapitalisedCasePipe,
  UUIDService,
  WINDOW_PROVIDER,
} from '@ic-monorepo/shared-common';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { TraceService, init, routingInstrumentation } from '@sentry/angular-ivy';
import { Integrations } from '@sentry/tracing';
import { IntercomModule } from '@supy-io/ngx-intercom';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { WebcamModule } from 'ngx-webcam';
import { DialogService } from 'primeng/dynamicdialog';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalErrorHandler } from './core/errors/global-error-handler';
import { LayoutModule } from './core/layout/layout.module';
import { AuthStore } from './feature-auth/store/auth.store';
import { AutomaticFormModule } from './feature-automatic-form/automatic-form.module';
import { ClinicianSubmissionStore } from './feature-clinician-submission/store/clinician-submission.store';
import { PatientRecordStore } from './feature-patient-record/redesign/store/patient-record.store';
import { SubmissionFlowStore } from './feature-patient-submission/store/submission-flow.store';
import { SubmissionDashboardMediaStore } from './feature-submissions-dashboard/store/media/submission-dashboard-media.store';
import { SubmissionDashboardStore } from './feature-submissions-dashboard/store/submission-dashboard.store';
import { UserDashboardStore } from './feature-user-dashboard/store/user-dashboard.store';
import { GraphQLModule } from './graphql.module';
import { CheckForUpdateService } from './services/check-for-update/check-for-update.service';
import { MainDrawerService } from './services/main-drawer/main-drawer.service';
import { NhsPipe } from './shared/pipes/nhs.pipe';
import { LoggingService } from './shared/services/logging/logging.service';
import { EntryComparisonStore } from './store/entry-comparison/entry-comparison.store';

+init({
  environment: environment.name,
  release: `isla-portal@${environment.version}`,
  dsn: environment.sentryDsn,
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', environment.url],
      routingInstrumentation: routingInstrumentation,
    }),
  ],
  tracesSampleRate: 0.5,
  beforeSend: event => {
    if (window.location.hostname === 'localhost') {
      return null;
    }
    return event;
  },
});

const stores = [
  AuthStore,
  EntryComparisonStore,
  SubmissionFlowStore,
  ClinicianSubmissionStore,
  PatientRecordStore,
  UserDashboardStore,
  ClinicalFormEditorStore,
  SubmissionDashboardStore,
  SubmissionDashboardMediaStore,
];
const libs = [PdfViewerModule, FileUploaderPanelComponent];

@NgModule({
  declarations: [AppComponent],
  imports: [
    ...libs,
    CommonModule,
    BrowserModule,
    HttpClientModule,
    LayoutModule,
    BrowserAnimationsModule,
    PrimeNgModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAnalyticsModule,
    provideAnalytics(() => getAnalytics()),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    provideStorage(() => getStorage()),
    AppRoutingModule,
    AutomaticFormModule,
    WebcamModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    IntercomModule.forRoot({
      appId: environment.intercomAppId, // from your Intercom config
      updateOnRouterChange: true, // will automatically run `update` on router event changes. Default: `false`
      alignment: 'left',
    }),
    PinchZoomModule,
    AngularFirePerformanceModule,
    GraphQLModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  exports: [],
  providers: [
    ...stores,
    UUIDService,
    DialogService,
    FileUploadService,
    UserTrackingService,
    LoggingService,
    PerformanceMonitoringService,
    StringToCapitalisedCasePipe,
    CamelCaseToTitleCasePipe,
    MainDrawerService,
    NhsPipe,
    CheckForUpdateService,
    WINDOW_PROVIDER,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    { provide: TraceService, deps: [Router] },
    { provide: APP_VERSION, useValue: environment.version },
    { provide: APP_NAME, useValue: environment.firebase.projectId },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
      deps: [TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
