<form [formGroup]="entryForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <mat-form-field class="half-width-field" appearance="fill">
      <mat-label>Please enter the date of discharge</mat-label>
      <input matInput type="date" formControlName="dateOfDischarge" />
    </mat-form-field>

    <mat-form-field class="half-width-field" appearance="fill">
      <mat-label>Site</mat-label>
      <mat-select formControlName="site">
        <mat-option value="Chelsea">Chelsea</mat-option>
        <mat-option value="Sutton">Sutton</mat-option>
        <mat-option value="Kingston">Kingston</mat-option>
        <mat-option value="Cavendish Square">Cavendish Square</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="half-width-field" appearance="fill">
      <mat-label>Ward or area of discharge</mat-label>
      <mat-select formControlName="ward">
        <mat-option value="">Option one</mat-option>
        <mat-option value="">Option two</mat-option>
        <mat-option value="">Option three</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="half-width-field" appearance="fill">
      <mat-label>Consultant</mat-label>
      <mat-select formControlName="consultant">
        <mat-option value="">Consultant one</mat-option>
        <mat-option value="">Consultant two</mat-option>
        <mat-option value="">Consultant three</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="half-width-field" appearance="fill">
      <mat-label>Staff name providing PaD </mat-label>
      <input
        matInput
        formControlName="staffProvidingPad"
        type="text"
        autocomplete="off"
      />
      <mat-error *ngIf="staffProvidingPad.touched && staffProvidingPad.invalid">
        Please enter a staff name providing PaD
      </mat-error>
    </mat-form-field>

    <mat-form-field class="half-width-field" appearance="fill">
      <mat-label>Type of wound</mat-label>
      <mat-select multiple formControlName="typeOfWound">
        <mat-option value="Pressure ulcer">Pressure ulcer</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      class="half-width-field"
      appearance="fill"
      *ngIf="
        entryForm.get('typeOfWound').value.indexOf('Pressure ulcer') !== -1
      "
    >
      <mat-label>Category of pressure ulcer</mat-label>
      <mat-select formControlName="pressureUlcerCategory">
        <mat-option value="Category 1">Category 1</mat-option>
        <mat-option value="Category 2">Category 2</mat-option>
        <mat-option value="Category 3">Category 3</mat-option>
        <mat-option value="Category 4">Category 4</mat-option>
        <mat-option value="Unstageable">Unstageable</mat-option>
        <mat-option value="Deep Tissue Injury">Deep Tissue Injury</mat-option>
        <mat-option value="Mucosal">Mucosal</mat-option>
      </mat-select>
    </mat-form-field>

    <h3>Wound Assessment</h3>

    <mat-form-field class="half-width-field" appearance="fill">
      <mat-label>Location</mat-label>
      <mat-select formControlName="location">
        <mat-option value="Left upper leg">Left upper leg</mat-option>
        <mat-option value="Right upper leg">Right upper leg</mat-option>
        <mat-option value="Left lower leg">Left lower leg</mat-option>
        <mat-option value="Right lower leg">Right lower leg</mat-option>
        <mat-option value="Left ankle">Left ankle</mat-option>
        <mat-option value="Right ankle">Right ankle</mat-option>
        <mat-option value="Left foot">Left foot</mat-option>
        <mat-option value="Right foot">Right foot</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="full-width-group" style="margin: auto">
      <mat-label class="label-header">Measurements</mat-label>
      <br />
      <mat-form-field class="single-width-field">
        <input
          matInput
          type="number"
          placeholder="Length"
          formControlName="length"
        />
        <span matSuffix>cm</span>
      </mat-form-field>

      <mat-form-field class="single-width-field">
        <input
          matInput
          type="number"
          placeholder="Width"
          formControlName="width"
        />
        <span matSuffix>cm</span>
      </mat-form-field>

      <mat-form-field class="single-width-field">
        <input
          matInput
          type="number"
          placeholder="Depth"
          formControlName="depth"
        />
        <span matSuffix>cm</span>
      </mat-form-field>
    </div>

    <div class="full-width-group" style="margin: auto">
      <mat-label class="label-header">TIMES framework</mat-label>
      <br />
      <mat-form-field class="single-width-field">
        <input
          matInput
          type="number"
          placeholder="Epithelia"
          formControlName="epithelia"
          #epithelia
          matTooltip="Pink or white tissue"
        />
        <span matSuffix>%</span>
        <mat-hint class="extended-hint-field" align="start"
          >Total assigned:
          {{
            epithelia.value +
              granulation.value +
              hypergranulation.value +
              slough.value +
              necrotic.value +
              haematoma.value +
              boneTendonFascia.value
          }}%</mat-hint
        >
      </mat-form-field>

      <mat-form-field class="single-width-field">
        <input
          matInput
          type="number"
          placeholder="Granulation"
          formControlName="granulation"
          #granulation
          matTooltip="Red tissue"
        />
        <span matSuffix>%</span>
      </mat-form-field>

      <mat-form-field class="single-width-field">
        <input
          matInput
          type="number"
          placeholder="Hypergranulation"
          formControlName="hypergranulation"
          #hypergranulation
          matTooltip="Bright or dark red tissue"
        />
        <span matSuffix>%</span>
      </mat-form-field>

      <mat-form-field class="single-width-field">
        <input
          matInput
          type="number"
          placeholder="Slough"
          formControlName="slough"
          #slough
          matTooltip="Yellow, green or brown tissue"
        />
        <span matSuffix>%</span>
      </mat-form-field>

      <mat-form-field class="single-width-field">
        <input
          matInput
          type="number"
          placeholder="Necrotic"
          formControlName="necrotic"
          #necrotic
          matTooltip="Black tissue"
        />
        <span matSuffix>%</span>
      </mat-form-field>

      <mat-form-field class="single-width-field">
        <input
          matInput
          type="number"
          placeholder="Haematoma"
          formControlName="haematoma"
          #haematoma
        />
        <span matSuffix>%</span>
      </mat-form-field>

      <mat-form-field class="single-width-field">
        <input
          matInput
          type="number"
          placeholder="Bone/tendon/fascia"
          formControlName="boneTendonFascia"
          #boneTendonFascia
        />
        <span matSuffix>%</span>
      </mat-form-field>
    </div>

    <mat-form-field class="half-width-field" appearance="fill">
      <mat-label>Edges</mat-label>
      <mat-select multiple formControlName="edges">
        <mat-option value="Healthy">Healthy</mat-option>
        <mat-option value="Callused">Callused</mat-option>
        <mat-option value="Rolled">Rolled</mat-option>
        <mat-option value="Macerated">Macerated</mat-option>
        <mat-option value="Boggy">Boggy</mat-option>
        <mat-option value="Excoriated">Excoriated</mat-option>
      </mat-select>
    </mat-form-field>

    <br />

    <mat-radio-group
      class="half-width-field"
      appearance="fill"
      formControlName="signsOfInfection"
    >
      <mat-label>Any signs of infection?</mat-label>
      <mat-radio-button value="Yes">Yes</mat-radio-button>
      <mat-radio-button value="No">No</mat-radio-button>
    </mat-radio-group>

    <br />

    <mat-radio-group
      class="half-width-field"
      appearance="fill"
      formControlName="woundSwabSent"
    >
      <mat-label>Wound swab sent?</mat-label>
      <mat-radio-button value="Yes">Yes</mat-radio-button>
      <mat-radio-button value="No">No</mat-radio-button>
    </mat-radio-group>

    <br />

    <mat-radio-group
      class="half-width-field"
      appearance="fill"
      formControlName="antibiotics"
    >
      <mat-label>Antibiotics?</mat-label>
      <mat-radio-button value="Yes">Yes</mat-radio-button>
      <mat-radio-button value="No">No</mat-radio-button>
    </mat-radio-group>

    <br />

    <mat-form-field
      class="half-width-field"
      appearance="fill"
      *ngIf="entryForm.get('signsOfInfection').value === 'Yes'"
    >
      <mat-label>Microbiology results for wound</mat-label>
      <mat-select multiple formControlName="microbiologyResults">
        <mat-option value="">option</mat-option>
        <mat-option value="">option</mat-option>
      </mat-select>
    </mat-form-field>

    <div>
      <!-- pressure ulcer photos? -->
    </div>

    <h3>Recommended wound treatment plan</h3>
    <mat-form-field class="half-width-field" appearance="fill">
      <mat-label>Cleanse with:</mat-label>
      <mat-select multiple formControlName="recommendedTreatment">
        <mat-option value="Tap water">Tap water</mat-option>
        <mat-option value="Normal saline">Normal saline</mat-option>
        <mat-option value="PHMB irrigation solution"
          >PHMB irrigation solution</mat-option
        >
      </mat-select>
    </mat-form-field>

    <br />

    <mat-radio-group
      class="half-width-field"
      appearance="fill"
      formControlName="barrierFilm"
    >
      <mat-label>Barrier film to the surrounding skin:</mat-label>
      <mat-radio-button value="Yes">Yes</mat-radio-button>
      <mat-radio-button value="No">No</mat-radio-button>
    </mat-radio-group>

    <br />

    <mat-form-field class="half-width-field" appearance="fill">
      <mat-label>Primary dressing:</mat-label>
      <mat-select multiple formControlName="primaryDressing">
        <mat-option value="Non-required">Non-required</mat-option>
        <mat-option value="Non-adherent wound contact layer"
          >Non-adherent wound contact layer</mat-option
        >
        <mat-option value="Hydrogel">Hydrogel</mat-option>
        <mat-option value="Hydrocolloid">Hydrocolloid</mat-option>
        <mat-option value="Silicone bordered polyurethane foam"
          >Silicone bordered polyurethane foam</mat-option
        >
        <mat-option value="Alginate">Alginate</mat-option>
        <mat-option value="Hydrofiber">Hydrofiber</mat-option>
        <mat-option value="Honey">Honey</mat-option>
        <mat-option value="Silver">Silver</mat-option>
        <mat-option value="PHMB gel">PHMB gel</mat-option>
        <mat-option value="Alginogel">Alginogel</mat-option>
        <mat-option value="Iodine">Iodine</mat-option>
        <mat-option value="Tubular bandage">Tubular bandage</mat-option>
        <mat-option value="Soft bandage">Soft bandage</mat-option>
        <mat-option value="Crepe bandage">Crepe bandage</mat-option>
        <mat-option value="NPWT">NPWT</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      class="half-width-field"
      appearance="fill"
      *ngIf="entryForm.get('primaryDressing').value.indexOf('NPWT') !== -1"
    >
      <mat-label>NPWT options</mat-label>
      <mat-select formControlName="npwtOptions">
        <mat-option value="Topical / incisional (i.e. PICO)"
          >Topical / incisional (i.e. PICO)</mat-option
        >
        <mat-option value="VAC (Renasys Touch)">VAC (Renasys Touch)</mat-option>
      </mat-select>
    </mat-form-field>

    <div
      *ngIf="
        entryForm.get('npwtOptions').value.indexOf('VAC (Renasys Touch)') !== -1
      "
    >
      <mat-form-field class="single-width-field">
        <input
          matInput
          placeholder="Pressure"
          formControlName="vacPressure"
          type="number"
        />
      </mat-form-field>
      <span>mmHg</span>
      <br />
      <mat-radio-group formControlName="vacMode">
        <mat-label>Mode</mat-label>
        <mat-radio-button value="Intermittent">Intermittent</mat-radio-button>
        <mat-radio-button value="Continuous">Continuous</mat-radio-button>
      </mat-radio-group>
      <br />
      <mat-selection-list
        class="half-width-field"
        formControlName="vacAdjunctDressing"
      >
        <mat-label>Adjunct dressing:</mat-label>
        <mat-list-option value="Gauze">Gauze</mat-list-option>
        <mat-list-option value="Foam">Foam</mat-list-option>
      </mat-selection-list>
    </div>

    <br />

    <mat-form-field class="half-width-field" appearance="fill">
      <mat-label>Secondary dressing:</mat-label>
      <mat-select multiple formControlName="secondaryDressing">
        <mat-option value="Non-required">Non-required</mat-option>
        <mat-option value="Non-adherent wound contact layer"
          >Non-adherent wound contact layer</mat-option
        >
        <mat-option value="Hydrogel">Hydrogel</mat-option>
        <mat-option value="Hydrocolloid">Hydrocolloid</mat-option>
        <mat-option value="Silicone bordered polyurethane foam"
          >Silicone bordered polyurethane foam</mat-option
        >
        <mat-option value="Alginate">Alginate</mat-option>
        <mat-option value="Hydrofiber">Hydrofiber</mat-option>
        <mat-option value="Honey">Honey</mat-option>
        <mat-option value="Silver">Silver</mat-option>
        <mat-option value="PHMB gel">PHMB gel</mat-option>
        <mat-option value="Alginogel">Alginogel</mat-option>
        <mat-option value="Iodine">Iodine</mat-option>
        <mat-option value="Tubular bandage">Tubular bandage</mat-option>
        <mat-option value="Soft bandage">Soft bandage</mat-option>
        <mat-option value="Crepe bandage">Crepe bandage</mat-option>
        <mat-option value="NPWT">NPWT</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      class="half-width-field"
      appearance="fill"
      *ngIf="
        entryForm.get('primaryDressing').value.indexOf('Non-required') === -1
      "
    >
      <mat-label>Recommended dressing change frequency</mat-label>
      <mat-select formControlName="dressingChangeFrequency">
        <mat-option value="Once daily">Once daily</mat-option>
        <mat-option value="Alternate days">Alternate days</mat-option>
        <mat-option value="2 or 3 times per week"
          >2 or 3 times per week</mat-option
        >
        <mat-option value="Once a week">Once a week</mat-option>
        <mat-option value="When dressing becomes soaked"
          >When dressing becomes soaked</mat-option
        >
      </mat-select>
    </mat-form-field>

    <br />

    <mat-form-field class="half-width-field" appearance="fill">
      <mat-label
        >Who will help managing the pressure ulcer after hospital
        discharge?</mat-label
      >
      <mat-select multiple formControlName="managingPressureUlcer">
        <mat-option value="Self-managed">Self-managed</mat-option>
        <mat-option value="Family/friends">Family/friends</mat-option>
        <mat-option value="Contracted carers">Contracted carers</mat-option>
        <mat-option value="Community Nurse">Community Nurse</mat-option>
        <mat-option value="Practice Nurse (GP surgery)"
          >Practice Nurse (GP surgery)</mat-option
        >
        <mat-option value="Other">Other</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      class="half-width-field"
      appearance="fill"
      *ngIf="
        entryForm
          .get('managingPressureUlcer')
          .value.indexOf('Contracted carers') !== -1 ||
        entryForm
          .get('managingPressureUlcer')
          .value.indexOf('Community Nurse') !== -1 ||
        entryForm
          .get('managingPressureUlcer')
          .value.indexOf('Practice Nurse (GP surgery)') !== -1
      "
    >
      <mat-label>Start date for care:</mat-label>
      <input matInput type="date" formControlName="managementStartDate" />
    </mat-form-field>

    <br />

    <div>
      <mat-label>Other recommendations</mat-label>
      <br />
      <mat-selection-list
        class="full-width-field mat-select-list-format"
        formControlName="otherRecommendations"
      >
        <mat-list-option
          value="A copy of the pressure ulcer prevention leaflet is provided to you alongside this care plan. Please read it for more information on pressure ulcer prevention."
          >A copy of the pressure ulcer prevention leaflet is provided to you
          alongside this care plan. Please read it for more information on
          pressure ulcer prevention.</mat-list-option
        >
        <mat-list-option value="Package of care to be initiated"
          >Package of care to be initiated</mat-list-option
        >
        <mat-list-option
          value="Pressure redistributing equipment to be arranged"
          >Pressure redistributing equipment to be arranged</mat-list-option
        >
        <mat-list-option
          value="Offload the heels from the mattress (please use pillow lengthwise, keeping knees slightly flexed and heels floating/Heel up boots)."
          >Offload the heels from the mattress</mat-list-option
        >
        <mat-list-option value="Sitting out plan provided"
          >Sitting out plan provided</mat-list-option
        >
        <mat-list-option
          value="Skin must be kept clean and dry at all times. If increased moisture/incontinence use a barrier product in order to prevent skin breakdown."
          >Skin must be kept clean and dry at all times. If increased
          moisture/incontinence use a barrier product in order to prevent skin
          breakdown.</mat-list-option
        >
        <mat-list-option
          value="If you have a pressure relieving mattress and cushion connected to a pump please check that this is working and no alarms or faulty lights are on – if so contact the supplier immediately on the customer service number for advice."
          >If you have a pressure relieving mattress and cushion connected to a
          pump please check that this is working and no alarms or faulty lights
          are on – if so contact the supplier immediately on the customer
          service number for advice.</mat-list-option
        >
      </mat-selection-list>
    </div>

    <!-- <br>

        <mat-slide-toggle
            class="sensitive-image-field"
            matInput
            formControlName = "sensitiveImage"
            value = "sensitiveImage"
            labelPosition = "after"
            color = 'primary'
            (click)="setSensitiveImage($event.checked)"
          >
          Toggle sensitive image
        </mat-slide-toggle>
        <div *ngIf="isSensitive">
          <p class="sensitive-text">The image will be blurred unless your clinician is actively reviewing it.</p>
        </div> -->

    <br />
    <button
      mat-raised-button
      class="positive-button"
      type="submit"
      [disabled]="!valid()"
    >
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
