export default [
  // maternityForm
  'dueDate',
  'maternityUnit',
  'intendedPlaceOfBirth',
  'teamName',
  'namedMidwife',
  'midwifeOrTeamContactDetails',
  'namedObstetricOrMidwifeConsultant',
  'knownMedicalConditionsOrAllergies',
  'birthSettingPreference',
  'birthPartner',
  'studentsPresent',
  'additionalRequirements',
  'additionalRequirementsFreeText',
  'birthMethodPreferenceDiscussionHasOccurred',
  'vaginalDelivery',
  'awareOfInductionOfLabour',
  'typesOfPainReliefConsidered',
  'otherOptionsDuringLabourConsidered',
  'fetalHeartRateMonitoringPreferences',
  'vaginalExaminationPreferences',
  'awareOfReasonsForIntervention',
  'awareOfReasonsForAssistedBirth',
  'awareOfEpisiotomy',
  'thirdStagePreferences',
  'cordCuttingPreferences',
  'skinToSkinPreferences',
  'thoughtsAroundFeeding',
  'vitaminKPreferences',
  'contraceptionPreferences',
  'notes'
];
