<form [formGroup]="assessmentAndTreatmentForm">
  <div class="form-formatting">
    <mat-card>
      <mat-card-title style="text-align: center"
        >CONSENT FORM FOR ASSESSMENT AND TREATMENT</mat-card-title
      >

      <mat-card-content>
        <p style="text-align: left">
          I
          <mat-form-field class="half-width-field" appearance="outline">
            <mat-label>Please enter your name</mat-label>
            <input
              matInput
              type="text"
              formControlName="parentOrGuardianName"
              placeholder="Parent/Guardian's Name"
            />
          </mat-form-field>
          as the
          <mat-form-field class="half-width-field" appearance="outline">
            <mat-label>Please select Parent/Guardian from the dropdown</mat-label>
            <mat-select formControlName="parentOrGuardian">
              <mat-option value="Parent">Parent</mat-option>
              <mat-option value="Guardian">Guardian</mat-option>
            </mat-select>
          </mat-form-field>

          of my child have read and understood the Paediatric Physiotherapy information leaflet.
        </p>

        <p style="text-align: left">
          I consent to my child being assessed and treated by the Paediatric Physiotherapy Service.
        </p>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Parent/Guardian Signature</p>
          </div>
          <mat-form-field class="half-width-field" appearance="outline">
            <mat-label>Please enter your name here</mat-label>
            <input matInput type="text" formControlName="parentOrGuardianSignature" />
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <button
      mat-raised-button
      class="positive-button"
      type="submit"
      [disabled]="assessmentAndTreatmentForm.invalid || isSubmitting"
      (click)="onSubmit()"
    >
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
