import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  CollectionWithId,
  EntryWithId,
  PatientWithId
} from '@islacare/ic-types';
import firebase from 'firebase/compat/app';
import { map, switchMap, take } from 'rxjs/operators';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, combineLatest, forkJoin } from 'rxjs';
import { AuditService } from '../../../../services/audit/audit.service';
import { CollectionsService } from '../../../../services/collections/collections.service';
import { EntriesService } from '../../../../services/entries/entries.service';
import { TeamsService } from '../../../../services/teams/teams.service';
import { FormService } from '../../../../shared/services/form/form.service';
import { UsersService } from '../../../../shared/services/users/users.service';
import { RdDeleteEntryDialogComponent } from '../../dialogs/rd-delete-entry-dialog/rd-delete-entry-dialog.component';
import { RdEntryOriginalImageComponent } from '../../dialogs/rd-entry-original-image/rd-entry-original-image.component';
import { MoveEntriesData } from '../../dialogs/rd-move-entries-confirmation-dialog/rd-move-entries-confirmation.component';

@Injectable({
  providedIn: 'root'
})
export class RdPatientRecordDialogsService {
  constructor(
    private dialogService: DialogService,
    private teamsService: TeamsService,
    private collectionsService: CollectionsService,
    private userService: UsersService,
    private formsService: FormService,
    private entriesService: EntriesService,
    private audit: AuditService,
    private db: AngularFirestore
  ) {}

  deleteEntry(): DynamicDialogRef {
    return this.dialogService.open(RdDeleteEntryDialogComponent, {
      styleClass: 'delete-entry-dialog md:w-6 w-11',
      footer: 'Loading...',
      header: `Delete confirmation`
    });
  }

  viewOriginalEntryEditedImageDialog(
    patient: PatientWithId,
    collection: CollectionWithId,
    entryId: string
  ): DynamicDialogRef {
    return this.dialogService.open(RdEntryOriginalImageComponent, {
      styleClass: 'view-original-image-dialog md:w-10',
      footer: 'Loading...',
      header: `Viewing original image`,
      data: { patient: patient, collection: collection, entryId: entryId }
    });
  }

  getTargetFolders$(entries: EntryWithId[]): Observable<
    {
      label: string;
      items: any;
    }[]
  > {
    const teamId = entries[0]?.entryTeamId;

    const team$ = this.teamsService.getTeam$(teamId);

    return team$.pipe(
      switchMap(team => {
        const orgTeams$ = this.teamsService.getOrganisationTeams$(
          team.organisationId
        );

        return combineLatest([
          orgTeams$,
          this.collectionsService.getCollections(entries[0].patientId)
        ]).pipe(
          switchMap(([orgTeams, collections]) => {
            const orgTeamIds = orgTeams.map(orgTeam => orgTeam.id);
            const snapshotObservables = orgTeams.map(orgTeam =>
              this.teamsService.getTeamSnapshot(orgTeam.id)
            );

            const filteredCollections = collections.filter(collection =>
              orgTeamIds.includes(collection.teamId)
            );
            filteredCollections.sort((a, b) => a.name.localeCompare(b.name));
            const result = {};

            orgTeams.forEach(orgTeam => {
              result[orgTeam.id] = [];
            });

            filteredCollections.forEach(collection => {
              result[collection.teamId].push(collection);
            });

            const snapshotObservable = forkJoin(snapshotObservables);

            return snapshotObservable.pipe(
              map(snapshotData => {
                const dropdownData = snapshotData.map((snapshot, index) => ({
                  label: snapshot.data()?.name,
                  items: result[orgTeams[index].id].map(collection => ({
                    label: collection.name,
                    value: collection
                  }))
                }));

                return dropdownData.filter(data => data.items.length > 0);
              })
            );
          })
        );
      })
    );
  }

  async moveEntries(data: MoveEntriesData): Promise<void> {
    const user = await this.userService.me();
    const entriesCollection = this.db
      .collection('patients')
      .doc(data.destFolder.patientId)
      .collection('collections')
      .doc(data.destFolder.id)
      .collection('entries');

    const originalEntriesCollection = this.db
      .collection('patients')
      .doc(data.entries[0].patientId)
      .collection('collections')
      .doc(data.entries[0].collectionId)
      .collection('entries');

    data.entries.forEach(async entry => {
      if (entry.entryTeamId !== data.destFolder.teamId) {
        this.copyFormDoc(entry, data.destFolder.teamId);
      }

      const originalEntryCollectionId = entry.collectionId;
      entry.collectionId = data.destFolder.id;
      entry.entryTeamId = data.destFolder.teamId;
      entry.lastUpdated = firebase.firestore.Timestamp.now();
      entry.requiresAttention = true;
      if (!entry.locationHistory) {
        entry.locationHistory = [];
      }
      entry.locationHistory.push({
        originalFolder: data.baseFolder,
        dateMoved: firebase.firestore.Timestamp.now(),
        movedBy: user.email
      });
      const { id, ...newEntry } = entry;
      const newEntryDoc = await entriesCollection.add(newEntry);

      this.entriesService.deleteEntry(
        entry.patientId,
        originalEntryCollectionId,
        entry.id,
        'Duplicate'
      );
      await originalEntriesCollection
        .doc(entry.id)
        .update({
          moved: {
            targetEntryId: newEntryDoc.id,
            targetCollectionId: data.destFolder.id,
            updatedAt: firebase.firestore.Timestamp.now()
          }
        })
        .catch(error => {
          throw error;
        });
      await this.audit.moveEntriesBetweenFolders(
        data.destFolder.patientId,
        entry.id,
        data.destFolder.id,
        originalEntryCollectionId,
        newEntryDoc.id
      );
    });
  }

  async copyFormDoc(entry, teamId) {
    const forms = await this.formsService
      .getFormsOnTeam(teamId)
      .pipe(take(1))
      .toPromise();

    for (const formId of entry.formIds) {
      const isFormPresent = forms.some(form => form.id === formId);

      if (!isFormPresent && formId !== 'default') {
        const baseForm = (
          await this.formsService.getFormSnapshot(entry.entryTeamId, formId)
        ).data();
        const formsCollection = this.db
          .collection('teams')
          .doc(teamId)
          .collection('forms');
        await formsCollection.doc(formId).set(baseForm);
        await formsCollection.doc(formId).update({ isArchived: true });
      }
    }
  }
}
