export default [
  // Standing Frame Form
  'childName',
  'aHNumber',
  'manufacturer',
  'serialNumber',
  'model',

  'haveBenefitsBeenExplainedToCarer',
  'haveBenefitsBeenExplainedComments',
  'assessTheEnviromentIsClearBeforeUsingStandingFrame',
  'assessTheEnviromentIsClearComments',
  'performEquipmentSafetyChecklist',
  'performEquipmentSafetyChecklistComments',
  'checkChildIsWearingAppropriateFootwearOrOrthotics',
  'checkChildIsWearingAppropriateFootwearComments',
  'checkEquipmentBrakesAreOnBeforeHandlingChildOrEquipment',
  'checkEquipmentBrakesAreOnBeforeHandlingComments',
  'ensureChildIsStrappedIntoEquipmentSafely',
  'ensureChildIsStrappedIntoEquipmentComments',
  'checkChildIsComfortableAndWellPositioned',
  'checkChildIsComfortableAndWellPositionedComments',
  'assessHowLongChildShouldUsedEquipment',
  'assessHowLongChildShouldUsedEquipmentComments',
  'monitorChangesInChildWhilstUsingEquipment',
  'monitorChangesInChildComments',
  'prepareForGettingChildOutOfEquipmentInAnEmergency',
  'prepareForGettingChildOutComments',
  'checkForPressureAreasAfterEquipmentUse',
  'checkForPressureAreasComments',
  'storeEquipmentSafelyAndNotToBeAdjustedByAnyone',
  'storeEquipmentSafelyComments',
  'chargingInstructionsExplained',
  'chargingInstructionsComments',
  'hasUserGuideBeenReadAndUnderstood',
  'hasUserGuideBeenUnderstoodComments',
  'notes'
];
