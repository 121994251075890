import { UntypedFormGroup } from '@angular/forms';

/**
 * @description Use this to compare two strings to see if they are the same
 * currently set up to take one formControl value and a string value (see systmOneLinkAccount component)
 */
export function matchingStringValidator(controlName: string, matchingStringValue: string) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    if (control.value !== matchingStringValue) {
      control.setErrors({ confirmMatchingStringValidator: true });
    } else {
      control.setErrors(null);
    }
  };
}
