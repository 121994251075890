import {CommonModule} from '@angular/common';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SidebarModule} from 'primeng/sidebar';
import {SafeUrlPipe} from '../../pipes/safe-url/safe-url.pipe';
import {SharedCommonModule} from '../../shared-common.module';

@Component({
  standalone: true,
  selector: 'ic-release-notes-sidebar',
  templateUrl: './release-notes-sidebar.component.html',
  styleUrls: ['./release-notes-sidebar.component.scss'],
  imports: [CommonModule, SidebarModule, SharedCommonModule, SafeUrlPipe]
})
export class ReleaseNotesSidebarComponent {

  url = 'https://headwayapp.co/isla-care-changes'
  @Input() sidebarIsVisible = false;
  @Output() updateStateOnClose = new EventEmitter<void>;

  setStateToClosed(): void {
    this.updateStateOnClose.emit();
  }
}
