import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import { Timestamp } from '@firebase/firestore-types';
import {
  AuditBase,
  CollectionWithId,
  Created,
  CreatedBy,
  EditActionEnum,
  Fresh,
  PatientWithId,
  ScheduleTemplateDetails,
  ScheduledItem,
  ScheduledItemWithId,
} from '@islacare/ic-types';
import { environment } from 'apps/frontend/portal/src/environments/environment';
import firebase from 'firebase/compat/app';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, firstValueFrom } from 'rxjs';
import { AuditService } from '../../../services/audit/audit.service';
import { CollectionsService } from '../../../services/collections/collections.service';
import { ToastService, ToastType } from '../../../shared/services/toast/toast.service';
import { UsersService } from '../../../shared/services/users/users.service';
import { cleanObject } from '../../../utils/cleanObject';
import { EnhancedScheduleDialogComponent } from '../../dialogs/enhanced-schedule-dialog/enhanced-schedule-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class EnhancedScheduleService {
  constructor(
    private db: AngularFirestore,
    private userService: UsersService,
    private auditService: AuditService,
    private dialogService: DialogService,
    private collectionsService: CollectionsService,
    private toastService: ToastService,
  ) {}

  // on update of schedule run function to create collectionAuth for scheduled item IF no collectionAuthId AND type === SUBMISSION_REQUEST
  // will work almost exactly the same as sendScheduledRequestsToPatients - should probably be run an hour earlier
  // do collection group query on scheduled items, then use parent ref to find collection and use data from here to generate collectionAuths as per sendScheduledRequestsToPatients function
  // check firestore rules for schedule - only READ | WRITE by authd users - look at collectionsAuths / entries rules
  // if scheduled item has collectionAuthId then not editable / deletable

  getScheduledItem$(patientId: string, collectionId: string, scheduledItemId: string): Observable<ScheduledItemWithId> {
    return this.db
      .doc<ScheduledItemWithId>(`patients/${patientId}/collections/${collectionId}/scheduledItems/${scheduledItemId}`)
      .valueChanges({ idField: 'id' });
  }

  getScheduledItems$(patientId: string, collectionId: string): Observable<ScheduledItemWithId[]> {
    return this.db
      .collection<ScheduledItemWithId>(`patients/${patientId}/collections/${collectionId}/scheduledItems`)
      .valueChanges({ idField: 'id' });
  }

  openScheduleDialog(collection: CollectionWithId, patient: PatientWithId): void {
    this.dialogService.open(EnhancedScheduleDialogComponent, {
      header: 'Schedule Builder',
      height: '90%',
      width: '90%',
      data: {
        collection: collection,
        patient: patient,
        updatePhoneNumberText: 'Add / update phone number',
      },
    });
  }

  upsertScheduledItem(
    patientId: string,
    collection: CollectionWithId,
    scheduledItem: ScheduledItemWithId,
    multipleItems: boolean,
  ): Promise<DocumentReference<Fresh<AuditBase>>> {
    if (scheduledItem?.id) {
      return this.updateScheduledItem(patientId, collection.id, scheduledItem, collection.teamId);
    }

    return this.createScheduledItem(patientId, collection, scheduledItem, multipleItems);
  }

  async updateScheduledItemCollection(
    patientId: string,
    collection: CollectionWithId,
    scheduledItem: ScheduledItemWithId,
  ): Promise<DocumentReference<Fresh<AuditBase>>> {
    const user = await this.userService.me();

    return this.updateScheduledItemAfterUserEdit(patientId, collection, scheduledItem, user.id, user.email);
  }

  async deleteScheduledItem(
    patientId: string,
    collection: CollectionWithId,
    scheduledItem: ScheduledItemWithId,
    isClearingSchedule?: boolean,
  ): Promise<DocumentReference<Fresh<AuditBase>>> {
    if (scheduledItem?.created?.by === CreatedBy.AUTOMATION && !scheduledItem?.edited?.by) {
      this.deleteItemCreatedByAutomationEvent(isClearingSchedule, patientId, collection, scheduledItem);
    }
    return await this.db
      .collection(this.getPath(patientId, collection.id))
      .doc(scheduledItem.id)
      .delete()
      .then(() =>
        this.auditService.editScheduleOnCollection(
          patientId,
          collection.id,
          scheduledItem.id,
          collection.teamId,
          EditActionEnum.delete_item,
        ),
      );
  }

  applyScheduleTemplateToCollection(
    teamId: string,
    collectionId: string,
    patientId: string,
    scheduleTemplateDetails: ScheduleTemplateDetails,
  ): Promise<firebase.functions.HttpsCallableResult> {
    const applyScheduleTemplateToCollectionFbFunction = firebase
      .app()
      .functions(environment.region)
      .httpsCallable('applyScheduleTemplateToCollection');

    this.auditService.applyScheduleTemplateOnCollection(
      patientId,
      collectionId,
      scheduleTemplateDetails.scheduleTemplateId,
      teamId,
    );

    return applyScheduleTemplateToCollectionFbFunction({
      teamId,
      collectionId,
      patientId,
      scheduleTemplateDetails,
    });
  }

  private async createScheduledItem(
    patientId: string,
    collection: CollectionWithId,
    scheduledItem: ScheduledItem,
    multipleItems: boolean,
  ): Promise<DocumentReference<Fresh<AuditBase>>> {
    const user = await this.userService.me();

    if (!user) throw new Error('User not signed in');

    return await this.db
      .collection<ScheduledItem>(this.getPath(patientId, collection.id))
      .add({
        ...cleanObject(scheduledItem),
        created: {
          by: CreatedBy.USER,
          at: firebase.firestore.FieldValue.serverTimestamp() as Timestamp,
          organisationId: user?.organisationId || null,
          teamId: collection.teamId,
          userId: user.id,
        },
      })
      .then(doc =>
        this.auditService.editScheduleOnCollection(
          patientId,
          collection.id,
          doc.id,
          collection.teamId,
          multipleItems ? EditActionEnum.add_recurring_items : EditActionEnum.add_single_item,
        ),
      );
  }

  private async updateScheduledItem(
    patientId: string,
    collectionId: string,
    scheduledItem: ScheduledItemWithId,
    teamId: string,
  ): Promise<DocumentReference<Fresh<AuditBase>>> {
    return await this.db
      .collection(this.getPath(patientId, collectionId))
      .doc(scheduledItem.id)
      .update(scheduledItem)
      .then(() =>
        this.auditService.editScheduleOnCollection(
          patientId,
          collectionId,
          scheduledItem.id,
          teamId,
          EditActionEnum.edit_item,
        ),
      );
  }

  async updateScheduledItemAfterUserEdit(
    patientId: string,
    collection: CollectionWithId,
    scheduledItem: ScheduledItemWithId,
    userId: string,
    email: string,
  ): Promise<DocumentReference<Fresh<AuditBase>>> {
    const edited: Created = {
      by: CreatedBy.USER,
      at: firebase.firestore.FieldValue.serverTimestamp() as Timestamp,
      organisationId: scheduledItem?.created?.organisationId || collection?.organisationId || null,
      email: email,
      teamId: scheduledItem?.created?.teamId || collection?.teamId || null,
      creatorId: userId || null,
    };
    return await this.db
      .collection(this.getPath(patientId, collection.id))
      .doc(scheduledItem.id)
      .update({
        edited: edited,
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp() as Timestamp,
      })
      .then(() =>
        this.auditService.editScheduleOnCollection(
          patientId,
          collection.id,
          scheduledItem.id,
          collection.teamId,
          EditActionEnum.edit_item,
        ),
      );
  }

  private getPath(patientId: string, collectionId: string): string {
    return `patients/${patientId}/collections/${collectionId}/scheduledItems`;
  }

  async clearAllScheduleItems(patientId: string, collection: CollectionWithId): Promise<void> {
    const endOfToday = new Date().setHours(23, 59, 59, 999);
    const scheduledItems = await firstValueFrom(this.getScheduledItems$(patientId, collection.id));
    for (const item of scheduledItems) {
      if ((item.scheduledDate as any).seconds * 1000 <= endOfToday) continue;

      await this.deleteScheduledItem(patientId, collection, item, true);
    }
    this.auditService.editScheduleOnCollection(
      patientId,
      collection.id,
      null,
      collection.teamId,
      EditActionEnum.clear_schedule,
    );
  }

  async deleteItemCreatedByAutomationEvent(
    isClearingSchedule: boolean,
    patientId: string,
    collection: CollectionWithId,
    scheduledItem: ScheduledItemWithId,
  ): Promise<void> {
    await this.updateAutomationDisabledOnCollection({
      patientId: patientId,
      collectionId: collection.id,
    });
    if (!isClearingSchedule) {
      await this.updateBulkScheduledItemAfterUserEdit(scheduledItem, patientId, collection);
    }
  }

  async updateAutomationDisabledOnCollection(event: { patientId: string; collectionId: string }): Promise<void> {
    await this.collectionsService.updateCollection(event.patientId, event.collectionId, {
      automationDisabled: true,
    });
  }

  async updateBulkScheduledItemAfterUserEdit(
    scheduledItem: ScheduledItemWithId,
    patientId: string,
    collection: CollectionWithId,
  ): Promise<void> {
    const user = await this.userService.me();

    try {
      await this.updateScheduledItemAfterUserEdit(patientId, collection, scheduledItem, user.id, user.email);
    } catch (err) {
      const message = `Error updating property on ${scheduledItem.id}: \n${(err as Error).message}`;
      this.toastService.open(ToastType.Error, message);
    }
  }
}
