import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { QrCodeDialogComponent } from '../../shared/components/dialogs/qr-code-dialog/qr-code-dialog.component';
import { CollectionAuthsService } from '../collectionsAuths/collectionAuths.service';

export interface QrCodeDialogData {
  collectionId: string;
  collectionAuthId: string;
  collectionAuthUrl: string;
  partialRequestData: PartialRequestData;
  partialPatientData: PartialPatientData;
  createdBy: string;
  createdForOrg: string;
  captureType: string;
  requestId: string;
  isMediaOptional: boolean;
}
interface PartialRequestData {
  formIds: string[];
  reminder: boolean;
  teamId: string;
  keepLinkSevenDays: any;
}
interface PartialPatientData {
  patientId: string;
  patientFirstName: string;
  patientPhone: string;
  patientYearOfBirth: string;
  additionalPhoneNumbers: any;
}
@Injectable({
  providedIn: 'root',
})
export class QrCodeService {
  constructor(
    private dialogService: DialogService,
    private collectionAuthsService: CollectionAuthsService,
  ) {}

  async openQrCodeDialog(dialogData: QrCodeDialogData): Promise<void> {
    this.dialogService.open(QrCodeDialogComponent, {
      header: 'Scan QR code below to submit to this collection',
      width: '80%',
      height: '90%',
      data: {
        link: dialogData.collectionAuthUrl,
      },
    });

    await this.collectionAuthsService.createPatientCollectionAuth(
      dialogData.collectionAuthUrl,
      dialogData.collectionAuthId,
      dialogData.collectionId,
      dialogData.partialRequestData,
      dialogData.partialPatientData,
      dialogData.createdBy,
      dialogData.createdForOrg,
      dialogData.captureType,
      dialogData.requestId,
      dialogData.isMediaOptional,
    );
  }
}
