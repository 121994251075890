import { Component, OnInit } from '@angular/core';
import { MediaCardViewType } from '@ic-monorepo/shared-submissions';
import { CaptureType } from '@islacare/ic-types';
import { FileTypeHelper } from '@utils/helpers';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SubmissionFlowMedia } from '../../../feature-patient-submission/store/submission-flow.store';
import { DialogData } from '../../../shared/components/dialogs/dialog-data';

export interface CaptureDialogData extends DialogData {
  captureType: CaptureType;
  hideNotesForm?: boolean;
}

@Component({
  selector: 'ic-clinician-submission-capture',
  templateUrl: './clinician-submission-capture.component.html',
  providers: [ConfirmationService],
})
export class ClinicianSubmissionCaptureComponent
  extends FileTypeHelper
  implements OnInit
{
  mediaToCapture: SubmissionFlowMedia = null;
  data: CaptureDialogData = null;

  get mediaCardViewType(): typeof MediaCardViewType {
    return MediaCardViewType;
  }

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.data = this.config.data;
  }

  setMediaToCapture(mediaToCapture: SubmissionFlowMedia): void {
    this.mediaToCapture = mediaToCapture;
  }

  removeMedia = (): void => (this.mediaToCapture = null);

  close(): void {
    this.ref.close(this.mediaToCapture);
  }
}
