<form *ngIf="form" [formGroup]="form">
  <div class="flex flex-column gap-2 mb-5">
    <p class="mb-1">
      {{ data.message }}
    </p>
    <div id="input-container">
      <input
        [class.ng-invalid]="templateName.invalid || isNameInUse"
        aria-describedby="input-template-name"
        id="template-name"
        class="w-full"
        type="text"
        pInputText
        [placeholder]="data.placeholder ?? ''"
        formControlName="name"
        data-cy="dialog-input-field"
      />
      <small
        *ngIf="templateName.invalid && templateName.touched"
        id="template-name-help"
        class="p-error block"
      >
        Value is <strong>required</strong>
      </small>
      <small *ngIf="isNameInUse" id="template-name-help" class="p-error block">
        Template name is already in use, please enter another name
      </small>
    </div>
  </div>
  <div class="w-full flex flex-row gap-2 justify-content-end">
    <div *ngIf="data.negativeButton">
      <button
        type="button"
        [label]="data.negativeButton"
        (click)="ref.close()"
        class="p-button-text"
        pButton
        type="button"
      ></button>
    </div>
    <div>
      <button
        [disabled]="templateName.invalid || isNameInUse"
        [label]="data.positiveButton"
        [icon]="data.positiveButtonIcon"
        (click)="closeDialog(templateName.value)"
        data-cy="dialog-confirm-btn"
        type="button"
        pButton
      ></button>
    </div>
  </div>
</form>
