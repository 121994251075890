<h2 mat-dialog-title class="dialog-header">
  Original submission
  <button mat-icon-button mat-dialog-close aria-label="Close" matTooltip="Close">
    <mat-icon>close</mat-icon>
  </button>
</h2>

<mat-dialog-content>
  <div *ngIf="entry">
    <div class="entry-info-container">
      <div class="entry-info">
        <p>
          <strong>Submitted on:</strong>
          {{ entry.createdAt | timestamp | date: 'HH:mm dd MMM yyyy' }}
        </p>
        <p><strong>Taken by:</strong> {{ entry.capturedByEmail }}</p>
      </div>
    </div>
    <div class="image-container">
      <img [src]="entry.urlLarge | authImage" width="100%" alt="submission" />
    </div>
  </div>
  <div *ngIf="loading" class="loader-container">
    <img [src]="loadingImagePath" alt="loader" />
  </div>
</mat-dialog-content>
