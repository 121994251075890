import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * @description Custom validators that check if an email address is valid or not
 * @param email (Optional) The email entered into the field
 * @returns Warnning the email is invalid
 */
export function emailValidator(
  email?: string,
  checkAcceptableFormat = false,
  whitelistedEmailDomains = []
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let value: string = control.value;
    if (email) {
      value = email;
    }

    if (!value) return null;

    let isAcceptableEmailDomain = false;

    if (checkAcceptableFormat) {
      isAcceptableEmailDomain = whitelistedEmailDomains?.some(domain =>
        value.toLowerCase().endsWith(domain)
      );
    }

    // add warning if email format is invalid
    if (!hasValidFormat(value)) return { invalidFormat: true };
    if (checkAcceptableFormat && !isAcceptableEmailDomain) {
      return { acceptableFormatError: true };
    } else {
      return null;
    }
  };
}

export function hasValidFormat(email: string) {
  const regex = new RegExp(/^\S+@\S+\.\S+$/);
  return regex.test(email);
}
