import { Injectable } from '@angular/core';
import { LoggingService } from '@ic-monorepo/services';
import { environment } from 'apps/frontend/portal/src/environments/environment';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root',
})
export class Hl7Service {
  constructor(private log: LoggingService) {}

  async createPatientWithA19(
    localId: string | undefined,
    nhs: string | undefined,
    organisationId: string,
    teamId: string
  ) {
    const createPatientWithA19FirebaseFunction = firebase
      .app()
      .functions(environment.region)
      .httpsCallable('createPatientWithA19');
    try {
      const result = await createPatientWithA19FirebaseFunction({
        localId,
        organisationId,
        teamId,
        nhs,
      });
      return result;
    } catch (err) {
      console.error('error', err);
      return 'error';
    }
  }

  async sendPdfToEpr(
    nhs,
    mrn,
    patientId,
    collectionId,
    organisationId,
    lastName,
    firstName,
    visitNumber,
    dateOfBirth,
    entryIdArray,
    pdfFormData,
    withCommentsFlag
  ) {
    const sendPdfToEprFirebaseFunction = firebase
      .app()
      .functions(environment.region)
      .httpsCallable('sendPdfToEpr');
    try {
      const result = await sendPdfToEprFirebaseFunction({
        nhs: nhs,
        mrn: mrn,
        patientId: patientId,
        collectionId: collectionId,
        organisationId: organisationId,
        lastName: lastName,
        firstName: firstName,
        visitNumber: visitNumber,
        dateOfBirth: dateOfBirth,
        entyIdArry: entryIdArray,
        pdfFormData: pdfFormData,
        withCommentsFlag: withCommentsFlag,
      });
      return result;
    } catch (err) {
      this.log.consoleError('error', err);
    }
  }
}
