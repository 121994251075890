import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormService, LoggingService } from '@ic-monorepo/services';
import { SystemCheckService } from '@ic-monorepo/shared-common';
import {
  CollectionWithId,
  EntryWithId,
  PatientWithId,
  WithId,
} from '@islacare/ic-types';
import { cloneDeep } from 'lodash-es';
import { MenuItem } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { take } from 'rxjs/operators';
import {
  EntriesService,
  EntryWithIdUrl,
  EntryWithUrlLarge,
} from '../../../services/entries/entries.service';
import { TeamsService } from '../../../services/teams/teams.service';
import { EntryWithIdCollection } from '../../../store/entry-comparison/entry-comparison.store';
import { PatientRecordService } from '../../services/patient-record/patient-record.service';

@Component({
  selector: 'ic-collection-entry-review',
  templateUrl: './collection-entry-review.component.html',
  styleUrls: ['./collection-entry-review.component.scss'],
})
export class CollectionEntryReviewComponent implements OnInit {
  collection: CollectionWithId;
  entries: (EntryWithUrlLarge & WithId & EntryWithIdCollection)[];
  patient: PatientWithId;
  measurements: boolean[];
  isFileDownloading: boolean;
  isPDFDownloading: boolean;
  titles = {};
  hasSections = {};
  isEntryLoading = true;
  isEveryEntryFormOnly: boolean;
  allEntries: EntryWithIdUrl[];
  currentIndex: number;
  formattedCollectionNames: string;
  multiCollectionFlag: boolean;
  multiEntries: boolean;

  isViewAllReviewers = false;
  pdfMenuItems: MenuItem[];
  isMobile = this.systemCheck.isMobile;

  constructor(
    private entriesService: EntriesService,
    private formService: FormService,
    private router: Router,
    private teamsService: TeamsService,
    private patientRecordService: PatientRecordService,
    private loggingService: LoggingService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private systemCheck: SystemCheckService
  ) {}

  async ngOnInit(): Promise<void> {
    const {
      patient,
      collection,
      entries,
      allEntries = [],
      currentIndex = -1,
    } = this.config.data;
    this.patient = cloneDeep(patient);
    this.collection = cloneDeep(collection);
    this.entries = cloneDeep(
      await Promise.all(
        entries.map((entry) =>
          this.entriesService.includeUrlLarge(entry).pipe(take(1)).toPromise()
        )
      )
    );

    this.multiCollectionFlag =
      this.patientRecordService.checkForMultiCollection(this.entries);
    this.formattedCollectionNames = this.formatCollectionNames(this.entries);
    this.allEntries = allEntries || [];
    this.currentIndex = currentIndex;

    this.setIsEveryEntryFormOnly();

    await this.getFormResponsesForEntries();
    this.isEntryLoading = false;

    this.teamsService.myEnableWoundMeasurements$.subscribe(
      (measurements: boolean[]) => {
        this.measurements = measurements;
      }
    );

    this.setPdfMenuItems();
  }

  private formatCollectionNames(entries): string {
    const collectionNames = entries.map((entry) => entry.collectionName);
    const collectionNamesSet = new Set(collectionNames);
    return [...collectionNamesSet].join(', ');
  }

  private getMultiEntriesFlag(entries: number): boolean {
    return entries > 1;
  }

  async getFormResponsesForEntries(): Promise<void> {
    for (const entry of this.entries) {
      const teamId = entry.entryTeamId;
      entry.allFormResponses = [];

      this.multiEntries = this.getMultiEntriesFlag(this.entries.length);

      for (const formId of entry?.formIds) {
        const formResponse = entry?.formResponse[formId];
        const { formResponseKeys, formKeysMap, isNewFormResponse } =
          await this.formService.getFormkeys(formId, teamId);

        const bodyMapUrl = await this.formService.returnBodyMapUrl(
          formResponseKeys,
          formResponse
        );

        const imageMapObject = await this.formService.getImageMapObject(
          formResponse
        );

        this.hasSections[formId] = await this.formService.getAutomaticForm(
          formId,
          teamId,
          formKeysMap,
          formResponseKeys
        );

        this.formService.getScore(
          formId,
          formResponse,
          formResponseKeys,
          formKeysMap
        );

        const allKeysWithNoResponse = this.formService.getKeysWithNoResponse(
          formResponseKeys,
          formResponse,
          imageMapObject
        );

        entry.allFormResponses.push({
          showForm: allKeysWithNoResponse.length !== formResponseKeys.length,
          formId: formId,
          formResponse: formResponse,
          teamId: teamId,
          formResponseKeys: formResponseKeys,
          formKeysMap: formKeysMap,
          bodyMapUrl: bodyMapUrl,
          imageMapObject: imageMapObject,
          isNewFormResponse,
        });

        formKeysMap?.forEach((value, key) => {
          if (this.hasSections) key = key.split('---')[0];
          this.titles[key] = value;
        });
      }

      entry.entryContainsText = this.setEntryContainsText(entry);
    }
  }

  navigateToEntryEdit(entry: EntryWithId, formId: string): void {
    try {
      this.router.navigate(
        [
          '/patients',
          entry.patientId,
          'collections',
          entry.collectionId,
          'entries',
          entry.id,
          'edit',
        ],
        {
          queryParams: { formId: formId },
        }
      );
    } catch (error) {
      this.loggingService.consoleError('error: ', error);
    }
  }

  editImage(entry: EntryWithId): void {
    this.router.navigate([
      'patients',
      entry.patientId,
      'collections',
      entry.collectionId,
      'entries',
      entry.id,
      'edit-image',
    ]);
  }

  showMeasureImageButton(): boolean {
    return (
      this.measurements && this.measurements.some((element) => element === true)
    );
  }

  measureImage(entry: EntryWithId): void {
    this.router.navigate([
      'patients',
      entry.patientId,
      'collections',
      entry.collectionId,
      'entries',
      entry.id,
      'measure-image',
    ]);
  }

  async downloadFile(entry: EntryWithId): Promise<void> {
    this.isFileDownloading = true;
    await this.patientRecordService.downloadFileFromEntry(
      entry,
      this.patient,
      this.collection
    );
    this.isFileDownloading = false;
  }

  generatePdf(withComments: boolean): void {
    this.patientRecordService.openPdfGenerateDialog(
      this.collection,
      this.entries,
      this.patient,
      withComments,
      this.titles,
      false,
      this.hasSections
    );
  }

  setPdfMenuItems(): void {
    this.pdfMenuItems = [
      {
        label: 'With comments & communication',
        command: () => {
          this.generatePdf(true);
        },
      },
      {
        label: 'Without comments & communication',
        command: () => {
          this.generatePdf(false);
        },
      },
    ];
  }

  private isCurrentIndexNumber(index): boolean {
    return typeof index === 'number';
  }
  async gotoEntry(isPrevious: boolean): Promise<void> {
    this.isEntryLoading = true;

    const currentIndexValid = this.isCurrentIndexNumber(this.currentIndex);
    if (!!this.allEntries && currentIndexValid) {
      if (isPrevious) {
        this.currentIndex -= 1;
      } else {
        this.currentIndex += 1;
      }

      const newEntry: EntryWithIdUrl = this.allEntries[this.currentIndex];

      const entryWithUrl: EntryWithUrlLarge = await this.entriesService
        .includeUrlLarge(newEntry)
        .pipe(take(1))
        .toPromise();

      this.entries = cloneDeep([entryWithUrl]);

      this.setIsEveryEntryFormOnly();

      await this.getFormResponsesForEntries();

      this.isEntryLoading = false;
    } else {
      // handle error
      this.isEntryLoading = false;
      this.backToPatientRecord();
    }
  }

  scrollToResponses(): void {
    document.getElementById('form-response-container').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  backToPatientRecord(): void {
    this.ref.close();
  }

  toggleReviewersView(): void {
    this.isViewAllReviewers = !this.isViewAllReviewers;
  }

  private setIsEveryEntryFormOnly(): void {
    this.isEveryEntryFormOnly = this.entries.every(
      (entry: EntryWithUrlLarge & WithId) => entry.formOnly
    );
  }

  private setEntryContainsText(entry: EntryWithId): string {
    let notesIsEmpty = false;

    if (entry.formResponse?.default?.notes === '') {
      notesIsEmpty = true;
    }

    if (!entry.formNames) return;
    if (entry.formNames.length === 1 && notesIsEmpty) return;

    const textForEntry = entry.formNames
      .map((name: string) => {
        if (name === 'Default notes form' && notesIsEmpty) return;
        return name === 'Default notes form' ? 'notes' : name;
      })
      .toString()
      .split(',')
      .join(', ')
      .replace(/^,/, '')
      .replace(/,\s*$/, '')
      .replace(/,(?=[^,]*$)/, ' and');

    return textForEntry;
  }

  openOriginalImageDialog(entry: EntryWithUrlLarge & WithId) {
    this.patientRecordService.openOriginalImageDialog(
      this.patient,
      this.collection,
      entry.id
    );
  }
}
