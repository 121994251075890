<form [formGroup]="painMedicationForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <div>
      <h3>Pain medication</h3>
    </div>

    <!-- QUESTION ONE -->
    <div class="question-container">
      <div class="half-width-field hori-verti-center">
        <p>Please list all the drugs that you are currently taking for your pain:</p>
      </div>
      <div formArrayName="drugsCurrentlyTakingForPain">
        <div
          *ngFor="
            let drugCurrentlyTakingForPain of drugsCurrentlyTakingForPainForm.controls;
            let i = index
          "
          [formGroupName]="i"
        >
          <mat-form-field class="single-width-field">
            <mat-label> Drug name </mat-label>
            <input matInput type="text" formControlName="drugName" />
          </mat-form-field>
          <mat-form-field class="single-width-field">
            <mat-label> Dose </mat-label>
            <input matInput type="text" formControlName="drugDose" />
          </mat-form-field>
          <mat-form-field class="single-width-field">
            <mat-label> Times per day </mat-label>
            <input matInput type="number" formControlName="drugDailyIntake" />
          </mat-form-field>
          <button mat-button (click)="deleteDrugsCurrentlyTakingForPain(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
      <div class="hori-verti-center">
        <button
          class="positive-button"
          mat-button
          type="button"
          (click)="addDrugsCurrentlyTakingForPain()"
        >
          Add A Drug
        </button>
      </div>
    </div>

    <!-- Question two -->
    <div class="question-container">
      <div class="half-width-field hori-verti-center">
        <p>Please list all the drugs that you are currently taking for any other conditions:</p>
      </div>
      <div formArrayName="drugsCurrentlyTakingForOtherConditions">
        <div
          *ngFor="
            let drugCurrentlyTakingForOtherConditions of drugsCurrentlyTakingForOtherConditionsForm.controls;
            let i = index
          "
          [formGroupName]="i"
        >
          <mat-form-field class="single-width-field">
            <mat-label> Drug name </mat-label>
            <input matInput type="text" formControlName="drugName" />
          </mat-form-field>
          <mat-form-field class="single-width-field">
            <mat-label> Dose </mat-label>
            <input matInput type="text" formControlName="drugDose" />
          </mat-form-field>
          <mat-form-field class="single-width-field">
            <mat-label> How many times a day do you take the drug </mat-label>
            <input matInput type="number" formControlName="drugDailyIntake" />
          </mat-form-field>
          <button mat-button (click)="deleteDrugsCurrentlyTakingForOtherConditions(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
      <div class="hori-verti-center">
        <button
          class="positive-button"
          mat-button
          type="button"
          (click)="addDrugsCurrentlyTakingForOtherConditions()"
        >
          Add A Drug
        </button>
      </div>
    </div>

    <!-- Question three -->
    <div class="question-container">
      <div class="half-width-field hori-verti-center">
        <p>
          Please list all the drugs that you have previously taken for your pain, but which you no
          longer take:
        </p>
      </div>
      <div formArrayName="drugsPreviouslyTakenForPain">
        <div
          *ngFor="
            let drugPreviouslyTakenForPain of drugsPreviouslyTakenForPainForm.controls;
            let i = index
          "
          [formGroupName]="i"
        >
          <mat-form-field class="single-width-field">
            <mat-label> Drug name </mat-label>
            <input matInput type="text" formControlName="drugName" />
          </mat-form-field>
          <mat-form-field class="single-width-field">
            <mat-label> Dose </mat-label>
            <input matInput type="text" formControlName="drugDose" />
          </mat-form-field>
          <mat-form-field class="single-width-field">
            <mat-label> How many times a day do you take the drug </mat-label>
            <input matInput type="number" formControlName="drugDailyIntake" />
          </mat-form-field>
          <button mat-button (click)="deleteDrugsPreviouslyTakenForPain(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
      <div class="hori-verti-center">
        <button
          class="positive-button"
          mat-button
          type="button"
          (click)="addDrugsPreviouslyTakenForPain()"
        >
          Add A Drug
        </button>
      </div>
    </div>

    <div>
      <button
        mat-raised-button
        class="positive-button"
        type="submit"
        [disabled]="isSubmitting"
        click="onSubmit()"
      >
        {{ isSubmitting ? 'Submitting...' : 'Submit' }}
      </button>
    </div>
  </div>
</form>
