<video controls playsinline #video class="video"></video>

<div *ngIf="isVideoing" class="recording-circle"></div>

<h1 class="headerImg" *ngIf="entry">Capture short video</h1>

<div class="video-icons" *ngIf="!isVideoed">
  <button
    mat-icon-button
    (click)="startRecording()"
    class="video-icons_button"
    *ngIf="!isVideoing"
    icEventTracker="sf-takes-video"
  >
    <mat-icon class="video-icons_play-icon">play_circle_filled</mat-icon>
  </button>
  <button
    mat-icon-button
    (click)="stopRecording()"
    class="video-icons_button"
    *ngIf="isVideoing"
    icEventTracker="sf-stops-video"
  >
    <mat-icon class="video-icons_pause-icon">pause_circle_filled</mat-icon>
  </button>
</div>

<div class="form-container" *ngIf="isVideoed">
  <div class="retakeConfirmBtn" *ngIf="!confirmed">
    <button mat-raised-button class="positive-button" (click)="triggerConfirm()" icEventTracker="sf-uploads-video">
      Confirm
    </button>
    <br />
    <button mat-raised-button class="mb-2" (click)="restartRecording()" icEventTracker="sf-retakes-video">
      Retake Video
    </button>
  </div>
</div>
