import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormService } from '../../../services/form/form.service';
import { DialogData } from '../dialog-data';

export interface IFormDialog extends DialogData {
  formId: string;
  teamId: string;
  formData: FormData;
}

@UntilDestroy()
@Component({
  selector: 'ic-form-dialog',
  templateUrl: './form-dialog.component.html',
})
export class FormDialogComponent implements OnInit {
  data: IFormDialog = null;

  constructor(
    private config: DynamicDialogConfig,
    private formService: FormService,
    private ref: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    this.data = this.config.data;
    this.formService.submit$.pipe(untilDestroyed(this)).subscribe({
      next: (formData) => {
        console.log(formData);
        return this.ref.close({ ...this.data, formData: formData });
      },
    });
  }
}
