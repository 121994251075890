import { Component, Input } from '@angular/core';
import { FormField } from '@islacare/ic-types';

@Component({
  selector: 'ic-automatic-title',
  templateUrl: './automatic-title.component.html',
  styleUrls: ['./automatic-title.component.scss'],
})
export class AutomaticTitleComponent {
  @Input() field: FormField;

  fontWeight() {
    if (this.field?.boldTitle === true) {
      return 'bold';
    }
  }
  textAlign() {
    return this.field?.alignment || 'center';
  }
}
