<form [formGroup]="entryForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <h3>Non-attendance Policy for Phototherapy</h3>
    <mat-card class="mat-card-content">
      <p>
        This policy sets out the standard procedure the Trust Staff are expected
        to follow to ensure an appropriate response when patients fail to attend
        for appointments. This is a key component to ensuring safe and effective
        care.
      </p>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>Definitions</h4>
      <p>
        Non-attendance (DNA) occurs when a patient fails to attend without
        negotiating this with the service providers (Phototherapists and
        Nurses).
      </p>
      <p>
        The term attendance refers to any occasion in which it is planned for a
        patient to attend any of the following:
      </p>
      <ul>
        <li>An appointment with an individual practitioner</li>
        <li>An appointment for phototherapy</li>
        <li>An appointment for day services</li>
      </ul>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>Standard Procedures</h4>
      <p>
        The Trust recognizes that ‘non-attendance’ may occur in a range of
        different circumstances and that the associated risk in individual
        instances will vary. Consequently response to non-attendance needs to be
        based on robust risk assessment and management.
      </p>
      <p>
        The staff is expected to follow the procedural guidance set out below
        which covers:
      </p>
      <ul>
        <li>
          Non-attendance at first appointments following referral/re-referral
        </li>
        <li>Non-attendance subsequent to assessment</li>
        <li>Non-attendance subsequent to commencement of treatment</li>
      </ul>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>Non-attendance subsequent to assessment/treatment</h4>
      <p>
        If the patient contacts the department and cancels an appointment or
        does not attend without notice, this is recorded as a cancellation/DNA
        and they will be informed as such. If the patient cancels or does not
        attend without notice a further 2 appointments they will be
        automatically discharged back to their GP.
      </p>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>Not available to commence treatment</h4>
      <p>
        If a patient is given an appointment or commencement of treatment date
        and is unavailable for certain reasons for example work commitments,
        holidays or surgery, the referring doctor will be informed. If there is
        a substantial time to wait before commencing treatment, the referring
        doctor will consider discharge back to the GP.
      </p>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>Lateness</h4>
      <p>
        If the patient arrives 20 minutes late without notice they may not be
        treated and their appointment will be marked as such. 3 late arrivals
        without notice will result in discharge.
      </p>
      <p>I have read and understood the above:</p>
    </mat-card>

    <div>
      <mat-form-field
        appearance="fill"
        required
        class="notes-field half-width-field"
      >
        <mat-label>Patient name</mat-label>
        <input
          matInput
          formControlName="patientName"
          type="notes"
          placeholder="Patient name"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <button
      mat-raised-button
      class="positive-button"
      type="submit"
      [disabled]="!valid()"
    >
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
