<form [formGroup]="dermGenFuForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <div class="full-width-field" style="margin: auto">
      <h3>DERMOTOLOGY GENERAL CLINIC FU FORM</h3>
    </div>

    <div class="question-container">
      <div class="half-width-field verti-center">
        <p>
          How has your skin condition progressed since your last appointment?
        </p>
      </div>
      <mat-form-field appearance="fill" class="notes-field half-width-field">
        <input
          matInput
          formControlName="howHasYourSkinConditionProgressed"
          type="notes"
          placeholder="Please tell us how your skin condition has progressed here"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field verti-center">
        <p>
          What medications (including topical, oral, injections and over the
          counter products) are you currently using for your skin disease?
        </p>
      </div>
      <mat-form-field appearance="fill" class="notes-field half-width-field">
        <input
          matInput
          formControlName="whatMedicationsAreYouUsingForYourSkinDisease"
          type="notes"
          placeholder="Please tell us the medications you are using here"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field verti-center">
        <p>
          Have there been any changes to your medications since your last clinic
          appointment?
        </p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="haveThereBeenAnyChangesToYourMedications">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      *ngIf="
        dermGenFuForm
          .get('haveThereBeenAnyChangesToYourMedications')
          .value.indexOf('Yes') !== -1
      "
    >
      <mat-form-field appearance="fill" class="half-width-field">
        <input
          matInput
          formControlName="haveThereBeenAnyChangesToYourMedicationsFreeText"
          type="notes"
          placeholder="If yes, please tell us the changes to your medications"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <mat-form-field appearance="fill" class="notes-field half-width-field">
      <input
        matInput
        formControlName="notes"
        type="notes"
        placeholder="Additional comments"
        autocomplete="off"
      />
    </mat-form-field>

    <br />

    <div class="button-group">
      <button
        class="positive-button"
        mat-raised-button
        color="accent"
        type="submit"
      >
        {{ isSubmitting ? 'Submitting...' : 'Submit' }}
      </button>
    </div>
  </div>
</form>
