export default [
  'dateOfSurgery',
  'ward',
  'surgicalWound',
  'woundClosureMaterial',
  'dateOfRemoval',
  'removalNeededBy',
  'surgicalWoundAssessment',
  'exudateAmount',
  'surgicalWoundAssessmentOther',
  'microBiologyResults',
  'drainSites',
  'drainSitesRemovalDate',
  'drainSitesRemovalNeededBy',
  'legWoundCabg',
  'dateOfLegWoundSiteRemoval',
  'armWoundCabg',
  'dateOfArmWoundSiteRemoval',
  'groinWoundCabg',
  'dateOfGroinWoundSiteRemoval',
  'additionalResources',
  'responsibleSurgeon',
  'staffProvidingPad',
  'consent',
  'notes'
];
