import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PatientSubmissionTextsService {
  // Landing
  landingOrgRequested = `requested some information from you.`;
  landingInfoProvided = `<p>The information you submit will be sent directly to your healthcare provider, to help them provide you with the best possible care.</p>`;
  landingIslaInfo = `<p><a href="https://www.islacare.co.uk/" target="_blank">Isla</a> is a technology partner of your healthcare provider. They offer a private and secure platform for the capture, storage and sharing of photos, videos, and structured assessment forms between patients and healthcare professionals.</p><p>For information regarding our commitment to the safe storage of personal data, please review our <a href="https://www.islacare.co.uk/privacy/" target="_blank">privacy policy</a>.</p>`;
  landingFwRequestInfo = `<p>This request will be forwarded to the email address you enter below. You will be able to complete the submission on any device where you can access your email.</p>`;

  // Verification
  somethingLooksWrongText = `<p>You have been mistakenly sent this SMS. Please delete it.</p>
  <p>This has triggered an alert and we will investigate the mistake.</p>`;
  yobAttemptedTooManyTimes = `Your request has been blocked due to too many failed verification attempts. Please reach out to Isla via the chat icon at the bottom of the screen to unblock your request.`;

  // Consent
  consentConfirm = `<p>Please confirm that you consent for any data submitted to be accessed by healthcare professionals directly involved in the care of</p>`;
  consentMoreInfo = `<small>For information regarding our commitment to the safe storage of personal data, please review our <a href="https://www.islacare.co.uk/privacy/" target="_blank">privacy policy</a>.</small>`;
  consentNoConsent = `<p>You have stated that you do not consent for data to be collected, please specify:</p>`;
  consentRequestNoContact = `<p>You will not be contacted any further regarding this specific data request.</p><p><strong>Note:</strong> If you wish for any previous data that we may hold on you to be deleted, you can make a request for deletion in line with our <a href="https://www.islacare.co.uk/privacy/" target="_blank">privacy policy</a>.</p>`;
  consentRequestNeverContact = `<p>You will never be contacted this way again, unless agreed with your healthcare professional.</p><p>An alert has been triggered to the Isla system and any further requests for data from you will be blocked.</p><p><strong>Note:</strong> If you wish for any previous data that we may hold on you to be deleted, you can make a request for deletion in line with our <a href="https://www.islacare.co.uk/privacy/" target="_blank">privacy policy</a>.</strong></p>`;
  consentLinkUsed = `We have already successfully received a response to this request, and it will be reviewed by your healthcare provider. If you'd like to submit an additional response, please continue.`;

  // Items to submit
  itemsToSubmitQuestionnaire = `<p class="mt-0">Patient health status questionnaires are used to gather data about your past medical history or current health condition.</p>`;
  itemsToSubmitPhoto = `<p class="mt-0">Photos are used to give your healthcare professional a visual
  record of the progress of your condition.</p><p class="mt-0">You can choose to take a photo or upload an existing photo from your device. This should take <strong>2 minutes</strong> to complete.</p>`;
  itemsToSubmitVideo = `<p class="mt-0">Video clips are used to give your healthcare professional a visual record of the progress of your condition.</p><p class="mt-0">You can choose to either record a video clip, or upload an existing video from your device. This should take <strong>2 minutes</strong> to complete.</p>`;
  itemsToSubmitSound = `<p class="mt-0">Sound recordings are used to help your healthcare professional monitor the progress of your condition.</p><p class="mt-0">This should take <strong>2 minutes</strong> to complete.</p>`;

  itemsToSubmitQuestionnaireOnBehalf = `<p class="mt-0">Patient health status questionnaires are used to gather data about your the past medical history or current health condition of the patient you have consented on behalf of.</p>`;
  itemsToSubmitPhotoOnBehalf = `<p class="mt-0">Photos are used to give healthcare professionals a visual record of the progress of the condition of the patient you have consented on behalf of.</p><p class="mt-0">You can choose to either take a photo, or upload an existing photo from your device. This should take <strong>2 minutes</strong> to complete.</p>`;
  itemsToSubmitVideoOnBehalf = `<p class="mt-0">Video clips are used to give healthcare professionals a visual record of the progress of the condition of the patient you have consented on behalf of.</p><p class="mt-0">You can choose to either record a video clip, or upload an existing video from your device. This should take <strong>2 minutes</strong> to complete.</p>`;
  itemsToSubmitSoundOnBehalf = `<p class="mt-0">Sound recordings are used to give healthcare professionals a visual record of the progress of the condition of the patient you have consented on behalf of.</p><p class="mt-0">This should take <strong>2 minutes</strong> to complete.</p>`;

  itemsToSubmitFile = ``;
  itemsToSubmitAbandon = `If you abandon the process (accidentally or intentionally) part-way, your reply will not be received by your healthcare provider, and you will need to restart the process using the same link from your text message or email.`;

  // Questionnaire
  questionnaireFormInfo = `Your questionnaire will be saved after you click submit, and you will be able to send it to your clinical team at the next step.`;
  questionnaireUploadInfo = `Your questionnaire will be saved after you click submit, and you will be able to add a`;

  // Guidance
  guidanceImagePath = `assets/submission-flow/`;
  photoGuidance = [
    {
      imgUrl: `${this.guidanceImagePath}camera-keep-steady.png`,
      text: 'Hold the camera steady as possible, to avoid blur',
      voiceOverDesc:
        'Illustration of steady camera phone with a green tick above it on the left. To the right side of the illustration is a moving camera phone with a red X above it',
    },
    {
      imgUrl: `${this.guidanceImagePath}camera-good-lighting.png`,
      text: 'Take photo in good lighting (ideally daylight)',
      voiceOverDesc:
        'Illustration of camera phone on bright background with a green tick above it. To the right of the illustration is a camera phone in dark black background and a red X above it',
    },
    {
      imgUrl: `${this.guidanceImagePath}camera-cover-area.png`,
      text: 'Make sure all of the affected area is captured within the frame',
      voiceOverDesc: 'Illustration of a person taking a picture of a lump on their thumb',
    },
    {
      imgUrl: `${this.guidanceImagePath}camera-good-background.png`,
      text: 'Ensure there are no other people in the background of the image',
      voiceOverDesc:
        'Illustration of camera phone taking picture of a hand and a green tick above it. To the right side of the image is a camera phone taking picture of a hand with two people in the background and a red X above it',
    },
    {
      imgUrl: `${this.guidanceImagePath}camera-help.png`,
      text: 'If you are struggling, have someone else take the photo for you, or try taking the photo in a mirror',
      voiceOverDesc: 'Illustration of a person taking a picture of their forearm with a camera phone',
    },
  ];

  videoGuidance = [
    {
      imgUrl: `${this.guidanceImagePath}camera-good-lighting.png`,
      text: 'Record video in good lighting (ideally daylight)',
    },
    {
      imgUrl: `${this.guidanceImagePath}camera-cover-area.png`,
      text: 'Make sure all of the affected area is captured within the frame',
    },
    {
      imgUrl: `${this.guidanceImagePath}camera-good-background.png`,
      text: 'Ensure there are no other people in the background of the video',
    },
    {
      imgUrl: `${this.guidanceImagePath}camera-help.png`,
      text: 'If you are struggling, have someone else take the video for you, or try taking the video in a mirror',
    },
  ];

  soundGuidance = [
    { imgUrl: null, text: 'Try to avoid any background noise where possible.' },
    {
      imgUrl: null,
      text: 'Do not ask health-related questions as part of this recording - your healthcare professional may not be able to respond.',
    },
    {
      imgUrl: null,
      text: 'Follow any other instructions that may have been given to you by your healthcare professional.',
    },
  ];

  // Item capture
  sensitiveImages = `Sensitive images will be blurred unless they are being actively reviewed by the healthcare professionals responsible for your care`;
}
