import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'ic-clinical-form-field-html',
  templateUrl: './clinical-form-field-html.component.html',
  styleUrls: [],
})
export class ClinicalFormFieldHtmlComponent extends FieldType<FieldTypeConfig> implements OnInit {
  ngOnInit(): void {
    if (this.field.key) {
      this.field.resetOnHide = true;
      const text = this.extractTextFromHtml(this.props?.html);
      this.formControl.setValue(text);
    }
  }
  private extractTextFromHtml(html: string | number | (string | number)[]): string {
    const doc = new DOMParser().parseFromString(html.toString(), 'text/html');
    return doc?.body?.textContent || '';
  }
}
