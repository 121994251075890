import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';
import { CaptureType } from '@islacare/ic-types';
import { SoundRecordingService } from 'apps/frontend/portal/src/app/feature-clinician-submission/services/sound-recording/sound-recording.service';
import { SubmissionFlowMedia } from 'apps/frontend/portal/src/app/feature-patient-submission/store/submission-flow.store';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';

export interface SoundObject {
  audioFile: File;
  audioUrl: SafeResourceUrl;
  fileType: string;
}

@Component({
  standalone: true,
  selector: 'ic-sound-capture',
  templateUrl: './sound-capture.component.html',
  imports: [CommonModule, ButtonModule, DividerModule],
})
export class SoundCaptureComponent implements OnInit, OnDestroy {
  @Output() capture = new EventEmitter<SubmissionFlowMedia>();

  hideButtons = false;
  isPlaying = false;
  displayControls = true;
  isAudioRecording = false;
  audioRecordedTime: string;
  audioUrl: SafeUrl;
  audioFile: Blob;
  audioName: string;

  sensitive = false;
  notes = '';

  constructor(
    private soundRecordingService: SoundRecordingService,
    private ref: ChangeDetectorRef,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.startSoundRecordingSubscriptions();
  }

  ngOnDestroy(): void {
    this.abortAudioRecording();
  }

  startSoundRecordingSubscriptions(): void {
    this.soundRecordingService.recordingFailed$().subscribe(() => {
      this.isAudioRecording = false;
      this.ref.detectChanges();
    });

    this.soundRecordingService.getRecordingTime$().subscribe((time) => {
      this.audioRecordedTime = time;
      this.ref.detectChanges();
    });

    this.soundRecordingService.getRecording$().subscribe((data) => {
      this.audioFile = data.blob;
      this.audioName = data.title;
      this.audioUrl = this.sanitizer.bypassSecurityTrustUrl(
        URL.createObjectURL(data.blob)
      );
      this.ref.detectChanges();
    });
  }

  startAudioRecording(): void {
    if (!this.isAudioRecording) {
      this.isAudioRecording = true;
      this.soundRecordingService.startRecording();
    }
  }

  stopAudioRecording(): void {
    if (this.isAudioRecording) {
      this.soundRecordingService.stopRecording();
      this.isAudioRecording = false;
    }
  }

  abortAudioRecording(): void {
    if (this.isAudioRecording) {
      this.isAudioRecording = false;
      this.soundRecordingService.abortRecording();
    }
  }

  continue(): void {
    this.sendSnapshot();
    this.hideButtons = true;
  }

  sendSnapshot(): void {
    const url = URL.createObjectURL(this.audioFile);

    this.capture.emit({
      file: new File([this.audioFile], `${Date.now()}.wav`, {
        type: 'audio/wav',
      }),
      url: url,
      safeUrl: this.sanitizer.bypassSecurityTrustUrl(url),
      captureType: CaptureType.SOUND_RECORDING,
      notes: this.notes,
      sensitive: this.sensitive,
    });
  }
}
