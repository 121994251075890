<form [formGroup]="standingFrameForm">
  <div class="form-formatting">
    <mat-card>
      <mat-card-title>Standing Frame Form</mat-card-title>

      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Name of Child</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="text" formControlName="childName" />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>AH Number</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="text" formControlName="aHNumber" />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Manufacturer</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="text" formControlName="manufacturer" />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Serial Number</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="text" formControlName="serialNumber" />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Model</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="text" formControlName="model" />
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Have the benefits of standing been explained to carer and handout given if
              appropriate?
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="haveBenefitsBeenExplainedToCarer">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea matInput formControlName="haveBenefitsBeenExplainedComments" type="notes">
          </textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Before using standing frame assess that the enviroment is clear and there is adequate
              space for equipment. Also State the locations where the equipment is safe to be used
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="assessTheEnviromentIsClearBeforeUsingStandingFrame">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea matInput formControlName="assessTheEnviromentIsClearComments" type="notes">
          </textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Before using the equipment make sure that it appears in good working order. This may
              include:
            </p>
            <ul>
              <li>Cleaning</li>
              <li>Straps are not frayed</li>
              <li>No parts missing</li>
              <li>Moveable parts secure</li>
              <li>Clips are working</li>
              <li>BRAKES are working</li>
              <li>Wheels turn freely</li>
            </ul>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="performEquipmentSafetyChecklist">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea matInput formControlName="performEquipmentSafetyChecklistComments" type="notes">
          </textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Check that the child is wearing the appropriate footwear or orthotics</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="checkChildIsWearingAppropriateFootwearOrOrthotics">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea
            matInput
            formControlName="checkChildIsWearingAppropriateFootwearComments"
            type="notes"
          >
          </textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Make sure the brakes of the equipment are on before handling the child/equipment</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="checkEquipmentBrakesAreOnBeforeHandlingChildOrEquipment">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea
            matInput
            formControlName="checkEquipmentBrakesAreOnBeforeHandlingComments"
            type="notes"
          >
          </textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Ensure that the child is strapped into the equipment safely, making sure on the
              standing frame the chest straps, foot straps and pelvic strap are fastened.
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="ensureChildIsStrappedIntoEquipmentSafely">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea
            matInput
            formControlName="ensureChildIsStrappedIntoEquipmentComments"
            type="notes"
          >
          </textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Check that the child is comfortable and well positioned.</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="checkChildIsComfortableAndWellPositioned">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea
            matInput
            formControlName="checkChildIsComfortableAndWellPositionedComments"
            type="notes"
          >
          </textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Make sure that you understand how long the child should be in the equipment for.</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="assessHowLongChildShouldUsedEquipment">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea
            matInput
            formControlName="assessHowLongChildShouldUsedEquipmentComments"
            type="notes"
          >
          </textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Monitor for any changes in the child whilst in the equipment</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="monitorChangesInChildWhilstUsingEquipment">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea matInput formControlName="monitorChangesInChildComments" type="notes">
          </textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>In an emergency, prepare how to get them out quickly and safely from equipment</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="prepareForGettingChildOutOfEquipmentInAnEmergency">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea matInput formControlName="prepareForGettingChildOutComments" type="notes">
          </textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Check for any pressure areas after your child has been taken out of the equipment.
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="checkForPressureAreasAfterEquipmentUse">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea matInput formControlName="checkForPressureAreasComments" type="notes">
          </textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Safe storage and not to be adjusted by anyone else unless advised by the therapist
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="storeEquipmentSafelyAndNotToBeAdjustedByAnyone">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea matInput formControlName="storeEquipmentSafelyComments" type="notes">
          </textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Charging instructions if appropriate</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="chargingInstructionsExplained">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea matInput formControlName="chargingInstructionsComments" type="notes">
          </textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Has the user guide been read and understood?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="hasUserGuideBeenReadAndUnderstood">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea matInput formControlName="hasUserGuideBeenUnderstoodComments" type="notes">
          </textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>

    <br />
    <mat-form-field class="full-width-field" appearance="fill">
      <mat-label>Any other comments?</mat-label>
      <textarea matInput formControlName="notes" type="notes"> </textarea>
    </mat-form-field>
    <br />

    <button
      mat-raised-button
      class="positive-button"
      type="submit"
      [disabled]="standingFrameForm.invalid || isSubmitting"
      (click)="onSubmit()"
    >
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
