export default [
  // PHQ
  'PHQ',
  'littleInterestOrPleasure',
  'feelingDownDepressedOrHopeless',
  'troubleSleeping',
  'feelingTiredOrLittleEnergy',
  'poorAppetiteOrOvereating',
  'feelingBadAboutYourself',
  'troubleConcentratingOnThings',
  'movingOrSpeakingSlowerOrFasterThanNormal',
  'negativeThoughtsTowardsYourself',
  'howDifficultHaveTheseProblemsMadeThings',
  'phqTotalScore'
];
