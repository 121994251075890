import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Organisation } from '@islacare/ic-types';
import { environment } from 'apps/frontend/portal/src/environments/environment';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root',
})
export class EmailDomainService {
  constructor(private db: AngularFirestore) {}

  isAcceptableEmailDomain(email: string, organisationId?: string) {
    const isAcceptableEmailDomainFbFunction = firebase
      .app()
      .functions(environment.region)
      .httpsCallable('isAcceptableEmailDomainHttp');

    return isAcceptableEmailDomainFbFunction({
      email: email,
      organisationId: organisationId,
    });
  }

  async getAcceptableEmailDomains(organisationId) {
    const organisationSnap = await this.db
      .doc(`organisations/${organisationId}`)
      .ref.get();
    const organisation = organisationSnap.data() as Organisation;
    return organisation?.featureFlags?.securityAndCompliance
      ?.emailDomainValidation?.whitelistedDomains;
  }
}
