<span [class.hidden]="!formState?.user && field?.props?.showForLoggedInUsersOnly" class="relative">
  <button
    *ngIf="formState?.isEditing"
    #btn
    aria-label="Edit group"
    label="Edit group"
    type="button"
    pButton
    icon="pi pi-ellipsis-v"
    (click)="menu.toggle($event)"
    class="p-button-rounded p-button-text p-button-secondary absolute top-0 left-100 ml-4 -mt-4"
  ></button>
  <p-tieredMenu #menu [model]="items" [popup]="true" appendTo="body"></p-tieredMenu>

  <div
    class="clinical-form-wrapper-field-group bg-white border-1 border-error border-round px-4 pb-0 my-4"
    [class.border-black-alpha-10]="isFormGroupValid(field)"
  >
    <ng-container *ngIf="field?.props">
      <div class="mb-4">
        <h5 *ngIf="field?.props?.label" class="block text-xl font-semibold mt-0 mb-2 pt-4">
          {{ field?.props?.label }}
        </h5>
        <p *ngIf="field?.props?.subtitle" class="block mb-4 mt-0 text-sm">
          {{ field?.props?.subtitle }}
        </p>
      </div>
    </ng-container>

    <ng-container #fieldComponent></ng-container>
  </div>
</span>
