import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  standalone: true,
  selector: 'ic-progress-spinner',
  imports: [CommonModule, ProgressSpinnerModule],
  template: `
    <div class="text-center">
      <i class="pi pi-spinner pi-spin text-brand text-6xl"></i>
    </div>
  `,
})
export class ProgressSpinnerComponent {}
