export default [
  // dn4ScaleForm
  'painFeelLikeBurning',
  'painFeelLikePainfulCold',
  'painFeelLikeElectricShocks',
  'painFeelLikeTingling',
  'painFeelLikePinsAndNeedles',
  'painFeelLikeNumbness',
  'painFeelLikeItching'
];
