export default [
  // painMedicationForm
  'drugsCurrentlyTakingForPain',
  'drugsCurrentlyTakingForOtherConditions',
  'drugsPreviouslyTakenForPain',
  'drugDose',
  'drugDailyIntake',

  // formArray index
  'drugName'
];
