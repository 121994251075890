import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { PatientsService } from '../../../shared/services/patients/patients.service';
import { UsersService } from '../../../shared/services/users/users.service';
import { SystemCheckService } from '@ic-monorepo/shared-common';
import {
  AuditActionRecipient,
  GroupedResponsesithTeamReference,
  MessageMedium,
  TeamResource,
  TeamResourceWithId,
} from '@islacare/ic-types';
import { AuditService } from 'apps/frontend/portal/src/app/services/audit/audit.service';
import { MessageService } from 'primeng/api';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { OrganisationFeatureToggleService } from '../../../services/organisation-feature-toggle/organisation-feature-toggle.service';
import { ResponsesService } from '../../../services/responses.service';
import { TeamResourcesService } from '../../../services/team-resources/team-resources.service';
import { emailValidator } from '../../../utils/custom-validators/email.validator';
import { mobilePhoneNumberValidator } from '../../../utils/custom-validators/mobile-phone-number.validator';
import { AttachResourceFileDialogComponent } from '../attach-resource-file-dialog/attach-resource-file-dialog.component';

interface PatientResponseData {
  title: string;
  message: string;
  additionalPhoneNumbers: Record<string, string>;
  additionalEmailAddresses: Record<string, string>;
  patientPhoneNumber: string;
  emailFunctionalityEnabled: boolean;
  positiveButton: string;
  negativeButton: string;
  patientEmail?: string;
  automatedCreation?: boolean;
  collectionTeamId: string;
  patientId: string;
}

interface Medwise {
  url: string;
  imgUrl: string;
  altAttr: string;
}
export interface TeamResourceWithPublicUrl extends TeamResourceWithId {
  publicUrl?: string;
}
@Component({
  selector: 'ic-patient-response-dialog',
  templateUrl: './patient-response-dialog.component.html',
  styleUrls: ['./patient-response-dialog.component.scss'],
})
export class PatientResponseDialogComponent implements OnInit {
  responseForm = this.fb.group({
    recipient: [],
    response: [
      '',
      [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(999),
      ],
    ],
    contactMethod: [MessageMedium.SMS, [String]],
  });
  MessageMedium = MessageMedium;
  responses$: Observable<GroupedResponsesithTeamReference[]>;
  selectedResponse: string;
  recipientPhoneKeys: string[];
  recipientEmailKeys: string[];
  noAvailablePhoneNumber: boolean;
  resourcesArray$: Observable<TeamResource[]>;
  isTeamAdmin$: Observable<boolean>;
  showAddResources$: Observable<boolean>;
  resourceText: string;
  selectedResource: TeamResourceWithPublicUrl;
  data: PatientResponseData;
  isMedwiseFeatureEnabled$: Observable<boolean>;
  isMobile = this.systemCheckService.isMobile;

  medwise: Medwise = {
    url: 'https://medwise.ai/',
    imgUrl:
      'https://firebasestorage.googleapis.com/v0/b/portal-dev-e4b9a.appspot.com/o/formImages%2Fmedwise.png?alt=media&token=5d9f026a-4c3e-4aa5-842b-ead34fb38b9c',
    altAttr: 'Link to Medwise',
  };

  contactMethods = [
    {
      name: 'SMS',
      method: MessageMedium.SMS,
    },
    {
      name: 'Email',
      method: MessageMedium.EMAIL,
    },
  ];

  multiplePhoneNumbersMsg = `This patient has multiple phone numbers. Please choose a recipient.`;
  multipleEmailsMsg = `This patient has multiple email addresses. Please choose a recipient.`;
  noPhoneNumberMsg = `There is no phone number associated with this patient. Please update it or add an additional number for this patient`;
  noEmailMsg: string;
  invalidEmailMsg: string;
  dialogExpanded = false;

  get uploadButtonText(): string {
    return `${this.selectedResource ? `Replace` : `Attach`} file`;
  }

  get uploadButtonIcon(): string {
    return `pi ${this.selectedResource ? `pi-file-edit` : `pi-upload`}`;
  }

  constructor(
    private organisationFeatureToggleService: OrganisationFeatureToggleService,
    private systemCheckService: SystemCheckService,
    private resourceService: TeamResourcesService,
    private responsesService: ResponsesService,
    private patientsService: PatientsService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private config: DynamicDialogConfig,
    private auditService: AuditService,
    private usersService: UsersService,
    private fb: UntypedFormBuilder,
    public ref: DynamicDialogRef,
  ) {
    this.data = this.config.data;

    this.setupMsgs();
  }

  setupMsgs() {
    this.noEmailMsg = `There is no valid email address associated with this patient. Please add one ${
      this.data.automatedCreation
        ? 'by updating in your Trust system.'
        : 'via the edit patient details screen.'
    }`;

    this.invalidEmailMsg = `The email address for this patient is invalid. Please edit
    ${
      this.data.automatedCreation
        ? 'by updating in your Trust system.'
        : 'via the edit patient details screen.'
    }
    `;
  }

  ngOnInit(): void {
    this.showAddResources$ =
      this.organisationFeatureToggleService.teamResourcesEnabled$;

    this.responses$ = this.responsesService.myResponseTemplates$;

    if (typeof this.data.additionalPhoneNumbers === 'string')
      this.data.additionalPhoneNumbers = {};
    if (typeof this.data?.additionalEmailAddresses === 'string')
      this.data.additionalEmailAddresses = {};

    if (this.data.additionalPhoneNumbers) {
      this.recipientPhoneKeys = Object.keys(this.data.additionalPhoneNumbers);

      if (this.data.patientPhoneNumber) {
        this.recipientPhoneKeys.push('Patient');
        this.data.additionalPhoneNumbers['Patient'] =
          this.data.patientPhoneNumber;
      }
    }

    if (this.data?.additionalEmailAddresses) {
      this.recipientEmailKeys = Object.keys(this.data.additionalEmailAddresses);

      if (this.data.patientEmail) {
        this.recipientEmailKeys.push('Patient');
        this.data.additionalEmailAddresses['Patient'] = this.data.patientEmail;
      }
    }

    this.checkForValidPhone();
    this.updateContactMethod();
    this.resourcesArray$ = this.resourceService.getTeamResources$(
      this.data?.collectionTeamId,
    );
    this.isTeamAdmin$ = this.usersService.me$.pipe(
      map((user) => !!user?.roles?.teamAdmin?.[this.data?.collectionTeamId]),
    );
    this.isMedwiseFeatureEnabled$ = this.isMedwiseOptionAvailable$();
  }

  responseSubmit(resource: TeamResourceWithPublicUrl) {
    const recipient: AuditActionRecipient = {
      contact:
        this.responseForm.value.contactMethod === MessageMedium.SMS
          ? this.responseForm.value.recipient
          : 'Patient',
      contactMethod: this.responseForm.value.contactMethod,
      contactDetail:
        this.responseForm.value.contactMethod === MessageMedium.SMS
          ? this.data.patientPhoneNumber
          : this.data?.additionalEmailAddresses?.[
              this.responseForm.value.recipient
            ] || this.data.patientEmail,
    };
    if (resource) {
      this.auditService.respondToPatientViaText(
        this.data.patientId,
        recipient,
        this.data.collectionTeamId,
        true,
        resource.id,
      );
    } else {
      this.auditService.respondToPatientViaText(
        this.data.patientId,
        recipient,
        this.data.collectionTeamId,
        false,
        '',
      );
    }
    this.ref.close({
      response: this.responseForm.value.response,
      recipient: this.responseForm.value.recipient,
      contactMethod: this.responseForm.value.contactMethod,
    });
  }

  selectResponse(response: string) {
    this.responseForm.controls.response.setValue(response);
    this.responseForm.controls.contactMethod.updateValueAndValidity();
  }

  updateContactMethod() {
    if (
      this.responseForm.controls.contactMethod.value === MessageMedium.EMAIL
    ) {
      this.responseForm.controls.contactMethod.setValidators([
        emailValidator(this.data.patientEmail),
      ]);
      this.responseForm.controls.recipient.clearValidators();
      this.noAvailablePhoneNumber = false;
    } else {
      this.responseForm.controls.contactMethod.clearValidators();
      this.checkForValidPhone();
    }
    this.responseForm.controls.contactMethod.updateValueAndValidity();
    this.responseForm.controls.recipient.updateValueAndValidity();
  }

  checkForValidPhone() {
    if (!this.recipientPhoneKeys || this.recipientPhoneKeys?.length === 0) {
      this.noAvailablePhoneNumber = true;
    } else if (this.recipientPhoneKeys?.length === 1) {
      this.responseForm.controls.recipient.patchValue(
        this.recipientPhoneKeys[0],
      );
      this.responseForm.controls.recipient.setValidators([
        Validators.required,
        mobilePhoneNumberValidator(this.data.additionalPhoneNumbers),
      ]);
    } else if (this.recipientPhoneKeys?.length > 1) {
      this.responseForm.controls.recipient.setValidators([
        Validators.required,
        mobilePhoneNumberValidator(this.data.additionalPhoneNumbers),
      ]);
      this.responseForm.controls.recipient.updateValueAndValidity();
    }
  }

  responseFormInvalid(): boolean {
    return (
      this.responseForm.controls.response.invalid ||
      this.responseForm.controls.recipient.invalid ||
      this.responseForm.controls.contactMethod.invalid ||
      this.noAvailablePhoneNumber
    );
  }

  /**
   * @description Get the name to be displayed in error messages
   * @returns Valid name to display in error messages
   */
  getName(): string {
    const recipient = this.responseForm.get('recipient').value;
    return this.patientsService.getNameToBeDisplayForPhoneNumber(recipient);
  }

  openResourceDialog(resourceArray) {
    this.openResourceDialog$(resourceArray)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: `Successfully attached file: '${this.selectedResource.fileName}'`,
            detail: 'Success',
          });
        },
        error: (error: Error) => {
          this.messageService.add({
            severity: 'error',
            summary: error.message,
            detail: 'Something went wrong',
          });
        },
      });
  }

  openResourceDialog$(resourceArray): Observable<any> {
    const dialogRef = this.dialogService.open(
      AttachResourceFileDialogComponent,
      {
        data: {
          positiveButton: 'Select',
          negativeButton: 'Cancel',
          resourceArray,
        },
        styleClass: 'attach-resource-file-dialog',
        header: 'Choose file to attach',
        width: '95%',
      },
    );

    return dialogRef.onClose.pipe(
      take(1),
      filter((result) => !!result),
      tap((result) => {
        this.selectedResource = result;
        //if a resource file is edited,remove the previous text and add new one
        const updatedText = this.responseForm.controls.response.value.replace(
          this.resourceText,
          '',
        );
        //add new resource description and file link
        this.resourceText = ` ${result?.description}. Please click on the link: ${result?.shortUrl}`;
        this.selectResponse(updatedText + this.resourceText);
      }),
    );
  }

  expandDialog(): void {
    this.config.width = '100%';
    this.config.height = '100%';
    this.dialogExpanded = true;
  }

  minimizeDialog(): void {
    this.config.width = '25rem';
    this.config.height = '100%';
    this.dialogExpanded = false;
  }

  private isMedwiseOptionAvailable$(): Observable<boolean> {
    return combineLatest([
      this.showAddResources$,
      this.organisationFeatureToggleService.isMedwiseEnabled$,
    ]).pipe(map((arr) => !arr.some((bool) => !bool)));
  }
}
