export default [
  'currentLocation',
  'currentConsultant',
  'currentClinician',

  'priority',
  'skinType',
  'typeOfLight',
  'psoralens',
  'site',
  'siteDetails',
  'diagnosis',
  'diagnosisDetails',
  'delayStart',
  'delayStartReason',
  'previousPhototherapy',
  'previousPhototherapyDetails',

  'pregnancy',
  'paediatricPatient',
  'systemicLupusErythematous',
  'gorlinsSyndrome',
  'livedMoreThanOneYearInTropics',
  'previousRadiotherapy',
  'renalOrHepaticImpairment',
  'currentImmunosupression',
  'currentImmunosupressionDetails',
  'personalHistoryOfSkinCA',
  'personalHistoryOfSkinCaDetails',
  'adverseReactionsToPsoralens',
  'adverseReactionsToPsoralensDetails',
  'disabilitiesLanguageInterpreter',
  'disabilitiesLanguageInterpreterDetails',
  'comorbidities',
  'currentMedication',
  'treatmentRisksAndSideEffectsExplainedToPatient',
  'patientVerballyConsentsToRecordOfTreatmentBeingStored',
  'followUpWithConsultantAfterTreatment',
  'whenWasTheFollowUpWithConsultant'
];
