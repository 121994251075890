import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import {
  CamelCaseToTitleCasePipe,
  HashDirective,
} from '@ic-monorepo/shared-common';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';
import firebase from 'firebase/compat/app';
import { cloneDeep } from 'lodash-es';

interface FormArrayData {
  formArrayName: string;
  dataSource: any[];
  columns: string[];
  form: UntypedFormGroup;
  inputType: {
    [columnName: string]: string;
  };
}
@Component({
  standalone: true,
  selector: 'ic-vulval-clinic-form',
  templateUrl: './vulval-clinic-form.component.html',
  styleUrls: ['./vulval-clinic-form.component.scss'],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CamelCaseToTitleCasePipe,
    HashDirective,
  ],
})
export class VulvalClinicFormComponent implements OnInit {
  @Input() data = null;
  @ViewChildren(HashDirective) private hashes: QueryList<HashDirective>;

  vulvalClinicForm: UntypedFormGroup;
  medicalProblemsFormArrayData: FormArrayData;
  surgeriesFormArrayData: FormArrayData;
  otherHospitalisationsFormArrayData: FormArrayData;
  prescribedMedicationsFormArrayData: FormArrayData;
  allergicMedicationsFormArrayData: FormArrayData;
  allergicSubstancesFormArrayData: FormArrayData;
  substancesUsedToHelpSymptomsFormArrayData: FormArrayData;
  sanitaryProtectionFormArrayData: FormArrayData;
  productsUsedFormArrayData: FormArrayData;
  isSubmitting: boolean;
  vulvaImageUrl = null;
  constructor(
    private fb: UntypedFormBuilder,
    private formService: FormService
  ) {}

  async ngOnInit(): Promise<void> {
    this.vulvalClinicForm = this.fb.group({
      reasonForBeingReferredToVulvalClinic: ['', [Validators.required]],
      vulvalSymptoms: ['', [Validators.required]],
      childhoodIllness: [''],
      doYouSmoke: [''],
      listOfDiagnosedMedicalProblems: this.fb.array([]),
      listOfSurgeries: this.fb.array([]),
      otherHospitalisations: this.fb.array([]),
      prescribedMedications: this.fb.array([]),
      anyAllergiesToMedications: [''],
      listOfAllergicMedications: this.fb.array([]),
      anyAllergiesToSubstances: [''],
      listOfAllergicSubstances: this.fb.array([]),
      washingFrequency: [''],
      showerBathWashInSinkOrOther: [''],
      waysOfWashing: [''],
      isVulvalRegionWashedSeparately: [''],
      wayOfWashingVulvalRegionSeparately: [''],
      anyOtherSubstancesUsedToHelpSymptoms: [''],
      listOfSubstancesUsedToHelpSymptoms: this.fb.array([]),
      useOfAnySanitaryProtection: [''],
      listOfProductsUsedForSanitaryProtection: this.fb.array([]),
      sexualIntercourse: [''],
      listOfProductsUsedOrTried: this.fb.array([]),
      anyFamilyMemberDiagnosedWithSkinCancer: [''],
      anyFamilyMemberDiagnosedWithFemaleCancer: [''],
      anyFamilyMemberHaveSimilarVulvalSymptoms: [''],
      anyFamilyMemberDiagnosedWithAutoimmuneDisease: [''],
      detailsAboutFamilyHistory: [''],
      ageAtFirstPeriod: [''],
      dateOrAgeOfLastPeriod: [''],
      stillHavingPeriods: [''],
      noOfDaysAfterWhichPeriodOccurs: [''],
      noOfDaysForWhichPeriodLasts: [''],
      heavyPeriodsOrIrregularityOrSpottingOrPainOrDischarge: [''],
      noOfPregnancies: [''],
      noOfLiveBirths: [''],
      pregnantOrBreastFeeding: [''],
      hadHysterectomyOrCesarean: [''],
      urinaryTractOrBladderOrKidneyInfectionsWithinLastYear: [''],
      bloodInUrine: [''],
      problemsWithControlOfUrination: [''],
      hotFlashesOrSweatingAtNight: [''],
      anySymptomsAtOrAroundPeriodTime: [''],
      dateOrAgeAtLastSmearTest: [''],
      anyAbnormalSmearTestsInPast: [''],
    });

    this.medicalProblemsFormArrayData = {
      formArrayName: 'listOfDiagnosedMedicalProblems',
      dataSource: (
        this.vulvalClinicForm?.controls?.[
          'listOfDiagnosedMedicalProblems'
        ] as UntypedFormArray
      )?.controls,
      columns: [
        'yearOrAgeDiagnosed',
        'medicalProblem',
        'hospitalOrGP',
        'delete',
      ],
      form: this.fb.group({
        yearOrAgeDiagnosed: [''],
        medicalProblem: [''],
        hospitalOrGP: [''],
      }),
      inputType: {
        yearOrAgeDiagnosed: 'number',
        medicalProblem: 'text',
        hospitalOrGP: 'text',
      },
    };

    this.surgeriesFormArrayData = {
      formArrayName: 'listOfSurgeries',
      dataSource: (
        this.vulvalClinicForm?.controls?.['listOfSurgeries'] as UntypedFormArray
      )?.controls,
      columns: ['year', 'reason', 'hospital', 'delete'],
      form: this.fb.group({
        year: [''],
        reason: [''],
        hospital: [''],
      }),
      inputType: {
        year: 'number',
        reason: 'text',
        hospital: 'text',
      },
    };

    this.otherHospitalisationsFormArrayData = {
      formArrayName: 'otherHospitalisations',
      dataSource: (
        this.vulvalClinicForm?.controls?.[
          'otherHospitalisations'
        ] as UntypedFormArray
      )?.controls,
      columns: ['year', 'reason', 'hospital', 'delete'],
      form: this.fb.group({
        year: [''],
        reason: [''],
        hospital: [''],
      }),
      inputType: {
        year: 'number',
        reason: 'text',
        hospital: 'text',
      },
    };

    this.prescribedMedicationsFormArrayData = {
      formArrayName: 'prescribedMedications',
      dataSource: (
        this.vulvalClinicForm?.controls?.[
          'prescribedMedications'
        ] as UntypedFormArray
      )?.controls,
      columns: ['medication', 'strength', 'frequencyTaken', 'delete'],
      form: this.fb.group({
        medication: [''],
        strength: [''],
        frequencyTaken: [''],
      }),
      inputType: {
        medication: 'text',
        strength: 'text',
        frequencyTaken: 'text',
      },
    };

    this.allergicMedicationsFormArrayData = {
      formArrayName: 'listOfAllergicMedications',
      dataSource: (
        this.vulvalClinicForm?.controls?.[
          'listOfAllergicMedications'
        ] as UntypedFormArray
      )?.controls,
      columns: ['medication', 'reaction', 'delete'],
      form: this.fb.group({
        medication: [''],
        reaction: [''],
      }),
      inputType: {
        medication: 'text',
        reaction: 'text',
      },
    };

    this.allergicSubstancesFormArrayData = {
      formArrayName: 'listOfAllergicSubstances',
      dataSource: (
        this.vulvalClinicForm?.controls?.[
          'listOfAllergicSubstances'
        ] as UntypedFormArray
      )?.controls,
      columns: ['substance', 'reaction', 'delete'],
      form: this.fb.group({
        substance: [''],
        reaction: [''],
      }),
      inputType: {
        substance: 'text',
        reaction: 'text',
      },
    };

    this.substancesUsedToHelpSymptomsFormArrayData = {
      formArrayName: 'listOfSubstancesUsedToHelpSymptoms',
      dataSource: (
        this.vulvalClinicForm?.controls?.[
          'listOfSubstancesUsedToHelpSymptoms'
        ] as UntypedFormArray
      )?.controls,
      columns: ['substance', 'symptom', 'delete'],
      form: this.fb.group({
        substance: [''],
        symptom: [''],
      }),
      inputType: {
        substance: 'text',
        symptom: 'text',
      },
    };

    this.sanitaryProtectionFormArrayData = {
      formArrayName: 'listOfProductsUsedForSanitaryProtection',
      dataSource: (
        this.vulvalClinicForm?.controls?.[
          'listOfProductsUsedForSanitaryProtection'
        ] as UntypedFormArray
      )?.controls,
      columns: ['product', 'delete'],
      form: this.fb.group({
        product: [''],
      }),
      inputType: {
        product: 'text',
      },
    };

    this.productsUsedFormArrayData = {
      formArrayName: 'listOfProductsUsedOrTried',
      dataSource: (
        this.vulvalClinicForm?.controls?.[
          'listOfProductsUsedOrTried'
        ] as UntypedFormArray
      )?.controls,
      columns: ['product', 'delete'],
      form: this.fb.group({
        product: [''],
      }),
      inputType: {
        product: 'text',
      },
    };

    this.formService.retreive$.subscribe((formData) => {
      this.vulvalClinicForm.patchValue(formData);
      setTimeout(() => {
        for (const control in this.vulvalClinicForm.controls) {
          if (
            this.vulvalClinicForm.controls[control] instanceof
              UntypedFormArray &&
            formData?.[control]?.length
          ) {
            let i = 0;
            const addButton = document.getElementById(control + 'add-button');

            while (i < formData[control].length && addButton) {
              addButton.click();
              i++;
            }

            this.vulvalClinicForm.controls[control].patchValue(
              formData[control]
            );
          }
        }
      }, 0);
    });

    await this.getVulvaImageUrl();
  }

  addForm(formArrayName, form) {
    const formArray = this.vulvalClinicForm?.controls?.[
      formArrayName
    ] as UntypedFormArray;

    if (formArray) {
      const newForm = cloneDeep(form);
      formArray.push(newForm);
      this.updateTable(formArrayName);
    }
  }

  deleteForm(formArrayName, index) {
    const formArray = this.vulvalClinicForm?.controls?.[
      formArrayName
    ] as UntypedFormArray;

    if (formArray) {
      formArray.removeAt(index);
      this.updateTable(formArrayName);
    }
  }

  onSubmit() {
    if (!this.vulvalClinicForm.valid) return;

    const formResponse = {
      ...cloneDeep(this.vulvalClinicForm.value),
      generalHealthHistory: 'subtitle_HEADER',
      personalHygieneHabits: 'subtitle_HEADER',
      familyHealthHistory: 'subtitle_HEADER',
      womensHealthHistory: 'subtitle_HEADER',
      formId: this.data?.formId,
      index: this.data?.index || 0,
    };
    this.isSubmitting = true;
    this.formService.submit(formResponse);
  }

  updateTable(tableHash) {
    const table = this.hashes.find((tableItem: HashDirective) => {
      return tableItem.icHash === tableHash;
    })?.tableRef;

    if (table) table.renderRows();
  }

  resetFormArray(formArrayName) {
    if (this.vulvalClinicForm?.controls?.[formArrayName])
      (
        this.vulvalClinicForm.controls[formArrayName] as UntypedFormArray
      ).clear();
  }

  resetPeriodFields() {
    this.vulvalClinicForm?.controls?.[
      'noOfDaysAfterWhichPeriodOccurs'
    ].patchValue(null);
    this.vulvalClinicForm?.controls?.['noOfDaysForWhichPeriodLasts'].patchValue(
      null
    );
    this.vulvalClinicForm?.controls?.[
      'heavyPeriodsOrIrregularityOrSpottingOrPainOrDischarge'
    ].patchValue(null);
  }

  openUrlInNewTab(url) {
    window.open(url);
  }

  getVulvaImageUrl = async () => {
    const storageRef = firebase.storage().ref('vulva-image.png');
    this.vulvaImageUrl = await storageRef.getDownloadURL();
  };
}
