import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TeamWithId } from '@islacare/ic-types';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogData } from '../../../shared/components/dialogs/dialog-data';

const { required } = Validators;
interface DialogDataWithTextTeamsField extends DialogData {
  textField: string;
  teamsList: TeamWithId[];
  hideNote?: boolean;
  isConfirmed?: boolean;
}

@Component({
  selector: 'ic-collection-read-only-access',
  templateUrl: './collection-read-only-access.component.html',
  styleUrls: ['./collection-read-only-access.component.scss'],
})
export class CollectionReadOnlyAccessComponent implements OnInit {
  form: UntypedFormGroup;
  checkboxMessage = `By checking the box below, you confirm that you are accessing the information to provide or
  support the individual patient’s direct care. Your access request will be logged.`;
  checkboxValue: boolean;

  get data(): DialogDataWithTextTeamsField {
    return this.config.data;
  }

  constructor(
    private fb: UntypedFormBuilder,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      teamId: ['', [required]],
    });
  }

  submit(): void {
    if (this.form.valid) {
      this.ref.close(this.form.value);
    } else if (!this.form.controls['teamId'].touched) {
      this.form.controls['teamId'].markAsTouched();
    }
  }
}
