<ng-container *ngIf="data && form">
  <p>{{ data.message }}</p>
  <form [formGroup]="form">
    <div class="field">
      <label for="teamList"
        >Please select which of your teams should have access:</label
      >
      <div class="p-fluid">
        <p-dropdown
          [options]="data.teamsList | orderBy : 'name'"
          placeholder="Select a team"
          data-cy="team-list-dropdown"
          formControlName="teamId"
          optionLabel="name"
          optionValue="id"
          name="teamList"
        ></p-dropdown>
      </div>
    </div>
  </form>

  <p-message
    [text]="checkboxMessage"
    severity="info"
    styleClass="block mb-2"
  ></p-message>
  <div class="field-checkbox mb-5">
    <p-checkbox
      [binary]="true"
      [(ngModel)]="checkboxValue"
      inputId="reasonToAccessCheckbox"
      #checked
    ></p-checkbox>
    <label for="reasonToAccessCheckbox" data-cy="reason-to-access-checkbox">
      I confirm I have a reason to access this patient's information pertaining
      to direct care of the patient.
    </label>
  </div>

  <p>
    Please do not request access if you are attempting to view a test patient.
  </p>

  <div class="w-full flex justify-content-end gap-2">
    <div>
      <button
        (click)="ref.close()"
        class="p-button-outlined"
        aria-label="Close"
        label="Close"
        type="button"
        pButton
      ></button>
    </div>
    <div>
      <button
        aria-label="Request Access"
        [disabled]="!checkboxValue || !form.valid"
        (click)="submit()"
        data-cy="submit-collection-read-only-access"
        label="Request Access"
        pButton
      ></button>
    </div>
  </div>
</ng-container>
