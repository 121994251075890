<p-calendar
  [defaultDate]="props.defaultDate"
  [dateFormat]="props.dateFormat"
  [hourFormat]="props.hourFormat"
  [showTime]="props.showTime"
  [showIcon]="props.showIcon"
  [showButtonBar]="props.showButtonBar"
  [showOtherMonths]="props.showOtherMonths"
  [selectOtherMonths]="props.selectOtherMonths"
  [selectionMode]="props.selectionMode || 'single'"
  [numberOfMonths]="props.numberOfMonths"
  [inline]="props.inline"
  [readonlyInput]="props.readonlyInput"
  [touchUI]="props.touchUI"
  [monthNavigator]="props.monthNavigator"
  [yearNavigator]="props.yearNavigator"
  [yearRange]="props.yearRange"
  [placeholder]="props.placeholder"
  [formControl]="formControl"
  [formlyAttributes]="field"
  class="w-full"
>
</p-calendar>
