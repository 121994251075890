import { Directive, HostListener, Input } from '@angular/core';
import { IntercomService } from '../../../services/intercom/intercom.service';

@Directive({
  selector: '[icOpenIntercomArticle]'
})
export class OpenIntercomArticleDirective {
  @Input() icOpenIntercomArticle: number;

  constructor(private intercomService: IntercomService) {}

  @HostListener('click', ['$event'])
  clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    this.intercomService.openArticle(this.icOpenIntercomArticle);
  }
}
