export default [
  // neuropathicPainSymptomInventoryForm
  'painFeelLikeBurningSlider',
  'painFeelLikeSqueezingSlider',
  'painFeelLikePressureSlider',
  'painHasBeenPresent',
  'painFeelLikeElectricShocksSlider',
  'painFeelLikeStabbingSlider',
  'painAttacks',
  'painIncreasedByBrushingSlider',
  'painIncreasedByPressureSlider',
  'painIncreasedByContactWithColdSlider',
  'painFeelsLikePinsAndNeedlesSlider',
  'painFeelsLikeTinglingSlider',
  'burningSuperficialSpontaneousPain',
  'pressingDeepSpontaneousPain',
  'paroxysmalPain',
  'evokedPain',
  'paresthesiaDysesthesiaPain',
  'neuropathicPainSymptomTotalAnswer'
];
