import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'stringToCapitalisedCase'
})
export class StringToCapitalisedCasePipe implements PipeTransform {
  transform(nameString: any) {
    if (!nameString || typeof nameString !== 'string') {
      return nameString;
    }
    // Lowercase everything
    const lowercaseString = nameString.toLowerCase();

    // Uppercase anything at the start of a word boundary
    const re = /(\b[A-z](?!\s))/g;
    return lowercaseString.replace(re, function (x) {
      return x.toUpperCase();
    });
  }
}
