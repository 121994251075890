import { AfterViewChecked, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  standalone: true,
  selector: 'p-panel'
})
export class PanelDirective implements AfterViewChecked {
  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
  ) { }

  ngAfterViewChecked() {
    const panelCollapseToggle = this.el.nativeElement.querySelector('button[role="button"]');
    const panelIconsContainer = this.el.nativeElement.querySelector('p-panel .p-panel-icons-end');

    if (panelCollapseToggle) {
      this.renderer.setAttribute(panelCollapseToggle, 'aria-label', 'Toggle panel information');

      if (panelIconsContainer) {
        this.renderer.setAttribute(panelIconsContainer, 'id', panelCollapseToggle.getAttribute('aria-controls'));
      }
    }
  }

}
