<form [formGroup]="entryForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <mat-radio-group
      appearance="fill"
      class="half-width-field"
      formControlName="ppad"
    >
      <mat-label>PPaD captured?</mat-label>
      <mat-radio-button value="No" (change)="setPpad(false)"
        >No
      </mat-radio-button>
      <mat-radio-button value="Yes" [checked]="true" (change)="setPpad(true)"
        >Yes
      </mat-radio-button>
    </mat-radio-group>
    <br />

    <mat-form-field appearance="fill" *ngIf="!ppad" class="half-width-field">
      <mat-label>Reason for no PPaD</mat-label>
      <mat-select formControlName="noPpadReason">
        <mat-option value="No resources">No resources (camera etc)</mat-option>
        <mat-option value="No time/personnel trained"
          >No time/personnel trained</mat-option
        >
        <mat-option value="Patient or family declined - no time"
          >Patient or family declined - no time</mat-option
        >
        <mat-option value="Patient or family declined - cultural or religious"
          >Patient or family declined - cultural or religious</mat-option
        >
        <mat-option value="Patient or family declined - other"
          >Patient or family declined - other</mat-option
        >
      </mat-select>
    </mat-form-field>
    <br />
    <mat-form-field appearance="fill" class="half-width-field">
      <mat-label>Date of surgery</mat-label>
      <input
        matInput
        type="date"
        formControlName="dateOfSurgery"
        placeholder="UK: "
        class="date-placeholder"
        (change)="daysSinceOp()"
      />
      <!-- <mat-error *ngIf="dateOfSurgery.invalid && dateOfSurgery.touched">
        Please enter a date
      </mat-error> -->
    </mat-form-field>

    <span> Today's date is: {{ todaysDate | date : 'dd/MM/yyyy' }} </span>
    <br />
    <span> Days since surgery date: {{ daysDiff }} </span>
    <br />
    <mat-form-field appearance="fill" class="half-width-field">
      <mat-label>Date of discharge</mat-label>
      <input
        matInput
        type="date"
        placeholder="UK: "
        formControlName="dateOfDischarge"
        class="date-placeholder"
      />
    </mat-form-field>

    <ng-container *ngIf="form$ | async as form">
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Ward of discharge </mat-label>
        <mat-select formControlName="ward">
          <!-- <mat-option value="Rose">Rose</mat-option>
            <mat-option value="PICU">PICU</mat-option> -->
          <mat-option [value]="ward" *ngFor="let ward of form?.ward?.sort()">
            {{ ward }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="ward.touched && ward.invalid">
          Please select a ward
        </mat-error>
      </mat-form-field>
    </ng-container>

    <mat-form-field appearance="fill" class="half-width-field">
      <mat-label>Category </mat-label>
      <mat-select multiple formControlName="category">
        <mat-option value="Median Sternotomy">Median Sternotomy</mat-option>
        <mat-option value="Left Thoracotomy">Left Thoracotomy</mat-option>
        <mat-option value="Right Thoracotomy">Right Thoracotomy</mat-option>
        <mat-option value="Implantable Device (Left side)"
          >Implantable Device (Left side)</mat-option
        >
        <mat-option value="Implantable Device (Right side)"
          >Implantable Device (Right side)</mat-option
        >
      </mat-select>
      <mat-error *ngIf="category.touched && category.invalid">
        Please select a category
      </mat-error>
    </mat-form-field>

    <br />

    <mat-form-field appearance="fill" class="half-width-field">
      <mat-label>Suture materials </mat-label>
      <mat-select multiple formControlName="sutureMaterial">
        <mat-option value="Absorbable sutures">Absorbable sutures</mat-option>
        <mat-option
          value="Non-absorbable sutures (dark, thread-like appearance)"
          >Non-absorbable sutures (dark, thread-like appearance)</mat-option
        >
        <mat-option value="Surgical clips">Surgical clips</mat-option>
        <mat-option value="Glue/tissue adhesive (e.g. Dermabond)"
          >Glue/tissue adhesive (e.g. Dermabond)</mat-option
        >
        <mat-option value="Wound closure tapes (e.g. Steri-Strips)"
          >Wound closure tapes (e.g. Steri-Strips)</mat-option
        >
        <mat-option value="Deep tension sutures"
          >Deep tension sutures</mat-option
        >
      </mat-select>
      <mat-error *ngIf="sutureMaterial.invalid && sutureMaterial.touched">
        Please choose Suture material options
      </mat-error>
    </mat-form-field>

    <mat-form-field
      appearance="fill"
      class="half-width-field"
      *ngIf="
        entryForm
          .get('sutureMaterial')
          .value.indexOf(
            'Non-absorbable sutures (dark, thread-like appearance)'
          ) !== -1 ||
        entryForm.get('sutureMaterial').value.indexOf('Surgical clips') !==
          -1 ||
        entryForm
          .get('sutureMaterial')
          .value.indexOf('Deep tension sutures') !== -1
      "
    >
      <mat-label>Suture materials removal date </mat-label>
      <input
        matInput
        type="date"
        placeholder="UK: "
        formControlName="sutureMaterialRemovalDate"
        class="date-placeholder"
      />
      <mat-error
        *ngIf="
          sutureMaterialRemovalDate.invalid && sutureMaterialRemovalDate.touched
        "
      >
        Please enter the suture material removal date
      </mat-error>
    </mat-form-field>

    <mat-form-field
      appearance="fill"
      class="half-width-field"
      *ngIf="entryForm.get('sutureMaterialRemovalDate').value !== ''"
    >
      <!-- this works -->
      <mat-label>Removal needed by</mat-label>
      <mat-select formControlName="sutureRemovalNeededBy">
        <mat-option value="Our hospital (wound clinic or other)"
          >Our hospital (wound clinic or other)</mat-option
        >
        <mat-option value="GP/Practice nurse">GP/Practice nurse</mat-option>
        <mat-option value="Onward (transfer) hospital/Convalesce"
          >Onward (transfer) hospital/Convalesce</mat-option
        >
        <mat-option value="District nurse">District nurse</mat-option>
        <mat-option value="Other">Other</mat-option>
      </mat-select>
    </mat-form-field>

    <br />

    <mat-form-field appearance="fill" class="half-width-field">
      <mat-label>
        {{
          entryForm
            .get('category')
            .value.indexOf('Implantable Device (Left side)') !== -1 ||
          entryForm
            .get('category')
            .value.indexOf('Implantable Device (Right side)') !== -1
            ? 'Wound assessment *'
            : 'Surgical wound assessment *'
        }}
      </mat-label>
      <mat-select multiple formControlName="surgicalWoundAssessment">
        <mat-option value="Dry, intact & exposed"
          >Dry, intact & exposed</mat-option
        >
        <mat-option value="Exudate present - serous or haemoserous"
          >Exudate (fluid) present - serous or haemoserous</mat-option
        >
        <mat-option value="Exudate present - thick/cloudy/pus"
          >Exudate (fluid) present - thick/cloudy/pus</mat-option
        >
        <mat-option value="Minor gaping along incision"
          >Minor gaping (opening) along the incision</mat-option
        >
        <mat-option value="Some slough evident">Some slough evident</mat-option>
        <mat-option value="Bruising near the incision"
          >Bruising near the incision</mat-option
        >
        <mat-option value="Skin tear/blister noted near incision"
          >Skin tear/blister noted near the incision</mat-option
        >
        <mat-option value="Signs of inflammation"
          >Signs of inflammation (heat/redness/swelling/pain)</mat-option
        >
        <mat-option value="Eschar and/or dark necrotic tissue noted"
          >Eschar (scab) and/or dark necrotic tissue noted</mat-option
        >
        <mat-option value="Dressing applied after assessment"
          >Dressing applied after assessment</mat-option
        >
        <mat-option value="Topical negative pressure dressing applied"
          >Topical negative pressure dressing applied after</mat-option
        >
        <mat-option value="Other">Other</mat-option>
      </mat-select>
      <mat-error
        *ngIf="
          surgicalWoundAssessment.invalid && surgicalWoundAssessment.touched
        "
      >
        Please choose a Surgical wound assessment option
      </mat-error>
    </mat-form-field>

    <mat-form-field
      appearance="fill"
      class="half-width-field"
      *ngIf="entryForm.get('surgicalWoundAssessment').value === 'Other'"
    >
      <input
        matInput
        formControlName="surgicalWoundAssessmentOther"
        type="notes"
        placeholder="Surgical wound assessment notes"
        autocomplete="off"
      />
    </mat-form-field>

    <br />

    <mat-form-field appearance="fill" class="half-width-field">
      <mat-label>Microbiology results for surgical wound</mat-label>
      <mat-select formControlName="microbioResults">
        <mat-option value="None">None</mat-option>
        <mat-option value="Antibiotics prescribed to cover surgical wound"
          >Antibiotics are prescribed to cover the surgical wound swab
          results</mat-option
        >
        <mat-option value="Pending surgical wound swabs"
          >There are pending surgical wound swabs. We will follow up these
          results and will inform your GP to action if necessary</mat-option
        >
      </mat-select>
    </mat-form-field>

    <br />

    <!--
       value="Median Sternotomy">Median Sternotomy</mat-option>
        <mat-option value="Left Thoracotomy">Left Thoracotomy</mat-option>
        <mat-option value="Right Thoracotomy">Right Thoracotomy</mat-option>
        <mat-option value="Implantable Device (Left side)">Implantable Device (Left side)</mat-option>
        <mat-option value="Implantable Device (Right side)">Implantable Device (Right side)</mat-option>
     -->

    <mat-form-field
      appearance="fill"
      class="half-width-field"
      *ngIf="
        entryForm.get('category').value.indexOf('Median Sternotomy') !== -1 ||
        entryForm.get('category').value.indexOf('Left Thoracotomy') !== -1 ||
        entryForm.get('category').value.indexOf('Right Thoracotomy') !== -1
      "
    >
      <mat-label>Drain sites</mat-label>
      <mat-select formControlName="drainSites">
        <mat-option value="Dry and intact">Dry and intact</mat-option>
        <mat-option value="Sutures (dark thread appearance) to be removed"
          >Sutures (dark thread appearance) to be removed</mat-option
        >
        <mat-option
          value="Drain site sutures removed and a small dressing placed over the area. Remove the dressing after the next day or two"
          >Drain site sutures removed and a small dressing placed over the area.
          Remove the dressing after the next day or two</mat-option
        >
      </mat-select>
    </mat-form-field>

    <mat-form-field
      appearance="fill"
      class="half-width-field"
      class="half-width-field"
      *ngIf="
        entryForm.get('drainSites').value ===
        'Sutures (dark thread appearance) to be removed'
      "
    >
      <mat-label>Date of drain sites removal</mat-label>
      <input
        matInput
        type="date"
        placeholder="UK: "
        formControlName="drainSitesRemovalDate"
        class="date-placeholder"
      />
    </mat-form-field>

    <mat-form-field
      appearance="fill"
      class="half-width-field"
      *ngIf="entryForm.get('drainSitesRemovalDate').value !== ''"
    >
      <mat-label>Drain sites removal needed by</mat-label>
      <mat-select formControlName="drainSitesRemovalNeededBy">
        <mat-option value="Our hospital"
          >Our hospital (wound clinic or other)</mat-option
        >
        <mat-option value="GP/Practice nurse">GP/Practice nurse</mat-option>
        <mat-option value="Onward hospital/Convalesce"
          >Onward (transfer) hospital/Convalesce</mat-option
        >
        <mat-option value="District nurse">District nurse</mat-option>
        <mat-option value="Other">Other</mat-option>
      </mat-select>
    </mat-form-field>

    <br />

    <mat-form-field appearance="fill" class="half-width-field">
      <mat-label>Additional resources provided</mat-label>
      <mat-select multiple formControlName="additionalResources">
        <mat-option value="Additional surgical bra provided"
          >Additional surgical bra provided</mat-option
        >
        <mat-option value="Gauze pack(s) provided for under bra band"
          >Gauze pack(s) provided for under bra band</mat-option
        >
        <mat-option value="Extra dressing(s)">Extra dressing(s)</mat-option>
        <mat-option value="Antimicrobial wash">Antimicrobial wash</mat-option>
        <mat-option value="Antimicrobial wipes">Antimicrobial wipes</mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container *ngIf="form$ | async as form">
      <!-- <ng-container *ngIf="form.teamId == entry.formId"> collection.teamId? -->

      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Responsible Surgeon</mat-label>
        <mat-select formControlName="responsibleSurgeon">
          <mat-option
            [value]="responsibleSurgeon"
            *ngFor="let responsibleSurgeon of form?.responsibleSurgeon?.sort()"
          >
            {{ responsibleSurgeon }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <br />
      <mat-form-field class="notes-field half-width-field" appearance="fill">
        <mat-label>Additional Comments</mat-label>
        <textarea
          matInput
          formControlName="additionalComments"
          placeholder="Insert comments here"
        ></textarea>
      </mat-form-field>
      <br />

      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Responsible Cardiology Consultant</mat-label>
        <mat-select formControlName="cardiologyConsultant">
          <mat-option
            [value]="cardiologyConsultant"
            *ngFor="
              let cardiologyConsultant of form?.responsibleConsultant?.sort()
            "
          >
            {{ cardiologyConsultant }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Responsible Clinical Nurse Specialist</mat-label>
        <mat-select formControlName="clinicalNurse">
          <mat-option
            [value]="responsibleClinician"
            *ngFor="
              let responsibleClinician of form?.responsibleClinician?.sort()
            "
          >
            {{ responsibleClinician }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="clinicalNurse.invalid && clinicalNurse.touched">
          Select a clinical nurse
        </mat-error>
      </mat-form-field>
    </ng-container>

    <mat-form-field class="half-width-field">
      <mat-label>Staff providing PPaD </mat-label>
      <input
        matInput
        formControlName="staffProvidingPpad"
        type="notes"
        autocomplete="off"
      />
      <mat-error
        *ngIf="staffProvidingPpad.invalid && staffProvidingPpad.touched"
      >
        Please enter a staff name providing PPaD
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" class="notes-field half-width-field">
      <input
        matInput
        formControlName="notes"
        type="notes"
        placeholder="Comments"
        autocomplete="off"
      />
    </mat-form-field>
    <!-- <br>
    <mat-slide-toggle
      class="sensitive-image-field"
      matInput
      formControlName = "sensitiveImage"
      value = "sensitiveImage"
      labelPosition = "after"
      color = 'primary'
      (click)="setSensitiveImage($event.checked)"
    >
    Toggle sensitive image
    </mat-slide-toggle>
    <div *ngIf="isSensitive">
      <p class="sensitive-text">The image will be blurred unless your clinician is actively reviewing it.</p>
    </div> -->
    <br />
    <button
      mat-raised-button
      class="positive-button"
      type="submit"
      [disabled]="!valid()"
    >
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
      <!-- [disabled]="isSubmitting" -->
    </button>
  </div>
</form>
