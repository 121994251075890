import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { FormService, LoggingService } from '@ic-monorepo/services';
import { AutomaticFormComponent } from 'apps/frontend/portal/src/app/feature-automatic-form/automatic-form.component';
import { IntercomService } from 'apps/frontend/portal/src/app/services/intercom/intercom.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ClinicalFormComponent } from '../components/clinical-form/clinical-form.component';
import { AssessmentAndTreatmentConsentFormComponent } from '../legacy-forms/assessment-and-treatment-consent-form/assessment-and-treatment-consent-form.component';
import { BoadiceaFormComponent } from '../legacy-forms/boadicea-form/boadicea-form.component';
import { BreastFormComponent } from '../legacy-forms/breast-form/breast-form.component';
import { BurnsPatientAssessmentFormComponent } from '../legacy-forms/burns-patient-assessment-form/burns-patient-assessment-form.component';
import { BurnsReferralFormComponent } from '../legacy-forms/burns-referral-form/burns-referral-form.component';
import { BurnsScarFormComponent } from '../legacy-forms/burns-scar-form/burns-scar-form.component';
import { CadiFormComponent } from '../legacy-forms/cadi-form/cadi-form.component';
import { CadiPhqFormComponent } from '../legacy-forms/cadi-phq-form/cadi-phq-form.component';
import { CCHQFormComponent } from '../legacy-forms/cchq-form/cchq-form.component';
import { CovidPatientVisitorScreeningFormComponent } from '../legacy-forms/covid-patient-visitor-screening-form/covid-patient-visitor-screening-form.component';
import { DermGeneralFuFormComponent } from '../legacy-forms/derm-general-fu-form/derm-general-fu-form.component';
import { DermGeneralNewFormComponent } from '../legacy-forms/derm-general-new-form/derm-general-new-form.component';
import { DermMohsFormComponent } from '../legacy-forms/derm-mohs-form/derm-mohs-form.component';
import { DlqiAdditionalFormComponent } from '../legacy-forms/dlqi-additional-form/dlqi-additional-form.component';
import { DlqiFormComponent } from '../legacy-forms/dlqi-form/dlqi-form.component';
import { DlqiHadsFormComponent } from '../legacy-forms/dlqi-hads-form/dlqi-hads-form.component';
import { DopplerFormComponent } from '../legacy-forms/doppler-form/doppler-form.component';
import { Gad7FormComponent } from '../legacy-forms/gad7-form/gad7-form.component';
import { HadsFormComponent } from '../legacy-forms/hads-form/hads-form.component';
import { HariAssessmentFormComponent } from '../legacy-forms/hari-assessment-form/hari-assessment-form.component';
import { IsotretinoinOptInFormComponent } from '../legacy-forms/isotretinoin-opt-in-form/isotretinoin-opt-in-form.component';
import { IsotretinoinOptOutOffContraceptionFormComponent } from '../legacy-forms/isotretinoin-opt-out-off-contraception-form/isotretinoin-opt-out-off-contraception-form.component';
import { IsotretinoinOptOutOnContraceptionFormComponent } from '../legacy-forms/isotretinoin-opt-out-on-contraception-form/isotretinoin-opt-out-on-contraception-form.component';
import { KCCQFormComponent } from '../legacy-forms/kccq-form/kccq-form.component';
import { LothianFollowUpFormComponent } from '../legacy-forms/lothian-follow-up-form/lothian-follow-up-form.component';
import { LothianPatientResponseFormComponent } from '../legacy-forms/lothian-patient-response-form/lothian-patient-response-form.component';
import { MaternityFormComponent } from '../legacy-forms/maternity-form/maternity-form.component';
import { MfqFormComponent } from '../legacy-forms/mfq-form/mfq-form.component';
import { MskReferralFormComponent } from '../legacy-forms/msk-referral-form/msk-referral-form.component';
import { NeurologyFormComponent } from '../legacy-forms/neurology-form/neurology-form.component';
import { NucaPatientQuestionnaireComponent } from '../legacy-forms/nuca-patient-questionnaire/nuca-patient-questionnaire.component';
import { NucaReferralFormComponent } from '../legacy-forms/nuca-referral-form/nuca-referral-form.component';
import { PadAdultCardiacWoundMultipleSitesComponent } from '../legacy-forms/pad-adult-cardiac-wound-multiple-sites/pad-adult-cardiac-wound-multiple-sites.component';
import { PadAdultCardiacWoundComponent } from '../legacy-forms/pad-adult-cardiac-wound/pad-adult-cardiac-wound.component';
import { PadAdultThoracicFormComponent } from '../legacy-forms/pad-adult-thoracic-form/pad-adult-thoracic-form.component';
import { PadPaediatricCardiacWoundGsttComponent } from '../legacy-forms/pad-paediatric-cardiac-wound-gstt/pad-paediatric-cardiac-wound-gstt.component';
import { PadPaediatricCardiacWoundComponent } from '../legacy-forms/pad-paediatric-cardiac-wound/pad-paediatric-cardiac-wound.component';
import { PadPressureUlcerFormComponent } from '../legacy-forms/pad-pressure-ulcer-form/pad-pressure-ulcer-form.component';
import { BpiInterferenceScaleFormComponent } from '../legacy-forms/pain-bpi-interference-form/pain-bpi-interference-form.component';
import { Dn4FormComponent } from '../legacy-forms/pain-dn4-form/pain-dn4-form.component';
import { PainFollowUpPatientFormComponent } from '../legacy-forms/pain-follow-up-patient-form/pain-follow-up-patient-form.component';
import { PainMedicationFormComponent } from '../legacy-forms/pain-medication-form/pain-medication-form.component';
import { NeuropathicSymptomFormComponent } from '../legacy-forms/pain-neuropathic-symptom-form/pain-neuropathic-symptom-form.component';
import { PainNewPatientFormComponent } from '../legacy-forms/pain-new-patient-form/pain-new-patient-form.component';
import { PainSummaryFormComponent } from '../legacy-forms/pain-summary-form/pain-summary-form.component';
import { PatientEvaluationMeasureFormComponent } from '../legacy-forms/patient-evaluation-measure-form/patient-evaluation-measure-form.component';
import { PatientSpecificFunctionalScaleFormComponent } from '../legacy-forms/patient-specific-functional-scale-form/patient-specific-functional-scale-form.component';
import { PhotoFallbackFormComponent } from '../legacy-forms/photo-fallback-form/photo-fallback-form.component';
import { PhototherapyFormComponent } from '../legacy-forms/phototherapy-form/phototherapy-form.component';
import { PhototherapyNonAttendanceFormComponent } from '../legacy-forms/phototherapy-non-attendance-form/phototherapy-non-attendance-form.component';
import { PhototherapyPuvaConsentFormComponent } from '../legacy-forms/phototherapy-puva-consent-form/phototherapy-puva-consent-form.component';
import { PhototherapyUvbConsentFormComponent } from '../legacy-forms/phototherapy-uvb-consent-form/phototherapy-uvb-consent-form.component';
import { PhqFormComponent } from '../legacy-forms/phq-form/phq-form.component';
import { PoemFormComponent } from '../legacy-forms/poem-form/poem-form.component';
import { SexualHealthFeedbackFormComponent } from '../legacy-forms/sexual-health-feedback-form/sexual-health-feedback-form.component';
import { SsiSurveillanceFormComponent } from '../legacy-forms/ssi-surveillance-form/ssi-surveillance-form.component';
import { StandingFrameFormComponent } from '../legacy-forms/standing-frame-form/standing-frame-form.component';
import { SurgicalWoundHealingFormComponent } from '../legacy-forms/surgical-wound-healing-form/surgical-wound-healing-form.component';
import { TrustSsiSurveillanceFormComponent } from '../legacy-forms/trust-ssi-surveillance-form/trust-ssi-surveillance-form.component';
import { Uas7FormComponent } from '../legacy-forms/uas7-form/uas7-form.component';
import { UseOfEquipmentTrainingFormComponent } from '../legacy-forms/use-of-equipment-training-form/use-of-equipment-training-form.component';
import { VideoAndPhotoConsentFormComponent } from '../legacy-forms/video-and-photo-consent-form/video-and-photo-consent-form.component';
import { VideoFallbackFormComponent } from '../legacy-forms/video-fallback-form/video-fallback-form.component';
import { VisualPainFormComponent } from '../legacy-forms/visual-pain-form/visual-pain-form.component';
import { VulvalClinicFormComponent } from '../legacy-forms/vulval-clinic-form/vulval-clinic-form.component';
import { WoundAssessmentFormComponent } from '../legacy-forms/wound-assessment-form/wound-assessment-form.component';

@Component({
  standalone: true,
  selector: 'ic-form-switch',
  templateUrl: './form-switch.component.html',
  styleUrls: ['./form-switch.component.scss'],
})
export class FormSwitchComponent implements OnChanges, OnInit, OnDestroy {
  @Input() formId: string | undefined;
  @Input() index: number | undefined;
  @Input() video: boolean;
  @Input() teamId: string;
  @Input() yearOfBirth: string;
  @Input() formData: FormData;
  @Input() dialogRef: DynamicDialogRef;

  //Get tag child component will be placed
  @ViewChild('target', { read: ViewContainerRef, static: true })
  target: ViewContainerRef;
  componentRef: ComponentRef<any>;

  constructor(
    private compiler: ComponentFactoryResolver,
    private form: FormService,
    private log: LoggingService,
    private intercom: IntercomService
  ) { }

  forms = {
    KNPAEwPTPRKZEhRaMzuz: SsiSurveillanceFormComponent,
    pafS10Z17fR3o5gPckSh: PadPaediatricCardiacWoundComponent,
    nmNcqW9ijF0Sonz8dDMd: PadPaediatricCardiacWoundGsttComponent,
    PyCv9RyRSl7RzvMCuDMc: PadAdultCardiacWoundComponent,
    '5BTxaAEj1PLosmqKSfpK': TrustSsiSurveillanceFormComponent,
    bkMlcL6H6RrXrb5soItN: PadPressureUlcerFormComponent,
    '8XRCKvit25VYBEsvKUy8': NeurologyFormComponent,
    '9R09K0w78ppSoxpJVTzZ': NeurologyFormComponent,
    N1bmUHVQL2XZ6bln4n2x: DlqiHadsFormComponent,
    qpQXh36CkhadsWFYhC67: PadAdultThoracicFormComponent,
    YLj3zOI9MZ4qmxjEP67C: CadiPhqFormComponent,
    MlsweaG5RTeQR71iGM2x: WoundAssessmentFormComponent,
    q3NLdkEIxfJ5KEjsQ1HY: BurnsReferralFormComponent,
    VQky56ouEUrXLDn9uaBl: BurnsPatientAssessmentFormComponent,
    dRaMPSxDmno8XmVbta3r: PainNewPatientFormComponent,
    ctx4B3mqfYsr4X1C9Qsw: PainFollowUpPatientFormComponent,
    r2O9VLCJ8gtHpyi0EbED: DlqiAdditionalFormComponent,
    KJcu1dQX8zDVlA3ifdzv: SexualHealthFeedbackFormComponent,
    llQXeHhYes7JEIv96Vyw: SurgicalWoundHealingFormComponent,
    bJsGkCw1gj78leLjZSy2: MfqFormComponent,
    tNcRl9oopwNAv483Mg8e: PoemFormComponent,
    TBoiRDmAg7066NRqSys4: BurnsScarFormComponent,
    WDkliqcPNPD6E9BWVD2G: DlqiFormComponent,
    vVMe9HsSPaaO6OuM9qc9: HadsFormComponent,
    EeOijT6ZhF6STJPh7D79: CadiFormComponent,
    Kl0n6y4nrbps5Dl4AmBr: PhqFormComponent,
    Bdrc1XSxKR7Xm54kQ3UK: HariAssessmentFormComponent,
    ai8WnXjsqJhkGvI7d8Be: DermMohsFormComponent,
    RtSovJUspuHwcGgW8zCb: DermGeneralFuFormComponent,
    zi8sFi0WHgSm9C0YCcgp: DermGeneralNewFormComponent,
    '6aLUFCHaroyHZtQOp9RZ': PadAdultCardiacWoundMultipleSitesComponent,
    '16OpH6mV0dGJyWqwrB6m': PainMedicationFormComponent,
    '9T7kspSeqvivkXIKAsnJ': PainSummaryFormComponent,
    GS1R408jnK4jlzZxCCjy: Dn4FormComponent,
    HnsZqPuuAFe4nVZiYakU: NeuropathicSymptomFormComponent,
    jwZ7wS16a4S5SaJ0iydf: BpiInterferenceScaleFormComponent,
    nMeulaCjfiMlj7VzWHfX: VisualPainFormComponent,
    BviqBZh2UuTVxQrHxVgM: Gad7FormComponent,
    HDpjGaDgFWwf4999BDHQ: IsotretinoinOptOutOnContraceptionFormComponent,
    LyxaPrsVW0RvrSuobZQJ: IsotretinoinOptOutOffContraceptionFormComponent,
    RI1g6wwCtI4vqTaRl3y8: IsotretinoinOptInFormComponent,
    lbJJWeZefGkjasUQrGlr: PhototherapyFormComponent,
    bzt86zsReVu4Xza7l8TC: NucaPatientQuestionnaireComponent,
    '5pYlWSfYn9mhxtFeKSCO': PhototherapyPuvaConsentFormComponent,
    '27KNqQxwYRgkoZLvLFAt': PhototherapyUvbConsentFormComponent,
    miZz5s9m5k66njURHbIH: PhototherapyNonAttendanceFormComponent,
    '3VY1Ptg6S5fU6Li4PApa': BreastFormComponent,
    WGE82S3G1ecSGbBEB9Pv: MaternityFormComponent,
    s2rTLV66Tczx8TgP4inr: LothianPatientResponseFormComponent,
    '0LK9HUhnyC8SLG58MSbI': LothianFollowUpFormComponent,
    cwXU0L2Mywf5VQO2aDs5: NucaReferralFormComponent,
    '2BqNmGVgPKbeyi72nPHX': DopplerFormComponent,
    MivijS1lNLfeFMB5p9wL: CCHQFormComponent,
    tQQscuT2m2OALyf8lMV4: StandingFrameFormComponent,
    HDW9V1A6VInjKxRTe2hw: UseOfEquipmentTrainingFormComponent,
    HphAQ1p9MMGcqHWESIuy: MskReferralFormComponent,
    vStdwNDp6MbUoi2ZNYeL: Uas7FormComponent,
    c0RXrlhINxoMd6Ozs6iY: KCCQFormComponent,
    G0xEK12U1Gjscd9OeqKc: VideoAndPhotoConsentFormComponent,
    oQKWUEkHrwXqZUmFKq98: CovidPatientVisitorScreeningFormComponent,
    '4hG2UNHignDIw6xdfaDd': BoadiceaFormComponent,
    jLTGkst3CzHolvC5WKh6: PatientEvaluationMeasureFormComponent,
    yrSWg6Mq3LpuzlgxZAkz: PatientSpecificFunctionalScaleFormComponent,
    EufjCcW9JCXw5785CvfH: AssessmentAndTreatmentConsentFormComponent,
    uqtN04OghDsWNOenobCB: VulvalClinicFormComponent,
  };

  ngOnInit() {
    this.intercom.shutdownIntercom();
  }

  async ngOnChanges(changes) {
    if (this.formData) {
      this.form.retreive(this.formData);
    }

    if (changes['formId'] || changes['video']) {
      await this.setupForm();
    }

    if (changes['index'] && this.componentRef) {
      this.componentRef.instance.data.index = this.index;
    }
  }
  ngOnDestroy() {
    this.intercom.initialiseIntercom();
  }

  async setupForm() {
    window.scroll(0, 0);

    this.log.consoleLog(
      `Setting up a form with Form Id ${this.formId} and video set to ${this.video} and teamId set to ${this.teamId} and index set to ${this.index}`
    );
    let componentFactory;
    let form;
    if (this.teamId) {
      form =
        this.formId &&
        (
          await this.form.getAutomaticFormSnapshot(this.teamId, this.formId)
        ).data();
    } else {
      form = this.formId && (await this.form.getFormData(this.formId));
    }

    if (form?.fieldConfig) {
      // This is a formly form
      componentFactory = this.compiler.resolveComponentFactory(
        ClinicalFormComponent
      );
      if (this.target) {
        this.target.clear();
      }
      this.componentRef =
        this.target && this.target.createComponent(componentFactory);

      this.componentRef.instance.clinicalFormResponse = this.formData;
      this.componentRef.instance.clinicalForm = { ...form, id: this.formId };

      if (this.dialogRef) {
        this.componentRef.instance.dialogRef = this.dialogRef;
      }
      return;
    } else if (
      !this.formId ||
      (!Object.keys(this.forms).includes(this.formId) && !form?.isAutomaticForm)
    ) {
      this.formId = 'default';
      if (this.video) {
        componentFactory = this.compiler.resolveComponentFactory(
          VideoFallbackFormComponent
        );
      } else {
        componentFactory = this.compiler.resolveComponentFactory(
          PhotoFallbackFormComponent
        );
      }
    } else if (form?.isAutomaticForm) {
      // This an automatic form
      componentFactory = this.compiler.resolveComponentFactory(
        AutomaticFormComponent
      );
    } else {
      // This is a hardcode html from - in legacy-forms
      componentFactory = this.compiler.resolveComponentFactory(
        this.forms[this.formId]
      );
    }

    if (this.target) {
      this.target.clear();
    }
    this.componentRef =
      this.target && this.target.createComponent(componentFactory);

    this.componentRef.instance.data = {
      teamId: this.teamId,
      formId: this.formId,
      yearOfBirth: this.yearOfBirth,
      index: this.index,
      ...(form?.isAutomaticForm ? { automatedForm: form } : {}),
    };
  }
}
