import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-use-of-equipment-training-form',
  templateUrl: './use-of-equipment-training-form.component.html',
  styleUrls: ['./use-of-equipment-training-form.component.scss'],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class UseOfEquipmentTrainingFormComponent implements OnInit {
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  useOfEquipmentTrainingForm: UntypedFormGroup;
  isSubmitting = false;

  ngOnInit(): void {
    this.useOfEquipmentTrainingForm = this.fb.group({
      typeOfEquipment: ['', [Validators.required]],
      serialNumber: ['', [Validators.required]],

      benefitsOfEquipmentExplained: ['', [Validators.required]],
      benefitsOfEquipmentComments: ['', []],
      checkEnvriomentIsClearAndSuitable: ['', [Validators.required]],
      checkEnvriomentComments: ['', []],
      performEquipmentSafetyChecklist: ['', [Validators.required]],
      performEquipmentSafetyChecklistComments: ['', []],
      checkChildIsWearingAppropriateFootwearOrOrthotics: [
        '',
        [Validators.required],
      ],
      checkChildIsWearingAppropriateFootwearComments: ['', []],
      checkBrakesOnEquipmentBeforeUse: ['', [Validators.required]],
      checkBrakesOnEquipmentComments: ['', []],
      referToManualHandlingOrPosturalCarePlan: ['', [Validators.required]],
      referToManualHandlingComments: ['', []],
      ensureChildIsSafelySecuredAndCheckAllStraps: ['', [Validators.required]],
      ensureChildIsSafelySecuredComments: ['', []],
      checkChildIsComfortableAndAssessHowLongChildShouldUseEquipment: [
        '',
        [Validators.required],
      ],
      checkChildIsComfortableComments: ['', []],
      monitorChildUsingEquipmentAndTakeChildOutIfAppearUnwell: [
        '',
        [Validators.required],
      ],
      monitorChildUsingEquipmentComments: ['', []],
      storeEquipmentSafelyAndNotToBeAdjustedByAnyone: [
        '',
        [Validators.required],
      ],
      storeEquipmentSafelyComments: ['', []],
      readAndUnderstandUserGuideForEquipment: ['', [Validators.required]],
      readAndUnderstandUserGuideComments: ['', []],
      doNotUseEquipmentIfProblemsAndFaultsArePresent: [
        '',
        [Validators.required],
      ],
      checkForProblemsAndFaultsComments: ['', []],
      referToGuidelinesForChargingInstruction: ['', [Validators.required]],
      referToGuidelinesComments: ['', []],

      traineeName: ['', [Validators.required]],
      traineeRole: ['', [Validators.required]],
      traineeDate: ['', [Validators.required]],

      trainerName: ['', [Validators.required]],
      trainerRole: ['', [Validators.required]],
      trainerDate: ['', [Validators.required]],

      notes: ['', []],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.useOfEquipmentTrainingForm.controls[key]) {
          this.useOfEquipmentTrainingForm.controls[key].setValue(formData[key]);
        }
      });
    });
  }

  onSubmit() {
    this.isSubmitting = true;
    const formResponse = {
      ...this.useOfEquipmentTrainingForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  valid(): boolean {
    return (
      this.useOfEquipmentTrainingForm.dirty &&
      this.useOfEquipmentTrainingForm.valid &&
      !this.isSubmitting
    );
  }
}
