export default [
  // breastForm
  'sex',
  'mainSymptom',
  'mainSymptomLengthInMonths',
  'otherSymptoms',
  'otherSymptomsLengthInMonths',
  'weightSt', // BMI calculator
  'weightLbs', // BMI calculator
  'heightFt', // BMI calculator
  'heightIns', // BMI calculator
  'bmi',
  'breastDensityScore',
  'alcohol',
  'glassesOfWinePerWeek', // if alcohol
  'pintsOfBeerPerWeek', // if alcohol
  'bottlesOfBeerCiderOrAlcopopPerWeek', // if alcohol
  'shotsOfSpiritPerWeek', // if alcohol
  'weeklyAlcoholIntakeInGrams',
  'ageFirstPeriod',
  'oralContraceptive',
  'currentlyOnContraceptives',
  'oralContraceptiveYears', // if oralContraceptive
  'oralContraceptiveLast2years', // if oralContraceptive
  'stillGetPeriods',
  'periodsStoppedNaturally', // if stillGetPeriods = false
  'periodsStoppedNaturallyAge', // if periodsStoppedNaturally
  'everUsedMenopauseHormoneTherapy',
  'currentlyUsingMenopauseHormoneTherapy',
  'everUsedHormoneReplacementTherapy',
  'yearsUsedHrt', // if everUsedHormoneReplacementTherapy
  'usedHrtInLast5Years', // if everUsedHormoneReplacementTherapy
  'typeOfHrtUsed', // if everUsedHormoneReplacementTherapy
  'children',
  'ageAtFirstLiveBirth',
  'childrenList',
  'childYOB', // if children - ITERATE
  'childSex', // if children - ITERATE
  'mammogram',
  'mammogramDensityInformed', // if mammogram
  'mammogramBirads', // if mammogram
  'endometriosis',
  'tubalLigation',
  'oophorectomy',
  'mastectomy',
  'breastCancerOneBreast',
  'breastCancerOneBreastAge', // if breastCancerOne
  'breastCancerOtherBreast',
  'breastCancerOtherBreastAge', // if breastCancerOther
  'ovarianCancer',
  'ovarianCancerAge', // if ovarianCancer
  'pancreaticCancer',
  'pancreaticCancerAge', // if pancreaticCancer
  'breastCancerGene',
  'familyHistory',
  'firstDegRelativesNumber', // if familyHistory
  'firstDegRelativesList', // if familyHistory
  'firstDegRelativeDiagnosisAge', // if familyHistory - ITERATE
  'secondDegRelativesMaternalNumber', // if familyHistory
  'secondDegRelativesMaternalList', // if familyHistory
  'secondDegRelativeMaternalAge', // if familyHistory - ITERATE
  'secondDegRelativesPaternalNumber', // if familyHistory
  'secondDegRelativesPaternalList', // if familyHistory
  'secondDegRelativePaternalAge', // if familyHistory - ITERATE
  'childIndex'
];
