import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { environment } from 'apps/frontend/portal/src/environments/environment';
import firebase from 'firebase/compat/app';
import { combineLatest, from, Observable, of, Subject } from 'rxjs';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { LayoutDefaultSidebarService } from '../../core/layout/services/layout-default-sidebar/layout-default-sidebar.service';
import { AuthStore } from '../../feature-auth/store/auth.store';
import { UsersService } from '../../shared/services/users/users.service';
import { AuditService } from '../audit/audit.service';
import { DialogRefService } from '../dialog/dialog-ref.service';
import { OrganisationsService } from '../organisations/organisations.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _checkIfReverificationIsRequired$: Observable<boolean>;
  private _loggedIn$: Subject<boolean> = new Subject();
  private _loggedOut$: Subject<boolean> = new Subject();
  private _redirectUrl = '';
  private _isAuthenticated = false;

  constructor(
    private layoutDefaultSidebarService: LayoutDefaultSidebarService,
    private organisationsService: OrganisationsService,
    private dialogRefService: DialogRefService,
    private usersService: UsersService,
    private authStore: AuthStore,
    private audit: AuditService,
    private router: Router,
    public afAuth: AngularFireAuth,
  ) {}

  async logOut(): Promise<void> {
    this.layoutDefaultSidebarService.showSidebar = false;
    this.dialogRefService.closeAllDialogs();

    await this.audit.logoutNative();
    this._loggedOut$.next(true);
    this._checkIfReverificationIsRequired$ = null;
    return this.afAuth.signOut();
  }

  get loggedIn$(): Observable<boolean> {
    return this._loggedIn$.asObservable();
  }

  get loggedOut$(): Observable<boolean> {
    return this._loggedOut$.asObservable();
  }

  checkIfReverificationIsRequired$(): Observable<boolean> {
    if (!this._checkIfReverificationIsRequired$) {
      this._checkIfReverificationIsRequired$ = from(
        firebase.app().functions(environment.region).httpsCallable('is2FAReverificationRequired')(''),
      ).pipe(
        map(r => !!r.data),
        shareReplay(1),
      );
    }
    return this._checkIfReverificationIsRequired$;
  }

  orgRequires2FA$(): Observable<boolean> {
    return this.usersService.myOrganisationId$.pipe(
      switchMap(orgId =>
        orgId
          ? this.organisationsService
              .getOrganisation$(orgId)
              .pipe(
                map(organisation =>
                  organisation ? organisation.featureFlags?.securityAndCompliance?.twoFactorAuthEnabled : false,
                ),
              )
          : of(false),
      ),
      shareReplay(1),
    );
  }

  canActivate(stateUrl?: string): Observable<boolean> {
    return this.afAuth.authState.pipe(
      switchMap(user => {
        if (!user) {
          this.authStore.setRedirectUrl(stateUrl);
          this.router.navigate(['auth', 'login']);
          return of(false);
        }
        if (!user.emailVerified) {
          this.router.navigate(['auth', 'verify']);
          return of(false);
        }
        return combineLatest([this.orgRequires2FA$(), this.checkIfReverificationIsRequired$()]).pipe(
          map(([org2FA, reverify]) => !(org2FA && reverify)),
          tap(shouldNotReverify => !shouldNotReverify && this.router.navigate(['auth', 'verify'])),
        );
      }),
      take(1),
    );
  }

  canActivateIfAdmin(): Observable<boolean> {
    return this.usersService.me$.pipe(
      map(me => {
        if (me?.roles?.admin) return true;

        //   Redirects to patient list page if not an admin and they enter the link to the admin page
        this.router.navigate(['patients']);
        return false;
      }),
    );
  }

  setRedirectUrl(url: string): void {
    this._redirectUrl = url;
  }

  getRedirectUrl(): string {
    return this._redirectUrl;
  }

  isAuthenticated(): boolean {
    // Your logic to check if the user is authenticated goes here
    // You can use a token, session, or any other authentication method
    return this._isAuthenticated;
  }

  setAuthenticated(value: boolean): void {
    this._isAuthenticated = value;
  }
}
