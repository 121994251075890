<form [formGroup]="entryForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <mat-form-field appearance="fill" class="notes-field half-width-field">
      <input
        matInput
        formControlName="notes"
        type="notes"
        placeholder="Notes"
        autocomplete="off"
      />
    </mat-form-field>
    <br />
    <button
      mat-raised-button
      class="positive-button"
      type="submit"
      [disabled]="isSubmitting"
    >
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
