import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, DocumentReference } from '@angular/fire/compat/firestore';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CamelCaseToTitleCasePipe, OrderByPipe, SystemCheckService } from '@ic-monorepo/shared-common';
import {
  ClinicalForm,
  ClinicalFormResponse,
  ContentInputType,
  EntryWithId,
  Form,
  FormDataObject,
  FormField,
  FormFieldValidationSchema,
  FormGraphConfigs,
  FormValidation,
  FormWithId,
  GroupedFormWithTeamReference,
  UserInputType,
} from '@islacare/ic-types';
import firebase from 'firebase/compat/app';
import { groupBy } from 'lodash-es';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, ReplaySubject, Subject, combineLatest, from, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { TeamsService } from '../../../services/teams/teams.service';
import { convertFirestoreTimestampToDate } from '../../../utils/convertFirestoreTimestampToDate';
import { FormPreviewDialogComponent } from '../../material-deprecated/dialogs/form-preview-dialog/form-preview-dialog.component';
import { UsersService } from '../users/users.service';
import assessmentAndTreatmentOrder from './response-order/assessment-and-treatment-order';
import boadiceaOrder from './response-order/boadicea-order';
import breastOrder from './response-order/breast-order';
import burnsPatientAssessmentOrder from './response-order/burns-patient-assessment-order';
import BurnsReferralOrder from './response-order/burns-referral-order';
import BurnsScarOrder from './response-order/burns-scar-order';
import CadiOrder from './response-order/cadi-order';
import CadiPhqFormOrder from './response-order/cadi-phq-order';
import cchqOrder from './response-order/cchq-order';
import covidPatientVisitorScreeningOrder from './response-order/covid-patient-visitor-screening-order';
import DermGeneralFuOrder from './response-order/derm-general-fu-order';
import DermGeneralNewOrder from './response-order/derm-general-new-order';
import DermMohsOrder from './response-order/derm-mohs-order';
import DlqiAdditionalOrder from './response-order/dlqi-additional-order';
import DlqiHadsFormOrder from './response-order/dlqi-hads-order';
import DlqiOrder from './response-order/dlqi-order';
import dopplerOrder from './response-order/doppler-order';
import Gad7Order from './response-order/gad7-order';
import HadsOrder from './response-order/hads-order';
import HariAssessmentOrder from './response-order/hari-assessment-order';
import isotretinoinOptInOrder from './response-order/isotretinoin-opt-in-order';
import isotretinoinOptOutOnContraceptionOrder from './response-order/isotretinoin-opt-out-on-contraception-order';
import kccqOrder from './response-order/kccq-order';
import lothianFollowUpOrder from './response-order/lothian-follow-up-order';
import lothianPatientResponseOrder from './response-order/lothian-patient-responses-order';
import maternityOrder from './response-order/maternity-order';
import MfqFormOrder from './response-order/mfq-order';
import mskReferralOrder from './response-order/msk-referral-order';
import NeurologyFormOrder from './response-order/neurology-order';
import nucaPqOrder from './response-order/nuca-pq-order';
import nucaReferralOrder from './response-order/nuca-referral-order';
import PadAdultThoracicFormOrder from './response-order/pad-adult-thoracic-order';
import PadCardiacWoundMultipleSitesOrder from './response-order/pad-cardiac-wound-multiple-sites-order';
import PadAdultCardiacWoundOrder from './response-order/pad-cardiac-wound-order';
import PadPaediatricCardiacWoundOrder from './response-order/pad-paediatric-cardiac-wound-order';
import PadPressureUlcerFormOrder from './response-order/pad-pressure-ulcer-order';
import {
  default as BpiInterfacesScaleOrder,
  default as painBpiInterfaceOrder,
} from './response-order/pain-bpi-interface-order'; // should this be used somewhere?
import Dn4Order from './response-order/pain-dn4-order';
import painFollowUpPatientOrder from './response-order/pain-follow-up-patient-order';
import PainMedicationOrder from './response-order/pain-medication-order';
import NeuropathicSymptomOrder from './response-order/pain-neuropathic-order';
import painNewPatientOrder from './response-order/pain-new-patient-order';
import PainSummaryOrder from './response-order/pain-summary-order';
import patientEvaluationMeasureFormOrder from './response-order/patient-evaluation-measure-form.order';
import patientSpecificFunctionalScaleFormOrder from './response-order/patient-specific-functional-scale-form-order';
import phototherapyNonAttendanceOrder from './response-order/phototherapy-non-attendance-order';
import PhototherapyOrder from './response-order/phototherapy-order';
import phototherapyPuvaConsentOrder from './response-order/phototherapy-puva-consent-order';
import phototherapyUvbConsentOrder from './response-order/phototherapy-uvb-consent-order';
import PhqOrder from './response-order/phq-order';
import PoemFormOrder from './response-order/poem-order';
import SexualHealthFeedbackOrder from './response-order/sexual-health-feedback-order';
import SsiSurveillanceOrder from './response-order/ssi-surveillance-order';
import standingFrameOrder from './response-order/standing-frame-order';
import SurgicalWoundHealingOrder from './response-order/surgical-wound-healing-order';
import TrustSsiSurveillanceFormOrder from './response-order/trust-ssi-surveillance-order';
import uas7Order from './response-order/uas7-order';
import useOfEquipmentTrainingOrder from './response-order/use-of-equipment-training-order';
import videoAndPhotoConsentOrder from './response-order/video-and-photo-consent-order';
import VisualPainOrder from './response-order/visual-pain-order';
import vulvalFormOrder from './response-order/vulval-order';
import WoundAssessmentFormOrder from './response-order/wound-assessment-form-order';

export interface FormResponseDisplayInput {
  formId: string;
  teamId: string;
  formResponse: any;
  formResponseKeys?: string[];
  formKeysMap?: any;
  bodyMapUrl?: string;
  imageMapObject?: any;
}

export interface AddFormToTeamRequest {
  data: FormDataObject;
  teamId: string;
  formId: string;
  isArchived: boolean;
}

export enum FormType {
  AUTOMATIC = 'AUTOMATIC',
  FORMLY = 'FORMLY',
  FORMLY_AUTOMATIC = 'FORMLY_AUTOMATIC',
  LEGACY = 'LEGACY',
}

@Injectable({
  providedIn: 'root',
})
export class FormService {
  private _submit$ = new Subject<FormData | ClinicalFormResponse>();

  get submit$(): Observable<FormData | ClinicalFormResponse> {
    return this._submit$.asObservable();
  }

  private _retreive$ = new ReplaySubject<FormData | ClinicalFormResponse>(1);
  retreive$ = this._retreive$.asObservable();

  private _sensitive$ = new Subject<boolean>();
  get sensitive$(): Observable<boolean> {
    return this._sensitive$.asObservable();
  }

  constructor(
    private systemCheckService: SystemCheckService,
    private toCamelCase: CamelCaseToTitleCasePipe,
    private dialogService: DialogService,
    private usersService: UsersService,
    private teamsService: TeamsService,
    private fb: UntypedFormBuilder,
    private db: AngularFirestore,
    public dialog: MatDialog,
  ) {}

  myGroupedFormsWithTeam$(): Observable<GroupedFormWithTeamReference[]> {
    return this.myForms$.pipe(switchMap(async forms => await this.makeGroupedForm(forms)));
  }

  get myForms$(): Observable<FormWithId[]> {
    return this.usersService.user$.pipe(
      switchMap(user => (!user ? of([]) : of(user?.teamIds))),
      switchMap(teamIds =>
        teamIds && teamIds.length
          ? combineLatest(teamIds.map(t => this.getForms(t, false))).pipe(map(forms => forms.flat()))
          : of([]),
      ),
      shareReplay({ refCount: true }),
    );
  }

  getForms(teamId: string, excludeArchived?: boolean): Observable<FormWithId[]> {
    return this.getFormsOnTeam(teamId).pipe(
      map(forms =>
        forms.filter(form =>
          excludeArchived
            ? !form?.isArchived && (!form?.isAutomaticForm || (form?.isAutomaticForm && form?.enableForm))
            : !form?.isAutomaticForm || (form?.isAutomaticForm && form?.enableForm),
        ),
      ),
    );
  }

  getFormsOnTeam(teamId: string): Observable<FormWithId[]> {
    return this.db.collection<FormWithId>(`teams/${teamId}/forms`).valueChanges({ idField: 'id' });
  }

  /**
   * @deprecated getFormsWithTeam should not be used - should use getFormsWithTeam$
   */
  getFormsWithTeam = (teamId: string): Observable<GroupedFormWithTeamReference[]> =>
    this.getForms(teamId, true).pipe(switchMap(async forms => await this.makeGroupedForm(forms)));

  getFormsWithTeam$(teamId: string): Observable<GroupedFormWithTeamReference[]> {
    return this.getForms(teamId, true).pipe(
      switchMap(forms => from(this.makeGroupedForm(forms))),
      map(forms => [
        {
          teamName: 'Default',
          forms: [{ id: 'default', name: 'Default notes form' }],
        } as any,
        ...forms,
      ]),
    );
  }

  async getFormKeysMap(formId, teamId, formResponseKeys) {
    const formKeysMap = new Map();
    try {
      const automatedFormSnapshot = await this.getAutomaticFormSnapshot(teamId, formId);
      const automatedFormOrderedArray = automatedFormSnapshot.data().formFieldsOrderedArray;
      const automatedFormOrderedArrayGrouped = groupBy(automatedFormOrderedArray, 'controlName');

      formResponseKeys.forEach(key => {
        const displayName = automatedFormOrderedArrayGrouped?.[key]?.[0].displayName;
        const subtitle = automatedFormOrderedArrayGrouped?.[key]?.[0].subTitle;
        const title = automatedFormOrderedArrayGrouped?.[key]?.[0].title;
        const value = automatedFormOrderedArrayGrouped?.[key]?.[0].value;
        formKeysMap.set(key, displayName || title || value || subtitle || this.toCamelCase.transform(key));
      });
      return formKeysMap;
    } catch (error) {
      return null;
    }
  }

  submit(data: FormData | ClinicalFormResponse): void {
    this._submit$.next(data);
  }

  setSensitiveImage(status: boolean) {
    this._sensitive$.next(status);
  }

  retreive(data: FormData | ClinicalFormResponse) {
    this._retreive$.next(data);
  }

  resetRetreive() {
    // Reset the _retreive subject with dummy data
    this._retreive$.next({ key: 'value' } as unknown as FormData);
  }

  getResponseOrder(formId) {
    switch (formId) {
      case 'KNPAEwPTPRKZEhRaMzuz':
        return SsiSurveillanceOrder;
      case 'pafS10Z17fR3o5gPckSh':
        return PadPaediatricCardiacWoundOrder;
      case 'nmNcqW9ijF0Sonz8dDMd':
        return PadPaediatricCardiacWoundOrder;
      case 'PyCv9RyRSl7RzvMCuDMc':
        return PadAdultCardiacWoundOrder;
      case '5BTxaAEj1PLosmqKSfpK':
        return TrustSsiSurveillanceFormOrder;
      case 'bkMlcL6H6RrXrb5soItN':
        return PadPressureUlcerFormOrder;
      case '8XRCKvit25VYBEsvKUy8':
        return NeurologyFormOrder;
      case 'N1bmUHVQL2XZ6bln4n2x':
        return DlqiHadsFormOrder;
      case 'qpQXh36CkhadsWFYhC67':
        return PadAdultThoracicFormOrder;
      case 'YLj3zOI9MZ4qmxjEP67C':
        return CadiPhqFormOrder;
      case 'MlsweaG5RTeQR71iGM2x':
        return WoundAssessmentFormOrder;
      case 'q3NLdkEIxfJ5KEjsQ1HY':
        return BurnsReferralOrder;
      case 'VQky56ouEUrXLDn9uaBl':
        return burnsPatientAssessmentOrder;
      case 'dRaMPSxDmno8XmVbta3r':
        return painNewPatientOrder;
      case 'ctx4B3mqfYsr4X1C9Qsw':
        return painFollowUpPatientOrder;
      case 'r2O9VLCJ8gtHpyi0EbED':
        return DlqiAdditionalOrder;
      case 'KJcu1dQX8zDVlA3ifdzv':
        return SexualHealthFeedbackOrder;
      case 'llQXeHhYes7JEIv96Vyw':
        return SurgicalWoundHealingOrder;
      case 'bJsGkCw1gj78leLjZSy2':
        return MfqFormOrder;
      case 'tNcRl9oopwNAv483Mg8e':
        return PoemFormOrder;
      case 'TBoiRDmAg7066NRqSys4':
        return BurnsScarOrder;
      case 'WDkliqcPNPD6E9BWVD2G':
        return DlqiOrder;
      case 'vVMe9HsSPaaO6OuM9qc9':
        return HadsOrder;
      case 'EeOijT6ZhF6STJPh7D79':
        return CadiOrder;
      case 'Kl0n6y4nrbps5Dl4AmBr':
        return PhqOrder;
      case 'Bdrc1XSxKR7Xm54kQ3UK':
        return HariAssessmentOrder;
      case 'ai8WnXjsqJhkGvI7d8Be':
        return DermMohsOrder;
      case 'RtSovJUspuHwcGgW8zCb':
        return DermGeneralFuOrder;
      case 'zi8sFi0WHgSm9C0YCcgp':
        return DermGeneralNewOrder;
      case '6aLUFCHaroyHZtQOp9RZ':
        return PadCardiacWoundMultipleSitesOrder;
      case '16OpH6mV0dGJyWqwrB6m':
        return PainMedicationOrder;
      case '9T7kspSeqvivkXIKAsnJ':
        return PainSummaryOrder;
      case 'GS1R408jnK4jlzZxCCjy':
        return Dn4Order;
      case 'HnsZqPuuAFe4nVZiYakU':
        return NeuropathicSymptomOrder;
      case 'NOT-USED':
        return BpiInterfacesScaleOrder;
      case 'jwZ7wS16a4S5SaJ0iydf':
        return painBpiInterfaceOrder;
      case 'nMeulaCjfiMlj7VzWHfX':
        return VisualPainOrder;
      case 'BviqBZh2UuTVxQrHxVgM':
        return Gad7Order;
      case 'HDpjGaDgFWwf4999BDHQ':
        return isotretinoinOptOutOnContraceptionOrder;
      case 'LyxaPrsVW0RvrSuobZQJ':
        return isotretinoinOptOutOnContraceptionOrder;
      case 'RI1g6wwCtI4vqTaRl3y8':
        return isotretinoinOptInOrder;
      case 'lbJJWeZefGkjasUQrGlr':
        return PhototherapyOrder;
      case 'bzt86zsReVu4Xza7l8TC':
        return nucaPqOrder;
      case '5pYlWSfYn9mhxtFeKSCO':
        return phototherapyPuvaConsentOrder;
      case '27KNqQxwYRgkoZLvLFAt':
        return phototherapyUvbConsentOrder;
      case 'miZz5s9m5k66njURHbIH':
        return phototherapyNonAttendanceOrder;
      case '3VY1Ptg6S5fU6Li4PApa':
        return breastOrder;
      case 'WGE82S3G1ecSGbBEB9Pv':
        return maternityOrder;
      case 's2rTLV66Tczx8TgP4inr':
        return lothianPatientResponseOrder;
      case '0LK9HUhnyC8SLG58MSbI':
        return lothianFollowUpOrder;
      case 'cwXU0L2Mywf5VQO2aDs5':
        return nucaReferralOrder;
      case '2BqNmGVgPKbeyi72nPHX':
        return dopplerOrder;
      case 'MivijS1lNLfeFMB5p9wL':
        return cchqOrder;
      case 'tQQscuT2m2OALyf8lMV4':
        return standingFrameOrder;
      case 'HDW9V1A6VInjKxRTe2hw':
        return useOfEquipmentTrainingOrder;
      case 'HphAQ1p9MMGcqHWESIuy':
        return mskReferralOrder;
      case 'vStdwNDp6MbUoi2ZNYeL':
        return uas7Order;
      case 'c0RXrlhINxoMd6Ozs6iY':
        return kccqOrder;
      case 'G0xEK12U1Gjscd9OeqKc':
        return videoAndPhotoConsentOrder;
      case 'oQKWUEkHrwXqZUmFKq98':
        return covidPatientVisitorScreeningOrder;
      case '4hG2UNHignDIw6xdfaDd':
        return boadiceaOrder;
      case 'woundMeasurementPrototype':
        return ['woundLengthMm', 'woundWidthMm', 'rulerLengthMm'];
      case 'jLTGkst3CzHolvC5WKh6':
        return patientEvaluationMeasureFormOrder;
      case 'yrSWg6Mq3LpuzlgxZAkz':
        return patientSpecificFunctionalScaleFormOrder;
      case 'EufjCcW9JCXw5785CvfH':
        return assessmentAndTreatmentOrder;
      case 'uqtN04OghDsWNOenobCB':
        return vulvalFormOrder;
      default:
        return ['notes'];
    }
  }

  getForm(teamId: string, formId: string): Observable<FormWithId> {
    return this.db.doc<FormWithId>(`teams/${teamId}/forms/${formId}`).valueChanges();
  }

  getFormSnapshot = (teamId: string, formId: string) => {
    return this.db.doc<FormWithId>(`teams/${teamId}/forms/${formId}`).ref.get();
  };

  showForm(formIds: string[], teamId: string, title: string): void {
    this.dialogService.open(FormPreviewDialogComponent, {
      header: 'Create folders',
      width: '80%',
      data: {
        title: title,
        initialMessage: 'The form(s) below will be presented for all submissions when set on a collection.',
        finalMessage: 'Note: This form is as a display only, submitting triggers no action.',
        positiveButton: 'Thanks',
        formIds: formIds,
        teamId: teamId,
        video: false,
      },
    });
  }

  showFormPreview(formIds: string[], teamId: string, title: string): void {
    let styleClass = `full-screen-mobile bespoke-calc-dialog`;
    let maskStyleClass = ``;

    if (this.systemCheckService.isMobile) {
      styleClass = `${styleClass} ${'mobile-dialog md:w-10 w-12 border-round-top-sm overflow-hidden h-full'}`;
      maskStyleClass = `dynamic-dialog-full-screen-mask`;
    }

    this.dialogService.open(FormPreviewDialogComponent, {
      header: title,
      width: '80%',
      styleClass: styleClass,
      maskStyleClass: maskStyleClass,
      data: {
        finalMessage: 'Note: This form is as a display only, submitting triggers no action.',
        positiveButton: 'Done',
        formIds: formIds,
        teamId: teamId,
        video: false,
      },
    });
  }

  async getBodyMapUrl(bodyMapPath: string): Promise<string> {
    const storageRef = firebase.storage().ref(bodyMapPath);
    const downloadUrl = await storageRef.getDownloadURL();
    return downloadUrl;
  }

  getAutomaticFormSnapshot(teamId: string, formId: string) {
    if (teamId) {
      return this.db.doc<FormWithId>(`teams/${teamId}/forms/${formId}`).ref.get();
    } else {
      return this.db.doc<FormWithId>(`formData/${formId}`).ref.get();
    }
  }

  getFormlyFormSnapshotFromFormData(formId: string) {
    return this.db.doc<ClinicalForm>(`formData/${formId}`).ref.get();
  }

  async getFormData(formId: string): Promise<FormDataObject | ClinicalForm> {
    const doc = await this.db.doc<FormDataObject | ClinicalForm>(`formData/${formId}`).ref.get();
    return { ...doc.data(), id: doc.id };
  }

  // getDetailedFormData, getDetailedReferencedFormData temp living on the UI
  async getDetailedFormData(formId: string): Promise<Form> {
    const doc = await this.db.doc<Form>(`formData/${formId}`).ref.get();
    return { ...(doc.data() as Form) };
  }

  async getDetailedReferencedFormData(formIds: string[]): Promise<Form[]> {
    const allFormPromises: Promise<Form>[] = [];
    formIds.map((formId: string) => {
      const referencedForm = this.getDetailedFormData(formId);

      // append sectionId to controlName, responseOrder
      referencedForm.then((form: Form) => {
        form.formFieldsOrderedArray.forEach((formField: FormField) => {
          formField.controlName = formField.controlName + '---' + formId;
        });

        form.responseOrder = [...form.responseOrder].map((formControlName: string) => {
          return formControlName + '---' + formId;
        });
      });

      allFormPromises.push(referencedForm);
    });

    return await Promise.all(allFormPromises);
  }

  getAllFormData() {
    return this.db.collection<FormDataObject>('formData').valueChanges({ idField: 'id' });
  }

  async addFormDataToTeamAndUpdateEnabledTeamIds(formDataId: string, teamId: string) {
    const formDataFromGlobalForm: FormDataObject = (await this.getFormData(formDataId)) as FormDataObject;

    const addFormRequest: AddFormToTeamRequest = {
      data: formDataFromGlobalForm,
      teamId: teamId,
      formId: formDataId,
      isArchived: false,
    };
    try {
      await this.addFormDataToTeam(addFormRequest);
      await this.updateEnabledTeamIdsOnFormData(formDataId, teamId);
    } catch (err) {
      console.error(err);
    }

    if (formDataFromGlobalForm.hasSections) {
      const referenceFormIds = formDataFromGlobalForm.formFieldsOrderedArray
        .filter(obj => obj.inputType === ContentInputType.FORM_REFERENCE)
        .map(obj => obj.formId);

      const referencedForms = (
        await this.db
          .collection<FormDataObject>('formData', ref =>
            ref.where(firebase.firestore.FieldPath.documentId(), 'in', referenceFormIds),
          )
          .get()
          .toPromise()
      ).docs;

      for (const formSnapshot of referencedForms) {
        const request: AddFormToTeamRequest = {
          data: formSnapshot.data(),
          teamId: teamId,
          formId: formSnapshot.id,
          isArchived: true,
        };
        await this.addFormDataToTeam(request);
      }
    }
  }

  updateEnabledTeamIdsOnFormData(formId: string, teamId: string) {
    return this.db.doc(`formData/${formId}`).update({
      enabledTeamIds: firebase.firestore.FieldValue.arrayUnion(teamId),
    });
  }

  async addFormDataToTeam(request: AddFormToTeamRequest) {
    const formType: FormType = this.getFormType(request.data);

    const dataForTeam: Form = {
      name: request.data.name,
      teamId: request.teamId,
      enableForm: true,
      hasSections: request.data.hasSections || false,
      isArchived: request.isArchived,
      formVersionNumber: request.data.formVersionNumber || 1,
    };

    if (formType === FormType.FORMLY) {
      dataForTeam.fieldConfig = request.data['fieldConfig'];
    }

    if (formType === FormType.AUTOMATIC) {
      dataForTeam.formFieldsOrderedArray = request.data.formFieldsOrderedArray || [];
      dataForTeam.isAutomaticForm = request.data.isAutomaticForm || false;
    }

    if (formType === FormType.FORMLY_AUTOMATIC) {
      dataForTeam.fieldConfig = request.data['fieldConfig'];
      dataForTeam.formFieldsOrderedArray = request.data.formFieldsOrderedArray || [];
      dataForTeam.isAutomaticForm = request.data.isAutomaticForm || false;
    }

    const formDataWithMetadata: Form = this.addFormMetadata(request.data, dataForTeam);

    const document: AngularFirestoreDocument<unknown> = this.db.doc(`teams/${request.teamId}/forms/${request.formId}`);
    const documentRef: DocumentReference<unknown> = document.ref;

    documentRef.get().then(async (docSnapshot: firebase.firestore.DocumentSnapshot<unknown>) => {
      if (docSnapshot.exists) {
        return document?.update(formDataWithMetadata);
      } else {
        return document?.set(formDataWithMetadata);
      }
    });
  }
  async archiveFromOnTeam(formDataId: string, teamId: string) {
    await this.db.doc(`teams/${teamId}/forms/${formDataId}`)?.update({ isArchived: true });
    // don't remove the team from enabledTeamIds to give visibility of what they used to have
  }

  async makeGroupedForm(forms: FormWithId[]) {
    //get all the distict teamIds from the forms
    const teamIds = [...new Set(forms.map(form => form.teamId))].filter(teamId => teamId);

    //get the name & id for these teams, so we can group it accordingly.
    const teams = await Promise.all(
      teamIds.map(async teamId => {
        const teamRef = await this.teamsService.getTeamSnapshot(teamId);
        return { name: teamRef.data()?.name, id: teamRef.id };
      }),
    );

    const groupedForms: GroupedFormWithTeamReference[] = [];

    forms.forEach(form => {
      const teamIndex = groupedForms.findIndex(item => item.teamId === form.teamId);
      if (teamIndex === -1) {
        const obj: GroupedFormWithTeamReference = {
          teamId: form.teamId,
          forms: [form],
          teamName: teams.find(team => team.id === form.teamId)?.name,
        };
        groupedForms.push(obj);
      } else {
        groupedForms[teamIndex]['forms'].push(form);
      }
    });

    return groupedForms;
  }

  async getAutomatedFormResponseOrder(formId) {
    const formData = (await this.db.doc<FormDataObject>(`formData/${formId}`).ref.get()).data();
    return formData?.responseOrder;
  }

  async getFormGraphConfigs(formId: string): Promise<FormGraphConfigs> {
    const formData = (await this.db.doc<FormDataObject>(`formData/${formId}`).ref.get()).data();
    return formData?.graphConfigs;
  }

  getErrorMessage = (control: UntypedFormControl | AbstractControl) => {
    if (control?.errors?.required) return 'This must be provided';
    else if (control?.errors?.minlength)
      return `Provide atleast ${control?.errors?.minlength?.requiredLength} characters.`;
    else if (control?.errors?.email) return 'Invalid email';
    else if (control?.errors?.pattern) return 'Invalid Pattern';
    else if (control?.errors?.maxlength)
      return `Provide atmost ${control?.errors?.maxlength?.requiredLength} characters.`;
    else if (control?.errors?.min) return `Minimum value must be ${control?.errors?.min?.min}`;
    else if (control?.errors?.max) return `Maximum value must be ${control?.errors?.max?.max}`;
  };

  isValidFormField(formField: FormField): boolean {
    const validateData = FormFieldValidationSchema().validate(formField);
    if (validateData.error) {
      console.log('error', validateData.error);
      return false;
    }
    return true;
  }

  isUserInputField(inputType: any) {
    if (Object.values(UserInputType).includes(inputType as any)) return true;
    return false;
  }

  getValidationArray(validationObject: FormValidation) {
    const validationsArray = Object.keys(validationObject);
    return validationsArray?.length
      ? validationsArray.map(validation =>
          validation === 'required' || validation === 'email'
            ? Validators[validation]
            : Validators[validation](validationObject[validation]),
        )
      : [];
  }

  buildForm(formFields: FormField[], formIds?) {
    const formObj = {};
    formFields?.forEach(formField => {
      if (formField.inputType === ContentInputType.FORM_REFERENCE && formIds) return formIds.push(formField?.formId);

      if (!this.isValidFormField(formField)) return;

      if (formField.minDate) formField.minDate = convertFirestoreTimestampToDate(formField.minDate);

      if (formField.maxDate) formField.maxDate = convertFirestoreTimestampToDate(formField.maxDate);

      if (this.isUserInputField(formField.inputType)) {
        if (formField.inputType === UserInputType.FORM_ARRAY) {
          formObj[formField.controlName] = this.fb.array([]);
        } else {
          formObj[formField.controlName] = [formField.value, this.getValidationArray(formField?.validation || {})];
        }
      }
    });
    return this.fb.group(formObj);
  }

  getUpdatedFormsArray(forms: GroupedFormWithTeamReference[]): GroupedFormWithTeamReference[] {
    const index = forms.findIndex(f => f.teamId === 'default');
    if (index === -1) {
      const defaultObject: GroupedFormWithTeamReference = {
        forms: [
          {
            id: 'default',
            name: 'Default notes form',
            teamId: 'default',
            formVersionNumber: 1,
          },
        ],
        teamId: 'default',
        teamName: 'Default',
      };
      forms.unshift(defaultObject);
    }
    const pipe = new OrderByPipe();
    return forms.map(form => {
      form.forms = pipe.transform(form.forms, 'name');
      return form;
    });
  }

  async getFormkeys(formId: string, teamId: string) {
    const automaticFormData = (await this.getFormData(formId)) as FormDataObject;
    const formResponseKeys = automaticFormData?.responseOrder || this.getResponseOrder(formId);
    const formKeysMap = await this.getFormKeysMap(formId, teamId, formResponseKeys);
    return {
      formResponseKeys,
      formKeysMap,
      isNewFormResponse: !!automaticFormData?.featureFlags?.newFormResponse,
    };
  }

  async getAutomaticForm(
    formId: string,
    teamId: string,
    formKeysMap: Map<string, string>,
    formResponseKeys: string[],
  ): Promise<boolean> {
    const automaticForm = await this.getAutomaticFormSnapshot(teamId, formId);
    const hasSections = automaticForm?.data()?.hasSections;
    if (automaticForm?.data()?.formFieldsOrderedArray) {
      for (const formField of automaticForm?.data()?.formFieldsOrderedArray) {
        if (formField.inputType === ContentInputType.FORM_REFERENCE) {
          const referencedFormResponseKeys = await this.getAutomatedFormResponseOrder(formField.formId);
          const referencedFormKeysMap = await this.getFormKeysMap(formField.formId, teamId, referencedFormResponseKeys);
          const referencedAutomaticForm = await this.getAutomaticFormSnapshot(teamId, formField.formId);
          if (automaticForm.data()?.hasSections) {
            referencedAutomaticForm.data().formFieldsOrderedArray.forEach(field => {
              formResponseKeys[formResponseKeys.indexOf(field.controlName)] =
                field.controlName + '---' + formField.formId;
              referencedFormKeysMap.delete(field.controlName);
              referencedFormKeysMap.set(
                field.controlName + '---' + formField.formId,
                field.displayName ||
                  field.title ||
                  field.subTitle ||
                  field.value ||
                  this.toCamelCase.transform(field.controlName),
              );
            });
          }
          referencedFormKeysMap.forEach((value, key) => formKeysMap.set(key, value));
        }
      }
    }
    return hasSections;
  }

  async returnBodyMapUrl(formResponseKeys: string[], formResponse: any) {
    let bodyMapUrl;
    if (formResponseKeys?.includes('bodyMap') && formResponse?.bodyMap) {
      bodyMapUrl = await this.getBodyMapUrl(formResponse['bodyMap']);
    }
    return bodyMapUrl;
  }

  async getImageMapObject(formResponse: any) {
    const imageMapObject = {};
    if (formResponse?.imageMapDataUrlArray?.length) {
      for (const imageData of formResponse.imageMapDataUrlArray) {
        const imageURL = await this.getBodyMapUrl(imageData['imageMap']);
        imageMapObject[imageData?.['controlName']] = imageURL;
      }
    }
    return imageMapObject;
  }

  getScore(formId: string, formResponse: any, formResponseKeys: string[], formKeysMap: Map<string, string>): void {
    const scoreLookup = 'score' + formId;
    if (formResponse && formResponse[scoreLookup]) {
      formResponseKeys[formResponseKeys.indexOf('score')] = scoreLookup;
      formKeysMap.delete('score');
      formKeysMap.set(scoreLookup, 'Score');
    }
  }

  getKeysWithNoResponse(tempFormResponseKeys: string[], formResponse: any, imageMapObject: any): string[] {
    const keysWithNoResponse = [];
    if (formResponse && tempFormResponseKeys) {
      for (const key of tempFormResponseKeys) {
        if (!formResponse[key] && formResponse[key] !== 0 && !Object.prototype.hasOwnProperty.call(imageMapObject, key))
          keysWithNoResponse.push(key);
      }
    }
    return keysWithNoResponse;
  }

  spliceKeysWithNoResponse(keysWithNoResponse: string[], allFormResponsesKeys: string[], entries: EntryWithId[]) {
    const keyCount = keysWithNoResponse?.reduce((acc, key) => {
      if (!acc[key]) acc[key] = 1;
      else acc[key]++;
      return acc;
    }, {});

    for (const key in keyCount) {
      if (keyCount[key] === entries.length) {
        const index = allFormResponsesKeys.findIndex(formKey => formKey === key);
        allFormResponsesKeys.splice(index, 1);
      }
    }
    return allFormResponsesKeys;
  }

  private getFormType(form: FormDataObject): FormType {
    const isAutomaticForm = !!form?.isAutomaticForm;
    if (form['fieldConfig'] && isAutomaticForm) {
      return FormType.FORMLY_AUTOMATIC;
    } else if (form['fieldConfig']) {
      return FormType.FORMLY;
    } else if (isAutomaticForm) {
      return FormType.AUTOMATIC;
    } else {
      return FormType.LEGACY;
    }
  }

  private addFormMetadata(formData: FormDataObject, targetFormData: Form): Form {
    const copyOfTargetForm = { ...targetFormData };

    if (formData?.responsibleSurgeon) {
      copyOfTargetForm.responsibleSurgeon = formData.responsibleSurgeon;
    }
    if (formData?.responsibleConsultant) {
      copyOfTargetForm.responsibleConsultant = formData.responsibleConsultant;
    }
    if (formData?.responsibleClinician) {
      copyOfTargetForm.responsibleClinician = formData.responsibleClinician;
    }
    if (formData?.secretaries) {
      copyOfTargetForm.secretaries = formData.secretaries;
    }
    if (formData?.ward) {
      copyOfTargetForm.ward = formData.ward;
    }

    return copyOfTargetForm;
  }
}
