import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-dlqi-hads-form',
  templateUrl: './dlqi-hads-form.component.html',
  styleUrls: ['./dlqi-hads-form.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
})
export class DlqiHadsFormComponent implements OnInit {
  @Input() data;
  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  dlqiForm: UntypedFormGroup;
  hadsForm: UntypedFormGroup;
  additionalForm: UntypedFormGroup;
  isSubmitting = false;
  isSensitive: boolean;
  isLinear = true;
  dlqiTotalScore: number;

  dermatologyAnswerList: string[] = [
    'Very much - 3',
    'A lot - 2',
    'A little - 1',
    'Not at all - 0',
  ];

  ngOnInit(): void {
    this.dlqiForm = this.fb.group({
      itchySorePainfulOrStingingSkin: ['', Validators.required],
      embarrassedOrSelfConscious: ['', Validators.required],
      interferedWithShoppingHomeOrGarden: ['', Validators.required],
      skinInfluencedClothesYouWear: ['', Validators.required],
      skinAffectedSocialOrLeisureActivities: ['', Validators.required],
      skinAffectedAnySport: ['', Validators.required],
      skinPreventedWorkingOrStudyingYesNo: ['', Validators.required],
      skinPreventedWorkingOrStudying: [''],
      skinCreatedProblemsWithPartnerFriendsOrFamily: ['', Validators.required],
      skinCausedSexualDifficulties: ['', Validators.required],
      skinTreatmentBeenAProblem: ['', Validators.required],
      dlqiTotalScore: [''],
    });
    this.hadsForm = this.fb.group({
      stillEnjoyThings: ['', Validators.required],
      seeFunnySideOfThings: ['', Validators.required],
      feelCheerful: ['', Validators.required],
      feelSlowedDown: ['', Validators.required],
      lostInterestInAppearance: ['', Validators.required],
      lookForwardWithEnjoyment: ['', Validators.required],
      enjoyABookRadioOrTv: ['', Validators.required],
      tenseOrWoundUp: ['', Validators.required],
      frightenedFeelingAboutSomethingAwful: ['', Validators.required],
      worryingThoughts: ['', Validators.required],
      sitAtEaseAndFeelRelaxed: ['', Validators.required],
      butterfliesInStomachFeeling: ['', Validators.required],
      feelingRestless: ['', Validators.required],
      suddenFeelingOfPanic: ['', Validators.required],
      depressionTotalScore: [''],
      anxietyTotalScore: [''],
    });
    this.additionalForm = this.fb.group({
      currentTreatmentSideEffects: [''],
      deliveryIssueWithCurrentTreatment: [''],
      deliveryIssueWithCurrentTreatmentText: [''],
      currentWeight: [''],
      newMedicalIssuesSinceLastAppointment: [''],
      newMedicalIssuesSinceLastAppointmentText: [''],
      upToDateWithVaccinations: [''],
      upToDateWithVaccinationsText: [''],
      ageAppropriateCancerScreening: [''],
      ageAppropriateCancerScreeningText: [''],
      planningOnHavingChildrenInTheNextYear: [''],
      planningOnHavingChildrenInTheNextYearText: [''],
      areYouFemale: [''],
      areYouSexuallyActive: [''],
      whatContraceptionAreYouUsing: [''],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.dlqiForm.controls[key])
          this.dlqiForm.controls[key].setValue(formData[key]);
        if (this.hadsForm.controls[key])
          this.hadsForm.controls[key].setValue(formData[key]);
        if (this.additionalForm.controls[key])
          this.additionalForm.controls[key].setValue(formData[key]);
      });
    });
  }

  onSubmit() {
    if (!this.dlqiFormValid() && !this.hadsFormValid()) return;
    this.setDlqiScore();
    this.setHadsScore();
    this.isSubmitting = true;
    const formResponse = {
      ...this.dlqiForm.value,
      ...this.hadsForm.value,
      ...this.additionalForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
      showOnChart: true,
    };
    this.form.submit(formResponse);
  }

  // DLQI form
  get itchySorePainfulOrStingingSkin() {
    return this.dlqiForm.get('itchySorePainfulOrStingingSkin');
  }
  get embarrassedOrSelfConscious() {
    return this.dlqiForm.get('embarrassedOrSelfConscious');
  }
  get interferedWithShoppingHomeOrGarden() {
    return this.dlqiForm.get('interferedWithShoppingHomeOrGarden');
  }
  get skinInfluencedClothesYouWear() {
    return this.dlqiForm.get('skinInfluencedClothesYouWear');
  }
  get skinAffectedSocialOrLeisureActivities() {
    return this.dlqiForm.get('skinAffectedSocialOrLeisureActivities');
  }
  get skinAffectedAnySport() {
    return this.dlqiForm.get('skinAffectedAnySport');
  }
  get skinPreventedWorkingOrStudyingYesNo() {
    return this.dlqiForm.get('skinPreventedWorkingOrStudyingYesNo');
  }
  get skinPreventedWorkingOrStudying() {
    return this.dlqiForm.get('skinPreventedWorkingOrStudying');
  }
  get skinCreatedProblemsWithPartnerFriendsOrFamily() {
    return this.dlqiForm.get('skinCreatedProblemsWithPartnerFriendsOrFamily');
  }
  get skinCausedSexualDifficulties() {
    return this.dlqiForm.get('skinCausedSexualDifficulties');
  }
  get skinTreatmentBeenAProblem() {
    return this.dlqiForm.get('skinTreatmentBeenAProblem');
  }
  // HADS form
  // D
  get stillEnjoyThings() {
    return this.hadsForm.get('stillEnjoyThings');
  }
  get seeFunnySideOfThings() {
    return this.hadsForm.get('seeFunnySideOfThings');
  }
  get feelCheerful() {
    return this.hadsForm.get('feelCheerful');
  }
  get feelSlowedDown() {
    return this.hadsForm.get('feelSlowedDown');
  }
  get lostInterestInAppearance() {
    return this.hadsForm.get('lostInterestInAppearance');
  }
  get lookForwardWithEnjoyment() {
    return this.hadsForm.get('lookForwardWithEnjoyment');
  }
  get enjoyABookRadioOrTv() {
    return this.hadsForm.get('enjoyABookRadioOrTv');
  }

  // A
  get tenseOrWoundUp() {
    return this.hadsForm.get('tenseOrWoundUp');
  }
  get frightenedFeelingAboutSomethingAwful() {
    return this.hadsForm.get('frightenedFeelingAboutSomethingAwful');
  }
  get worryingThoughts() {
    return this.hadsForm.get('worryingThoughts');
  }
  get sitAtEaseAndFeelRelaxed() {
    return this.hadsForm.get('sitAtEaseAndFeelRelaxed');
  }
  get butterfliesInStomachFeeling() {
    return this.hadsForm.get('butterfliesInStomachFeeling');
  }
  get feelingRestless() {
    return this.hadsForm.get('feelingRestless');
  }
  get suddenFeelingOfPanic() {
    return this.hadsForm.get('suddenFeelingOfPanic');
  }

  // Additional info form
  get currentTreatmentSideEffects() {
    return this.additionalForm.get('currentTreatmentSideEffects');
  }
  get deliveryIssueWithCurrentTreatment() {
    return this.additionalForm.get('deliveryIssueWithCurrentTreatment');
  }
  get deliveryIssueWithCurrentTreatmentText() {
    return this.additionalForm.get('deliveryIssueWithCurrentTreatmentText');
  }
  get currentWeight() {
    return this.additionalForm.get('currentWeight');
  }
  get newMedicalIssuesSinceLastAppointment() {
    return this.additionalForm.get('newMedicalIssuesSinceLastAppointment');
  }
  get newMedicalIssuesSinceLastAppointmentText() {
    return this.additionalForm.get('newMedicalIssuesSinceLastAppointmentText');
  }
  get upToDateWithVaccinations() {
    return this.additionalForm.get('upToDateWithVaccinations');
  }
  get upToDateWithVaccinationsText() {
    return this.additionalForm.get('upToDateWithVaccinationsText');
  }
  get ageAppropriateCancerScreening() {
    return this.additionalForm.get('ageAppropriateCancerScreening');
  }
  get ageAppropriateCancerScreeningText() {
    return this.additionalForm.get('ageAppropriateCancerScreeningText');
  }
  get planningOnHavingChildrenInTheNextYear() {
    return this.additionalForm.get('planningOnHavingChildrenInTheNextYear');
  }
  get planningOnHavingChildrenInTheNextYearText() {
    return this.additionalForm.get('planningOnHavingChildrenInTheNextYearText');
  }
  get areYouFemale() {
    return this.additionalForm.get('areYouFemale');
  }
  get whatContraceptionAreYouUsing() {
    return this.additionalForm.get('whatContraceptionAreYouUsing');
  }

  dlqiFormValid(): boolean {
    return (
      this.dlqiForm.dirty &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.itchySorePainfulOrStingingSkin.errors
      ) &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.embarrassedOrSelfConscious.errors
      ) &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.interferedWithShoppingHomeOrGarden.errors
      ) &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.skinInfluencedClothesYouWear.errors
      ) &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.skinAffectedSocialOrLeisureActivities.errors
      ) &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.skinAffectedAnySport.errors
      ) &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.skinPreventedWorkingOrStudyingYesNo.errors
      ) &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.skinPreventedWorkingOrStudying.errors
      ) &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.skinCreatedProblemsWithPartnerFriendsOrFamily
          .errors
      ) &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.skinCausedSexualDifficulties.errors
      ) &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.skinTreatmentBeenAProblem.errors
      )
    );
  }
  hadsFormValid(): boolean {
    return (
      this.hadsForm.dirty &&
      !(
        this.hadsForm.controls && this.hadsForm.controls.stillEnjoyThings.errors
      ) &&
      !(
        this.hadsForm.controls &&
        this.hadsForm.controls.seeFunnySideOfThings.errors
      ) &&
      !(this.hadsForm.controls && this.hadsForm.controls.feelCheerful.errors) &&
      !(
        this.hadsForm.controls && this.hadsForm.controls.feelSlowedDown.errors
      ) &&
      !(
        this.hadsForm.controls &&
        this.hadsForm.controls.lostInterestInAppearance.errors
      ) &&
      !(
        this.hadsForm.controls &&
        this.hadsForm.controls.lookForwardWithEnjoyment.errors
      ) &&
      !(
        this.hadsForm.controls &&
        this.hadsForm.controls.enjoyABookRadioOrTv.errors
      ) &&
      !(
        this.hadsForm.controls && this.hadsForm.controls.tenseOrWoundUp.errors
      ) &&
      !(
        this.hadsForm.controls &&
        this.hadsForm.controls.frightenedFeelingAboutSomethingAwful.errors
      ) &&
      !(
        this.hadsForm.controls && this.hadsForm.controls.worryingThoughts.errors
      ) &&
      !(
        this.hadsForm.controls &&
        this.hadsForm.controls.sitAtEaseAndFeelRelaxed.errors
      ) &&
      !(
        this.hadsForm.controls &&
        this.hadsForm.controls.butterfliesInStomachFeeling.errors
      ) &&
      !(
        this.hadsForm.controls && this.hadsForm.controls.feelingRestless.errors
      ) &&
      !(
        this.hadsForm.controls &&
        this.hadsForm.controls.suddenFeelingOfPanic.errors
      )
    );
  }

  setDlqiScore() {
    const dlqiQuestion1 = this.dlqiForm.get(
      'itchySorePainfulOrStingingSkin'
    ).value;
    const dlqiQuestion2 = this.dlqiForm.get('embarrassedOrSelfConscious').value;
    const dlqiQuestion3 = this.dlqiForm.get(
      'interferedWithShoppingHomeOrGarden'
    ).value;
    const dlqiQuestion4 = this.dlqiForm.get(
      'skinInfluencedClothesYouWear'
    ).value;
    const dlqiQuestion5 = this.dlqiForm.get(
      'skinAffectedSocialOrLeisureActivities'
    ).value;
    const dlqiQuestion6 = this.dlqiForm.get('skinAffectedAnySport').value;
    const dlqiQuestion7 = this.dlqiForm.get(
      'skinPreventedWorkingOrStudyingYesNo'
    ).value;
    const dlqiQuestion8 = this.dlqiForm.get(
      'skinPreventedWorkingOrStudying'
    ).value;
    const dlqiQuestion9 = this.dlqiForm.get(
      'skinCreatedProblemsWithPartnerFriendsOrFamily'
    ).value;
    const dlqiQuestion10 = this.dlqiForm.get(
      'skinCausedSexualDifficulties'
    ).value;
    const dlqiQuestion11 = this.dlqiForm.get('skinTreatmentBeenAProblem').value;

    const dlqiTotalScore =
      +dlqiQuestion1.match(/\d+/g) +
      +dlqiQuestion2.match(/\d+/g) +
      +dlqiQuestion3.match(/\d+/g) +
      +dlqiQuestion4.match(/\d+/g) +
      +dlqiQuestion5.match(/\d+/g) +
      +dlqiQuestion6.match(/\d+/g) +
      +dlqiQuestion7.match(/\d+/g) +
      +dlqiQuestion8.match(/\d+/g) +
      +dlqiQuestion9.match(/\d+/g) +
      +dlqiQuestion10.match(/\d+/g) +
      +dlqiQuestion11.match(/\d+/g);

    this.dlqiForm.get('dlqiTotalScore').setValue(dlqiTotalScore);
  }

  setHadsScore() {
    const dQuestion1 = this.hadsForm.get('stillEnjoyThings').value;
    const dQuestion2 = this.hadsForm.get('seeFunnySideOfThings').value;
    const dQuestion3 = this.hadsForm.get('feelCheerful').value;
    const dQuestion4 = this.hadsForm.get('feelSlowedDown').value;
    const dQuestion5 = this.hadsForm.get('lostInterestInAppearance').value;
    const dQuestion6 = this.hadsForm.get('lookForwardWithEnjoyment').value;
    const dQuestion7 = this.hadsForm.get('enjoyABookRadioOrTv').value;

    const depressionTotalScore =
      +dQuestion1.match(/\d+/g) +
      +dQuestion2.match(/\d+/g) +
      +dQuestion3.match(/\d+/g) +
      +dQuestion4.match(/\d+/g) +
      +dQuestion5.match(/\d+/g) +
      +dQuestion6.match(/\d+/g) +
      +dQuestion7.match(/\d+/g);
    this.hadsForm.get('depressionTotalScore').setValue(depressionTotalScore);

    const aQuestion1 = this.hadsForm.get('tenseOrWoundUp').value;
    const aQuestion2 = this.hadsForm.get(
      'frightenedFeelingAboutSomethingAwful'
    ).value;
    const aQuestion3 = this.hadsForm.get('worryingThoughts').value;
    const aQuestion4 = this.hadsForm.get('sitAtEaseAndFeelRelaxed').value;
    const aQuestion5 = this.hadsForm.get('butterfliesInStomachFeeling').value;
    const aQuestion6 = this.hadsForm.get('feelingRestless').value;
    const aQuestion7 = this.hadsForm.get('suddenFeelingOfPanic').value;

    const anxietyTotalScore =
      +aQuestion1.match(/\d+/g) +
      +aQuestion2.match(/\d+/g) +
      +aQuestion3.match(/\d+/g) +
      +aQuestion4.match(/\d+/g) +
      +aQuestion5.match(/\d+/g) +
      +aQuestion6.match(/\d+/g) +
      +aQuestion7.match(/\d+/g);
    this.hadsForm.get('anxietyTotalScore').setValue(anxietyTotalScore);
  }
}
