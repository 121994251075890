import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FormField } from '@islacare/ic-types';

@Component({
  selector: 'ic-automatic-images',
  templateUrl: './automatic-images.component.html',
  styleUrls: ['./automatic-images.component.scss'],
})
export class AutomaticImagesComponent {
  @Input() field: FormField;
  @Input() control: UntypedFormControl;
}
