import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { CaptureType } from '@islacare/ic-types';
import { ButtonModule } from 'primeng/button';
import {
  ReadFile,
  ReadFileService,
} from '../../services/read-file/read-file.service';

@Component({
  standalone: true,
  selector: 'ic-file-selector',
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.scss'],
  imports: [CommonModule, ButtonModule],
})
export class FileSelectorComponent implements OnInit, AfterViewInit {
  @Output() filesArraySnapshotEvent = new EventEmitter<ReadFile[]>();
  @Input() isMobile: boolean;
  @Input() shouldShowAddButton = false;
  @Input() captureType: CaptureType;
  @Input() desktopStylesObj = {};
  @Input() mobileStylesObj = {};
  @Input() isSingleFileUpload: boolean;
  @Input() openFileInputByDefault = true;

  @ViewChild('fileInput') fileInput: ElementRef;

  isHovering: boolean;
  file: File;
  fileType: string;
  video: boolean;
  srcData: SafeResourceUrl;
  fileArray: ReadFile[] = [];

  captureText: string;

  @HostListener('drop', ['$event']) onHoverDrop($event) {
    const files = $event.dataTransfer.files;
    this.readFilesToArray(files);
    this.isHovering = false;
    $event.preventDefault();
  }

  @HostListener('dragover', ['$event']) onDragOver($event) {
    this.isHovering = true;
    $event.preventDefault();
  }

  @HostListener('dragleave', ['$event']) onDragLeave($event) {
    this.isHovering = false;
    $event.preventDefault();
  }

  constructor(private readFileService: ReadFileService) {}

  ngOnInit(): void {
    this.captureText = this.setCaptureText(this.captureType);
  }

  ngAfterViewInit(): void {
    if (this.openFileInputByDefault) this.fileInput?.nativeElement?.click();
  }

  onFileSelect($event) {
    const files = ($event.target as HTMLInputElement).files;
    this.readFilesToArray(files);
  }

  async readFilesToArray(files: FileList) {
    this.fileArray = [];

    const readAllFiles = Array.from(files).map(async (file) => {
      let newFile: File;

      if (file.name.split('.').pop()?.toLocaleLowerCase() === 'e2e') {
        const blob = file.slice(0, file.size);
        newFile = new File([blob], file.name, {
          type: 'file/e2e',
          lastModified: file.lastModified,
        });
      }

      await this.readFileService
        .readFile(newFile || file)
        .then((file) => this.fileArray.push(file));
    });

    Promise.all(readAllFiles).then(() => {
      this.filesArraySnapshotEvent.emit(this.fileArray);
    });
  }

  emitFileArray() {
    this.filesArraySnapshotEvent.emit(this.fileArray);
  }

  private setCaptureText(captureType: CaptureType): string {
    switch (captureType) {
      case CaptureType.PHOTO: {
        return 'photo';
      }
      case CaptureType.VIDEO: {
        return 'video';
      }
      case CaptureType.SOUND_RECORDING: {
        return 'sound recording';
      }
      case CaptureType.FILE_UPLOAD: {
        return 'file';
      }
    }
  }
}
