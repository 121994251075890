<div class="isla-primary-dark pb-3">
  <span class="font-semibold">
    {{ data.patient?.lastName | uppercase }},
    {{ data.patient?.firstName | stringToCapitalisedCase }}
  </span>
</div>
<!-- Date of Birth -->
<div *ngIf="data.patient?.dateOfBirth" class="isla-primary-dark pb-2">
  <span class="font-semibold">Date of birth:</span> {{ data.patient?.dateOfBirth }}
</div>
<!-- NHS Number -->
<div *ngIf="data.patient?.nhs" class="isla-primary-dark pb-2">
  <span class="font-semibold">NHS number:</span> {{ data.patient?.nhs | nhs }}
</div>
<!-- Hospital Number -->
<div *ngIf="data.patient?.localIds[data.user.organisationId]" class="isla-primary-dark pb-2">
  <span class="font-semibold">Hospital number: </span>
  {{ data.patient?.localIds[data.user.organisationId] }}
</div>
<!-- EMIS -->
<div *ngIf="data.patient?.emisPatientCredentials">
  <ng-container>
    <span class="banner-emis-icon-mini">
      <img
        src="./../../../assets/emis-logo.png"
        class="banner-emis-icon-mini"
        alt="emis logo icon"
      />
    </span>
  </ng-container>
</div>
<!-- System One -->
<div *ngIf="data.shouldShowS1Icon">
  <ng-container>
    <span class="s1-icon">
      <img
        src="./../../../assets/systmone-logo-text.png"
        class="banner-s1-icon-mini"
        alt="system one logo icon"
      />
    </span>
  </ng-container>
</div>

<hr class="line-break" />
<div *ngIf="data.patient?.patientContactConsentWithdrawn">
  <span class="font-semibold flex justify-content-end"> Contact consent </span>
  Patient has withdrawn consent to be contacted via Isla
</div>
<div class="isla-primary-dark patient-contact pb-3 py-3">
  <div class="font-semibold pb-2">Patient contact</div>
  <div class="patient-phone pb-2" *ngIf="data.patient?.phone">
    <i class="pi pi-phone"></i>
    {{ data.patient?.phone }}
  </div>
  <div class="patient-email pb-2" *ngIf="data.patient?.email && data.isOrgEmailEnabled">
    <i class="pi pi-envelope"></i>
    <a href="mailto: {{ data.patient?.email }}">
      {{ data.patient?.email }}
    </a>
  </div>
</div>
<div
  class="isla-primary-dark additional-contacts pb-3"
  *ngIf="
    (data.patient?.additionalPhoneNumbers | keyvalue)?.length > 0 ||
    ((data.patient?.additionalEmailAddresses | keyvalue)?.length > 0 && data.isOrgEmailEnabled)
  "
>
  <div class="font-semibold pb-2">Additional contact</div>
  <ng-container *ngFor="let additionalNum of data.patient?.additionalPhoneNumbers | keyvalue">
    <div class="pb-2">
      <i class="pi pi-phone"></i>
      ({{ additionalNum.key.toString() | titlecase }})
      {{ additionalNum.value }}
    </div>
  </ng-container>
  <ng-container *ngIf="data.isOrgEmailEnabled">
    <ng-container *ngFor="let additionalEmail of data.patient?.additionalEmailAddresses | keyvalue">
      <div class="pb-2">
        <i class="pi pi-envelope"></i>
        <span>({{ additionalEmail.key | titlecase }})</span>
        <a href="mailto: {{ additionalEmail.value }}">
          {{ additionalEmail.value }}
        </a>
      </div>
    </ng-container>
  </ng-container>
</div>
<div class="w-full flex flex-row gap-2 justify-content-center">
  <button
    *ngIf="data.isEditButtonVisible"
    pButton
    pRipple
    type="button"
    icon="pi pi-pencil"
    iconPos="left"
    class="p-button-outlined mr-2 mb-2 w-full"
    pTooltip="{{
      data.isEditButtonDisabled
        ? 'You are unable to edit patient details because this patient isn\'t in any of your teams'
        : ''
    }}"
    disabled="{{ data.isEditButtonDisabled }}"
    label="Edit details"
    (click)="editPatientBtn(data.patient?.id)"
  ></button>
</div>
<div class="w-full flex flex-row gap-2 justify-content-center">
  <button
    pButton
    pRipple
    type="button"
    icon="pi pi-arrow-left"
    class="w-full mr-2 mb-2"
    label="Back to patient record"
    (click)="closeDialog()"
  ></button>
</div>
