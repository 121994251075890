import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'ic-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  imports: [CommonModule]
})
export class AvatarComponent {
  @Input() label: string;
  @Input() size = 48;

  getInitials(label: string): string {
    const atIndex = label.indexOf('@');
    if (atIndex === -1) {
      return label.slice(0, 1).toUpperCase();
    }

    const username = label.substring(0, atIndex);
    const parts = username.split('.');
    if (parts.length > 1) {
      return parts.map(part => part[0].toUpperCase()).join('');
    } else {
      return username.slice(0, 1).toUpperCase();
    }
  }

  getBackgroundColor(label: string): string {
    const sanitizedLabel = label.replace(/[^a-zA-Z]/g, '');
    const hash = this.hashCode(sanitizedLabel);
    const color = this.intToRGB(hash);
    return `#${color}`;
  }

  private hashCode(str: string): number {
    let hash = 0;
    if (str.length === 0) {
      return hash;
    }
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash;
    }
    return hash;
  }

  private intToRGB(value: number): string {
    const formattedString = (value & 0x00ffffff).toString(16).toUpperCase();
    return '00000'.substring(0, 6 - formattedString.length) + formattedString;
  }
}
