import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { MenuItem } from 'primeng/api';
import { ClinicalFormsEditService } from '../../../services/clinical-forms-edit/clinical-forms-edit.service';

@Component({
  selector: 'ic-clinical-form-wrapper-section',
  templateUrl: './clinical-form-wrapper-section.component.html'
})
export class ClinicalFormWrapperSectionComponent extends FieldWrapper {
  items: MenuItem[] = [
    {
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: () => this.edit()
    },
    {
      label: 'Add section before',
      icon: 'pi pi-arrow-left',
      styleClass: 'el-bg-primary-subtle',
      command: () => this.addSectionBefore()
    },
    {
      label: 'Add section after',
      icon: 'pi pi-arrow-right',
      styleClass: 'el-bg-primary-subtle',
      command: () => this.addSectionAfter()
    }
  ];

  constructor(private formEditService: ClinicalFormsEditService) {
    super();
  }

  edit() {
    this.formEditService.editSection(this.field);
  }

  addSectionBefore() {
    this.formEditService.addSection(this.field, true);
  }

  addSectionAfter() {
    this.formEditService.addSection(this.field, false);
  }
}
