export default [
  // dopplerForm
  'typeOfDoppler',
  'megahertzUsedManual',
  'dateOfMeasurement',

  'leftBrachialPulse',
  'leftDorsalisPedis',
  'leftPosteriorTibial',
  'leftPeroneal',
  'leftOther',
  'leftToePulse',
  'leftHighestAnklePulse',
  'leftAbpiFoot',
  'leftAbpiToe',

  'rightBrachialPulse',
  'rightDorsalisPedis',
  'rightPosteriorTibial',
  'rightPeroneal',
  'rightOther',
  'rightToePulse',
  'rightHighestAnklePulse',
  'rightAbpiFoot',
  'rightAbpiToe',

  'highestBrachialPressure',
  'nextAssessmentDue',

  'notes'
];
