<form [formGroup]="vulvalClinicForm">
  <div class="form-formatting">
    <h4>VULVAL CLINIC FORM</h4>

    <mat-card>
      We want to share <strong>5 helpful tips </strong>for general vulval skin
      care and are useful for all vulval skin conditions and symptoms. Trying
      this will most likely improve your skin and help your symptoms including
      itching and soreness while you are waiting to see our team.
      <p style="text-decoration: underline">
        <strong>Helpful Tips For Your Vulva To Feel More Comfortable</strong>
      </p>
      <p class="text-align-left">
        <strong>1.</strong> Try to wash your vulva only once a day using an
        emollient wash/washing cream/soap substitute. Any fragrance free
        emollient can be used. This is a list of products to wash with that many
        of our patients find helpful and can be bought over the counter at any
        chemist: Dermol, Doublebase, Diprobase, Cetraben, E45, Oilatum, Balneum,
        Hydromol. Some women find very hot baths make their condition worse.
        Always ‘pat’ your skin dry and try to avoid rubbing it.
      </p>

      <p class="text-align-left">
        <strong>2.</strong> Avoid using wet wipes, soaps, shower gels, and any
        fragranced products, depilatory creams. Products that are marketed as
        ‘inmtimite’ or ‘feminine’ washes can also irritate the skin and should
        eb avoided. Also avoid washing with only water as this dries the skin.
      </p>

      <p class="text-align-left">
        <strong>3.</strong> Apply a barrier ointment to protect the skin from
        contact with urine or faeces. This also reduces chaffing from toilet
        paper when you wipe and rubbing when you are walking and
        exercising/cycling/swimming etc. This is a list of barrier creams and
        ointments that patients find helpful: Hyrdomol ointment, Epaderm
        Ointment, Double base gel, Vaseline, Diprobase cream. These should be
        applied on the genital skin, front and back, at least twice a day. Many
        patients will apply this before and after using the bathroom to protect
        the skin and ease symptoms.
      </p>

      <p class="text-align-left">
        <strong>4.</strong> Sanitary pads can sometimes cause irritation and
        worsen vulval symptoms like itching. If you use sanitary pads for your
        period or for urine leak then please ensure that you are using an
        organic non-bleached cotton pad such as ‘Natracare pads’ or Always
        ‘Cotton range’ (not the regular range) that are available in
        supermarkets and chemist.
      </p>

      <p class="text-align-left">
        <strong>5.</strong> If you experience discomfort during sexual activity
        please consider using lubricants/moisturisers. Lubricants such as YES®
        are recommended, either water or oil based. Please refer to the website
        for more information
        <a
          class="link-style"
          (click)="openUrlInNewTab('https://www.yesyesyes.org/')"
          >www.yesyesyes.org</a
        >
      </p>
    </mat-card>

    <mat-card style="margin-top: 2%">
      <div>
        <div>
          <p class="text-align-left">
            The Vulva is the visible external (outside) part of the female
            genitalia. You might find it helpful to have a look at your own
            vulva with a mirror. You can then look at this basic diagram to
            consider where you may be having the issues that you have been
            referred to our clinic to discuss.
          </p>
          <p class="text-align-left">
            This area of your body includes the outer and inner ‘lips’ of the
            vulva called the labia majora and labia minora.
          </p>
           
          <p class="text-align-left">
            The vestibule is the area which surrounds and contains:
          </p>
          <p class="text-align-left">
            <strong>1. </strong>the opening of the vagina (or introitus) where
            babies are delivered vaginally or where people may have penetrative
            sex (intercourse), and
          </p>
          <p class="text-align-left">
            <strong>2. </strong>the opening of the urethra (where you ‘wee’ or
            urinate from) known as the urethral meatus.
          </p>
          <p class="text-align-left">
            The perineum is the area of skin and tissue extending below the
            vagina to the anus (the opening where you ‘poo’ or defecate from)
          </p>
          <p class="text-align-left">
            Every person’s vulva looks different. It is unique to you. We all
            also have different names for what we call our personal anatomy and
            may describe things differently which is more than acceptable.
          </p>
        </div>
        <div>
          <img
            src="{{ vulvaImageUrl }}"
            alt="vulva image"
            *ngIf="vulvaImageUrl?.length"
            style="height: 50%; width: 40%"
          />
        </div>
      </div>
    </mat-card>
    <mat-card style="margin-top: 2%">
      <p class="text-align-left">
        Before your appointment it would be helpful for the doctors if you could
        complete as many of the following health questions. All questions
        contained in this questionnaire are confidential and will become part of
        your medical record to help us during your consultation. Please complete
        the following form. All fields are mandatory in order to submit. If you
        are not sure about an answer please put 'not applicable' or 'don't
        know'.
      </p>
      <div class="question-container">
        <div class="half-width-field">
          <p>
            What is reason that you believe you have been refered to our vulval
            clinic for?
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-select
            multiple
            formControlName="reasonForBeingReferredToVulvalClinic"
          >
            <mat-option value="Itch">Itch</mat-option>
            <mat-option value="Pain/Soreness">Pain/Soreness</mat-option>
            <mat-option value="Lesions or Lumps">Lesions or Lumps</mat-option>
            <mat-option value="Dryness">Dryness</mat-option>
            <mat-option value="Sexual issues"> Sexual issues</mat-option>
            <mat-option value="Discharge">Discharge</mat-option>
            <mat-option value="Other">Other</mat-option>
            <mat-option value="Not applicable/don't know"
              >Not applicable/don't know</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <br />
      <div class="question-container">
        <div class="full-width-field">
          <p>
            Please tell us more about your vulval symptoms? When it started?
            what has happened since? Any treatments trialed, duration of use,
            source of treatment and if helpful?
          </p>
        </div>
        <mat-form-field appearance="fill" class="full-width-field">
          <textarea
            matInput
            formControlName="vulvalSymptoms"
            type="notes"
          ></textarea>
        </mat-form-field>
      </div>
      <br />
    </mat-card>
    <mat-card style="margin-top: 2%">
      <mat-card-title>GENERAL HEALTH HISTORY</mat-card-title>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Childhood illness</mat-label>
        <mat-select multiple formControlName="childhoodIllness">
          <mat-option value="Eczema">Eczema</mat-option>
          <mat-option value="Asthma">Asthma</mat-option>
          <mat-option value="Other">Other</mat-option>
          <mat-option value="Not applicable/don't know"
            >Not applicable/don't know</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Do you smoke?</mat-label>
        <mat-select formControlName="doYouSmoke">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="question-container">
        <div class="full-width-field">
          <p class="font-weight-500">
            List any medical problems that doctors have diagnosed (including
            skin conditions such as dermatitis, psoriasis or eczema)
          </p>
        </div>
        <ng-container
          *ngTemplateOutlet="
            formArrayTable;
            context: { tableData: medicalProblemsFormArrayData }
          "
        >
        </ng-container>
      </div>
      <div class="question-container">
        <div class="full-width-field">
          <p class="font-weight-500">Surgeries?</p>
        </div>
        <ng-container
          *ngTemplateOutlet="
            formArrayTable;
            context: { tableData: surgeriesFormArrayData }
          "
        >
        </ng-container>
      </div>
      <div class="question-container">
        <div class="full-width-field">
          <p class="font-weight-500">Other hospitalisations?</p>
        </div>
        <ng-container
          *ngTemplateOutlet="
            formArrayTable;
            context: { tableData: otherHospitalisationsFormArrayData }
          "
        >
        </ng-container>
      </div>
      <div class="question-container">
        <div class="full-width-field">
          <p class="font-weight-500">
            List your prescribed medications and any over-the-counter drugs you
            use, such as vitamins, blood pressure tablets, creams ointments or
            inhalers
          </p>
        </div>
        <ng-container
          *ngTemplateOutlet="
            formArrayTable;
            context: { tableData: prescribedMedicationsFormArrayData }
          "
        >
        </ng-container>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>Do you have any allergies to medications?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-select formControlName="anyAllergiesToMedications">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option
              value="No"
              (click)="resetFormArray('listOfAllergicMedications')"
              >No</mat-option
            >
            <mat-option value="Not applicable/don't know"
              >Not applicable/don't know</mat-option
            >
          </mat-select>
        </mat-form-field>
        <ng-container
          *ngIf="
            vulvalClinicForm?.controls?.['anyAllergiesToMedications']?.value ===
            'Yes'
          "
        >
          <div class="full-width-field">
            <p class="font-weight-500">Please list the medication(s):</p>
          </div>
          <ng-container
            *ngTemplateOutlet="
              formArrayTable;
              context: { tableData: allergicMedicationsFormArrayData }
            "
          >
          </ng-container>
        </ng-container>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>
            Do you have any allergies or intolerances to Foods, metals
            (ear-rings, watches etc), dust, pollen or other substances?
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-select formControlName="anyAllergiesToSubstances">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option
              value="No"
              (click)="resetFormArray('listOfAllergicSubstances')"
              >No</mat-option
            >
            <mat-option value="Not applicable/don't know"
              >Not applicable/don't know</mat-option
            >
          </mat-select>
        </mat-form-field>
        <ng-container
          *ngIf="
            vulvalClinicForm?.controls?.['anyAllergiesToSubstances']?.value ===
            'Yes'
          "
        >
          <div class="full-width-field">
            <p class="font-weight-500">Please list the substance(s):</p>
          </div>
          <ng-container
            *ngTemplateOutlet="
              formArrayTable;
              context: { tableData: allergicSubstancesFormArrayData }
            "
          >
          </ng-container>
        </ng-container>
      </div>
    </mat-card>

    <mat-card style="margin-top: 2%">
      <mat-card-title>PERSONAL HYGIENE HABITS</mat-card-title>
      <div class="question-container">
        <div class="half-width-field">
          <p>How often do you wash per day or week?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-select formControlName="washingFrequency">
            <mat-option value="Once a day">Once a day</mat-option>
            <mat-option value="Twice a day">Twice a day</mat-option>
            <mat-option value="More than twice a day"
              >More than twice a day</mat-option
            >
            <mat-option value="Every other day">Every other day</mat-option>
            <mat-option value="Twice a week"> Twice a week</mat-option>
            <mat-option value="Weekly">Weekly</mat-option>
            <mat-option value="Every two weeks">Every two weeks</mat-option>
            <mat-option value="Other">Other</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <br />
      <div class="question-container">
        <div class="half-width-field">
          <p>Do you shower, bath, wash in a sink or other?</p>
        </div>
        <mat-form-field appearance="fill" class="half-width-field">
          <textarea
            matInput
            formControlName="showerBathWashInSinkOrOther"
            type="notes"
          ></textarea>
        </mat-form-field>
      </div>
      <br />
      <div class="question-container">
        <div class="half-width-field">
          <p>What do you use to wash?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-select multiple formControlName="waysOfWashing">
            <mat-option value="Nothing">Nothing</mat-option>
            <mat-option value="Hands">Hands</mat-option>
            <mat-option value="Flannel">Flannel</mat-option>
            <mat-option value="Bath sponge">Bath sponge</mat-option>
            <mat-option value="Wet-wipes"> Wet-wipes</mat-option>
            <mat-option value="Personal feminine wipes"
              >Personal feminine wipes</mat-option
            >
            <mat-option value="Other">Other</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <br />
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Do you wash your vulval region separately to the rest of your body?
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-select formControlName="isVulvalRegionWashedSeparately">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option
              value="No"
              (click)="
                vulvalClinicForm?.controls?.['wayOfWashingVulvalRegionSeparately'].patchValue(
                  ''
                )
              "
              >No
            </mat-option>
          </mat-select>
        </mat-form-field>
        <ng-container
          *ngIf="
            vulvalClinicForm?.controls?.['isVulvalRegionWashedSeparately']
              ?.value === 'Yes'
          "
        >
          <div class="half-width-field">
            <p>How do you wash this area?</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <textarea
              matInput
              formControlName="wayOfWashingVulvalRegionSeparately"
              type="notes"
            ></textarea>
          </mat-form-field>
        </ng-container>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>
            Do you use or have you used any other substances or products on your
            vulval/genital regions to help your symptoms?
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-select formControlName="anyOtherSubstancesUsedToHelpSymptoms">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option
              value="No"
              (click)="resetFormArray('listOfSubstancesUsedToHelpSymptoms')"
              >No
            </mat-option>
          </mat-select>
        </mat-form-field>
        <ng-container
          *ngIf="
            vulvalClinicForm?.controls?.['anyOtherSubstancesUsedToHelpSymptoms']
              ?.value === 'Yes'
          "
        >
          <div class="full-width-field">
            <p class="font-weight-500">Please list the substance(s):</p>
          </div>
          <ng-container
            *ngTemplateOutlet="
              formArrayTable;
              context: { tableData: substancesUsedToHelpSymptomsFormArrayData }
            "
          >
          </ng-container>
        </ng-container>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>
            Do you use any sanitary protection (sanitary towels, incontinence
            pads/pants or panty liners)?
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-select formControlName="useOfAnySanitaryProtection">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option
              value="No"
              (click)="
                resetFormArray('listOfProductsUsedForSanitaryProtection')
              "
              >No
            </mat-option>
          </mat-select>
        </mat-form-field>
        <ng-container
          *ngIf="
            vulvalClinicForm?.controls?.['useOfAnySanitaryProtection']?.value ===
            'Yes'
          "
        >
          <div class="full-width-field">
            <p class="font-weight-500">
              Please list the type of product(s) used:
            </p>
          </div>
          <ng-container
            *ngTemplateOutlet="
              formArrayTable;
              context: { tableData: sanitaryProtectionFormArrayData }
            "
          >
          </ng-container>
        </ng-container>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>Do you have sexual intercourse?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-select formControlName="sexualIntercourse">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option
              value="No"
              (click)="resetFormArray('listOfProductsUsedOrTried')"
              >No</mat-option
            >
          </mat-select>
        </mat-form-field>
        <ng-container
          *ngIf="vulvalClinicForm?.controls?.['sexualIntercourse']?.value === 'Yes'"
        >
          <div class="full-width-field">
            <p class="font-weight-500">
              Please list the type of lubrication or contraceptive product(s)
              used or tried:
            </p>
          </div>
          <ng-container
            *ngTemplateOutlet="
              formArrayTable;
              context: { tableData: productsUsedFormArrayData }
            "
          >
          </ng-container>
        </ng-container>
      </div>
    </mat-card>

    <mat-card style="margin-top: 2%">
      <mat-card-title>FAMILY HEALTH HISTORY</mat-card-title>

      <div class="question-container">
        <div class="half-width-field">
          <p>Has anyone in your family been diagnosed with skin cancer?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-select formControlName="anyFamilyMemberDiagnosedWithSkinCancer">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
            <mat-option value="Not applicable/don't know"
              >Not applicable/don't know</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <br />

      <div class="question-container">
        <div class="half-width-field">
          <p>
            Has anyone in your family been diagnosed with female cancers
            (breast/womb/ovary/vulval)?
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-select
            formControlName="anyFamilyMemberDiagnosedWithFemaleCancer"
          >
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
            <mat-option value="Not applicable/don't know"
              >Not applicable/don't know</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <br />

      <div class="question-container">
        <div class="half-width-field">
          <p>Does anyone in the family have similar vulval symptoms to you?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-select
            formControlName="anyFamilyMemberHaveSimilarVulvalSymptoms"
          >
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
            <mat-option value="Not applicable/don't know"
              >Not applicable/don't know</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <br />

      <div class="question-container">
        <div class="half-width-field">
          <p>
            Does anyone in the family have autoimmune diseases
            (Diabetes/thyroid/arthritis etc)
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-select
            formControlName="anyFamilyMemberDiagnosedWithAutoimmuneDisease"
          >
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
            <mat-option value="Not applicable/don't know"
              >Not applicable/don't know</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <br />

      <div class="question-container">
        <div class="half-width-field">
          <p>
            Anything else you would like to tell us about your family history?
          </p>
        </div>
        <mat-form-field appearance="fill" class="half-width-field">
          <textarea
            matInput
            formControlName="detailsAboutFamilyHistory"
            type="notes"
          ></textarea>
        </mat-form-field>
      </div>
      <br />
    </mat-card>

    <mat-card style="margin-top: 2%">
      <mat-card-title>WOMEN'S HEALTH HISTORY</mat-card-title>

      <div class="question-container">
        <div class="half-width-field">
          <p>Age you had your first period:</p>
        </div>
        <mat-form-field appearance="fill" class="half-width-field">
          <input matInput type="text" formControlName="ageAtFirstPeriod" />
        </mat-form-field>
      </div>
      <br />

      <div class="question-container">
        <div class="half-width-field">
          <p>Date (or age) of your last period:</p>
        </div>
        <mat-form-field appearance="fill" class="half-width-field">
          <input matInput type="text" formControlName="dateOrAgeOfLastPeriod" />
        </mat-form-field>
      </div>
      <br />

      <div class="question-container">
        <div class="half-width-field">
          <p>Are you still having periods?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-select formControlName="stillHavingPeriods">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No" (click)="resetPeriodFields()">No</mat-option>
            <mat-option value="Not applicable/don't know"
              >Not applicable/don't know</mat-option
            >
          </mat-select>
        </mat-form-field>
        <ng-container
          *ngIf="
            vulvalClinicForm?.controls?.['stillHavingPeriods']?.value === 'Yes'
          "
        >
          <div class="full-width-field">
            <p style="text-align: left">
              My period occurs every
              <mat-form-field class="half-width-field" appearance="outline">
                <input
                  matInput
                  type="number"
                  formControlName="noOfDaysAfterWhichPeriodOccurs"
                />
              </mat-form-field>
              days, and lasts for
              <mat-form-field class="half-width-field" appearance="outline">
                <input
                  matInput
                  type="number"
                  formControlName="noOfDaysForWhichPeriodLasts"
                />
              </mat-form-field>
              days.
            </p>
          </div>
          <div class="half-width-field">
            <p>Heavy periods, irregularity, spotting, pain, or discharge?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select
              formControlName="heavyPeriodsOrIrregularityOrSpottingOrPainOrDischarge"
            >
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>Number of pregnancies?</p>
        </div>
        <mat-form-field appearance="fill" class="half-width-field">
          <input matInput type="text" formControlName="noOfPregnancies" />
        </mat-form-field>
      </div>
      <br />

      <div class="question-container">
        <div class="half-width-field">
          <p>Number of live births?</p>
        </div>
        <mat-form-field appearance="fill" class="half-width-field">
          <input matInput type="text" formControlName="noOfLiveBirths" />
        </mat-form-field>
      </div>
      <br />

      <div class="question-container">
        <div class="half-width-field">
          <p>Are you pregnant or breastfeeding?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-select formControlName="pregnantOrBreastFeeding">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
            <mat-option value="Not applicable/don't know"
              >Not applicable/don't know</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <br />

      <div class="question-container">
        <div class="half-width-field">
          <p>Have you had a D&C, hysterectomy, or Caesarean?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-select formControlName="hadHysterectomyOrCaesarean">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
            <mat-option value="Not applicable/don't know"
              >Not applicable/don't know</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <br />

      <div class="question-container">
        <div class="half-width-field">
          <p>
            Any urinary tract, bladder, or kidney infections within the last
            year?
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-select
            formControlName="urinaryTractOrBladderOrKidneyInfectionsWithinLastYear"
          >
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
            <mat-option value="Not applicable/don't know"
              >Not applicable/don't know</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <br />

      <div class="question-container">
        <div class="half-width-field">
          <p>Any blood in your urine?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-select formControlName="bloodInUrine">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
            <mat-option value="Not applicable/don't know"
              >Not applicable/don't know</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <br />

      <div class="question-container">
        <div class="half-width-field">
          <p>Any problems with control of urination?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-select formControlName="problemsWithControlOfUrination">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
            <mat-option value="Not applicable/don't know"
              >Not applicable/don't know</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <br />

      <div class="question-container">
        <div class="half-width-field">
          <p>Any hot flashes/flushes or sweating at night?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-select formControlName="hotFlashesOrSweatingAtNight">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
            <mat-option value="Not applicable/don't know"
              >Not applicable/don't know</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <br />

      <div class="question-container">
        <div class="half-width-field">
          <p>
            Do you have menstrual tension, pain, bloating, irritability, or
            other symptoms at or around time of period?
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-select formControlName="anySymptomsAtOrAroundPeriodTime">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
            <mat-option value="Not applicable/don't know"
              >Not applicable/don't know</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <br />

      <div class="question-container">
        <div class="half-width-field">
          <p>Date of or age at last smear test?</p>
        </div>
        <mat-form-field appearance="fill" class="half-width-field">
          <input
            matInput
            type="text"
            formControlName="dateOrAgeAtLastSmearTest"
          />
        </mat-form-field>
      </div>
      <br />

      <div class="question-container">
        <div class="half-width-field">
          <p>Have you had any abnormal smear tests in the past?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-select formControlName="anyAbnormalSmearTestsInPast">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
            <mat-option value="Not applicable/don't know"
              >Not applicable/don't know</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <br />
    </mat-card>

    <button
      mat-raised-button
      class="positive-button"
      [disabled]="isSubmitting || !vulvalClinicForm.valid"
      (click)="onSubmit()"
    >
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>

  <ng-template #formArrayTable let-tableData="tableData">
    <div style="overflow-x: auto; width: 100%">
      <table
        mat-table
        [dataSource]="tableData?.dataSource"
        [formArrayName]="tableData?.formArrayName"
        class="full-width-table margin-bottom-5"
        [icHash]="tableData?.formArrayName"
      >
        <!-- Row definitions -->
        <tr mat-header-row *matHeaderRowDef="tableData?.columns"></tr>
        <tr
          mat-row
          *matRowDef="let row; let index = index; columns: tableData?.columns"
        ></tr>
        <tr
          mat-footer-row
          *matFooterRowDef="tableData?.columns"
          style="background: #d3d3d373"
        ></tr>

        <!-- Column definitions -->
        <ng-container *ngFor="let column of tableData?.columns">
          <ng-container [matColumnDef]="column" *ngIf="column !== 'delete'">
            <th mat-header-cell *matHeaderCellDef class="table-header-cell">
              {{ column | camelCaseToTitleCase }}
            </th>
            <td
              mat-cell
              *matCellDef="let row; let i = index"
              [formGroupName]="i"
            >
              <mat-form-field appearance="fill">
                <input
                  matInput
                  [type]="tableData?.inputType[column] || 'text'"
                  [formControlName]="column"
                />
              </mat-form-field>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container [matColumnDef]="column" *ngIf="column === 'delete'">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="table-header-cell"
              style="text-align: right"
            >
              {{ column | camelCaseToTitleCase }}
            </th>
            <td mat-cell *matCellDef="let row; let i = index">
              <mat-icon
                style="color: #ef7715; cursor: pointer; float: right"
                title="Delete this row"
                (click)="deleteForm(tableData?.formArrayName, i)"
                >delete</mat-icon
              >
            </td>
            <td mat-footer-cell *matFooterCellDef>
              <button
                mat-raised-button
                class="positive-button"
                [id]="tableData?.formArrayName + 'add-button'"
                (click)="addForm(tableData?.formArrayName, tableData?.form)"
                style="float: right"
              >
                <mat-icon>add</mat-icon>Add Record
              </button>
            </td>
          </ng-container>
        </ng-container>
      </table>
    </div>
  </ng-template>
</form>
