import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-cchq-form',
  templateUrl: './cchq-form.component.html',
  styleUrls: ['./cchq-form.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
})
export class CCHQFormComponent implements OnInit {
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  cchqForm: UntypedFormGroup;
  isSubmitting = false;

  cchqResponsesVeryEasyImpossible = [
    '0 - N/A',
    '1 - Very Easy',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7 - Impossible',
  ];
  cchqResponsesNeverAlways = [
    '0 - N/A',
    '1 - Never',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7 - Always',
  ];
  cchqResponsesAlwaysNever = [
    '0 - N/A',
    '1 - Always',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7 - Never',
  ];
  cchqResponsesNeverSoaked = [
    '0 - N/A',
    '1 - Never',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7 - Soaked',
  ];
  cchqResponsesOutstandingAwful = [
    '0 - N/A',
    '1 - Outstanding',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7 - Awful',
  ];
  cchqResponsesHappyUnhappy = [
    '0 - N/A',
    '1 - Very Happy',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7 - Very Unhappy',
  ];
  cchqResponsesChange = ['Better', 'No Change', 'Worse', 'N/A'];

  ngOnInit(): void {
    this.cchqForm = this.fb.group({
      personCompletingForm: ['', [Validators.required]],

      puttingOnTrousers: ['0', [Validators.required]],
      takingOffTrousers: ['0', [Validators.required]],
      puttingOnShirt: ['0', [Validators.required]],
      changingDiapers: ['0', [Validators.required]],
      easeOfSittingOnAToiletSeat: ['0', [Validators.required]],
      easeOfSittingInABathtubWithOrWithoutAdaptiveEquipment: [
        '0',
        [Validators.required],
      ],
      easeOfBathing: ['0', [Validators.required]],
      easeOfSelfFeeding: ['0', [Validators.required]],
      easeOfFeeding: ['0', [Validators.required]],

      easeOfPositioningInAWheelchair: ['0', [Validators.required]],
      easeOfPositioningInOtherDeviceEgStandingFrame: [
        '0',
        [Validators.required],
      ],
      easeOfTransferringInAndOutOfAWheelchair: ['0', [Validators.required]],
      easeOfEaseOfPuttingOnOrthosesOrPositioningDevices: [
        '0',
        [Validators.required],
      ],
      easeOfControllingWheelchair: ['0', [Validators.required]],
      easeOfGettingOutOfACar: ['0', [Validators.required]],
      easeOfGettingInACar: ['0', [Validators.required]],

      painOrDiscomfortDuringPositionChanges: ['0', [Validators.required]],
      painOrDiscomfortDuringDiaperChanges: ['0', [Validators.required]],
      painOrDiscomfortPreventsParticipationInSchoolVariousProgramsOrOtherActivities:
        ['0', [Validators.required]],
      usingPainControlMedicine: ['0', [Validators.required]],
      sleepsThroughTheNight: ['0', [Validators.required]],

      easeOfExtendingArmsToReachCommunicationDevices: [
        '0',
        [Validators.required],
      ],
      easeOfPlayingAlone: ['0', [Validators.required]],
      easeOfPlayingWithOtherChildren: ['', [Validators.required]],
      easeOfBeingCompletelyUnderstoodByThoseWhoKnowThemWell: [
        '0',
        [Validators.required],
      ],
      problemWithDrooling: ['0', [Validators.required]],
      selfEsteem: ['0', [Validators.required]],
      describeChild: ['0', [Validators.required]],

      changeInPersonalCare: ['', [Validators.required]],
      changeInPersonalCareComments: ['', []],
      changeInPositioningOrTransferring: ['', [Validators.required]],
      changeInPositioningOrTransferringComments: ['', []],
      changeInComfort: ['', [Validators.required]],
      changeInComfortComments: ['', []],
      changeInInteractionAndCommunication: ['', [Validators.required]],
      changeInInteractionAndCommunicationComments: ['', []],

      notes: ['', []],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.cchqForm.controls[key])
          this.cchqForm.controls[key].setValue(formData[key]);
      });
    });
  }

  get mainSymptom() {
    return this.cchqForm.get('mainSymptom');
  }

  //Personal Care
  get puttingOnTrousers() {
    //let score = this.cchqForm.get('puttingOnTrousers');
    return this.cchqForm.get('puttingOnTrousers');
  }
  get takingOffTrousers() {
    return this.cchqForm.get('takingOffTrousers');
  }
  get puttingOnShirt() {
    return this.cchqForm.get('puttingOnShirt');
  }
  get changingDiapers() {
    return this.cchqForm.get('changingDiapers');
  }
  get easeOfSittingOnAToiletSeat() {
    return this.cchqForm.get('easeOfSittingOnAToiletSeat');
  }
  get easeOfSittingInABathtubWithOrWithoutAdaptiveEquipment() {
    return this.cchqForm.get(
      'easeOfSittingInABathtubWithOrWithoutAdaptiveEquipment'
    );
  }
  get easeOfBathing() {
    return this.cchqForm.get('easeOfBathing');
  }
  get easeOfSelfFeeding() {
    return this.cchqForm.get('easeOfSelfFeeding');
  }
  get easeOfFeeding() {
    return this.cchqForm.get('easeOfFeeding');
  }
  get averagePersonalCareScore() {
    const result =
      (parseInt(this.puttingOnTrousers.value, 10) +
        parseInt(this.takingOffTrousers.value, 10) +
        parseInt(this.puttingOnShirt.value, 10) +
        parseInt(this.changingDiapers.value, 10) +
        parseInt(this.easeOfSittingOnAToiletSeat.value, 10) +
        parseInt(
          this.easeOfSittingInABathtubWithOrWithoutAdaptiveEquipment.value,
          10
        ) +
        parseInt(this.easeOfBathing.value, 10) +
        parseInt(this.easeOfSelfFeeding.value, 10) +
        parseInt(this.easeOfFeeding.value, 10)) /
      9;

    return result.toFixed(2);
  }

  //Positioning Transferring
  get easeOfPositioningInAWheelchair() {
    return this.cchqForm.get('easeOfPositioningInAWheelchair');
  }
  get easeOfPositioningInOtherDeviceEgStandingFrame() {
    return this.cchqForm.get('easeOfPositioningInOtherDeviceEgStandingFrame');
  }
  get easeOfTransferringInAndOutOfAWheelchair() {
    return this.cchqForm.get('easeOfTransferringInAndOutOfAWheelchair');
  }
  get easeOfEaseOfPuttingOnOrthosesOrPositioningDevices() {
    return this.cchqForm.get(
      'easeOfEaseOfPuttingOnOrthosesOrPositioningDevices'
    );
  }
  get easeOfControllingWheelchair() {
    return this.cchqForm.get('easeOfControllingWheelchair');
  }
  get easeOfGettingOutOfACar() {
    return this.cchqForm.get('easeOfGettingOutOfACar');
  }
  get easeOfGettingInACar() {
    return this.cchqForm.get('easeOfGettingInACar');
  }
  get averagePositioningAndTransferringScore() {
    const result =
      (parseInt(this.easeOfPositioningInAWheelchair.value, 10) +
        parseInt(this.easeOfPositioningInOtherDeviceEgStandingFrame.value, 10) +
        parseInt(this.easeOfTransferringInAndOutOfAWheelchair.value, 10) +
        parseInt(
          this.easeOfEaseOfPuttingOnOrthosesOrPositioningDevices.value,
          10
        ) +
        parseInt(this.easeOfControllingWheelchair.value, 10) +
        parseInt(this.easeOfGettingOutOfACar.value, 10) +
        parseInt(this.easeOfGettingInACar.value, 10)) /
      7;

    return result.toFixed(2);
  }

  //comfort
  get painOrDiscomfortDuringPositionChanges() {
    return this.cchqForm.get('painOrDiscomfortDuringPositionChanges');
  }
  get painOrDiscomfortDuringDiaperChanges() {
    return this.cchqForm.get('painOrDiscomfortDuringDiaperChanges');
  }
  get painOrDiscomfortPreventsParticipationInSchoolVariousProgramsOrOtherActivities() {
    return this.cchqForm.get(
      'painOrDiscomfortPreventsParticipationInSchoolVariousProgramsOrOtherActivities'
    );
  }
  get usingPainControlMedicine() {
    return this.cchqForm.get('usingPainControlMedicine');
  }
  get sleepsThroughTheNight() {
    return this.cchqForm.get('sleepsThroughTheNight');
  }
  get averageComfortScore() {
    const result =
      (parseInt(this.painOrDiscomfortDuringPositionChanges.value, 10) +
        parseInt(this.painOrDiscomfortDuringDiaperChanges.value, 10) +
        parseInt(
          this
            .painOrDiscomfortPreventsParticipationInSchoolVariousProgramsOrOtherActivities
            .value,
          10
        ) +
        parseInt(this.usingPainControlMedicine.value, 10) +
        parseInt(this.sleepsThroughTheNight.value, 10)) /
      5;

    return result.toFixed(2);
  }

  //Interaction Communication
  get easeOfExtendingArmsToReachCommunicationDevices() {
    return this.cchqForm.get('easeOfExtendingArmsToReachCommunicationDevices');
  }
  get easeOfPlayingAlone() {
    return this.cchqForm.get('easeOfPlayingAlone');
  }
  get easeOfPlayingWithOtherChildren() {
    return this.cchqForm.get('easeOfPlayingWithOtherChildren');
  }
  get easeOfBeingCompletelyUnderstoodByThoseWhoKnowThemWell() {
    return this.cchqForm.get(
      'easeOfBeingCompletelyUnderstoodByThoseWhoKnowThemWell'
    );
  }
  get problemWithDrooling() {
    return this.cchqForm.get('problemWithDrooling');
  }
  get selfEsteem() {
    return this.cchqForm.get('selfEsteem');
  }
  get describeChild() {
    return this.cchqForm.get('describeChild');
  }
  get averageInteractionAndCommunicationScore() {
    const result =
      (parseInt(this.easeOfExtendingArmsToReachCommunicationDevices.value, 10) +
        parseInt(this.easeOfPlayingAlone.value, 10) +
        parseInt(this.easeOfPlayingWithOtherChildren.value, 10) +
        parseInt(
          this.easeOfBeingCompletelyUnderstoodByThoseWhoKnowThemWell.value,
          10
        ) +
        parseInt(this.problemWithDrooling.value, 10) +
        parseInt(this.selfEsteem.value, 10) +
        parseInt(this.describeChild.value, 10)) /
      7;

    return result.toFixed(2);
  }

  //Total Average for CCHQ Scores

  get overallAverageCchqScores() {
    const result =
      (parseFloat(this.averagePersonalCareScore) +
        parseFloat(this.averagePositioningAndTransferringScore) +
        parseFloat(this.averageComfortScore) +
        parseFloat(this.averageInteractionAndCommunicationScore)) /
      4;
    return result.toFixed(2);
  }

  onSubmit() {
    this.isSubmitting = true;
    const formResponse = {
      ...this.cchqForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
      averagePersonalCareScore: this.averagePersonalCareScore,
      averagePositioningAndTransferringScore:
        this.averagePositioningAndTransferringScore,
      averageComfortScore: this.averageComfortScore,
      averageInteractionAndCommunicationScore:
        this.averageInteractionAndCommunicationScore,
      overallAverageCchqScores: this.overallAverageCchqScores,
    };
    this.form.submit(formResponse);
  }

  valid(): boolean {
    return this.cchqForm.dirty && this.cchqForm.valid && !this.isSubmitting;
  }
}
