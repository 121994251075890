export default [
  'howManyDaysSkinBeenItchy',
  'howManyDaysSleepBeenDisturbed',
  'howManyDaysSkinBeenBleeding',
  'howManyDaysSkinBeenOozingClearFluid',
  'howManyDaysSkinBeenCracked',
  'howManyDaysSkinBeenFlaking',
  'howManyDaysSkinFeltDryOrRough',
  'totalPoemScore',
  'notes'
];
