export default [
  'problemsWithHealing',
  'anyDischargeOrLeakage',
  'dischargeLeakageType',
  'dischargeLeakageOther',
  'additionalSymptoms',
  'sampleTakenFromWound',
  'seenHealthcareWorker',
  'healthcareWorker',
  'healthcareWorkerOther',
  'woundSymptomsDate',
  'beenPrescribedAntibiotics',
  'whoPrescribedThem',
  'readmittedToHospitalDueToInfection',
  'readmittedToHospitalDetails',
  'notes'
];
