import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-burns-scar-form',
  templateUrl: './burns-scar-form.component.html',
  styleUrls: ['./burns-scar-form.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
})
export class BurnsScarFormComponent implements OnInit {
  @Input() data;
  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  burnsScarForm: UntypedFormGroup;
  isSubmitting = false;
  isSensitive: boolean;
  sliderMin = 0;
  sliderMax = 10;
  tickInterval = '1';

  ngOnInit(): void {
    this.burnsScarForm = this.fb.group({
      scarPainful: [''],
      scarItching: [''],
      scarDifferentColour: [''],
      scarStiffness: [''],
      scarThickness: [''],
      scarIrregular: [''],
      scarOpinion: [''],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.burnsScarForm.controls[key])
          this.burnsScarForm.controls[key].setValue(formData[key]);
      });
    });
  }

  onSubmit() {
    this.isSubmitting = true;
    const formResponse = {
      ...this.burnsScarForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }
}
