import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-phq-form',
  templateUrl: './phq-form.component.html',
  styleUrls: ['./phq-form.component.scss'],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class PhqFormComponent implements OnInit {
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  phqForm: UntypedFormGroup;
  isSubmitting = false;

  answerList: string[] = [
    'Not at all - 0',
    'Several days - 1',
    'More than half the days - 2',
    'Nearly every day - 3',
  ];

  ngOnInit(): void {
    this.phqForm = this.fb.group({
      littleInterestOrPleasure: ['', [Validators.required]],
      feelingDownDepressedOrHopeless: ['', [Validators.required]],
      troubleSleeping: ['', [Validators.required]],
      feelingTiredOrLittleEnergy: ['', [Validators.required]],
      poorAppetiteOrOvereating: ['', [Validators.required]],
      feelingBadAboutYourself: ['', [Validators.required]],
      troubleConcentratingOnThings: ['', [Validators.required]],
      movingOrSpeakingSlowerOrFasterThanNormal: ['', [Validators.required]],
      negativeThoughtsTowardsYourself: ['', [Validators.required]],
      howDifficultHaveTheseProblemsMadeThings: ['', [Validators.required]],
      phqTotalScore: [''],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.phqForm.controls[key])
          this.phqForm.controls[key].setValue(formData[key]);
      });
    });
  }

  // PHQ
  get littleInterestOrPleasure() {
    return this.phqForm.get('littleInterestOrPleasure');
  }
  get feelingDownDepressedOrHopeless() {
    return this.phqForm.get('feelingDownDepressedOrHopeless');
  }
  get troubleSleeping() {
    return this.phqForm.get('troubleSleeping');
  }
  get feelingTiredOrLittleEnergy() {
    return this.phqForm.get('feelingTiredOrLittleEnergy');
  }
  get poorAppetiteOrOvereating() {
    return this.phqForm.get('poorAppetiteOrOvereating');
  }
  get feelingBadAboutYourself() {
    return this.phqForm.get('feelingBadAboutYourself');
  }
  get troubleConcentratingOnThings() {
    return this.phqForm.get('troubleConcentratingOnThings');
  }
  get movingOrSpeakingSlowerOrFasterThanNormal() {
    return this.phqForm.get('movingOrSpeakingSlowerOrFasterThanNormal');
  }
  get negativeThoughtsTowardsYourself() {
    return this.phqForm.get('negativeThoughtsTowardsYourself');
  }
  get howDifficultHaveTheseProblemsMadeThings() {
    return this.phqForm.get('howDifficultHaveTheseProblemsMadeThings');
  }

  onSubmit() {
    if (!this.phqFormValid()) return;
    this.setPhqScore();

    this.isSubmitting = true;
    const formResponse = {
      ...this.phqForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
      showOnChart: true,
    };
    this.form.submit(formResponse);
  }

  phqFormValid(): boolean {
    return (
      this.phqForm.dirty &&
      !(
        this.phqForm.controls &&
        this.phqForm.controls['littleInterestOrPleasure'].errors
      ) &&
      !(
        this.phqForm.controls &&
        this.phqForm.controls['feelingDownDepressedOrHopeless'].errors
      ) &&
      !(
        this.phqForm.controls && this.phqForm.controls['troubleSleeping'].errors
      ) &&
      !(
        this.phqForm.controls &&
        this.phqForm.controls['feelingTiredOrLittleEnergy'].errors
      ) &&
      !(
        this.phqForm.controls &&
        this.phqForm.controls['poorAppetiteOrOvereating'].errors
      ) &&
      !(
        this.phqForm.controls &&
        this.phqForm.controls['feelingBadAboutYourself'].errors
      ) &&
      !(
        this.phqForm.controls &&
        this.phqForm.controls['troubleConcentratingOnThings'].errors
      ) &&
      !(
        this.phqForm.controls &&
        this.phqForm.controls['movingOrSpeakingSlowerOrFasterThanNormal'].errors
      ) &&
      !(
        this.phqForm.controls &&
        this.phqForm.controls['negativeThoughtsTowardsYourself'].errors
      ) &&
      !(
        this.phqForm.controls &&
        this.phqForm.controls['howDifficultHaveTheseProblemsMadeThings'].errors
      )
    );
  }

  setPhqScore() {
    const phqQuestion1 = this.phqForm.get('littleInterestOrPleasure').value;
    const phqQuestion2 = this.phqForm.get(
      'feelingDownDepressedOrHopeless'
    ).value;
    const phqQuestion3 = this.phqForm.get('troubleSleeping').value;
    const phqQuestion4 = this.phqForm.get('feelingTiredOrLittleEnergy').value;
    const phqQuestion5 = this.phqForm.get('poorAppetiteOrOvereating').value;
    const phqQuestion6 = this.phqForm.get('feelingBadAboutYourself').value;
    const phqQuestion7 = this.phqForm.get('troubleConcentratingOnThings').value;
    const phqQuestion8 = this.phqForm.get(
      'movingOrSpeakingSlowerOrFasterThanNormal'
    ).value;
    const phqQuestion9 = this.phqForm.get(
      'negativeThoughtsTowardsYourself'
    ).value;

    const phqTotalScore =
      +phqQuestion1.match(/\d+/g) +
      +phqQuestion2.match(/\d+/g) +
      +phqQuestion3.match(/\d+/g) +
      +phqQuestion4.match(/\d+/g) +
      +phqQuestion5.match(/\d+/g) +
      +phqQuestion6.match(/\d+/g) +
      +phqQuestion7.match(/\d+/g) +
      +phqQuestion8.match(/\d+/g) +
      +phqQuestion9.match(/\d+/g);

    this.phqForm.get('phqTotalScore').setValue(phqTotalScore);
  }
}
