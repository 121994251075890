<form [formGroup]="kccqForm">
  <div class="form-formatting">
    <mat-card>
      <mat-card-content>
        <mat-card-title> The Kansas City Cardiomyopathy Questionnaire (KCCQ) </mat-card-title>
        <p>
          The following questions refer to your <strong>heart failure</strong> and how it may affect
          your life. Please read and complete the following questions. There are no right or wrong
          answers. Please mark the answer that best applies to you.
        </p>
      </mat-card-content>
    </mat-card>

    <br />

    <mat-card>
      <mat-card-content>
        <p>
          <strong>1. Heart failure</strong> affects different people in different ways. Some feel
          shortness of breath while others feel fatigue. Please indicate how much you are limited by
          heart failure (shortness of breath or fatigue) in your ability to do the following
          activities over the past 2 weeks.
        </p>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>a. Dressing yourself:</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="dressingYourself">
              <div *ngFor="let response of kccqQuestion1Response">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>b. Showering/bathing:</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="showeringOrBathing">
              <div *ngFor="let response of kccqQuestion1Response">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>c. Walking 1 block on level ground:</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="walking1Block">
              <div *ngFor="let response of kccqQuestion1Response">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>d. Doing yard work, housework or carrying groceries:</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="doingHouseworkOrCarryingGroceries">
              <div *ngFor="let response of kccqQuestion1Response">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>e. Climbing a flight of stairs without stopping:</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="climbingAFlightOfStairsWithoutStopping">
              <div *ngFor="let response of kccqQuestion1Response">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>f. Hurrying or jogging (as if to catch a bus):</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="HurryingOrJogging">
              <div *ngFor="let response of kccqQuestion1Response">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <p>
          <strong>2. Compared with 2 weeks ago</strong>, have your symptoms of
          <strong>heart failure</strong> (shortness of breath, fatigue or ankle swelling) changed?
          My symptoms of <strong>heart failure</strong> have become…
        </p>

        <div class="question-container">
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="symptomsOfHeartFailureComparedTo2WeeksAGo">
              <div *ngFor="let response of kccqQuestion2Response">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <p>
          <strong>3. Over the past 2 weeks</strong>, how many times did you have
          <strong>swelling</strong> in your feet, ankles or legs when you woke up in the morning?
        </p>

        <div class="question-container">
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="frequencyOfSwellingInFeetOrLegsOverPast2Weeks">
              <div *ngFor="let response of kccqQuestion3Response">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <p>
          <strong>4. Over the past 2 weeks</strong>, how much has <strong>swelling</strong> in your
          feet, ankles or legs bothered you? It has been…
        </p>

        <div class="question-container">
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="howMuchHasTheSwellingBotheredYou">
              <div *ngFor="let response of kccqQuestion4Response">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <p>
          <strong>5. Over the past 2 weeks</strong>, on average, how many times has
          <strong>fatigue</strong> limited your ability to do what you want?
        </p>

        <div class="question-container">
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="howMuchHasFatigueLimitYourAbilities">
              <div *ngFor="let response of kccqQuestion5Response">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <p>
          <strong>6. Over the past 2 weeks</strong>, how much has fatigue bothered you? It has been…
        </p>

        <div class="question-container">
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="howMuchHasFatigueBotheredYou">
              <div *ngFor="let response of kccqQuestion6Response">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <p>
          <strong>7. Over the past 2 weeks</strong>, on average, how many times has shortness of
          breath limited your ability to do what you wanted?
        </p>

        <div class="question-container">
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="howMuchHasShortnessOfBreathLimitYourAbilities">
              <div *ngFor="let response of kccqQuestion7Response">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <p>
          <strong>8. Over the past 2 weeks</strong>, how much has your shortness of breath bothered
          you? It has been…
        </p>

        <div class="question-container">
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="howMuchHasShortnessOfBreathBotheredYou">
              <div *ngFor="let response of kccqQuestion8Response">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <p>
          <strong>9. Over the past 2 weeks</strong>, on average, how many times have you been forced
          to sleep sitting up in a chair or with at least 3 pillows to prop you up because of
          shortness of breath?
        </p>

        <div class="question-container">
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="howMuchHasShortnessOfBreathAffectYourSleeping">
              <div *ngFor="let response of kccqQuestion9Response">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <p>
          <strong>10. Heart Failure</strong> symptoms can worsen for a number of reasons. How sure
          are you that you know what to do, or whom to call, if your
          <strong>heart failure</strong> gets worse?
        </p>

        <div class="question-container">
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="doYouKnowWhoToContactIfHeartFailureSymptomsWorsen">
              <div *ngFor="let response of kccqQuestion10Response">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <p>
          <strong>11.</strong> How well do you understand what things you are able to do to keep
          your heart failure symptoms from getting worse? (for example; weighing yourself, eating a
          low salt diet, etc)
        </p>

        <div class="question-container">
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select
              formControlName="howWellDoYouUnderstandMeasuresToPreventHeartFailureSymptomsFromWorsening"
            >
              <div *ngFor="let response of kccqQuestion11Response">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <p>
          <strong>12. Over the past 2 weeks</strong>, how much has your
          <strong>heart failure</strong> limited your enjoyment of life?
        </p>

        <div class="question-container">
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="howMuchHasYourHeartFailureLimitYourEnjoymentOfLife">
              <div *ngFor="let response of kccqQuestion12Response">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <p>
          <strong>13.</strong> If you had to spend the rest of your life with your
          <strong>heart failure</strong> the way it is right now, how would you feel about this?
        </p>

        <div class="question-container">
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="satisfactionWithLivingWithHeartFailureAtItsCurrentState">
              <div *ngFor="let response of kccqQuestion13Response">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <p>
          <strong>14. Over the past 2 weeks</strong>, how often have you felt discouraged or down in
          the dumps because of your <strong>heart failure</strong>
        </p>

        <div class="question-container">
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="howOftenDoYouFeelDiscouragedDueToYourHeartFailure">
              <div *ngFor="let response of kccqQuestion14Response">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <p>
          <strong>15.</strong> How much does your <strong>heart failure</strong> affect your
          lifestyle? Please indicate how your <strong>heart failure</strong> may have limited your
          participation in the following activities <strong>over the past 2 weeks</strong>
        </p>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>a. Hobbies, recreational activities:</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="hobbiesAndRecreationalActivity">
              <div *ngFor="let response of kccqQuestion15Response">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>b. Working or doing household chores:</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="WorkingOrDoingHouseholdChores">
              <div *ngFor="let response of kccqQuestion15Response">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>c. Visiting family or friends out of your home:</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="VisitingFamilyAndFriendsOutside">
              <div *ngFor="let response of kccqQuestion15Response">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>d. Intimate relationships with loved ones:</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="intimateRelationshipsWithLovedOnes">
              <div *ngFor="let response of kccqQuestion15Response">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-form-field class="full-width-field" appearance="fill">
      <mat-label>Any other comments?</mat-label>
      <textarea matInput formControlName="notes" type="notes"> </textarea>
    </mat-form-field>
    <br />

    <button
      mat-raised-button
      class="positive-button"
      type="submit"
      [disabled]="!valid() || isSubmitting"
      (click)="onSubmit()"
    >
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
