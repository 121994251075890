export default [
  'DLQI',
  'itchySorePainfulOrStingingSkin',
  'embarrassedOrSelfConscious',
  'interferedWithShoppingHomeOrGarden',
  'skinInfluencedClothesYouWear',
  'skinAffectedSocialOrLeisureActivities',
  'skinAffectedAnySport',
  'skinPreventedWorkingOrStudyingYesNo',
  'skinPreventedWorkingOrStudying',
  'skinCreatedProblemsWithPartnerFriendsOrFamily',
  'skinCausedSexualDifficulties',
  'skinTreatmentBeenAProblem',

  'TDLQI',
  'skinConditionMakeYouFeelSelfConscious',
  'skinConditionMakeYouFeelUpset',
  'skinConditionMakeYouFeelThatYouLookDifferent',
  'skinConditionMakeYouFeelThatPeopleStare',
  'skinConditionMakeYouFeelEmbarrassed',
  'skinConditionMakeYouFeelUncomfortableAroundOthers',
  'skinConditionStopYouFromGoingPlaces',
  'feelTheNeedToCoverUpAffectedAreas',

  'skinConditionAffectYourStudiesOrJob',
  'skinConditionWorryYouAboutFutureCareer',
  'skinConditionCausePainOrDiscomfort',
  'skinConditionAffectYourSleep',

  'skinConditionMakeYouAnnoyed',
  'thinkAlotAboutYourSkinCondition',
  'skinConditionMakeYouAvoidMeetingNewPeople',
  'receiveUnfriendlyCommentsAboutYourSkin',
  'skinConditionAffectFriendships',
  'skinConditionAffectInitimateRelationships',

  'IDLQI',
  'howSevereYourChildsDermatitisHasBeen',
  'howMuchHasYourChildBeenItchingAndScratching',
  'howHasYourChildsMoodBeen',
  'howLongHasItTakenToGetYourChildToSleep',
  'averageTimeYourChildsSleepDisturbed',
  'hasYourChildsEczemaInterferedWithPlayingOrSwimming',
  'hasYourChildsEczemaInterferedWithFamilyActivities',
  'hasYourChildsEczemaCausedProblemsAtMealtimes',
  'hasYourChildsEczemaTreatmentCausedProblems',
  'hasYourChildsEczemaUncomfortWhenDressingAndUndressing',
  'hasYourChildsEczemaCausedProblemsAtBathtime',

  'dlqiTotalScore'
];
