import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-pad-pressure-ulcer-form',
  templateUrl: './pad-pressure-ulcer-form.component.html',
  styleUrls: ['./pad-pressure-ulcer-form.component.scss'],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class PadPressureUlcerFormComponent implements OnInit {
  @Input() data;
  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  entryForm: UntypedFormGroup;
  isSubmitting = false;
  isSensitive: boolean;

  ngOnInit(): void {
    this.entryForm = this.fb.group({
      dateOfDischarge: [''],
      site: [''],
      ward: [''],
      consultant: [''],
      staffProvidingPad: ['', [Validators.required]],
      typeOfWound: [''],
      pressureUlcerCategory: [''],
      location: [''],
      length: [''],
      width: [''],
      depth: [''],
      epithelia: [''],
      granulation: [''],
      hypergranulation: [''],
      slough: [''],
      necrotic: [''],
      haematoma: [''],
      boneTendonFascia: [''],
      edges: [''],
      signsOfInfection: [''],
      woundSwabSent: [''],
      antibiotics: [''],
      microbiologyResults: [''],
      recommendedTreatment: [''],
      barrierFilm: [''],
      primaryDressing: [''],
      npwtOptions: [''],
      vacPressure: [''],
      vacMode: [''],
      vacAdjunctDressing: [''],
      secondaryDressing: [''],
      dressingChangeFrequency: [''],
      managingPressureUlcer: [''],
      managementStartDate: [''],
      otherRecommendations: [''],
      sensitiveImage: ['', [Validators.required, Boolean]],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.entryForm.controls[key])
          this.entryForm.controls[key].setValue(formData[key]);
      });
      const sensitive = this.entryForm.get('sensitiveImage').value;
      this.setSensitiveImage(sensitive);
    });
  }

  get staffProvidingPad() {
    return this.entryForm.get('staffProvidingPad');
  }

  setSensitiveImage(status: boolean) {
    if (typeof status === 'string') {
      this.form.setSensitiveImage(false);
      this.isSensitive = false;
    } else if (status || status === false) {
      this.form.setSensitiveImage(status);
      this.isSensitive = status;
    } else {
      this.isSensitive = !this.isSensitive;
      this.form.setSensitiveImage(this.isSensitive);
    }
  }

  onSubmit() {
    if (!this.valid()) return;

    this.isSubmitting = true;
    const formResponse = {
      ...this.entryForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  valid(): boolean {
    return (
      this.entryForm.dirty &&
      !(
        this.entryForm.controls &&
        this.entryForm.controls['staffProvidingPad'].errors
      )
    );
  }
}
