import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ClinicalForm } from '@islacare/ic-types';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { buildSaveClinicalFormDetailsForm } from '../../forms/clinical-form-details.form';

@Component({
  selector: 'ic-save-form-dialog',
  templateUrl: './save-form-dialog.component.html',
  styleUrls: [],
})
export class SaveFormDialogComponent implements OnInit {
  clinicalFormDetailsForm: FormlyFieldConfig[]; // form containing interface ClinicalForm
  clinicalFormDetailsFormGroup = new FormGroup({});
  existingClinicalFormDetails: ClinicalForm;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this.existingClinicalFormDetails =
      this.config.data.existingClinicalFormDetails;
    this.clinicalFormDetailsForm = buildSaveClinicalFormDetailsForm(
      this.config.data.clinicalFormFieldConfig
    );
  }

  onSubmit(): void {
    this.ref.close(this.clinicalFormDetailsFormGroup.value);
  }

  closeDialog(): void {
    this.ref.close();
  }
}
