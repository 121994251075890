import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Timestamp } from '@firebase/firestore-types';
import { TeamResource } from '@islacare/ic-types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class TeamResourcesService {
  constructor(private db: AngularFirestore) {}

  async create(resource: TeamResource, teamId: string, resourceId?: string) {
    if (!resourceId) resourceId = this.db.createId();
    await this.db
      .doc<TeamResource>(`teams/${teamId}/resources/${resourceId}`)
      .set(resource)
      .catch((error) => {
        throw error;
      });
  }

  async update(
    updates: Partial<TeamResource>,
    teamId: string,
    resourceId: string
  ) {
    await this.db
      .doc<TeamResource>(`teams/${teamId}/resources/${resourceId}`)
      .update(updates)
      .catch((error) => {
        throw error;
      });
  }

  getNewResourceId() {
    return this.db.createId();
  }

  getTeamResources$(teamId: string): Observable<TeamResource[]> {
    return this.db
      .collection<TeamResource>(`teams/${teamId}/resources`)
      .valueChanges({ idField: 'id' })
      .pipe(
        map((resources) =>
          resources
            .filter((item) => !item.deleted)
            .sort(
              (item1, item2) =>
                (item2?.lastUpdated as Timestamp)?.seconds -
                (item1?.lastUpdated as Timestamp)?.seconds
            )
        )
      );
  }
}
