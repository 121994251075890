import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';
import { take } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'ic-assessment-and-treatment-consent-form',
  templateUrl: './assessment-and-treatment-consent-form.component.html',
  styleUrls: ['./assessment-and-treatment-consent-form.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
})
export class AssessmentAndTreatmentConsentFormComponent implements OnInit {
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  assessmentAndTreatmentForm: UntypedFormGroup;
  isSubmitting = false;

  ngOnInit(): void {
    this.assessmentAndTreatmentForm = this.fb.group({
      parentOrGuardianName: ['', [Validators.required]],
      parentOrGuardian: ['', [Validators.required]],
      parentOrGuardianSignature: ['', [Validators.required]],
    });

    this.form.retreive$.pipe(take(1)).subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.assessmentAndTreatmentForm.controls[key]) {
          this.assessmentAndTreatmentForm.controls[key].setValue(formData[key]);
        }
      });
    });
  }

  onSubmit() {
    this.isSubmitting = true;
    const formResponse = {
      ...this.assessmentAndTreatmentForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  valid(): boolean {
    return (
      this.assessmentAndTreatmentForm.dirty &&
      this.assessmentAndTreatmentForm.valid &&
      !this.isSubmitting
    );
  }
}
