import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-kccq-form',
  templateUrl: './kccq-form.component.html',
  styleUrls: ['./kccq-form.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
})
export class KCCQFormComponent implements OnInit {
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  kccqForm: UntypedFormGroup;
  isSubmitting = false;

  kccqQuestion1Response = [
    'Extremely Limited',
    'Quite a bit Limited',
    'Moderately Limited',
    'Slightly Limited',
    'Not at all Limited',
    'Limited for other reasons',
    'N/A',
  ];
  kccqQuestion2Response = [
    'Much worse',
    'Slightly worse',
    'Not changed',
    'Slightly better',
    'Much better',
    'No symptoms over the last 2 weeks',
  ];
  kccqQuestion3Response = [
    'Every Morning',
    '3+ times a week, but not every day',
    '1-2 times per week',
    'Less than once a week',
    'Never over the past 2 weeks',
  ];
  kccqQuestion4Response = [
    'Extremely bothersome',
    'Quite a bit bothersome',
    'Moderately bothersome',
    'Slightly bothersome',
    'Not at all bothersome',
    'I have had no swelling',
  ];
  kccqQuestion5Response = [
    'All of the time',
    'Several times a day',
    'At least once a day',
    '3+ times a week, but not every day',
    '1-2 times per week',
    'Less than once a week',
    'Never over the past 2 weeks',
  ];
  kccqQuestion6Response = [
    'Extremely bothersome',
    'Quite a bit bothersome',
    'Moderately bothersome',
    'Slightly bothersome',
    'Not at all bothersome',
    'I have had no fatigue',
  ];
  kccqQuestion7Response = [
    'All of the time',
    '3+ times, several times a day',
    'At least once a day',
    'Once per week but not every day',
    '1-2 times per week',
    'Less than once a week',
    'Never over the past 2 weeks',
  ];
  kccqQuestion8Response = [
    'Extremely bothersome',
    'Quite a bit bothersome',
    'Moderately bothersome',
    'Slightly bothersome',
    'Not at all bothersome',
    'I have had no shotness of breath',
  ];
  kccqQuestion9Response = [
    'Every night',
    '3+ times a week, but not every day',
    '1-2 times per week',
    'Less than once a week',
    'Never over the past 2 weeks',
  ];
  kccqQuestion10Response = [
    'Not at all sure',
    'Not very sure',
    'Somewhat sure',
    'Mostly Sure',
    'Completely Sure',
  ];
  kccqQuestion11Response = [
    'Do not understand at all',
    'Do not understand very well',
    'Somewhat understand',
    'Mostly understand',
    'Completely understand',
  ];
  kccqQuestion12Response = [
    'It has extremely limited my enjoyment of life',
    'It has limited my enjoyment of life quite a bit',
    'It has moderately limited my enjoyment of life',
    'It has slightly limited my enjoyment of life',
    'It has not limited my enjoyment of life at all',
  ];
  kccqQuestion13Response = [
    'Not at all satisfied',
    'Mostly dissatisfied',
    'Somewhat satisfied',
    'Mostly satisfied',
    'Completely satisfied',
  ];
  kccqQuestion14Response = [
    'I felt that way all of the time',
    'I felt that way most of the time',
    'I ocassionally felt that way',
    'I rarely felt that way',
    'I never felt that way',
  ];
  kccqQuestion15Response = [
    'Severely Limited',
    'Limited quite a bit',
    'Moderately limited',
    'Slightly limited',
    'Did not limit at all',
    'Did not do for other reasons',
    'N/A',
  ];

  ngOnInit(): void {
    this.kccqForm = this.fb.group({
      dressingYourself: ['', [Validators.required]],
      showeringOrBathing: ['', [Validators.required]],
      walking1Block: ['', [Validators.required]],
      doingHouseworkOrCarryingGroceries: ['', [Validators.required]],
      climbingAFlightOfStairsWithoutStopping: ['', [Validators.required]],
      HurryingOrJogging: ['', [Validators.required]],

      symptomsOfHeartFailureComparedTo2WeeksAGo: ['', [Validators.required]],
      frequencyOfSwellingInFeetOrLegsOverPast2Weeks: [
        '',
        [Validators.required],
      ],

      howMuchHasTheSwellingBotheredYou: ['', [Validators.required]],
      howMuchHasFatigueLimitYourAbilities: ['', [Validators.required]],

      howMuchHasFatigueBotheredYou: ['', [Validators.required]],
      howMuchHasShortnessOfBreathLimitYourAbilities: [
        '',
        [Validators.required],
      ],

      howMuchHasShortnessOfBreathBotheredYou: ['', [Validators.required]],
      howMuchHasShortnessOfBreathAffectYourSleeping: [
        '',
        [Validators.required],
      ],

      doYouKnowWhoToContactIfHeartFailureSymptomsWorsen: [
        '',
        [Validators.required],
      ],
      howWellDoYouUnderstandMeasuresToPreventHeartFailureSymptomsFromWorsening:
        ['', [Validators.required]],
      howMuchHasYourHeartFailureLimitYourEnjoymentOfLife: [
        '',
        [Validators.required],
      ],
      satisfactionWithLivingWithHeartFailureAtItsCurrentState: [
        '',
        [Validators.required],
      ],
      howOftenDoYouFeelDiscouragedDueToYourHeartFailure: [
        '',
        [Validators.required],
      ],

      hobbiesAndRecreationalActivity: ['', [Validators.required]],
      WorkingOrDoingHouseholdChores: ['', [Validators.required]],
      VisitingFamilyAndFriendsOutside: ['', [Validators.required]],
      intimateRelationshipsWithLovedOnes: ['', [Validators.required]],

      notes: ['', []],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.kccqForm.controls[key])
          this.kccqForm.controls[key].setValue(formData[key]);
      });
    });
  }

  onSubmit() {
    this.isSubmitting = true;
    const formResponse = {
      ...this.kccqForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  valid(): boolean {
    return this.kccqForm.dirty && this.kccqForm.valid && !this.isSubmitting;
  }
}
