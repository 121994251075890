import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService, LoggingService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

const { required } = Validators;

@Component({
  standalone: true,
  selector: 'ic-burns-patient-assessment-form',
  templateUrl: './burns-patient-assessment-form.component.html',
  styleUrls: ['./burns-patient-assessment-form.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
})
export class BurnsPatientAssessmentFormComponent implements OnInit {
  @Input() data;

  constructor(
    private fb: UntypedFormBuilder,
    private form: FormService,
    private log: LoggingService
  ) {}

  entryForm: UntypedFormGroup;
  isSubmitting = false;
  low = true;
  medium = false;
  high = false;
  value: number;
  painDescriptor: string;

  ngOnInit(): void {
    this.entryForm = this.fb.group({
      redness: ['', [required]],
      swelling: ['', [required]],
      painful: ['', [required]],
      painScore: ['', [required]],
      areYouWell: ['', [required]],
      nauseaVomitingOrDiarrhea: ['', [required]],
      notes: ['', []],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.entryForm.controls[key])
          this.entryForm.controls[key].setValue(formData[key]);
      });
    });
  }

  setSliderScore($event) {
    const score = $event.value;
    if (score <= 3) {
      (this.low = true), (this.medium = false), (this.high = false);
    } else if (score <= 7) {
      (this.low = false), (this.medium = true), (this.high = false);
    } else if (score <= 10) {
      (this.low = false), (this.medium = false), (this.high = true);
    }

    if (score === 1) {
      this.painDescriptor = 'No pain';
    } else if (score === 2) {
      this.painDescriptor = 'Mild pain';
    } else if (score === 3) {
      this.painDescriptor = 'Uncomfortable pain';
    } else if (score === 4) {
      this.painDescriptor = 'Moderate pain';
    } else if (score === 5) {
      this.painDescriptor = 'Distracting pain';
    } else if (score === 6) {
      this.painDescriptor = 'Distressing pain';
    } else if (score === 7) {
      this.painDescriptor = 'Unmanageable pain';
    } else if (score === 8) {
      this.painDescriptor = 'Intense pain';
    } else if (score === 9) {
      this.painDescriptor = 'Severe pain';
    } else if (score === 10) {
      this.painDescriptor = 'Unbearable pain';
    }

    this.log.consoleLog(
      `low: ${this.low}, medium: ${this.medium}, high: ${this.high}`
    );
    return this.entryForm.controls.painScore.setValue(score.toString());
  }

  onSubmit() {
    this.isSubmitting = true;
    const formResponse = {
      ...this.entryForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  valid(): boolean {
    return this.entryForm.dirty && this.entryForm.valid && !this.isSubmitting;
  }
}
