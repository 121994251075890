<div class="flex flex-column align-items-center gap-2">
  <webcam
    (imageCapture)="captureMedia($event)"
    (cameraSwitched)="captureReady = true"
    (initError)="setError($event)"
    [videoOptions]="cameraOptions"
    [allowCameraSwitch]="true"
    [hidden]="!captureReady"
    [trigger]="trigger$"
    [imageQuality]="1"
    #webcam
  >
  </webcam>
  <div class="flex mb-2">
    <button
      (click)="triggerSnapshot()"
      class="p-button-info"
      label="Take photo"
      icon="pi pi-camera"
      data-cy="capture-photo-button"
      type="button"
      pButton
    ></button>
  </div>
</div>
