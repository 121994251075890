import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

export enum ToastType {
  Success = 'success',
  Info = 'info',
  Warning = 'warn',
  Error = 'error'
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(private messageService: MessageService) {}

  open(type: ToastType, message: string, summary?: string) {
    const summaryFromType = type == 'warn' ? 'warning' : type;

    this.messageService.add({
      severity: type,
      summary: summary
        ? summary
        : summaryFromType.charAt(0).toUpperCase() + summaryFromType.slice(1),
      detail: message
    });
  }
}
