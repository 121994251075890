<div class="field">
  <form [formGroup]="smsForm">
    <p>
      We have generated a unique
      <a
        [href]="data.message | devAppendPathToCurrentUrl"
        data-cy="patient-submission-link-sms"
        >link</a
      >
      to add to this collection.
    </p>
    <ng-container *ngIf="recipientKeys">
      <ng-container *ngIf="recipientKeys.length > 1">
        <p *ngIf="messageType === MessageType.MANUAL_REMINDER">
          You can only send reminders to the number that the initial request was
          sent to. If you’d like to send to a different number, please send a
          new request
        </p>
        <p *ngIf="messageType !== MessageType.MANUAL_REMINDER">
          Note, there are multiple phone numbers for this patient. Please select
          the person we should contact:
        </p>
      </ng-container>
      <div class="field">
        <label class="font-bold block" for="recipientDropdown"
          >Recipient:</label
        >

        <div class="p-fluid flex gap-2 flex-column md:flex-row">
          <p-dropdown
            name="recipientDropdown"
            formControlName="recipient"
            [options]="data.additionalPhoneNumbers | keyvalue"
            optionValue="key"
            placeholder="Select a recipient"
            [showClear]="false"
            [disabled]="data?.disableRecipient"
            [readonly]="data?.disableRecipient"
          >
            <ng-template let-recipient pTemplate="selectedItem">
              <div>{{ recipient.key }}: ({{ recipient.value }})</div>
            </ng-template>
            <ng-template let-recipient pTemplate="item">
              <div>{{ recipient.key }}: ({{ recipient.value }})</div>
            </ng-template>
          </p-dropdown>
        </div>
      </div>
      <ic-phone-validation-errors
        [control]="smsForm.get('recipient')"
        [name]="getName()"
      ></ic-phone-validation-errors>
      <div class="flex flex-end"></div>
    </ng-container>

    <div class="field m-2 mt-3">
      <p-checkbox
        [formControl]="$any(smsForm).controls['reminder']"
        [binary]="true"
        label="Send a reminder to the patient tomorrow morning if they haven't submitted anything."
      ></p-checkbox>
    </div>

    <div class="field m-2">
      <p-checkbox
        [binary]="true"
        label="Include [Dear Patient]"
        formControlName="includeMessageIntro"
      ></p-checkbox>
    </div>

    <div class="field mb-2">
      <label class="font-bold block" for="smsMessageText"
        >We will share the link at the end of the SMS as below if the phone
        number is valid:</label
      >
      <div class="bg-white border-black-alpha-10 border-round px-4 py-3">
        <p class="mt-0 text-sm">{{ smsForm.controls.messageIntro.value }}</p>
        <textarea
          class="w-full"
          name="smsMessageText"
          pInputTextarea
          formControlName="messageBody"
          rows="5"
          placeholder="message content here..."
        >
        </textarea>
        <small
          *ngIf="
            !smsForm.get('messageBody').valid &&
            smsForm.get('messageBody').touched
          "
          class="p-invalid text-brand"
        >
          A message is required
        </small>
        <p class="mb-0 text-sm">
          {{ smsForm.controls.messageExpiry.value }}
          <span class="text-link font-bold"
            >[unique link will appear here]</span
          >
        </p>
      </div>
    </div>
  </form>
</div>
<div class="flex gap-2 justify-content-end">
  <button
    pButton
    class="p-button-outlined"
    data-cy="smsPatientSubmissionLinkCancel"
    label="Cancel"
    aria-label="Cancel"
    (click)="closeDialog()"
  ></button>
  <button
    label="Yes, send!"
    data-cy="sms-patient-submission"
    aria-label="Send SMS"
    (click)="smsSubmit()"
    [disabled]="
      !smsForm.get('recipient').valid || !smsForm.get('messageBody').valid
    "
    pButton
  ></button>
</div>
