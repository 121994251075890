import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CamelCaseToTitleCasePipe } from '@ic-monorepo/shared-common';
import { SharedModule } from '../shared/shared.module';
import { AutomaticFormComponent } from './automatic-form.component';
import { AutomaticCheckboxComponent } from './components/automatic-checkbox/automatic-checkbox.component';
import { AutomaticDatePickerComponent } from './components/automatic-date-picker/automatic-date-picker.component';
import { AutomaticFormArrayComponent } from './components/automatic-form-array/automatic-form-array.component';
import { AutomaticFormReferenceComponent } from './components/automatic-form-reference/automatic-form-reference.component';
import { AutomaticImageMapComponent } from './components/automatic-image-map/automatic-image-map.component';
import { AutomaticImagesComponent } from './components/automatic-images/automatic-images.component';
import { AutomaticInputComponent } from './components/automatic-input/automatic-input.component';
import { AutomaticMultiSelectComponent } from './components/automatic-multi-select/automatic-multi-select.component';
import { AutomaticRadioComponent } from './components/automatic-radio/automatic-radio.component';
import { AutomaticSingleSelectComponent } from './components/automatic-single-select/automatic-single-select.component';
import { AutomaticSliderComponent } from './components/automatic-slider/automatic-slider.component';
import { AutomaticSubtitleComponent } from './components/automatic-subtitle/automatic-subtitle.component';
import { AutomaticTextareaComponent } from './components/automatic-textarea/automatic-textarea.component';
import { AutomaticTitleComponent } from './components/automatic-title/automatic-title.component';
import { AutomaticToggleComponent } from './components/automatic-toggle/automatic-toggle.component';

const components = [
  AutomaticFormComponent,
  AutomaticFormReferenceComponent,
  AutomaticInputComponent,
  AutomaticTextareaComponent,
  AutomaticSingleSelectComponent,
  AutomaticMultiSelectComponent,
  AutomaticDatePickerComponent,
  AutomaticSliderComponent,
  AutomaticRadioComponent,
  AutomaticToggleComponent,
  AutomaticImagesComponent,
  AutomaticCheckboxComponent,
  AutomaticImageMapComponent,
  AutomaticTitleComponent,
  AutomaticSubtitleComponent,
  AutomaticFormArrayComponent,
];

const modules = [CommonModule, SharedModule];

const libs = [CamelCaseToTitleCasePipe];

@NgModule({
  imports: [...modules, ...libs],
  declarations: [...components],
})
export class AutomaticFormModule {}
