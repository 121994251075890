<form [formGroup]="dn4ScaleForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <div class="full-width-field" style="margin: auto">
      <h4>Does the pain have one or more of the following characteristics?</h4>
    </div>
    <div class="question-container">
      <div class="half-width-field">
        <p>Burning?</p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="painFeelLikeBurning">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
        <mat-error *ngIf="painFeelLikeBurning.touched">Please select an option</mat-error>
      </mat-form-field>
    </div>
    <div class="question-container">
      <div class="half-width-field">
        <p>Painful Cold?</p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="painFeelLikePainfulCold">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
        <mat-error *ngIf="painFeelLikePainfulCold.touched">Please select an option</mat-error>
      </mat-form-field>
    </div>
    <div class="question-container">
      <div class="half-width-field">
        <p>Electric Shocks?</p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="painFeelLikeElectricShocks">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
        <mat-error *ngIf="painFeelLikeElectricShocks.touched">Please select an option</mat-error>
      </mat-form-field>
    </div>
    <div class="full-width-field" style="margin: auto">
      <h4>Is the pain associated with one or more of the following symptoms in the same area?</h4>
    </div>
    <div class="question-container">
      <div class="half-width-field">
        <p>Tingling?</p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="painFeelLikeTingling">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
        <mat-error *ngIf="painFeelLikeTingling.touched">Please select an option</mat-error>
      </mat-form-field>
    </div>
    <div class="question-container">
      <div class="half-width-field">
        <p>Pins and needles?</p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="painFeelLikePinsAndNeedles">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
        <mat-error *ngIf="painFeelLikePinsAndNeedles.touched">Please select an option</mat-error>
      </mat-form-field>
    </div>
    <div class="question-container">
      <div class="half-width-field">
        <p>Numbness?</p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="painFeelLikeNumbness">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
        <mat-error *ngIf="painFeelLikeNumbness.touched">Please select an option</mat-error>
      </mat-form-field>
    </div>
    <div class="question-container">
      <div class="half-width-field">
        <p>Itching?</p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="painFeelLikeItching">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
        <mat-error *ngIf="painFeelLikeItching.touched">Please select an option</mat-error>
      </mat-form-field>
    </div>
    <div>
      <button mat-button style="margin: 10px" mat-raised-button [disabled]="!dn4ScaleFormValid()">
        Submit
      </button>
    </div>
  </div>
</form>
