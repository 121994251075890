import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { FormWithId } from '@islacare/ic-types';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  standalone: true,

  selector: 'ic-pad-adult-cardiac-wound',
  templateUrl: './pad-adult-cardiac-wound-multiple-sites.component.html',
  styleUrls: ['./pad-adult-cardiac-wound-multiple-sites.component.scss'],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class PadAdultCardiacWoundMultipleSitesComponent implements OnInit {
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  entryForm: UntypedFormGroup;
  isSubmitting = false;
  selectedSuturesValue = '';
  legWoundPresent = false;
  todaysDate = new Date();
  daysDiff: number;
  isSensitive: boolean;
  form$: Observable<FormWithId>;

  ngOnInit(): void {
    this.form$ = this.form.getForm(this.data.teamId, this.data.formId);

    this.entryForm = this.fb.group({
      dateOfSurgery: ['', [Validators.required]],
      daysSinceOp: [''],
      // dateOfPostOpSurgery: [''],
      ward: ['', [Validators.required]],
      woundAssessments: this.fb.array([]),
      drainSites: [''],
      drainSitesRemovalDate: [''],
      drainSitesRemovalNeededBy: [''],
      notes: ['', [Validators.maxLength(400)]],
      additionalResources: [''],
      responsibleSurgeon: ['', [Validators.required]],
      additionalComments: [''],
      staffProvidingPad: ['', [Validators.required]],
      consent: ['', [Validators.required]],
      sensitiveImage: ['', [Validators.required]],
    });

    this.form.retreive$.pipe(take(1)).subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.entryForm.controls[key] && key !== 'woundAssessments') {
          this.entryForm.controls[key].setValue(formData[key]);
        }
      });

      const sensitive = this.entryForm.get('sensitiveImage').value;
      this.setSensitiveImage(sensitive);

      for (let i = 0; i < formData['woundAssessments']?.length; i++) {
        this.addWoundAssessment();
      }

      this.woundAssessmentFormCurrent.patchValue(formData['woundAssessments']);
    });

    if (this.woundAssessmentFormCurrent.length === 0) {
      this.addWoundAssessment();
    }
  }

  get woundAssessments(): UntypedFormArray {
    return this.entryForm.get('woundAssessments') as UntypedFormArray;
  }

  get dateOfSurgery() {
    return this.entryForm.get('dateOfSurgery');
  }

  get ward() {
    return this.entryForm.get('ward');
  }

  get notes() {
    return this.entryForm.get('notes');
  }
  get responsibleSurgeon() {
    return this.entryForm.get('responsibleSurgeon');
  }
  get staffProvidingPad() {
    return this.entryForm.get('staffProvidingPad');
  }
  get consent() {
    return this.entryForm.get('consent');
  }

  daysSinceOp() {
    const surgeryDate = new Date(this.dateOfSurgery.value);
    const today = this.todaysDate;

    const timeDiff = today.getTime() - surgeryDate.getTime();

    this.daysDiff = Math.trunc(timeDiff / (1000 * 3600 * 24));
    this.entryForm.get('daysSinceOp').setValue(String(this.daysDiff));
  }

  setLegWoundPresent(legWoundResponse) {
    this.legWoundPresent = legWoundResponse;

    // Sets value back to none and hides the date fields when lag wound response is set back to no
    if (legWoundResponse === false) {
      this.entryForm.get('legWoundCabg').setValue('None'); // sets default to none
      this.entryForm.get('armWoundCabg').setValue('None'); // sets default to none
      this.entryForm.get('groinWoundCabg').setValue('None'); // sets default to none
    }
  }

  onSubmit() {
    if (!this.valid()) return;

    this.isSubmitting = true;
    const formResponse = {
      ...this.entryForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  setSensitiveImage(status: boolean) {
    if (typeof status === 'string') {
      this.form.setSensitiveImage(false);
      this.isSensitive = false;
    } else if (status || status === false) {
      this.form.setSensitiveImage(status);
      this.isSensitive = status;
    } else {
      this.isSensitive = !this.isSensitive;
      this.form.setSensitiveImage(this.isSensitive);
    }
  }

  valid(): boolean {
    return (
      this.entryForm.dirty &&
      !(
        this.entryForm.controls &&
        this.entryForm.controls['dateOfSurgery'].errors
      ) &&
      !(this.entryForm.controls && this.entryForm.controls['ward'].errors) &&
      !(this.entryForm.controls && this.entryForm.controls['notes'].errors) &&
      !(
        this.entryForm.controls &&
        this.entryForm.controls['responsibleSurgeon'].errors
      ) &&
      !(
        this.entryForm.controls &&
        this.entryForm.controls['staffProvidingPad'].errors
      ) &&
      !(this.entryForm.controls && this.entryForm.controls['consent'].errors) &&
      !this.isSubmitting
    );
  }

  get woundAssessmentFormCurrent() {
    return this.entryForm.get('woundAssessments') as UntypedFormArray;
  }

  addWoundAssessment() {
    const assessment = this.fb.group({
      surgicalWound: ['', [Validators.required]],
      woundClosureMaterial: ['', [Validators.required]],
      dateOfRemoval: [''],
      removalNeededBy: [''],
      surgicalWoundAssessment: ['', [Validators.required]],
      exudateAmount: [''],
      surgicalWoundAssessmentOther: [''],
      microBiologyResults: [''],
      drainSites: [''],
      drainSitesRemovalDate: [''],
      drainSitesRemovalNeededBy: [''],
    });
    this.woundAssessmentFormCurrent.push(assessment);
  }

  deleteWoundAssessment(i) {
    this.woundAssessmentFormCurrent.removeAt(i);
  }
}
