<ng-container *ngIf="field && control">
  <div class="question-container" *ngIf="field?.title">
    <ic-automatic-title
      [field]="field"
      class="half-width-field"
    ></ic-automatic-title>
    <ng-container *ngTemplateOutlet="multiSelectField"></ng-container>
  </div>
  <ng-container *ngIf="!field?.title">
    <ng-container *ngTemplateOutlet="multiSelectField"></ng-container>
  </ng-container>

  <ng-template #multiSelectField>
    <mat-form-field appearance="fill" class="half-width-field">
      <mat-label *ngIf="field?.subTitle"
        ><ic-automatic-subtitle [field]="field"></ic-automatic-subtitle
      ></mat-label>

      <mat-select
        multiple
        [formControl]="control"
        *ngIf="field?.inputType === UserInputType.MULTI_SELECT"
      >
        <mat-option
          *ngFor="let option of field?.options"
          [value]="option?.label"
          [id]="field?.controlName + '-' + option?.label"
        >
          {{ option?.label }}</mat-option
        >
      </mat-select>

      <mat-error *ngIf="control?.touched && control?.invalid">
        {{ formService?.getErrorMessage(control) }}
      </mat-error>
    </mat-form-field>
  </ng-template>
</ng-container>
