import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-lothian-follow-up-form',
  templateUrl: './lothian-follow-up-form.component.html',
  styleUrls: ['./lothian-follow-up-form.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
})
export class LothianFollowUpFormComponent implements OnInit {
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  lothianForm: UntypedFormGroup;
  isSubmitting = false;
  sliderMin = 0;
  sliderMax = 100;

  answerList: string[] = ['A little', 'Quite a bit', 'A lot'];

  ngOnInit(): void {
    this.lothianForm = this.fb.group({
      woundSymptoms: ['', Validators.required],
      rednessSpreadingAwayFromWound: [''],
      woundAreaWarmerThanSurroundingSkin: [''],
      woundAreaBecomeSwollen: [''],
      woundLeakedClearFluid: [''],
      woundLeakedBloodStainedFluid: [''],
      woundLeakedThickYellowGreenFluid: [''],
      woundBeenPainfulToTouch: [''],
      woundBeenSmelly: [''],
      haveOrHadARaisedTemperature: [''],
      woundSeparatedOpen: [''],
      deeperTissueSeparate: [''],
      haveYouBeenDiagnosedWithAnInfection: ['', Validators.required],
      whatDateWasThisDiagnosedOn: [''],
      hasAnythingBeenPutOnToCoverTheWound: [''],
      haveYouBeenGivenAnitobioticsForYourWound: [''],
      haveTheEdgesOfTheWoundBeenSeparatedDeliberately: [''],
      haveYouSoughtAdviceWithAProblemWithYourWound: ['', Validators.required],
      haveYouBeenInHospitalForTreatmentForYourWound: [''],
      hasYourWoundBeenScrapedOrCutToRemoveUnwantedTissue: [''],
      hasYourWoundBeenDrained: [''],
      haveYouHadAnOperationUnderGeneralAnaesthetic: [''],

      takePartInTelephoneInterview: ['', Validators.required],

      accessToHealthcareServicesHasImproved: ['', Validators.required],
      thisSavedTimeTravellingToHospitalOrClinic: ['', Validators.required],
      thisProvidedForMyHealthcareNeeds: ['', Validators.required],
      toolUsefulnessComments: [''],

      itWasSimpleToUseTheOnlineForm: ['', Validators.required],
      itWasEasyToLearnToUseTheForm: ['', Validators.required],
      iWasAbleToNavigateTheToolQuicklyWhenSubmitting: ['', Validators.required],
      toolEaseOfUseComments: [''],

      pleasantToInteractWith: ['', Validators.required],
      iLikeUsingIslaHostingTheForm: ['', Validators.required],
      islaHostingTheFormIsSimpleAndEasyToUnderstand: ['', Validators.required],
      hostedFormIsAbleToDoEverythingIExpect: ['', Validators.required],
      platformInterfaceComments: [''],

      couldEasilyCommunicateWithTheHealthcareStaff: ['', Validators.required],
      couldEasilyGetAResponseFromTheHealthcareStaff: ['', Validators.required],
      feltIWasAbleToExpressMyselfEffectively: ['', Validators.required],
      couldCommunicateWithHealthcareStaffAsWellAsInPerson: [
        '',
        Validators.required,
      ],
      platformQualityOfCommunicationComments: [''],

      remoteWoundReviewsAreSameAsInPerson: ['', Validators.required],
      whenIMadeAMistakeOnTheFormICouldRecoverQuicklyAndEasily: [
        '',
        Validators.required,
      ],
      islaPlatformOnlineFormGaveErrorMessagesClearly: ['', Validators.required],
      onlineToolReliabilityComments: [''],

      feelComfortableCommunicatingWithTheHealthcareStaff: [
        '',
        Validators.required,
      ],
      foundThisAnAcceptableWayToReceiveHealthcareServices: [
        '',
        Validators.required,
      ],
      wouldUseTheOnlineSystemAgain: ['', Validators.required],
      shouldBeAvailableForOthersUndergoingSimilarSurgery: [
        '',
        Validators.required,
      ],
      overallSatisfiedWithTheOnlineSystem: ['', Validators.required],
      onlineToolComments: [''],

      highestNumberOfPeopleAskedToGoForAWoundReview: [''],
      highestNumberOfMissedInfections: [''],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.lothianForm.controls[key])
          this.lothianForm.controls[key].setValue(formData[key]);
      });
    });
  }

  get woundSymptoms() {
    return this.lothianForm.get('woundSymptoms');
  }
  get rednessSpreadingAwayFromWound() {
    return this.lothianForm.get('rednessSpreadingAwayFromWound');
  }
  get woundAreaWarmerThanSurroundingSkin() {
    return this.lothianForm.get('woundAreaWarmerThanSurroundingSkin');
  }
  get woundAreaBecomeSwollen() {
    return this.lothianForm.get('woundAreaBecomeSwollen');
  }
  get woundLeakedClearFluid() {
    return this.lothianForm.get('woundLeakedClearFluid');
  }
  get woundLeakedBloodStainedFluid() {
    return this.lothianForm.get('woundLeakedBloodStainedFluid');
  }
  get woundLeakedThickYellowGreenFluid() {
    return this.lothianForm.get('woundLeakedThickYellowGreenFluid');
  }
  get woundBeenPainfulToTouch() {
    return this.lothianForm.get('woundBeenPainfulToTouch');
  }
  get woundBeenSmelly() {
    return this.lothianForm.get('woundBeenSmelly');
  }
  get haveOrHadARaisedTemperature() {
    return this.lothianForm.get('haveOrHadARaisedTemperature');
  }
  get woundSeparatedOpen() {
    return this.lothianForm.get('woundSeparatedOpen');
  }
  get deeperTissueSeparate() {
    return this.lothianForm.get('deeperTissueSeparate');
  }
  get haveYouBeenDiagnosedWithAnInfection() {
    return this.lothianForm.get('haveYouBeenDiagnosedWithAnInfection');
  }
  get whatDateWasThisDiagnosedOn() {
    return this.lothianForm.get('whatDateWasThisDiagnosedOn');
  }
  get hasAnythingBeenPutOnToCoverTheWound() {
    return this.lothianForm.get('hasAnythingBeenPutOnToCoverTheWound');
  }
  get haveYouBeenGivenAnitobioticsForYourWound() {
    return this.lothianForm.get('haveYouBeenGivenAnitobioticsForYourWound');
  }
  get haveTheEdgesOfTheWoundBeenSeparatedDeliberately() {
    return this.lothianForm.get(
      'haveTheEdgesOfTheWoundBeenSeparatedDeliberately'
    );
  }
  get haveYouSoughtAdviceWithAProblemWithYourWound() {
    return this.lothianForm.get('haveYouSoughtAdviceWithAProblemWithYourWound');
  }
  get haveYouBeenInHospitalForTreatmentForYourWound() {
    return this.lothianForm.get(
      'haveYouBeenInHospitalForTreatmentForYourWound'
    );
  }
  get hasYourWoundBeenScrapedOrCutToRemoveUnwantedTissue() {
    return this.lothianForm.get(
      'hasYourWoundBeenScrapedOrCutToRemoveUnwantedTissue'
    );
  }
  get hasYourWoundBeenDrained() {
    return this.lothianForm.get('hasYourWoundBeenDrained');
  }
  get haveYouHadAnOperationUnderGeneralAnaesthetic() {
    return this.lothianForm.get('haveYouHadAnOperationUnderGeneralAnaesthetic');
  }

  get takePartInTelephoneInterview() {
    return this.lothianForm.get('takePartInTelephoneInterview');
  }

  get accessToHealthcareServicesHasImproved() {
    return this.lothianForm.get('accessToHealthcareServicesHasImproved');
  }

  get thisSavedTimeTravellingToHospitalOrClinic() {
    return this.lothianForm.get('thisSavedTimeTravellingToHospitalOrClinic');
  }

  get thisProvidedForMyHealthcareNeeds() {
    return this.lothianForm.get('thisProvidedForMyHealthcareNeeds');
  }

  get toolUsefulnessComments() {
    return this.lothianForm.get('toolUsefulnessComments');
  }

  get itWasSimpleToUseTheOnlineForm() {
    return this.lothianForm.get('itWasSimpleToUseTheOnlineForm');
  }

  get itWasEasyToLearnToUseTheForm() {
    return this.lothianForm.get('itWasEasyToLearnToUseTheForm');
  }

  get iWasAbleToNavigateTheToolQuicklyWhenSubmitting() {
    return this.lothianForm.get(
      'iWasAbleToNavigateTheToolQuicklyWhenSubmitting'
    );
  }

  get pleasantToInteractWith() {
    return this.lothianForm.get('pleasantToInteractWith');
  }

  get iLikeUsingIslaHostingTheForm() {
    return this.lothianForm.get('iLikeUsingIslaHostingTheForm');
  }

  get islaHostingTheFormIsSimpleAndEasyToUnderstand() {
    return this.lothianForm.get(
      'islaHostingTheFormIsSimpleAndEasyToUnderstand'
    );
  }

  get hostedFormIsAbleToDoEverythingIExpect() {
    return this.lothianForm.get('hostedFormIsAbleToDoEverythingIExpect');
  }

  get platformInterfaceComments() {
    return this.lothianForm.get('platformInterfaceComments');
  }

  get couldEasilyCommunicateWithTheHealthcareStaff() {
    return this.lothianForm.get('couldEasilyCommunicateWithTheHealthcareStaff');
  }

  get couldEasilyGetAResponseFromTheHealthcareStaff() {
    return this.lothianForm.get(
      'couldEasilyGetAResponseFromTheHealthcareStaff'
    );
  }

  get feltIWasAbleToExpressMyselfEffectively() {
    return this.lothianForm.get('feltIWasAbleToExpressMyselfEffectively');
  }

  get couldCommunicateWithHealthcareStaffAsWellAsInPerson() {
    return this.lothianForm.get(
      'couldCommunicateWithHealthcareStaffAsWellAsInPerson'
    );
  }

  get platformQualityOfCommunicationComments() {
    return this.lothianForm.get('platformQualityOfCommunicationComments');
  }

  get remoteWoundReviewsAreSameAsInPerson() {
    return this.lothianForm.get('remoteWoundReviewsAreSameAsInPerson');
  }

  get whenIMadeAMistakeOnTheFormICouldRecoverQuicklyAndEasily() {
    return this.lothianForm.get(
      'whenIMadeAMistakeOnTheFormICouldRecoverQuicklyAndEasily'
    );
  }

  get islaPlatformOnlineFormGaveErrorMessagesClearly() {
    return this.lothianForm.get(
      'islaPlatformOnlineFormGaveErrorMessagesClearly'
    );
  }

  get onlineToolReliabilityComments() {
    return this.lothianForm.get('onlineToolReliabilityComments');
  }

  get feelComfortableCommunicatingWithTheHealthcareStaff() {
    return this.lothianForm.get(
      'feelComfortableCommunicatingWithTheHealthcareStaff'
    );
  }

  get foundThisAnAcceptableWayToReceiveHealthcareServices() {
    return this.lothianForm.get(
      'foundThisAnAcceptableWayToReceiveHealthcareServices'
    );
  }

  get wouldUseTheOnlineSystemAgain() {
    return this.lothianForm.get('wouldUseTheOnlineSystemAgain');
  }

  get shouldBeAvailableForOthersUndergoingSimilarSurgery() {
    return this.lothianForm.get(
      'shouldBeAvailableForOthersUndergoingSimilarSurgery'
    );
  }

  get overallSatisfiedWithTheOnlineSystem() {
    return this.lothianForm.get('overallSatisfiedWithTheOnlineSystem');
  }

  get onlineToolComments() {
    return this.lothianForm.get('onlineToolComments');
  }

  get highestNumberOfPeopleAskedToGoForAWoundReview() {
    return this.lothianForm.get(
      'highestNumberOfPeopleAskedToGoForAWoundReview'
    );
  }

  get highestNumberOfMissedInfections() {
    return this.lothianForm.get('highestNumberOfMissedInfections');
  }

  // checking validation
  woundSymptomsNoneValidation() {
    this.lothianForm.get('woundSymptoms').setValue('None');
  }

  woundSymptoms0Validation(event) {
    if (event.source._selected) {
      this.lothianForm
        .get('rednessSpreadingAwayFromWound')
        .setValidators(Validators.required);

      if (this.lothianForm.get('woundSymptoms').value.indexOf('None') !== -1) {
        this.lothianForm.get('woundSymptoms').patchValue('');
      }
    } else {
      this.lothianForm.get('rednessSpreadingAwayFromWound').clearValidators();
      this.lothianForm.get('rednessSpreadingAwayFromWound').reset('');
    }
  }
  woundSymptoms1Validation(event) {
    if (event.source._selected) {
      this.lothianForm
        .get('woundAreaWarmerThanSurroundingSkin')
        .setValidators(Validators.required);

      if (this.lothianForm.get('woundSymptoms').value.indexOf('None') !== -1) {
        this.lothianForm.get('woundSymptoms').patchValue('');
      }
    } else {
      this.lothianForm
        .get('woundAreaWarmerThanSurroundingSkin')
        .clearValidators();
      this.lothianForm.get('woundAreaWarmerThanSurroundingSkin').reset('');
    }
  }
  woundSymptoms2Validation(event) {
    if (event.source._selected) {
      this.lothianForm
        .get('woundAreaBecomeSwollen')
        .setValidators(Validators.required);

      if (this.lothianForm.get('woundSymptoms').value.indexOf('None') !== -1) {
        this.lothianForm.get('woundSymptoms').patchValue('');
      }
    } else {
      this.lothianForm.get('woundAreaBecomeSwollen').clearValidators();
      this.lothianForm.get('woundAreaBecomeSwollen').reset('');
    }
  }
  woundSymptoms3Validation(event) {
    if (event.source._selected) {
      this.lothianForm
        .get('woundLeakedClearFluid')
        .setValidators(Validators.required);

      this.lothianForm
        .get('woundLeakedBloodStainedFluid')
        .setValidators(Validators.required);

      this.lothianForm
        .get('woundLeakedThickYellowGreenFluid')
        .setValidators(Validators.required);

      if (this.lothianForm.get('woundSymptoms').value.indexOf('None') !== -1) {
        this.lothianForm.get('woundSymptoms').patchValue('');
      }
    } else {
      this.lothianForm.get('woundLeakedClearFluid').clearValidators();
      this.lothianForm.get('woundLeakedClearFluid').reset('');

      this.lothianForm.get('woundLeakedBloodStainedFluid').clearValidators();
      this.lothianForm.get('woundLeakedBloodStainedFluid').reset('');

      this.lothianForm
        .get('woundLeakedThickYellowGreenFluid')
        .clearValidators();
      this.lothianForm.get('woundLeakedThickYellowGreenFluid').reset('');
    }
  }
  woundSymptoms4Validation(event) {
    if (event.source._selected) {
      this.lothianForm
        .get('woundBeenPainfulToTouch')
        .setValidators(Validators.required);

      if (this.lothianForm.get('woundSymptoms').value.indexOf('None') !== -1) {
        this.lothianForm.get('woundSymptoms').patchValue('');
      }
    } else {
      this.lothianForm.get('woundBeenPainfulToTouch').clearValidators();
      this.lothianForm.get('woundBeenPainfulToTouch').reset('');
    }
  }
  woundSymptoms5Validation(event) {
    if (event.source._selected) {
      this.lothianForm
        .get('woundBeenSmelly')
        .setValidators(Validators.required);

      if (this.lothianForm.get('woundSymptoms').value.indexOf('None') !== -1) {
        this.lothianForm.get('woundSymptoms').patchValue('');
      }
    } else {
      this.lothianForm.get('woundBeenSmelly').clearValidators();
      this.lothianForm.get('woundBeenSmelly').reset('');
    }
  }
  woundSymptoms6Validation(event) {
    if (event.source._selected) {
      this.lothianForm
        .get('haveOrHadARaisedTemperature')
        .setValidators(Validators.required);

      if (this.lothianForm.get('woundSymptoms').value.indexOf('None') !== -1) {
        this.lothianForm.get('woundSymptoms').patchValue('');
      }
    } else {
      this.lothianForm.get('haveOrHadARaisedTemperature').clearValidators();
      this.lothianForm.get('haveOrHadARaisedTemperature').reset('');
    }
  }
  woundSymptoms7Validation(event) {
    if (event.source._selected) {
      this.lothianForm
        .get('woundSeparatedOpen')
        .setValidators(Validators.required);

      if (this.lothianForm.get('woundSymptoms').value.indexOf('None') !== -1) {
        this.lothianForm.get('woundSymptoms').patchValue('');
      }
    } else {
      this.lothianForm.get('woundSeparatedOpen').clearValidators();
      this.lothianForm.get('woundSeparatedOpen').reset('');
      this.lothianForm.get('deeperTissueSeparate').clearValidators();
      this.lothianForm.get('deeperTissueSeparate').reset('');
    }
  }

  diagnosedWithInfectionValidation(event) {
    if (event.value === 'Yes') {
      this.lothianForm
        .get('whatDateWasThisDiagnosedOn')
        .setValidators(Validators.required);
      this.lothianForm
        .get('hasAnythingBeenPutOnToCoverTheWound')
        .setValidators(Validators.required);
      this.lothianForm
        .get('haveYouBeenGivenAnitobioticsForYourWound')
        .setValidators(Validators.required);
      this.lothianForm
        .get('haveTheEdgesOfTheWoundBeenSeparatedDeliberately')
        .setValidators(Validators.required);
    } else {
      this.lothianForm.get('whatDateWasThisDiagnosedOn').clearValidators();
      this.lothianForm
        .get('hasAnythingBeenPutOnToCoverTheWound')
        .clearValidators();
      this.lothianForm
        .get('haveYouBeenGivenAnitobioticsForYourWound')
        .clearValidators();
      this.lothianForm
        .get('haveTheEdgesOfTheWoundBeenSeparatedDeliberately')
        .clearValidators();
      this.lothianForm.get('whatDateWasThisDiagnosedOn').reset('');
      this.lothianForm.get('hasAnythingBeenPutOnToCoverTheWound').reset('');
      this.lothianForm
        .get('haveYouBeenGivenAnitobioticsForYourWound')
        .reset('');
      this.lothianForm
        .get('haveTheEdgesOfTheWoundBeenSeparatedDeliberately')
        .reset('');
    }
  }

  problemWithWoundValidation(event) {
    if (event.value === 'Yes') {
      this.lothianForm
        .get('haveYouBeenInHospitalForTreatmentForYourWound')
        .setValidators(Validators.required);
    } else {
      this.lothianForm
        .get('haveYouBeenInHospitalForTreatmentForYourWound')
        .clearValidators();
      this.lothianForm
        .get('haveYouBeenInHospitalForTreatmentForYourWound')
        .reset('');

      this.lothianForm
        .get('hasYourWoundBeenScrapedOrCutToRemoveUnwantedTissue')
        .clearValidators();
      this.lothianForm
        .get('hasYourWoundBeenScrapedOrCutToRemoveUnwantedTissue')
        .reset('');
      this.lothianForm.get('hasYourWoundBeenDrained').clearValidators();
      this.lothianForm.get('hasYourWoundBeenDrained').reset('');
      this.lothianForm
        .get('haveYouHadAnOperationUnderGeneralAnaesthetic')
        .clearValidators();
      this.lothianForm
        .get('haveYouHadAnOperationUnderGeneralAnaesthetic')
        .reset('');
    }
  }

  treatmentForWoundValidation(event) {
    if (event.value === 'Yes') {
      this.lothianForm
        .get('hasYourWoundBeenScrapedOrCutToRemoveUnwantedTissue')
        .setValidators(Validators.required);
      this.lothianForm
        .get('hasYourWoundBeenDrained')
        .setValidators(Validators.required);
      this.lothianForm
        .get('haveYouHadAnOperationUnderGeneralAnaesthetic')
        .setValidators(Validators.required);
    } else {
      this.lothianForm
        .get('hasYourWoundBeenScrapedOrCutToRemoveUnwantedTissue')
        .clearValidators();
      this.lothianForm
        .get('hasYourWoundBeenScrapedOrCutToRemoveUnwantedTissue')
        .reset('');
      this.lothianForm.get('hasYourWoundBeenDrained').clearValidators();
      this.lothianForm.get('hasYourWoundBeenDrained').reset('');
      this.lothianForm
        .get('haveYouHadAnOperationUnderGeneralAnaesthetic')
        .clearValidators();
      this.lothianForm
        .get('haveYouHadAnOperationUnderGeneralAnaesthetic')
        .reset('');
    }
  }

  onSubmit() {
    this.isSubmitting = true;
    const formResponse = {
      ...this.lothianForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  lothianFormValid(): boolean {
    return (
      this.lothianForm.dirty &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['woundSymptoms'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['rednessSpreadingAwayFromWound'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['woundAreaWarmerThanSurroundingSkin'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['woundAreaBecomeSwollen'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['woundLeakedClearFluid'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['woundLeakedBloodStainedFluid'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['woundLeakedThickYellowGreenFluid'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['woundBeenPainfulToTouch'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['woundBeenSmelly'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['haveOrHadARaisedTemperature'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['woundSeparatedOpen'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['deeperTissueSeparate'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['haveYouBeenDiagnosedWithAnInfection'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['whatDateWasThisDiagnosedOn'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['hasAnythingBeenPutOnToCoverTheWound'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['haveYouBeenGivenAnitobioticsForYourWound']
          .errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls[
          'haveTheEdgesOfTheWoundBeenSeparatedDeliberately'
        ].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls[
          'haveYouSoughtAdviceWithAProblemWithYourWound'
        ].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls[
          'haveYouBeenInHospitalForTreatmentForYourWound'
        ].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls[
          'hasYourWoundBeenScrapedOrCutToRemoveUnwantedTissue'
        ].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['hasYourWoundBeenDrained'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls[
          'haveYouHadAnOperationUnderGeneralAnaesthetic'
        ].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['takePartInTelephoneInterview'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['accessToHealthcareServicesHasImproved']
          .errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['thisSavedTimeTravellingToHospitalOrClinic']
          .errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['thisProvidedForMyHealthcareNeeds'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['itWasSimpleToUseTheOnlineForm'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['itWasEasyToLearnToUseTheForm'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls[
          'iWasAbleToNavigateTheToolQuicklyWhenSubmitting'
        ].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['pleasantToInteractWith'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['iLikeUsingIslaHostingTheForm'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls[
          'islaHostingTheFormIsSimpleAndEasyToUnderstand'
        ].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['hostedFormIsAbleToDoEverythingIExpect']
          .errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls[
          'couldEasilyCommunicateWithTheHealthcareStaff'
        ].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls[
          'couldEasilyGetAResponseFromTheHealthcareStaff'
        ].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['feltIWasAbleToExpressMyselfEffectively']
          .errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls[
          'couldCommunicateWithHealthcareStaffAsWellAsInPerson'
        ].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['remoteWoundReviewsAreSameAsInPerson'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls[
          'whenIMadeAMistakeOnTheFormICouldRecoverQuicklyAndEasily'
        ].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls[
          'islaPlatformOnlineFormGaveErrorMessagesClearly'
        ].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls[
          'feelComfortableCommunicatingWithTheHealthcareStaff'
        ].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls[
          'foundThisAnAcceptableWayToReceiveHealthcareServices'
        ].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['wouldUseTheOnlineSystemAgain'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls[
          'shouldBeAvailableForOthersUndergoingSimilarSurgery'
        ].errors
      )
    );
  }
}
