import { Component, OnInit } from '@angular/core';
import { TeamWithId } from '@islacare/ic-types';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { TeamsService } from '../../../../services/teams/teams.service';
import { DialogData } from '../dialog-data';

interface DialogDataWithTextField extends DialogData {
  title: string;
  negativeButton: string;
  positiveButton: string;
  teamIds: string[];
  firstName: string;
  lastName: string;
  isConfirmed?: boolean;
}

@Component({
  selector: 'ic-add-patient-to-team-dialog',
  templateUrl: './add-patient-to-team-dialog.component.html',
  styleUrls: ['./add-patient-to-team-dialog.component.scss'],
})
export class AddPatientToTeamDialogComponent implements OnInit {
  dialogData = this.config.data as DialogDataWithTextField;
  team$: Observable<TeamWithId>;

  constructor(
    private config: DynamicDialogConfig,
    private teamsService: TeamsService,
    public ref: DynamicDialogRef,
  ) {}

  ngOnInit(): void {
    this.team$ = this.teamsService.getTeam$(this.dialogData.teamIds[0]);
  }

  submit(): void {
    const requestObj = {
      button: true,
    };
    this.ref.close(requestObj);
  }
}
