import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { FormService, UsersService } from '@ic-monorepo/services';
import { User } from '@islacare/ic-types';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'ic-breast-form',
  templateUrl: './breast-form.component.html',
  styleUrls: ['./breast-form.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
})
export class BreastFormComponent implements OnInit {
  @Input() data;
  user$: Observable<User>;

  constructor(
    private fb: UntypedFormBuilder,
    private form: FormService,
    private usersService: UsersService
  ) {}

  breastForm: UntypedFormGroup;
  isSubmitting = false;
  weightKg: number;
  heightM: number;

  ngOnInit(): void {
    this.user$ = this.usersService.me$;

    this.breastForm = this.fb.group({
      sex: ['', []],

      mainSymptom: ['', []],
      mainSymptomLengthInMonths: [''],
      otherSymptoms: ['', []],
      otherSymptomsLengthInMonths: [''],

      weightSt: [''],
      weightLbs: [''],
      heightFt: [''],
      heightIns: [''],

      breastDensityScore: [''],

      alcohol: ['', []],
      glassesOfWinePerWeek: [''],
      pintsOfBeerPerWeek: [''],
      bottlesOfBeerCiderOrAlcopopPerWeek: [''],
      shotsOfSpiritPerWeek: [''],

      ageFirstPeriod: [''],
      oralContraceptive: [''],
      currentlyOnContraceptives: ['', []],
      oralContraceptiveYears: [''],
      oralContraceptiveLast2years: ['', []],
      stillGetPeriods: ['', []],
      periodsStoppedNaturally: ['', []],
      periodsStoppedNaturallyAge: [''],

      everUsedMenopauseHormoneTherapy: ['', []],
      currentlyUsingMenopauseHormoneTherapy: ['', []],

      everUsedHormoneReplacementTherapy: ['', []],
      yearsUsedHrt: ['', []],
      usedHrtInLast5Years: ['', []],
      typeOfHrtUsed: ['', []],

      children: ['', []],
      ageAtFirstLiveBirth: ['', []],
      childrenList: this.fb.array([
        this.fb.group({
          childYOB: ['', []],
          childSex: ['', []],
          childIndex: [1, []],
        }),
      ]),

      mammogram: ['', []],
      mammogramDensityInformed: ['', []],
      mammogramBirads: ['', []],

      endometriosis: ['', []],
      tubalLigation: ['', []],
      oophorectomy: ['', []],
      mastectomy: ['', []],

      breastCancerOneBreast: ['', []],
      breastCancerOneBreastAge: ['', []],
      breastCancerOtherBreast: ['', []],
      breastCancerOtherBreastAge: ['', []],
      ovarianCancer: ['', []],
      ovarianCancerAge: ['', []],
      pancreaticCancer: ['', []],
      pancreaticCancerAge: ['', []],

      breastCancerGene: ['', []],

      familyHistory: ['', []],
      firstDegRelativesNumber: ['', []],
      firstDegRelativesList: this.fb.array([
        this.fb.group({
          firstDegRelativeRelationship: ['', []],
          firstDegRelativeCurrentAge: ['', []],
          firstDegRelativeDiagnosisAge: ['', []],
          firstDegRelativeStatus: ['', []],
        }),
      ]),
      secondDegRelativesMaternalNumber: ['', []],
      secondDegRelativesMaternalList: this.fb.array([
        this.fb.group({
          secondDegRelativeMaternalAge: ['', []],
        }),
      ]),
      secondDegRelativesPaternalNumber: ['', []],
      secondDegRelativesPaternalList: this.fb.array([
        this.fb.group({
          secondDegRelativePaternalAge: ['', []],
        }),
      ]),

      notes: ['', []],
    });

    this.form.retreive$.pipe(take(1)).subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (
          this.breastForm.controls[key] &&
          key !== 'childrenList' &&
          key !== 'firstDegRelativesList' &&
          key !== 'secondDegRelativesMaternalList' &&
          key !== 'secondDegRelativesPaternalList'
        ) {
          this.breastForm.controls[key].setValue(formData[key]);
        }
      });

      let i = 0;
      while (i < formData['childrenList'].length) {
        if (i > 0) this.addChild();
        const childrenList = this.breastForm.controls
          .childrenList as UntypedFormArray;
        childrenList.controls[i].patchValue(formData['childrenList'][i]);
        i++;
      }

      i = 0;
      while (i < formData['firstDegRelativesList'].length) {
        if (i > 0) this.addFirstDegRelative();
        const firstDegRelativesList = this.breastForm.controls
          .firstDegRelativesList as UntypedFormArray;
        firstDegRelativesList.controls[i].patchValue(
          formData['firstDegRelativesList'][i]
        );
        i++;
      }

      i = 0;
      while (i < formData['secondDegRelativesMaternalList'].length) {
        if (i > 0) this.addSecondDegRelativeMaternal();
        const secondDegRelativesMaternalList = this.breastForm.controls
          .secondDegRelativesMaternalList as UntypedFormArray;
        secondDegRelativesMaternalList.controls[i].patchValue(
          formData['secondDegRelativesMaternalList'][i]
        );
        i++;
      }

      i = 0;
      while (i < formData['secondDegRelativesPaternalList'].length) {
        if (i > 0) this.addSecondDegRelativePaternal();
        const secondDegRelativesPaternalList = this.breastForm.controls
          .secondDegRelativesPaternalList as UntypedFormArray;
        secondDegRelativesPaternalList.controls[i].patchValue(
          formData['secondDegRelativesPaternalList'][i]
        );
        i++;
      }
    });
  }

  removeChild(i) {
    const childrenListArray = this.breastForm.controls
      .childrenList as UntypedFormArray;
    childrenListArray.removeAt(i);
  }

  removeFirstDegRelative(i) {
    const firstDegRelativesListArray = this.breastForm.controls
      .firstDegRelativesList as UntypedFormArray;
    firstDegRelativesListArray.removeAt(i);
  }

  removeSecondDegRelativeMaternal(i) {
    const secondDegRelativesMaternalListArray = this.breastForm.controls
      .secondDegRelativesMaternalList as UntypedFormArray;
    secondDegRelativesMaternalListArray.removeAt(i);
  }

  removeSecondDegRelativePaternal(i) {
    const secondDegRelativesPaternalListArray = this.breastForm.controls
      .secondDegRelativesPaternalList as UntypedFormArray;
    secondDegRelativesPaternalListArray.removeAt(i);
  }

  addChild() {
    const childrenListArray = this.breastForm.controls
      .childrenList as UntypedFormArray;
    const arraylen = childrenListArray.length;

    const newChildGroup: UntypedFormGroup = this.fb.group({
      childYOB: ['', []],
      childSex: ['', []],
      childIndex: [arraylen + 1, []],
    });

    childrenListArray.insert(arraylen, newChildGroup);
  }

  addFirstDegRelative() {
    const firstDegRelativesListArray = this.breastForm.controls
      .firstDegRelativesList as UntypedFormArray;
    const arraylen = firstDegRelativesListArray.length;

    const newRelativeGroup: UntypedFormGroup = this.fb.group({
      firstDegRelativeRelationship: ['', []],
      firstDegRelativeCurrentAge: ['', []],
      firstDegRelativeDiagnosisAge: ['', []],
      firstDegRelativeStatus: ['', []],
    });

    firstDegRelativesListArray.insert(arraylen, newRelativeGroup);
  }

  addSecondDegRelativeMaternal() {
    const secondDegRelativesMaternalListArray = this.breastForm.controls
      .secondDegRelativesMaternalList as UntypedFormArray;
    const arraylen = secondDegRelativesMaternalListArray.length;

    const newRelativeGroup: UntypedFormGroup = this.fb.group({
      secondDegRelativeMaternalAge: ['', []],
    });

    secondDegRelativesMaternalListArray.insert(arraylen, newRelativeGroup);
  }

  addSecondDegRelativePaternal() {
    const secondDegRelativesPaternalListArray = this.breastForm.controls
      .secondDegRelativesPaternalList as UntypedFormArray;
    const arraylen = secondDegRelativesPaternalListArray.length;

    const newRelativeGroup: UntypedFormGroup = this.fb.group({
      secondDegRelativePaternalAge: ['', []],
    });

    secondDegRelativesPaternalListArray.insert(arraylen, newRelativeGroup);
  }

  get mainSymptom() {
    return this.breastForm.get('mainSymptom');
  }

  get otherSymptoms() {
    return this.breastForm.get('otherSymptoms');
  }

  get weightSt() {
    return this.breastForm.get('weightSt');
  }

  get weightLbs() {
    return this.breastForm.get('weightLbs');
  }

  get heightFt() {
    return this.breastForm.get('heightFt');
  }

  get heightIns() {
    return this.breastForm.get('heightIns');
  }

  get alcohol() {
    return this.breastForm.get('alcohol');
  }

  get glassesOfWinePerWeek() {
    return this.breastForm.get('glassesOfWinePerWeek');
  }

  get pintsOfBeerPerWeek() {
    return this.breastForm.get('pintsOfBeerPerWeek');
  }

  get bottlesOfBeerCiderOrAlcopopPerWeek() {
    return this.breastForm.get('bottlesOfBeerCiderOrAlcopopPerWeek');
  }

  get shotsOfSpiritPerWeek() {
    return this.breastForm.get('shotsOfSpiritPerWeek');
  }

  get oralContraceptive() {
    return this.breastForm.get('oralContraceptive');
  }

  get stillGetPeriods() {
    return this.breastForm.get('stillGetPeriods');
  }

  get periodsStoppedNaturally() {
    return this.breastForm.get('periodsStoppedNaturally');
  }

  get everUsedHormoneReplacementTherapy() {
    return this.breastForm.get('everUsedHormoneReplacementTherapy');
  }

  get children() {
    return this.breastForm.get('children');
  }

  get mammogram() {
    return this.breastForm.get('mammogram');
  }

  get mammogramDensityInformed() {
    return this.breastForm.get('mammogramDensityInformed');
  }

  get breastCancerOneBreast() {
    return this.breastForm.get('breastCancerOneBreast');
  }

  get breastCancerOtherBreast() {
    return this.breastForm.get('breastCancerOtherBreast');
  }

  get ovarianCancer() {
    return this.breastForm.get('ovarianCancer');
  }

  get pancreaticCancer() {
    return this.breastForm.get('pancreaticCancer');
  }

  get familyHistory() {
    return this.breastForm.get('familyHistory');
  }

  get everUsedMenopauseHormoneTherapy() {
    return this.breastForm.get('everUsedMenopauseHormoneTherapy');
  }

  get sex() {
    return this.breastForm.get('sex');
  }

  get weeklyAlcoholIntakeInGrams() {
    return this.calcAlcoholIntakeInGrams(
      parseInt(this.glassesOfWinePerWeek.value, 10),
      parseInt(this.pintsOfBeerPerWeek.value, 10),
      parseInt(this.bottlesOfBeerCiderOrAlcopopPerWeek.value, 10),
      parseInt(this.shotsOfSpiritPerWeek.value, 10)
    );
  }

  get bmi() {
    return this.calcBMI(
      parseInt(this.weightSt.value, 10),
      parseInt(this.weightLbs.value, 10),
      parseInt(this.heightFt.value, 10),
      parseInt(this.heightIns.value, 10)
    );
  }

  /* calcBMIs(weightSt, weightLbs, heightFt, heightIns) {
    const weightKg = ((this.weightSt.value*14)+this.weightLbs.value) * 0.45359237;
    const heightM = ((this.heightFt.value*12)+this.heightIns.value) / 39.37;

    let bmi: any = 'Please enter more information';
    if (weightKg > 0 && heightM > 0) {
      bmi = weightKg / (heightM**2);
    }

    if (bmi === 'Please enter more information') {
      this.bmi = bmi;
    } else {
      this.bmi = Math.trunc(bmi*10)/10;
    }
  } */

  calcBMI(weightSt, weightLbs, heightFt, heightIns) {
    const weightKg = (weightSt * 14 + weightLbs) * 0.45359237;
    const heightM = (heightFt * 12 + heightIns) / 39.37;

    let result;

    if (weightKg > 0 && heightM > 0) {
      result = weightKg / heightM ** 2;
    } else {
      result = 'n/a';
    }
    return (result = Math.trunc(result * 10) / 10);
  }

  calcAlcoholIntakeInGrams(wine, beer, bottle, spirit) {
    //average alcohol percentage in UK
    const wineAlcoholPercentage = 0.12;
    const beerAlcoholPercentage = 0.045;
    const alcopopAlcoholPercentage = 0.04;
    const spiritAlcoholPercentage = 0.375;

    const ethanolDensityInGrams = 0.789;

    //average portion size in ml in UK
    const averageGlassOfWine = 175;
    const averagePint = 473;
    const averageBottle = 275;
    const averageShot = 25;

    let totalAlcoholIntake = 0;

    totalAlcoholIntake =
      averageGlassOfWine *
        wine *
        wineAlcoholPercentage *
        ethanolDensityInGrams +
      averagePint * beer * beerAlcoholPercentage * ethanolDensityInGrams +
      averageBottle *
        bottle *
        alcopopAlcoholPercentage *
        ethanolDensityInGrams +
      averageShot * spirit * spiritAlcoholPercentage * ethanolDensityInGrams;

    return totalAlcoholIntake;
  }

  onSubmit() {
    this.isSubmitting = true;
    const formResponse = {
      ...this.breastForm.value,
      bmi: this.bmi,
      weeklyAlcoholIntakeInGrams: this.weeklyAlcoholIntakeInGrams,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  valid(): boolean {
    return this.breastForm.dirty && this.breastForm.valid && !this.isSubmitting;
  }
}
