export default [
  'feelingMiserableOrUnhappy',
  'didntEnjoyAnything',
  'feltSoTiredAndDoneNothing',
  'wasVeryRestless',
  'feltLikeIWasNoGoodAnymore',
  'criedAlot',
  'hardToThinkOrConcentrate',
  'iHatedMyself',
  'iWasABadPerson',
  'iFeltLonely',
  'thoughtNobodyLovedMe',
  'thoughICouldNeverBeAsGoodAsOtherKids',
  'iDidEverythingWrong',
  'notes',
  'mfqTotalScore'
];
