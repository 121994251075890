<form [formGroup]="entryForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <h3>Hands and Feet PUVA Phototherapy Information and Consent Form</h3>
    <mat-card class="mat-card-content">
      <p>
        Your doctor has referred you to the Phototherapy Unit for a course of
        topical PUVA phototherapy to phototherapy to treat your skin condition.
        This form explains about this treatment in detail, including its
        benefits, risks and alternatives. If you have any questions or concerns,
        please speak to a doctor or nurse caring for you. At the bottom of the
        page there will be a section for you to sign if you are happy to go
        ahead with the treatment.
      </p>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>What is phototherapy?</h4>
      <p>
        Natural sunlight has been known to be beneficial in certain skin
        disorders for thousands of years. The ultraviolet part of the radiation
        produced by the sun (UVR) is used in phototherapy. Although it can’t be
        seen, it is an important part of sunlight and is divided into
        ultraviolet A (UVA) and ultraviolet B (UVB) wavelengths. UVA rays go
        deeper into the skin than other types of UVR and can cause the skin to
        tan. UVB rays do not penetrate as deeply but can cause the skin to burn.
        In phototherapy we use carefully calculated doses of UV light to
        minimise this risk.
      </p>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>What is topical PUVA?</h4>
      <p>
        PUVA therapy is a treatment in which a medication called psoralen (P) is
        applied to the skin before exposure of the skin to UVA wavelengths of
        light. The psoralen will be applied by soaking your hands and/or feet,
        in a basin of psoralen solution
      </p>
      <p>
        The psoralen reacts in the skin with the UVA to help improve or clear a
        variety of skin conditions. It also makes you more sensitive to sunlight
        UVA (the rays that get through cloud and window glass) during the
        treatment.
      </p>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>Are there any other types of phototherapy?</h4>
      <p>
        The other phototherapy treatments are narrowband UVB (NB-UVB, in which a
        small part of the UVB light spectrum is used to treat the skin
        condition), oral PUVA (where psoralen is taken as a tablet by mouth) and
        UVA1. The choice of phototherapy treatment depends upon your skin
        condition.
      </p>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>Why am I having this treatment?</h4>
      <p>
        This is the most suitable treatment for your skin condition. It is often
        recommended if you have tried ointments and creams without success but
        before trying tablet medication. Topical PUVA therapy has been found to
        be a particularly useful treatment for many skin conditions, such as
        psoriasis, mycosis fungoides and many more. It is often used if UVB
        treatment has failed, as PUVA is a more potent form of treatment than
        UVB.
      </p>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>Important points to remember before and during your treatment</h4>
      <ul>
        <li>
          You need to commit to attending the Phototherapy Department twice a
          week. Attending regularly helps to achieve better results from your
          treatment.
        </li>
        <li>
          You should reduce your exposure to the sun to minimise the risk of
          sunburn. On treatment days you should cover up the treated areas,
          particularly on sunny days. Use a sunscreen with a sun protection
          factor (SPF) of a least 50 and a UVA seal of protection, and reapply
          it regularly to the treated areas. Please do not sunbathe or use a
          sunbed during the whole course of your treatment.
        </li>
        <li>
          Before you start PUVA treatment, the doctor and nurse will check the
          medicines that you are taking. If any medications you are taking
          (including anything bought over the counter) change during your PUVA
          treatment, please let the nursing staff know before they start your
          next treatment. This is because some tablets can affect the way your
          skin reacts to UVA.
        </li>
      </ul>
      <h4>On the day of your treatment</h4>
      <ul>
        <li>
          Do not wear perfumes, deodorants, aftershave lotions or other cosmetic
          products on the affected areas before your treatment. Some of these
          contain substances which make your skin more sensitive to light. This
          can cause patchy discolouration of the skin and take some months to
          fade. You can use them after each treatment.
        </li>
        <li>
          Please let us know if you have started any new medications or creams
          as some can make your skin more sensitive to light.
        </li>
        <li>
          On treatment days, please do not apply any creams or ointments to your
          skin before you go in the machine apart from an appropriate
          moisturiser. We suggest that you moisturise with a water-based
          moisturiser beforehand as this helps your skin to absorb the
          ultraviolet light. Please ask your nursing team for more information
          on this.
        </li>
        <li>
          Do not use oily creams as these could cause burning or prevent the UVA
          from being absorbed.
        </li>
      </ul>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>Special requirements for bath PUVA treatments</h4>
      <p>
        You will be required to wear protective eye goggles during the treatment
        in the machine.
      </p>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>What can I expect on my first treatment?</h4>
      <p>
        On your first visit to the unit you will have a pre-treatment nursing
        assessment. A member of the phototherapy nursing staff will also show
        you round the unit and will explain the treatment and all the procedures
        to you.
      </p>
      <p>
        Your first treatment in the UVA machine will usually happen on your
        first visit.
      </p>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>What happens during treatment?</h4>
      <ul>
        <li>
          You will need to soak your hands and/or feet for 15 minutes before
          your treatment starts. We assess your skin before this to ensure there
          are no problems.
        </li>
        <li>You should avoid alcohol before your treatment.</li>
        <li>
          The nurse will call you from the waiting room when the machine is
          available for your treatment. They will examine your skin on each
          visit and ask you some questions before soaking your hands and/or
          feet.
        </li>
        <li>
          We will give you goggles to protect your eyes, which you must wear at
          all times during your treatment.
        </li>
        <li>
          We will show you how to place your hands/feet in the correct position
          in the machine to make sure that all your skin is exposed evenly.
        </li>
        <li>
          You will be in the UVA machine from one to 20 minutes. We closely
          monitor the amount of UVA you receive and we will increase the dose
          with each treatment as long as you have tolerated the previous
          treatment. The machine is quite bright and you may feel warm. Please
          let the nursing staff know if you feel uncomfortable or unwell.
        </li>
      </ul>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>How long do treatments last?</h4>
      <p>
        This depends on your skin condition and varies from person to person. We
        generally give treatments twice a week (either on Mondays and Fridays)
        for 12 weeks. Individual treatments initially last up to a few minutes
        (with an increased duration with each subsequent visit if tolerated).
        The total visit may take up to 45 minutes. The total number of
        treatments will depend on your response to treatment and on your
        specific skin condition. We do our best to keep to your appointment
        time, but occasionally there may be a short queue. Please ask one of the
        nurses if you are concerned about your waiting time.
      </p>
      <p>
        The total number of treatments you have will depend on response to
        treatment and on your specific skin condition.
      </p>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>What are the risks with PUVA phototherapy?</h4>
      <p>
        Your doctor or nurse will discuss the possible complications of this
        treatment with you in more detail, but you need to be aware of the
        possible side effects listed below.
      </p>
      <p>Effects that could happen during your course</p>
      <ul>
        <li>
          It is likely that you will get a mild sunburn reaction at least once
          during your course. If you get any severe sunburn-like reactions then
          you should contact the Phototherapy Unit and speak to one of the photo
          nurses. If there is any delay in getting further advice, you can apply
          steroid ointment (if you have some) as well as moisturisers.
        </li>
        <li>
          You may get a spotty, itchy rash (prickly heat). This affects about 1
          in 10 people.
        </li>
        <li>
          If you skin goes brown easily in sunlight you may develop a dark tan
          on the treated areas.
        </li>
        <li>Your skin may become dry and itchy.</li>
        <li>
          The disease being treated may worsen instead of improve. Your doctor
          will advise you of the risk of this happening.
        </li>
      </ul>
      <p>
        If you develop any of these side effects or any other symptoms that you
        think may be PUVA related, please talk to the nursing staff. Always
        inform a member of staff if you are unwell or unable to attend
        treatment.
      </p>
    </mat-card>

    <h4>Risks associated with multiple courses :</h4>

    <mat-card class="mat-card-content">
      <h4>Skin cancer</h4>
      <p>
        People who have had a lot of sunlight exposure have an increased risk of
        cancer. In the UK approximately 1 in 10 of us will develop skin cancer.
        This risk is higher with PUVA than with sunlight and is related to the
        amount of overall whole-body PUVA treatments you have had. It is usual
        practice to be asked to attend your local clinic for a skin cancer
        review once you have received more than 200 PUVA treatments.
      </p>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>Photoaging</h4>
      <p>
        If you need many treatments you may develop sunlight-induced skin
        changes, such as wrinkling and skin discolouration, similar to those of
        the ageing process or produced by cigarette smoking.
      </p>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>Will I have any follow-up appointments?</h4>
      <p>
        You will usually have a follow-up appointment with a doctor six months
        after your treatment, or sooner if you are having problems. You will be
        able to book your appointments in advance. Generally you will be offered
        appointments as near to your preferred time slot as possible, however we
        cannot guarantee the same time slot for all your visits.
      </p>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>Consent – asking for your consent</h4>
      <p>
        We want to involve you in decisions about your care and treatment. If
        you decide to go ahead, you will be asked to sign a consent form at the
        bottom of this page. This states that you agree to have the treatment
        and you understand what it involves. If you would like more information
        about our consent process, please speak to a member of staff caring for
        you.
      </p>
      <p>
        For more information leaflets on conditions, procedures and treatments
        please visit www.bad.org.uk/for-the-public/patient-information-leaflets
      </p>
      <ul>
        <li>
          I confirm I have read and understood what the treatment entails.
        </li>
        <li>I consent to having PUVA hands and feet light treatment.</li>
      </ul>
    </mat-card>

    <div>
      <mat-form-field
        appearance="fill"
        required
        class="notes-field half-width-field"
      >
        <mat-label>Patient name</mat-label>
        <input
          matInput
          formControlName="patientName"
          type="notes"
          placeholder="Patient name"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <button
      mat-raised-button
      class="positive-button"
      type="submit"
      [disabled]="!valid()"
    >
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
