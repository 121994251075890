import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Appointment, AppointmentWithId } from '@islacare/ic-types';
import { add, endOfDay, startOfDay, sub } from 'date-fns';
import { groupBy } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppointmentsService {
  constructor(private db: AngularFirestore) {}

  myPatientAppointments$ = (
    organisationId,
    specialties
  ): Observable<AppointmentWithId[]> => {
    return this.db
      .collectionGroup<AppointmentWithId>('appointments', (ref) =>
        ref
          .where('specialties', 'array-contains-any', specialties)
          .where('organisationId', '==', organisationId)
          .where('start', '>=', sub(startOfDay(new Date()), { weeks: 2 }))
          .where('start', '<=', add(endOfDay(new Date()), { weeks: 5 }))
      )
      .valueChanges({ idField: 'id' });
  };

  byPatientId(appointment: Appointment[]): {
    [patientId: string]: Appointment[];
  } {
    return groupBy(appointment, 'patientId');
  }

  getAppointments(patientId: string): Observable<AppointmentWithId[]> {
    return this.db
      .collection<AppointmentWithId>(`patients/${patientId}/appointments`)
      .valueChanges({ idField: 'id' });
  }

  getAppointmentWards$(patientId: string): Observable<string[]> {
    return this.db
      .collection<AppointmentWithId>(`patients/${patientId}/appointments`)
      .valueChanges({ idField: 'id' })
      .pipe(
        map((appointments) =>
          appointments.map((appointment) => appointment.ward)
        )
      );
  }
}
