import {
  ClinicalFormField,
  ClinicalFormStatus,
  ClinicalFormWrapper,
} from '@islacare/ic-types';
import { FormlyFieldConfig } from '@ngx-formly/core';

const yesNoOptions = {
  options: [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ],
};

const clinicalFormFieldOptions = Object.values(ClinicalFormStatus).map(
  (value) => ({
    value: value,
    label: value.charAt(0).toUpperCase() + value.slice(1),
  })
);

function extractKeyAndLabelFromFormArray(form: FormlyFieldConfig[]) {
  return form.flatMap((obj) => {
    if (obj?.key && obj?.props?.label) {
      return [{ value: obj.key, label: obj.props.label }];
    }

    if (Array.isArray(obj.fieldGroup) && obj?.fieldGroup?.length) {
      return extractKeyAndLabelFromFormArray(obj.fieldGroup);
    }

    return [];
  });
}

export function buildSaveClinicalFormDetailsForm(
  clinicalFormFieldConfig: FormlyFieldConfig[]
): FormlyFieldConfig[] {
  const clinicalFormDetailsForm: FormlyFieldConfig[] = [
    {
      wrappers: [ClinicalFormWrapper.SECTION],
      fieldGroup: [
        {
          key: 'name',
          type: ClinicalFormField.TEXT,
          props: {
            label: 'Name: ',
            description: '',
            required: true,
          },
        },
        {
          key: 'description',
          type: ClinicalFormField.TEXTAREA,
          props: {
            label: 'Description: ',
            description: '',
            required: true,
          },
        },
        {
          key: 'status',
          type: ClinicalFormField.SELECT,
          props: {
            label: 'Status: ',
            description: '',
            options: clinicalFormFieldOptions,
            required: true,
          },
          expressions: {
            hide: 'true', // note: this is staying hidden for now until we add statuses to clinical forms
          },
        },

        {
          key: 'isPublic',
          type: ClinicalFormField.RADIO,
          props: {
            label: 'Public: ',
            options: yesNoOptions.options,
            description: '',
            required: true,
          },
        },
        {
          key: 'graphConfigsDefaultKeys',
          type: ClinicalFormField.MULTISELECT,
          props: {
            label: 'Graph config default display keys: ',
            options: extractKeyAndLabelFromFormArray(clinicalFormFieldConfig),
            description:
              'These are the points that will automatically be displayed on the graph',
          },
        },
        {
          key: 'graphConfigsEnabled',
          type: ClinicalFormField.RADIO,
          props: {
            label: 'Graph config enabled: ',
            options: yesNoOptions.options,
            description: '',
          },
        },
        {
          key: 'calculationReferences',
          type: ClinicalFormField.TEXT,
          props: {
            label: 'Calculation reference: ',
            description: '',
          },
        },
        {
          key: 'calculationDescription',
          type: ClinicalFormField.TEXTAREA,
          props: {
            label: 'Calculation description: ',
            description: '',
          },
        },
        {
          key: 'calculationEnabled',
          type: ClinicalFormField.RADIO,
          props: {
            label: 'Calculation enabled: ',
            options: yesNoOptions.options,
            description: '',
          },
        },
        {
          key: 'calculationValidated',
          type: ClinicalFormField.RADIO,
          props: {
            label: 'Calculation validated: ',
            options: yesNoOptions.options,
            description: '',
          },
        },
      ],
    },
  ];
  return clinicalFormDetailsForm;
}
