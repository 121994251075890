export default [
  //MSK Referal Form
  'historyOfUlcerationOrWound',
  'existingWoundOrUlcer',
  'riskFromDiabetesOrOtherComorbidities',
  'vascularInsufficiencyPvdOrPad',
  'peripheralOrMotorNeuropathy',
  'chronicKidney',
  'amputation',
  'anatomicalLocation',
  'footDeformity',
  'footDeformityType',
  'footDeformityUlcerationRisk',
  'increasedRiskFromExistingCallosity',
  'notes'
];
