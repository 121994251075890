import { Injectable } from '@angular/core';
import { EntryWithId } from '@islacare/ic-types';
import { ComponentStore } from '@ngrx/component-store';

export interface EntryWithIdCollection extends EntryWithId {
  collectionName?: string;
  entryContainsText?: string;
}

export interface EntryComparisonState {
  selectedEntriesForComparison: EntryWithIdCollection[];
}

const defaultState: EntryComparisonState = {
  selectedEntriesForComparison: [],
};

@Injectable()
export class EntryComparisonStore extends ComponentStore<EntryComparisonState> {
  constructor() {
    super(defaultState);
  }

  // Selects all selectedEntriesForComparison
  readonly entriesForComparison$ = this.select((state) => {
    return state.selectedEntriesForComparison;
  });

  // Adds new entry
  readonly loadEntries = this.updater((state, entries: EntryWithId[]) => {
    const currentEntriesCopy: EntryWithIdCollection[] = [
      ...state.selectedEntriesForComparison,
    ];

    for (const entry of entries) {
      const entryExists = currentEntriesCopy.some(
        (item) => item.id === entry.id
      );
      if (!entryExists && entry?.isChecked) {
        currentEntriesCopy.push(entry);
      }
    }

    return {
      ...state,
      selectedEntriesForComparison: currentEntriesCopy,
    };
  });

  // Removes all entries
  readonly clearAllEntries = this.updater((state) => {
    const entriesCopy: EntryWithIdCollection[] = [
      ...state.selectedEntriesForComparison,
    ];

    entriesCopy.forEach((entry) => (entry.isChecked = false));
    entriesCopy.length = 0;

    return {
      ...state,
      selectedEntriesForComparison: entriesCopy,
    };
  });

  // Removes selected entry
  readonly clearSelectedEntry = this.updater((state, entry: EntryWithId) => {
    const entriesCopy: EntryWithIdCollection[] = [
      ...state.selectedEntriesForComparison,
    ];

    const entryForDeletion = entriesCopy.find(
      (element) => element.id === entry.id
    );
    if (entryForDeletion) {
      // update entry data
      entryForDeletion.isChecked = false;

      // remove item from entries array
      const index = entriesCopy.findIndex(
        (selectedEntry) => selectedEntry.id === entry.id
      );
      entriesCopy.splice(index, 1);
    }

    return {
      ...state,
      selectedEntriesForComparison: entriesCopy,
    };
  });
}
