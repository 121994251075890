import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-standing-frame-form',
  templateUrl: './standing-frame-form.component.html',
  styleUrls: ['./standing-frame-form.component.scss'],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class StandingFrameFormComponent implements OnInit {
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  standingFrameForm: UntypedFormGroup;
  isSubmitting = false;

  ngOnInit(): void {
    this.standingFrameForm = this.fb.group({
      childName: ['', [Validators.required]],
      aHNumber: ['', [Validators.required]],
      manufacturer: ['', [Validators.required]],
      serialNumber: ['', [Validators.required]],
      model: ['', [Validators.required]],

      haveBenefitsBeenExplainedToCarer: ['', [Validators.required]],
      haveBenefitsBeenExplainedComments: ['', []],
      assessTheEnviromentIsClearBeforeUsingStandingFrame: [
        '',
        [Validators.required],
      ],
      assessTheEnviromentIsClearComments: ['', []],
      performEquipmentSafetyChecklist: ['', [Validators.required]],
      performEquipmentSafetyChecklistComments: ['', []],
      checkChildIsWearingAppropriateFootwearOrOrthotics: [
        '',
        [Validators.required],
      ],
      checkChildIsWearingAppropriateFootwearComments: ['', []],
      checkEquipmentBrakesAreOnBeforeHandlingChildOrEquipment: [
        '',
        [Validators.required],
      ],
      checkEquipmentBrakesAreOnBeforeHandlingComments: ['', []],
      ensureChildIsStrappedIntoEquipmentSafely: ['', [Validators.required]],
      ensureChildIsStrappedIntoEquipmentComments: ['', []],
      checkChildIsComfortableAndWellPositioned: ['', [Validators.required]],
      checkChildIsComfortableAndWellPositionedComments: ['', []],
      assessHowLongChildShouldUsedEquipment: ['', [Validators.required]],
      assessHowLongChildShouldUsedEquipmentComments: ['', []],
      monitorChangesInChildWhilstUsingEquipment: ['', [Validators.required]],
      monitorChangesInChildComments: ['', []],
      prepareForGettingChildOutOfEquipmentInAnEmergency: [
        '',
        [Validators.required],
      ],
      prepareForGettingChildOutComments: ['', []],
      checkForPressureAreasAfterEquipmentUse: ['', [Validators.required]],
      checkForPressureAreasComments: ['', []],
      storeEquipmentSafelyAndNotToBeAdjustedByAnyone: [
        '',
        [Validators.required],
      ],
      storeEquipmentSafelyComments: ['', []],
      chargingInstructionsExplained: ['', [Validators.required]],
      chargingInstructionsComments: ['', []],
      hasUserGuideBeenReadAndUnderstood: ['', [Validators.required]],
      hasUserGuideBeenUnderstoodComments: ['', []],
      notes: ['', []],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.standingFrameForm.controls[key]) {
          this.standingFrameForm.controls[key].setValue(formData[key]);
        }
      });
    });
  }

  onSubmit() {
    this.isSubmitting = true;
    const formResponse = {
      ...this.standingFrameForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  valid(): boolean {
    return (
      this.standingFrameForm.dirty &&
      this.standingFrameForm.valid &&
      !this.isSubmitting
    );
  }
}
