<ng-container *ngIf="patientPhoneAndEmailForm">
  <form [formGroup]="patientPhoneAndEmailForm">
    <h1 mat-dialog-title>
      {{ data.title }}
    </h1>
    <div mat-dialog-content>
      <p>{{ data.message }}</p>
    </div>
    <div class="half-width-field d-col">
      <h4>Mobile number:</h4>
      <mat-form-field class="form-text-field" appearance="outline">
        <input matInput formControlName="phone" />
      </mat-form-field>
    </div>
    <div class="half-width-field d-col">
      <h4>Email address:</h4>
      <mat-form-field class="form-text-field" appearance="outline">
        <input matInput formControlName="email" type="email" />
      </mat-form-field>
    </div>
    <div mat-dialog-actions class="dialog-button-group">
      <div *ngIf="data.negativeButton">
        <button [mat-dialog-close] mat-button>
          {{ data.negativeButton }}
        </button>
      </div>
      <div class="submit-button">
        <button
          [mat-dialog-close]="patientPhoneAndEmailForm?.value"
          [disabled]="!patientPhoneAndEmailForm?.valid"
          mat-button
        >
          {{ data.positiveButton }}
        </button>
      </div>
    </div>
  </form>
</ng-container>
