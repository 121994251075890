import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AccessRequest } from '@islacare/ic-types';

@Injectable({
  providedIn: 'root',
})
export class AccessRequestsService {
  constructor(
    private db: AngularFirestore,
    private analytics: AngularFireAnalytics
  ) {}

  saveAccessRequest(accessRequest: AccessRequest) {
    const id = this.db.createId();
    this.analytics.logEvent('User requested access to patient');

    return this.db.collection('accessRequests').doc(id).set(accessRequest);
  }

  async saveReadOnlyAccessRequest(accessRequest: AccessRequest) {
    const id = this.db.createId();
    await this.db
      .collection('accessRequests')
      .doc(id)
      .set(accessRequest)
      .catch((error) => {
        throw error;
      });
    this.analytics.logEvent('User requested read-only access for collection');
  }
}
