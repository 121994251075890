import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CaptureType } from '@islacare/ic-types';
import { SubmissionFlowMedia } from 'apps/frontend/portal/src/app/feature-patient-submission/store/submission-flow.store';
import { UserCameraService } from 'apps/frontend/portal/src/app/services/user-camera/user-camera.service';
import { ButtonModule } from 'primeng/button';

export interface VideoObject {
  videoFile: File;
  videoUrl: SafeResourceUrl;
  fileType: string;
}

@Component({
  standalone: true,
  selector: 'ic-video-capture',
  templateUrl: './video-capture.component.html',
  imports: [CommonModule, ButtonModule],
})
export class VideoCaptureComponent implements AfterViewInit, OnDestroy {
  @ViewChild('videoElement') videoElement: ElementRef;
  @Output() capture = new EventEmitter<SubmissionFlowMedia>();

  capturedVideo: Blob;
  captureReady = false;
  recording = false;
  sensitive = false;
  notes = '';

  @HostListener('window:beforeunload') handleUnload() {
    this.userCamera.disconnect();
  }

  constructor(
    private sanitizer: DomSanitizer,
    private userCamera: UserCameraService
  ) {}

  ngAfterViewInit(): void {
    this.userCamera
      .getVideoStream(this.videoElement.nativeElement)
      .then(() => (this.captureReady = true));
  }

  ngOnDestroy(): void {
    this.userCamera.disconnect();
  }

  async startRecording() {
    await this.userCamera.startRecording(this.videoElement.nativeElement, true);
    this.recording = true;
  }

  async stopRecording() {
    this.capturedVideo = await this.userCamera.stopAndGetRecording(
      this.videoElement.nativeElement
    );
    this.recording = false;
  }

  clearRecording() {
    this.videoElement.nativeElement.removeAttribute('src');
    this.userCamera.getVideoStream(this.videoElement.nativeElement);
  }

  captureMedia() {
    const url = URL.createObjectURL(this.capturedVideo);

    this.capture.emit({
      file: new File([this.capturedVideo], `${Date.now()}.webm`, {
        type: 'video/webm',
      }),
      url: url,
      safeUrl: this.sanitizer.bypassSecurityTrustUrl(url),
      captureType: CaptureType.VIDEO,
      notes: this.notes,
      sensitive: this.sensitive,
    });
  }
}
