<form [formGroup]="breastForm">
  <div class="form-formatting">
    <mat-card>
      <mat-card-title>Sex</mat-card-title>

      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Are you:</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="sex" data-cy="sex-breast-form">
              <mat-option value="male">Male</mat-option>
              <mat-option value="female">Female</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <br />

    <mat-card>
      <mat-card-title>Symptoms</mat-card-title>

      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>What's your main symptom?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="mainSymptom" data-cy="main-symptom-breast">
              <mat-option value="Pain">Pain</mat-option>
              <mat-option value="A lump">A lump</mat-option>
              <mat-option value="General lumpiness">General lumpiness</mat-option>
              <mat-option value="Nipple discharge">Nipple discharge</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>For how many months?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input
              matInput
              type="number"
              formControlName="mainSymptomLengthInMonths"
              data-cy="main-symptom-length-in-months-breast"
            />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Any other symptoms?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="otherSymptoms" data-cy="other-symptoms-breast" multiple>
              <mat-option
                value="Pain"
                [disabled]="
                  otherSymptoms.value === 'None of the above' || mainSymptom.value === 'Pain'
                "
              >
                Pain
              </mat-option>
              <mat-option
                value="A lump"
                [disabled]="
                  otherSymptoms.value === 'None of the above' || mainSymptom.value === 'A lump'
                "
              >
                A lump
              </mat-option>
              <mat-option
                value="General lumpiness"
                [disabled]="
                  otherSymptoms.value === 'None of the above' ||
                  mainSymptom.value === 'General lumpiness'
                "
              >
                General lumpiness
              </mat-option>
              <mat-option
                value="Nipple discharge"
                [disabled]="
                  otherSymptoms.value === 'None of the above' ||
                  mainSymptom.value === 'Nipple discharge'
                "
              >
                Nipple discharge
              </mat-option>
              <mat-option
                value="None of the above"
                [disabled]="
                  otherSymptoms.value !== '' && otherSymptoms.value !== 'None of the above'
                "
              >
                None of the above
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>For how many months?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="number" formControlName="otherSymptomsLengthInMonths" />
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <br />

    <mat-card>
      <mat-card-title>BMI</mat-card-title>

      <mat-card-content>
        <p>What is your weight?</p>
        <mat-form-field class="third-width-field" appearance="fill">
          <input matInput type="number" formControlName="weightSt" />
        </mat-form-field>
        <span>st</span>
        <mat-form-field class="third-width-field" appearance="fill">
          <input matInput type="number" formControlName="weightLbs" />
        </mat-form-field>
        <span>lbs</span>
        <br />

        <p>What is your height?</p>
        <mat-form-field class="third-width-field" appearance="fill">
          <input matInput type="number" formControlName="heightFt" />
        </mat-form-field>
        <span>ft</span>
        <mat-form-field class="third-width-field" appearance="fill">
          <input matInput type="number" formControlName="heightIns" />
        </mat-form-field>
        <span>ins</span>
        <br />
        <span *ngIf="bmi">
          <strong> BMI: {{ bmi }} </strong>
        </span>
        <br />
        <span>
          <em> Please click away from height and weight fields to update BMI </em>
        </span>
      </mat-card-content>
    </mat-card>

    <br />

    <mat-card>
      <mat-card-title>Alcohol</mat-card-title>

      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Do you drink alcohol?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="alcohol">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="unknown">Unknown</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="alcohol.value === 'yes'">
          <div class="question-container">
            <div class="half-width-field">
              <p>Glasses of wine per week:</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <input
                matInput
                type="number"
                formControlName="glassesOfWinePerWeek"
                data-cy="glasses-of-wine-per-week-breast"
              />
            </mat-form-field>
          </div>
          <div class="question-container">
            <div class="half-width-field">
              <p>Pints of beer per week:</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <input
                matInput
                type="number"
                formControlName="pintsOfBeerPerWeek"
                data-cy="pints-of-beer-per-week-breast"
              />
            </mat-form-field>
          </div>
          <div class="question-container">
            <div class="half-width-field">
              <p>Bottles of beer/cider/alcopop per week:</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <input
                matInput
                type="number"
                formControlName="bottlesOfBeerCiderOrAlcopopPerWeek"
                data-cy="bottles-of-beer-cider-or-alcopop-per-week-breast"
              />
            </mat-form-field>
          </div>
          <div class="question-container">
            <div class="half-width-field">
              <p>Shots of spirit per week:</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <input
                matInput
                type="number"
                formControlName="shotsOfSpiritPerWeek"
                data-cy="shots-of-spirit-per-week-breast"
              />
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <br />

    <mat-card>
      <mat-card-title>Menstruation and contraception</mat-card-title>

      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Age at first period:</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="number" formControlName="ageFirstPeriod" />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Have you ever used oral contraceptives?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="oralContraceptive">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="unknown">Unknown</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="oralContraceptive.value === 'yes'">
          <div class="question-container">
            <div class="half-width-field">
              <p>Are you currently taking oral contraceptives?</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <mat-select formControlName="currentlyOnContraceptives">
                <mat-option value="yes">Yes</mat-option>
                <mat-option value="no">No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="question-container">
            <div class="half-width-field">
              <p>How many years have/had you use oral contraceptives?</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <input matInput type="number" formControlName="oralContraceptiveYears" />
            </mat-form-field>
          </div>

          <div class="question-container">
            <div class="half-width-field">
              <p>Have you taken the pill in the last two years?</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <mat-select formControlName="oralContraceptiveLast2years">
                <mat-option value="yes">Yes</mat-option>
                <mat-option value="no">No</mat-option>
                <mat-option value="unknown">Unknown</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              Do you still get periods? If they stopped because of contraception or operation on
              womb (hysterectomy, ablation procedure), treat as unknown
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="stillGetPeriods">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="unknown">Unknown</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="stillGetPeriods.value === 'no'">
          <div class="question-container">
            <div class="half-width-field">
              <p>Did your periods stop naturally?</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <mat-select formControlName="periodsStoppedNaturally">
                <mat-option value="yes">Yes</mat-option>
                <mat-option value="no">No</mat-option>
                <mat-option value="unknown">Unknown</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="stillGetPeriods.value === 'no' && periodsStoppedNaturally.value === 'yes'">
          <div class="question-container">
            <div class="half-width-field">
              <p>At what age did your periods stop naturally?</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <input matInput type="number" formControlName="periodsStoppedNaturallyAge" />
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <br />

    <mat-card>
      <mat-card-title>Menopause Hormone Therapy (MHT)</mat-card-title>

      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Have you ever used MHT?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="everUsedMenopauseHormoneTherapy">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="unknown">Unknown</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="everUsedMenopauseHormoneTherapy.value === 'yes'">
          <div class="question-container">
            <div class="half-width-field">
              <p>Are you currently on MHT?</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <mat-select formControlName="currentlyUsingMenopauseHormoneTherapy">
                <mat-option value="yes">Yes</mat-option>
                <mat-option value="no">No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <br />

    <mat-card>
      <mat-card-title>HRT</mat-card-title>

      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Have you ever used HRT?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="everUsedHormoneReplacementTherapy">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="unknown">Unknown</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="everUsedHormoneReplacementTherapy.value === 'yes'">
          <div class="question-container">
            <div class="half-width-field">
              <p>For how many years did you use HRT?</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <input matInput type="number" formControlName="yearsUsedHrt" />
            </mat-form-field>
          </div>

          <div class="question-container">
            <div class="half-width-field">
              <p>Have you used HRT in the last five years?</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <mat-select formControlName="usedHrtInLast5Years">
                <mat-option value="yes">Yes</mat-option>
                <mat-option value="no">No</mat-option>
                <mat-option value="unknown">Unknown</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="question-container">
            <div class="half-width-field">
              <p>What type of HRT did you use?</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <mat-select formControlName="typeOfHrtUsed" multiple>
                <mat-option value="Oestrogen-only">Oestrogen-only</mat-option>
                <mat-option value="Combined HRT">Combined HRT</mat-option>
                <mat-option value="Other type">Other type</mat-option>
                <mat-option value="Unknown">Unknown</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <br />

    <mat-card>
      <mat-card-title>Children</mat-card-title>

      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Have you had children?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="children">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="unknown">Unknown</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="children.value === 'yes'">
          <div *ngIf="sex.value === 'female'" class="question-container">
            <div class="half-width-field">
              <p>Your age at the first live birth:</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <input matInput type="number" formControlName="ageAtFirstLiveBirth" />
            </mat-form-field>
          </div>
          <ng-container
            *ngFor="let userFormGroup of breastForm.get('childrenList')['controls']; let i = index"
          >
            <div [formGroup]="userFormGroup">
              <div class="question-container">
                <div class="half-width-field">
                  <p>Child {{ i + 1 }} year of birth:</p>
                </div>
                <mat-form-field class="half-width-field" appearance="fill">
                  <input
                    matInput
                    type="number"
                    formControlName="childYOB"
                    data-cy="child-yob-breast"
                  />
                </mat-form-field>
              </div>
              <div class="question-container">
                <div class="half-width-field">
                  <p>Child {{ i + 1 }} sex:</p>
                </div>
                <mat-form-field class="half-width-field" appearance="fill">
                  <mat-select formControlName="childSex" data-cy="child-sex-breast">
                    <mat-option value="male">Male</mat-option>
                    <mat-option value="female">Female</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <label>
                <button (click)="removeChild(i)">
                  <mat-icon>delete</mat-icon>
                </button>
              </label>
              <br /><br />
            </div>
          </ng-container>

          <button mat-raised-button class="positive-button" (click)="addChild()">Add child</button>
        </div>
      </mat-card-content>
    </mat-card>

    <br />

    <mat-card>
      <mat-card-title>Mammogram</mat-card-title>

      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Have you ever had a mammogram?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="mammogram">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="unknown">Unknown</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="mammogram.value === 'yes'">
          <div class="question-container">
            <div class="half-width-field">
              <p>Were you told about your breast density?</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <mat-select formControlName="mammogramDensityInformed">
                <mat-option value="yes">Yes</mat-option>
                <mat-option value="no">No</mat-option>
                <mat-option value="unknown">Unknown</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="mammogramDensityInformed.value === 'yes'">
            <div class="question-container">
              <div class="half-width-field">
                <p>What was your breast density? (BIRADs)</p>
              </div>
              <mat-form-field class="half-width-field" appearance="fill">
                <mat-select formControlName="mammogramBirads">
                  <mat-option value="A">A</mat-option>
                  <mat-option value="B">B</mat-option>
                  <mat-option value="C">C</mat-option>
                  <mat-option value="D">D</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <br />

    <mat-card>
      <mat-card-title>Other conditions and surgeries</mat-card-title>

      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Have you been diagnosed with endometriosis?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="endometriosis">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="unknown">Unknown</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Have you had your tubes tied (tubal ligation)?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="tubalLigation">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="unknown">Unknown</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Have you had both ovaries removed (oophorectomy)?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="oophorectomy">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="unknown">Unknown</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Have you had both breasts removed (mastectomy)?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="mastectomy">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="unknown">Unknown</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Have you had breast cancer in one breast? (Not pre-breast cancer - DCIS)</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="breastCancerOneBreast">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="unknown">Unknown</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="breastCancerOneBreast.value === 'yes'">
          <div class="question-container">
            <div class="half-width-field">
              <p>At what age did you have breast cancer in one breast?</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <input matInput type="number" formControlName="breastCancerOneBreastAge" />
            </mat-form-field>
          </div>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Have you had breast cancer in the other breast? (Not pre-breast cancer - DCIS)</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="breastCancerOtherBreast">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="unknown">Unknown</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="breastCancerOtherBreast.value === 'yes'">
          <div class="question-container">
            <div class="half-width-field">
              <p>At what age did you have breast cancer in the other breast?</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <input matInput type="number" formControlName="breastCancerOtherBreastAge" />
            </mat-form-field>
          </div>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Have you had ovarian cancer?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="ovarianCancer">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="unknown">Unknown</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="ovarianCancer.value === 'yes'">
          <div class="question-container">
            <div class="half-width-field">
              <p>At what age did you have ovarian cancer?</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <input matInput type="number" formControlName="ovarianCancerAge" />
            </mat-form-field>
          </div>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Have you had pancreatic cancer?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="pancreaticCancer">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="unknown">Unknown</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="pancreaticCancer.value === 'yes'">
          <div class="question-container">
            <div class="half-width-field">
              <p>At what age did you have pancreatic cancer?</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <input matInput type="number" formControlName="pancreaticCancerAge" />
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <br />

    <mat-card>
      <mat-card-title>Breast cancer gene test</mat-card-title>

      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Have you had a breast cancer gene test? If yes, please bring gene report to clinic
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="breastCancerGene">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="unknown">Unknown</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <br />

    <mat-card>
      <mat-card-title>Family history</mat-card-title>

      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Have you had a family history of breast cancer?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="familyHistory">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="unknown">Unknown</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="familyHistory.value === 'yes'">
          <div class="question-container">
            <div class="half-width-field">
              <p>How many first-degree relatives (mother/sister/daughter)?</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <input matInput type="number" formControlName="firstDegRelativesNumber" />
            </mat-form-field>
          </div>

          <p>First-degree relatives (mother/sister/daughter):</p>
          <ng-container
            *ngFor="
              let userFormGroup of breastForm.get('firstDegRelativesList')['controls'];
              let i = index
            "
          >
            <div [formGroup]="userFormGroup">
              <div class="question-container">
                <div class="half-width-field">
                  <p>Relationship {{ i + 1 }}:</p>
                </div>
                <mat-form-field class="half-width-field" appearance="fill">
                  <mat-select formControlName="firstDegRelativeRelationship">
                    <mat-option value="mother">Mother</mat-option>
                    <mat-option value="sister">Sister</mat-option>
                    <mat-option value="daughter">Daughter</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="question-container">
                <div class="half-width-field">
                  <p>Current age of relative {{ i + 1 }}:</p>
                </div>
                <mat-form-field class="half-width-field" appearance="fill">
                  <input matInput type="number" formControlName="firstDegRelativeCurrentAge" />
                </mat-form-field>
              </div>
              <div class="question-container">
                <div class="half-width-field">
                  <p>Age of diagnosis for relative {{ i + 1 }} (Leave blank if unknown):</p>
                </div>
                <mat-form-field class="half-width-field" appearance="fill">
                  <input matInput type="number" formControlName="firstDegRelativeDiagnosisAge" />
                </mat-form-field>
              </div>
              <div class="question-container">
                <div class="half-width-field">
                  <p>Status of relative {{ i + 1 }}:</p>
                </div>
                <mat-radio-group
                  class="half-width-field"
                  appearance="fill"
                  formControlName="firstDegRelativeStatus"
                >
                  <mat-radio-button value="alive">Alive</mat-radio-button>
                  <mat-radio-button value="deceased">Deceased</mat-radio-button>
                </mat-radio-group>
              </div>
              <label>
                <button (click)="removeFirstDegRelative(i)">
                  <mat-icon>delete</mat-icon>
                </button>
              </label>
              <br /><br />
            </div>
          </ng-container>

          <button mat-raised-button class="positive-button" (click)="addFirstDegRelative()">
            Add first-degree relative
          </button>

          <div class="question-container">
            <div class="half-width-field">
              <p>
                How many second degree relatives (mother/sister/daughter) on your mother's side?
              </p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <input matInput type="number" formControlName="secondDegRelativesMaternalNumber" />
            </mat-form-field>
          </div>

          <p>
            Ages at diagnosis of second degree relatives (grandmother, aunt) on your mother's side:
          </p>
          <ng-container
            *ngFor="
              let userFormGroup of breastForm.get(
                '
                .secondDegRelativesMaternalList'
              )['controls'];
              let i = index
            "
          >
            <div [formGroup]="userFormGroup">
              <div class="question-container">
                <div class="half-width-field">
                  <p>Age of relative {{ i + 1 }}:</p>
                </div>
                <mat-form-field class="half-width-field" appearance="fill">
                  <input matInput type="number" formControlName="secondDegRelativeMaternalAge" />
                </mat-form-field>
              </div>
              <label>
                <button (click)="removeSecondDegRelativeMaternal(i)">
                  <mat-icon>delete</mat-icon>
                </button>
              </label>
              <br /><br />
            </div>
          </ng-container>

          <button
            mat-raised-button
            class="positive-button"
            (click)="addSecondDegRelativeMaternal()"
          >
            Add maternal second-degree relative
          </button>

          <div class="question-container">
            <div class="half-width-field">
              <p>
                How many second degree relatives (mother/sister/daughter) on your father's side?
              </p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <input matInput type="number" formControlName="secondDegRelativesPaternalNumber" />
            </mat-form-field>
          </div>

          <p>
            Ages at diagnosis of second degree relatives (grandmother, aunt) on your father's side:
          </p>
          <ng-container
            *ngFor="
              let userFormGroup of breastForm.get('secondDegRelativesPaternalList')['controls'];
              let i = index
            "
          >
            <div [formGroup]="userFormGroup">
              <div class="question-container">
                <div class="half-width-field">
                  <p>Age of relative {{ i + 1 }}:</p>
                </div>
                <mat-form-field class="half-width-field" appearance="fill">
                  <input matInput type="number" formControlName="secondDegRelativePaternalAge" />
                </mat-form-field>
              </div>
              <label>
                <button (click)="removeSecondDegRelativePaternal(i)">
                  <mat-icon>delete</mat-icon>
                </button>
              </label>
              <br /><br />
            </div>
          </ng-container>

          <button
            mat-raised-button
            class="positive-button"
            (click)="addSecondDegRelativePaternal()"
          >
            Add paternal second-degree relative
          </button>
        </div>
      </mat-card-content>
    </mat-card>

    <br />

    <div *ngIf="user$ | async as user">
      <mat-card>
        <mat-card-title>Breast Density Score</mat-card-title>

        <mat-card-content>
          <div class="full-width-field">
            <p>This section should only be filled out by the clinician</p>
          </div>
          <div class="question-container">
            <div class="half-width-field">
              <p>Breast Density Score:</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <input matInput type="number" formControlName="breastDensityScore" />
            </mat-form-field>
          </div>
        </mat-card-content>
      </mat-card>

      <br />
    </div>

    <mat-form-field class="full-width-field" appearance="fill">
      <mat-label>Any other comments?</mat-label>
      <textarea matInput formControlName="notes" type="notes"> </textarea>
    </mat-form-field>
    <br />

    <button
      mat-raised-button
      class="positive-button"
      type="submit"
      [disabled]="isSubmitting"
      (click)="onSubmit()"
    >
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
