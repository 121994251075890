import { Component, OnInit } from '@angular/core';
import { ClinicalFormService } from '@ic-monorepo/shared-common';
import { FieldType } from '@ngx-formly/core';

import { Observable, take } from 'rxjs';

@Component({
  selector: 'ic-clinical-form-field-image-map',
  templateUrl: './clinical-form-field-image-map.component.html',
})
export class ClinicalFormFieldImageMapComponent extends FieldType implements OnInit {
  imageURL$: Observable<string>;

  constructor(private clinicalFormService: ClinicalFormService) {
    super();
  }

  ngOnInit(): void {
    this.imageURL$ = this.clinicalFormService
      .getStorageDownloadUrl$(this.model?.[this.key as string] ?? this.field?.props.storageURL)
      .pipe(take(1));
  }

  saveImage(base64ImageOutput: string): void {
    this.formControl.setValue(base64ImageOutput);
  }
}
