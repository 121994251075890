import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'apps/frontend/portal/src/environments/environment';

@Pipe({
  standalone: true,
  name: 'devAppendPathToCurrentUrl'
})
export class DevAppendPathToCurrentUrlPipe implements PipeTransform {
  transform(value: string): string {
    if (!environment.production) {
      try {
        const url = new URL(value);
        return `${window.location.protocol}//${window.location.host}${url.pathname}${url.search}`;
      } catch (error) {
        console.error('Invalid URL provided:', value);
        return value;
      }
    }

    return value;
  }
}
