<form [formGroup]="patientEvaluationMeasureForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <h1>Patient Evaluation Measure Form</h1>

    <mat-card class="form-card">
      <mat-card-title>Part 1 - Treatment</mat-card-title>
      <h4 style="text-align: 'right'">Score : {{ getScore('treatment') || 0 }} / 35</h4>

      <p>1. Throughout my treatment I have seen the same doctor:</p>
      <div class="slider-full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>1</div>
          <div>Every time</div>
        </div>
        <mat-slider
          class="slider-width"
          formControlName="seenSameDoctor"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>7</div>
          <div>Not at all</div>
        </div>
      </div>

      <p>2. When the doctor saw me, he or she knew about my case:</p>
      <div class="slider-full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>1</div>
          <div>Very well</div>
        </div>
        <mat-slider
          class="slider-width"
          formControlName="knewAboutCase"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>7</div>
          <div>Not at all</div>
        </div>
      </div>

      <p>3. When I was with the doctor, he or she gave me the chance to talk:</p>
      <div class="slider-full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>1</div>
          <div>As much as I wanted</div>
        </div>
        <mat-slider
          class="slider-width"
          formControlName="chanceToTalk"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>7</div>
          <div>Not at all</div>
        </div>
      </div>

      <p>4. When I did talk to the doctor, he or she listened and understood me:</p>
      <div class="slider-full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>1</div>
          <div>Very much</div>
        </div>
        <mat-slider
          class="slider-width"
          formControlName="listenedAndUnderstood"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>7</div>
          <div>Not at all</div>
        </div>
      </div>

      <p>5. I was given information about my treatment and progress:</p>
      <div class="slider-full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>1</div>
          <div>All I wanted</div>
        </div>
        <mat-slider
          class="slider-width"
          formControlName="treatmentProgressInformation"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>7</div>
          <div>Not at all</div>
        </div>
      </div>
    </mat-card>

    <mat-card class="form-card">
      <mat-card-title>Part 2 - How is your hand now</mat-card-title>

      <h4 style="text-align: 'right'">Score : {{ getScore('hand') || 0 }} / 77</h4>

      <p>1. The feeling in my hand is now:</p>
      <div class="slider-full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>1</div>
          <div>Normal</div>
        </div>
        <mat-slider
          class="slider-width"
          formControlName="handAbsent"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>7</div>
          <div>Absent</div>
        </div>
      </div>

      <p>2. When my hand is cold and/or damp, the pain is now:</p>
      <div class="slider-full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>1</div>
          <div>Non-existent</div>
        </div>
        <mat-slider
          class="slider-width"
          formControlName="handColdDampPain"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>7</div>
          <div>Unbearable</div>
        </div>
      </div>

      <p>3. Most of the time, the pain in my hand is now:</p>
      <div class="slider-full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>1</div>
          <div>Non-existent</div>
        </div>
        <mat-slider
          class="slider-width"
          formControlName="handPain"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>7</div>
          <div>Unbearable</div>
        </div>
      </div>

      <p>4. The duration my pain is present is:</p>
      <div class="slider-full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>1</div>
          <div>Never</div>
        </div>
        <mat-slider
          class="slider-width"
          formControlName="handPainDuration"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>7</div>
          <div>All the time</div>
        </div>
      </div>

      <p>5. When I try to use my hand for fiddly things, it is now:</p>
      <div class="slider-full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>1</div>
          <div>Skilful</div>
        </div>
        <mat-slider
          class="slider-width"
          formControlName="handClumsy"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>7</div>
          <div>Clumsy</div>
        </div>
      </div>

      <p>6. Generally, when I move my hand it is:</p>
      <div class="slider-full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>1</div>
          <div>Flexible</div>
        </div>
        <mat-slider
          class="slider-width"
          formControlName="handFlexible"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>7</div>
          <div>Stiff</div>
        </div>
      </div>

      <p>7. The grip in my hand is now:</p>
      <div class="slider-full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>1</div>
          <div>Strong</div>
        </div>
        <mat-slider
          class="slider-width"
          formControlName="handGrip"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>7</div>
          <div>Weak</div>
        </div>
      </div>

      <p>8. For everyday activities, my hand is now:</p>
      <div class="slider-full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>1</div>
          <div>No problem</div>
        </div>
        <mat-slider
          class="slider-width"
          formControlName="handEverdayActivities"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>7</div>
          <div>Useless</div>
        </div>
      </div>

      <p>9. For my work, my hand is now:</p>
      <div class="slider-full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>1</div>
          <div>No problem</div>
        </div>
        <mat-slider
          class="slider-width"
          formControlName="handWork"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>7</div>
          <div>Useless</div>
        </div>
      </div>

      <p>10. When I look at the appearance of my hand now, I feel:</p>
      <div class="slider-full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>1</div>
          <div>Unconcerned</div>
        </div>
        <mat-slider
          class="slider-width"
          formControlName="handEmbarrassed"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>7</div>
          <div>Embarrassed & self-conscious</div>
        </div>
      </div>

      <p>11. Generally, when I think about my hand I feel:</p>
      <div class="slider-full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>1</div>
          <div>Unconcerned</div>
        </div>
        <mat-slider
          class="slider-width"
          formControlName="handUpset"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>7</div>
          <div>Very upset</div>
        </div>
      </div>
    </mat-card>

    <mat-card class="form-card">
      <mat-card-title>Part 3 - Overall assessment</mat-card-title>

      <h4 style="text-align: 'right'">Score : {{ getScore('overallAssessment') || 0 }} / 21</h4>

      <p>1. Generally, my treatment at the hospital has been:</p>
      <div class="slider-full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>1</div>
          <div>Very satisfactory</div>
        </div>
        <mat-slider
          class="slider-width"
          formControlName="treatmentSatisfactory"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>7</div>
          <div>Very unsatisfactory</div>
        </div>
      </div>

      <p>2. Generally, my hand is now:</p>
      <div class="slider-full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>1</div>
          <div>Very satisfactory</div>
        </div>
        <mat-slider
          class="slider-width"
          formControlName="handSatisfactory"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>7</div>
          <div>Very unsatisfactory</div>
        </div>
      </div>

      <p>3. Bearing in mind my original injury or condition, I feel my hand is now:</p>
      <div class="slider-full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>1</div>
          <div>Better than I expected</div>
        </div>
        <mat-slider
          class="slider-width"
          formControlName="handExpected"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>7</div>
          <div>Worse than I expected</div>
        </div>
      </div>
    </mat-card>

    <div>
      <button
        style="margin: auto; display: block"
        mat-raised-button
        class="positive-button"
        type="submit"
        [disabled]="!valid()"
      >
        {{ isSubmitting ? 'Submitting...' : 'Submit' }}
      </button>
    </div>
  </div>
</form>
