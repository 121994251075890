<ng-container *ngIf="showNavBar$ | async as showNavBar">
  <header *ngIf="!isAuthOrNewEntryRoute && showNavBar">
    <ic-layout-default-header class="app-header bg-main shadow-1 flex w-full"></ic-layout-default-header>
  </header>
</ng-container>

<main [ngClass]="{ 'primeng-override blue-gradient-bg': isRouteMatched }">
  <p-message
    *ngIf="!systemCheckService.cookiesEnabled"
    [text]="enableCookiesMessage"
    styleClass="block"
    severity="error"
  ></p-message>

  <ic-shell>
    <router-outlet class="main-router-outlet"></router-outlet>
  </ic-shell>
</main>

<ic-layout-default-sidebar></ic-layout-default-sidebar>
<ic-file-uploader-panel></ic-file-uploader-panel>
