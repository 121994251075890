export default [
  'dressingYourself',
  'showeringOrBathing',
  'walking1Block',
  'doingHouseworkOrCarryingGroceries',
  'climbingAFlightOfStairsWithoutStopping',
  'HurryingOrJogging',

  'symptomsOfHeartFailureComparedTo2WeeksAGo',
  'frequencyOfSwellingInFeetOrLegsOverPast2Weeks',

  'howMuchHasTheSwellingBotheredYou',
  'howMuchHasFatigueLimitYourAbilities',

  'howMuchHasFatigueBotheredYou',
  'howMuchHasShortnessOfBreathLimitYourAbilities',

  'howMuchHasShortnessOfBreathBotheredYou',
  'howMuchHasShortnessOfBreathAffectYourSleeping',

  'doYouKnowWhoToContactIfHeartFailureSymptomsWorsen',
  'howWellDoYouUnderstandMeasuresToPreventHeartFailureSymptomsFromWorsening',
  'howMuchHasYourHeartFailureLimitYourEnjoymentOfLife',
  'satisfactionWithLivingWithHeartFailureAtItsCurrentState',
  'howOftenDoYouFeelDiscouragedDueToYourHeartFailure',

  'hobbiesAndRecreationalActivity',
  'WorkingOrDoingHouseholdChores',
  'VisitingFamilyAndFriendsOutside',
  'intimateRelationshipsWithLovedOnes',

  'notes'
];
