<div *ngIf="team$ | async as team" class="flex flex-column gap-4">
  <div class="flex flex-column gap-3">
    <span class="text-secondary">
      You are adding this patient to <span class="font-semibold">{{ team.name }}.</span> This will give all members of
      your team access to this patients record.
    </span>

    <div class="flex flex-column">
      <h4 class="text-2xl font-semibold m-0">{{ dialogData.firstName }} {{ dialogData.lastName }}</h4>
      <span class="text-secondary">{{ team.name }}</span>
    </div>
  </div>

  <div
    [ngClass]="{ 'border-brand el-bg-primary-subtle-alt': dialogData.isConfirmed }"
    class="border-solid border-1 border-round-md border-main bg-main-surface p-3"
  >
    <p-checkbox
      [(ngModel)]="dialogData.isConfirmed"
      [binary]="true"
      label="By checking this box, you confirm that you are adding this patient to your team to provide or support the individual patient&#x27;s direct care. This action will be logged."
      data-cy="confirm-access-checkbox"
    >
    </p-checkbox>
  </div>

  <div class="flex justify-content-between">
    <button
      (click)="ref.close()"
      class="p-button-outlined"
      icon="pi pi-arrow-left"
      aria-label="Back"
      type="button"
      label="Back"
      pButton
    ></button>
    <button
      [disabled]="!dialogData.isConfirmed"
      (click)="submit()"
      data-cy="dialog-submit-btn"
      aria-label="Confirm"
      icon="pi pi-check"
      label="Confirm"
      type="button"
      pButton
    ></button>
  </div>
</div>
