<div
  (dragover)="onDragOver($event)"
  (drop)="handleDrop($event)"
  class="border-dashed border-round border-2 border-brand-subtle p-4"
  data-cy="file-dropzone"
>
  <div class="flex flex-column align-items-center">
    <div class="border-circle el-bg-primary-subtle p-3">
      <i class="pi pi-cloud-upload text-5xl"></i>
    </div>

    <h3><strong>Drag & drop files here</strong></h3>

    <p class="mt-0">or</p>

    <div class="flex sm:flex-row flex-column gap-3">
      <button
        (click)="triggerFileUpload()"
        aria-label="Choose files"
        label="Choose files"
        icon="pi pi-upload"
        type="file"
        pButton
      ></button>
      <button
        (click)="menu.toggle($event)"
        data-cy="capture-media-menu-button"
        aria-label="Capture media"
        class="p-button-outlined"
        data-cy="capture-media-menu-button"
        type="button"
        pButton
      >
        <div class="flex align-items-center gap-3">
          <span class="flex align-items-center">
            <i class="pi pi-plus mr-2"></i> Capture media
          </span>
          <i class="pi pi-angle-down"></i>
        </div>
      </button>
    </div>
    <div class="flex flex-column gap-3">
      <div class="card flex justify-content-center">
        <p-menu [model]="captureMenuItems" [popup]="true" appendTo="body" #menu></p-menu>
      </div>
    </div>
  </div>
</div>

<p class="text-center">Files must be less than 2 GB</p>
