import { Injectable } from '@angular/core';
import {
  AttachmentCounts,
  ClinicianSubmissionFlowItem,
  ClinicianSubmissionState,
  FormState,
  ListViewType,
} from '@ic-monorepo/shared-submissions';
import {
  CollectionWithId,
  FormWithId,
  GroupedFormWithTeamReference,
  PatientWithId,
} from '@islacare/ic-types';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import {
  SubmissionFlowMedia,
  UploadStatus,
} from '../../feature-patient-submission/store/submission-flow.store';
import { Consent } from '../services/submission-flow-controls/submission-flow-controls.service';

export const defaultState: ClinicianSubmissionState = {
  currentComponentIndex: 0,
  submitting: false,
  loading: false,
  error: '',
  collection: null,
  patient: null,
  consent: null,
  submissionFlowSteps: [],
  formsList: [],
  attachedFiles: [],
  selectedForms: [],
  listViewType: 'card',
  defaultCollectionForms: [],
};

@Injectable()
export class ClinicianSubmissionStore extends ComponentStore<ClinicianSubmissionState> {
  readonly full$: Observable<ClinicianSubmissionState> = this.select(
    (state) => state
  );
  readonly loading$: Observable<boolean> = this.select(
    (state) => state.loading
  );
  readonly submitting$: Observable<boolean> = this.select(
    (state) => state.submitting
  );
  readonly consent$: Observable<Consent> = this.select(
    (state) => state.consent
  );
  readonly listViewType$: Observable<ListViewType> = this.select(
    (state) => state.listViewType
  );
  readonly error$: Observable<string> = this.select((state) => state.error);
  readonly patient$: Observable<PatientWithId> = this.select(
    (state) => state.patient
  );
  readonly collection$: Observable<CollectionWithId> = this.select(
    (state) => state.collection
  );
  readonly currentComponentIndex$: Observable<number> = this.select(
    (state) => state.currentComponentIndex
  );
  readonly submissionFlowSteps$: Observable<ClinicianSubmissionFlowItem[]> =
    this.select((state) => state.submissionFlowSteps);
  readonly currentFlowStep$: Observable<ClinicianSubmissionFlowItem> =
    this.select(
      (state) => state.submissionFlowSteps[state.currentComponentIndex]
    );
  readonly formsList$: Observable<GroupedFormWithTeamReference[]> = this.select(
    (state) => state.formsList
  );
  readonly selectedFormsState$: Observable<FormState[]> = this.select(
    (state) => state.selectedForms
  );
  readonly isSubmitStep$: Observable<boolean> = this.select(
    (state) =>
      state.submissionFlowSteps.length === state.currentComponentIndex + 1
  );
  readonly attachedFiles$: Observable<SubmissionFlowMedia[]> = this.select(
    (state) => state.attachedFiles
  );
  readonly hasAttachedFiles$: Observable<boolean> = this.select(
    (state) => state.attachedFiles.length > 0
  );
  readonly attachmentCounts$: Observable<AttachmentCounts> = this.select(
    (state) => ({
      fileCount: state.attachedFiles.filter(
        (file) => file.uploadStatus !== UploadStatus.UPLOADED
      ).length,
      formCount: state.selectedForms.length,
      incompletedFormCount: state.selectedForms
        .filter(
          (form) => form.completed === false || form.completed === undefined
        )
        .reduce((count) => count + 1, 0),
      submittedFiles: state.attachedFiles.filter(
        (file) => file.uploadStatus == UploadStatus.UPLOADED
      ).length,
    })
  );
  readonly defaultCollectionForms$: Observable<FormWithId[]> = this.select(
    (state) => state.defaultCollectionForms
  );

  constructor() {
    super(defaultState);
  }

  reset = this.updater(() => defaultState);

  setLoading = this.updater((state: ClinicianSubmissionState) => ({
    ...state,
    loading: true,
  }));

  clearLoading = this.updater((state: ClinicianSubmissionState) => ({
    ...state,
    loading: false,
  }));

  setSubmitting = this.updater((state: ClinicianSubmissionState) => ({
    ...state,
    submitting: true,
  }));

  clearSubmitting = this.updater((state: ClinicianSubmissionState) => ({
    ...state,
    submitting: false,
  }));

  setError = this.updater((state: ClinicianSubmissionState, error: string) => ({
    ...state,
    error,
  }));

  clearError = this.updater((state: ClinicianSubmissionState) => ({
    ...state,
    error: null,
  }));

  setSubmissionFlowSteps = this.updater(
    (
      state: ClinicianSubmissionState,
      steps: ClinicianSubmissionFlowItem[]
    ) => ({
      ...state,
      submissionFlowSteps: steps,
    })
  );

  setPatient = this.updater(
    (state: ClinicianSubmissionState, patient: PatientWithId) => ({
      ...state,
      patient: patient,
    })
  );

  setCollection = this.updater(
    (state: ClinicianSubmissionState, collection: CollectionWithId) => ({
      ...state,
      collection: collection,
    })
  );

  populateFormsList = this.updater(
    (
      state: ClinicianSubmissionState,
      formsList: GroupedFormWithTeamReference[]
    ) => ({
      ...state,
      formsList: formsList,
    })
  );

  setDefaultCollectionForms = this.updater(
    (state: ClinicianSubmissionState, defaultForms: FormWithId[]) => ({
      ...state,
      defaultCollectionForms: defaultForms,
    })
  );

  updateSelectedForms = this.updater(
    (state: ClinicianSubmissionState, formState: FormState[]) => {
      const selectedFormIds = state.selectedForms.map((form) => form.formId);
      const newFormIds = formState.map((form) => form.formId);

      const updatedSelectedForms = state.selectedForms.filter((form) =>
        newFormIds.includes(form.formId)
      );

      const newForms = formState.filter(
        (form) => !selectedFormIds.includes(form.formId)
      );
      const finalSelectedForms = [...updatedSelectedForms, ...newForms];

      return {
        ...state,
        selectedForms: finalSelectedForms,
      };
    }
  );

  updateForm = this.updater(
    (state: ClinicianSubmissionState, formState: FormState) => {
      const forms = state.selectedForms;
      const formIndex = forms.findIndex(
        (form) => form.formId === formState.formId
      );
      if (formIndex !== -1) {
        const updatedForm = { ...forms[formIndex], ...formState };
        forms[formIndex] = updatedForm;
      }

      return { ...state, selectedForms: forms };
    }
  );

  removeForm = this.updater(
    (state: ClinicianSubmissionState, formState: FormState) => {
      const forms = state.selectedForms;
      const formIndex = forms.findIndex(
        (form) => form.formId === formState.formId
      );
      if (formIndex !== -1) {
        forms.splice(formIndex, 1);
      }

      return { ...state, selectedForms: forms };
    }
  );

  insertAttachedFile(newFile: SubmissionFlowMedia) {
    this.setState((state) => ({
      ...state,
      attachedFiles: [...state.attachedFiles, newFile],
    }));
  }

  insertAttachedFiles(newFiles: SubmissionFlowMedia[]) {
    this.setState((state) => ({
      ...state,
      attachedFiles: [...state.attachedFiles, ...newFiles],
    }));
  }

  updateAttachedFileStatus(
    attachedFile: SubmissionFlowMedia,
    status: UploadStatus
  ) {
    this.setState((state) => {
      const fileIndex = state.attachedFiles.findIndex(
        (file) => file.id === attachedFile.id
      );
      if (fileIndex !== -1) {
        const updatedFiles = [...state.attachedFiles];
        updatedFiles[fileIndex] = {
          ...updatedFiles[fileIndex],
          uploadStatus: status,
        };
        return { ...state, attachedFiles: updatedFiles };
      }
      return state;
    });
  }

  removeAttachedFile(fileToRemove: SubmissionFlowMedia) {
    this.setState((state) => ({
      ...state,
      attachedFiles: state.attachedFiles.filter(
        (file) => file !== fileToRemove
      ),
    }));
  }

  setAttachedFilesOrder(files: SubmissionFlowMedia[]): void {
    this.setState((state) => ({ ...state, attachedFiles: files }));
  }

  setListViewType = this.updater(
    (state: ClinicianSubmissionState, type: ListViewType) => ({
      ...state,
      listViewType: type,
    })
  );

  readonly disableNextButton$: Observable<boolean> = this.select(
    (state) =>
      state.currentComponentIndex >= state.submissionFlowSteps.length - 1
  );

  setCurrentComponentIndex = this.updater(
    (state: ClinicianSubmissionState, index: number) => ({
      ...state,
      currentComponentIndex: index,
    })
  );

  decrementCurrentComponentIndex = this.updater(
    (state: ClinicianSubmissionState) => ({
      ...state,
      currentComponentIndex: state.currentComponentIndex - 1,
    })
  );

  incrementCurrentComponentIndex = this.updater(
    (state: ClinicianSubmissionState) => ({
      ...state,
      currentComponentIndex: state.currentComponentIndex + 1,
    })
  );

  readonly consentValid$: Observable<boolean> = this.select(
    (state) => !!state.consent
  );

  setConsent = this.updater(
    (state: ClinicianSubmissionState, consent: Consent) => ({
      ...state,
      consent: consent,
    })
  );
}
