<div class="dialog-content align-items-center justify-content-center">
  <qr-code [value]="qrCodeLink" size="300" errorCorrectionLevel="M"></qr-code>
</div>

<div class="dialog-footer justify-content-between">
  <button
    (click)="closeDialog()"
    class="p-button-outlined"
    icon="pi pi-arrow-left"
    aria-label="Back"
    type="button"
    label="Back"
    pButton
  ></button>
  <button pButton type="button" label="Done" aria-label="Done" (click)="done()"></button>
</div>
