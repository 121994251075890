<div *ngIf="data" class="flex align-items-center justify-content-center h-full w-full">
  <ng-container *ngIf="!!mediaToCapture; else capturingMedia">
    <p-confirmDialog [closable]="false"></p-confirmDialog>

    <div class="flex flex-column gap-2">
      <ic-captured-media-card
        [viewType]="mediaCardViewType.PREVIEW"
        [media]="mediaToCapture"
        [hideNotesForm]="data?.hideNotesForm"
        (remove)="removeMedia()"
      ></ic-captured-media-card>
      <button
        label="Confirm"
        icon="pi pi-check"
        class="mt-1 p-button-success"
        aria-label="Confirm capture"
        type="button"
        (click)="close()"
        data-cy="confirm-capture-button"
        pButton
      ></button>
      <button
        [label]="'Retake ' + getCaptureTypeFriendly(mediaToCapture.captureType)"
        [attr.aria-label]="'Retake ' + getCaptureTypeFriendly(mediaToCapture.captureType)"
        class="p-button-outlined"
        type="button"
        (click)="removeMedia()"
        pButton
      ></button>
    </div>
  </ng-container>

  <ng-template #capturingMedia>
    <ic-capture-container
      [efCaptureType]="data.captureType"
      (capture)="setMediaToCapture($event)"
      class="w-full"
    ></ic-capture-container>
  </ng-template>
</div>
