export default [
  'nextOfKinName',
  'nextOfKinRelationship',
  'nextOfKinContact',
  'emergencyContact',
  'ethnicity',
  'smoking',
  'alcohol',
  'presentingIssues',
  'patientPerceivedProblems',
  'pastMedicalHistory',

  // Allergies as a loop from pain form
  'allergyMedicines',
  'allergyMedicineReaction',
  'allergyMedicineDate',
  // Medication history as a loop from pain from
  'medications',
  'drugDose',
  'drugFrequency',

  // Allergy medicines free text
  'allergyMedicinesFreeText',

  //falls
  'fallsInPast12Months',
  'fallsIndicators',
  'fallsComments',
  //General Mobility
  'generalMobility',
  'generalMobilityComments',
  'reasonForPhysiotherapistReferral',
  // Frailty
  'rockwoodScore',
  'frailtycomments',
  // Red flag
  'redFlagAlerts',
  'redFlagComments',
  // General skin condition
  'generalSkinCondition',
  'generalSkinConditionComments',
  // Level of Continence
  'continenceAssessment',
  'continenceComments',
  'commnuityNursing',
  //pain
  'painDescription',
  'painLocation',
  //social history
  'livesWith',
  'support',
  // function and transfers
  'needsAssistanceWith',
  'assistanceComments',
  'needEquipment',
  'reasonForOTReferral',
  'doTheyLiveInMerton',
  // Mental health and wellbeing
  'cognition',
  'mood',
  'pastHistoryOf6CITorOther',
  'GDS',
  // summary and action plan
  'carePlanGiven',
  'admissionPreventionPlanGiven',
  'informationProvided',

  //
  'medicineName'
];
