import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { FormWithId } from '@islacare/ic-types';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'ic-pad-adult-thoracic-form',
  templateUrl: './pad-adult-thoracic-form.component.html',
  styleUrls: ['./pad-adult-thoracic-form.component.scss'],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class PadAdultThoracicFormComponent implements OnInit {
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  entryForm: UntypedFormGroup;
  isSubmitting = false;
  isSensitive: boolean;
  form$: Observable<FormWithId>;

  surgicalWoundAssessmentList: string[] = [
    // "Dry, intact & exposed",
    // "Exudate (fluid) present - serous or haemoserous",
    // "Exudate (fluid) present - thick/cloudy/pus",
    'Minor gaping (opening) along the incision',
    'Some slough evident',
    'Bruising near the incision',
    'Skin tear/blister noted near the incision',
    'Signs of inflammation (heat/redness/swelling/pain)',
    'Usually irritated reddish surrounding of drain site, NO signs of inflammation',
    'Eschar (scab) and/or dark necrotic tissue noted',
    'Dressing applied after assessment',
    'Topical negative pressure dressing applied after assessment',
  ];

  additionalResourcesList: string[] = [
    'Antimicrobial wash provided. Please use as directed. Not suitable for prolonged use.',
    "Antimicrobial wipes (single use/disposable wipes); please use this in the evening for 'freshen up'. No need to rinse.",
    'Advised to use body lotion to prevent skin drying out. Please DO NOT apply any products directly to wound bed.',
    'Additional surgical bra provided.',
    'Gauze pack(s) provided for under bra band.',
    'Extra dressing(s) provided.',
    'Post-op Opsite as a waterproof dressing.',
    'Additional Portex bag for patients with drains inserted.',
  ];

  ngOnInit(): void {
    this.form$ = this.form.getForm(this.data.teamId, this.data.formId);

    this.entryForm = this.fb.group({
      // dateOfDischarge: ['', [Validators.required, Boolean]],
      dateOfDischarge: [
        new Date().toISOString().substring(0, 10),
        [Validators.required],
      ],
      ward: ['', [Validators.required]],
      category: ['', [Validators.required]],
      surgicalWound: [''],
      woundClosureMaterial: ['', [Validators.required]],
      woundClosureRemovalDate: [''],
      woundClosureRemovalNeededBy: [''],
      drainClinicAppointment: [''],
      drainClinicAppointmentDate: [''],
      surgicalWoundAssessment: ['', [Validators.required]],
      microbiologyResults: [''],
      drainSites: [''],
      drainSuturesRemovalDate: [''],
      notes: ['', [Validators.required]],
      additionalResources: [''],
      responsibleSurgeon: ['', [Validators.required]],
      staffProvidingPad: ['', [Validators.required]],
      chaperonePresent: ['', [Validators.required]],
      sensitiveImage: ['', [Validators.required]],
    });

    this.form.retreive$.pipe(take(1)).subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.entryForm.controls[key])
          this.entryForm.controls[key].setValue(formData[key]);
      });
      const sensitive = this.entryForm.get('sensitiveImage').value;
      this.setSensitiveImage(sensitive);
    });

    this.entryForm.get('category').setValue('Adult Thoracic');
  }

  get dateOfDischarge() {
    return this.entryForm.get('dateOfDischarge');
  }
  get ward() {
    return this.entryForm.get('ward');
  }
  get category() {
    return this.entryForm.get('category');
  }
  get surgicalWound() {
    return this.entryForm.get('surgicalWound');
  }
  get woundClosureMaterial() {
    return this.entryForm.get('woundClosureMaterial');
  }
  get surgicalWoundAssessment() {
    return this.entryForm.get('surgicalWoundAssessment');
  }
  get notes() {
    return this.entryForm.get('notes');
  }
  get responsibleSurgeon() {
    return this.entryForm.get('responsibleSurgeon');
  }
  get staffProvidingPad() {
    return this.entryForm.get('staffProvidingPad');
  }
  get chaperonePresent() {
    return this.entryForm.get('chaperonePresent');
  }

  drainSitesConditionalValidation(selectedValue) {
    if (selectedValue === 'Drain still in situ') {
      this.entryForm
        .get('drainSiteSutureRemovalDate')
        .setValue(new Date().toISOString().substring(0, 10));
    }
  }

  woundClosureMaterialValidation() {
    if (
      this.entryForm
        .get('woundClosureMaterial')
        .value.indexOf('Absorbable sutures') !== -1 ||
      this.entryForm
        .get('woundClosureMaterial')
        .value.indexOf('Glue/tissue adhesive (eg. Dermabond)') !== -1 ||
      this.entryForm
        .get('woundClosureMaterial')
        .value.indexOf('Wound closure tapes (eg. Steri-Strips)') !== -1
    ) {
      this.entryForm.get('woundClosureRemovalNeededBy').setValue('');
    }
  }

  onSubmit() {
    if (!this.valid()) return;

    this.isSubmitting = true;
    const formResponse = {
      ...this.entryForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  setSensitiveImage(status: boolean) {
    if (typeof status === 'string') {
      this.form.setSensitiveImage(false);
      this.isSensitive = false;
    } else if (status || status === false) {
      this.form.setSensitiveImage(status);
      this.isSensitive = status;
    } else {
      this.isSensitive = !this.isSensitive;
      this.form.setSensitiveImage(this.isSensitive);
    }
  }

  valid(): boolean {
    return (
      this.entryForm.dirty &&
      !(
        this.entryForm.controls &&
        this.entryForm.controls['dateOfDischarge'].errors
      ) &&
      !(this.entryForm.controls && this.entryForm.controls['ward'].errors) &&
      !(
        this.entryForm.controls && this.entryForm.controls['category'].errors
      ) &&
      !(
        this.entryForm.controls &&
        this.entryForm.controls['woundClosureMaterial'].errors
      ) &&
      !(
        this.entryForm.controls &&
        this.entryForm.controls['surgicalWoundAssessment'].errors
      ) &&
      !(this.entryForm.controls && this.entryForm.controls['notes'].errors) &&
      !(
        this.entryForm.controls &&
        this.entryForm.controls['responsibleSurgeon'].errors
      ) &&
      !(
        this.entryForm.controls &&
        this.entryForm.controls['staffProvidingPad'].errors
      ) &&
      !(
        this.entryForm.controls &&
        this.entryForm.controls['chaperonePresent'].errors
      ) &&
      !this.isSubmitting
    );
  }
}
