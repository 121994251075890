import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Timestamp } from '@firebase/firestore-types';
import { LoggingService } from '@ic-monorepo/services';
import {
  Collection,
  CollectionAuth,
  CollectionWithId,
  Schedule,
  UserWithId,
} from '@islacare/ic-types';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import firebase from 'firebase/compat/app';
import { groupBy, intersection } from 'lodash-es';
import { Observable, combineLatest, from, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { EntriesService, EntryWithUrl } from '../entries/entries.service';

export interface CollectionWithEntries extends CollectionWithId {
  entries: EntryWithUrl[];
}

const mixInto =
  (collection: CollectionWithId) =>
  (entries: EntryWithUrl[]): CollectionWithEntries => ({
    ...collection,
    entries,
  });

@Injectable({
  providedIn: 'root',
})
export class CollectionsService {
  constructor(
    private db: AngularFirestore,
    private entries: EntriesService,
    private log: LoggingService
  ) {}

  getCollections(patientId: string): Observable<CollectionWithId[]> {
    return this.db
      .collection<Collection>(`patients/${patientId}/collections`, (ref) =>
        ref.where('deleted', '==', false)
      )
      .valueChanges({ idField: 'id' });
  }

  getCollectionSnapshot(
    patientId: string,
    collectionId
  ): Promise<firebase.firestore.DocumentSnapshot<Collection>> {
    return this.db
      .doc<Collection>(`patients/${patientId}/collections/${collectionId}`)
      .ref.get();
  }

  getSortedCollections(patientId: string): Observable<CollectionWithId[]> {
    return this.db
      .collection<Collection>(`patients/${patientId}/collections`, (ref) =>
        ref.where('deleted', '==', false).orderBy('lastUpdated', 'desc')
      )
      .valueChanges({ idField: 'id' });
  }

  getCollectionsWithEntries = (
    patientId: string,
    imageSize: string,
    user: UserWithId
  ): Observable<CollectionWithEntries[]> => {
    return this.getCollections(patientId).pipe(
      switchMap((collections) =>
        combineLatest(
          collections
            .filter(
              (collection) =>
                collection.organisationId === user.organisationId ||
                this.isCollectionReadOnly(collection, user)
            )
            .map((collection) =>
              this.entries
                .getEntriesWithUrls(patientId, collection.id, imageSize)
                .pipe(map(mixInto(collection)))
            )
        )
      )
    );
  };

  byPatientId(collection: Collection[]): { [patientId: string]: Collection[] } {
    return groupBy(collection, 'patientId');
  }

  getCollection(
    patientId: string,
    collectionId: string
  ): Observable<CollectionWithId> {
    return this.db
      .doc<CollectionWithId>(
        `patients/${patientId}/collections/${collectionId}`
      )
      .valueChanges();
  }

  updateCollection(
    patientId: string,
    collectionId: string,
    updates: Partial<Collection>
  ) {
    return this.db
      .doc<Collection>(`patients/${patientId}/collections/${collectionId}`)
      .set(updates as Collection, { merge: true })
      .catch((error) => {
        throw error;
      });
  }

  getCollectionDoc = (patientId: string, collectionId: string) => {
    return this.db
      .doc<Collection>(`patients/${patientId}/collections/${collectionId}`)
      .ref.get();
  };

  getSmsBody = async (
    patientId: string,
    collectionId: string
  ): Promise<string> => {
    const collection = await this.getCollectionDoc(patientId, collectionId);
    return collection.get('smsBody');
  };

  getSms = async (patientId: string, collectionId: string): Promise<string> => {
    const collection = await this.getCollectionDoc(patientId, collectionId);
    return collection.get('sms');
  };

  async deleteCollection(patientId: string, collectionId: string) {
    this.log.consoleLog('delete collection started');

    try {
      await this.db
        .doc(`patients/${patientId}/collections/${collectionId}`)
        .update({
          deleted: true,
        })
        .catch((error) => {
          throw error;
        });
    } catch (err) {
      this.log.consoleError(err);
    }
  }

  activeCollectionAuths(
    patientId: string,
    collectionId: string
  ): Observable<boolean> {
    const midnightTonight = new Date();
    midnightTonight.setHours(23, 59, 0, 0);
    const midnightTonightTimestamp =
      firebase.firestore.Timestamp.fromDate(midnightTonight);
    return this.db
      .collection<CollectionAuth>(
        `patients/${patientId}/collections/${collectionId}/collectionAuths`,
        (ref) => ref.where('expiresAt', '>', midnightTonightTimestamp)
      )
      .valueChanges()
      .pipe(
        switchMap((collectionAuths) => {
          return collectionAuths.length === 0 ? of(false) : of(true);
        })
      );
  }

  activeSchedule(patientId: string, collectionId: string): Observable<boolean> {
    return this.db
      .doc<Collection>(`patients/${patientId}/collections/${collectionId}`)
      .valueChanges()
      .pipe(
        switchMap((collection) => {
          const lastscheduledItemToDate = new Date(
            (collection?.dateOfLastScheduledItem as Timestamp)?.seconds * 1000
          );
          if (
            // There is still an outstanding scheduled item in the future:
            endOfDay(lastscheduledItemToDate) >= endOfDay(new Date())
          ) {
            return of(true);
          } else {
            return of(false);
          }
        })
      );
  }

  scheduleStatus(
    { startDate, frequency, duration, keepLinkSevenDays }: Schedule,
    today: Date = new Date()
  ): 'inactive' | 'active' {
    if (!startDate || !duration || !frequency) {
      return 'inactive';
    }
    const start = new Date(
      (startDate as unknown as firebase.firestore.Timestamp)?.seconds * 1000
    );

    let extraDays = 0;
    if (keepLinkSevenDays === true) {
      extraDays = 7;
    }

    const interval = {
      start: startOfDay(start),
      end: endOfDay(addDays(start, frequency * (duration - 1) + extraDays)),
    };
    if (today <= interval.end) {
      return 'active';
    }
    if (today > interval.end) {
      return 'inactive';
    }
  }

  // @Deprecated please use setCollectionDisplayOrder$
  setCollectionDisplayOrder(patientId, collectionId, boolean) {
    // Set the custom order boolean on the collection
    const collectionDocPath = `patients/${patientId}/collections/${collectionId}`;
    this.db.doc(collectionDocPath).update({
      customDisplayOrder: boolean,
    });
  }

  setCollectionDisplayOrder$(patientId, collectionId, boolean) {
    // Set the custom order boolean on the collection
    const collectionDocPath = `patients/${patientId}/collections/${collectionId}`;
    return from(
      this.db.doc(collectionDocPath).update({
        customDisplayOrder: boolean,
      })
    );
  }

  async checkCollectionDisplayOrder(patientId: string, collectionId: string) {
    const collectionDisplayOrder = (
      await this.getCollectionDoc(patientId, collectionId)
    ).get('customDisplayOrder');
    const isCustomOrder = collectionDisplayOrder ? true : false;
    return isCustomOrder;
  }

  //Same function is copied to entries service
  isCollectionReadOnly(collection: Collection, user: UserWithId) {
    return (
      !user?.organisationIds?.includes(collection?.organisationId) &&
      !user?.teamIds?.includes(collection?.teamId) &&
      (intersection(collection?.readOnlyTeamIds, user?.teamIds).length > 0 ||
        user?.patientShareOrganisationIds?.includes(collection?.organisationId))
    );
  }

  isCollectionLocked(collection: Collection, user: UserWithId) {
    return (
      !user?.organisationIds?.includes(collection?.organisationId) &&
      !user?.patientShareOrganisationIds?.includes(
        collection?.organisationId
      ) &&
      !user?.teamIds?.includes(collection?.teamId) &&
      intersection(collection?.readOnlyTeamIds || [], user?.teamIds).length ===
        0
    );
  }

  getCollection$(patientId: string, id: string): Observable<CollectionWithId> {
    return this.db
      .doc<Collection>(`patients/${patientId}/collections/${id}`)
      .valueChanges()
      .pipe(
        map((collection) => ({
          ...collection,
          id,
        }))
      );
  }
}
