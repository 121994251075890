import { Injectable } from '@angular/core';

export interface TabLabels {
  title: string;
  icon: string;
}

@Injectable({
  providedIn: 'root'
})
export class ClinicianSubmissionTextsService {
  consentConfirm = `<p>Please confirm that you have obtained consent for any data submitted to be accessed by clinicians directly involved in the care of</p>`;
  consentMoreInfo = `<small>For information regarding our commitment to the safe storage of personal data, please review our <a href="https://www.islacare.co.uk/privacy/" target="_blank">privacy policy</a>.</small>`;
  consentNoConsent = `<p>You have stated that you do not consent for data to be collected, please specify:</p>`;
  closeInProgressSubmission = `Outstanding items will not be uploaded and any progress will be lost.`;

  tabLabels: TabLabels[] = [
    { title: 'Photos, videos and files', icon: 'pi pi-images' },
    { title: 'Forms', icon: 'pi pi-file' },
    { title: 'Media', icon: 'pi pi-images' }
  ];
}
