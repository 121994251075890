<span [class.hidden]="!formState?.user && field?.props?.showForLoggedInUsersOnly" class="relative">
  <button
    *ngIf="formState?.isEditing"
    #btn
    aria-label="Edit section"
    label="Edit section"
    type="button"
    pButton
    icon="pi pi-ellipsis-v"
    (click)="menu.toggle($event)"
    class="p-button-rounded p-button-text p-button-secondary absolute bottom-100 left-100 ml-4"
  ></button>
  <p-tieredMenu #menu [model]="items" [popup]="true" appendTo="body"></p-tieredMenu>

  <div class="p-4">
    <h5 class="block text-xl font-semibold m-0" *ngIf="props?.label">{{ props?.label }}</h5>
    <h4 *ngIf="props?.subtitle">{{ props?.subtitle }}</h4>

    <ng-container #fieldComponent></ng-container>
  </div>
</span>
