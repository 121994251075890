import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MultiCollection } from '@islacare/ic-types';
import { environment } from 'apps/frontend/portal/src/environments/environment';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import { ToastService, ToastType } from '../../shared/services/toast/toast.service';

interface FormDataObject {
  name: string;
  id: string;
  teamId?: string;
}

interface FormObject {
  [key: string]: FormDataObject;
}
interface PDFParams {
  patientId: string;
  collectionId: string;
  entryIdArray: string[];
  formData: FormObject;
  withCommentsFlag?: boolean;
  isPasswordProtected?: boolean;
  pdfPassword?: number;
  imageSize?: string;
  isEnhancedConsentPdf?: boolean;
  enhancedConsentPdfData?: any;
  multiCollections?: MultiCollection[];
}

@Injectable({
  providedIn: 'root',
})
export class PdfGeneratorService {
  constructor(
    private sanitizer: DomSanitizer,
    private readonly toastService: ToastService,
  ) {}

  async generatePdf(
    patientId: string,
    collectionId: string,
    entryIdArray: string[],
    formData: FormObject,
    withCommentsFlag: boolean,
    isPasswordProtected: boolean,
    pdfPassword?: number,
    titles?: any,
    isEnhancedConsentPdf?: boolean,
    enhancedConsentPdfData?: any,
    hasSections?: Record<string, boolean>,
    collectionIdsWithEntries?: MultiCollection[],
  ) {
    const pdfGenerator = firebase.app().functions(environment.region).httpsCallable('pdfGenerator');

    if (Object.keys(formData).includes('default')) titles['notes'] = 'Notes';

    try {
      const result = await pdfGenerator({
        patientId,
        collectionId,
        entryIdArray,
        formData,
        withCommentsFlag,
        isPasswordProtected,
        pdfPassword,
        titles,
        isEnhancedConsentPdf,
        enhancedConsentPdfData,
        hasSections,
        collectionIdsWithEntries,
      });
      const base64response = await fetch(`data:application/pdf;base64,${result.data}`);
      const blob = await base64response.blob(); //converting to blob because base64 string of large pdfs was not bound completely in object tag
      const file = new Blob([blob], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      return {
        fileURL: this.sanitizer.bypassSecurityTrustResourceUrl(fileURL + '#toolbar=0'),
        fileObjectURL: fileURL,
        Base64: result.data,
      };
    } catch (err) {
      this.toastService.open(ToastType.Error, 'PDF generation unsuccessful.');
      throw err;
    }
  }

  async sendEmailToPatient(organisationName: string, fileName: string, pdfParams: PDFParams) {
    const sendEncryptedPdfToPatient = firebase
      .app()
      .functions(environment.region)
      .httpsCallable('sendEncryptedPdfToPatient');

    return await sendEncryptedPdfToPatient({
      organisationName,
      fileName,
      pdfParams,
    });
  }

  async sendSmsToPatient(
    patientId: string,
    createdForOrg: string,
    to: string,
    smsText: string,
    entryIdArray: string[],
  ) {
    const sendSmsForPdfPassword = firebase.app().functions(environment.region).httpsCallable('sendSmsForPdfPassword');

    try {
      return await sendSmsForPdfPassword({
        patientId,
        createdForOrg,
        to,
        smsText,
        entryIdArray,
      });
    } catch (err) {
      return err;
    }
  }
}
