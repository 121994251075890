import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { PrimeNgModule } from '../../modules/primeng.module';

@Component({
  standalone: true,
  selector: 'ic-image-draw',
  templateUrl: './image-draw.component.html',
  styleUrls: ['./image-draw.component.scss'],
  imports: [CommonModule, PrimeNgModule],
})
export class ImageDrawComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() src: string;
  @Output() imageOutputBase64: EventEmitter<string> = new EventEmitter();

  @ViewChild('canvas') canvasRef!: ElementRef;
  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D;

  isDrawing = false;
  backgroundImage: HTMLImageElement;

  originalSrc: string;

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent): void {
    if (event.target === this.canvas) {
      this.startDrawing(event.touches[0]);
    }
  }

  @HostListener('touchmove', ['$event'])
  onTouchMove(event: TouchEvent): void {
    if (event.target === this.canvas) {
      this.draw(event.touches[0]);
    }
  }

  @HostListener('touchend')
  onTouchEnd(): void {
    this.stopDrawing();
  }

  ngOnInit(): void {
    this.loadBackgroundImage();
  }

  ngAfterViewInit(): void {
    this.canvas = this.canvasRef.nativeElement;
    this.context = this.canvas.getContext('2d');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.src && !changes.src.firstChange) {
      this.loadBackgroundImage();
    }
  }

  startDrawing(e: MouseEvent | Touch): void {
    this.isDrawing = true;
    this.draw(e);
  }

  draw(e: MouseEvent | Touch): void {
    if (!this.isDrawing) return;

    const x = e.clientX - this.canvas.getBoundingClientRect().left;
    const y = e.clientY - this.canvas.getBoundingClientRect().top;

    this.context.lineWidth = 4;
    this.context.strokeStyle = 'red';
    this.context.lineCap = 'round';

    this.context.lineTo(x, y);
    this.context.stroke();
    this.context.beginPath();
    this.context.moveTo(x, y);
  }

  stopDrawing(): void {
    if (!this.isDrawing) return;

    this.isDrawing = false;
    this.context.beginPath();

    const canvasImageData = this.canvas.toDataURL('image/png');
    this.imageOutputBase64.emit(canvasImageData);
  }

  clearCanvas(): void {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.context.drawImage(this.backgroundImage, 0, 0, this.canvas.width, this.canvas.height);

    this.imageOutputBase64.emit(this.originalSrc);
  }

  loadBackgroundImage(): void {
    this.backgroundImage = new Image();
    this.backgroundImage.src = this.src;
    this.backgroundImage.setAttribute('crossorigin', 'anonymous');
    this.backgroundImage.onload = () => {
      this.updateCanvasSize();
      this.context.drawImage(this.backgroundImage, 0, 0, this.canvas.width, this.canvas.height);
      this.originalSrc = this.canvas.toDataURL('image/png');
    };
  }

  private updateCanvasSize(): void {
    const parentContainer = this.canvas.parentElement;
    if (parentContainer) {
      const containerWidth = parentContainer.clientWidth;

      const imageAspectRatio = this.backgroundImage.width / this.backgroundImage.height;
      const canvasWidth = containerWidth;
      const canvasHeight = canvasWidth / imageAspectRatio;

      this.canvas.width = canvasWidth;
      this.canvas.height = canvasHeight;
    }
  }
}
