import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[icOnlyNumber]'
})
export class OnlyNumberDirective {
  //   Allows these numbers 0-9 only on input
  numberKeyCodes = [
    'Digit1',
    'Digit2',
    'Digit3',
    'Digit4',
    'Digit5',
    'Digit6',
    'Digit7',
    'Digit8',
    'Digit9',
    'Digit0'
  ];
  //   Allows backspace and enter on input
  actionKeyCodes = ['Backspace', 'Enter'];

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    return this.numberKeyCodes.includes(event.code) || this.actionKeyCodes.includes(event.code);
  }
}
