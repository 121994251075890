import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-nuca-referral-form',
  templateUrl: './nuca-referral-form.component.html',
  styleUrls: ['./nuca-referral-form.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
})
export class NucaReferralFormComponent implements OnInit {
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  nucaReferralForm: UntypedFormGroup;
  isSubmitting = false;

  ngOnInit(): void {
    this.nucaReferralForm = this.fb.group({
      currentLocation: [''],
      currentConsultant: [''],
      currentClinician: [''],

      priority: [''],
      skinType: [''],
      typeOfLight: [''],
      psoralens: [''],
      site: [''],
      siteDetails: [''],
      diagnosis: [''],
      diagnosisDetails: [''],
      delayStart: [''],
      delayStartReason: [''],
      previousPhototherapy: [''],
      previousPhototherapyDetails: [''],

      pregnancy: [''],
      paediatricPatient: [''],
      systemicLupusErythematous: [''],
      gorlinsSyndrome: [''],
      livedMoreThanOneYearInTropics: [''],
      previousRadiotherapy: [''],
      renalOrHepaticImpairment: [''],
      currentImmunosupression: [''],
      currentImmunosupressionDetails: [''],
      personalHistoryOfSkinCA: [''],
      personalHistoryOfSkinCaDetails: [''],
      adverseReactionsToPsoralens: [''],
      adverseReactionsToPsoralensDetails: [''],
      disabilitiesLanguageInterpreter: [''],
      disabilitiesLanguageInterpreterDetails: [''],
      comorbidities: [''],
      currentMedication: [''],

      treatmentRisksAndSideEffectsExplainedToPatient: [''],
      patientVerballyConsentsToRecordOfTreatmentBeingStored: [''],

      followUpWithConsultantAfterTreatment: [''],
      whenWasTheFollowUpWithConsultant: [''],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.nucaReferralForm.controls[key])
          this.nucaReferralForm.controls[key].setValue(formData[key]);
      });
    });
  }

  onSubmit() {
    this.isSubmitting = true;
    const formResponse = {
      ...this.nucaReferralForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }
}
