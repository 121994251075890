import { ClinicalFormField, ClinicalFormWrapper } from '@islacare/ic-types';

/**
 * @description A new empty formly form that is displayed when you click on create form on the teams page
 */
export const newFormlyForm = [
  {
    type: ClinicalFormField.STEPPER,
    fieldGroup: [
      {
        wrappers: [ClinicalFormWrapper.SECTION],
        props: {
          label: 'New Section Label',
          subtitle: 'New Section Subtitle',
        },
        fieldGroup: [
          {
            wrappers: [ClinicalFormWrapper.GROUP],
            props: {
              label: 'New Group Label',
              subtitle: 'New Group Subtitle',
            },
            fieldGroup: [
              {
                key: 'questionId1',
                type: ClinicalFormField.TEXT,
                props: {
                  label: 'Welcome to your new form',
                  description: 'Edit your form',
                  required: false,
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
