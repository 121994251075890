import { NgModule } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { FocusTrapModule } from 'primeng/focustrap';
import { GalleriaModule } from 'primeng/galleria';
import { ImageModule } from 'primeng/image';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KnobModule } from 'primeng/knob';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from 'primeng/password';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';

const primeNgModules = [
    ChipsModule,
    ContextMenuModule,
    ImageModule,
    PasswordModule,
    ButtonModule,
    MultiSelectModule,
    CardModule,
    TimelineModule,
    MessageModule,
    MessagesModule,
    DynamicDialogModule,
    ProgressSpinnerModule,
    AvatarModule,
    AvatarGroupModule,
    ConfirmPopupModule,
    ToastModule,
    RadioButtonModule,
    DropdownModule,
    SelectButtonModule,
    DividerModule,
    ChartModule,
    CalendarModule,
    InputNumberModule,
    CheckboxModule,
    InputTextModule,
    DialogModule,
    InputSwitchModule,
    InputTextareaModule,
    TableModule,
    SidebarModule,
    PanelModule,
    TagModule,
    TooltipModule,
    ProgressBarModule,
    MenuModule,
    StepsModule,
    KnobModule,
    SkeletonModule,
    TreeTableModule,
    BadgeModule,
    PaginatorModule,
    TreeModule,
    ListboxModule,
    GalleriaModule,
    FileUploadModule,
    InputMaskModule,
    FocusTrapModule,
    TabViewModule,
    TieredMenuModule,
    ConfirmDialogModule,
    AccordionModule,
    ChipModule,
    SplitButtonModule,
    ConfirmDialogModule,
    ScrollPanelModule,
    DividerModule,
    OverlayPanelModule
];

@NgModule({
    exports: [primeNgModules]
})
export class PrimeNgModule { }
