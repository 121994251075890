import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-pain-medication-form',
  templateUrl: './pain-medication-form.component.html',
  styleUrls: ['./pain-medication-form.component.scss'],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class PainMedicationFormComponent implements OnInit {
  @Input() data;
  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  painMedicationForm: UntypedFormGroup;
  painSummaryForm: UntypedFormGroup;
  isSubmitting = false;
  isSensitive: boolean;
  isLinear = true;
  low = true;
  medium = false;
  high = false;
  painDescriptor: string;
  neuroPainSymptomTotalAnswer: number;
  sliderMin = 0;
  sliderMax = 10;
  step = '1';
  tickInterval = '1';

  ngOnInit(): void {
    this.painMedicationForm = this.fb.group({
      drugsCurrentlyTakingForPain: this.fb.array([]),
      drugsCurrentlyTakingForOtherConditions: this.fb.array([]),
      drugsPreviouslyTakenForPain: this.fb.array([]),
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (
          this.painMedicationForm.controls[key] &&
          key !== 'drugsCurrentlyTakingForPain' &&
          key !== 'drugsCurrentlyTakingForOtherConditions' &&
          key !== 'drugsPreviouslyTakenForPain'
        )
          this.painMedicationForm.controls[key].setValue(formData[key]);
      });

      for (
        let i = 0;
        i < formData['drugsCurrentlyTakingForPain']?.length;
        i++
      ) {
        this.addDrugsCurrentlyTakingForPain();
      }

      for (
        let i = 0;
        i < formData['drugsCurrentlyTakingForOtherConditions']?.length;
        i++
      ) {
        this.addDrugsCurrentlyTakingForOtherConditions();
      }

      for (
        let i = 0;
        i < formData['drugsPreviouslyTakenForPain']?.length;
        i++
      ) {
        this.addDrugsPreviouslyTakenForPain();
      }

      this.drugsCurrentlyTakingForPainForm.patchValue(
        formData['drugsCurrentlyTakingForPain']
      );
      this.drugsCurrentlyTakingForOtherConditionsForm.patchValue(
        formData['drugsCurrentlyTakingForOtherConditions']
      );
      this.drugsPreviouslyTakenForPainForm.patchValue(
        formData['drugsPreviouslyTakenForPain']
      );
    });
  }

  get drugsCurrentlyTakingForPainForm() {
    return this.painMedicationForm.get(
      'drugsCurrentlyTakingForPain'
    ) as UntypedFormArray;
  }
  get drugsCurrentlyTakingForOtherConditionsForm() {
    return this.painMedicationForm.get(
      'drugsCurrentlyTakingForOtherConditions'
    ) as UntypedFormArray;
  }
  get drugsPreviouslyTakenForPainForm() {
    return this.painMedicationForm.get(
      'drugsPreviouslyTakenForPain'
    ) as UntypedFormArray;
  }

  addDrugsCurrentlyTakingForPain() {
    const drugCurrentlyTakingForPain = this.fb.group({
      drugName: [''],
      drugDose: [''],
      drugDailyIntake: [''],
    });
    this.drugsCurrentlyTakingForPainForm.push(drugCurrentlyTakingForPain);
  }
  deleteDrugsCurrentlyTakingForPain(i) {
    this.drugsCurrentlyTakingForPainForm.removeAt(i);
  }

  addDrugsCurrentlyTakingForOtherConditions() {
    const drugCurrentlyTakingForOtherConditions = this.fb.group({
      drugName: [''],
      drugDose: [''],
      drugDailyIntake: [''],
    });
    this.drugsCurrentlyTakingForOtherConditionsForm.push(
      drugCurrentlyTakingForOtherConditions
    );
  }

  deleteDrugsCurrentlyTakingForOtherConditions(i) {
    this.drugsCurrentlyTakingForOtherConditionsForm.removeAt(i);
  }

  addDrugsPreviouslyTakenForPain() {
    const drugPreviouslyTakenForPain = this.fb.group({
      drugName: [''],
      drugDose: [''],
      drugDailyIntake: [''],
    });
    this.drugsPreviouslyTakenForPainForm.push(drugPreviouslyTakenForPain);
  }

  deleteDrugsPreviouslyTakenForPain(i) {
    this.drugsPreviouslyTakenForPainForm.removeAt(i);
  }

  onSubmit() {
    this.isSubmitting = true;
    const formResponse = {
      ...this.painMedicationForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }
}
