<div class="w-full">
  <canvas
    aria-label="Canvas to draw on"
    #canvas
    (mousedown)="startDrawing($event)"
    (mousemove)="draw($event)"
    (mouseup)="stopDrawing()"
    (mouseout)="stopDrawing()"
    (touchstart)="onTouchStart($event)"
    (touchmove)="onTouchMove($event)"
    (touchend)="onTouchEnd()"
    [style.touch-action]="'none'"
  ></canvas>
</div>
<div class="flex justify-content-end">
  <button
    (click)="clearCanvas()"
    class="p-button-outlined p-button-danger"
    aria-label="Undo drawing"
    label="Undo drawing"
    pButton
    type="button"
  ></button>
</div>
