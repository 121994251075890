import { Component } from '@angular/core';
import { FieldWrapper, FormlyFieldConfig } from '@ngx-formly/core';
import { MenuItem } from 'primeng/api';
import { ClinicalFormsEditService } from '../../../services/clinical-forms-edit/clinical-forms-edit.service';

@Component({
  selector: 'ic-clinical-form-wrapper-field-group',
  templateUrl: './clinical-form-wrapper-field-group.component.html'
})
export class ClinicalFormWrapperFieldGroupComponent extends FieldWrapper {
  items: MenuItem[] = [
    {
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: () => this.edit()
    },
    {
      label: 'Add group before',
      icon: 'pi pi-arrow-up',
      styleClass: 'el-bg-primary-subtle',
      command: () => this.addGroupBefore()
    },
    {
      label: 'Add group after',
      icon: 'pi pi-arrow-down',
      styleClass: 'el-bg-primary-subtle',
      command: () => this.addGroupAfter()
    }
  ];

  constructor(private formEditService: ClinicalFormsEditService) {
    super();
  }

  isFormGroupValid(field: FormlyFieldConfig): boolean {
    if (field?.key && !this.formState?.isEditing) {
      const isFormFieldInvalid = !field?.formControl?.valid;
      const isFormFieldTouched = field?.formControl?.touched;

      const isFormFieldValidAndUnTouched = !(isFormFieldInvalid && isFormFieldTouched);

      return isFormFieldValidAndUnTouched;
    }

    if (field?.fieldGroup) {
      return field?.fieldGroup.every((formField: FormlyFieldConfig) =>
        this.isFormGroupValid(formField)
      );
    }

    return true;
  }

  edit() {
    this.formEditService.editGroup(this.field);
  }

  addGroupBefore() {
    this.formEditService.addGroup(this.field, true);
  }

  addGroupAfter() {
    this.formEditService.addGroup(this.field, false);
  }
}
