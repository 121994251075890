import { Injectable } from '@angular/core';
import { RadioButtonControl } from '@ic-monorepo/shared-common';

export enum Consent {
  APPROVE = 'CONSENT_APPROVE',
  APPROVE_PATIENTS_BEHALF = 'CONSENT_APPROVE_PATIENTS_BEHALF',
  CONSENT_BEST_INTEREST = 'CONSENT_BEST_INTEREST',
  DECLINE = 'CONSENT_DECLINE',
}
export enum ConsentNoContact {
  REQUEST_NO_CONTACT = 'REQUEST_NO_CONTACT',
  NEVER_CONTACT = 'NEVER_CONTACT',
}
@Injectable({
  providedIn: 'root',
})
export class SubmissionFlowControlsService {
  // Consent
  patientConsent: RadioButtonControl[] = [
    {
      key: Consent.APPROVE,
      label: 'I consent',
      icon: 'pi pi-check-circle',
      color: '#388852',
    },
    {
      key: Consent.APPROVE_PATIENTS_BEHALF,
      label: `I consent on the patient's behalf`,
      icon: 'pi pi-check-circle',
      color: '#388852',
      description: `Patient is not able to provide consent themselves, so you’re consenting in their best interest.`,
    },
    {
      key: Consent.DECLINE,
      label: 'I do not consent',
      icon: 'pi pi-times-circle',
      color: '#D9342B',
    },
  ];

  clinicianConsent: RadioButtonControl[] = [
    {
      key: Consent.APPROVE,
      label: 'Consent obtained from patient',
      icon: 'pi pi-check-circle',
      color: '#388852',
    },
    {
      key: Consent.CONSENT_BEST_INTEREST,
      label: 'Consent not obtained, but acting in best interest',
      icon: 'pi pi-check-circle',
      color: '#818ea1',
    },
  ];

  consentNoContact: RadioButtonControl[] = [
    {
      key: ConsentNoContact.REQUEST_NO_CONTACT,
      label: 'I do not want to be contacted about this request again',
    },
    {
      key: ConsentNoContact.NEVER_CONTACT,
      label: 'I never want to be contacted in this way again',
    },
  ];

  constructor() {}
}
