import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CheckForUpdateService {
  //needs to be private so it can't be written by anything else
  private _updateAvailable$ = new Subject<boolean>();
  private _systemError$ = new Subject<boolean>();

  //this is public and type observable so its read only, other sources can ref this property
  updateAvailable$ = this._updateAvailable$.asObservable();
  systemError$ = this._systemError$.asObservable();

  constructor(private updates: SwUpdate) {
    try {
      //updateAvailable.next(true) // uncomment for testing: this is where the subject emits the value ==true for an observer to subscribe to
      this.watchForUpdates();
      //comment for testing in dev, where service workers are not enabled
    } catch (e) {
      console.log('An error with the Service Worker update occurred');
      console.dir(e);
    }
  }

  watchForUpdates() {
    if (!this.updates?.isEnabled) {
      // avoid errors for browsers which cant handle service workers
      this._updateAvailable$.next(false);
      this._systemError$.next(false);
      return;
    }

    // if the serviceWorker finds and successfully downloads an update,
    this.updates.versionUpdates.subscribe(evt => {
      switch (evt.type) {
        case 'VERSION_READY':
          this._updateAvailable$.next(true);
          break;
        case 'VERSION_DETECTED':
          this._updateAvailable$.next(true);
          break;
        case 'VERSION_INSTALLATION_FAILED':
          this._systemError$.next(true);
          break;
      }
    });

    this.updates.unrecoverable.subscribe(() => {
      this._systemError$.next(true);
    });
  }
}
