<form [formGroup]="poemForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <div class="full-width-field" style="margin: auto">
      <h3>Patient-Oriented Eczema Measure</h3>
    </div>
    <!-- QUESTION ONE -->
    <div class="question-container">
      <div class="half-width-field">
        <p>
          Over the last week, on how many days has your/your child's skin been
          itchy because of the Eczema?
        </p>
      </div>
      <mat-form-field class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select
          formControlName="howManyDaysSkinBeenItchy"
          (selectionChange)="poemQuestion1 = getQuestionScore($event.value)"
        >
          <mat-option [value]="answer" *ngFor="let answer of answerList">
            {{ answer }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="howManyDaysSkinBeenItchy.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <!-- QUESTION TWO -->
    <div class="question-container">
      <div class="half-width-field">
        <p>
          Over the last week, on how many days has your/your child's sleep been
          disturbed because of the Eczema?
        </p>
      </div>
      <mat-form-field class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select
          formControlName="howManyDaysSleepBeenDisturbed"
          (selectionChange)="poemQuestion2 = getQuestionScore($event.value)"
        >
          <mat-option [value]="answer" *ngFor="let answer of answerList">
            {{ answer }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="howManyDaysSleepBeenDisturbed.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <!-- QUESTION THREE -->
    <div class="question-container">
      <div class="half-width-field">
        <p>
          Over the last week, on how many days has your/your child's skin been
          bleeding because of the Eczema?
        </p>
      </div>
      <mat-form-field class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select
          formControlName="howManyDaysSkinBeenBleeding"
          (selectionChange)="poemQuestion3 = getQuestionScore($event.value)"
        >
          <mat-option [value]="answer" *ngFor="let answer of answerList">
            {{ answer }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="howManyDaysSkinBeenBleeding.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <!-- QUESTION FOUR -->
    <div class="question-container">
      <div class="half-width-field">
        <p>
          Over the last week, on how many days has your/your child's skin been
          weeping or oozing clear fluid because of the Eczema?
        </p>
      </div>
      <mat-form-field class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select
          formControlName="howManyDaysSkinBeenOozingClearFluid"
          (selectionChange)="poemQuestion4 = getQuestionScore($event.value)"
        >
          <mat-option [value]="answer" *ngFor="let answer of answerList">
            {{ answer }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="howManyDaysSkinBeenOozingClearFluid.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <!-- QUESTION FIVE -->
    <div class="question-container">
      <div class="half-width-field">
        <p>
          Over the last week, on how many days has your/your child's skin been
          cracked because of the Eczema?
        </p>
      </div>
      <mat-form-field class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select
          formControlName="howManyDaysSkinBeenCracked"
          (selectionChange)="poemQuestion5 = getQuestionScore($event.value)"
        >
          <mat-option [value]="answer" *ngFor="let answer of answerList">
            {{ answer }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="howManyDaysSkinBeenCracked.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <!-- QUESTION SIX -->
    <div class="question-container">
      <div class="half-width-field">
        <p>
          Over the last week, on how many days has your/your child's skin been
          flaking off because of the Eczema?
        </p>
      </div>
      <mat-form-field class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select
          formControlName="howManyDaysSkinBeenFlaking"
          (selectionChange)="poemQuestion6 = getQuestionScore($event.value)"
        >
          <mat-option [value]="answer" *ngFor="let answer of answerList">
            {{ answer }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="howManyDaysSkinBeenFlaking.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <!-- QUESTION SEVEN -->
    <div class="question-container">
      <div class="half-width-field">
        <p>
          Over the last week, on how many days has your/your child's skin felt
          dry or rough because of the Eczema?
        </p>
      </div>
      <mat-form-field class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select
          formControlName="howManyDaysSkinFeltDryOrRough"
          (selectionChange)="poemQuestion7 = getQuestionScore($event.value)"
        >
          <mat-option [value]="answer" *ngFor="let answer of answerList">
            {{ answer }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="howManyDaysSkinFeltDryOrRough.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>
    <mat-form-field appearance="fill" class="notes-field half-width-field">
      <input
        matInput
        formControlName="notes"
        type="notes"
        placeholder="Add any additional comments here"
        autocomplete="off"
      />
    </mat-form-field>
    <br />
    <div class="button-group">
      <button
        class="positive-button"
        mat-raised-button
        color="accent"
        type="submit"
        [disabled]="!poemFormValid()"
      >
        {{ isSubmitting ? 'Submitting...' : 'Submit' }}
      </button>
    </div>
  </div>
</form>
