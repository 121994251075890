import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  collection,
  collectionData,
  CollectionReference,
  Firestore,
  query,
  where
} from '@angular/fire/firestore';
import { StringToCapitalisedCasePipe } from '@ic-monorepo/shared-common';
import {
  AdditionalEmailAddresses,
  Appointment,
  Collection,
  CollectionWithId,
  Entry,
  Patient,
  PatientWithId,
  RequiresAttentionObject,
  TeamWithId
} from '@islacare/ic-types';
import { toUppercaseAndTrim } from '@islacare/ic-types/dist/entityMappers';
import { environment } from 'apps/frontend/portal/src/environments/environment';
import firebase from 'firebase/compat/app';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuditService } from '../../../services/audit/audit.service';
import { EntriesService } from '../../../services/entries/entries.service';
import { TeamsService } from '../../../services/teams/teams.service';
import { ConfirmNgDialogComponent } from '../../components';
import { LoggingService } from '../logging/logging.service';
import { UsersService } from '../users/users.service';
const { arrayRemove, arrayUnion } = firebase.firestore.FieldValue;

export interface PatientWithTeams extends PatientWithId {
  teams: TeamWithId[];
}

export interface PatientWithAppointments extends PatientWithId {
  appointments: Appointment[];
}

export enum PatientExists {
  userHasAccess,
  userInSameOrg,
  userInDiffOrg,
  patientDoesntExist
}

export interface SmsRecipient {
  name: string;
  phone: string;
}
export interface EmailRecipient {
  name: string;
  email: string;
}

@Injectable({
  providedIn: 'root'
})
export class PatientsService {
  constructor(
    private db: AngularFirestore,
    private usersService: UsersService,
    private audit: AuditService,
    private analytics: AngularFireAnalytics,
    private teamsService: TeamsService,
    private log: LoggingService,
    private entriesService: EntriesService,
    private firestore: Firestore,
    private stringToCapitalisedPipe: StringToCapitalisedCasePipe,
    private dialogService: DialogService,
    public config: DynamicDialogConfig
  ) { }

  getPatientsInMyOrganisation$: Observable<PatientWithId[]> =
    this.usersService.me$.pipe(
      switchMap(user => (!user ? of(null) : of(user.organisationId))),
      switchMap(organisationId =>
        organisationId
          ? this.db
            .collection<Patient>('patients', ref =>
              ref.where('organisationIds', 'array-contains', organisationId)
            )
            .valueChanges({ idField: 'id' })
          : of([])
      )
    );

  getPatientsForOrganisation$(
    organisationId: string
  ): Observable<PatientWithId[]> {
    return this.db
      .collection<Patient>('patients', ref =>
        ref.where('organisationIds', 'array-contains', organisationId)
      )
      .valueChanges({ idField: 'id' });
  }

  getUnarchivedPatientsForTeams$(
    teamIds: string[]
  ): Observable<PatientWithId[]> {
    return collectionData<PatientWithId>(
      query<PatientWithId>(
        collection(
          this.firestore,
          'patients'
        ) as CollectionReference<PatientWithId>,
        where('teamIds', 'array-contains-any', teamIds),
        where('isArchived', '==', false)
      ),
      { idField: 'id' }
    );
  }

  getAllPatientsForTeams$(teamIds: string[]): Observable<PatientWithId[]> {
    return this.db
      .collection<Patient>('patients', ref =>
        ref.where('teamIds', 'array-contains-any', teamIds)
      )
      .valueChanges({ idField: 'id' });
  }

  patients$: Observable<PatientWithId[]> = this.db
    .collection<Patient>('patients', ref => ref)
    .valueChanges({ idField: 'id' });

  myPatients$: Observable<PatientWithId[]> = this.usersService.me$.pipe(
    switchMap(me =>
      me == null
        ? of([])
        : this.db
          .collection<Patient>('patients', ref =>
            ref.where('teamIds', 'array-contains-any', me?.teamIds)
          )
          .valueChanges({ idField: 'id' })
    )
  );

  myPatientsUpdatedAfter$(referenceDate: Date): Observable<PatientWithId[]> {
    return this.usersService.me$.pipe(
      switchMap(me =>
        me == null
          ? of([])
          : this.db
            .collection<Patient>('patients', ref =>
              ref
                .where('teamIds', 'array-contains-any', me?.teamIds)
                .where('lastUpdated', '>=', referenceDate)
            )
            .valueChanges({ idField: 'id' })
      )
    );
  }

  myNonArchivedPatients$: Observable<PatientWithId[]> =
    this.usersService.me$.pipe(
      switchMap(me =>
        me == null
          ? of([])
          : this.db
            .collection<Patient>('patients', ref =>
              ref
                .where('teamIds', 'array-contains-any', me?.teamIds)
                .where('isArchived', '==', false)
            )
            .valueChanges({ idField: 'id' })
      )
    );

  myAllPatients$: Observable<PatientWithId[]> = this.usersService.me$.pipe(
    switchMap(me =>
      me == null
        ? of([])
        : this.db
          .collection<Patient>('patients', ref =>
            ref.where('teamIds', 'array-contains-any', me?.teamIds)
          )
          .valueChanges({ idField: 'id' })
    )
  );

  myArchivedPatients$: Observable<PatientWithId[]> = this.usersService.me$.pipe(
    switchMap(me =>
      me == null
        ? of([])
        : this.db
          .collection<Patient>('patients', ref =>
            ref
              .where('teamIds', 'array-contains-any', me?.teamIds)
              .where('isArchived', '==', true)
          )
          .valueChanges({ idField: 'id' })
    )
  );

  getOrganisationPatients$(
    organisationId: string
  ): Observable<PatientWithId[]> {
    return this.db
      .collection<Patient>('patients', ref =>
        ref.where('organisationIds', 'array-contains', organisationId)
      )
      .valueChanges({ idField: 'id' });
  }

  getPatients$ = (teamIds: string[]): Observable<PatientWithId[]> => {
    return this.db
      .collection<Patient>('patients', ref =>
        ref.where('teamIds', 'array-contains-any', teamIds.slice(0, 9))
      )
      .valueChanges({ idField: 'id' });
  };

  getPatientsFromTeamId$ = (teamId: string): Observable<PatientWithId[]> => {
    return this.db
      .collection<Patient>('patients', ref =>
        ref.where('teamIds', 'array-contains', teamId)
      )
      .valueChanges({ idField: 'id' });
  };

  getPatientWithTeams$ = (patientId: string): Observable<PatientWithTeams> =>
    this.getPatient$(patientId).pipe(
      switchMap(patient =>
        this.teamsService.getTeams$(patient?.teamIds).pipe(
          map(teams => ({
            ...patient,
            teams
          }))
        )
      )
    );

  getPatientSnapshot(patientId: string) {
    return this.db.doc<PatientWithId>(`patients/${patientId}`).ref.get();
  }

  async getMostRecentAppointmentFhirId(
    patientId: string,
    organisationId: string
  ) {
    const example = await this.db
      .collection<Appointment>(`patients/${patientId}/appointments`, ref =>
        ref
          .where('organisationId', '==', organisationId)
          .orderBy('start', 'desc')
          .limit(1)
      )
      .get()
      .toPromise();

    if (example.docs[0]) {
      return example.docs[0].data().fhirId;
    } else {
      return '';
    }
  }

  async create(patient: Patient) {
    const patientId = this.db.createId();
    await this.db
      .doc<Patient>(`patients/${patientId}`)
      .set(patient)
      .catch(error => {
        throw error;
      });
    await this.audit.patientCreate(patientId);
  }

  getPatient$(id: string): Observable<PatientWithId> {
    return this.db
      .doc<Patient>(`patients/${id}`)
      .valueChanges()
      .pipe(
        map(patient => ({
          ...patient,
          id
        }))
      );
  }

  async setMembership(patientId: string, teamId: string, isMember: boolean) {
    const patientDoc = this.db.doc(`patients/${patientId}`);
    const { teamIds } = (await patientDoc.get().toPromise()).data() as Patient;

    if (isMember && teamIds.length >= 30) {
      // Firestore melts when you have more than 30 query ids
      throw new Error('A user cannot have more than 30 teams');
    }

    await patientDoc.update({
      teamIds: isMember ? arrayUnion(teamId) : arrayRemove(teamId)
    });

    await this.audit.setPatientMembership(patientId, teamId, isMember);
    await this.analytics.logEvent('add patient to team', { patientId, teamId });
  }

  getPatientNumbersAndSmsIntros(
    additionalPhoneNumbers: any,
    patientFirstName: string,
    patientPhone: string,
    assignedRecipient?: string
  ) {
    let smsIntro;
    const patientFirstNameProper =
      //  `${patientFirstName.charAt(0).toUpperCase()}${patientFirstName
      //   .slice(1)
      //   .toLowerCase()}`;
      this.stringToCapitalisedPipe.transform(patientFirstName);

    if (assignedRecipient) {
      smsIntro = `Dear ${patientFirstNameProper}'s ${assignedRecipient}, `;
      additionalPhoneNumbers['Patient'] = patientPhone;
    } else if (
      !additionalPhoneNumbers ||
      Object.keys(additionalPhoneNumbers).length === 0
    ) {
      smsIntro = `Dear ${patientFirstNameProper}, `;
      additionalPhoneNumbers = {};
      additionalPhoneNumbers['Patient'] = patientPhone;
    } else if (
      // used as a null check
      (patientPhone === '' || patientPhone === '+44') &&
      Object.keys(additionalPhoneNumbers).length === 1
    ) {
      smsIntro = `Dear ${patientFirstNameProper}'s ${Object.keys(
        additionalPhoneNumbers
      )}, `;
    } else if (
      // used as a null check
      (patientPhone === '' || patientPhone === '+44') &&
      Object.keys(additionalPhoneNumbers).length >= 2
    ) {
      smsIntro = 'Hello, ';
    } else if (patientFirstNameProper) {
      smsIntro = `Dear ${patientFirstNameProper}, `;
      additionalPhoneNumbers['Patient'] = patientPhone;
    } else {
      smsIntro = 'Hello, ';
      additionalPhoneNumbers['Patient'] = patientPhone;
    }

    return { smsIntro, additionalPhoneNumbers };
  }

  getPatientEmails(
    additionalEmailAddresses: AdditionalEmailAddresses,
    patientEmail: string
  ): AdditionalEmailAddresses {
    const allPatientEmails = additionalEmailAddresses;
    if (patientEmail) allPatientEmails['Patient'] = patientEmail;
    return allPatientEmails;
  }

  smsRecipients(
    otherPhoneNumbers: any,
    patientFirstName: string,
    patientPhone: string
  ): SmsRecipient[] {
    const { additionalPhoneNumbers } = this.getPatientNumbersAndSmsIntros(
      otherPhoneNumbers,
      patientFirstName,
      patientPhone
    );

    return Object.keys(additionalPhoneNumbers).map(name => ({
      name,
      phone: additionalPhoneNumbers[name]
    }));
  }

  emailRecipients(
    otherEmailAddresses: AdditionalEmailAddresses,
    patientEmail: string
  ): EmailRecipient[] {
    const additionalEmailAddresses = { ...otherEmailAddresses };

    if (patientEmail) additionalEmailAddresses['Patient'] = patientEmail;

    return Object.keys(additionalEmailAddresses).map(name => ({
      name,
      email: additionalEmailAddresses[name]
    }));
  }

  async findExistingPatient(
    nhsNumber: string,
    localId: string,
    organisationId: string,
    teamIds: string[]
  ): Promise<{
    result: PatientExists;
    patientId: string;
  }> {
    let queryResult;
    if (nhsNumber) {
      queryResult = await this.searchExistingPatientWithNhsNumber(nhsNumber);
    } else if (!queryResult?.data && localId) {
      queryResult = await this.searchExistingPatientWithLocalId(
        toUppercaseAndTrim(localId),
        organisationId
      );
    }

    if (queryResult?.data) {
      const queryData: {
        id: string;
        teamIds: string[];
        organisationIds: string[];
      } = queryResult.data;
      // patient already exists
      const patientInSameTeam =
        queryData?.teamIds && teamIds.some(r => queryData?.teamIds.includes(r));
      const patientInSameOrg =
        queryData?.organisationIds &&
        queryData.organisationIds.includes(organisationId);

      if (patientInSameTeam)
        return { result: PatientExists.userHasAccess, patientId: queryData.id };

      if (patientInSameOrg)
        return { result: PatientExists.userInSameOrg, patientId: queryData.id };

      return { result: PatientExists.userInDiffOrg, patientId: queryData.id };
    }

    return { result: PatientExists.patientDoesntExist, patientId: '' };
  }

  searchExistingPatientWithNhsNumber(
    nhsNumber: string
  ): Promise<firebase.functions.HttpsCallableResult> {
    const checkIfPatientExistsInIslaFBFunction = firebase
      .app()
      .functions(environment.region)
      .httpsCallable('checkIfPatientExistsInIsla');

    return checkIfPatientExistsInIslaFBFunction(nhsNumber);
  }

  searchExistingPatientWithLocalId(
    localId: string,
    organisationId: string
  ): Promise<firebase.functions.HttpsCallableResult> {
    const searchExistingPatientWithLocalId = firebase
      .app()
      .functions(environment.region)
      .httpsCallable('searchExistingPatientWithLocalId');

    return searchExistingPatientWithLocalId({ localId, organisationId });
  }

  searchExistingPatientWithNhsAndDob(nhsNumber, dob) {
    const searchExistingPatientWithNhsAndDobFbFunction = firebase
      .app()
      .functions(environment.region)
      .httpsCallable('searchExistingPatientWithNhsAndDob');

    return searchExistingPatientWithNhsAndDobFbFunction({ nhsNumber, dob });
  }

  async setRequiresAttentionObjectOnPatient(
    patientId: string,
    entryTeamIds: string[]
  ) {
    const { requiresAttentionMap } = (
      await this.db.doc(`patients/${patientId}`).get().toPromise()
    ).data() as Patient;

    const requiresAttentionObj = Object.assign({}, requiresAttentionMap);

    const allEntries =
      await this.entriesService.getMyTeamEntriesWithRequiresAttentionTrue(
        patientId,
        entryTeamIds
      );

    //grouping of all the entries (requiresAttention true) accrording to their teamId
    const groupedTeamObj = allEntries.reduce((result, entry) => {
      if (result[entry.entryTeamId]) result[entry.entryTeamId].push(entry);
      else result[entry.entryTeamId] = [entry];

      return result;
    }, {});

    //if a teamId is not present in groupedTeamObj, its count will be 0; otherwise update the count
    entryTeamIds.forEach(entryTeamId => {
      if (!groupedTeamObj[entryTeamId]) requiresAttentionObj[entryTeamId] = 0;
      else
        requiresAttentionObj[entryTeamId] = groupedTeamObj[entryTeamId].length;
    });
    this.setRequiresAttentionMap(patientId, requiresAttentionObj);
  }

  async setRequiresAttentionMap(
    patientId: string,
    requiresAttentionObj: RequiresAttentionObject
  ) {
    await this.db
      .doc<Patient>(`patients/${patientId}`)
      .update({ requiresAttentionMap: requiresAttentionObj })
      .catch(err => {
        this.log.consoleError(err);
      });
  }

  async pdsGetPatientDetailsByNhsNumber(dateOfBirth, nhsNumber, uuid) {
    const sendPatientParamsToPds = firebase
      .app()
      .functions(environment.region)
      .httpsCallable('sendPatientParamsToPds');

    try {
      const result = await sendPatientParamsToPds({
        dateOfBirth,
        nhsNumber,
        uuid
      });
      return result;
    } catch (err) {
      this.log.consoleError('error', err);
    }
  }

  // copied from collectionsService to avoid circular dependency:

  getCollections(patientId: string): Observable<CollectionWithId[]> {
    return this.db
      .collection<Collection>(`patients/${patientId}/collections`)
      .valueChanges({ idField: 'id' });
  }

  async getPatientTeamIds(patientId: string) {
    const patientDoc = this.db.doc(`patients/${patientId}`);
    const { teamIds } = (await patientDoc.get().toPromise()).data() as Patient;
    return teamIds;
  }

  async getPatientOrganisationIds(patientId: string) {
    const patientDoc = this.db.doc(`patients/${patientId}`);
    const { organisationIds } = (
      await patientDoc.get().toPromise()
    ).data() as Patient;
    return organisationIds;
  }

  updatePatientTeamIds(patientId, patientTeamIds) {
    this.db
      .doc<Patient>(`patients/${patientId}`)
      .update({ teamIds: patientTeamIds })
      .catch(err => {
        this.log.consoleError(err);
      });
  }

  updatePatientOrganisationIds(patientId, patientOrganisationIds) {
    this.db
      .doc(`patients/${patientId}`)
      .update({ organisationIds: arrayUnion(...patientOrganisationIds) })
      .catch(err => {
        this.log.consoleError(err);
      });
  }

  getEntryPatientOfMyTeams(patientId, myTeamIds) {
    return this.db
      .collectionGroup<Entry>('entries', ref =>
        ref
          .where('patientId', '==', patientId)
          .where('deleted', '==', false)
          .where('requiresAttention', '==', true)
          .where('entryTeamId', 'in', myTeamIds)
      )
      .valueChanges({ idField: 'id' })
      .pipe(map(entries => entries.length));
  }

  archivePatients(patientIds: any[]) {
    return new Promise(resolve => {
      const dialogRef = this.dialogService.open(ConfirmNgDialogComponent, {
        header: `Are you sure you want to archive the selected ${patientIds.length > 1 ? 'patients' : 'patient'
          }?`,
        width: '55%',
        data: {
          message: `Selected ${patientIds.length > 1 ? 'patients' : 'patient'
            } will be archived for all clinicians responsible for ${patientIds.length > 1 ? `these patients'` : `this patient's`
            } care.`,
          positiveButton: `${patientIds.length > 1
            ? 'Yes, archive patients'
            : 'Yes, archive patient'
            }`,
          negativeButton: 'Cancel',
          patientIds: patientIds,
          action: 'archive',
          isArchived: true
        }
      });

      dialogRef.onClose.subscribe(async result => {
        resolve(true);
      });
    });
  }

  unarchivePatients(patientIds: any[]) {
    return new Promise(resolve => {
      const dialogRef = this.dialogService.open(ConfirmNgDialogComponent, {
        header: `Are you sure you want to unarchive the selected ${patientIds.length > 1 ? 'patients' : 'patient'
          }?`,
        width: '55%',
        data: {
          message: `Selected ${patientIds.length > 1 ? 'patients' : 'patient'
            } will be unarchived for all clinicians responsible for ${patientIds.length > 1 ? `these patients'` : `this patient's`
            } care.`,
          positiveButton: `${patientIds.length > 1
            ? 'Yes, unarchive patients'
            : 'Yes, unarchive patient'
            }`,
          negativeButton: 'Cancel',
          patientIds: patientIds,
          action: 'Unarchive',
          isArchived: false
        }
      });

      dialogRef.onClose.subscribe(async result => {
        resolve(true);
      });
    });
  }

  async updatePatientLocalId(patientId, userOrgId, mrn) {
    await this.db
      .doc<Patient>(`patients/${patientId}`)
      .update({ localIds: { [`${userOrgId}`]: mrn } })
      .catch(err => {
        this.log.consoleError(err);
      });
  }

  async updatePatient(patientId, patient: Partial<Patient>) {
    await this.db
      .doc<Patient>(`patients/${patientId}`)
      .update(patient)
      .catch(err => {
        this.log.consoleError(err);
      });
  }

  async updatePatientFromPds(patient: PatientWithId): Promise<void> {
    if (!patient.phoneUpdatedViaPds) {
      return
    }

    const updatePatientFromPds = firebase
      .app()
      .functions(environment.region)
      .httpsCallable('updatePatientFromPds');

    try {
      await updatePatientFromPds({ patientId: patient.id });
    } catch (err) {
      this.log.consoleError('Error updating patient from PDS: ', err);
    }
  }

  async getPatientsForTeamMembership(
    limit,
    firstDoc,
    lastDoc,
    patientFilter,
    teamsFilter
  ) {
    const getPatientsForTeamMembership = firebase
      .app()
      .functions(environment.region)
      .httpsCallable('getPatientsForTeamMembership');

    try {
      const result = await getPatientsForTeamMembership({
        limit,
        firstDoc,
        lastDoc,
        patientFilter,
        teamsFilter
      });
      return JSON.parse(result.data);
    } catch (err) {
      this.log.consoleError('error', err);
    }
  }

  async isPatientInMyOrganisation(patientOrgIds: string[]) {
    const me = await this.usersService.me();
    return patientOrgIds?.some(
      item => me?.organisationId === item || me?.organisationIds?.includes(item)
    );
  }

  async isPatientInMyTeam(patientTeamIds: string[]) {
    const me = await this.usersService.me();
    return patientTeamIds?.some(item => me?.teamIds?.includes(item));
  }

  getNameToBeDisplayForPhoneNumber(recipient: string) {
    if (!recipient || recipient === 'Patient') {
      return "patient's";
    }

    return `patient's ${recipient}'s`;
  }

  getPatientWithMRN$(mrn: string): Observable<PatientWithId[]> {
    return this.usersService.me$.pipe(
      switchMap(user =>
        user?.organisationId
          ? this.db
            .collection<PatientWithId>('patients', ref => {
              return ref
                .where(`localIds.${user.organisationId}`, '==', mrn)
                .where('teamIds', 'array-contains-any', user.teamIds);
            })
            .valueChanges({ idField: 'id' })
          : of([])
      )
    );
  }

  getPatientWithNHSNumber$(nhsNumber: string): Observable<PatientWithId[]> {
    return this.usersService.me$.pipe(
      switchMap(user =>
        user?.organisationId
          ? this.db
            .collection<PatientWithId>('patients', ref => {
              return ref
                .where('nhs', '==', nhsNumber)
                .where('teamIds', 'array-contains-any', user.teamIds);
            })
            .valueChanges({ idField: 'id' })
          : of([])
      )
    );
  }
}
