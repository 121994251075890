export default [
  'woundBeenPainfulToTouch',
  'howHasThePainBeen',
  'liquidComingFromTheSite',
  'fluidLeakingOverThePastDay',
  'colourAndConsistencyOfTheFluid',
  'rednessSpreadingAwayFromWound',
  'rednessStatus',
  'hasTheAreaAroundTheWoundBecomeSwollen',
  'hasTheAreaAroundTheWoundBecomeSwollenDetails',
  'hasTheWoundedAreaBecomeWarmer',
  'hasTheWoundedAreaBecomeWarmerDetails',
  'hasAnyPartSeparatedOrGaped',
  'hasTheWoundOpenedMoreDetails',
  'hasTheDeeperTissueSeparated',
  'haveYouHadAFever',
  'haveYouBeenDiagnosedWithAnInfectionSinceSurgery'
];
