import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControlOptions, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { matchingStringValidator } from '../../../../utils/custom-validators/matching-string.validator';
import { SystmOneLinkAccountHelpDialogComponent } from '../systm-one-link-account-help-dialog/systm-one-link-account-help-dialog.component';

interface LinkAccountData {
  s1InstanceName: string;
  patientNhsNumber: string;
}

@Component({
  selector: 'ic-systm-one-link-account-dialog',
  templateUrl: './systm-one-link-account-dialog.component.html',
  styleUrls: ['./systm-one-link-account-dialog.component.scss'],
})
export class SystmOneLinkAccountDialogComponent implements OnInit {
  linkAccountForm: UntypedFormGroup;
  isNhsNumberCorrect = false;
  s1InstanceName: string;

  constructor(
    private dialogRef: MatDialogRef<SystmOneLinkAccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: LinkAccountData,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
  ) {}

  async ngOnInit(): Promise<void> {
    this.s1InstanceName = this.data.s1InstanceName;
    this.linkAccountForm = this.fb.group(
      {
        accountId: [null, [Validators.required]],
        passphrase: [null, [Validators.required]],
        nhsNumber: [null, [Validators.required, Validators.min(10), Validators.max(10)]],
      },
      {
        validator: [matchingStringValidator('nhsNumber', this.data.patientNhsNumber)],
      } as AbstractControlOptions,
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getDetails() {
    this.dialogRef.close({
      systmOnePatientAccountId: this.linkAccountForm.get('accountId').value,
      systmOnePatientPassphrase: this.linkAccountForm.get('passphrase').value,
    });
  }

  openLinkAccountHelpDialog() {
    this.dialog.open(SystmOneLinkAccountHelpDialogComponent, {
      width: '60%',
      data: {
        title: 'Link account help',
      },
    });
  }
}
