import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-gad7-form',
  templateUrl: './gad7-form.component.html',
  styleUrls: ['./gad7-form.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
})
export class Gad7FormComponent implements OnInit {
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  gad7Form: UntypedFormGroup;
  isSubmitting = false;

  answerList: string[] = [
    'Not at all - 0',
    'Several days - 1',
    'More than half the days - 2',
    'Nearly every day - 3',
  ];

  ngOnInit(): void {
    this.gad7Form = this.fb.group({
      feelingNervousAnxiousOrOnEdge: ['', [Validators.required]],
      notBeingAbleToStopOrControlWorrying: ['', [Validators.required]],
      worryingTooMuchAboutDifferentThings: ['', [Validators.required]],
      troubleRelaxing: ['', [Validators.required]],
      beingSoRestlessThatItIsHardToSitStill: ['', [Validators.required]],
      becomingEasilyAnnoyedOrIrritable: ['', [Validators.required]],
      feelingAfraidAsIfSomethingAwfulMightHappen: ['', [Validators.required]],
      howDifficultHaveTheseProblemsMadeThings: ['', [Validators.required]],
      gad7TotalScore: [''],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.gad7Form.controls[key])
          this.gad7Form.controls[key].setValue(formData[key]);
      });
    });
  }

  // GAD7
  get feelingNervousAnxiousOrOnEdge() {
    return this.gad7Form.get('feelingNervousAnxiousOrOnEdge');
  }
  get notBeingAbleToStopOrControlWorrying() {
    return this.gad7Form.get('notBeingAbleToStopOrControlWorrying');
  }
  get worryingTooMuchAboutDifferentThings() {
    return this.gad7Form.get('worryingTooMuchAboutDifferentThings');
  }
  get troubleRelaxing() {
    return this.gad7Form.get('troubleRelaxing');
  }
  get beingSoRestlessThatItIsHardToSitStill() {
    return this.gad7Form.get('beingSoRestlessThatItIsHardToSitStill');
  }
  get becomingEasilyAnnoyedOrIrritable() {
    return this.gad7Form.get('becomingEasilyAnnoyedOrIrritable');
  }
  get feelingAfraidAsIfSomethingAwfulMightHappen() {
    return this.gad7Form.get('feelingAfraidAsIfSomethingAwfulMightHappen');
  }
  get howDifficultHaveTheseProblemsMadeThings() {
    return this.gad7Form.get('howDifficultHaveTheseProblemsMadeThings');
  }

  onSubmit() {
    if (!this.gad7FormValid()) return;
    this.setGad7Score();

    this.isSubmitting = true;
    const formResponse = {
      ...this.gad7Form.value,
      formId: this.data.formId,
      index: this.data.index || 0,
      showOnChart: true,
    };
    this.form.submit(formResponse);
  }

  gad7FormValid(): boolean {
    return (
      this.gad7Form.dirty &&
      !(
        this.gad7Form.controls &&
        this.gad7Form.controls.feelingNervousAnxiousOrOnEdge.errors
      ) &&
      !(
        this.gad7Form.controls &&
        this.gad7Form.controls.notBeingAbleToStopOrControlWorrying.errors
      ) &&
      !(
        this.gad7Form.controls &&
        this.gad7Form.controls.worryingTooMuchAboutDifferentThings.errors
      ) &&
      !(
        this.gad7Form.controls && this.gad7Form.controls.troubleRelaxing.errors
      ) &&
      !(
        this.gad7Form.controls &&
        this.gad7Form.controls.beingSoRestlessThatItIsHardToSitStill.errors
      ) &&
      !(
        this.gad7Form.controls &&
        this.gad7Form.controls.becomingEasilyAnnoyedOrIrritable.errors
      ) &&
      !(
        this.gad7Form.controls &&
        this.gad7Form.controls.feelingAfraidAsIfSomethingAwfulMightHappen.errors
      ) &&
      !(
        this.gad7Form.controls &&
        this.gad7Form.controls.howDifficultHaveTheseProblemsMadeThings.errors
      )
    );
  }

  setGad7Score() {
    const gad7Question1 = this.gad7Form.get(
      'feelingNervousAnxiousOrOnEdge'
    ).value;
    const gad7Question2 = this.gad7Form.get(
      'notBeingAbleToStopOrControlWorrying'
    ).value;
    const gad7Question3 = this.gad7Form.get(
      'worryingTooMuchAboutDifferentThings'
    ).value;
    const gad7Question4 = this.gad7Form.get('troubleRelaxing').value;
    const gad7Question5 = this.gad7Form.get(
      'beingSoRestlessThatItIsHardToSitStill'
    ).value;
    const gad7Question6 = this.gad7Form.get(
      'becomingEasilyAnnoyedOrIrritable'
    ).value;
    const gad7Question7 = this.gad7Form.get(
      'feelingAfraidAsIfSomethingAwfulMightHappen'
    ).value;

    const gad7TotalScore =
      +gad7Question1.match(/\d+/g) +
      +gad7Question2.match(/\d+/g) +
      +gad7Question3.match(/\d+/g) +
      +gad7Question4.match(/\d+/g) +
      +gad7Question5.match(/\d+/g) +
      +gad7Question6.match(/\d+/g) +
      +gad7Question7.match(/\d+/g);

    this.gad7Form.get('gad7TotalScore').setValue(gad7TotalScore);
  }
}
