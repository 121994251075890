<ng-container *ngIf="allUploads$ | async as allUploads">
  <ul *ngIf="allUploads.length > 0; else noUploads" class="ic-file-uploader-list p-0">
    <li *ngFor="let upload of allUploads.slice().reverse()" [class]="rowClass">
      <div [class]="rowLabelClass">
        <i [class]="upload.status === 'cancelled' ? 'pi pi-file-excel' : 'pi pi-file'"></i>
        <span class="file-name text-overflow-ellipsis overflow-hidden">{{ upload.fileName }}</span>
        <span>
          &nbsp;{{
            upload.status === 'cancelled'
              ? 'Cancelled'
              : (upload.progress$ | async | number: '1.0-0') + '%'
          }}
        </span>
      </div>
      <div
        (click)="upload.status === 'ongoing' ? cancelUpload(upload) : null"
        (mouseenter)="upload.status === 'ongoing' ? (upload.isHovered = true) : null"
        (mouseleave)="upload.status === 'ongoing' ? (upload.isHovered = false) : null"
        [class.cursor-pointer]="upload.status === 'ongoing' && upload.isHovered"
        class="p-3"
      >
        <i [class]="getIconClass(upload)"></i>
      </div>
    </li>
  </ul>
</ng-container>

<ng-template #noUploads>
  <p-message severity="info" text="You have no uploads" styleClass="w-full"></p-message>
</ng-template>
