<ng-container *ngIf="field.fieldGroup">
  <!-- DESKTOP VIEW -->
  <div [ngClass]="props?.isEditor ? 'hidden' : 'hidden md:block'">
    <table class="border-round-top w-full" [attr.aria-label]="field.props?.label">
      <thead>
        <tr class="text-left bg-black-alpha-10">
          <th *ngFor="let fieldInTable of field.fieldGroup?.[0]?.fieldGroup" class="py-4 px-3">
            {{ fieldInTable.props?.label }}
            <span
              *ngIf="fieldInTable.props?.required && props.hideRequiredMarker !== true"
              aria-hidden="true"
              >*</span
            >
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody class="bg-surface">
        <tr *ngFor="let row of field.fieldGroup; let i = index">
          <td *ngFor="let col of row.fieldGroup" class="p-2">
            <formly-field [field]="col"></formly-field>
          </td>
          <td class="p-2" *ngIf="!field?.props?.hideDelete">
            <button
              pButton
              pRipple
              type="button"
              class="p-button-outlined p-button-danger"
              icon="pi pi-trash"
              aria-label="Delete"
              (click)="remove(i)"
            ></button>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      *ngIf="props.addText"
      class="flex md:justify-content-end border-main border-bottom-1 border-top-1 p-2 bg-surface"
    >
      <button
        pButton
        type="button"
        data-cy="add-record"
        icon="pi pi-plus"
        (click)="add()"
        [label]="props.addText"
        [attr.aria-label]="props.addText"
        class="w-full mx-2 md:mx-0 md:w-auto"
      ></button>
    </div>
  </div>

  <!-- MOBILE VIEW -->
  <div [ngClass]="props?.isEditor ? 'block' : 'md:hidden'">
    <div *ngFor="let row of field.fieldGroup; let i = index">
      <div class="bg-surface border-round p-4 my-4 border-1 border-main">
        <div class="flex justify-content-end">{{ i + 1 }} of {{ field?.fieldGroup?.length }}</div>
        <formly-field [field]="row"></formly-field>
        <button
          pButton
          pRipple
          type="button"
          class="p-button-outlined p-button-danger w-full"
          icon="pi pi-trash"
          (click)="remove(i)"
          label="Delete"
          aria-label="Delete"
          *ngIf="!field?.props?.hideDelete"
        ></button>
      </div>
    </div>
    <div class="flex md:justify-content-end py-2" *ngIf="props.addText">
      <button
        pButton
        type="button"
        data-cy="add-record"
        icon="pi pi-plus"
        (click)="add()"
        [label]="props.addText"
        [attr.aria-label]="props.addText"
        class="w-full md:mx-0 md:w-auto"
      ></button>
    </div>
  </div>
</ng-container>
