import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-surgical-wound-healing-form',
  templateUrl: './surgical-wound-healing-form.component.html',
  styleUrls: ['./surgical-wound-healing-form.component.scss'],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class SurgicalWoundHealingFormComponent implements OnInit {
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  surgicalWoundHealingForm: UntypedFormGroup;
  isSubmitting = false;

  additionalSymptomsList = [
    'Pain or soreness in addition to the discomfort experienced following your surgery',
    'Redness or inflammation spreading from the edges of the wound',
    'The area around the wound felt warmer/hotter than the rest of your skin',
    'The area around the wound became swollen',
    'The edges of any part of the wound separated or gaped open',
  ];

  healthcareWorkerList = [
    'GP',
    'District Nurse',
    'Doctor or Nurse at hospital',
    'Other',
  ];

  ngOnInit(): void {
    this.surgicalWoundHealingForm = this.fb.group({
      problemsWithHealing: [''],
      anyDischargeOrLeakage: [''],
      dischargeLeakageType: [''],
      dischargeLeakageOther: [''],
      additionalSymptoms: [''],
      sampleTakenFromWound: [''],
      seenHealthcareWorker: [''],
      healthcareWorker: [''],
      healthcareWorkerOther: [''],
      woundSymptomsDate: [''],
      beenPrescribedAntibiotics: [''],
      whoPrescribedThem: [''],
      readmittedToHospitalDueToInfection: [''],
      readmittedToHospitalDetails: [''],
      notes: [''],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.surgicalWoundHealingForm.controls[key])
          this.surgicalWoundHealingForm.controls[key].setValue(formData[key]);
      });
    });
  }

  onSubmit() {
    this.isSubmitting = true;
    const formResponse = {
      ...this.surgicalWoundHealingForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  surgicalWoundHealingFormValid(): boolean {
    return (
      this.surgicalWoundHealingForm.dirty &&
      this.surgicalWoundHealingForm.valid &&
      !this.isSubmitting
    );
  }
}
