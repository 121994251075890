import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { PatientsService } from '@ic-monorepo/services';
import { SystemCheckService } from '@ic-monorepo/shared-common';
import { Collection, MessageType } from '@islacare/ic-types';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { tap } from 'rxjs/operators';
import { CollectionsService } from '../../../services/collections/collections.service';
import { OrganisationsService } from '../../../services/organisations/organisations.service';
import { PatientMessageCopyService } from '../../../services/patientMessage-copy/patientMessage-copy.service';
import { DialogData } from '../../../shared/components/dialogs/dialog-data';
import { mobilePhoneNumberValidator } from '../../../utils/custom-validators/mobile-phone-number.validator';
interface AdditionalPhoneNumbersMap {
  [key: string]: string;
}
interface DialogDataLong extends DialogData {
  message: string;
  smsReminder?: string;
  messageBodyReminder?: string;
  messageType?: MessageType;
  patientPhone: string;
  additionalPhoneNumbers?: AdditionalPhoneNumbersMap;
  patientId: string;
  collectionId: string;
  patientFirstName: string;
  disableRecipient?: boolean;
  hideReminderCheckbox?: boolean;
  preSetRecipient?: string;
  keepLinkSevenDays?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'ic-sms-confirmation',
  templateUrl: './sms-confirmation-dialog.component.html',
})
export class SmsConfirmationDialogComponent implements OnInit {
  smsForm = this.fb.group({
    recipient: ['', []],
    messageIntro: ['', []],
    messageBody: ['', [Validators.required]],
    messageExpiry: ['', []],
    reminder: [true],
    includeMessageIntro: [true],
  });
  recipientKeys: string[];
  smsBody: string;
  smsReminder: string;
  smsIntro: string;
  messageType: MessageType;
  MessageType = MessageType;
  smsFull: string;
  errorMessage: string;
  data: DialogDataLong;
  keepLinkSevenDays: boolean;
  messageBodyCanBeOverwritten = true;
  isMobile = this.systemCheck.isMobile;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly patientsService: PatientsService,
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private systemCheck: SystemCheckService,
    private patientMessageCopyService: PatientMessageCopyService,
    private collectionsService: CollectionsService,
    private organisationsService: OrganisationsService
  ) {
    this.data = this.config.data;
  }

  async ngOnInit(): Promise<void> {
    this.data = this.config.data;
    this.setAdditionalPhoneNumbers();
    await this.setMessageData();
  }

  setAdditionalPhoneNumbers() {
    if (this.data.additionalPhoneNumbers) {
      this.recipientKeys = Object.keys(this.data.additionalPhoneNumbers) || [];

      this.smsForm
        .get('recipient')
        .setValidators([
          Validators.required,
          mobilePhoneNumberValidator(this.data.additionalPhoneNumbers),
        ]);

      if (this.data.preSetRecipient) {
        if (
          Object.keys(this.data.additionalPhoneNumbers).includes(
            this.data.preSetRecipient
          ) ||
          this.data.preSetRecipient === 'Patient'
        ) {
          this.smsForm.controls.recipient.patchValue(this.data.preSetRecipient);
        } else {
          this.errorMessage =
            'The recipient set on this request no longer exists on the patient, please send a new request.';
        }
      } else if (this.recipientKeys?.length === 1) {
        this.smsForm.get('recipient').patchValue(this.recipientKeys[0]);
      }
    }
  }

  /**
   * @description Get the name to be displayed in error messages
   * @returns Valid name to display in error messages
   */
  getName(): string {
    const recipient = this.smsForm.get('recipient').value;
    return this.patientsService.getNameToBeDisplayForPhoneNumber(recipient);
  }

  smsSubmit(): void {
    this.ref.close({
      smsFull:
        (this.smsForm.value.messageIntro ?? '') +
        (this.smsForm.value.messageBody || this.smsFull),
      recipient: this.smsForm.value.recipient,
      reminder: this.smsForm.value.reminder,
    });
  }
  closeDialog(): void {
    this.ref.close();
  }

  async setMessageData() {
    this.messageType = this.data.messageType;
    this.keepLinkSevenDays = this.data.keepLinkSevenDays;
    const collection: Collection = (
      await this.collectionsService.getCollectionSnapshot(
        this.data.patientId,
        this.data.collectionId
      )
    ).data();
    const organisation = (
      await this.organisationsService.getOrganisationSnapshot(
        collection.organisationId
      )
    ).data();
    const preSetMessage = collection?.request?.smsBody;
    if (preSetMessage && !this.data?.disableRecipient) {
      this.smsForm.controls.messageBody.setValue(preSetMessage);
    } else {
      this.smsForm.controls.messageBody.setValue(
        this.patientMessageCopyService.getMessageBody(
          organisation.name,
          this.messageType
        )
      );
    }
    this.smsForm.valueChanges
      .pipe(
        tap((formValue) => {
          const recipient = Object.keys(
            this.data.additionalPhoneNumbers ?? {}
          ).includes(formValue.recipient)
            ? formValue.recipient
            : null;

          this.smsForm
            .get('messageIntro')
            .setValue(
              this.patientMessageCopyService.getMessageIntro(
                this.data.additionalPhoneNumbers,
                this.data.patientFirstName,
                this.data.patientPhone,
                formValue.includeMessageIntro,
                recipient
              ),
              { emitEvent: false }
            );

          this.smsForm
            .get('messageExpiry')
            .setValue(
              this.patientMessageCopyService.getMessageExpiry(
                formValue.reminder,
                this.data.keepLinkSevenDays
              ),
              { emitEvent: false }
            );
        })
      )
      .subscribe();
    this.smsForm.updateValueAndValidity();
  }
}
