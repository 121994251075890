<mat-horizontal-stepper [linear]="isLinear" #stepper>
  <mat-step [stepControl]="painMedicationForm" label="Medication">
    <form [formGroup]="painMedicationForm">
      <div class="form-formatting">
        <div>
          <h3>Neuropathic Pain Clinic Questionnaire (New patients)</h3>
        </div>

        <!-- QUESTION ONE -->
        <div class="question-container">
          <div class="half-width-field hori-verti-center">
            <p>Please list all the drugs that you are currently taking for your pain:</p>
          </div>
          <div formArrayName="drugsCurrentlyTakingForPain">
            <div
              *ngFor="
                let drugCurrentlyTakingForPain of drugsCurrentlyTakingForPainForm.controls;
                let i = index
              "
              [formGroupName]="i"
            >
              <mat-form-field class="single-width-field">
                <mat-label> Drug name </mat-label>
                <input matInput type="text" formControlName="drugName" />
              </mat-form-field>
              <mat-form-field class="single-width-field">
                <mat-label> Dose </mat-label>
                <input matInput type="text" formControlName="drugDose" />
              </mat-form-field>
              <mat-form-field class="single-width-field">
                <mat-label> Times per day </mat-label>
                <input matInput type="number" formControlName="drugDailyIntake" />
              </mat-form-field>
              <button mat-button (click)="deleteDrugsCurrentlyTakingForPain(i)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
          <div class="hori-verti-center">
            <button
              class="positive-button"
              mat-button
              type="button"
              (click)="addDrugsCurrentlyTakingForPain()"
            >
              Add A Drug
            </button>
          </div>
        </div>

        <!-- Question two -->
        <div class="question-container">
          <div class="half-width-field hori-verti-center">
            <p>Please list all the drugs that you are currently taking for any other conditions:</p>
          </div>
          <div formArrayName="drugsCurrentlyTakingForOtherConditions">
            <div
              *ngFor="
                let drugCurrentlyTakingForOtherConditions of drugsCurrentlyTakingForOtherConditionsForm.controls;
                let i = index
              "
              [formGroupName]="i"
            >
              <mat-form-field class="single-width-field">
                <mat-label> Drug name </mat-label>
                <input matInput type="text" formControlName="drugName" />
              </mat-form-field>
              <mat-form-field class="single-width-field">
                <mat-label> Dose </mat-label>
                <input matInput type="text" formControlName="drugDose" />
              </mat-form-field>
              <mat-form-field class="single-width-field">
                <mat-label> How many times a day do you take the drug </mat-label>
                <input matInput type="number" formControlName="drugDailyIntake" />
              </mat-form-field>
              <button mat-button (click)="deleteDrugsCurrentlyTakingForOtherConditions(i)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
          <div class="hori-verti-center">
            <button
              class="positive-button"
              mat-button
              type="button"
              (click)="addDrugsCurrentlyTakingForOtherConditions()"
            >
              Add A Drug
            </button>
          </div>
        </div>

        <!-- Question three -->
        <div class="question-container">
          <div class="half-width-field hori-verti-center">
            <p>
              Please list all the drugs that you have previously taken for your pain, but which you
              no longer take:
            </p>
          </div>
          <div formArrayName="drugsPreviouslyTakenForPain">
            <div
              *ngFor="
                let drugPreviouslyTakenForPain of drugsPreviouslyTakenForPainForm.controls;
                let i = index
              "
              [formGroupName]="i"
            >
              <mat-form-field class="single-width-field">
                <mat-label> Drug name </mat-label>
                <input matInput type="text" formControlName="drugName" />
              </mat-form-field>
              <mat-form-field class="single-width-field">
                <mat-label> Dose </mat-label>
                <input matInput type="text" formControlName="drugDose" />
              </mat-form-field>
              <mat-form-field class="single-width-field">
                <mat-label> How many times a day do you take the drug </mat-label>
                <input matInput type="number" formControlName="drugDailyIntake" />
              </mat-form-field>
              <button mat-button (click)="deleteDrugsPreviouslyTakenForPain(i)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
          <div class="hori-verti-center">
            <button
              class="positive-button"
              mat-button
              type="button"
              (click)="addDrugsPreviouslyTakenForPain()"
            >
              Add A Drug
            </button>
          </div>
        </div>

        <div>
          <button mat-button matStepperNext style="margin: 10px" mat-raised-button>Next</button>
        </div>
      </div>
    </form>
  </mat-step>

  <!-- Pain Summary -->
  <mat-step [stepControl]="painSummaryForm" label="Pain Summary">
    <form [formGroup]="painSummaryForm">
      <div class="form-formatting">
        <div class="question-container">
          <div class="half-width-field hori-verti-center">
            <p>Please tell us where on your body do you mostly feel your pain:</p>
          </div>
          <mat-form-field class="half-width-field">
            <input
              matInput
              type="text"
              formControlName="bodyAreaWithMostPain"
              placeholder="e.g. head, right arm, lower left leg"
              autocomplete="off"
            />
            <mat-error *ngIf="bodyAreaWithMostPain.touched">
              Please enter where you have pain the most.
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <p>
            Please mark the scale below to show how intense your pain is. A zero (0) means no pain,
            and ten (10) means extreme pain.
          </p>
          <br />
          <p>How intense is your pain now?</p>
          <div class="full-width-field verti-center slider-wrapper">
            <div class="slider-label">
              <div>0</div>
              <div>No pain</div>
            </div>
            <mat-slider
              class="full-width-field"
              formControlName="painIntensityNow"
              value="painIntensityNow"
              [min]="sliderMin"
              [max]="sliderMax"
            >
            </mat-slider>
            <!-- <mat-error *ngIf="painIntensityNow.touched && painIntensityNow.invalid">Please use the slider</mat-error> -->
            <div class="slider-label">
              <div>10</div>
              <div>Extreme pain</div>
            </div>
          </div>

          <!-- <p class = pain-descriptor>{{painDescriptor}}</p> -->
        </div>
        <div>
          <p>How intense was your pain on average last week?</p>
          <div class="full-width-field verti-center slider-wrapper">
            <div class="slider-label">
              <div>0</div>
              <div>No pain</div>
            </div>
            <mat-slider
              class="full-width-field"
              formControlName="painIntensityLastWeek"
              value="painIntensityLastWeek"
              [min]="sliderMin"
              [max]="sliderMax"
            >
            </mat-slider>
            <div class="slider-label">
              <div>10</div>
              <div>Extreme pain</div>
            </div>
          </div>
        </div>

        <div>
          <button
            mat-button
            matStepperNext
            style="margin: 10px"
            mat-raised-button
            [disabled]="!painSummaryFormValid()"
          >
            Next
          </button>
        </div>
      </div>
    </form>
  </mat-step>

  <!-- DN4 Scale -->
  <mat-step [stepControl]="dn4ScaleForm" label="DN4 Scale">
    <form [formGroup]="dn4ScaleForm">
      <div class="form-formatting">
        <div class="full-width-field" style="margin: auto">
          <h4>Does the pain have one or more of the following characteristics?</h4>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Burning?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Please select an option</mat-label>
            <mat-select formControlName="painFeelLikeBurning">
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="painFeelLikeBurning.touched">Please select an option</mat-error>
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Painful Cold?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Please select an option</mat-label>
            <mat-select formControlName="painFeelLikePainfulCold">
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="painFeelLikePainfulCold.touched">Please select an option</mat-error>
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Electric Shocks?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Please select an option</mat-label>
            <mat-select formControlName="painFeelLikeElectricShocks">
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="painFeelLikeElectricShocks.touched"
              >Please select an option</mat-error
            >
          </mat-form-field>
        </div>
        <div class="full-width-field" style="margin: auto">
          <h4>
            Is the pain associated with one or more of the following symptoms in the same area?
          </h4>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Tingling?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Please select an option</mat-label>
            <mat-select formControlName="painFeelLikeTingling">
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="painFeelLikeTingling.touched">Please select an option</mat-error>
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Pins and needles?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Please select an option</mat-label>
            <mat-select formControlName="painFeelLikePinsAndNeedles">
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="painFeelLikePinsAndNeedles.touched"
              >Please select an option</mat-error
            >
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Numbness?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Please select an option</mat-label>
            <mat-select formControlName="painFeelLikeNumbness">
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="painFeelLikeNumbness.touched">Please select an option</mat-error>
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Itching?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Please select an option</mat-label>
            <mat-select formControlName="painFeelLikeItching">
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="painFeelLikeItching.touched">Please select an option</mat-error>
          </mat-form-field>
        </div>
        <div>
          <button
            mat-button
            matStepperNext
            style="margin: 10px"
            mat-raised-button
            [disabled]="!dn4ScaleFormValid()"
          >
            Next
          </button>
        </div>
      </div>
    </form>
  </mat-step>

  <!-- Neuropathic Pain Symptom Inventory -->
  <mat-step
    [stepControl]="neuropathicPainSymptomInventoryForm"
    label="Neuropathic Pain Symptom Inventory"
  >
    <form [formGroup]="neuropathicPainSymptomInventoryForm">
      <div class="form-formatting">
        <div>
          <p>1. Does your pain feel like burning?</p>
          <div class="full-width-field verti-center slider-wrapper">
            <div class="slider-label">
              <div>0</div>
              <div>No burning</div>
            </div>
            <mat-slider
              class="full-width-field"
              formControlName="painFeelLikeBurningSlider"
              value="painFeelLikeBurningSlider"
              [min]="sliderMin"
              [max]="sliderMax"
            >
            </mat-slider>
            <div class="slider-label">
              <div>10</div>
              <div>Extreme burning</div>
            </div>
          </div>
        </div>
        <div>
          <p>2. Does your pain feel like squeezing?</p>
          <div class="full-width-field verti-center slider-wrapper">
            <div class="slider-label">
              <div>0</div>
              <div>No squeezing</div>
            </div>
            <mat-slider
              class="full-width-field"
              formControlName="painFeelLikeSqueezingSlider"
              value="painFeelLikeSqueezingSlider"
              [min]="sliderMin"
              [max]="sliderMax"
            >
            </mat-slider>
            <div class="slider-label">
              <div>10</div>
              <div>Extreme squeezing</div>
            </div>
          </div>
        </div>
        <div>
          <p>3. Does your pain feel like pressure?</p>
          <div class="full-width-field verti-center slider-wrapper">
            <div class="slider-label">
              <div>0</div>
              <div>No pressure</div>
            </div>
            <mat-slider
              class="full-width-field"
              formControlName="painFeelLikePressureSlider"
              value="painFeelLikePressureSlider"
              [min]="sliderMin"
              [max]="sliderMax"
            >
            </mat-slider>
            <div class="slider-label">
              <div>10</div>
              <div>Extreme pressure</div>
            </div>
          </div>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>
              4. During the past 24 hours your spontaneous pain has been present, please select the
              response that best describes your case:
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Please select an option</mat-label>
            <mat-select formControlName="painHasBeenPresent">
              <mat-option value="Permanently">Permanently</mat-option>
              <mat-option value="Between 8 and 12 hours">Between 8 and 12 hours</mat-option>
              <mat-option value="Between 4 and 7 hours">Between 4 and 7 hours</mat-option>
              <mat-option value="Between 1 and 3 hours">Between 1 and 3 hours</mat-option>
              <mat-option value="Less than 1 hour">Less than 1 hour</mat-option>
            </mat-select>
            <mat-error *ngIf="painHasBeenPresent.touched">Please select an option</mat-error>
          </mat-form-field>
        </div>
        <div class="full-width-field" style="margin: auto">
          <p>
            We wish to know if you have brief attacks of pain. For each of the following questions,
            please select the number that best describes the
            <em>average severity of your painful attacks during the past 24 hours.</em> Select the
            number 0 if you have not felt such pain.
          </p>
        </div>
        <div>
          <p>5. Does your pain feel like electric shocks?</p>
          <div class="full-width-field verti-center slider-wrapper">
            <div class="slider-label">
              <div>0</div>
              <div>No electric shocks</div>
            </div>
            <mat-slider
              class="full-width-field"
              formControlName="painFeelLikeElectricShocksSlider"
              value="painFeelLikeElectricShocksSlider"
              [min]="sliderMin"
              [max]="sliderMax"
            >
            </mat-slider>
            <div class="slider-label">
              <div>10</div>
              <div>Extreme electric shocks</div>
            </div>
          </div>
        </div>
        <div>
          <p>6. Does your pain feel like stabbing?</p>
          <div class="full-width-field verti-center slider-wrapper">
            <div class="slider-label">
              <div>0</div>
              <div>No stabbing</div>
            </div>
            <mat-slider
              class="full-width-field"
              formControlName="painFeelLikeStabbingSlider"
              value="painFeelLikeStabbingSlider"
              [min]="sliderMin"
              [max]="sliderMax"
            >
            </mat-slider>
            <div class="slider-label">
              <div>10</div>
              <div>Extreme stabbing</div>
            </div>
          </div>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>
              7. During the past 24 hours how many of these pain attacks have you had? please select
              the response that best describes your case:
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Please select an option</mat-label>
            <mat-select formControlName="painAttacks">
              <mat-option value="More than 20">More than 20</mat-option>
              <mat-option value="Between 11 and 20">Between 11 and 20</mat-option>
              <mat-option value="Between 6 and 10">Between 6 and 10</mat-option>
              <mat-option value="Between 1 and 5">Between 1 and 5</mat-option>
              <mat-option value="No pain attack">No pain attack</mat-option>
            </mat-select>
            <mat-error *ngIf="painAttacks.touched">Please select an option</mat-error>
          </mat-form-field>
        </div>
        <div class="full-width-field" style="margin: auto">
          <p>
            We wish to know if you feel pain provoked or increased by brushing, pressure, contact
            with cold or warmth on the painful area. For each of the following questions, please
            select the number that best describes the
            <em>average severity of your provoked pain during the past 24 hours.</em> Select the
            number 0 if you have not felt such pain.
          </p>
        </div>
        <div>
          <p>8. Is your pain provoked or increased by brushing on the painful area?</p>
          <div class="full-width-field verti-center slider-wrapper">
            <div class="slider-label">
              <div>0</div>
              <div>Not provoked</div>
            </div>
            <mat-slider
              class="full-width-field"
              formControlName="painIncreasedByBrushingSlider"
              value="painIncreasedByBrushingSlider"
              [min]="sliderMin"
              [max]="sliderMax"
            >
            </mat-slider>
            <div class="slider-label">
              <div>10</div>
              <div>Extremely provoked</div>
            </div>
          </div>
        </div>
        <div>
          <p>9. Is your pain provoked or increased by pressure on the painful area?</p>
          <div class="full-width-field verti-center slider-wrapper">
            <div class="slider-label">
              <div>0</div>
              <div>Not provoked</div>
            </div>
            <mat-slider
              class="full-width-field"
              formControlName="painIncreasedByPressureSlider"
              value="painIncreasedByPressureSlider"
              [min]="sliderMin"
              [max]="sliderMax"
            >
            </mat-slider>
            <div class="slider-label">
              <div>10</div>
              <div>Extremely provoked</div>
            </div>
          </div>
        </div>
        <div>
          <p>
            10. Is your pain provoked or increased by contact with something cold on the painful
            area?
          </p>
          <div class="full-width-field verti-center slider-wrapper">
            <div class="slider-label">
              <div>0</div>
              <div>Not provoked</div>
            </div>
            <mat-slider
              class="full-width-field"
              formControlName="painIncreasedByContactWithColdSlider"
              value="painIncreasedByContactWithColdSlider"
              [min]="sliderMin"
              [max]="sliderMax"
            >
            </mat-slider>
            <div class="slider-label">
              <div>10</div>
              <div>Extremely provoked</div>
            </div>
          </div>
        </div>
        <div class="full-width-field" style="margin: auto">
          <p>
            We wish to know if your feel abnormal sensations <em>in the painful area.</em> For each
            of the following questions, please select the number that best describes the
            <em>average severity of your abnormal sensations during the past 24 hours.</em> Select
            the number 0 if you have not felt such sensation.
          </p>
        </div>
        <div>
          <p>11. Do you feel pins and needles?</p>
          <div class="full-width-field verti-center slider-wrapper">
            <div class="slider-label">
              <div>0</div>
              <div>No pins and needles</div>
            </div>
            <mat-slider
              class="full-width-field"
              formControlName="painFeelsLikePinsAndNeedlesSlider"
              value="painFeelsLikePinsAndNeedlesSlider"
              [min]="sliderMin"
              [max]="sliderMax"
            >
            </mat-slider>
            <div class="slider-label">
              <div>10</div>
              <div>Extreme pins and needles</div>
            </div>
          </div>
        </div>
        <div>
          <p>12. Do you feel tingling?</p>
          <div class="full-width-field verti-center slider-wrapper">
            <div class="slider-label">
              <div>0</div>
              <div>No tingling</div>
            </div>
            <mat-slider
              class="full-width-field"
              formControlName="painFeelsLikeTinglingSlider"
              value="painFeelsLikeTinglingSlider"
              [min]="sliderMin"
              [max]="sliderMax"
            >
            </mat-slider>
            <div class="slider-label">
              <div>10</div>
              <div>Extreme tingling</div>
            </div>
          </div>
        </div>
        <div>
          <button
            mat-button
            matStepperNext
            style="margin: 10px"
            mat-raised-button
            [disabled]="!neuropathicPainSymptomInventoryFormValid()"
          >
            Next
          </button>
        </div>
      </div>
    </form>
  </mat-step>

  <!-- BPI Interference Scale -->
  <mat-step [stepControl]="bpiInterferenceScaleForm" label="BPI Interference Scale">
    <form [formGroup]="bpiInterferenceScaleForm" (ngSubmit)="onSubmit()">
      <div class="form-formatting">
        <div>
          <h3>
            Please select the number that describes how, during the past week, pain has interfered
            with your:
          </h3>
        </div>
        <div>
          <p>1. General activity:</p>
          <div class="full-width-field verti-center slider-wrapper">
            <div class="slider-label">
              <div>0</div>
              <div>No interference</div>
            </div>
            <mat-slider
              class="full-width-field"
              formControlName="generalActivitySlider"
              value="generalActivitySlider"
              [min]="sliderMin"
              [max]="sliderMax"
            >
            </mat-slider>
            <div class="slider-label">
              <div>10</div>
              <div>Complete interference</div>
            </div>
          </div>
        </div>
        <div>
          <p>2. Mood:</p>
          <div class="full-width-field verti-center slider-wrapper">
            <div class="slider-label">
              <div>0</div>
              <div>No interference</div>
            </div>
            <mat-slider
              class="full-width-field"
              formControlName="moodSlider"
              value="moodSlider"
              [min]="sliderMin"
              [max]="sliderMax"
            >
            </mat-slider>
            <div class="slider-label">
              <div>10</div>
              <div>Complete interference</div>
            </div>
          </div>
        </div>
        <div>
          <p>3. Walking ability:</p>
          <div class="full-width-field verti-center slider-wrapper">
            <div class="slider-label">
              <div>0</div>
              <div>No interference</div>
            </div>
            <mat-slider
              class="full-width-field"
              formControlName="walkingAbilitySlider"
              value="walkingAbilitySlider"
              [min]="sliderMin"
              [max]="sliderMax"
            >
            </mat-slider>
            <div class="slider-label">
              <div>10</div>
              <div>Complete interference</div>
            </div>
          </div>
        </div>
        <div>
          <p>4. Normal work (including both work outside the home and housework):</p>
          <div class="full-width-field verti-center slider-wrapper">
            <div class="slider-label">
              <div>0</div>
              <div>No interference</div>
            </div>
            <mat-slider
              class="full-width-field"
              formControlName="normalWorkSlider"
              value="normalWorkSlider"
              [min]="sliderMin"
              [max]="sliderMax"
            >
            </mat-slider>
            <div class="slider-label">
              <div>10</div>
              <div>Complete interference</div>
            </div>
          </div>
        </div>
        <div>
          <p>5. Relationships with other people:</p>
          <div class="full-width-field verti-center slider-wrapper">
            <div class="slider-label">
              <div>0</div>
              <div>No interference</div>
            </div>
            <mat-slider
              class="full-width-field"
              formControlName="relationshipsWithOtherPeopleSlider"
              value="relationshipsWithOtherPeopleSlider"
              [min]="sliderMin"
              [max]="sliderMax"
            >
            </mat-slider>
            <div class="slider-label">
              <div>10</div>
              <div>Complete interference</div>
            </div>
          </div>
        </div>
        <div>
          <p>6. Sleep:</p>
          <div class="full-width-field verti-center slider-wrapper">
            <div class="slider-label">
              <div>0</div>
              <div>No interference</div>
            </div>
            <mat-slider
              class="full-width-field"
              formControlName="sleepSlider"
              value="sleepSlider"
              [min]="sliderMin"
              [max]="sliderMax"
            >
            </mat-slider>
            <div class="slider-label">
              <div>10</div>
              <div>Complete interference</div>
            </div>
          </div>
        </div>
        <div>
          <p>7. Enjoyment of life:</p>
          <div class="full-width-field verti-center slider-wrapper">
            <div class="slider-label">
              <div>0</div>
              <div>No interference</div>
            </div>
            <mat-slider
              class="full-width-field"
              formControlName="enjoymentOfLifeSlider"
              value="enjoymentOfLifeSlider"
              [min]="sliderMin"
              [max]="sliderMax"
            >
            </mat-slider>
            <div class="slider-label">
              <div>10</div>
              <div>Complete interference</div>
            </div>
          </div>
        </div>
        <div>
          <button mat-raised-button class="positive-button" type="submit" [disabled]="isSubmitting">
            {{ isSubmitting ? 'Submitting...' : 'Submit' }}
          </button>
        </div>
      </div>
    </form>
  </mat-step>
</mat-horizontal-stepper>
