import { formatDate } from '@angular/common';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FormService } from '@ic-monorepo/services';
import {
  ContentInputType,
  Form,
  FormField,
  ResponseFormat,
  UserInputType,
  validationMarker,
} from '@islacare/ic-types';
import { take } from 'rxjs/operators';
import { AutomaticImageMapComponent } from './components/automatic-image-map/automatic-image-map.component';

@Component({
  selector: 'ic-automatic-form',
  templateUrl: './automatic-form.component.html',
  styleUrls: ['./automatic-form.component.scss'],
})
export class AutomaticFormComponent implements OnInit, AfterContentChecked {
  @Input() data: {
    teamId: string;
    formId: string;
    yearOfBirth: string;
    index: number;
    automatedForm: Form;
  };
  @ViewChildren(AutomaticImageMapComponent)
  AutomaticImageMapComponentList: QueryList<AutomaticImageMapComponent>;
  @ViewChildren('elements', { read: ElementRef })
  elements: QueryList<ElementRef>;
  automaticForm: UntypedFormGroup;
  formFields: FormField[] = [];
  isSubmitting: boolean;
  ContentInputType = ContentInputType;
  UserInputType = UserInputType;

  referencedFormFields: {
    [key: string]: {
      formFieldsOrderedArray: FormField[];
      automaticForm: UntypedFormGroup;
    };
  } = {};
  formIds = [];
  addedFields = [];
  bmiResponse: boolean;
  addBmiResponse = false;
  tallies = {};
  formData;
  referencedFormData;
  validationMarkerArray = [];
  pageHeight: number;
  formArrayHeights = {};
  isLoggedIn = false;
  form;
  sections;
  ContentInputTypes;
  isEditUrl: boolean;
  constructor(
    public formService: FormService,
    private changeRef: ChangeDetectorRef,
    private router: Router,
    public afAuth: AngularFireAuth
  ) {}

  async ngOnInit() {
    this.ContentInputTypes = Object.values(ContentInputType);
    this.afAuth.authState.subscribe(async (loggedInUser) => {
      if (loggedInUser) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
    });

    this.isEditUrl =
      this.router?.url?.split('/')?.pop().split('?')[0] === 'edit';
    const form: Form = this.data?.automatedForm;

    this.form = form;
    this.automaticForm = this.formService.buildForm(
      form?.formFieldsOrderedArray,
      this.formIds
    );
    this.formFields = form?.formFieldsOrderedArray;
    this.formService.retreive$?.pipe(take(1)).subscribe((formData) => {
      this.formData = formData;
      this.automaticForm.patchValue(formData);
    });

    //set qual value on options fields on edit
    if (this.isEditUrl) {
      for (const fieldKey in this.automaticForm.controls) {
        const field = form.formFieldsOrderedArray.find(
          (field) => field.controlName === fieldKey
        );
        if (field.options) {
          if (field.inputType === UserInputType.MULTI_SELECT) {
            const response = [];
            this.automaticForm.controls[fieldKey].value
              ? this.automaticForm.controls[fieldKey].value.forEach((value) => {
                  response.push(value.split(' value: ')[0]);
                })
              : null;
            this.automaticForm.controls[fieldKey].setValue(response);
          } else {
            Array.isArray(this.automaticForm.controls[fieldKey].value)
              ? this.automaticForm.controls[fieldKey].setValue(
                  this.automaticForm.controls[fieldKey].value[0]
                )
              : this.automaticForm.controls[fieldKey].value;
          }
        }
      }
    }
    if (this.form?.hasSections) {
      this.sections = this.formFields.filter(
        (field) => field.inputType === ContentInputType.FORM_REFERENCE
      );
      this.sections[0]['isFirstPage'] = true;
      this.sections[this.sections.length - 1]['isLastPage'] = true;
    }
    this.buildReferenceForms();
  }
  ngAfterContentChecked() {
    this.changeRef.detectChanges();
  }

  async buildReferenceForms() {
    for (const formId of this.formIds) {
      if (!(formId.substring(0, 5) === 'array')) {
        const automaticForm = await this.formService.getAutomaticFormSnapshot(
          this.data?.automatedForm.teamId,
          formId
        );
        const formFieldsOrderedArray =
          automaticForm.data().formFieldsOrderedArray;
        if (automaticForm.exists) {
          const automaticFormGroup = this.formService.buildForm(
            formFieldsOrderedArray,
            this.formIds
          );
          this.formService.retreive$.subscribe((formData) => {
            if (this.form.hasSections) {
              const automaticFormData = {};
              Object.keys(formData).forEach((controlName) => {
                const data = formData[controlName];
                if (
                  controlName.split('---')[1] === formId ||
                  controlName === 'imageMapDataUrlArray' ||
                  controlName === 'bodyMap'
                ) {
                  automaticFormData[controlName.split('---')[0]] = data;
                }
              });
              this.referencedFormData = {
                ...this.referencedFormData,
                ...automaticFormData,
              };
              automaticFormGroup.patchValue(automaticFormData);
            } else {
              automaticFormGroup.patchValue(formData);
            }
          });

          this.referencedFormFields[formId] = {
            formFieldsOrderedArray: automaticForm.data().formFieldsOrderedArray,
            automaticForm: automaticFormGroup,
          };
        }
      }
    }
  }

  // move into a form service
  isContentField(inputType: any) {
    if (Object.values(ContentInputType).includes(inputType as any)) return true;

    return false;
  }

  async onSubmit() {
    if (!this.automaticForm.valid) {
      this.automaticForm.markAllAsTouched();
      this.setValidationMarkers(this.automaticForm);
      setTimeout(() => {
        this.setValidationMarkers(this.automaticForm);
      }, 1);
      return;
    }

    const { referencedAutomaticForm, abortSubmit } =
      this.validateReferencedForms();
    if (abortSubmit) return;
    const formResponse = {
      ...JSON.parse(JSON.stringify(this.automaticForm.value)),
      ...(referencedAutomaticForm
        ? JSON.parse(JSON.stringify(referencedAutomaticForm))
        : null),
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    const modifiedResponse = await this.modifyResponse(formResponse);
    this.isSubmitting = true;
    this.formService.submit(modifiedResponse);
  }

  validateReferencedForms() {
    let referencedAutomaticForm = {};
    let abortSubmit = true;
    for (const field of this.formFields) {
      if (field.inputType === ContentInputType.FORM_REFERENCE) {
        const formId = field.formId;
        if (!this.hideField(field) && this.showField(field)) {
          if (!this.referencedFormFields[formId].automaticForm.valid) {
            this.referencedFormFields[formId].automaticForm.markAllAsTouched();
            this.setValidationMarkers(
              this.referencedFormFields[formId].automaticForm,
              this.referencedFormFields[formId].formFieldsOrderedArray
            );
            return { referencedAutomaticForm, abortSubmit };
          }
        }
        const value = this.referencedFormFields[formId].automaticForm.value;
        if (this.form?.hasSections) {
          this.referencedFormFields[formId].formFieldsOrderedArray.forEach(
            (field) => {
              let response;
              switch (field.inputType) {
                case ContentInputType.SCORE:
                case ContentInputType.SCALED_SCORE:
                case ContentInputType.HIGHEST_SCORE:
                case ContentInputType.LOWEST_SCORE:
                case ContentInputType.CALCULATION:
                case ContentInputType.THRESHOLD:
                  response = field.value;
                  break;
                case ContentInputType.HEADING:
                  response = 'subtitle_HEADER';
                  break;
                case ContentInputType.SUBHEADING:
                case ContentInputType.PARAGRAPH:
                  if (field.responseText) response = 'response_TEXT';
                  break;
                default:
                  response = value[field.controlName];
                  break;
              }

              delete value[field.controlName];
              value[field.controlName + '---' + formId] = response;
            }
          );
        }
        if (JSON.stringify(value) !== '{}')
          referencedAutomaticForm = { ...referencedAutomaticForm, ...value };
      }
    }
    abortSubmit = false;
    return { referencedAutomaticForm, abortSubmit };
  }

  validateSection(automaticForm, stepper, formFields) {
    if (!automaticForm.valid) {
      automaticForm.markAllAsTouched();
      this.setValidationMarkers(automaticForm, formFields);
      return;
    }
    this.validationMarkerArray = [];
    stepper.next();
  }
  async modifyResponse(formResponse: any) {
    const formFields = [...this.formFields];
    for (const formId of this.formIds) {
      this.referencedFormFields[formId].formFieldsOrderedArray.forEach(
        (field) => {
          if (this.form?.hasSections) {
            field.sectionControlName = field.controlName + '---' + formId;
          }
          if (
            formResponse[field.controlName + '---' + formId] === 'response_TEXT'
          )
            formResponse[field.controlName + '---' + formId] = {
              response_TEXT: field.value,
            };
          if (this.isScoreType(field?.inputType) && !this.form.hasSections) {
            field.controlName = field.controlName + formId;
          } else if (field?.showField || field?.hideField) {
            field['formId'] = 'ref ' + formId;
          }
          formFields.push(field);
        }
      );
    }
    for (const field of formFields) {
      let tallyResponses = '';
      let bodyMap;
      let imageMap;
      const controlName = field.sectionControlName || field.controlName;
      switch (field?.inputType) {
        case UserInputType.DATEPICKER:
          formResponse[controlName] =
            formResponse[controlName] &&
            formatDate(formResponse[controlName], 'yyyy-MM-dd', 'en-GB');
          break;

        case ContentInputType.SCORE:
        case ContentInputType.SCALED_SCORE:
        case ContentInputType.HIGHEST_SCORE:
        case ContentInputType.LOWEST_SCORE:
        case ContentInputType.CALCULATION:
        case ContentInputType.THRESHOLD:
          formResponse[controlName] = field.totalScore
            ? `${field.value}/${field.totalScore}`
            : field.value;
          break;

        case ContentInputType.SUBHEADING:
        case ContentInputType.PARAGRAPH:
          if (field.responseText) {
            formResponse[controlName] = { response_TEXT: field.value };
          }
          break;

        case ContentInputType.BMI:
          if (this.addBmiResponse) {
            formResponse[controlName] = String(this.bmiResponse);
          }
          break;

        case ContentInputType.RESPONSE_TALLY:
          if (field?.responses?.length) {
            for (let i = 0; i < field.responses.length; i++) {
              tallyResponses =
                tallyResponses +
                `${field.responses[i]} : ${this.tallies[field.responses[i]]} `;
            }
            formResponse[controlName] = tallyResponses;
          } else if (field?.valueGreaterThan || field?.valueLessThan) {
            formResponse[controlName] = this.tallies[controlName];
          }
          break;

        case ContentInputType.HEADING:
          formResponse[controlName] = 'subtitle_HEADER';
          break;

        case UserInputType.BODY_MAP:
          if (
            (field.hideField && this.hideField(field)) ||
            (field.showField && !this.showField(field)) ||
            field?.removeResponse
          )
            break;
          bodyMap = this.AutomaticImageMapComponentList.find((item) => {
            return item.field.controlName === field.controlName;
          });
          formResponse['bodyMapDataUrl'] = bodyMap?.canvas?.toDataURL();
          break;

        case UserInputType.IMAGE_MAP:
          if (
            (field.hideField && this.hideField(field)) ||
            (field.showField && !this.showField(field)) ||
            field?.removeResponse
          )
            break;
          imageMap = this.AutomaticImageMapComponentList.find((item) => {
            return item.field.controlName === field.controlName;
          });

          formResponse['imageMapDataUrlArray'] = [
            ...(formResponse['imageMapDataUrlArray'] || []),
            {
              controlName: controlName,
              imageURL: await imageMap?.resizeImage(),
            },
          ];
          break;
      }
      if (field.options) {
        const formField = this.formFields.find(
          (fieldItem) => fieldItem.controlName === field.controlName
        );
        const value = formField?.options?.find(
          (option) =>
            option.label ===
            this.automaticForm?.controls?.[field.controlName]?.value
        )?.value;
        switch (this.data?.automatedForm.responseFormat) {
          case ResponseFormat.QUAL:
            break;
          case ResponseFormat.QUANT:
            formResponse[field.controlName] = value;
            break;
          default:
            formResponse[field.controlName]
              ? (formResponse[field.controlName] =
                  value || value === 0
                    ? [formResponse[field.controlName], value]
                    : formResponse[field.controlName])
              : null;
        }
        if (field.inputType === UserInputType.MULTI_SELECT) {
          const options = Array.isArray(formResponse[field.controlName]?.[0])
            ? formResponse[field.controlName][0]
            : formResponse[field.controlName];
          if (Array.isArray(options)) {
            const response = [];
            options?.forEach((option) => {
              const value = this.getOptionValue(field.controlName, option);
              value
                ? response.push(`${option} value: ${value}`)
                : response.push(option);
            });
            formResponse[field.controlName] = response;
          }
        }
      }
      if (field.sectionControlName) {
        const sectionId = field.sectionControlName.split('---')[1];
        if (
          (field.hideField &&
            this.hideField(field, this.referencedFormFields[sectionId])) ||
          (field.showField &&
            !this.showField(field, this.referencedFormFields[sectionId])) ||
          field?.removeResponse
        )
          formResponse[controlName] = null;
      } else {
        if (
          (field.hideField && this.hideField(field)) ||
          (field.showField && !this.showField(field)) ||
          field?.removeResponse
        )
          formResponse[controlName] = null;
      }
    }

    //removing undefined or null values from response before submitting
    Object.keys(formResponse).forEach(
      (key) =>
        !formResponse[key] &&
        formResponse[key] !== 0 &&
        delete formResponse[key]
    );

    return formResponse;
  }

  hideField(field, section?) {
    if (!field?.hideField) {
      return false;
    }
    const hideField = field?.hideField;
    let automaticForm = this.isReferencedForm(field.formId);
    let formFields = this.formFields;
    if (section) {
      automaticForm = section.automaticForm;
      formFields = section.formFieldsOrderedArray;
    }
    const formKeys = hideField && Object.keys(hideField);
    if (typeof hideField === 'boolean') {
      return hideField;
    } else {
      return this.hideOrShow(
        field,
        formKeys,
        automaticForm,
        formFields,
        hideField,
        false
      );
    }
  }
  showField(field, section?) {
    if (!field?.showField) {
      return true;
    }
    const showField = field?.showField;
    const formKeys = showField && Object.keys(showField);
    let automaticForm = this.isReferencedForm(field.formId);
    let formFields = this.formFields;
    if (section) {
      automaticForm = section.automaticForm;
      formFields = section.formFieldsOrderedArray;
    }
    return this.hideOrShow(
      field,
      formKeys,
      automaticForm,
      formFields,
      showField,
      true
    );
  }
  isReferencedForm(formId): UntypedFormGroup {
    return formId?.substring(0, 4) === 'ref '
      ? this.referencedFormFields[formId?.substring(4)]?.['automaticForm']
      : this.automaticForm;
  }
  hideOrShow(
    field,
    formKeys,
    automaticForm,
    formFields,
    targetField,
    isShowField
  ) {
    let flag = true;
    if (formKeys?.length) {
      formKeys.forEach((key) => {
        const value = automaticForm?.controls?.[key]?.value;
        if (value === null || value === undefined) {
          flag = isShowField ? false : true;
        } else if (
          formFields?.find((i) => i.controlName === key)?.inputType ===
          UserInputType.MULTI_SELECT
        ) {
          flag = flag && value.includes(targetField?.[key]);
        } else {
          flag = flag && value === targetField?.[key];
        }
      });
      if ((isShowField && !flag) || (!isShowField && flag)) {
        this.automaticForm?.controls?.[field.controlName]?.setErrors(undefined);
      } else if (
        field.validation &&
        this.automaticForm?.controls?.[field.controlName]?.touched &&
        !this.automaticForm?.controls?.[field.controlName]?.value
      ) {
        this.automaticForm?.controls?.[
          field.controlName
        ]?.updateValueAndValidity();
      }
      return flag;
    } else {
      return false;
    }
  }
  shouldShowContainer(field, section?) {
    return (
      !this.hideField(field, section) &&
      this.showField(field, section) &&
      this.showFieldOnValidation(field, section)
    );
  }
  showFieldOnValidation(field, section?) {
    const automaticForm = section ? section.automaticForm : this.automaticForm;
    const fields = field.showFieldOnValidation;
    if (!fields) {
      return true;
    }
    let valid = true;
    for (const field of fields) {
      if (automaticForm?.controls?.[field]?.value === 0) {
        valid = valid && true;
      } else {
        valid = valid && !!automaticForm?.controls?.[field]?.value;
      }
    }
    return valid;
  }
  updateBmiResponse(bmiResponse) {
    this.addBmiResponse = true;
    this.bmiResponse = bmiResponse;
  }
  updateTallies(tallies) {
    this.tallies = tallies;
  }

  formatNumbers(value: number) {
    let result;
    //deconstruct number
    const numSplit = String(value).split('.');
    //insert comma every thousand
    const integer = numSplit[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const decimal = numSplit[1];

    //reconstruct number
    if (decimal?.length === 1) {
      //append trailing zero
      result = integer + '.' + decimal + '0';
    } else if (decimal?.length === 2) {
      result = integer + '.' + decimal;
    } else {
      result = integer;
    }
    return result;
  }
  getOptionValue(controlName: string, optionLabel: string) {
    const field = this.formFields.find(
      (field) => field.controlName === controlName
    );
    const option = field.options?.find(
      (option) => option.label === optionLabel
    );
    return option.value;
  }
  addToReferencedForms(
    formArrayScores,
    formArrayFields,
    controlValues,
    arrayControlName,
    automaticForm,
    formId?
  ) {
    const formFieldsOrderedArray = {};
    for (const row in formArrayScores) {
      const rowId = this.form?.hasSections
        ? `array${arrayControlName}${row}_${formId}`
        : `array${arrayControlName}${row}`;
      //deleted row? remove from this.referencedFormFields and this.formIds
      if (formArrayScores[row] === null) {
        delete this.referencedFormFields[rowId];
        this.formIds.splice(
          this.formIds.findIndex((formId) => formId === rowId),
          1
        );
        return;
      }
      if (!this.formIds.includes(rowId)) this.formIds.push(rowId);
      //build formFieldsOrderedArray for each row
      for (const index in formArrayFields) {
        const field = JSON.parse(JSON.stringify(formArrayFields[index]));
        if (
          this.isScoreType(field.inputType) &&
          formArrayScores[row][field.controlName]
        ) {
          field.value = formArrayScores[row][field.controlName];
          automaticForm.value[arrayControlName][row][field.controlName] =
            field.value;
        } else if (controlValues?.[row][field.controlName]) {
          field.value = controlValues[row][field.controlName];
        }
        !formFieldsOrderedArray[row]
          ? (formFieldsOrderedArray[row] = [])
          : null;
        formFieldsOrderedArray[row].push(field);
      }
      this.referencedFormFields[rowId] = {
        formFieldsOrderedArray: formFieldsOrderedArray[row],
        automaticForm: this.formService.buildForm(formFieldsOrderedArray[row]),
      };
    }
  }
  scrollTo(controlName) {
    this.elements
      .find((childElement) => childElement.nativeElement.id === controlName)
      .nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
  addToValidationMarker(height, controlName) {
    this.formArrayHeights[controlName] = height;
  }

  isLoggedInUser(field) {
    if (
      !field.loggedInUserOnly ||
      (field.loggedInUserOnly && this.isLoggedIn)
    ) {
      return true;
    } else {
      return false;
    }
  }
  isBoolean(value) {
    return typeof value === 'boolean';
  }
  isScoreType(inputType) {
    if (
      inputType === ContentInputType.SCORE ||
      inputType === ContentInputType.SCALED_SCORE ||
      inputType === ContentInputType.HIGHEST_SCORE ||
      inputType === ContentInputType.LOWEST_SCORE ||
      inputType === ContentInputType.CALCULATION ||
      inputType === ContentInputType.RESPONSE_TALLY ||
      inputType === ContentInputType.THRESHOLD
    ) {
      return true;
    } else {
      return false;
    }
  }
  setValidationMarkers(
    automaticForm: UntypedFormGroup,
    formFields?: any[]
  ): void {
    let formFieldsArray = [];
    if (!this.form?.hasSections) {
      //check other referenced forms that are SHOWN
      this.formFields.forEach((field) => {
        if (field.inputType === ContentInputType.FORM_REFERENCE) {
          const formId = field.formId;
          if (!this.hideField(field) && this.showField(field)) {
            formFieldsArray = [
              ...formFieldsArray,
              ...this.referencedFormFields[formId].formFieldsOrderedArray,
            ];
          }
        } else {
          formFieldsArray = [...formFieldsArray, field];
        }
      });
    } else {
      formFieldsArray = formFields;
    }
    this.validationMarkerArray = [];
    let validationCount = 0;
    const topMarginHeight = 20;
    const marginValidationMarker: validationMarker = {
      controlName: 'top-margin',
      fill: false,
      height: topMarginHeight,
    };
    this.validationMarkerArray.push(marginValidationMarker);
    formFieldsArray.forEach((formField) => {
      if (this.showField(formField) && !this.hideField(formField)) {
        const controlName = formField.controlName;
        const childElement = this.elements.find(
          (childElement) => childElement.nativeElement.id === controlName
        )?.nativeElement;
        let height = childElement?.offsetHeight;
        if (formField.inputType === UserInputType.FORM_ARRAY) {
          //add form-array top bar and bottom margin height
          height = this.formArrayHeights[controlName] + 125;
        }
        if (
          (this.showField(formField) &&
            !this.hideField(formField) &&
            !automaticForm.controls[controlName]) ||
          automaticForm.controls[controlName].valid
        ) {
          const validationMarker: validationMarker = {
            controlName: controlName,
            fill: false,
            height: height,
          };
          this.validationMarkerArray.push(validationMarker);
        } else {
          const validationMarker: validationMarker = {
            controlName: controlName,
            fill: true,
            height: height,
            title:
              formField.title ||
              formField.subTitle ||
              formField.value ||
              formField.controlName,
          };
          this.validationMarkerArray.push(validationMarker);
          validationCount += 1;
        }
      }
    });
    const submitErrorsFooterHeight = 240 + 20 * validationCount;
    const validationMarker: validationMarker = {
      controlName: 'submitAndErrors',
      fill: false,
      height: submitErrorsFooterHeight,
    };
    this.validationMarkerArray.push(validationMarker);
    let heightSum = 0;
    this.validationMarkerArray.forEach((marker) => {
      heightSum += marker.height;
      marker['cummulativeHeight'] = heightSum;
    });
    this.pageHeight =
      this.validationMarkerArray[
        this.validationMarkerArray.length - 1
      ].cummulativeHeight;
  }
}
