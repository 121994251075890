<form [formGroup]="useOfEquipmentTrainingForm">
  <div class="form-formatting">
    <mat-card>
      <mat-card-title>Standing Frame Form</mat-card-title>

      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p><strong>Type of Equipment</strong></p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="text" formControlName="typeOfEquipment" />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p><strong>Serial Number</strong></p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="text" formControlName="serialNumber" />
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p><strong>Benefits of the equipment explained</strong></p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="benefitsOfEquipmentExplained">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="n/a">N/A</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea matInput formControlName="benefitsOfEquipmentComments" type="notes"> </textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong
                >Check environment is clear and location of use of equipment is suitable</strong
              >
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="checkEnvriomentIsClearAndSuitable">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="n/a">N/A</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea matInput formControlName="checkEnvriomentComments" type="notes"> </textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p><strong>Ensure the equipment appears to be in good working order e.g.</strong></p>
            <ul>
              <li>It’s clean</li>
              <li>No parts are missing</li>
              <li>Moveable parts secure</li>
              <li>Clips are working</li>
              <li>BRAKES are working</li>
              <li>Wheels turn freely</li>
            </ul>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="performEquipmentSafetyChecklist">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="n/a">N/A</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea matInput formControlName="performEquipmentSafetyChecklistComments" type="notes">
          </textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>Check that the child is wearing the appropriate footwear or orthotics</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="checkChildIsWearingAppropriateFootwearOrOrthotics">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="n/a">N/A</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea
            matInput
            formControlName="checkChildIsWearingAppropriateFootwearComments"
            type="notes"
          >
          </textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong
                >Ensure brakes are on the equipment before child is handled into it (if
                applicable)</strong
              >
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="checkBrakesOnEquipmentBeforeUse">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="n/a">N/A</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea matInput formControlName="checkBrakesOnEquipmentComments" type="notes">
          </textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong
                >Refer to manual handling or postural care plan for instructions on how to handle
                child in/out of equipment</strong
              >
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="referToManualHandlingOrPosturalCarePlan">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="n/a">N/A</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea matInput formControlName="referToManualHandlingComments" type="notes">
          </textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong
                >Ensure the child is safely secured in the equipment, making sure that all straps
                are secure (if appropriate)</strong
              >
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="ensureChildIsSafelySecuredAndCheckAllStraps">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="n/a">N/A</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea matInput formControlName="ensureChildIsSafelySecuredComments" type="notes">
          </textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong
                >Ensure child appears comfortable and you know how long they should use the
                equipment for</strong
              >
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select
              formControlName="checkChildIsComfortableAndAssessHowLongChildShouldUseEquipment"
            >
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="n/a">N/A</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea matInput formControlName="checkChildIsComfortableComments" type="notes">
          </textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong
                >Monitor child whilst in equipment and take them out if they appear unwell</strong
              >
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="monitorChildUsingEquipmentAndTakeChildOutIfAppearUnwell">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="n/a">N/A</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea matInput formControlName="monitorChildUsingEquipmentComments" type="notes">
          </textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong
                >Ensure the equipment is stored safely and not adjusted by anyone unless advised by
                your physiotherapist</strong
              >
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="storeEquipmentSafelyAndNotToBeAdjustedByAnyone">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="n/a">N/A</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea matInput formControlName="storeEquipmentSafelyComments" type="notes">
          </textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong
                >Ensure that you have read and understood a copy of the user guide for the
                equipment</strong
              >
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="readAndUnderstandUserGuideForEquipment">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="n/a">N/A</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea matInput formControlName="readAndUnderstandUserGuideComments" type="notes">
          </textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong
                >Ensure that if there are any problems, faults or if the child appears to have grown
                that you contact the child’s physiotherapist and DO NOT use the equipment</strong
              >
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="doNotUseEquipmentIfProblemsAndFaultsArePresent">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="n/a">N/A</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea matInput formControlName="checkForProblemsAndFaultsComments" type="notes">
          </textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>If charging instructions please refer to manufacturers guidelines</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="referToGuidelinesForChargingInstruction">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
              <mat-option value="n/a">N/A</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea matInput formControlName="referToGuidelinesComments" type="notes"> </textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-title>Trainee</mat-card-title>

      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p><strong>Name</strong></p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="text" formControlName="traineeName" />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p><strong>Role</strong></p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="text" formControlName="traineeRole" />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p><strong>Date</strong></p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="date" formControlName="traineeDate" />
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-title>Trainer</mat-card-title>

      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p><strong>Name</strong></p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="text" formControlName="trainerName" />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p><strong>Role</strong></p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="text" formControlName="trainerRole" />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p><strong>Date</strong></p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="date" formControlName="trainerDate" />
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <br />
    <mat-form-field class="full-width-field" appearance="fill">
      <mat-label>Any other comments?</mat-label>
      <textarea matInput formControlName="notes" type="notes"> </textarea>
    </mat-form-field>
    <br />
    <button
      mat-raised-button
      class="positive-button"
      type="submit"
      [disabled]="useOfEquipmentTrainingForm.invalid || isSubmitting"
      (click)="onSubmit()"
    >
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
