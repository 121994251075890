<form [formGroup]="uas7Form" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <mat-card>
      <mat-card-title>Weekly Urticaria Activity Score (UAS7)</mat-card-title>
      <mat-card-content class="uas7Text">
        <p>
          Please complete the diary for 7 days. This will provide your UAS7 score for the week
          before your next appointment.
        </p>
        <p>
          When completing the diary, please try to reflect the previous 24 hours as a whole, not
          merely the current situation.
        </p>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content class="uas7Text">
        <p>
          Evaluate the last 24 hours in terms of both the wheals (hives) and the itching with a
          score, using the scale described in the table below:
        </p>
        <table>
          <thead>
            <tr class="dark">
              <th>Wheals (Hives) Score</th>
              <th>Itching Score</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>0 = No wheals</td>
              <td>0 = None</td>
            </tr>
            <tr class="dark">
              <td>1 = Mild (&lt;20 wheals)</td>
              <td>1 = Mild (present but not annoying or troublesome)</td>
            </tr>
            <tr>
              <td>2 = Moderate (20–50 wheals)</td>
              <td>
                2 = Moderate (troublesome but does not interfere with normal daily activity or
                sleep)
              </td>
            </tr>
            <tr class="dark">
              <td>3 = Intense (&gt;50 wheals)</td>
              <td>
                3 = Intense (severe itch, which is sufficiently troublesome to interfere with normal
                daily activity or sleep)
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          For instance, if you had 30 wheals in the past 24 hours, you would score a 2 for wheals.
          If your itching in the past 24 hours was intense and interfering with sleep, you would
          score a 3 for itch, making your total score for the day: 2 + 3 = 5.
        </p>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-title>Your Daily UAS (UAS7)</mat-card-title>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Wheals Score within 24hr</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="whealScore">
              <mat-option value="0">0 (None)</mat-option>
              <mat-option value="1">1 (Mild)</mat-option>
              <mat-option value="2">2 (Moderate)</mat-option>
              <mat-option value="3">3 (Intense)</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Itching Score within 24hr</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="itchingScore">
              <mat-option value="0">0 (None)</mat-option>
              <mat-option value="1">1 (Mild)</mat-option>
              <mat-option value="2">2 (Moderate)</mat-option>
              <mat-option value="3">3 (Intense)</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>Total Score {{ totalScore }}</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput disabled />
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <br />
    <mat-form-field class="full-width-field" appearance="fill">
      <mat-label>Any other comments?</mat-label>
      <textarea matInput formControlName="notes" type="notes"> </textarea>
    </mat-form-field>
    <br />
    <button
      mat-raised-button
      class="positive-button"
      type="submit"
      [disabled]="uas7Form.invalid || isSubmitting"
    >
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
