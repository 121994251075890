<ng-container>
  <p>{{ data.message }}</p>
  <br />
  <div class="w-full flex justify-content-end gap-2">
    <div>
      <button
        (click)="ref.close()"
        class="p-button-outlined"
        aria-label="Close"
        type="button"
        pButton
      >
        {{ data.negativeButton }}
      </button>
    </div>
    <div class="submit-button">
      <p-confirmPopup></p-confirmPopup>
      <button
        *ngIf="data.isArchived"
        style="background-color: #d9342b; border: 1px solid #d9342b"
        aria-label="Confirm"
        data-cy="confirmation-confirm-data-archived"
        (click)="confirm()"
        type="button"
        pButton
      >
        <i class="pi pi-trash"></i>&nbsp;
        {{ data.positiveButton }}
      </button>
      <button
        *ngIf="!data.isArchived"
        aria-label="Confirm"
        data-cy="confirmation-confirm-data-not-archived"
        (click)="confirm()"
        type="button"
        pButton
      >
        <i class="pi pi-upload cursor-pointer p-1"></i>&nbsp;
        {{ data.positiveButton }}
      </button>
    </div>
  </div>
</ng-container>
