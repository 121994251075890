import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';
import { retryWithDelay } from '@ic-monorepo/util-operators';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatest,
  of,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

@Pipe({
  standalone: true,
  name: 'authImage',
  pure: false,
})
export class AuthImagePipe implements PipeTransform, OnDestroy {
  private subscription: Subscription;
  private transformValue = new BehaviorSubject<string>('');
  private latestValue!: string | SafeUrl | SafeResourceUrl;
  private errorImagePath = 'assets/error-image.jpeg';
  private loadingImagePath = 'assets/loading-image.gif';

  constructor(
    private http: HttpClient,
    private domSanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private auth: AngularFireAuth
  ) {
    this.setUpSubscription();
  }

  transform(imagePath: string | Blob | SafeResourceUrl): SafeUrl {
    if (!imagePath) return this.errorImagePath;

    this.transformValue.next(imagePath as string);

    return this.latestValue || this.loadingImagePath;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setUpSubscription(): void {
    if (this.subscription && !this.subscription.closed) return;

    this.subscription = new Subscription();

    const transformSubscription = combineLatest([
      this.transformValue.asObservable().pipe(distinctUntilChanged()),
      this.getHeaders$(),
    ])
      .pipe(
        switchMap(([imagePath, headers]) =>
          this.http
            .get(imagePath, {
              observe: 'response',
              responseType: 'blob',
              headers,
            })
            .pipe(
              filter((response) => !!response.body),
              map((response: HttpResponse<Blob>) =>
                URL.createObjectURL(response.body as Blob)
              ),
              map((unsafeBlobUrl: string) =>
                this.domSanitizer.bypassSecurityTrustUrl(unsafeBlobUrl)
              ),
              filter((blobUrl) => blobUrl !== this.latestValue),
              retryWithDelay(3000, 3),
              catchError(() => of(this.errorImagePath))
            )
        ),
        map((r) => r as SafeUrl),
        tap((imagePath) => {
          this.latestValue = imagePath;
          this.cdr.markForCheck();
        })
      )
      .subscribe();
    this.subscription.add(transformSubscription);
  }

  private getHeaders$(): Observable<{ Authorization: string }> {
    return this.auth.idToken.pipe(
      map((token) => ({ Authorization: `Bearer ${token}` }))
    );
  }
}
