<ng-container *ngIf="field && control">
  <div class="question-container" *ngIf="field?.title">
    <ic-automatic-title
      [field]="field"
      class="half-width-field"
    ></ic-automatic-title>
    <ng-container *ngTemplateOutlet="dateField"></ng-container>
  </div>
  <ng-container *ngIf="!field?.title">
    <ng-container *ngTemplateOutlet="dateField"></ng-container>
  </ng-container>
  <ng-template #dateField>
    <mat-form-field appearance="fill" class="half-width-field">
      <mat-label *ngIf="field?.subTitle"
        ><ic-automatic-subtitle [field]="field"></ic-automatic-subtitle
      ></mat-label>

      <input
        matInput
        [matDatepicker]="picker"
        [formControl]="control"
        [min]="field?.minDate"
        [max]="field?.maxDate"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>

      <mat-error *ngIf="control?.touched && control?.invalid">
        {{ formService?.getErrorMessage(control) }}
      </mat-error>
    </mat-form-field>
  </ng-template>
</ng-container>
