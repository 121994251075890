<ng-container *ngIf="field">
  <ng-container *ngIf="shouldShowContainer(field)">
    <div>
      <h3
        class="static-text"
        *ngIf="
          field?.inputType === ContentInputType.HEADING ||
          field?.inputType === ContentInputType.TITLE
        "
        [ngStyle]="{ 'text-align': field?.alignment || 'center' }"
        [id]="field?.controlName"
        [hidden]="field?.responseText === 'responseOnly' || false"
      >
        {{ field?.value }}
      </h3>

      <h4
        class="static-text"
        *ngIf="field?.inputType === ContentInputType.SCORE"
        [ngStyle]="{ 'text-align': field?.alignment || 'center' }"
        [hidden]="field?.hideScore || false"
      >
        {{ field?.title }} : {{ getScore(field) || 0 }}
        <span *ngIf="!field.hideDenominator"> / {{ field?.totalScore }}</span>
      </h4>

      <h4
        class="static-text"
        *ngIf="field?.inputType === ContentInputType.CALCULATION"
        [ngStyle]="{ 'text-align': field?.alignment || 'center' }"
        [hidden]="field?.hideScore || false"
      >
        {{ field?.title }} : {{ applyCalculation(field) || 0 }}
        <span *ngIf="!field.hideDenominator"> / {{ field?.totalScore }}</span>
      </h4>
      <h4
        class="static-text"
        *ngIf="field?.inputType === ContentInputType.THRESHOLD"
        [ngStyle]="{ 'text-align': field?.alignment || 'center' }"
        [hidden]="field?.hideScore || false"
      >
        {{ field?.title }} : {{ thresholdCalculation(field) || 0 }}
        <span *ngIf="!field.hideDenominator"> / {{ field?.totalScore }}</span>
      </h4>

      <p
        class="static-text"
        *ngIf="field?.inputType === ContentInputType.SUBHEADING"
        [ngStyle]="{
          'text-align': field?.alignment || 'center',
          'font-weight': '600'
        }"
        [id]="field?.controlName"
        [hidden]="field?.responseText === 'responseOnly' || false"
      >
        {{ field?.value }}
      </p>
      <h4
        class="static-text"
        *ngIf="field?.inputType === ContentInputType.BMI"
        [ngStyle]="{ 'text-align': field?.alignment || 'center' }"
        [hidden]="field?.hideScore || false"
      >
        {{ field?.title }} : {{ getBmi(field) || 0 }}
      </h4>
      <p
        class="static-text"
        [hidden]="field?.responseText === 'responseOnly' || false"
        *ngIf="field?.inputType === ContentInputType.PARAGRAPH"
        [ngStyle]="{ 'text-align': field?.alignment || 'center' }"
      >
        {{ field?.value }}
      </p>
      <h4
        class="static-text"
        *ngIf="field?.inputType === ContentInputType.SCALED_SCORE"
        [ngStyle]="{ 'text-align': field?.alignment || 'center' }"
        [hidden]="field?.hideScore || false"
      >
        {{ field?.title }} : {{ getScaledScore(field) || 0 }}
        <span *ngIf="!field.hideDenominator">/ {{ field?.scale }}</span>
      </h4>
      <div
        *ngIf="field?.inputType === ContentInputType.SPACING"
        class="spacing"
        [ngStyle]="{ height: field?.height || '100px' }"
      ></div>
      <ng-container *ngIf="field?.inputType === ContentInputType.RESPONSE_TALLY">
        <ng-container *ngFor="let response of field.responses">
          <h4
            [ngStyle]="{ 'text-align': field?.alignment || 'center' }"
            [hidden]="field?.hideScore || false"
          >
            {{ response }} : {{ getTally(response, field) || 0 }}
          </h4>
        </ng-container>
        <h4
          *ngIf="field?.valueGreaterThan || field?.valueLessThan"
          [ngStyle]="{ 'text-align': field?.alignment || 'center' }"
          [hidden]="field?.hideScore || false"
        >
          {{ field?.title }} : {{ getTally(field?.controlName, field) || 0 }}
        </h4>
      </ng-container>

      <ic-automatic-input
        *ngIf="
          field.inputType === UserInputType.TEXT_FIELD ||
          field.inputType === UserInputType.NUMBER_FIELD ||
          field.inputType === UserInputType.DATE_FIELD
        "
        [field]="field"
        [control]="$any(automaticForm.get(field.controlName))"
      ></ic-automatic-input>

      <ic-automatic-textarea
        *ngIf="field.inputType === UserInputType.TEXTAREA"
        [field]="field"
        [control]="$any(automaticForm.get(field.controlName))"
      ></ic-automatic-textarea>

      <ic-automatic-single-select
        *ngIf="field.inputType === UserInputType.SINGLE_SELECT"
        [field]="field"
        [control]="$any(automaticForm.get(field.controlName))"
      ></ic-automatic-single-select>

      <ic-automatic-multi-select
        *ngIf="field.inputType === UserInputType.MULTI_SELECT"
        [field]="field"
        [control]="$any(automaticForm.get(field.controlName))"
      ></ic-automatic-multi-select>

      <ic-automatic-date-picker
        *ngIf="field.inputType === UserInputType.DATEPICKER"
        [field]="field"
        [control]="$any(automaticForm.get(field.controlName))"
      ></ic-automatic-date-picker>

      <ic-automatic-checkbox
        *ngIf="field.inputType === UserInputType.CHECKBOX"
        [field]="field"
        [control]="$any(automaticForm.get(field.controlName))"
      ></ic-automatic-checkbox>

      <ic-automatic-slider
        *ngIf="field.inputType === UserInputType.SLIDER"
        [field]="field"
        [control]="$any(automaticForm.get(field.controlName))"
      ></ic-automatic-slider>

      <ic-automatic-radio
        *ngIf="field.inputType === UserInputType.RADIO"
        [field]="field"
        [control]="$any(automaticForm.get(field.controlName))"
      ></ic-automatic-radio>

      <ic-automatic-toggle
        *ngIf="field.inputType === UserInputType.TOGGLE"
        [field]="field"
        [control]="$any(automaticForm.get(field.controlName))"
      ></ic-automatic-toggle>

      <ic-automatic-images
        *ngIf="field.inputType === ContentInputType.IMAGES"
        [field]="field"
        [control]="$any(automaticForm.get(field.controlName))"
      ></ic-automatic-images>

      <h4
        class="static-text"
        *ngIf="field?.inputType === ContentInputType.LOWEST_SCORE"
        [ngStyle]="{ 'text-align': field?.alignment || 'center' }"
        [hidden]="field?.hideScore || false"
      >
        {{ field?.title }} : {{ getLowestScore(field) || 0 }}
        <span *ngIf="!field.hideDenominator"> / {{ field?.totalScore }}</span>
      </h4>

      <h4
        class="static-text"
        *ngIf="field?.inputType === ContentInputType.HIGHEST_SCORE"
        [ngStyle]="{ 'text-align': field?.alignment || 'center' }"
        [hidden]="field?.hideScore || false"
      >
        {{ field?.title }} : {{ getHighestScore(field) || 0 }}
        <span *ngIf="!field.hideDenominator"> / {{ field?.totalScore }}</span>
      </h4>
    </div>
  </ng-container>
</ng-container>
