// This removes any properties in an object which is equal to undefined or null

export function cleanObject(obj) {
  for (const propertyName in obj) {
    if (
      obj[propertyName] === null ||
      obj[propertyName] === undefined ||
      (Array.isArray(obj[propertyName]) && !obj[propertyName].length) ||
      obj[propertyName] === ''
    ) {
      delete obj[propertyName];
    }
  }
  return obj;
}
