<p-tabView [activeIndex]="tabIndex" (onChange)="changeTab($event)">
  <p-tabPanel>
    <ng-template pTemplate="header">Add new outcome</ng-template>
    <ic-rd-outcome-create
      [outcomes]="dialogData.outcomeMeasureControl"
      (formValid)="updateFormValidity($event)"
      #rdOutcomeCreateComponent
    ></ic-rd-outcome-create>
  </p-tabPanel>
  <p-tabPanel>
    <ng-template pTemplate="header"
      ><span data-cy="navigate-to-previous-outcomes">View previous outcomes</span></ng-template
    >
    <ic-rd-outcome-previous
      [patientId]="dialogData.patientId"
      [collectionId]="dialogData.collectionId"
      [outcomes]="dialogData.outcomeMeasureControl"
      (cancel)="closeDialog()"
      #rdOutcomePreviousComponent
    ></ic-rd-outcome-previous>
  </p-tabPanel>
</p-tabView>

<ng-template #dialogFooter>
  <div class="flex flex-row justify-content-end gap-2">
    <button (click)="closeDialog()" class="p-button-outlined" type="button" pButton>Skip</button>
    <button
      *ngIf="tabIndex === 0"
      (click)="submitOutcome()"
      [disabled]="!formValid"
      data-cy="submit-outcome"
      aria-label="Submit"
      label="Submit"
      pButton
      icEventTracker="pr-record-outcome"
    ></button>
    <button
      *ngIf="tabIndex === 1"
      (click)="rdOutcomePreviousComponent.exportButtonClick()"
      data-cy="outcome-export-to-csv"
      aria-label="Export to CSV"
      label="Export to CSV"
      icon="pi pi-download"
      pButton
    ></button>
  </div>
</ng-template>
