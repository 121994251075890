import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { FormSwitchComponent } from '@ic-monorepo/feature-clinical-forms';

import {
  CamelCaseToTitleCasePipe,
  HashDirective,
  MediaDisplayComponent,
  PrimeNgModule,
  SharedCommonModule,
  StringToCapitalisedCasePipe,
  VideoRecordComponent,
} from '@ic-monorepo/shared-common';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { NgxdModule } from '@ngxd/core';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { CodeInputModule } from 'angular-code-input';
import { QrCodeModule } from 'ng-qrcode';
import { NgChartsModule } from 'ng2-charts';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MoveEntriesConfirmationDialogComponent } from '../feature-patient-record/redesign/dialogs/rd-move-entries-confirmation-dialog/rd-move-entries-confirmation.component';
import { MoveEntriesDialogComponent } from '../feature-patient-record/redesign/dialogs/rd-move-entries-dialog/rd-move-entries.component';
import { ManageTagsDropdownComponent } from '../feature-submissions-dashboard/components/manage-tags-dropdown/manage-tags-dropdown.component';
import { ConfirmNgDialogComponent, GlobalFormCalculationDialogComponent } from './components';
import { AddPatientToTeamDialogComponent } from './components/dialogs/add-patient-to-team-dialog/add-patient-to-team-dialog.component';
import { CheckboxConfirmationDialogComponent } from './components/dialogs/checkbox-confirmation-dialog/checkbox-confirmation-dialog.component';
import { ConfirmDialogComponent } from './components/dialogs/confirm-dialog/confirm-dialog.component';
import { DataEntryDialogComponent } from './components/dialogs/data-entry-dialog/data-entry-dialog.component';
import { FormDialogComponent } from './components/dialogs/form-dialog/form-dialog.component';
import { ImageDialogComponent } from './components/dialogs/image-dialog/image-dialog.component';
import { QrCodeDialogComponent } from './components/dialogs/qr-code-dialog/qr-code-dialog.component';
import { RequestAccessWithinOrgDialogComponent } from './components/dialogs/request-access-within-org-dialog/request-access-within-org-dialog.component';
import { RequestDialogComponent } from './components/dialogs/request-dialog/request-dialog.component';
import { ViewPatientDetailsComponent } from './components/dialogs/view-patient-details/view-patient-details.component';
import { FormResponseTableComponent } from './components/form-response-table/form-response-table/form-response-table.component';
import { PhoneValidationErrorsComponent } from './components/phone-validation-errors/phone-validation-errors.component';
import { RequestComponent } from './components/request/request.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SubmissionComponent } from './components/submission/submission.component';
import { EventTrackerDirective } from './directives/event-tracker/event-tracker.directive';
import { LimitInputDirective } from './directives/limit-input/limit-input.directive';
import { OnlyNumberDirective } from './directives/only-number/only-number.directive';
import { OpenIntercomArticleDirective } from './directives/open-intercom-article/open-intercom-article.directive';
import { MaterialModule } from './modules/material.module';
import { ConvertTimestampToDateStringPipe, ResizedImageUrlPipe, YesNoPipe } from './pipes';
import { AlphaNumericPipe } from './pipes/alphanumeric.pipe';
import { BodyMapUrlPipe } from './pipes/body-map-url.pipe';
import { NhsPipe } from './pipes/nhs.pipe';
import { ScoreKeyTextPipe } from './pipes/score-key-text.pipe';

const components = [
  RequestComponent,
  SpinnerComponent,
  RequestAccessWithinOrgDialogComponent,
  QrCodeDialogComponent,
  PhoneValidationErrorsComponent,
  DataEntryDialogComponent,
  ConfirmDialogComponent,
  MoveEntriesDialogComponent,
  MoveEntriesConfirmationDialogComponent,
  ConfirmNgDialogComponent,
  ImageDialogComponent,
  RequestDialogComponent,
  SubmissionComponent,
  RequestComponent,
  CheckboxConfirmationDialogComponent,
  FormResponseTableComponent,
  AddPatientToTeamDialogComponent,
  FormDialogComponent,
  ViewPatientDetailsComponent,
  ManageTagsDropdownComponent,
  GlobalFormCalculationDialogComponent,
];

const modules = [
  VgCoreModule,
  VgControlsModule,
  VgOverlayPlayModule,
  VgBufferingModule,
  CommonModule,
  FormsModule,
  LayoutModule,
  NgxdModule,
  ReactiveFormsModule,
  RouterModule,
  NgChartsModule,
  PinchZoomModule,
  DragDropModule,
  QrCodeModule,
  SharedCommonModule,
  ScrollingModule,
  MaterialModule,
  PrimeNgModule,
  CodeInputModule,
];

const directives = [LimitInputDirective, OnlyNumberDirective];

const pipes = [
  NhsPipe,
  YesNoPipe,
  AlphaNumericPipe,
  ConvertTimestampToDateStringPipe,
  OpenIntercomArticleDirective,
  EventTrackerDirective,
  ScoreKeyTextPipe,
  BodyMapUrlPipe,
  ResizedImageUrlPipe,
];

const libs = [
  FormSwitchComponent,
  HashDirective,
  VideoRecordComponent,
  StringToCapitalisedCasePipe,
  MediaDisplayComponent,
  CamelCaseToTitleCasePipe,
];

@NgModule({
  imports: [...modules, ...libs],
  declarations: [...components, ...directives, ...pipes],
  exports: [...components, ...modules, ...directives, ...pipes, FormSwitchComponent],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    DynamicDialogConfig,
    MessageService,
    ConfirmationService,
  ],
})
export class SharedModule {}
