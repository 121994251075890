<form [formGroup]="entryForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <mat-card>
      <mat-card-title
        >Roaccutane® (isotretinoin) Acknowledgement Form for Female
        Patients</mat-card-title
      >
      <mat-card-subtitle
        >To be completed and signed by the patient or
        parent/guardian</mat-card-subtitle
      >
      <mat-card-content>
        <p>
          By signing this acknowledgement form, you declare that you have read
          and understood each of the items listed below, and that you accept the
          risks and necessary precautionary measures associated with Roaccutane
          treatment. Please read each item below carefully, and sign this
          acknowledgement form only if you fully understand each item and agree
          to follow your doctor’s instructions. If a parent or guardian is
          required to sign the acknowledgement form, he or she must also read
          and understand each item before signing the agreement.
        </p>
        <br />
        <strong
          >Do not sign this agreement, and do not take Roaccutane if there is
          anything that you do not understand about the information you have
          received about using Roaccutane.</strong
        >
        <ul>
          <li>
            I understand that there is a very high risk that my unborn baby
            could have severe birth defects if I am pregnant or become pregnant
            while taking Roaccutane. This is why I must not be pregnant while
            taking Roaccutane.
          </li>
          <li>
            I understand that I must not take Roaccutane if I am pregnant.
          </li>
          <li>
            I understand that I must not get pregnant during the entire time of
            my treatment, and for one month after the end of my treatment with
            Roaccutane.
          </li>
          <li>
            I understand that I must use at least 1, and preferably 2 separate,
            effective forms of contraception at least one month before starting
            treatment, throughout the treatment period, and for at least one
            month after stopping the treatment. The only exception is if my
            doctor considers that there is no risk of pregnancy.
          </li>
          <li>
            I understand that I cannot get a prescription for Roaccutane unless
            I have a negative pregnancy test result carried out under medical
            supervision during the first 3 days of my period immediately prior
            to starting Roaccutane therapy. I accept the need for monthly follow
            up visits and more pregnancy tests as decided by my doctor. I will
            also have a pregnancy test 5 weeks after stopping Roaccutane
            therapy.
          </li>
          <li>
            I understand that I should not start taking Roaccutane until I am
            sure that I am not pregnant and have a negative pregnancy test.
          </li>
          <li>
            I have read and understand the following materials my prescriber has
            given to me: Roaccutane – the facts you need and Contraception
            advice for people taking Roaccutane.
          </li>
          <li>
            I understand that I must stop taking Roaccutane immediately and
            inform my doctor if I get pregnant, miss my period, stop using
            contraception methods, or have sex without using contraception. I
            understand that if I become pregnant my doctor may refer me to a
            physician specialised or experienced in birth defects for evaluation
            and advice.
          </li>
        </ul>
        <p>
          My prescriber has answered all my questions about Roaccutane and I
          accept the risks and precautionary measures involved, which have been
          fully explained to me.
        </p>
      </mat-card-content>
    </mat-card>
    <div>
      <mat-form-field
        appearance="fill"
        required
        class="notes-field half-width-field"
      >
        <mat-label>Your name</mat-label>
        <input
          matInput
          formControlName="patientName"
          type="notes"
          placeholder="Patient name"
          autocomplete="off"
        />
      </mat-form-field>

      <mat-checkbox
        formControlName="parentOrGuardianRequired"
        appearance="fill"
        class="checkbox"
      >
        Parent/Guardian authorisation required?
      </mat-checkbox>

      <ng-container *ngIf="parentOrGuardianRequired">
        <mat-form-field
          appearance="fill"
          required
          class="notes-field half-width-field"
        >
          <mat-label>Parent/Guardian name</mat-label>
          <input
            matInput
            formControlName="parentOrGuardianName"
            type="notes"
            placeholder="Patient name"
            autocomplete="off"
          />
        </mat-form-field>
      </ng-container>
      <br />
      <mat-form-field
        appearance="fill"
        required
        class="notes-field half-width-field"
      >
        <mat-label>Your clinician's name</mat-label>
        <input
          matInput
          formControlName="consultantName"
          type="notes"
          placeholder="Consultant Dermatologist name"
          autocomplete="off"
        />
      </mat-form-field>
      <br />

      <button
        mat-raised-button
        class="positive-button"
        type="submit"
        [disabled]="!valid()"
      >
        {{ isSubmitting ? 'Submitting...' : 'Submit' }}
      </button>
    </div>
  </div>
</form>
