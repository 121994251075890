<ng-container>
  <h3 mat-dialog-title>
    {{ data.title }}
  </h3>
  <ng-container *ngIf="data.news2Button && isMobile">
    <p-messages severity="warn" styleClass="banner-sm">
      <ng-template pTemplate>
        <div class="grid p-3 gap-2">
          <i class="pi pi-exclamation-triangle text-xl"></i>
          <span class="font-bold">NEWS2 calculator for calculation purposes only.</span>
          <span>
            Filling out this form will not save any information to a patient's record or initiate any other
            actions.</span
          >
        </div>
      </ng-template>
    </p-messages>
  </ng-container>
  <ng-container *ngIf="data.news2Button && !isMobile">
    <p-messages severity="warn" styleClass="banner-sm">
      <ng-template pTemplate>
        <div class="flex gap-2">
          <i class="pi pi-exclamation-triangle text-xl"></i>
          <span class="font-bold">NEWS2 calculator for calculation purposes only.</span>
          <span>
            Filling out this form will not save any information to a patient's record or initiate any other
            actions.</span
          >
        </div>
      </ng-template>
    </p-messages>
  </ng-container>
  <div data-cy="form-dialog-container" mat-dialog-content>
    <p>{{ data.initialMessage }}</p>
    <mat-horizontal-stepper #stepper>
      <mat-step *ngFor="let formId of data.formIds">
        <ng-template matStepLabel></ng-template>
        <ng-container>
          <mat-card class="form-box">
            <ic-form-switch
              class="form-container"
              [formId]="formId"
              [teamId]="data.teamId"
              [video]="data.video"
              [dialogRef]="dialogRef"
            >
            </ic-form-switch>
          </mat-card>
        </ng-container>
      </mat-step>
    </mat-horizontal-stepper>
    <p *ngIf="!data.news2Button" [class.px-4]="isMobile">
      {{ data.finalMessage }}
    </p>
  </div>
  <div *ngIf="!isCalcForm" class="flex flex-row justify-content-end sticky bottom-0 py-3 surface-50">
    <button
      (click)="closeDialog(true)"
      [disabled]="buttonDisabled"
      [attr.aria-label]="data.positiveButton"
      [label]="data.positiveButton"
      data-cy="show-form-submit-button"
      pButton
    ></button>
  </div>
</ng-container>
