import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-video-consent-form',
  templateUrl: './video-and-photo-consent-form.component.html',
  styleUrls: ['./video-and-photo-consent-form.component.scss'],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class VideoAndPhotoConsentFormComponent implements OnInit {
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  videoAndPhotoConsentForm: UntypedFormGroup;
  isSubmitting = false;
  relationshipReasponse = ['Parent', 'Guardian'];

  ngOnInit(): void {
    this.videoAndPhotoConsentForm = this.fb.group({
      childName: ['', [Validators.required]],
      permission: ['', [Validators.required]],
      dateOfConsent: ['', [Validators.required]],
      relationshipToChild: ['', [Validators.required]],
      parentOrGuardianName: ['', [Validators.required]],
      notes: ['', []],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.videoAndPhotoConsentForm.controls[key]) {
          this.videoAndPhotoConsentForm.controls[key].setValue(formData[key]);
        }
      });
    });
  }

  get childName() {
    return this.videoAndPhotoConsentForm.get('childName').value;
  }

  onSubmit() {
    this.isSubmitting = true;
    const formResponse = {
      ...this.videoAndPhotoConsentForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  valid(): boolean {
    return (
      this.videoAndPhotoConsentForm.dirty &&
      this.videoAndPhotoConsentForm.valid &&
      !this.isSubmitting
    );
  }
}
