<div class="form-dialog custom-dialog">
  <form [formGroup]="linkAccountForm" (ngSubmit)="linkAccountForm.valid && getDetails()">
    <h1 mat-dialog-title>Link patient to SystmOne</h1>
    <div>
      <div mat-dialog-content>
        <p>Linking patient to SystmOne instance: {{ s1InstanceName }}</p>
      </div>
      <p>Please enter the patient's account ID and passphrase from SystmOne.</p>
      <div class="d-flex space-around">
        <mat-form-field
          appearance="outline"
          class="half-width-field"
          [color]="linkAccountForm.get('accountId')?.valid ? 'accent' : null"
        >
          <input matInput formControlName="accountId" type="text" placeholder="Account ID" autocomplete="off" />

          <mat-error
            *ngIf="linkAccountForm.get('accountId')?.errors?.required && linkAccountForm.get('accountId')?.dirty"
          >
            Account ID is required
          </mat-error>
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          class="half-width-field"
          [color]="linkAccountForm.get('passphrase')?.valid ? 'accent' : null"
        >
          <input matInput formControlName="passphrase" type="text" placeholder="Passphrase" autocomplete="off" />

          <mat-error
            *ngIf="linkAccountForm.get('passphrase')?.errors?.required && linkAccountForm.get('passphrase')?.dirty"
          >
            Passphrase is required
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <p>Please enter the patient's NHS number from SystmOne</p>
        <div class="d-flex space-around">
          <mat-form-field
            appearance="outline"
            class="half-width-field"
            [color]="linkAccountForm.get('nhsNumber')?.valid ? 'accent' : null"
          >
            <input
              matInput
              formControlName="nhsNumber"
              type="text"
              placeholder="NHS number"
              autocomplete="off"
              [maxLength]="10"
              icOnlyNumber
            />
            <mat-error
              *ngIf="linkAccountForm.get('nhsNumber')?.errors?.required && linkAccountForm.get('nhsNumber')?.dirty"
            >
              Please enter a valid NHS number
            </mat-error>
            <mat-error *ngIf="linkAccountForm.get('nhsNumber')?.errors?.confirmMatchingStringValidator">
              NHS number entered not the same as patient
            </mat-error>
          </mat-form-field>
          <div class="half-width-field vis-hidden"></div>
        </div>
      </div>
    </div>
    <div mat-dialog-content>
      <p>
        By pressing continue you confirm you have obtained consent from the patient to link their Isla record to
        SystmOne.
      </p>
    </div>
    <div>
      <p>
        <a
          style="cursor: pointer; text-decoration: underline; text-underline-offset: 3px"
          (click)="openLinkAccountHelpDialog()"
        >
          Need help linking an account?</a
        >
      </p>
    </div>
    <div mat-dialog-actions class="dialog-button-group">
      <button mat-button (click)="onNoClick()">Cancel</button>
      <div class="submit-button">
        <button data-cy="submit-system-one-link-account" mat-button [disabled]="linkAccountForm.invalid">
          Continue
        </button>
      </div>
    </div>
  </form>
</div>
