import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Organisation, OrganisationWithId } from '@islacare/ic-types';
import firebase from 'firebase/compat/app';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { DEFAULT_ORGANISATION_FEATURE_FLAGS } from '../../shared/constants/organisation-default-flag';

export interface OrganisationWithUrl extends Organisation {
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class OrganisationsService {
  constructor(
    private db: AngularFirestore,
    private storage: AngularFireStorage,
  ) {}

  getAllOrganisations$(queryFn?: QueryFn): Observable<OrganisationWithId[]> {
    return this.db
      .collection<Organisation>('organisations', queryFn)
      .snapshotChanges()
      .pipe(
        map(organisations => {
          return organisations.map(org => {
            const data = org.payload.doc.data() as Organisation;
            data['id'] = org.payload.doc.id;
            return data as OrganisationWithId;
          });
        }),
      );
  }

  getOrganisation$(id: string): Observable<OrganisationWithId | null> {
    if (!id) return of();

    return this.db
      .doc<Organisation>(`organisations/${id}`)
      .valueChanges()
      .pipe(map(org => org && { ...org, id }));
  }

  getOrganisations$(ids: string[]): Observable<OrganisationWithId[]> {
    if (!ids?.length) return of({} as any);

    return this.db
      .collection<OrganisationWithId>('organisations', ref =>
        ref.where(firebase.firestore.FieldPath.documentId(), 'in', ids),
      )
      .valueChanges({ idField: 'id' });
  }

  getOrganisationSnapshot(orgId: string): Promise<firebase.firestore.DocumentSnapshot<Organisation>> {
    if (!orgId) return;

    return this.db.doc<Organisation>(`organisations/${orgId}`).ref.get();
  }

  getOrganisationWithUrl$(id: string): Observable<OrganisationWithUrl> {
    if (!id) return of();
    return this.getOrganisation$(id).pipe(mergeMap(this.includeUrl));
  }

  private includeUrl = (organisation: Organisation): Observable<OrganisationWithUrl | null> => {
    return organisation
      ? this.imageUrl(organisation.avatar).pipe(
          map(url => ({
            ...organisation,
            url,
          })),
        )
      : of(null);
  };

  private imageUrl = (path: string): Observable<string> => (path ? this.storage.ref(path).getDownloadURL() : of(''));

  /**
   * @description Create a new Organidation
   * @param organisation
   * @param file
   * @param fileName
   */
  async createOrganisation(organisation, file: File, fileName: string, domains: string[]) {
    const organisationId = this.db.createId();
    const fileExtention = fileName.split('.').pop();
    const featureFlagDefaults = DEFAULT_ORGANISATION_FEATURE_FLAGS;
    featureFlagDefaults.securityAndCompliance.emailDomainValidation.whitelistedDomains = domains;
    await this.storeOrganisationAvatar(organisationId, file, fileExtention);
    const compiledOrganisation: Organisation = {
      ...{ name: organisation.name },
      ...{ shortName: organisation.shortName },
      ...{ avatar: `/organisationAvatars/${organisationId}.${fileExtention}` },
      ...{ featureFlags: featureFlagDefaults },
    };

    await this.setOrganisationOnCreate(organisationId, compiledOrganisation);
  }

  async setOrganisationOnCreate(orgId: string, org: Organisation) {
    await this.db
      .doc<Organisation>(`organisations/${orgId}`)
      .set(org)
      .catch(error => {
        throw error;
      });
  }

  /**
   * @description Upload the organisation avatar to server
   * @param organisationId
   * @param file
   * @param fileExtention
   */
  async storeOrganisationAvatar(organisationId: string, file: File, fileExtention: string) {
    const storagePath = `organisationAvatars/${organisationId}.${fileExtention}`;
    const storageRef = this.storage.ref(storagePath);
    await storageRef.put(file);
  }
}
