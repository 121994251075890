import { Component } from '@angular/core';
import { ClinicalFormField } from '@islacare/ic-types';
import { FieldWrapper } from '@ngx-formly/core';
import { MenuItem } from 'primeng/api';
import { ClinicalFormsEditService } from '../../../services/clinical-forms-edit/clinical-forms-edit.service';

@Component({
  selector: 'ic-clinical-form-wrapper-edit',
  templateUrl: './clinical-form-wrapper-edit.component.html',
  styleUrls: ['./clinical-form-wrapper-edit.component.scss']
})
export class ClinicalFormWrapperEditComponent extends FieldWrapper {
  ClinicalFormField = ClinicalFormField;

  constructor(private formEditService: ClinicalFormsEditService) {
    super();
  }

  items: MenuItem[] = [
    {
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: () => this.edit()
    },
    {
      label: 'Add field before',
      icon: 'pi pi-arrow-up',
      command: () => this.addFieldBefore()
    },
    {
      label: 'Add field after',
      icon: 'pi pi-arrow-down',
      command: () => this.addFieldAfter()
    },
    {
      label: 'Duplicate field before',
      icon: 'pi pi-copy',
      command: () => this.addDuplicateFieldBefore()
    },
    {
      label: 'Duplicate field after',
      icon: 'pi pi-copy',
      command: () => this.addDuplicateFieldAfter()
    }
  ];

  addFieldBefore() {
    this.formEditService.addField(this.field, true);
  }

  addDuplicateFieldBefore() {
    this.formEditService.addDuplicateField(this.field, true);
  }

  addFieldAfter() {
    this.formEditService.addField(this.field, false);
  }

  addDuplicateFieldAfter() {
    this.formEditService.addDuplicateField(this.field, false);
  }

  edit() {
    this.formEditService.editField(this.field);
  }
}
