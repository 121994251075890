import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Team, TeamWithId, UserWithId } from '@islacare/ic-types';
import { TeamsService } from '../../../../services/teams/teams.service';
import { DialogData } from '../dialog-data';

interface DialogDataWithTextField extends DialogData {
  textField: string;
  title: string;
  message: string;
  negativeButton: string;
  positiveButton: string;
  showUserTeams?: boolean;
  user?: UserWithId;
  isConfirmed?: boolean;
}
@Component({
  selector: 'ic-request-access-within-org-dialog',
  templateUrl: './request-access-within-org-dialog.component.html',
  styleUrls: ['./request-access-within-org-dialog.component.scss'],
})
export class RequestAccessWithinOrgDialogComponent implements OnInit {
  requestAccessWithinOrgForm = this.fb.group({});
  teamsList: TeamWithId[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<RequestAccessWithinOrgDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataWithTextField,
    private teamsService: TeamsService
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.data.showUserTeams && this.data.user) {
      this.requestAccessWithinOrgForm.addControl(
        'teamId',
        new UntypedFormControl('', Validators.required)
      );

      const teamsSnapshot = await Promise.all(
        this.data.user?.teamIds.map(
          async (teamId) => await this.teamsService.getTeamSnapshot(teamId)
        )
      );

      teamsSnapshot.forEach((doc) => {
        const team = doc.data() as Team;
        if (!team.isPersonal) this.teamsList.push({ ...team, id: doc.id });
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  userConfirmation(): void {
    this.data.isConfirmed = !this.data.isConfirmed;
  }

  requestAccessWithinOrgFormSubmit(): void {
    const requestObj = {
      button: true,
    };

    if (this.data.showUserTeams && this.data.user)
      requestObj['teamId'] = this.requestAccessWithinOrgForm.value.teamId;
    this.dialogRef.close(requestObj);
  }
}
