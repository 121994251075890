<form [formGroup]="entryForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <mat-form-field class="half-width-field" appearance="fill">
      <mat-label>Date of surgery </mat-label>
      <input matInput formControlName="dateOfSurgery" type="date" (change)="daysSinceOp()" />
    </mat-form-field>

    <!-- <ng-container *ngIf="form$ | async as form"> -->
    <mat-form-field class="half-width-field" appearance="fill">
      <mat-label>Surgical category</mat-label>
      <mat-select formControlName="surgicalCategory">
        <!-- <mat-option value="Abdominal hysterectomy">Abdominal hysterectomy</mat-option>
          <mat-option value="Bile duct, liver or pancreatic surgery">Bile duct, liver or pancreatic surgery</mat-option>
          <mat-option value="Breast surgery">Breast surgery</mat-option> -->
        <mat-option value="Cardiac surgery (non-CABG)">Cardiac surgery (non-CABG)</mat-option>
        <!-- <mat-option value="Cholecystectomy">Cholecystectomy</mat-option> -->
        <mat-option value="Coronary artery bypass graft"
          >Coronary artery bypass graft (CABG)</mat-option
        >
        <!-- <mat-option value="Cranial surgery">Cranial surgery</mat-option>
          <mat-option value="Gastric Surgery">Gastric Surgery</mat-option>
          <mat-option value="Hip replacement">Hip replacement</mat-option>
          <mat-option value="Knee replacement">Knee replacement</mat-option>
          <mat-option value="Large bowel surgery">Large bowel surgery</mat-option>
          <mat-option value="Limb amputation">Limb amputation</mat-option>
          <mat-option value="Reduction of long bone fracture">Reduction of long bone fracture</mat-option>
          <mat-option value="Repair of neck and femur">Repair of neck and femur</mat-option>
          <mat-option value="Small bowel surgery">Small bowel surgery</mat-option>
          <mat-option value="Vascular surgery">Vascular surgery</mat-option> -->
        <mat-option value="Thoracic">Thoracic</mat-option>
        <!-- <mat-option [value]="surgicalCategory" *ngFor="let surgicalCategory of form.surgicalCategory">
            {{ surgicalCategory }}
          </mat-option> -->
      </mat-select>
    </mat-form-field>
    <!-- </ng-container> -->
    <br />
    <span> Today's date is: {{ todaysDate | date: 'dd/MM/yyyy' }} </span>
    <br />
    <span> Days since surgery date: {{ daysDiff }} </span>
    <br />

    <mat-radio-group
      appearance="fill"
      class="half-width-field"
      formControlName="healingByPrimaryIntention"
    >
      <mat-label>Healing well by primary intention</mat-label>
      <mat-radio-button value="Yes">Yes</mat-radio-button>
      <mat-radio-button value="No">No</mat-radio-button>
    </mat-radio-group>

    <br />
    <div *ngIf="entryForm.get('healingByPrimaryIntention').value === 'No'">
      <!-- CABG -->
      <mat-form-field
        class="half-width-field"
        appearance="fill"
        *ngIf="
          entryForm.get('surgicalCategory').value.indexOf('Coronary artery bypass graft') !== -1
        "
      >
        <!-- && entryForm.get('healingByPrimaryIntention') -->
        <mat-label>Please indicate which wound(s) have healing problems?</mat-label>
        <mat-select multiple formControlName="cabgSurgicalWound">
          <mat-option value="Chest">Chest</mat-option>
          <mat-option value="Submammary">Submammary</mat-option>
          <mat-option value="Left leg">Left leg</mat-option>
          <mat-option value="Right leg">Right leg</mat-option>
          <mat-option value="Left arm">Left arm</mat-option>
          <mat-option value="Right arm">Right arm</mat-option>
          <mat-option value="Drain site(s)">Drain site(s)</mat-option>
        </mat-select>
      </mat-form-field>

      <br />

      <!-- FOR CABG CHEST -->
      <div
        class="wound-option-container"
        *ngIf="entryForm.get('cabgSurgicalWound').value.indexOf('Chest') !== -1"
      >
        <br />
        <mat-label><strong>Chest wound</strong></mat-label>
        <br />

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Is there any evidence of SSI (please tick all that apply)</mat-label>
          <mat-select formControlName="criteriaForSSIs" #criteriaForSSIs multiple>
            <mat-select-trigger>
              {{ criteriaForSSIs.value ? criteriaForSSIs.value[0] : '' }}
              <span *ngIf="criteriaForSSIs.value?.length > 1" class="example-additional-selection">
                (+{{ criteriaForSSIs.value.length - 1 }}
                {{ criteriaForSSIs.value?.length === 2 ? 'other' : 'others' }})
              </span>
            </mat-select-trigger>
            <mat-option
              *ngFor="let criteriaForSSI of criteriaForSSIList"
              [value]="criteriaForSSI"
              >{{ criteriaForSSI }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Surgical site infection?</mat-label>
          <mat-select matNativeControl formControlName="surgicalSiteInfection">
            <mat-option value="No">No</mat-option>
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="Unsure">Unsure</mat-option>
          </mat-select>
        </mat-form-field>
        <br />

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Current microbiology results</mat-label>
          <mat-select multiple formControlName="chestCurrentMicrobiologyResults">
            <mat-option *ngFor="let microbio of microbiologyResultsList">
              {{ microbio }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <br />

        <mat-label>Surgical wound dehiscence?</mat-label>
        <mat-selection-list
          class="half-width-field"
          appearance="fill"
          formControlName="surgicalWoundDehiscence"
        >
          <mat-list-option value="No">No</mat-list-option>
          <mat-list-option value="Yes – incision spontaneously dehisced"
            >Yes – incision spontaneously dehisced</mat-list-option
          >
          <mat-list-option value="Yes – incision deliberately opened by clinician"
            >Yes – incision deliberately opened by clinician</mat-list-option
          >
        </mat-selection-list>

        <mat-form-field
          class="half-width-field"
          appearance="fill"
          *ngIf="
            surgicalWoundDehiscence.value.indexOf('Yes – incision spontaneously dehisced') !== -1 ||
            surgicalWoundDehiscence.value.indexOf(
              'Yes – incision deliberately opened by clinician'
            ) !== -1
          "
        >
          <mat-label>Depth of dehiscence</mat-label>
          <mat-select formControlName="depthOfDehiscence">
            <mat-option value="Dermal layer only involved">Dermal layer only involved</mat-option>
            <mat-option
              value="No visible subcutaneous fat, subcutenous layer exposed, facia not visible"
              >No visible subcutaneous fat, subcutenous layer exposed, facia not visible</mat-option
            >
            <mat-option value="Subcutaneous layers and facia exposed"
              >Subcutaneous layers and facia exposed</mat-option
            >
            <mat-option
              value="Any area of fascial dehicence with organ space, visera, implant or bone exposed"
              >Any area of fascial dehicence with organ space, visera, implant or bone
              exposed</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field
          *ngIf="
            entryForm
              .get('surgicalWoundDehiscence')
              .value.indexOf('Yes – incision spontaneously dehisced') !== -1
          "
          class="half-width-field"
          appearance="fill"
        >
          <mat-label>Reason for spontaneous dehiscence</mat-label>
          <mat-select multiple formControlName="spontaneousDehiscenceReason">
            <mat-option value="Tension or wound closure issues (inc diathermy)"
              >Tension or wound closure issues (inc diathermy)</mat-option
            >
            <mat-option value="Local odema">Local odema</mat-option>
            <mat-option value="General oedema">General oedema</mat-option>
            <mat-option value="Incisional haematoma">Incisional haematoma</mat-option>
            <mat-option value="Seroma or hernia">Seroma or hernia</mat-option>
            <mat-option value="External trauma">External trauma</mat-option>
            <mat-option value="Other (including patient movement or mechanical disruption)"
              >Other (including patient movement or mechanical disruption)</mat-option
            >
            <mat-option value="Unknown">Unknown</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          class="half-width-field"
          appearance="fill"
          *ngIf="
            entryForm
              .get('surgicalWoundDehiscence')
              .value.indexOf('Yes – incision spontaneously dehisced') !== -1
          "
        >
          <mat-label>Date of dehiscence first noted</mat-label>
          <input matInput formControlName="dehiscenceDateOfOnset" type="date" />
        </mat-form-field>

        <br />
        <mat-form-field
          class="half-width-field"
          appearance="fill"
          *ngIf="entryForm.get('surgicalSiteInfection').value.indexOf('Yes') !== -1"
        >
          <mat-label>Classification</mat-label>
          <mat-select formControlName="classification">
            <mat-option value="Superficial incisional">Superficial incisional</mat-option>
            <mat-option value="Deep incisional">Deep incisional</mat-option>
            <mat-option value="Organ/space">Organ/space</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Point of detection</mat-label>
          <mat-select formControlName="detection">
            <mat-option value="Primary admission">Primary admission</mat-option>
            <mat-option value="Readmission">Readmission</mat-option>
            <mat-option value="Wound clinic">Wound clinic</mat-option>
            <mat-option value="Outpatients (inc ward attender)"
              >Outpatients (inc ward attender)</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-divider class="full-width-field"></mat-divider>
      </div>
      <!-- CABG CHEST END -->

      <!-- FOR SUBMAMMARY START -->
      <div
        class="wound-option-container"
        *ngIf="entryForm.get('cabgSurgicalWound').value.indexOf('Submammary') !== -1"
      >
        <br />
        <mat-label><strong>Submammary wound</strong></mat-label>
        <br />

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Is there any evidence of SSI (please tick all that apply)</mat-label>
          <mat-select formControlName="submammaryCriteriaForSSIs" #criteriaForSSIs multiple>
            <mat-select-trigger>
              {{ criteriaForSSIs.value ? criteriaForSSIs.value[0] : '' }}
              <span *ngIf="criteriaForSSIs.value?.length > 1" class="example-additional-selection">
                (+{{ criteriaForSSIs.value.length - 1 }}
                {{ criteriaForSSIs.value?.length === 2 ? 'other' : 'others' }})
              </span>
            </mat-select-trigger>
            <mat-option
              *ngFor="let criteriaForSSI of criteriaForSSIList"
              [value]="criteriaForSSI"
              >{{ criteriaForSSI }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Surgical site infection?</mat-label>
          <mat-select matNativeControl formControlName="submammarySurgicalSiteInfection">
            <mat-option value="No">No</mat-option>
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="Unsure">Unsure</mat-option>
          </mat-select>
        </mat-form-field>
        <br />

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Current microbiology results</mat-label>
          <mat-select multiple formControlName="submammaryCurrentMicrobiologyResults">
            <mat-option *ngFor="let microbio of microbiologyResultsList">
              {{ microbio }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <br />

        <mat-label>Surgical wound dehiscence?</mat-label>
        <mat-selection-list
          class="half-width-field"
          appearance="fill"
          formControlName="submammarySurgicalWoundDehiscence"
        >
          <mat-list-option value="No">No</mat-list-option>
          <mat-list-option value="Yes – incision spontaneously dehisced"
            >Yes – incision spontaneously dehisced</mat-list-option
          >
          <mat-list-option value="Yes – incision deliberately opened by clinician"
            >Yes – incision deliberately opened by clinician</mat-list-option
          >
        </mat-selection-list>

        <mat-form-field
          class="half-width-field"
          appearance="fill"
          *ngIf="
            entryForm
              .get('submammarySurgicalWoundDehiscence')
              .value.indexOf('Yes – incision spontaneously dehisced') !== -1 ||
            entryForm
              .get('submammarySurgicalWoundDehiscence')
              .value.indexOf('Yes – incision deliberately opened by clinician') !== -1
          "
        >
          <mat-label>Depth of dehiscence</mat-label>
          <mat-select formControlName="submammaryDepthOfDehiscence">
            <mat-option value="Dermal layer only involved">Dermal layer only involved</mat-option>
            <mat-option
              value="No visible subcutaneous fat, subcutenous layer exposed, facia not visible"
              >No visible subcutaneous fat, subcutenous layer exposed, facia not visible</mat-option
            >
            <mat-option value="Subcutaneous layers and facia exposed"
              >Subcutaneous layers and facia exposed</mat-option
            >
            <mat-option
              value="Any area of fascial dehicence with organ space, visera, implant or bone exposed"
              >Any area of fascial dehicence with organ space, visera, implant or bone
              exposed</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field
          *ngIf="
            entryForm
              .get('submammarySurgicalWoundDehiscence')
              .value.indexOf('Yes – incision spontaneously dehisced') !== -1
          "
          class="half-width-field"
          appearance="fill"
        >
          <mat-label>Reason for spontaneous dehiscence</mat-label>
          <mat-select multiple formControlName="submammarySpontaneousDehiscenceReason">
            <mat-option value="Tension or wound closure issues (inc diathermy)"
              >Tension or wound closure issues (inc diathermy)</mat-option
            >
            <mat-option value="Local odema">Local odema</mat-option>
            <mat-option value="General oedema">General oedema</mat-option>
            <mat-option value="Incisional haematoma">Incisional haematoma</mat-option>
            <mat-option value="Seroma or hernia">Seroma or hernia</mat-option>
            <mat-option value="External trauma">External trauma</mat-option>
            <mat-option value="Other (including patient movement or mechanical disruption)"
              >Other (including patient movement or mechanical disruption)</mat-option
            >
            <mat-option value="Unknown">Unknown</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          class="half-width-field"
          appearance="fill"
          *ngIf="
            entryForm
              .get('submammarySurgicalWoundDehiscence')
              .value.indexOf('Yes – incision spontaneously dehisced') !== -1
          "
        >
          <mat-label>Date of dehiscence first noted</mat-label>
          <input matInput formControlName="submammaryDehiscenceDateOfOnset" type="date" />
        </mat-form-field>

        <br />
        <mat-form-field
          class="half-width-field"
          appearance="fill"
          *ngIf="entryForm.get('submammarySurgicalSiteInfection').value.indexOf('Yes') !== -1"
        >
          <mat-label>Classification</mat-label>
          <mat-select formControlName="submammaryClassification">
            <mat-option value="Superficial incisional">Superficial incisional</mat-option>
            <mat-option value="Deep incisional">Deep incisional</mat-option>
            <mat-option value="Organ/space">Organ/space</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Point of detection</mat-label>
          <mat-select formControlName="submammaryDetection">
            <mat-option value="Primary admission">Primary admission</mat-option>
            <mat-option value="Readmission">Readmission</mat-option>
            <mat-option value="Wound clinic">Wound clinic</mat-option>
            <mat-option value="Outpatients (inc ward attender)"
              >Outpatients (inc ward attender)</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-divider class="full-width-field"></mat-divider>
      </div>
      <!-- CABG SUBMAMMARY END -->

      <!-- CABG LEFT LEG START -->
      <div
        class="wound-option-container"
        *ngIf="entryForm.get('cabgSurgicalWound').value.indexOf('Left leg') !== -1"
      >
        <br />
        <mat-label><strong>Left leg wound</strong></mat-label>
        <br />

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Is there any evidence of SSI (please tick all that apply)</mat-label>
          <mat-select formControlName="leftLegCriteriaForSSIs" #criteriaForSSIs multiple>
            <mat-select-trigger>
              {{ criteriaForSSIs.value ? criteriaForSSIs.value[0] : '' }}
              <span *ngIf="criteriaForSSIs.value?.length > 1" class="example-additional-selection">
                (+{{ criteriaForSSIs.value.length - 1 }}
                {{ criteriaForSSIs.value?.length === 2 ? 'other' : 'others' }})
              </span>
            </mat-select-trigger>
            <mat-option
              *ngFor="let criteriaForSSI of criteriaForSSIList"
              [value]="criteriaForSSI"
              >{{ criteriaForSSI }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Surgical site infection?</mat-label>
          <mat-select matNativeControl formControlName="leftLegSurgicalSiteInfection">
            <mat-option value="No">No</mat-option>
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="Unsure">Unsure</mat-option>
          </mat-select>
        </mat-form-field>
        <br />

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Current microbiology results</mat-label>
          <mat-select multiple formControlName="leftLegCurrentMicrobiologyResults">
            <mat-option *ngFor="let microbio of microbiologyResultsList">
              {{ microbio }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <br />

        <mat-label>Surgical wound dehiscence?</mat-label>
        <mat-selection-list
          class="half-width-field"
          appearance="fill"
          formControlName="leftLegSurgicalWoundDehiscence"
        >
          <mat-list-option value="No">No</mat-list-option>
          <mat-list-option value="Yes – incision spontaneously dehisced"
            >Yes – incision spontaneously dehisced</mat-list-option
          >
          <mat-list-option value="Yes – incision deliberately opened by clinician"
            >Yes – incision deliberately opened by clinician</mat-list-option
          >
        </mat-selection-list>

        <mat-form-field
          class="half-width-field"
          appearance="fill"
          *ngIf="
            entryForm
              .get('leftLegSurgicalWoundDehiscence')
              .value.indexOf('Yes – incision spontaneously dehisced') !== -1 ||
            entryForm
              .get('leftLegSurgicalWoundDehiscence')
              .value.indexOf('Yes – incision deliberately opened by clinician') !== -1
          "
        >
          <mat-label>Depth of dehiscence</mat-label>
          <mat-select formControlName="leftLegDepthOfDehiscence">
            <mat-option value="Dermal layer only involved">Dermal layer only involved</mat-option>
            <mat-option
              value="No visible subcutaneous fat, subcutenous layer exposed, facia not visible"
              >No visible subcutaneous fat, subcutenous layer exposed, facia not visible</mat-option
            >
            <mat-option value="Subcutaneous layers and facia exposed"
              >Subcutaneous layers and facia exposed</mat-option
            >
            <mat-option
              value="Any area of fascial dehicence with organ space, visera, implant or bone exposed"
              >Any area of fascial dehicence with organ space, visera, implant or bone
              exposed</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field
          *ngIf="
            entryForm
              .get('leftLegSurgicalWoundDehiscence')
              .value.indexOf('Yes – incision spontaneously dehisced') !== -1
          "
          class="half-width-field"
          appearance="fill"
        >
          <mat-label>Reason for spontaneous dehiscence</mat-label>
          <mat-select multiple formControlName="leftLegSpontaneousDehiscenceReason">
            <mat-option value="Tension or wound closure issues (inc diathermy)"
              >Tension or wound closure issues (inc diathermy)</mat-option
            >
            <mat-option value="Local odema">Local odema</mat-option>
            <mat-option value="General oedema">General oedema</mat-option>
            <mat-option value="Incisional haematoma">Incisional haematoma</mat-option>
            <mat-option value="Seroma or hernia">Seroma or hernia</mat-option>
            <mat-option value="External trauma">External trauma</mat-option>
            <mat-option value="Other (including patient movement or mechanical disruption)"
              >Other (including patient movement or mechanical disruption)</mat-option
            >
            <mat-option value="Unknown">Unknown</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          class="half-width-field"
          appearance="fill"
          *ngIf="
            entryForm
              .get('leftLegSurgicalWoundDehiscence')
              .value.indexOf('Yes – incision spontaneously dehisced') !== -1
          "
        >
          <mat-label>Date of dehiscence first noted</mat-label>
          <input matInput formControlName="leftLegDehiscenceDateOfOnset" type="date" />
        </mat-form-field>

        <br />
        <mat-form-field
          class="half-width-field"
          appearance="fill"
          *ngIf="entryForm.get('leftLegSurgicalSiteInfection').value.indexOf('Yes') !== -1"
        >
          <mat-label>Classification</mat-label>
          <mat-select formControlName="leftLegClassification">
            <mat-option value="Superficial incisional">Superficial incisional</mat-option>
            <mat-option value="Deep incisional">Deep incisional</mat-option>
            <mat-option value="Organ/space">Organ/space</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Point of detection</mat-label>
          <mat-select formControlName="leftLegDetection">
            <mat-option value="Primary admission">Primary admission</mat-option>
            <mat-option value="Readmission">Readmission</mat-option>
            <mat-option value="Wound clinic">Wound clinic</mat-option>
            <mat-option value="Outpatients (inc ward attender)"
              >Outpatients (inc ward attender)</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-divider class="full-width-field"></mat-divider>
      </div>
      <!-- CABG LEFT LEG END -->

      <!-- CABG RIGHT LEG START -->
      <div
        class="wound-option-container"
        *ngIf="entryForm.get('cabgSurgicalWound').value.indexOf('Right leg') !== -1"
      >
        <br />
        <mat-label><strong>Right leg wound</strong></mat-label>
        <br />

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Is there any evidence of SSI (please tick all that apply)</mat-label>
          <mat-select formControlName="rightLegCriteriaForSSIs" #criteriaForSSIs multiple>
            <mat-select-trigger>
              {{ criteriaForSSIs.value ? criteriaForSSIs.value[0] : '' }}
              <span *ngIf="criteriaForSSIs.value?.length > 1" class="example-additional-selection">
                (+{{ criteriaForSSIs.value.length - 1 }}
                {{ criteriaForSSIs.value?.length === 2 ? 'other' : 'others' }})
              </span>
            </mat-select-trigger>
            <mat-option
              *ngFor="let criteriaForSSI of criteriaForSSIList"
              [value]="criteriaForSSI"
              >{{ criteriaForSSI }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Surgical site infection?</mat-label>
          <mat-select matNativeControl formControlName="rightLegSurgicalSiteInfection">
            <mat-option value="No">No</mat-option>
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="Unsure">Unsure</mat-option>
          </mat-select>
        </mat-form-field>
        <br />

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Current microbiology results</mat-label>
          <mat-select multiple formControlName="rightLegCurrentMicrobiologyResults">
            <mat-option *ngFor="let microbio of microbiologyResultsList">
              {{ microbio }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <br />

        <mat-label>Surgical wound dehiscence?</mat-label>
        <mat-selection-list
          class="half-width-field"
          appearance="fill"
          formControlName="rightLegSurgicalWoundDehiscence"
        >
          <mat-list-option value="No">No</mat-list-option>
          <mat-list-option value="Yes – incision spontaneously dehisced"
            >Yes – incision spontaneously dehisced</mat-list-option
          >
          <mat-list-option value="Yes – incision deliberately opened by clinician"
            >Yes – incision deliberately opened by clinician</mat-list-option
          >
        </mat-selection-list>

        <mat-form-field
          class="half-width-field"
          appearance="fill"
          *ngIf="
            entryForm
              .get('rightLegSurgicalWoundDehiscence')
              .value.indexOf('Yes – incision spontaneously dehisced') !== -1 ||
            entryForm
              .get('rightLegSurgicalWoundDehiscence')
              .value.indexOf('Yes – incision deliberately opened by clinician') !== -1
          "
        >
          <mat-label>Depth of dehiscence</mat-label>
          <mat-select formControlName="rightLegDepthOfDehiscence">
            <mat-option value="Dermal layer only involved">Dermal layer only involved</mat-option>
            <mat-option
              value="No visible subcutaneous fat, subcutenous layer exposed, facia not visible"
              >No visible subcutaneous fat, subcutenous layer exposed, facia not visible</mat-option
            >
            <mat-option value="Subcutaneous layers and facia exposed"
              >Subcutaneous layers and facia exposed</mat-option
            >
            <mat-option
              value="Any area of fascial dehicence with organ space, visera, implant or bone exposed"
              >Any area of fascial dehicence with organ space, visera, implant or bone
              exposed</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field
          *ngIf="
            entryForm
              .get('rightLegSurgicalWoundDehiscence')
              .value.indexOf('Yes – incision spontaneously dehisced') !== -1
          "
          class="half-width-field"
          appearance="fill"
        >
          <mat-label>Reason for spontaneous dehiscence</mat-label>
          <mat-select multiple formControlName="rightLegSpontaneousDehiscenceReason">
            <mat-option value="Tension or wound closure issues (inc diathermy)"
              >Tension or wound closure issues (inc diathermy)</mat-option
            >
            <mat-option value="Local odema">Local odema</mat-option>
            <mat-option value="General oedema">General oedema</mat-option>
            <mat-option value="Incisional haematoma">Incisional haematoma</mat-option>
            <mat-option value="Seroma or hernia">Seroma or hernia</mat-option>
            <mat-option value="External trauma">External trauma</mat-option>
            <mat-option value="Other (including patient movement or mechanical disruption)"
              >Other (including patient movement or mechanical disruption)</mat-option
            >
            <mat-option value="Unknown">Unknown</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          class="half-width-field"
          appearance="fill"
          *ngIf="
            entryForm
              .get('rightLegSurgicalWoundDehiscence')
              .value.indexOf('Yes – incision spontaneously dehisced') !== -1
          "
        >
          <mat-label>Date of dehiscence first noted</mat-label>
          <input matInput formControlName="rightLegDehiscenceDateOfOnset" type="date" />
        </mat-form-field>

        <br />
        <mat-form-field
          class="half-width-field"
          appearance="fill"
          *ngIf="entryForm.get('rightLegSurgicalSiteInfection').value.indexOf('Yes') !== -1"
        >
          <mat-label>Classification</mat-label>
          <mat-select formControlName="rightLegClassification">
            <mat-option value="Superficial incisional">Superficial incisional</mat-option>
            <mat-option value="Deep incisional">Deep incisional</mat-option>
            <mat-option value="Organ/space">Organ/space</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Point of detection</mat-label>
          <mat-select formControlName="rightLegDetection">
            <mat-option value="Primary admission">Primary admission</mat-option>
            <mat-option value="Readmission">Readmission</mat-option>
            <mat-option value="Wound clinic">Wound clinic</mat-option>
            <mat-option value="Outpatients (inc ward attender)"
              >Outpatients (inc ward attender)</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-divider class="full-width-field"></mat-divider>
      </div>
      <!-- CABG RIGHT LEG END -->

      <!-- CABG LEFT ARM START -->
      <div
        class="wound-option-container"
        *ngIf="entryForm.get('cabgSurgicalWound').value.indexOf('Left arm') !== -1"
      >
        <br />
        <mat-label><strong>Left arm wound</strong></mat-label>
        <br />

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Is there any evidence of SSI (please tick all that apply)</mat-label>
          <mat-select formControlName="leftArmCriteriaForSSIs" #criteriaForSSIs multiple>
            <mat-select-trigger>
              {{ criteriaForSSIs.value ? criteriaForSSIs.value[0] : '' }}
              <span *ngIf="criteriaForSSIs.value?.length > 1" class="example-additional-selection">
                (+{{ criteriaForSSIs.value.length - 1 }}
                {{ criteriaForSSIs.value?.length === 2 ? 'other' : 'others' }})
              </span>
            </mat-select-trigger>
            <mat-option
              *ngFor="let criteriaForSSI of criteriaForSSIList"
              [value]="criteriaForSSI"
              >{{ criteriaForSSI }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Surgical site infection?</mat-label>
          <mat-select matNativeControl formControlName="leftArmSurgicalSiteInfection">
            <mat-option value="No">No</mat-option>
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="Unsure">Unsure</mat-option>
          </mat-select>
        </mat-form-field>
        <br />

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Current microbiology results</mat-label>
          <mat-select multiple formControlName="leftArmCurrentMicrobiologyResults">
            <mat-option *ngFor="let microbio of microbiologyResultsList">
              {{ microbio }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <br />

        <mat-label>Surgical wound dehiscence?</mat-label>
        <mat-selection-list
          class="half-width-field"
          appearance="fill"
          formControlName="leftArmSurgicalWoundDehiscence"
        >
          <mat-list-option value="No">No</mat-list-option>
          <mat-list-option value="Yes – incision spontaneously dehisced"
            >Yes – incision spontaneously dehisced</mat-list-option
          >
          <mat-list-option value="Yes – incision deliberately opened by clinician"
            >Yes – incision deliberately opened by clinician</mat-list-option
          >
        </mat-selection-list>

        <mat-form-field
          class="half-width-field"
          appearance="fill"
          *ngIf="
            entryForm
              .get('leftArmSurgicalWoundDehiscence')
              .value.indexOf('Yes – incision spontaneously dehisced') !== -1 ||
            entryForm
              .get('leftArmSurgicalWoundDehiscence')
              .value.indexOf('Yes – incision deliberately opened by clinician') !== -1
          "
        >
          <mat-label>Depth of dehiscence</mat-label>
          <mat-select formControlName="leftArmDepthOfDehiscence">
            <mat-option value="Dermal layer only involved">Dermal layer only involved</mat-option>
            <mat-option
              value="No visible subcutaneous fat, subcutenous layer exposed, facia not visible"
              >No visible subcutaneous fat, subcutenous layer exposed, facia not visible</mat-option
            >
            <mat-option value="Subcutaneous layers and facia exposed"
              >Subcutaneous layers and facia exposed</mat-option
            >
            <mat-option
              value="Any area of fascial dehicence with organ space, visera, implant or bone exposed"
              >Any area of fascial dehicence with organ space, visera, implant or bone
              exposed</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field
          *ngIf="
            entryForm
              .get('leftArmSurgicalWoundDehiscence')
              .value.indexOf('Yes – incision spontaneously dehisced') !== -1
          "
          class="half-width-field"
          appearance="fill"
        >
          <mat-label>Reason for spontaneous dehiscence</mat-label>
          <mat-select multiple formControlName="leftArmSpontaneousDehiscenceReason">
            <mat-option value="Tension or wound closure issues (inc diathermy)"
              >Tension or wound closure issues (inc diathermy)</mat-option
            >
            <mat-option value="Local odema">Local odema</mat-option>
            <mat-option value="General oedema">General oedema</mat-option>
            <mat-option value="Incisional haematoma">Incisional haematoma</mat-option>
            <mat-option value="Seroma or hernia">Seroma or hernia</mat-option>
            <mat-option value="External trauma">External trauma</mat-option>
            <mat-option value="Other (including patient movement or mechanical disruption)"
              >Other (including patient movement or mechanical disruption)</mat-option
            >
            <mat-option value="Unknown">Unknown</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          class="half-width-field"
          appearance="fill"
          *ngIf="
            entryForm
              .get('leftArmSurgicalWoundDehiscence')
              .value.indexOf('Yes – incision spontaneously dehisced') !== -1
          "
        >
          <mat-label>Date of dehiscence first noted</mat-label>
          <input matInput formControlName="leftArmDehiscenceDateOfOnset" type="date" />
        </mat-form-field>

        <br />
        <mat-form-field
          class="half-width-field"
          appearance="fill"
          *ngIf="entryForm.get('leftArmSurgicalSiteInfection').value.indexOf('Yes') !== -1"
        >
          <mat-label>Classification</mat-label>
          <mat-select formControlName="leftArmClassification">
            <mat-option value="Superficial incisional">Superficial incisional</mat-option>
            <mat-option value="Deep incisional">Deep incisional</mat-option>
            <mat-option value="Organ/space">Organ/space</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Point of detection</mat-label>
          <mat-select formControlName="leftArmDetection">
            <mat-option value="Primary admission">Primary admission</mat-option>
            <mat-option value="Readmission">Readmission</mat-option>
            <mat-option value="Wound clinic">Wound clinic</mat-option>
            <mat-option value="Outpatients (inc ward attender)"
              >Outpatients (inc ward attender)</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-divider class="full-width-field"></mat-divider>
      </div>
      <!-- CABG LEFT ARM END -->

      <!-- CABG RIGHT ARM START -->
      <div
        class="wound-option-container"
        *ngIf="entryForm.get('cabgSurgicalWound').value.indexOf('Right arm') !== -1"
      >
        <br />

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Is there any evidence of SSI (please tick all that apply)</mat-label>
          <mat-select formControlName="rightArmCriteriaForSSIs" #criteriaForSSIs multiple>
            <mat-select-trigger>
              {{ criteriaForSSIs.value ? criteriaForSSIs.value[0] : '' }}
              <span *ngIf="criteriaForSSIs.value?.length > 1" class="example-additional-selection">
                (+{{ criteriaForSSIs.value.length - 1 }}
                {{ criteriaForSSIs.value?.length === 2 ? 'other' : 'others' }})
              </span>
            </mat-select-trigger>
            <mat-option
              *ngFor="let criteriaForSSI of criteriaForSSIList"
              [value]="criteriaForSSI"
              >{{ criteriaForSSI }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Surgical site infection?</mat-label>
          <mat-select matNativeControl formControlName="rightArmSurgicalSiteInfection">
            <mat-option value="No">No</mat-option>
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="Unsure">Unsure</mat-option>
          </mat-select>
        </mat-form-field>
        <br />

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Current microbiology results</mat-label>
          <mat-select multiple formControlName="rightArmCurrentMicrobiologyResults">
            <mat-option *ngFor="let microbio of microbiologyResultsList">
              {{ microbio }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <br />

        <mat-label><strong>Right arm wound</strong></mat-label>
        <br />
        <mat-label>Surgical wound dehiscence?</mat-label>
        <mat-selection-list
          class="half-width-field"
          appearance="fill"
          formControlName="rightArmSurgicalWoundDehiscence"
        >
          <mat-list-option value="No">No</mat-list-option>
          <mat-list-option value="Yes – incision spontaneously dehisced"
            >Yes – incision spontaneously dehisced</mat-list-option
          >
          <mat-list-option value="Yes – incision deliberately opened by clinician"
            >Yes – incision deliberately opened by clinician</mat-list-option
          >
        </mat-selection-list>

        <mat-form-field
          class="half-width-field"
          appearance="fill"
          *ngIf="
            entryForm
              .get('rightArmSurgicalWoundDehiscence')
              .value.indexOf('Yes – incision spontaneously dehisced') !== -1 ||
            entryForm
              .get('rightArmSurgicalWoundDehiscence')
              .value.indexOf('Yes – incision deliberately opened by clinician') !== -1
          "
        >
          <mat-label>Depth of dehiscence</mat-label>
          <mat-select formControlName="rightArmDepthOfDehiscence">
            <mat-option value="Dermal layer only involved">Dermal layer only involved</mat-option>
            <mat-option
              value="No visible subcutaneous fat, subcutenous layer exposed, facia not visible"
              >No visible subcutaneous fat, subcutenous layer exposed, facia not visible</mat-option
            >
            <mat-option value="Subcutaneous layers and facia exposed"
              >Subcutaneous layers and facia exposed</mat-option
            >
            <mat-option
              value="Any area of fascial dehicence with organ space, visera, implant or bone exposed"
              >Any area of fascial dehicence with organ space, visera, implant or bone
              exposed</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field
          *ngIf="
            entryForm
              .get('rightArmSurgicalWoundDehiscence')
              .value.indexOf('Yes – incision spontaneously dehisced') !== -1
          "
          class="half-width-field"
          appearance="fill"
        >
          <mat-label>Reason for spontaneous dehiscence</mat-label>
          <mat-select multiple formControlName="rightArmSpontaneousDehiscenceReason">
            <mat-option value="Tension or wound closure issues (inc diathermy)"
              >Tension or wound closure issues (inc diathermy)</mat-option
            >
            <mat-option value="Local odema">Local odema</mat-option>
            <mat-option value="General oedema">General oedema</mat-option>
            <mat-option value="Incisional haematoma">Incisional haematoma</mat-option>
            <mat-option value="Seroma or hernia">Seroma or hernia</mat-option>
            <mat-option value="External trauma">External trauma</mat-option>
            <mat-option value="Other (including patient movement or mechanical disruption)"
              >Other (including patient movement or mechanical disruption)</mat-option
            >
            <mat-option value="Unknown">Unknown</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          class="half-width-field"
          appearance="fill"
          *ngIf="
            entryForm
              .get('rightArmSurgicalWoundDehiscence')
              .value.indexOf('Yes – incision spontaneously dehisced') !== -1
          "
        >
          <mat-label>Date of dehiscence first noted</mat-label>
          <input matInput formControlName="rightArmDehiscenceDateOfOnset" type="date" />
        </mat-form-field>

        <br />
        <mat-form-field
          class="half-width-field"
          appearance="fill"
          *ngIf="entryForm.get('rightArmSurgicalSiteInfection').value.indexOf('Yes') !== -1"
        >
          <mat-label>Classification</mat-label>
          <mat-select formControlName="rightArmClassification">
            <mat-option value="Superficial incisional">Superficial incisional</mat-option>
            <mat-option value="Deep incisional">Deep incisional</mat-option>
            <mat-option value="Organ/space">Organ/space</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Point of detection</mat-label>
          <mat-select formControlName="rightArmDetection">
            <mat-option value="Primary admission">Primary admission</mat-option>
            <mat-option value="Readmission">Readmission</mat-option>
            <mat-option value="Wound clinic">Wound clinic</mat-option>
            <mat-option value="Outpatients (inc ward attender)"
              >Outpatients (inc ward attender)</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-divider class="full-width-field"></mat-divider>
      </div>
      <!-- CABG RIGHT ARM END -->

      <!-- CABG DRAIN SITES START -->
      <div
        class="wound-option-container"
        *ngIf="entryForm.get('cabgSurgicalWound').value.indexOf('Drain site(s)') !== -1"
      >
        <br />
        <mat-label><strong>Drain site(s) wound</strong></mat-label>
        <br />

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Is there any evidence of SSI (please tick all that apply)</mat-label>
          <mat-select formControlName="drainSiteCriteriaForSSIs" #criteriaForSSIs multiple>
            <mat-select-trigger>
              {{ criteriaForSSIs.value ? criteriaForSSIs.value[0] : '' }}
              <span *ngIf="criteriaForSSIs.value?.length > 1" class="example-additional-selection">
                (+{{ criteriaForSSIs.value.length - 1 }}
                {{ criteriaForSSIs.value?.length === 2 ? 'other' : 'others' }})
              </span>
            </mat-select-trigger>
            <mat-option
              *ngFor="let criteriaForSSI of criteriaForSSIList"
              [value]="criteriaForSSI"
              >{{ criteriaForSSI }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Surgical site infection?</mat-label>
          <mat-select matNativeControl formControlName="drainSiteSurgicalSiteInfection">
            <mat-option value="No">No</mat-option>
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="Unsure">Unsure</mat-option>
          </mat-select>
        </mat-form-field>
        <br />

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Current microbiology results</mat-label>
          <mat-select multiple formControlName="drainSiteCurrentMicrobiologyResults">
            <mat-option *ngFor="let microbio of microbiologyResultsList">
              {{ microbio }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <br />

        <mat-label>Surgical wound dehiscence?</mat-label>
        <mat-selection-list
          class="half-width-field"
          appearance="fill"
          formControlName="drainSiteSurgicalWoundDehiscence"
        >
          <mat-list-option value="No">No</mat-list-option>
          <mat-list-option value="Yes – incision spontaneously dehisced"
            >Yes – incision spontaneously dehisced</mat-list-option
          >
          <mat-list-option value="Yes – incision deliberately opened by clinician"
            >Yes – incision deliberately opened by clinician</mat-list-option
          >
        </mat-selection-list>

        <mat-form-field
          class="half-width-field"
          appearance="fill"
          *ngIf="
            entryForm
              .get('drainSiteSurgicalWoundDehiscence')
              .value.indexOf('Yes – incision spontaneously dehisced') !== -1 ||
            entryForm
              .get('drainSiteSurgicalWoundDehiscence')
              .value.indexOf('Yes – incision deliberately opened by clinician') !== -1
          "
        >
          <mat-label>Depth of dehiscence</mat-label>
          <mat-select formControlName="drainSiteDepthOfDehiscence">
            <mat-option value="Dermal layer only involved">Dermal layer only involved</mat-option>
            <mat-option
              value="No visible subcutaneous fat, subcutenous layer exposed, facia not visible"
              >No visible subcutaneous fat, subcutenous layer exposed, facia not visible</mat-option
            >
            <mat-option value="Subcutaneous layers and facia exposed"
              >Subcutaneous layers and facia exposed</mat-option
            >
            <mat-option
              value="Any area of fascial dehicence with organ space, visera, implant or bone exposed"
              >Any area of fascial dehicence with organ space, visera, implant or bone
              exposed</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field
          *ngIf="
            entryForm
              .get('drainSiteSurgicalWoundDehiscence')
              .value.indexOf('Yes – incision spontaneously dehisced') !== -1
          "
          class="half-width-field"
          appearance="fill"
        >
          <mat-label>Reason for spontaneous dehiscence</mat-label>
          <mat-select multiple formControlName="drainSiteSpontaneousDehiscenceReason">
            <mat-option value="Tension or wound closure issues (inc diathermy)"
              >Tension or wound closure issues (inc diathermy)</mat-option
            >
            <mat-option value="Local odema">Local odema</mat-option>
            <mat-option value="General oedema">General oedema</mat-option>
            <mat-option value="Incisional haematoma">Incisional haematoma</mat-option>
            <mat-option value="Seroma or hernia">Seroma or hernia</mat-option>
            <mat-option value="External trauma">External trauma</mat-option>
            <mat-option value="Other (including patient movement or mechanical disruption)"
              >Other (including patient movement or mechanical disruption)</mat-option
            >
            <mat-option value="Unknown">Unknown</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          class="half-width-field"
          appearance="fill"
          *ngIf="
            entryForm
              .get('drainSiteSurgicalWoundDehiscence')
              .value.indexOf('Yes – incision spontaneously dehisced') !== -1
          "
        >
          <mat-label>Date of dehiscence first noted</mat-label>
          <input matInput formControlName="drainSiteDehiscenceDateOfOnset" type="date" />
        </mat-form-field>

        <br />
        <mat-form-field
          class="half-width-field"
          appearance="fill"
          *ngIf="entryForm.get('drainSiteSurgicalSiteInfection').value.indexOf('Yes') !== -1"
        >
          <mat-label>Classification</mat-label>
          <mat-select formControlName="drainSiteClassification">
            <mat-option value="Superficial incisional">Superficial incisional</mat-option>
            <mat-option value="Deep incisional">Deep incisional</mat-option>
            <mat-option value="Organ/space">Organ/space</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Point of detection</mat-label>
          <mat-select formControlName="drainSiteDetection">
            <mat-option value="Primary admission">Primary admission</mat-option>
            <mat-option value="Readmission">Readmission</mat-option>
            <mat-option value="Wound clinic">Wound clinic</mat-option>
            <mat-option value="Outpatients (inc ward attender)"
              >Outpatients (inc ward attender)</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-divider class="full-width-field"></mat-divider>
      </div>
      <!-- CABG DRAIN SITES END -->

      <!-- HIP START -->
      <div *ngIf="entryForm.get('surgicalCategory').value.indexOf('Hip replacement') !== -1">
        <mat-label><strong>Hip wound</strong></mat-label>
        <br />
        <mat-radio-group class="half-width-field" appearance="fill" formControlName="rightHipWound">
          <mat-label>Have you got a wound on the right side of your hip?</mat-label>
          <mat-radio-button value="Yes">Yes</mat-radio-button>
          <mat-radio-button value="No">No</mat-radio-button>
        </mat-radio-group>
        <br />
        <mat-radio-group class="half-width-field" appearance="fill" formControlName="leftHipWound">
          <mat-label>Have you got a wound on the left side of your hip?</mat-label>
          <mat-radio-button value="Yes">Yes</mat-radio-button>
          <mat-radio-button value="No">No</mat-radio-button>
        </mat-radio-group>
        <br />

        <!-- RIGHT HIP START -->
        <div *ngIf="entryForm.get('rightHipWound').value === 'Yes'">
          <mat-label><strong>right side hip wound</strong></mat-label>
          <br />

          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Is there any evidence of SSI (please tick all that apply)</mat-label>
            <mat-select formControlName="rightHipCriteriaForSSIs" #criteriaForSSIs multiple>
              <mat-select-trigger>
                {{ criteriaForSSIs.value ? criteriaForSSIs.value[0] : '' }}
                <span
                  *ngIf="criteriaForSSIs.value?.length > 1"
                  class="example-additional-selection"
                >
                  (+{{ criteriaForSSIs.value.length - 1 }}
                  {{ criteriaForSSIs.value?.length === 2 ? 'other' : 'others' }})
                </span>
              </mat-select-trigger>
              <mat-option
                *ngFor="let criteriaForSSI of criteriaForSSIList"
                [value]="criteriaForSSI"
                >{{ criteriaForSSI }}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Surgical site infection?</mat-label>
            <mat-select matNativeControl formControlName="rightHipSurgicalSiteInfection">
              <mat-option value="No">No</mat-option>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="Unsure">Unsure</mat-option>
            </mat-select>
          </mat-form-field>
          <br />

          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Current microbiology results</mat-label>
            <mat-select multiple formControlName="rightHipCurrentMicrobiologyResults">
              <mat-option *ngFor="let microbio of microbiologyResultsList">
                {{ microbio }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <br />

          <mat-label>Surgical wound dehiscence?</mat-label>
          <mat-selection-list
            class="half-width-field"
            appearance="fill"
            formControlName="rightHipSurgicalWoundDehiscence"
          >
            <mat-list-option value="No">No</mat-list-option>
            <mat-list-option value="Yes – incision spontaneously dehisced"
              >Yes – incision spontaneously dehisced</mat-list-option
            >
            <mat-list-option value="Yes – incision deliberately opened by clinician"
              >Yes – incision deliberately opened by clinician</mat-list-option
            >
          </mat-selection-list>

          <mat-form-field
            class="half-width-field"
            appearance="fill"
            *ngIf="
              entryForm
                .get('rightHipSurgicalWoundDehiscence')
                .value.indexOf('Yes – incision spontaneously dehisced') !== -1 ||
              entryForm
                .get('rightHipSurgicalWoundDehiscence')
                .value.indexOf('Yes – incision deliberately opened by clinician') !== -1
            "
          >
            <mat-label>Depth of dehiscence</mat-label>
            <mat-select formControlName="rightHipDepthOfDehiscence">
              <mat-option value="Dermal layer only involved">Dermal layer only involved</mat-option>
              <mat-option
                value="No visible subcutaneous fat, subcutenous layer exposed, facia not visible"
                >No visible subcutaneous fat, subcutenous layer exposed, facia not
                visible</mat-option
              >
              <mat-option value="Subcutaneous layers and facia exposed"
                >Subcutaneous layers and facia exposed</mat-option
              >
              <mat-option
                value="Any area of fascial dehicence with organ space, visera, implant or bone exposed"
                >Any area of fascial dehicence with organ space, visera, implant or bone
                exposed</mat-option
              >
            </mat-select>
          </mat-form-field>

          <mat-form-field
            *ngIf="
              entryForm
                .get('rightHipSurgicalWoundDehiscence')
                .value.indexOf('Yes – incision spontaneously dehisced') !== -1
            "
            class="half-width-field"
            appearance="fill"
          >
            <mat-label>Reason for spontaneous dehiscence</mat-label>
            <mat-select multiple formControlName="rightHipSpontaneousDehiscenceReason">
              <mat-option value="Tension or wound closure issues (inc diathermy)"
                >Tension or wound closure issues (inc diathermy)</mat-option
              >
              <mat-option value="Local odema">Local odema</mat-option>
              <mat-option value="General oedema">General oedema</mat-option>
              <mat-option value="Incisional haematoma">Incisional haematoma</mat-option>
              <mat-option value="Seroma or hernia">Seroma or hernia</mat-option>
              <mat-option value="External trauma">External trauma</mat-option>
              <mat-option value="Other (including patient movement or mechanical disruption)"
                >Other (including patient movement or mechanical disruption)</mat-option
              >
              <mat-option value="Unknown">Unknown</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            class="half-width-field"
            appearance="fill"
            *ngIf="
              entryForm
                .get('rightHipSurgicalWoundDehiscence')
                .value.indexOf('Yes – incision spontaneously dehisced') !== -1
            "
          >
            <mat-label>Date of dehiscence first noted</mat-label>
            <input matInput formControlName="rightHipDehiscenceDateOfOnset" type="date" />
          </mat-form-field>

          <br />
          <mat-form-field
            class="half-width-field"
            appearance="fill"
            *ngIf="entryForm.get('rightHipSurgicalSiteInfection').value.indexOf('Yes') !== -1"
          >
            <mat-label>Classification</mat-label>
            <mat-select formControlName="rightHipClassification">
              <mat-option value="Superficial incisional">Superficial incisional</mat-option>
              <mat-option value="Deep incisional">Deep incisional</mat-option>
              <mat-option value="Organ/space">Organ/space</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Point of detection</mat-label>
            <mat-select formControlName="rightHipDetection">
              <mat-option value="Primary admission">Primary admission</mat-option>
              <mat-option value="Readmission">Readmission</mat-option>
              <mat-option value="Wound clinic">Wound clinic</mat-option>
              <mat-option value="Outpatients (inc ward attender)"
                >Outpatients (inc ward attender)</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-divider class="full-width-field"></mat-divider>
        </div>

        <!-- RIGHT HIP END -->

        <!-- LEFT HIP START -->

        <div *ngIf="entryForm.get('leftHipWound').value === 'Yes'">
          <mat-label><strong>left side hip wound</strong></mat-label>
          <br />

          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Is there any evidence of SSI (please tick all that apply)</mat-label>
            <mat-select formControlName="leftHipCriteriaForSSIs" #criteriaForSSIs multiple>
              <mat-select-trigger>
                {{ criteriaForSSIs.value ? criteriaForSSIs.value[0] : '' }}
                <span
                  *ngIf="criteriaForSSIs.value?.length > 1"
                  class="example-additional-selection"
                >
                  (+{{ criteriaForSSIs.value.length - 1 }}
                  {{ criteriaForSSIs.value?.length === 2 ? 'other' : 'others' }})
                </span>
              </mat-select-trigger>
              <mat-option
                *ngFor="let criteriaForSSI of criteriaForSSIList"
                [value]="criteriaForSSI"
                >{{ criteriaForSSI }}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Surgical site infection?</mat-label>
            <mat-select matNativeControl formControlName="leftHipSurgicalSiteInfection">
              <mat-option value="No">No</mat-option>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="Unsure">Unsure</mat-option>
            </mat-select>
          </mat-form-field>
          <br />

          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Current microbiology results</mat-label>
            <mat-select multiple formControlName="leftHipCurrentMicrobiologyResults">
              <mat-option *ngFor="let microbio of microbiologyResultsList">
                {{ microbio }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <br />

          <mat-label>Surgical wound dehiscence?</mat-label>
          <mat-selection-list
            class="half-width-field"
            appearance="fill"
            formControlName="leftHipSurgicalWoundDehiscence"
          >
            <mat-list-option value="No">No</mat-list-option>
            <mat-list-option value="Yes – incision spontaneously dehisced"
              >Yes – incision spontaneously dehisced</mat-list-option
            >
            <mat-list-option value="Yes – incision deliberately opened by clinician"
              >Yes – incision deliberately opened by clinician</mat-list-option
            >
          </mat-selection-list>

          <mat-form-field
            class="half-width-field"
            appearance="fill"
            *ngIf="
              entryForm
                .get('leftHipSurgicalWoundDehiscence')
                .value.indexOf('Yes – incision spontaneously dehisced') !== -1 ||
              entryForm
                .get('leftHipSurgicalWoundDehiscence')
                .value.indexOf('Yes – incision deliberately opened by clinician') !== -1
            "
          >
            <mat-label>Depth of dehiscence</mat-label>
            <mat-select formControlName="leftHipDepthOfDehiscence">
              <mat-option value="Dermal layer only involved">Dermal layer only involved</mat-option>
              <mat-option
                value="No visible subcutaneous fat, subcutenous layer exposed, facia not visible"
                >No visible subcutaneous fat, subcutenous layer exposed, facia not
                visible</mat-option
              >
              <mat-option value="Subcutaneous layers and facia exposed"
                >Subcutaneous layers and facia exposed</mat-option
              >
              <mat-option
                value="Any area of fascial dehicence with organ space, visera, implant or bone exposed"
                >Any area of fascial dehicence with organ space, visera, implant or bone
                exposed</mat-option
              >
            </mat-select>
          </mat-form-field>

          <mat-form-field
            *ngIf="
              entryForm
                .get('leftHipSurgicalWoundDehiscence')
                .value.indexOf('Yes – incision spontaneously dehisced') !== -1
            "
            class="half-width-field"
            appearance="fill"
          >
            <mat-label>Reason for spontaneous dehiscence</mat-label>
            <mat-select multiple formControlName="leftHipSpontaneousDehiscenceReason">
              <mat-option value="Tension or wound closure issues (inc diathermy)"
                >Tension or wound closure issues (inc diathermy)</mat-option
              >
              <mat-option value="Local odema">Local odema</mat-option>
              <mat-option value="General oedema">General oedema</mat-option>
              <mat-option value="Incisional haematoma">Incisional haematoma</mat-option>
              <mat-option value="Seroma or hernia">Seroma or hernia</mat-option>
              <mat-option value="External trauma">External trauma</mat-option>
              <mat-option value="Other (including patient movement or mechanical disruption)"
                >Other (including patient movement or mechanical disruption)</mat-option
              >
              <mat-option value="Unknown">Unknown</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            class="half-width-field"
            appearance="fill"
            *ngIf="
              entryForm
                .get('leftHipSurgicalWoundDehiscence')
                .value.indexOf('Yes – incision spontaneously dehisced') !== -1
            "
          >
            <mat-label>Date of dehiscence first noted</mat-label>
            <input matInput formControlName="leftHipDehiscenceDateOfOnset" type="date" />
          </mat-form-field>

          <br />
          <mat-form-field
            class="half-width-field"
            appearance="fill"
            *ngIf="entryForm.get('leftHipSurgicalSiteInfection').value.indexOf('Yes') !== -1"
          >
            <mat-label>Classification</mat-label>
            <mat-select formControlName="leftHipClassification">
              <mat-option value="Superficial incisional">Superficial incisional</mat-option>
              <mat-option value="Deep incisional">Deep incisional</mat-option>
              <mat-option value="Organ/space">Organ/space</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Point of detection</mat-label>
            <mat-select formControlName="leftHipDetection">
              <mat-option value="Primary admission">Primary admission</mat-option>
              <mat-option value="Readmission">Readmission</mat-option>
              <mat-option value="Wound clinic">Wound clinic</mat-option>
              <mat-option value="Outpatients (inc ward attender)"
                >Outpatients (inc ward attender)</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-divider class="full-width-field"></mat-divider>
        </div>
        <!-- LEFT HIP END -->
      </div>
      <!-- HIP END -->
      <br />
      <!-- KNEE START -->
      <div *ngIf="entryForm.get('surgicalCategory').value.indexOf('Knee replacement') !== -1">
        <mat-label><strong>Knee wound</strong></mat-label>
        <br />
        <mat-radio-group
          class="half-width-field"
          appearance="fill"
          formControlName="rightKneeWound"
        >
          <mat-label>Have you got a wound on the right side of your knee?</mat-label>
          <mat-radio-button value="Yes">Yes</mat-radio-button>
          <mat-radio-button value="No">No</mat-radio-button>
        </mat-radio-group>
        <br />
        <mat-radio-group class="half-width-field" appearance="fill" formControlName="leftKneeWound">
          <mat-label>Have you got a wound on the left side of your knee?</mat-label>
          <mat-radio-button value="Yes">Yes</mat-radio-button>
          <mat-radio-button value="No">No</mat-radio-button>
        </mat-radio-group>
        <br />

        <!-- RIGHT HIP START -->
        <div *ngIf="entryForm.get('rightKneeWound').value === 'Yes'">
          <mat-label><strong>right side knee wound</strong></mat-label>
          <br />

          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Is there any evidence of SSI (please tick all that apply)</mat-label>
            <mat-select formControlName="rightKneeCriteriaForSSIs" #criteriaForSSIs multiple>
              <mat-select-trigger>
                {{ criteriaForSSIs.value ? criteriaForSSIs.value[0] : '' }}
                <span
                  *ngIf="criteriaForSSIs.value?.length > 1"
                  class="example-additional-selection"
                >
                  (+{{ criteriaForSSIs.value.length - 1 }}
                  {{ criteriaForSSIs.value?.length === 2 ? 'other' : 'others' }})
                </span>
              </mat-select-trigger>
              <mat-option
                *ngFor="let criteriaForSSI of criteriaForSSIList"
                [value]="criteriaForSSI"
                >{{ criteriaForSSI }}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Surgical site infection?</mat-label>
            <mat-select matNativeControl formControlName="rightKneeSurgicalSiteInfection">
              <mat-option value="No">No</mat-option>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="Unsure">Unsure</mat-option>
            </mat-select>
          </mat-form-field>
          <br />

          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Current microbiology results</mat-label>
            <mat-select multiple formControlName="rightKneeCurrentMicrobiologyResults">
              <mat-option *ngFor="let microbio of microbiologyResultsList">
                {{ microbio }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <br />

          <mat-label>Surgical wound dehiscence?</mat-label>
          <mat-selection-list
            class="half-width-field"
            appearance="fill"
            formControlName="rightKneeSurgicalWoundDehiscence"
          >
            <mat-list-option value="No">No</mat-list-option>
            <mat-list-option value="Yes – incision spontaneously dehisced"
              >Yes – incision spontaneously dehisced</mat-list-option
            >
            <mat-list-option value="Yes – incision deliberately opened by clinician"
              >Yes – incision deliberately opened by clinician</mat-list-option
            >
          </mat-selection-list>

          <mat-form-field
            class="half-width-field"
            appearance="fill"
            *ngIf="
              entryForm
                .get('rightKneeSurgicalWoundDehiscence')
                .value.indexOf('Yes – incision spontaneously dehisced') !== -1 ||
              entryForm
                .get('rightKneeSurgicalWoundDehiscence')
                .value.indexOf('Yes – incision deliberately opened by clinician') !== -1
            "
          >
            <mat-label>Depth of dehiscence</mat-label>
            <mat-select formControlName="rightKneeDepthOfDehiscence">
              <mat-option value="Dermal layer only involved">Dermal layer only involved</mat-option>
              <mat-option
                value="No visible subcutaneous fat, subcutenous layer exposed, facia not visible"
                >No visible subcutaneous fat, subcutenous layer exposed, facia not
                visible</mat-option
              >
              <mat-option value="Subcutaneous layers and facia exposed"
                >Subcutaneous layers and facia exposed</mat-option
              >
              <mat-option
                value="Any area of fascial dehicence with organ space, visera, implant or bone exposed"
                >Any area of fascial dehicence with organ space, visera, implant or bone
                exposed</mat-option
              >
            </mat-select>
          </mat-form-field>

          <mat-form-field
            *ngIf="
              entryForm
                .get('rightKneeSurgicalWoundDehiscence')
                .value.indexOf('Yes – incision spontaneously dehisced') !== -1
            "
            class="half-width-field"
            appearance="fill"
          >
            <mat-label>Reason for spontaneous dehiscence</mat-label>
            <mat-select multiple formControlName="rightKneeSpontaneousDehiscenceReason">
              <mat-option value="Tension or wound closure issues (inc diathermy)"
                >Tension or wound closure issues (inc diathermy)</mat-option
              >
              <mat-option value="Local odema">Local odema</mat-option>
              <mat-option value="General oedema">General oedema</mat-option>
              <mat-option value="Incisional haematoma">Incisional haematoma</mat-option>
              <mat-option value="Seroma or hernia">Seroma or hernia</mat-option>
              <mat-option value="External trauma">External trauma</mat-option>
              <mat-option value="Other (including patient movement or mechanical disruption)"
                >Other (including patient movement or mechanical disruption)</mat-option
              >
              <mat-option value="Unknown">Unknown</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            class="half-width-field"
            appearance="fill"
            *ngIf="
              entryForm
                .get('rightKneeSurgicalWoundDehiscence')
                .value.indexOf('Yes – incision spontaneously dehisced') !== -1
            "
          >
            <mat-label>Date of dehiscence first noted</mat-label>
            <input matInput formControlName="rightKneeDehiscenceDateOfOnset" type="date" />
          </mat-form-field>

          <br />
          <mat-form-field
            class="half-width-field"
            appearance="fill"
            *ngIf="entryForm.get('rightKneeSurgicalSiteInfection').value.indexOf('Yes') !== -1"
          >
            <mat-label>Classification</mat-label>
            <mat-select formControlName="rightKneeClassification">
              <mat-option value="Superficial incisional">Superficial incisional</mat-option>
              <mat-option value="Deep incisional">Deep incisional</mat-option>
              <mat-option value="Organ/space">Organ/space</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Point of detection</mat-label>
            <mat-select formControlName="rightKneeDetection">
              <mat-option value="Primary admission">Primary admission</mat-option>
              <mat-option value="Readmission">Readmission</mat-option>
              <mat-option value="Wound clinic">Wound clinic</mat-option>
              <mat-option value="Outpatients (inc ward attender)"
                >Outpatients (inc ward attender)</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-divider class="full-width-field"></mat-divider>
        </div>
        <!-- For knee right end -->
        <!-- For knee left start -->

        <div *ngIf="entryForm.get('leftKneeWound').value === 'Yes'">
          <mat-label><strong>Left side knee wound</strong></mat-label>
          <br />

          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Is there any evidence of SSI (please tick all that apply)</mat-label>
            <mat-select formControlName="leftKneeCriteriaForSSIs" #criteriaForSSIs multiple>
              <mat-select-trigger>
                {{ criteriaForSSIs.value ? criteriaForSSIs.value[0] : '' }}
                <span
                  *ngIf="criteriaForSSIs.value?.length > 1"
                  class="example-additional-selection"
                >
                  (+{{ criteriaForSSIs.value.length - 1 }}
                  {{ criteriaForSSIs.value?.length === 2 ? 'other' : 'others' }})
                </span>
              </mat-select-trigger>
              <mat-option
                *ngFor="let criteriaForSSI of criteriaForSSIList"
                [value]="criteriaForSSI"
                >{{ criteriaForSSI }}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Surgical site infection?</mat-label>
            <mat-select matNativeControl formControlName="leftKneeSurgicalSiteInfection">
              <mat-option value="No">No</mat-option>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="Unsure">Unsure</mat-option>
            </mat-select>
          </mat-form-field>
          <br />

          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Current microbiology results</mat-label>
            <mat-select multiple formControlName="leftKneeCurrentMicrobiologyResults">
              <mat-option *ngFor="let microbio of microbiologyResultsList">
                {{ microbio }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <br />

          <mat-label>Surgical wound dehiscence?</mat-label>
          <mat-selection-list
            class="half-width-field"
            appearance="fill"
            formControlName="leftKneeSurgicalWoundDehiscence"
          >
            <mat-list-option value="No">No</mat-list-option>
            <mat-list-option value="Yes – incision spontaneously dehisced"
              >Yes – incision spontaneously dehisced</mat-list-option
            >
            <mat-list-option value="Yes – incision deliberately opened by clinician"
              >Yes – incision deliberately opened by clinician</mat-list-option
            >
          </mat-selection-list>

          <mat-form-field
            class="half-width-field"
            appearance="fill"
            *ngIf="
              entryForm
                .get('leftKneeSurgicalWoundDehiscence')
                .value.indexOf('Yes – incision spontaneously dehisced') !== -1 ||
              entryForm
                .get('leftKneeSurgicalWoundDehiscence')
                .value.indexOf('Yes – incision deliberately opened by clinician') !== -1
            "
          >
            <mat-label>Depth of dehiscence</mat-label>
            <mat-select formControlName="leftKneeDepthOfDehiscence">
              <mat-option value="Dermal layer only involved">Dermal layer only involved</mat-option>
              <mat-option
                value="No visible subcutaneous fat, subcutenous layer exposed, facia not visible"
                >No visible subcutaneous fat, subcutenous layer exposed, facia not
                visible</mat-option
              >
              <mat-option value="Subcutaneous layers and facia exposed"
                >Subcutaneous layers and facia exposed</mat-option
              >
              <mat-option
                value="Any area of fascial dehicence with organ space, visera, implant or bone exposed"
                >Any area of fascial dehicence with organ space, visera, implant or bone
                exposed</mat-option
              >
            </mat-select>
          </mat-form-field>

          <mat-form-field
            *ngIf="
              entryForm
                .get('leftKneeSurgicalWoundDehiscence')
                .value.indexOf('Yes – incision spontaneously dehisced') !== -1
            "
            class="half-width-field"
            appearance="fill"
          >
            <mat-label>Reason for spontaneous dehiscence</mat-label>
            <mat-select multiple formControlName="leftKneeSpontaneousDehiscenceReason">
              <mat-option value="Tension or wound closure issues (inc diathermy)"
                >Tension or wound closure issues (inc diathermy)</mat-option
              >
              <mat-option value="Local odema">Local odema</mat-option>
              <mat-option value="General oedema">General oedema</mat-option>
              <mat-option value="Incisional haematoma">Incisional haematoma</mat-option>
              <mat-option value="Seroma or hernia">Seroma or hernia</mat-option>
              <mat-option value="External trauma">External trauma</mat-option>
              <mat-option value="Other (including patient movement or mechanical disruption)"
                >Other (including patient movement or mechanical disruption)</mat-option
              >
              <mat-option value="Unknown">Unknown</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            class="half-width-field"
            appearance="fill"
            *ngIf="
              entryForm
                .get('leftKneeSurgicalWoundDehiscence')
                .value.indexOf('Yes – incision spontaneously dehisced') !== -1
            "
          >
            <mat-label>Date of dehiscence first noted</mat-label>
            <input matInput formControlName="leftKneeDehiscenceDateOfOnset" type="date" />
          </mat-form-field>

          <br />
          <mat-form-field
            class="half-width-field"
            appearance="fill"
            *ngIf="entryForm.get('leftKneeSurgicalSiteInfection').value.indexOf('Yes') !== -1"
          >
            <mat-label>Classification</mat-label>
            <mat-select formControlName="leftKneeClassification">
              <mat-option value="Superficial incisional">Superficial incisional</mat-option>
              <mat-option value="Deep incisional">Deep incisional</mat-option>
              <mat-option value="Organ/space">Organ/space</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Point of detection</mat-label>
            <mat-select formControlName="leftKneeDetection">
              <mat-option value="Primary admission">Primary admission</mat-option>
              <mat-option value="Readmission">Readmission</mat-option>
              <mat-option value="Wound clinic">Wound clinic</mat-option>
              <mat-option value="Outpatients (inc ward attender)"
                >Outpatients (inc ward attender)</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-divider class="full-width-field"></mat-divider>
        </div>
        <!-- For knee left end -->
      </div>
      <!-- KNEE END -->

      <!-- TODO: THIS WORKS ONLY TEMP NEED A CLEANER SOLUTION -->
      <div
        *ngIf="
          entryForm.get('surgicalCategory').value.indexOf('Abdominal hysterectomy') !== -1 ||
          entryForm
            .get('surgicalCategory')
            .value.indexOf('Bile duct, liver or pancreatic surgery') !== -1 ||
          entryForm.get('surgicalCategory').value.indexOf('Breast surgery') !== -1 ||
          entryForm.get('surgicalCategory').value.indexOf('Cardiac surgery (non-CABG)') !== -1 ||
          entryForm.get('surgicalCategory').value.indexOf('Cholecystectomy') !== -1 ||
          entryForm.get('surgicalCategory').value.indexOf('Cranial surgery') !== -1 ||
          entryForm.get('surgicalCategory').value.indexOf('Gastric Surgery') !== -1 ||
          entryForm.get('surgicalCategory').value.indexOf('Large bowel surgery') !== -1 ||
          entryForm.get('surgicalCategory').value.indexOf('Limb amputation') !== -1 ||
          entryForm.get('surgicalCategory').value.indexOf('Reduction of long bone fracture') !==
            -1 ||
          entryForm.get('surgicalCategory').value.indexOf('Repair of neck and femur') !== -1 ||
          entryForm.get('surgicalCategory').value.indexOf('Small bowel surgery') !== -1 ||
          entryForm.get('surgicalCategory').value.indexOf('Thoracic') !== -1
        "
      >
        <mat-label
          ><strong>{{
            entryForm.get('surgicalCategory').value.indexOf('Coronary artery bypass graft') !==
              -1 ||
            entryForm.get('surgicalCategory').value.indexOf('Hip replacement') !== -1 ||
            entryForm.get('surgicalCategory').value.indexOf('Knee replacement') !== -1
              ? 'Other wounds'
              : 'Wounds'
          }}</strong></mat-label
        >
        <br />

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Is there any evidence of SSI (please tick all that apply)</mat-label>
          <mat-select formControlName="generalWoundCriteriaForSSIs" #criteriaForSSIs multiple>
            <mat-select-trigger>
              {{ criteriaForSSIs.value ? criteriaForSSIs.value[0] : '' }}
              <span *ngIf="criteriaForSSIs.value?.length > 1" class="example-additional-selection">
                (+{{ criteriaForSSIs.value.length - 1 }}
                {{ criteriaForSSIs.value?.length === 2 ? 'other' : 'others' }})
              </span>
            </mat-select-trigger>
            <mat-option
              *ngFor="let criteriaForSSI of criteriaForSSIList"
              [value]="criteriaForSSI"
              >{{ criteriaForSSI }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Surgical site infection?</mat-label>
          <mat-select formControlName="generalWoundSurgicalSiteInfection">
            <mat-option value="No">No</mat-option>
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="Unsure">Unsure</mat-option>
          </mat-select>
        </mat-form-field>
        <br />

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Current microbiology results</mat-label>
          <mat-select multiple formControlName="generalWoundCurrentMicrobiologyResults">
            <mat-option *ngFor="let microbio of microbiologyResultsList">
              {{ microbio }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <br />

        <mat-label>Surgical wound dehiscence?</mat-label>
        <mat-selection-list
          class="half-width-field"
          appearance="fill"
          formControlName="generalSurgicalWoundDehiscence"
        >
          <mat-list-option value="No">No</mat-list-option>
          <mat-list-option value="Yes – incision spontaneously dehisced"
            >Yes – incision spontaneously dehisced</mat-list-option
          >
          <mat-list-option value="Yes – incision deliberately opened by clinician"
            >Yes – incision deliberately opened by clinician</mat-list-option
          >
        </mat-selection-list>

        <mat-form-field
          class="half-width-field"
          appearance="fill"
          *ngIf="
            entryForm
              .get('generalSurgicalWoundDehiscence')
              .value.indexOf('Yes – incision spontaneously dehisced') !== -1 ||
            entryForm
              .get('generalSurgicalWoundDehiscence')
              .value.indexOf('Yes – incision deliberately opened by clinician') !== -1
          "
        >
          <mat-label>Depth of dehiscence</mat-label>
          <mat-select formControlName="generalWoundDepthOfDehiscence">
            <mat-option value="Dermal layer only involved">Dermal layer only involved</mat-option>
            <mat-option
              value="No visible subcutaneous fat, subcutenous layer exposed, facia not visible"
              >No visible subcutaneous fat, subcutenous layer exposed, facia not visible</mat-option
            >
            <mat-option value="Subcutaneous layers and facia exposed"
              >Subcutaneous layers and facia exposed</mat-option
            >
            <mat-option
              value="Any area of fascial dehicence with organ space, visera, implant or bone exposed"
              >Any area of fascial dehicence with organ space, visera, implant or bone
              exposed</mat-option
            >
            <mat-option value="Unsure">Unsure</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          *ngIf="
            entryForm
              .get('generalSurgicalWoundDehiscence')
              .value.indexOf('Yes – incision spontaneously dehisced') !== -1
          "
          class="half-width-field"
          appearance="fill"
        >
          <mat-label>Reason for spontaneous dehiscence</mat-label>
          <mat-select multiple formControlName="generalWoundSpontaneousDehiscenceReason">
            <mat-option value="Tension or wound closure issues (inc diathermy)"
              >Tension or wound closure issues (inc diathermy)</mat-option
            >
            <mat-option value="Local odema">Local odema</mat-option>
            <mat-option value="General oedema">General oedema</mat-option>
            <mat-option value="Incisional haematoma">Incisional haematoma</mat-option>
            <mat-option value="Seroma or hernia">Seroma or hernia</mat-option>
            <mat-option value="External trauma">External trauma</mat-option>
            <mat-option value="Other (including patient movement or mechanical disruption)"
              >Other (including patient movement or mechanical disruption)</mat-option
            >
            <mat-option value="Unknown">Unknown</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          class="half-width-field"
          appearance="fill"
          *ngIf="
            entryForm
              .get('generalSurgicalWoundDehiscence')
              .value.indexOf('Yes – incision spontaneously dehisced') !== -1
          "
        >
          <mat-label>Date of dehiscence first noted</mat-label>
          <input matInput formControlName="generalDehiscenceDateOfOnset" type="date" />
        </mat-form-field>

        <br />
        <mat-form-field
          class="half-width-field"
          appearance="fill"
          *ngIf="entryForm.get('generalWoundSurgicalSiteInfection').value.indexOf('Yes') !== -1"
        >
          <mat-label>Classification</mat-label>
          <mat-select formControlName="generalWoundClassification">
            <mat-option value="Superficial incisional">Superficial incisional</mat-option>
            <mat-option value="Deep incisional">Deep incisional</mat-option>
            <mat-option value="Organ/space">Organ/space</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Point of detection</mat-label>
          <mat-select formControlName="generalWoundDetection">
            <mat-option value="Primary admission">Primary admission</mat-option>
            <mat-option value="Readmission">Readmission</mat-option>
            <mat-option value="Wound clinic">Wound clinic</mat-option>
            <mat-option value="Outpatients (inc ward attender)"
              >Outpatients (inc ward attender)</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-divider class="full-width-field"></mat-divider>
      </div>

      <br />
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Impact (please tick all that apply)</mat-label>
        <mat-select formControlName="impacts" #impacts multiple>
          <mat-select-trigger>
            {{ impacts.value ? impacts.value[0] : '' }}
            <span *ngIf="impacts.value?.length > 1" class="example-additional-selection">
              (+{{ impacts.value.length - 1 }}
              {{ impacts.value?.length === 2 ? 'other' : 'others' }})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let impact of impactList" [value]="impact">{{ impact }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- end div for primary intention yes or no-->

    <mat-form-field class="half-width-field" appearance="fill">
      <input matInput formControlName="notes" placeholder="Notes:" autocomplete="off" />
    </mat-form-field>
    <!-- <br>
    <mat-slide-toggle
        class="sensitive-image-field"
        matInput
        formControlName = "sensitiveImage"
        value = "sensitiveImage"
        labelPosition = "after"
        color = 'primary'
        (click)="setSensitiveImage($event.checked)"
      >
      Toggle sensitive image
    </mat-slide-toggle>
    <div *ngIf="isSensitive">
      <p class="sensitive-text">The image will be blurred unless your clinician is actively reviewing it.</p>
    </div> -->
    <br />

    <div class="button-group">
      <button
        class="positive-button"
        mat-raised-button
        color="accent"
        type="submit"
        [disabled]="isSubmitting"
      >
        {{ isSubmitting ? 'Submitting...' : 'Submit' }}
      </button>
    </div>
  </div>
</form>
