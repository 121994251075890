import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { SystemCheckService } from '@ic-monorepo/shared-common';
import { MessageType } from '@islacare/ic-types';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { tap } from 'rxjs';
import { CollectionsService } from '../../../services/collections/collections.service';
import { OrganisationsService } from '../../../services/organisations/organisations.service';
import { PatientMessageCopyService } from '../../../services/patientMessage-copy/patientMessage-copy.service';
import { emailValidator } from '../../../utils/custom-validators/email.validator';
interface AdditionalEmailAddressesMap {
  [key: string]: string;
}
interface IDialogDataEmailRequest {
  message: string;
  email: string;
  patientFirstName: string;
  patientId: string;
  collectionId: string;
  messageType: MessageType;
  preSetRecipient: string;
  keepLinkSevenDays: boolean;
  additionalEmailAddresses?: AdditionalEmailAddressesMap;
  hideReminderCheckbox: boolean;
}

@UntilDestroy()
@Component({
  selector: 'ic-email-request-dialog',
  templateUrl: './email-request-dialog.component.html',
})
export class EmailRequestDialogComponent implements OnInit {
  data: IDialogDataEmailRequest;
  recipientKeys: string[];
  additionalEmailAddresses;
  messageBodyCanBeOverwritten = true;

  emailRequestForm = this._fb.group({
    recipient: ['', [emailValidator()]],
    messageIntro: ['', []],
    messageBody: ['', [Validators.required]],
    messageExpiry: ['', []],
    reminder: [true],
    includeMessageIntro: [true],
  });
  isMobile = this.systemCheck.isMobile;
  messageType: MessageType;
  MessageType = MessageType;
  messageBody;
  messageIntro;
  constructor(
    private ref: DynamicDialogRef,
    private _fb: UntypedFormBuilder,
    private config: DynamicDialogConfig,
    private systemCheck: SystemCheckService,
    private patientMessageCopyService: PatientMessageCopyService,
    private collectionsService: CollectionsService,
    private organisationsService: OrganisationsService
  ) {
    this.data = this.config.data;
  }

  async ngOnInit(): Promise<void> {
    await this.setMessageDetails();
    if (this.data.additionalEmailAddresses) {
      this.recipientKeys =
        Object.keys(this.data.additionalEmailAddresses) || [];

      this.emailRequestForm
        .get('recipient')
        .setValidators([Validators.required, emailValidator()]);
      if (this.data.preSetRecipient) {
        if (
          Object.keys(this.data.additionalEmailAddresses).includes(
            this.data.preSetRecipient
          ) ||
          this.data.preSetRecipient === 'Patient'
        ) {
          this.emailRequestForm.controls.recipient.patchValue(
            this.data.preSetRecipient
          );
        }
      } else if (this.recipientKeys?.length === 1 && this.data.email) {
        this.emailRequestForm.get('recipient').patchValue('Patient');
      }
    } else if (this.data.email) {
      this.emailRequestForm.get('recipient').patchValue(this.data.email);
    }
  }

  onNoClick(): void {
    this.ref.close();
  }

  emailRequestSubmit(): void {
    this.ref.close({
      emailText:
        (this.emailRequestForm.value.messageIntro ?? '') +
        this.emailRequestForm.value.messageBody,
      reminder: this.emailRequestForm.value.reminder,
      recipientEmail:
        this.data.additionalEmailAddresses[
          this.emailRequestForm.value.recipient
        ],
      emailRecipientName: this.emailRequestForm.value.recipient,
    });
  }

  async setMessageDetails() {
    const collection = (
      await this.collectionsService.getCollectionSnapshot(
        this.data.patientId,
        this.data.collectionId
      )
    )?.data();
    const organisation = (
      await this.organisationsService.getOrganisationSnapshot(
        collection?.organisationId
      )
    )?.data();

    this.messageType = this.data.messageType;
    const preSetMessage = collection?.request?.smsBody;

    if (preSetMessage) {
      this.messageBodyCanBeOverwritten = false;
      this.emailRequestForm.controls.messageBody.setValue(preSetMessage);
    } else {
      this.emailRequestForm.controls.messageBody.setValue(
        this.patientMessageCopyService.getMessageBody(
          organisation.name,
          this.messageType
        )
      );
    }

    this.emailRequestForm.valueChanges
      .pipe(
        tap((formValue) => {
          const recipient = Object.keys(
            this.data.additionalEmailAddresses ?? {}
          ).includes(formValue.recipient)
            ? formValue.recipient
            : null;

          this.emailRequestForm
            .get('messageIntro')
            .setValue(
              this.patientMessageCopyService.getMessageIntro(
                this.data.additionalEmailAddresses,
                this.data.patientFirstName,
                this.data.email,
                formValue.includeMessageIntro,
                recipient
              ),
              { emitEvent: false }
            );

          this.emailRequestForm
            .get('messageExpiry')
            .setValue(
              this.patientMessageCopyService.getMessageExpiry(
                formValue.reminder,
                this.data.keepLinkSevenDays
              ),
              { emitEvent: false }
            );
        })
      )
      .subscribe();
    this.emailRequestForm.updateValueAndValidity();
  }
}
