<div class="flex flex-column gap-3">
  <div class="flex flex-column gap-1">
    <span class="mb-3">{{ config.data.message }}</span>
    <span><strong>Description:</strong> {{ config.data.description }}</span>
    <span><strong>Has Calculations:</strong> {{ config.data.hasCalculation ? 'Yes' : 'No' }}</span>
    <span>
      <strong>Calculation Reference:</strong>
      <a [href]="config.data.calculationReference" target="_blank">{{ config.data.calculationReference }}</a>
    </span>
    <span><strong>Validated:</strong> {{ config.data.validated ? 'Yes' : 'No' }}</span>
  </div>

  <div class="flex align-items-end justify-content-end">
    <p-button
      (click)="ref.close()"
      [attr.aria-label]="config.data.positiveButton"
      [label]="config.data.positiveButton"
      class="p-button-danger"
      type="button"
    ></p-button>
  </div>
</div>
