import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class NavBarContextService {
  private hideNavBarSubject = new BehaviorSubject<boolean>(false);
  hideNavBar$ = this.hideNavBarSubject.asObservable();

  constructor(private route: ActivatedRoute) {}

  watchUrlForNavBarVisibility(): void {
    this.route.queryParamMap
      .pipe(
        map(params => params.get('hideNavBar')),
        tap(hideNavBar => {
          if (hideNavBar === 'true') {
            this.setHideNavBar(true);
          }
          if (hideNavBar === 'false') {
            this.setHideNavBar(false);
          }
        }),
      )
      .subscribe();
  }

  setHideNavBar(value: boolean): void {
    this.hideNavBarSubject.next(value);
  }
}
