import { Injectable } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { SessionStorageService } from '@ic-monorepo/shared-common';
import { EnhancedConsentDetails } from '@ic-monorepo/shared-submissions';
import {
  CaptureType,
  ConsentedUser,
  EntryFlowDetails,
  RequestDoc,
} from '@islacare/ic-types';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, tap } from 'rxjs';
import { IntercomService } from '../../services/intercom/intercom.service';

export enum UploadStatus {
  WAITING = 'waiting',
  UPLOADING = 'uploading',
  UPLOADED = 'uploaded',
}

export interface SubmissionFlowState {
  progress: number;
  complete: boolean;
  loading: boolean;
  error: string;
  submitting: boolean;
  requestId: string;
  requestDoc: RequestDoc;
  entryFlowDetails: EntryFlowDetails;
  consentedUser: ConsentedUser | string;
  consentBestInterest: boolean;
  enhancedConsentDetails: EnhancedConsentDetails;
  formData: any[] | any;
  media: SubmissionFlowMedia[];
  pageRefreshed: boolean;
  safeMode: boolean;
}

export interface SubmissionFlowMedia {
  id?: string;
  file: File;
  url: string;
  safeUrl: SafeUrl;
  captureType: CaptureType;
  notes: string;
  sensitive: boolean;
  uploadProgress?: number;
  uploadStatus?: UploadStatus;
}

const defaultState: SubmissionFlowState = {
  progress: 10,
  complete: false,
  loading: true,
  error: null,
  submitting: false,
  requestId: null,
  requestDoc: null,
  entryFlowDetails: null,
  consentedUser: null,
  consentBestInterest: false,
  enhancedConsentDetails: null,
  formData: [],
  media: [],
  pageRefreshed: false,
  safeMode: false,
};

@Injectable()
export class SubmissionFlowStore extends ComponentStore<SubmissionFlowState> {
  readonly full$: Observable<SubmissionFlowState> = this.select(
    (state) => state
  ).pipe(
    tap((state: SubmissionFlowState) => {
      if (!state.consentedUser) return;

      this.sessionStorageService.setItem(`submission-${state.requestId}`, {
        requestId: state.requestId,
        requestDoc: state.requestDoc,
        entryFlowDetails: state.entryFlowDetails,
        consentedUser: state.consentedUser,
        consentBestInterest: state.consentBestInterest,
        enhancedConsentDetails: state.enhancedConsentDetails,
        formData: state.formData,
        pageRefreshed: state.pageRefreshed,
      });
    })
  );
  readonly progress$: Observable<number> = this.select(
    (state) => state.progress
  );
  readonly submitting$: Observable<boolean> = this.select(
    (state) => state.submitting
  );
  readonly formIds$: Observable<string[]> = this.select(
    (state) => state.entryFlowDetails?.formIds
  );
  readonly mediaCount$: Observable<number> = this.select(
    (state) => state?.media?.length
  );
  readonly captureType$: Observable<CaptureType> = this.select(
    (state) => state.entryFlowDetails?.captureType
  );

  constructor(
    private sessionStorageService: SessionStorageService,
    private intercomService: IntercomService
  ) {
    super(defaultState);
  }

  setupSubmissionState(requestId: string, requestDoc: RequestDoc): boolean {
    this.intercomService.setIntercomProperties({ islaRequestId: requestId });

    if (!requestDoc) {
      this.setError('No Request Doc provided');
      return;
    }

    const locallyStoredRequest = this.sessionStorageService.getItem(
      `submission-${requestId}`
    );

    if (locallyStoredRequest) {
      this.setState({
        ...defaultState,
        ...locallyStoredRequest,
        loading: false,
        pageRefreshed: true,
      });
      return false;
    }

    this.sessionStorageService.clear();

    this.setState({
      ...defaultState,
      requestId: requestId,
      requestDoc: requestDoc,
      loading: false,
    });
    return true;
  }

  setLoading(loading: boolean) {
    this.setState((state) => ({
      ...state,
      loading: loading,
    }));
  }

  setError(error: string) {
    this.setState((state) => ({
      ...state,
      error: error,
    }));
  }

  setSubmitting(submitting: boolean) {
    this.setState((state) => ({
      ...state,
      submitting: submitting,
    }));
  }

  setProgress(progress: number) {
    this.setState((state) => {
      return {
        ...state,
        error: null,
        progress: progress,
      };
    });
  }

  setEntryFlowDetails(entryFlowDetails: EntryFlowDetails) {
    this.intercomService.setIntercomProperties({
      islaPatientId: entryFlowDetails.patientId,
      islaCollectionId: entryFlowDetails.collectionId,
      islaCollectionAuthId: entryFlowDetails.collectionAuthId,
    });

    this.setState((state) => {
      return {
        ...state,
        entryFlowDetails: entryFlowDetails,
      };
    });
  }

  setEnhancedConsentDetails(enhancedConsent: EnhancedConsentDetails) {
    this.setState((state) => {
      return {
        ...state,
        enhancedConsentDetails: enhancedConsent,
      };
    });
  }

  setConsentedUser(consentedUser: ConsentedUser | string) {
    this.setState((state) => ({
      ...state,
      consentedUser: consentedUser,
    }));
  }

  setConsentAsBestInterest() {
    this.setState((state) => ({
      ...state,
      consentBestInterest: true,
    }));
  }

  setFormData(formData: any) {
    this.setState((state) => {
      if (state.formData.length > 0) {
        state.formData = state.formData.filter(
          (data) => data.formId !== formData.formId
        );
      }

      return {
        ...state,
        formData:
          state.formData.length === 0
            ? [formData]
            : [...state.formData, formData],
      };
    });
  }

  clearFormData() {
    this.setState((state) => {
      return { ...state, formData: [] };
    });
  }

  setMedia(media: SubmissionFlowMedia) {
    this.setState((state) => {
      return {
        ...state,
        media: state.media.length === 0 ? [media] : [...state.media, media],
      };
    });
  }

  removeMedia(media: SubmissionFlowMedia) {
    this.setState((state) => {
      return {
        ...state,
        media: state.media.filter((x) => x != media),
      };
    });
  }

  clearMedia() {
    this.setState((state) => {
      return {
        ...state,
        media: [],
      };
    });
  }

  setComplete() {
    this.setState((state) => ({
      ...state,
      complete: true,
      progress: 100,
      media: [],
    }));
  }

  setSafeMode(safeMode: boolean) {
    this.setState((state) => ({
      ...state,
      safeMode: safeMode,
    }));
  }

  clearSubmissionData(requestId: string): void {
    this.sessionStorageService.deleteItem(`submission-${requestId}`);
  }
}
