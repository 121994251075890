import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-patient-specific-functional-scale-form',
  templateUrl: './patient-specific-functional-scale-form.component.html',
  styleUrls: ['./patient-specific-functional-scale-form.component.scss'],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class PatientSpecificFunctionalScaleFormComponent implements OnInit {
  @Input() data;
  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  patientSpecificFunctionalScaleForm: UntypedFormGroup;
  isSubmitting = false;
  testy;

  ngOnInit(): void {
    this.patientSpecificFunctionalScaleForm = this.fb.group({
      assessmentType: ['', [Validators.required]],
      activityOneName: ['', [Validators.required]],
      activityOneScore: [0, [Validators.required]],
      activityTwoName: ['', []],
      activityTwoScore: [0, []],
      activityThreeName: ['', []],
      activityThreeScore: [0, []],
      baselineScore: [0, []],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.patientSpecificFunctionalScaleForm.controls[key])
          this.patientSpecificFunctionalScaleForm.controls[key].setValue(
            formData[key]
          );
      });
    });
  }

  get assessmentType() {
    return this.patientSpecificFunctionalScaleForm.get('assessmentType');
  }

  get activityOneName() {
    return this.patientSpecificFunctionalScaleForm.get('activityOneName');
  }

  get activityOneScore() {
    return this.patientSpecificFunctionalScaleForm.get('activityOneScore');
  }

  get activityTwoName() {
    return this.patientSpecificFunctionalScaleForm.get('activityTwoName');
  }

  get activityTwoScore() {
    return this.patientSpecificFunctionalScaleForm.get('activityTwoScore');
  }

  get activityThreeName() {
    return this.patientSpecificFunctionalScaleForm.get('activityThreeName');
  }

  get activityThreeScore() {
    return this.patientSpecificFunctionalScaleForm.get('activityThreeScore');
  }

  get activityTotalScore() {
    return (
      this.activityOneScore.value +
      this.activityTwoScore.value +
      this.activityThreeScore.value
    );
  }

  get baselineScore() {
    return this.patientSpecificFunctionalScaleForm.get('baselineScore');
  }

  get bestAvailableScore() {
    let noActivities = 0;
    if (this.activityOneName.value !== '' || this.activityOneScore.value > 0) {
      noActivities++;
    }
    if (this.activityTwoName.value !== '' || this.activityTwoScore.value > 0) {
      noActivities++;
    }
    if (
      this.activityThreeName.value !== '' ||
      this.activityThreeScore.value > 0
    ) {
      noActivities++;
    }
    return noActivities * 10;
  }

  get initalScore() {
    if (this.assessmentType.value === 'Baseline Assessment') {
      return this.activityTotalScore;
    } else {
      return '';
    }
  }

  get psfsScore() {
    if (this.assessmentType.value === 'Follow-up Assessment') {
      const result = this.activityTotalScore - this.baselineScore.value;
      return (
        (result / (this.bestAvailableScore - this.baselineScore.value)) *
        100
      ).toFixed(2);
    } else {
      return '';
    }
  }

  resetBaselineField(event) {
    if (event.value === 'Follow-up Assessment') {
      this.baselineScore.setValidators(Validators.required);
    } else {
      this.baselineScore.clearValidators();
      this.baselineScore.reset('');
    }
  }

  onSubmit() {
    if (!this.valid()) return;

    this.isSubmitting = true;
    const formResponse = {
      ...this.patientSpecificFunctionalScaleForm.value,
      activityDetails: 'subtitle_HEADER',
      scores: 'subtitle_HEADER',
      bestAvailableScore: this.bestAvailableScore,
      psfsScore: this.psfsScore,
      initalScore: this.initalScore,
      formId: this.data.formId,
      index: this.data.index || 0,
      showOnChart: true,
    };
    this.form.submit(formResponse);
  }

  valid(): boolean {
    return (
      this.patientSpecificFunctionalScaleForm.dirty &&
      this.patientSpecificFunctionalScaleForm.valid &&
      !this.isSubmitting
    );
  }
}
