<ng-container *ngIf="field && control">
  <div class="question-container" *ngIf="field?.title">
    <ic-automatic-title
      [field]="field"
      class="half-width-field"
    ></ic-automatic-title>
    <ng-container *ngTemplateOutlet="inputField"></ng-container>
  </div>
  <ng-container *ngIf="!field?.title">
    <ng-container *ngTemplateOutlet="inputField"></ng-container>
  </ng-container>

  <ng-template #inputField>
    <mat-form-field appearance="fill" class="half-width-field">
      <mat-label *ngIf="field?.subTitle">
        <ic-automatic-subtitle [field]="field"></ic-automatic-subtitle>
      </mat-label>

      <input
        matInput
        [formControl]="control"
        [type]="getType(field?.inputType)"
        [placeholder]="field?.placeholder || ''"
        autocomplete="off"
      />

      <mat-error *ngIf="control?.touched && control?.invalid">
        {{ formService?.getErrorMessage(control) }}
      </mat-error>
    </mat-form-field>
  </ng-template>
</ng-container>
