import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  ImageDrawComponent,
  PrimeNgModule,
  SharedCommonModule,
} from '@ic-monorepo/shared-common';
import { ClinicalFormField, ClinicalFormWrapper } from '@islacare/ic-types';
import { FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { FormlyPrimeNGModule } from '@ngx-formly/primeng';
import { FormlyCheckboxModule } from '@ngx-formly/primeng/checkbox';
import { FormlyDatepickerModule } from '@ngx-formly/primeng/datepicker';
import { FormlyInputModule } from '@ngx-formly/primeng/input';
import { FormlyRadioModule } from '@ngx-formly/primeng/radio';
import { ClinicalFormStepperComponent } from './components/clinical-form-stepper/clinical-form-stepper.component';
import { ClinicalFormComponent } from './components/clinical-form/clinical-form.component';
import { ClinicalFormFieldCalculationComponent } from './components/fields/clinical-form-field-calculation/clinical-form-field-calculation.component';
import { ClinicalFormFieldCheckboxComponent } from './components/fields/clinical-form-field-checkbox/clinical-form-field-checkbox.component';
import { ClinicalFormFieldDatepickerComponent } from './components/fields/clinical-form-field-datepicker/clinical-form-field-datepicker.component';
import { ClinicalFormFieldHtmlComponent } from './components/fields/clinical-form-field-html/clinical-form-field-html.component';
import { ClinicalFormFieldImageMapComponent } from './components/fields/clinical-form-field-image-map/clinical-form-field-image-map.component';
import { ClinicalFormFieldImageComponent } from './components/fields/clinical-form-field-image/clinical-form-field-image.component';
import { ClinicalFormFieldMessageComponent } from './components/fields/clinical-form-field-message/clinical-form-field-message.component';
import { ClinicalFormFieldMultiSelectComponent } from './components/fields/clinical-form-field-multi-select/clinical-form-field-multi-select.component';
import { ClinicalFormFieldNumberComponent } from './components/fields/clinical-form-field-number/clinical-form-field-number.component';
import { ClinicalFormFieldRadioButtonComponent } from './components/fields/clinical-form-field-radio-button/clinical-form-field-radio-button.component';
import { ClinicalFormFieldSelectComponent } from './components/fields/clinical-form-field-select/clinical-form-field-select.component';
import { ClinicalFormFieldSliderComponent } from './components/fields/clinical-form-field-slider/clinical-form-field-slider.component';
import { ClinicalFormFieldTableComponent } from './components/fields/clinical-form-field-table/clinical-form-field-table.component';
import { ClinicalFormFieldTextAreaComponent } from './components/fields/clinical-form-field-text-area/clinical-form-field-text-area.component';
import { ClinicalFormFieldTextComponent } from './components/fields/clinical-form-field-text/clinical-form-field-text.component';
import { ClinicalFormWrapperEditComponent } from './components/wrappers/clinical-form-wrapper-edit/clinical-form-wrapper-edit.component';
import { ClinicalFormWrapperFieldGroupComponent } from './components/wrappers/clinical-form-wrapper-field-group/clinical-form-wrapper-field-group.component';
import { ClinicalFormWrapperFieldComponent } from './components/wrappers/clinical-form-wrapper-field/clinical-form-wrapper-field.component';
import { ClinicalFormWrapperInlineFieldComponent } from './components/wrappers/clinical-form-wrapper-inline-field/clinical-form-wrapper-inline-field.component';
import { ClinicalFormWrapperSectionComponent } from './components/wrappers/clinical-form-wrapper-section/clinical-form-wrapper-section.component';
import { SaveFormDialogComponent } from './dialogs/save-form-dialog/save-form-dialog.component';
import { clinicalFormDefaultFormFieldExtension } from './extensions/clinical-form-default-extension';
import { FeatureClinicalFormsRoutingModule } from './feature-clinical-forms-routing.module';
import { ClinicalFormEditorPageComponent } from './pages/clinical-form-editor-page/clinical-form-editor-page.component';

@NgModule({
  imports: [
    CommonModule,
    FeatureClinicalFormsRoutingModule,
    PrimeNgModule,
    ReactiveFormsModule,
    FormlyPrimeNGModule,
    FormlyModule.forChild({
      types: [
        {
          name: ClinicalFormField.STEPPER,
          component: ClinicalFormStepperComponent,
          wrappers: [],
        },
        {
          name: ClinicalFormField.TEXT,
          wrappers: [ClinicalFormWrapper.EDITOR, ClinicalFormWrapper.FIELD],
          component: ClinicalFormFieldTextComponent,
        },
        {
          name: ClinicalFormField.DATE,
          wrappers: [ClinicalFormWrapper.EDITOR, ClinicalFormWrapper.FIELD],
          component: ClinicalFormFieldDatepickerComponent,
          defaultOptions: {
            props: {
              dateFormat: 'dd/mm/yy',
              hourFormat: '24',
              numberOfMonths: 1,
              selectionMode: 'single',
              readonlyInput: false,
              showTime: false,
              showButtonBar: true,
              showIcon: true,
              showOtherMonths: true,
              yearRange: '2020:2030',
              inline: false,
            },
          },
        },
        {
          name: ClinicalFormField.SELECT,
          component: ClinicalFormFieldSelectComponent,
          wrappers: [ClinicalFormWrapper.EDITOR, ClinicalFormWrapper.FIELD],
        },
        {
          name: ClinicalFormField.RADIO,
          wrappers: [ClinicalFormWrapper.EDITOR, ClinicalFormWrapper.FIELD],
          component: ClinicalFormFieldRadioButtonComponent,
        },
        {
          name: ClinicalFormField.NUMBER,
          component: ClinicalFormFieldNumberComponent,
          wrappers: [ClinicalFormWrapper.EDITOR, ClinicalFormWrapper.FIELD],
        },
        {
          name: ClinicalFormField.HTML,
          component: ClinicalFormFieldHtmlComponent,
          wrappers: [ClinicalFormWrapper.EDITOR],
        },
        {
          name: ClinicalFormField.IMAGE,
          component: ClinicalFormFieldImageComponent,
          wrappers: [ClinicalFormWrapper.EDITOR],
        },
        {
          name: ClinicalFormField.TABLE,
          component: ClinicalFormFieldTableComponent,
          wrappers: [ClinicalFormWrapper.EDITOR, ClinicalFormWrapper.FIELD],
        },
        {
          name: ClinicalFormField.CALCULATION,
          component: ClinicalFormFieldCalculationComponent,
          wrappers: [
            ClinicalFormWrapper.EDITOR,
            ClinicalFormWrapper.INLINE_FIELD,
          ],
        },
        {
          name: ClinicalFormField.MULTISELECT,
          component: ClinicalFormFieldMultiSelectComponent,
          wrappers: [ClinicalFormWrapper.EDITOR, ClinicalFormWrapper.FIELD],
        },
        {
          name: ClinicalFormField.TEXTAREA,
          component: ClinicalFormFieldTextAreaComponent,
          wrappers: [ClinicalFormWrapper.EDITOR, ClinicalFormWrapper.FIELD],
        },
        {
          name: ClinicalFormField.FIELDMESSAGE,
          component: ClinicalFormFieldMessageComponent,
          wrappers: [ClinicalFormWrapper.EDITOR, ClinicalFormWrapper.FIELD],
        },
        {
          name: ClinicalFormField.SLIDER,
          component: ClinicalFormFieldSliderComponent,
          wrappers: [ClinicalFormWrapper.EDITOR, ClinicalFormWrapper.FIELD],
        },
        {
          name: ClinicalFormField.CHECKBOX,
          component: ClinicalFormFieldCheckboxComponent,
          wrappers: [ClinicalFormWrapper.EDITOR, ClinicalFormWrapper.FIELD],
        },
        {
          name: ClinicalFormField.IMAGEMAP,
          component: ClinicalFormFieldImageMapComponent,
          wrappers: [ClinicalFormWrapper.EDITOR, ClinicalFormWrapper.FIELD],
        },
      ],
      wrappers: [
        {
          name: ClinicalFormWrapper.SECTION,
          component: ClinicalFormWrapperSectionComponent,
        },
        {
          name: ClinicalFormWrapper.GROUP,
          component: ClinicalFormWrapperFieldGroupComponent,
        },
        {
          name: ClinicalFormWrapper.FIELD,
          component: ClinicalFormWrapperFieldComponent,
        },
        {
          name: ClinicalFormWrapper.INLINE_FIELD,
          component: ClinicalFormWrapperInlineFieldComponent,
        },
        {
          name: ClinicalFormWrapper.EDITOR,
          component: ClinicalFormWrapperEditComponent,
        },
      ],
      validationMessages: [
        { name: 'required', message: 'This field is required' },
      ],
      extensions: [
        {
          name: 'default-field-extension',
          extension: clinicalFormDefaultFormFieldExtension,
        },
      ],
    }),
    FormlyDatepickerModule,
    FormlyInputModule,
    FormlyRadioModule,
    FormlyCheckboxModule,
    FormlySelectModule,
    SharedCommonModule,
    ImageDrawComponent,
  ],
  declarations: [
    ClinicalFormComponent,
    ClinicalFormStepperComponent,
    ClinicalFormWrapperFieldGroupComponent,
    ClinicalFormWrapperFieldComponent,
    ClinicalFormWrapperEditComponent,
    ClinicalFormWrapperInlineFieldComponent,
    ClinicalFormWrapperSectionComponent,
    ClinicalFormFieldHtmlComponent,
    ClinicalFormFieldImageComponent,
    ClinicalFormFieldTableComponent,
    ClinicalFormFieldNumberComponent,
    ClinicalFormFieldCalculationComponent,
    ClinicalFormFieldMultiSelectComponent,
    ClinicalFormFieldTextComponent,
    ClinicalFormFieldTextAreaComponent,
    ClinicalFormFieldImageMapComponent,
    ClinicalFormFieldMessageComponent,
    ClinicalFormFieldSliderComponent,
    ClinicalFormFieldCheckboxComponent,
    ClinicalFormFieldRadioButtonComponent,
    ClinicalFormFieldSelectComponent,
    ClinicalFormFieldDatepickerComponent,
    ClinicalFormEditorPageComponent,
    SaveFormDialogComponent,
  ],
})
export class FeatureClinicalFormsModule {}
