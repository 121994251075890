<ng-container
  *ngIf="{
    captureOptions: captureOptions$ | async
  } as vm"
>
  <form [formGroup]="requestForm">
    <ng-container *ngIf="!contentHidden">
      <span class="p-float-label p-fluid my-2">
        <textarea
          id="float-input"
          pInputTextarea
          formControlName="smsBody"
          placeholder="please capture a photo for..."
        ></textarea>
        <label for="float-input">
          "<em> Dear <u>patient first name</u> </em>
          ", and then the content below:
        </label>
      </span>
    </ng-container>

    <div class="field">
      <label for="capture" class="block mb-3">Submission of:</label>
      <div
        *ngIf="isMobile; else captureSelectButton"
        class="checkbox-select-button"
      >
        <div
          *ngFor="let option of vm.captureOptions"
          [class.checkbox-select-button-highlighted]="
            option.value === requestForm.get('capture').value
          "
          (click)="requestForm.get('capture').setValue(option.value)"
        >
          <p-radioButton
            [value]="option.value"
            formControlName="capture"
          ></p-radioButton>
          <label [for]="option.value">
            <i [class]="option.icon"></i>
            {{ option.label }}
          </label>
        </div>
      </div>
      <ng-template #captureSelectButton>
        <p-selectButton
          [options]="vm.captureOptions"
          formControlName="capture"
          optionValue="value"
          data-cy="request-media-type"
        >
          <ng-template let-item>
            <i [class]="item.icon" [ngClass]="{ 'pr-1': !isMobile }"></i>
            <span *ngIf="!isMobile"> {{ item.label }}</span>
          </ng-template>
        </p-selectButton>
      </ng-template>
      <ng-container
        *ngIf="selectedFileType && selectedFileType !== CaptureType.FORM"
      >
        <p-checkbox
          class="flex gap-1 p-750 px-0"
          label="Patient can skip {{ selectedFileType }} submission"
          ariaLabel="Patient can skip {{ selectedFileType }} submission"
          formControlName="isMediaOptional"
          [binary]="true"
        ></p-checkbox>
      </ng-container>
    </div>

    <ng-container *ngIf="forms && forms.length > 0">
      <div class="field">
        <label
          class="block"
          for="form-selector"
          *ngIf="!templateUrlParams.teamId"
        >
          {{ selectedFormIds?.length < 2 ? 'Form' : 'Forms' }} to be completed
          for this submission:
        </label>
        <div [ngClass]="{ 'flex align-items-center': !isMobile }">
          <div class="p-fluid" [ngClass]="{ 'flex-grow-1 pr-2': !isMobile }">
            <p-multiSelect
              name="form-selector"
              [panelStyleClass]="!isMobile ? 'w-full' : 'max-w-20rem'"
              [selectedItemsLabel]="'{0} items selected'"
              [options]="forms | orderBy: 'pipe'"
              [group]="true"
              data-cy="collection-request-form-dropdown"
              optionGroupChildren="forms"
              optionGroupLabel="teamName"
              optionLabel="name"
              optionValue="id"
              appendTo="body"
              [maxSelectedLabels]="2"
              (onChange)="isFormChecked(); emitValues()"
              placeholder="Select a form"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="selectedFormIds"
              display="chip"
            >
            </p-multiSelect>
          </div>
          <div class="pt-2">
            <button
              pButton
              type="button"
              class="p-button-text"
              *ngIf="selectedFormIds"
              (click)="showForm()"
              data-cy="request-show-form"
            >
              Show selected form<ng-container *ngIf="selectedFormIds.length > 1"
                >s</ng-container
              >
            </button>
          </div>
        </div>
        <p-message
          severity="warn"
          *ngIf="!formChecked"
          text="Must have a form selected!"
        ></p-message>
      </div>
    </ng-container>

    <ng-container *ngIf="!alertEmailHidden">
      <div class="field">
        <label class="block" for="email-text">
          Email address to be alerted for new submissions:
        </label>
        <input
          type="text"
          pInputText
          class="w-full"
          placeholder="team-name@nhs.net"
          formControlName="alertEmail"
          name="email-text"
        />
        <p class="pt-0 mt-0">
          Multiple emails can be added separated by commas(,)
        </p>
        <ng-container *ngTemplateOutlet="emailErrors"></ng-container>
      </div>
    </ng-container>

    <div class="field">
      <label for="contactMethod-selectors" class="block mb-3"
        >Contact method:</label
      >
      <div
        *ngIf="isMobile; else contactMethodSelectButton"
        class="checkbox-select-button"
      >
        <div
          *ngFor="let option of contactMethodOptions"
          [class.checkbox-select-button-highlighted]="
            option.value === requestForm.get('contactMethod').value
          "
          (click)="requestForm.get('contactMethod').setValue(option.value)"
        >
          <p-radioButton
            [value]="option.value"
            formControlName="contactMethod"
          ></p-radioButton>
          <label [for]="option.value">{{ option.label }}</label>
        </div>
      </div>
      <ng-template #contactMethodSelectButton>
        <p-selectButton
          [options]="contactMethodOptions"
          formControlName="contactMethod"
          name="contactMethod-selectors"
          optionValue="value"
          class="py-2"
        >
          <ng-template let-item>
            <i [class]="item.icon" [ngClass]="{ 'pr-1': !isMobile }"></i>
            <span *ngIf="!isMobile"> {{ item.label }}</span>
          </ng-template>
        </p-selectButton>
      </ng-template>
      <p-message
        *ngIf="requestForm.get('contactMethod').value === 'qrCode'"
        text="This will generate a QR code that you can show to the patient, so they can submit now"
        severity="info"
        styleClass="mt-3"
      >
      </p-message>
    </div>

    <div
      *ngIf="requestForm.get('contactMethod').value !== 'qrCode'"
      class="field"
    >
      <label for="keepLinkSevenDays-selectors" class="block mb-2"
        >Link expires:
      </label>
      <div
        *ngIf="isMobile; else keepLinkSevenDaysSelectButton"
        class="checkbox-select-button"
      >
        <div
          *ngFor="let option of keepLinkSevenDaysOptions"
          [class.checkbox-select-button-highlighted]="
            option.value === requestForm.get('keepLinkSevenDays').value
          "
          (click)="requestForm.get('keepLinkSevenDays').setValue(option.value)"
        >
          <p-radioButton
            [value]="option.value"
            formControlName="keepLinkSevenDays"
          ></p-radioButton>
          <label [for]="option.value">{{ option.label }}</label>
        </div>
      </div>
      <ng-template #keepLinkSevenDaysSelectButton>
        <p-selectButton
          name="keepLinkSevenDays-selectors"
          [options]="keepLinkSevenDaysOptions"
          optionValue="value"
          formControlName="keepLinkSevenDays"
          class="py-2"
        >
          <ng-template let-item>
            <i [class]="item.icon" [ngClass]="{ 'pr-1': !isMobile }"></i>
            <span *ngIf="!isMobile"> {{ item.label }}</span>
          </ng-template>
        </p-selectButton>
      </ng-template>
      <div *ngIf="showLinkLengthHelp">
        <p>
          The request link will expire at midnight on {{ dateLinkExpires }}.
        </p>
      </div>
    </div>
  </form>
</ng-container>

<ng-template #emailErrors>
  <div *ngIf="emailValidityLogs$ | async as emailValidityLogs">
    <span class="mb-0 p-error" *ngIf="emailValidityLogs.length === 1">
      The following email entered is invalid:
    </span>
    <span class="mb-0 p-error" *ngIf="emailValidityLogs.length > 1">
      The following emails entered are invalid:
    </span>
    <span class="p-error" *ngFor="let email of emailValidityLogs">
      {{ email }}
    </span>
  </div>
</ng-template>
