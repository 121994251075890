import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormService, PatientsService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-dlqi-form',
  templateUrl: './dlqi-form.component.html',
  styleUrls: ['./dlqi-form.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
})
export class DlqiFormComponent implements OnInit {
  @Input() data;

  constructor(
    private fb: UntypedFormBuilder,
    private form: FormService,
    private patientsService: PatientsService,
    private route: ActivatedRoute,
    public afAuth: AngularFireAuth
  ) {}

  dlqiForm: UntypedFormGroup;
  teenagerDlqiForm: UntypedFormGroup;
  infantDlqiForm: UntypedFormGroup;
  isSubmitting = false;
  isSensitive: boolean;
  dlqiTotalScore: number;
  ageUnderFour: boolean;
  ageFourToSixteen: boolean;
  ageOverSixteen: boolean;
  ageBracket: 'underFour' | 'fourToSixteen' | 'overSixteen' = 'overSixteen';
  patientId: string;
  patientAgeInYears;
  patientBirthYear;

  dermatologyAnswerList: string[] = [
    'Very much - 3',
    'A lot - 2',
    'A little - 1',
    'Not at all - 0',
  ];

  teenagersAnswerList: string[] = ['Never', 'Sometimes', 'Always'];

  infantsAnswerList: string[] = [
    'Very much',
    'A lot',
    'A little',
    'Not at all',
  ];

  ngOnInit(): void {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    // this works incognito and gets id
    this.route.paramMap.subscribe((pId) => {
      this.patientId = pId.get('patientId');
    });

    this.afAuth.authState.subscribe((res) => {
      if (res && res.uid && this.patientId) {
        this.patientsService
          .getPatient$(this.patientId)
          .subscribe((patient) => {
            const patientAgeInYears = this.calculate_age(patient.dateOfBirth);

            if (patientAgeInYears < 4) {
              this.ageBracket = 'underFour';
            } else if (patientAgeInYears >= 4 && patientAgeInYears <= 16) {
              this.ageBracket = 'fourToSixteen';
            } else {
              this.ageBracket = 'overSixteen';
            }
          });
      } else {
        this.patientBirthYear = this.data.yearOfBirth; //coming from collection auth
        this.patientAgeInYears = currentYear - this.patientBirthYear;

        if (this.patientAgeInYears < 4) {
          this.ageBracket = 'underFour';
        } else if (
          this.patientAgeInYears >= 4 &&
          this.patientAgeInYears <= 16
        ) {
          this.ageBracket = 'fourToSixteen';
        } else {
          this.ageBracket = 'overSixteen';
        }
      }
    });

    // over 16
    this.dlqiForm = this.fb.group({
      itchySorePainfulOrStingingSkin: ['', Validators.required],
      embarrassedOrSelfConscious: ['', Validators.required],
      interferedWithShoppingHomeOrGarden: ['', Validators.required],
      skinInfluencedClothesYouWear: ['', Validators.required],
      skinAffectedSocialOrLeisureActivities: ['', Validators.required],
      skinAffectedAnySport: ['', Validators.required],
      skinPreventedWorkingOrStudyingYesNo: ['', Validators.required],
      skinPreventedWorkingOrStudying: [''],
      skinCreatedProblemsWithPartnerFriendsOrFamily: ['', Validators.required],
      skinCausedSexualDifficulties: ['', Validators.required],
      skinTreatmentBeenAProblem: ['', Validators.required],
      dlqiTotalScore: [''],
    });

    // 4 to 16
    this.teenagerDlqiForm = this.fb.group({
      skinConditionMakeYouFeelSelfConscious: ['', Validators.required],
      skinConditionMakeYouFeelUpset: ['', Validators.required],
      skinConditionMakeYouFeelThatYouLookDifferent: ['', Validators.required],
      skinConditionMakeYouFeelThatPeopleStare: ['', Validators.required],
      skinConditionMakeYouFeelEmbarrassed: ['', Validators.required],
      skinConditionMakeYouFeelUncomfortableAroundOthers: [
        '',
        Validators.required,
      ],
      skinConditionStopYouFromGoingPlaces: ['', Validators.required],
      feelTheNeedToCoverUpAffectedAreas: ['', Validators.required],

      skinConditionAffectYourStudiesOrJob: ['', Validators.required],
      skinConditionWorryYouAboutFutureCareer: ['', Validators.required],
      skinConditionCausePainOrDiscomfort: ['', Validators.required],
      skinConditionAffectYourSleep: ['', Validators.required],

      skinConditionMakeYouAnnoyed: ['', Validators.required],
      thinkAlotAboutYourSkinCondition: ['', Validators.required],
      skinConditionMakeYouAvoidMeetingNewPeople: ['', Validators.required],
      receiveUnfriendlyCommentsAboutYourSkin: ['', Validators.required],
      skinConditionAffectFriendships: ['', Validators.required],
      skinConditionAffectInitimateRelationships: ['', Validators.required],
    });

    // under 4
    this.infantDlqiForm = this.fb.group({
      howSevereYourChildsDermatitisHasBeen: ['', Validators.required],
      howMuchHasYourChildBeenItchingAndScratching: ['', Validators.required],
      howHasYourChildsMoodBeen: ['', Validators.required],
      howLongHasItTakenToGetYourChildToSleep: ['', Validators.required],
      averageTimeYourChildsSleepDisturbed: ['', Validators.required],
      hasYourChildsEczemaInterferedWithPlayingOrSwimming: [
        '',
        Validators.required,
      ],
      hasYourChildsEczemaInterferedWithFamilyActivities: [
        '',
        Validators.required,
      ],
      hasYourChildsEczemaCausedProblemsAtMealtimes: ['', Validators.required],
      hasYourChildsEczemaTreatmentCausedProblems: ['', Validators.required],
      hasYourChildsEczemaUncomfortWhenDressingAndUndressing: [
        '',
        Validators.required,
      ],
      hasYourChildsEczemaCausedProblemsAtBathtime: ['', Validators.required],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.dlqiForm.controls[key])
          this.dlqiForm.controls[key].setValue(formData[key]);
        if (this.teenagerDlqiForm.controls[key])
          this.teenagerDlqiForm.controls[key].setValue(formData[key]);
        if (this.infantDlqiForm.controls[key])
          this.infantDlqiForm.controls[key].setValue(formData[key]);
      });
    });
  }

  onSubmit() {
    if (!this.dlqiFormValid()) return;
    this.setDlqiScore();
    this.isSubmitting = true;
    const formResponse = {
      ...this.dlqiForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
      showOnChart: true,
    };
    this.form.submit(formResponse);
  }
  teenagerFormSubmit() {
    this.isSubmitting = true;
    const formResponse = {
      ...this.teenagerDlqiForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }
  infantFormSubmit() {
    this.isSubmitting = true;
    const formResponse = {
      ...this.infantDlqiForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  // DLQI form
  get itchySorePainfulOrStingingSkin() {
    return this.dlqiForm.get('itchySorePainfulOrStingingSkin');
  }
  get embarrassedOrSelfConscious() {
    return this.dlqiForm.get('embarrassedOrSelfConscious');
  }
  get interferedWithShoppingHomeOrGarden() {
    return this.dlqiForm.get('interferedWithShoppingHomeOrGarden');
  }
  get skinInfluencedClothesYouWear() {
    return this.dlqiForm.get('skinInfluencedClothesYouWear');
  }
  get skinAffectedSocialOrLeisureActivities() {
    return this.dlqiForm.get('skinAffectedSocialOrLeisureActivities');
  }
  get skinAffectedAnySport() {
    return this.dlqiForm.get('skinAffectedAnySport');
  }
  get skinPreventedWorkingOrStudyingYesNo() {
    return this.dlqiForm.get('skinPreventedWorkingOrStudyingYesNo');
  }
  get skinPreventedWorkingOrStudying() {
    return this.dlqiForm.get('skinPreventedWorkingOrStudying');
  }
  get skinCreatedProblemsWithPartnerFriendsOrFamily() {
    return this.dlqiForm.get('skinCreatedProblemsWithPartnerFriendsOrFamily');
  }
  get skinCausedSexualDifficulties() {
    return this.dlqiForm.get('skinCausedSexualDifficulties');
  }
  get skinTreatmentBeenAProblem() {
    return this.dlqiForm.get('skinTreatmentBeenAProblem');
  }

  // TDLQI FORM
  get skinConditionMakeYouFeelSelfConscious() {
    return this.teenagerDlqiForm.get('skinConditionMakeYouFeelSelfConscious');
  }
  get skinConditionMakeYouFeelUpset() {
    return this.teenagerDlqiForm.get('skinConditionMakeYouFeelUpset');
  }
  get skinConditionMakeYouFeelThatYouLookDifferent() {
    return this.teenagerDlqiForm.get(
      'skinConditionMakeYouFeelThatYouLookDifferent'
    );
  }
  get skinConditionMakeYouFeelThatPeopleStare() {
    return this.teenagerDlqiForm.get('skinConditionMakeYouFeelThatPeopleStare');
  }
  get skinConditionMakeYouFeelEmbarrassed() {
    return this.teenagerDlqiForm.get('skinConditionMakeYouFeelEmbarrassed');
  }
  get skinConditionMakeYouFeelUncomfortableAroundOthers() {
    return this.teenagerDlqiForm.get(
      'skinConditionMakeYouFeelUncomfortableAroundOthers'
    );
  }
  get skinConditionStopYouFromGoingPlaces() {
    return this.teenagerDlqiForm.get('skinConditionStopYouFromGoingPlaces');
  }
  get feelTheNeedToCoverUpAffectedAreas() {
    return this.teenagerDlqiForm.get('feelTheNeedToCoverUpAffectedAreas');
  }

  get skinConditionAffectYourStudiesOrJob() {
    return this.teenagerDlqiForm.get('skinConditionAffectYourStudiesOrJob');
  }
  get skinConditionWorryYouAboutFutureCareer() {
    return this.teenagerDlqiForm.get('skinConditionWorryYouAboutFutureCareer');
  }
  get skinConditionCausePainOrDiscomfort() {
    return this.teenagerDlqiForm.get('skinConditionCausePainOrDiscomfort');
  }
  get skinConditionAffectYourSleep() {
    return this.teenagerDlqiForm.get('skinConditionAffectYourSleep');
  }

  get skinConditionMakeYouAnnoyed() {
    return this.teenagerDlqiForm.get('skinConditionMakeYouAnnoyed');
  }
  get thinkAlotAboutYourSkinCondition() {
    return this.teenagerDlqiForm.get('thinkAlotAboutYourSkinCondition');
  }
  get skinConditionMakeYouAvoidMeetingNewPeople() {
    return this.teenagerDlqiForm.get(
      'skinConditionMakeYouAvoidMeetingNewPeople'
    );
  }
  get receiveUnfriendlyCommentsAboutYourSkin() {
    return this.teenagerDlqiForm.get('receiveUnfriendlyCommentsAboutYourSkin');
  }
  get skinConditionAffectFriendships() {
    return this.teenagerDlqiForm.get('skinConditionAffectFriendships');
  }
  get skinConditionAffectInitimateRelationships() {
    return this.teenagerDlqiForm.get(
      'skinConditionAffectInitimateRelationships'
    );
  }

  // IDLQI FORM
  get howSevereYourChildsDermatitisHasBeen() {
    return this.infantDlqiForm.get('howSevereYourChildsDermatitisHasBeen');
  }
  get howMuchHasYourChildBeenItchingAndScratching() {
    return this.infantDlqiForm.get(
      'howMuchHasYourChildBeenItchingAndScratching'
    );
  }
  get howHasYourChildsMoodBeen() {
    return this.infantDlqiForm.get('howHasYourChildsMoodBeen');
  }
  get howLongHasItTakenToGetYourChildToSleep() {
    return this.infantDlqiForm.get('howLongHasItTakenToGetYourChildToSleep');
  }
  get averageTimeYourChildsSleepDisturbed() {
    return this.infantDlqiForm.get('averageTimeYourChildsSleepDisturbed');
  }
  get hasYourChildsEczemaInterferedWithPlayingOrSwimming() {
    return this.infantDlqiForm.get(
      'hasYourChildsEczemaInterferedWithPlayingOrSwimming'
    );
  }
  get hasYourChildsEczemaInterferedWithFamilyActivities() {
    return this.infantDlqiForm.get(
      'hasYourChildsEczemaInterferedWithFamilyActivities'
    );
  }
  get hasYourChildsEczemaCausedProblemsAtMealtimes() {
    return this.infantDlqiForm.get(
      'hasYourChildsEczemaCausedProblemsAtMealtimes'
    );
  }
  get hasYourChildsEczemaTreatmentCausedProblems() {
    return this.infantDlqiForm.get(
      'hasYourChildsEczemaTreatmentCausedProblems'
    );
  }
  get hasYourChildsEczemaUncomfortWhenDressingAndUndressing() {
    return this.infantDlqiForm.get(
      'hasYourChildsEczemaUncomfortWhenDressingAndUndressing'
    );
  }
  get hasYourChildsEczemaCausedProblemsAtBathtime() {
    return this.infantDlqiForm.get(
      'hasYourChildsEczemaCausedProblemsAtBathtime'
    );
  }

  dlqiFormValid(): boolean {
    return (
      this.dlqiForm.dirty &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.itchySorePainfulOrStingingSkin.errors
      ) &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.embarrassedOrSelfConscious.errors
      ) &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.interferedWithShoppingHomeOrGarden.errors
      ) &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.skinInfluencedClothesYouWear.errors
      ) &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.skinAffectedSocialOrLeisureActivities.errors
      ) &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.skinAffectedAnySport.errors
      ) &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.skinPreventedWorkingOrStudyingYesNo.errors
      ) &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.skinPreventedWorkingOrStudying.errors
      ) &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.skinCreatedProblemsWithPartnerFriendsOrFamily
          .errors
      ) &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.skinCausedSexualDifficulties.errors
      ) &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.skinTreatmentBeenAProblem.errors
      )
    );
  }

  teenagerDlqiFormValid(): boolean {
    return (
      this.teenagerDlqiForm.dirty &&
      !(
        this.teenagerDlqiForm.controls &&
        this.teenagerDlqiForm.controls.skinConditionMakeYouFeelSelfConscious
          .errors
      ) &&
      !(
        this.teenagerDlqiForm.controls &&
        this.teenagerDlqiForm.controls.skinConditionMakeYouFeelUpset.errors
      ) &&
      !(
        this.teenagerDlqiForm.controls &&
        this.teenagerDlqiForm.controls
          .skinConditionMakeYouFeelThatYouLookDifferent.errors
      ) &&
      !(
        this.teenagerDlqiForm.controls &&
        this.teenagerDlqiForm.controls.skinConditionMakeYouFeelThatPeopleStare
          .errors
      ) &&
      !(
        this.teenagerDlqiForm.controls &&
        this.teenagerDlqiForm.controls.skinConditionMakeYouFeelEmbarrassed
          .errors
      ) &&
      !(
        this.teenagerDlqiForm.controls &&
        this.teenagerDlqiForm.controls
          .skinConditionMakeYouFeelUncomfortableAroundOthers.errors
      ) &&
      !(
        this.teenagerDlqiForm.controls &&
        this.teenagerDlqiForm.controls.skinConditionStopYouFromGoingPlaces
          .errors
      ) &&
      !(
        this.teenagerDlqiForm.controls &&
        this.teenagerDlqiForm.controls.feelTheNeedToCoverUpAffectedAreas.errors
      ) &&
      !(
        this.teenagerDlqiForm.controls &&
        this.teenagerDlqiForm.controls.skinConditionAffectYourStudiesOrJob
          .errors
      ) &&
      !(
        this.teenagerDlqiForm.controls &&
        this.teenagerDlqiForm.controls.skinConditionWorryYouAboutFutureCareer
          .errors
      ) &&
      !(
        this.teenagerDlqiForm.controls &&
        this.teenagerDlqiForm.controls.skinConditionCausePainOrDiscomfort.errors
      ) &&
      !(
        this.teenagerDlqiForm.controls &&
        this.teenagerDlqiForm.controls.skinConditionAffectYourSleep.errors
      ) &&
      !(
        this.teenagerDlqiForm.controls &&
        this.teenagerDlqiForm.controls.skinConditionMakeYouAnnoyed.errors
      ) &&
      !(
        this.teenagerDlqiForm.controls &&
        this.teenagerDlqiForm.controls.thinkAlotAboutYourSkinCondition.errors
      ) &&
      !(
        this.teenagerDlqiForm.controls &&
        this.teenagerDlqiForm.controls.skinConditionMakeYouAvoidMeetingNewPeople
          .errors
      ) &&
      !(
        this.teenagerDlqiForm.controls &&
        this.teenagerDlqiForm.controls.receiveUnfriendlyCommentsAboutYourSkin
          .errors
      ) &&
      !(
        this.teenagerDlqiForm.controls &&
        this.teenagerDlqiForm.controls.skinConditionAffectFriendships.errors
      ) &&
      !(
        this.teenagerDlqiForm.controls &&
        this.teenagerDlqiForm.controls.skinConditionAffectInitimateRelationships
          .errors
      )
    );
  }

  infantDlqiFormValid(): boolean {
    return (
      this.infantDlqiForm.dirty &&
      !(
        this.infantDlqiForm.controls &&
        this.infantDlqiForm.controls.howSevereYourChildsDermatitisHasBeen.errors
      ) &&
      !(
        this.infantDlqiForm.controls &&
        this.infantDlqiForm.controls.howMuchHasYourChildBeenItchingAndScratching
          .errors
      ) &&
      !(
        this.infantDlqiForm.controls &&
        this.infantDlqiForm.controls.howHasYourChildsMoodBeen.errors
      ) &&
      !(
        this.infantDlqiForm.controls &&
        this.infantDlqiForm.controls.howLongHasItTakenToGetYourChildToSleep
          .errors
      ) &&
      !(
        this.infantDlqiForm.controls &&
        this.infantDlqiForm.controls.averageTimeYourChildsSleepDisturbed.errors
      ) &&
      !(
        this.infantDlqiForm.controls &&
        this.infantDlqiForm.controls
          .hasYourChildsEczemaInterferedWithPlayingOrSwimming.errors
      ) &&
      !(
        this.infantDlqiForm.controls &&
        this.infantDlqiForm.controls
          .hasYourChildsEczemaInterferedWithFamilyActivities.errors
      ) &&
      !(
        this.infantDlqiForm.controls &&
        this.infantDlqiForm.controls
          .hasYourChildsEczemaCausedProblemsAtMealtimes.errors
      ) &&
      !(
        this.infantDlqiForm.controls &&
        this.infantDlqiForm.controls.hasYourChildsEczemaTreatmentCausedProblems
          .errors
      ) &&
      !(
        this.infantDlqiForm.controls &&
        this.infantDlqiForm.controls
          .hasYourChildsEczemaUncomfortWhenDressingAndUndressing.errors
      ) &&
      !(
        this.infantDlqiForm.controls &&
        this.infantDlqiForm.controls.hasYourChildsEczemaCausedProblemsAtBathtime
          .errors
      )
    );
  }

  setDlqiScore() {
    const dlqiQuestion1 = this.dlqiForm.get(
      'itchySorePainfulOrStingingSkin'
    ).value;
    const dlqiQuestion2 = this.dlqiForm.get('embarrassedOrSelfConscious').value;
    const dlqiQuestion3 = this.dlqiForm.get(
      'interferedWithShoppingHomeOrGarden'
    ).value;
    const dlqiQuestion4 = this.dlqiForm.get(
      'skinInfluencedClothesYouWear'
    ).value;
    const dlqiQuestion5 = this.dlqiForm.get(
      'skinAffectedSocialOrLeisureActivities'
    ).value;
    const dlqiQuestion6 = this.dlqiForm.get('skinAffectedAnySport').value;
    const dlqiQuestion7 = this.dlqiForm.get(
      'skinPreventedWorkingOrStudyingYesNo'
    ).value;
    const dlqiQuestion8 = this.dlqiForm.get(
      'skinPreventedWorkingOrStudying'
    ).value;
    const dlqiQuestion9 = this.dlqiForm.get(
      'skinCreatedProblemsWithPartnerFriendsOrFamily'
    ).value;
    const dlqiQuestion10 = this.dlqiForm.get(
      'skinCausedSexualDifficulties'
    ).value;
    const dlqiQuestion11 = this.dlqiForm.get('skinTreatmentBeenAProblem').value;

    const dlqiTotalScore =
      +dlqiQuestion1.match(/\d+/g) +
      +dlqiQuestion2.match(/\d+/g) +
      +dlqiQuestion3.match(/\d+/g) +
      +dlqiQuestion4.match(/\d+/g) +
      +dlqiQuestion5.match(/\d+/g) +
      +dlqiQuestion6.match(/\d+/g) +
      +dlqiQuestion7.match(/\d+/g) +
      +dlqiQuestion8.match(/\d+/g) +
      +dlqiQuestion9.match(/\d+/g) +
      +dlqiQuestion10.match(/\d+/g) +
      +dlqiQuestion11.match(/\d+/g);

    this.dlqiForm.get('dlqiTotalScore').setValue(dlqiTotalScore);
  }

  calculate_age(dob) {
    const dobDate = new Date(dob);
    const diff = Date.now() - dobDate.getTime();
    const ageDate = new Date(diff);

    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
}
