<div
  class="ic-file-uploader-panel bg-main-surface border-round-top-md border-1 border-main"
  [ngClass]="{
    hidden: panelHidden$ | async,
    expanded: isExpanded
  }"
>
  <div
    class="ic-file-uploader-panel-header flex flex-row align-items-center justify-content-between cursor-pointer p-4 pr-2 flash-panel"
    [ngClass]="{
      'py-2': isExpanded
    }"
    (click)="togglePanel()"
  >
    <div class="flex flex-row line-height-1">
      <ng-container
        *ngIf="{
          count: ongoingUploadCount$ | async
        } as ongoingUploads"
      >
        <span>{{
          ongoingUploads.count > 0 ? 'Uploading ' + ongoingUploads.count + ' files...' : 'Uploads'
        }}</span>
      </ng-container>
    </div>

    <div class="flex flex-row gap-2">
      <div class="m-2">
        <i class="pi pi-chevron-up"></i>
      </div>
      <div (click)="$event.stopPropagation(); hidePanel()" class="m-2">
        <i class="pi pi-times"></i>
      </div>
    </div>
  </div>
  <div
    class="ic-file-uploader-panel-content overflow-y-auto overflow-x-hidden hidden p-2"
    [class.block]="isExpanded"
  >
    <ic-file-uploader></ic-file-uploader>
  </div>
</div>
