import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  LoggingService,
  PatientsService,
  UsersService,
} from '@ic-monorepo/services';
import {
  SessionStorageService,
  StringToCapitalisedCasePipe,
} from '@ic-monorepo/shared-common';
import { MediaService } from '@ic-monorepo/shared-submissions';
import {
  CaptureType,
  CollectionWithId,
  ConsentedUser,
  EntryFlowDetails,
  IndexedImageUrlWithEnhancedConsentConfig,
  RequestDoc,
} from '@islacare/ic-types';
import { configureScope } from '@sentry/angular-ivy';
import { SubmissionFlowStore } from 'apps/frontend/portal/src/app/feature-patient-submission/store/submission-flow.store';
import { CollectionsService } from 'apps/frontend/portal/src/app/services/collections/collections.service';
import { CollectionAuthsService } from 'apps/frontend/portal/src/app/services/collectionsAuths/collectionAuths.service';
import { IntercomService } from 'apps/frontend/portal/src/app/services/intercom/intercom.service';
import { environment } from 'apps/frontend/portal/src/environments/environment';
import firebase from 'firebase/compat/app';
import { MessageService } from 'primeng/api';
import { Observable, combineLatest, from, of, throwError } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';

export type EnhancedConsentDetails = {
  reason: string;
  images: IndexedImageUrlWithEnhancedConsentConfig[];
};
export type EnhancedConsentFlowDetailsResponse = {
  entryFlowDetails: EntryFlowDetails;
  enhancedConsent: EnhancedConsentDetails;
};

@Injectable({
  providedIn: 'root',
})
export class EntryFlowService {
  numberOfSubmissions = 0;

  set properties(entryFlowDetails: EntryFlowDetails) {
    this.storage.setItem('entry-flow-properties', entryFlowDetails);
  }

  get properties(): EntryFlowDetails {
    return this.storage.getItem('entry-flow-properties');
  }

  set requestId(requestId: string) {
    this.storage.setItem('entry-flow-requestId', requestId);
  }

  get requestId(): string {
    return this.storage.getItem('entry-flow-requestId');
  }

  constructor(
    private collectionAuthService: CollectionAuthsService,
    private storage: SessionStorageService,
    private db: AngularFirestore,
    private log: LoggingService,
    private collectionsService: CollectionsService,
    private mediaService: MediaService,
    private analytics: AngularFireAnalytics,
    private patientsService: PatientsService,
    private user: UsersService,
    private intercom: IntercomService,
    private messageService: MessageService,
    private sfStore: SubmissionFlowStore
  ) {}

  setConsentedUser(consentedUserType: ConsentedUser) {
    if (
      !consentedUserType ||
      this.properties?.userType === ConsentedUser.AUTH_USER
    )
      return;

    this.properties = { ...this.properties, userType: consentedUserType };
  }

  setConsentBestInterest() {
    this.properties = { ...this.properties, consentBestInterest: true };
  }

  getRequestInfo$(requestId: string): Observable<RequestDoc> {
    this.requestId = requestId || this.requestId;
    this.intercom.setIntercomProperties({ islaRequestId: requestId });
    if (!this.requestId) throw new Error('Link invalid');

    this.setPatientSentryUserId(`reqId_${requestId}`);

    return this.db
      .doc(`requests/${requestId}`)
      .get()
      .pipe(
        tap(() => this.clearSessionStorage()),
        map((requestDoc) => requestDoc.data() as RequestDoc),
        map((requestDoc) => {
          return {
            ...requestDoc,
            orgAvatarUrl: this.getOrgAvatarUrl(
              requestDoc?.organisation?.orgAvatarPath
            ),
          };
        }),
        map((requestDoc) => {
          if (requestDoc) return requestDoc;

          const err = 'Request doc not found';
          this.sfStore.setError(err);
          throw new Error(err);
        })
      );
  }

  // This function exists for us to get the org avatar on the patient
  // submission flow - we're using replace to remove an unneeded '/'
  getOrgAvatarUrl(orgAvatarPath: string) {
    if (!orgAvatarPath) {
      return 'defaultPath';
    }
    return `https://firebasestorage.googleapis.com/v0/b/${
      environment.firebase.projectId
    }.appspot.com/o/${encodeURIComponent(
      orgAvatarPath.replace(/^\/|\/$/g, '')
    )}?alt=media`;
  }

  getEntryFlowDetailsWithRequestId$(
    yearOfBirth: string,
    requestId: string
  ): Observable<EntryFlowDetails> {
    const getEntryFlowDetails = firebase
      .app()
      .functions(environment.region)
      .httpsCallable('getEntryFlowDetails');

    return from(
      getEntryFlowDetails({
        yearOfBirth,
        requestId,
      })
    ).pipe(
      map((patientSubmissionParams) => patientSubmissionParams.data),
      map(
        (res) =>
          ({
            ...res,
            userType:
              res.recipient === 'Patient'
                ? ConsentedUser.PATIENT
                : ConsentedUser.THIRD_PARTY,
          } as EntryFlowDetails)
      ),
      tap((entryFlowDetails) => (this.properties = entryFlowDetails)),
      tap((entryFlowDetails) =>
        this.intercom.setIntercomProperties({
          islaPatientId: entryFlowDetails.patientId,
          islaCollectionId: entryFlowDetails.collectionId,
          islaCollectionAuthId: entryFlowDetails.collectionAuthId,
        })
      ),
      take(1)
    );
  }

  getEnhancedConsentFlowDetails$(
    requestId: string,
    verificationCode: number
  ): Observable<EnhancedConsentFlowDetailsResponse> {
    const getEnhancedConsentDetails = firebase
      .app()
      .functions(environment.region)
      .httpsCallable('getEnhancedConsentDetails');

    return from(
      getEnhancedConsentDetails({
        requestId,
        verificationCode,
      })
    ).pipe(
      map((enhancedConsentDetails) => enhancedConsentDetails.data),
      map((res) => ({
        entryFlowDetails: res.entryFlowDetails,
        enhancedConsent: res.enhancedConsent,
        userType:
          res.entryFlowDetails.recipient === 'Patient'
            ? ConsentedUser.PATIENT
            : ConsentedUser.THIRD_PARTY,
      })),
      tap((res) => (this.properties = res.entryFlowDetails)),
      tap((res) =>
        this.intercom.setIntercomProperties({
          islaPatientId: res.entryFlowDetails.patientId,
          islaCollectionId: res.entryFlowDetails.collectionId,
          islaCollectionAuthId: res.entryFlowDetails.collectionAuthId,
        })
      ),
      take(1)
    );
  }

  getEntryFlowDetailsAsAuthUser$(
    patientId: string,
    collectionId: string,
    captureType: CaptureType
  ): Observable<EntryFlowDetails> {
    this.analytics.setUserProperties({ userType: 'Clinician' });

    return combineLatest([
      this.collectionsService.getCollection(patientId, collectionId),
      this.patientsService.getPatient$(patientId).pipe(
        map((profile) => {
          const firstName = new StringToCapitalisedCasePipe().transform(
            profile.firstName
          );
          const lastName = profile.lastName?.toUpperCase();
          return `${lastName}, ${firstName}`;
        })
      ),
    ]).pipe(
      take(1),
      map(
        ([collection, patientName]) =>
          ({
            patientId,
            collectionId,
            captureType: this.sanitiseCaptureType(captureType),
            patientName,
            formIds: collection?.submission?.formIds.length
              ? collection.submission.formIds
              : collection?.formIds,
            teamId: '',
            userType: ConsentedUser.AUTH_USER,
          } as EntryFlowDetails)
      ),
      tap(() => this.clearSessionStorage()),
      tap((entryFlowDetails) => (this.properties = entryFlowDetails)),
      take(1)
    );
  }

  async triggerCannotConfirmIdentity(): Promise<void> {
    try {
      await this.db.collection('patientReportedIssues').add({
        issue: 'Patient cannot confirm identity',
        patientId: this.properties.patientId,
        collectionId: this.properties.collectionId,
        authId: this.properties.collectionAuthId,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
      this.updatingAuthDocAsInactive();
    } catch (error: any) {
      this.log.consoleLog(error);
      this.messageService.add({
        severity: 'error',
        summary: 'Error: Cannot confirm identity',
        detail: error.message,
        sticky: true,
      });
      throw new Error(`Error: withdraw consent once: ${error.message}`);
    }
  }

  async triggerWrongPerson(requestId: string): Promise<void> {
    try {
      await this.db.collection('patientReportedIssues').add({
        issue: 'Patient recorded first name was not them',
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        requestId,
      });
    } catch (error: any) {
      this.log.consoleLog(error);
      this.messageService.add({
        severity: 'error',
        summary: 'Error: Wrong person',
        detail: error.message,
        sticky: true,
      });
      throw new Error(`Error: withdraw consent once: ${error.message}`);
    }
  }

  async triggerWithdrawConsent(): Promise<void> {
    try {
      await this.db
        .collection('patientReportedIssues')
        .add({
          issue: 'Patient contact consent withdrawn',
          patientId: this.properties.patientId,
          collectionId: this.properties.collectionId,
          authId: this.properties.collectionAuthId,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          this.log.consoleLog('Successfully written');
        });
    } catch (error: any) {
      this.log.consoleLog(error);
      this.messageService.add({
        severity: 'error',
        summary: 'Error: Withdraw consent',
        detail: error.message,
        sticky: true,
      });
      throw new Error(`Error: withdraw consent once: ${error.message}`);
    }
  }

  async triggerWithdrawConsentOnce(): Promise<void> {
    try {
      await this.db
        .collection('patientReportedIssues')
        .add({
          issue: 'Patient contact consent withdrawn only for this request',
          patientId: this.properties.patientId,
          collectionId: this.properties.collectionId,
          authId: this.properties.collectionAuthId,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          this.log.consoleLog('Successfully written');
        });
    } catch (error: any) {
      this.log.consoleLog(error);
      this.messageService.add({
        severity: 'error',
        summary: 'Error: Withdraw consent once',
        detail: error.message,
        sticky: true,
      });
      throw new Error(`Error: withdraw consent once: ${error.message}`);
    }
  }

  storeFormData(formData: any) {
    this.storage.setItem(formData.formId, formData);
  }

  getFormResponses$(): Observable<{ [key: string]: any }> {
    return this.getFormIds$().pipe(
      map((formIds) => {
        const formResponses = {};
        for (const formId of formIds) {
          if (this.storage.getItem(formId))
            formResponses[formId] = this.storage.getItem(formId);
        }

        if (
          Object.keys(formResponses).length === 0 ||
          this.properties.captureType !== CaptureType.FORM
        ) {
          formResponses['default'] = this.storage.getItem('default');
        }

        return formResponses;
      }),
      take(1)
    );
  }

  submitEntry$(
    formResponses,
    isVideo = false,
    file = null,
    webcamImage = false,
    sensitive = false,
    dateTime = new Date()
  ) {
    //Specific to Canrisk form due to serverTimestamp failing in session storage
    Object.keys(formResponses).forEach((key) => {
      if (formResponses[key]?.formLastUpdated)
        formResponses[key].formLastUpdated =
          firebase.firestore.FieldValue.serverTimestamp();
    });

    this.numberOfSubmissions++;

    if (this.properties.userType === ConsentedUser.AUTH_USER)
      return this.submitEntryForAuthUser$(
        formResponses,
        isVideo,
        file,
        webcamImage,
        sensitive,
        dateTime
      );

    return this.submitEntryForPatient$(
      formResponses,
      isVideo,
      file,
      webcamImage,
      sensitive,
      dateTime
    );
  }

  submitEntryForPatient$(
    formResponses,
    isVideo = false,
    file = null,
    webcamImage = false,
    sensitive = false,
    dateTime?
  ) {
    return from(
      this.mediaService.storeMedia(
        this.properties.patientId,
        this.properties.collectionId,
        file, //file
        this.properties.captureType === CaptureType.FORM ? true : false,
        isVideo,
        sensitive,
        webcamImage, //is it a webcam?
        formResponses as any,
        Object.keys(formResponses),
        this.properties.recipient,
        this.properties.consentBestInterest || false, //consent best interest
        this.properties.collectionAuthId,
        dateTime,
        this.properties.teamId
      )
    ).pipe(
      tap({
        next: () => this.updatingAuthDocUsed(),
        error: (err: Error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Something went wrong',
            detail: err.message,
          });
        },
      })
    );
  }

  submitEntryForAuthUser$(
    formResponses,
    isVideo = false,
    file = null,
    webcamImage = false,
    sensitive = false,
    dateTime?
  ) {
    return from(
      this.mediaService.storeMedia(
        this.properties.patientId,
        this.properties.collectionId,
        file, //file
        this.properties.captureType === CaptureType.FORM ? true : false,
        isVideo,
        sensitive,
        webcamImage, //is it a webcam?
        formResponses as any,
        Object.keys(formResponses),
        null,
        this.properties.consentBestInterest || false, //consent best interest
        undefined, //if recipient is null -> user submission flow
        dateTime
      )
    ).pipe(
      tap({
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Something went wrong',
            detail:
              'User Flow -> It looks like something went wrong, please try again',
          });
        },
      })
    );
  }

  getFormIds$(): Observable<string[]> {
    if (this.properties?.collectionAuthId) {
      return of(
        this.properties?.formIds?.filter(
          (formId) =>
            formId !== 'default' ||
            this.properties.captureType === CaptureType.FORM
        )
      );
    }

    return this.getCollection$().pipe(
      map((collection) => {
        const formIdArray = collection?.submission?.formIds.length
          ? collection.submission.formIds
          : collection?.formIds;

        return formIdArray?.filter(
          (formId) =>
            formId !== 'default' ||
            collection?.submission?.capture === CaptureType.FORM ||
            (collection?.submission?.capture as any) === 'formOnly'
        );
      })
    );
  }

  resetSubmissionCount() {
    this.numberOfSubmissions = 0;
  }

  clearSessionStorage() {
    this.clearFormResponses();
    this.storage.deleteItem('entry-flow-collectionId');
    this.storage.deleteItem('entry-flow-patientId');
    this.storage.deleteItem('entry-flow-collectionAuthId');
    this.storage.deleteItem('entry-flow-captureType');
    this.storage.deleteItem('entry-flow-formIds');
  }

  clearFormResponses() {
    this.getFormIds$()
      .pipe(
        catchError(() => of([])),
        tap((formIds) => {
          formIds?.forEach((formId) => this.storage.deleteItem(formId));
          this.storage.deleteItem('default');
        }),
        take(1)
      )
      .subscribe();
  }

  async forwardRequest(requestId: string, patientName: string, email: string) {
    const forwardRequestToPatientEmailResult = firebase
      .app()
      .functions('europe-west2')
      .httpsCallable('forwardRequestToPatientEmail');

    return await forwardRequestToPatientEmailResult({
      requestId,
      patientName,
      email,
    });
  }

  private sanitiseCaptureType(captureType: string): CaptureType {
    if (captureType === 'entry') return CaptureType.PHOTO;
    if (captureType === 'capture') return CaptureType.VIDEO;

    return captureType as CaptureType;
  }

  private updatingAuthDocUsed() {
    if (
      this.properties.patientId &&
      this.properties.collectionId &&
      this.properties.collectionAuthId
    ) {
      this.collectionAuthService.updateAsUsed(
        this.properties.patientId,
        this.properties.collectionId,
        this.properties.collectionAuthId
      );
    }
  }

  private async updatingAuthDocAsInactive() {
    //set active to false on collection auth doc
    if (
      this.properties.patientId &&
      this.properties.collectionId &&
      this.properties.collectionAuthId
    ) {
      await this.collectionAuthService.updateAsInactive(
        this.properties.patientId,
        this.properties.collectionId,
        this.properties.collectionAuthId
      );
    }
  }

  private getCollection$(): Observable<CollectionWithId> {
    if (!this.properties?.patientId || !this.properties?.collectionId)
      return throwError(
        () => new Error('Properties missing from submission flow')
      );

    return this.collectionsService
      .getCollection(this.properties.patientId, this.properties.collectionId)
      .pipe(take(1));
  }

  public setPatientSentryUserId(id: string) {
    return this.user.user$
      .pipe(
        tap((user) => {
          if (user) return;
          this.analytics.setUserId(id);
          this.analytics.setUserProperties({ userType: 'Patient' });

          configureScope((scope) => {
            scope.setUser({
              id,
            });
          });
        }),
        take(1)
      )
      .subscribe();
  }
}
