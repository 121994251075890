<p-messages
  *ngIf="field?.props?.customTemplate"
  [severity]="field?.props?.severity"
  [closable]="field?.props?.closable"
  [escape]="field?.props?.escape"
>
  <ng-template pTemplate>
    <div class="flex flex-column sm:flex-row">
      <div
        *ngIf="!field?.props?.hideIcon"
        class="flex justify-content-center align-items-center mb-3 sm:mb-0"
      >
        <span
          *ngIf="field?.props?.severity === 'info'"
          class="p-inline-message-icon pi pi-info-circle isla-message"
        ></span>
        <span
          *ngIf="field?.props?.severity === 'success'"
          class="p-inline-message-icon pi pi-check isla-message"
        ></span>
        <span
          *ngIf="field?.props?.severity === 'warn'"
          class="p-inline-message-icon pi pi-exclamation-triangle isla-message"
        ></span>
        <span
          *ngIf="field?.props?.severity === 'error'"
          class="p-inline-message-icon pi pi-times-circle isla-message"
        ></span>
      </div>

      <ic-clinical-form-field-html
        class="w-full"
        [innerHTML]="field?.props?.html"
      ></ic-clinical-form-field-html>
    </div>
  </ng-template>
</p-messages>

<!-- Supports simple messaging that does not require a template (custom html) -->
<p-message
  *ngIf="!field?.props?.customTemplate"
  [severity]="field?.props?.severity"
  [text]="field?.props?.text"
  [styleClass]="field?.props?.styleClass"
></p-message>
