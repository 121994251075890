import { Pipe, PipeTransform } from '@angular/core';
import firebase from 'firebase/compat/app';

@Pipe({
  name: 'timestamp'
})
export class TimestampPipe implements PipeTransform {
  transform(value: firebase.firestore.Timestamp): number | null {
    return value?.seconds ? value?.seconds * 1000 : null;
  }
}
