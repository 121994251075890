import { Component } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  EntryFlowService,
  PatientSubmissionTextsService,
} from '@ic-monorepo/shared-submissions';
import {
  ToastService,
  ToastType,
} from 'apps/frontend/portal/src/app/shared/services/toast/toast.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import {
  SubmissionFlowState,
  SubmissionFlowStore,
} from '../../store/submission-flow.store';

@Component({
  selector: 'ic-forward-request-to-email',
  templateUrl: './forward-request-to-email.component.html',
})
export class ForwardRequestToEmailComponent {
  submitting$: Observable<boolean> = this.sfStore.submitting$;
  stateSnapshot: SubmissionFlowState;
  emailForm: UntypedFormGroup;

  get email(): AbstractControl {
    return this.emailForm.get('email');
  }

  get confirmEmail(): AbstractControl {
    return this.emailForm.get('confirmEmail');
  }

  constructor(
    private entryFlowService: EntryFlowService,
    private sfStore: SubmissionFlowStore,
    private toastService: ToastService,
    private formBuilder: UntypedFormBuilder,
    private config: DynamicDialogConfig,
    public sfTexts: PatientSubmissionTextsService,
    public ref: DynamicDialogRef
  ) {
    this.stateSnapshot = this.config.data.snapshot;
    this.emailForm = this.formBuilder.group(
      {
        email: ['', [Validators.required, Validators.email]],
        confirmEmail: ['', Validators.required],
      },
      { validator: this.checkEmailsMatch }
    );
  }

  checkEmailsMatch(group: FormGroup): null | { mismatch: boolean } {
    const email = group.get('email').value;
    const confirmEmail = group.get('confirmEmail').value;

    return email === confirmEmail ? null : { mismatch: true };
  }

  async forwardRequestToEmail(): Promise<void> {
    const requestId = this.stateSnapshot.requestId;
    const patientName = this.stateSnapshot.requestDoc.patient.firstName;
    const email = this.emailForm.get('email').value;

    this.sfStore.setSubmitting(true);

    try {
      const result = await this.entryFlowService.forwardRequest(
        requestId,
        patientName,
        email
      );

      if (result && result.data?.success) {
        this.toastService.open(
          ToastType.Success,
          'Successfully sent an email to the email address you provided'
        );
        this.ref.close(true);
      } else {
        const fwRequestError =
          'There was a problem forwarding this request to your email.';

        this.toastService.open(ToastType.Error, fwRequestError);
        throw new Error(fwRequestError);
      }
    } catch (error) {
      this.sfStore.setError(error.message);
      this.ref.close(false);
    } finally {
      this.sfStore.setSubmitting(false);
    }
  }
}
