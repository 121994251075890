<form [formGroup]="visualPainForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <div style="margin: 0 auto">
      <h3>Visual Pain Form</h3>
    </div>

    <!-- PAIN SCALE -->
    <div>
      <p>Please report your current pain severity on the line below:</p>
      <div class="full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>No pain</div>
        </div>
        <mat-slider
          thumbLabel
          class="full-width-field"
          formControlName="painSeverityScale"
          value="painSeverityScale"
          [min]="sliderMin"
          [max]="painSliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>Extreme pain</div>
        </div>
      </div>
    </div>

    <!-- WONG BAKER DROPDOWN -->
    <div class="question-container">
      <div class="half-width-field">
        <p>
          Please report which of the following describes your current pain
          severity the best:
        </p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="wongBakerDropdown">
          <mat-option
            *ngFor="let option of answerList; let i = index"
            [value]="option"
          >
            {{ option }} <span [innerHTML]="emojiList[i]"></span>
          </mat-option>
        </mat-select>
        <mat-error *ngIf="wongBakerDropdown.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <!-- BODY MAP OPTION -->
    <div class="question-container">
      <div class="half-width-field">
        <p>Please mark the areas where you feel pain on the body:</p>
      </div>
      <div class="body-map-holder">
        <div id="sheet-container">
          <canvas id="sheet" width="264" height="297"></canvas>
        </div>
        <br />
        <button
          mat-raised-button
          class="reset-button"
          (click)="clearMarking($event)"
        >
          RESET
        </button>
      </div>
    </div>

    <button
      mat-raised-button
      class="positive-button"
      type="submit"
      [disabled]="isSubmitting"
    >
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
