import {Directive, HostListener, Input} from '@angular/core';
import {AngularFireAnalytics} from '@angular/fire/compat/analytics';
import {captureMessage} from '@sentry/angular-ivy';

@Directive({
  selector: '[icEventTracker]'
})
export class EventTrackerDirective {
  @Input('icEventTracker') eventName: string;

  @HostListener('click')
  clickEvent() {
    this.analytics.logEvent(this.eventName, { action: 'click' });
    captureMessage(`EventTrackerDirective: ${this.eventName} clicked`)
  }
  constructor(private analytics: AngularFireAnalytics) {}
}
