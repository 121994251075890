import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { Observable, combineLatest, map, of, startWith } from 'rxjs';
import {
  SubmissionGroupedTagsForDropdowns,
  SubmissionTagForDropdowns,
} from '../../interfaces/submissions-dashboard.interface';
import { SubmissionsTagsService } from '../../services/submissions-tags/submissions-tags.service';

@Component({
  selector: 'ic-monorepo-manage-tags-dropdown',
  templateUrl: './manage-tags-dropdown.component.html',
  styleUrls: ['./manage-tags-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageTagsDropdownComponent implements OnInit, OnChanges {
  @Input() teamId: string;
  @Input() selectedSubmissionsCount: number;
  @Output() submitSelectedTags = new EventEmitter<SubmissionTagForDropdowns[]>();
  @Output() closePanelEmitted = new EventEmitter<boolean>();

  filteredTags$: Observable<SubmissionGroupedTagsForDropdowns[]> = of([]);
  selectedTags: SubmissionTagForDropdowns[] = [];
  searchControl = new FormControl('');

  constructor(private submissionsTagsService: SubmissionsTagsService) {}

  ngOnInit(): void {
    this.filteredTags$ = this.getFilteredTags$();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedSubmissionsCount && changes.selectedSubmissionsCount.currentValue) {
      this.filteredTags$ = this.getFilteredTags$();
    }
  }
  getFilteredTags$(): Observable<SubmissionGroupedTagsForDropdowns[]> {
    return combineLatest([
      this.submissionsTagsService.getGroupedTags$([this.teamId]),
      this.searchControl.valueChanges.pipe(startWith('')),
    ]).pipe(
      map(([groupedTags, searchQuery]) => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        return groupedTags.map(group => ({
          ...group,
          tags: group.tags.filter(tag => tag.tagDisplayName.toLowerCase().includes(lowerCaseQuery)),
        }));
      }),
    );
  }

  toggleSelectedTag(event: CheckboxChangeEvent, tag: SubmissionTagForDropdowns): void {
    if (event.checked) {
      this.selectedTags.push(tag);
    } else {
      this.selectedTags = this.selectedTags.filter(item => item.tagKey !== tag.tagKey);
    }
  }

  submitTags(): void {
    this.submitSelectedTags.emit(this.selectedTags);
  }

  closePanel(): void {
    this.closePanelEmitted.emit(false);
    this.selectedTags = [];
  }
}
