import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FeatureClinicalFormsModule } from '@ic-monorepo/feature-clinical-forms';
import { FileUploaderComponent, PrimeNgModule, ReleaseNotesSidebarComponent } from '@ic-monorepo/shared-common';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { SharedModule } from '../../shared/shared.module';
import { IfNotProductionDirective } from '../directives/if-not-production/if-not-production.directive';
import { LayoutDefaultHeaderComponent } from './components/layout-default-header/layout-default-header.component';
import { LayoutDefaultSidebarComponent } from './components/layout-default-sidebar/layout-default-sidebar.component';
import { UploadMonitorComponent } from './components/upload-monitor/upload-monitor.component';
import { ShellPageComponent } from './pages/shell/shell.page';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ToastModule,
    RouterModule,
    PrimeNgModule,
    FileUploaderComponent,
    ReleaseNotesSidebarComponent,
    IfNotProductionDirective,
    FeatureClinicalFormsModule,
  ],
  declarations: [
    ShellPageComponent,
    UploadMonitorComponent,
    LayoutDefaultHeaderComponent,
    LayoutDefaultSidebarComponent,
  ],
  exports: [ShellPageComponent, LayoutDefaultHeaderComponent, LayoutDefaultSidebarComponent],
  providers: [MessageService],
})
export class LayoutModule {}
