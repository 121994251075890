<form [formGroup]="maternityForm">
  <div class="form-formatting">
    <mat-card>
      <mat-card-title>Birth plan</mat-card-title>
      <mat-card-content>
        <div class="question-container">
          <div class="full-width-field">
            <p>
              A birth plan supports you (and your birth partner/s) to make informed choices about
              your care around the birth. Sharing your preferences with your care providers enables
              them to personalise the care and support you receive. Work your way through the
              questions at your own pace and then submit them to your midwife from 34 weeks onwards.
              This personal care plan should be created, in consultation with a healthcare
              professional, especially if you have an underlying health condition (such as diabetes,
              hypertension) or have developed a pregnancy-related condition (such as pre-eclampsia).
              Note, if you are using the 'Mum and Baby App' this care plan can be completed there
              too.
            </p>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <br />

    <mat-card>
      <mat-card-title>Basic details</mat-card-title>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>My due date:</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input
              matInput
              type="date"
              formControlName="dueDate"
              placeholder="UK: "
              class="date-placeholder"
            />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Name of my maternity unit:</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="text" formControlName="maternityUnit" />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Intended place of birth:</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="intendedPlaceOfBirth">
              <mat-option value="Home">Home</mat-option>
              <mat-option value="Midwife-led unit">Midwife-led unit</mat-option>
              <mat-option value="Obstetric-led unit">In a labour ward</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Team name:</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="text" formControlName="teamName" />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Named midwife:</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="text" formControlName="namedMidwife" />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Midwife/team contact details:</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <textarea matInput type="text" formControlName="midwifeOrTeamContactDetails"></textarea>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Named obstetric/midwife consultant:</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="text" formControlName="namedObstetricOrMidwifeConsultant" />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Known medical conditions/allergies:</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <textarea
              matInput
              type="text"
              formControlName="knownMedicalConditionsOrAllergies"
            ></textarea>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <br />

    <mat-card>
      <mat-card-title>At the birth</mat-card-title>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>
              I am aware of my three choices of birth setting (home, birth centre and labour ward)
              and have had a discussion with my midwife/doctor about which option is recommended for
              me. I would prefer to give birth...
            </p>
            <p>
              <em>
                Certain options might be recommended for you based on your personal health and
                pregnancy.
              </em>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="birthSettingPreference">
              <mat-option value="At home">At home</mat-option>
              <mat-option value="In a birth centre">In a birth centre</mat-option>
              <mat-option value="In a labour ward">In a labour ward</mat-option>
              <mat-option value="I prefer to wait and see">I prefer to wait and see</mat-option>
              <mat-option value="I am not sure/I would like to find out more"
                >I am not sure/I would like to find out more.</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>My birth partner(s) will be...</p>
            <p>
              <em>Think of up to two people that you would like to have with you during labour</em>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <textarea matInput type="text" formControlName="birthPartner"></textarea>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Student midwives/doctors may be working with the team when I have my baby...</p>
            <p>
              <em
                >Students work closely alongside a supervised midwife and will provide you with care
                and support under supervision, with your consent.</em
              >
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="studentsPresent">
              <mat-option value="I am happy for a student to be present during my labour/birth"
                >I am happy for a student to be present during my labour/birth</mat-option
              >
              <mat-option value="I prefer that no students are present during my labour/birth"
                >I prefer that no students are present during my labour/birth</mat-option
              >
              <mat-option value="I prefer to wait and see">I prefer to wait and see</mat-option>
              <mat-option value="I am not sure/I would like to find out more"
                >I am not sure/I would like to find out more</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>I have additional requirements...</p>
            <p>
              <em
                >If you have any special requirements, please tell your maternity team as early as
                possible. Use of interpreting services will vary depending on local policy and
                availability, please discuss with your midwife.</em
              >
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="additionalRequirements" multiple>
              <mat-option value="I will need help to translate into my language"
                >I will need help to translate into my language</mat-option
              >
              <mat-option value="I have allergies and/or special dietary requirements"
                >I have allergies and/or special dietary requirements</mat-option
              >
              <mat-option
                value="I have religious beliefs and customs that I would like to be observed"
                >I have religious beliefs and customs that I would like to be observed</mat-option
              >
              <mat-option value="I/my partner have additional needs"
                >I/my partner have additional needs</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Any other things that I would like you to know about me...</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <textarea
              matInput
              type="text"
              formControlName="additionalRequirementsFreeText"
            ></textarea>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              I have had a discussion with my midwife/doctor about how I would like to give birth.
            </p>
            <p>
              <em
                >The majority of women will have a vaginal birth, however for some a caesarean birth
                may be recommended.</em
              >
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="birthMethodPreferenceDiscussionHasOccurred">
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <br />

    <mat-card>
      <mat-card-title>Vaginal birth</mat-card-title>

      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>How are you planning to give birth?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="vaginalDelivery">
              <mat-option value="I am having a vaginal delivery / I am not sure"
                >I am having a vaginal delivery / I am not sure</mat-option
              >
              <mat-option value="I am having a planned caesarean"
                >I am having a planned caesarean</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              The questions in this section are only relevant if you are <strong>not</strong> having
              a planned caesarean.
            </p>
          </div>
        </div>

        <br />

        <div *ngIf="vaginalDelivery.value === 'I am having a vaginal delivery / I am not sure'">
          <div class="question-container">
            <div class="half-width-field">
              <p>
                In some circumstances, your midwife or doctor may recommend starting your labour
                artificially, instead of waiting for it to start naturally (this is known as
                induction of labour)...
              </p>
              <p>
                <em
                  >If you go 10 or more days past your estimated due date, you have certain medical
                  conditions, or your doctor is concerned about the health of your baby you may be
                  offered an induction of labour. This will be planned carefully with your
                  midwife/doctor.</em
                >
              </p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <mat-select formControlName="awareOfInductionOfLabour">
                <mat-option value="I am aware of why an induction might be recommended"
                  >I am aware of why an induction might be recommended</mat-option
                >
                <mat-option value="I am not sure/I would like to find out more"
                  >I am not sure/I would like to find out more</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div class="question-container">
            <div class="half-width-field">
              <p>
                During labour and birth I would consider the following coping strategies/pain
                relief...
              </p>
              <p>
                <em
                  >Your options for pain relief will depend on where you plan to give birth. Discuss
                  with your midwife and ask what options are available to you at your local
                  maternity unit.</em
                >
              </p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <mat-select formControlName="typesOfPainReliefConsidered" multiple>
                <mat-option value="I prefer to avoid all pain relief"
                  >I prefer to avoid all pain relief</mat-option
                >
                <mat-option value="Self-hypnosis/hypnobirthing"
                  >Self-hypnosis/hypnobirthing</mat-option
                >
                <mat-option value="Aromatherapy/homeopathy/reflexology"
                  >Aromatherapy/homeopathy/reflexology
                </mat-option>
                <mat-option value="Water (bath or birthing pool)"
                  >Water (bath or birthing pool)</mat-option
                >
                <mat-option value="TENS machine (transcutaneous electrical nerve stimulation)"
                  >TENS machine (transcutaneous electrical nerve stimulation)</mat-option
                >
                <mat-option value="Gas and air (entonox)">Gas and air (entonox)</mat-option>
                <mat-option value="Pethidine/diamorphine/meptid (opioid injection)"
                  >Pethidine/diamorphine/meptid (opioid injection)</mat-option
                >
                <mat-option value="Epidural">Epidural</mat-option>
                <mat-option value="I prefer to wait and see">I prefer to wait and see</mat-option>
                <mat-option value="I am not sure/I would like to find out more"
                  >I am not sure/I would like to find out more</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div class="question-container">
            <div class="half-width-field">
              <p>During labour and birth I would consider...</p>
              <p>
                <em
                  >Your circumstances in labour may influence what choices are available to you.
                  Please discuss this with your midwife at 34-40 weeks.</em
                >
              </p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <mat-select formControlName="otherOptionsDuringLabourConsidered" multiple>
                <mat-option value="Massage">Massage</mat-option>
                <mat-option value="Walking/standing">Walking/standing</mat-option>
                <mat-option value="Different upright positions such as all fours/squatting/kneeling"
                  >Different upright positions such as all fours/squatting/kneeling</mat-option
                >
                <mat-option value="A birthing ball">A birthing ball</mat-option>
                <mat-option value="Bean bags, birth stool and birth couches if available"
                  >Bean bags, birth stool and birth couches if available</mat-option
                >
                <mat-option value="A birthing pool">A birthing pool</mat-option>
                <mat-option
                  value="A bed, for rest – propped up with pillows or whilst lying on my side"
                  >A bed, for rest – propped up with pillows or whilst lying on my side</mat-option
                >
                <mat-option value="Music to be played (which I will provide)"
                  >Music to be played (which I will provide)
                </mat-option>
                <mat-option value="The lights dimmed">The lights dimmed</mat-option>
                <mat-option value="My birth partner take photographs/filming"
                  >My birth partner take photographs/filming
                </mat-option>
                <mat-option value="I prefer to wait and see">I prefer to wait and see</mat-option>
                <mat-option value="I am not sure/I would like to find out more"
                  >I am not sure/I would like to find out more</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div class="question-container">
            <div class="half-width-field">
              <p>
                During labour and birth, it is recommended that your baby’s heartbeat is
                monitored...
              </p>
              <p>
                <em
                  >The majority of women will have a vaginal birth, however for some a caesarean
                  birth may be recommended.</em
                >
              </p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <mat-select formControlName="fetalHeartRateMonitoringPreferences">
                <mat-option
                  value="I prefer to have intermittent fetal heart rate monitoring with a handheld device"
                  >I prefer to have intermittent fetal heart rate monitoring with a handheld
                  device</mat-option
                >
                <mat-option
                  value="I prefer to have continuous fetal heart rate monitoring using a CTG machine"
                  >I prefer to have continuous fetal heart rate monitoring using a CTG
                  machine</mat-option
                >
                <mat-option
                  value="If I need continuous monitoring I would like to be mobile and use wireless monitoring if available"
                >
                  If I need continuous monitoring I would like to be mobile and use wireless
                  monitoring if available
                </mat-option>
                <mat-option value="I prefer to wait and see">I prefer to wait and see</mat-option>
                <mat-option value="I am not sure/I would like to find out more"
                  >I am not sure/I would like to find out more</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div class="question-container">
            <div class="half-width-field">
              <p>
                During labour, your midwife and/or doctor may recommend vaginal examinations to
                assess the progress of your labour...
              </p>
              <p>
                <em
                  >Vaginal examinations are a routine part of assessing labour progress and will not
                  be undertaken without your consent.</em
                >
              </p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <mat-select formControlName="vaginalExaminationPreferences">
                <mat-option value="I am aware of why vaginal examinations are part of routine care"
                  >I am aware of why vaginal examinations are part of routine care</mat-option
                >
                <mat-option value="I prefer to avoid vaginal examinations if possible"
                  >I prefer to avoid vaginal examinations if possible</mat-option
                >
                <mat-option value="I prefer to wait and see">I prefer to wait and see</mat-option>
                <mat-option value="I am not sure/I would like to find out more"
                  >I am not sure/I would like to find out more</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div class="question-container">
            <div class="half-width-field">
              <p>
                In some circumstances, your midwife or doctor may recommend interventions to assist
                with your labour...
              </p>
              <p>
                <em
                  >Interventions may be recommended if your labour slows down, or if there are
                  concerns with you or your baby’s health.</em
                >
              </p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <mat-select formControlName="awareOfReasonsForIntervention">
                <mat-option value="I am aware of why assistance/intervention might be recommended"
                  >I am aware of why assistance/intervention might be recommended</mat-option
                >
                <mat-option value="I am not sure/I would like to find out more"
                  >I am not sure/I would like to find out more</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div class="question-container">
            <div class="half-width-field">
              <p>
                In some circumstances, your maternity team may intervene by recommending an assisted
                or caesarean birth...
              </p>
              <p>
                <em
                  >An assisted or caesarean birth may be recommended if it is thought to be the
                  safest way to deliver your baby; your doctor will discuss this with you and ask
                  for your consent before any procedure is undertaken.</em
                >
              </p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <mat-select formControlName="awareOfReasonsForAssistedBirth">
                <mat-option value="I understand why an assisted birth might be recommended"
                  >I understand why an assisted birth might be recommended</mat-option
                >
                <mat-option value="I am not sure/I would like to find out more"
                  >I am not sure/I would like to find out more</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div class="question-container">
            <div class="half-width-field">
              <p>
                In some circumstances, your midwife or doctor may recommend a cut to the perineum to
                facilitate birth (episiotomy)...
              </p>
              <p>
                <em
                  >An episiotomy may be recommended for an assisted birth or if your midwife/doctor
                  is concerned that your baby needs to be born quickly. Your midwife/doctor will
                  always ask for your consent.</em
                >
              </p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <mat-select formControlName="awareOfEpisiotomy">
                <mat-option value="I understand why an episiotomy might be recommended"
                  >I understand why an episiotomy might be recommended</mat-option
                >
                <mat-option value="I prefer to avoid an episiotomy"
                  >I prefer to avoid an episiotomy</mat-option
                >
                <mat-option value="I am not sure/I would like to find out more"
                  >I am not sure/I would like to find out more</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div class="question-container">
            <div class="half-width-field">
              <p>
                After your baby is born, your placenta will be expelled (this is known as the third
                stage of labour). There are two ways this can happen...
              </p>
              <p>
                <em
                  >Your midwife or doctor may recommend an active third stage due to your personal
                  circumstance and will discuss this with you at the time of birth.</em
                >
              </p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <mat-select formControlName="thirdStagePreferences">
                <mat-option
                  value="I would like to have a natural (physiological) third stage, the cord is left intact and I push the placenta out myself"
                >
                  I would like to have a natural (physiological) third stage, the cord is left
                  intact and I push the placenta out myself</mat-option
                >
                <mat-option
                  value="I would like to have an active third stage, where the cord is cut after a few minutes and I receive an injection of oxytocin, the midwife/doctor delivers my placenta"
                >
                  I would like to have an active third stage, where the cord is cut after a few
                  minutes and I receive an injection of oxytocin, the midwife/doctor delivers my
                  placenta</mat-option
                >
                <mat-option value="I prefer to wait and see">I prefer to wait and see</mat-option>
                <mat-option value="I am not sure/I would like to find out more"
                  >I am not sure/I would like to find out more</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div class="question-container">
            <div class="half-width-field">
              <p>Cord cutting...</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <mat-select formControlName="cordCuttingPreferences">
                <mat-option value="I/my birth partner would like to cut the umbilical cord"
                  >I/my birth partner would like to cut the umbilical cord</mat-option
                >
                <mat-option value="I prefer the midwife/doctor to cut the umbilical cord"
                  >I prefer the midwife/doctor to cut the umbilical cord</mat-option
                >
                <mat-option value="I prefer to wait and see">I prefer to wait and see</mat-option>
                <mat-option value="I am not sure/I would like to find out more"
                  >I am not sure/I would like to find out more</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <!-- </mat-card-content>
      </mat-card>

    </div> -->

    <br />

    <mat-card>
      <mat-card-title>After the birth</mat-card-title>
      <mat-card-content>
        <br />

        <div class="question-container">
          <div class="half-width-field">
            <p>
              Skin-to-skin contact with your baby – immediately after birth – is recommended for
              all...
            </p>
            <p>
              <em
                >As long as you and your baby are both well, skin-to-skin can be done following any
                type of birth. Your partner can also have skin-to-skin contact with your baby.</em
              >
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="skinToSkinPreferences">
              <mat-option
                value="I understand why skin-to-skin contact is recommended. I would like immediate skin-to-skin contact"
              >
                I understand why skin-to-skin contact is recommended. I would like immediate
                skin-to-skin contact
              </mat-option>
              <mat-option value="I prefer to wait and see">I prefer to wait and see</mat-option>
              <mat-option value="I am not sure/I would like to find out more"
                >I am not sure/I would like to find out more</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              I am aware that I will be provided with support to feed my baby, my thoughts around
              feeding are...
            </p>
            <p>
              <em
                >During pregnancy you will have a chance to discuss infant feeding, this will
                include information about the value of breastfeeding. A midwife will help you to get
                feeding off to a good start as soon as your baby shows cues that he/she is ready to
                feed.</em
              >
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <textarea matInput type="text" formControlName="thoughtsAroundFeeding"></textarea>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>After my baby is born, they will be offered Vitamin K...</p>
            <p>
              <em
                >Vitamin K is a supplement that is recommended for all babies that prevents a rare
                condition known as Vitamin K Deficiency Bleeding (VKDB). The Vitamin K supplement
                has no known side effects.</em
              >
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="vitaminKPreferences">
              <mat-option value="I would like my baby to have Vitamin K by injection"
                >I would like my baby to have Vitamin K by injection</mat-option
              >
              <mat-option value="I would like my baby to have Vitamin K by oral drops"
                >I would like my baby to have Vitamin K by oral drops</mat-option
              >
              <mat-option value="I do not want my baby to have Vitamin K"
                >I do not want my baby to have Vitamin K
              </mat-option>
              <mat-option value="I am not sure/I would like to find out more"
                >I am not sure/I would like to find out more</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>My preferred choice of contraception</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="contraceptionPreferences">
              <mat-option value="I have read the Contraception Leaflet"
                >I have read the Contraception Leaflet
              </mat-option>
              <mat-option
                value="I have made a decision regarding my choice of contraception post birth"
                >I have made a decision regarding my choice of contraception post birth</mat-option
              >
              <mat-option value="I prefer to wait and see">I prefer to wait and see</mat-option>
              <mat-option value="I am not sure/I would like to find out more"
                >I am not sure/I would like to find out more</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <br />

    <mat-form-field class="full-width-field" appearance="fill">
      <mat-label>Any other comments?</mat-label>
      <textarea matInput formControlName="notes" type="notes"> </textarea>
    </mat-form-field>
    <br />

    <button
      mat-raised-button
      class="positive-button"
      type="submit"
      [disabled]="isSubmitting"
      (click)="onSubmit()"
    >
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
