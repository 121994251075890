import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { SubmissionsMediaState } from '../../interfaces/submissions-dashboard.interface';
import { Observable } from 'rxjs';

export const defaultState: SubmissionsMediaState = {
  consentType: [],
  pathway: [],
  requestedBy: [],
  reviewStatus: false,
  selectedEndDate: undefined,
  selectedExcludedTags: [],
  selectedIncludedTags: [],
  selectedStartDate: undefined,
  submittedBy: [],
  teams: [],
  pagination: undefined,
};

@Injectable()
export class SubmissionDashboardMediaStore extends ComponentStore<SubmissionsMediaState> {
  readonly submissionMediaFilter$: Observable<SubmissionsMediaState> = this.select((state: SubmissionsMediaState) => {
    return state;
  });

  constructor() {
    super(defaultState);
  }

  setSubmissionMediaFilter = this.updater((state: SubmissionsMediaState, filter: Partial<SubmissionsMediaState>) => {
    const copyOfState = { ...state };
    if (copyOfState.pagination) {
      copyOfState.pagination = undefined;
    }
    return {
      ...copyOfState,
      ...filter,
    };
  });

  patchSubmissionFilter(partialFilter: Partial<SubmissionsMediaState>): void {
    this.patchState((state: SubmissionsMediaState) => {
      const copyOfState = { ...state };

      if (partialFilter.pagination) {
        copyOfState.pagination = partialFilter.pagination;
      }
      return {
        ...copyOfState,
        ...partialFilter,
      };
    });
  }

  clearSubmissionMediaStore = this.updater(() => {
    return defaultState;
  });
}
