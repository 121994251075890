import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PatientWithId, User } from '@islacare/ic-types';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogData } from '../dialog-data';

interface IDialogPatientDetails extends DialogData {
  patient: PatientWithId;
  isEditButtonVisible: boolean;
  isEditButtonDisabled: boolean;
  isOrgEmailEnabled: boolean;
  shouldShowS1Icon: boolean;
  user: User;
}
@Component({
  selector: 'ic-view-patient-details',
  templateUrl: './view-patient-details.component.html',
  styleUrls: ['./view-patient-details.component.scss'],
})
export class ViewPatientDetailsComponent {
  data: IDialogPatientDetails;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private router: Router
  ) {
    this.data = config.data;
  }

  editPatientBtn(patientId: string): void {
    this.ref.close();
    this.router.navigate([`/patient/${patientId}/edit`]);
  }
  closeDialog(): void {
    this.ref.close();
  }
}
