import { Pipe, PipeTransform } from '@angular/core';
import { EntryWithId } from '@islacare/ic-types';
import { EntriesService } from 'apps/frontend/portal/src/app/services/entries/entries.service';
import { Observable } from 'rxjs';

@Pipe({
  standalone: true,
  name: 'mediaUrl',
})
export class MediaUrlPipe implements PipeTransform {
  constructor(private entriesService: EntriesService) {}

  transform(entry: EntryWithId, size: string): Observable<string> {
    return this.entriesService.returnUrl(entry, size);
  }
}
