<ng-container *ngIf="field && control">
  <div class="question-container" *ngIf="field?.title">
    <ic-automatic-title
      [field]="field"
      class="half-width-field"
    ></ic-automatic-title>
    <ng-container *ngTemplateOutlet="textareaField"></ng-container>
  </div>

  <ng-container *ngIf="!field?.title">
    <ng-container *ngTemplateOutlet="textareaField"></ng-container>
  </ng-container>

  <ng-template #textareaField>
    <mat-form-field
      appearance="fill"
      [class]="field.title ? 'half-width-field' : 'full-width-field'"
    >
      <mat-label *ngIf="field?.subTitle"
        ><ic-automatic-subtitle [field]="field"></ic-automatic-subtitle
      ></mat-label>

      <textarea matInput [formControl]="control" type="notes"> </textarea>

      <mat-error *ngIf="control?.touched && control?.invalid">
        {{ formService?.getErrorMessage(control) }}
      </mat-error>
    </mat-form-field>
  </ng-template>
</ng-container>
