import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { TeamsService } from 'apps/frontend/portal/src/app/services/teams/teams.service';
import { take } from 'rxjs';

@Directive({
  selector: '[icAdminOnly]',
})
export class AdminOnlyDirective implements OnInit {
  @Input('icAdminOnly') teamId: string;

  @Input()
  set icAdminOnlyElse(templateRef: TemplateRef<unknown>) {
    this.elseTemplateRef = templateRef;
  }
  private elseTemplateRef: TemplateRef<unknown>;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private teamsService: TeamsService
  ) {}

  ngOnInit() {
    this.teamsService
      .isTeamAdmin$(this.teamId)
      .pipe(take(1))
      .subscribe((isAdmin) => {
        if (isAdmin) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
          if (this.elseTemplateRef) {
            this.viewContainer.createEmbeddedView(this.elseTemplateRef);
          }
        }
      });
  }
}
