<ng-container *ngIf="!showNumberField; else useNumberInput">
  <div class="flex gap-1 justify-content-center md:justify-content-between my-4 md:mx-4 flex-wrap">
    <div *ngFor="let option of sliderRange">
      <label>
        <div
          class="align-items-center border-main bg-main-surface border-1 border-round flex flex-column-reverse gap-1 p-3"
          ngDefaultControl
        >
          <p-radioButton
            [inputId]="option"
            [value]="option"
            [name]="field?.key.toString()"
            [formControl]="formControl"
            [attr.data-cy]="field?.key + '-option-' + option"
          ></p-radioButton>
          {{ option }}
        </div>
      </label>
    </div>
  </div>
  <div class="flex justify-content-between">
    <div>{{ field?.props?.minLabel }}</div>
    <div>{{ field?.props?.maxLabel }}</div>
  </div>
</ng-container>

<ng-template #useNumberInput>
  <p-inputNumber
    [name]="field?.props?.label"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [prefix]="field?.props?.prefix"
    [suffix]="field?.props?.suffix"
    [min]="field?.props?.min"
    [max]="field?.props?.max"
    [showButtons]="true"
    [placeholder]="numberPlaceHolder"
  ></p-inputNumber>
</ng-template>
