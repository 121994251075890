import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { FormField } from '@islacare/ic-types';

@Component({
  selector: 'ic-automatic-checkbox',
  templateUrl: './automatic-checkbox.component.html',
  styleUrls: ['./automatic-checkbox.component.scss'],
})
export class AutomaticCheckboxComponent {
  @Input() field: FormField;
  @Input() control: UntypedFormControl;
  constructor(public formService: FormService) {}

  fontWeight() {
    if (this.field?.boldTitle === true) {
      return 'bold';
    }
  }
  textAlign() {
    return this.field?.alignment || 'center';
  }
}
