import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, map, switchMap } from 'rxjs';
import { OrganisationsService } from '../../../services/organisations/organisations.service';
import { FormService } from '../../../shared/services/form/form.service';
import { UsersService } from '../../../shared/services/users/users.service';
import { LayoutDefaultNavService, NavToolItem } from '../services/layout-default-nav/layout-default-nav.service';
import { LayoutDefaultSidebarService } from '../services/layout-default-sidebar/layout-default-sidebar.service';

@Injectable()
export class LayoutDefaultHelper {
  showMenuButton$ = this.layoutDefaultSidebarService.showMenuButton$;
  showSidebar$ = this.layoutDefaultSidebarService.showSidebar$;
  navToolItems$ = this.layoutDefaultNavService.getNavToolItems();
  navItems$ = this.layoutDefaultNavService.getNavItems();
  user$ = this.usersService.me$;

  orgLogoUrl$: Observable<string>;

  constructor(
    public layoutDefaultSidebarService: LayoutDefaultSidebarService,
    private layoutDefaultNavService: LayoutDefaultNavService,
    private organisationsService: OrganisationsService,
    private activatedRoute: ActivatedRoute,
    private usersService: UsersService,
    private formService: FormService,
    private router: Router,
  ) {}

  getOrgLogoUrl(): void {
    this.orgLogoUrl$ = this.usersService.myOrganisationId$.pipe(
      switchMap(id => this.organisationsService.getOrganisationWithUrl$(id)),
      map(org => org?.url),
    );
  }

  isActiveRoute(baseRoute: string): boolean {
    return this.router.url.startsWith(baseRoute);
  }

  navigateByUrl(url: string): void {
    this.router.navigate([url]);
  }

  navToolAction(navToolItem: NavToolItem): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { isCalcForm: true, formId: navToolItem.id },
      queryParamsHandling: 'merge',
    });

    this.formService.showFormPreview([navToolItem.id], null, `${navToolItem.label} Calculator`);
  }
}
