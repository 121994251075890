<form [formGroup]="nucaForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <h3>NUCA Patient Questionnaire</h3>

    <p>All fields must be completed in order to submit</p>

    <div class="question-container">
      <div class="half-width-field">
        <p>Present occupation</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <input
          matInput
          formControlName="presentOccupation"
          type="notes"
          placeholder="Occupation"
          autocomplete="off"
        />
        <mat-error *ngIf="presentOccupation.touched">
          Please enter your occupation
        </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Previous occupations</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <input
          matInput
          formControlName="previousOccupations"
          type="notes"
          placeholder="Previous occupations"
          autocomplete="off"
        />
        <mat-error *ngIf="previousOccupations.touched">
          Please enter your previous occupations
        </mat-error>
      </mat-form-field>
    </div>

    <h4>Presenting complaint</h4>

    <div class="question-container">
      <div class="half-width-field">
        <p>
          <strong>Where</strong> on your body is the lesion for which you have
          been referred?
        </p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <input
          matInput
          formControlName="lesionBodyLocation"
          type="notes"
          placeholder="Body location"
          autocomplete="off"
        />
        <mat-error *ngIf="lesionBodyLocation.touched">
          Please enter where on your body the lesion is
        </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p><strong>How long</strong> have you had this lesion?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <input
          matInput
          formControlName="howLongHaveYouHadThisLesion"
          type="notes"
          placeholder="E.g 2 months"
          autocomplete="off"
        />
        <mat-error *ngIf="howLongHaveYouHadThisLesion.touched">
          Please enter how long you had the lesion
        </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>
          <strong>When did this lesion change?</strong>
        </p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <input
          matInput
          formControlName="whenDidThisLesionChange"
          type="notes"
          placeholder="E.g. 5 days ago"
          autocomplete="off"
        />
        <mat-error *ngIf="whenDidThisLesionChange.touched">
          Please enter when this lesion changed
        </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Has this lesion changed in the last <strong>3 months?</strong></p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="hasTheLesionChangedInTheLastThreeMonths">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
          <mat-option value="Don't know">Don't know</mat-option>
        </mat-select>
        <mat-error *ngIf="hasTheLesionChangedInTheLastThreeMonths.touched">
          Please select if this lesion changed in the last 3 months
        </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Has the lesion changed <strong>in size?</strong></p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="lesionChangedInSize">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
          <mat-option value="Don't know">Don't know</mat-option>
        </mat-select>
        <mat-error *ngIf="lesionChangedInSize.touched">
          Please select if your lesion changed in size
        </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Has the lesion changed <strong>in shape?</strong></p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="lesionChangedInShape">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
          <mat-option value="Don't know">Don't know</mat-option>
        </mat-select>
        <mat-error *ngIf="lesionChangedInShape.touched">
          Please select if your lesion changed in shape
        </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Has the lesion changed <strong>in colour?</strong></p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="lesionChangedInColour">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
          <mat-option value="Don't know">Don't know</mat-option>
        </mat-select>
        <mat-error *ngIf="lesionChangedInColour.touched">
          Please select if your lesion changed in colour
        </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Has the lesion been any of the following:</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select multiple formControlName="lesionBeenAnyOfTheFollowing">
          <mat-option value="itchy">Itchy</mat-option>
          <mat-option value="crusty or rough">Crusty or rough</mat-option>
          <mat-option value="oozing">Oozing</mat-option>
          <mat-option value="bleeding">Bleeding</mat-option>
          <mat-option value="painful">Painful</mat-option>
          <mat-option value="Don't know">Don't know</mat-option>
          <mat-option value="None">None</mat-option>
        </mat-select>
        <mat-error *ngIf="lesionBeenAnyOfTheFollowing.touched">
          Please select if your lesion has been any of the following
        </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Have <strong>you</strong> had a previous skin cancer?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="previouslyHadSkinCancer">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
          <mat-option value="Don't know">Don't know</mat-option>
        </mat-select>
        <mat-error *ngIf="previouslyHadSkinCancer.touched">
          Please select if you've had cancer before
        </mat-error>
      </mat-form-field>
    </div>

    <div
      class="question-container"
      *ngIf="
        nucaForm.get('previouslyHadSkinCancer').value.indexOf('Yes') !== -1
      "
    >
      <div class="half-width-field">
        <p>
          <strong>Was this a:</strong>
        </p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="previousCancerDetails">
          <mat-option value="BCC">BCC</mat-option>
          <mat-option value="SCC">SCC</mat-option>
          <mat-option value="Melanoma">Melanoma</mat-option>
          <mat-option value="Don't know">Don't know</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Has a member of family ever had skin cancer?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="familyMemberPreviouslyHadSkinCancer">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
          <mat-option value="Don't know">Don't know</mat-option>
        </mat-select>
        <mat-error *ngIf="familyMemberPreviouslyHadSkinCancer.touched">
          Please select if a family member has had cancer before
        </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Do you sunburn easily?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="doYouSunburnEasily">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
          <mat-option value="Don't know">Don't know</mat-option>
        </mat-select>
        <mat-error *ngIf="doYouSunburnEasily.touched">
          Please select if you sunburn easily
        </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Have you ever lived somewhere hot and sunny?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="livedSomewhereHotAndSunny">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
          <mat-option value="Don't know">Don't know</mat-option>
        </mat-select>
        <mat-error *ngIf="livedSomewhereHotAndSunny.touched">
          Please select if you have lived somewhere hot and sunny
        </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Have you ever worked outside regularly?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="haveYouEverWorkedOutsideRegularly">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
          <mat-option value="Don't know">Don't know</mat-option>
        </mat-select>
        <mat-error *ngIf="haveYouEverWorkedOutsideRegularly.touched">
          Please select if you have worked outside regularly
        </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>
          Do you have any hobbies that take place outside? (e.g: running,
          cycling, tennis, gardening)
        </p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="hobbiesThatTakePlaceOutside">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
          <mat-option value="Don't know">Don't know</mat-option>
        </mat-select>
        <mat-error *ngIf="hobbiesThatTakePlaceOutside.touched">
          Please select if you have hobbies that take place outside
        </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Have you ever used sun beds?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="haveYouEverUsedSunbeds">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
          <mat-option value="Don't know">Don't know</mat-option>
        </mat-select>
        <mat-error *ngIf="haveYouEverUsedSunbeds.touched">
          Please select if you have ever used sunbeds
        </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Did you ever have severe or blistering sun burn as a child?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select
          formControlName="didYouEverHaveSevereOrBlisteringSunburnAsAChild"
        >
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
          <mat-option value="Don't know">Don't know</mat-option>
        </mat-select>
        <mat-error
          *ngIf="didYouEverHaveSevereOrBlisteringSunburnAsAChild.touched"
        >
          Please select if you have ever had severe sunburn as a child
        </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>
          Do you have a reduced immune system (immunosuppression) due to any of
          the following: Organ transplant Medication (e.g. ciclosporin,
          chemotherapy, long term steroids, methotrexate, azathioprine,
          biologics) Cancer (any type) Illness (e.g. HIV, Rheumatoid arthritis)
        </p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="doYouHaveAHistoryOfImmunosuppression">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
          <mat-option value="Don't know">Don't know</mat-option>
        </mat-select>
        <mat-error *ngIf="doYouHaveAHistoryOfImmunosuppression.touched">
          Please select if you have a history of immunosuppression
        </mat-error>
      </mat-form-field>
    </div>

    <div
      class="question-container"
      *ngIf="
        nucaForm
          .get('doYouHaveAHistoryOfImmunosuppression')
          .value.indexOf('Yes') !== -1
      "
    >
      <div class="half-width-field">
        <p>Is this due to: (please select as appropriate)</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select multiple formControlName="immunosuppressionDetails">
          <mat-option value="Organ transplant">Organ transplant</mat-option>
          <mat-option
            value="Medication (eg: methotrexate, ciclosporin, chemotherapy, long term steroids)"
            >Medication (eg: methotrexate, ciclosporin, chemotherapy, long term
            steroids)</mat-option
          >
          <mat-option value="Cancer (any type)">Cancer (any type)</mat-option>
          <mat-option value="Illness (eg: rheumatoid arthritis , HIV)"
            >Illness (eg: rheumatoid arthritis , HIV)</mat-option
          >
          <mat-option value="None of the above">None of the above</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div
      class="question-container"
      *ngIf="
        nucaForm.get('shieldingDuringCovidPandemic').value.indexOf('Yes') !== -1
      "
    >
      <div class="half-width-field">
        <p>Please tell us why</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <input
          matInput
          formControlName="covidShieldingDetails"
          type="notes"
          placeholder="Enter details here"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <h4>In the event that you need a biopsy</h4>

    <div class="question-container">
      <div class="half-width-field">
        <p>Do you have any medication allergies?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="doYouHaveAnyAllergies">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
          <mat-option value="Don't know">Don't know</mat-option>
        </mat-select>
        <mat-error *ngIf="doYouHaveAnyAllergies.touched">
          Please select if you have allergies or not
        </mat-error>
      </mat-form-field>
    </div>
    <div
      class="question-container"
      *ngIf="nucaForm.get('doYouHaveAnyAllergies').value.indexOf('Yes') !== -1"
    >
      <div class="half-width-field">
        <p>Please give details about your allergies</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <input
          matInput
          formControlName="doYouHaveAnyAllergiesDetails"
          type="notes"
          placeholder="Allergy details"
          autocomplete="off"
        />
        <mat-error *ngIf="doYouHaveAnyAllergiesDetails.touched">
          Please enter allergy details here
        </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Do you have a pacemaker?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="doYouHaveAPacemaker">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
          <mat-option value="Don't know">Don't know</mat-option>
        </mat-select>
        <mat-error *ngIf="doYouHaveAPacemaker.touched">
          Please enter if you have a pacemaker
        </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Are you on any blood thinning agents?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please scroll and select an option</mat-label>
        <mat-select multiple formControlName="areYouOnAnyBloodThinningAgents">
          <mat-option value="No">No</mat-option>
          <mat-option value="Don't know">Don't know</mat-option>
          <mat-option value="Aspirin">Aspirin</mat-option>
          <mat-option value="Warfarin">Warfarin</mat-option>
          <mat-option value="Clopidogrel">Clopidogrel</mat-option>
          <mat-option value="Dipyridamole">Dipyridamole</mat-option>
          <mat-option value="Rivaroxiban">Rivaroxiban</mat-option>
          <mat-option value="Apixiban">Apixiban</mat-option>
          <mat-option value="Dabigatran">Dabigatran</mat-option>
          <mat-option value="Other">Other</mat-option>
        </mat-select>
        <mat-error *ngIf="areYouOnAnyBloodThinningAgents.touched">
          Please select if you are on any blood thinning agents
        </mat-error>
      </mat-form-field>
    </div>

    <div
      class="question-container"
      *ngIf="
        nucaForm
          .get('areYouOnAnyBloodThinningAgents')
          .value.indexOf('Other') !== -1
      "
    >
      <div class="half-width-field">
        <p>Blood thinning agents details</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <input
          matInput
          formControlName="bloodThinningAgentsDetails"
          type="notes"
          placeholder="Enter details here"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Do you have any blood-borne viruses?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please scroll and select an option</mat-label>
        <mat-select multiple formControlName="anyBloodborneViruses">
          <mat-option value="No">No</mat-option>
          <mat-option value="Don't know">Don't know</mat-option>
          <mat-option value="HIV">HIV</mat-option>
          <mat-option value="Hepatitis A">Hepatitis A</mat-option>
          <mat-option value="Hepatitis B">Hepatitis B</mat-option>
          <mat-option value="Hepatitis C">Hepatitis C</mat-option>
        </mat-select>
        <mat-error *ngIf="anyBloodborneViruses.touched">
          Please select if have any blood-borne viruses
        </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Are you pregnant or breast feeding?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="pregnantOrBreastfeeding">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
          <mat-option value="Don't know">Don't know</mat-option>
        </mat-select>
        <mat-error *ngIf="pregnantOrBreastfeeding.touched">
          Please select if you are pregnant or breast feeding
        </mat-error>
      </mat-form-field>
    </div>

    <mat-card>
      <h4>Consent to Clinical Photography</h4>

      <p>
        <strong
          ><u
            >Your photographs will be uploaded onto your secure clinical record
            in order that your case can be processed.</u
          ></strong
        >
      </p>
      <p>
        <em>Please tick the relevant box and then sign below</em>
      </p>

      <div class="question-container">
        <div class="half-width-field">
          <p>
            I consent to the production of clinical photographs/video recordings
            for
            <strong>my diagnosis and medical record</strong>
          </p>
        </div>
        <mat-form-field appearance="fill" class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select
            formControlName="consentToImagesOrRecordingsOnMyMedicalRecord"
          >
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <mat-error
            *ngIf="consentToImagesOrRecordingsOnMyMedicalRecord.touched"
          >
            Please select Yes or No
          </mat-error>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>
            I consent that these images can be used anonymously for teaching and
            research which may include training (including computer learning) to
            improve the diagnosis/management of skin conditions.
          </p>
        </div>
        <mat-form-field appearance="fill" class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="consentToImagesBeingUsedAnonymously">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <mat-error *ngIf="consentToImagesBeingUsedAnonymously.touched">
            Please select Yes or No
          </mat-error>
        </mat-form-field>
      </div>

      <mat-form-field
        appearance="fill"
        required
        class="notes-field half-width-field"
      >
        <mat-label>Please type your name below</mat-label>
        <input
          matInput
          formControlName="patientSignature"
          type="notes"
          placeholder="Patient signature"
          autocomplete="off"
        />
        <mat-error *ngIf="patientSignature.touched">
          Please enter your signature
        </mat-error>
      </mat-form-field>
    </mat-card>

    <div *ngIf="!valid()" style="margin-top: 20px">
      <span style="color: #ff8400; font-size: 18px">Incomplete fields.</span>
    </div>

    <button
      mat-raised-button
      class="positive-button"
      type="submit"
      [disabled]="!valid()"
    >
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
