<ng-container
  *ngIf="{
    captureOptions: captureOptions$ | async
  } as vm"
>
  <div class="field">
    <div class="collection-form">
      <form [formGroup]="submissionForm">
        <div class="field">
          <label for="capture" class="block mb-3">Submission of:</label>
          <div
            *ngIf="isMobile; else captureSelectButton"
            class="checkbox-select-button"
          >
            <div
              *ngFor="let option of vm.captureOptions"
              [class.checkbox-select-button-highlighted]="
                option.value === submissionForm.get('capture').value
              "
              (click)="submissionForm.get('capture').setValue(option.value)"
            >
              <p-radioButton
                [value]="option.value"
                formControlName="capture"
              ></p-radioButton>
              <label [for]="option.value">
                <i [class]="option.icon"></i>
                {{ option.label }}
              </label>
            </div>
          </div>
          <ng-template #captureSelectButton>
            <p-selectButton
              [options]="vm.captureOptions"
              formControlName="capture"
              optionValue="value"
              data-cy="submission-capture-types"
            >
              <ng-template let-item>
                <i [class]="item.icon" [ngClass]="{ 'pr-1': !isMobile }"></i>
                <span *ngIf="!isMobile"> {{ item.label }}</span>
              </ng-template>
            </p-selectButton>
          </ng-template>
        </div>
        <ng-container *ngIf="forms && forms.length > 0">
          <div class="w-full py-2">
            <label
              class="font-bold block py-2"
              for="form-selector"
              *ngIf="!templateUrlParams.teamId"
            >
              {{
                selectedFormIds?.length < 2 ? 'Default form' : 'Default forms'
              }}
              to be completed for this submission:
            </label>
            <div [ngClass]="{ 'flex align-items-center': !isMobile }">
              <div
                class="p-fluid"
                [ngClass]="{ 'flex-grow-1 pr-2': !isMobile }"
              >
                <p-multiSelect
                  name="form-selector"
                  [panelStyleClass]="!isMobile ? 'w-full' : 'max-w-20rem'"
                  [selectedItemsLabel]="'{0} items selected'"
                  [options]="forms | orderBy : 'pipe'"
                  [group]="true"
                  data-cy="collection-submission-form-dropdown"
                  optionGroupChildren="forms"
                  optionGroupLabel="teamName"
                  optionLabel="name"
                  optionValue="id"
                  appendTo="body"
                  [maxSelectedLabels]="2"
                  (onChange)="isFormChecked(); emitValues()"
                  placeholder="Select a form"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="selectedFormIds"
                  display="chip"
                >
                </p-multiSelect>
              </div>
              <div class="py-2">
                <button
                  pButton
                  type="button"
                  class="p-button-text"
                  *ngIf="selectedFormIds"
                  (click)="showForm()"
                  data-cy="submission-show-form"
                >
                  Show form<ng-container *ngIf="selectedFormIds.length > 1"
                    >s</ng-container
                  >
                </button>
              </div>
            </div>
          </div>

          <p-message
            severity="warn"
            *ngIf="!formChecked"
            text="A collection must have a form selected!"
          ></p-message>
        </ng-container>

        <ng-container *ngIf="!alertEmailHidden">
          <div class="field">
            <label class="font-bold block" for="email-text">
              Email address to be alerted for new submissions:
            </label>
            <input
              type="text"
              pInputText
              class="w-full"
              placeholder="team-name@nhs.net"
              formControlName="alertEmail"
              name="email-text"
            />
            <p class="pt-0 mt-0">
              Multiple emails can be added separated by commas(,)
            </p>
          </div>
          <ng-container *ngTemplateOutlet="emailErrors"></ng-container>
        </ng-container>
        <div *ngIf="isSelectFormFocused && usedAsModel" class="space-150"></div>
      </form>
    </div>
  </div>
</ng-container>

<ng-template #emailErrors>
  <div *ngIf="emailValidityLogs$ | async as emailValidityLogs">
    <span class="mb-0 p-error" *ngIf="emailValidityLogs.length === 1">
      The following email entered is invalid:
    </span>
    <span class="mb-0 p-error" *ngIf="emailValidityLogs.length > 1">
      The following emails entered are invalid:
    </span>
    <span class="p-error" *ngFor="let email of emailValidityLogs">
      {{ email }}
    </span>
  </div>
</ng-template>
