import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nhs'
})
export class NhsPipe implements PipeTransform {
  transform(nhsNumber: string): string {
    if (nhsNumber) {
      const nhsString = nhsNumber.toString();
      const nhs1 = nhsString.slice(0, 3);
      const nhs2 = nhsString.slice(3, 6);
      const nhs3 = nhsString.slice(6, 10);

      return nhs1 + ' ' + nhs2 + ' ' + nhs3;
    } else return;
  }
}
