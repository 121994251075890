<form [formGroup]="videoAndPhotoConsentForm">
  <div class="form-formatting">
    <mat-card>
      <mat-card-title>Video and Photo Consent Form</mat-card-title>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Name of Child</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="text" formControlName="childName" />
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <p>
          As part of <strong>{{ childName }}'s</strong> ongoing clinical support, we would like to
          make use of Isla to capture videos/photos for the duration of these sessions within the
          school setting. This may apply to multiple videos and photos captured over a period of a
          patient’s episode of care.
        </p>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Your permission is required for this recording to happen:</p>
          </div>
          <mat-radio-group class="half-width-field" appearance="fill" formControlName="permission">
            <mat-label>Please select one:</mat-label><br />
            <mat-radio-button value="Yes">I <strong>do</strong> give permission</mat-radio-button
            ><br />
            <mat-radio-button value="No"
              >I <strong>do not</strong> give permission</mat-radio-button
            >
            <p>
              For my child <strong>{{ childName }}</strong> to be video recorded for clinical
              purposes only.
            </p>
          </mat-radio-group>
        </div>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Date</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="date" formControlName="dateOfConsent" />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Relationship to child</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="relationshipToChild">
              <div *ngFor="let response of relationshipReasponse">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Name Printed</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="text" formControlName="parentOrGuardianName" />
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-card>
      <mat-card-content>
        <p>
          For more infomation about ISLA and how we look after your privacy, please
          <a href="https://www.islacare.co.uk/privacy/">click here</a>
        </p>
      </mat-card-content>
    </mat-card>

    <br />
    <mat-form-field class="full-width-field" appearance="fill">
      <mat-label>Any other comments?</mat-label>
      <textarea matInput formControlName="notes" type="notes"> </textarea>
    </mat-form-field>
    <br />
    <button
      mat-raised-button
      class="positive-button"
      type="submit"
      [disabled]="videoAndPhotoConsentForm.invalid || isSubmitting"
      (click)="onSubmit()"
    >
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
