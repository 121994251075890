<ng-container *ngIf="targetFolder$ | async as targetFolder">
    <p data-cy="confirmation-dialog-msg" class="font-semibold">{{ data.message }}</p>
    <ng-container>
      <div class="field">
        <label class="block font-normal" for="form-selector">Folder</label>
        <div [ngClass]="{ 'flex align-items-center': !isMobile }">
          <div class="p-fluid" [ngClass]="{ 'flex-grow-1 pr-2': !isMobile }">
            <p-dropdown
            class="dropdown-move-entries"
            [options]="targetFolder"
            placeholder="Select folder"
            [group]="true"
            [(ngModel)]="selectedFolderLabel"
          >
            <ng-template let-option pTemplate="group">
              <div >
                <span>{{ option.label }}</span>
              </div>
            </ng-template>
          </p-dropdown>
          
          </div>
        </div>
        <div class="mt-3">
          <p-messages severity="warn" styleClass="banner-sm">
            <ng-template pTemplate>
              <div class="flex gap-2 align-items-center">
                <i class="pi pi-exclamation-triangle text-xl "></i>
                <span class="font-bold ">Warning</span>
                <span>
                  These items will not be duplicated. Once moved the item will no longer be visible in the original folder.
                </span>
              </div>
            </ng-template>
          </p-messages>
        </div>
      </div>
    </ng-container>
    <div class="flex justify-content-end gap-2 mt-3 w-full">
      <button
        [label]="data.negativeButton"
        (click)="closeDialog()"
        class="p-button-outlined"
        aria-label="Close"
        type="button"
        pButton
      ></button>
      <button
        [label]="data.positiveButton"
        (click)="moveEntriesBetweenFoldersConfirmation()"
        icon="pi pi-folder"
        data-cy="confirmationConfirm"
        aria-label="Confirm"
        pButton
      ></button>
    </div>
  </ng-container>