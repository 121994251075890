import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { FormField } from '@islacare/ic-types';

@Component({
  selector: 'ic-automatic-textarea',
  templateUrl: './automatic-textarea.component.html',
  styleUrls: ['./automatic-textarea.component.scss'],
})
export class AutomaticTextareaComponent {
  @Input() field: FormField;
  @Input() control: UntypedFormControl;

  constructor(public formService: FormService) {}
}
