import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormService, UsersService } from '@ic-monorepo/services';
import {
  CalculationParams,
  ClinicalForm,
  ClinicalFormField,
  ClinicalFormStatus,
  ClinicalFormWrapper,
  Created,
  CreatedBy,
  FormGraphConfigs,
  UserWithId
} from '@islacare/ic-types';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Timestamp } from 'firebase/firestore';
import { cloneDeep } from 'lodash-es';
import { DialogService } from 'primeng/dynamicdialog';
import { EMPTY, Observable, Subject, switchMap, take, tap } from 'rxjs';
import { SaveFormDialogComponent } from '../../dialogs/save-form-dialog/save-form-dialog.component';
import { EditorField, fieldEditorForm } from '../../forms/field-editor.form';
import { fieldGroupEditorForm } from '../../forms/field-group-editor.form';
import { newFormlyForm } from '../../forms/new-formly-form';
import { sectionEditorForm } from '../../forms/section-editor.form';

export interface CurrentlyEditingRef {
  title: string;
  data: FormlyFieldConfig;
  targetObject: FormlyFieldConfig;
  form: FormlyFieldConfig[];
}

export interface DuplicateFieldRef {
  targetObject: FormlyFieldConfig;
  insertBefore: boolean;
  objectToInsert: FormlyFieldConfig;
}

export interface AddFieldRef {
  targetObject: FormlyFieldConfig;
  insertBefore: boolean;
  objectToInsert: FormlyFieldConfig;
}

export interface SaveFormDetails {
  name: string;
  status: ClinicalFormStatus;
  isPublic: boolean;
  description: string;
  calculationDescription: string;
  calculationEnabled: boolean;
  calculationReferences: string;
  calculationValidated: boolean;
  graphConfigsDefaultKeys: string[];
  graphConfigsEnabled: boolean;
}

export interface SavedFormlyFieldConfig {
  key?: string;
  type?: ClinicalFormField;
  props?: {
    [property: string]: string;
  };
  wrappers?: ClinicalFormWrapper[];
  expressions?: {
    [property: string]: string;
  };
  fieldArray?: { fieldGroup: SavedFormlyFieldConfig[] };
  fieldGroup?: SavedFormlyFieldConfig[];
  defaultValue?: any[];
}

@Injectable({
  providedIn: 'root'
})
export class ClinicalFormsEditService {
  form: FormlyFieldConfig[];
  user: UserWithId;

  constructor(
    private dialogService: DialogService,
    private usersService: UsersService,
    private db: AngularFirestore,
    private formService: FormService
  ) {
    this.usersService.me$
      .pipe(
        take(1),
        tap(user => {
          this.user = user;
        })
      )
      .subscribe();
  }

  get currentlyEditing$(): Observable<CurrentlyEditingRef | null> {
    return this._currentlyEditing$.asObservable();
  }

  get addField$(): Observable<AddFieldRef | null> {
    return this._addField$.asObservable();
  }

  get addDuplicateField$(): Observable<DuplicateFieldRef | null> {
    return this._duplicateField$.asObservable();
  }

  private _currentlyEditing$: Subject<CurrentlyEditingRef | null> =
    new Subject();
  private _addField$: Subject<AddFieldRef | null> = new Subject();
  private _duplicateField$: Subject<DuplicateFieldRef | null> = new Subject();

  addSection(section: FormlyFieldConfig, insertBefore = true): void {
    this._addField$.next({
      targetObject: section,
      insertBefore,
      objectToInsert: {
        wrappers: [ClinicalFormWrapper.SECTION],
        fieldGroup: [
          {
            wrappers: [ClinicalFormWrapper.GROUP],
            fieldGroup: [
              {
                type: ClinicalFormField.TEXT,
                props: { label: 'Newly added field' }
              }
            ]
          }
        ]
      }
    });
  }

  editSection(section: FormlyFieldConfig): void {
    this._currentlyEditing$.next({
      title: 'Section editor',
      data: { ...section },
      targetObject: section,

      form: sectionEditorForm
    });
  }

  addGroup(group: FormlyFieldConfig, insertBefore = true): void {
    this._addField$.next({
      targetObject: group,
      insertBefore,
      objectToInsert: {
        wrappers: [ClinicalFormWrapper.GROUP],
        fieldGroup: [
          {
            type: ClinicalFormField.TEXT,
            props: { label: 'Newly added field' }
          }
        ]
      }
    });
  }

  editGroup(group: FormlyFieldConfig): void {
    this._currentlyEditing$.next({
      title: 'Section editor',
      data: { ...group },
      targetObject: group,
      form: fieldGroupEditorForm
    });
  }

  addField(field: FormlyFieldConfig, insertBefore = true): void {
    this._addField$.next({
      targetObject: field,
      insertBefore,
      objectToInsert: {
        type: ClinicalFormField.TEXT,
        props: { label: 'Newly added field' }
      }
    });
  }

  addDuplicateField(field: FormlyFieldConfig, insertBefore = true): void {
    field = this.mapExpressionsForFieldEditor(field);
    this._duplicateField$.next({
      targetObject: field,
      insertBefore,
      objectToInsert: {
        type: field.type,
        props: field.props
      }
    });
  }

  editField(field: FormlyFieldConfig): void {
    field = this.mapExpressionsForFieldEditor(field);
    this._currentlyEditing$.next({
      title: 'Field editor',
      data: { ...field },
      targetObject: { ...field },
      form: fieldEditorForm
    });
  }

  closeSidebar(): void {
    this._currentlyEditing$.next(null);
  }

  setUpFormDocDetails(
    formDetails: SaveFormDetails,
    clonedForm: FormlyFieldConfig[],
    teamId: string,
    formId: string
  ): Promise<void> {
    const formIdToUse = formId ? formId : this.db.createId(); // if existing formId use that else create a new formId
    const fieldConfigToSave = this.cleanFieldConfigReadyForSaving(
      cloneDeep(clonedForm)
    );

    const formData: ClinicalForm = {
      id: formIdToUse,
      name: formDetails.name,
      description: formDetails.description,
      status: formDetails?.status ?? null,
      fieldConfig: fieldConfigToSave,
      created: this.getCreatedData(teamId),
      isPublic: formDetails.isPublic,
      graphConfigs: this.getGraphConfigs(formDetails),
      calculationParams: this.getCalculationParams(formDetails)
    };

    /// if existing formId update the form doc else create a new doc
    const dbDoc = this.db.doc<ClinicalForm>(`formData/${formIdToUse}`);
    return formId ? dbDoc.update(formData) : dbDoc.set(formData);
  }

  async openSaveFormDialog(
    clinicalFormFieldConfig: FormlyFieldConfig[],
    teamId: string,
    formId: string
  ): Promise<void> {
    const existingClinicalFormDetails = (
      await this.formService.getFormlyFormSnapshotFromFormData(formId)
    ).data();

    const dialogRef = this.dialogService.open(SaveFormDialogComponent, {
      header: 'Review and save form',
      width: '70%',
      data: {
        clinicalFormFieldConfig,
        existingClinicalFormDetails
      }
    });
    dialogRef.onClose
      .pipe(
        switchMap(result =>
          result
            ? this.setUpFormDocDetails(
              result,
              clinicalFormFieldConfig,
              teamId,
              formId
            )
            : EMPTY
        )
      )
      .subscribe();
  }

  async getFormFieldConfigs(formId: string): Promise<FormlyFieldConfig[]> {
    const form = (
      await this.formService.getFormlyFormSnapshotFromFormData(formId)
    ).data();
    const result = formId ? form?.fieldConfig : newFormlyForm;

    return result as FormlyFieldConfig[];
  }

  cleanFieldConfigReadyForSaving(
    fields?: FormlyFieldConfig[]
  ): SavedFormlyFieldConfig[] {
    return (fields ?? []).map(field =>
      this.mapFormlyFieldConfigToSavedFormlyfieldConfig(field)
    );
  }

  mapFormlyFieldConfigToSavedFormlyfieldConfig(
    field: FormlyFieldConfig
  ): SavedFormlyFieldConfig {
    if (!field) return {};

    const { props, wrappers, type, key, fieldArray, expressions, fieldGroup } =
      field;

    const savedField: SavedFormlyFieldConfig = {};

    if (key) {
      savedField.key = field.key as string;
    }

    if (type) {
      savedField.type = field.type as ClinicalFormField;
    }

    if (type === ClinicalFormField.TABLE) {
      savedField.defaultValue = [{}];
    }

    if (wrappers?.length) {
      savedField.wrappers = wrappers as ClinicalFormWrapper[];
    }

    for (const property of Object.values(EditorField)) {
      if (property.startsWith('props.')) {
        const propName = property.replace('props.', '');
        if (props && propName in props && props?.[propName]) {
          if (!savedField?.props) savedField.props = {};

          savedField.props[propName] = props[propName];
        }
      }

      if (property.startsWith('expressions.')) {
        const propName = property.replace('expressions.', '');
        const nestedPropValue = this.findNestedObject(expressions, propName);
        if (nestedPropValue) {
          if (!savedField?.expressions) savedField.expressions = {};

          savedField.expressions[propName] = nestedPropValue as string;
        }
      }
    }

    if (
      fieldArray &&
      'fieldGroup' in fieldArray &&
      fieldArray?.fieldGroup?.length
    ) {
      savedField.fieldArray = {
        fieldGroup: this.cleanFieldConfigReadyForSaving(fieldArray.fieldGroup)
      };
    }

    if (fieldGroup && type !== ClinicalFormField.TABLE && fieldGroup?.length) {
      savedField.fieldGroup = this.cleanFieldConfigReadyForSaving(fieldGroup);
    }

    return savedField;
  }

  private findNestedObject(obj: any, path: string): any {
    if (obj?.[path]) return obj[path];

    const keys = path.split('.');
    let nestedObj = obj;

    for (const key of keys) {
      if (nestedObj && typeof nestedObj === 'object' && key in nestedObj) {
        nestedObj = nestedObj[key];
      } else {
        return undefined; // Return undefined if the key is not found at any level
      }
    }

    return nestedObj;
  }

  getCreatedData(teamId: string): Created {
    const date = new Date();
    const seconds = Math.floor(date.getTime() / 1000);
    const created: Created = {
      by: CreatedBy.USER,
      creatorId: this.user.id,
      at: new Timestamp(seconds, 0),
      organisationId: this.user.organisationId,
      teamId: teamId,
      email: this.user.email
    };
    return created;
  }

  getGraphConfigs(formDetails: SaveFormDetails): FormGraphConfigs {
    const graphConfigs: FormGraphConfigs = {
      defaultKeysEnabled: formDetails?.graphConfigsDefaultKeys ?? [],
      enabled: formDetails?.graphConfigsEnabled ?? false
    };
    return graphConfigs;
  }

  getCalculationParams(formDetails: SaveFormDetails): CalculationParams {
    const calculationParams: CalculationParams = {
      calculationReference: formDetails?.calculationReferences ?? '',
      description: formDetails?.calculationDescription ?? '',
      hasCalculation: formDetails?.calculationEnabled ?? false,
      validated: formDetails?.calculationValidated ?? false
    };
    return calculationParams;
  }

  getKeysFromForm(fieldConfigs: FormlyFieldConfig[]): string[] {
    return fieldConfigs.reduce((keys: string[], field: FormlyFieldConfig) => {
      if (field.key) {
        keys.push(field.key.toString());
      }

      if (field?.fieldGroup?.length) {
        const subFieldKeys = this.getKeysFromForm(field.fieldGroup);
        keys.push(...subFieldKeys);
      }

      return keys;
    }, []);
  }

  private mapExpressionsForFieldEditor(
    field: FormlyFieldConfig
  ): FormlyFieldConfig {
    const { expressions } = field as any;

    if (expressions?.['props.calculationValue']) {
      return {
        ...field,
        expressions: {
          ...expressions,
          props: { calculationValue: expressions['props.calculationValue'] }
        }
      };
    }

    return field;
  }
}
