export default [
  // // PHQ
  // 'PHQ',
  // "littleInterestOrPleasure",
  // "feelingDownDepressedOrHopeless",
  // "troubleSleeping",
  // "feelingTiredOrLittleEnergy",
  // "poorAppetiteOrOvereating",
  // "feelingBadAboutYourself",
  // "troubleConcentratingOnThings",
  // "movingOrSpeakingSlowerOrFasterThanNormal",
  // "negativeThoughtsTowardsYourself",
  // "howDifficultHaveTheseProblemsMadeThings",
  // "phqTotalScore",
  // GAD7
  'feelingNervousAnxiousOrOnEdge',
  'notBeingAbleToStopOrControlWorrying',
  'worryingTooMuchAboutDifferentThings',
  'troubleRelaxing',
  'beingSoRestlessThatItIsHardToSitStill',
  'becomingEasilyAnnoyedOrIrritable',
  'feelingAfraidAsIfSomethingAwfulMightHappen',
  'howDifficultHaveTheseProblemsMadeThings',
  'gad7TotalScore'
];
