import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemCheckService } from '@ic-monorepo/shared-common';
import {
  CaptureType,
  Collection,
  GroupedFormWithTeamReference,
  MessageMedium,
  TeamWithId,
  TemplateUrlParams
} from '@islacare/ic-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { PatientRecordService } from '../../../feature-patient-record/services/patient-record/patient-record.service';
import { FileTypeHelper } from '../../../utils/helpers/file-type/file-type.helper';
import { multipleEmailValidityLogs } from '../../../utils/multipleEmailValidityLogs';
import { FormService } from '../../services/form/form.service';
import { UsersService } from '../../services/users/users.service';
import { multipleEmailValidator } from '../../validators/multiple-emails.validator';
import { CaptureTypeOptions } from '../submission/submission.component';

const { minLength } = Validators;

interface PrimeSelectButtonOptions {
  label: string;
  value: CaptureType | string;
  icon: string;
}

@UntilDestroy()
@Component({
  selector: 'ic-request',
  templateUrl: './request.component.html'
})
export class RequestComponent
  extends FileTypeHelper
  implements OnInit, OnChanges
{
  @Input() collection: Collection;
  @Input() teamId: string;
  @Input() template;
  @Input() alertEmailHidden: boolean;
  @Input() contentHidden: boolean;
  @Input() showLinkLengthHelp: boolean;
  @Output() requestTypeEvent = new EventEmitter<any>();

  MessageMedium = MessageMedium;

  requestForm = this.fb.group({
    smsBody: ['', [minLength(2)]],
    capture: [CaptureType.PHOTO],
    reminder: ['', [Boolean]],
    alertEmail: ['', [minLength(2), multipleEmailValidator]],
    keepLinkSevenDays: ['false'],
    contactMethod: [MessageMedium.SMS],
    isMediaOptional: [false]
  });

  selectedFormIds: string[] = ['default'];
  selectedTemplateId: string;
  setDate: Date;
  forms$: Observable<GroupedFormWithTeamReference[]> = of([]);
  forms: GroupedFormWithTeamReference[] = [];
  team$: Observable<TeamWithId>;
  selectedPhoneNumber: string;
  smsIntro: string;
  recipientKeys: string[];
  isSubmitting = false;
  formChecked = false;
  requestDetailsSet = false;
  originalSelectedTeamId: string;
  templateUrlParams: TemplateUrlParams;
  myOrganisation;
  CaptureType = CaptureType;

  collectionAuthId: string;
  requestId: string;
  captureOptions$: Observable<PrimeSelectButtonOptions[]>;
  contactMethodOptions: PrimeSelectButtonOptions[];
  keepLinkSevenDaysOptions: PrimeSelectButtonOptions[];
  emailValidityLogs$: Observable<{ [key: string]: boolean }[] | []>;
  isMobile = this.systemCheck.isMobile;

  constructor(
    private fb: UntypedFormBuilder,
    private formService: FormService,
    private router: Router,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private db: AngularFirestore,
    private systemCheck: SystemCheckService,
    private patientRecordService: PatientRecordService
  ) {
    super();
    this.route.params.subscribe(params => {
      this.templateUrlParams = params as TemplateUrlParams;
    });
  }

  async ngOnInit(): Promise<void> {
    this.captureOptions$ = this.patientRecordService
      .getCaptureOptions$()
      .pipe(
        map((captureTypeOptions: CaptureTypeOptions[]) =>
          captureTypeOptions.filter(
            (captureTypeOptions: CaptureTypeOptions) =>
              captureTypeOptions.value !== CaptureType.FILE_UPLOAD
          )
        )
      );
    this.collectionAuthId = this.db.createId();
    this.requestId = this.db.createId();
    this.myOrganisation = await this.usersService.myOrganisation$
      ?.pipe(take(1))
      .toPromise();

    if (this.collection) {
      const { formIds } = this.collection;
      this.selectedFormIds = formIds || ['default'];
    }

    if (this.collection && this.collection.request) {
      const { capture, formIds, alertEmail, teamId, isMediaOptional } =
        this.collection.request;

      let contactMethod = MessageMedium.SMS;
      const collectionContactMethod = this.collection.request?.contactMethod;
      if (
        (collectionContactMethod === MessageMedium.EMAIL ||
          collectionContactMethod === 'sendByEmail') &&
        this.myOrganisation?.featureFlags?.functionality?.emailEnabled
      )
        contactMethod = MessageMedium.EMAIL;

      let keepLinkSevenDays = this.collection.request?.keepLinkSevenDays
        ?.valueOf()
        .toString();
      if (!keepLinkSevenDays) keepLinkSevenDays = 'false'; // if keepLinkSevenDays was empty or undefined

      let smsBody = this.collection.request?.smsBody;
      if (!smsBody) smsBody = this.collection.smsBody || '';

      this.requestForm.patchValue({
        capture: capture ? capture : 'photo',
        smsBody,
        alertEmail,
        keepLinkSevenDays,
        contactMethod,
        isMediaOptional
      });
      this.selectedFormIds = formIds;
      this.teamId = teamId;
      this.originalSelectedTeamId = teamId;
    }

    this.setupControls();
    this.checkForOldCaptureValue();
    this.getFormSelection();
    this.isFormChecked();
    this.emitValues();
    this.onFormChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.template) {
      this.teamId = this.template.teamId;
    }
    this.getFormSelection();

    if (this.template) {
      if (this.router.url.split('/').includes('edit')) {
        // sets the template data on the collection on the collection edit page
        if (changes['template']) {
          this.setRequestDetailsFromTemplate();
        }
      } else {
        // sets template data on the collection on the collection create & template page
        if (changes['template'] && changes['template'].currentValue) {
          this.setRequestDetailsFromTemplate();
        }
      }

      const alertEmail = this.requestForm.controls.alertEmail.value;
      this.requestDetailsSet = !!alertEmail;
    } else if (this.teamId) {
      this.selectedFormIds = ['default'];
      this.selectedTemplateId = null;
      this.requestForm.patchValue({
        alertEmail: '',
        smsBody: '',
        keepLinkSevenDays: 'false'
      });
      this.getFormSelection();
    }

    this.emitValues();
  }

  get selectedFileType(): string {
    const fileType = this.requestForm.get('capture').value;
    return this.getCaptureTypeFriendly(fileType);
  }

  setRequestDetailsFromTemplate() {
    const { id, smsBody, alertEmail, formIds, request, isMediaOptional } =
      this.template;

    this.selectedFormIds = formIds || ['default'];
    this.selectedTemplateId = id;

    let keepLinkSevenDays = request?.keepLinkSevenDays?.valueOf().toString();
    if (!keepLinkSevenDays) keepLinkSevenDays = 'false'; // if keepLinkSevenDays was empty or undefined

    this.requestForm.patchValue({
      alertEmail: alertEmail,
      smsBody: smsBody,
      isMediaOptional: !!isMediaOptional
    });
    if (request) {
      this.requestForm.patchValue(request);
      this.requestForm.patchValue({ keepLinkSevenDays: keepLinkSevenDays });

      if (request.formIds) {
        this.selectedFormIds = request.formIds;
      }
    }
    this.getFormSelection();
  }

  get alertEmail() {
    return this.requestForm.get('alertEmail');
  }

  get capture() {
    return this.requestForm.get('capture');
  }

  get daysToAddToLinkExpiry() {
    let days = 0;
    if (this.requestForm.get('keepLinkSevenDays').value === 'true') {
      days = 6;
    }
    return days;
  }

  get dateLinkExpires() {
    const date = new Date();
    date.setDate(date.getDate() + this.daysToAddToLinkExpiry);
    const dateString = date.toLocaleString('en-GB', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
    return dateString;
  }

  getFormSelection(): void {
    if (this.teamId) {
      this.forms$ = this.formService.getFormsWithTeam(this.teamId);
    } else if (this.templateUrlParams.teamId) {
      this.forms$ = this.formService.getFormsWithTeam(
        this.templateUrlParams.teamId
      );
    }
    this.forms$
      .pipe(
        untilDestroyed(this),
        tap(forms => (this.forms = this.getAllItems(forms)))
      )
      .subscribe();
  }

  isFormChecked(): void {
    if (!this.selectedFormIds) this.selectedFormIds = ['default'];
    this.formChecked = this.selectedFormIds?.length >= 1;
  }

  showForm(): void {
    this.formService.showForm(
      this.selectedFormIds,
      this.teamId,
      'Form to be completed for all submissions into this collection'
    );
  }

  onFormChanges(): void {
    this.requestForm.valueChanges.subscribe(() => {
      this.emitValues();
    });
  }

  emitValues(): void {
    if (this.requestForm.controls.alertEmail.value === undefined) {
      this.requestForm.patchValue({ alertEmail: '' });
    }

    this.emailValidityLogs$ = multipleEmailValidityLogs(
      this.requestForm.controls['alertEmail'].errors?.emailValidityLogs
    );
    this.requestTypeEvent.emit({
      ...this.requestForm.value,
      alertEmailErrors: this.requestForm.controls['alertEmail'].errors,
      collectionAuthId: this.collectionAuthId,
      requestId: this.requestId,
      formIds: this.selectedFormIds || ['default'],
      teamId: this.teamId,
      keepLinkSevenDays:
        this.requestForm.controls.keepLinkSevenDays.value === 'true',
      captureType: this.requestForm.controls.capture.value,
      isMediaOptional: this.isMediaOptionValue
    });
  }

  get isMediaOptionValue(): boolean {
    if (this.requestForm.controls.capture.value === 'form') {
      return false;
    }
    return !!this.requestForm.controls.isMediaOptional.value;
  }

  setupControls(): void {
    this.contactMethodOptions = [
      {
        label: 'Text message',
        value: MessageMedium.SMS,
        icon: 'pi pi-mobile'
      }
    ];

    if (this.myOrganisation?.featureFlags?.functionality?.emailEnabled) {
      this.contactMethodOptions.push({
        label: 'Email',
        value: MessageMedium.EMAIL,
        icon: 'pi pi-envelope'
      });
    }

    if (this.contentHidden) {
      this.contactMethodOptions.push({
        label: 'QR code',
        value: 'qrCode',
        icon: 'pi pi-qrcode'
      });
    }

    this.keepLinkSevenDaysOptions = [
      {
        label: 'At midnight',
        value: 'false',
        icon: 'pi pi-moon'
      },
      {
        label: 'After seven days',
        value: 'true',
        icon: 'pi pi-calendar-plus'
      }
    ];
  }

  private checkForOldCaptureValue(): void {
    //this checks if captureType value is the old depricated version
    if (
      this.requestForm.controls.capture.value === 'entry' ||
      this.requestForm.controls.capture.value === 'capture'
    )
      this.requestForm.controls.capture.setValue(CaptureType.PHOTO);
  }

  /**
   * @description Get the updated form array with default option
   * @param forms
   * @returns
   */
  getAllItems(
    forms: GroupedFormWithTeamReference[]
  ): GroupedFormWithTeamReference[] {
    return this.formService.getUpdatedFormsArray(forms);
  }
}
