export default [
  'dateOfSurgery',
  'surgicalCategory',
  'healingByPrimaryIntention',
  'cabgSurgicalWound',
  'surgicalSiteInfection',
  'chestCurrentMicrobiologyResults',
  'classification',
  'surgicalWoundDehiscence',
  'depthOfDehiscence',
  'spontaneousDehiscenceReason',
  'criteriaForSSIs',
  'detection',
  'submammarySurgicalSiteInfection',
  'submammaryCurrentMicrobiologyResults',
  'submammaryClassification',
  'submammarySurgicalWoundDehiscence',
  'submammaryDepthOfDehiscence',
  'submammarySpontaneousDehiscenceReason',
  'submammaryDehiscenceDateOfOnset',
  'submammaryCriteriaForSSIs',
  'submammaryDetection',
  'leftLegSurgicalSiteInfection',
  'leftLegCurrentMicrobiologyResults',
  'leftLegClassification',
  'leftLegSurgicalWoundDehiscence',
  'leftLegDepthOfDehiscence',
  'leftLegSpontaneousDehiscenceReason',
  'leftLegDehiscenceDateOfOnset',
  'leftLegCriteriaForSSIs',
  'leftLegDetection',
  'rightLegSurgicalSiteInfection',
  'rightLegCurrentMicrobiologyResults',
  'rightLegClassification',
  'rightLegSurgicalWoundDehiscence',
  'rightLegDepthOfDehiscence',
  'rightLegSpontaneousDehiscenceReason',
  'rightLegDehiscenceDateOfOnset',
  'rightLegCriteriaForSSIs',
  'rightLegDetection',
  'leftArmSurgicalSiteInfection',
  'leftArmCurrentMicrobiologyResults',
  'leftArmClassification',
  'leftArmSurgicalWoundDehiscence',
  'leftArmDepthOfDehiscence',
  'leftArmSpontaneousDehiscenceReason',
  'leftArmDehiscenceDateOfOnset',
  'leftArmCriteriaForSSIs',
  'leftArmDetection',
  'rightArmSurgicalSiteInfection',
  'rightArmCurrentMicrobiologyResults',
  'rightArmClassification',
  'rightArmSurgicalWoundDehiscence',
  'rightArmDepthOfDehiscence',
  'rightArmSpontaneousDehiscenceReason',
  'rightArmDehiscenceDateOfOnset',
  'rightArmCriteriaForSSIs',
  'rightArmDetection',
  'drainSiteSurgicalSiteInfection',
  'drainSiteCurrentMicrobiologyResults',
  'drainSiteClassification',
  'drainSiteSurgicalWoundDehiscence',
  'drainSiteDepthOfDehiscence',
  'drainSiteSpontaneousDehiscenceReason',
  'drainSiteDehiscenceDateOfOnset',
  'drainSiteCriteriaForSSIs',
  'drainSiteDetection',
  'rightHipSurgicalWoundDehiscence',
  'rightHipDepthOfDehiscence',
  'rightHipSpontaneousDehiscenceReason',
  'rightHipDehiscenceDateOfOnset',
  'rightHipCriteriaForSSIs',
  'rightHipSurgicalSiteInfection',
  'rightHipCurrentMicrobiologyResults',
  'rightHipClassification',
  'rightHipDetection',
  'leftHipSurgicalWoundDehiscence',
  'leftHipDepthOfDehiscence',
  'leftHipSpontaneousDehiscenceReason',
  'leftHipDehiscenceDateOfOnset',
  'leftHipCriteriaForSSIs',
  'leftHipSurgicalSiteInfection',
  'leftHipCurrentMicrobiologyResults',
  'leftHipClassification',
  'leftHipDetection',
  'rightKneeSurgicalWoundDehiscence',
  'rightKneeDepthOfDehiscence',
  'rightKneeSpontaneousDehiscenceReason',
  'rightKneeDehiscenceDateOfOnset',
  'rightKneeCriteriaForSSIs',
  'rightKneeSurgicalSiteInfection',
  'rightKneeCurrentMicrobiologyResults',
  'rightKneeClassification',
  'rightKneeDetection',
  'leftKneeSurgicalWoundDehiscence',
  'leftKneeDepthOfDehiscence',
  'leftKneeSpontaneousDehiscenceReason',
  'leftKneeDehiscenceDateOfOnset',
  'leftKneeCriteriaForSSIs',
  'leftKneeSurgicalSiteInfection',
  'leftKneeCurrentMicrobiologyResults',
  'leftKneeClassification',
  'leftKneeDetection',
  'generalSurgicalWoundDehiscence',
  'generalWoundDepthOfDehiscence',
  'generalWoundSpontaneousDehiscenceReason',
  'generalDehiscenceDateOfOnset',
  'generalWoundCriteriaForSSIs',
  'generalWoundSurgicalSiteInfection',
  'generalWoundCurrentMicrobiologyResults',
  'generalWoundClassification',
  'generalWoundDetection',
  'impacts',
  'notes'
];
