import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-pain-bpi-interference-form',
  templateUrl: './pain-bpi-interference-form.component.html',
  styleUrls: ['./pain-bpi-interference-form.component.scss'],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class BpiInterferenceScaleFormComponent implements OnInit {
  @Input() data;
  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  bpiInterferenceScaleForm: UntypedFormGroup;
  isSubmitting = false;
  isLinear = true;
  low = true;
  medium = false;
  high = false;
  painDescriptor: string;
  neuroPainSymptomTotalAnswer: number;
  sliderMin = 0;
  sliderMax = 10;
  step = '1';
  tickInterval = '1';

  ngOnInit(): void {
    this.bpiInterferenceScaleForm = this.fb.group({
      generalActivitySlider: [''],
      moodSlider: [''],
      walkingAbilitySlider: [''],
      normalWorkSlider: [''],
      relationshipsWithOtherPeopleSlider: [''],
      sleepSlider: [''],
      enjoymentOfLifeSlider: [''],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.bpiInterferenceScaleForm.controls[key])
          this.bpiInterferenceScaleForm.controls[key].setValue(formData[key]);
      });
    });
  }

  onSubmit() {
    const formResponse = {
      ...this.bpiInterferenceScaleForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }
}
