<form [formGroup]="nucaReferralForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <h2>Phototherapy Referral</h2>

    <!-- PATIENT INFO -->
    <mat-card class="mat-card">
      <h3 style="text-align: center">Patient information</h3>
      <div class="question-container">
        <div class="half-width-field">
          <p>Current Location</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="currentLocation">
            <mat-option value="ChelWest">ChelWest</mat-option>
            <mat-option value="WestMid">WestMid</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>Current Consultant</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please enter a consultant</mat-label>
          <input matInput formControlName="currentConsultant" type="notes" autocomplete="off" />
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>Referring Clinician</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please enter a clinician</mat-label>
          <input matInput formControlName="currentClinician" type="notes" autocomplete="off" />
        </mat-form-field>
      </div>
    </mat-card>

    <!-- TREATMENT REQUEST -->
    <mat-card class="mat-card">
      <h3 style="text-align: center">Treatment Request</h3>

      <div class="question-container">
        <div class="half-width-field">
          <p>Priority</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="priority">
            <mat-option value="Urgent">Urgent</mat-option>
            <mat-option value="Routine">Routine</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>Skin type</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="skinType">
            <mat-option value="1">1</mat-option>
            <mat-option value="2">2</mat-option>
            <mat-option value="3">3</mat-option>
            <mat-option value="4">4</mat-option>
            <mat-option value="5">5</mat-option>
            <mat-option value="6">6</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>Type of light</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="typeOfLight">
            <mat-option value="Narrowband UV TL01">Narrowband UV TL01</mat-option>
            <mat-option value="Hand and/or foot PUVA">Hand and/or foot PUVA</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>Psoralens</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="psoralens">
            <mat-option value="Oral 8-MOP">Oral 8-MOP</mat-option>
            <mat-option value="Oral 5-MOP">Oral 5-MOP</mat-option>
            <mat-option value="Soak 8-MOP">Soak 8-MOP</mat-option>
            <mat-option value="N/A">N/A</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>Site</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="site">
            <mat-option value="Whole Body">Whole Body</mat-option>
            <mat-option value="Face">Face</mat-option>
            <mat-option value="Hands and/or Feet">Hands and/or Feet</mat-option>
            <mat-option value="Other">Other</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="question-container" *ngIf="nucaReferralForm.get('site').value === 'Other'">
        <div class="half-width-field">
          <p>Site details</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please enter details</mat-label>
          <input matInput formControlName="siteDetails" type="notes" autocomplete="off" />
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>Diagnosis</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="diagnosis">
            <mat-option value="Acne">Acne</mat-option>
            <mat-option value="Alopecia Areata">Alopecia Areata</mat-option>
            <mat-option value="Atopic Eczema">Atopic Eczema</mat-option>
            <mat-option value="Chronic Urticaria">Chronic Urticaria</mat-option>
            <mat-option value="Contact Dermatitis">Contact Dermatitis</mat-option>
            <mat-option value="Granuloma Annulare">Granuloma Annulare</mat-option>
            <mat-option value="GVHD">GVHD</mat-option>
            <mat-option value="Hyperkeratotic Eczema">Hyperkeratotic Eczema</mat-option>
            <mat-option value="Lichen Planus">Lichen Planus</mat-option>
            <mat-option value="Mycosis Fungoides (or Pre)">Mycosis Fungoides (or Pre)</mat-option>
            <mat-option value="Nodular Prurigo">Nodular Prurigo</mat-option>
            <mat-option value="Other Dermatitis">Other Dermatitis</mat-option>
            <mat-option value="Palmar Plantar Pustulosis">Palmar Plantar Pustulosis</mat-option>
            <mat-option value="Pityriasis Lichenoids Chronica"
              >Pityriasis Lichenoids Chronica</mat-option
            >
            <mat-option value="Polymorphic Light Eruption">Polymorphic Light Eruption</mat-option>
            <mat-option value="Pompholyx">Pompholyx</mat-option>
            <mat-option value="Pruritis">Pruritis</mat-option>
            <mat-option value="Psoriasis">Psoriasis</mat-option>
            <mat-option value="Vitiligo">Vitiligo</mat-option>
            <mat-option value="Other">Other</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="question-container" *ngIf="nucaReferralForm.get('diagnosis').value === 'Other'">
        <div class="half-width-field">
          <p>Diagnosis details</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please enter details</mat-label>
          <input matInput formControlName="diagnosisDetails" type="notes" autocomplete="off" />
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>Delay Start</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="delayStart">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="question-container" *ngIf="nucaReferralForm.get('delayStart').value === 'Yes'">
        <div class="half-width-field">
          <p>Reason for Delay Start?</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please enter details</mat-label>
          <input matInput formControlName="delayStartReason" type="notes" autocomplete="off" />
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>Previous Phototherapy</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="previousPhototherapy">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div
        class="question-container"
        *ngIf="nucaReferralForm.get('previousPhototherapy').value === 'Yes'"
      >
        <div class="half-width-field">
          <p>Previous phototherapy details</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please enter details</mat-label>
          <input
            matInput
            formControlName="previousPhototherapyDetails"
            type="notes"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
    </mat-card>

    <!-- GENERAL RISKS -->
    <mat-card class="mat-card">
      <h3 style="text-align: center">General risks</h3>

      <div class="question-container">
        <div class="half-width-field">
          <p>Pregnancy</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="pregnancy">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>Paediatric patient</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="paediatricPatient">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>Systemic Lupus Erythematous</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="systemicLupusErythematous">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>Gorlin's Syndrome</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="gorlinsSyndrome">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>Lived more than 1 year in Tropics</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="livedMoreThanOneYearInTropics">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>Previous Radiotherapy</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="previousRadiotherapy">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>Renal/Hepatic impairment</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="renalOrHepaticImpairment">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>Current immunosuppression</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="currentImmunosupression">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div
        class="question-container"
        *ngIf="nucaReferralForm.get('currentImmunosupression').value === 'Yes'"
      >
        <div class="half-width-field">
          <p>Please give details about your current immunosuppression</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please enter details</mat-label>
          <input
            matInput
            formControlName="currentImmunosupressionDetails"
            type="notes"
            autocomplete="off"
          />
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>Personal history of Skin CA</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="personalHistoryOfSkinCA">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div
        class="question-container"
        *ngIf="nucaReferralForm.get('personalHistoryOfSkinCA').value === 'Yes'"
      >
        <div class="half-width-field">
          <p>Please give details about your personal history of Skin CA</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please enter details</mat-label>
          <input
            matInput
            formControlName="personalHistoryOfSkinCaDetails"
            type="notes"
            autocomplete="off"
          />
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>Adverse reactions to psoralens</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="adverseReactionsToPsoralens">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div
        class="question-container"
        *ngIf="nucaReferralForm.get('adverseReactionsToPsoralens').value === 'Yes'"
      >
        <div class="half-width-field">
          <p>Please give details about your adverse reactions to psoralens</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please enter details</mat-label>
          <input
            matInput
            formControlName="adverseReactionsToPsoralensDetails"
            type="notes"
            autocomplete="off"
          />
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>Disabilities/Language/Interpreter?</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="disabilitiesLanguageInterpreter">
            <!-- gosh how am i supposed to word this one -->
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div
        class="question-container"
        *ngIf="nucaReferralForm.get('disabilitiesLanguageInterpreter').value === 'Yes'"
      >
        <div class="half-width-field">
          <p>
            Please give details about any disabilities, different languages spoken or need an
            interpreter?
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please enter details</mat-label>
          <input
            matInput
            formControlName="disabilitiesLanguageInterpreterDetails"
            type="notes"
            autocomplete="off"
          />
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>Comorbidities</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please enter details</mat-label>
          <input matInput formControlName="comorbidities" type="notes" autocomplete="off" />
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>Current medication</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please enter details</mat-label>
          <input matInput formControlName="currentMedication" type="notes" autocomplete="off" />
        </mat-form-field>
      </div>
    </mat-card>

    <!-- READ THE FOLLOWING -->
    <mat-card class="mat-card">
      <div class="question-container">
        <div class="half-width-field">
          <p>The treatment, possible risks and side effects have been explained to the patient</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="treatmentRisksAndSideEffectsExplainedToPatient">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>
            Patient verbally consents to a record of their treatment being stored. The patient
            understands the information will be used to plan their future treatment. The data is
            confidential and will be anonymised for audit and/or research purposes.
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="patientVerballyConsentsToRecordOfTreatmentBeingStored">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-card>

    <mat-card>
      <div class="question-container">
        <div class="half-width-field">
          <p>Follow up with consultant/CNS required once treatment complete</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="followUpWithConsultantAfterTreatment">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div
        class="question-container"
        *ngIf="nucaReferralForm.get('followUpWithConsultantAfterTreatment').value === 'Yes'"
      >
        <div class="half-width-field">
          <p>In (Number) Months/Weeks</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please enter details</mat-label>
          <input
            matInput
            placeholder="E.g. 4 weeks"
            formControlName="whenWasTheFollowUpWithConsultant"
            type="notes"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
    </mat-card>

    <button mat-raised-button class="positive-button" type="submit">
      <!-- [disabled]="!valid()" -->
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
