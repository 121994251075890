import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { AvatarComponent } from './components/avatar/avatar.component';
import { AdminOnlyDirective } from './directives/admin-only/admin-only.directive';
import { PrimeNgModule } from './modules/primeng.module';
import { AuthImagePipe } from './pipes/auth-image/auth-image.pipe';
import { DaysFromDatePipe } from './pipes/days-from-date/days-from-date.pipe';
import { FormIdsToNamesPipe } from './pipes/form-id-to-name/form-id-to-name.pipe';
import { NhsDatePipe } from './pipes/nhs-date/nhs-date.pipe';
import { OrderByPipe } from './pipes/order-by/order-by.pipe';
import { PhoneFormatPipe } from './pipes/phone-format/phone-format.pipe';
import { SortByPriorityPipe } from './pipes/sort-by-priority/sort-by-priority.pipe';
import { TimestampPipe } from './pipes/timestamp/timestamp.pipe';
import { UserIdToUserEmailPipe } from './pipes/user-id-to-user-email/user-id-to-user-email.pipe';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    PrimeNgModule,
    AvatarComponent,
    SortByPriorityPipe,
    UserIdToUserEmailPipe,
    FormIdsToNamesPipe,
  ],
  declarations: [TimestampPipe, DaysFromDatePipe, OrderByPipe, AdminOnlyDirective, NhsDatePipe, PhoneFormatPipe],
  exports: [
    TimestampPipe,
    DaysFromDatePipe,
    OrderByPipe,
    AdminOnlyDirective,
    NgSelectModule,
    NhsDatePipe,
    AvatarComponent,
    PhoneFormatPipe,
    SortByPriorityPipe,
    UserIdToUserEmailPipe,
    FormIdsToNamesPipe,
  ],
  providers: [AuthImagePipe],
})
export class SharedCommonModule {}
