import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UsersService } from '../../shared/services/users/users.service';
import { OrganisationsService } from '../organisations/organisations.service';

export enum OrganisationFeatureToggles {
  EMAIL_REQUESTS_ENABLED = 'isEmailFunctionalityEnabled$',
  REQUEST_COLLECTION_ACCESS_DISABLED = 'isRequestAccessToCollectionDataDisabled$',
  EDIT_AUTOMATIC_PATIENTS_ENABLED = 'editAutomaticallyCreatedPatients$',
  SOUND_RECORDING_ENABLED = 'soundRecordingEnabled$',
  TEAM_RESOURCES_ENABLED = 'teamResourcesEnabled$',
  ENHANCED_CONSENT_REQUEST_ENABLED = 'enhancedConsentRequestAvailable$',
  ADD_PATIENT_DISABLED = 'hideAddPatient$',
  IS_MEDWISE_ENABLED = 'isMedwiseEnabled$',
}

@Injectable({
  providedIn: 'root',
})
export class OrganisationFeatureToggleService {
  constructor(
    private usersService: UsersService,
    private organisationsService: OrganisationsService,
  ) {}

  get isEmailFunctionalityEnabled$(): Observable<boolean> {
    return this.usersService.myOrganisation$.pipe(
      map(organisation => organisation.featureFlags?.functionality?.emailEnabled),
      catchError(() => of(false)),
    );
  }

  isRequestAccessToCollectionDataDisabled$(organisationId: string): Observable<boolean> {
    return this.organisationsService.getOrganisation$(organisationId).pipe(
      map(organisation => organisation.featureFlags?.functionality?.requestAccessToCollectionDataDisabled),
      catchError(() => of(false)),
    );
  }

  get editAutomaticallyCreatedPatients$(): Observable<boolean> {
    return this.usersService.myOrganisations$.pipe(
      map(organisations =>
        organisations.some(organisation => organisation.featureFlags?.functionality?.editAutomaticallyCreatedPatients),
      ),
      catchError(() => of(false)),
    );
  }

  get soundRecordingEnabled$(): Observable<boolean> {
    return this.usersService.myOrganisations$.pipe(
      map(organisations =>
        organisations.some(organisation => organisation.featureFlags?.functionality?.soundRecordingEnabled),
      ),
      catchError(() => of(false)),
    );
  }

  get teamResourcesEnabled$(): Observable<boolean> {
    return this.usersService.myOrganisations$.pipe(
      map(organisations =>
        organisations.some(organisation => organisation.featureFlags?.functionality?.teamResourcesEnabled),
      ),
      catchError(() => of(false)),
    );
  }

  get enhancedConsentRequestAvailable$(): Observable<boolean> {
    return this.usersService.myOrganisations$.pipe(
      map(organisations =>
        organisations.some(organisation => organisation.featureFlags?.functionality?.enhancedConsentRequestsEnabled),
      ),
      catchError(() => of(false)),
    );
  }

  get hideAddPatient$(): Observable<boolean> {
    return this.usersService.myOrganisation$.pipe(
      map(organisation => organisation.featureFlags?.functionality?.hideAddPatient || false),
      catchError(() => of(false)),
    );
  }

  get hideChangeLog$(): Observable<boolean> {
    return this.usersService.myOrganisation$.pipe(
      map(organisation => organisation.featureFlags?.functionality?.hideChangeLog || false),
      catchError(() => of(false)),
    );
  }

  get isMedwiseEnabled$(): Observable<boolean> {
    return this.usersService.myOrganisation$.pipe(
      map(organisation => organisation.featureFlags?.functionality?.isMedwiseEnabled || false),
      catchError(() => of(false)),
    );
  }
}
