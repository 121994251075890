import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemCheckService } from '@ic-monorepo/shared-common';
import { AuditActionEnum } from '@islacare/ic-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuditService } from 'apps/frontend/portal/src/app/services/audit/audit.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogData } from '../../../components/dialogs/dialog-data';

interface IFormDialog extends DialogData {
  initialMessage: string;
  finalMessage: string;
  formIds: string[];
  teamId?: string;
  video: boolean;
  news2Button?: boolean;
}
@UntilDestroy()
@Component({
  selector: 'ic-form-dialog',
  templateUrl: './form-preview-dialog.component.html',
  styleUrls: ['./form-preview-dialog.component.scss'],
})
export class FormPreviewDialogComponent implements OnInit, OnDestroy {
  data: IFormDialog = null;
  buttonDisabled = true;
  activeStep$: Observable<number>;

  isMobile = this.systemCheck.isMobile;
  isCalcForm = false;
  dialogRef = null;

  constructor(
    private systemCheck: SystemCheckService,
    private auditService: AuditService,
    private ref: DynamicDialogRef,
    private route: ActivatedRoute,
    private router: Router,
    public config: DynamicDialogConfig,
  ) {
    this.data = config.data;
    this.dialogRef = ref;
  }

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParams;
    this.isCalcForm = queryParams['isCalcForm'] === 'true';
    // This is a temporary solution for the NEWS2 fat button
    this.checkForBespokeCalcs(queryParams['formId']);
  }

  /**
   * Checks for bespoke calculations in forms.
   *
   * @deprecated Not fit for purpose.
   * @reason This method hardcodes logic for specific forms, which is not ideal for a reusable component.
   * A separate 'tools' service should eventually be created
   * @todo Refactor to use a dedicated 'tools' service for handling such forms.
   *
   * @param {string} formId - The ID of the form to check.
   */
  checkForBespokeCalcs(formId: string): void {
    const news2FormId = 'moa0MHnVlyNZQFT58NUn';
    const sepsisFormId = 'zr1uGrBTK3sFOpGikown';

    if (formId === news2FormId) {
      this.activeStep$ = this.route.queryParamMap.pipe(map(params => Number(params.get('step'))));

      this.activeStep$.pipe(untilDestroyed(this)).subscribe((activeStep: number) => {
        this.buttonDisabled = activeStep < 8;

        if (activeStep === 8) {
          this.auditService.userCompletedFormCalculator(news2FormId, AuditActionEnum.news2_completed);
        }
      });
    }

    if (formId === sepsisFormId) {
      this.auditService.userCompletedFormCalculator(sepsisFormId, AuditActionEnum.think_sepsis_calc_complete);
    }
  }

  closeDialog(value?: any): void {
    this.ref.close(value);
  }

  ngOnDestroy(): void {
    const currentParams = { ...this.route.snapshot.queryParams };

    delete currentParams['isCalcForm'];
    delete currentParams['step'];

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: currentParams,
      queryParamsHandling: '',
      replaceUrl: true,
    });
  }
}
