import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CanActivate } from '@angular/router';
import { AuthService } from '../../../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuard implements CanActivate {
  constructor(public afAuth: AngularFireAuth, private auth: AuthService) {}

  canActivate() {
    return this.auth.canActivateIfAdmin();
  }
}
