import { Component, OnInit } from '@angular/core';
import { SystemCheckService } from '@ic-monorepo/shared-common';
import { TeamResourceWithId } from '@islacare/ic-types';
import firebase from 'firebase/compat/app';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogData } from '../../../shared/components/dialogs/dialog-data';

interface TeamResourceWithPublicUrl extends TeamResourceWithId {
  publicUrl?: string;
}

interface IResourceDialog extends DialogData {
  resourceArray: TeamResourceWithPublicUrl[];
}

@Component({
  selector: 'ic-attach-resource-file-dialog',
  templateUrl: './attach-resource-file-dialog.component.html',
  styleUrls: ['./attach-resource-file-dialog.component.scss'],
})
export class AttachResourceFileDialogComponent implements OnInit {
  selectedResource: TeamResourceWithPublicUrl;
  data: IResourceDialog;

  isMobile = this.systemCheck.isMobile;

  constructor(
    private systemCheck: SystemCheckService,
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {
    this.data = this.config.data;
  }

  async ngOnInit(): Promise<void> {
    for (const resource of this.data.resourceArray) {
      resource.publicUrl = await this.getDownloadUrl(resource.storageFilePath);
    }
  }

  trackById(index: number, item: TeamResourceWithPublicUrl): string {
    return item.id;
  }

  async getDownloadUrl(storageFilePath) {
    const storageRef = firebase.storage()?.ref(storageFilePath);
    let publicUrl = '';
    if (storageRef) publicUrl = await storageRef?.getDownloadURL();
    return publicUrl;
  }

  openPreviewInNewTab(url: string) {
    window.open(url);
  }
}
