export default [
  'dateOfDischarge',
  'site',
  'ward',
  'consultant',
  'staffProvidingPad',
  'typeOfWound',
  'pressureUlcerCategory',
  'location',
  'length',
  'width',
  'depth',
  'epithelia',
  'granulation',
  'hypergranulation',
  'slough',
  'necrotic',
  'haematoma',
  'boneTendonFascia',
  'edges',
  'signsOfInfection',
  'woundSwabSent',
  'antibiotics',
  'microbiologyResults',
  'recommendedTreatment',
  'barrierFilm',
  'primaryDressing',
  'npwtOptions',
  'vacPressure',
  'vacMode',
  'vacAdjunctDressing',
  'secondaryDressing',
  'dressingChangeFrequency',
  'managingPressureUlcer',
  'managementStartDate',
  'otherRecommendations'
];
