import { Component, OnInit } from '@angular/core';
import { SystemCheckService } from '@ic-monorepo/shared-common';
import {
  CollectionWithId,
  GroupedFormWithTeamReference,
  PatientWithId,
  ScheduleTemplateDetails,
  ScheduledItemType,
  ScheduledItemWithId,
  UserWithId,
} from '@islacare/ic-types';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { FormService } from '../../../shared/services/form/form.service';
import { EmailRecipient, PatientsService, SmsRecipient } from '../../../shared/services/patients/patients.service';
import { ToastService, ToastType } from '../../../shared/services/toast/toast.service';
import { UsersService } from '../../../shared/services/users/users.service';
import { EnhancedScheduleService } from '../../services/enhanced-schedule/enhanced-schedule.service';

export interface EnhancedScheduleDialogData {
  collection: CollectionWithId;
  patient: PatientWithId;
}

@Component({
  selector: 'ic-enhanced-schedule-dialog',
  templateUrl: './enhanced-schedule-dialog.component.html',
  styleUrls: ['./enhanced-schedule-dialog.component.scss'],
})
export class EnhancedScheduleDialogComponent implements OnInit {
  scheduledItems$: Observable<ScheduledItemWithId[]>;
  forms$: Observable<GroupedFormWithTeamReference[]>;
  ScheduledItemType = ScheduledItemType;
  currentItemFormSubj$ = new Subject<ScheduledItemType>();
  currentItemForm$: Observable<ScheduledItemType>;
  user$: Observable<UserWithId>;
  formsDict: { [key: string]: string } = {};
  selectedItem: ScheduledItemWithId;
  smsRecipients: SmsRecipient[];
  emailRecipients: EmailRecipient[];
  showApplyScheduleTemplateForm: boolean;
  isLoading: boolean;
  isEditing: boolean;
  previewOnly: boolean;
  isMobile = this.systemCheck.isMobile;
  userId: string;
  dialogData: EnhancedScheduleDialogData;

  constructor(
    private formService: FormService,
    private enhancedScheduleService: EnhancedScheduleService,
    private usersService: UsersService,
    private patientsService: PatientsService,
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private systemCheck: SystemCheckService,
    private toastService: ToastService,
  ) {
    this.dialogData = this.config.data;
  }

  ngOnInit(): void {
    this.scheduledItems$ = this.enhancedScheduleService.getScheduledItems$(
      this.dialogData.patient.id,
      this.dialogData.collection.id,
    );

    this.smsRecipients = this.patientsService.smsRecipients(
      this.dialogData.patient.additionalPhoneNumbers,
      this.dialogData.patient.firstName,
      this.dialogData.patient.phone,
    );

    this.emailRecipients = this.patientsService.emailRecipients(
      this.dialogData.patient.additionalEmailAddresses,
      this.dialogData.patient.email,
    );

    this.currentItemForm$ = this.currentItemFormSubj$.pipe(tap(formSelected => (this.isEditing = !!formSelected)));

    this.forms$ = this.formService.getFormsWithTeam$(this.dialogData.collection.teamId);

    this.user$ = this.usersService.me$.pipe(tap(user => (this.userId = user.id)));
  }

  async addNewScheduledItems(scheduledItems: ScheduledItemWithId[]) {
    this.isLoading = true;
    await Promise.all(
      scheduledItems.map(item =>
        this.enhancedScheduleService.upsertScheduledItem(
          this.dialogData.patient.id,
          this.dialogData.collection,
          item,
          scheduledItems.length > 1,
        ),
      ),
    )
      .then(() => {
        this.currentItemFormSubj$.next(null);
      })
      .finally(() => {
        this.isLoading = false;
        this.isEditing = false;
      });
  }

  applyScheduleTemplate(scheduleTemplateDetails: ScheduleTemplateDetails) {
    this.isLoading = true;

    this.enhancedScheduleService
      .applyScheduleTemplateToCollection(
        this.dialogData.collection.teamId,
        this.dialogData.collection.id,
        this.dialogData.patient.id,
        scheduleTemplateDetails,
      )
      .then(() => {
        this.toggleScheduleTemplateForm(false);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  toggleScheduleTemplateForm(open = true) {
    this.closeForm();
    this.showApplyScheduleTemplateForm = open;
  }

  openScheduledItemForm(scheduledItemType: ScheduledItemType) {
    if (!this.previewOnly) {
      this.closeForm();
      this.showApplyScheduleTemplateForm = false;
      this.selectedItem = null;
      this.currentItemFormSubj$.next(scheduledItemType);
    }
  }

  editScheduledItem(scheduledItem: ScheduledItemWithId) {
    this.closeForm();
    this.selectedItem = scheduledItem;
    this.currentItemFormSubj$.next(scheduledItem.type);
  }

  previewScheduledItem(scheduledItem: ScheduledItemWithId) {
    this.closeForm();
    this.previewOnly = true;
    this.selectedItem = scheduledItem;
    this.currentItemFormSubj$.next(scheduledItem.type);
  }

  deleteScheduledItem(event: { scheduledItem: ScheduledItemWithId; multipleItems: boolean }) {
    this.enhancedScheduleService.deleteScheduledItem(
      this.dialogData.patient.id,
      this.dialogData.collection,
      event.scheduledItem,
      event.multipleItems,
    );
  }

  closeForm() {
    this.previewOnly = false;
    this.currentItemFormSubj$.next(null);
  }

  clearAllScheduleItems() {
    this.enhancedScheduleService.clearAllScheduleItems(this.dialogData.patient.id, this.dialogData.collection);
  }

  closeDialog() {
    this.ref.close();
  }

  async updateAutomationDisabledOnCollection(event: { patientId: string; collectionId: string }) {
    await this.enhancedScheduleService.updateAutomationDisabledOnCollection(event);
  }

  async updateScheduledItemAfterUserEdit() {
    const currentScheduledItems = await firstValueFrom(this.scheduledItems$.pipe(take(1)));
    for (const scheduleItem of currentScheduledItems) {
      try {
        await this.enhancedScheduleService.updateScheduledItemCollection(
          this.dialogData.patient.id,
          this.dialogData.collection,
          scheduleItem,
        );
      } catch (err) {
        const message = `Error updating property on ${scheduleItem.id}: \n${(err as Error).message}`;
        this.toastService.open(ToastType.Error, message);
      }
    }
  }
}
