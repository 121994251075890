import { Component } from '@angular/core';
import { SystemCheckService } from '@ic-monorepo/shared-common';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

interface SubmissionsResponse {
  alertEmail: string;
  capture: string;
  captureType: string;
  formIds: string[];
  teamId: string;
}

@Component({
  selector: 'ic-submission-dialog',
  templateUrl: './submission-dialog.component.html',
})
export class SubmissionDialogComponent {
  confirmSubmissionValid = false;
  submissionResponse: SubmissionsResponse;
  isMobile = this.systemCheck.isMobile;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private systemCheck: SystemCheckService
  ) {
    if (this.isMobile) {
      this.config.width = '90%';
    }
  }

  getSubmissionResponse(response: SubmissionsResponse): void {
    this.confirmSubmissionValid = !!(
      response.formIds.length > 0 && response.captureType
    );
    this.submissionResponse = response;
  }

  async onSubmit(submissionResponse: SubmissionsResponse): Promise<void> {
    this.ref.close(submissionResponse);
  }

  closeDialog(): void {
    this.ref.close();
  }
}
