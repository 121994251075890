export default [
  'treatment',
  'seenSameDoctor',
  'knewAboutCase',
  'chanceToTalk',
  'listenedAndUnderstood',
  'treatmentProgressInformation',
  'treatmentSectionScore',
  'howYourHandIsNow',
  'handAbsent',
  'handColdDampPain',
  'handPain',
  'handPainDuration',
  'handClumsy',
  'handFlexible',
  'handGrip',
  'handEverdayActivities',
  'handWork',
  'handEmbarrassed',
  'handUpset',
  'handSectionScore',
  'overallAssessment',
  'treatmentSatisfactory',
  'handSatisfactory',
  'handExpected',
  'overallAssessmentSectionScore'
];
