import { NgModule } from '@angular/core';
import { ApolloClientOptions, InMemoryCache } from '@apollo/client/core';
import { APOLLO_NAMED_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '../environments/environment';

export function createApollo(
  httpLink: HttpLink
): Record<string, ApolloClientOptions<any>> {
  return {
    patientSearch: {
      name: 'patient-search',
      link: httpLink.create({ uri: environment.patientSearchApi }),
      cache: new InMemoryCache(),
    },
    submissionSearch: {
      name: 'submission-search',
      link: httpLink.create({ uri: environment.submissionsApi }),
      cache: new InMemoryCache(),
    },
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      deps: [HttpLink],
      useFactory: createApollo,
    },
  ],
})
export class GraphQLModule {}
