import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SystemCheckService {
  get cookiesEnabled(): boolean {
    return navigator.cookieEnabled;
  }

  get isAndroid(): boolean {
    return /windows phone/i.test(navigator.userAgent) || /android/i.test(navigator.userAgent);
  }

  get isIOS(): boolean {
    return (!(window as any)?.MSStream &&
      (/iPad|iPhone|iPod/.test(navigator.userAgent) ||
        navigator.platform.match(/(iPhone|iPod|iPad)/i) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1))) as boolean;
  }

  get isMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  get isTablet(): boolean {
    const userAgent = navigator.userAgent;
    const isTabletUA =
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/i.test(
        userAgent,
      );
    const isTabletWidth = window.innerWidth <= 1024;
    return isTabletUA || isTabletWidth;
  }

  get isMobileOrTablet(): boolean {
    return this.isMobile || this.isTablet;
  }
}
