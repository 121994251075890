<form [formGroup]="surgicalWoundHealingForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <div class="question-container">
      <div class="half-width-field">
        <p>Have you had any problems with the healing of your surgical wound?</p>
      </div>
      <mat-form-field class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="problemsWithHealing">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <p>If the answer is NO, you do not need to continue the questionnaire.</p>
    </div>

    <div class="question-container">
      <div class="full-width-field">
        <p>
          Since you were discharged from the hospital after your surgery, did you have any of the
          following symptoms?
        </p>
      </div>
      <div class="half-width-field">
        <p>Did you have any discharge or leakage of fluid from any part of the wound?</p>
      </div>
      <mat-form-field class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="anyDischargeOrLeakage">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      class="question-container"
      *ngIf="surgicalWoundHealingForm.get('anyDischargeOrLeakage').value.indexOf('Yes') !== -1"
    >
      <div class="half-width-field">
        <p>If yes, was it either</p>
      </div>
      <mat-form-field class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="dischargeLeakageType" multiple>
          <mat-option value="Clear or blood stained">Clear or blood stained</mat-option>
          <mat-option value="Yellow/green pus">Yellow/green pus</mat-option>
          <mat-option value="Other">Other</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="surgicalWoundHealingForm.get('dischargeLeakageType').value.indexOf('Other') !== -1">
      <mat-form-field class="half-width-field">
        <input
          matInput
          type="text"
          autocomplete="off"
          placeholder="If other type here what the discharge or leakage was like"
          formControlName="dischargeLeakageOther"
        />
      </mat-form-field>
    </div>
    <div class="question-container">
      <div class="half-width-field">
        <p>Did you have any of the following symptoms?</p>
      </div>
      <mat-form-field class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="additionalSymptoms" multiple>
          <mat-option
            [value]="additionalSymptoms"
            *ngFor="let additionalSymptoms of additionalSymptomsList"
          >
            {{ additionalSymptoms }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Was any sample taken form the wound?</p>
      </div>
      <mat-form-field class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="sampleTakenFromWound">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="question-container">
      <div class="half-width-field">
        <p>Did you see a healthcare worker because of these symptoms?</p>
      </div>
      <mat-form-field class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="seenHealthcareWorker">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      class="question-container"
      *ngIf="surgicalWoundHealingForm.get('seenHealthcareWorker').value.indexOf('Yes') !== -1"
    >
      <div class="half-width-field">
        <p>If yes please indicate?</p>
      </div>
      <mat-form-field class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="healthcareWorker" multiple>
          <mat-option
            [value]="healthcareWorker"
            *ngFor="let healthcareWorker of healthcareWorkerList"
          >
            {{ healthcareWorker }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="surgicalWoundHealingForm.get('healthcareWorker').value.indexOf('Other') !== -1">
      <mat-form-field class="half-width-field">
        <input
          matInput
          type="text"
          autocomplete="off"
          placeholder="If other please tell us who you have seen"
          formControlName="healthcareWorkerOther"
        />
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>
          When did you notice these symptoms? If you can't remember the exact date, please give an
          approximate date
        </p>
      </div>
      <mat-form-field class="half-width-field">
        <mat-label>Please select a date</mat-label>
        <input matInput type="date" formControlName="woundSymptomsDate" />
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Have you been prescribed antibiotics for an infection of your surgical wound?</p>
      </div>
      <mat-form-field class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="beenPrescribedAntibiotics">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      class="question-container"
      *ngIf="surgicalWoundHealingForm.get('beenPrescribedAntibiotics').value.indexOf('Yes') !== -1"
    >
      <div class="half-width-field">
        <p>If yes, who prescribed them?</p>
      </div>
      <mat-form-field class="half-width-field">
        <input
          matInput
          type="text"
          autocomplete="off"
          placeholder="E.g. your GP"
          formControlName="whoPrescribedThem"
        />
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Have you been re-admitted to hospital due to an infection of the surgical wound?</p>
      </div>
      <mat-form-field class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="readmittedToHospitalDueToInfection">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      class="question-container"
      *ngIf="
        surgicalWoundHealingForm.get('readmittedToHospitalDueToInfection').value.indexOf('Yes') !==
        -1
      "
    >
      <div class="half-width-field">
        <p>If yes, please specify</p>
      </div>
      <mat-form-field class="half-width-field">
        <input
          matInput
          type="text"
          autocomplete="off"
          placeholder="Please tell us what your infection is like"
          formControlName="readmittedToHospitalDetails"
        />
      </mat-form-field>
    </div>

    <mat-form-field class="notes-field half-width-field">
      <input
        matInput
        formControlName="notes"
        type="notes"
        placeholder="Additional comments"
        autocomplete="off"
      />
    </mat-form-field>

    <br />
    <button mat-raised-button class="positive-button" type="submit">
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
