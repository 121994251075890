import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortDateTime',
  standalone: true
})
export class ShortDateTimePipe implements PipeTransform {
  transform(timestamp: number): string {
    const datePipe = new DatePipe('en-US');
    const formattedDate = datePipe.transform(timestamp, 'd MMM yyyy HH:mm');
    return formattedDate || '';
  }
}
