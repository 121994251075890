export default [
  'activityDetails',
  'assessmentType',
  'activityOneName',
  'activityOneScore',
  'activityTwoName',
  'activityTwoScore',
  'activityThreeName',
  'activityThreeScore',
  'scores',
  'baselineScore',
  'bestAvailableScore',
  'psfsScore',
  'initalScore'
];
