export default [
  'reasonForBeingReferredToVulvalClinic',
  'vulvalSymptoms',
  'generalHealthHistory',
  'childhoodIllness',
  'doYouSmoke',
  'listOfDiagnosedMedicalProblems',
  'yearOrAgeDiagnosed',
  'medicalProblem',
  'hospitalOrGP',
  'listOfSurgeries',
  'year',
  'reason',
  'hospital',
  'otherHospitalisations',
  'prescribedMedications',
  'medication',
  'strength',
  'frequencyTaken',
  'anyAllergiesToMedications',
  'listOfAllergicMedications',
  'anyAllergiesToSubstances',
  'listOfAllergicSubstances',
  'reaction',
  'personalHygieneHabits',
  'washingFrequency',
  'showerBathWashInSinkOrOther',
  'waysOfWashing',
  'isVulvalRegionWashedSeparately',
  'wayOfWashingVulvalRegionSeparately',
  'anyOtherSubstancesUsedToHelpSymptoms',
  'listOfSubstancesUsedToHelpSymptoms',
  'substance',
  'symptom',
  'useOfAnySanitaryProtection',
  'listOfProductsUsedForSanitaryProtection',
  'product',
  'sexualIntercourse',
  'listOfProductsUsedOrTried',
  'familyHealthHistory',
  'anyFamilyMemberDiagnosedWithSkinCancer',
  'anyFamilyMemberDiagnosedWithFemaleCancer',
  'anyFamilyMemberHaveSimilarVulvalSymptoms',
  'anyFamilyMemberDiagnosedWithAutoimmuneDisease',
  'detailsAboutFamilyHistory',
  'womensHealthHistory',
  'ageAtFirstPeriod',
  'dateOrAgeOfLastPeriod',
  'stillHavingPeriods',
  'noOfDaysAfterWhichPeriodOccurs',
  'noOfDaysForWhichPeriodLasts',
  'heavyPeriodsOrIrregularityOrSpottingOrPainOrDischarge',
  'noOfPregnancies',
  'noOfLiveBirths',
  'pregnantOrBreastFeeding',
  'hadHysterectomyOrCesarean',
  'urinaryTractOrBladderOrKidneyInfectionsWithinLastYear',
  'bloodInUrine',
  'problemsWithControlOfUrination',
  'hotFlashesOrSweatingAtNight',
  'anySymptomsAtOrAroundPeriodTime',
  'dateOrAgeAtLastSmearTest',
  'anyAbnormalSmearTestsInPast'
];
