import { Component, HostListener, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { NavigationEnd, Router } from '@angular/router';
import { SystemCheckService } from '@ic-monorepo/shared-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { User } from 'firebase/auth';
import { Observable, distinctUntilChanged, map } from 'rxjs';
import { environment } from '../environments/environment';
import { ScreenTrackerService } from './core/screen_tracker/screen-tracker.service';
import { NavBarContextService } from './feature-auth/services/nav-bar-context.service';
import { AuditService } from './services/audit/audit.service';
import { AuthService } from './services/auth/auth.service';
import { AutoLogoutService } from './services/auto-logout/auto-logout.service';
import { IntercomService } from './services/intercom/intercom.service';

@UntilDestroy()
@Component({
  selector: 'ic-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  time: ReturnType<typeof setTimeout>;
  enableCookiesMessage =
    'To use our website, you must enable cookies in your browser settings. Cookies are required for many features on our site to work properly. Without cookies, you may experience issues using our site or accessing certain features.';
  isRouteMatched = false;

  @HostListener('document:click')
  resetTimer(): void {
    clearTimeout(this.time);

    this.time = setTimeout(
      async () => {
        this.audit.logoutAuto();
        this.auth.logOut();
      },
      30 * 60 * 1000,
    );
  }

  showNavBar$: Observable<boolean>;

  get isAuthOrNewEntryRoute(): boolean {
    return this.router.url.includes('auth') || this.router.url.includes('entry/new');
  }

  get authState$(): Observable<User> {
    return this.afAuth.authState;
  }

  constructor(
    private screenTrackerService: ScreenTrackerService,
    private navBarContextService: NavBarContextService,
    private autoLogoutService: AutoLogoutService,
    private analytics: AngularFireAnalytics,
    private intercom: IntercomService,
    private afAuth: AngularFireAuth,
    private audit: AuditService,
    private auth: AuthService,
    private router: Router,
    public systemCheckService: SystemCheckService,
  ) {
    this.navBarContextService.watchUrlForNavBarVisibility();
    this.screenTrackerService.trackScreenView();
  }

  ngOnInit(): void {
    this.subscribeToRouteChange();
    this.subscribeToHideNavBar();
    this.analytics.setUserProperties({ APP_VERSION: environment.version });
    this.intercom.initialiseIntercom();
    this.autoLogoutService.start();
    if (!/iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || window.innerWidth > 1024) {
      this.resetTimer();
    }
  }

  subscribeToRouteChange(): void {
    this.router.events
      .pipe(
        distinctUntilChanged((previous: any, current: any) => {
          return previous instanceof NavigationEnd && current instanceof NavigationEnd && previous.url === current.url;
        }),
        untilDestroyed(this),
      )
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          const routeUrl = this.router.routerState.snapshot.url;
          const routeKeywords = ['admin', 'teams', 'profile', 'auth'];
          this.isRouteMatched = routeKeywords.some(keyword => routeUrl.includes(keyword));
        }
      });
  }

  subscribeToHideNavBar(): void {
    this.showNavBar$ = this.navBarContextService.hideNavBar$.pipe(
      map(hideNavBar => {
        return !hideNavBar;
      }),
      untilDestroyed(this),
    );
  }
}
