<ng-container *ngIf="media">
  <div class="flex flex-column align-items-center gap-2">
    <div
      [ngSwitch]="media.captureType"
      [class.h-15rem]="viewType !== mediaCardViewType.PREVIEW"
      class="bg-main-surface-alt overflow-hidden flex flex-column justify-content-center w-full border-round-sm"
    >
      <ng-container *ngSwitchCase="captureType.PHOTO">
        <p-image
          [class.blur-75]="media.sensitive"
          [imageClass]="uploaded ? 'object-cover h-full grayscale-100' : 'object-cover h-full'"
          [src]="media.safeUrl"
          [preview]="!uploaded"
          styleClass="flex flex-column align-items-center h-15rem"
          alt="Card preview image"
          appendTo="body"
        >
          <ng-template pTemplate="indicator">
            <i class="pi pi-eye" aria-label="Preview image"> </i>
          </ng-template>
        </p-image>
        <!-- <p-image
          [class.blur-75]="media.sensitive"
          [imageClass]="
            uploaded
              ? 'object-cover h-full grayscale-100'
              : 'object-cover h-full'
          "
          [src]="media.safeUrl"
          [preview]="!uploaded"
          styleClass="flex flex-column align-items-center h-15rem"
          alt="Card preview image"
          appendTo="body"
        ></p-image> -->
      </ng-container>
      <ng-container *ngSwitchCase="captureType.VIDEO">
        <video width="100%" height="400" [src]="media.safeUrl" type="video/quicktime"></video>
        <!-- <video
          [muted]="'muted'"
          poster="data:image/gif,AAAA"
          preload="metadata"
          height="400"
          width="100%"
          playsinline
          controls
          autoplay
        >
          <source  />
        </video> -->
      </ng-container>
      <ng-container *ngSwitchCase="captureType.SOUND_RECORDING">
        <audio [src]="media.safeUrl" controls></audio>
      </ng-container>
      <ng-container *ngSwitchCase="captureType.FILE_UPLOAD">
        <div
          class="flex flex-column align-items-center justify-content-center gap-2 el-bg-primary-subtle border-round-sm text-center w-full h-full file-preview"
        >
          <strong>
            <i
              [class]="!fileTypeIsPdf(media.file.type) ? 'pi-file' : 'pi-file-pdf'"
              class="pi thumbnail-icon text-8xl"
            ></i>
          </strong>

          <div *ngIf="fileTypeIsPdf(media.file.type)" class="absolute mt-3 mr-3 top-0 right-0">
            <button
              (click)="viewPdf()"
              class="list-view-button p-button-secondary p-button-outlined bg-main-surface-alt"
              icon="pi pi-search-plus"
              aria-label="View PDF"
              pTooltip="View PDF"
              pButton
            ></button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="flex flex-column w-full gap-3 pt-2">
    <ng-container *ngIf="!hideNotesForm">
      <div class="flex flex-row gap-3 justify-content-between">
        <div [pTooltip]="media.file.name" class="ellipsis-text">
          <strong *ngIf="showFileName">{{ media.file.name }}</strong>
        </div>
        <div class="flex white-space-nowrap">
          <span>{{ media.file.lastModified | shortDateTime }}</span>
        </div>
      </div>
      <div class="flex flex-row justify-content-between align-items-center h-2-5rem">
        <ng-container *ngIf="media.captureType === captureType.PHOTO">
          <label for="sensitivityToggle"
            ><strong class="flex align-items-center">Mark photo as sensitive</strong></label
          >
          <p-inputSwitch
            [disabled]="uploaded || loading"
            [(ngModel)]="media.sensitive"
            inputId="sensitivityToggle"
            icEventTracker="sf-marks-image-as-sensitive"
            data-cy="captured-item-sensitive-switch"
          ></p-inputSwitch>
        </ng-container>
      </div>
      <div class="flex flex-column gap-1">
        <span><strong>Add notes</strong> (optional)</span>
        <input
          [placeholder]="'Notes about this ' + (getCaptureTypeFriendly(media.captureType) | lowercase) + '...'"
          [(ngModel)]="media.notes"
          [disabled]="uploaded || loading"
          aria-describedby="captured-image-add-notes"
          type="text"
          data-cy="captured-item-notes-field"
          pInputText
        />
      </div>
    </ng-container>
    <ng-container *ngIf="viewType !== mediaCardViewType.PREVIEW">
      <ng-container *ngIf="showDeleteButton">
        <div class="flex flex-row gap-2">
          <button
            *ngIf="!uploaded"
            [label]="
              viewType === mediaCardViewType.PATIENT
                ? 'Delete this ' + (getCaptureTypeFriendly(media.captureType) | lowercase)
                : 'Delete'
            "
            [disabled]="uploaded || loading"
            (click)="removeItem($event)"
            class="p-button-danger p-button-outlined w-full"
            [attr.aria-label]="
              viewType === mediaCardViewType.PATIENT
                ? 'Delete this ' + (getCaptureTypeFriendly(media.captureType) | lowercase)
                : 'Delete'
            "
            pTooltip="Delete this item"
            icon="pi pi-trash"
            type="button"
            pButton
          ></button>
          <p-button
            *ngIf="viewType === mediaCardViewType.CLINICIAN"
            [icon]="uploaded ? 'pi pi-check' : 'pi pi-upload'"
            [label]="uploaded ? 'Submitted' : 'Submit'"
            [disabled]="uploaded"
            [loading]="loading"
            (click)="emitSubmit()"
            styleClass="p-button-info w-full"
            type="button"
          ></p-button>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <p-confirmPopup key="mediaCardConfirmPopup"></p-confirmPopup>
</ng-container>

<ng-container *ngIf="media && previewPdf">
  <p-dialog
    [(visible)]="previewPdf"
    [header]="media.file.name"
    [draggable]="false"
    [modal]="true"
    styleClass="w-10"
    appendTo="body"
  >
    <pdf-viewer
      [src]="media.safeUrl['changingThisBreaksApplicationSecurity']"
      [original-size]="false"
      [render-text]="true"
      [page]="1"
    ></pdf-viewer>

    <ng-template pTemplate="footer">
      <button (click)="previewPdf = false" class="p-button-outlined" aria-label="Close" label="Close" pButton></button>
    </ng-template>
  </p-dialog>
</ng-container>
