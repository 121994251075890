export default [
  'skinCondition',
  'affectedAreas',
  'delayedLightTreatment',
  'delayedLightTreatmentReason',
  'haveYouHadPhototherapyBefore',
  'previousPhototherapyDetails',
  'burntInSunOrDuringTreatment',
  'burntInSunOrDuringTreatmentDetails',
  'skinConditionGetBetterOrWorseInSun',
  'howLongHaveYouHadTheSkinCondition',
  'anyOtherMedicalProblemsOrSkinConditions',
  'otherMedicalProblemsOrSkinConditionsDetails',
  'doYouTakeAnyMedication',
  'medicationDetails',
  'haveYouEverHadAColdSore',
  'coldSoreDetails',
  'hadAnInfectionWhichMadeYourSkinConditionWorse',
  'worsenedSkinConditionDetails',
  'doYouSmoke',
  'smokingDailyAmount',
  'doYouDrinkAlcohol',
  'alcoholWeeklyAmount',
  'doYouLiveAlone',
  'livingSituationDetails',
  'whatIsYourOccupation',
  'doYouThinkYouWorkMakesSkinConditionWorse',
  'doesYourSkinConditionAffectYourMood',
  'skinConditionAffectingMoodReferral',
  'disabilitiesThatMayAffectTreatment',
  'disabilityDetails',
  'doYouNeedAnInterpreterForFaceToFaceAssessment',
  'interpreterLanguage',
  'informationLeafletAboutLightTreatment',
  'anymoreAdditionalComments',
  'notes'
];
