<div>
  <form [formGroup]="clinicalFormDetailsFormGroup">
    <formly-form
      [fields]="clinicalFormDetailsForm"
      [form]="clinicalFormDetailsFormGroup"
      [model]="existingClinicalFormDetails"
    ></formly-form>
  </form>
</div>
<div class="w-full flex justify-content-end">
  <div class="pr-2">
    <button
      pButton
      aria-label="Close save form dialog"
      type="button"
      label="Cancel"
      class="p-button-outlined"
      (click)="closeDialog()"
    ></button>
  </div>
  <div>
    <button
      (click)="onSubmit()"
      label="Save"
      [disabled]="!clinicalFormDetailsFormGroup.valid"
      pButton
    ></button>
  </div>
</div>
