<div
  class="flex flex-column gap-3 p-field-checkbox"
  *ngFor="let option of $any(field?.props?.options)"
>
  <label
    class="flex flex-row align-items-center gap-2 p-checkbox-label w-full border-1 border-round"
    [attr.style]="'background-color: ' + option?.colour + ' !important'"
    [ngClass]="
      formControl.value?.includes(option.value)
        ? 'border-brand el-bg-primary-subtle'
        : 'border-main bg-main-surface'
    "
  >
    <p-checkbox
      value="category"
      [value]="option.value"
      [inputId]="option.value"
      [required]="field?.props?.required"
      [formControl]="formControl"
      [attr.data-cy]="field?.key + '-option-' + option.value"
      [id]="option.key"
    ></p-checkbox>
    <div class="flex flex-column">
      <span>{{ option?.label }}</span>
      <div class="text-xs mt-1 mb-1" *ngIf="option?.info">{{ option?.info }}</div>
    </div>
  </label>
</div>
