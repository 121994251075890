<form [formGroup]="hariForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <div class="full-width-field" style="margin: auto">
      <h3>Holistic Assessment and Rapid Investigation (HARI)</h3>
    </div>

    <br />

    <mat-card class="form-card">
      <mat-card-header>
        <div mat-card-avatar class="form-header"></div>
        <mat-card-title>Next of Kin</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Name</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <input
              matInput
              formControlName="nextOfKinName"
              type="notes"
              placeholder=""
              autocomplete="off"
            />
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Relationship</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <input
              matInput
              formControlName="nextOfKinRelationship"
              type="notes"
              placeholder=""
              autocomplete="off"
            />
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Contact details</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <input matInput formControlName="nextOfKinContact" type="notes" autocomplete="off" />
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Emergency Contact in case of failure to answer door</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <input matInput formControlName="emergencyContact" type="notes" autocomplete="off" />
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="form-card">
      <mat-card-header>
        <div mat-card-avatar class="form-header"></div>
        <mat-card-title>Background assessment</mat-card-title>
        <!-- <mat-card-subtitle></mat-card-subtitle> -->
      </mat-card-header>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Ethnicity</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <input matInput formControlName="ethnicity" type="notes" autocomplete="off" />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Smoking</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <input matInput formControlName="smoking" type="notes" autocomplete="off" />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Alcohol units per week</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <input matInput formControlName="alcohol" type="number" autocomplete="off" />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Presenting issues</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <textarea
              matInput
              matInput
              formControlName="presentingIssues"
              type="notes"
              placeholder=""
              autocomplete="off"
            >
            </textarea>
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Patient's perceived problems</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <textarea
              matInput
              matInput
              formControlName="patientPerceivedProblems"
              type="notes"
              placeholder=""
              autocomplete="off"
            >
            </textarea>
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Past medical history</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <textarea
              matInput
              formControlName="pastMedicalHistory"
              type="notes"
              placeholder=""
              autocomplete="off"
            >
            </textarea>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="form-card">
      <form [formGroup]="medicineForm">
        <mat-card-header style="text-align: left">
          <div mat-card-avatar class="form-header"></div>
          <mat-card-title>Drug History/Medicines Management</mat-card-title>
          <mat-card-subtitle
            >Any High Risk drugs e.g. Anticoagulants (Warfarin/DOAC), Methotrexate, Lithium,
            Antipsychotics, NSAIDs (ibuprofen/naproxen/diclofenac) OR on > 4 drugs – discuss with
            Pharmacist</mat-card-subtitle
          >
        </mat-card-header>

        <div class="full-width-field" style="text-align: left">
          <h4>Add an allergy and your drug history one at a time</h4>
        </div>
        <mat-card-content>
          <div class="question-container">
            <div class="half-width-field hori-verti-center">
              <p>Allergies, sensitivities and adverse drug reactions</p>
            </div>
            <div formArrayName="allergyMedicines">
              <div
                *ngFor="let allergyMedicine of allergyMedicineFormCurrent.controls; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field class="single-width-field">
                  <mat-label> Medicine/substance </mat-label>
                  <input matInput type="text" formControlName="medicineName" />
                </mat-form-field>
                <mat-form-field class="single-width-field">
                  <mat-label> Reaction & severity </mat-label>
                  <input matInput type="text" formControlName="allergyMedicineReaction" />
                </mat-form-field>
                <mat-form-field class="single-width-field">
                  <mat-label> Date allergy occurred </mat-label>
                  <input matInput type="date" formControlName="allergyMedicineDate" />
                </mat-form-field>
                <button mat-button (click)="deleteAllergyMedicine(i)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
            <div class="hori-verti-center">
              <button
                class="positive-button"
                mat-button
                type="button"
                (click)="addAllergyMedicine()"
              >
                Add an allergy
              </button>
            </div>
          </div>

          <div class="question-container">
            <div class="half-width-field hori-verti-center">
              <p>Medication History (Acute & Repeat)</p>
              <p>
                List below used to identify discrepancies between EMIS and patient's home/ patient's
                actual medication usage. Please refer to EMIS for full medication summary.
              </p>
            </div>
            <mat-form-field appearance="fill" class="half-width-field">
              <textarea
                matInput
                formControlName="medications"
                type="notes"
                placeholder=""
                autocomplete="off"
              >
              </textarea>
            </mat-form-field>
          </div>
        </mat-card-content>
      </form>

      <div class="full-width-field" style="text-align: left">
        <h4>OR enter any allergies and your drug history in this free text box</h4>
      </div>
      <div class="question-container">
        <div class="half-width-field">
          <p>Allergies, sensitivities and adverse drug reactions</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <textarea matInput type="text" formControlName="allergyMedicinesFreeText"></textarea>
        </mat-form-field>
      </div>
    </mat-card>

    <mat-card class="form-card">
      <mat-card-header>
        <div mat-card-avatar class="form-header"></div>
        <mat-card-title>Falls assessment</mat-card-title>
        <!-- <mat-card-subtitle></mat-card-subtitle> -->
      </mat-card-header>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>How many falls/ near misses in the past 12 months?</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <input
              matInput
              formControlName="fallsInPast12Months"
              type="number"
              placeholder=""
              autocomplete="off"
            />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Please select further risk of falls</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select multiple formControlName="fallsIndicators">
              <mat-option *ngFor="let option of fallsList" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Comments for any falls indicators above</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <input
              matInput
              formControlName="fallsComments"
              type="notes"
              placeholder=""
              autocomplete="off"
            />
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="form-card">
      <mat-card-header>
        <div mat-card-avatar class="form-header"></div>
        <mat-card-title>General mobility assessment</mat-card-title>
        <!-- <mat-card-subtitle></mat-card-subtitle> -->
      </mat-card-header>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>General mobility comments:</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Select the appropriate options </mat-label>
            <mat-select multiple formControlName="generalMobility">
              <mat-option *ngFor="let option of generalMobilityList" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>General mobility comments:</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <input
              matInput
              formControlName="generalMobilityComments"
              type="notes"
              placeholder="Notes on the selections above"
              autocomplete="off"
            />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Reason for referral to Physiotherapist</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <input
              matInput
              formControlName="reasonForPhysiotherapistReferral"
              type="notes"
              placeholder=""
              autocomplete="off"
            />
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="form-card">
      <mat-card-header>
        <div mat-card-avatar class="form-header"></div>
        <mat-card-title>Frailty assessment</mat-card-title>
        <!-- <mat-card-subtitle></mat-card-subtitle> -->
      </mat-card-header>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Rockwood Score</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <input
              matInput
              formControlName="rockwoodScore"
              type="number"
              placeholder=""
              autocomplete="off"
            />
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Additional comments</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <input
              matInput
              formControlName="frailtycomments"
              type="text"
              placeholder=""
              autocomplete="off"
            />
          </mat-form-field>
        </div>
        <p>
          If concerned regarding frailty discuss with Consultant / ANP to arrange comprehensive
          medical assessment or Therapists to discuss therapy needs identified
        </p>
        <div class="question-container">
          <div class="half-width-field">
            <p>Red flag checklist</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select any red flags </mat-label>
            <mat-select multiple formControlName="redFlagAlerts">
              <mat-option *ngFor="let option of redFlagList" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Red flag comments</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <input
              matInput
              formControlName="redFlagComments"
              type="notes"
              placeholder="Any additional comments or concerns"
              autocomplete="off"
            />
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="form-card">
      <mat-card-header>
        <div mat-card-avatar class="form-header"></div>
        <mat-card-title>Skin condition assessment</mat-card-title>
        <!-- <mat-card-subtitle></mat-card-subtitle> -->
      </mat-card-header>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>General skin condition</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select any that apply </mat-label>
            <mat-select multiple formControlName="generalSkinCondition">
              <mat-option *ngFor="let option of skinConditionList" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Skin condition comments</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <input
              matInput
              formControlName="generalSkinConditionComments"
              type="notes"
              placeholder="Any additional comments or concerns"
              autocomplete="off"
            />
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Level of Continence</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select any that apply </mat-label>
            <mat-select multiple formControlName="continenceAssessment">
              <mat-option *ngFor="let option of continenceAssessmentList" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Continence assessment comments</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <input
              matInput
              formControlName="continenceComments"
              type="notes"
              placeholder="Any additional comments or concerns"
              autocomplete="off"
            />
          </mat-form-field>
        </div>
        <div class="question-container">
          <mat-radio-group
            class="full-width-field"
            appearance="fill"
            formControlName="commnuityNursing"
          >
            <mat-label>Is patient having active treatment from Community nursing</mat-label>
            <mat-radio-button value="Yes">Yes</mat-radio-button>
            <mat-radio-button value="No">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="form-card">
      <mat-card-header>
        <div mat-card-avatar class="form-header"></div>
        <mat-card-title>Pain assessment</mat-card-title>
        <!-- <mat-card-subtitle></mat-card-subtitle> -->
      </mat-card-header>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Pain (Acute/chronic, location, duration, perceived level of pain)</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <input
              matInput
              formControlName="painDescription"
              type="notes"
              placeholder="Describe the pain"
              autocomplete="off"
            />
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Pain location</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <input
              matInput
              type="text"
              formControlName="painLocation"
              placeholder="e.g. head, right arm, lower left leg"
              autocomplete="off"
            />
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="form-card">
      <mat-card-header>
        <div mat-card-avatar class="form-header"></div>
        <mat-card-title>Social history</mat-card-title>
        <!-- <mat-card-subtitle></mat-card-subtitle> -->
      </mat-card-header>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Living situation</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select multiple formControlName="livesWith">
              <mat-option *ngFor="let option of livesWithList" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Any living support?</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <input
              matInput
              formControlName="support"
              type="notes"
              placeholder=""
              autocomplete="off"
            />
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="form-card">
      <mat-card-header>
        <div mat-card-avatar class="form-header"></div>
        <mat-card-title>Function and Transfers</mat-card-title>
        <!-- <mat-card-subtitle></mat-card-subtitle> -->
      </mat-card-header>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Any difficulty with?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Select all where difficulty </mat-label>
            <mat-select multiple formControlName="needsAssistanceWith">
              <mat-option *ngFor="let option of needsAssistanceWithList" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Any comments from the options above?</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <input
              matInput
              formControlName="assistanceComments"
              type="notes"
              placeholder=""
              autocomplete="off"
            />
          </mat-form-field>
        </div>
        <mat-radio-group class="half-width-field" appearance="fill" formControlName="needEquipment">
          <mat-label>Needs equipment?</mat-label>
          <mat-radio-button value="Yes">Yes</mat-radio-button>
          <mat-radio-button value="No">No</mat-radio-button>
        </mat-radio-group>
        <div class="question-container">
          <div class="half-width-field">
            <p>Reason for referral to OT</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <input
              matInput
              formControlName="reasonForOTReferral"
              type="notes"
              placeholder=""
              autocomplete="off"
            />
          </mat-form-field>
        </div>
        <mat-radio-group
          class="full-width-field"
          appearance="fill"
          formControlName="doTheyLiveInMerton"
        >
          <mat-label>Do they live in Merton Borough (council Tax paid to Merton)?</mat-label>
          <mat-radio-button value="Yes">Yes</mat-radio-button>
          <mat-radio-button value="No">No</mat-radio-button>
        </mat-radio-group>
      </mat-card-content>
    </mat-card>

    <mat-card class="form-card">
      <mat-card-header>
        <div mat-card-avatar class="form-header"></div>
        <mat-card-title>Mental Health and Wellbeing</mat-card-title>
        <!-- <mat-card-subtitle></mat-card-subtitle> -->
      </mat-card-header>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Cognition</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <input
              matInput
              formControlName="cognition"
              type="notes"
              placeholder=""
              autocomplete="off"
            />
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Mood</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <input matInput formControlName="mood" type="notes" placeholder="" autocomplete="off" />
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Past History of 6CIT or other Cognitive assessment</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <input
              matInput
              formControlName="pastHistoryOf6CITorOther"
              type="notes"
              placeholder=""
              autocomplete="off"
            />
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>GDS</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <input matInput formControlName="GDS" type="notes" placeholder="" autocomplete="off" />
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="form-card">
      <mat-card-header>
        <div mat-card-avatar class="form-header"></div>
        <mat-card-title>Summary and action plan</mat-card-title>
        <!-- <mat-card-subtitle></mat-card-subtitle> -->
      </mat-card-header>
      <mat-card-content>
        <mat-radio-group class="full-width-field" appearance="fill" formControlName="carePlanGiven">
          <mat-label>Care plan given to patient?</mat-label>
          <mat-radio-button value="Yes">Yes</mat-radio-button>
          <mat-radio-button value="No">No</mat-radio-button>
        </mat-radio-group>
        <br />
        <mat-radio-group
          class="full-width-field"
          appearance="fill"
          formControlName="admissionPreventionPlanGiven"
        >
          <mat-label>Admission Prevention Plan given to patient?</mat-label>
          <mat-radio-button value="Yes">Yes</mat-radio-button>
          <mat-radio-button value="No">No</mat-radio-button>
        </mat-radio-group>
        <div class="question-container">
          <div class="half-width-field">
            <p>Heath Promotion Information Provided & Onwards referral (If required):</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Select all that apply </mat-label>
            <mat-select multiple formControlName="informationProvided">
              <mat-option *ngFor="let option of informationProvidedList" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <div class="button-group">
      <button class="positive-button" mat-raised-button color="accent" type="submit">
        {{ isSubmitting ? 'Submitting...' : 'Submit' }}
      </button>
    </div>
  </div>
</form>
