<form [formGroup]="lothianForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <div>
      <h3>Lothian Patient 30-day Follow-up Questionnaire</h3>
    </div>

    <mat-card class="form-card">
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Have you experienced any of the following symptoms in or around your
            wound?
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select multiple formControlName="woundSymptoms">
            <mat-option
              value="None"
              (onSelectionChange)="woundSymptomsNoneValidation()"
              >None</mat-option
            >
            <mat-option
              value="Redness spreading away"
              (onSelectionChange)="woundSymptoms0Validation($event)"
              >Redness spreading away</mat-option
            >
            <mat-option
              value="Warmer than the surrounding skin"
              (onSelectionChange)="woundSymptoms1Validation($event)"
              >Warmer than the surrounding skin</mat-option
            >
            <mat-option
              value="Swollen"
              (onSelectionChange)="woundSymptoms2Validation($event)"
              >Swollen</mat-option
            >
            <mat-option
              value="Fluid leaking"
              (onSelectionChange)="woundSymptoms3Validation($event)"
              >Fluid leaking</mat-option
            >
            <mat-option
              value="Painful to touch"
              (onSelectionChange)="woundSymptoms4Validation($event)"
              >Painful to touch</mat-option
            >
            <mat-option
              value="Smelly"
              (onSelectionChange)="woundSymptoms5Validation($event)"
              >Smelly</mat-option
            >
            <mat-option
              value="Raised temperature or fever (fever > 38 °C)"
              (onSelectionChange)="woundSymptoms6Validation($event)"
              >Raised temperature or fever (fever > 38 °C)</mat-option
            >
            <mat-option
              value="Wound separated/gaped open"
              (onSelectionChange)="woundSymptoms7Validation($event)"
              >Wound separated/gaped open</mat-option
            >
          </mat-select>
          <mat-error *ngIf="woundSymptoms.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="question-container"
        *ngIf="
          lothianForm
            .get('woundSymptoms')
            .value.indexOf('Redness spreading away') !== -1
        "
      >
        <div class="half-width-field">
          <p>Was there redness spreading away from the wound?</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="rednessSpreadingAwayFromWound">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="rednessSpreadingAwayFromWound.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="question-container"
        *ngIf="
          lothianForm
            .get('woundSymptoms')
            .value.indexOf('Warmer than the surrounding skin') !== -1
        "
      >
        <div class="half-width-field">
          <p>Was the area around the wound warmer than the surrounding skin?</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="woundAreaWarmerThanSurroundingSkin">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="woundAreaWarmerThanSurroundingSkin.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="question-container"
        *ngIf="lothianForm.get('woundSymptoms').value.indexOf('Swollen') !== -1"
      >
        <div class="half-width-field">
          <p>Has the area around the wound become swollen?</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="woundAreaBecomeSwollen">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="woundAreaBecomeSwollen.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="question-container"
        *ngIf="
          lothianForm.get('woundSymptoms').value.indexOf('Fluid leaking') !== -1
        "
      >
        <div class="half-width-field">
          <p>Has any part of the wound leaked clear fluid?</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="woundLeakedClearFluid">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="woundLeakedClearFluid.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="question-container"
        *ngIf="
          lothianForm.get('woundSymptoms').value.indexOf('Fluid leaking') !== -1
        "
      >
        <div class="half-width-field">
          <p>
            Has any part of the wound leaked blood-stained fluid? (haemoserous
            exudate)
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="woundLeakedBloodStainedFluid">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="woundLeakedBloodStainedFluid.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="question-container"
        *ngIf="
          lothianForm.get('woundSymptoms').value.indexOf('Fluid leaking') !== -1
        "
      >
        <div class="half-width-field">
          <p>Has any part of the wound leaked thick and yellow/green fluid?</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="woundLeakedThickYellowGreenFluid">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="woundLeakedThickYellowGreenFluid.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="question-container"
        *ngIf="
          lothianForm.get('woundSymptoms').value.indexOf('Painful to touch') !==
          -1
        "
      >
        <div class="half-width-field">
          <p>Has the wound been painful to touch?</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="woundBeenPainfulToTouch">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="woundBeenPainfulToTouch.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="question-container"
        *ngIf="lothianForm.get('woundSymptoms').value.indexOf('Smelly') !== -1"
      >
        <div class="half-width-field">
          <p>Has the wound been smelly?</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="woundBeenSmelly">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="woundBeenSmelly.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="question-container"
        *ngIf="
          lothianForm
            .get('woundSymptoms')
            .value.indexOf('Raised temperature or fever (fever > 38 °C)') !== -1
        "
      >
        <div class="half-width-field">
          <p>
            Have you had, or felt like you have had, a raised temperature or
            fever? (fever > 38 °C)
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="haveOrHadARaisedTemperature">
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="haveOrHadARaisedTemperature.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="question-container"
        *ngIf="
          lothianForm
            .get('woundSymptoms')
            .value.indexOf('Wound separated/gaped open') !== -1
        "
      >
        <div class="half-width-field">
          <p>
            Have the edges of any part of the wound separated/gaped open of
            their own accord?
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="woundSeparatedOpen">
            <mat-option value="Not at all">Not at all</mat-option>
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="woundSeparatedOpen.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="question-container"
        *ngIf="
          lothianForm.get('woundSeparatedOpen').value.indexOf('A little') !==
            -1 ||
          lothianForm.get('woundSeparatedOpen').value.indexOf('Quite a bit') !==
            -1 ||
          lothianForm.get('woundSeparatedOpen').value.indexOf('A lot') !== -1
        "
      >
        <div class="half-width-field">
          <p>Did the deeper tissue separate?</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="deeperTissueSeparate">
            <mat-option value="Not at all">Not at all</mat-option>
            <mat-option *ngFor="let answer of answerList" [value]="answer">
              {{ answer }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="deeperTissueSeparate.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>Have you been diagnosed with a wound infection?</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select
            formControlName="haveYouBeenDiagnosedWithAnInfection"
            (selectionChange)="diagnosedWithInfectionValidation($event)"
          >
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <mat-error *ngIf="haveYouBeenDiagnosedWithAnInfection.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="question-container"
        *ngIf="
          lothianForm
            .get('haveYouBeenDiagnosedWithAnInfection')
            .value.indexOf('Yes') !== -1
        "
      >
        <div class="half-width-field">
          <p>What date was this diagnosed on?</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Date of diagnoses</mat-label>
          <input
            matInput
            type="date"
            formControlName="whatDateWasThisDiagnosedOn"
          />
          <mat-error *ngIf="whatDateWasThisDiagnosedOn.touched">
            Please select a date
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="question-container"
        *ngIf="
          lothianForm
            .get('haveYouBeenDiagnosedWithAnInfection')
            .value.indexOf('Yes') !== -1
        "
      >
        <div class="half-width-field">
          <p>
            Has anything been put on the skin to cover the wound? (dressing)
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="hasAnythingBeenPutOnToCoverTheWound">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <mat-error *ngIf="hasAnythingBeenPutOnToCoverTheWound.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="question-container"
        *ngIf="
          lothianForm
            .get('haveYouBeenDiagnosedWithAnInfection')
            .value.indexOf('Yes') !== -1
        "
      >
        <div class="half-width-field">
          <p>Have you been given antibiotics for a problem with your wound?</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select
            formControlName="haveYouBeenGivenAnitobioticsForYourWound"
          >
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <mat-error *ngIf="haveYouBeenGivenAnitobioticsForYourWound.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="question-container"
        *ngIf="
          lothianForm
            .get('haveYouBeenDiagnosedWithAnInfection')
            .value.indexOf('Yes') !== -1
        "
      >
        <div class="half-width-field">
          <p>
            Have the edges of your wound been deliberately separated by a doctor
            or nurse?
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select
            formControlName="haveTheEdgesOfTheWoundBeenSeparatedDeliberately"
          >
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <mat-error
            *ngIf="haveTheEdgesOfTheWoundBeenSeparatedDeliberately.touched"
          >
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>
            Have you sought advice because of a problem with your wound, other
            than at a routine planned follow-up appointment?
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select
            formControlName="haveYouSoughtAdviceWithAProblemWithYourWound"
            (selectionChange)="problemWithWoundValidation($event)"
          >
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <mat-error
            *ngIf="haveYouSoughtAdviceWithAProblemWithYourWound.touched"
          >
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="question-container"
        *ngIf="
          lothianForm
            .get('haveYouSoughtAdviceWithAProblemWithYourWound')
            .value.indexOf('Yes') !== -1
        "
      >
        <div class="half-width-field">
          <p>
            Have you been back into hospital for treatment of a problem with
            your wound?
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select
            formControlName="haveYouBeenInHospitalForTreatmentForYourWound"
            (selectionChange)="treatmentForWoundValidation($event)"
          >
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <mat-error
            *ngIf="haveYouBeenInHospitalForTreatmentForYourWound.touched"
          >
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="question-container"
        *ngIf="
          lothianForm
            .get('haveYouBeenInHospitalForTreatmentForYourWound')
            .value.indexOf('Yes') !== -1
        "
      >
        <div class="half-width-field">
          <p>
            Has your wound been scraped or cut to remove any unwanted tissue?
            (debridement of wound)
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select
            formControlName="hasYourWoundBeenScrapedOrCutToRemoveUnwantedTissue"
          >
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <mat-error
            *ngIf="hasYourWoundBeenScrapedOrCutToRemoveUnwantedTissue.touched"
          >
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="question-container"
        *ngIf="
          lothianForm
            .get('haveYouBeenInHospitalForTreatmentForYourWound')
            .value.indexOf('Yes') !== -1
        "
      >
        <div class="half-width-field">
          <p>Has your wound been drained? (drainage of pus/abscess)</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="hasYourWoundBeenDrained">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <mat-error *ngIf="hasYourWoundBeenDrained.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="question-container"
        *ngIf="
          lothianForm
            .get('haveYouBeenInHospitalForTreatmentForYourWound')
            .value.indexOf('Yes') !== -1
        "
      >
        <div class="half-width-field">
          <p>
            Have you had an operation under general anaesthetic for treatment of
            a problem with your wound?
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select
            formControlName="haveYouHadAnOperationUnderGeneralAnaesthetic"
          >
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <mat-error
            *ngIf="haveYouHadAnOperationUnderGeneralAnaesthetic.touched"
          >
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>
    </mat-card>

    <div>
      <h3>Questions on how you found using the online platform</h3>
    </div>

    <mat-card class="form-card">
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Please indicate if you would be happy to take part in a short
            telephone interview (less than 1 hour) about your experience in the
            study?
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="takePartInTelephoneInterview">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <mat-error *ngIf="takePartInTelephoneInterview.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div>
        <h3>With regards to how useful you found the experience</h3>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>I felt this improved my access to healthcare services</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="accessToHealthcareServicesHasImproved">
            <mat-option value="Strongly agree">Strongly agree</mat-option>
            <mat-option value="Agree">Agree</mat-option>
            <mat-option value="Neutral">Neutral</mat-option>
            <mat-option value="Disagree">Disagree</mat-option>
            <mat-option value="Strongly disagree">Strongly disagree</mat-option>
          </mat-select>
          <mat-error *ngIf="accessToHealthcareServicesHasImproved.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>
            I felt this saved (or would save) me time traveling to a hospital or
            specialist clinic
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select
            formControlName="thisSavedTimeTravellingToHospitalOrClinic"
          >
            <mat-option value="Strongly agree">Strongly agree</mat-option>
            <mat-option value="Agree">Agree</mat-option>
            <mat-option value="Neutral">Neutral</mat-option>
            <mat-option value="Disagree">Disagree</mat-option>
            <mat-option value="Strongly disagree">Strongly disagree</mat-option>
          </mat-select>
          <mat-error *ngIf="thisSavedTimeTravellingToHospitalOrClinic.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>I felt this provided for my healthcare needs</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="thisProvidedForMyHealthcareNeeds">
            <mat-option value="Strongly agree">Strongly agree</mat-option>
            <mat-option value="Agree">Agree</mat-option>
            <mat-option value="Neutral">Neutral</mat-option>
            <mat-option value="Disagree">Disagree</mat-option>
            <mat-option value="Strongly disagree">Strongly disagree</mat-option>
          </mat-select>
          <mat-error *ngIf="thisProvidedForMyHealthcareNeeds.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>
            Please feel free to add any comments you might have regarding the
            usefulness of the online tool:
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label></mat-label>
          <input
            matInput
            type="text"
            formControlName="toolUsefulnessComments"
          />
        </mat-form-field>
      </div>

      <div>
        <h3>
          With regards to how easy to use and learn you found the online form:
        </h3>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>It was simple to use the online form</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="itWasSimpleToUseTheOnlineForm">
            <mat-option value="Strongly agree">Strongly agree</mat-option>
            <mat-option value="Agree">Agree</mat-option>
            <mat-option value="Neutral">Neutral</mat-option>
            <mat-option value="Disagree">Disagree</mat-option>
            <mat-option value="Strongly disagree">Strongly disagree</mat-option>
          </mat-select>
          <mat-error *ngIf="itWasSimpleToUseTheOnlineForm.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>It was easy to learn to use the online form</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="itWasEasyToLearnToUseTheForm">
            <mat-option value="Strongly agree">Strongly agree</mat-option>
            <mat-option value="Agree">Agree</mat-option>
            <mat-option value="Neutral">Neutral</mat-option>
            <mat-option value="Disagree">Disagree</mat-option>
            <mat-option value="Strongly disagree">Strongly disagree</mat-option>
          </mat-select>
          <mat-error *ngIf="itWasEasyToLearnToUseTheForm.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>
            I was able to navigate the tool quickly when submitting a response.
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select
            formControlName="iWasAbleToNavigateTheToolQuicklyWhenSubmitting"
          >
            <mat-option value="Strongly agree">Strongly agree</mat-option>
            <mat-option value="Agree">Agree</mat-option>
            <mat-option value="Neutral">Neutral</mat-option>
            <mat-option value="Disagree">Disagree</mat-option>
            <mat-option value="Strongly disagree">Strongly disagree</mat-option>
          </mat-select>
          <mat-error
            *ngIf="iWasAbleToNavigateTheToolQuicklyWhenSubmitting.touched"
          >
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>
            Please feel free to add any comments you might have regarding the
            ease of use or learning about the online tool:
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label></mat-label>
          <input matInput type="text" formControlName="toolEaseOfUseComments" />
        </mat-form-field>
      </div>

      <div>
        <h3>
          With regards to how you found the interface for the online form:
        </h3>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>
            The way I interact with the platform (ISLA) hosting the online form
            is pleasant
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="pleasantToInteractWith">
            <mat-option value="Strongly agree">Strongly agree</mat-option>
            <mat-option value="Agree">Agree</mat-option>
            <mat-option value="Neutral">Neutral</mat-option>
            <mat-option value="Disagree">Disagree</mat-option>
            <mat-option value="Strongly disagree">Strongly disagree</mat-option>
          </mat-select>
          <mat-error *ngIf="pleasantToInteractWith.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>I like using the platform (ISLA) hosting the online form</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="iLikeUsingIslaHostingTheForm">
            <mat-option value="Strongly agree">Strongly agree</mat-option>
            <mat-option value="Agree">Agree</mat-option>
            <mat-option value="Neutral">Neutral</mat-option>
            <mat-option value="Disagree">Disagree</mat-option>
            <mat-option value="Strongly disagree">Strongly disagree</mat-option>
          </mat-select>
          <mat-error *ngIf="iLikeUsingIslaHostingTheForm.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>
            The platform (ISLA) hosting the online form is simple and easy to
            understand
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select
            formControlName="islaHostingTheFormIsSimpleAndEasyToUnderstand"
          >
            <mat-option value="Strongly agree">Strongly agree</mat-option>
            <mat-option value="Agree">Agree</mat-option>
            <mat-option value="Neutral">Neutral</mat-option>
            <mat-option value="Disagree">Disagree</mat-option>
            <mat-option value="Strongly disagree">Strongly disagree</mat-option>
          </mat-select>
          <mat-error
            *ngIf="islaHostingTheFormIsSimpleAndEasyToUnderstand.touched"
          >
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>
            The platform (ISLA) hosting the online form is able to do everything
            I would want it to be able to do
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="hostedFormIsAbleToDoEverythingIExpect">
            <mat-option value="Strongly agree">Strongly agree</mat-option>
            <mat-option value="Agree">Agree</mat-option>
            <mat-option value="Neutral">Neutral</mat-option>
            <mat-option value="Disagree">Disagree</mat-option>
            <mat-option value="Strongly disagree">Strongly disagree</mat-option>
          </mat-select>
          <mat-error *ngIf="hostedFormIsAbleToDoEverythingIExpect.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>
            Please feel free to add any comments you might have regarding the
            interface of the platform (ISLA) or the online tool:
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label></mat-label>
          <input
            matInput
            type="text"
            formControlName="platformInterfaceComments"
          />
        </mat-form-field>
      </div>

      <div>
        <h3>
          With regards to how you found the quality of communication with
          healthcare staff online:
        </h3>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>
            I could easily communicate to the healthcare staff using the
            platform
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select
            formControlName="couldEasilyCommunicateWithTheHealthcareStaff"
          >
            <mat-option value="Strongly agree">Strongly agree</mat-option>
            <mat-option value="Agree">Agree</mat-option>
            <mat-option value="Neutral">Neutral</mat-option>
            <mat-option value="Disagree">Disagree</mat-option>
            <mat-option value="Strongly disagree">Strongly disagree</mat-option>
          </mat-select>
          <mat-error
            *ngIf="couldEasilyCommunicateWithTheHealthcareStaff.touched"
          >
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>
            I could easily get a response to the healthcare staff using the
            platform
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select
            formControlName="couldEasilyGetAResponseFromTheHealthcareStaff"
          >
            <mat-option value="Strongly agree">Strongly agree</mat-option>
            <mat-option value="Agree">Agree</mat-option>
            <mat-option value="Neutral">Neutral</mat-option>
            <mat-option value="Disagree">Disagree</mat-option>
            <mat-option value="Strongly disagree">Strongly disagree</mat-option>
          </mat-select>
          <mat-error
            *ngIf="couldEasilyGetAResponseFromTheHealthcareStaff.touched"
          >
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>
            I felt I was able to express myself effectively regarding my wound
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="feltIWasAbleToExpressMyselfEffectively">
            <mat-option value="Strongly agree">Strongly agree</mat-option>
            <mat-option value="Agree">Agree</mat-option>
            <mat-option value="Neutral">Neutral</mat-option>
            <mat-option value="Disagree">Disagree</mat-option>
            <mat-option value="Strongly disagree">Strongly disagree</mat-option>
          </mat-select>
          <mat-error *ngIf="feltIWasAbleToExpressMyselfEffectively.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>
            Using the platform, I could communicate with the healthcare staff as
            well as if we met in person
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select
            formControlName="couldCommunicateWithHealthcareStaffAsWellAsInPerson"
          >
            <mat-option value="Strongly agree">Strongly agree</mat-option>
            <mat-option value="Agree">Agree</mat-option>
            <mat-option value="Neutral">Neutral</mat-option>
            <mat-option value="Disagree">Disagree</mat-option>
            <mat-option value="Strongly disagree">Strongly disagree</mat-option>
          </mat-select>
          <mat-error
            *ngIf="couldCommunicateWithHealthcareStaffAsWellAsInPerson.touched"
          >
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>
            Please feel free to add any comments you might have regarding the
            quality of communication with healthcare staff online:
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label></mat-label>
          <input
            matInput
            type="text"
            formControlName="platformQualityOfCommunicationComments"
          />
        </mat-form-field>
      </div>

      <div>
        <h3>
          With regards to how reliable you found the platform (ISLA) or the
          online form:
        </h3>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>
            I think the remote wound reviews provided are the same as in-person
            wound reviews
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="remoteWoundReviewsAreSameAsInPerson">
            <mat-option value="Strongly agree">Strongly agree</mat-option>
            <mat-option value="Agree">Agree</mat-option>
            <mat-option value="Neutral">Neutral</mat-option>
            <mat-option value="Disagree">Disagree</mat-option>
            <mat-option value="Strongly disagree">Strongly disagree</mat-option>
          </mat-select>
          <mat-error *ngIf="remoteWoundReviewsAreSameAsInPerson.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>
            Whenever I made a mistake using the online form, I could recover
            easily and quickly
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select
            formControlName="whenIMadeAMistakeOnTheFormICouldRecoverQuicklyAndEasily"
          >
            <mat-option value="Strongly agree">Strongly agree</mat-option>
            <mat-option value="Agree">Agree</mat-option>
            <mat-option value="Neutral">Neutral</mat-option>
            <mat-option value="Disagree">Disagree</mat-option>
            <mat-option value="Strongly disagree">Strongly disagree</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              whenIMadeAMistakeOnTheFormICouldRecoverQuicklyAndEasily.touched
            "
          >
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>
            The platform (ISLA) or the online form gave error messages that
            clearly told me how to fix problems
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select
            formControlName="islaPlatformOnlineFormGaveErrorMessagesClearly"
          >
            <mat-option value="Strongly agree">Strongly agree</mat-option>
            <mat-option value="Agree">Agree</mat-option>
            <mat-option value="Neutral">Neutral</mat-option>
            <mat-option value="Disagree">Disagree</mat-option>
            <mat-option value="Strongly disagree">Strongly disagree</mat-option>
          </mat-select>
          <mat-error
            *ngIf="islaPlatformOnlineFormGaveErrorMessagesClearly.touched"
          >
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>
            Please feel free to add any comments you might have regarding the
            reliability of the online tool:
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label></mat-label>
          <input
            matInput
            type="text"
            formControlName="onlineToolReliabilityComments"
          />
        </mat-form-field>
      </div>

      <div>
        <h3>With regards to how satisfied you were using the online form:</h3>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>
            I feel comfortable communicating with the healthcare staff online
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select
            formControlName="feelComfortableCommunicatingWithTheHealthcareStaff"
          >
            <mat-option value="Strongly agree">Strongly agree</mat-option>
            <mat-option value="Agree">Agree</mat-option>
            <mat-option value="Neutral">Neutral</mat-option>
            <mat-option value="Disagree">Disagree</mat-option>
            <mat-option value="Strongly disagree">Strongly disagree</mat-option>
          </mat-select>
          <mat-error
            *ngIf="feelComfortableCommunicatingWithTheHealthcareStaff.touched"
          >
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>I found this an acceptable way to receive healthcare services</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select
            formControlName="foundThisAnAcceptableWayToReceiveHealthcareServices"
          >
            <mat-option value="Strongly agree">Strongly agree</mat-option>
            <mat-option value="Agree">Agree</mat-option>
            <mat-option value="Neutral">Neutral</mat-option>
            <mat-option value="Disagree">Disagree</mat-option>
            <mat-option value="Strongly disagree">Strongly disagree</mat-option>
          </mat-select>
          <mat-error
            *ngIf="foundThisAnAcceptableWayToReceiveHealthcareServices.touched"
          >
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>I would use the online system again</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="wouldUseTheOnlineSystemAgain">
            <mat-option value="Strongly agree">Strongly agree</mat-option>
            <mat-option value="Agree">Agree</mat-option>
            <mat-option value="Neutral">Neutral</mat-option>
            <mat-option value="Disagree">Disagree</mat-option>
            <mat-option value="Strongly disagree">Strongly disagree</mat-option>
          </mat-select>
          <mat-error *ngIf="wouldUseTheOnlineSystemAgain.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>
            I think the online system should be available for others undergoing
            similar surgery.
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select
            formControlName="shouldBeAvailableForOthersUndergoingSimilarSurgery"
          >
            <mat-option value="Strongly agree">Strongly agree</mat-option>
            <mat-option value="Agree">Agree</mat-option>
            <mat-option value="Neutral">Neutral</mat-option>
            <mat-option value="Disagree">Disagree</mat-option>
            <mat-option value="Strongly disagree">Strongly disagree</mat-option>
          </mat-select>
          <mat-error
            *ngIf="shouldBeAvailableForOthersUndergoingSimilarSurgery.touched"
          >
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>Overall, I am satisfied with the online system</p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="overallSatisfiedWithTheOnlineSystem">
            <mat-option value="Strongly agree">Strongly agree</mat-option>
            <mat-option value="Agree">Agree</mat-option>
            <mat-option value="Neutral">Neutral</mat-option>
            <mat-option value="Disagree">Disagree</mat-option>
            <mat-option value="Strongly disagree">Strongly disagree</mat-option>
          </mat-select>
          <mat-error *ngIf="overallSatisfiedWithTheOnlineSystem.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div class="question-container">
        <div class="half-width-field">
          <p>
            Please feel free to add any other comments you might have regarding
            of the online tool:
          </p>
        </div>
        <mat-form-field class="half-width-field">
          <mat-label></mat-label>
          <input matInput type="text" formControlName="onlineToolComments" />
        </mat-form-field>
      </div>
    </mat-card>

    <mat-card class="form-card">
      <div style="width: 80%; margin: 0 auto; text-align: left">
        <p>
          Wound infections can be difficult to pick up, even when reviewed
          in-person by experienced healthcare staff. When reviewing wounds based
          on symptoms you report and online images, we can either try to ensure
          we either:
        </p>

        <ul>
          <li>
            See everyone who might have a wound infection - this mean we don't
            miss these infections but people may need to make unnecessary
            journeys (aka need to take time off work / wait at their GP or A&E)
            to be told there's no problem.
          </li>
          <li>
            See only those with clear signs of infection - this means it may be
            longer before people get recommended to be checked out for
            infection.
          </li>
        </ul>

        <p>
          This is a difficult balance to get right, and we want to find out what
          opinions patients have about where the best balance would be between
          these two extremes:
        </p>
      </div>
    </mat-card>

    <mat-card class="form-card">
      <div style="margin: 10px 0px">
        <div style="width: 80%; margin: 0 auto">
          <p>
            If there were 100 people who get asked to go for a wound review (at
            their GP or hospital), what is the HIGHEST number of people you
            think should be asked to go but actually don't have a wound
            infection.
          </p>
        </div>
        <div class="slider-full-width-field verti-center slider-wrapper">
          <div class="slider-label">
            <div>0</div>
            <div>(None should have to go)</div>
          </div>
          <mat-slider
            class="slider-width"
            formControlName="highestNumberOfPeopleAskedToGoForAWoundReview"
            [min]="sliderMin"
            [max]="sliderMax"
            thumbLabel
            #highestNumberOfPeopleAskedToGoForAWoundReviewSlider
          >
          </mat-slider>
          <div class="slider-label">
            <div>100</div>
            <div>(It's ok if all have to go)</div>
          </div>
        </div>
        <div style="width: 80%; margin: 10px auto">
          <p>
            From what you've indicated above, you feel it would be ok if
            {{
              lothianForm.get('highestNumberOfPeopleAskedToGoForAWoundReview')
                .value
            }}
            of those 100 people ({{
              lothianForm.get('highestNumberOfPeopleAskedToGoForAWoundReview')
                .value
            }}% of patients) are recommended to go for a wound review but are
            told they are ok, if the other
            {{
              100 -
                lothianForm.get('highestNumberOfPeopleAskedToGoForAWoundReview')
                  .value
            }}
            people do actually have wound infections.
          </p>
        </div>
      </div>
    </mat-card>

    <mat-card class="form-card">
      <div style="margin: 10px 0px">
        <div style="width: 80%; margin: 0 auto">
          <p>
            If there were 100 people who are told their wound doesn't need a
            review (at their GP or hospital), what is the HIGHEST number of
            missed infections you think would be ok?
          </p>
        </div>
        <div
          class="slider-full-width-field verti-center slider-wrapper missedInfectionsSlider"
        >
          <div class="slider-label">
            <div>0</div>
            <div>(All should be picked up first time)</div>
          </div>
          <mat-slider
            class="slider-width"
            formControlName="highestNumberOfMissedInfections"
            [min]="sliderMin"
            [max]="sliderMax"
            thumbLabel
            #highestNumberOfMissedInfectionsSlider
          >
          </mat-slider>
          <div class="slider-label">
            <div>100</div>
            <div>(Its ok if all are missed first time)</div>
          </div>
        </div>
        <div style="width: 80%; margin: 0 auto">
          <p>
            From what you've indicated above, you feel it would be ok if
            {{
              lothianForm.get('highestNumberOfMissedInfectionsSlider').value
            }}
            of those 100 people ({{
              lothianForm.get('highestNumberOfMissedInfectionsSlider').value
            }}% of patients) might have an infection missed until it got worse,
            if the other
            {{
              lothianForm.get('highestNumberOfMissedInfectionsSlider').value
            }}
            people were reassured correctly they didn't.
          </p>
        </div>
      </div>
    </mat-card>

    <button
      mat-raised-button
      class="positive-button"
      type="submit"
      [disabled]="!lothianFormValid()"
    >
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
