import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

const { required } = Validators;
@Component({
  standalone: true,
  selector: 'ic-patient-evaluation-measure-form',
  templateUrl: './patient-evaluation-measure-form.component.html',
  styleUrls: ['./patient-evaluation-measure-form.component.scss'],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class PatientEvaluationMeasureFormComponent implements OnInit {
  @Input() data;
  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  patientEvaluationMeasureForm: UntypedFormGroup;
  isSubmitting = false;
  sliderMin = 1;
  sliderMax = 7;

  ngOnInit(): void {
    this.patientEvaluationMeasureForm = this.fb.group({
      seenSameDoctor: ['', required],
      knewAboutCase: ['', required],
      chanceToTalk: ['', required],
      listenedAndUnderstood: ['', required],
      treatmentProgressInformation: ['', required],
      handAbsent: ['', required],
      handColdDampPain: ['', required],
      handPain: ['', required],
      handPainDuration: ['', required],
      handClumsy: ['', required],
      handFlexible: ['', required],
      handGrip: ['', required],
      handEverdayActivities: ['', required],
      handWork: ['', required],
      handEmbarrassed: ['', required],
      handUpset: ['', required],
      treatmentSatisfactory: ['', required],
      handSatisfactory: ['', required],
      handExpected: ['', required],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.patientEvaluationMeasureForm.controls[key])
          this.patientEvaluationMeasureForm.controls[key].setValue(
            formData[key]
          );
      });
    });
  }

  onSubmit() {
    if (!this.valid()) return;

    this.isSubmitting = true;
    const formResponse = {
      ...this.patientEvaluationMeasureForm.value,
      formId: this.data.formId,
      treatment: 'subtitle_HEADER',
      howYourHandIsNow: 'subtitle_HEADER',
      overallAssessment: 'subtitle_HEADER',
      index: this.data.index || 0,
      treatmentSectionScore: this.getScore('treatment'),
      handSectionScore: this.getScore('hand'),
      overallAssessmentSectionScore: this.getScore('overallAssessment'),
    };
    this.form.submit(formResponse);
  }

  valid(): boolean {
    return (
      this.patientEvaluationMeasureForm.dirty &&
      this.patientEvaluationMeasureForm.valid &&
      !this.isSubmitting
    );
  }

  getScore(section: string) {
    switch (section) {
      case 'treatment': {
        const treatmentSectionFields = [
          'seenSameDoctor',
          'knewAboutCase',
          'chanceToTalk',
          'listenedAndUnderstood',
          'treatmentProgressInformation',
        ];
        return this.calculateScore(treatmentSectionFields);
      }

      case 'hand': {
        const handSectionFields = [
          'handAbsent',
          'handColdDampPain',
          'handPain',
          'handPainDuration',
          'handClumsy',
          'handFlexible',
          'handGrip',
          'handEverdayActivities',
          'handWork',
          'handEmbarrassed',
          'handUpset',
        ];
        return this.calculateScore(handSectionFields);
      }

      case 'overallAssessment': {
        const overallAssessmentFields = [
          'treatmentSatisfactory',
          'handSatisfactory',
          'handExpected',
        ];
        return this.calculateScore(overallAssessmentFields);
      }
    }
  }

  calculateScore(fieldsArray) {
    const calculatedScore = fieldsArray.reduce((result, item) => {
      result += +(
        this.patientEvaluationMeasureForm?.controls?.[item].value || 0
      );
      return result;
    }, 0);

    return calculatedScore;
  }
}
