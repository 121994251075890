import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { cloneDeep } from 'lodash-es';
import { ConfirmationService } from 'primeng/api';
import { Observable, from, map, switchMap, take, tap } from 'rxjs';
import { fieldEditingFormController } from '../../forms/field-editor.form';
import {
  ClinicalFormsEditService,
  CurrentlyEditingRef
} from '../../services/clinical-forms-edit/clinical-forms-edit.service';
import { ClinicalFormEditorStore } from '../../store/clinical-forms-editor.store';

@UntilDestroy()
@Component({
  selector: 'ic-clinical-form-editor-page',
  templateUrl: './clinical-form-editor-page.component.html',
  styleUrls: ['./clinical-form-editor-page.component.scss'],
  providers: [ClinicalFormEditorStore, ConfirmationService]
})
export class ClinicalFormEditorPageComponent implements OnInit {
  teamId: string;
  formId: string;

  currentlyEditing$: Observable<CurrentlyEditingRef>;
  sidebarVisible = false;
  fieldConfigForSidebarForm: FormlyFieldConfig[];
  sidebarForm = new FormGroup({});
  options: FormlyFormOptions;

  clinicalFormFieldConfig$: Observable<FormlyFieldConfig[]>;
  clinicalFormOptions = {
    formState: {
      model: {},
      calculationValues: {},
      user: true,
      isEditing: true
    }
  };
  clinicalFormKeys: string[] = [];

  constructor(
    private store: ClinicalFormEditorStore,
    private formEditService: ClinicalFormsEditService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute
  ) { }

  async ngOnInit(): Promise<void> {
    this.clinicalFormFieldConfig$ = this.getFieldConfigForForm$();

    this.formEditService.addField$
      .pipe(untilDestroyed(this))
      .subscribe(({ targetObject, insertBefore, objectToInsert }) =>
        this.store.addField({ targetObject, insertBefore, objectToInsert })
      );

    this.currentlyEditing$ = this.formEditService.currentlyEditing$?.pipe(
      tap(fieldBeingEdited => {
        this.store.setFieldBeingEdited(cloneDeep(fieldBeingEdited.data));
        this.store.setResetState(cloneDeep(fieldBeingEdited.data));
        this.store.setTargetObject(cloneDeep(fieldBeingEdited.targetObject));

        this.options = {
          formState: {
            model: cloneDeep(fieldBeingEdited.data),
            fieldEditingFormController: fieldEditingFormController,
            clinicalFormKeys: this.clinicalFormKeys?.filter(
              key => key !== fieldBeingEdited?.data?.key
            )
          }
        };
        this.fieldConfigForSidebarForm = fieldBeingEdited.form;
        this.sidebarVisible = true;
      })
    );

    this.formEditService.addDuplicateField$
      ?.pipe(untilDestroyed(this))
      .subscribe(({ targetObject, insertBefore, objectToInsert }) =>
        this.store.addDuplicateField({
          targetObject,
          insertBefore,
          objectToInsert
        })
      );
  }

  updateFieldConfig(fieldConfig: FormlyFieldConfig): void {
    this.store.updateFieldConfig({
      fieldConfig
    });
  }

  closeSidebar(): void {
    this.sidebarVisible = false;
  }

  deleteField(fieldToDelete: FormlyFieldConfig, event: Event) {
    this.confirmationService.confirm({
      target: event.target,
      message: `Are you sure that you want to delete "${fieldToDelete?.props?.label ?? fieldToDelete?.key ?? 'this field'
        }"?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.store.deleteField({ fieldToDelete });
        this.closeSidebar();
      }
    });
  }

  resetFormToInitialState() {
    this.store.resetState$
      .pipe(
        take(1),
        tap(resetState => (this.options.formState.model = resetState))
      )
      .subscribe();
  }

  openSaveFormDialog(clinicalFormFieldConfig: FormlyFieldConfig[]): void {
    this.formEditService.openSaveFormDialog(
      clinicalFormFieldConfig,
      this.teamId,
      this.formId
    );
  }

  private getFieldConfigForForm$(): Observable<FormlyFieldConfig[]> {
    return this.route.queryParamMap.pipe(
      take(1),
      map(params => ({
        formId: params.get('formId'),
        teamId: params.get('teamId')
      })),
      tap(({ teamId, formId }) => {
        this.formId = formId;
        this.teamId = teamId;
      }),
      switchMap(({ formId }) =>
        from(this.formEditService.getFormFieldConfigs(formId))
      ),
      tap(fieldConfigsFromForm =>
        this.store.setFieldConfigs(fieldConfigsFromForm)
      ),
      switchMap(() => this.store.fieldConfigs$),
      tap(fieldConfigs => {
        this.clinicalFormKeys =
          this.formEditService.getKeysFromForm(fieldConfigs);
      })
    );
  }
}
