<h2 class="px-4" *ngIf="field?.props?.label">{{ field?.props?.label }}</h2>
<ng-container *ngIf="{ activeIndex: activeStep$ | async } as vm">
  <div [class.py-4]="!isMobile" class="w-full">
    <div *ngIf="steps?.length > 1" #stepper class="clinical-form-stepper my-2 mx-4">
      <div class="progress-bar" [style.width]="percentageProgress"></div>
      <button
        *ngFor="let step of steps; let i = index"
        [class.active]="vm.activeIndex === i"
        [disabled]="!step.canClick()"
        (click)="step.onClick()"
        [ngClass]="{
          'form-step-complete': i < vm?.activeIndex
        }"
        class="form-step"
        type="button"
      >
        <span class="step-number" *ngIf="i >= vm?.activeIndex">{{ i + 1 }}</span>
        <span class="step-complete" *ngIf="i < vm?.activeIndex">
          <i class="pi pi-check"></i>
        </span>
      </button>
    </div>

    <div pFocusTrap>
      <formly-field [field]="field?.fieldGroup?.[vm.activeIndex]"></formly-field>

      <div
        [ngClass]="{
          'bg-surface-white w-full fixed bottom-0 shadow-5 py-3': isCalcForm && isMobile
        }"
      >
        <div class="flex justify-content-between mx-4">
          <span>
            <button
              pButton
              type="button"
              name="Back"
              (click)="previousStep()"
              data-cy="previous-form-section"
              class="p-button-outlined"
              [icon]="!isMobile ? 'pi pi-arrow-left' : ''"
              *ngIf="vm.activeIndex !== 0 && steps?.length > 1"
            >
              Previous
            </button>
          </span>
          <button
            pButton
            type="button"
            (click)="nextStep()"
            data-cy="next-form-section"
            [icon]="!isMobile ? 'pi pi-arrow-right' : ''"
            iconPos="right"
            *ngIf="vm.activeIndex < steps?.length - 1"
            [class.disabled]="!isFormSectionValid(field?.fieldGroup?.[vm.activeIndex])"
          >
            Next
          </button>
          <button
            pButton
            *ngIf="vm.activeIndex === steps?.length - 1"
            type="submit"
            [class.disabled]="!isFormSectionValid(field?.fieldGroup?.[vm.activeIndex])"
            data-cy="submit-form"
            (click)="submit($event)"
          >
            {{ isCalcForm ? 'Done' : 'Submit' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
