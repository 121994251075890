<form [formGroup]="entryForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <h3>NB-UVB Phototherapy Information and Consent Form</h3>
    <mat-card class="mat-card-content">
      <p>
        Your doctor has referred you to the Phototherapy Unit for a course of
        narrowband UVB (NB-UVB) phototherapy to treat your skin condition. This
        form explains about this treatment in detail, including its benefits,
        risks and alternatives. If you have any questions or concerns, please
        speak to a doctor or nurse caring for you. At the bottom of the page
        there will be a section for you to sign if you are happy to go ahead
        with the treatment.
      </p>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>What is phototherapy?</h4>
      <p>
        Natural sunlight has been known to be beneficial in certain skin
        disorders for thousands of years. The ultraviolet part of the radiation
        produced by the sun (UVR) is used in phototherapy. Although it can’t be
        seen, it is an important part of sunlight and is divided into
        ultraviolet A (UVA) and ultraviolet B (UVB) wavelengths. UVA rays go
        deeper into the skin than other types of UVR and can cause the skin to
        tan. UVB rays do not penetrate as deeply but can cause the skin to burn.
        In phototherapy we use carefully calculated doses of UV light to
        minimise this risk.
      </p>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>What is UVB?</h4>
      <p>
        UVB stands for ultraviolet B. Narrowband UVB (NB-UVB) is a treatment
        where a small part of the UVB light spectrum is used to treat skin
        conditions. Treatment lamps produce these special rays, which have
        anti-inflammatory effects on the skin. Although these rays are also
        present in natural sunlight, UVB treatment is usually more effective
        than sunlight because harmful and ineffective sunlight rays are filtered
        out. In this way, we also minimise the risk of burning.
      </p>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>Are there any other types of phototherapy?</h4>
      <p>
        The other phototherapy treatments are PUVA (psoralen plus UVA, where UVA
        radiation is combined with a chemical called psoralen that increases the
        effect of UVA on the skin) and UVA1. The choice of phototherapy
        treatment depends upon your skin condition.
      </p>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>Why am I having this treatment?</h4>
      <p>
        This is the most suitable treatment for your skin condition. It is often
        recommended if you have tried ointments and creams without success but
        before trying tablet medication. NB-UVB has been found to be useful for
        many different skin conditions, including psoriasis, eczema and
        vitiligo. NB-UVB is also used to build up tolerance to natural
        ultraviolet light in people with light-sensitive skin conditions. Most
        people find that their skin has improved after about 30 treatments and
        that it remains clear for three to four months or more. However, the
        number of treatments needed and the results of the treatment vary from
        one person to another and from one skin disease to another.
      </p>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>Important points to remember before and during your treatment</h4>
      <ul>
        <li>
          You need to commit to attending the Phototherapy Department two to
          three times a week. Attending regularly helps to achieve better
          results from your treatment.
        </li>
        <li>
          You should reduce your exposure to the sun to minimise the risk of
          sunburn. Cover up with long sleeved clothes, particularly on sunny
          days. You may also need to wear a hat. Use a sunscreen with a sun
          protection factor (SPF) of a least 50 and a UVA seal of protection,
          and reapply it regularly. Please do not sunbathe or use a sunbed
          during the whole course of your treatment.
        </li>
        <li>
          Let us know if you have a haircut or if, for any other reason, any
          areas of skin become newly exposed during the course of treatment. If
          you wear clothing during your treatment, it is important that you wear
          the same clothing or the exact same style for every treatment of your
          course.
        </li>
        <li>
          Before you start UVB treatment, the doctor and nurse will check the
          medicines that you are taking. If any medications you are taking
          (including anything bought over the counter) change during your UVB
          treatment, please let the nursing staff know before they start your
          next treatment. This is because some tablets can affect the way your
          skin reacts to UVB.
        </li>
        <li>Men must wear genital protection while in the machine.</li>
        <li>
          If you are planning a pregnancy or think that there is a chance you
          could become pregnant during the treatment, we advise that you take
          the recommended pre-pregnancy folic acid supplements.
        </li>
      </ul>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>On the day of your treatment</h4>
      <ul>
        <li>
          Do not wear perfumes, deodorants, aftershave lotions or other cosmetic
          products before your treatment. Some of these contain substances which
          make your skin more sensitive to light. This can cause patchy
          discolouration of the skin and take some months to fade. You can use
          them after each treatment.
        </li>
        <li>
          Please let us know if you have started any new medications or creams
          as some can make your skin more sensitive to light.
        </li>
        <li>
          On treatment days, please do not apply any creams or ointments to your
          skin before you go in the machine apart from an appropriate
          moisturiser. We suggest that you moisturise with a water-based
          moisturiser beforehand as this helps your skin to absorb the
          ultraviolet light. Please ask your nursing team for more information
          on this.
        </li>
        <li>
          Do not use oily creams as these could cause burning or prevent the UVA
          from being absorbed.
        </li>
      </ul>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>What can I expect on my first treatment?</h4>
      <p>
        On your first visit to the unit you will have a pre-treatment nursing
        assessment. A member of the phototherapy nursing staff will also show
        you round the unit and will explain the treatment and all the procedures
        to you. Before you begin light treatment, you will usually receive a
        series of small test doses, called MED (minimal erythema dose). A number
        of doses of UVB light will be shone on your back or arm, this takes
        about 25 minutes and the result will be read 24 hours later. This result
        will help us decide what your starting dose should be. This may also be
        important if you suspect you are unusually sensitive to sunlight or if
        you are taking medications that make you extra sensitive. Otherwise, we
        may simply ask you about your history of sun exposure, for example
        whether you tan or burn, and use your skin type to determine your
        starting dose. Even if you have had UVB treatment in the past, an MED
        will normally be required because your sensitivity to UVB can change.
        The MED not only makes sure that you are treated at a safe UVB dose, but
        also that you are started at a dose that is high enough. If an MED
        cannot be done, treatment has to be started at a low dose.
      </p>
      <p>
        Your first treatment in the phototherapy machine will most likely happen
        on your second visit.
      </p>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>What happens during treatment?</h4>
      <p>
        The nurse will call you from the waiting room when the machine is
        available for your treatment. They will examine your skin on each visit
        and ask you some questions before you enter the machine. We will give
        you goggles to protect your eyes, which you must wear at all times
        during your treatment. You may also be asked to wear a visor if your
        face is not being treated. We will show you how to stand in the correct
        position in the machine to make sure that all your skin is exposed
        evenly.
      </p>
      <p>
        You will be in the UVB machine for up to 10 minutes. We closely monitor
        the amount of UVB you receive and we will increase the dose with each
        treatment as long as you have tolerated the previous treatment. The
        machine is quite bright and you may feel warm if you need to stay in the
        booth for a long time. Please let the nursing staff know if you feel
        uncomfortable or unwell.
      </p>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>How long do treatments last?</h4>
      <p>
        This depends on your skin condition and varies from person to person. We
        generally give treatments two to three times a week for 10 to 16 weeks.
        Individual treatments can last up to 10 minutes, with the total visit
        taking up to half an hour. The total number of treatments you have will
        depend on your response to treatment and on your specific skin
        condition.
      </p>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>What are the risks with UVB phototherapy?</h4>
      <p>
        Your doctor or nurse will discuss the possible complications of this
        treatment with you in more detail, but you need to be aware of the
        possible side effects listed below.
      </p>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>Effects that could happen during your course:</h4>
      <ul>
        <li>
          It is likely that you will get a mild sunburn reaction at least once
          during your course. If you get any severe sunburn-like reactions then
          you should contact the Phototherapy Unit and speak to one of the photo
          nurses. If there is any delay in getting further advice, you can apply
          steroid ointment (if you have some) as well as moisturisers.
        </li>
        <li>
          You may get a spotty, itchy rash (prickly heat). This affects about 1
          in 10 people.
        </li>
        <li>You may develop a dark tan.</li>
        <li>
          Your skin may become dry and itchy. Regular use of moisturisers will
          help to minimise this.
        </li>
        <li>
          Cold sores may affect 1 in 30 people who have UVB phototherapy to the
          facial area. Tell your nurse if you notice early signs of a cold sore
          such as tingling or a painful bump developing on your lips. If you
          have a history of cold sores we will apply sunblock to your lips
          before each treatment.
        </li>
        <li>
          The disease being treated may worsen instead of improve. Your doctor
          will advise you of the risk of this happening.
        </li>
      </ul>
    </mat-card>

    <h4>Risks associated with multiple courses:</h4>

    <mat-card class="mat-card-content">
      <h4>Skin cancer</h4>
      <p>
        People who have had a lot of sunlight exposure have an increased risk of
        cancer. In the UK approximately 1 in 10 of us will develop skin cancer.
        Although we do not know the risk of NB-UVB, if we assume that it is the
        same as with sunlight, a course every year over the whole of someone’s
        life would generally double the risk of them developing treatable skin
        cancer. Many factors influence this risk. If you are not having your
        face treated, the risk will be reduced. It is usual practice to be asked
        to attend your local clinic for a skin cancer review once you have
        received more than 500 UVB treatments.
      </p>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>Photoaging</h4>
      <p>
        If you need many treatments you may develop sunlight-induced skin
        changes, such as wrinkling and skin discolouration, similar to those of
        the ageing process or produced by cigarette smoking.
      </p>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>Will I have any follow-up appointments?</h4>
      <p>
        You will usually have a follow-up appointment with a doctor six months
        after your treatment, or sooner if you are having problems. You will be
        able to book your appointments in advance. Generally you will be offered
        appointments as near to your preferred time slot as possible, however we
        cannot guarantee the same time slot for all your visits.
      </p>
    </mat-card>

    <mat-card class="mat-card-content">
      <h4>Consent - asking for your consent</h4>
      <p>
        We want to involve you in decisions about your care and treatment. If
        you decide to go ahead, you will be asked to sign a consent form at the
        bottom of this page. This states that you agree to have the treatment
        and you understand what it involves. If you would like more information
        about our consent process, please speak to a member of staff caring for
        you.
      </p>
      <p>
        For more information leaflets on conditions, procedures and treatments
        please visit www.bad.org.uk/for-the-public/patient-information-leaflets
      </p>
      <ul>
        <li>
          I confirm I have read and understood what the treatment entails.
        </li>
        <li>I consent to having and MED test and NB UVB light treatment.</li>
      </ul>
    </mat-card>

    <div>
      <mat-form-field
        appearance="fill"
        required
        class="notes-field half-width-field"
      >
        <mat-label>Patient name</mat-label>
        <input
          matInput
          formControlName="patientName"
          type="notes"
          placeholder="Patient name"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <button
      mat-raised-button
      class="positive-button"
      type="submit"
      [disabled]="!valid()"
    >
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
