import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  CoordinateObj,
  MlFeatureDetections,
  SsiMlOptions,
  WithId,
} from '@islacare/ic-types';
import { fabric } from 'fabric';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  EntriesService,
  EntryWithUrlLarge,
} from '../../../services/entries/entries.service';
import { isMobile } from '../../../utils/isMobileView';

@Component({
  selector: 'ic-ml-features-detected-dialog',
  templateUrl: './ml-features-detected-dialog.component.html',
  styleUrls: ['./ml-features-detected-dialog.component.scss'],
})
export class MlFeaturesDetectedDialogComponent implements OnInit {
  isLoading: boolean;
  patientId: string;
  collectionId: string;
  entry: EntryWithUrlLarge & WithId;
  MLDetectionDoc: MlFeatureDetections;
  canvas: any;
  imgWidth: number;
  imgHeight: number;
  isMobile = isMobile;
  entry$: Observable<EntryWithUrlLarge>;
  heightScaleRatio: number;
  widthScaleRatio: number;
  canvasWidth: number;
  canvasHeight: number;
  loadingImagePath = 'assets/loading-image.gif';
  loading = true;
  isViewAllReviewers = false;

  @ViewChild('canvasContainer') canvasContainer: ElementRef<HTMLDivElement>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientId: string;
      collectionId: string;
      entryId: string;
    },
    public entriesService: EntriesService
  ) {}

  async ngOnInit() {
    const { patientId, collectionId, entryId } = this.data;
    this.patientId = patientId;
    this.collectionId = collectionId;
    this.entry$ = this.entriesService
      .getEntryForReviewLarge(this.patientId, this.collectionId, entryId)
      .pipe(
        tap(async (largeEntry) => {
          this.loading = false;
          this.entry = { ...largeEntry, id: entryId };

          //remove wound from features detected
          const woundIndex = this.entry.mlFeaturesDetected.indexOf(
            SsiMlOptions.wound
          );
          if (woundIndex > -1)
            this.entry.mlFeaturesDetected.splice(woundIndex, 1);

          this.canvas = new fabric.StaticCanvas('sheet-ml');
          await this.setCanvasImage();
          await this.setDetectionsOnCanvas();
          this.canvas.renderAll();
        })
      );
  }

  toggleReviewersView() {
    this.isViewAllReviewers = !this.isViewAllReviewers;
  }

  setCanvasImage() {
    return new Promise((resolve) => {
      fabric.Image.fromURL(
        this.entry.urlLarge,
        (image) => {
          //to fit image in canvas according to it's dimensions
          this.imgWidth = image.width;
          this.imgHeight = image.height;
          const imgRatio = this.imgWidth / this.imgHeight;
          this.canvasHeight = this.canvasContainer.nativeElement.clientHeight;

          this.canvasWidth = this.canvasHeight * imgRatio;
          const canvasRatio = this.canvasWidth / this.canvasHeight;

          if (imgRatio <= canvasRatio) {
            if (this.imgHeight > this.canvasHeight) {
              image.scaleToHeight(this.canvasHeight);
            }
          } else {
            if (this.imgWidth > this.canvasWidth) {
              image.scaleToWidth(this.canvasWidth);
            }
          }

          this.heightScaleRatio = this.canvasHeight / this.imgHeight;
          this.widthScaleRatio = this.canvasWidth / this.imgWidth;
          this.canvas.setHeight(this.canvasHeight);
          this.canvas.setWidth(this.canvasWidth);
          this.canvas.add(image);
          resolve('');
        },
        {
          crossOrigin: 'anonymous',
        }
      );
    });
  }

  async setDetectionsOnCanvas() {
    this.MLDetectionDoc = await this.entriesService.getEntryMLFeatureDetections(
      this.patientId,
      this.collectionId,
      this.entry.id
    );

    this.checkForWoundLocalisedRedness();

    this.entry.mlFeaturesDetected.forEach((feature: string) => {
      const ssiMlOption = feature as SsiMlOptions;
      const detectionsArray = this.MLDetectionDoc[ssiMlOption];
      detectionsArray.forEach((coordinateObj: CoordinateObj) => {
        const scaledObj = this.scaleCoordinates(coordinateObj);
        this.drawRect(ssiMlOption, scaledObj);
      });
    });
  }

  checkForWoundLocalisedRedness() {
    //if entry has both wound and localisedRedness present, then assign all wound coords to localisedRedness.
    const localisedRednessIndex = this.entry.mlFeaturesDetected.indexOf(
      SsiMlOptions.localisedRedness
    );

    if (localisedRednessIndex > -1 && this.MLDetectionDoc[SsiMlOptions.wound])
      this.MLDetectionDoc[SsiMlOptions.localisedRedness] = [
        ...this.MLDetectionDoc[SsiMlOptions.wound],
      ];
  }

  scaleCoordinates(coordinateObj: CoordinateObj) {
    const newObj = Object.assign({}, coordinateObj);
    newObj.xmin = coordinateObj.xmin * this.widthScaleRatio;
    newObj.xmax = coordinateObj.xmax * this.widthScaleRatio;
    newObj.ymin = coordinateObj.ymin * this.heightScaleRatio;
    newObj.ymax = coordinateObj.ymax * this.heightScaleRatio;

    return newObj;
  }

  drawRect(feature: SsiMlOptions, scaledObj: CoordinateObj) {
    const rectangle = new fabric.Rect({
      top: scaledObj.ymin,
      left: scaledObj.xmin,
      width: scaledObj.xmax - scaledObj.xmin,
      height: scaledObj.ymax - scaledObj.ymin,
      stroke: this.entriesService.colorMap[feature] || '#000000',
      strokeWidth: 2,
      fill: '',
    });

    this.canvas.add(rectangle);
  }
}
