<ng-select
  [placeholder]="props?.placeholder || 'Please select'"
  [items]="props.options | formlySelectOptions: field | async"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [clearable]="!props.required"
  [multiple]="true"
  [bindLabel]="field?.props?.optionLabel || 'label'"
  [bindValue]="field?.props?.optionValue || 'value'"
  [groupBy]="field?.props?.group || false"
  [groupValue]="field?.props?.optionGroupChildren || 'items'"
  styleClass="w-full"
>
</ng-select>
