<ng-container *ngIf="!isMobile; else mobileView">
  <ng-container *ngIf="!shouldShowAddButton">
    <div
      data-cy="dropzone"
      class="dropzone"
      [class.hovering]="isHovering"
      [ngStyle]="desktopStylesObj"
    >
      <h3>File Drop Zone</h3>
      <p>Drag and Drop a file...</p>
      <label class="file-label">
        <input
          #fileInput
          class="file-input"
          [multiple]="!isSingleFileUpload"
          type="file"
          data-cy="file-selector-add"
          (change)="onFileSelect($event)"
          accept="acceptableFileTypes"
        />
        ...or choose a file
      </label>
    </div>
  </ng-container>

  <ng-container *ngIf="shouldShowAddButton">
    <div class="flex flex-row justify-content-end m-3">
      <button
        (click)="fileSelect.click()"
        icon="pi pi-upload"
        label="Add file"
        pButton
      ></button>

      <input
        class="file-input"
        multiple
        type="file"
        data-cy="file-selector-add-second-input"
        (change)="onFileSelect($event)"
        accept="acceptableFileTypes"
        aria-label="File select"
        #fileSelect
      />
    </div>
  </ng-container>
</ng-container>

<ng-template #mobileView>
  <ng-container *ngIf="!shouldShowAddButton">
    <div class="mobile-container" [ngStyle]="mobileStylesObj">
      <h2>{{ captureText | titlecase }} Upload</h2>
      <p>Please take a {{ captureText }} or choose an existing one to upload</p>
      <label class="file-label">
        <input
          class="file-input"
          multiple
          type="file"
          (change)="onFileSelect($event)"
          accept="acceptableFileTypes"
        />
        Add {{ captureText }}
      </label>
    </div>

    <div class="file-preview-container">
      <p style="font-weight: 200">Uploaded files must be less than 500 MB</p>
    </div>
  </ng-container>

  <ng-container *ngIf="shouldShowAddButton">
    <div class="top-container">
      <label class="add-more-button" icEventTracker="sf-add-file">
        <input
          class="file-input"
          multiple
          type="file"
          (change)="onFileSelect($event)"
          accept="acceptableFileTypes"
        />
        Add File
      </label>
    </div>
  </ng-container>
</ng-template>
