import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

export interface AuthState {
  loading: boolean;
  error: string;
  submitting: boolean;
  redirectUrl: string;
  waitingForAuthFlow: boolean;
}

const defaultState: AuthState = {
  loading: false,
  error: null,
  submitting: false,
  redirectUrl: null,
  waitingForAuthFlow: false
};

@Injectable()
export class AuthStore extends ComponentStore<AuthState> {
  readonly full$: Observable<AuthState> = this.select(state => state);
  readonly error$: Observable<string> = this.select(state => state.error);
  readonly submitting$: Observable<boolean> = this.select(state => state.submitting);

  constructor() {
    super(defaultState);
  }

  readonly resetStore = this.updater(() => defaultState);

  readonly clearUser = this.updater(state => ({ ...state, user: null }));
  setUser(user: any) {
    this.setState(state => {
      return {
        ...state,
        user: user
      };
    });
  }

  setLoading(loading: boolean) {
    this.setState(state => ({
      ...state,
      loading: loading
    }));
  }

  setSubmitting(submitting: boolean) {
    this.setState(state => ({
      ...state,
      submitting: submitting
    }));
  }

  setError(error: string) {
    this.setState(state => ({
      ...state,
      error: error
    }));
  }

  setRedirectUrl(redirectUrl: string) {
    if (redirectUrl === '/patients') return;
    this.setState(state => ({
      ...state,
      redirectUrl: redirectUrl
    }));
  }

  setWaitingForAuthFlow(waitingForAuthFlow: boolean) {
    this.setState(state => ({
      ...state,
      waitingForAuthFlow: waitingForAuthFlow
    }));
  }
}
