import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class LayoutDefaultSidebarService {
  private _showSidebar = new BehaviorSubject<boolean>(false);
  private _showMenuButton = new BehaviorSubject<boolean>(false);

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
  ) {
    this.monitorBreakpoints();
    this.monitorRouteChange();
  }

  get showSidebar$(): Observable<boolean> {
    return this._showSidebar.asObservable();
  }
  set showSidebar(value: boolean) {
    this._showSidebar.next(value);
  }

  get showMenuButton$(): Observable<boolean> {
    return this._showMenuButton.asObservable();
  }

  private monitorBreakpoints(): void {
    // This is the trigger that is met when the nav items start to get hidden
    const smallScreenBreakpoint = '(max-width: 960px)';

    this.breakpointObserver
      .observe([Breakpoints.Handset, smallScreenBreakpoint])
      .pipe(
        map(result => result.matches),
        tap(isMobileOrTabletOrCustom => {
          this._showMenuButton.next(isMobileOrTabletOrCustom);
          if (!isMobileOrTabletOrCustom) {
            this._showSidebar.next(false);
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private monitorRouteChange(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this._showSidebar.next(false);
      });
  }
}
