import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RadioButtonModule } from 'primeng/radiobutton';

export interface RadioButtonControl {
  key: string;
  label: string;
  description?: string;
  icon?: string;
  color?: string;
  setDefault?: boolean;
}

@Component({
  standalone: true,
  selector: 'ic-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  imports: [CommonModule, ReactiveFormsModule, FormsModule, RadioButtonModule]
})
export class RadioButtonComponent implements OnInit {
  @Input() options: any[];
  @Output() selectedValue: EventEmitter<any> = new EventEmitter();

  selected: any;

  ngOnInit(): void {
    this.setSelectedDefault();
  }

  setSelected(option: any): void {
    this.selected = option;
    this.selectedValue.emit(this.selected);
  }

  setSelectedDefault(): void {
    this.options.forEach(option => {
      if (option.setDefault) {
        this.selected = option;
      }
    });
  }
}
