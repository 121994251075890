export default [
  'dateOfDischarge',
  'ward',
  'category',
  'surgicalWound',
  'woundClosureMaterial',
  'woundClosureRemovalDate',
  'woundClosureRemovalNeededBy',
  'surgicalWoundAssessment',
  'drainClinicAppointment',
  'drainClinicAppointmentDate',
  'microbiologyResults',
  'drainSites',
  'drainSuturesRemovalDate',
  'additionalResources',
  'responsibleSurgeon',
  'staffProvidingPad',
  'chaperonePresent',
  'notes'
];
