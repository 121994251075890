import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { FormField, UserInputType } from '@islacare/ic-types';

@Component({
  selector: 'ic-automatic-input',
  templateUrl: './automatic-input.component.html',
  styleUrls: ['./automatic-input.component.scss'],
})
export class AutomaticInputComponent implements OnInit {
  @Input() field: FormField;
  @Input() control: UntypedFormControl;

  constructor(public formService: FormService) {}
  ngOnInit() {
    if (
      this.field?.inputType === UserInputType.DATE_FIELD &&
      this.field?.setTodaysDate &&
      this.control.value === null
    ) {
      return this.control.setValue(new Date().toISOString().split('T')[0]);
    }
  }
  getType(inputType: string) {
    switch (inputType) {
      case 'text-field':
        return 'text';
      case 'number-field':
        return 'number';
      case 'date-field':
        return 'date';
      default:
        return 'text';
    }
  }
}
