<textarea
  pInputTextarea
  [autoResize]="field?.props?.autoResize"
  [rows]="field?.props?.rows || 5"
  [cols]="field?.props?.cols || 30"
  [placeholder]="field?.props?.placeholder"
  [disabled]="field?.props?.disabled"
  [formControl]="formControl"
  [formlyAttributes]="field"
></textarea>
