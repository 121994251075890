export default [
  //Video Consent Form Order
  'childName',
  'permission',
  'dateOfConsent',
  'relationshipToChild',
  'parentOrGuardianName',

  'notes'
];
