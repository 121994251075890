import { CommonModule } from '@angular/common';
import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  NgZone,
  OnChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoggingService } from '@ic-monorepo/services';
import { VideoConvertStatus } from '@islacare/ic-types';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import * as pdf from 'ng2-pdf-viewer';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { AuthImagePipe } from '../../pipes/auth-image/auth-image.pipe';
import { FilePlaceholderComponent } from '../file-placeholder/file-placeholder.component';
import { ImageModule } from 'primeng/image';

const isIE11 = /Trident.*rv[ :]*11\./.test(navigator.userAgent);

const modules = [
  CommonModule,
  VgBufferingModule,
  VgControlsModule,
  VgCoreModule,
  VgOverlayPlayModule,
  PinchZoomModule,
  ButtonModule,
  ImageModule,
  TooltipModule,
  PdfViewerModule,
];
const pipes = [AuthImagePipe];

@Component({
  standalone: true,
  selector: 'ic-media-display',
  templateUrl: './media-display.component.html',
  styleUrls: ['./media-display.component.scss'],
  imports: [FilePlaceholderComponent, ...modules, ...pipes],
})
export class MediaDisplayComponent implements OnChanges {
  pdfPage = 1;
  totalPdfPages: number;
  pdfIsLoaded = false;

  @Input() source: Uint8Array | string | SafeResourceUrl;
  @Input() video: boolean;
  @Input() sensitiveImage: boolean;
  @Input() fileType: string;
  @Input() enableZoom = false; // default
  @Input() entryId: string; //used to link to the right place
  @Input() collectionId: string; //used to link to the right place
  @Input() patientId: string; //used to link to the right place
  @Input() videoConvertStatus: any;
  @Input() enableAuthImage = true;
  @Input() minHeight = 'initial';
  @Input() hideVideoControls = false; // default
  @Input() hidePdfControls = false;
  @Input() isLargeFile: boolean;

  @ViewChild('shadowHost', { read: ViewContainerRef, static: true })
  shadowHost: ViewContainerRef;
  componentRef: ComponentRef<any>;
  brokenImg = false;
  VideoConvertStatusEnum = VideoConvertStatus;
  sanitzedSource: SafeResourceUrl;

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private componentFactoryResolver: ComponentFactoryResolver,
    private log: LoggingService,
    private santizer: DomSanitizer
  ) {}

  ngOnChanges(changes: any): void {
    const fileType = changes['fileType']?.currentValue;
    if ((fileType === 'pdf' || fileType === 'application/pdf') && !isIE11) {
      this.loadPdfViewer(pdf.PdfViewerComponent);
    }

    if (changes?.source?.currentValue) {
      this.sanitzedSource = this.santizer.bypassSecurityTrustResourceUrl(
        changes.source.currentValue
      );
    }
  }

  onPlayerReady(event: any): void {
    if (event?.getDefaultMedia()) {
      this.log.consoleLog('onPlayer', event, event.duration);

      event
        .getDefaultMedia()
        .subscriptions.loadedMetadata.subscribe(($event: any) => {
          this.log.consoleLog('API  ', $event.duration);
        });
    }
  }

  loadPdfViewer(pdfViewer: any): void {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(pdfViewer);
    const viewContainerRef = this.shadowHost;

    this.componentRef =
      viewContainerRef && viewContainerRef.createComponent(componentFactory);

    if (this.componentRef && this.componentRef.instance) {
      this.componentRef.instance.src = this.source;
      this.componentRef.instance.afterLoadComplete.subscribe((ev: any) => {
        this.afterLoadComplete(ev);
      });
    }
  }

  afterLoadComplete(pdfData: any) {
    this.totalPdfPages = pdfData.numPages;
    this.pdfIsLoaded = true;
  }

  nextPage() {
    this.pdfPage++;
  }

  prevPage() {
    this.pdfPage--;
  }

  goToEntry() {
    if (this.entryId && this.collectionId) {
      this.ngZone.run(() =>
        this.router.navigate([
          '/patients',
          this.patientId,
          'review',
          this.collectionId,
          this.entryId,
        ])
      );
    } else {
      return;
    }
  }

  handleMissingImage(event: any) {
    (event.target as HTMLImageElement).style.display = 'none';
    this.brokenImg = true;
  }
}
