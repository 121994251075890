import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { captureException, captureMessage } from '@sentry/angular-ivy';
import MediaInfoFactory, { MediaInfo, ReadChunkFunc } from 'mediainfo.js';
import { MessageService } from 'primeng/api';

export interface ReadFile {
  file: File;
  fileType: string;
  fileUrl: string;
  srcData: SafeResourceUrl | string;
  isVideo: boolean;
  isLargeFile: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ReadFileService {
  acceptedFileTypes = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'image/tif',
    'image/bmp',
    'video/mp4',
    'video/quicktime',
    'video/avi',
    'video/webm',
    'audio/mp3',
    'audio/wav',
    'application/pdf',
    'file/e2e',
    '.e2e',
    'text/csv',
  ];

  constructor(
    private messageService: MessageService,
    private sanitizer: DomSanitizer
  ) {}

  async readFile(file: File): Promise<ReadFile> {
    const fileSizeInMB: number = file.size / (1024 * 1024);
    const fileReader: FileReader = new FileReader();
    const isLargeFile = fileSizeInMB > 500;
    const fileType: string = file.type;

    if (!this.isValidFile(fileType, fileSizeInMB, file)) {
      captureException('Invalid file');
      return;
    }

    return new Promise((resolve, reject) => {
      fileReader.onloadend = async (event: ProgressEvent<FileReader>) => {
        const isVideo = fileType.split('/')[0] === 'video';
        const fileUrl =
          isVideo && !isLargeFile ? URL.createObjectURL(file) : null;
        const srcData = isVideo
          ? this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl)
          : event.target.result;

        const fileObj: ReadFile = {
          file,
          fileType,
          fileUrl,
          srcData,
          isVideo,
          isLargeFile,
        };

        if (isVideo) {
          MediaInfoFactory({ format: 'object' }, (mediainfo: MediaInfo) => {
            this.getVideoMetaData(mediainfo, file).then(() => {
              resolve(fileObj);
            });
          });
        } else {
          resolve(fileObj);
        }
      };

      fileReader.onerror = (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Something went wrong',
          detail: `Error reading file`,
        });
        captureException(error);
        reject(error);
      };

      fileReader.readAsDataURL(file);
    });
  }

  isValidFile(fileType: string, fileSizeInMB: number, file: File): boolean {
    if (!this.isAcceptableFileType(fileType)) {
      const message = `The file type of "${fileType}" is not supported and cannot be accepted for file: ${file.name}`;
      this.messageService.add({
        severity: 'info',
        summary: 'Info',
        detail: message,
      });
      return false;
    }

    if (!this.isAcceptableFileSize(fileType, fileSizeInMB)) {
      const message = `The file "${file.name}" exceeds the size limit and cannot be accepted.`;
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: message,
      });
      return false;
    }

    return true;
  }

  isAcceptableFileType(fileType: string): boolean {
    return this.acceptedFileTypes.includes(fileType);
  }

  isAcceptableFileSize(
    fileType: string,
    fileSizeInMb: number,
    maxFileSizeInMb = 2000
  ): boolean {
    const acceptableFileSizeInMb = this.acceptedFileTypes.some((type) =>
      fileType.includes(type)
    )
      ? maxFileSizeInMb
      : 0;

    return fileSizeInMb < acceptableFileSizeInMb;
  }

  getVideoMetaData(mediainfo: MediaInfo, file): Promise<string> {
    return new Promise<string>((resolve) => {
      const noFileMessage = `No media file provided`;

      if (!file) {
        captureMessage(noFileMessage);
        return resolve(noFileMessage);
      }

      const getSize = () => file.size;

      const readChunk: ReadChunkFunc = (chunkSize, offset) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = (event: ProgressEvent<FileReader>) => {
            if (event.target.error) {
              reject(event.target.error);
            } else {
              resolve(new Uint8Array(event.target.result as ArrayBuffer));
            }
          };

          reader.onerror = (error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Something went wrong',
              detail: `Error fetching video meta data`,
            });
            captureException(error);
            reject(error);
          };

          reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize));
        });

      const analyzeDataPromise = mediainfo.analyzeData(
        getSize,
        readChunk
      ) as Promise<string>;

      analyzeDataPromise
        .then((result: string) => resolve(result))
        .catch(() => resolve("Can't get media information"));
    });
  }
}
