<span [class.hidden]="!formState?.user && field?.props?.showForLoggedInUsersOnly" class="relative">
  <div class="mb-4" [id]="key" tabindex="0" *ngIf="field.type !== ClinicalFormField.CALCULATION">
    <label
      *ngIf="props.label && props.hideLabel !== true"
      [attr.id]="key"
      [attr.for]="id"
      class="question-label block font-semibold mb-2"
    >
      {{ props.label }}
      <span *ngIf="props.required && props.hideRequiredMarker !== true" aria-hidden="true">*</span>
    </label>

    <p *ngIf="props.subtitle" [attr.id]="key" [attr.for]="id" class="text-sm block mb-4 mt-0">
      {{ props.subtitle }}
    </p>

    <div class="field-wrapper">
      <ng-container #fieldComponent></ng-container>
    </div>

    <div class="mt-2 ml-1">
      <small *ngIf="props?.description && !showError" class="flex gap-1">
        <i class="pi pi-info-circle"></i>
        <span>{{ props?.description }}</span>
      </small>
      <small
        *ngIf="showError && field.type !== ClinicalFormField.TABLE"
        class="text-error flex gap-1"
      >
        <i class="pi pi-exclamation-circle"></i>
        <formly-validation-message [field]="field"></formly-validation-message>
      </small>
    </div>
  </div>

  <div *ngIf="field.type === ClinicalFormField.CALCULATION">
    <button
      *ngIf="formState?.isEditing"
      #btn
      aria-label="Edit field"
      label="Edit field"
      type="button"
      pButton
      icon="pi pi-ellipsis-v"
      (click)="menu.toggle($event)"
      class="p-button-rounded p-button-text p-button-secondary absolute top-0 left-100 ml-4"
    ></button>
    <p-tieredMenu #menu [model]="items" [popup]="true" appendTo="body"></p-tieredMenu>

    <div class="mb-4 flex justify-content-between" [id]="key">
      <label
        *ngIf="props.label && props.hideLabel !== true"
        [attr.id]="key"
        [attr.for]="id"
        class="align-items-center flex font-semibold text-lg"
      >
        {{ props.label }}
        <span *ngIf="props.required && props.hideRequiredMarker !== true" aria-hidden="true"
          >*</span
        >
      </label>

      <div class="field-wrapper">
        <ng-container #fieldComponent></ng-container>
      </div>
    </div>

    <div>
      <small *ngIf="props?.description && !showError" class="flex gap-1 mt-1 ml-1">
        <i class="pi pi-info-circle"></i>
        <span>{{ props?.description }}</span>
      </small>
      <small *ngIf="showError && props?.required" class="text-error flex gap-1 mt-1 ml-1">
        <i class="pi pi-exclamation-circle"></i>
        <formly-validation-message [field]="field"></formly-validation-message>
      </small>
    </div>
  </div>
</span>
