<div class="field">
  <div>
    <ic-request
      [teamId]="config?.data?.teamId"
      [collection]="config.data.collection"
      [alertEmailHidden]="true"
      [contentHidden]="true"
      [showLinkLengthHelp]="true"
      (requestTypeEvent)="getRequestResponse($event)"
    >
    </ic-request>
  </div>
</div>
<div class="w-full flex justify-content-end">
  <div class="pr-2">
    <button
      pButton
      type="button"
      label="Cancel"
      aria-label="Cancel"
      class="p-button-outlined"
      (click)="closeDialog()"
    ></button>
  </div>
  <div>
    <button
      [disabled]="!confirmRequestValid"
      (click)="onSubmit(requestResponse)"
      label="Confirm"
      data-cy="request-dialog-positive-button"
      pButton
    ></button>
  </div>
</div>
