import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';

import {
  CollectionTemplate,
  CollectionTemplateType,
  CollectionTemplateWithId,
  GroupedTemplatesWithTeamReference,
} from '@islacare/ic-types';
import firebase from 'firebase/compat/app';
import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { LoggingService } from '../../shared/services/logging/logging.service';
import { UsersService } from '../../shared/services/users/users.service';
import { TeamsService } from '../teams/teams.service';

export interface CollectionTemplateWithUrl extends CollectionTemplate {
  formResponse: any;
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  get myTemplates$(): Observable<GroupedTemplatesWithTeamReference[]> {
    return this.usersService.me$.pipe(
      switchMap(user => (!user ? of([]) : of(user?.teamIds))),
      switchMap(teamIds =>
        teamIds && teamIds.length
          ? combineLatest(teamIds.map(teamId => this.getTemplates$(teamId))).pipe(
              map(templatess => templatess.flat()),
              switchMap(async templates => await this.makeGroupedTemplates(templates)),
            )
          : of([]),
      ),
    );
  }

  constructor(
    private db: AngularFirestore,
    private storage: AngularFireStorage,
    private usersService: UsersService,
    public log: LoggingService,
    private teamsService: TeamsService,
  ) {}

  getTemplate$(teamId: string, templateId: string): Observable<CollectionTemplateWithUrl | CollectionTemplateWithId> {
    return this.db
      .collection('teams')
      .doc(teamId)
      .collection('collectionTemplates')
      .doc(templateId)
      .valueChanges()
      .pipe(switchMap((collection: any) => this.includeUrl$(collection)));
  }

  getTemplates$(teamId: string): Observable<CollectionTemplateWithId[]> {
    return this.db
      .collection<CollectionTemplateWithId>(`teams/${teamId}/collectionTemplates`)
      .valueChanges({ idField: 'id' });
  }

  async updateName(teamId: string, templateId: string, entry: string) {
    try {
      await this.db.doc<CollectionTemplate>(`teams/${teamId}/collectionTemplates/${templateId}`).update({
        name: entry,
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      });
      this.log.consoleLog('Saved name successfully');
    } catch (err) {
      this.log.consoleLog('Unable to save: ', err);
    }
  }

  async updateVideoPath(basePath, fileName, fileType) {
    try {
      await this.db.doc<CollectionTemplate>(`${basePath}`).update({
        videoPath: `${basePath}/${fileName}`,
        fileType: `${fileType}`,
      });

      this.log.consoleLog('Saved updates successfully');
    } catch (err) {
      this.log.consoleLog('Unable to save: ', err);
    }
  }

  async updateTemplate(teamId: string, templateId: string, updates: Partial<CollectionTemplate>) {
    try {
      await this.db.doc<CollectionTemplate>(`teams/${teamId}/collectionTemplates/${templateId}`).set(
        {
          ...updates,
          lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
        } as CollectionTemplate,
        { merge: true },
      );

      this.log.consoleLog('Saved updates successfully');
    } catch (err) {
      this.log.consoleLog('Unable to save: ', err);
    }
  }

  private includeUrl$(
    template: CollectionTemplateWithId,
  ): Observable<CollectionTemplateWithUrl | CollectionTemplateWithId> {
    return template?.videoPath
      ? this.videoUrl(template.videoPath).pipe(
          map(url => ({
            ...template,
            url,
          })),
        )
      : of(template);
  }

  private videoUrl = (path: string): Observable<string> => {
    return this.storage.ref(path).getDownloadURL();
  };

  createFromName(name: string, teamId: string, type: CollectionTemplateType): Promise<DocumentReference> {
    const templateDoc = {
      name: name,
      teamId: teamId,
      lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      type: type,
    };

    return this.db.collection<CollectionTemplate>(`teams/${teamId}/collectionTemplates`).add(templateDoc);
  }

  async makeGroupedTemplates(templates: CollectionTemplateWithId[]) {
    //get all the distict teamIds from the templates
    const teamIds = [...new Set(templates.map(template => template.teamId))].filter(teamId => teamId);

    //get the name & id for these teams, so we can group it accordingly.
    const teams = await Promise.all(
      teamIds.map(async teamId => {
        const teamRef = await this.teamsService.getTeamSnapshot(teamId);
        return {
          name: teamRef.data()?.name,
          id: teamRef.id,
          isPersonal: teamRef.data()?.isPersonal,
        };
      }),
    );

    const groupedTemplates: GroupedTemplatesWithTeamReference[] = [];

    templates.forEach(template => {
      const teamIndex = groupedTemplates.findIndex(item => item.teamId === template.teamId);
      if (teamIndex === -1) {
        const selectedTeam = teams.find(team => team.id === template.teamId);
        //group only those templates which are not in personal teams.
        if (!selectedTeam.isPersonal) {
          const obj: GroupedTemplatesWithTeamReference = {
            teamId: template.teamId,
            templates: [template],
            teamName: selectedTeam?.name,
          };
          groupedTemplates.push(obj);
        }
      } else {
        groupedTemplates[teamIndex]['templates'].push(template);
      }
    });

    return groupedTemplates;
  }
}
