import { Component } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ScheduleTemplateWithId } from '@islacare/ic-types';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogData } from '../dialog-data';

interface IDialogDataEntry extends DialogData {
  textField: string;
  positiveButtonIcon: string;
  placeholder: string;
  existingTemplates?: ScheduleTemplateWithId[];
}

@Component({
  selector: 'ic-data-entry-dialog',
  templateUrl: './data-entry-dialog.component.html',
  styleUrls: ['./data-entry-dialog.component.scss'],
})
export class DataEntryDialogComponent {
  data: IDialogDataEntry;
  form: FormGroup;

  get templateName(): AbstractControl | null {
    return this.form?.get('name');
  }

  get isNameInUse(): boolean {
    return (
      this.data?.existingTemplates?.filter(
        (x) =>
          x.name.toLocaleLowerCase() ===
          this.templateName.value.toLocaleLowerCase()
      ).length > 0
    );
  }

  constructor(
    private fb: FormBuilder,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    this.data = config.data;
    this.form = this.fb.group({
      name: [
        this.data.textField ?? '',
        [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)],
      ],
    });
  }

  closeDialog(templateName: string) {
    this.ref.close(templateName);
  }
}
