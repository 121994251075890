<form [formGroup]="entryForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <p>Is there any redness visible to the surrounding skin?</p>
    <mat-form-field class="half-width-field" appearance="fill">
      <mat-select formControlName="redness">
        <mat-option value="No">No</mat-option>
        <mat-option value="Yes">Yes</mat-option>
      </mat-select>
    </mat-form-field>

    <p>Is there any swelling visible to the surrounding skin?</p>
    <mat-form-field class="half-width-field" appearance="fill">
      <mat-select formControlName="swelling">
        <mat-option value="No">No</mat-option>
        <mat-option value="Yes">Yes</mat-option>
      </mat-select>
    </mat-form-field>

    <p>Is the area more or less painful than it was previously?</p>
    <mat-form-field class="half-width-field" appearance="fill">
      <mat-select formControlName="painful">
        <mat-option value="Less">Less</mat-option>
        <mat-option value="More">More</mat-option>
      </mat-select>
    </mat-form-field>

    <p>How would you rate your pain?</p>
    <mat-slider
      [ngClass]="{ low: low, medium: medium, high: high }"
      class="full-width-field"
      formControlName="painScore"
      value="painScore"
      thumbLabel
      tickInterval="1"
      min="1"
      max="10"
      (change)="setSliderScore($event)"
    >
    </mat-slider>

    <p class="pain-descriptor">{{ painDescriptor }}</p>

    <p>Do you feel well in yourself today?</p>
    <mat-form-field class="half-width-field" appearance="fill">
      <mat-select formControlName="areYouWell">
        <mat-option value="Yes">Yes</mat-option>
        <mat-option value="No">No</mat-option>
      </mat-select>
    </mat-form-field>

    <p>Have you experienced any nausea/vomiting or diarrhea?</p>
    <mat-form-field class="half-width-field" appearance="fill">
      <mat-select formControlName="nauseaVomitingOrDiarrhea">
        <mat-option value="Yes">Yes</mat-option>
        <mat-option value="No">No</mat-option>
      </mat-select>
    </mat-form-field>

    <br />

    <mat-form-field class="full-width-field" appearance="fill">
      <mat-label>Any other comments?</mat-label>
      <textarea matInput formControlName="notes" type="notes"> </textarea>
    </mat-form-field>
    <br />

    <button mat-raised-button class="positive-button" type="submit" [disabled]="isSubmitting">
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
