import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'ic-nhs-error-page',
  templateUrl: './nhs-error-page.component.html',
})
export class NhsErrorPageComponent {
  constructor() {}
}
