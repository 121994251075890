<form [formGroup]="sexualHealthFeedbackForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <p>Age?</p>
    <mat-form-field class="half-width-field">
      <mat-label>Please select an option</mat-label>
      <mat-select formControlName="age">
        <mat-option [value]="age" *ngFor="let age of ageList">
          {{ age }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="age.invalid"
        >Please select an option from the dropdown</mat-error
      >
    </mat-form-field>

    <p>Sex?</p>
    <mat-form-field class="half-width-field">
      <mat-label>Please select an option</mat-label>
      <mat-select formControlName="sex">
        <mat-option [value]="sex" *ngFor="let sex of sexList">
          {{ sex }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="sex.invalid"
        >Please select an option from the dropdown</mat-error
      >
    </mat-form-field>

    <p>
      How satisfied were you with the care received at your most recent virtual
      photo diagnoses appointment?
    </p>
    <mat-form-field class="half-width-field">
      <mat-label>Please select an option</mat-label>
      <mat-select formControlName="satisfiedWithAppointment">
        <mat-option
          [value]="satisfaction"
          *ngFor="let satisfaction of satisfactionList"
        >
          {{ satisfaction }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="satisfiedWithAppointment.invalid"
        >Please select an option from the dropdown</mat-error
      >
    </mat-form-field>

    <p>How did you choose to receive your treatment?</p>
    <mat-form-field class="half-width-field">
      <mat-label>Please select an option</mat-label>
      <mat-select formControlName="treatmentMechanism">
        <mat-option
          [value]="treatmentOption"
          *ngFor="let treatmentOption of treatmentOptions"
        >
          {{ treatmentOption }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="treatmentMechanism.invalid"
        >Please select an option from the dropdown</mat-error
      >
    </mat-form-field>

    <p>How satisfied were you with with how you received your treatment?</p>
    <mat-form-field class="half-width-field">
      <mat-label>Please select an option</mat-label>
      <mat-select formControlName="satisfiedWithTreatment">
        <mat-option
          [value]="satisfaction"
          *ngFor="let satisfaction of satisfactionList"
        >
          {{ satisfaction }}
        </mat-option>
        <mat-option value="Not received">I have not received it yet</mat-option>
        <mat-option value="Not applicable">Not applicable</mat-option>
      </mat-select>
      <mat-error *ngIf="satisfiedWithTreatment.invalid"
        >Please select an option from the dropdown</mat-error
      >
    </mat-form-field>

    <p>In future would you use this service again?</p>
    <mat-form-field class="half-width-field">
      <mat-label>Please select an option</mat-label>
      <mat-select formControlName="wouldUseServiceAgain">
        <mat-option value="Yes">Yes</mat-option>
        <mat-option value="No">No</mat-option>
      </mat-select>
      <mat-error *ngIf="wouldUseServiceAgain.invalid"
        >Please select an option from the dropdown</mat-error
      >
    </mat-form-field>

    <p>Which clinic style do you prefer?</p>
    <mat-form-field class="half-width-field">
      <mat-label>Please select an option</mat-label>
      <mat-select formControlName="clinicStyle">
        <mat-option value="Face to face clinic">Face to face clinic</mat-option>
        <mat-option value="No preference">No preference</mat-option>
        <mat-option value="Virtual Photo diagnoses clinic"
          >Virtual Photo diagnoses clinic</mat-option
        >
      </mat-select>
      <mat-error *ngIf="clinicStyle.invalid"
        >Please select an option from the dropdown</mat-error
      >
    </mat-form-field>

    <br />

    <mat-form-field appearance="fill" class="notes-field half-width-field">
      <input
        matInput
        formControlName="notes"
        type="notes"
        placeholder="Add any additional comments here"
        autocomplete="off"
      />
    </mat-form-field>

    <br />
    <div class="button-group">
      <button
        class="positive-button"
        mat-raised-button
        color="accent"
        type="submit"
        [disabled]="!sexualHealthFeedbackFormValid()"
      >
        {{ isSubmitting ? 'Submitting...' : 'Submit' }}
      </button>
    </div>
  </div>
</form>
