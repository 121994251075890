import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganisationsService } from '../../../../services/organisations/organisations.service';
import { FormService } from '../../../../shared/services/form/form.service';
import { UsersService } from '../../../../shared/services/users/users.service';
import { LayoutDefaultHelper } from '../../helpers/layout-default.helper';
import { LayoutDefaultNavService } from '../../services/layout-default-nav/layout-default-nav.service';
import { LayoutDefaultSidebarService } from '../../services/layout-default-sidebar/layout-default-sidebar.service';

@Component({
  selector: 'ic-layout-default-header',
  templateUrl: './layout-default-header.component.html',
})
export class LayoutDefaultHeaderComponent extends LayoutDefaultHelper implements OnInit {
  constructor(
    layoutDefaultSidebarService: LayoutDefaultSidebarService,
    layoutDefaultNavService: LayoutDefaultNavService,
    organisationsService: OrganisationsService,
    activatedRoute: ActivatedRoute,
    usersService: UsersService,
    formService: FormService,
    router: Router,
  ) {
    super(
      layoutDefaultSidebarService,
      layoutDefaultNavService,
      organisationsService,
      activatedRoute,
      usersService,
      formService,
      router,
    );
  }

  ngOnInit(): void {
    this.getOrgLogoUrl();
  }
}
