import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PatientSubmissionTextsService } from '@ic-monorepo/shared-submissions';
import { FileTypeHelper } from '@utils/helpers';
import { SubmissionFlowMedia } from 'apps/frontend/portal/src/app/feature-patient-submission/store/submission-flow.store';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { ImageModule } from 'primeng/image';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { ShortDateTimePipe } from '../../../../../shared-common/src/lib/pipes/short-date-time/short-date-time.pipe';

export enum MediaCardViewType {
  PATIENT = 'patient',
  CLINICIAN = 'clinician',
  PREVIEW = 'preview'
}

@Component({
  standalone: true,
  selector: 'ic-captured-media-card',
  templateUrl: './captured-media-card.component.html',
  styleUrls: ['./captured-media-card.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    InputSwitchModule,
    ButtonModule,
    InputTextModule,
    DividerModule,
    ImageModule,
    ShortDateTimePipe,
    TooltipModule,
    ConfirmPopupModule,
    DialogModule,
    PdfViewerModule
  ]
})
export class CapturedMediaCardComponent extends FileTypeHelper {
  @Input() viewType: MediaCardViewType = MediaCardViewType.PATIENT;
  @Input() media: SubmissionFlowMedia;
  @Input() showDeleteButton = true;
  @Input() uploaded = false;
  @Input() loading = false;
  @Input() hideNotesForm = false;

  @Output() submitMedia = new EventEmitter<SubmissionFlowMedia>();
  @Output() remove = new EventEmitter();

  previewPdf: boolean;

  get showFileName(): boolean {
    return this.media?.file?.name && !this.media.file.name.startsWith('temp-');
  }

  get mediaCardViewType(): typeof MediaCardViewType {
    return MediaCardViewType;
  }

  constructor(
    private confirmationService: ConfirmationService,
    public sfTexts: PatientSubmissionTextsService
  ) {
    super();
  }

  emitSubmit(): void {
    this.submitMedia.emit(this.media);
  }

  removeItem(event: Event): void {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Are you sure that you want to remove this attachment?',
      icon: 'pi pi-exclamation-triangle',
      key: 'mediaCardConfirmPopup',
      accept: () => this.remove.emit()
    });
  }

  viewPdf(): void {
    this.previewPdf = true;
  }
}
