<!-- Request Access Pop-up to handle within-organisation lookup -->
<ng-container *ngIf="requestAccessWithinOrgForm">
  <form
    [formGroup]="requestAccessWithinOrgForm"
    (ngSubmit)="requestAccessWithinOrgForm.valid && requestAccessWithinOrgFormSubmit()"
  >
    <h1 mat-dialog-title>
      {{ data.title }}
    </h1>
    <mat-dialog-content>
      <p>{{ data.message }}</p>
      <mat-form-field
        appearance="outline"
        class="half-width-field"
        *ngIf="data.showUserTeams && data.user"
      >
        <mat-label>Select a team...</mat-label>
        <mat-select formControlName="teamId">
          <mat-option *ngFor="let team of teamsList | orderBy: 'name'" [value]="team.id">
            {{ team.name }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="
            requestAccessWithinOrgForm.controls['teamId']?.touched &&
            requestAccessWithinOrgForm.controls['teamId']?.invalid
          "
        >
          A team must be selected.
        </mat-error>
      </mat-form-field>

      <p class="highlighted-text">
        By checking the box below, you confirm that you are accessing the information to provide or
        support the individual patient’s direct care. Your access request will be logged.
      </p>

      <p>Please do not request access if you are attempting to view a test patient.</p>
    </mat-dialog-content>

    <mat-checkbox
      [checked]="data.isConfirmed"
      (change)="userConfirmation()"
      data-cy="request-access-withinOrg-checkbox"
    >
      I confirm I have a reason to access this patient’s information pertaining to direct care of
      the patient.
    </mat-checkbox>

    <div mat-dialog-actions class="dialog-button-group">
      <button mat-button type="button" (click)="onNoClick()">
        {{ data.negativeButton || 'No' }}
      </button>
      <div class="submit-button">
        <button
          data-cy="request-access-within-org-submit-reason"
          mat-button
          type="submit"
          [mat-dialog-close]="data.textField"
          [disabled]="!data.isConfirmed || !requestAccessWithinOrgForm.valid"
        >
          {{ data.positiveButton || 'Yes' }}
        </button>
      </div>
    </div>
  </form>
</ng-container>
