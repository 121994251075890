<div class="h-full bg-main-surface">
  <div
    class="flex flex-column gap-3 align-items-center justify-content-center overflow-y-auto h-full"
  >
    <i [ngClass]="messageIcon"></i>
    <h2>{{ messageTitle }}</h2>
    <p class="max-w-30rem text-center text-secondary">
      {{ messageBody
      }}<a [attr.href]="messageLink" target="_blank" class="underline">{{ messageLinkText }}</a>
    </p>
  </div>
</div>
