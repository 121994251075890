import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-mfq-form',
  templateUrl: './mfq-form.component.html',
  styleUrls: ['./mfq-form.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
})
export class MfqFormComponent implements OnInit {
  @Input() data;
  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  mfqForm: UntypedFormGroup;
  isSubmitting = false;
  isSensitive: boolean;

  mfqScoreList: string[] = ['NOT TRUE = 0', 'SOMETIMES = 1', 'TRUE = 2'];

  ngOnInit(): void {
    this.mfqForm = this.fb.group({
      feelingMiserableOrUnhappy: ['', Validators.required],
      didntEnjoyAnything: ['', Validators.required],
      feltSoTiredAndDoneNothing: ['', Validators.required],
      wasVeryRestless: ['', Validators.required],
      feltLikeIWasNoGoodAnymore: ['', Validators.required],
      criedAlot: ['', Validators.required],
      hardToThinkOrConcentrate: ['', Validators.required],
      iHatedMyself: ['', Validators.required],
      iWasABadPerson: ['', Validators.required],
      iFeltLonely: ['', Validators.required],
      thoughtNobodyLovedMe: ['', Validators.required],
      thoughICouldNeverBeAsGoodAsOtherKids: ['', Validators.required],
      iDidEverythingWrong: ['', Validators.required],
      notes: [''],
      mfqTotalScore: [''],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.mfqForm.controls[key])
          this.mfqForm.controls[key].setValue(formData[key]);
      });
      const sensitive = this.mfqForm.get('sensitiveImage').value;
      this.setSensitiveImage(sensitive);
    });
  }

  get feelingMiserableOrUnhappy() {
    return this.mfqForm.get('feelingMiserableOrUnhappy');
  }

  get didntEnjoyAnything() {
    return this.mfqForm.get('didntEnjoyAnything');
  }

  get feltSoTiredAndDoneNothing() {
    return this.mfqForm.get('feltSoTiredAndDoneNothing');
  }

  get wasVeryRestless() {
    return this.mfqForm.get('wasVeryRestless');
  }

  get feltLikeIWasNoGoodAnymore() {
    return this.mfqForm.get('feltLikeIWasNoGoodAnymore');
  }

  get criedAlot() {
    return this.mfqForm.get('criedAlot');
  }

  get hardToThinkOrConcentrate() {
    return this.mfqForm.get('hardToThinkOrConcentrate');
  }

  get iHatedMyself() {
    return this.mfqForm.get('iHatedMyself');
  }

  get iWasABadPerson() {
    return this.mfqForm.get('iWasABadPerson');
  }

  get iFeltLonely() {
    return this.mfqForm.get('iFeltLonely');
  }

  get thoughtNobodyLovedMe() {
    return this.mfqForm.get('thoughtNobodyLovedMe');
  }

  get thoughICouldNeverBeAsGoodAsOtherKids() {
    return this.mfqForm.get('thoughICouldNeverBeAsGoodAsOtherKids');
  }

  get iDidEverythingWrong() {
    return this.mfqForm.get('iDidEverythingWrong');
  }

  onSubmit() {
    if (!this.valid()) return;
    this.setMfqScore();

    this.isSubmitting = true;
    const formResponse = {
      ...this.mfqForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
      showOnChart: true,
    };
    this.form.submit(formResponse);
  }

  setSensitiveImage(status: boolean) {
    if (typeof status === 'string') {
      this.form.setSensitiveImage(false);
      this.isSensitive = false;
    } else if (status || status === false) {
      this.form.setSensitiveImage(status);
      this.isSensitive = status;
    } else {
      this.isSensitive = !this.isSensitive;
      this.form.setSensitiveImage(this.isSensitive);
    }
  }

  valid(): boolean {
    return (
      this.mfqForm.dirty &&
      !(
        this.mfqForm.controls &&
        this.mfqForm.controls['feelingMiserableOrUnhappy'].errors
      ) &&
      !(
        this.mfqForm.controls &&
        this.mfqForm.controls['didntEnjoyAnything'].errors
      ) &&
      !(
        this.mfqForm.controls &&
        this.mfqForm.controls['feltSoTiredAndDoneNothing'].errors
      ) &&
      !(
        this.mfqForm.controls && this.mfqForm.controls['wasVeryRestless'].errors
      ) &&
      !(
        this.mfqForm.controls &&
        this.mfqForm.controls['feltLikeIWasNoGoodAnymore'].errors
      ) &&
      !(this.mfqForm.controls && this.mfqForm.controls['criedAlot'].errors) &&
      !(
        this.mfqForm.controls &&
        this.mfqForm.controls['hardToThinkOrConcentrate'].errors
      ) &&
      !(
        this.mfqForm.controls && this.mfqForm.controls['iHatedMyself'].errors
      ) &&
      !(
        this.mfqForm.controls && this.mfqForm.controls['iWasABadPerson'].errors
      ) &&
      !(this.mfqForm.controls && this.mfqForm.controls['iFeltLonely'].errors) &&
      !(
        this.mfqForm.controls &&
        this.mfqForm.controls['thoughtNobodyLovedMe'].errors
      ) &&
      !(
        this.mfqForm.controls &&
        this.mfqForm.controls['thoughICouldNeverBeAsGoodAsOtherKids'].errors
      ) &&
      !(
        this.mfqForm.controls &&
        this.mfqForm.controls['iDidEverythingWrong'].errors
      ) &&
      !this.isSubmitting
    );
  }

  setMfqScore() {
    const mfqQuestion1 = this.mfqForm.get('feelingMiserableOrUnhappy').value;
    const mfqQuestion2 = this.mfqForm.get('didntEnjoyAnything').value;
    const mfqQuestion3 = this.mfqForm.get('feltSoTiredAndDoneNothing').value;
    const mfqQuestion4 = this.mfqForm.get('wasVeryRestless').value;
    const mfqQuestion5 = this.mfqForm.get('feltLikeIWasNoGoodAnymore').value;
    const mfqQuestion6 = this.mfqForm.get('criedAlot').value;
    const mfqQuestion7 = this.mfqForm.get('hardToThinkOrConcentrate').value;
    const mfqQuestion8 = this.mfqForm.get('iHatedMyself').value;
    const mfqQuestion9 = this.mfqForm.get('iWasABadPerson').value;
    const mfqQuestion10 = this.mfqForm.get('iFeltLonely').value;
    const mfqQuestion11 = this.mfqForm.get('thoughtNobodyLovedMe').value;
    const mfqQuestion12 = this.mfqForm.get(
      'thoughICouldNeverBeAsGoodAsOtherKids'
    ).value;
    const mfqQuestion13 = this.mfqForm.get('iDidEverythingWrong').value;

    const mfqTotalScore =
      +mfqQuestion1.match(/\d+/g) +
      +mfqQuestion2.match(/\d+/g) +
      +mfqQuestion3.match(/\d+/g) +
      +mfqQuestion4.match(/\d+/g) +
      +mfqQuestion5.match(/\d+/g) +
      +mfqQuestion6.match(/\d+/g) +
      +mfqQuestion7.match(/\d+/g) +
      +mfqQuestion8.match(/\d+/g) +
      +mfqQuestion9.match(/\d+/g) +
      +mfqQuestion10.match(/\d+/g) +
      +mfqQuestion11.match(/\d+/g) +
      +mfqQuestion12.match(/\d+/g) +
      +mfqQuestion13.match(/\d+/g);
    this.mfqForm.get('mfqTotalScore').setValue(mfqTotalScore);
  }
}
