import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  standalone: true,
  selector: 'code-input',
})
export class CodeInputDirective {
  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
  ) { }

  ngAfterViewInit() {
    const telInputs = this.el.nativeElement.querySelectorAll('input[type="tel"]');

    telInputs.forEach((input, index) => {
      const ariaLabel = `Code input ${index + 1}`;
      this.renderer.setAttribute(input, 'aria-label', ariaLabel);
    });
  }
}
