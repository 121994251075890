import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

const { required } = Validators;
@Component({
  standalone: true,
  selector: 'ic-nuca-patient-questionnaire',
  templateUrl: './nuca-patient-questionnaire.component.html',
  styleUrls: ['./nuca-patient-questionnaire.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
})
export class NucaPatientQuestionnaireComponent implements OnInit {
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  nucaForm: UntypedFormGroup;
  isSubmitting = false;

  ngOnInit(): void {
    this.nucaForm = this.fb.group({
      presentOccupation: ['', required],
      previousOccupations: ['', required],
      lesionBodyLocation: ['', required],
      howLongHaveYouHadThisLesion: ['', required],
      whenDidThisLesionChange: ['', required],
      hasTheLesionChangedInTheLastThreeMonths: ['', required],
      lesionChangedInSize: ['', required],
      lesionChangedInShape: ['', required],
      lesionChangedInColour: ['', required],
      lesionBeenAnyOfTheFollowing: ['', required],
      previouslyHadSkinCancer: ['', required],
      previousCancerDetails: [''],
      familyMemberPreviouslyHadSkinCancer: ['', required],
      doYouSunburnEasily: ['', required],
      livedSomewhereHotAndSunny: ['', required],
      haveYouEverWorkedOutsideRegularly: ['', required],
      hobbiesThatTakePlaceOutside: ['', required],
      haveYouEverUsedSunbeds: ['', required],
      didYouEverHaveSevereOrBlisteringSunburnAsAChild: ['', required],
      doYouHaveAHistoryOfImmunosuppression: ['', required],
      immunosuppressionDetails: [''],
      shieldingDuringCovidPandemic: ['', required],
      covidShieldingDetails: [''],
      doYouHaveAnyAllergies: ['', required],
      doYouHaveAnyAllergiesDetails: [''],
      doYouHaveAPacemaker: ['', required],
      areYouOnAnyBloodThinningAgents: ['', required],
      bloodThinningAgentsDetails: [''],
      anyBloodborneViruses: ['', required],
      pregnantOrBreastfeeding: ['', required],
      consentToImagesOrRecordingsOnMyMedicalRecord: ['', required],
      consentToImagesBeingUsedAnonymously: ['', required],
      patientSignature: ['', required],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.nucaForm.controls[key])
          this.nucaForm.controls[key].setValue(formData[key]);
      });
    });
  }

  get presentOccupation() {
    return this.nucaForm.get('presentOccupation');
  }
  get previousOccupations() {
    return this.nucaForm.get('previousOccupations');
  }
  get lesionBodyLocation() {
    return this.nucaForm.get('lesionBodyLocation');
  }
  get howLongHaveYouHadThisLesion() {
    return this.nucaForm.get('howLongHaveYouHadThisLesion');
  }
  get whenDidThisLesionChange() {
    return this.nucaForm.get('whenDidThisLesionChange');
  }
  get hasTheLesionChangedInTheLastThreeMonths() {
    return this.nucaForm.get('hasTheLesionChangedInTheLastThreeMonths');
  }
  get lesionChangedInSize() {
    return this.nucaForm.get('lesionChangedInSize');
  }
  get lesionChangedInShape() {
    return this.nucaForm.get('lesionChangedInShape');
  }
  get lesionChangedInColour() {
    return this.nucaForm.get('lesionChangedInColour');
  }
  get lesionBeenAnyOfTheFollowing() {
    return this.nucaForm.get('lesionBeenAnyOfTheFollowing');
  }
  get previouslyHadSkinCancer() {
    return this.nucaForm.get('previouslyHadSkinCancer');
  }
  get familyMemberPreviouslyHadSkinCancer() {
    return this.nucaForm.get('familyMemberPreviouslyHadSkinCancer');
  }
  get doYouSunburnEasily() {
    return this.nucaForm.get('doYouSunburnEasily');
  }
  get livedSomewhereHotAndSunny() {
    return this.nucaForm.get('livedSomewhereHotAndSunny');
  }
  get haveYouEverWorkedOutsideRegularly() {
    return this.nucaForm.get('haveYouEverWorkedOutsideRegularly');
  }
  get hobbiesThatTakePlaceOutside() {
    return this.nucaForm.get('hobbiesThatTakePlaceOutside');
  }
  get haveYouEverUsedSunbeds() {
    return this.nucaForm.get('haveYouEverUsedSunbeds');
  }
  get didYouEverHaveSevereOrBlisteringSunburnAsAChild() {
    return this.nucaForm.get('didYouEverHaveSevereOrBlisteringSunburnAsAChild');
  }
  get doYouHaveAHistoryOfImmunosuppression() {
    return this.nucaForm.get('doYouHaveAHistoryOfImmunosuppression');
  }
  get doYouHaveAnyAllergies() {
    return this.nucaForm.get('doYouHaveAnyAllergies');
  }
  get doYouHaveAnyAllergiesDetails() {
    return this.nucaForm.get('doYouHaveAnyAllergiesDetails');
  }
  get doYouHaveAPacemaker() {
    return this.nucaForm.get('doYouHaveAPacemaker');
  }
  get areYouOnAnyBloodThinningAgents() {
    return this.nucaForm.get('areYouOnAnyBloodThinningAgents');
  }
  get anyBloodborneViruses() {
    return this.nucaForm.get('anyBloodborneViruses');
  }
  get pregnantOrBreastfeeding() {
    return this.nucaForm.get('pregnantOrBreastfeeding');
  }
  get consentToImagesOrRecordingsOnMyMedicalRecord() {
    return this.nucaForm.get('consentToImagesOrRecordingsOnMyMedicalRecord');
  }
  get consentToImagesBeingUsedAnonymously() {
    return this.nucaForm.get('consentToImagesBeingUsedAnonymously');
  }
  get patientSignature() {
    return this.nucaForm.get('patientSignature');
  }

  onSubmit() {
    if (!this.valid()) return;
    this.isSubmitting = true;
    const formResponse = {
      ...this.nucaForm.value,
      formId: this.data.formId,
      lesionQuestions: 'subtitle_HEADER',
      riskFactors: 'subtitle_HEADER',
      surgeryRiskFactors: 'subtitle_HEADER',
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  valid(): boolean {
    return (
      this.nucaForm.dirty &&
      !(
        this.nucaForm.controls &&
        this.nucaForm.controls['presentOccupation'].errors
      ) &&
      !(
        this.nucaForm.controls &&
        this.nucaForm.controls['previousOccupations'].errors
      ) &&
      !(
        this.nucaForm.controls &&
        this.nucaForm.controls['lesionBodyLocation'].errors
      ) &&
      !(
        this.nucaForm.controls &&
        this.nucaForm.controls['howLongHaveYouHadThisLesion'].errors
      ) &&
      !(
        this.nucaForm.controls &&
        this.nucaForm.controls['whenDidThisLesionChange'].errors
      ) &&
      !(
        this.nucaForm.controls &&
        this.nucaForm.controls['hasTheLesionChangedInTheLastThreeMonths'].errors
      ) &&
      !(
        this.nucaForm.controls &&
        this.nucaForm.controls['lesionChangedInSize'].errors
      ) &&
      !(
        this.nucaForm.controls &&
        this.nucaForm.controls['lesionChangedInShape'].errors
      ) &&
      !(
        this.nucaForm.controls &&
        this.nucaForm.controls['lesionChangedInColour'].errors
      ) &&
      !(
        this.nucaForm.controls &&
        this.nucaForm.controls['lesionBeenAnyOfTheFollowing'].errors
      ) &&
      !(
        this.nucaForm.controls &&
        this.nucaForm.controls['previouslyHadSkinCancer'].errors
      ) &&
      !(
        this.nucaForm.controls &&
        this.nucaForm.controls['familyMemberPreviouslyHadSkinCancer'].errors
      ) &&
      !(
        this.nucaForm.controls &&
        this.nucaForm.controls['doYouSunburnEasily'].errors
      ) &&
      !(
        this.nucaForm.controls &&
        this.nucaForm.controls['livedSomewhereHotAndSunny'].errors
      ) &&
      !(
        this.nucaForm.controls &&
        this.nucaForm.controls['haveYouEverWorkedOutsideRegularly'].errors
      ) &&
      !(
        this.nucaForm.controls &&
        this.nucaForm.controls['hobbiesThatTakePlaceOutside'].errors
      ) &&
      !(
        this.nucaForm.controls &&
        this.nucaForm.controls['haveYouEverUsedSunbeds'].errors
      ) &&
      !(
        this.nucaForm.controls &&
        this.nucaForm.controls[
          'didYouEverHaveSevereOrBlisteringSunburnAsAChild'
        ].errors
      ) &&
      !(
        this.nucaForm.controls &&
        this.nucaForm.controls['doYouHaveAHistoryOfImmunosuppression'].errors
      ) &&
      !(
        this.nucaForm.controls &&
        this.nucaForm.controls['doYouHaveAnyAllergies'].errors
      ) &&
      !(
        this.nucaForm.controls &&
        this.nucaForm.controls['doYouHaveAnyAllergiesDetails'].errors
      ) &&
      !(
        this.nucaForm.controls &&
        this.nucaForm.controls['doYouHaveAPacemaker'].errors
      ) &&
      !(
        this.nucaForm.controls &&
        this.nucaForm.controls['areYouOnAnyBloodThinningAgents'].errors
      ) &&
      !(
        this.nucaForm.controls &&
        this.nucaForm.controls['anyBloodborneViruses'].errors
      ) &&
      !(
        this.nucaForm.controls &&
        this.nucaForm.controls['pregnantOrBreastfeeding'].errors
      ) &&
      !(
        this.nucaForm.controls &&
        this.nucaForm.controls['consentToImagesOrRecordingsOnMyMedicalRecord']
          .errors
      ) &&
      !(
        this.nucaForm.controls &&
        this.nucaForm.controls['consentToImagesBeingUsedAnonymously'].errors
      ) &&
      !(
        this.nucaForm.controls &&
        this.nucaForm.controls['patientSignature'].errors
      ) &&
      //  && this.nucaForm.valid
      !this.isSubmitting
    );
  }
}
