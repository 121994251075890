<form [formGroup]="cchqForm">
  <div class="form-formatting">
    <mat-card>
      <mat-card-content> </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-content>
        <mat-card-title> Care and Comfort Hypertonicity Questionnaire (CCHQ) </mat-card-title>

        <div class="question-container">
          <div class="half-width-field">
            <p>Name of person completing form:</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="text" formControlName="personCompletingForm" />
          </mat-form-field>
        </div>

        <div style="margin: 0 auto">
          <p>
            Please rate how easy or difficult it is for you or your child in the last two weeks to
            perform the following tasks relative to a cooperate individual without a disability (try
            to distinguish between motor control and abnormal tone as the explanation for the
            problem):
          </p>
        </div>
      </mat-card-content>
    </mat-card>

    <br />

    <mat-card>
      <mat-card-content>
        <mat-card-title>Personal Care</mat-card-title>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>1. Putting on trousers:</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="puttingOnTrousers">
              <div *ngFor="let response of cchqResponsesVeryEasyImpossible">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>2. Taking off trousers:</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="takingOffTrousers">
              <div *ngFor="let response of cchqResponsesVeryEasyImpossible">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>3. Putting on a shirt:</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="puttingOnShirt">
              <div *ngFor="let response of cchqResponsesVeryEasyImpossible">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>4. Changing diapers:</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="changingDiapers">
              <div *ngFor="let response of cchqResponsesVeryEasyImpossible">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>5. Ease of sitting on a toilet seat:</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="easeOfSittingOnAToiletSeat">
              <div *ngFor="let response of cchqResponsesVeryEasyImpossible">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>6. Ease of sitting in a bathtub, with or without adaptive equipment:</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="easeOfSittingInABathtubWithOrWithoutAdaptiveEquipment">
              <div *ngFor="let response of cchqResponsesVeryEasyImpossible">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>7. Ease of bathing:</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="easeOfBathing">
              <div *ngFor="let response of cchqResponsesVeryEasyImpossible">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>8. Ease of self-feeding:</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="easeOfSelfFeeding">
              <div *ngFor="let response of cchqResponsesVeryEasyImpossible">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>8a. Ease of feeding:</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="easeOfFeeding">
              <div *ngFor="let response of cchqResponsesVeryEasyImpossible">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p><strong>Average Personal Care Score:</strong> {{ averagePersonalCareScore }}</p>
          </div>
          <div class="half-width-field"></div>
        </div>
      </mat-card-content>
    </mat-card>

    <br />

    <mat-card>
      <mat-card-content>
        <mat-card-title>Positioning/ Transferring</mat-card-title>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>9. Ease of positioning in a wheelchair:</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="easeOfPositioningInAWheelchair">
              <div *ngFor="let response of cchqResponsesVeryEasyImpossible">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <b
                >10. Ease of positioning in a device other than a wheelchair, such as a standing
                frame:</b
              >
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="easeOfPositioningInOtherDeviceEgStandingFrame">
              <div *ngFor="let response of cchqResponsesVeryEasyImpossible">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>11. Ease of transferring in and out of a wheelchair:</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="easeOfTransferringInAndOutOfAWheelchair">
              <div *ngFor="let response of cchqResponsesVeryEasyImpossible">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>12. Ease of putting on orthoses or positioning devices:</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="easeOfEaseOfPuttingOnOrthosesOrPositioningDevices">
              <div *ngFor="let response of cchqResponsesVeryEasyImpossible">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>13. Ease of controlling his/her wheelchair:</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="easeOfControllingWheelchair">
              <div *ngFor="let response of cchqResponsesVeryEasyImpossible">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>14. Ease of getting out of a car:</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="easeOfGettingOutOfACar">
              <div *ngFor="let response of cchqResponsesVeryEasyImpossible">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>15. Ease of getting in a car:</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="easeOfGettingInACar">
              <div *ngFor="let response of cchqResponsesVeryEasyImpossible">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>Average Positioning/Transferring Score:</strong>
              {{ averagePositioningAndTransferringScore }}
            </p>
          </div>
          <div class="half-width-field"></div>
        </div>
      </mat-card-content>
    </mat-card>

    <br />

    <mat-card>
      <mat-card-content>
        <mat-card-title>Comfort</mat-card-title>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>16. Is there pain or discomfort during position changes?</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="painOrDiscomfortDuringPositionChanges">
              <div *ngFor="let response of cchqResponsesNeverAlways">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>17. Is there pain or discomfort during diaper changes?</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="painOrDiscomfortDuringDiaperChanges">
              <div *ngFor="let response of cchqResponsesNeverAlways">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <b
                >18. Does the pain or discomfort prevent your child from participating in school,
                various programs, or other activities?</b
              >
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select
              formControlName="painOrDiscomfortPreventsParticipationInSchoolVariousProgramsOrOtherActivities"
            >
              <div *ngFor="let response of cchqResponsesNeverAlways">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>19. Is your child using pain control medicine?</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="usingPainControlMedicine">
              <div *ngFor="let response of cchqResponsesNeverAlways">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>20. Does your child sleep through the night?</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="sleepsThroughTheNight">
              <div *ngFor="let response of cchqResponsesAlwaysNever">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p><strong>Average Comfort Score:</strong> {{ averageComfortScore }}</p>
          </div>
          <div class="half-width-field"></div>
        </div>
      </mat-card-content>
    </mat-card>

    <br />

    <mat-card>
      <mat-card-content>
        <mat-card-title>Interaction/ Communication</mat-card-title>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <b
                >21. How easy is it for your child to extend arms to reach communication devices?</b
              >
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="easeOfExtendingArmsToReachCommunicationDevices">
              <div *ngFor="let response of cchqResponsesVeryEasyImpossible">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>22. How easy is it for your child to play alone?</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="easeOfPlayingAlone">
              <div *ngFor="let response of cchqResponsesVeryEasyImpossible">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>23. How easy is it for your child to play with other children?</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="easeOfPlayingWithOtherChildren">
              <div *ngFor="let response of cchqResponsesVeryEasyImpossible">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <b
                >24. How easy is it for your child to be completely understood by those who know
                your child well?</b
              >
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="easeOfBeingCompletelyUnderstoodByThoseWhoKnowThemWell">
              <div *ngFor="let response of cchqResponsesVeryEasyImpossible">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>25. Does your child have a problem with drooling?</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="problemWithDrooling">
              <div *ngFor="let response of cchqResponsesNeverSoaked">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>26. My child's self-esteem is</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="selfEsteem">
              <div *ngFor="let response of cchqResponsesOutstandingAwful">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>27. Describe your child:</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="describeChild">
              <div *ngFor="let response of cchqResponsesHappyUnhappy">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>Average Interaction/Communication Score:</strong>
              {{ averageInteractionAndCommunicationScore }}
            </p>
          </div>
          <div class="half-width-field"></div>
        </div>
      </mat-card-content>
    </mat-card>

    <br />

    <mat-card>
      <mat-card-content>
        <mat-card-title>Change since last visit</mat-card-title>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>Personal Care:</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="changeInPersonalCare">
              <div *ngFor="let response of cchqResponsesChange">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea matInput formControlName="changeInPersonalCareComments" type="notes">
          </textarea>
        </mat-form-field>
        <br />

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>Positioning/transferring:</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="changeInPositioningOrTransferring">
              <div *ngFor="let response of cchqResponsesChange">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea
            matInput
            formControlName="changeInPositioningOrTransferringComments"
            type="notes"
          >
          </textarea>
        </mat-form-field>
        <br />

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>Comfort:</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="changeInComfort">
              <div *ngFor="let response of cchqResponsesChange">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea matInput formControlName="changeInComfortComments" type="notes"> </textarea>
        </mat-form-field>
        <br />

        <div class="question-container">
          <div class="half-width-field">
            <p>
              <strong>Interaction/Communication:</strong>
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="changeInInteractionAndCommunication">
              <div *ngFor="let response of cchqResponsesChange">
                <mat-option [value]="response">{{ response }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-field" appearance="fill">
          <mat-label>Comments</mat-label>
          <textarea
            matInput
            formControlName="changeInInteractionAndCommunicationComments"
            type="notes"
          >
          </textarea>
        </mat-form-field>
        <br />
      </mat-card-content>
    </mat-card>

    <br />

    <mat-form-field class="full-width-field" appearance="fill">
      <mat-label>Any other comments?</mat-label>
      <textarea matInput formControlName="notes" type="notes"> </textarea>
    </mat-form-field>
    <br />

    <button
      mat-raised-button
      class="positive-button"
      type="submit"
      [disabled]="!valid() || isSubmitting"
      (click)="onSubmit()"
    >
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
