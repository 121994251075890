import { ErrorHandler, Injectable } from '@angular/core';
import { captureException } from '@sentry/angular-ivy';
import { MessageService } from 'primeng/api';
import { HTTPStatusCode } from './http-status-codes.enum';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private readonly chunkErrorRegex = /Loading chunk [\d]+ failed/;
  private readonly permissionsErrorRegex =
    /Missing or insufficient permissions/;
  private readonly urlErrorRegex = /Request URL has expired|Invalid URL/;

  constructor(private messageService: MessageService) {}

  handleError(error: any) {
    captureException(error);
    console.error(error);

    if (error.status) {
      this.handleHttpError(error);
      return;
    }

    switch (true) {
      case this.chunkErrorRegex.test(error.message):
        this.displayToast(
          'Chunk loading failed',
          'Unfortunatley, something went wrong. Please reload the page',
          'chunkErrorToast'
        );
        break;
      case this.permissionsErrorRegex.test(error.message):
        this.displayToast(
          'Missing or insufficient permissions',
          'You do not have the required permissions to access this resource'
        );
        break;
      case this.urlErrorRegex.test(error.message):
        this.displayToast(
          'Invalid URL',
          'The URL you were provided is invalid or has expired'
        );
        break;
      default:
        return;
    }
  }

  private handleHttpError(error: any) {
    let summary = 'An unexpected error has occurred';
    let statusMessage;

    switch (error.status) {
      case HTTPStatusCode.BadRequest:
      case HTTPStatusCode.Unauthorized:
      case HTTPStatusCode.Forbidden:
      case HTTPStatusCode.NotFound:
      case HTTPStatusCode.MethodNotAllowed:
        summary = 'Client Error';
        statusMessage = error.message;
        break;
      case HTTPStatusCode.RequestTimeout:
        summary = 'Request Timeout';
        statusMessage = error.message;
        break;
      case HTTPStatusCode.InternalServerError:
      case HTTPStatusCode.NotImplemented:
      case HTTPStatusCode.BadGateway:
      case HTTPStatusCode.ServiceUnavailable:
      case HTTPStatusCode.GatewayTimeout:
      case HTTPStatusCode.HTTPVersionNotSupported:
      case HTTPStatusCode.VariantAlsoNegotiates:
        summary = 'Server Error';
        statusMessage = error.message;
        break;
      default:
        statusMessage = 'The error has been logged';
    }

    this.displayToast(summary, statusMessage);
  }

  private displayToast(summary: string, detail: string, key?: string) {
    if (key) {
      this.messageService.add({
        key: key,
        severity: 'error',
        summary: summary,
        detail: detail,
        sticky: true,
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: summary,
        detail: detail,
      });
    }
  }
}
