<div class="flex flex-column gap-3">
  <div *ngFor="let option of $any(field?.props?.options)">
    <label
      class="flex flex-row align-items-center gap-2 w-full border-1 border-round p-radio-button-label"
      [ngClass]="
        formControl.value === option.value
          ? 'border-brand el-bg-primary-subtle'
          : 'border-main bg-main-surface'
      "
      [attr.style]="'background-color: ' + option?.colour + ' !important'"
    >
      <p-radioButton
        [name]="field?.id"
        [attr.data-cy]="field?.key + '-option-' + option.value"
        [formControl]="formControl"
        [value]="option.value"
        [id]="option.value"
      ></p-radioButton>
      <div class="flex flex-column">
        <span>{{ option.label }}</span>
        <div class="text-xs mt-1 mb-1" *ngIf="option.info">{{ option.info }}</div>
      </div>
    </label>
  </div>
</div>
