import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nhsDate'
})
export class NhsDatePipe implements PipeTransform {
  transform(value: any, includeTime: boolean = false): string {
    if (!value) return '';

    const dateObj = new Date(value);
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    };

    const formattedDate = dateObj.toLocaleDateString('en-GB', options).replace(',', '');

    if (includeTime) {
      const timePart = dateObj
        .toLocaleTimeString('en-GB', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        })
        .replace(/^0/, '12');

      const formattedTime = timePart.replace(/\s/g, '');
      return formattedDate + `, ${formattedTime}`;
    }

    return formattedDate;
  }
}
