import {
  Component,
  ElementRef,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { FormService } from '@ic-monorepo/services';
import { FormField } from '@islacare/ic-types';

@Component({
  selector: 'ic-automatic-radio',
  templateUrl: './automatic-radio.component.html',
  styleUrls: ['./automatic-radio.component.scss'],
})
export class AutomaticRadioComponent {
  @Input() field: FormField;
  @Input() control: UntypedFormControl;

  // For testing purpose
  @ViewChild('fieldTitle') fieldTitle: ElementRef;
  @ViewChild(MatRadioGroup) matRadio: MatRadioGroup;
  @ViewChildren(MatRadioButton) matRadioOptions: QueryList<MatRadioButton>;
  constructor(public formService: FormService) {}

  fontWeight() {
    if (this.field.boldTitle === true) {
      return 'bold';
    }
  }
  textAlign() {
    return this.field.alignment || 'center';
  }
}
