import { Pipe, PipeTransform } from '@angular/core';
import { FormService } from '@ic-monorepo/services';

@Pipe({
  name: 'bodyMapUrl',
})
export class BodyMapUrlPipe implements PipeTransform {
  constructor(private readonly formService: FormService) {}
  transform(imageMapPath: string): Promise<string> {
    return this.formService.getBodyMapUrl(imageMapPath);
  }
}
