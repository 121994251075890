import { AbstractControl } from '@angular/forms';
import { ClinicalFormField } from '@islacare/ic-types';
import { FormlyFieldConfig } from '@ngx-formly/core';

const clinicalFormFieldOptions = Object.values(ClinicalFormField).map(value => ({
  value: value,
  label: value.charAt(0).toUpperCase() + value.slice(1),
}));

const colourOptions = [
  { value: '#CCE5FF', label: 'Blue' },
  { value: '#FFDFDF', label: 'Red' },
  { value: '#FFE5CC', label: 'Orange' },
  { value: '#FFFFCC', label: 'Yellow' },
  { value: '#CCFFDD', label: 'Green' },
  { value: '#FFFFFF', label: 'White' },
  { value: '', label: 'None' },
];

export function fieldEditingFormController(fieldType: ClinicalFormField, fieldKey: EditorField): boolean {
  let enabledFields: EditorField[] = [];
  switch (fieldType) {
    case ClinicalFormField.CALCULATION: {
      enabledFields = [
        EditorField.TYPE,
        EditorField.KEY,
        EditorField.PROPS_LABEL,
        EditorField.EXPRESSIONS_HIDE,
        EditorField.EXPRESSIONS_PROPS_CALCULATION_VALUE,
        EditorField.PROPS_SHOW_FOR_LOGGED_IN_USERS_ONLY,
        EditorField.PROPS_DESCRIPTION,
        EditorField.PROPS_SEPARATOR,
        EditorField.PROPS_MAX_SCORE,
      ];
      break;
    }
    case ClinicalFormField.CHECKBOX: {
      {
        enabledFields = [
          EditorField.TYPE,
          EditorField.PROPS_LABEL,
          EditorField.EXPRESSIONS_HIDE,
          EditorField.PROPS_SHOW_FOR_LOGGED_IN_USERS_ONLY,
          EditorField.KEY,
          EditorField.PROPS_REQUIRED,
          EditorField.PROPS_DESCRIPTION,
          EditorField.PROPS_PLACEHOLDER,
          EditorField.PROPS_OPTIONS,
        ];
        break;
      }
    }
    case ClinicalFormField.DATE: {
      {
        enabledFields = [
          EditorField.TYPE,
          EditorField.PROPS_LABEL,
          EditorField.EXPRESSIONS_HIDE,
          EditorField.PROPS_SHOW_FOR_LOGGED_IN_USERS_ONLY,
          EditorField.KEY,
          EditorField.PROPS_REQUIRED,
          EditorField.PROPS_DESCRIPTION,
          EditorField.PROPS_PLACEHOLDER,
        ];
        break;
      }
    }
    case ClinicalFormField.FIELDMESSAGE: {
      enabledFields = [
        EditorField.TYPE,
        EditorField.PROPS_LABEL,
        EditorField.EXPRESSIONS_HIDE,
        EditorField.PROPS_SHOW_FOR_LOGGED_IN_USERS_ONLY,
        EditorField.PROPS_HTML,
      ];
      break;
    }
    case ClinicalFormField.HTML: {
      enabledFields = [
        EditorField.KEY,
        EditorField.TYPE,
        EditorField.PROPS_LABEL,
        EditorField.EXPRESSIONS_HIDE,
        EditorField.PROPS_SHOW_FOR_LOGGED_IN_USERS_ONLY,
        EditorField.PROPS_HTML,
      ];
      break;
    }
    case ClinicalFormField.IMAGE: {
      enabledFields = [
        EditorField.TYPE,
        EditorField.PROPS_LABEL,
        EditorField.PROPS_STORAGE_URL,
        EditorField.EXPRESSIONS_HIDE,
        EditorField.PROPS_SHOW_FOR_LOGGED_IN_USERS_ONLY,
      ];
      break;
    }
    case ClinicalFormField.IMAGEMAP: {
      enabledFields = [
        EditorField.KEY,
        EditorField.TYPE,
        EditorField.PROPS_LABEL,
        EditorField.EXPRESSIONS_HIDE,
        EditorField.PROPS_REQUIRED,
        EditorField.PROPS_STORAGE_URL,
        EditorField.PROPS_SHOW_FOR_LOGGED_IN_USERS_ONLY,
      ];
      break;
    }
    case ClinicalFormField.MULTISELECT: {
      enabledFields = [
        EditorField.TYPE,
        EditorField.PROPS_LABEL,
        EditorField.EXPRESSIONS_HIDE,
        EditorField.PROPS_SHOW_FOR_LOGGED_IN_USERS_ONLY,
        EditorField.KEY,
        EditorField.PROPS_REQUIRED,
        EditorField.PROPS_DESCRIPTION,
        EditorField.PROPS_PLACEHOLDER,
        EditorField.PROPS_OPTIONS,
      ];
      break;
    }
    case ClinicalFormField.NUMBER: {
      enabledFields = [
        EditorField.TYPE,
        EditorField.PROPS_LABEL,
        EditorField.EXPRESSIONS_HIDE,
        EditorField.PROPS_SHOW_FOR_LOGGED_IN_USERS_ONLY,
        EditorField.KEY,
        EditorField.PROPS_REQUIRED,
        EditorField.PROPS_DESCRIPTION,
        EditorField.PROPS_PLACEHOLDER,
        EditorField.PROPS_MIN,
        EditorField.PROPS_MAX,
        EditorField.PROPS_SUFFIX,
        EditorField.PROPS_PREFIX,
      ];
      break;
    }
    case ClinicalFormField.RADIO: {
      enabledFields = [
        EditorField.TYPE,
        EditorField.PROPS_LABEL,
        EditorField.EXPRESSIONS_HIDE,
        EditorField.PROPS_SHOW_FOR_LOGGED_IN_USERS_ONLY,
        EditorField.KEY,
        EditorField.PROPS_REQUIRED,
        EditorField.PROPS_DESCRIPTION,
        EditorField.PROPS_PLACEHOLDER,
        EditorField.PROPS_OPTIONS,
      ];
      break;
    }
    case ClinicalFormField.SELECT: {
      enabledFields = [
        EditorField.TYPE,
        EditorField.PROPS_LABEL,
        EditorField.EXPRESSIONS_HIDE,
        EditorField.PROPS_SHOW_FOR_LOGGED_IN_USERS_ONLY,
        EditorField.KEY,
        EditorField.PROPS_REQUIRED,
        EditorField.PROPS_DESCRIPTION,
        EditorField.PROPS_PLACEHOLDER,
        EditorField.PROPS_OPTIONS,
      ];
      break;
    }
    case ClinicalFormField.SLIDER: {
      enabledFields = [
        EditorField.TYPE,
        EditorField.PROPS_LABEL,
        EditorField.EXPRESSIONS_HIDE,
        EditorField.PROPS_SHOW_FOR_LOGGED_IN_USERS_ONLY,
        EditorField.KEY,
        EditorField.PROPS_REQUIRED,
        EditorField.PROPS_DESCRIPTION,
        EditorField.PROPS_PLACEHOLDER,
        EditorField.PROPS_MIN,
        EditorField.PROPS_MAX,
        EditorField.PROPS_MIN_LABEL,
        EditorField.PROPS_MAX_LABEL,
      ];
      break;
    }
    case ClinicalFormField.STEPPER: {
      enabledFields = [EditorField.TYPE];
      break;
    }
    case ClinicalFormField.TABLE: {
      enabledFields = [
        EditorField.TYPE,
        EditorField.PROPS_LABEL,
        EditorField.EXPRESSIONS_HIDE,
        EditorField.PROPS_SHOW_FOR_LOGGED_IN_USERS_ONLY,
        EditorField.KEY,
        EditorField.PROPS_REQUIRED,
        EditorField.PROPS_DESCRIPTION,
        EditorField.PROPS_PLACEHOLDER,
        EditorField.FIELDARRAY_FIELDGROUP,
        EditorField.PROPS_ADD_TEXT,
      ];
      break;
    }
    case ClinicalFormField.TEXT: {
      enabledFields = [
        EditorField.TYPE,
        EditorField.PROPS_LABEL,
        EditorField.EXPRESSIONS_HIDE,
        EditorField.PROPS_SHOW_FOR_LOGGED_IN_USERS_ONLY,
        EditorField.KEY,
        EditorField.PROPS_REQUIRED,
        EditorField.PROPS_DESCRIPTION,
        EditorField.PROPS_PLACEHOLDER,
      ];
      break;
    }
    case ClinicalFormField.TEXTAREA: {
      enabledFields = [
        EditorField.TYPE,
        EditorField.PROPS_LABEL,
        EditorField.EXPRESSIONS_HIDE,
        EditorField.PROPS_SHOW_FOR_LOGGED_IN_USERS_ONLY,
        EditorField.KEY,
        EditorField.PROPS_REQUIRED,
        EditorField.PROPS_DESCRIPTION,
        EditorField.PROPS_PLACEHOLDER,
      ];
      break;
    }
  }
  return !enabledFields.includes(fieldKey);
}

export enum EditorField {
  // All
  TYPE = 'type',
  PROPS_LABEL = 'props.label',
  EXPRESSIONS_HIDE = 'expressions.hide',
  PROPS_SHOW_FOR_LOGGED_IN_USERS_ONLY = 'props.showForLoggedInUsersOnly',
  PROPS_SUBTITLE = 'props.subtitle',
  //All input fields
  KEY = 'key',
  PROPS_REQUIRED = 'props.required',
  PROPS_DESCRIPTION = 'props.description',
  PROPS_PLACEHOLDER = 'props.placeholder',
  // Calculation
  EXPRESSIONS_PROPS_CALCULATION_VALUE = 'expressions.props.calculationValue',
  PROPS_SEPARATOR = 'props.separator',
  PROPS_MAX_SCORE = 'props.maxScore',
  // Select, Multiselect, Radio,
  PROPS_OPTIONS = 'props.options',
  // Image, Image map
  PROPS_STORAGE_URL = 'props.storageURL',
  // Number
  PROPS_MIN = 'props.min',
  PROPS_MAX = 'props.max',
  PROPS_SUFFIX = 'props.suffix',
  PROPS_PREFIX = 'props.prefix',
  // Table
  FIELDARRAY_FIELDGROUP = 'fieldArray.fieldGroup',
  PROPS_ADD_TEXT = 'props.addText',
  // Field message, html
  PROPS_HTML = 'props.html',
  // Field message
  PROPS_HIDE_ICON = 'props.hideIcon',
  PROPS_SEVERITY = 'props.severity',
  PROPS_CUSTOM_TEMPLATE = 'props.customTemplate',
  PROPS_TEXT = 'props.text',
  PROPS_STYLE_CLASS = 'props.styleClass',
  PROPS_CLOSABLE = 'props.closable',
  PROPS_ESCAPE = 'props.escape',
  // Slider
  PROPS_MIN_LABEL = 'props.minLabel',
  PROPS_MAX_LABEL = 'props.maxLabel',
}

export const fieldEditorForm: FormlyFieldConfig[] = [
  {
    type: ClinicalFormField.SELECT,
    key: EditorField.TYPE,

    props: {
      label: 'Type',
      required: true,
      options: clinicalFormFieldOptions,
    },
  },
  {
    type: ClinicalFormField.TEXT,
    key: EditorField.KEY,
    props: { label: 'Key' },
    expressions: {
      hide: 'formState.fieldEditingFormController(formState.model?.type, field?.key)',
    },
    validators: {
      duplicateKey: {
        expression: (control: AbstractControl, field: FormlyFieldConfig) =>
          !field?.options?.formState?.clinicalFormKeys?.some(key => control.value === key),
        message: 'This key is already in use',
      },
    },
  },
  {
    type: ClinicalFormField.TEXT,
    key: EditorField.PROPS_LABEL,
    props: { label: 'Label' },
    expressions: {
      hide: 'formState.fieldEditingFormController(formState.model?.type, field?.key)',
    },
  },
  {
    type: ClinicalFormField.TEXT,
    key: EditorField.PROPS_STORAGE_URL,
    props: { label: 'Storage path for image' },
    expressions: {
      hide: 'formState.fieldEditingFormController(formState.model?.type, field?.key)',
    },
  },
  {
    type: ClinicalFormField.RADIO,
    key: EditorField.PROPS_REQUIRED,
    props: {
      label: 'Required',
      options: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ],
    },
    expressions: {
      hide: 'formState.fieldEditingFormController(formState.model?.type, field?.key)',
    },
  },
  {
    type: ClinicalFormField.TEXT,
    key: EditorField.EXPRESSIONS_HIDE,
    props: { label: 'Hide expression' },
    expressions: {
      hide: 'formState.fieldEditingFormController(formState.model?.type, field?.key)',
    },
  },
  {
    type: ClinicalFormField.RADIO,
    key: EditorField.PROPS_SHOW_FOR_LOGGED_IN_USERS_ONLY,
    props: {
      label: 'Show for logged in users only',
      options: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ],
    },
    expressions: {
      hide: 'formState.fieldEditingFormController(formState.model?.type, field?.key)',
    },
  },
  {
    type: ClinicalFormField.TEXT,
    key: EditorField.PROPS_DESCRIPTION,
    props: { label: 'Description' },
    expressions: {
      hide: 'formState.fieldEditingFormController(formState.model?.type, field?.key)',
    },
  },
  {
    type: ClinicalFormField.TEXT,
    key: EditorField.PROPS_PLACEHOLDER,
    props: { label: 'Placeholder' },
    expressions: {
      hide: 'formState.fieldEditingFormController(formState.model?.type, field?.key)',
    },
  },
  {
    type: ClinicalFormField.TEXTAREA,
    key: EditorField.PROPS_HTML,
    props: { label: 'HTML' },
    expressions: {
      hide: 'formState.fieldEditingFormController(formState.model?.type, field?.key)',
    },
  },
  {
    type: ClinicalFormField.TEXT,
    key: EditorField.EXPRESSIONS_PROPS_CALCULATION_VALUE,
    props: { label: 'Calculation expression' },
    expressions: {
      hide: 'formState.fieldEditingFormController(formState.model?.type, field?.key)',
    },
  },
  {
    type: ClinicalFormField.TEXT,
    key: EditorField.PROPS_SEPARATOR,
    props: { label: 'Separator' },
    expressions: {
      hide: 'formState.fieldEditingFormController(formState.model?.type, field?.key)',
    },
  },
  {
    type: ClinicalFormField.TEXT,
    key: EditorField.PROPS_MAX_SCORE,
    props: { label: 'Max score' },
    expressions: {
      hide: 'formState.fieldEditingFormController(formState.model?.type, field?.key)',
    },
  },
  {
    type: ClinicalFormField.TABLE,
    key: EditorField.PROPS_OPTIONS,
    props: {
      label: 'Options',
      addText: 'Add option',
    },
    defaultValue: [{}],
    fieldArray: {
      fieldGroup: [
        {
          key: 'value',
          type: ClinicalFormField.NUMBER,
          props: {
            label: 'Value',
            required: true,
          },
        },
        {
          key: 'label',
          type: ClinicalFormField.TEXT,
          props: {
            label: 'Label',
            required: true,
          },
        },
        {
          key: 'calculationValue',
          type: ClinicalFormField.NUMBER,
          props: {
            label: 'Calculation value',
          },
        },
        {
          key: 'colour',
          type: ClinicalFormField.SELECT,
          props: {
            label: 'Colour when submitting',
            options: colourOptions,
          },
        },
        {
          key: 'responseHighlightColour',
          type: ClinicalFormField.SELECT,
          props: {
            label: 'Colour when reviewing',
            options: colourOptions,
          },
        },
      ],
    },
    expressions: {
      hide: 'formState.fieldEditingFormController(formState.model?.type, field?.key)',
    },
  },

  {
    type: ClinicalFormField.NUMBER,
    key: EditorField.PROPS_MIN,
    props: {
      label: 'Minimum number',
    },
    expressions: {
      hide: 'formState.fieldEditingFormController(formState.model?.type, field?.key)',
    },
  },
  {
    type: ClinicalFormField.TEXT,
    key: EditorField.PROPS_MIN_LABEL,
    props: {
      label: 'Minimum slider range label',
    },
    expressions: {
      hide: 'formState.fieldEditingFormController(formState.model?.type, field?.key)',
    },
  },
  {
    type: ClinicalFormField.NUMBER,
    key: EditorField.PROPS_MAX,
    props: {
      label: 'Maximum number',
    },
    expressions: {
      hide: 'formState.fieldEditingFormController(formState.model?.type, field?.key)',
    },
  },
  {
    type: ClinicalFormField.TEXT,
    key: EditorField.PROPS_MAX_LABEL,
    props: {
      label: 'Maximum slider range label',
    },
    expressions: {
      hide: 'formState.fieldEditingFormController(formState.model?.type, field?.key)',
    },
  },
  {
    type: ClinicalFormField.TEXT,
    key: EditorField.PROPS_SUFFIX,
    props: { label: 'Units' },
    expressions: {
      hide: 'formState.fieldEditingFormController(formState.model?.type, field?.key)',
    },
  },
  {
    type: ClinicalFormField.TEXT,
    key: EditorField.PROPS_PREFIX,
    props: { label: 'Prefix' },
    expressions: {
      hide: 'formState.fieldEditingFormController(formState.model?.type, field?.key)',
    },
  },
  {
    type: ClinicalFormField.TABLE,
    key: EditorField.FIELDARRAY_FIELDGROUP,
    props: {
      label: 'Columns',
      addText: 'Add column',
      isEditor: true,
    },
    defaultValue: [{}],
    fieldArray: {
      fieldGroup: [
        {
          key: 'key',
          type: ClinicalFormField.TEXT,
          props: {
            label: 'Key',
            required: true,
          },
        },
        {
          key: 'props.label',
          type: ClinicalFormField.TEXT,
          props: {
            label: 'Label',
            required: true,
          },
        },
        {
          key: 'type',
          type: ClinicalFormField.SELECT,
          props: {
            label: 'Type',
            required: true,
            options: clinicalFormFieldOptions,
          },
        },
        {
          type: 'table',
          key: EditorField.PROPS_OPTIONS,
          props: {
            label: 'Options',
            addText: 'Add option',
            isEditor: true,
          },
          defaultValue: [{}],
          fieldArray: {
            fieldGroup: [
              {
                key: 'value',
                type: ClinicalFormField.NUMBER,
                props: {
                  label: 'Value',
                  required: true,
                },
              },
              {
                key: 'label',
                type: ClinicalFormField.TEXT,
                props: {
                  label: 'Label',
                  required: true,
                },
              },
            ],
          },
        },

        {
          type: ClinicalFormField.RADIO,
          key: EditorField.PROPS_REQUIRED,
          props: {
            label: 'Required',
            options: [
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ],
          },
          expressions: {
            hide: (field: FormlyFieldConfig) =>
              fieldEditingFormController(field.model[EditorField.TYPE] as ClinicalFormField, field.key as EditorField),
          },
        },
      ],
    },
    expressions: {
      hide: 'formState.fieldEditingFormController(formState.model?.type, field?.key)',
    },
  },
  {
    type: ClinicalFormField.TEXT,
    key: EditorField.PROPS_ADD_TEXT,
    props: { label: 'Add button text' },
    expressions: {
      hide: 'formState.fieldEditingFormController(formState.model?.type, field?.key)',
    },
  },
];
