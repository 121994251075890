<ng-container *ngIf="targetTeam$ | async as targetTeam">
  <p data-cy="confirmation-dialog-msg">
    <i class="pi pi-exclamation-triangle"></i>
    Are you sure you want to move<span *ngIf="data.entries.length > 1">
      all</span
    >
    <strong> {{ data.entries.length }}</strong> item<span
      *ngIf="data.entries.length > 1"
      >s</span
    >
    from <strong>{{ data.baseFolder }}</strong> to
    <strong>{{ data.destFolder.name }}</strong
    >?
  </p>
  <ng-container>
    <div class="field">
      <div class="justify-content-between flex align-items-center">
        <div
          class="folder-box border-2 border-solid border-main m-2 p-2 w-15rem h-6rem border-round-md text-center align-items-center gap-2"
        >
          <i class="pi pi-folder pr-1"></i>
          <body class="font-semibold text-sm line-height-3">
            {{ data.baseTeam }}
          </body>
          <h5 class="font-semibold text-base line-height-3.5 mt-0">
            {{ data.baseFolder }}
          </h5>
        </div>
        <i class="pi pi-arrow-right pr-1"></i>

        <div
          class="folder-box border-2 border-solid border-main m-2 p-2 w-15rem h-6rem border-round-md text-center align-items-center gap-2"
        >
          <i class="pi pi-folder pr-1"></i>
          <body class="font-semibold text-sm line-height-3">
            {{ targetTeam }}
          </body>
          <h5 class="font-semibold text-base line-height-3.5 mt-0">
            {{ data.destFolder.name }}
          </h5>
        </div>
      </div>
      <div class="mt-3">
        <p-messages severity="warn" styleClass="banner-sm">
          <ng-template pTemplate>
            <div class="flex align-items-center gap-2">
              <i class="pi pi-exclamation-triangle text-xl"></i>
              <span>
                These items will not be duplicated. Once moved the item will no
                longer be visible in the original folder.
              </span>
            </div>
          </ng-template>
        </p-messages>
      </div>
    </div>
  </ng-container>
  <div class="flex justify-content-end gap-2 mt-3 w-full">
    <button
      [label]="data.negativeButton"
      (click)="closeDialog()"
      class="p-button-outlined"
      icon="pi pi-arrow-left"
      aria-label="Close"
      type="button"
      pButton
    ></button>
    <button
      [label]="data.positiveButton"
      (click)="moveEntriesBetweenFolders()"
      icon="pi pi-folder"
      data-cy="confirmationConfirm"
      aria-label="Confirm"
      pButton
    ></button>
  </div>
</ng-container>
