<div
  [style.backgroundColor]="getBackgroundColor(label)"
  [style.height.px]="size"
  [style.width.px]="size"
  class="border-circle flex flex-row justify-content-center align-items-center p-1"
>
  <span
    *ngIf="label.length >= 1"
    [style.fontSize.px]="size / 2"
    class="text-main-inverse"
    data-testid="avatar-initials"
  >
    {{ getInitials(label) }}
  </span>
</div>
