import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-cadi-phq-form',
  templateUrl: './cadi-phq-form.component.html',
  styleUrls: ['./cadi-phq-form.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
})
export class CadiPhqFormComponent implements OnInit {
  @Input() data;
  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  cadiForm: UntypedFormGroup;
  phqForm: UntypedFormGroup;
  isotretinoinForm: UntypedFormGroup;
  isSubmitting = false;
  isLinear = true;

  answerList: string[] = [
    'Not at all - 0',
    'Several days - 1',
    'More than half the days - 2',
    'Nearly every day - 3',
  ];

  ngOnInit(): void {
    this.cadiForm = this.fb.group({
      feelingAggressiveFrustratedOrEmbarrassed: ['', Validators.required],
      acneInterferedWithSocialLifeEventsOrRelationships: [
        '',
        Validators.required,
      ],
      avoidedPublicChangingFacilities: ['', Validators.required],
      feelingsAboutSkinAppearance: ['', Validators.required],
      howBadIsYourAcneNow: ['', Validators.required],
      cadiTotalScore: [''],
    });
    this.phqForm = this.fb.group({
      littleInterestOrPleasure: ['', [Validators.required]],
      feelingDownDepressedOrHopeless: ['', [Validators.required]],
      troubleSleeping: ['', [Validators.required]],
      feelingTiredOrLittleEnergy: ['', [Validators.required]],
      poorAppetiteOrOvereating: ['', [Validators.required]],
      feelingBadAboutYourself: ['', [Validators.required]],
      troubleConcentratingOnThings: ['', [Validators.required]],
      movingOrSpeakingSlowerOrFasterThanNormal: ['', [Validators.required]],
      negativeThoughtsTowardsYourself: ['', [Validators.required]],
      howDifficultHaveTheseProblemsMadeThings: ['', [Validators.required]],
      phqTotalScore: [''],
    });

    this.isotretinoinForm = this.fb.group({
      areYouTakingIsotretinoin: [''],
      startedCurrentTreatmentCourse: [''],
      doseCurrentlyTaking: [''],
      takingAnyOtherPills: [''],
      facialMoisturiserBeingUsed: [''],
      lipBalmBeingUsed: [''],
      feelLikeAcneImproving: [''],
      dryFacialSkin: [''],
      dryLips: [''],
      dryOrIrritatedEyes: [''],
      rashOnTheBody: [''],
      noseBleeds: [''],
      changesToYourVision: [''],
      changeToYourBowelHabit: [''],
      changesToYourMood: [''],
      muscleAchesOrPain: [''],
      backPain: [''],
      erectileDysfunction: [''],
      areYouFemale: [''],
      areYouSexuallyActive: [''],
      areYouOnContraception: [''],
      contraceptionDetails: [''],
      doYouUseCondoms: [''],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.cadiForm.controls[key])
          this.cadiForm.controls[key].setValue(formData[key]);
        if (this.phqForm.controls[key])
          this.phqForm.controls[key].setValue(formData[key]);
      });
    });

    this.setConditionalValidation();
  }

  // CADI
  get feelingAggressiveFrustratedOrEmbarrassed() {
    return this.cadiForm.get('feelingAggressiveFrustratedOrEmbarrassed');
  }
  get acneInterferedWithSocialLifeEventsOrRelationships() {
    return this.cadiForm.get(
      'acneInterferedWithSocialLifeEventsOrRelationships'
    );
  }
  get avoidedPublicChangingFacilities() {
    return this.cadiForm.get('avoidedPublicChangingFacilities');
  }
  get feelingsAboutSkinAppearance() {
    return this.cadiForm.get('feelingsAboutSkinAppearance');
  }
  get howBadIsYourAcneNow() {
    return this.cadiForm.get('howBadIsYourAcneNow');
  }

  // PHQ
  get littleInterestOrPleasure() {
    return this.phqForm.get('littleInterestOrPleasure');
  }
  get feelingDownDepressedOrHopeless() {
    return this.phqForm.get('feelingDownDepressedOrHopeless');
  }
  get troubleSleeping() {
    return this.phqForm.get('troubleSleeping');
  }
  get feelingTiredOrLittleEnergy() {
    return this.phqForm.get('feelingTiredOrLittleEnergy');
  }
  get poorAppetiteOrOvereating() {
    return this.phqForm.get('poorAppetiteOrOvereating');
  }
  get feelingBadAboutYourself() {
    return this.phqForm.get('feelingBadAboutYourself');
  }
  get troubleConcentratingOnThings() {
    return this.phqForm.get('troubleConcentratingOnThings');
  }
  get movingOrSpeakingSlowerOrFasterThanNormal() {
    return this.phqForm.get('movingOrSpeakingSlowerOrFasterThanNormal');
  }
  get negativeThoughtsTowardsYourself() {
    return this.phqForm.get('negativeThoughtsTowardsYourself');
  }
  get howDifficultHaveTheseProblemsMadeThings() {
    return this.phqForm.get('howDifficultHaveTheseProblemsMadeThings');
  }

  get areYouTakingIsotretinoin() {
    return this.isotretinoinForm.get('areYouTakingIsotretinoin');
  }
  get startedCurrentTreatmentCourse() {
    return this.isotretinoinForm.get('startedCurrentTreatmentCourse');
  }
  get doseCurrentlyTaking() {
    return this.isotretinoinForm.get('doseCurrentlyTaking');
  }
  get takingAnyOtherPills() {
    return this.isotretinoinForm.get('takingAnyOtherPills');
  }
  get facialMoisturiserBeingUsed() {
    return this.isotretinoinForm.get('facialMoisturiserBeingUsed');
  }
  get lipBalmBeingUsed() {
    return this.isotretinoinForm.get('lipBalmBeingUsed');
  }
  get feelLikeAcneImproving() {
    return this.isotretinoinForm.get('feelLikeAcneImproving');
  }
  get dryFacialSkin() {
    return this.isotretinoinForm.get('dryFacialSkin');
  }
  get dryLips() {
    return this.isotretinoinForm.get('dryLips');
  }
  get dryOrIrritatedEyes() {
    return this.isotretinoinForm.get('dryOrIrritatedEyes');
  }
  get rashOnTheBody() {
    return this.isotretinoinForm.get('rashOnTheBody');
  }
  get noseBleeds() {
    return this.isotretinoinForm.get('noseBleeds');
  }
  get changesToYourVision() {
    return this.isotretinoinForm.get('changesToYourVision');
  }
  get changeToYourBowelHabit() {
    return this.isotretinoinForm.get('changeToYourBowelHabit');
  }
  get changesToYourMood() {
    return this.isotretinoinForm.get('changesToYourMood');
  }
  get muscleAchesOrPain() {
    return this.isotretinoinForm.get('muscleAchesOrPain');
  }
  get backPain() {
    return this.isotretinoinForm.get('backPain');
  }
  get erectileDysfunction() {
    return this.isotretinoinForm.get('erectileDysfunction');
  }
  get areYouFemale() {
    return this.isotretinoinForm.get('areYouFemale');
  }
  get areYouSexuallyActive() {
    return this.isotretinoinForm.get('areYouSexuallyActive');
  }
  get areYouOnContraception() {
    return this.isotretinoinForm.get('areYouOnContraception');
  }
  get contraceptionDetails() {
    return this.isotretinoinForm.get('contraceptionDetails');
  }
  get doYouUseCondoms() {
    return this.isotretinoinForm.get('doYouUseCondoms');
  }

  onSubmit() {
    if (!this.cadiFormValid()) return;
    this.setCadiScore();
    this.setPhqScore();

    this.isSubmitting = true;
    const formResponse = {
      ...this.cadiForm.value,
      ...this.phqForm.value,
      ...this.isotretinoinForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
      showOnChart: true,
    };
    this.form.submit(formResponse);
  }

  setConditionalValidation() {
    this.isotretinoinForm
      .get('areYouTakingIsotretinoin')
      .valueChanges.subscribe(() => {
        if (
          this.isotretinoinForm
            .get('areYouTakingIsotretinoin')
            .value.indexOf('Yes') !== -1
        ) {
          this.isotretinoinForm
            .get('startedCurrentTreatmentCourse')
            .setValidators([Validators.required]);
          this.isotretinoinForm
            .get('doseCurrentlyTaking')
            .setValidators([Validators.required]);
          this.isotretinoinForm
            .get('takingAnyOtherPills')
            .setValidators([Validators.required]);
          this.isotretinoinForm
            .get('facialMoisturiserBeingUsed')
            .setValidators([Validators.required]);
          this.isotretinoinForm
            .get('lipBalmBeingUsed')
            .setValidators([Validators.required]);
          this.isotretinoinForm
            .get('feelLikeAcneImproving')
            .setValidators([Validators.required]);
          this.isotretinoinForm
            .get('dryFacialSkin')
            .setValidators([Validators.required]);
          this.isotretinoinForm
            .get('dryLips')
            .setValidators([Validators.required]);
          this.isotretinoinForm
            .get('dryOrIrritatedEyes')
            .setValidators([Validators.required]);
          this.isotretinoinForm
            .get('rashOnTheBody')
            .setValidators([Validators.required]);
          this.isotretinoinForm
            .get('noseBleeds')
            .setValidators([Validators.required]);
          this.isotretinoinForm
            .get('changesToYourVision')
            .setValidators([Validators.required]);
          this.isotretinoinForm
            .get('changeToYourBowelHabit')
            .setValidators([Validators.required]);
          this.isotretinoinForm
            .get('changesToYourMood')
            .setValidators([Validators.required]);
          this.isotretinoinForm
            .get('muscleAchesOrPain')
            .setValidators([Validators.required]);
          this.isotretinoinForm
            .get('backPain')
            .setValidators([Validators.required]);
          this.isotretinoinForm
            .get('erectileDysfunction')
            .setValidators([Validators.required]);
        }
      });
  }

  cadiFormValid(): boolean {
    return this.cadiForm.valid;
  }

  phqFormValid(): boolean {
    return (
      this.phqForm.dirty &&
      !(
        this.phqForm.controls &&
        this.phqForm.controls.littleInterestOrPleasure.errors
      ) &&
      !(
        this.phqForm.controls &&
        this.phqForm.controls.feelingDownDepressedOrHopeless.errors
      ) &&
      !(
        this.phqForm.controls && this.phqForm.controls.troubleSleeping.errors
      ) &&
      !(
        this.phqForm.controls &&
        this.phqForm.controls.feelingTiredOrLittleEnergy.errors
      ) &&
      !(
        this.phqForm.controls &&
        this.phqForm.controls.poorAppetiteOrOvereating.errors
      ) &&
      !(
        this.phqForm.controls &&
        this.phqForm.controls.feelingBadAboutYourself.errors
      ) &&
      !(
        this.phqForm.controls &&
        this.phqForm.controls.troubleConcentratingOnThings.errors
      ) &&
      !(
        this.phqForm.controls &&
        this.phqForm.controls.movingOrSpeakingSlowerOrFasterThanNormal.errors
      ) &&
      !(
        this.phqForm.controls &&
        this.phqForm.controls.negativeThoughtsTowardsYourself.errors
      ) &&
      !(
        this.phqForm.controls &&
        this.phqForm.controls.howDifficultHaveTheseProblemsMadeThings.errors
      )
    );
  }

  isotretinoinFormValid(): boolean {
    return (
      this.isotretinoinForm.dirty &&
      !(
        this.isotretinoinForm.controls &&
        this.isotretinoinForm.controls.startedCurrentTreatmentCourse.errors
      ) &&
      !(
        this.isotretinoinForm.controls &&
        this.isotretinoinForm.controls.doseCurrentlyTaking.errors
      ) &&
      !(
        this.isotretinoinForm.controls &&
        this.isotretinoinForm.controls.takingAnyOtherPills.errors
      ) &&
      !(
        this.isotretinoinForm.controls &&
        this.isotretinoinForm.controls.facialMoisturiserBeingUsed.errors
      ) &&
      !(
        this.isotretinoinForm.controls &&
        this.isotretinoinForm.controls.lipBalmBeingUsed.errors
      ) &&
      !(
        this.isotretinoinForm.controls &&
        this.isotretinoinForm.controls.feelLikeAcneImproving.errors
      ) &&
      !(
        this.isotretinoinForm.controls &&
        this.isotretinoinForm.controls.dryFacialSkin.errors
      ) &&
      !(
        this.isotretinoinForm.controls &&
        this.isotretinoinForm.controls.dryLips.errors
      ) &&
      !(
        this.isotretinoinForm.controls &&
        this.isotretinoinForm.controls.dryOrIrritatedEyes.errors
      ) &&
      !(
        this.isotretinoinForm.controls &&
        this.isotretinoinForm.controls.rashOnTheBody.errors
      ) &&
      !(
        this.isotretinoinForm.controls &&
        this.isotretinoinForm.controls.noseBleeds.errors
      ) &&
      !(
        this.isotretinoinForm.controls &&
        this.isotretinoinForm.controls.changesToYourVision.errors
      ) &&
      !(
        this.isotretinoinForm.controls &&
        this.isotretinoinForm.controls.changeToYourBowelHabit.errors
      ) &&
      !(
        this.isotretinoinForm.controls &&
        this.isotretinoinForm.controls.changesToYourMood.errors
      ) &&
      !(
        this.isotretinoinForm.controls &&
        this.isotretinoinForm.controls.muscleAchesOrPain.errors
      ) &&
      !(
        this.isotretinoinForm.controls &&
        this.isotretinoinForm.controls.backPain.errors
      ) &&
      !(
        this.isotretinoinForm.controls &&
        this.isotretinoinForm.controls.erectileDysfunction.errors
      )
    );
  }

  setCadiScore() {
    const cadiQuestion1 = this.cadiForm.get(
      'feelingAggressiveFrustratedOrEmbarrassed'
    ).value;
    const cadiQuestion2 = this.cadiForm.get(
      'acneInterferedWithSocialLifeEventsOrRelationships'
    ).value;
    const cadiQuestion3 = this.cadiForm.get(
      'avoidedPublicChangingFacilities'
    ).value;
    const cadiQuestion4 = this.cadiForm.get(
      'feelingsAboutSkinAppearance'
    ).value;
    const cadiQuestion5 = this.cadiForm.get('howBadIsYourAcneNow').value;

    const cadiTotalScore =
      +cadiQuestion1.match(/\d+/g) +
      +cadiQuestion2.match(/\d+/g) +
      +cadiQuestion3.match(/\d+/g) +
      +cadiQuestion4.match(/\d+/g) +
      +cadiQuestion5.match(/\d+/g);

    this.cadiForm.get('cadiTotalScore').setValue(cadiTotalScore);
  }

  setPhqScore() {
    const phqQuestion1 = this.phqForm.get('littleInterestOrPleasure').value;
    const phqQuestion2 = this.phqForm.get(
      'feelingDownDepressedOrHopeless'
    ).value;
    const phqQuestion3 = this.phqForm.get('troubleSleeping').value;
    const phqQuestion4 = this.phqForm.get('feelingTiredOrLittleEnergy').value;
    const phqQuestion5 = this.phqForm.get('poorAppetiteOrOvereating').value;
    const phqQuestion6 = this.phqForm.get('feelingBadAboutYourself').value;
    const phqQuestion7 = this.phqForm.get('troubleConcentratingOnThings').value;
    const phqQuestion8 = this.phqForm.get(
      'movingOrSpeakingSlowerOrFasterThanNormal'
    ).value;
    const phqQuestion9 = this.phqForm.get(
      'negativeThoughtsTowardsYourself'
    ).value;

    const phqTotalScore =
      +phqQuestion1.match(/\d+/g) +
      +phqQuestion2.match(/\d+/g) +
      +phqQuestion3.match(/\d+/g) +
      +phqQuestion4.match(/\d+/g) +
      +phqQuestion5.match(/\d+/g) +
      +phqQuestion6.match(/\d+/g) +
      +phqQuestion7.match(/\d+/g) +
      +phqQuestion8.match(/\d+/g) +
      +phqQuestion9.match(/\d+/g);

    this.phqForm.get('phqTotalScore').setValue(phqTotalScore);
  }
}
