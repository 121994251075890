import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Constraint, TagFilter, TagGroup } from '@islacare/ic-types';
import { Observable, map } from 'rxjs';
import {
  SubmissionGroupedTagsForDropdowns,
  SubmissionTagForDropdowns,
} from '../../interfaces/submissions-dashboard.interface';

// TODO fix interface in file. Actual tag interface used on table row is FormResponseTagRow
export type TagGroupWithId = TagGroup & { tagGroupId: string };

export interface FormResponseTagRow {
  tagGroupId: string;
  tagKey: string;
  tagDisplayName: string;
}

@Injectable({
  providedIn: 'root',
})
export class SubmissionsTagsService {
  constructor(private db: AngularFirestore) {}

  getGroupedTags$(teamIds: string[]): Observable<SubmissionGroupedTagsForDropdowns[]> {
    return this.getTagGroupsFromTeamId$(teamIds).pipe(
      map(tagGroups => {
        const mappedTagGroups = this.mapTagGroupsToDropdownTagGroups(tagGroups);
        return mappedTagGroups;
      }),
    );
  }

  getTagGroupsFromTeamId$(teamIds: string[]): Observable<TagGroupWithId[]> {
    return this.db
      .collection<TagGroup>('tagGroups')
      .valueChanges({ idField: 'tagGroupId' })
      .pipe(
        map((docs: TagGroupWithId[]) =>
          docs.filter(tagGroup => tagGroup.teamIds && teamIds.some(id => tagGroup.teamIds.includes(id))),
        ),
      );
  }

  mapTagGroupsToDropdownTagGroups(tagGroups: TagGroupWithId[]): SubmissionGroupedTagsForDropdowns[] {
    const groupedSubmissionTags: SubmissionGroupedTagsForDropdowns[] = [];

    tagGroups.forEach(tagGroupWithId => {
      const formattedTags = Object.keys(tagGroupWithId.options).map(key => {
        const formattedTag: SubmissionTagForDropdowns = {
          tagGroupKey: tagGroupWithId.tagGroupId,
          tagDisplayName: tagGroupWithId.options[key].displayName,
          tagKey: key,
        };

        return formattedTag;
      });

      const tagGroupWithTags: SubmissionGroupedTagsForDropdowns = {
        tagGroupDisplayName: tagGroupWithId.groupDisplayName,
        tagGroupKey: tagGroupWithId.tagGroupId,
        tags: formattedTags,
      };

      groupedSubmissionTags.push(tagGroupWithTags);
    });

    return groupedSubmissionTags;
  }

  /**
   * For form responses & media filters
   */

  mapSelectedTagsWithConstraints(
    selectedIncludedTags: SubmissionTagForDropdowns[],
    selectedExcludedTags: SubmissionTagForDropdowns[],
  ): TagFilter[] {
    let mappedIncludedTagWithConstraint: TagFilter[] = [];
    let mappedExcludedTagWithConstraint: TagFilter[] = [];

    if (selectedIncludedTags?.length) {
      mappedIncludedTagWithConstraint = this.mapTagWithConstraint(selectedIncludedTags, Constraint.CONTAINS);
    }

    if (selectedExcludedTags?.length) {
      mappedExcludedTagWithConstraint = this.mapTagWithConstraint(selectedExcludedTags, Constraint.NOT_CONTAINS);
    }

    return [...mappedIncludedTagWithConstraint, ...mappedExcludedTagWithConstraint];
  }

  private mapTagWithConstraint(selectedTags: SubmissionTagForDropdowns[], constraint: Constraint): TagFilter[] {
    const mappedTags: TagFilter[] = selectedTags?.map((tag: SubmissionTagForDropdowns) => {
      const mappedTag: TagFilter = {
        tagGroupId: tag.tagGroupKey,
        tagKey: tag.tagKey,
        constraint: constraint,
      };
      return mappedTag;
    });
    return mappedTags;
  }
}
