<form [formGroup]="neuropathicPainSymptomInventoryForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <div>
      <p>1. Does your pain feel like burning?</p>
      <div class="full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>0</div>
          <div>No burning</div>
        </div>
        <mat-slider
          class="full-width-field"
          formControlName="painFeelLikeBurningSlider"
          value="painFeelLikeBurningSlider"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>10</div>
          <div>Extreme burning</div>
        </div>
      </div>
    </div>
    <div>
      <p>2. Does your pain feel like squeezing?</p>
      <div class="full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>0</div>
          <div>No squeezing</div>
        </div>
        <mat-slider
          class="full-width-field"
          formControlName="painFeelLikeSqueezingSlider"
          value="painFeelLikeSqueezingSlider"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>10</div>
          <div>Extreme squeezing</div>
        </div>
      </div>
    </div>
    <div>
      <p>3. Does your pain feel like pressure?</p>
      <div class="full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>0</div>
          <div>No pressure</div>
        </div>
        <mat-slider
          class="full-width-field"
          formControlName="painFeelLikePressureSlider"
          value="painFeelLikePressureSlider"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>10</div>
          <div>Extreme pressure</div>
        </div>
      </div>
    </div>
    <div class="question-container">
      <div class="half-width-field">
        <p>
          4. During the past 24 hours your spontaneous pain has been present, please select the
          response that best describes your case:
        </p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="painHasBeenPresent">
          <mat-option value="Permanently">Permanently</mat-option>
          <mat-option value="Between 8 and 12 hours">Between 8 and 12 hours</mat-option>
          <mat-option value="Between 4 and 7 hours">Between 4 and 7 hours</mat-option>
          <mat-option value="Between 1 and 3 hours">Between 1 and 3 hours</mat-option>
          <mat-option value="Less than 1 hour">Less than 1 hour</mat-option>
        </mat-select>
        <mat-error *ngIf="painHasBeenPresent.touched">Please select an option</mat-error>
      </mat-form-field>
    </div>
    <div class="full-width-field" style="margin: auto">
      <p>
        We wish to know if you have brief attacks of pain. For each of the following questions,
        please select the number that best describes the
        <em>average severity of your painful attacks during the past 24 hours.</em> Select the
        number 0 if you have not felt such pain.
      </p>
    </div>
    <div>
      <p>5. Does your pain feel like electric shocks?</p>
      <div class="full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>0</div>
          <div>No electric shocks</div>
        </div>
        <mat-slider
          class="full-width-field"
          formControlName="painFeelLikeElectricShocksSlider"
          value="painFeelLikeElectricShocksSlider"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>10</div>
          <div>Extreme electric shocks</div>
        </div>
      </div>
    </div>
    <div>
      <p>6. Does your pain feel like stabbing?</p>
      <div class="full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>0</div>
          <div>No stabbing</div>
        </div>
        <mat-slider
          class="full-width-field"
          formControlName="painFeelLikeStabbingSlider"
          value="painFeelLikeStabbingSlider"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>10</div>
          <div>Extreme stabbing</div>
        </div>
      </div>
    </div>
    <div class="question-container">
      <div class="half-width-field">
        <p>
          7. During the past 24 hours how many of these pain attacks have you had? please select the
          response that best describes your case:
        </p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="painAttacks">
          <mat-option value="More than 20">More than 20</mat-option>
          <mat-option value="Between 11 and 20">Between 11 and 20</mat-option>
          <mat-option value="Between 6 and 10">Between 6 and 10</mat-option>
          <mat-option value="Between 1 and 5">Between 1 and 5</mat-option>
          <mat-option value="No pain attack">No pain attack</mat-option>
        </mat-select>
        <mat-error *ngIf="painAttacks.touched">Please select an option</mat-error>
      </mat-form-field>
    </div>
    <div class="full-width-field" style="margin: auto">
      <p>
        We wish to know if you feel pain provoked or increased by brushing, pressure, contact with
        cold or warmth on the painful area. For each of the following questions, please select the
        number that best describes the
        <em>average severity of your provoked pain during the past 24 hours.</em> Select the number
        0 if you have not felt such pain.
      </p>
    </div>
    <div>
      <p>8. Is your pain provoked or increased by brushing on the painful area?</p>
      <div class="full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>0</div>
          <div>Not provoked</div>
        </div>
        <mat-slider
          class="full-width-field"
          formControlName="painIncreasedByBrushingSlider"
          value="painIncreasedByBrushingSlider"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>10</div>
          <div>Extremely provoked</div>
        </div>
      </div>
    </div>
    <div>
      <p>9. Is your pain provoked or increased by pressure on the painful area?</p>
      <div class="full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>0</div>
          <div>Not provoked</div>
        </div>
        <mat-slider
          class="full-width-field"
          formControlName="painIncreasedByPressureSlider"
          value="painIncreasedByPressureSlider"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>10</div>
          <div>Extremely provoked</div>
        </div>
      </div>
    </div>
    <div>
      <p>
        10. Is your pain provoked or increased by contact with something cold on the painful area?
      </p>
      <div class="full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>0</div>
          <div>Not provoked</div>
        </div>
        <mat-slider
          class="full-width-field"
          formControlName="painIncreasedByContactWithColdSlider"
          value="painIncreasedByContactWithColdSlider"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>10</div>
          <div>Extremely provoked</div>
        </div>
      </div>
    </div>
    <div class="full-width-field" style="margin: auto">
      <p>
        We wish to know if your feel abnormal sensations <em>in the painful area.</em> For each of
        the following questions, please select the number that best describes the
        <em>average severity of your abnormal sensations during the past 24 hours.</em> Select the
        number 0 if you have not felt such sensation.
      </p>
    </div>
    <div>
      <p>11. Do you feel pins and needles?</p>
      <div class="full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>0</div>
          <div>No pins and needles</div>
        </div>
        <mat-slider
          class="full-width-field"
          formControlName="painFeelsLikePinsAndNeedlesSlider"
          value="painFeelsLikePinsAndNeedlesSlider"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>10</div>
          <div>Extreme pins and needles</div>
        </div>
      </div>
    </div>
    <div>
      <p>12. Do you feel tingling?</p>
      <div class="full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>0</div>
          <div>No tingling</div>
        </div>
        <mat-slider
          class="full-width-field"
          formControlName="painFeelsLikeTinglingSlider"
          value="painFeelsLikeTinglingSlider"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>10</div>
          <div>Extreme tingling</div>
        </div>
      </div>
    </div>
    <div>
      <button mat-raised-button class="positive-button" type="submit" [disabled]="isSubmitting">
        {{ isSubmitting ? 'Submitting...' : 'Submit' }}
      </button>
    </div>
  </div>
</form>
