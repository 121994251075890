import { Component } from '@angular/core';
import { EntryWithId } from '@islacare/ic-types';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef
} from 'primeng/dynamicdialog';

import { SystemCheckService } from '@ic-monorepo/shared-common';
import { Observable } from 'rxjs';
import { CollectionsService } from '../../../../services/collections/collections.service';
import { TeamsService } from '../../../../services/teams/teams.service';
import { DialogData } from '../../../../shared/components/dialogs/dialog-data';
import { RdPatientRecordDialogsService } from '../../services/rd-patient-record-dialogs/rd-patient-record-dialogs.service';
import { MoveEntriesConfirmationDialogComponent } from '../rd-move-entries-confirmation-dialog/rd-move-entries-confirmation.component';
interface MoveEntriesData extends DialogData {
  entries: EntryWithId[];
}

@Component({
  selector: 'ic-move-entries-dialog',
  templateUrl: './rd-move-entries.component.html',
  styleUrls: ['./rd-move-entries.component.scss']
})
export class MoveEntriesDialogComponent {
  data: MoveEntriesData;
  targetFolder$: Observable<
    {
      label: string;
      items: any;
    }[]
  >;
  selectedFolderLabel: any;
  isMobile = this.systemCheck?.isMobile;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private patientRecordService: RdPatientRecordDialogsService,
    private teamsService: TeamsService,
    private collectionsService: CollectionsService,
    private dialogService: DialogService,
    private systemCheck: SystemCheckService
  ) {
    this.data = config.data;
  }
  ngOnInit() {
    this.targetFolder$ = this.patientRecordService.getTargetFolders$(
      this.data.entries
    );
  }

  async moveEntriesBetweenFoldersConfirmation() {
    const dialogRef = this.dialogService.open(
      MoveEntriesConfirmationDialogComponent,
      {
        header: `Confirm move items`,
        width: '50%',
        data: {
          message: ``,
          entries: this.data?.entries,
          baseFolder: (
            await this.collectionsService.getCollectionSnapshot(
              this.data?.entries[0].patientId,
              this.data?.entries[0].collectionId
            )
          ).data()?.name,
          destFolder: this.selectedFolderLabel,
          baseTeam: (
            await this.teamsService.getTeamSnapshot(
              this.data?.entries[0].entryTeamId
            )
          ).data()?.name,
          positiveButton: 'Yes, move all selected items',
          negativeButton: 'Back'
        }
      }
    );
    dialogRef?.onClose.subscribe(() => {
      this.closeDialog();
    });
  }
  closeDialog() {
    this.ref.close();
  }
}
