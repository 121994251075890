<div class="flex flex-column gap-2" #arrayHeightRef *ngIf="formGroup" id="arrayContainer">
  <div>
    <p-table
      [value]="control.controls"
      styleClass="p-datatable-sm"
      class="automatic-form-array-table"
      #primeTable
      [responsiveLayout]="sc.isMobile ? 'stack' : 'scroll'"
    >
      <ng-template pTemplate="caption">
        {{ field.title }}
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <ng-container *ngFor="let formArrayField of field?.formArrayFields">
            <th>
              {{
                formArrayField?.title || formArrayField?.subTitle || formArrayField?.controlName
                  | camelCaseToTitleCase
              }}{{ formArrayField?.validation?.required ? ' *' : '' }}
            </th>
          </ng-container>
          <th style="text-align: center">Delete</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-i="rowIndex">
        <tr class="scalein">
          <ng-container *ngFor="let formArrayField of field?.formArrayFields">
            <td>
              <span class="p-column-title"
                >{{
                  formArrayField?.title || formArrayField?.subTitle || formArrayField?.controlName
                    | camelCaseToTitleCase
                }}{{ formArrayField?.validation?.required ? ' *' : '' }}</span
              >
              <ic-automatic-form-reference
                *ngIf="tableData?.fields[formArrayField?.controlName] && getFormGroup(i)?.controls"
                [field]="tableData?.fields[formArrayField?.controlName]"
                [control]="getFormGroup(i)?.controls"
                [automaticForm]="getFormGroup(i)"
                [formFields]="field?.formArrayFields"
                [index]="i"
                [isFormArray]="true"
                (scoresArray)="addScores($event)"
                [class]="formArrayField?.controlName + i"
              >
              </ic-automatic-form-reference>
            </td>
          </ng-container>
          <td style="text-align: center">
            <button
              class="p-button-rounded p-button-text p-button-danger mr-2"
              icon="pi pi-trash"
              type="button"
              pRipple
              pButton
              (click)="deleteForm(i)"
            >
              ᠎
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="flex justify-content-end">
    <div>
      <button
        (click)="addForm()"
        data-cy="form-array-add"
        style="background-color: #ef7715; cursor: pointer; float: right; border: none"
        class="p-button-raised"
        type="button"
        label="Add Record"
        icon="pi pi-plus"
        pButton
      >
        ᠎
      </button>
    </div>
  </div>
</div>
