export default [
  'testedPositive',
  'closeContactSymptoms',
  'closeContactPositive',
  'highTemprature',
  'smellTasteLoss',
  'continuousCough',
  'redListTravel',
  'visitorName',
  'visitorRelation'
];
