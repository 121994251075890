import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { MenuItem } from 'primeng/api';
import { ClinicalFormsEditService } from '../../../services/clinical-forms-edit/clinical-forms-edit.service';

@Component({
  selector: 'ic-clinical-form-wrapper-inline-field',
  templateUrl: './clinical-form-wrapper-inline-field.component.html',
  styleUrls: ['./clinical-form-wrapper-inline-field.component.scss']
})
export class ClinicalFormWrapperInlineFieldComponent extends FieldWrapper {
  items: MenuItem[] = [
    {
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: event => this.edit(event)
    }
  ];

  constructor(private formEditService: ClinicalFormsEditService) {
    super();
  }

  edit(event) {
    this.formEditService.editField(this.field);
  }
}
