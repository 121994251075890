<form [formGroup]="lothianForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <h3>Lothian patient questionnaire</h3>

    <div class="question-container">
      <div class="half-width-field">
        <p>Has the wound been painful to touch?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select
          formControlName="woundBeenPainfulToTouch"
          (selectionChange)="woundPainfulValidation($event.value)"
        >
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
        <mat-error *ngIf="woundBeenPainfulToTouch.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <div
      class="question-container"
      *ngIf="lothianForm.get('woundBeenPainfulToTouch').value === 'Yes'"
    >
      <div class="half-width-field">
        <p>Over the past day, do you feel the pain is:</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="howHasThePainBeen">
          <mat-option value="Worse">Worse</mat-option>
          <mat-option value="Same">Same</mat-option>
          <mat-option value="Better">Better</mat-option>
          <mat-option value="New (first time noticed)"
            >New (first time noticed)</mat-option
          >
        </mat-select>
        <mat-error *ngIf="howHasThePainBeen.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Is there liquid coming from the wound site?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select
          formControlName="liquidComingFromTheSite"
          (selectionChange)="liquidFromSiteValidation($event.value)"
        >
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
        <mat-error *ngIf="liquidComingFromTheSite.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <div
      class="question-container"
      *ngIf="lothianForm.get('liquidComingFromTheSite').value === 'Yes'"
    >
      <div class="half-width-field">
        <p>Over the past day, has the amount of fluid leaking:</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="fluidLeakingOverThePastDay">
          <mat-option value="Increased">Increased</mat-option>
          <mat-option value="Stayed the same">Stayed the same</mat-option>
          <mat-option value="Reduced">Reduced</mat-option>
          <mat-option value="New (first time noticed)"
            >New (first time noticed)</mat-option
          >
        </mat-select>
        <mat-error *ngIf="fluidLeakingOverThePastDay.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <div
      class="question-container"
      *ngIf="lothianForm.get('liquidComingFromTheSite').value === 'Yes'"
    >
      <div class="half-width-field">
        <p>What is the colour/consistency of the fluid at present?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="colourAndConsistencyOfTheFluid">
          <mat-option value="Clear">Clear</mat-option>
          <mat-option value="Bloody">Bloody</mat-option>
          <mat-option value="Yellowish">Yellowish</mat-option>
          <mat-option value="Thick/yellow">Thick/yellow</mat-option>
          <mat-option value="Green/brown">Green/brown</mat-option>
        </mat-select>
        <mat-error *ngIf="colourAndConsistencyOfTheFluid.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Is there redness spreading away from the wound?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select
          formControlName="rednessSpreadingAwayFromWound"
          (selectionChange)="rednessSpreadingValidation($event.value)"
        >
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
        <mat-error *ngIf="rednessSpreadingAwayFromWound.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <div
      class="question-container"
      *ngIf="lothianForm.get('rednessSpreadingAwayFromWound').value === 'Yes'"
    >
      <div class="half-width-field">
        <p>Over the past day, has the redness gotten:</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="rednessStatus">
          <mat-option value="Worse (redder or bigger)"
            >Worse (redder or bigger)</mat-option
          >
          <mat-option value="Same">Same</mat-option>
          <mat-option value="Better (less red or smaller)"
            >Better (less red or smaller)</mat-option
          >
          <mat-option value="New (first time noticed)"
            >New (first time noticed)</mat-option
          >
        </mat-select>
        <mat-error *ngIf="rednessStatus.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Has the area around the wound become swollen?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select
          formControlName="hasTheAreaAroundTheWoundBecomeSwollen"
          (selectionChange)="woundAreaSwollenValidation($event.value)"
        >
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
        <mat-error *ngIf="hasTheAreaAroundTheWoundBecomeSwollen.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <div
      class="question-container"
      *ngIf="
        lothianForm.get('hasTheAreaAroundTheWoundBecomeSwollen').value === 'Yes'
      "
    >
      <div class="half-width-field">
        <p>Over the past day, has the swelling gotten:</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select
          formControlName="hasTheAreaAroundTheWoundBecomeSwollenDetails"
        >
          <mat-option value="Worse (more swollen or bigger area)"
            >Worse (more swollen or bigger area)</mat-option
          >
          <mat-option value="Same">Same</mat-option>
          <mat-option value="Better (less swollen or smaller area)"
            >Better (less swollen or smaller area)</mat-option
          >
          <mat-option value="New (first time noticed)"
            >New (first time noticed)</mat-option
          >
        </mat-select>
        <mat-error *ngIf="hasTheAreaAroundTheWoundBecomeSwollenDetails.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Was the area around the wound warmer than the surrounding skin?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select
          formControlName="hasTheWoundedAreaBecomeWarmer"
          (selectionChange)="woundAreaWarmerValidation($event.value)"
        >
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
        <mat-error *ngIf="hasTheWoundedAreaBecomeWarmer.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <div
      class="question-container"
      *ngIf="lothianForm.get('hasTheWoundedAreaBecomeWarmer').value === 'Yes'"
    >
      <div class="half-width-field">
        <p>Over the past day, has the warmness gotten:</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="hasTheWoundedAreaBecomeWarmerDetails">
          <mat-option value="Worse (hotter to touch)"
            >Worse (hotter to touch)</mat-option
          >
          <mat-option value="Same">Same</mat-option>
          <mat-option value="Better (less hot to touch)"
            >Better (less hot to touch)</mat-option
          >
          <mat-option value="New (first time noticed)"
            >New (first time noticed)</mat-option
          >
        </mat-select>
        <mat-error *ngIf="hasTheWoundedAreaBecomeWarmerDetails.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>
          Have the edges of any part of the wound separated/gaped open of their
          own accord?
        </p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select
          formControlName="hasAnyPartSeparatedOrGaped"
          (selectionChange)="woundSeparatedValidation($event.value)"
        >
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
        <mat-error *ngIf="hasAnyPartSeparatedOrGaped.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <div
      class="question-container"
      *ngIf="lothianForm.get('hasAnyPartSeparatedOrGaped').value === 'Yes'"
    >
      <div class="half-width-field">
        <p>Over the past day, has the wound opened more?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="hasTheWoundOpenedMoreDetails">
          <mat-option value="Worse (more opening up)"
            >Worse (more opening up)</mat-option
          >
          <mat-option value="Same">Same</mat-option>
          <mat-option value="Better (closing up)"
            >Better (closing up)</mat-option
          >
          <mat-option value="New (first time noticed)"
            >New (first time noticed)</mat-option
          >
        </mat-select>
        <mat-error *ngIf="hasTheWoundOpenedMoreDetails.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <div
      class="question-container"
      *ngIf="lothianForm.get('hasAnyPartSeparatedOrGaped').value === 'Yes'"
    >
      <div class="half-width-field">
        <p>Has the deeper tissue separated?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="hasTheDeeperTissueSeparated">
          <mat-option value="Just the skin">Just the skin</mat-option>
          <mat-option value="Deeper">Deeper</mat-option>
        </mat-select>
        <mat-error *ngIf="hasTheDeeperTissueSeparated.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>
          Have you had, or felt like you have had, a raised temperature or
          fever? (fever &gt; 38 °C)
        </p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="haveYouHadAFever">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
        <mat-error *ngIf="haveYouHadAFever.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Have you been diagnosed with a wound infection since surgery?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select
          formControlName="haveYouBeenDiagnosedWithAnInfectionSinceSurgery"
        >
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
        <mat-error
          *ngIf="haveYouBeenDiagnosedWithAnInfectionSinceSurgery.touched"
        >
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <button
      mat-raised-button
      class="positive-button"
      type="submit"
      [disabled]="!lothianFormValid()"
    >
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
