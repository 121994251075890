<ng-container [ngSwitch]="efCaptureType">
  <ng-container *ngSwitchCase="captureType.PHOTO">
    <ic-photo-capture
      [captureReady]="captureReady"
      (capture)="capture.emit($event)"
    ></ic-photo-capture>
  </ng-container>
  <ng-container *ngSwitchCase="captureType.VIDEO">
    <ic-video-capture (capture)="capture.emit($event)"></ic-video-capture>
  </ng-container>
  <ng-container *ngSwitchCase="captureType.SOUND_RECORDING">
    <ic-sound-capture (capture)="capture.emit($event)"></ic-sound-capture>
  </ng-container>
  <ng-container *ngSwitchDefault
    >No matching capture type provided!</ng-container
  >
</ng-container>
