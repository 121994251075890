<div class="container">
  <mat-horizontal-stepper [linear]="true" #stepper>
    <mat-step label="Patient">
      <div>
        <h4>Navigating the patient</h4>
        <ul>
          <li>Open the record of the patient on SystmOne you wish to link their account</li>
          <li>Once in their patient record, click "administrative" in the side menu</li>
          <li>Click "online services" that should be near the bottom of the menu</li>
        </ul>
      </div>
      <div style="display: flex; justify-content: flex-end">
        <button mat-button matStepperNext style="margin: 10px" mat-raised-button>Next</button>
      </div>
    </mat-step>
    <mat-step label="Registration">
      <div>
        <h4>Registering for online services</h4>
        <p>If the patient has already been set up for online services, skip to the next step.</p>
        <ul>
          <li>
            Once you have clicked online services from the side menu, you should see "Register for online services" and
            click that which will then open a new window
          </li>
          <li>
            Under the Identity verification header you'll see the "Vouched for" check box. Select this checkbox and
            choose which type of vouching.
          </li>
          <li>
            Under Online credentials in the "third party (HSCIC approved) apps" box you will be able to get the "Account
            ID" and "third party passphrase" by selecting verbally
          </li>
          <li>
            Once you note these details you can press "Ok" at the top left of the window and save the patient. (Without
            saving the patient first you will not be able to make a successful link)
          </li>
        </ul>
      </div>
      <div style="display: flex; justify-content: space-between">
        <button mat-button matStepperPrevious style="margin: 10px" mat-raised-button>back</button>
        <button mat-button matStepperNext style="margin: 10px" mat-raised-button>Next</button>
      </div>
    </mat-step>
    <mat-step label="Details">
      <div>
        <h4>Getting linkage details</h4>
        <p>
          If you had to register the patient for online services and already obtained the Account ID and third party
          passphrase, then you can skip this and enter the details in Isla.
        </p>
        <ul>
          <li>On the Online services screen click "view or issue new third party passphrase"</li>
          <li>
            Under the Identity verification header you'll see the "Vouched for" check box. Select this checkbox and
            choose which type of vouching.
          </li>
          <li>
            Under Online credentials in the "third party (HSCIC approved) apps" box you will be able to get the "Account
            ID" and "third party passphrase" by selecting verbally
          </li>
          <li>
            Once you note these details you can press "Ok" at the top left of the window and save the patient. (Without
            saving the patient first you will not be able to make a successful link)
          </li>
        </ul>
      </div>
      <div style="display: flex; justify-content: space-between">
        <button mat-button matStepperPrevious style="margin: 10px" mat-raised-button>back</button>
        <button mat-button matStepperNext style="margin: 10px" mat-raised-button (click)="onNoClick()">close</button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
