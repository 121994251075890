<form [formGroup]="entryForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <mat-form-field class="half-width-field" appearance="fill">
      <mat-label>Date of injury</mat-label>
      <input matInput formControlName="dateOfInjury" type="date" placeholder="Choose a date" />
    </mat-form-field>

    <mat-form-field class="half-width-field" appearance="fill">
      <mat-label>Time of injury</mat-label>
      <input matInput formControlName="timeOfInjury" type="time" placeholder="Add a time" />
    </mat-form-field>
    <br />

    <mat-form-field class="double-width-field" appearance="fill">
      <mat-label>TBSA</mat-label>
      <input
        matInput
        formControlName="TBSAPercentage"
        type="number"
        placeholder="10"
        matTooltip="Total Body Surface Area"
      />
      <span matSuffix>%</span>
    </mat-form-field>

    <br />

    <mat-form-field appearance="fill" class="full-width-field">
      <mat-label>Affected areas</mat-label>
      <input
        matInput
        formControlName="areasAffected"
        type="notes"
        placeholder="Left hand, left arm..."
      />
    </mat-form-field>
    <br />

    <mat-form-field class="full-width-field" appearance="fill">
      <mat-label>Mechanism of injury</mat-label>
      <textarea matInput formControlName="mechanismOfInjury" type="notes"> </textarea>
    </mat-form-field>
    <br />

    <button mat-raised-button class="positive-button" type="submit" [disabled]="isSubmitting">
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
