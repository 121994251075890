import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';

@Component({
  standalone: true,
  selector: 'ic-chip',
  templateUrl: './chip.component.html',
  imports: [CommonModule]
})
export class ChipComponent implements OnChanges {
  @Input() label: string;
  @Input() icon: string;
  @Input() severity: 'info' | 'warning' | 'error' | 'success' | 'brand';

  statusClass: string;

  constructor() {
    this.statusClass = '';
  }

  ngOnChanges() {
    this.statusClass = this.setCssClass();
  }

  private setCssClass(): string {
    switch (this.severity) {
      case 'success':
        return 'text-success el-bg-success-subtle';
      case 'warning':
        return 'text-warning el-bg-warning-subtle';
      case 'error':
        return 'text-error el-bg-error-subtle';
      case 'brand':
        return 'text-main el-bg-primary-subtle';
      case 'info':
      default:
        return 'text-link el-bg-action-subtle';
    }
  }
}
