import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-pain-neuropathic-symptom-form',
  templateUrl: './pain-neuropathic-symptom-form.component.html',
  styleUrls: ['./pain-neuropathic-symptom-form.component.scss'],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class NeuropathicSymptomFormComponent implements OnInit {
  @Input() data;
  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  neuropathicPainSymptomInventoryForm: UntypedFormGroup;
  isSubmitting = false;
  isSensitive: boolean;
  isLinear = true;
  low = true;
  medium = false;
  high = false;
  painDescriptor: string;
  neuroPainSymptomTotalAnswer: number;
  sliderMin = 0;
  sliderMax = 10;
  step = '1';
  tickInterval = '1';

  ngOnInit(): void {
    this.neuropathicPainSymptomInventoryForm = this.fb.group({
      painFeelLikeBurningSlider: [''],
      painFeelLikeSqueezingSlider: [''],
      painFeelLikePressureSlider: [''],
      painHasBeenPresent: ['', Validators.required],
      painFeelLikeElectricShocksSlider: [''],
      painFeelLikeStabbingSlider: [''],
      painAttacks: ['', Validators.required],
      painIncreasedByBrushingSlider: [''],
      painIncreasedByPressureSlider: [''],
      painIncreasedByContactWithColdSlider: [''],
      painFeelsLikePinsAndNeedlesSlider: [''],
      painFeelsLikeTinglingSlider: [''],
      burningSuperficialSpontaneousPain: [''],
      pressingDeepSpontaneousPain: [''],
      paroxysmalPain: [''],
      evokedPain: [''],
      paresthesiaDysesthesiaPain: [''],
      neuropathicPainSymptomTotalAnswer: [''],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.neuropathicPainSymptomInventoryForm.controls[key])
          this.neuropathicPainSymptomInventoryForm.controls[key].setValue(
            formData[key]
          );
      });
    });
  }

  // neuro
  get painHasBeenPresent() {
    return this.neuropathicPainSymptomInventoryForm.get('painHasBeenPresent');
  }

  get painAttacks() {
    return this.neuropathicPainSymptomInventoryForm.get('painAttacks');
  }

  setNeuroPainSymptomScore() {
    const neuroPainSymptomQ1 = this.neuropathicPainSymptomInventoryForm.get(
      'painFeelLikeBurningSlider'
    ).value;
    const neuroPainSymptomQ2 = this.neuropathicPainSymptomInventoryForm.get(
      'painFeelLikeSqueezingSlider'
    ).value;
    const neuroPainSymptomQ3 = this.neuropathicPainSymptomInventoryForm.get(
      'painFeelLikePressureSlider'
    ).value;
    const neuroPainSymptomQ5 = this.neuropathicPainSymptomInventoryForm.get(
      'painFeelLikeElectricShocksSlider'
    ).value;
    const neuroPainSymptomQ6 = this.neuropathicPainSymptomInventoryForm.get(
      'painFeelLikeStabbingSlider'
    ).value;
    const neuroPainSymptomQ8 = this.neuropathicPainSymptomInventoryForm.get(
      'painIncreasedByBrushingSlider'
    ).value;
    const neuroPainSymptomQ9 = this.neuropathicPainSymptomInventoryForm.get(
      'painIncreasedByPressureSlider'
    ).value;
    const neuroPainSymptomQ10 = this.neuropathicPainSymptomInventoryForm.get(
      'painIncreasedByContactWithColdSlider'
    ).value;
    const neuroPainSymptomQ11 = this.neuropathicPainSymptomInventoryForm.get(
      'painFeelsLikePinsAndNeedlesSlider'
    ).value;
    const neuroPainSymptomQ12 = this.neuropathicPainSymptomInventoryForm.get(
      'painFeelsLikeTinglingSlider'
    ).value;

    const neuroPainSymptomTotal1 = neuroPainSymptomQ1;
    const neuroPainSymptomTotal2 =
      (neuroPainSymptomQ2 + neuroPainSymptomQ3) / 2;
    const neuroPainSymptomTotal3 =
      (neuroPainSymptomQ5 + neuroPainSymptomQ6) / 2;
    const neuroPainSymptomTotal4 =
      (neuroPainSymptomQ8 + neuroPainSymptomQ9 + neuroPainSymptomQ10) / 3;
    const neuroPainSymptomTotal5 =
      (neuroPainSymptomQ11 + neuroPainSymptomQ12) / 2;
    const neuroPainSymptomTotalAnswer =
      neuroPainSymptomTotal1 +
      neuroPainSymptomTotal2 +
      neuroPainSymptomTotal3 +
      neuroPainSymptomTotal4 +
      neuroPainSymptomTotal5;

    const neuroPainSymptomTotalAnswerRounded =
      Math.round(neuroPainSymptomTotalAnswer * 10) / 10;

    this.neuropathicPainSymptomInventoryForm
      .get('burningSuperficialSpontaneousPain')
      .setValue(neuroPainSymptomTotal1);
    this.neuropathicPainSymptomInventoryForm
      .get('pressingDeepSpontaneousPain')
      .setValue(neuroPainSymptomTotal2);
    this.neuropathicPainSymptomInventoryForm
      .get('paroxysmalPain')
      .setValue(neuroPainSymptomTotal3);
    this.neuropathicPainSymptomInventoryForm
      .get('evokedPain')
      .setValue(neuroPainSymptomTotal4);
    this.neuropathicPainSymptomInventoryForm
      .get('paresthesiaDysesthesiaPain')
      .setValue(neuroPainSymptomTotal5);
    this.neuropathicPainSymptomInventoryForm
      .get('neuropathicPainSymptomTotalAnswer')
      .setValue(neuroPainSymptomTotalAnswerRounded);
  }

  onSubmit() {
    this.setNeuroPainSymptomScore();
    this.isSubmitting = true;
    const formResponse = {
      ...this.neuropathicPainSymptomInventoryForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  neuropathicPainSymptomInventoryFormValid(): boolean {
    return (
      this.neuropathicPainSymptomInventoryForm.dirty &&
      !(
        this.neuropathicPainSymptomInventoryForm.controls &&
        this.neuropathicPainSymptomInventoryForm.controls['painHasBeenPresent']
          .errors
      ) &&
      !(
        this.neuropathicPainSymptomInventoryForm.controls &&
        this.neuropathicPainSymptomInventoryForm.controls['painAttacks'].errors
      )
    );
  }
}
