<form [formGroup]="cadiForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <div class="full-width-field" style="margin: auto">
      <h3>The Cardiff Acne Disability Index</h3>
    </div>
    <div class="question-container">
      <div class="half-width-field">
        <p>
          As a result of having acne, during the last month have you been aggressive, frustrated or
          embarrassed?
        </p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="feelingAggressiveFrustratedOrEmbarrassed">
          <mat-option value="Very much indeed - 3">Very much indeed - 3</mat-option>
          <mat-option value="A lot - 2">A lot - 2</mat-option>
          <mat-option value="A little - 1">A little - 1</mat-option>
          <mat-option value="Not at all - 0">Not at all - 0</mat-option>
        </mat-select>
        <mat-error *ngIf="feelingAggressiveFrustratedOrEmbarrassed.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>
    <div class="question-container">
      <div class="half-width-field">
        <p>
          Do you think that having acne during the last month interfered with your daily social
          life, social events or personal or sexual relationships?
        </p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="acneInterferedWithSocialLifeEventsOrRelationships">
          <mat-option value="Severely, affecting all activities - 3"
            >Severely, affecting all activities - 3</mat-option
          >
          <mat-option value="Moderately, in most activities - 2"
            >Moderately, in most activities - 2</mat-option
          >
          <mat-option value="Occasionally or in only some activities - 1"
            >Occasionally or in only some activities - 1</mat-option
          >
          <mat-option value="Not at all - 0">Not at all - 0</mat-option>
        </mat-select>
        <mat-error *ngIf="acneInterferedWithSocialLifeEventsOrRelationships.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>
    <div class="question-container">
      <div class="half-width-field">
        <p>
          During the last month have you avoided public changing facilities or wearing swimming
          costumes because of your acne?
        </p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="avoidedPublicChangingFacilities">
          <mat-option value="All of the time - 3">All of the time - 3</mat-option>
          <mat-option value="Most of the time - 2">Most of the time - 2</mat-option>
          <mat-option value="Occasionally - 1">Occasionally - 1</mat-option>
          <mat-option value="Not at all - 0">Not at all - 0</mat-option>
        </mat-select>
        <mat-error *ngIf="avoidedPublicChangingFacilities.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>
    <div class="question-container">
      <div class="half-width-field">
        <p>
          How would you describe your feelings about the appearance of your skin over the past
          month?
        </p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="feelingsAboutSkinAppearance">
          <mat-option value="Very depressed and miserable - 3"
            >Very depressed and miserable - 3</mat-option
          >
          <mat-option value="Usually concerned - 2">Usually concerned - 2</mat-option>
          <mat-option value="Occasionally concerned - 1">Occasionally concerned - 1</mat-option>
          <mat-option value="Not bothered - 0">Not bothered - 0</mat-option>
        </mat-select>
        <mat-error *ngIf="feelingsAboutSkinAppearance.touched"> Please select an option </mat-error>
      </mat-form-field>
    </div>
    <div class="question-container">
      <div class="half-width-field">
        <p>Please indicate how bad you think your acne is now:</p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="howBadIsYourAcneNow">
          <mat-option value="The worst it possibly could be - 3"
            >The worst it possibly could be - 3</mat-option
          >
          <mat-option value="A major problem - 2">A major problem - 2</mat-option>
          <mat-option value="A minor problem - 1">A minor problem - 1</mat-option>
          <mat-option value="Not a problem - 0">Not a problem - 0</mat-option>
        </mat-select>
        <mat-error *ngIf="howBadIsYourAcneNow.touched"> Please select an option </mat-error>
      </mat-form-field>
    </div>
    <div class="button-group">
      <button
        class="positive-button"
        mat-raised-button
        color="accent"
        type="submit"
        [disabled]="!cadiFormValid()"
      >
        {{ isSubmitting ? 'Submitting...' : 'Submit' }}
      </button>
    </div>
  </div>
</form>
