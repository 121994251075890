import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScreenTrackerService {
  constructor(
    private analytics: AngularFireAnalytics,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  trackScreenView(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.rootRoute(this.route)),
        filter((route: ActivatedRoute) => route.outlet === 'primary'),
        distinctUntilChanged((prev, curr) => prev.url === curr.url)
      )
      .subscribe((route: ActivatedRoute) => {
        const url = this.router.url;
        const screenName = route.snapshot.component.name;
        const formattedScreenName = this.formatScreenName(screenName);
        this.analytics.logEvent('screen_view', {
          screen_name: formattedScreenName,
          url: url
        });
      });
  }

  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  private formatScreenName(screenName: string): string {
    const pageComponentSuffix = 'PageComponent';
    const componentSuffix = 'Component';
    const suffixToRemove = screenName.endsWith(pageComponentSuffix)
      ? pageComponentSuffix
      : componentSuffix;

    if (screenName.endsWith(suffixToRemove)) {
      return `sf-${screenName.toLowerCase().replace(/ /g, '-')}`.slice(0, -suffixToRemove.length);
    }
    return screenName;
  }
}
