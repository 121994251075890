import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Organisation, OrganisationWithId, Roles, User, UserWithId } from '@islacare/ic-types';
import { environment } from 'apps/frontend/portal/src/environments/environment';
import firebase from 'firebase/compat/app';
import { serverTimestamp } from 'firebase/firestore';
import { Observable, forkJoin, from, of } from 'rxjs';
import { filter, map, skipWhile, switchMap, take } from 'rxjs/operators';
import { AuditService } from '../../../services/audit/audit.service';
import { OrganisationsService } from '../../../services/organisations/organisations.service';
import { LoggingService } from '../logging/logging.service';
const { arrayRemove, arrayUnion } = firebase.firestore.FieldValue;
const now = serverTimestamp();
@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private _user$: Observable<UserWithId>;

  constructor(
    private organisationsService: OrganisationsService,
    private analytics: AngularFireAnalytics,
    private afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private log: LoggingService,
    private audit: AuditService,
  ) {}

  get user$(): Observable<UserWithId | null> {
    if (!this._user$) {
      this._user$ = this.afAuth.authState.pipe(switchMap(user => (user?.uid ? this.getUser$(user.uid) : of(null))));
    }
    return this._user$;
  }

  me$: Observable<UserWithId | null> = this.user$;

  // TODO: refactor to use roles.admin property from user$
  iAmAnAdmin$: Observable<boolean> = this.me$.pipe(map(me => me?.roles?.admin));

  // TODO: refactor to use organisationId property from user$
  myOrganisationId$: Observable<string | null> = this.me$.pipe(map(me => me?.organisationId));
  myOrganisationIds$: Observable<string[] | null> = this.me$.pipe(map(me => me?.organisationIds));

  // TODO: refactor into organisation service
  myTeammates$: Observable<UserWithId[] | null> = this.user$.pipe(
    switchMap(user =>
      user == null
        ? of([])
        : this.db
            .collection<User>('users', ref => ref.where('organisationId', '==', user.organisationId || ''))
            .valueChanges({ idField: 'id' }),
    ),
  );

  getAllTeamMembers$(): Observable<any[]> {
    return this.user$.pipe(
      skipWhile(user => !user),
      switchMap(user =>
        forkJoin(
          user?.teamIds?.map(teamId =>
            this.db
              .collection<User>('users', ref => ref.where('teamIds', 'array-contains', teamId))
              .get()
              .pipe(map(users => users.docs.map(user => ({ ...user.data(), id: user.id })))),
          ),
        ),
      ),
      map(users => ([] as any[]).concat(...users)),
    );
  }

  // TODO: refactor into organisation service
  myOrganisation$: Observable<OrganisationWithId> = this.myOrganisationId$.pipe(
    filter(id => !!id),
    switchMap(id =>
      this.db
        .doc<Organisation>(`organisations/${id}`)
        .valueChanges()
        .pipe(map(org => org && { ...org, id })),
    ),
  );

  get myOrganisations$(): Observable<OrganisationWithId[]> {
    return this.myOrganisationIds$.pipe(
      filter(ids => !!ids?.length),
      switchMap(ids => this.organisationsService.getOrganisations$(ids)),
    );
  }

  /**
   * @description get all users from a organisation
   * @param organisationId
   * @returns
   */
  getUsersInOrganisation$(organisationId: Observable<string>): Observable<UserWithId[]> {
    return organisationId.pipe(
      switchMap(organisationId =>
        organisationId
          ? this.db
              .collection<User>('users', ref => ref.where('organisationIds', 'array-contains', organisationId))
              .valueChanges({ idField: 'id' })
          : of([]),
      ),
    );
  }

  // TODO: refactor into organisation service what is the difference between getUsersInMyOrganisation$ and myTeammates$
  getUsersInMyOrganisation$: Observable<UserWithId[]> = this.me$.pipe(
    switchMap(me => (!me ? of(null) : of(me.organisationId))),
    switchMap(organisationId =>
      organisationId
        ? this.db
            .collection<User>('users', ref => ref.where('organisationIds', 'array-contains', organisationId))
            .valueChanges({ idField: 'id' })
        : of([]),
    ),
  );

  me() {
    return this.me$.pipe(take(1)).toPromise();
  }

  getUserEmail(userId: string): Observable<string> {
    if (userId === ('automatedRequest' || 'automatedReminder')) {
      return of(userId);
    } else {
      return this.getUsersInMyOrganisation$.pipe(
        map(users => users.filter(user => user.id === userId)),
        map(user => (user && user[0] && user[0].email ? user[0].email : 'User unavailable')),
      );
    }
  }

  create(user: User) {
    return this.db
      .collection<User>('users')
      .add(user)
      .catch(error => {
        throw error;
      });
  }

  async setMembership(userId: string, teamId: string, isMember: boolean, teamOrgId: string) {
    const userDoc = this.db.doc(`users/${userId}`);
    const { teamIds } = (await userDoc.get().toPromise()).data() as User;

    if (isMember && teamIds.length >= 30) {
      // Firestore melts when you have more than 30 query ids
      throw new Error('A user cannot have more than 30 teams');
    }

    await userDoc.update({
      teamIds: isMember ? arrayUnion(teamId) : arrayRemove(teamId),
    });
    await this.audit.setMembership(userId, teamId, isMember, teamOrgId);
    await this.analytics.logEvent('add member to team', { userId, teamId });
  }

  async setAuthRegistrationToken(userId: string, registrationToken: string) {
    const userDoc = this.db.doc(`users/${userId}`);

    return await userDoc.update({
      registrationToken,
    });
  }

  updateUserProfile$(update: Partial<User>) {
    return this.user$.pipe(
      take(1),
      switchMap(user => {
        if (!user) throw new Error('User not logged in');

        return this.db.doc<User>(`users/${user.id}`).update(update);
      }),
    );
  }

  getUser$(id: string): Observable<UserWithId> {
    return this.db
      .doc<User>(`users/${id}`)
      .valueChanges()
      .pipe(map(user => user && { ...user, id }));
  }

  getTeamMembers$(teamId: string): Observable<UserWithId[]> {
    return this.db
      .collection<User>('users', ref => ref.where('teamIds', 'array-contains', teamId))
      .valueChanges({ idField: 'id' });
  }

  getTeamMembersInMyOrganisation$ = (teamId: string): Observable<UserWithId[]> =>
    this.myOrganisationId$.pipe(
      switchMap(orgId =>
        this.db
          .collection<User>('users', ref =>
            ref.where('teamIds', 'array-contains', teamId).where('organisationId', '==', orgId),
          )
          .valueChanges({ idField: 'id' }),
      ),
    );

  async addAdminRoleToUser(id: string) {
    this.audit.updateAdminRole(id, true);
    await this.db.doc<User>(`users/${id}`).update({
      roles: { admin: true },
    });
  }

  async removeAdminRoleToUser(id: string) {
    this.audit.updateAdminRole(id, false);
    await this.db.doc<User>(`users/${id}`).update({
      roles: { admin: false },
    });
  }

  async removeUserFromOrg(userId: string, orgId: string, orgIds: string[]) {
    const index = orgIds.indexOf(orgId); // check the index of orgId in orgIds
    if (index > -1) {
      // if orgId is in orgIds...
      orgIds.splice(index, 1); // remove it and return whatever is left in orgIds
    }

    await this.db.doc(`users/${userId}`).update({
      organisationId: orgIds.length > 0 ? orgIds[0] : '', // if the resulting orgIds is still greater than 0, default to the first, otherwise set to empty string
      organisationIds: orgIds,
    });

    await this.audit.userRemovedFromOrg(userId);
  }

  async getUsersForTeamMembership(
    limit,
    pageStartIndex = 0,
    userfilter,
    teamsFilter,
    isUpdate,
    organisationId: string = null,
  ) {
    const getUsersForTeamMembership = firebase
      .app()
      .functions(environment.region)
      .httpsCallable('getUsersForTeamMembership');

    try {
      const result = await getUsersForTeamMembership({
        limit,
        pageStartIndex,
        userfilter,
        teamsFilter,
        isUpdate,
        organisationId,
      });
      return result.data;
    } catch (err) {
      this.log.consoleError('error', err);
    }
  }

  isEmailEnabledForUserOrganisation$(): Observable<boolean> {
    return this.user$.pipe(
      switchMap(user => from(this.organisationsService.getOrganisationSnapshot(user.organisationId))),
      map(organisation => organisation.data().featureFlags?.functionality?.emailEnabled),
    );
  }

  isIslaAdmin(): Observable<boolean> {
    return this.user$.pipe(map(user => user?.roles?.islaAdmin));
  }

  async updateUserTeamAdminStatus(userId: string, roles: Roles) {
    const userDoc = this.db.doc(`users/${userId}`);

    return await userDoc.update({
      roles,
    });
  }

  async updateUserLastLoggedInTime(): Promise<void> {
    const user = await this.afAuth.currentUser;
    this.db.doc(`users/${user.uid}`).update({
      lastLoggedIn: now,
    });
  }
}
