import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-covid-patient-visitor-screening-form',
  templateUrl: './covid-patient-visitor-screening-form.component.html',
  styleUrls: ['./covid-patient-visitor-screening-form.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
})
export class CovidPatientVisitorScreeningFormComponent implements OnInit {
  @Input() data;
  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  covidVisitorForm: UntypedFormGroup;
  isSubmitting = false;

  ngOnInit(): void {
    this.covidVisitorForm = this.fb.group({
      testedPositive: ['', [Validators.required, Boolean]],
      closeContactSymptoms: ['', [Validators.required, Boolean]],
      closeContactPositive: ['', [Validators.required, Boolean]],
      highTemprature: ['', [Validators.required, Boolean]],
      smellTasteLoss: ['', [Validators.required, Boolean]],
      continuousCough: ['', [Validators.required, Boolean]],
      redListTravel: ['', [Validators.required, Boolean]],
      visitorName: ['', [Validators.required, Boolean]],
      visitorRelation: ['', [Validators.required, Boolean]],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.covidVisitorForm.controls[key])
          this.covidVisitorForm.controls[key].setValue(formData[key]);
      });
    });
  }

  onSubmit() {
    if (!this.valid()) return;

    this.isSubmitting = true;
    const formResponse = {
      ...this.covidVisitorForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  valid(): boolean {
    return (
      this.covidVisitorForm.dirty &&
      this.covidVisitorForm.valid &&
      !this.isSubmitting
    );
  }
}
