import { Injectable } from '@angular/core';
import { StringToCapitalisedCasePipe } from '@ic-monorepo/shared-common';
import { MessageType, Organisation } from '@islacare/ic-types';
import { OrganisationsService } from '../organisations/organisations.service';
import { TeamsService } from '../teams/teams.service';

export interface OrganisationWithUrl extends Organisation {
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class PatientMessageCopyService {
  constructor(
    private stringToCapitalisedPipe: StringToCapitalisedCasePipe,
    private teamsService: TeamsService,
    private organisationsService: OrganisationsService,
  ) {}

  getMessageBody(orgName: string, messageType: MessageType) {
    if (messageType === MessageType.MANUAL_REMINDER) {
      return `just following up from the message we sent you previously as we didn't receive anything from you.`;
    }
    return `${orgName} has requested some information as part of your treatment.`;
  }
  // PLEASE ALSO UPDATE CREATEPATIENTMESSAGEFORCOLLECTIONAUTH WITH ANY CHANGES MADE HERE

  getMessageExpiry(reminder: boolean, keepLinkSevenDays: boolean, scheduleDate?: Date): string {
    let disableTime = 'tonight';
    if (keepLinkSevenDays) {
      disableTime = this.getDisableTime(scheduleDate);
    }

    if (reminder && !keepLinkSevenDays) {
      return 'If you are unable to submit today you will receive a follow up reminder tomorrow. Please submit by following the link. This link will expire at 11:59pm tonight:';
    }
    return `Please submit by following the link. This link will expire at 11:59pm ${disableTime}:`;
  }

  getMessageIntro(
    additionalContacts: any,
    patientFirstName: string,
    patientContact: string,
    includeMessageIntro: boolean,
    assignedRecipient?: string,
  ) {
    if (!includeMessageIntro) return;

    const patientFirstNameProper = this.stringToCapitalisedPipe.transform(patientFirstName);
    if (!patientFirstNameProper) {
      return 'Hello, ';
    }

    if (assignedRecipient && assignedRecipient !== 'Patient' && assignedRecipient !== patientFirstNameProper) {
      return `Dear ${patientFirstNameProper}'s ${assignedRecipient}, `;
    }

    if (
      !this.hasPatientContact(patientContact) &&
      !!additionalContacts &&
      Object.keys(additionalContacts)?.length === 1
    ) {
      return `Dear ${patientFirstNameProper}'s ${Object.keys(additionalContacts)}, `;
    }

    return `Dear ${patientFirstNameProper}, `;
  }

  hasPatientContact(patientContact: string) {
    // +44 can be the default value for patient contact - is treated as no contact
    return patientContact !== '' && patientContact !== '+44' && patientContact !== undefined;
  }

  getDisableTime(scheduleDate?: Date) {
    if (scheduleDate) {
      const disableDate = new Date(scheduleDate.getTime());
      disableDate.setDate(disableDate.getDate() + 6);
      return (
        'on ' +
        disableDate.toLocaleString('en-GB', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
      );
    } else {
      const date = new Date();
      date.setDate(date.getDate() + 6);
      return (
        'on ' +
        date.toLocaleString('en-GB', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
      );
    }
  }

  replaceDateInMessage(stringContainingDate: string, updatedDate: string): string {
    if (!stringContainingDate) return;
    const messageMidnightRemoved = stringContainingDate.replace('midnight', '11:59pm');
    //regex plucks out (on + day of the week + , + date + month + year) e.g 'on Thursday, 23 March 2023'
    const reg =
      /(on)\s(Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday),\s+\d{1,2}\s+(January|February|March|April|May|June|July|August|September|October|November|December)\s\d{4}/g;
    return messageMidnightRemoved.replace(reg, updatedDate);
  }

  async getScheduleTemplateMessage(teamId: string, reminder: boolean, keepLinkSevenDays: boolean, scheduleDate?: Date) {
    const team = (await this.teamsService.getTeamSnapshot(teamId)).data();
    const organisation = (await this.organisationsService.getOrganisationSnapshot(team?.organisationId))?.data();
    return {
      messageBody: this.getMessageBody(organisation?.name, MessageType.ORIGINAL),
      messageExpiry: this.getMessageExpiry(reminder, keepLinkSevenDays, scheduleDate),
    };
  }
}
