import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { StringToCapitalisedCasePipe } from '@ic-monorepo/shared-common';
import {
  CollectionWithId,
  EntryWithId,
  FormWithId,
  OrganisationWithId,
  Patient,
  PatientWithId,
} from '@islacare/ic-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep } from 'lodash-es';
import { MenuItem } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { map, take, tap } from 'rxjs/operators';
import { Hl7Service } from '../../../services/hl7.service';
import { OrganisationsService } from '../../../services/organisations/organisations.service';
import { PdfGeneratorService } from '../../../services/pdf-generator/pdf-generator.service';
import { SystmOneService } from '../../../shared/material-deprecated/services/systm-one/systm-one.service';
import { FormService } from '@ic-monorepo/services';
import { LoggingService } from '@ic-monorepo/services';
import { PatientsService } from '@ic-monorepo/services';
import { ToastService, ToastType } from '../../../shared/services/toast/toast.service';
import { UsersService } from '@ic-monorepo/services';
import { isLandlineNumber, phoneInvalid } from '../../../utils/phoneInvalid';
import { PatientRecordService } from '../../services/patient-record/patient-record.service';
import { PatientPhoneEmailEditDialogComponent } from '../patient-phone-email-edit-dialog/patient-phone-email-edit-dialog.component';

@UntilDestroy()
@Component({
  selector: 'ic-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
})
export class PdfViewerComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;
  isMobile: boolean =
    /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent) &&
    navigator.platform !== 'iPad';
  isPasswordProtected: boolean;
  patientContactList: string[] = [];
  patientContactPerson: string;
  pdfPassword: number;
  pdfData: any;
  patient: PatientWithId;
  collection: CollectionWithId;
  entries: EntryWithId[];
  withComments: boolean;
  isPdfLoading: boolean;
  titles: any;
  hasSections: Record<string, boolean>;
  formData: FormWithId[];
  isEmailView: boolean;
  isPatientPhoneInvalid: boolean;
  patientNumbersAndSmsIntros: any;
  pdfPasswordSms: string;
  isSendingMessage: boolean;
  organisation: OrganisationWithId;
  isSendingEmail: boolean;
  pdfFormData: any;
  updatingPatientDetails: boolean;
  savedToEpr: boolean;
  savedToS1: boolean;
  isLoading: boolean;
  multiCollectionFlag: boolean;
  items: MenuItem[];
  activeIndex = 0;
  isPatientLinkedToS1: boolean;
  userTeamIds: string[];

  constructor(
    private patientsService: PatientsService,
    private patientRecordService: PatientRecordService,
    private formService: FormService,
    private usersService: UsersService,
    private organisationsService: OrganisationsService,
    private pdfGeneratorService: PdfGeneratorService,
    public dialog: MatDialog,
    private hl7Service: Hl7Service,
    public loggingService: LoggingService,
    public ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private systmOneService: SystmOneService,
    private toastService: ToastService,
    private stringToCapitalisedPipe: StringToCapitalisedCasePipe,
  ) {}

  async ngOnInit(): Promise<void> {
    const { patient, collection, entries, withComments, titles, hasSections, isEmailView } = this.config.data;
    this.patient = cloneDeep(patient);
    this.collection = cloneDeep(collection);
    this.entries = cloneDeep(entries);
    this.withComments = withComments;
    this.titles = cloneDeep(titles);
    this.hasSections = cloneDeep(hasSections);
    this.isEmailView = isEmailView;

    this.multiCollectionFlag = this.patientRecordService.checkForMultiCollection(this.entries);

    this.usersService.me$
      .pipe(
        untilDestroyed(this),
        tap(user => (this.userTeamIds = user.teamIds)),
        map(user => user?.organisationId),
      )
      .subscribe(async orgId => {
        const org = (await this.organisationsService.getOrganisationSnapshot(orgId))?.data();
        this.organisation = { ...org, id: orgId };

        this.setPdfSms();
      });

    this.setPatientContact();

    this.formService.myForms$.pipe(take(1)).subscribe(async formResult => {
      this.formData = formResult;
      if (!this.isEmailView) await this.createPdf();
    });

    this.items = [{ label: 'Contact details' }, { label: 'Send PDF' }];

    const isPatientConnectedWithTeamS1Instance = await this.systmOneService.isPatientConnectedWithTeamS1Instance(
      this.patient.id,
      this.collection.teamId,
    );

    this.isPatientLinkedToS1 = isPatientConnectedWithTeamS1Instance?.data;
  }

  changeStep(stepNumber: number): void {
    this.activeIndex = stepNumber;
    if (stepNumber === 1) this.getPDF();
  }

  setPatientContact(): void {
    this.isPatientPhoneInvalid = phoneInvalid(this.patient.phone) || isLandlineNumber(this.patient.phone);

    this.patientContactList = this.getPatientContactList();

    if (this.patientContactList.length === 1) {
      this.patientContactPerson = this.patientContactList[0];
    }
  }

  showEmailViewer = () => {
    this.isEmailView = true;

    setTimeout(() => {
      this.activeIndex = 0;
      this.isPasswordProtected = false;

      if (this.patientContactList.length === 1 && this.patientContactList.includes('Patient')) {
        this.patientContactPerson = 'Patient';
      }
    }, 0);
  };

  getPatientContactList(): string[] {
    if (!this.patient.additionalPhoneNumbers) {
      this.patient.additionalPhoneNumbers = {};
      this.patientContactPerson = 'Patient';
    }

    this.patientNumbersAndSmsIntros =
      ((this.patient.additionalPhoneNumbers || this.patient.phone) &&
        this.patientsService.getPatientNumbersAndSmsIntros(
          this.patient.additionalPhoneNumbers,
          this.patient.firstName,
          this.patient.phone,
        )) ||
      {};

    return this.patientNumbersAndSmsIntros?.additionalPhoneNumbers
      ? Object.keys(this.patientNumbersAndSmsIntros?.additionalPhoneNumbers)
      : [];
  }

  getSmsIntro(): string {
    const patientFirstName = this.stringToCapitalisedPipe.transform(this.patient?.firstName);

    if ((!this.patientContactPerson?.length && this.patient?.phone) || this.patientContactPerson === 'Patient') {
      return `Hi ${patientFirstName},`;
    } else {
      return `Hi ${patientFirstName}'s ${this.patientContactPerson},`;
    }
  }

  async sendMessageToPatient(): Promise<any> {
    const sendSmsTo =
      this.patientNumbersAndSmsIntros?.additionalPhoneNumbers[this.patientContactPerson || this.patientContactList[0]];
    const smsText = `${this.pdfPasswordSms}${this.pdfPassword}`;

    this.isSendingMessage = true;

    if (this.organisation?.id) {
      const result = await this.pdfGeneratorService.sendSmsToPatient(
        this.patient.id,
        this.organisation.id,
        sendSmsTo,
        smsText,
        this.entries.map(entry => entry.id),
      );

      this.isSendingMessage = false;
      return result;
    }
  }

  setPdfSms(): void {
    this.pdfPasswordSms = `${this.getSmsIntro()} ${
      this.organisation?.name
    } has shared a health record to your email with an encrypted PDF attached. The password for the PDF is: `;
  }

  async sendEmailAndSmsToPatient(): Promise<void> {
    this.isSendingEmail = true;
    this.entries.forEach(entry => {
      this.patientRecordService.logEntryExportToPdfAudit(this.patient.id, entry.id, this.collection.teamId, 'emailPdf');
    });
    Promise.all([this.sendEmailToPatient(), this.sendMessageToPatient()])
      .then(result => {
        if (result[0]?.data?.['errorMessage']) throw result[0]?.data?.['errorMessage'];
        else if (result[1]?.data?.['errorMessage']) throw result[1]?.data?.['errorMessage'];
        else this.toastService.open(ToastType.Success, 'PDF sent successfully.');
      })
      .catch(err => this.loggingService.consoleError(`error while sending sms and pdf: ${err}`))
      .finally(() => (this.isSendingEmail = false));
  }

  sendEmailToPatient = async () => {
    const fileName = `${this.patient?.firstName} ${this.patient?.lastName}-${
      this.collection.name
    }-${new Date().toLocaleDateString()}.pdf`;

    const pdfParams = {
      patientId: this.patient.id,
      collectionId: this.collection.id,
      entryIdArray: this.entries.map(entry => entry.id),
      formData: this.pdfFormData,
      withCommentsFlag: this.withComments || false,
      isPasswordProtected: this.isPasswordProtected || false,
      pdfPassword: this.pdfPassword,
    };

    return await this.pdfGeneratorService.sendEmailToPatient(this.organisation?.name, fileName, pdfParams);
  };

  getPDF = async () => {
    if (!this.isPasswordProtected) {
      this.pdfPassword = 100000 + Math.floor(Math.random() * 900000);
      this.pdfData = null;
      this.isPasswordProtected = true;

      await this.createPdf();
    }
  };

  downloadThisFile(): void {
    this.entries.forEach(entry => {
      this.patientRecordService.logEntryExportToPdfAudit(
        this.patient.id,
        entry.id,
        this.collection.teamId,
        'downloadPdf',
      );
    });
    this.patientRecordService.downloadFile(
      this.pdfData.fileObjectURL,
      this.entries[0],
      false,
      this.patient,
      this.collection,
    );
  }

  navigateToPatientEdit(): void {
    const dialogRef = this.dialog.open(PatientPhoneEmailEditDialogComponent, {
      width: '80%',
      data: {
        type: 'patient-phone-email-edit',
        title: 'Patient requires Phone and Email',
        message: "Please enter the patient's details below to be able to share this PDF via Email",
        data: {
          phone: this.patient.phone,
          email: this.patient.email,
        },
        positiveButton: 'Save',
        negativeButton: 'Cancel',
      },
      hasBackdrop: true,
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        this.updatingPatientDetails = true;
        await this.patientsService.updatePatient(this.patient.id, result as Partial<Patient>);

        this.patient = (await this.patientsService.getPatientSnapshot(this.patient.id)).data();

        this.setPatientContact();
        this.setPdfSms();
        this.toastService.open(ToastType.Success, 'Details updated successfully');
        this.updatingPatientDetails = false;
      }
    });

    // ..pop up a dialogfor each of email & phone (as required)
  }

  savePdfToEpr = async () => {
    this.savedToEpr = true;
    this.isLoading = true;
    this.entries.forEach(entry => {
      this.patientRecordService.logEntryExportToPdfAudit(
        this.patient.id,
        entry.id,
        this.collection.teamId,
        'savePdfToEpr',
      );
    });

    const patient = (await this.patientsService.getPatientSnapshot(this.patient.id)).data() as Patient;
    // Get visit number
    const mostRecentAppointmentFhirId = await this.patientsService.getMostRecentAppointmentFhirId(
      this.patient.id,
      this.organisation?.id,
    );

    // Get MRN
    let mrn = this.organisation ? patient.localIds[this.organisation.id] : '';

    if (!mrn) {
      const dialogRef = this.patientRecordService.openMrnDialog();
      dialogRef?.onClose.subscribe(async result => {
        if (result && this.organisation?.id) {
          this.patientsService.updatePatientLocalId(this.patient.id, this.organisation.id, result);
          mrn = result;
          await this.hl7Service.sendPdfToEpr(
            patient.nhs,
            mrn,
            this.patient.id,
            this.collection.id,
            this.organisation.id,
            patient.lastName,
            patient.firstName,
            mostRecentAppointmentFhirId,
            patient.dateOfBirth,
            this.entries.map(entry => entry.id),
            this.pdfFormData,
            this.withComments,
          );
        }
      });
    } else {
      if (this.organisation?.id) {
        await this.hl7Service.sendPdfToEpr(
          patient.nhs,
          mrn,
          this.patient.id,
          this.collection.id,
          this.organisation.id,
          patient.lastName,
          patient.firstName,
          mostRecentAppointmentFhirId,
          patient.dateOfBirth,
          this.entries.map(entry => entry.id),
          this.pdfFormData,
          this.withComments,
        );
      }
    }
    this.isLoading = false;
    this.toastService.open(ToastType.Success, 'PDF submitted to EPR. Note: it can take up to 30s to appear.');
  };

  printThisPdf = () => {
    this.entries.forEach(entry => {
      this.patientRecordService.logEntryExportToPdfAudit(this.patient.id, entry.id, this.collection.teamId, 'printPdf');
    });
    const ele = document.getElementById('image-pdf-viewer') as HTMLIFrameElement;
    ele?.contentWindow?.print();
  };

  async createPdf(): Promise<void> {
    this.isPdfLoading = true;

    const { pdfData, pdfFormData } = await this.patientRecordService
      .createEntriesImagesAsPdf(
        this.patient.id,
        this.collection.id,
        this.entries,
        this.withComments,
        this.isPasswordProtected,
        this.pdfPassword,
        this.titles,
        this.formData,
        this.multiCollectionFlag,
        this.hasSections,
      )
      .catch(err => {
        this.ref.close();
        throw err;
      });

    this.pdfData = cloneDeep(pdfData);
    this.pdfFormData = cloneDeep(pdfFormData);

    this.isPdfLoading = false;
  }

  backToPatientRecord(): void {
    this.ref.close();
  }

  async savePdfToS1(): Promise<void> {
    this.isLoading = true;

    const result = await this.systmOneService.callSendMessageToSystmOneTrigger(
      this.entries[0],
      this.patient.id,
      this.collection.id,
      this.entries[0].id,
      this.collection.teamId,
      this.collection.organisationId,
      this.patient,
      this.collection,
    );
    this.savedToS1 = true;
    this.isLoading = false;
    if (result.success) {
      this.toastService.open(ToastType.Success, 'PDF submitted to SystmOne. Note: it can take up to 30s to appear.');
    } else {
      this.toastService.open(ToastType.Error, 'There was an error submitting your PDF to SystmOne.');
    }
  }
}
