export default [
  //basic wound assessment
  'responsibleClinician',
  'staffName',
  'currentLocation',
  'bedNumber',
  'consentAndChaperone',
  'allergiesAndSensitivities',
  'medicalHistory',
  'medicalHistoryOther',
  'woundType',
  'woundTypeDetail',
  'pressureUlcerOccurrence',
  'miscellaneousWoundDetails',
  'sutureMaterialsRemoved',
  'sutureMaterialsNotYetRemoved',
  'bodyLocation',
  'bodyLocationSide',
  'bodyLocationDetail',
  'woundDuration',
  'reasonForAssessment',
  'alternativeReasonForAssessment',
  'woundDescription',
  'dehiscenceWoundDescription',
  'notes',
  'exudate',
  'exudateAmount',
  'lengthMeasurement',
  'widthMeasurement',
  'depthMeasurement',
  // Wound & Dressing care
  'analgesia',
  'actions',
  'turningFrequency',
  'woundCleansing',
  'woundCleansingDetail',
  'woundDressing',
  'woundDressingDetail',
  'statusForNegativePressureTherapy',
  'VACId',
  'intensityOfVAC',
  'modeOfVAC',
  'VACOptions',
  'VACPressure',
  'VACVolumeForPrevious24Hours',
  'additionalDressingInformation',
  // Health Promotion:
  'healthPromotion',
  'chestSupport',
  // Actions & investigatoins:
  'investigations',
  'mostRecentBloodTest',
  'bloodResultWhiteBloodCellCount',
  'bloodResultCReactiveProtein',
  'bloodResultHaemaglobin',
  'bloodResultIron',
  'bloodResultNotes',
  'previousSwabs',
  'specimenType',
  'mostRecentSwabDate',
  'previousSwabResult',
  // Planning & referrals
  'followUpPlan',
  'dateOfNextReview',
  'referrals',
  // Sepsis assessment:
  'isThisSepsis',
  'oneRedFlagPresent',
  'sepsisComments'
];
