import {
  AfterViewInit,
  Component,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  EntriesService,
  EntryWithUrlLarge,
} from 'apps/frontend/portal/src/app/services/entries/entries.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'ic-rd-entry-original-image',
  templateUrl: './rd-entry-original-image.component.html',
  styleUrls: ['./rd-entry-original-image.component.scss'],
})
export class RdEntryOriginalImageComponent implements OnInit, AfterViewInit {
  @ViewChild('dialogFooter', { read: TemplateRef })
  dialogFooter: TemplateRef<any>;
  entryWithOriginalImage: EntryWithUrlLarge;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private entriesService: EntriesService,
    private renderer: Renderer2,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {}

  async ngOnInit(): Promise<void> {
    const { patient, collection, entryId } = this.config.data;
    this.entryWithOriginalImage = await this.entriesService.getEntryWithUrl(
      patient.id,
      collection.id,
      entryId,
      true
    );
  }

  ngAfterViewInit(): void {
    const footer = document.querySelector(
      '.view-original-image-dialog .p-dialog-footer'
    );

    if (footer) {
      Promise.resolve(null).then(() => {
        this.renderer.setProperty(footer, 'innerHTML', '');
        const embeddedView = this.viewContainerRef.createEmbeddedView(
          this.dialogFooter
        );
        this.renderer.appendChild(footer, embeddedView.rootNodes[0]);
      });
    }
  }
}
