export default [
  //UseOfEquipmentTrainingForm
  'typeOfEquipment',
  'serialNumber',

  'benefitsOfEquipmentExplained',
  'benefitsOfEquipmentComments',
  'checkEnvriomentIsClearAndSuitable',
  'checkEnvriomentComments',
  'performEquipmentSafetyChecklist',
  'performEquipmentSafetyChecklistComments',
  'checkChildIsWearingAppropriateFootwearOrOrthotics',
  'checkChildIsWearingAppropriateFootwearComments',
  'checkBrakesOnEquipmentBeforeUse',
  'checkBrakesOnEquipmentComments',
  'referToManualHandlingOrPosturalCarePlan',
  'referToManualHandlingComments',
  'ensureChildIsSafelySecuredAndCheckAllStraps',
  'ensureChildIsSafelySecuredComments',
  'checkChildIsComfortableAndAssessHowLongChildShouldUseEquipment',
  'checkChildIsComfortableComments',
  'monitorChildUsingEquipmentAndTakeChildOutIfAppearUnwell',
  'monitorChildUsingEquipmentComments',
  'storeEquipmentSafelyAndNotToBeAdjustedByAnyone',
  'storeEquipmentSafelyComments',
  'readAndUnderstandUserGuideForEquipment',
  'readAndUnderstandUserGuideComments',
  'doNotUseEquipmentIfProblemsAndFaultsArePresent',
  'checkForProblemsAndFaultsComments',
  'referToGuidelinesForChargingInstruction',
  'referToGuidelinesComments',

  //Trainee
  'traineeName',
  'traineeRole',
  'traineeDate',

  //Trainer
  'trainerName',
  'trainerRole',
  'trainerDate',

  'notes'
];
