<p mat-dialog-title class="attach-file-dialog-title">
  {{ data.title }}
</p>

<div class="resource-container">
  <p-message
    text="Please note that you can select only one file here"
    styleClass="w-full block mb-2"
    severity="warn"
  ></p-message>

  <p-message
    *ngIf="!data?.resourceArray"
    styleClass="w-full block mb-2"
    text="No files found"
    severity="error"
  ></p-message>

  <ng-container *ngIf="data?.resourceArray as items">
    <div class="grid">
      <ng-container *ngFor="let item of items; trackBy: trackById">
        <div [class]="isMobile ? 'col-12' : 'col-3'">
          <p-card
            [styleClass]="
              selectedResource?.id === item.id
                ? 'bg-main-surface pb-0 shadow-4'
                : 'bg-main-surface pb-0'
            "
          >
            <ng-template pTemplate="header">
              <div class="flex flex-row justify-content-between">
                <div class="flex ml-2">
                  <p-checkbox
                    *ngIf="!selectedResource || selectedResource.id === item.id"
                    (onChange)="
                      $event.checked ? (selectedResource = item) : (selectedResource = null)
                    "
                    [binary]="true"
                  ></p-checkbox>
                </div>
                <button
                  *ngIf="item?.shortUrl"
                  (click)="openPreviewInNewTab(item?.shortUrl)"
                  class="p-button-rounded p-button-text"
                  aria-label="Open preview in new tab"
                  pTooltip="Open preview in new tab"
                  icon="pi pi-external-link"
                  type="button"
                  pButton
                ></button>
              </div>
            </ng-template>
            <div class="media-container">
              <ic-media-display
                *ngIf="item.publicUrl?.length"
                [hideVideoControls]="true"
                [fileType]="item.fileType"
                [source]="item.publicUrl"
                [hidePdfControls]="true"
                [video]="item.isVideo"
                class="media-container"
              >
              </ic-media-display>
            </div>
            <p
              *ngIf="item?.fileName as fileName"
              class="media-file-name mb-0"
              [pTooltip]="fileName"
            >
              {{ fileName }}
            </p>
          </p-card>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>

<div class="w-full flex flex-row align-items-center justify-content-end gap-2">
  <p *ngIf="selectedResource?.fileName as selected" class="font-bold m-0">
    {{ 'Selected: ' + selectedResource.fileName }}
  </p>
  <div>
    <button
      *ngIf="!isMobile"
      [label]="data.negativeButton"
      (click)="ref.close()"
      class="p-button-outlined"
      aria-label="Close"
      type="button"
      pButton
    ></button>
  </div>
  <div>
    <button
      [disabled]="!selectedResource?.id"
      [label]="data.positiveButton"
      (click)="ref.close(selectedResource)"
      data-cy="submission-dialog-positive-btn-attach-resource"
      aria-label="Submit"
      pButton
    ></button>
  </div>
</div>
