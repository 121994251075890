import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import { FormField } from '@islacare/ic-types';
import { isMobile } from 'apps/frontend/portal/src/app/utils/isMobileView';
import { fabric } from 'fabric';
import firebase from 'firebase/compat/app';

@Component({
  selector: 'ic-automatic-image-map',
  templateUrl: './automatic-image-map.component.html',
  styleUrls: ['./automatic-image-map.component.scss'],
})
export class AutomaticImageMapComponent implements OnInit, AfterViewInit {
  @Input() field: FormField;
  @Input() formId: string;
  @Input() setData: any;

  canvas: any;
  isMobile = isMobile;
  imgHeight;
  imgWidth;

  get canvasId(): string {
    return `canvas-${this.field?.controlName}`;
  }

  constructor(@Inject(DOCUMENT) private _document: Document) {}

  ngOnInit(): void {
    const existingCanvas = this._document.getElementById(this.canvasId);
    if (existingCanvas) {
      existingCanvas.remove();
    }
  }

  ngAfterViewInit(): void {
    this.setupCanvas();
  }

  setupCanvas() {
    this.canvas = new fabric.Canvas(this.canvasId);
    this.canvas.isDrawingMode = true;
    this.canvas.freeDrawingBrush.width = 2;
    this.canvas.freeDrawingBrush.color = 'red';
    this.setCanvasImage();
  }

  clearMarking(event) {
    event.preventDefault();
    this.canvas.clear();
    this.setCanvasImage(true);
  }

  async setCanvasImage(resetImage?: boolean) {
    fabric.Image.fromURL(
      await this.getImageURL(resetImage),
      (image) => {
        //to fit image in canvas according to it's dimensions
        this.imgWidth = image.width;
        this.imgHeight = image.height;
        const imgRatio = this.imgWidth / this.imgHeight;
        const canvasWidth = this.calculateCanvasWidth();
        // const canvasHeight = self.isMobile ? (self.imgHeight <= 250 ? self.imgHeight : 250) : self.imgHeight <= 500 ? self.imgHeight : 500;
        const canvasHeight = canvasWidth / imgRatio;
        const canvasRatio = canvasWidth / canvasHeight;

        if (imgRatio <= canvasRatio) {
          if (this.imgHeight > canvasHeight) {
            image.scaleToHeight(canvasHeight);
          }
        } else {
          if (this.imgWidth > canvasWidth) {
            image.scaleToWidth(canvasWidth);
          }
        }

        this.canvas.setWidth(canvasWidth);
        this.canvas.setHeight(canvasHeight);
        this.canvas.add(image);
        this.canvas.centerObject(image);
        this.canvas.renderAll();
      },
      {
        crossOrigin: 'anonymous',
      }
    );
  }

  calculateCanvasWidth() {
    if (this.isMobile) {
      return this.imgWidth <= 250 ? this.imgWidth : 250;
    } else {
      return this.imgWidth <= 500 ? this.imgWidth : 500;
    }
  }

  async getImageURL(resetImage?: boolean) {
    const controlName = this.formId
      ? this.field.controlName + '---' + this.formId
      : this.field.controlName;
    try {
      let storageImagePath;
      const imagePath =
        this.setData?.[this.field.controlName] ||
        this.setData?.imageMapDataUrlArray?.find(
          (item) => item.controlName === controlName
        )?.imageMap;

      if (imagePath?.length && !resetImage) {
        storageImagePath = imagePath;
      } else if (this.field?.storageImagePath?.length) {
        storageImagePath = this.field?.storageImagePath;
      } else {
        storageImagePath = 'formImages/bodyMap.png';
      }
      const storageRef = firebase.storage().ref(storageImagePath);
      return storageRef.getDownloadURL();
    } catch (err) {
      console.log('error:', err);
      return null;
    }
  }
  fontWeight() {
    if (this.field?.boldTitle === true) {
      return 'bold';
    }
  }
  textAlign() {
    return this.field?.alignment || 'center';
  }

  //resize canvas image back to it's original dimensions so that mobile submissions does not appear very small on entry-review page or while editing it from desktop
  resizeImage() {
    if (this.isMobile) {
      const newHeight = this.imgHeight <= 500 ? this.imgHeight : 500;
      const newWidth = this.imgWidth <= 500 ? this.imgWidth : 500;

      let data = '';
      const img = new Image(newWidth, newHeight);
      img.src = this.canvas.toDataURL();
      return new Promise((res) => {
        img.onload = () => {
          const elem = document.createElement('canvas');
          elem.width = newWidth;
          elem.height = newHeight;
          const ctx = elem.getContext('2d');
          ctx.drawImage(img, 0, 0, newWidth, newHeight);
          data = ctx.canvas.toDataURL();
          res(data);
        };
      });
    } else {
      return this.canvas.toDataURL();
    }
  }
}
