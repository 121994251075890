import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ClinicalFormResponse } from '@islacare/ic-types';
import { AuditActionStatusEnum, AuditService } from 'apps/frontend/portal/src/app/services/audit/audit.service';
import { Observable } from 'rxjs';

interface UploadFormFilesRequest {
  formData: ClinicalFormResponse;
  patientId: string;
  collectionId: string;
  entryId: string;
}

@Injectable({
  providedIn: 'root',
})
export class ClinicalFormService {
  constructor(
    private storage: AngularFireStorage,
    private auditService: AuditService,
  ) {}

  getStorageDownloadUrl$(storagePath: string): Observable<string> {
    return this.storage.ref(storagePath).getDownloadURL();
  }

  uploadFormFiles({ formData, patientId, collectionId, entryId }: UploadFormFilesRequest): ClinicalFormResponse {
    const initialFormResponse = formData.formResponse;
    try {
      this.auditService.logFormResponse(
        patientId,
        collectionId,
        entryId,
        initialFormResponse,
        AuditActionStatusEnum.BEFORE,
      );
    } catch (error) {
      console.error('error', error);
    }

    for (const controlName in formData.formResponse) {
      const controlValue = formData.formResponse[controlName];
      // upload all base64 strings in form and replace form value with path to file location
      if (typeof controlValue === 'string' && controlValue.includes('base64')) {
        const storagePath = `patients/${patientId}/collections/${collectionId}/${entryId}/${controlName}-image-map`;
        formData.formResponse[controlName] = this.uploadBase64String(controlValue, storagePath);
      }
    }

    try {
      this.auditService.logFormResponse(
        patientId,
        collectionId,
        entryId,
        formData.formResponse,
        AuditActionStatusEnum.AFTER,
      );
    } catch (error) {
      console.error('error', error);
    }

    return formData;
  }

  uploadBase64String(base64String: string, storagePath: string): string {
    const storageRef = this.storage.ref(storagePath);
    const contentType = this.getContentType(base64String);

    storageRef
      .putString(base64String, 'data_url', {
        contentType: contentType,
      })
      .catch(err => {
        console.error(err);
        throw new Error('Something went wrong uploading files from form response.');
      });

    //returning path so it can be saved on formResponse
    return storagePath;
  }

  private getContentType(imageURL: string): string {
    return imageURL ? imageURL.split(';')[0]?.split(':')[1] : '';
  }
}
