import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CaptureType } from '@islacare/ic-types';
import { SubmissionFlowService } from 'apps/frontend/portal/src/app/feature-clinician-submission/services/submission-flow/submission-flow.service';
import {
  SubmissionFlowMedia,
  SubmissionFlowStore,
} from 'apps/frontend/portal/src/app/feature-patient-submission/store/submission-flow.store';
import { WebcamImage, WebcamInitError, WebcamModule } from 'ngx-webcam';
import { ButtonModule } from 'primeng/button';
import { Observable, Subject } from 'rxjs';

@Component({
  standalone: true,
  selector: 'ic-photo-capture',
  templateUrl: './photo-capture.component.html',
  imports: [CommonModule, WebcamModule, ButtonModule],
})
export class PhotoCaptureComponent {
  @Output() capture = new EventEmitter<SubmissionFlowMedia>();
  @Input() captureReady: boolean;

  sensitive = false;
  notes = '';

  cameraOptions: MediaTrackConstraints = {
    facingMode: 'environment',
  };

  private trigger: Subject<void> = new Subject<void>();
  get trigger$(): Observable<void> {
    return this.trigger.asObservable();
  }

  constructor(
    private sfService: SubmissionFlowService,
    private sfStore: SubmissionFlowStore,
    private sanitizer: DomSanitizer
  ) {}

  setError(error: WebcamInitError) {
    this.sfStore.setError(`Media capture error: ${error.message}`);
  }

  triggerSnapshot(): void {
    this.trigger.next();
  }

  captureMedia(media: WebcamImage) {
    this.capture.emit({
      file: this.sfService.handleCapturedImage(media.imageAsBase64),
      url: media.imageAsDataUrl,
      safeUrl: this.sanitizer.bypassSecurityTrustUrl(media.imageAsDataUrl),
      captureType: CaptureType.PHOTO,
      notes: this.notes,
      sensitive: this.sensitive,
    });
  }
}
