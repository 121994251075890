import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormService } from '@ic-monorepo/services';
import { SystemCheckService } from '@ic-monorepo/shared-common';
import {
  CaptureType,
  Collection,
  GroupedFormWithTeamReference,
  TeamWithId,
  TemplateUrlParams,
} from '@islacare/ic-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PatientRecordService } from '../../../feature-patient-record/services/patient-record/patient-record.service';
import { multipleEmailValidityLogs } from '../../../utils/multipleEmailValidityLogs';
import { multipleEmailValidator } from '../../validators/multiple-emails.validator';

const { minLength } = Validators;

export interface CaptureTypeOptions {
  label: string;
  value: CaptureType;
  icon: string;
}

@UntilDestroy()
@Component({
  selector: 'ic-submission',
  templateUrl: './submission.component.html',
})
export class SubmissionComponent implements OnInit, OnChanges {
  @Input() collection: Collection;
  @Output() submissionTypeEvent = new EventEmitter<any>();
  @Input() teamId;
  @Input() template;
  @Input() alertEmailHidden;
  @Input() usedAsModel = false;

  CaptureType = CaptureType;
  selectedFormIds: string[] = ['default'];
  selectedTemplateId: string;
  forms$: Observable<GroupedFormWithTeamReference[]> = of([]);
  forms: GroupedFormWithTeamReference[] = [];
  team$: Observable<TeamWithId>;
  formChecked = false;
  submissionDetailsSet = false;
  isSelectFormFocused = false;

  submissionForm = this.fb.group({
    capture: [CaptureType.PHOTO],
    alertEmail: ['', [minLength(2), multipleEmailValidator]],
  });
  originalSelectedTeamId: string;
  templateUrlParams: TemplateUrlParams;
  captureOptions$: Observable<CaptureTypeOptions[]>;
  emailValidityLogs$: Observable<{ [key: string]: boolean }[] | []>;
  isMobile = this.systemCheck.isMobile;

  constructor(
    private fb: UntypedFormBuilder,
    private formService: FormService,
    private router: Router,
    private route: ActivatedRoute,
    private systemCheck: SystemCheckService,
    private patientRecordService: PatientRecordService
  ) {
    this.route.params.subscribe((params) => {
      this.templateUrlParams = params as TemplateUrlParams;
    });
  }

  ngOnInit(): void {
    this.captureOptions$ = this.patientRecordService.getCaptureOptions$();
    if (this.collection) {
      const { formIds } = this.collection;
      if (formIds) {
        this.selectedFormIds = formIds;
      } else {
        this.selectedFormIds = ['default'];
      }
    }

    if (this.collection && this.collection.submission) {
      const { capture, formIds, teamId, alertEmail } =
        this.collection.submission;
      this.submissionForm.patchValue({
        capture: capture ? capture : CaptureType.PHOTO,
        alertEmail: alertEmail,
      });
      this.selectedFormIds = formIds;
      this.teamId = teamId;
      this.originalSelectedTeamId = teamId;
    }
    this.getFormSelection();
    this.isFormChecked();
    this.emitValues();
    this.onFormChanges();
    this.checkForOldCaptureValue();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.template) {
      this.teamId = this.template.teamId;
    }
    this.getFormSelection();

    if (this.template) {
      if (this.router.url.split('/').includes('edit')) {
        // sets the template data on the collection on the collection edit and template page
        if (changes['template']) {
          this.setSubmissionDetailsFromTemplate();
        }
      } else {
        // sets template data on the collection on the collection create & template page
        if (changes['template'] && changes['template'].currentValue) {
          this.setSubmissionDetailsFromTemplate();
        }
      }

      const alertEmail = this.submissionForm.controls.alertEmail.value;
      this.submissionDetailsSet = alertEmail ? true : false;
    } else if (this.teamId) {
      this.selectedFormIds = ['default'];
      this.selectedTemplateId = '';
      this.submissionForm.patchValue({
        capture: CaptureType.PHOTO,
        alertEmail: '',
      });
      this.getFormSelection();
    }

    this.emitValues();
  }

  get capture() {
    return this.submissionForm.get('capture');
  }

  setSubmissionDetailsFromTemplate(): void {
    const { id, alertEmail, formIds, submission } = this.template;

    this.selectedFormIds = formIds || ['default'];
    this.selectedTemplateId = id;
    this.submissionForm.patchValue({ alertEmail: alertEmail });

    if (submission) {
      this.submissionForm.patchValue(submission);
      if (submission.formIds) {
        this.selectedFormIds = submission.formIds;
      }
    }

    this.getFormSelection();
  }

  get alertEmail() {
    return this.submissionForm.get('alertEmail');
  }

  getFormSelection(): void {
    if (this.teamId) {
      this.forms$ = this.formService.getFormsWithTeam(this.teamId);
    } else if (this.templateUrlParams.teamId) {
      this.forms$ = this.formService.getFormsWithTeam(
        this.templateUrlParams.teamId
      );
    }
    this.forms$
      .pipe(
        untilDestroyed(this),
        tap((forms) => (this.forms = this.getAllItems(forms)))
      )
      .subscribe();
  }

  isFormChecked(): void {
    if (!this.selectedFormIds) this.selectedFormIds = ['default'];
    this.formChecked = this.selectedFormIds?.length < 1 ? false : true;
  }

  showForm(): void {
    this.formService.showForm(
      this.selectedFormIds,
      this.teamId,
      'Form to be completed for all submissions into this collection'
    );
  }

  onFormChanges(): void {
    this.submissionForm.valueChanges.subscribe(() => {
      this.emitValues();
    });
  }
  emitValues(): void {
    if (this.submissionForm.controls.alertEmail.value === undefined) {
      this.submissionForm.patchValue({ alertEmail: '' });
    }

    this.emailValidityLogs$ = multipleEmailValidityLogs(
      this.submissionForm.controls['alertEmail'].errors?.emailValidityLogs
    );

    this.submissionTypeEvent.emit({
      ...this.submissionForm.value,
      alertEmailErrors: this.submissionForm.controls['alertEmail'].errors,
      formIds: this.selectedFormIds || ['default'],
      teamId: this.teamId,
      captureType: this.submissionForm.get('capture').value,
    });
  }

  private checkForOldCaptureValue(): void {
    //this checks if captureType value is the old depricated version
    if (
      this.submissionForm.controls.capture.value === 'entry' ||
      this.submissionForm.controls.capture.value === 'capture'
    )
      this.submissionForm.controls.capture.setValue(null);
  }

  /**
   * @description Get the updated form array with default option
   * @param forms
   * @returns
   */
  getAllItems(
    forms: GroupedFormWithTeamReference[]
  ): GroupedFormWithTeamReference[] {
    return this.formService.getUpdatedFormsArray(forms);
  }
}
