import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';
import firebase from 'firebase/compat/app';

@Component({
  standalone: true,
  selector: 'ic-boadicea-form',
  templateUrl: './boadicea-form.component.html',
  styleUrls: ['./boadicea-form.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
})
export class BoadiceaFormComponent implements OnInit {
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  boadiceaForm: UntypedFormGroup;
  isSubmitting = false;

  ngOnInit(): void {
    this.boadiceaForm = this.fb.group({
      sex: ['', [Validators.required]],

      doYouHaveSymptoms: ['', [Validators.required]],
      mainSymptom: ['', []],
      mainSymptomOther: ['', []],
      firstNoticeMainSymptom: ['', [Validators.required]],
      mainSymptomLengthInMonths: ['', []],
      mainSymptomLengthInDays: ['', []],
      mainSymptomLocation: ['', []],
      mainSymptomVariesWithCycle: ['', []],
      doesPainRadiateElsewhere: ['', []],
      typeOfBreastLump: ['', []],
      isLumpAssociatedWithBreastFeeding: ['', []],
      hadTemperatureWithLump: ['', []],
      areYouASmoker: ['', []],
      lumpType: ['', []],
      lumpLocation: ['', []],
      nippleDischargeOccurrence: ['', []],
      nippleDischargeAmount: ['', []],
      nippleDischargeConsistency: ['', []],
      nippleDischargeColour: ['', []],
      invertedNipple: ['', []],
      isSkinFlakyOrItchy: ['', []],
      changeInSkin: ['', []],
      isThereSkinDipling: ['', []],
      hasShapeOrSizeChanged: ['', []],
      otherSymptoms: ['no', []],
      firstNoticeOtherSymptoms: ['', [Validators.required]],
      otherSymptomsLengthInMonths: ['', []],
      otherSymptomsLengthInDays: ['', []],

      specifyOtherSymptoms: ['', []],
      otherSymptomLocation: ['', []],
      otherSymptomVariesWithCycle: ['', []],
      doesOtherSymptomPainRadiateElsewhere: ['', []],
      otherSymptomTypeOfBreastLump: ['', []],
      isOtherSymptomLumpAssociatedWithBreastFeeding: ['', []],
      temperatureWithLump: ['', []],
      haveYouSmoked: ['', []],
      otherSymptomLumpType: ['', []],
      otherSymptomLumpLocation: ['', []],
      nippleDischargeFrequency: ['', []],
      otherSymptomNippleDischargeAmount: ['', []],
      otherSymptomNippleDischargeConsistency: ['', []],
      otherSymptomNippleDischargeColour: ['', []],
      isNippleInverted: ['', []],
      skinFlakyOrItchy: ['', []],
      otherSymptomChangeInSkin: ['', []],
      skinDipling: ['', []],
      shapeOrSizeChanged: ['', []],

      weightImperialOrMetric: ['', [Validators.required]],
      weightSt: [0, []],
      weightLbs: [0, []],
      weightMetric: [0],
      heightImperialOrMetric: ['', [Validators.required]],
      heightFt: [0, []],
      heightIns: [0, []],
      heightMetric: [0, []],

      drinkAlcohol: ['', [Validators.required]],
      glassesOfWinePerWeek: [0, [Validators.required]],
      pintsOfBeerPerWeek: [0, [Validators.required]],
      bottlesOfBeerCiderOrAlcopopPerWeek: [0, [Validators.required]],
      shotsOfSpiritPerWeek: [0, [Validators.required]],
      doYouSmoke: ['', [Validators.required]],

      ageOfFirstPeriod: [''],
      hasYourPeriodsStopped: ['', [Validators.required]],
      periodsStoppedAge: [''],

      takenContraceptivePill: ['', [Validators.required]],
      yearsTakingContraceptivePills: [''],
      takenContraceptivePillLastTwoYears: ['', []],

      haveYouUsedHrt: ['', [Validators.required]],
      yearsUsingHrt: [''],
      usedHrtInLast5Years: ['', []],

      hadChildren: ['', [Validators.required]],
      childrenList: this.fb.array([
        this.fb.group({
          childYOB: [''],
          childSex: ['', []],
          childIndex: [1],
        }),
      ]),

      haveYouHadAMammogram: ['', [Validators.required]],
      doYouKnowYourBreastDensity: ['', []],
      breastDensityBiRads: ['', []],
      biRadsCategory: ['', []],

      endometriosisHistory: ['', [Validators.required]],
      tubalLigationHistory: ['', [Validators.required]],
      oophorectomy: ['', [Validators.required]],
      mastectomy: ['', [Validators.required]],

      breastCancerFirstBreast: ['', [Validators.required]],
      firstBreastCancerDiagnosisAge: [''],
      breastCancerSecondBreast: ['', [Validators.required]],
      secondBreastCancerDiagnosisAge: [''],
      ovarianCancer: ['', [Validators.required]],
      ovarianCancerDiagnosisAge: [''],
      pancreaticCancer: ['', [Validators.required]],
      pancreaticCancerDiagnosisAge: [''],

      familyHistoryOfCancer: ['', [Validators.required]],
      immediateFamily: this.fb.array([
        this.fb.group({
          relation: ['', []],
          hadCancer: ['', []],
          gender: ['', []],
          YOB: ['', []],
          livingStatus: ['', []],
          ashkn: ['', []],
          hadBreastCancer: ['no', []],
          breastCancerAge: [''],
          hadCancerInOtherBreast: ['no', []],
          otherBreastCancerAge: [''],
          hadOvarianCancer: ['no', []],
          ovarianCancerAge: [''],
          hadPancreaCancer: ['no', []],
          pancreaCancerAge: [''],
          hadProstateCancer: ['no', []],
          prostateCancerAge: [''],

          geneTest: ['no', []],
          brca1TestType: ['mutation', []],
          brca1Result: ['', []],
          brca2TestType: ['mutation', []],
          brca2Result: ['', []],
          palb2TestType: ['mutation', []],
          palb2Result: ['', []],
          chek2TestType: ['mutation', []],
          chek2Result: ['', []],
          atmTestType: ['mutation', []],
          atmResult: ['', []],
          rad51dTestType: ['mutation', []],
          rad51dResult: ['', []],
          rad51cTestType: ['mutation', []],
          rad51cResult: ['', []],
          brip1TestType: ['mutation', []],
          brip1Result: ['', []],
        }),
      ]),
      extendedFamilyHistory: ['', []],
      extendedFamily: this.fb.array([
        this.fb.group({
          relation: ['', []],
          hadCancer: ['', []],
          sideOfFamily: ['', []],
          gender: ['', []],
          YOB: ['', []],
          livingStatus: ['', []],
          ashkn: ['', []],
          hadBreastCancer: ['no', []],
          breastCancerAge: [''],
          hadCancerInOtherBreast: ['no', []],
          otherBreastCancerAge: [''],
          hadOvarianCancer: ['no', []],
          ovarianCancerAge: [''],
          hadPancreaCancer: ['no', []],
          pancreaCancerAge: [''],
          hadProstateCancer: ['no', []],
          prostateCancerAge: [''],

          geneTest: ['no', []],
          brca1TestType: ['mutation', []],
          brca1Result: ['', []],
          brca2TestType: ['mutation', []],
          brca2Result: ['', []],
          palb2TestType: ['mutation', []],
          palb2Result: ['', []],
          chek2TestType: ['mutation', []],
          chek2Result: ['', []],
          atmTestType: ['mutation', []],
          atmResult: ['', []],
          rad51dTestType: ['mutation', []],
          rad51dResult: ['', []],
          rad51cTestType: ['mutation', []],
          rad51cResult: ['', []],
          brip1TestType: ['mutation', []],
          brip1Result: ['', []],
        }),
      ]),
      addRelation: ['no', []],

      notes: ['', []],
    });

    this.setDefaultValues();

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (
          this.boadiceaForm.controls[key] &&
          key !== 'childrenList' &&
          key !== 'immediateFamily' && //TODO change to new arrays
          key !== 'extendedFamily'
        ) {
          //TODO change to new arrays
          this.boadiceaForm.controls[key].setValue(formData[key]);
        }
      });

      for (let i = 0; i < formData['childrenList']?.length; i++) {
        if (i > 0) this.addChild();
        const childrenList = this.boadiceaForm.controls
          .childrenList as UntypedFormArray;
        childrenList.controls[i].patchValue(formData['childrenList'][i]);
      }
      for (let i = 0; i < formData['immediateFamily']?.length; i++) {
        if (i > 0) this.addImmediateFamilyMember('');
        const immediateFamilyArray = this.boadiceaForm.controls
          .immediateFamily as UntypedFormArray;
        immediateFamilyArray.controls[i].patchValue(
          formData['immediateFamily'][i]
        );
      }

      for (let i = 0; i < formData['extendedFamily']?.length; i++) {
        if (i > 0) this.addExtendedFamilyMember('');
        const extendedFamilyArray = this.boadiceaForm.controls
          .extendedFamily as UntypedFormArray;
        extendedFamilyArray.controls[i].patchValue(
          formData['extendedFamily'][i]
        );
      }
    });
  }

  get sex() {
    return this.boadiceaForm.get('sex');
  }

  get doYouHaveSymptoms() {
    return this.boadiceaForm.get('doYouHaveSymptoms');
  }

  get mainSymptom() {
    return this.boadiceaForm.get('mainSymptom');
  }

  get firstNoticeMainSymptom() {
    return this.boadiceaForm.get('firstNoticeMainSymptom');
  }

  get mainSymptomLengthInDays() {
    return this.boadiceaForm.get('mainSymptomLengthInDays');
  }

  get mainSymptomLengthInMonths() {
    return this.boadiceaForm.get('mainSymptomLengthInMonths');
  }

  get firstNoticeOtherSymptoms() {
    return this.boadiceaForm.get('firstNoticeOtherSymptoms');
  }

  get otherSymptoms() {
    return this.boadiceaForm.get('otherSymptoms');
  }

  get otherSymptomsLengthInMonths() {
    return this.boadiceaForm.get('otherSymptomsLengthInMonths');
  }

  get otherSymptomsLengthInDays() {
    return this.boadiceaForm.get('otherSymptomsLengthInDays');
  }

  get weightImperialOrMetric() {
    return this.boadiceaForm.get('weightImperialOrMetric');
  }
  get weightSt() {
    return this.boadiceaForm.get('weightSt');
  }

  get weightLbs() {
    return this.boadiceaForm.get('weightLbs');
  }

  get weightMetric() {
    return this.boadiceaForm.get('weightMetric');
  }

  get weightKg() {
    if (this.weightMetric.value > 0) {
      return this.boadiceaForm.get('weightMetric').value;
    } else {
      return this.formatWeight(
        parseInt(this.weightSt.value, 10),
        parseInt(this.weightLbs.value, 10)
      );
    }
  }

  get heightImperialOrMetric() {
    return this.boadiceaForm.get('heightImperialOrMetric');
  }
  get heightFt() {
    return this.boadiceaForm.get('heightFt');
  }

  get heightIns() {
    return this.boadiceaForm.get('heightIns');
  }

  get heightMetric() {
    return this.boadiceaForm.get('heightMetric');
  }

  get heightCm() {
    if (this.heightMetric.value > 0) {
      return this.boadiceaForm.get('heightMetric').value;
    } else {
      return this.formatHeight(
        parseInt(this.heightFt.value, 10),
        parseInt(this.heightIns.value, 10)
      );
    }
  }

  get bmi() {
    return this.calcBMI(this.weightKg, this.heightCm);
  }

  get drinkAlcohol() {
    return this.boadiceaForm.get('drinkAlcohol');
  }

  get glassesOfWinePerWeek() {
    return this.boadiceaForm.get('glassesOfWinePerWeek');
  }

  get pintsOfBeerPerWeek() {
    return this.boadiceaForm.get('pintsOfBeerPerWeek');
  }

  get bottlesOfBeerCiderOrAlcopopPerWeek() {
    return this.boadiceaForm.get('bottlesOfBeerCiderOrAlcopopPerWeek');
  }

  get shotsOfSpiritPerWeek() {
    return this.boadiceaForm.get('shotsOfSpiritPerWeek');
  }

  get hasYourPeriodsStopped() {
    return this.boadiceaForm.get('hasYourPeriodsStopped');
  }

  get periodsStoppedAge() {
    return this.boadiceaForm.get('periodsStoppedAge');
  }

  get takenContraceptivePill() {
    return this.boadiceaForm.get('takenContraceptivePill');
  }

  get yearsTakingContraceptivePills() {
    return this.boadiceaForm.get('yearsTakingContraceptivePills');
  }

  get takenContraceptivePillLastTwoYears() {
    return this.boadiceaForm.get('takenContraceptivePillLastTwoYears');
  }

  get haveYouUsedHrt() {
    return this.boadiceaForm.get('haveYouUsedHrt');
  }

  get yearsUsingHrt() {
    return this.boadiceaForm.get('yearsUsingHrt');
  }

  get usedHrtInLast5Years() {
    return this.boadiceaForm.get('usedHrtInLast5Years');
  }

  get hadChildren() {
    return this.boadiceaForm.get('hadChildren');
  }

  get endometriosisHistory() {
    return this.boadiceaForm.get('endometriosisHistory');
  }

  get tubalLigationHistory() {
    return this.boadiceaForm.get('tubalLigationHistory');
  }

  get oophorectomy() {
    return this.boadiceaForm.get('oophorectomy');
  }

  get mastectomy() {
    return this.boadiceaForm.get('mastectomy');
  }

  get haveYouHadAMammogram() {
    return this.boadiceaForm.get('haveYouHadAMammogram');
  }

  get doYouKnowYourBreastDensity() {
    return this.boadiceaForm.get('doYouKnowYourBreastDensity');
  }

  get breastDensityBiRads() {
    return this.boadiceaForm.get('breastDensityBiRads');
  }

  get biRadsCategory() {
    return this.boadiceaForm.get('biRadsCategory');
  }

  get breastCancerFirstBreast() {
    return this.boadiceaForm.get('breastCancerFirstBreast');
  }
  get firstBreastCancerDiagnosisAge() {
    return this.boadiceaForm.get('firstBreastCancerDiagnosisAge');
  }

  get breastCancerSecondBreast() {
    return this.boadiceaForm.get('breastCancerSecondBreast');
  }
  get secondBreastCancerDiagnosisAge() {
    return this.boadiceaForm.get('secondBreastCancerDiagnosisAge');
  }

  get ovarianCancer() {
    return this.boadiceaForm.get('ovarianCancer');
  }
  get ovarianCancerDiagnosisAge() {
    return this.boadiceaForm.get('ovarianCancerDiagnosisAge');
  }

  get pancreaticCancer() {
    return this.boadiceaForm.get('pancreaticCancer');
  }
  get pancreaticCancerDiagnosisAge() {
    return this.boadiceaForm.get('pancreaticCancerDiagnosisAge');
  }

  get familyHistoryOfCancer() {
    return this.boadiceaForm.get('familyHistoryOfCancer');
  }

  get addRelation() {
    return this.boadiceaForm.get('addRelation');
  }

  get extendedFamilyHistory() {
    return this.boadiceaForm.get('extendedFamilyHistory');
  }

  get weeklyAlcoholIntakeInGrams() {
    return this.calcAlcoholIntakeInGrams(
      parseInt(this.glassesOfWinePerWeek.value + 0, 10),
      parseInt(this.pintsOfBeerPerWeek.value + 0, 10),
      parseInt(this.bottlesOfBeerCiderOrAlcopopPerWeek.value + 0, 10),
      parseInt(this.shotsOfSpiritPerWeek.value + 0, 10)
    );
  }

  clearBc1AgeField() {
    this.firstBreastCancerDiagnosisAge.reset('');
  }
  clearBc2AgeField() {
    this.secondBreastCancerDiagnosisAge.reset('');
  }
  clearOvcAgeField() {
    this.ovarianCancerDiagnosisAge.reset('');
  }
  clearPancAgeField() {
    this.pancreaticCancerDiagnosisAge.reset('');
  }

  clearChildList(event) {
    const childrenListArray = this.boadiceaForm.controls
      .childrenList as UntypedFormArray;
    childrenListArray.clear();

    if (event.value === 'yes') {
      this.addChild();
    }
  }

  periodStoppedToggle(event) {
    if (event.value === 'yes') {
      this.periodsStoppedAge.setValidators(Validators.required);
    } else {
      this.periodsStoppedAge.clearValidators();
      this.periodsStoppedAge.reset('');
    }
  }

  genderToggle(event) {
    if (event.value === 'M') {
      this.hasYourPeriodsStopped.clearValidators();
      this.takenContraceptivePill.clearValidators();
      this.haveYouUsedHrt.clearValidators();
      this.hadChildren.clearValidators();
      this.hasYourPeriodsStopped.reset('');
      this.takenContraceptivePill.reset('');
      this.haveYouUsedHrt.reset('');
      this.hadChildren.reset('');
    } else {
      this.hasYourPeriodsStopped.setValidators(Validators.required);
      this.takenContraceptivePill.setValidators(Validators.required);
      this.haveYouUsedHrt.setValidators(Validators.required);
      this.hadChildren.setValidators(Validators.required);
    }
  }

  ocPillToggle(event) {
    if (event.value === 'yes') {
      this.yearsTakingContraceptivePills.setValidators(Validators.required);
      this.takenContraceptivePillLastTwoYears.setValidators(
        Validators.required
      );
    } else {
      this.yearsTakingContraceptivePills.clearValidators();
      this.takenContraceptivePillLastTwoYears.clearValidators();
      this.yearsTakingContraceptivePills.reset('');
      this.takenContraceptivePillLastTwoYears.reset('');
    }
  }

  hrtUsedToggle(event) {
    if (event.value === 'yes') {
      this.yearsUsingHrt.setValidators(Validators.required);
      this.usedHrtInLast5Years.setValidators(Validators.required);
    } else {
      this.yearsUsingHrt.clearValidators();
      this.usedHrtInLast5Years.clearValidators();
      this.yearsUsingHrt.reset('');
      this.usedHrtInLast5Years.reset('');
    }
  }

  setValidatorForMember(event, formControls) {
    if (event.value === 'yes') {
      formControls.controls.YOB.setValidators(Validators.required);
      formControls.controls.livingStatus.setValidators(Validators.required);
      formControls.controls.ashkn.setValidators(Validators.required);
      formControls?.controls?.sideOfFamily?.setValidators(Validators.required);
    }
    if (event.value === 'no') {
      formControls.controls.YOB.clearValidators();
      formControls.controls.livingStatus.clearValidators();
      formControls.controls.ashkn.clearValidators();
      formControls?.controls?.sideOfFamily?.clearValidators();
      formControls.controls.YOB.reset('');
      formControls.controls.livingStatus.reset('');
      formControls.controls.ashkn.reset('');
      formControls?.controls?.sideOfFamily?.reset('');
    }
  }

  hadMammogramToggle() {
    this.doYouKnowYourBreastDensity.reset('');
    this.breastDensityBiRads.reset('');
    this.biRadsCategory.reset('');
  }

  addChild() {
    const childrenListArray = this.boadiceaForm.controls
      .childrenList as UntypedFormArray;
    const arraylen = childrenListArray.length;

    const newChildGroup: UntypedFormGroup = this.fb.group({
      childYOB: ['', [Validators.required]],
      childSex: ['', [Validators.required]],

      childIndex: [arraylen + 1],
    });

    childrenListArray.insert(arraylen, newChildGroup);
  }

  removeChild(i) {
    const childrenListArray = this.boadiceaForm.controls
      .childrenList as UntypedFormArray;
    childrenListArray.removeAt(i);
  }

  heightToggle(event) {
    if (event.value === 'metric') {
      this.heightFt.setValue('');
      this.heightIns.setValue('');
    } else {
      this.heightFt.setValue(0);
      this.heightIns.setValue(0);
      this.heightMetric.setValue(0);
    }
  }

  weightToggle(event) {
    if (event.value === 'metric') {
      this.weightSt.setValue('');
      this.weightLbs.setValue('');
    } else {
      this.weightSt.setValue(0);
      this.weightLbs.setValue(0);
      this.weightMetric.setValue(0);
    }
  }

  formatWeight(stones: number, pounds: number) {
    const result = stones * 6.35 + pounds / 2.205;
    return result.toFixed(2);
  }

  formatHeight(feet: number, inches: number) {
    const result = feet * 30.48 + inches * 2.54;
    return result.toFixed(2);
  }

  symptomToggle() {
    this.mainSymptom.reset('');
    this.mainSymptomLengthInMonths.reset('');
    this.mainSymptomLengthInDays.reset('');
    this.otherSymptoms.reset('');
    this.otherSymptomsLengthInMonths.reset('');
    this.otherSymptomsLengthInDays.reset('');
  }

  mainSymptomToggle() {
    this.mainSymptomLengthInMonths.reset('');
    this.mainSymptomLengthInDays.reset('');
  }

  otherSymptomToggle() {
    this.otherSymptomsLengthInMonths.reset('');
    this.otherSymptomsLengthInDays.reset('');
  }

  calcAlcoholIntakeInGrams(wine, beer, bottle, spirit) {
    //average alcohol percentage in UK
    const wineAlcoholPercentage = 0.12;
    const beerAlcoholPercentage = 0.045;
    const alcopopAlcoholPercentage = 0.04;
    const spiritAlcoholPercentage = 0.375;

    const ethanolDensityInGrams = 0.789;

    //average portion size in ml in UK
    const averageGlassOfWine = 175;
    const averagePint = 473;
    const averageBottle = 275;
    const averageShot = 25;

    let totalAlcoholIntake = 0;

    totalAlcoholIntake =
      averageGlassOfWine *
        wine *
        wineAlcoholPercentage *
        ethanolDensityInGrams +
      averagePint * beer * beerAlcoholPercentage * ethanolDensityInGrams +
      averageBottle *
        bottle *
        alcopopAlcoholPercentage *
        ethanolDensityInGrams +
      averageShot * spirit * spiritAlcoholPercentage * ethanolDensityInGrams;

    return totalAlcoholIntake;
  }

  calcBMI(weightKg, heightCm) {
    const heightM = heightCm / 100;

    let result;

    if (weightKg > 0 && heightM > 0) {
      result = weightKg / heightM ** 2;
    } else {
      result = 'n/a';
    }
    return (result = Math.trunc(result * 10) / 10);
  }

  addImmediateFamilyMember(input) {
    const immediateFamilyArray = this.boadiceaForm.controls
      .immediateFamily as UntypedFormArray;
    const arrayLen = immediateFamilyArray.length;

    let relation;
    let hadCanc = 'no';
    let gen = '';
    let formValidator = [];
    let arrLen = 3;

    if (this.hadChildren.value === 'yes') {
      arrLen = 5;
    }

    if (arrayLen > arrLen) {
      hadCanc = 'yes';
      formValidator = [Validators.required];
    }

    switch (input) {
      case 0:
        relation = 'mother';
        gen = 'female';
        break;
      case 1:
        relation = 'father';
        gen = 'male';
        break;
      case 2:
        relation = 'sister';
        gen = 'female';
        break;
      case 3:
        relation = 'brother';
        gen = 'male';
        break;
      case 4:
        relation = 'daughter';
        gen = 'female';
        break;
      case 5:
        relation = 'son';
        gen = 'male';
        break;
      default:
        relation = '';
        break;
    }

    const newMemberGroup: UntypedFormGroup = this.fb.group({
      relation: [relation, []],
      hadCancer: [hadCanc, []],
      gender: [gen, []],
      YOB: ['', formValidator],
      livingStatus: ['', formValidator],
      ashkn: ['', formValidator],
      hadBreastCancer: ['no', []],
      breastCancerAge: [''],
      hadCancerInOtherBreast: ['no', []],
      otherBreastCancerAge: [''],
      hadOvarianCancer: ['no', []],
      ovarianCancerAge: [''],
      hadPancreaCancer: ['no', []],
      pancreaCancerAge: [''],
      hadProstateCancer: ['no', []],
      prostateCancerAge: [''],

      geneTest: ['no', []],
      brca1TestType: ['mutation', []],
      brca1Result: ['', []],
      brca2TestType: ['mutation', []],
      brca2Result: ['', []],
      palb2TestType: ['mutation', []],
      palb2Result: ['', []],
      chek2TestType: ['mutation', []],
      chek2Result: ['', []],
      atmTestType: ['mutation', []],
      atmResult: ['', []],
      rad51dTestType: ['mutation', []],
      rad51dResult: ['', []],
      rad51cTestType: ['mutation', []],
      rad51cResult: ['', []],
      brip1TestType: ['mutation', []],
      brip1Result: ['', []],
    });

    immediateFamilyArray.insert(arrayLen, newMemberGroup);
  }

  addImmediateFamily(input) {
    this.addImmediateFamilyMember(parseInt(input, 10));
    this.addRelation.reset('no');
  }

  removeImmediateFamilyMember(i) {
    const immediateFamilyArray = this.boadiceaForm.controls
      .immediateFamily as UntypedFormArray;
    immediateFamilyArray.removeAt(i);
  }

  addExtendedFamilyMember(input, _side?) {
    const extendedFamilyArray = this.boadiceaForm.controls
      .extendedFamily as UntypedFormArray;
    const arrayLen = extendedFamilyArray.length;

    let relation;
    let hadCanc = 'no';
    let gen = '';
    let side = '';
    let formValidator = [];

    if (arrayLen > 5) {
      hadCanc = 'yes';
      formValidator = [Validators.required];
    }

    switch (input) {
      case 0:
        relation = 'grandmother';
        gen = 'female';
        side = _side;
        break;
      case 1:
        relation = 'grandfather';
        gen = 'male';
        side = _side;
        break;
      case 2:
        relation = 'aunt';
        gen = 'female';
        break;
      case 3:
        relation = 'uncle';
        gen = 'male';
        break;
      case 4:
        relation = 'cousin';
        break;
      default:
        relation = '';
        break;
    }

    const newMemberGroup: UntypedFormGroup = this.fb.group({
      relation: [relation, []],
      hadCancer: [hadCanc, []],
      sideOfFamily: [side, []],
      gender: [gen, []],
      YOB: ['', formValidator],
      livingStatus: ['', formValidator],
      ashkn: ['', formValidator],
      hadBreastCancer: ['no', []],
      breastCancerAge: [''],
      hadCancerInOtherBreast: ['no', []],
      otherBreastCancerAge: [''],
      hadOvarianCancer: ['no', []],
      ovarianCancerAge: [''],
      hadPancreaCancer: ['no', []],
      pancreaCancerAge: [''],
      hadProstateCancer: ['no', []],
      prostateCancerAge: [''],

      geneTest: ['no', []],
      brca1TestType: ['mutation', []],
      brca1Result: ['', []],
      brca2TestType: ['mutation', []],
      brca2Result: ['', []],
      palb2TestType: ['mutation', []],
      palb2Result: ['', []],
      chek2TestType: ['mutation', []],
      chek2Result: ['', []],
      atmTestType: ['mutation', []],
      atmResult: ['', []],
      rad51dTestType: ['mutation', []],
      rad51dResult: ['', []],
      rad51cTestType: ['mutation', []],
      rad51cResult: ['', []],
      brip1TestType: ['mutation', []],
      brip1Result: ['', []],
    });

    extendedFamilyArray.insert(arrayLen, newMemberGroup);
  }

  addExtendedFamily(input) {
    this.addExtendedFamilyMember(parseInt(input, 10));
    this.addRelation.reset('no');
  }

  removeExtendedFamilyMember(i) {
    const extendedFamilyArray = this.boadiceaForm.controls
      .extendedFamily as UntypedFormArray;
    extendedFamilyArray.removeAt(i);
  }

  familyToggle(event) {
    const immediateFamilyArray = this.boadiceaForm.controls
      .immediateFamily as UntypedFormArray;
    const extendedFamilyArray = this.boadiceaForm.controls
      .extendedFamily as UntypedFormArray;
    immediateFamilyArray.clear();
    extendedFamilyArray.clear();

    if (event.value === 'yes') {
      this.addImmediateFamilyMember(0);
      this.addImmediateFamilyMember(1);
      this.addImmediateFamilyMember(2);
      this.addImmediateFamilyMember(3);

      if (this.hadChildren.value === 'yes') {
        this.addImmediateFamilyMember(4);
        this.addImmediateFamilyMember(5);
      }

      this.addExtendedFamilyMember(0, 'mother');
      this.addExtendedFamilyMember(1, 'mother');
      this.addExtendedFamilyMember(0, 'father');
      this.addExtendedFamilyMember(1, 'father');
      this.addExtendedFamilyMember(2);
      this.addExtendedFamilyMember(3);
      //this.addExtendedFamilyMember(4);
    }
  }

  extendedFamilyToggle(event) {
    const extendedFamilyArray = this.boadiceaForm.controls
      .extendedFamily as UntypedFormArray;
    extendedFamilyArray.clear();

    if (event.value === 'yes') {
      this.addExtendedFamilyMember(0, 'mother');
      this.addExtendedFamilyMember(1, 'mother');
      this.addExtendedFamilyMember(0, 'father');
      this.addExtendedFamilyMember(1, 'father');
      this.addExtendedFamilyMember(2);
      this.addExtendedFamilyMember(3);
      //this.addExtendedFamilyMember(4);
    }
  }

  setDefaultValues() {
    this.firstNoticeMainSymptom.setValue('days');
    this.firstNoticeOtherSymptoms.setValue('days');
    this.weightImperialOrMetric.setValue('imperial');
    this.heightImperialOrMetric.setValue('imperial');
    this.hadChildren.setValue('no');
    this.haveYouHadAMammogram.setValue('no');
    this.endometriosisHistory.setValue('no');
    this.tubalLigationHistory.setValue('no');
    this.oophorectomy.setValue('no');
    this.mastectomy.setValue('no');
    this.breastCancerFirstBreast.setValue('no');
    this.breastCancerSecondBreast.setValue('no');
    this.ovarianCancer.setValue('no');
    this.pancreaticCancer.setValue('no');
    this.addRelation.setValue('no');
  }

  onSubmit() {
    if (!this.boadiceaForm.valid) {
      this.boadiceaForm.markAllAsTouched();
      this.boadiceaForm.controls.immediateFamily.markAllAsTouched();
      return;
    }
    this.isSubmitting = true;
    const formResponse = {
      ...this.boadiceaForm.value,
      formId: this.data.formId,
      weightKg: this.weightKg,
      heightCm: this.heightCm,
      bmi: this.bmi,
      weeklyAlcoholIntakeInGrams: this.weeklyAlcoholIntakeInGrams,
      symptoms: 'subtitle_HEADER',
      bodyMassIndex: 'subtitle_HEADER',
      lifestyle: 'subtitle_HEADER',
      menstruation: 'subtitle_HEADER',
      contraceptives: 'subtitle_HEADER',
      hormoneReplacementTherapy: 'subtitle_HEADER',
      childrenInfo: 'subtitle_HEADER',
      breastScreening: 'subtitle_HEADER',
      medicalHistory: 'subtitle_HEADER',
      cancerHistory: 'subtitle_HEADER',
      familyHistory: 'subtitle_HEADER',
      formLastUpdated: firebase.firestore.FieldValue.serverTimestamp(),

      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  valid(): boolean {
    return (
      this.boadiceaForm.dirty && this.boadiceaForm.valid && !this.isSubmitting
    );
  }
}
