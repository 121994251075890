import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { FormWithId } from '@islacare/ic-types';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'ic-trust-ssi-surveillance-form',
  templateUrl: './trust-ssi-surveillance-form.component.html',
  styleUrls: ['./trust-ssi-surveillance-form.component.scss'],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class TrustSsiSurveillanceFormComponent implements OnInit {
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  entryForm: UntypedFormGroup;
  isSubmitting = false;
  todaysDate = new Date();
  daysDiff: number;
  isSensitive: boolean;
  form$: Observable<FormWithId>;

  incisionReasonList: string[] = [
    'Incision spontaneously dehisced',
    'Incision opened by a clinician',
  ];

  criteriaForSSIList: string[] = [
    'Abscess or other evidence of infection found during re-operation, by radiology or histopath examination',
    'Aspirated fluid/swab of surgical site yields organisms and pus cells are present',
    'Clinician’s diagnosis',
    'Fever (temperature 38ºC or more)',
    'Heat',
    'Localised pain and tenderness',
    'Localised swelling',
    'Purulent drainage',
    'Redness',
    // 'Sternal instability' //commenting out temporarily
  ];

  impactList: string[] = [
    'Readmission to hospital',
    'Readmission to ICU',
    'Longer ICU stay',
    'Longer hospital stay',
    'Wound revision in theatres',
    'Wound care including wound dressings longer than expected',
    'Specialist input (TVN and/or plastic surgeon)',
  ];

  microbiologyResultsList: string[] = [
    '-1  | Missing/Null',
    '110 | Bacteroides spp.',
    '113 | Bacteroides fragilis group',
    '130 | Burkholderia (Pseudomonas) spp.',
    '132 | Burkholderia cepacia',
    '160 | Chryseomanas spp.',
    '180 | Citrobacter spp.',
    '182 | Citrobacter diversus (koserii)',
    '184 | Citrobacter freundii',
    '200 | Clostridium spp.',
    '202 | Clostridium difficile',
    '204 | Clostridium perfringens',
    '206 | Clostridium septicum',
    '221 | Coliforms (unspecified)',
    '240 | Corynebacterium spp.',
    '242 | Corynebacterium jeikeium',
    '251 | Diptheroids (unspecified)',
    '270 | Enterobacter spp.',
    '272 | Enterobacter aerogenes',
    '274 | Enterobacter agglomerans',
    '276 | Enterobacter cloacae',
    '290 | Enterococcus spp.',
    '292 | Enterococcus faecalis',
    '294 | Enterococcus faecium',
    '295 | Enterococcus faecium (vancomycin -resistant)',
    '311 | Escherichia coli',
    '330 | Flavobacterium spp.',
    '350 | Fusobacterium spp.',
    '380 | Haemophilus spp.',
    '382 | Haemophilus influenzae',
    '384 | Haemophilus parainfluenzae',
    '400 | Hafnia spp.',
    '420 | Klebsiella spp.',
    '422 | Klebsiella pneumoniae (aerogenes)',
    '424 | Klebsiella oxytoca',
    '450 | Legionella spp.',
    '452 | Legionella pneumophila',
    '470 | Listeria spp.',
    '472 | Listeria monocytogenes',
    '490 | Micrococcus spp.',
    '510 | Moraxella spp.',
    '512 | Moraxella (Branhamella) catarrhalis',
    '531 | Morganella morganii',
    '552 | Mycobacterium avium',
    '554 | Mycobacterium chelonae',
    '556 | Mycobacterium fortuitum',
    '558 | Mycobacterium tuberculosis',
    '559 | Mycobacterium - other spp.',
    '570 | Neisseria spp.',
    '572 | Neisseria meningitidis',
    '590 | Nocardia spp.',
    '592 | Nocardia asteroides',
    '620 | Peptococcus spp.',
    '630 | Peptostreptococcus spp.',
    '640 | Prevotella spp.',
    '650 | Proprionibacterium spp.',
    '670 | Proteus spp.',
    '672 | Proteus mirabilis',
    '674 | Proteus vulgaris',
    '690 | Providencia spp.',
    '692 | Providencia alcalifaciens',
    '694 | Providencia rettgeri',
    '696 | Providencia stuartii',
    '710 | Pseudomonas spp.',
    '712 | Pseudomonas aeruginosa',
    '732 | Salmonella enteritidis',
    '734 | Salmonella typhi',
    '739 | Salmonella -other spp.',
    '750 | Serratia spp.',
    '752 | Serratia liquefaciens',
    '754 | Serratia marcescens',
    '770 | S. aureus, methicillin - resistant (MRSA)',
    '772 | S. aureus, methicillin - sensitive (MSSA)',
    '780 | Staphylococcus, coagulase - negative (CNS)',
    '782 | Staphylococcus epidermidis',
    '783 | Staphylococcus haemolyticus',
    '784 | Staphylococcus hominis',
    '785 | Staphylococcus lugdunensis',
    '786 | Staphylococcus saprophyticus',
    '787 | Staphylococcus schleiferi',
    '788 | Staphylococcus capitis',
    '789 | Staphylococcus warneri',
    '801 | Stenotrophomonas (Xanthomonas) maltophilia',
    '821 | Streptococcus agalactiae (group B)',
    '822 | Streptococcus bovis',
    '823 | Streptococcus pneumoniae',
    '824 | Streptococcus pyogenes (group A)',
    "825 | Streptococci, 'viridans group'",
    '829 | Streptococcus - other aerobic spp.',
    '840 | Yersinia spp.',
    '842 | Yersinia enterocolitica',
    '860 | Other Gram-negative bacteria',
    '870 | Other Gram-positive bacteria',
    '880 | Other anaerobes',
    '890 | Other bacteria',
    '910 | Aspergillus spp.',
    '920 | Candida spp.',
    '921 | Candida auris',
    '922 | Candida albicans',
    '924 | Candida tropicalis',
    '940 | Other fungi/yeast',
    '10  | Acinetobacter spp.',
    '12  | Acinetobacter baumanii (anitratus)',
    '14  | Acinetobacter Iwoffii',
    '30  | Aeromonas spp.',
    '50  | Alcaligenes spp.',
    '71  | Anaerobic cocci (unspecified)',
    '90  | Bacillus spp.',
  ];

  ngOnInit(): void {
    this.form$ = this.form.getForm(this.data.teamId, this.data.formId);

    this.entryForm = this.fb.group({
      dateOfSurgery: [''],
      daysSinceOp: [''],
      surgicalCategory: [''],
      healingByPrimaryIntention: ['', Boolean],
      cabgSurgicalWound: [''],
      surgicalSiteInfection: [''],
      chestCurrentMicrobiologyResults: [''],
      classification: [''],
      surgicalWoundDehiscence: [''],
      depthOfDehiscence: [''],
      spontaneousDehiscenceReason: [''],
      dehiscenceDateOfOnset: [''],
      criteriaForSSIs: [''],
      detection: [''],
      submammarySurgicalSiteInfection: [''],
      submammaryCurrentMicrobiologyResults: [''],
      submammaryClassification: [''],
      submammarySurgicalWoundDehiscence: [''],
      submammaryDepthOfDehiscence: [''],
      submammarySpontaneousDehiscenceReason: [''],
      submammaryDehiscenceDateOfOnset: [''],
      submammaryCriteriaForSSIs: [''],
      submammaryDetection: [''],
      leftLegSurgicalSiteInfection: [''],
      leftLegCurrentMicrobiologyResults: [''],
      leftLegClassification: [''],
      leftLegSurgicalWoundDehiscence: [''],
      leftLegDepthOfDehiscence: [''],
      leftLegSpontaneousDehiscenceReason: [''],
      leftLegDehiscenceDateOfOnset: [''],
      leftLegCriteriaForSSIs: [''],
      leftLegDetection: [''],
      rightLegSurgicalSiteInfection: [''],
      rightLegCurrentMicrobiologyResults: [''],
      rightLegClassification: [''],
      rightLegSurgicalWoundDehiscence: [''],
      rightLegDepthOfDehiscence: [''],
      rightLegSpontaneousDehiscenceReason: [''],
      rightLegDehiscenceDateOfOnset: [''],
      rightLegCriteriaForSSIs: [''],
      rightLegDetection: [''],
      leftArmSurgicalSiteInfection: [''],
      leftArmCurrentMicrobiologyResults: [''],
      leftArmClassification: [''],
      leftArmSurgicalWoundDehiscence: [''],
      leftArmDepthOfDehiscence: [''],
      leftArmSpontaneousDehiscenceReason: [''],
      leftArmDehiscenceDateOfOnset: [''],
      leftArmCriteriaForSSIs: [''],
      leftArmDetection: [''],
      rightArmSurgicalSiteInfection: [''],
      rightArmCurrentMicrobiologyResults: [''],
      rightArmClassification: [''],
      rightArmSurgicalWoundDehiscence: [''],
      rightArmDepthOfDehiscence: [''],
      rightArmSpontaneousDehiscenceReason: [''],
      rightArmDehiscenceDateOfOnset: [''],
      rightArmCriteriaForSSIs: [''],
      rightArmDetection: [''],
      drainSiteSurgicalSiteInfection: [''],
      drainSiteCurrentMicrobiologyResults: [''],
      drainSiteClassification: [''],
      drainSiteSurgicalWoundDehiscence: [''],
      drainSiteDepthOfDehiscence: [''],
      drainSiteSpontaneousDehiscenceReason: [''],
      drainSiteDehiscenceDateOfOnset: [''],
      drainSiteCriteriaForSSIs: [''],
      drainSiteDetection: [''],
      rightHipWound: [''],
      rightHipSurgicalWoundDehiscence: [''],
      rightHipDepthOfDehiscence: [''],
      rightHipSpontaneousDehiscenceReason: [''],
      rightHipDehiscenceDateOfOnset: [''],
      rightHipCriteriaForSSIs: [''],
      rightHipSurgicalSiteInfection: [''],
      rightHipCurrentMicrobiologyResults: [''],
      rightHipClassification: [''],
      rightHipDetection: [''],
      leftHipWound: [''],
      leftHipSurgicalWoundDehiscence: [''],
      leftHipDepthOfDehiscence: [''],
      leftHipSpontaneousDehiscenceReason: [''],
      leftHipDehiscenceDateOfOnset: [''],
      leftHipCriteriaForSSIs: [''],
      leftHipSurgicalSiteInfection: [''],
      leftHipCurrentMicrobiologyResults: [''],
      leftHipClassification: [''],
      leftHipDetection: [''],
      rightKneeWound: [''],
      rightKneeSurgicalWoundDehiscence: [''],
      rightKneeDepthOfDehiscence: [''],
      rightKneeSpontaneousDehiscenceReason: [''],
      rightKneeDehiscenceDateOfOnset: [''],
      rightKneeCriteriaForSSIs: [''],
      rightKneeSurgicalSiteInfection: [''],
      rightKneeCurrentMicrobiologyResults: [''],
      rightKneeClassification: [''],
      rightKneeDetection: [''],
      leftKneeWound: [''],
      leftKneeSurgicalWoundDehiscence: [''],
      leftKneeDepthOfDehiscence: [''],
      leftKneeSpontaneousDehiscenceReason: [''],
      leftKneeDehiscenceDateOfOnset: [''],
      leftKneeCriteriaForSSIs: [''],
      leftKneeSurgicalSiteInfection: [''],
      leftKneeCurrentMicrobiologyResults: [''],
      leftKneeClassification: [''],
      leftKneeDetection: [''],
      generalSurgicalWoundDehiscence: [''],
      generalWoundDepthOfDehiscence: [''],
      generalWoundSpontaneousDehiscenceReason: [''],
      generalDehiscenceDateOfOnset: [''],
      generalWoundCriteriaForSSIs: [''],
      generalWoundSurgicalSiteInfection: [''],
      generalWoundCurrentMicrobiologyResults: [''],
      generalWoundClassification: [''],
      generalWoundDetection: [''],
      impacts: [''],
      notes: [''],
      sensitiveImage: ['', [Validators.required]],
    });

    this.form.retreive$.pipe(take(1)).subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.entryForm.controls[key])
          this.entryForm.controls[key].setValue(formData[key]);
      });
      const sensitive = this.entryForm.get('sensitiveImage').value;
      this.setSensitiveImage(sensitive);
    });
  }

  get dateOfSurgery() {
    return this.entryForm.get('dateOfSurgery');
  }

  get surgicalWoundDehiscence() {
    return this.entryForm.get('surgicalWoundDehiscence');
  }

  get incisionReasons() {
    return this.entryForm.get('incisionReasons');
  }

  onSubmit() {
    this.isSubmitting = true;
    const formResponse = {
      ...this.entryForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  setSensitiveImage(status: boolean) {
    if (typeof status === 'string') {
      this.form.setSensitiveImage(false);
      this.isSensitive = false;
    } else if (status || status === false) {
      this.form.setSensitiveImage(status);
      this.isSensitive = status;
    } else {
      this.isSensitive = !this.isSensitive;
      this.form.setSensitiveImage(this.isSensitive);
    }
  }

  daysSinceOp() {
    const surgeryDate = new Date(this.dateOfSurgery.value);
    const today = this.todaysDate;
    const timeDiff = today.getTime() - surgeryDate.getTime();
    this.daysDiff = Math.trunc(timeDiff / (1000 * 3600 * 24));
    this.entryForm.get('daysSinceOp').setValue(String(this.daysDiff));
  }
}
