<form [formGroup]="bpiInterferenceScaleForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <div>
      <h3>
        Please select the number that describes how, during the past week, pain has interfered with
        your:
      </h3>
    </div>
    <div>
      <p>1. General activity:</p>
      <div class="full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>0</div>
          <div>No interference</div>
        </div>
        <mat-slider
          class="full-width-field"
          formControlName="generalActivitySlider"
          value="generalActivitySlider"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>10</div>
          <div>Complete interference</div>
        </div>
      </div>
    </div>
    <div>
      <p>2. Mood:</p>
      <div class="full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>0</div>
          <div>No interference</div>
        </div>
        <mat-slider
          class="full-width-field"
          formControlName="moodSlider"
          value="moodSlider"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>10</div>
          <div>Complete interference</div>
        </div>
      </div>
    </div>
    <div>
      <p>3. Walking ability:</p>
      <div class="full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>0</div>
          <div>No interference</div>
        </div>
        <mat-slider
          class="full-width-field"
          formControlName="walkingAbilitySlider"
          value="walkingAbilitySlider"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>10</div>
          <div>Complete interference</div>
        </div>
      </div>
    </div>
    <div>
      <p>4. Normal work (including both work outside the home and housework):</p>
      <div class="full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>0</div>
          <div>No interference</div>
        </div>
        <mat-slider
          class="full-width-field"
          formControlName="normalWorkSlider"
          value="normalWorkSlider"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>10</div>
          <div>Complete interference</div>
        </div>
      </div>
    </div>
    <div>
      <p>5. Relationships with other people:</p>
      <div class="full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>0</div>
          <div>No interference</div>
        </div>
        <mat-slider
          class="full-width-field"
          formControlName="relationshipsWithOtherPeopleSlider"
          value="relationshipsWithOtherPeopleSlider"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>10</div>
          <div>Complete interference</div>
        </div>
      </div>
    </div>
    <div>
      <p>6. Sleep:</p>
      <div class="full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>0</div>
          <div>No interference</div>
        </div>
        <mat-slider
          class="full-width-field"
          formControlName="sleepSlider"
          value="sleepSlider"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>10</div>
          <div>Complete interference</div>
        </div>
      </div>
    </div>
    <div>
      <p>7. Enjoyment of life:</p>
      <div class="full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>0</div>
          <div>No interference</div>
        </div>
        <mat-slider
          class="full-width-field"
          formControlName="enjoymentOfLifeSlider"
          value="enjoymentOfLifeSlider"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>10</div>
          <div>Complete interference</div>
        </div>
      </div>
    </div>
    <div>
      <button mat-raised-button class="positive-button" type="submit" [disabled]="isSubmitting">
        {{ isSubmitting ? 'Submitting...' : 'Submit' }}
      </button>
    </div>
  </div>
</form>
