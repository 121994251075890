export default [
  'HADS',
  'stillEnjoyThings',
  'seeFunnySideOfThings',
  'feelCheerful',
  'feelSlowedDown',
  'lostInterestInAppearance',
  'lookForwardWithEnjoyment',
  'enjoyABookRadioOrTv',
  'tenseOrWoundUp',
  'frightenedFeelingAboutSomethingAwful',
  'worryingThoughts',
  'sitAtEaseAndFeelRelaxed',
  'butterfliesInStomachFeeling',
  'feelingRestless',
  'suddenFeelingOfPanic'
];
