import {
  AfterViewInit,
  Component,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'ic-rd-delete-entry-dialog',
  templateUrl: './rd-delete-entry-dialog.component.html'
})
export class RdDeleteEntryDialogComponent implements AfterViewInit {
  @ViewChild('dialogFooter', { read: TemplateRef }) dialogFooter: TemplateRef<any>;

  deleteReasons = [
    'sensitive information contained',
    'patient request to delete',
    'sent/recorded in error',
    'duplicate'
  ];

  deleteReasonForm = this.formBuilder.group({
    reason: ['', [Validators.required]]
  });

  get selectedReason(): string {
    return this.deleteReasonForm.get('reason').value;
  }

  constructor(
    private viewContainerRef: ViewContainerRef,
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {}

  ngAfterViewInit(): void {
    const footer = document.querySelector('.delete-entry-dialog .p-dialog-footer');

    if (footer) {
      Promise.resolve(null).then(() => {
        this.renderer.setProperty(footer, 'innerHTML', '');
        const embeddedView = this.viewContainerRef.createEmbeddedView(this.dialogFooter);
        this.renderer.appendChild(footer, embeddedView.rootNodes[0]);
      });
    }
  }

  confirmRemove(): void {
    this.ref.close(this.selectedReason);
  }
}
