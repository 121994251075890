<ng-container *ngIf="field && control">
  <div
    class="question-container"
    *ngIf="field?.title"
    [ngStyle]="{ 'justify-content': justifyContent() }"
  >
    <ic-automatic-title
      [field]="field"
      class="half-width-field"
    ></ic-automatic-title>
    <ng-container *ngTemplateOutlet="singleSelectField"></ng-container>
  </div>
  <ng-container *ngIf="!field?.title">
    <ng-container *ngTemplateOutlet="singleSelectField"></ng-container>
  </ng-container>

  <ng-template #singleSelectField>
    <mat-form-field appearance="fill" class="half-width-field">
      <mat-label *ngIf="field?.subTitle"
        ><ic-automatic-subtitle [field]="field"></ic-automatic-subtitle
      ></mat-label>

      <mat-select [formControl]="control">
        <mat-option
          *ngFor="let option of field?.options"
          [value]="option?.label"
          [id]="field?.controlName + '-' + option?.label"
          >{{ option?.label }}</mat-option
        >
      </mat-select>

      <mat-error *ngIf="control?.touched && control?.invalid">
        {{ formService?.getErrorMessage(control) }}
      </mat-error>
    </mat-form-field>
  </ng-template>
</ng-container>
