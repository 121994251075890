<form [formGroup]="entryForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <mat-form-field appearance="fill" class="half-width-field">
      <mat-label>Date of surgery</mat-label>
      <input
        matInput
        type="date"
        formControlName="dateOfSurgery"
        placeholder="UK: "
        class="date-placeholder"
        (change)="daysSinceOp()"
      />
      <!-- <mat-error *ngIf="dateOfSurgery.invalid && dateOfSurgery.touched">
        Please enter a date
      </mat-error> -->
      <mat-error *ngIf="dateOfSurgery.touched && dateOfSurgery.invalid">
        Please select a date
      </mat-error>
    </mat-form-field>

    <span> Today's date is: {{ todaysDate | date : 'dd/MM/yyyy' }} </span>
    <br />
    <span> Days since surgery date: {{ daysDiff }} </span>
    <!-- <div class="half-width-field" style="border: 1px solid black">
      Days since operation date: {{ daysDiff }}

    </div>
    <div class="half-width-field">
      Todays date is: {{todaysDate | date:'dd/MM/yyyy'}}

    </div> -->
    <br />

    <ng-container *ngIf="form$ | async as form">
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Ward of discharge </mat-label>
        <mat-select formControlName="ward">
          <mat-option [value]="ward" *ngFor="let ward of form.ward">
            {{ ward }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="ward.touched && ward.invalid">
          Please select a ward
        </mat-error>
      </mat-form-field>
    </ng-container>
    <br />

    <div formArrayName="woundAssessments">
      <div
        *ngFor="
          let assessment of woundAssessmentFormCurrent.controls;
          let i = index
        "
        [formGroupName]="i"
      >
        <mat-card class="form-card">
          <mat-card-header>
            <div mat-card-avatar class="form-header"></div>
            <mat-card-title
              >Wound assessment:
              <span *ngIf="woundAssessments.controls[i].get('surgicalWound')">{{
                woundAssessments.controls[i].get('surgicalWound').value
              }}</span>
            </mat-card-title>
            <!-- <mat-card-subtitle></mat-card-subtitle> -->
          </mat-card-header>
          <mat-card-content>
            <mat-form-field
              appearance="fill"
              required
              hintLabel="Eg Sternal wound (central, midline incision)"
              class="half-width-field"
            >
              <mat-label>Surgical wound </mat-label>
              <mat-select formControlName="surgicalWound">
                <mat-option value="Sternal wound"
                  >Sternal wound (central, midline incision)</mat-option
                >
                <mat-option value="Minimally invasive sternal wound"
                  >Minimally invasive sternal wound (shorter; central chest
                  incision)</mat-option
                >
                <mat-option value="Left-sided thoracotomy wound"
                  >Left-sided thoracotomy wound</mat-option
                >
                <mat-option value="Right-sided thoracotomy wound"
                  >Right-sided thoracotomy wound</mat-option
                >
                <mat-option value="Left leg wound">Left leg wound</mat-option>
                <mat-option value="Right leg wound">Right leg wound</mat-option>
                <mat-option value="Arm wound">Arm wound</mat-option>
                <!-- <mat-option value="Groin wound">Groin wound</mat-option> -->
              </mat-select>
              <mat-error
                *ngIf="
                  woundAssessments.controls[i].get('surgicalWound').invalid &&
                  woundAssessments.controls[i].get('surgicalWound').touched
                "
              >
                Please enter a Surgical Wound option
              </mat-error>
            </mat-form-field>

            <br />

            <mat-form-field appearance="fill" class="half-width-field">
              <mat-label>Wound closure material </mat-label>
              <mat-select multiple formControlName="woundClosureMaterial">
                <mat-option value="Dissolvable sutures"
                  >Dissolvable sutures</mat-option
                >
                <mat-option
                  value="Non-absorbable sutures (dark, thread-like appearance)"
                  >Non-dissolvable sutures (dark, thread-like
                  appearance)</mat-option
                >
                <mat-option value="Surgical clips">Surgical clips</mat-option>
                <mat-option value="Glue/tissue adhesive"
                  >Glue/tissue adhesive</mat-option
                >
                <mat-option value="Wound closure tapes"
                  >Wound closure tapes</mat-option
                >
                <mat-option value="Deep tension sutures"
                  >Deep tension sutures</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="
                  woundAssessments.controls[i].get('woundClosureMaterial')
                    .invalid &&
                  woundAssessments.controls[i].get('woundClosureMaterial')
                    .touched
                "
              >
                Please choose Wound closure material options
              </mat-error>
            </mat-form-field>

            <mat-form-field
              appearance="fill"
              class="half-width-field"
              class="half-width-field"
              *ngIf="
                woundAssessments.controls[i]
                  .get('woundClosureMaterial')
                  .value.indexOf(
                    'Non-absorbable sutures (dark, thread-like appearance)'
                  ) !== -1 ||
                woundAssessments.controls[i]
                  .get('woundClosureMaterial')
                  .value.indexOf('Surgical clips') !== -1 ||
                woundAssessments.controls[i]
                  .get('woundClosureMaterial')
                  .value.indexOf('Deep tension sutures') !== -1
              "
            >
              <mat-label>Wound closure materials removal date </mat-label>
              <input
                matInput
                type="date"
                placeholder="UK: "
                formControlName="dateOfRemoval"
                class="date-placeholder"
              />
              <!-- suture material removal date -->
              <mat-error
                *ngIf="
                  woundAssessments.controls[i].get('dateOfRemoval').invalid &&
                  woundAssessments.controls[i].get('dateOfRemoval').touched
                "
              >
                Please enter the Wound closure material removal date
              </mat-error>
            </mat-form-field>

            <mat-form-field
              appearance="fill"
              class="half-width-field"
              *ngIf="
                woundAssessments.controls[i].get('dateOfRemoval').value !== ''
              "
            >
              <mat-label>Removal needed by</mat-label>
              <mat-select formControlName="removalNeededBy">
                <mat-option value="Our hospital"
                  >Our hospital (wound clinic or other)</mat-option
                >
                <mat-option value="GP/Practice nurse"
                  >GP/Practice nurse</mat-option
                >
                <mat-option value="Onward hospital/Convalesce"
                  >Onward (transfer) hospital/Convalesce</mat-option
                >
                <mat-option value="District nurse">District nurse</mat-option>
                <mat-option value="Other">Other</mat-option>
              </mat-select>
            </mat-form-field>

            <br />

            <mat-form-field appearance="fill" class="half-width-field">
              <mat-label>Surgical wound assessment </mat-label>

              <mat-select multiple formControlName="surgicalWoundAssessment">
                <mat-option value="Dry, intact & exposed"
                  >Dry, intact & exposed</mat-option
                >
                <mat-option value="Exudate present - serous or haemoserous"
                  >Exudate (fluid) present - serous or haemoserous</mat-option
                >
                <mat-option value="Exudate present - thick/cloudy/pus"
                  >Exudate (fluid) present - thick/cloudy/pus</mat-option
                >
                <mat-option value="Minor gaping along incision"
                  >Minor gaping (opening) along the incision</mat-option
                >
                <mat-option value="Some slough evident"
                  >Some slough evident</mat-option
                >
                <mat-option value="Bruising near incision"
                  >Bruising near the incision</mat-option
                >
                <mat-option value="Skin tear/blister near incision"
                  >Skin tear/blister noted near the incision</mat-option
                >
                <mat-option value="Signs of inflammation"
                  >Signs of inflammation
                  (heat/redness/swelling/pain)</mat-option
                >
                <mat-option value="Eschar and/or dark necrotic tissue"
                  >Eschar (scab) and/or dark necrotic tissue noted</mat-option
                >
                <mat-option value="Dressing applied after assessment"
                  >Dressing applied after assessment</mat-option
                >
                <mat-option
                  value="Topical negative pressure dressing applied after assessment"
                  >Topical negative pressure dressing applied after after
                  assessment</mat-option
                >
                <mat-option value="Other">Other</mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  woundAssessments.controls[i].get('surgicalWoundAssessment')
                    .invalid &&
                  woundAssessments.controls[i].get('surgicalWoundAssessment')
                    .touched
                "
              >
                Please choose a Surgical wound assessment option
              </mat-error>
            </mat-form-field>

            <mat-form-field
              class="half-width-field"
              appearance="fill"
              *ngIf="
                woundAssessments.controls[i]
                  .get('surgicalWoundAssessment')
                  .value.indexOf('Exudate present - serous or haemoserous') !==
                  -1 ||
                woundAssessments.controls[i]
                  .get('surgicalWoundAssessment')
                  .value.indexOf('Exudate present - thick/cloudy/pus') !== -1
              "
            >
              <mat-label>Select the amount of Exudate present</mat-label>
              <mat-select formControlName="exudateAmount">
                <mat-option value="Minor">Minor</mat-option>
                <mat-option value="Moderate">Moderate</mat-option>
                <mat-option value="Large">Large</mat-option>
                <mat-option value="Copious">Copious</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field
              appearance="fill"
              class="half-width-field"
              *ngIf="
                woundAssessments.controls[i]
                  .get('surgicalWoundAssessment')
                  .value.slice(-1)[0] === 'Other'
              "
            >
              <input
                matInput
                formControlName="surgicalWoundAssessmentOther"
                type="notes"
                placeholder="Surgical wound assessment notes"
                autocomplete="off"
              />
            </mat-form-field>

            <br />

            <mat-form-field appearance="fill" class="half-width-field">
              <mat-label>Microbiology results for surgical wound</mat-label>
              <mat-select formControlName="microBiologyResults">
                <mat-option value="Non applicable">Non applicable</mat-option>
                <mat-option value="Antibiotics prescribed"
                  >Antibiotics are prescribed to cover the surgical wound swab
                  results</mat-option
                >
                <mat-option value="Pending surgical wound swabs"
                  >There are pending surgical wound swabs. We will follow up
                  these results and will inform your GP to action if
                  necessary</mat-option
                >
              </mat-select>
            </mat-form-field>

            <button mat-button (click)="deleteWoundAssessment(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="hori-verti-center">
      <button
        class="positive-button"
        mat-button
        type="button"
        (click)="addWoundAssessment()"
      >
        Add additional wound assessment
      </button>
    </div>

    <mat-form-field appearance="fill" class="half-width-field">
      <mat-label>Drain sites</mat-label>
      <mat-select formControlName="drainSites">
        <mat-option value="Sutures removed. Dry and intact"
          >Sutures removed. Dry and intact</mat-option
        >
        <mat-option value="Sutures (dark thread appearance) to be removed"
          >Sutures (dark thread appearance) to be removed</mat-option
        >
        <mat-option value="Drain site sutures removed"
          >Drain site sutures removed and a small dressing placed over the area.
          Remove the dressing after the next day or two</mat-option
        >
      </mat-select>
    </mat-form-field>

    <mat-form-field
      appearance="fill"
      class="half-width-field"
      class="half-width-field"
      *ngIf="
        entryForm.get('drainSites').value ===
        'Sutures (dark thread appearance) to be removed'
      "
    >
      <mat-label>Date of drain sites removal</mat-label>
      <input
        matInput
        type="date"
        placeholder="UK: "
        formControlName="drainSitesRemovalDate"
        class="date-placeholder"
      />
    </mat-form-field>
    <!-- <br> -->

    <mat-form-field
      appearance="fill"
      class="half-width-field"
      *ngIf="entryForm.get('drainSitesRemovalDate').value !== ''"
    >
      <mat-label>Drain sites removal needed by</mat-label>
      <mat-select formControlName="drainSitesRemovalNeededBy">
        <mat-option value="Our hospital"
          >Our hospital (wound clinic or other)</mat-option
        >
        <mat-option value="GP/Practice nurse">GP/Practice nurse</mat-option>
        <mat-option value="Onward hospital/Convalesce"
          >Onward (transfer) hospital/Convalesce</mat-option
        >
        <mat-option value="District nurse">District nurse</mat-option>
        <mat-option value="Other">Other</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="notes-field half-width-field">
      <input
        matInput
        formControlName="notes"
        type="notes"
        placeholder="Comments"
        autocomplete="off"
      />
      <mat-error *ngIf="notes.invalid && notes.touched">
        Please enter a maximum of 400 characters
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" class="half-width-field">
      <mat-label>Additional resources provided</mat-label>
      <mat-select multiple formControlName="additionalResources">
        <mat-option value="Antimicrobial wash provided"
          >Antimicrobial wash provided. Please use as directed. Not suitable for
          prolonged use.</mat-option
        >
        <mat-option value="Antimicrobial wipes"
          >Antimicrobial wipes (single use/disposable wipes); please use this in
          the evening for ‘freshen up’. No need to rinse.</mat-option
        >
        <mat-option value="Clip remover">Clip remover provided.</mat-option>
        <mat-option value="Use body lotion to prevent skin drying out"
          >Advised to use body lotion to prevent skin drying out. Please DO NOT
          apply any products directly to wound bed.</mat-option
        >
        <mat-option value="Additional surgical bra provided"
          >Additional surgical bra provided.</mat-option
        >
        <mat-option value="Gauze pack(s) provided"
          >Gauze pack(s) provided</mat-option
        >
        <mat-option value="Extra dressing(s) provided"
          >Extra dressing(s) provided.</mat-option
        >
      </mat-select>
    </mat-form-field>

    <ng-container *ngIf="form$ | async as form">
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Responsible Surgeon </mat-label>
        <mat-select formControlName="responsibleSurgeon">
          <mat-option
            [value]="responsibleSurgeon"
            *ngFor="let responsibleSurgeon of form.responsibleSurgeon"
          >
            {{ responsibleSurgeon }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="responsibleSurgeon.invalid && responsibleSurgeon.touched"
        >
          Please select a surgeon
        </mat-error>
      </mat-form-field>
    </ng-container>

    <mat-form-field class="notes-field half-width-field" appearance="fill">
      <mat-label>Additional Comments</mat-label>
      <textarea
        matInput
        formControlName="additionalComments"
        placeholder="Insert comments here"
      ></textarea>
    </mat-form-field>
    <br />

    <mat-form-field appearance="fill" class="notes-field half-width-field">
      <input
        matInput
        formControlName="staffProvidingPad"
        type="notes"
        placeholder="Staff providing PaD *"
        autocomplete="off"
      />
      <mat-error *ngIf="staffProvidingPad.invalid && staffProvidingPad.touched">
        Please enter a staff name providing PaD
      </mat-error>
    </mat-form-field>

    <div class="central-block">
      <mat-checkbox
        appearance="fill"
        class="consent-checkbox"
        formControlName="consent"
      >
        Consent obtained
      </mat-checkbox>
    </div>

    <!-- <mat-slide-toggle
        class="sensitive-image-field"
        matInput
        formControlName = "sensitiveImage"
        value = "sensitiveImage"
        labelPosition = "after"
        color = 'primary'
        (click)="setSensitiveImage($event.checked)"
      >
      Toggle sensitive image
    </mat-slide-toggle>
    <div *ngIf="isSensitive">
      <p class="sensitive-text">The image will be blurred unless your clinician is actively reviewing it.</p>
    </div> -->
    <br />

    <button
      mat-raised-button
      class="positive-button"
      type="submit"
      color="accent"
      [disabled]="!valid()"
    >
      <!--

      [disabled]="isSubmitting" <- moved because button isnt disabled by default until required fields are filled in otherwise
      -->
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
