<div class="checkbox-select-button">
  <ng-container *ngFor="let option of options; let i = index">
    <div [class.checkbox-select-button-highlighted]="selected === option" (click)="setSelected(option)"
      class="cursor-pointer" ngDefaultControl data-cy="dynamic-radio-btn-set">
      <p-radioButton [(ngModel)]="selected" [inputId]="option.key" [value]="option" name="radioButton"
        [ariaLabel]="option.label"></p-radioButton>
      <label class="cursor-pointer">
        <i [class]="option.icon" [style.color]="option.color"></i>
        <strong>{{ option.label }}</strong>
      </label>
    </div>
    <small *ngIf="option.description" class="-mt-1">{{
      option.description
      }}</small>
  </ng-container>
</div>