import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../dialog-data';

interface CheckboxConfirmationDialog extends DialogData {
  isConfirmed?: boolean;
}
@Component({
  selector: 'ic-checkbox-confirmation-dialog',
  templateUrl: './checkbox-confirmation-dialog.component.html',
  styleUrls: ['./checkbox-confirmation-dialog.component.scss']
})
export class CheckboxConfirmationDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: CheckboxConfirmationDialog) {}

  userConfirmation(): void {
    this.data.isConfirmed = !this.data.isConfirmed;
  }
}
