<ng-container *ngIf="currentlyEditing$ | async as currentlyEditing">
  <ng-container *ngIf="sidebarForm && fieldConfigForSidebarForm && options">
    <form [formGroup]="sidebarForm">
      <p-sidebar
        [visible]="sidebarVisible"
        position="right"
        [modal]="false"
        styleClass="w-5 overflow-scroll"
      >
        <ng-template pTemplate="content">
          <h3>{{ currentlyEditing.title }}</h3>
          <formly-form
            [model]="options?.formState?.model"
            [fields]="fieldConfigForSidebarForm"
            [form]="sidebarForm"
            [options]="options"
          ></formly-form>
        </ng-template>
        <ng-template pTemplate="footer">
          <div class="flex justify-content-between p-4">
            <span class="gap-4 flex">
              <button
                type="button"
                pButton
                label="Close"
                aria-label="Close"
                (click)="closeSidebar()"
                class="p-button-outlined"
              ></button>
              <button
                type="button"
                pButton
                label="Reset"
                aria-label="Reset"
                (click)="resetFormToInitialState()"
                class="p-button-info p-button-outlined"
              ></button>
              <button
                *ngIf="options?.formState?.model?.id"
                type="button"
                pButton
                label="Delete"
                aria-label="Delete"
                icon="pi pi-trash"
                (click)="deleteField(options?.formState?.model, $event)"
                class="p-button-danger"
              ></button>
            </span>
            <button
              *ngIf="options?.formState?.model?.id"
              type="button"
              pButton
              [disabled]="sidebarForm.invalid"
              label="Update"
              aria-label="Update"
              (click)="updateFieldConfig(options?.formState?.model)"
            ></button>
          </div>
        </ng-template>
        <p-confirmPopup></p-confirmPopup>
      </p-sidebar>
    </form>
  </ng-container>
</ng-container>
<div class="clinical-form-wrapper">
  <ng-container
    *ngIf="clinicalFormFieldConfig$ | async as clinicalFormFieldConfig"
  >
    <button
      pButton
      class="m-4"
      (click)="openSaveFormDialog(clinicalFormFieldConfig)"
    >
      Save form
    </button>
    <formly-form
      [fields]="clinicalFormFieldConfig"
      [options]="clinicalFormOptions"
      [model]="clinicalFormOptions?.formState?.model"
    ></formly-form>
  </ng-container>
</div>
