import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  standalone: true,
  selector: 'p-progressBar'
})
export class ProgressbarDirective implements AfterViewInit {
  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
  ) { }

  ngAfterViewInit() {
    const progressBarDiv = this.el.nativeElement.querySelector('div[role="progressbar"]');

    if (progressBarDiv) {
      this.renderer.setAttribute(progressBarDiv, 'aria-label', 'Progress bar');
    }
  }
}
