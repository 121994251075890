import { Injectable } from '@angular/core';
import {
  EntryOrder,
  FormNameAndId,
  PatientRecordState,
} from '@ic-monorepo/shared-submissions';
import {
  CollectionWithId,
  PatientWithId,
  UserWithId,
} from '@islacare/ic-types';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, of, switchMap, take } from 'rxjs';
import { CollectionAuthWithPatientMessages } from '../../../services/collectionsAuths/collectionAuths.service';
import {
  EntriesService,
  EntryWithIdUrl,
} from '../../../services/entries/entries.service';

export const defaultState: PatientRecordState = {
  patient: null,
  user: null,
  currentCollectionId: null,
  collections: null,
  collectionAuths: null,
  entriesOrder: null,
  entryId: null,
  formNames: null,
  selectedEntries: null,
  loading: false,
  submitting: false,
  error: null,
};

@Injectable()
export class PatientRecordStore extends ComponentStore<PatientRecordState> {
  readonly loading$: Observable<boolean> = this.select(
    (state) => state.loading
  );
  readonly submitting$: Observable<boolean> = this.select(
    (state) => state.submitting
  );
  readonly error$: Observable<string> = this.select((state) => state.error);

  readonly full$: Observable<PatientRecordState> = this.select(
    (state) => state
  );
  readonly patient$: Observable<PatientWithId> = this.select(
    (state) => state.patient
  );
  readonly user$: Observable<UserWithId> = this.select((state) => state.user);
  readonly currentCollectionId$: Observable<string> = this.select(
    (state) => state.currentCollectionId
  );
  readonly collections$: Observable<CollectionWithId[]> = this.select(
    (state) => state.collections
  );
  readonly entriesOrder$: Observable<EntryOrder> = this.select(
    (state) => state.entriesOrder
  );
  readonly currentCollection$: Observable<CollectionWithId> = this.select(
    (state) => {
      return state.collections.find(
        (collection) => collection.id === state.currentCollectionId
      );
    }
  ).pipe(
    switchMap((collection) => {
      return of(collection);
    })
  );

  readonly collectionAuths$: Observable<CollectionAuthWithPatientMessages[]> =
    this.select((state) => state.collectionAuths);
  readonly formNames$: Observable<FormNameAndId[]> = this.select(
    (state) => state.formNames
  );
  readonly entryId$: Observable<string> = this.select((state) => state.entryId);
  readonly selectedEntries$: Observable<EntryWithIdUrl[]> = this.select(
    (state) => state.selectedEntries
  );
  readonly multipleFolderSelected$: Observable<boolean> = this.select(
    (state) => {
      const collectionIdsArray: string[] = state.selectedEntries.map(
        (entry) => entry.collectionId
      );
      const tempSet = new Set(collectionIdsArray);
      return state.selectedEntries.length > 0 && tempSet.size !== 1;
    }
  );

  constructor(private entriesService: EntriesService) {
    super(defaultState);
  }

  async setSelectedEntries(selectedEntry: EntryWithIdUrl) {
    const selectedEntryWithUrl = {
      ...selectedEntry,
      url: await this.entriesService
        .returnUrl(selectedEntry, 'small')
        .pipe(take(1))
        .toPromise(),
    };

    this.setState((state) => {
      return {
        ...state,
        selectedEntries:
          !state.selectedEntries || state.selectedEntries.length === 0
            ? [selectedEntryWithUrl]
            : [...state.selectedEntries, selectedEntryWithUrl],
      };
    });
  }

  deselectEntry(selectedEntry: EntryWithIdUrl) {
    this.setState((state) => {
      return {
        ...state,
        selectedEntries: state.selectedEntries.filter(
          (x) => x.id !== selectedEntry.id
        ),
      };
    });
  }

  clearSelectedEntries() {
    this.setState((state) => {
      return {
        ...state,
        selectedEntries: [],
      };
    });
  }

  setPatient = this.updater(
    (state: PatientRecordState, patient: PatientWithId) => ({
      ...state,
      patient: patient,
    })
  );

  setUser = this.updater((state: PatientRecordState, user: UserWithId) => ({
    ...state,
    user: user,
  }));

  setCurrentCollectionId = this.updater(
    (state: PatientRecordState, currentCollectionId: string) => ({
      ...state,
      currentCollectionId,
    })
  );

  setCollections = this.updater(
    (state: PatientRecordState, collections: CollectionWithId[]) => ({
      ...state,
      collections: collections,
    })
  );

  setCollectionAuths = this.updater(
    (
      state: PatientRecordState,
      collectionAuths: CollectionAuthWithPatientMessages[]
    ) => ({
      ...state,
      collectionAuths: collectionAuths,
    })
  );

  setFormNames = this.updater(
    (state: PatientRecordState, formNames: FormNameAndId[]) => ({
      ...state,
      formNames: formNames,
    })
  );

  setEntryId = this.updater((state: PatientRecordState, entryId: string) => ({
    ...state,
    entryId,
  }));

  setEntriesOrder = this.updater(
    (state: PatientRecordState, entriesOrder: EntryOrder) => ({
      ...state,
      entriesOrder,
    })
  );

  setLoading(loading: boolean) {
    console.log('➡ PatientRecordStore', 'Setting loading: ', loading);
    this.setState((state) => ({
      ...state,
      loading: loading,
    }));
  }

  setSubmitting(submitting: boolean) {
    console.log('➡ PatientRecordStore', 'Setting submitting: ', submitting);
    this.setState((state) => ({
      ...state,
      submitting: submitting,
    }));
  }

  setError(error: string) {
    console.log('➡ PatientRecordStore', 'Setting error: ', error, '...');
    this.setState((state) => ({
      ...state,
      error: error,
    }));
  }

  clearStore = this.updater(() => defaultState);
}
