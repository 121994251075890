import { Component, Input } from '@angular/core';
import { FormField } from '@islacare/ic-types';

@Component({
  selector: 'ic-automatic-subtitle',
  templateUrl: './automatic-subtitle.component.html',
  styleUrls: ['./automatic-subtitle.component.scss'],
})
export class AutomaticSubtitleComponent {
  @Input() field: FormField;
}
