import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { OutcomeMeasure, PatientOutcome } from '@islacare/ic-types';
import firebase from 'firebase/compat/app';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, combineLatest, from, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { CollectionsService } from '../../../services/collections/collections.service';
import { TeamsService } from '../../../services/teams/teams.service';
import { UsersService } from '@ic-monorepo/services';
import { cleanObject } from '../../../utils/cleanObject';
import { RdOutcomeMeasuresComponent } from '../../redesign/dialogs/rd-outcome-measures/rd-outcome-measures.component';

@Injectable({
  providedIn: 'root',
})
export class OutcomeService {
  serverMessage: string;
  existingOutcome: boolean;

  constructor(
    private db: AngularFirestore,
    private usersService: UsersService,
    private teamsService: TeamsService,
    private dialogService: DialogService,
    private collectionsService: CollectionsService,
  ) {}

  getPatientOutcomes$(patientId: string, collectionId: string): Observable<PatientOutcome[]> {
    return this.db
      .collection<PatientOutcome>(`patients/${patientId}/collections/${collectionId}/patientOutcomes`)
      .valueChanges();
  }

  areOutcomeMeasuresEnabledOnThisCollectionsTeam$(collectionTeamId: string | null): Observable<boolean> {
    const enabledForCollection$ = this.teamsService.getTeamFunction$(collectionTeamId).pipe(
      map(team => team.featureFlags?.outcomeMeasures?.isEnabled || false),
      catchError(() => of(false)),
    );

    const collectionTeamMatchesUserTeam$ = this.usersService.me$.pipe(
      map(me => me?.teamIds.includes(collectionTeamId)),
    );

    return combineLatest([enabledForCollection$, collectionTeamMatchesUserTeam$]).pipe(
      map(
        ([enabledForCollection, collectionTeamMatchesUserTeam]) =>
          enabledForCollection && collectionTeamMatchesUserTeam,
      ),
    );
  }

  getOutcomeMeasureOption(teamId): Observable<OutcomeMeasure> {
    return this.teamsService.getTeamFunction$(teamId).pipe(
      switchMap(team => {
        const outcomeId = team.featureFlags?.outcomeMeasures?.id;
        return this.db.doc<OutcomeMeasure>(`teams/${teamId}/outcomeMeasuresOptions/${outcomeId}`).valueChanges();
      }),
    );
  }

  rdRecordOutcomes$(collectionId: string, patientId: string, action: string, teamId: string): Observable<void> {
    const recordOutcome$ = teamId =>
      this.getOutcomeMeasureOption(teamId).pipe(
        switchMap(outComeMeasure => {
          const dialogRef = this.dialogService.open(RdOutcomeMeasuresComponent, {
            styleClass: 'rd-outcome-measures-dialog md:w-8 w-11 h-70 ',
            header: 'Patient outcomes',
            footer: 'Loading...',
            data: {
              message: 'Please select all relevant patient outcomes:',
              outcomeMeasureControl: outComeMeasure.controls,
              patientId: patientId,
              collectionId: collectionId,
              action: action,
            },
          });
          return combineLatest([
            dialogRef.onClose,
            this.usersService.me$,
            this.collectionsService.getCollection(patientId, collectionId),
          ]).pipe(
            take(1),
            map(([result, user, collection]) => {
              if (result)
                return {
                  userId: user.id,
                  userEmail: user.email,
                  teamId: collection?.teamId,
                  organisationId: collection.organisationId,
                  patientId: patientId,
                  createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                  collectionId: collectionId,
                  patientOutcome: cleanObject(result),
                };

              return null;
            }),
          );
        }),
        switchMap(patientOutcome => {
          if (patientOutcome) {
            const id = this.db.createId();
            return from(
              this.db
                .collection(`patients/${patientId}/collections/${collectionId}/patientOutcomes`)
                .doc(id)
                .set(patientOutcome),
            );
          }
          return of(null);
        }),
      );

    return this.areOutcomeMeasuresEnabledOnThisCollectionsTeam$(teamId).pipe(
      switchMap(isEnabled => {
        if (isEnabled) return recordOutcome$(teamId);

        return of(null);
      }),
      take(1),
    );
  }

  getPatientOutcomesData$(
    patientId: string,
    collectionId: string,
    teamId: string,
  ): Observable<
    { createdAt: any; recordedBy: string; outcomeResponses: { key: string; title: string; value: string }[] }[]
  > {
    const patientOutcomes$ = this.db
      .collection<PatientOutcome>(`patients/${patientId}/collections/${collectionId}/patientOutcomes`)
      .valueChanges();

    return combineLatest([patientOutcomes$, this.getOutcomeMeasureOption(teamId)]).pipe(
      map(([outcomes, option]) => {
        const outcomeData = [];
        outcomes.forEach(outcome => {
          const outcomesArr = [];
          for (const key in outcome.patientOutcome) {
            if (Object.prototype.hasOwnProperty.call(outcome.patientOutcome, key)) {
              const outcomeValue = outcome.patientOutcome[key] ?? 'N/A';
              outcomesArr.push({
                key: key,
                title: option.controls?.[key]?.title || key,
                value: outcomeValue,
              });
            }
          }
          outcomeData.push({
            createdAt: outcome.createdAt,
            outcomeResponses: outcomesArr,
            recordedBy: outcome?.userEmail,
          });
        });
        return outcomeData;
      }),
      catchError(() => of([])),
    );
  }
}
