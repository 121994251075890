export default [
  'surgicalWoundHealingProblems',
  'surgicalCategory',
  'surgicalWound',

  // chest
  'chestWoundRednessSpreading',
  'chestWoundAreaWarmer',
  'chestWoundLeakedClearFluid',
  'chestWoundLeakedBloodstainedFluid',
  'chestWoundLeakedYellowGreenFluid',
  'chestWoundSeparatedOrGaped',
  'chestWoundDeeperTissueSeparate',
  'chestWoundBecomeSwollen',
  'chestWoundBeenSmelly',
  'chestWoundPainfulToTouch',
  'chestWoundRaiseInTemperature',
  'chestWoundObservedDate',

  // left leg
  'leftLegWoundRednessSpreading',
  'leftLegWoundAreaWarmer',
  'leftLegWoundLeakedClearFluid',
  'leftLegWoundLeakedBloodstainedFluid',
  'leftLegWoundLeakedYellowGreenFluid',
  'leftLegWoundSeparatedOrGaped',
  'leftLegWoundDeeperTissueSeparate',
  'leftLegWoundBecomeSwollen',
  'leftLegWoundBeenSmelly',
  'leftLegWoundPainfulToTouch',
  'leftLegWoundRaiseInTemperature',
  'leftLegWoundObservedDate',

  // right leg
  'rightLegWoundRednessSpreading',
  'rightLegWoundAreaWarmer',
  'rightLegWoundLeakedClearFluid',
  'rightLegWoundLeakedBloodstainedFluid',
  'rightLegWoundLeakedYellowGreenFluid',
  'rightLegWoundSeparatedOrGaped',
  'rightLegWoundDeeperTissueSeparate',
  'rightLegWoundBecomeSwollen',
  'rightLegWoundBeenSmelly',
  'rightLegWoundPainfulToTouch',
  'rightLegWoundRaiseInTemperature',
  'rightLegWoundObservedDate',

  // left arm
  'leftArmWoundRednessSpreading',
  'leftArmWoundAreaWarmer',
  'leftArmWoundLeakedClearFluid',
  'leftArmWoundLeakedBloodstainedFluid',
  'leftArmWoundLeakedYellowGreenFluid',
  'leftArmWoundSeparatedOrGaped',
  'leftArmWoundDeeperTissueSeparate',
  'leftArmWoundBecomeSwollen',
  'leftArmWoundBeenSmelly',
  'leftArmWoundPainfulToTouch',
  'leftArmWoundRaiseInTemperature',
  'leftArmWoundObservedDate',

  // right arm
  'rightArmWoundRednessSpreading',
  'rightArmWoundAreaWarmer',
  'rightArmWoundLeakedClearFluid',
  'rightArmWoundLeakedBloodstainedFluid',
  'rightArmWoundLeakedYellowGreenFluid',
  'rightArmWoundSeparatedOrGaped',
  'rightArmWoundDeeperTissueSeparate',
  'rightArmWoundBecomeSwollen',
  'rightArmWoundBeenSmelly',
  'rightArmWoundPainfulToTouch',
  'rightArmWoundRaiseInTemperature',
  'rightArmWoundObservedDate',

  // right hip
  'rightHipWound',
  'rightHipWoundRednessSpreading',
  'rightHipWoundAreaWarmer',
  'rightHipWoundLeakedClearFluid',
  'rightHipWoundLeakedBloodstainedFluid',
  'rightHipWoundLeakedYellowGreenFluid',
  'rightHipWoundSeparatedOrGaped',
  'rightHipWoundDeeperTissueSeparate',
  'rightHipWoundBecomeSwollen',
  'rightHipWoundBeenSmelly',
  'rightHipWoundPainfulToTouch',
  'rightHipWoundRaiseInTemperature',
  'rightHipWoundObservedDate',

  // left hip
  'leftHipWound',
  'leftHipWoundRednessSpreading',
  'leftHipWoundAreaWarmer',
  'leftHipWoundLeakedClearFluid',
  'leftHipWoundLeakedBloodstainedFluid',
  'leftHipWoundLeakedYellowGreenFluid',
  'leftHipWoundSeparatedOrGaped',
  'leftHipWoundDeeperTissueSeparate',
  'leftHipWoundBecomeSwollen',
  'leftHipWoundBeenSmelly',
  'leftHipWoundPainfulToTouch',
  'leftHipWoundRaiseInTemperature',
  'leftHipWoundObservedDate',

  // right knee
  'rightKneeWound',
  'rightKneeWoundRednessSpreading',
  'rightKneeWoundAreaWarmer',
  'rightKneeWoundLeakedClearFluid',
  'rightKneeWoundLeakedBloodstainedFluid',
  'rightKneeWoundLeakedYellowGreenFluid',
  'rightKneeWoundSeparatedOrGaped',
  'rightKneeWoundDeeperTissueSeparate',
  'rightKneeWoundBecomeSwollen',
  'rightKneeWoundBeenSmelly',
  'rightKneeWoundPainfulToTouch',
  'rightKneeWoundRaiseInTemperature',
  'rightKneeWoundObservedDate',

  // left knee
  'leftKneeWound',
  'leftKneeWoundRednessSpreading',
  'leftKneeWoundAreaWarmer',
  'leftKneeWoundLeakedClearFluid',
  'leftKneeWoundLeakedBloodstainedFluid',
  'leftKneeWoundLeakedYellowGreenFluid',
  'leftKneeWoundSeparatedOrGaped',
  'leftKneeWoundDeeperTissueSeparate',
  'leftKneeWoundBecomeSwollen',
  'leftKneeWoundBeenSmelly',
  'leftKneeWoundPainfulToTouch',
  'leftKneeWoundRaiseInTemperature',
  'leftKneeWoundObservedDate',

  // general wound
  'generalWoundRednessSpreading',
  'generalWoundAreaWarmer',
  'generalWoundLeakedClearFluid',
  'generalWoundLeakedBloodstainedFluid',
  'generalWoundLeakedYellowGreenFluid',
  'generalWoundSeparatedOrGaped',
  'generalWoundDeeperTissueSeparate',
  'generalWoundBecomeSwollen',
  'generalWoundBeenSmelly',
  'generalWoundPainfulToTouch',
  'generalWoundRaiseInTemperature',
  'generalWoundObservedDate',

  'antibiotics',
  'prescriber',
  'prescriberOther',
  'readmission',
  'readmissionLocation',
  'notes'
];
