<form [formGroup]="phqForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <div class="full-width-field" style="margin: auto">
      <h3>The Patient Health Questionnaire</h3>
    </div>
    <div class="full-width-field" style="margin: auto">
      <p>
        Over the last 2 weeks, how often have you been bothered by any of the following problems?
      </p>
    </div>

    <br />

    <div class="question-container">
      <div class="half-width-field">
        <p>Little interest or pleasure in doing things</p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="littleInterestOrPleasure">
          <mat-option *ngFor="let option of answerList" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="littleInterestOrPleasure.touched"> Please select an option </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Feeling down, depressed, or hopeless</p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="feelingDownDepressedOrHopeless">
          <mat-option *ngFor="let option of answerList" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="feelingDownDepressedOrHopeless.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Trouble falling or staying asleep, or sleeping too much</p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="troubleSleeping">
          <mat-option *ngFor="let option of answerList" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="troubleSleeping.touched"> Please select an option </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Feeling tired or having little energy</p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="feelingTiredOrLittleEnergy">
          <mat-option *ngFor="let option of answerList" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="feelingTiredOrLittleEnergy.touched"> Please select an option </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Poor appetite or overeating</p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="poorAppetiteOrOvereating">
          <mat-option *ngFor="let option of answerList" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="poorAppetiteOrOvereating.touched"> Please select an option </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>
          Feeling bad about yourself - or that you are a failure or have let yourself or your family
          down
        </p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="feelingBadAboutYourself">
          <mat-option *ngFor="let option of answerList" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="feelingBadAboutYourself.touched"> Please select an option </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Trouble concentrating on things, such as reading the newspaper or watching television</p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="troubleConcentratingOnThings">
          <mat-option *ngFor="let option of answerList" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="troubleConcentratingOnThings.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>
          Moving or speaking so slowly that other people could have noticed. Or the opposite - being
          so figety or restless that you have been moving around a lot more than usual
        </p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="movingOrSpeakingSlowerOrFasterThanNormal">
          <mat-option *ngFor="let option of answerList" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="movingOrSpeakingSlowerOrFasterThanNormal.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Thoughts that you would be better off dead, or of hurting yourself</p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="negativeThoughtsTowardsYourself">
          <mat-option *ngFor="let option of answerList" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="negativeThoughtsTowardsYourself.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>
          If you checked off any problems, how difficult have these problems made it for you to do
          your work, take care of things at home, or get along with others?
        </p>
      </div>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="howDifficultHaveTheseProblemsMadeThings">
          <mat-option value="Not difficult at all">Not difficult at all</mat-option>
          <mat-option value="Somewhat difficult">Somewhat difficult</mat-option>
          <mat-option value="Very difficult">Very difficult</mat-option>
          <mat-option value="Extremely difficult">Extremely difficult</mat-option>
        </mat-select>
        <mat-error *ngIf="howDifficultHaveTheseProblemsMadeThings.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>
    <div class="button-group">
      <button
        class="positive-button"
        mat-raised-button
        color="accent"
        type="submit"
        [disabled]="!phqFormValid()"
      >
        {{ isSubmitting ? 'Submitting...' : 'Submit' }}
      </button>
    </div>
  </div>
</form>
