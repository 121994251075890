import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { FormService } from '@ic-monorepo/services';
import { FormField } from '@islacare/ic-types';

export const APP_DATE_FORMATS = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};
@Component({
  selector: 'ic-automatic-date-picker',
  templateUrl: './automatic-date-picker.component.html',
  styleUrls: ['./automatic-date-picker.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }],
})
export class AutomaticDatePickerComponent {
  @Input() field: FormField;
  @Input() control: UntypedFormControl;

  constructor(public formService: FormService) {}
}
