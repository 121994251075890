<ng-container *ngIf="ageBracket === 'overSixteen'">
  <form [formGroup]="dlqiForm" (ngSubmit)="onSubmit()">
    <div class="form-formatting">
      <div class="full-width-field" style="margin: auto">
        <h3>DERMATOLOGY LIFE QUALITY INDEX (DLQI)</h3>
      </div>

      <div class="full-width-field" style="margin: auto">
        <h4>
          The aim of this questionnaire is to measure how much your skin problem has affected you
          life OVER THE LAST WEEK. Please an option from the dropdown lists.
        </h4>
      </div>

      <!--  QUESTION ONE -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Over the last week, how <strong>itchy, sore, painful</strong> or
            <strong>stinging</strong> has your skin been?
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="itchySorePainfulOrStingingSkin">
            <mat-option *ngFor="let option of dermatologyAnswerList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="itchySorePainfulOrStingingSkin.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <!-- TODO: come back to deciding this? -->
      <!-- <mat-divider class="full-width-field"></mat-divider> -->

      <!--  QUESTION TWO -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Over the last week, how <strong>embarrassed</strong> or
            <strong>self conscious</strong> have you been because of your skin?
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="embarrassedOrSelfConscious">
            <mat-option *ngFor="let option of dermatologyAnswerList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="embarrassedOrSelfConscious.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <!--  QUESTION THREE -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Over the last week, how much has your skin interfered with you going
            <strong>shopping</strong> or looking after your <strong>home</strong> or
            <strong>garden</strong>.
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="interferedWithShoppingHomeOrGarden">
            <mat-option *ngFor="let option of dermatologyAnswerList" [value]="option">
              {{ option }}
            </mat-option>
            <mat-option value="Not relevant - 0">Not relevant - 0</mat-option>
          </mat-select>
          <mat-error *ngIf="interferedWithShoppingHomeOrGarden.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <!--  QUESTION FOUR -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Over the last week, how much has your skin influenced the <strong>clothes</strong> you
            wear?
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="skinInfluencedClothesYouWear">
            <mat-option *ngFor="let option of dermatologyAnswerList" [value]="option">
              {{ option }}
            </mat-option>
            <mat-option value="Not relevant - 0">Not relevant - 0</mat-option>
          </mat-select>
          <mat-error *ngIf="skinInfluencedClothesYouWear.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <!--  QUESTION FIVE -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Over the last week, how much has your skin affected any <strong>social</strong> or
            <strong>leisure</strong> activities
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="skinAffectedSocialOrLeisureActivities">
            <mat-option *ngFor="let option of dermatologyAnswerList" [value]="option">
              {{ option }}
            </mat-option>
            <mat-option value="Not relevant - 0">Not relevant - 0</mat-option>
          </mat-select>
          <mat-error *ngIf="skinAffectedSocialOrLeisureActivities.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <!--  QUESTION SIX -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Over the last week, how much has your skin made it difficult for you to do any
            <strong>sport</strong>?
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="skinAffectedAnySport">
            <mat-option *ngFor="let option of dermatologyAnswerList" [value]="option">
              {{ option }}
            </mat-option>
            <mat-option value="Not relevant - 0">Not relevant - 0</mat-option>
          </mat-select>
          <mat-error *ngIf="skinAffectedAnySport.touched"> Please select an option </mat-error>
        </mat-form-field>
      </div>

      <!--  QUESTION SEVEN -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Over the last week, has your skin prevented you from <strong>working</strong> or
            <strong>studying</strong>?
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="skinPreventedWorkingOrStudyingYesNo">
            <mat-option value="Yes - 3">Yes</mat-option>
            <mat-option value="No - 0">No</mat-option>
            <mat-option value="Not relevant - 0">Not relevant</mat-option>
          </mat-select>
          <mat-error *ngIf="skinPreventedWorkingOrStudyingYesNo.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <!-- QUESTION SEVEN PT 2 -->
      <div
        class="question-container"
        *ngIf="dlqiForm.get('skinPreventedWorkingOrStudyingYesNo').value === 'No'"
      >
        <div class="half-width-field">
          <p>
            Over the last week, how much has your skin prevented you from
            <strong>working</strong> or <strong>studying</strong>?
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="skinPreventedWorkingOrStudying">
            <mat-option value="A lot - 2">A lot - 2</mat-option>
            <mat-option value="A little - 1">A little - 1</mat-option>
            <mat-option value="Not at all - 0">Not at all - 0</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!--  QUESTION EIGHT -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Over the last week, how much has your skin created problems with your
            <strong>partner</strong> or any of your <strong>close friends</strong> or
            <strong>relatives</strong>?
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="skinCreatedProblemsWithPartnerFriendsOrFamily">
            <mat-option *ngFor="let option of dermatologyAnswerList" [value]="option">
              {{ option }}
            </mat-option>
            <mat-option value="Not relevant - 0">Not relevant - 0</mat-option>
          </mat-select>
          <mat-error *ngIf="skinCreatedProblemsWithPartnerFriendsOrFamily.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <!--  QUESTION NINE -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Over the last week, how much has your skin cause any
            <strong>sexual difficulties</strong>?
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="skinCausedSexualDifficulties">
            <mat-option *ngFor="let option of dermatologyAnswerList" [value]="option">
              {{ option }}
            </mat-option>
            <mat-option value="Not relevant - 0">Not relevant - 0</mat-option>
          </mat-select>
          <mat-error *ngIf="skinCausedSexualDifficulties.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <!--  QUESTION TEN -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Over the last week, how much of a problem has the <strong>treatment</strong> for your
            skin been, for example by making your home messy, or by taking up time?
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="skinTreatmentBeenAProblem">
            <mat-option *ngFor="let option of dermatologyAnswerList" [value]="option">
              {{ option }}
            </mat-option>
            <mat-option value="Not relevant - 0">Not relevant - 0</mat-option>
          </mat-select>
          <mat-error *ngIf="skinTreatmentBeenAProblem.touched"> Please select an option </mat-error>
        </mat-form-field>
      </div>
      <div class="button-group">
        <button
          class="positive-button"
          mat-raised-button
          color="accent"
          type="submit"
          [disabled]="!dlqiFormValid()"
        >
          {{ isSubmitting ? 'Submitting...' : 'Submit' }}
        </button>
      </div>
    </div>
  </form>
</ng-container>

<!-- age four to sixteen -->
<ng-container *ngIf="ageBracket === 'fourToSixteen'">
  <form [formGroup]="teenagerDlqiForm" (ngSubmit)="teenagerFormSubmit()">
    <div class="form-formatting">
      <div class="full-width-field" style="margin: auto">
        <h3>TEENAGERS QUALITY OF LIFE INDEX (TQLI)</h3>
      </div>

      <div class="full-width-field" style="margin: auto">
        <h4>
          The aim of this questionnaire is to measure the impact that your skin disease has on your
          Quality of Life at the moment.
        </h4>
      </div>

      <div class="full-width-field" style="margin: auto">
        <p>Self image</p>
      </div>
      <!--  QUESTION ONE -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Does your skin condition make you feel self-conscious?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="skinConditionMakeYouFeelSelfConscious">
            <mat-option *ngFor="let option of teenagersAnswerList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="skinConditionMakeYouFeelSelfConscious.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <!--  QUESTION TWO -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Does your skin condition make you feel upset?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="skinConditionMakeYouFeelUpset">
            <mat-option *ngFor="let option of teenagersAnswerList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="skinConditionMakeYouFeelUpset.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <!--  QUESTION THREE -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Does your skin condition make you feel that you look different?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="skinConditionMakeYouFeelThatYouLookDifferent">
            <mat-option *ngFor="let option of teenagersAnswerList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="skinConditionMakeYouFeelThatYouLookDifferent.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <!--  QUESTION FOUR -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Does your skin condition make you feel that people stare at you?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="skinConditionMakeYouFeelThatPeopleStare">
            <mat-option *ngFor="let option of teenagersAnswerList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="skinConditionMakeYouFeelThatPeopleStare.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <!--  QUESTION FIVE -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Does your skin condition make you feel embarrassed?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="skinConditionMakeYouFeelEmbarrassed">
            <mat-option *ngFor="let option of teenagersAnswerList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="skinConditionMakeYouFeelEmbarrassed.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <!--  QUESTION SIX -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Does your skin condition make you feel uncomfortable in the presence of others?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="skinConditionMakeYouFeelUncomfortableAroundOthers">
            <mat-option *ngFor="let option of teenagersAnswerList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="skinConditionMakeYouFeelUncomfortableAroundOthers.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <!--  QUESTION SEVEN -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Does your skin condition stop you from going to places you would love to go?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="skinConditionStopYouFromGoingPlaces">
            <mat-option *ngFor="let option of teenagersAnswerList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="skinConditionStopYouFromGoingPlaces.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <!--  QUESTION EIGHT -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Do you feel the need to cover up the affected areas of your skin condition?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="feelTheNeedToCoverUpAffectedAreas">
            <mat-option *ngFor="let option of teenagersAnswerList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="feelTheNeedToCoverUpAffectedAreas.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div class="full-width-field" style="margin: auto">
        <p>Physical well-being and future aspirations</p>
      </div>
      <!--  QUESTION NINE -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Does your skin condition affect your studies/job?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="skinConditionAffectYourStudiesOrJob">
            <mat-option *ngFor="let option of teenagersAnswerList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="skinConditionAffectYourStudiesOrJob.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <!--  QUESTION TEN -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Does your skin condition make you worry about your future career?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="skinConditionWorryYouAboutFutureCareer">
            <mat-option *ngFor="let option of teenagersAnswerList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="skinConditionWorryYouAboutFutureCareer.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <!--  QUESTION ELEVEN -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Does your skin condition cause any pain or discomfort?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="skinConditionCausePainOrDiscomfort">
            <mat-option *ngFor="let option of teenagersAnswerList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="skinConditionCausePainOrDiscomfort.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <!--  QUESTION TWELVE -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Does your skin condition affect your sleep?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="skinConditionAffectYourSleep">
            <mat-option *ngFor="let option of teenagersAnswerList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="skinConditionAffectYourSleep.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div class="full-width-field" style="margin: auto">
        <p>Psychological impact and relationships</p>
      </div>
      <!--  QUESTION THIRTEEN -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Does your skin condition make you annoyed?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="skinConditionMakeYouAnnoyed">
            <mat-option *ngFor="let option of teenagersAnswerList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="skinConditionMakeYouAnnoyed.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <!--  QUESTION FOURTEEN -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Do you think a lot about your skin condition?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="thinkAlotAboutYourSkinCondition">
            <mat-option *ngFor="let option of teenagersAnswerList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="thinkAlotAboutYourSkinCondition.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>
      <!--  QUESTION FIFTEEN -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Does your skin condition make you avoid meeting new people?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="skinConditionMakeYouAvoidMeetingNewPeople">
            <mat-option *ngFor="let option of teenagersAnswerList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="skinConditionMakeYouAvoidMeetingNewPeople.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <!--  QUESTION SIXTEEN -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Do you receive any unfriendly comments from other people about your skin?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="receiveUnfriendlyCommentsAboutYourSkin">
            <mat-option *ngFor="let option of teenagersAnswerList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="receiveUnfriendlyCommentsAboutYourSkin.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <!--  QUESTION SEVENTEEN -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Does your skin condition affect your relationships with friends?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="skinConditionAffectFriendships">
            <mat-option *ngFor="let option of teenagersAnswerList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="skinConditionAffectFriendships.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <!--  QUESTION EIGHTEEN -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Does your skin condition affect your intimate relationships?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="skinConditionAffectInitimateRelationships">
            <mat-option *ngFor="let option of teenagersAnswerList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="skinConditionAffectInitimateRelationships.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div class="button-group">
        <button
          class="positive-button"
          mat-raised-button
          color="accent"
          type="submit"
          [disabled]="!teenagerDlqiFormValid()"
        >
          {{ isSubmitting ? 'Submitting...' : 'Submit' }}
        </button>
      </div>
    </div>
  </form>
</ng-container>

<!-- age under four -->
<ng-container *ngIf="ageBracket === 'underFour'">
  <form [formGroup]="infantDlqiForm" (ngSubmit)="infantFormSubmit()">
    <div class="form-formatting">
      <div class="full-width-field" style="margin: auto">
        <h3>INFANTS DERMATITIS QUALITY OF LIFE INDEX (IDQOL)</h3>
      </div>

      <div class="full-width-field" style="margin: auto">
        <h4>
          The aim of this questionnaire is to record how your child's dermatitis has been. Each
          question concerns THE LAST WEEK ONLY. Please could you answer every question.
        </h4>
      </div>

      <div class="full-width-field" style="margin: auto">
        <p>Dermatitis Severity</p>
      </div>
      <!--  QUESTION ONE -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Over the last week, <strong>how severe</strong> do you think your child's dermatitis has
            been? i.e. how red, scaly, inflamed or widespread
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="howSevereYourChildsDermatitisHasBeen">
            <mat-option value="Extremely severe">Extremely severe</mat-option>
            <mat-option value="Severe">Severe</mat-option>
            <mat-option value="Average">Average</mat-option>
            <mat-option value="Fairly good">Fairly good</mat-option>
            <mat-option value="None">None</mat-option>
          </mat-select>
          <mat-error *ngIf="howSevereYourChildsDermatitisHasBeen.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div class="full-width-field" style="margin: auto">
        <p>Life quality index</p>
      </div>
      <!--  QUESTION TWO -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Over the last week, how much has your child been
            <strong>itching and scratching</strong>?
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="howMuchHasYourChildBeenItchingAndScratching">
            <mat-option value="All the time">All the time</mat-option>
            <mat-option value="A lot">A lot</mat-option>
            <mat-option value="A little">A little</mat-option>
            <mat-option value="None">None</mat-option>
          </mat-select>
          <mat-error *ngIf="howMuchHasYourChildBeenItchingAndScratching.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <!--  QUESTION THREE -->
      <div class="question-container">
        <div class="half-width-field">
          <p>Over the last week, what has your childs <strong>mood</strong> been?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="howHasYourChildsMoodBeen">
            <mat-option value="Always crying">Always crying</mat-option>
            <mat-option value="Extremely difficult">Extremely difficult</mat-option>
            <mat-option value="Very fretful">Very fretful</mat-option>
            <mat-option value="Slightly fretful">Slightly fretful</mat-option>
            <mat-option value="Happy">Happy</mat-option>
          </mat-select>
          <mat-error *ngIf="howHasYourChildsMoodBeen.touched"> Please select an option </mat-error>
        </mat-form-field>
      </div>

      <!--  QUESTION FOUR -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Over the last week approximately how much <strong>time</strong> on average has it taken
            <strong>to get your child off to sleep</strong> each night?
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="howLongHasItTakenToGetYourChildToSleep">
            <mat-option value="More than 2 hours">More than 2 hours</mat-option>
            <mat-option value="1 - 2 hours">1 - 2 hours</mat-option>
            <mat-option value="15 mins - 1 hour">15 mins - 1 hour</mat-option>
            <mat-option value="0 - 15 mins">0 - 15 mins</mat-option>
          </mat-select>
          <mat-error *ngIf="howLongHasItTakenToGetYourChildToSleep.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <!--  QUESTION FIVE -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Over the last week, what was the <strong>total time</strong> that your child's
            <strong>sleep was disturbed</strong> on average each night?
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="averageTimeYourChildsSleepDisturbed">
            <mat-option value="5 hours or more">5 hours or more</mat-option>
            <mat-option value="3 - 4 hours">3 - 4 hours</mat-option>
            <mat-option value="1 - 2 hours">1 - 2 hours</mat-option>
            <mat-option value="less than 1 hour">less than 1 hour</mat-option>
          </mat-select>
          <mat-error *ngIf="averageTimeYourChildsSleepDisturbed.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <!--  QUESTION SIX -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Over the last week, has your child's eczema interfered with
            <strong>playing or swimming</strong>?
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="hasYourChildsEczemaInterferedWithPlayingOrSwimming">
            <mat-option [value]="option" *ngFor="let option of infantsAnswerList">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="hasYourChildsEczemaInterferedWithPlayingOrSwimming.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <!--  QUESTION SEVEN -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Over the last week, has your child's eczema interfered with your child
            <strong>taking part in</strong> or <strong>enjoying other family activities</strong>?
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="hasYourChildsEczemaInterferedWithFamilyActivities">
            <mat-option [value]="option" *ngFor="let option of infantsAnswerList">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="hasYourChildsEczemaInterferedWithFamilyActivities.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <!--  QUESTION EIGHT -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Over the last week, have there been problems with your child at
            <strong>mealtimes</strong> because of the eczema?
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="hasYourChildsEczemaCausedProblemsAtMealtimes">
            <mat-option [value]="option" *ngFor="let option of infantsAnswerList">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="hasYourChildsEczemaCausedProblemsAtMealtimes.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <!--  QUESTION NINE -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Over the last week, have there been problems with your child caused by the
            <strong>treatment</strong>?
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="hasYourChildsEczemaTreatmentCausedProblems">
            <mat-option [value]="option" *ngFor="let option of infantsAnswerList">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="hasYourChildsEczemaTreatmentCausedProblems.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <!--  QUESTION TEN -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Over the last week, has your child's eczema meant that
            <strong>dressing and undressing</strong> the child has been
            <strong>uncomfortable</strong>?
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="hasYourChildsEczemaUncomfortWhenDressingAndUndressing">
            <mat-option [value]="option" *ngFor="let option of infantsAnswerList">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="hasYourChildsEczemaUncomfortWhenDressingAndUndressing.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <!--  QUESTION ELEVEN -->
      <div class="question-container">
        <div class="half-width-field">
          <p>
            Over the last week how much has your child having eczema been a problem at
            <strong>bathtime</strong>?
          </p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label> Please select an option </mat-label>
          <mat-select formControlName="hasYourChildsEczemaCausedProblemsAtBathtime">
            <mat-option [value]="option" *ngFor="let option of infantsAnswerList">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="hasYourChildsEczemaCausedProblemsAtBathtime.touched">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>

      <div class="button-group">
        <button
          class="positive-button"
          mat-raised-button
          color="accent"
          type="submit"
          [disabled]="!infantDlqiFormValid()"
        >
          {{ isSubmitting ? 'Submitting...' : 'Submit' }}
        </button>
      </div>
    </div>
  </form>
</ng-container>
