import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-lothian-patient-response-form',
  templateUrl: './lothian-patient-response-form.component.html',
  styleUrls: ['./lothian-patient-response-form.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
})
export class LothianPatientResponseFormComponent implements OnInit {
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  lothianForm: UntypedFormGroup;
  isSubmitting = false;

  ngOnInit(): void {
    this.lothianForm = this.fb.group({
      woundBeenPainfulToTouch: ['', Validators.required],
      howHasThePainBeen: [''],
      liquidComingFromTheSite: ['', Validators.required],
      fluidLeakingOverThePastDay: [''],
      colourAndConsistencyOfTheFluid: [''],
      rednessSpreadingAwayFromWound: ['', Validators.required],
      rednessStatus: [''],
      hasTheAreaAroundTheWoundBecomeSwollen: ['', Validators.required],
      hasTheAreaAroundTheWoundBecomeSwollenDetails: [''],
      hasTheWoundedAreaBecomeWarmer: ['', Validators.required],
      hasTheWoundedAreaBecomeWarmerDetails: [''],
      hasAnyPartSeparatedOrGaped: ['', Validators.required],
      hasTheWoundOpenedMoreDetails: [''],
      hasTheDeeperTissueSeparated: [''],
      haveYouHadAFever: ['', Validators.required],
      haveYouBeenDiagnosedWithAnInfectionSinceSurgery: [
        '',
        Validators.required,
      ],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.lothianForm.controls[key])
          this.lothianForm.controls[key].setValue(formData[key]);
      });
    });
  }

  get woundBeenPainfulToTouch() {
    return this.lothianForm.get('woundBeenPainfulToTouch');
  }
  get howHasThePainBeen() {
    return this.lothianForm.get('howHasThePainBeen');
  }
  get liquidComingFromTheSite() {
    return this.lothianForm.get('liquidComingFromTheSite');
  }
  get fluidLeakingOverThePastDay() {
    return this.lothianForm.get('fluidLeakingOverThePastDay');
  }
  get colourAndConsistencyOfTheFluid() {
    return this.lothianForm.get('colourAndConsistencyOfTheFluid');
  }
  get rednessSpreadingAwayFromWound() {
    return this.lothianForm.get('rednessSpreadingAwayFromWound');
  }
  get rednessStatus() {
    return this.lothianForm.get('rednessStatus');
  }
  get hasTheAreaAroundTheWoundBecomeSwollen() {
    return this.lothianForm.get('hasTheAreaAroundTheWoundBecomeSwollen');
  }
  get hasTheAreaAroundTheWoundBecomeSwollenDetails() {
    return this.lothianForm.get('hasTheAreaAroundTheWoundBecomeSwollenDetails');
  }
  get hasTheWoundedAreaBecomeWarmer() {
    return this.lothianForm.get('hasTheWoundedAreaBecomeWarmer');
  }
  get hasTheWoundedAreaBecomeWarmerDetails() {
    return this.lothianForm.get('hasTheWoundedAreaBecomeWarmerDetails');
  }
  get hasAnyPartSeparatedOrGaped() {
    return this.lothianForm.get('hasAnyPartSeparatedOrGaped');
  }
  get hasTheWoundOpenedMoreDetails() {
    return this.lothianForm.get('hasTheWoundOpenedMoreDetails');
  }
  get hasTheDeeperTissueSeparated() {
    return this.lothianForm.get('hasTheDeeperTissueSeparated');
  }
  get haveYouHadAFever() {
    return this.lothianForm.get('haveYouHadAFever');
  }
  get haveYouBeenDiagnosedWithAnInfectionSinceSurgery() {
    return this.lothianForm.get(
      'haveYouBeenDiagnosedWithAnInfectionSinceSurgery'
    );
  }

  woundPainfulValidation(value) {
    if (value === 'Yes') {
      this.lothianForm
        .get('howHasThePainBeen')
        .setValidators(Validators.required);
    } else {
      this.lothianForm.get('howHasThePainBeen').clearValidators();
      this.lothianForm.get('howHasThePainBeen').reset('');
    }
  }

  liquidFromSiteValidation(value) {
    if (value === 'Yes') {
      this.lothianForm
        .get('fluidLeakingOverThePastDay')
        .setValidators(Validators.required);
      this.lothianForm
        .get('colourAndConsistencyOfTheFluid')
        .setValidators(Validators.required);
    } else {
      this.lothianForm.get('fluidLeakingOverThePastDay').clearValidators();
      this.lothianForm.get('fluidLeakingOverThePastDay').reset('');
      this.lothianForm.get('colourAndConsistencyOfTheFluid').clearValidators();
      this.lothianForm.get('colourAndConsistencyOfTheFluid').reset('');
    }
  }

  rednessSpreadingValidation(value) {
    if (value === 'Yes') {
      this.lothianForm.get('rednessStatus').setValidators(Validators.required);
    } else {
      this.lothianForm.get('rednessStatus').clearValidators();
      this.lothianForm.get('rednessStatus').reset('');
    }
  }

  woundAreaSwollenValidation(value) {
    if (value === 'Yes') {
      this.lothianForm
        .get('hasTheAreaAroundTheWoundBecomeSwollenDetails')
        .setValidators(Validators.required);
    } else {
      this.lothianForm
        .get('hasTheAreaAroundTheWoundBecomeSwollenDetails')
        .clearValidators();
      this.lothianForm
        .get('hasTheAreaAroundTheWoundBecomeSwollenDetails')
        .reset('');
    }
  }

  woundAreaWarmerValidation(value) {
    if (value === 'Yes') {
      this.lothianForm
        .get('hasTheWoundedAreaBecomeWarmerDetails')
        .setValidators(Validators.required);
    } else {
      this.lothianForm
        .get('hasTheWoundedAreaBecomeWarmerDetails')
        .clearValidators();
      this.lothianForm.get('hasTheWoundedAreaBecomeWarmerDetails').reset('');
    }
  }

  woundSeparatedValidation(value) {
    if (value === 'Yes') {
      this.lothianForm
        .get('hasTheWoundOpenedMoreDetails')
        .setValidators(Validators.required);
      this.lothianForm
        .get('hasTheDeeperTissueSeparated')
        .setValidators(Validators.required);
    } else {
      this.lothianForm.get('hasTheWoundOpenedMoreDetails').clearValidators();
      this.lothianForm.get('hasTheWoundOpenedMoreDetails').reset('');
      this.lothianForm.get('hasTheDeeperTissueSeparated').clearValidators();
      this.lothianForm.get('hasTheDeeperTissueSeparated').reset('');
    }
  }

  onSubmit() {
    this.isSubmitting = true;
    const formResponse = {
      ...this.lothianForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  lothianFormValid(): boolean {
    return (
      this.lothianForm.dirty &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['woundBeenPainfulToTouch'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['howHasThePainBeen'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['liquidComingFromTheSite'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['fluidLeakingOverThePastDay'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['colourAndConsistencyOfTheFluid'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['rednessSpreadingAwayFromWound'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['rednessStatus'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['hasTheAreaAroundTheWoundBecomeSwollen']
          .errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls[
          'hasTheAreaAroundTheWoundBecomeSwollenDetails'
        ].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['hasTheWoundedAreaBecomeWarmer'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['hasTheWoundedAreaBecomeWarmerDetails'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['hasAnyPartSeparatedOrGaped'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['hasTheWoundOpenedMoreDetails'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['hasTheDeeperTissueSeparated'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls['haveYouHadAFever'].errors
      ) &&
      !(
        this.lothianForm.controls &&
        this.lothianForm.controls[
          'haveYouBeenDiagnosedWithAnInfectionSinceSurgery'
        ].errors
      )
    );
  }
}
