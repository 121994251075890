import { Component } from '@angular/core';
import { CollectionWithId, EntryWithId } from '@islacare/ic-types';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TeamsService } from '../../../../services/teams/teams.service';
import { DialogData } from '../../../../shared/components/dialogs/dialog-data';
import { RdPatientRecordDialogsService } from '../../services/rd-patient-record-dialogs/rd-patient-record-dialogs.service';
export interface MoveEntriesData extends DialogData {
  entries: EntryWithId[];
  baseFolder: string;
  destFolder: CollectionWithId;
  baseTeam: string;
}

@Component({
  selector: 'ic-move-entries-confirmation-dialog',
  templateUrl: './rd-move-entries-confirmation-dialog.component.html',
  styleUrls: ['./rd-move-entries-confirmation-dialog.component.scss']
})
export class MoveEntriesConfirmationDialogComponent {
  data: MoveEntriesData;
  targetTeam$: Observable<string>;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private teamsService: TeamsService,
    private patientRecordService: RdPatientRecordDialogsService
  ) {
    this.data = config.data;
  }
  async ngOnInit(): Promise<void> {
    this.targetTeam$ = this.teamsService
      .getTeamFunction$(this.data?.destFolder.teamId)
      .pipe(map(team => team.name));
  }

  async moveEntriesBetweenFolders() {
    this.patientRecordService.moveEntries(this.data);

    this.ref.close();
  }
  closeDialog() {
    this.ref.close();
  }
}
