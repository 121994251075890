import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'scoreKeyText'
})
export class ScoreKeyTextPipe implements PipeTransform {
  transform(scoreKey: string[]): string {
    if (!scoreKey) {
      return '';
    }

    const newScoreText = scoreKey.map((item: string) => this.getLabel(item));

    return newScoreText.join(', ');
  }

  private getLabel(item: string) {
    switch (item) {
      case 'CASScore':
        return 'CAS score';
      case 'appearanceScore':
        return 'Appearance score';
      case 'limitationScore':
        return 'Limitation score';
      default:
        return item;
    }
  }
}
