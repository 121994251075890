import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  AccessRequest,
  AdditionalEmailAddresses,
  AdditionalPhoneNumbers,
  AuditActionEnum,
  CaptureType,
  Collection,
  CollectionWithId,
  EntryWithId,
  FormWithId,
  Label,
  LabelGroup,
  LabelGroupAppliesToEnum,
  LabelGroupTypeEnum,
  LabelObject,
  MessageMedium,
  MessageType,
  MultiCollection,
  Patient,
  PatientWithId,
  Request,
  UserWithId,
} from '@islacare/ic-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from 'apps/frontend/portal/src/environments/environment';
import { saveAs } from 'file-saver';
import firebase from 'firebase/compat/app';
import { cloneDeep, intersection } from 'lodash-es';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, EMPTY, NEVER, Observable, combineLatest, forkJoin, from, of } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { EnhancedScheduleService } from '../../../feature-schedules/services/enhanced-schedule/enhanced-schedule.service';
import { AccessRequestsService } from '../../../services/access-requests/access-requests.service';
import { AuditService } from '../../../services/audit/audit.service';
import { CollectionsService } from '../../../services/collections/collections.service';
import { EntriesService } from '../../../services/entries/entries.service';
import { OrganisationFeatureToggleService } from '../../../services/organisation-feature-toggle/organisation-feature-toggle.service';
import { PdfGeneratorService } from '../../../services/pdf-generator/pdf-generator.service';
import { ResponsesService } from '../../../services/responses.service';
import { TeamsService } from '../../../services/teams/teams.service';
import { ConfirmDialogComponent } from '../../../shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { DataEntryDialogComponent } from '../../../shared/components/dialogs/data-entry-dialog/data-entry-dialog.component';
import { RequestDialogComponent } from '../../../shared/components/dialogs/request-dialog/request-dialog.component';
import { FormService } from '../../../shared/services/form/form.service';
import { LoggingService } from '../../../shared/services/logging/logging.service';
import { PatientsService } from '../../../shared/services/patients/patients.service';
import { UsersService } from '../../../shared/services/users/users.service';
import { CollectionEntryReviewComponent } from '../../dialogs/collection-entry-review/collection-entry-review.component';
import { CollectionReadOnlyAccessComponent } from '../../dialogs/collection-read-only-access/collection-read-only-access.component';
import { InvalidContactsRequestDialogComponent } from '../../dialogs/invalid-contacts-request-dialog/invalid-contacts-request-dialog.component';
import { MlFeaturesDetectedDialogComponent } from '../../dialogs/ml-features-detected-dialog/ml-features-detected-dialog.component';
import { OriginalImageDialogComponent } from '../../dialogs/original-image-dialog/original-image-dialog.component';
import { PatientResponseDialogComponent } from '../../dialogs/patient-response-dialog/patient-response-dialog.component';
import { PdfViewerComponent } from '../../dialogs/pdf-viewer/pdf-viewer.component';
import { SubmissionDialogComponent } from '../../dialogs/submission-dialog/submission-dialog.component';
import { EmailService } from '../email/email.service';
import { OutcomeService } from '../outcome/outcome.service';
import { SmsService } from '../sms/sms.service';

interface CaptureTypeOptions {
  label: string;
  value: CaptureType;
  icon: string;
}

export interface LabelGroupTeam {
  name: string;
  teamId: string;
  overlayVisible: boolean;
  labelGroups: {
    [key: string]: LabelGroup;
  };
  patientLabels: {
    [prefix: string]: {
      [labelGroupMapKey: string]: string[];
    };
  };
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class PatientRecordService {
  private _patientId$ = new BehaviorSubject<string>('');
  private _collections$ = new BehaviorSubject<CollectionWithId[]>(null);
  private _currentCollectionId$ = new BehaviorSubject<string>('');
  primeDynamicDialogRef: DynamicDialogRef;
  maxEntriesForComparison = 3;

  createdBy: string;
  createdForOrg: string;

  constructor(
    private collectionsService: CollectionsService,
    private usersService: UsersService,
    private entriesService: EntriesService,
    private dialog: MatDialog,
    private teamsService: TeamsService,
    private accessRequestsService: AccessRequestsService,
    private db: AngularFirestore,
    private patientsService: PatientsService,
    private smsService: SmsService,
    private emailService: EmailService,
    private router: Router,
    private responsesService: ResponsesService,
    private audit: AuditService,
    private bottomSheetRef: MatBottomSheet,
    private pdfGeneratorService: PdfGeneratorService,
    private formService: FormService,
    private loggingService: LoggingService,
    private outcomeService: OutcomeService,
    private dialogService: DialogService,
    private scheduleService: EnhancedScheduleService,
    private orgFeatureToggle: OrganisationFeatureToggleService,
    private auditService: AuditService,
  ) {}

  getSelectedPatient(patientId: string): void {
    this._patientId$.next(patientId);
  }

  patient$(): Observable<PatientWithId> {
    return this._patientId$.asObservable().pipe(
      filter(patientId => !!patientId),
      distinctUntilChanged(),
      switchMap(patientId => this.patientsService.getPatient$(patientId)),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  collections$(): Observable<CollectionWithId[]> {
    return this.patient$().pipe(
      switchMap(patient => this.getPatientCollections$(patient.id)),
      tap(collections => this._collections$.next(collections)),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  currentCollection$(): Observable<CollectionWithId> {
    return this._currentCollectionId$.asObservable().pipe(
      switchMap(collectionId => this.getCurrentCollection$(collectionId).pipe(shareReplay({ refCount: true }))),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  getEntries$(collection: CollectionWithId, patientId: string, teamIds: string[]): Observable<EntryWithId[]> {
    if (!collection || collection?.isLocked || !patientId || !teamIds?.length) {
      return of([]);
    }

    return this.entriesService.getEntries(patientId, collection.id).pipe(
      map(entries =>
        entries.map(entry => {
          const isEntryRequiresAttention =
            !entry.deleted && entry.requiresAttention && teamIds.indexOf(entry.entryTeamId) > -1;
          return { ...entry, isEntryRequiresAttention };
        }),
      ),
    );
  }

  updateCurrentCollection(collectionId): void {
    this._currentCollectionId$.next(collectionId);
  }

  checkForMultiCollection(entries: EntryWithId[]): boolean {
    const collectionIdsArray: string[] = entries.map(entry => entry.collectionId);
    const tempSet = new Set(collectionIdsArray);
    return tempSet.size !== 1;
  }

  private getCurrentCollection$(collectionId?: string): Observable<CollectionWithId> {
    return this.collections$().pipe(
      map(collections => (collections ? collections : [])),
      map(collections => collections.find(collection => collection.id === collectionId) || null),
    );
  }

  getPatientCollections$(patientId: string): Observable<CollectionWithId[]> {
    return combineLatest([this.collectionsService.getSortedCollections(patientId), this.usersService.user$]).pipe(
      map(([collections, user]) =>
        collections.map(collection => this.setIsLockedAndRequiresAttentionCount(collection, user)),
      ),
      distinctUntilChanged(),
      shareReplay({ refCount: true }),
    );
  }

  updateEntryOrderInCollection$(patientId: string, collectionId: string, entries: EntryWithId[]): Observable<void[]> {
    return forkJoin(
      entries.map((entry, index) =>
        from(this.entriesService.updateEntryOrderIndex(patientId, collectionId, entry.id, index)),
      ),
    );
  }

  requestReadOnlyAccessOnCollection$(collection: CollectionWithId, patientId: string): Observable<void> {
    let userId = '';

    return this.usersService.user$.pipe(
      take(1),
      map(user => {
        if (user) return user;
        throw new Error('User not signed in');
      }),
      tap(user => (userId = user.id)),
      switchMap(user =>
        forkJoin(user?.teamIds.map(teamId => this.teamsService.getTeam$(teamId).pipe(take(1)))).pipe(
          map(teams => teams.filter(team => !team?.isPersonal)),
        ),
      ),
      switchMap(teamsList => {
        const dialogRef = this.dialogService.open(CollectionReadOnlyAccessComponent, {
          header: 'Requesting read-only access',
          width: '80%',
          data: {
            message: `Would you like to request read-only access for this collection - ${collection.name}? If yes, please select which of your teams should have read-only access, and provide a reason for requiring the access.`,
            positiveButton: 'Request access',
            negativeButton: 'Back',
            teamsList: teamsList,
          },
        });
        return dialogRef.onClose;
      }),
      filter(result => !!result && !!userId),
      switchMap(result => {
        const accessRequest: AccessRequest = {
          userId: userId,
          patientId: patientId,
          collectionId: collection.id,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          accessReason:
            'I confirm I have a reason to access this patient’s information pertaining to direct care of the patient.',
          teamId: result?.teamId,
        };
        return from(this.accessRequestsService.saveReadOnlyAccessRequest(accessRequest));
      }),
    );
  }

  // Collection responses service
  addCommentToCollection$(patientId: string, collectionId: string) {
    const dialogRef = this.dialogService.open(DataEntryDialogComponent, {
      header: 'Add a comment',
      styleClass: 'interactable-overlay',
      position: 'bottom-left',
      width: '25rem',
      data: {
        message: 'The comment will be saved against this collection and viewable by all colleagues',
        positiveButton: 'Comment',
        negativeButton: 'Cancel',
      },
    });
    return dialogRef.onClose.pipe(
      take(1),
      filter(result => !!result),
      switchMap(result =>
        forkJoin([
          from(this.responsesService.save(patientId, collectionId, result, false, null, '')),
          from(this.audit.addCommentToCollection(patientId, collectionId)),
        ]),
      ),
    );
  }

  sendMessageToPatient$(patientId: string, collectionId: string, collectionTeamId: string): Observable<void> {
    return combineLatest([this.patient$(), this.usersService.isEmailEnabledForUserOrganisation$()]).pipe(
      take(1),
      switchMap(([patient, isEmailEnabled]) =>
        patient?.patientContactConsentWithdrawn
          ? this.promptWithdrawnConsent$()
          : this.openMessagePatientViaSMSOrEmailDialog$(
              patientId,
              collectionId,
              patient.additionalPhoneNumbers,
              patient?.additionalEmailAddresses || {},
              isEmailEnabled,
              patient.phone,
              patient.email,
              patient.automatedCreation,
              collectionTeamId,
            ),
      ),
    );
  }

  //Entries service
  markAllEntriesAsReviewed$(patientId: string, collectionId?: string): Observable<[void, void]> {
    return this.usersService.user$.pipe(
      take(1),
      map(user => {
        if (user) return user;
        throw new Error('User not signed in');
      }),
      switchMap(user => from(this.entriesService.getMyTeamEntriesWithRequiresAttentionTrue(patientId, user?.teamIds))),
      map(allEntries => (collectionId ? allEntries.filter(entry => entry.collectionId === collectionId) : allEntries)),
      switchMap(allEntries => {
        const collectionCount = new Set(allEntries.map(entry => entry.collectionId)).size;
        const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
          header: 'Review confirmation',
          width: '50%',
          data: {
            message: `Confirm review of ${allEntries?.length} new ${
              allEntries?.length > 1 ? 'entries' : 'entry'
            } on ${collectionCount} ${
              collectionCount > 1 ? 'collections' : 'collection'
            }. This will remove the alert(s) for all members of your clinical team.`,
            positiveButton: 'Confirm',
            negativeButton: 'Back',
          },
        });
        return dialogRef.onClose.pipe(
          take(1),
          filter(result => !!result),
          map(() => allEntries),
        );
      }),
      switchMap(allEntries =>
        forkJoin([
          from(this.entriesService.setRequiresAttentionFalseOnEntries(patientId, allEntries)),
          from(
            this.patientsService.setRequiresAttentionObjectOnPatient(
              patientId,
              allEntries.map(entry => entry.entryTeamId),
            ),
          ),
        ]),
      ),
    );
  }

  markEntryAsReviewed$(patientId: string, entry: EntryWithId): Observable<[void, void, void]> {
    return forkJoin([
      from(this.outcomeService.rdRecordOutcomes$(entry.collectionId, patientId, 'add', entry.entryTeamId)),
      from(this.entriesService.setRequiresAttentionFalseOnEntries(patientId, [entry])),
      from(this.patientsService.setRequiresAttentionObjectOnPatient(patientId, [entry.entryTeamId])),
    ]);
  }

  markEntryForReview$(patientId: string, entry: EntryWithId): Observable<[void, void]> {
    return forkJoin([
      from(this.entriesService.setRequiresAttentionTrueOnEntries(patientId, [entry])),
      from(this.patientsService.setRequiresAttentionObjectOnPatient(patientId, [entry.entryTeamId])),
    ]);
  }

  // Break out into submissions service
  scheduleSubmissions(collection: CollectionWithId, patient: PatientWithId): void {
    this.scheduleService.openScheduleDialog(collection, patient);
  }

  createSubmission$(collection: CollectionWithId, patient: PatientWithId): Observable<any> {
    this.primeDynamicDialogRef = this.dialogService.open(SubmissionDialogComponent, {
      header: 'Confirm submission details',
      width: '70%',
      data: {
        collection: collection,
      },
    });
    return this.primeDynamicDialogRef.onClose.pipe(
      take(1),
      filter(result => !!result),
      map(result => ({
        capture: result.capture,
        alertEmail: result.alertEmail,
        formIds: result.formIds,
        teamId: result?.teamId,
      })),
      switchMap(submission =>
        this.updateCollectionDocument$(patient.id, collection.id, {
          submission,
        }).pipe(map(() => submission.capture)),
      ),
    );
  }

  requestSubmission$(collection: CollectionWithId, patient: PatientWithId): Observable<void> {
    return this.usersService.user$.pipe(
      take(1),
      map((user: UserWithId) => {
        if (user) return user;
        throw new Error('User not signed in');
      }),
      tap((user: UserWithId) => {
        this.createdBy = user?.id;
        this.createdForOrg = collection?.organisationId;
      }),
      switchMap(() => {
        const dialogRef = this.dialogService.open(RequestDialogComponent, {
          header: 'Confirm request details',
          width: '80%',
          data: {
            collection: collection,
            patient: patient,
            createdBy: this.createdBy,
            createdForOrg: collection.organisationId,
          },
          style: { 'min-height': '560px' },
        });
        return dialogRef.onClose;
      }),
      take(1),
      switchMap(result => {
        if (!result) return EMPTY;

        const request = {
          capture: result.capture,
          smsBody: result.smsBody,
          formIds: result.formIds,
          teamId: result?.teamId,
          alertEmail: result.alertEmail,
          keepLinkSevenDays: result.keepLinkSevenDays,
          collectionAuthId: result.collectionAuthId,
          requestId: result.requestId,
          contactMethod: result?.contactMethod || MessageMedium.SMS, // default to SMS
          messageType: MessageType.ORIGINAL,
          isMediaOptional: result.isMediaOptional,
        } as Request;

        const updateCollectionDoc$ = this.updateCollectionDocument$(patient.id, collection.id, {
          request: {
            capture: request.capture,
            smsBody: request.smsBody,
            formIds: request.formIds,
            teamId: request?.teamId,
            alertEmail: request.alertEmail,
            keepLinkSevenDays: request.keepLinkSevenDays,
            contactMethod: request?.contactMethod || MessageMedium.SMS, // default to SMS
            isMediaOptional: request.isMediaOptional,
          },
        });

        return updateCollectionDoc$.pipe(
          switchMap(() => this.generateCollectionAuthUrlAndSendRequest$(request, patient, collection)),
        );
      }),
    );
  }

  generateCollectionAuthUrlAndSendRequest$(
    requestObj: any,
    patient: PatientWithId,
    collection: CollectionWithId,
  ): Observable<void> {
    const requestUrl =
      requestObj?.requestUrl || `${environment.url}/entry/new/identity?requestId=${requestObj.requestId}`;
    const patientDetails = {
      patientId: patient.id,
      patientFirstName: patient.firstName,
      patientPhone: patient.phone,
      additionalPhoneNumbers: patient.additionalPhoneNumbers as AdditionalPhoneNumbers,
      additionalEmailAddresses: patient?.additionalEmailAddresses || ({} as AdditionalEmailAddresses),
      patientYearOfBirth: patient.dateOfBirth.substring(0, 4),
      patientEmail: patient.email,
    };
    if (requestObj.messageMedium) {
      switch (requestObj.messageMedium) {
        case MessageMedium.EMAIL:
          return this.requestSubmissionViaEmail$(requestUrl, requestObj, collection.id, patientDetails);
        case MessageMedium.SMS:
        default:
          return this.requestSubmissionViaSMS$(requestUrl, requestObj, collection.id, patientDetails);
      }
    } else {
      switch (requestObj.contactMethod) {
        case MessageMedium.EMAIL:
          return this.requestSubmissionViaEmail$(requestUrl, requestObj, collection.id, patientDetails);
        case MessageMedium.SMS:
        default:
          return this.requestSubmissionViaSMS$(requestUrl, requestObj, collection.id, patientDetails);
      }
    }
  }

  private requestSubmissionViaSMS$(
    collectionAuthUrl: string,
    result: any,
    collectionId: string,
    patientDetails: {
      patientId: string;
      patientFirstName: string;
      patientPhone: string;
      additionalPhoneNumbers: AdditionalPhoneNumbers;
      additionalEmailAddresses: AdditionalEmailAddresses;
      patientYearOfBirth: string;
      patientEmail: string;
    },
  ): Observable<void> {
    return from(
      this.smsService.openSmsDialog(
        collectionAuthUrl,
        result.collectionAuthId,
        result,
        collectionId,
        null,
        patientDetails,
        this.createdBy || result?.createdBy,
        this.createdForOrg || result?.createdForOrg,
        result.capture,
        result.requestId,
        result?.disableRecipient,
        result?.hideReminderCheckbox,
        result?.messageType,
        result?.isMediaOptional,
      ),
    );
  }

  private requestSubmissionViaEmail$(
    collectionAuthUrl: string,
    result: any,
    collectionId: string,
    patientDetails: {
      patientId: string;
      patientFirstName: string;
      patientPhone: string;
      additionalPhoneNumbers: AdditionalPhoneNumbers;
      additionalEmailAddresses: AdditionalEmailAddresses;
      patientYearOfBirth: string;
      patientEmail: string;
    },
  ): Observable<void> {
    if (!patientDetails.patientEmail && Object.keys(patientDetails?.additionalEmailAddresses).length === 0) {
      const dialogRef = this.dialogService.open(InvalidContactsRequestDialogComponent, {
        width: '80%',
        header: 'Email address unavailable',
      });
      dialogRef.onClose.subscribe(result => {
        if (result === 'edit-patient') {
          this.router.navigate([`/patient/${patientDetails.patientId}/edit`]);
        }
      });
      return EMPTY;
    } else {
      return from(
        this.emailService.openEmailDialog(
          collectionAuthUrl,
          result.collectionAuthId,
          result,
          collectionId,
          null,
          patientDetails,
          this.createdBy || result?.createdBy,
          this.createdForOrg || result?.createdForOrg,
          result.capture,
          result.requestId,
          result?.messageType,
          result?.isMediaOptional,
        ),
      );
    }
  }

  private updateCollectionDocument$(patientId: string, collectionId: string, update: any): Observable<void> {
    const collectionDocPath = `patients/${patientId}/collections/${collectionId}`;
    return from(this.db.doc(collectionDocPath).update(update));
  }

  private setIsLockedAndRequiresAttentionCount(collection: CollectionWithId, user: UserWithId): CollectionWithId {
    return {
      ...collection,
      requiresAttentionCount: this.getRequiresAttentionCount(collection, user),
      isReadOnly: collection && user && this.collectionsService.isCollectionReadOnly(collection, user),
      isLocked: collection && user && this.collectionsService.isCollectionLocked(collection, user),
      isUserInSameTeam: user?.teamIds.includes(collection?.teamId),
    };
  }

  private getRequiresAttentionCount(collection: CollectionWithId, user: UserWithId): number {
    if (!collection?.requiresAttentionMap) return 0;

    const myTeamIds = intersection(Object.keys(collection.requiresAttentionMap), user?.teamIds);

    return myTeamIds.reduce((total, teamId) => total + collection.requiresAttentionMap[teamId], 0);
  }

  private promptWithdrawnConsent$(): Observable<never> {
    this.dialogService.open(ConfirmDialogComponent, {
      header: 'Consent withheld',
      width: '80%',
      data: {
        message: 'Unfortunately, the patient has withdrawn their consent to contact them through this service.',
        positiveButton: 'Understood',
      },
    });

    return EMPTY;
  }

  private openMessagePatientViaSMSOrEmailDialog$(
    patientId: string,
    collectionId: string,
    additionalPhoneNumbers: any,
    additionalEmailAddresses: any,
    emailEnabled: any,
    patientPhoneNumber,
    patientEmail,
    patientAutomatedCreation,
    collectionTeamId,
  ): Observable<void> {
    const dialogRef = this.dialogService.open(PatientResponseDialogComponent, {
      header: 'Message Patient',
      styleClass: 'interactable-overlay',
      position: 'bottom-left',
      width: '25rem',
      data: {
        type: 'data-select',
        message:
          'The response will be saved against this collection and sent to the patient via the selected method. Note: The patient will not be able to reply to this message.',
        positiveButton: 'Send',
        negativeButton: 'Cancel',
        additionalPhoneNumbers: additionalPhoneNumbers,
        additionalEmailAddresses: additionalEmailAddresses,
        emailFunctionalityEnabled: emailEnabled,
        patientPhoneNumber,
        patientEmail,
        automatedCreation: patientAutomatedCreation,
        collectionTeamId,
        patientId,
      },
    });
    return dialogRef.onClose.pipe(
      take(1),
      tap(result => {
        if (!result) return NEVER;
        this.audit.responseSent(patientId, collectionTeamId, result.contactMethod);
      }),
      filter(result => !!result),
      switchMap(result =>
        from(
          this.responsesService.save(
            patientId,
            collectionId,
            result.response,
            true,
            result.recipient,
            result.contactMethod,
          ),
        ),
      ),
    ) as Observable<void>;
  }

  openEntriesComparisonDialog(
    collection: CollectionWithId,
    entries: EntryWithId[],
    patient: PatientWithId,
    allEntries?: EntryWithId[],
    currentIndex?: number,
  ): DynamicDialogRef {
    this.primeDynamicDialogRef = this.dialogService.open(CollectionEntryReviewComponent, {
      height: '95%',
      width: '95%',
      header: `${collection.name}`,
      baseZIndex: 999999,
      data: {
        collection: collection,
        entries: entries,
        patient: patient,
        allEntries: allEntries,
        currentIndex: currentIndex,
      },
    });
    return this.primeDynamicDialogRef;
  }

  dismissBottomSheet(): void {
    this.bottomSheetRef && this.bottomSheetRef.dismiss();
  }

  dismissDialog(): void {
    this.dialog && this.dialog.closeAll();
  }

  async downloadFileFromEntry(entry: EntryWithId, patient?: Patient, collection?: Collection): Promise<void> {
    const storageRef = firebase.storage().ref(entry.imagePath);
    const downloadedPath = await storageRef.getDownloadURL();
    await this.downloadFile(downloadedPath, entry, entry.fileType.includes('e2e'), patient, collection);
  }

  async downloadFile(
    filePath: string,
    entry: EntryWithId,
    isE2E: boolean,
    patient?: Patient,
    collection?: Collection,
  ): Promise<void> {
    let patientSnapshot: Patient;
    let collectionSnapshot: Collection;

    if (!patient) patientSnapshot = await (await this.patientsService.getPatientSnapshot(entry.patientId)).data();
    else patientSnapshot = patient;

    if (!collection)
      collectionSnapshot = await (
        await this.collectionsService.getCollectionDoc(entry.patientId, entry.collectionId)
      ).data();
    else collectionSnapshot = collection;

    const fileName = `${patientSnapshot.firstName} ${patientSnapshot.lastName}-${
      collectionSnapshot.name
    }-${new Date().toLocaleDateString()}.${isE2E ? 'e2e' : 'pdf'}`;

    saveAs(filePath, fileName);
    return;
  }

  openPdfGenerateDialog(
    collection: CollectionWithId,
    entries: EntryWithId[],
    patient: PatientWithId,
    withComments: boolean,
    titles: any,
    isEmailView = false,
    hasSections?: Record<string, boolean>,
  ): DynamicDialogRef {
    this.primeDynamicDialogRef = this.dialogService.open(PdfViewerComponent, {
      height: '95%',
      width: '95%',
      baseZIndex: 999999,
      data: {
        collection: collection,
        entries: entries,
        patient: patient,
        withComments: withComments,
        titles: titles,
        hasSections,
        isEmailView: isEmailView,
      },
    });
    return this.primeDynamicDialogRef;
  }

  async createEntriesImagesAsPdf(
    patientId: string,
    collectionId: string,
    entries: EntryWithId[],
    withCommentsFlag: boolean,
    isPasswordProtected: boolean,
    pdfPassword: number,
    titles: any,
    formData: FormWithId[],
    multiFlag: boolean,
    hasSections?: Record<string, boolean>,
  ) {
    const entryIdsArray = entries.map(entry => entry?.id);
    const formIds = [...new Set(entries.map(entry => entry?.formIds).flat())];
    const pdfFormData = await this.createFormData(formIds, formData);
    let tempMultiCollection: MultiCollection[];
    if (multiFlag) {
      tempMultiCollection = [];
      for (const item of entries) {
        const currentFormData = await this.createFormData(item.formIds, formData);
        const currentCollection: MultiCollection = {
          patientId,
          collectionId: item.collectionId,
          entryIdArray: [item?.id],
          formData: currentFormData,
          withCommentsFlag,
          titles: titles,
        };
        tempMultiCollection.push(currentCollection);
      }
    }
    try {
      const pdfGeneratorResult = await this.pdfGeneratorService.generatePdf(
        patientId,
        collectionId,
        entryIdsArray,
        pdfFormData,
        withCommentsFlag,
        isPasswordProtected,
        pdfPassword,
        titles,
        false,
        null,
        hasSections,
        tempMultiCollection,
      );

      if (pdfGeneratorResult instanceof Error) {
        pdfGeneratorResult['error'] = true;
      }
      return { pdfData: pdfGeneratorResult, pdfFormData: pdfFormData };
    } catch (err) {
      this.loggingService.consoleError('error while downloading pdf', err);
      throw err;
    }
  }

  createFormData = async (formIds, formData) => {
    const formIdGroupedData = formData.reduce(
      async (result, obj) => {
        result[obj.id] = { id: obj.id, name: obj.name };
        return result;
      },
      {
        default: {
          id: 'default',
          name: 'Default notes form',
        },
      },
    );
    const formObj = {};
    if (formIds?.length) {
      for (let i = 0; i < formIds.length; i++) {
        const formId = formIds[i];
        if (formIdGroupedData[formId]) {
          formObj[formId] = formIdGroupedData[formId];
        } else {
          const form = await this.formService.getFormData(formId);
          formObj[formId] = {
            id: formId,
            name: form?.name || formId,
          };
        }
        formObj[formId].responseOrder =
          (await this.formService.getAutomatedFormResponseOrder(formId)) || this.formService.getResponseOrder(formId);
      }
    }
    return formObj;
  };

  openMrnDialog(): DynamicDialogRef {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      header: 'Patient requires MRN',
      width: '80%',
      data: {
        message: "Please update the patient's MRN to be able to save this PDF to the EPR",
        positiveButton: 'OK',
      },
    });
    return dialogRef;
  }

  openMLFeaturesDetectedDialog(patientId: string, collectionId: string, entryId: string) {
    const dialogRef = this.dialog.open(MlFeaturesDetectedDialogComponent, {
      maxWidth: '95vw',
      maxHeight: '95vh',
      height: '95%',
      width: '95%',
      autoFocus: false,
      data: {
        patientId: patientId,
        collectionId: collectionId,
        entryId: entryId,
      },
    });
    return dialogRef;
  }

  logEntryExportToPdfAudit(patientId: string, entryId: string, teamId: string, exportAction: string) {
    this.audit.submissionExportedAsPdf(patientId, entryId, teamId, exportAction);
  }

  openOriginalImageDialog(patient: PatientWithId, collection: CollectionWithId, entryId: string) {
    const dialogRef = this.dialog.open(OriginalImageDialogComponent, {
      maxWidth: '95vw',
      maxHeight: '95vh',
      height: '95%',
      width: '95%',
      autoFocus: false,
      data: {
        patient: patient,
        collection: collection,
        entryId: entryId,
      },
    });
    return dialogRef;
  }

  getCaptureOptions$(): Observable<CaptureTypeOptions[]> {
    const captureOptions = [
      {
        label: 'Photos',
        value: CaptureType.PHOTO,
        icon: 'pi pi-camera',
      },
      {
        label: 'Videos',
        value: CaptureType.VIDEO,
        icon: 'pi pi-video',
      },
      {
        label: 'Form only',
        value: CaptureType.FORM,
        icon: 'pi pi-file',
      },
      {
        label: 'Existing files',
        value: CaptureType.FILE_UPLOAD,
        icon: 'pi pi-upload',
      },
    ];

    const soundOption = {
      label: 'Sound recording',
      value: CaptureType.SOUND_RECORDING,
      icon: 'pi pi-volume-up',
    };

    return this.orgFeatureToggle.soundRecordingEnabled$.pipe(
      map(isSoundRecordingEnabled => {
        if (isSoundRecordingEnabled) return [...captureOptions, ...[soundOption]];
        return captureOptions;
      }),
    );
  }

  groupLabels$(patientInput?: PatientWithId): Observable<LabelGroupTeam[]> {
    const optionsForms = {};
    let patientLabels;

    // Choose the appropriate Observable for the patient
    const patientObservable = patientInput ? of(patientInput) : this.patient$();

    const getTeamsSharedWithPatient$ = combineLatest([
      this.teamsService.myTeams$,
      patientObservable.pipe(
        filter(patientFromService => {
          if (patientFromService.labels !== patientLabels && patientLabels !== undefined) {
            return false;
          }
          return true;
        }),
      ),
    ])?.pipe(
      map(([teams, patient]) => ({
        teams: teams.filter(team => patient.teamIds.includes(team.id)),
        patient,
      })),
    );

    const getTeamLabelObjects$ = (teams, patient) =>
      combineLatest(
        teams.map(team =>
          this.db
            .collection<LabelObject>(`/teams/${team.id}/labelGroupMaps`, ref =>
              ref.where('labelGroupAppliesTo', '==', LabelGroupAppliesToEnum.PATIENT),
            )
            .valueChanges()
            .pipe(
              map(
                labelObjects =>
                  ({
                    name: team.name,
                    teamId: team.id,
                    overlayVisible: Object.keys(optionsForms[team.id] ?? {}).length > 0,
                    labelGroups: this.filterArchivedOptions(labelObjects),
                    patientLabels: patient.labels,
                  }) as LabelGroupTeam,
              ),
            ),
        ) as Observable<LabelGroupTeam>[],
      );

    return getTeamsSharedWithPatient$.pipe(
      switchMap(({ teams, patient }) => getTeamLabelObjects$(teams, patient)),
      map(labelGroupTeams => {
        return labelGroupTeams.filter(
          labelGroupTeam =>
            Object.keys(labelGroupTeam.labelGroups ?? {}).length &&
            !this.teamContainsAllArchivedLabels(Object.values(labelGroupTeam.labelGroups)),
        );
      }),
      untilDestroyed(this),
    );
  }

  handleLabelFormUpdate(labelForm: UntypedFormGroup) {
    let patientLabels;
    let prevFormObject;
    combineLatest([labelForm.valueChanges, this.patient$()])
      .pipe(
        map(([formObject, patient]) => {
          if (patient.labels !== patientLabels && patientLabels !== undefined && prevFormObject === formObject) return;

          patientLabels = cloneDeep(patient.labels ?? {});
          prevFormObject = formObject;

          const { teamIdWithPrefix, formValue, teamId, groupKey, groupType, optionKey } = this.findChangedLabel(
            formObject,
            patient,
          );

          const newLabels = this.setNewLabelsFromFormChange(
            patient,
            teamIdWithPrefix,
            groupKey,
            formValue,
            groupType,
            optionKey,
            teamId,
            formObject,
            labelForm,
          );

          this.addLabelAuditAction(formValue, patientLabels, teamIdWithPrefix, groupKey, newLabels, patient, groupType);
          return { newLabels, patientId: patient.id };
        }),
        filter(newLabelsWithPatientId => !!newLabelsWithPatientId?.newLabels),
        mergeMap(({ newLabels, patientId }) => this.db.doc(`/patients/${patientId}`).update({ labels: newLabels })),
        untilDestroyed(this),
      )
      .subscribe();
  }

  setOtherSingleSelectOptionsFalse(
    teamId: string,
    groupKey: string,
    groupType: string,
    optionKey: string,
    formObject,
    labelForm: UntypedFormGroup,
  ): void {
    for (const formKey of Object.keys(formObject)) {
      const [otherTeamId, otherGroupKey, otherGroupType, otherOptionKey] = formKey.split('~');
      if (
        teamId === otherTeamId &&
        groupKey === otherGroupKey &&
        groupType === otherGroupType &&
        optionKey !== otherOptionKey
      ) {
        labelForm.get(formKey).setValue(false, { emitEvent: false });
      }
    }
  }

  findChangedLabel(
    formObject,
    patient: PatientWithId,
  ): {
    teamIdWithPrefix: string;
    formValue: boolean;
    teamId: string;
    groupKey: string;
    groupType: string;
    optionKey: string;
  } {
    let teamIdWithPrefix: string,
      formValue: boolean,
      teamId: string,
      groupKey: string,
      groupType: string,
      optionKey: string;

    for (const [key, value] of Object.entries(formObject)) {
      [teamId, groupKey, groupType, optionKey] = key.split('~');
      teamIdWithPrefix = 'tl' + teamId;

      if (
        (value && !patient.labels?.[teamIdWithPrefix]?.[groupKey]?.includes(optionKey)) ||
        (!value && patient.labels?.[teamIdWithPrefix]?.[groupKey]?.includes(optionKey))
      ) {
        formValue = value as boolean;
        break;
      }
    }
    return {
      teamIdWithPrefix,
      formValue,
      teamId,
      groupKey,
      groupType,
      optionKey,
    };
  }

  setNewLabelsFromFormChange(
    patient: PatientWithId,
    teamIdWithPrefix: string,
    groupKey: string,
    formValue: boolean,
    groupType: string,
    optionKey: string,
    teamId: string,
    formObject,
    labelForm: UntypedFormGroup,
  ): {
    [prefix: string]: {
      [labelGroupMapKey: string]: string[];
    };
  } {
    const newLabels = patient.labels ?? {};

    newLabels[teamIdWithPrefix] = newLabels?.[teamIdWithPrefix] ? newLabels[teamIdWithPrefix] : {};
    newLabels[teamIdWithPrefix][groupKey] = newLabels?.[teamIdWithPrefix][groupKey]
      ? newLabels[teamIdWithPrefix][groupKey]
      : [];

    switch (formValue) {
      case true:
        if (groupType === LabelGroupTypeEnum.SINGLE_SELECT) {
          newLabels[teamIdWithPrefix][groupKey] = [optionKey];
          this.setOtherSingleSelectOptionsFalse(teamId, groupKey, groupType, optionKey, formObject, labelForm);
        } else {
          newLabels[teamIdWithPrefix][groupKey] = [...(newLabels[teamIdWithPrefix][groupKey] ?? []), optionKey];
        }

        break;
      case false:
        newLabels[teamIdWithPrefix][groupKey] = newLabels[teamIdWithPrefix][groupKey].filter(
          label => label !== optionKey,
        );
        break;
    }
    return newLabels;
  }

  addLabelAuditAction(formValue, patientLabels, teamIdWithPrefix, groupKey, newLabels, patient, groupType): void {
    let action: AuditActionEnum;
    switch (formValue) {
      case true:
        action =
          patientLabels?.[teamIdWithPrefix]?.[groupKey]?.length && groupType === LabelGroupTypeEnum.SINGLE_SELECT
            ? AuditActionEnum.label_updated_on_patient
            : AuditActionEnum.label_added_to_patient;
        break;
      case false:
        action = AuditActionEnum.label_removed_from_patient;
    }

    this.auditService.patientLabelsChange(newLabels, patientLabels, patient.id, action, teamIdWithPrefix.substring(2));
  }

  filterArchivedOptions(labelObjects: LabelObject[]): {
    [k: string]: {
      options: {
        [k: string]: Label;
      };
      groupDisplayName: string;
      groupPlaceholder: string;
      groupType: LabelGroupTypeEnum;
    };
  } {
    return Object.fromEntries(
      Object.entries(labelObjects[0]?.labelGroupMap ?? {})
        .filter(([, labelGroup]) => !this.containsAllArchivedLabels(labelGroup))
        .map(([groupKey, labelGroup]) => [
          groupKey,
          {
            ...labelGroup,
            options: Object.fromEntries(Object.entries(labelGroup.options).filter(([, option]) => !option.isArchived)),
          },
        ]),
    );
  }

  containsAllArchivedLabels(labelGroup: LabelGroup): boolean {
    return Object.values(labelGroup.options)
      .map(label => label.isArchived)
      .reduce((prev, cur) => prev && cur);
  }

  teamContainsAllArchivedLabels(labelGroups: LabelGroup[]): boolean {
    let allArchived = true;
    for (const labelGroup of labelGroups) {
      allArchived =
        allArchived &&
        Object.values(labelGroup.options)
          .map(label => label.isArchived)
          .reduce((prev, cur) => prev && cur);
    }
    return allArchived;
  }

  sortObjectChildAlphabetically(obj1, obj2, child): 1 | -1 {
    if (obj1[child] < obj2[child]) return -1;
    if (obj1[child] > obj2[child]) return 1;
  }
}
