import { NgModule } from '@angular/core';
import { AngularFireAuthGuardModule } from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { NhsErrorPageComponent, PageNotFoundComponent } from '@ic-monorepo/shared-common';
import { AdminAuthGuard } from './core/guards/admin-auth/admin-auth.guard';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { MobileGuard } from './core/guards/mobile/mobile.guard';

const routes: Routes = [
  {
    path: 'entry',
    loadChildren: () =>
      import('./feature-patient-submission/patient-submission.module').then(m => m.PatientSubmissionModule),
  },
  {
    path: 'submission',
    loadChildren: () =>
      import('./feature-clinician-submission/clinician-submission.module').then(m => m.ClinicianSubmissionModule),
  },
  {
    path: 'patient',
    loadChildren: () => import('./feature-patient-manage/patient-manage.module').then(m => m.PatientManageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'patients',
    loadChildren: () => import('./feature-patient-list/patient-list.module').then(m => m.PatientListModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'patients/:patientId',
    loadChildren: () =>
      import('./feature-patient-record/redesign/rd-patient-record.module').then(m => m.RdPatientRecordModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'public/patient/:patientId',
    loadChildren: () => import('./feature-patient-public/patient-public.module').then(m => m.PatientPublicModule),
  },
  {
    path: 'submissions-dashboard',
    loadChildren: () =>
      import('./feature-submissions-dashboard/rd-submissions-dashboard.module').then(
        m => m.RdSubmissionsDashboardModule,
      ),
    canActivate: [AuthGuard, MobileGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./feature-auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./core/user/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'teams',
    loadChildren: () => import('./core/teams/teams.module').then(m => m.TeamsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('./core/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard, AdminAuthGuard],
  },
  {
    path: 'clinical-forms',
    loadChildren: () => import('@ic-monorepo/feature-clinical-forms').then(m => m.FeatureClinicalFormsModule),
  },
  {
    path: 'user-dashboards',
    loadChildren: () => import('./feature-user-dashboard/user-dashboard.module').then(m => m.UserDashboardModule),
  },
  {
    path: 'accessibility',
    loadChildren: () => import('./accessibility/accessibility.module').then(m => m.AccessibilityModule),
    canActivate: [AuthGuard],
  },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: 'notFound', component: NhsErrorPageComponent },
  { path: '', pathMatch: 'full', redirectTo: '/patients' },
  { path: '**', redirectTo: '/page-not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule, AngularFireAuthGuardModule],
  providers: [AdminAuthGuard],
})
export class AppRoutingModule {}
