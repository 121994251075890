import { Injectable } from '@angular/core';
import { UsersService } from '@ic-monorepo/services';
import { Observable, first, map, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserIdToUserEmailService {
  private userEmailStore = new Map<string, string>();

  constructor(private usersService: UsersService) {}

  getEmail$(id: string): Observable<string> {
    if (this.userEmailStore.has(id)) return of(this.userEmailStore.get(id));
    return this.usersService.getUser$(id).pipe(
      first(),
      map(user => ({ id, email: user?.email ?? id })),
      tap(({ id, email }) => this.storeEmail(id, email)),
      map(({ email }) => email),
    );
  }

  private storeEmail(id: string, email: string): void {
    this.userEmailStore.set(id, email);
  }
}
