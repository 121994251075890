<form [formGroup]="painSummaryForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <div class="question-container">
      <div class="half-width-field hori-verti-center">
        <p>Please mark on this body chart where you mostly feel your pain:</p>
      </div>
      <div class="body-map-holder">
        <div id="sheet-container">
          <canvas id="sheet" width="264" height="297"></canvas>
        </div>
        <br />
        <button mat-raised-button class="reset-button" (click)="clearMarking($event)">RESET</button>
      </div>
    </div>
    <div>
      <p>
        Please mark the scale below to show how intense your pain is. A zero (0) means no pain, and
        ten (10) means extreme pain.
      </p>
      <br />
      <p>How intense is your pain now?</p>
      <div class="full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>0</div>
          <div>No pain</div>
        </div>
        <mat-slider
          class="full-width-field"
          formControlName="painIntensityNow"
          value="painIntensityNow"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <!-- <mat-error *ngIf="painIntensityNow.touched && painIntensityNow.invalid">Please use the slider</mat-error> -->
        <div class="slider-label">
          <div>10</div>
          <div>Extreme pain</div>
        </div>
      </div>

      <!-- <p class = pain-descriptor>{{painDescriptor}}</p> -->
    </div>
    <div>
      <p>How intense was your pain on average last week?</p>
      <div class="full-width-field verti-center slider-wrapper">
        <div class="slider-label">
          <div>0</div>
          <div>No pain</div>
        </div>
        <mat-slider
          class="full-width-field"
          formControlName="painIntensityLastWeek"
          value="painIntensityLastWeek"
          [min]="sliderMin"
          [max]="sliderMax"
        >
        </mat-slider>
        <div class="slider-label">
          <div>10</div>
          <div>Extreme pain</div>
        </div>
      </div>
    </div>

    <div>
      <button
        mat-raised-button
        class="positive-button"
        type="submit"
        [disabled]="isSubmitting"
        [disabled]="!painSummaryFormValid()"
      >
        {{ isSubmitting ? 'Submitting...' : 'Submit' }}
      </button>
    </div>
  </div>
</form>
