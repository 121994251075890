<div class="field">
  <p>
    There is no email address associated with this patient. Please add/update
    contact details to request a submission or use the QR code to request a
    submission.
  </p>
</div>
<div class="flex justify-content-end">
  <div class="pr-2">
    <button
      (click)="onNoClick()"
      type="button"
      label="Cancel"
      class="p-button-outlined"
      aria-label="Cancel"
      pButton
    ></button>
  </div>
  <button
    label="Add/update email address"
    aria-label="Add/update email address"
    (click)="onSubmit()"
    pButton
  ></button>
</div>
