export default [
  // painMedicationForm
  'drugsCurrentlyTakingForPain',
  'drugsCurrentlyTakingForOtherConditions',
  'drugsPreviouslyTakenForPain',
  'drugDose',
  'drugDailyIntake',

  // painSummaryForm
  'bodyAreaWithMostPain',
  'painIntensityNow',
  'painIntensityLastWeek',

  // dn4ScaleForm
  'painFeelLikeBurning',
  'painFeelLikePainfulCold',
  'painFeelLikeElectricShocks',
  'painFeelLikeTingling',
  'painFeelLikePinsAndNeedles',
  'painFeelLikeNumbness',
  'painFeelLikeItching',

  // neuropathicPainSymptomInventoryForm
  'painFeelLikeBurningSlider',
  'painFeelLikeSqueezingSlider',
  'painFeelLikePressureSlider',
  'painHasBeenPresent',
  'painFeelLikeElectricShocksSlider',
  'painFeelLikeStabbingSlider',
  'painAttacks',
  'painIncreasedByBrushingSlider',
  'painIncreasedByPressureSlider',
  'painIncreasedByContactWithColdSlider',
  'painFeelsLikePinsAndNeedlesSlider',
  'painFeelsLikeTinglingSlider',
  'burningSuperficialSpontaneousPain',
  'pressingDeepSpontaneousPain',
  'paroxysmalPain',
  'evokedPain',
  'paresthesiaDysesthesiaPain',
  'neuropathicPainSymptomTotalAnswer',

  // bpiInterferenceScaleForm
  'generalActivitySlider',
  'moodSlider',
  'walkingAbilitySlider',
  'normalWorkSlider',
  'relationshipsWithOtherPeopleSlider',
  'sleepSlider',
  'enjoymentOfLifeSlider',

  // formArray index
  'drugName'
];
