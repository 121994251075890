import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TeamWithId } from '@islacare/ic-types';
import { ComponentStore } from '@ngrx/component-store';
import { ChartData } from 'chart.js';
import { Observable } from 'rxjs';

export interface UserDashboardState {
  loaded: boolean;
  error: string;
  myTeams: TeamWithId[];
  selectedTeam: TeamWithId;
  dashboardData: UserDashboardData;
  outcomesChartData: ChartData;
  scheduledItemsChartData: ChartData;
}

export interface UserDashboardData {
  countOfSubmissionsInGivenTeam: {
    total: number;
    requiringAttention: number;
  };
  changeOfSubmissionsVsPreviousWeek: string;
  responseRatePreviousMonth: {
    respondedOutOfTotal: string;
    respondedPercentage: number;
  };
  labelCounts?: CountOfLabelsWithPatientCount[];
}

interface CountOfLabelsWithPatientCount {
  key: string;
  groupDisplayName: string;
  groupLabels: LabelCount[];
}

interface LabelCount {
  label: string;
  count: number;
}

const defaultState: UserDashboardState = {
  loaded: true,
  error: null,
  myTeams: [],
  selectedTeam: null,
  dashboardData: null,
  outcomesChartData: null,
  scheduledItemsChartData: null,
};

@Injectable()
export class UserDashboardStore extends ComponentStore<UserDashboardState> {
  readonly full$: Observable<UserDashboardState> = this.select(state => state);
  readonly hasLoaded$: Observable<boolean> = this.select(state => state.loaded);
  readonly error$: Observable<string> = this.select(state => state.error);

  readonly myTeams$: Observable<TeamWithId[]> = this.select(state => state.myTeams);
  readonly selectedTeam$: Observable<TeamWithId> = this.select((state: any) => state.selectedTeam);
  readonly dashboardData$: Observable<UserDashboardData> = this.select((state: any) => state.dashboardData);

  readonly dashboardOutcomesChartData$: Observable<ChartData> = this.select(state => state.outcomesChartData);

  readonly dashboardScheduledItemsChartData$: Observable<ChartData> = this.select(
    state => state.scheduledItemsChartData,
  );

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    super(defaultState);
  }

  initialise(myTeams: TeamWithId[]): void {
    this.setState(() => ({
      ...defaultState,
      myTeams: myTeams,
    }));
  }

  setLoaded(loaded: boolean): void {
    this.setState(state => ({
      ...state,
      loaded: loaded,
    }));
  }

  setError(error: string): void {
    this.setState(state => ({
      ...state,
      error: error,
    }));
  }

  setDashboardData(dashboardData: UserDashboardData): void {
    this.setState(state => ({
      ...state,
      dashboardData: dashboardData,
    }));
  }

  setChartData(chart: 'outcomesChart' | 'scheduledItemsChart', updatedChartData: ChartData): void {
    if (chart === 'outcomesChart') {
      this.setState(state => ({
        ...state,
        outcomesChartData: updatedChartData,
      }));
    }

    if (chart === 'scheduledItemsChart') {
      this.setState(state => ({
        ...state,
        scheduledItemsChartData: updatedChartData,
      }));
    }
  }

  setSelectedTeam(selectedTeam: TeamWithId): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { teamId: selectedTeam.id },
      queryParamsHandling: 'merge',
    });

    this.setState(state => ({
      ...state,
      selectedTeam: selectedTeam,
    }));
  }

  clearStore(): void {
    this.setState(() => ({
      ...defaultState,
    }));
  }
}
