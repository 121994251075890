import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  consoleLog(...message: any[]): void {
    if (environment.CONSOLE_LOGGING_ENABLED) {
      console.log(message);
    }
  }

  consoleError(...message: any[]): void {
    if (environment.CONSOLE_LOGGING_ENABLED) {
      console.error(message);
    }
  }

  consoleWarn(...message: any[]): void {
    if (environment.CONSOLE_LOGGING_ENABLED) {
      console.warn(message);
    }
  }
}
