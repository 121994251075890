import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-dlqi-additional-form',
  templateUrl: './dlqi-additional-form.component.html',
  styleUrls: ['./dlqi-additional-form.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
})
export class DlqiAdditionalFormComponent implements OnInit {
  @Input() data;
  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  dlqiForm: UntypedFormGroup;
  additionalForm: UntypedFormGroup;
  isSubmitting = false;
  isSensitive: boolean;
  isLinear = true;
  dlqiTotalScore: number;

  dermatologyAnswerList: string[] = [
    'Very much - 3',
    'A lot - 2',
    'A little - 1',
    'Not at all - 0',
  ];

  ngOnInit(): void {
    this.dlqiForm = this.fb.group({
      itchySorePainfulOrStingingSkin: ['', Validators.required],
      embarrassedOrSelfConscious: ['', Validators.required],
      interferedWithShoppingHomeOrGarden: ['', Validators.required],
      skinInfluencedClothesYouWear: ['', Validators.required],
      skinAffectedSocialOrLeisureActivities: ['', Validators.required],
      skinAffectedAnySport: ['', Validators.required],
      skinPreventedWorkingOrStudyingYesNo: ['', Validators.required],
      skinPreventedWorkingOrStudying: [''],
      skinCreatedProblemsWithPartnerFriendsOrFamily: ['', Validators.required],
      skinCausedSexualDifficulties: ['', Validators.required],
      skinTreatmentBeenAProblem: ['', Validators.required],
      dlqiTotalScore: [''],
    });

    this.additionalForm = this.fb.group({
      currentTreatmentSideEffects: [''],
      deliveryIssueWithCurrentTreatment: [''],
      deliveryIssueWithCurrentTreatmentText: [''],
      currentWeight: [''],
      newMedicalIssuesSinceLastAppointment: [''],
      newMedicalIssuesSinceLastAppointmentText: [''],
      upToDateWithVaccinations: [''],
      upToDateWithVaccinationsText: [''],
      ageAppropriateCancerScreening: [''],
      ageAppropriateCancerScreeningText: [''],
      planningOnHavingChildrenInTheNextYear: [''],
      planningOnHavingChildrenInTheNextYearText: [''],
      areYouFemale: [''],
      areYouSexuallyActive: [''],
      whatContraceptionAreYouUsing: [''],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.dlqiForm.controls[key])
          this.dlqiForm.controls[key].setValue(formData[key]);
        if (this.additionalForm.controls[key])
          this.additionalForm.controls[key].setValue(formData[key]);
      });
    });
  }

  onSubmit() {
    if (!this.dlqiFormValid()) return;
    this.setDlqiScore();

    this.isSubmitting = true;
    const formResponse = {
      ...this.dlqiForm.value,
      ...this.additionalForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
      showOnChart: true,
    };
    this.form.submit(formResponse);
  }

  // DLQI FORM
  get itchySorePainfulOrStingingSkin() {
    return this.dlqiForm.get('itchySorePainfulOrStingingSkin');
  }
  get embarrassedOrSelfConscious() {
    return this.dlqiForm.get('embarrassedOrSelfConscious');
  }
  get interferedWithShoppingHomeOrGarden() {
    return this.dlqiForm.get('interferedWithShoppingHomeOrGarden');
  }
  get skinInfluencedClothesYouWear() {
    return this.dlqiForm.get('skinInfluencedClothesYouWear');
  }
  get skinAffectedSocialOrLeisureActivities() {
    return this.dlqiForm.get('skinAffectedSocialOrLeisureActivities');
  }
  get skinAffectedAnySport() {
    return this.dlqiForm.get('skinAffectedAnySport');
  }
  get skinPreventedWorkingOrStudyingYesNo() {
    return this.dlqiForm.get('skinPreventedWorkingOrStudyingYesNo');
  }
  get skinPreventedWorkingOrStudying() {
    return this.dlqiForm.get('skinPreventedWorkingOrStudying');
  }
  get skinCreatedProblemsWithPartnerFriendsOrFamily() {
    return this.dlqiForm.get('skinCreatedProblemsWithPartnerFriendsOrFamily');
  }
  get skinCausedSexualDifficulties() {
    return this.dlqiForm.get('skinCausedSexualDifficulties');
  }
  get skinTreatmentBeenAProblem() {
    return this.dlqiForm.get('skinTreatmentBeenAProblem');
  }

  // ADDITIONAL INFO FORM
  get currentTreatmentSideEffects() {
    return this.additionalForm.get('currentTreatmentSideEffects');
  }
  get deliveryIssueWithCurrentTreatment() {
    return this.additionalForm.get('deliveryIssueWithCurrentTreatment');
  }
  get deliveryIssueWithCurrentTreatmentText() {
    return this.additionalForm.get('deliveryIssueWithCurrentTreatmentText');
  }
  get currentWeight() {
    return this.additionalForm.get('currentWeight');
  }
  get newMedicalIssuesSinceLastAppointment() {
    return this.additionalForm.get('newMedicalIssuesSinceLastAppointment');
  }
  get newMedicalIssuesSinceLastAppointmentText() {
    return this.additionalForm.get('newMedicalIssuesSinceLastAppointmentText');
  }
  get upToDateWithVaccinations() {
    return this.additionalForm.get('upToDateWithVaccinations');
  }
  get upToDateWithVaccinationsText() {
    return this.additionalForm.get('upToDateWithVaccinationsText');
  }
  get ageAppropriateCancerScreening() {
    return this.additionalForm.get('ageAppropriateCancerScreening');
  }
  get ageAppropriateCancerScreeningText() {
    return this.additionalForm.get('ageAppropriateCancerScreeningText');
  }
  get planningOnHavingChildrenInTheNextYear() {
    return this.additionalForm.get('planningOnHavingChildrenInTheNextYear');
  }
  get planningOnHavingChildrenInTheNextYearText() {
    return this.additionalForm.get('planningOnHavingChildrenInTheNextYearText');
  }
  get areYouFemale() {
    return this.additionalForm.get('areYouFemale');
  }
  get whatContraceptionAreYouUsing() {
    return this.additionalForm.get('whatContraceptionAreYouUsing');
  }

  dlqiFormValid(): boolean {
    return (
      this.dlqiForm.dirty &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.itchySorePainfulOrStingingSkin.errors
      ) &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.embarrassedOrSelfConscious.errors
      ) &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.interferedWithShoppingHomeOrGarden.errors
      ) &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.skinInfluencedClothesYouWear.errors
      ) &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.skinAffectedSocialOrLeisureActivities.errors
      ) &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.skinAffectedAnySport.errors
      ) &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.skinPreventedWorkingOrStudyingYesNo.errors
      ) &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.skinPreventedWorkingOrStudying.errors
      ) &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.skinCreatedProblemsWithPartnerFriendsOrFamily
          .errors
      ) &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.skinCausedSexualDifficulties.errors
      ) &&
      !(
        this.dlqiForm.controls &&
        this.dlqiForm.controls.skinTreatmentBeenAProblem.errors
      )
    );
  }

  setDlqiScore() {
    const dlqiQuestion1 = this.dlqiForm.get(
      'itchySorePainfulOrStingingSkin'
    ).value;
    const dlqiQuestion2 = this.dlqiForm.get('embarrassedOrSelfConscious').value;
    const dlqiQuestion3 = this.dlqiForm.get(
      'interferedWithShoppingHomeOrGarden'
    ).value;
    const dlqiQuestion4 = this.dlqiForm.get(
      'skinInfluencedClothesYouWear'
    ).value;
    const dlqiQuestion5 = this.dlqiForm.get(
      'skinAffectedSocialOrLeisureActivities'
    ).value;
    const dlqiQuestion6 = this.dlqiForm.get('skinAffectedAnySport').value;
    const dlqiQuestion7 = this.dlqiForm.get(
      'skinPreventedWorkingOrStudyingYesNo'
    ).value;
    const dlqiQuestion8 = this.dlqiForm.get(
      'skinPreventedWorkingOrStudying'
    ).value;
    const dlqiQuestion9 = this.dlqiForm.get(
      'skinCreatedProblemsWithPartnerFriendsOrFamily'
    ).value;
    const dlqiQuestion10 = this.dlqiForm.get(
      'skinCausedSexualDifficulties'
    ).value;
    const dlqiQuestion11 = this.dlqiForm.get('skinTreatmentBeenAProblem').value;

    const dlqiTotalScore =
      +dlqiQuestion1.match(/\d+/g) +
      +dlqiQuestion2.match(/\d+/g) +
      +dlqiQuestion3.match(/\d+/g) +
      +dlqiQuestion4.match(/\d+/g) +
      +dlqiQuestion5.match(/\d+/g) +
      +dlqiQuestion6.match(/\d+/g) +
      +dlqiQuestion7.match(/\d+/g) +
      +dlqiQuestion8.match(/\d+/g) +
      +dlqiQuestion9.match(/\d+/g) +
      +dlqiQuestion10.match(/\d+/g) +
      +dlqiQuestion11.match(/\d+/g);

    this.dlqiForm.get('dlqiTotalScore').setValue(dlqiTotalScore);
  }
}
