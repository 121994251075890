import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OverscrollBehaviorService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  setOverscroll() {
    this.renderer.addClass(document.documentElement, 'overflow-hidden');
    this.renderer.addClass(document.body, 'overscroll-behavior-contain');
  }

  clearOverscroll() {
    this.renderer.removeClass(document.documentElement, 'overflow-hidden');
    this.renderer.removeClass(document.body, 'overscroll-behavior-contain');
  }
}
