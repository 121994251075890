import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ButtonModule } from 'primeng/button';
import { ImageModule } from 'primeng/image';
import { MessageModule } from 'primeng/message';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { Observable } from 'rxjs';
import {
  FileUploadService,
  UploadInfo,
} from '../../services/file-upload/file-upload.service';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ProgressBarModule,
    ButtonModule,
    ImageModule,
    MessageModule,
  ],
  selector: 'ic-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent implements OnInit {
  allUploads$: Observable<UploadInfo[]>;

  rowClass = `ic-file-uploader-row flex flex-row justify-content-between align-items-center gap-3 p-0 pl-4`;
  rowLabelClass = `flex flex-row justify-content-center align-items-center gap-3`;

  constructor(private fileUploadService: FileUploadService) {}

  ngOnInit(): void {
    this.allUploads$ = this.fileUploadService.allUploads$;
  }

  getIconClass(upload: UploadInfo): string {
    switch (upload.status) {
      case 'complete':
        return 'pi pi-check success-700';
      case 'errored':
        return 'pi pi-exclamation-triangle error-700';
      case 'cancelled':
        return 'pi pi-times-circle primary-700';
      case 'ongoing':
        return upload.isHovered ? 'pi pi-times' : 'pi pi-spinner pi-spin';
      default:
        return 'pi pi-spinner pi-spin';
    }
  }

  async cancelUpload(uploadInfo: UploadInfo) {
    if (uploadInfo.uploadTask) {
      await uploadInfo.uploadTask.cancel();
      this.fileUploadService.moveUploadToList(uploadInfo.id, 'cancelled');
    }
  }
}
