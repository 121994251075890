export default [
  'woundSymptoms',
  'rednessSpreadingAwayFromWound',
  'woundAreaWarmerThanSurroundingSkin',
  'woundAreaBecomeSwollen',
  'woundLeakedClearFluid',
  'woundLeakedBloodStainedFluid',
  'woundLeakedThickYellowGreenFluid',
  'woundBeenPainfulToTouch',
  'woundBeenSmelly',
  'haveOrHadARaisedTemperature',
  'woundSeparatedOpen',
  'deeperTissueSeparate',
  'haveYouBeenDiagnosedWithAnInfection',
  'whatDateWasThisDiagnosedOn',
  'hasAnythingBeenPutOnToCoverTheWound',
  'haveYouBeenGivenAnitobioticsForYourWound',
  'haveTheEdgesOfTheWoundBeenSeparatedDeliberately',
  'haveYouSoughtAdviceWithAProblemWithYourWound',
  'haveYouBeenInHospitalForTreatmentForYourWound',
  'hasYourWoundBeenScrapedOrCutToRemoveUnwantedTissue',
  'hasYourWoundBeenDrained',
  'haveYouHadAnOperationUnderGeneralAnaesthetic',

  'takePartInTelephoneInterview',

  'accessToHealthcareServicesHasImproved',
  'thisSavedTimeTravellingToHospitalOrClinic',
  'thisProvidedForMyHealthcareNeeds',
  'toolUsefulnessComments',

  'itWasSimpleToUseTheOnlineForm',
  'itWasEasyToLearnToUseTheForm',
  'iWasAbleToNavigateTheToolQuicklyWhenSubmitting',
  'toolEaseOfUseComments',

  'pleasantToInteractWith',
  'iLikeUsingIslaHostingTheForm',
  'islaHostingTheFormIsSimpleAndEasyToUnderstand',
  'hostedFormIsAbleToDoEverythingIExpect',
  'platformInterfaceComments',

  'couldEasilyCommunicateWithTheHealthcareStaff',
  'couldEasilyGetAResponseFromTheHealthcareStaff',
  'feltIWasAbleToExpressMyselfEffectively',
  'couldCommunicateWithHealthcareStaffAsWellAsInPerson',
  'platformQualityOfCommunicationComments',

  'remoteWoundReviewsAreSameAsInPerson',
  'whenIMadeAMistakeOnTheFormICouldRecoverQuicklyAndEasily',
  'islaPlatformOnlineFormGaveErrorMessagesClearly',
  'onlineToolReliabilityComments',

  'feelComfortableCommunicatingWithTheHealthcareStaff',
  'foundThisAnAcceptableWayToReceiveHealthcareServices',
  'wouldUseTheOnlineSystemAgain',
  'shouldBeAvailableForOthersUndergoingSimilarSurgery',
  'overallSatisfiedWithTheOnlineSystem',
  'onlineToolComments',

  'highestNumberOfPeopleAskedToGoForAWoundReview',
  'highestNumberOfMissedInfections'
];
