export default [
  // CADI
  'CADI',
  'feelingAggressiveFrustratedOrEmbarrassed',
  'acneInterferedWithSocialLifeEventsOrRelationships',
  'avoidedPublicChangingFacilities',
  'feelingsAboutSkinAppearance',
  'howBadIsYourAcneNow',
  'cadiTotalScore'
];
