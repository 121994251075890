import { Pipe, PipeTransform } from '@angular/core';
import { of } from 'rxjs';
@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {
  transform(source: any, key?: string, invert?: boolean) {
    const array = source && source.subscribe ? source.value : source;

    if (key?.charAt(0) === '+') {
      invert = false;
      key = key.substring(1);
    }

    if (key?.charAt(0) === '-') {
      invert = true;
      key = key.substring(1);
    }

    if (!array || !array.length) {
      return array;
    }

    let keyVal = [];
    if (key) keyVal = key.split('.');

    const valType = key ? array[0][key] : array[0];

    if (!key && typeof valType === 'object') {
      return array;
    }

    array.sort((a, b) => {
      switch (typeof valType) {
        case 'number':
          return (
            (key ? this.getDeepValue(a, keyVal) : a) - (key ? this.getDeepValue(b, keyVal) : b)
          );
        default: {
          let firstVal = key ? this.getDeepValue(a, keyVal) : a;
          let secondVal = key ? this.getDeepValue(b, keyVal) : b;
          firstVal = typeof firstVal === 'string' ? firstVal.toLowerCase() : firstVal;
          secondVal = typeof secondVal === 'string' ? secondVal.toLowerCase() : secondVal;
          return this.defaultSort(firstVal, secondVal);
        }
      }
    });

    if (invert) {
      array.reverse();
    }
    return source && source.subscribe ? of(array) : array;
  }

  //to access data from keys like createdAt.seconds
  getDeepValue = (obj, path) => path.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), obj);

  defaultSort(a, b) {
    if (!a) {
      return -1;
    }

    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    }

    return 0;
  }
}
