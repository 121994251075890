export default [
  'dateOfSurgery',
  'ward',
  'woundAssessments',
  'woundClosureMaterial',
  'dateOfRemoval',
  'removalNeededBy',
  'surgicalWoundAssessment',
  'exudateAmount',
  'surgicalWoundAssessmentOther',
  'microBiologyResults',
  'drainSites',
  'drainSitesRemovalDate',
  'drainSitesRemovalNeededBy',
  'additionalResources',
  'responsibleSurgeon',
  'staffProvidingPad',
  'consent',
  'notes',
  'surgicalCategory',
  'surgicalWound'
];
