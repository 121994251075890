<h2 mat-dialog-title class="dialog-header">
  Identified features
  <button mat-icon-button mat-dialog-close aria-label="Close" matTooltip="Close">
    <mat-icon>close</mat-icon>
  </button>
</h2>

<mat-dialog-content>
  <div *ngIf="entry$ | async">
    <div class="entry-info-container">
      <div class="entry-info">
        <p>
          <strong>Submitted on:</strong>
          {{ entry.createdAt | timestamp | date: 'HH:mm dd MMM yyyy' }}
        </p>
        <p><strong>Taken by:</strong> {{ entry.capturedByEmail }}</p>
        <div *ngIf="entry.requestedBy">
          <strong>Requested by: </strong>
          <span class="requested-by-user"
            ><a
              [href]="'mailto:' + entry.requestedBy?.email + '?Subject=Visual%20Record%20Request'"
              target="_top"
              [matTooltip]="entry.requestedBy?.name | titlecase"
              >{{ entry.requestedBy?.email }}</a
            ><span class="timestamp">{{
              entry.requestedBy?.createdAt | timestamp | date: 'HH:mm dd MMM yyyy'
            }}</span>
          </span>
        </div>
        <div>
          <strong>{{ isViewAllReviewers ? 'R' : 'Last r' }}eviewed by: </strong>
          <span *ngIf="!entry.reviewedBy; else reviewed">Not reviewed yet</span>
          <ng-template #reviewed>
            <span *ngIf="entry.reviewedBy?.length > 1">
              <span *ngIf="isViewAllReviewers">
                <span *ngFor="let review of entry.reviewedBy">
                  <span class="reviewed-by-user-multi">
                    <a
                      [href]="'mailto:' + review?.email + '?Subject=Visual%20Record%20Review'"
                      target="_top"
                      [matTooltip]="review?.name | titlecase"
                      >{{ review?.email }}</a
                    >
                    <span class="timestamp view-all">{{
                      review?.createdAt | date: 'HH:mm dd MMM yyyy'
                    }}</span>
                  </span>
                </span>
              </span>
            </span>
            <span *ngIf="!isViewAllReviewers">
              <span class="reviewed-by-user-single">
                <a
                  [href]="
                    'mailto:' + entry.reviewedBy[0]?.email + '?Subject=Visual%20Record%20Review'
                  "
                  target="_top"
                  [matTooltip]="entry.reviewedBy[0]?.name"
                  >{{ entry.reviewedBy[0]?.email }}</a
                >
                <span class="timestamp">{{
                  entry.reviewedBy[0]?.createdAt | date: 'HH:mm dd MMM yyyy'
                }}</span>
              </span>
            </span>
            <span
              *ngIf="entry.reviewedBy?.length > 1"
              class="view-all-reviewers"
              (click)="toggleReviewersView()"
              >View
              {{ isViewAllReviewers ? 'less' : 'more (' + entry.reviewedBy?.length + ')' }}</span
            >
          </ng-template>
        </div>
      </div>
      <div *ngIf="entry.mlFeaturesDetected?.length" class="entry-info">
        <p *ngFor="let tag of entry?.mlFeaturesDetected" class="tag-tab">
          <mat-icon [ngStyle]="{ color: entriesService?.colorMap[tag] }"> local_offer</mat-icon>
          {{ tag | camelCaseToTitleCase }}
        </p>
      </div>
    </div>
  </div>
  <div *ngIf="loading" class="loader-container">
    <img [src]="loadingImagePath" alt="loader" />
  </div>
  <div #canvasContainer class="sheet-container">
    <canvas id="sheet-ml"></canvas>
  </div>
</mat-dialog-content>
