<form [formGroup]="entryForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <mat-form-field appearance="fill" class="half-width-field">
      <mat-label>Date of discharge </mat-label>
      <input
        matInput
        type="date"
        formControlName="dateOfDischarge"
        class="date-placeholder"
      />
      <mat-error *ngIf="dateOfDischarge.touched && dateOfDischarge.invalid">
        Please enter a date
      </mat-error>
    </mat-form-field>

    <ng-container *ngIf="form$ | async as form">
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Ward of discharge </mat-label>
        <mat-select formControlName="ward">
          <!-- <mat-option value="Cedar">Cedar</mat-option>
                    <mat-option value="Maple">Maple</mat-option>
                    <mat-option value="Juniper">Juniper</mat-option>
                    <mat-option value="HDU">HDU</mat-option> -->
          <mat-option [value]="ward" *ngFor="let ward of form?.ward?.sort()">
            {{ ward }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="ward.touched && ward.invalid">
          Please select a ward
        </mat-error>
      </mat-form-field>
    </ng-container>
    <mat-form-field appearance="fill" class="half-width-field">
      <mat-label>Category </mat-label>
      <mat-select formControlName="category">
        <mat-option value="Pediatric cardiac (surgical)"
          >Pediatric cardiac (surgical)</mat-option
        >
        <mat-option value="Adult Thoracic">Adult Thoracic</mat-option>
      </mat-select>
      <mat-error *ngIf="category.touched && category.invalid">
        Please select a category
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" class="half-width-field">
      <mat-label>Surgical wound</mat-label>
      <mat-select formControlName="surgicalWound">
        <mat-option value="Sternal wound (central, midline incision)"
          >Sternal wound (central, midline incision)</mat-option
        >
        <mat-option value="Laparotomy">Laparotomy</mat-option>
        <mat-option value="Mini-laparotomy">Mini-laparotomy</mat-option>
        <mat-option value="Transverse Cervical incision"
          >Transverse Cervical incision</mat-option
        >
        <mat-option value="Hemi-Clamshell incision"
          >Hemi-Clamshell incision</mat-option
        >
        <mat-option value="Clamshell incision">Clamshell incision</mat-option>
        <mat-option value="Left-sided thoracotomy wound"
          >Left-sided thoracotomy wound</mat-option
        >
        <mat-option value="Right-sided thoracotomy wound"
          >Right-sided thoracotomy wound</mat-option
        >
        <mat-option value="Left-sided video-assisted thoracoscopic wound(s)"
          >Left-sided video-assisted thoracoscopic wound(s)</mat-option
        >
        <mat-option value="Right-sided video-assisted thoracoscopic wound(s)"
          >Right-sided video-assisted thoracoscopic wound(s)</mat-option
        >
        <mat-option value="Bilateral video-assisted thorascoscopic wound(s)"
          >Bilateral video-assisted thorascoscopic wound(s)</mat-option
        >
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="half-width-field">
      <mat-label>Wound closure material </mat-label>
      <mat-select
        multiple
        formControlName="woundClosureMaterial"
        (selectionChange)="woundClosureMaterialValidation()"
      >
        <mat-option value="Absorbable sutures">Absorbable sutures</mat-option>
        <mat-option
          value="Non-absorbable sutures (dark, thread-like appearance)"
          >Non-absorbable sutures (dark, thread-like appearance)</mat-option
        >
        <mat-option value="Surgical clips">Surgical clips</mat-option>
        <mat-option value="Glue/tissue adhesive (eg. Dermabond)"
          >Glue/tissue adhesive (eg. Dermabond)</mat-option
        >
        <mat-option value="Wound closure tapes (eg. Steri-Strips)"
          >Wound closure tapes (eg. Steri-Strips)</mat-option
        >
        <mat-option value="Deep tension sutures"
          >Deep tension sutures</mat-option
        >
      </mat-select>
      <mat-error
        *ngIf="woundClosureMaterial.invalid && woundClosureMaterial.touched"
      >
        Please select a wound closure material
      </mat-error>
    </mat-form-field>

    <div
      *ngIf="
        entryForm
          .get('woundClosureMaterial')
          .value.indexOf(
            'Non-absorbable sutures (dark, thread-like appearance)'
          ) !== -1 ||
        entryForm
          .get('woundClosureMaterial')
          .value.indexOf('Surgical clips') !== -1 ||
        entryForm
          .get('woundClosureMaterial')
          .value.indexOf('Deep tension sutures') !== -1
      "
    >
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Wound closure removal date</mat-label>
        <input
          matInput
          type="date"
          formControlName="woundClosureRemovalDate"
          class="date-placeholder"
        />
      </mat-form-field>

      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Removal needed by</mat-label>
        <mat-select formControlName="woundClosureRemovalNeededBy">
          <mat-option value="Our hospital (wound clinic or other)"
            >Our hospital (wound clinic or other)</mat-option
          >
          <mat-option value="GP/Practice Nurse">GP/Practice Nurse</mat-option>
          <mat-option value="Onward (transfer) hospital/Convalesce"
            >Onward (transfer) hospital/Convalesce</mat-option
          >
          <mat-option value="District Nurse">District Nurse</mat-option>
          <mat-option value="Thoracic Drain Clinic (Harefield)"
            >Thoracic Drain Clinic (Harefield)</mat-option
          >
          <mat-option value="Other">Other</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-form-field appearance="fill" class="half-width-field">
      <mat-label>Surgical wound assessment </mat-label>
      <mat-select multiple formControlName="surgicalWoundAssessment">
        <mat-option
          [disabled]="
            entryForm
              .get('surgicalWoundAssessment')
              .value.indexOf(
                'Exudate (fluid) present - serous or haemoserous'
              ) !== -1 ||
            entryForm
              .get('surgicalWoundAssessment')
              .value.indexOf('Exudate (fluid) present - thick/cloudy/pus') !==
              -1
          "
          value="Dry, intact & exposed"
          >Dry, intact & exposed</mat-option
        >

        <mat-option
          [disabled]="
            entryForm
              .get('surgicalWoundAssessment')
              .value.indexOf('Dry, intact & exposed') !== -1
          "
          value="Exudate (fluid) present - serous or haemoserous"
          >Exudate (fluid) present - serous or haemoserous</mat-option
        >

        <mat-option
          [disabled]="
            entryForm
              .get('surgicalWoundAssessment')
              .value.indexOf('Dry, intact & exposed') !== -1
          "
          value="Exudate (fluid) present - thick/cloudy/pus"
          >Exudate (fluid) present - thick/cloudy/pus</mat-option
        >

        <mat-option
          *ngFor="let surgicalWoundAssessment of surgicalWoundAssessmentList"
          [value]="surgicalWoundAssessment"
          >{{ surgicalWoundAssessment }}</mat-option
        >
      </mat-select>
      <mat-error
        *ngIf="
          surgicalWoundAssessment.invalid && surgicalWoundAssessment.touched
        "
      >
        Please select a surgical wound assessment option
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" class="half-width-field">
      <mat-label>Drain clinic appointment</mat-label>
      <mat-select formControlName="drainClinicAppointment">
        <mat-option value="Yes">Yes</mat-option>
        <mat-option value="No">No</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      appearance="fill"
      class="half-width-field"
      *ngIf="
        entryForm.get('drainClinicAppointment').value.indexOf('Yes') !== -1
      "
    >
      <mat-label>Drain clinic appointment date</mat-label>
      <input
        matInput
        type="date"
        formControlName="drainClinicAppointmentDate"
        class="date-placeholder"
      />
    </mat-form-field>

    <mat-form-field appearance="fill" class="half-width-field">
      <mat-label>Microbiology results for surgical wound</mat-label>
      <mat-select formControlName="microbiologyResults">
        <mat-option value="None">None</mat-option>
        <mat-option
          value="Antibiotics are prescribed to cover the surgical wound swab results"
          >Antibiotics are prescribed to cover the surgical wound swab
          results</mat-option
        >
        <mat-option
          value="There are pending surgical wound swabs. We will follow up these results and will inform your GP to action if necessary"
          >There are pending surgical wound swabs. We will follow up these
          results and will inform your GP to action if necessary</mat-option
        >
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="half-width-field">
      <mat-label>Drain sites</mat-label>
      <mat-select
        multiple
        formControlName="drainSites"
        (selectionChange)="drainSitesConditionalValidation($event.value)"
      >
        <mat-option value="Dry and intact">Dry and intact</mat-option>
        <mat-option value="Sutures (dark thread appearance) to be removed"
          >Sutures (dark thread appearance) to be removed</mat-option
        >
        <mat-option
          value="Drain site sutures removed and a small dressing placed over the area. Remove the dressing after the next day or two."
          >Drain site sutures removed and a small dressing placed over the area.
          Remove the dressing after the next day or two.</mat-option
        >
        <mat-option value="Drain still in situ">Drain still in situ</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      appearance="fill"
      class="half-width-field"
      *ngIf="
        entryForm
          .get('drainSites')
          .value.indexOf('Sutures (dark thread appearance) to be removed') !==
        -1
      "
    >
      <mat-label>Removal of Drain Sutures Date</mat-label>
      <input
        matInput
        type="date"
        formControlName="drainSuturesRemovalDate"
        class="date-placeholder"
      />
    </mat-form-field>

    <mat-form-field appearance="fill" class="notes-field half-width-field">
      <mat-label>Additional resources provided at discharge</mat-label>
      <mat-select multiple formControlName="additionalResources">
        <mat-option
          *ngFor="let additionalResource of additionalResourcesList"
          [value]="additionalResource"
        >
          {{ additionalResource }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container *ngIf="form$ | async as form">
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Responsible surgeon </mat-label>
        <mat-select formControlName="responsibleSurgeon">
          <!-- <mat-option value="Ms Emma Beddow">Ms Emma Beddow</mat-option>
                    <mat-option value="Prof. Vladimir Anikin">Prof. Vladimir Anikin</mat-option>
                    <mat-option value="Mr Nizar Asadi">Mr Nizar Asadi</mat-option>
                    <mat-option value="Mr Jonathan Finch">Mr Jonathan Finch</mat-option> -->
          <mat-option
            [value]="responsibleSurgeon"
            *ngFor="let responsibleSurgeon of form.responsibleSurgeon.sort()"
          >
            {{ responsibleSurgeon }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="responsibleSurgeon.invalid && responsibleSurgeon.touched"
        >
          Please select a surgeon
        </mat-error>
      </mat-form-field>
    </ng-container>

    <mat-form-field appearance="fill" class="notes-field half-width-field">
      <input
        matInput
        formControlName="notes"
        type="notes"
        placeholder="Comments *"
        autocomplete="off"
      />
      <mat-error *ngIf="notes.invalid && notes.touched">
        Please enter a maximum of 400 characters
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" class="half-width-field">
      <input
        matInput
        type="text"
        formControlName="staffProvidingPad"
        autocomplete="off"
        placeholder="Staff providing PaD *"
      />
      <mat-error *ngIf="staffProvidingPad.invalid && staffProvidingPad.touched">
        Please enter a staff providing PaD
      </mat-error>
    </mat-form-field>

    <br />

    <mat-radio-group
      class="half-width-field"
      appearance="fill"
      formControlName="chaperonePresent"
    >
      <mat-label>Chaperone present </mat-label>
      <mat-radio-button value="Yes">Yes</mat-radio-button>
      <mat-radio-button value="No">No</mat-radio-button>
    </mat-radio-group>

    <!-- <br>
        <mat-slide-toggle
            class="sensitive-image-field"
            matInput
            formControlName="sensitiveImage"
            value = "sensitiveImage"
            labelPosition = "after"
            color = 'primary'
            (click)="setSensitiveImage($event.checked)"
        >
        Toggle sensitive image
        </mat-slide-toggle> -->
    <br />
    <button
      mat-raised-button
      class="positive-button"
      type="submit"
      color="accent"
      [disabled]="!valid()"
    >
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
