// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import packageInfo from 'package.json';

export const environment = {
  production: false,
  name: 'staging',
  version: packageInfo?.version,
  url: 'https://staging.portal.isla.health',
  CONSOLE_LOGGING_ENABLED: false,
  sentryDsn: 'https://4f04ca356d934ebd9e97512b3081cb18@o392306.ingest.sentry.io/5239648',
  firebase: {
    apiKey: 'AIzaSyAm99_Aeiula_LheuXaHOiWolZlfBct4uw',
    databaseURL: 'https://isla-staging.firebaseio.com',
    authDomain: 'staging.portal.isla.health',
    projectId: 'isla-staging',
    storageBucket: 'isla-staging.appspot.com',
    messagingSenderId: '372271623848',
    appId: '1:372271623848:web:c979f389b430c300a1dc9d',
    measurementId: 'G-BW23N2JDLG',
  },
  localPatientLookupUrl: 'https://europe-west2-isla-staging.cloudfunctions.net/checkIfPatientExistsInIsla?nhs=',
  patientSearchApi: 'https://staging.portal.isla.health/api/views-engine/graphql',
  submissionsApi: 'https://staging.portal.isla.health/api/submissionsEngine/graphql',
  intercomAppId: 'i8t59v0e',
  region: 'europe-west2',
  nhsIdentifierAvailable: true,
  nhsLoginAvailable: true,
  bulkUpload: {
    submitURL: 'https://bulk-upload-4k643pyzaq-nw.a.run.app/process',
    patientOnlyTemplateURL:
      'https://storage.cloud.google.com/isla-bulk-upload-production/templates/patient-only-template.csv',
    patientAndAppointmentTemplateURL:
      'https://storage.cloud.google.com/isla-bulk-upload-production/templates/patient-and-appointment-template.csv',
    bucketName: 'isla-staging-bulk-upload',
  },
};
