import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { UserIdToUserEmailService } from '../../services/user-id-to-user-email/user-id-to-user-email.service';
@Pipe({
  name: 'userIdToUserEmail',
  standalone: true,
})
export class UserIdToUserEmailPipe implements PipeTransform {
  constructor(private userIdToUserEmailService: UserIdToUserEmailService) {}
  transform(id: string): Observable<string> {
    return this.userIdToUserEmailService.getEmail$(id);
  }
}
