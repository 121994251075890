<div class="field">
  <div>
    <ic-submission
      [collection]="config.data.collection"
      [alertEmailHidden]="true"
      [teamId]="config?.data?.teamId"
      [usedAsModel]="true"
      (submissionTypeEvent)="getSubmissionResponse($event)"
    >
    </ic-submission>
  </div>
</div>
<div class="w-full flex justify-content-end">
  <div class="pr-2">
    <button
      pButton
      type="button"
      label="Close"
      class="p-button-outlined"
      aria-label="Close"
      (click)="closeDialog()"
    ></button>
  </div>
  <div>
    <button
      [disabled]="!confirmSubmissionValid"
      (click)="onSubmit(submissionResponse)"
      label="Confirm"
      data-cy="submission-dialog-positive-button-submission"
      aria-label="Confirm"
      pButton
    ></button>
  </div>
</div>
