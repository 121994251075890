import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { SystemCheckService } from '@ic-monorepo/shared-common';

@Injectable({
  providedIn: 'root',
})
export class MobileGuard implements CanActivate {
  constructor(private systemCheckService: SystemCheckService) {}

  // only make route accessible on Desktop
  canActivate(): boolean {
    return !this.systemCheckService.isMobileOrTablet;
  }
}
