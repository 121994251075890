<form [formGroup]="dermGenNewForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <div class="full-width-field" style="margin: auto">
      <h3>DERMOTOLOGY GENERAL CLINIC NEW FORM</h3>
    </div>

    <div class="question-container">
      <div class="half-width-field verti-center">
        <p>
          Please provide a brief summary of why you have been referred to
          dermatology?
        </p>
      </div>
      <mat-form-field appearance="fill" class="notes-field half-width-field">
        <input
          matInput
          formControlName="briefSummaryWhyYouHaveBeenReferred"
          type="notes"
          placeholder="Please tell us a brief summary here"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field verti-center">
        <p>How has this problem impacted on your life?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <input
          matInput
          formControlName="howHasThisProblemImpactedYourLife"
          type="notes"
          placeholder="Please tell us how this impacted you here"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field verti-center">
        <p>
          Have you ever been told you have any medical problems/diagnoses
          (dermatological or other)?
        </p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="haveYouBeenToldYouHaveMedicalProblems">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      *ngIf="
        dermGenNewForm
          .get('haveYouBeenToldYouHaveMedicalProblems')
          .value.indexOf('Yes') !== -1
      "
    >
      <mat-form-field appearance="fill" class="half-width-field">
        <input
          matInput
          formControlName="haveYouBeenToldYouHaveMedicalProblemsFreeText"
          type="notes"
          placeholder="If yes, please tell us which medical problems"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field verti-center">
        <p>
          What medication do you take? Doses not needed (including over the
          counter, internet bought and herbal remedies)
        </p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <input
          matInput
          formControlName="whatMedicationDoYouTake"
          type="notes"
          placeholder="Please tell us which medication you take here"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field verti-center">
        <p>Do you have any known drug allergies?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="doYouHaveAnyKnownDrugAllergies">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      *ngIf="
        dermGenNewForm
          .get('doYouHaveAnyKnownDrugAllergies')
          .value.indexOf('Yes') !== -1
      "
    >
      <mat-form-field appearance="fill" class="half-width-field">
        <input
          matInput
          formControlName="doYouHaveAnyKnownDrugAllergiesFreeText"
          type="notes"
          placeholder="If yes, please tell us which allergies"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field verti-center">
        <p>What is your occupation?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <input
          matInput
          formControlName="whatIsYourOccupation"
          type="notes"
          placeholder="Please tell us your occupation here"
          autocomplete="off"
        />
      </mat-form-field>
    </div>
    <mat-form-field appearance="fill" class="notes-field half-width-field">
      <input
        matInput
        formControlName="notes"
        type="notes"
        placeholder="Additional comments"
        autocomplete="off"
      />
    </mat-form-field>

    <br />

    <div class="button-group">
      <button
        class="positive-button"
        mat-raised-button
        color="accent"
        type="submit"
      >
        {{ isSubmitting ? 'Submitting...' : 'Submit' }}
      </button>
    </div>
  </div>
</form>
