import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { InputNumberInputEvent } from 'primeng/inputnumber';

@Component({
  selector: 'ic-clinical-form-field-number',
  templateUrl: './clinical-form-field-number.component.html',
})
export class ClinicalFormFieldNumberComponent extends FieldType<FieldTypeConfig> {
  // This fixes issue with PrimeNG number field not validating on input - https://github.com/primefaces/primeng/issues/9132
  updateInput(event: InputNumberInputEvent): void {
    this.field.formControl.patchValue(Number(event.value));
  }
}
