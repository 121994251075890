export default [
  // cchqForm
  'personCompletingForm',

  'puttingOnTrousers',
  'takingOffTrousers',
  'puttingOnShirt',
  'changingDiapers',
  'easeOfSittingOnAToiletSeat',
  'easeOfSittingInABathtubWithOrWithoutAdaptiveEquipment',
  'easeOfBathing',
  'easeOfSelfFeeding',
  'easeOfFeeding',
  'averagePersonalCareScore',

  'easeOfPositioningInAWheelchair',
  'easeOfPositioningInOtherDeviceEgStandingFrame',
  'easeOfTransferringInAndOutOfAWheelchair',
  'easeOfEaseOfPuttingOnOrthosesOrPositioningDevices',
  'easeOfControllingWheelchair',
  'easeOfGettingOutOfACar',
  'easeOfGettingInACar',
  'averagePositioningAndTransferringScore',

  'painOrDiscomfortDuringPositionChanges',
  'painOrDiscomfortDuringDiaperChanges',
  'painOrDiscomfortPreventsParticipationInSchoolVariousProgramsOrOtherActivities',
  'usingPainControlMedicine',
  'sleepsThroughTheNight',
  'averageComfortScore',

  'easeOfExtendingArmsToReachCommunicationDevices',
  'easeOfPlayingAlone',
  'easeOfPlayingWithOtherChildren',
  'easeOfBeingCompletelyUnderstoodByThoseWhoKnowThemWell',
  'problemWithDrooling',
  'selfEsteem',
  'describeChild',
  'averageInteractionAndCommunicationScore',

  'changeInPersonalCare',
  'changeInPersonalCareComments',
  'changeInPositioningOrTransferring',
  'changeInPositioningOrTransferringComments',
  'changeInComfort',
  'changeInComfortComments',
  'changeInInteractionAndCommunication',
  'changeInInteractionAndCommunicationComments',
  'overallAverageCchqScores',

  'notes'
];
