import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SystemCheckService, UUIDService } from '@ic-monorepo/shared-common';
import {
  BucketStorageService,
  ClinicianSubmissionState,
  FormState,
  MediaService,
  StoredFile,
} from '@ic-monorepo/shared-submissions';
import { CaptureType, ConsentedUser, Submission } from '@islacare/ic-types';
import { retryBackoff } from 'backoff-rxjs';
import firebase from 'firebase/compat/app';
import { MenuItem } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  Observable,
  Subject,
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  forkJoin,
  from,
  map,
  of,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import { SubmissionFlowMedia, UploadStatus } from '../../../feature-patient-submission/store/submission-flow.store';
import { CollectionsService } from '../../../services/collections/collections.service';
import { OrganisationFeatureToggleService } from '../../../services/organisation-feature-toggle/organisation-feature-toggle.service';
import { ToastService, ToastType } from '../../../shared/services/toast/toast.service';
import { FileTypeHelper } from '../../../utils/helpers/file-type/file-type.helper';
import { ClinicianSubmissionCaptureComponent } from '../../components/clinician-submission-capture/clinician-submission-capture.component';
import { ClinicianSubmissionDialogEditItemComponent } from '../../components/clinician-submission-dialog-edit-item/clinician-submission-dialog-edit-item.component';
import { ClinicianSubmissionStore } from '../../store/clinician-submission.store';
import { Consent } from '../submission-flow-controls/submission-flow-controls.service';
import { EntryItem, EntryItemSnapshot } from '../submission-flow/submission-flow.service';

@Injectable({
  providedIn: 'root',
})
export class ClinicianSubmissionService extends FileTypeHelper {
  private _uploader = new Subject<boolean>();
  get uploader$(): Observable<boolean> {
    return this._uploader.asObservable();
  }
  uploaderClick(): void {
    this._uploader.next(true);
  }

  private _dialogRef: DynamicDialogRef;
  set dialogRef(dialogRef: DynamicDialogRef) {
    this._dialogRef = dialogRef;
  }
  get dialogRef(): DynamicDialogRef {
    return this._dialogRef;
  }

  getCaptureMenuItems$(includeUploads: boolean): Observable<MenuItem[]> {
    const isIOS = this.systemCheck.isIOS;
    const defaultMenuItems: MenuItem[] = [
      {
        label: 'Capture photo',
        icon: 'pi pi-camera',
        id: 'capture-photo',
        command: () => {
          isIOS ? this.uploaderClick() : this.openCaptureMediaDialog(CaptureType.PHOTO);
        },
      },
      {
        label: 'Capture video',
        icon: 'pi pi-video',
        id: 'capture-video',
        command: () => {
          isIOS ? this.uploaderClick() : this.openCaptureMediaDialog(CaptureType.VIDEO);
        },
      },
    ];

    const captureUploadMenuItem: MenuItem = {
      label: 'Upload files',
      icon: 'pi pi-upload',
      command: () => this.uploaderClick(),
    };

    const captureSoundRecordingMenuItem: MenuItem = {
      label: 'Capture sound',
      icon: 'pi pi-microphone',
      command: () => this.openCaptureMediaDialog(CaptureType.SOUND_RECORDING),
    };

    return this.organisationFeatureToggleService.soundRecordingEnabled$.pipe(
      take(1),
      map(isSoundRecordingEnabled => {
        const menuItems: MenuItem[] = [...defaultMenuItems];

        if (isSoundRecordingEnabled) {
          menuItems.push(captureSoundRecordingMenuItem);
        }

        if (includeUploads) {
          menuItems.unshift(captureUploadMenuItem);
        }

        return menuItems;
      }),
    );
  }

  constructor(
    private organisationFeatureToggleService: OrganisationFeatureToggleService,
    private clinicianSubmissionStore: ClinicianSubmissionStore,
    private collectionsService: CollectionsService,
    private dialogService: DialogService,
    private toastService: ToastService,
    private mediaService: MediaService,
    private uuidService: UUIDService,
    private sanitizer: DomSanitizer,
    private bucketStorageService: BucketStorageService,
    private systemCheck: SystemCheckService,
  ) {
    super();
  }

  /**
   * This function is used to capture media using the user's webcam or microphone (depending on the capture type)
   * @param captureType - The captureType that the dialog needs to display to the user such as 'PHOTO' or 'VIDEO' - see the 'CaptureType' enum
   */
  openCaptureMediaDialog(captureType: CaptureType): void {
    const dialogRef = this.dialogService.open(ClinicianSubmissionCaptureComponent, {
      header: `Capture ${this.getCaptureTypeFriendly(captureType)}`,
      styleClass: 'md:w-7 w-10',
      data: {
        captureType: captureType,
      },
    });

    dialogRef.onClose.subscribe((media: SubmissionFlowMedia) => {
      if (media) {
        this.clinicianSubmissionStore.insertAttachedFile({
          ...media,
          id: this.uuidService.generateUUID(),
        });
      }
    });
  }

  /**
   * Take a FileList object and inserts the objects into the store under a new object type that contains additional fields such as captureType, notes & the sensitive boolean
   * @param fileList - the list of files that gets selected via the 'File Upload' native input
   */
  storeFiles(fileList: FileList): void {
    const files: SubmissionFlowMedia[] = [];

    for (const file of Array.from(fileList)) {
      if (this.isFileSizeAndTypeValid(file)) {
        const captureType: CaptureType = this.getCaptureTypeForFileTypePath(file.type);
        const submissionFlowMedia: SubmissionFlowMedia = {
          id: this.uuidService.generateUUID(),
          file,
          url: '',
          safeUrl: this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file)),
          captureType: captureType,
          notes: '',
          sensitive: false,
        };
        files.push(submissionFlowMedia);
      }
    }

    this.clinicianSubmissionStore.insertAttachedFiles(files);
  }

  /**
   * Submits one attached file to the Clinician Submission flow and flags it as 'Uploaded' once it has been uploaded successfully
   * @param fullState - The full state for the Clinician Submission flow
   * @param media - A single media attachment item
   */
  submitAttachedFile(fullState: ClinicianSubmissionState, media: SubmissionFlowMedia): void {
    this.clinicianSubmissionStore.updateAttachedFileStatus(media, UploadStatus.UPLOADING);

    const entryItems = this.getMediaEntryItemsToSubmit(fullState, [media]);

    this.uploadEntries$(entryItems).subscribe({
      next: () => {
        this.toastService.open(ToastType.Success, `File uploaded successfully!`);
        this.clinicianSubmissionStore.updateAttachedFileStatus(media, UploadStatus.UPLOADED);
        this.clinicianSubmissionStore.clearSubmitting();
      },
      error: (err: Error) => {
        console.error(err);
        this.toastService.open(ToastType.Error, `Error while uploading file`);
        this.clinicianSubmissionStore.updateAttachedFileStatus(media, UploadStatus.WAITING);
      },
    });
  }

  /**
   * Submits all attachments (media & forms) and closes the dialog
   * @param fullState - The full state for the Clinician Submission flow
   */
  completeSubmission(fullState: ClinicianSubmissionState): void {
    this.clinicianSubmissionStore.setSubmitting();

    const waitingToBeUploadedAttachedFiles = fullState.attachedFiles.filter(
      file => file.uploadStatus !== UploadStatus.UPLOADED,
    );

    waitingToBeUploadedAttachedFiles.forEach(file => {
      this.clinicianSubmissionStore.updateAttachedFileStatus(file, UploadStatus.UPLOADING);
    });

    const formEntryItems = this.getFormEntryItemsToSubmit(fullState);

    let entryItems: EntryItem[] = [formEntryItems];
    entryItems = entryItems.concat(this.getMediaEntryItemsToSubmit(fullState, waitingToBeUploadedAttachedFiles));

    this.toastService.open(
      ToastType.Success,
      `Attachments are being submitted. The submission dialog will close once all uploads are complete`,
    );

    this.updateCollectionDefaultForms(fullState.patient.id, fullState.collection.id, fullState.selectedForms);

    this.uploadEntries(entryItems);
    this.dialogRef.close();
  }

  /**
   * Uploads entry items and handles the response
   * @param entryItems - An array of entry items to upload
   */
  uploadEntries(entryItems: EntryItem[]): void {
    const entryItemsToUpload = entryItems.filter(entry => entry?.media || entry?.snapshot?.formData);

    this.uploadEntries$(entryItemsToUpload).subscribe({
      next: result => {
        if (result.length > 0) {
          this.toastService.open(ToastType.Success, `Successfully uploaded ${entryItemsToUpload.length} attachments`);
          console.log('completeSubmission() completed!');
        } else {
          this.clinicianSubmissionStore.setError(
            `An error occured while uploading all attachments (completeSubmission())`,
          );
          console.error('completeSubmission() failed.');
        }
      },
      error: err => {
        this.clinicianSubmissionStore.setError(
          `An error occured while uploading attachments (completeSubmission()): ${err.message}`,
        );
        console.error('completeSubmission() error: ', err);
      },
    });
  }

  /**
   * Updates the collection's default forms
   * @param patientId - A patient ID
   * @param collectionId - A collection ID
   * @param selectedForms - An array of selected forms state
   */
  updateCollectionDefaultForms(patientId: string, collectionId: string, selectedForms: FormState[]): void {
    const defaultForms = selectedForms.map(form => form.formId);
    this.collectionsService.updateCollection(patientId, collectionId, {
      submission: { formIds: defaultForms } as Submission,
    });
  }

  /**
   * Converts the media files into EntryItems to be uploaded to the collection / folder
   * @param fullState - The full state for the Clinician Submission flow
   * @param media - An array of media attachment items
   */
  getMediaEntryItemsToSubmit(fullState: ClinicianSubmissionState, media: SubmissionFlowMedia[]): EntryItem[] {
    const entryItems: EntryItem[] = [];
    media.forEach(mediaItem => {
      const snapshot = this.getBaseSnapshotWithDefaultNotesForm(fullState, mediaItem);

      entryItems.push({
        media: mediaItem,
        snapshot: snapshot,
        formOnly: false,
      } as EntryItem);
    });
    return entryItems;
  }

  /**
   * Converts the forms into EntryItems to be uploaded to the collection / folder
   * @param fullState - The full state for the Clinician Submission flow
   */
  getFormEntryItemsToSubmit(fullState: ClinicianSubmissionState): EntryItem {
    const formsToSubmit = fullState.selectedForms.map(form => form.formData);

    const reducedFormData = fullState.selectedForms.reduce(
      (acc, currentFormData) => {
        acc[currentFormData.formId] = currentFormData.formData;
        return acc;
      },
      formsToSubmit.length > 0 ? {} : null,
    );

    const updatedSnapshotWithReducedForms = {
      ...this.getBaseSnapshot(fullState, true),
      formData: reducedFormData,
    };

    return {
      media: null,
      snapshot: updatedSnapshotWithReducedForms,
      formOnly: true,
    };
  }

  /**
   * Returns the base EntryItemSnapshot used when converting forms to EntryItems
   * @param fullState - The full state for the Clinician Submission flow
   * @param formOnly - Optional param that is used when called by getFormEntryItemsToSubmit()
   */
  getBaseSnapshot(fullState: ClinicianSubmissionState, formOnly?: boolean): EntryItemSnapshot {
    const snapshot = {
      patientId: fullState.patient.id,
      collectionId: fullState.collection.id,
      formData: null,
      consentedUser: ConsentedUser.AUTH_USER,
      consentBestInterest: fullState.consent === Consent.CONSENT_BEST_INTEREST,
      formOnly: formOnly,
    };

    return snapshot;
  }

  /**
   * Returns the base EntryItemSnapshot used when converting media to EntryItems
   * @param fullState - The full state for the Clinician Submission flow
   * @param mediaItem - A single media attachment item
   */
  getBaseSnapshotWithDefaultNotesForm(
    fullState: ClinicianSubmissionState,
    mediaItem: SubmissionFlowMedia,
  ): EntryItemSnapshot {
    return {
      ...this.getBaseSnapshot(fullState, false),
      formData: {
        default: {
          notes: mediaItem.notes?.toString() ?? '',
          sensitiveImage: mediaItem.sensitive,
          formId: 'default',
          index: 0,
        },
      },
    };
  }

  uploadEntries$(entryItems: EntryItem[]): Observable<string[]> {
    entryItems = entryItems.filter(entryItem => entryItem.snapshot.formData !== null);

    this.clinicianSubmissionStore.setSubmitting();

    const uploadObservables = entryItems.map((entryItem, index) =>
      this.submitEntry$(entryItem.media, entryItem.snapshot as EntryItemSnapshot, entryItem.formOnly, index).pipe(
        catchError(err => {
          this.clinicianSubmissionStore.setError(err.message);
          this.clinicianSubmissionStore.clearSubmitting();
          return throwError(() => new Error(err));
        }),
      ),
    );

    return forkJoin(uploadObservables).pipe(
      catchError(err => {
        console.error('Upload error:', err.message);
        return of([]);
      }),
    );
  }

  submitEntry$(media: SubmissionFlowMedia, snapshot: EntryItemSnapshot, formOnly: boolean, entryIndex: number) {
    const hasImageAsDataUrl = media ? 'imageAsDataUrl' in media : false;
    const { file, sensitive, captureType } = media ?? {};
    const currentDateTime = new Date();

    return from(
      this.mediaService.storeMedia(
        snapshot.patientId,
        snapshot.collectionId,
        file,
        formOnly,
        captureType === CaptureType.VIDEO,
        media ? sensitive : false,
        !!hasImageAsDataUrl,
        snapshot.formData,
        Object.keys(snapshot.formData),
        snapshot.consentedUser,
        snapshot.consentBestInterest,
        null,
        new Date(currentDateTime.setSeconds(currentDateTime.getSeconds() - entryIndex)),
      ),
    ).pipe(
      tap(result => {
        console.log('Media stored successfully:', result);
        if (!formOnly) {
          this.clinicianSubmissionStore.updateAttachedFileStatus(media, UploadStatus.UPLOADED);
        }
      }),
      tap({
        error: (err: Error) => {
          console.error(err);
        },
      }),
    );
  }

  /**
   * Opens up a dialog displaying file and notes form and returns DynamicDialogRef
   * @param media - A single SubmissionFlowMedia item type
   */
  openEditDialog(media: SubmissionFlowMedia): DynamicDialogRef {
    return this.dialogService.open(ClinicianSubmissionDialogEditItemComponent, {
      styleClass: 'md:w-6 w-11',
      showHeader: false,
      closable: false,
      data: media,
    });
  }

  private isFileSizeAndTypeValid(file: File): boolean {
    const exceptedFileTypes = this.allAcceptableFileTypes();

    if (file.size > this.maxClinicianSizeInBytes) {
      this.toastService.open(ToastType.Error, `${file.name} failed to upload. File size is over 2GB`);
      return false;
    }

    if (!exceptedFileTypes.includes(file.type)) {
      this.toastService.open(ToastType.Error, `${file.name} failed to upload. File type is not supported`);
      return false;
    }

    return true;
  }

  cleanUpTempFiles(patientId: string, collectionId: string): void {
    const pathPrefix = `patients/${patientId}/collections/${collectionId}/`;

    this.collectionsService
      .getCollection$(patientId, collectionId)
      .pipe(
        take(1),
        map(collection => collection?.tempFiles ?? []),
        switchMap(tempFiles =>
          forkJoin(
            tempFiles.map(tempFile =>
              this.bucketStorageService.delete$(pathPrefix + tempFile).pipe(catchError(() => of({}))),
            ),
          ),
        ),
        switchMap(() =>
          this.collectionsService.updateCollection(patientId, collectionId, {
            tempFiles: [],
          }),
        ),
      )
      .subscribe();
  }

  removeTempFileFromCollection(fileName: string): void {
    this.clinicianSubmissionStore.full$
      .pipe(
        take(1),
        switchMap(full =>
          this.collectionsService.updateCollection(full.patient.id, full.collection.id, {
            tempFiles: firebase.firestore.FieldValue.arrayRemove(fileName) as any,
          }),
        ),
        take(1),
      )
      .subscribe();
  }

  watchCollectionForTempFiles$(): Observable<SubmissionFlowMedia[]> {
    return this.clinicianSubmissionStore.full$.pipe(
      switchMap(full =>
        this.collectionsService.getCollection$(full.patient.id, full.collection.id).pipe(
          map(collection => collection.tempFiles),
          distinctUntilChanged((prev, curr) => prev === curr),
          debounceTime(1000),
          switchMap(tempFiles =>
            this.getTempFiles$(full.patient.id, full.collection.id, tempFiles).pipe(
              map(tempFileUrls =>
                tempFileUrls.map(
                  tempFile =>
                    ({
                      id: tempFile.name,
                      file: tempFile.file,
                      url: tempFile.url,
                      safeUrl: this.sanitizer.bypassSecurityTrustResourceUrl(tempFile.url),
                      captureType: this.getCaptureTypeForFileTypePath(tempFile.fileType),
                      notes: null,
                      sensitive: false,
                    }) as SubmissionFlowMedia,
                ),
              ),
            ),
          ),
          tap(tempFiles => {
            const tempFilesNotInStore = tempFiles.filter(
              tempFile => !full.attachedFiles.some(attachedFile => attachedFile.file.name === tempFile.file.name),
            );
            if (tempFilesNotInStore.length) {
              this.clinicianSubmissionStore.insertAttachedFiles(tempFilesNotInStore);
            }
          }),
        ),
      ),
    );
  }

  createTempFileForCompanionMode$(patientId: string, collectionId: string, file: File) {
    const uuid = this.uuidService.generateUUID();
    const fileName = `temp-${uuid}`;
    const tempStoragePath = `/patients/${patientId}/collections/${collectionId}/${fileName}`;
    return this.bucketStorageService.create$(tempStoragePath, file).pipe(
      switchMap(() =>
        this.collectionsService.updateCollection(patientId, collectionId, {
          tempFiles: firebase.firestore.FieldValue.arrayUnion(fileName) as any,
        }),
      ),
    );
  }

  private getTempFiles$(patientId: string, collectionId: string, tempFiles: string[]): Observable<StoredFile[]> {
    if (!tempFiles?.length) return of([]);

    const pathPrefix = `patients/${patientId}/collections/${collectionId}/`;

    // Create an array of observables for each temp file
    const observables = tempFiles.map(tempFile =>
      this.bucketStorageService.getStoredFile$(pathPrefix + tempFile).pipe(
        retryBackoff({
          initialInterval: 300,
          maxRetries: 6,
        }),
      ),
    );

    // Combine the observables into a single observable
    return combineLatest(observables);
  }
}
