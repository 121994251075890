import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Form } from '@islacare/ic-types';
import { Observable, first, map, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormIdToNameService {
  private formNameStore = new Map<string, string>();

  constructor(private db: AngularFirestore) {}

  getFormName$(id: string): Observable<string> {
    if (this.formNameStore.has(id)) return of(this.formNameStore.get(id)).pipe(map(name => name || id));

    return this.getFormNameFromDb$(id);
  }

  private getFormNameFromDb$(id: string): Observable<string> {
    return this.db
      .doc<Form>(`formData/${id}`)
      .get()
      .pipe(
        first(),
        map(doc => doc.data()),
        map(form => ({ id, name: form?.name ?? id })),
        tap(({ id, name }) => this.storeName(id, name)),
        map(({ name }) => name || ''),
      );
  }

  private storeName(id: string, name: string): void {
    this.formNameStore.set(id, name);
  }
}
