import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { SubmissionDashboardState } from '../interfaces/submission-dashboard-state.interface';

export const defaultState: SubmissionDashboardState = {
  createdAtRange: null,
  formFilter: null,
  pagination: null,
  requiresAttention: null,
  submittedBy: null,
  columnFilters: [],
  tags: [],
  teamIds: [],
  collectionTemplateIds: [],
  eprStatus: null,
  consentOptions: null,
};

@Injectable()
export class SubmissionDashboardStore extends ComponentStore<SubmissionDashboardState> {
  readonly submissionFilter$: Observable<SubmissionDashboardState> = this.select((state: SubmissionDashboardState) => {
    return state;
  });

  constructor() {
    super(defaultState);
  }

  setSubmissionFilter = this.updater((state: SubmissionDashboardState, filter: Partial<SubmissionDashboardState>) => {
    return {
      ...state,
      ...filter,
    };
  });

  patchSubmissionFilter(partialFilter: Partial<SubmissionDashboardState>): void {
    this.patchState((state: SubmissionDashboardState) => {
      if (partialFilter?.columnFilters) {
        partialFilter.columnFilters.forEach(column => {
          column.formId = state.formFilter[0].id;
        });
      }

      return {
        ...state,
        ...partialFilter,
      };
    });
  }

  clearSubmissionStore = this.updater(() => {
    return defaultState;
  });
}
