import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { FormWithId } from '@islacare/ic-types';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';
import { Observable } from 'rxjs';

@Component({
  standalone: true,
  selector: 'ic-poem-form',
  templateUrl: './poem-form.component.html',
  styleUrls: ['./poem-form.component.scss'],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class PoemFormComponent implements OnInit {
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  poemForm: UntypedFormGroup;
  isSubmitting = false;
  isSensitive: boolean;
  poemQuestion1: number;
  poemQuestion2: number;
  poemQuestion3: number;
  poemQuestion4: number;
  poemQuestion5: number;
  poemQuestion6: number;
  poemQuestion7: number;
  form$: Observable<FormWithId>;

  answerList: string[] = [
    'No days',
    '1-2 days',
    '3-4 days',
    '5-6 days',
    'Everyday',
  ];

  ngOnInit(): void {
    this.poemForm = this.fb.group({
      howManyDaysSkinBeenItchy: ['', Validators.required],
      howManyDaysSleepBeenDisturbed: ['', Validators.required],
      howManyDaysSkinBeenBleeding: ['', Validators.required],
      howManyDaysSkinBeenOozingClearFluid: ['', Validators.required],
      howManyDaysSkinBeenCracked: ['', Validators.required],
      howManyDaysSkinBeenFlaking: ['', Validators.required],
      howManyDaysSkinFeltDryOrRough: ['', Validators.required],
      notes: [''],
      totalPoemScore: [''],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.poemForm.controls[key])
          this.poemForm.controls[key].setValue(formData[key]);
      });
    });
    this.form$ = this.form.getForm(this.data.teamId, this.data.formId);
  }

  get howManyDaysSkinBeenItchy() {
    return this.poemForm.get('howManyDaysSkinBeenItchy');
  }

  get howManyDaysSleepBeenDisturbed() {
    return this.poemForm.get('howManyDaysSleepBeenDisturbed');
  }

  get howManyDaysSkinBeenBleeding() {
    return this.poemForm.get('howManyDaysSkinBeenBleeding');
  }

  get howManyDaysSkinBeenOozingClearFluid() {
    return this.poemForm.get('howManyDaysSkinBeenOozingClearFluid');
  }

  get howManyDaysSkinBeenCracked() {
    return this.poemForm.get('howManyDaysSkinBeenCracked');
  }

  get howManyDaysSkinBeenFlaking() {
    return this.poemForm.get('howManyDaysSkinBeenFlaking');
  }

  get howManyDaysSkinFeltDryOrRough() {
    return this.poemForm.get('howManyDaysSkinFeltDryOrRough');
  }

  onSubmit() {
    if (!this.poemFormValid) return;
    this.setPoemFormScore();
    this.isSubmitting = true;
    const formResponse = {
      ...this.poemForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  poemFormValid(): boolean {
    return (
      this.poemForm.dirty &&
      !(
        this.poemForm.controls &&
        this.poemForm.controls['howManyDaysSkinBeenItchy'].errors
      ) &&
      !(
        this.poemForm.controls &&
        this.poemForm.controls['howManyDaysSleepBeenDisturbed'].errors
      ) &&
      !(
        this.poemForm.controls &&
        this.poemForm.controls['howManyDaysSkinBeenBleeding'].errors
      ) &&
      !(
        this.poemForm.controls &&
        this.poemForm.controls['howManyDaysSkinBeenOozingClearFluid'].errors
      ) &&
      !(
        this.poemForm.controls &&
        this.poemForm.controls['howManyDaysSkinBeenCracked'].errors
      ) &&
      !(
        this.poemForm.controls &&
        this.poemForm.controls['howManyDaysSkinBeenFlaking'].errors
      ) &&
      !(
        this.poemForm.controls &&
        this.poemForm.controls['howManyDaysSkinFeltDryOrRough'].errors
      )
    );
  }

  getQuestionScore(value) {
    let questionScore: number;

    switch (value) {
      case 'No days':
        return (questionScore = 0);
      case '1-2 days':
        return (questionScore = 1);
      case '3-4 days':
        return (questionScore = 2);
      case '5-6 days':
        return (questionScore = 3);
      case 'Everyday':
        return (questionScore = 4);
    }

    return questionScore;
  }

  setPoemFormScore() {
    const totalPoemScore =
      this.poemQuestion1 +
      this.poemQuestion2 +
      this.poemQuestion3 +
      this.poemQuestion4 +
      this.poemQuestion5 +
      this.poemQuestion6 +
      this.poemQuestion7;

    this.poemForm.get('totalPoemScore').setValue(totalPoemScore);
  }
}
