<ng-container *ngIf="automaticForm && formFields">
  <form [formGroup]="automaticForm" (ngSubmit)="onSubmit()">
    <div class="form-formatting" id="form-container" #elements>
      <ng-container *ngFor="let field of formFields">
        <ng-container *ngIf="isLoggedInUser(field)">
          <ng-container *ngIf="shouldShowContainer(field)">
            <ng-container *ngIf="!this.form.hasSections">
              <ic-automatic-form-reference
                *ngIf="
                  field.inputType !== ContentInputType.FORM_REFERENCE &&
                  field.inputType !== UserInputType.BODY_MAP &&
                  field.inputType !== UserInputType.IMAGE_MAP
                "
                [field]="field"
                [control]="automaticForm.controls"
                [automaticForm]="automaticForm"
                [formFields]="formFields"
                [referencedFormFields]="referencedFormFields"
                (bmiResponseChange)="updateBmiResponse($event)"
                (tallyObject)="updateTallies($event)"
                [id]="field.controlName"
                [ngClass]="{
                  'static-margin':
                    ContentInputTypes.includes(field.inputType) &&
                    field.inputType !== ContentInputType.IMAGES
                }"
                #elements
              >
              </ic-automatic-form-reference>
              <ic-automatic-image-map
                *ngIf="
                  (field.inputType === UserInputType.BODY_MAP ||
                    field.inputType === UserInputType.IMAGE_MAP) &&
                  (!isEditUrl || (isEditUrl && formData))
                "
                [field]="field"
                [setData]="formData"
                [id]="field.controlName"
                #elements
              ></ic-automatic-image-map>
              <ic-automatic-form-array
                *ngIf="
                  field.inputType === UserInputType.FORM_ARRAY &&
                  (!isEditUrl || (isEditUrl && formData))
                "
                [field]="field"
                [control]="$any(automaticForm.get(field.controlName))"
                [formGroup]="automaticForm"
                [setData]="formData && formData[field.controlName]"
                (formArrayScores)="
                  addToReferencedForms(
                    $event,
                    field.formArrayFields,
                    $any(automaticForm.get(field.controlName)).value,
                    field.controlName,
                    automaticForm
                  )
                "
                (arrayHeight)="addToValidationMarker($event, field.controlName)"
                [id]="field.controlName"
                #elements
              ></ic-automatic-form-array>
              <ng-container *ngIf="this.referencedFormFields[field.formId]">
                <ng-container
                  *ngFor="
                    let formField of referencedFormFields[field.formId].formFieldsOrderedArray
                  "
                >
                  <ic-automatic-form-reference
                    *ngIf="field.inputType === ContentInputType.FORM_REFERENCE"
                    [field]="formField"
                    [control]="referencedFormFields[field.formId].automaticForm.controls"
                    [automaticForm]="referencedFormFields[field.formId].automaticForm"
                    [formFields]="referencedFormFields[field.formId].formFieldsOrderedArray"
                    [id]="formField.controlName"
                    #elements
                  >
                  </ic-automatic-form-reference>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="this.form.hasSections">
        <mat-horizontal-stepper linear="true" #stepper>
          <ng-container *ngFor="let section of sections">
            <mat-step
              *ngIf="
                section.inputType === ContentInputType.FORM_REFERENCE &&
                referencedFormFields[section.formId]
              "
              [stepControl]="referencedFormFields[section.formId].automaticForm"
            >
              <ng-template matStepContent>
                <ng-container
                  *ngFor="
                    let formField of referencedFormFields[section.formId].formFieldsOrderedArray
                  "
                >
                  <ng-template matStepLabel>{{ section.title }}</ng-template>
                  <ic-automatic-form-reference
                    [field]="formField"
                    [formId]="section.formId"
                    [control]="referencedFormFields[section.formId].automaticForm.controls"
                    [automaticForm]="referencedFormFields[section.formId].automaticForm"
                    [formFields]="referencedFormFields[section.formId].formFieldsOrderedArray"
                    [referencedFormFields]="referencedFormFields"
                    [id]="formField.controlName"
                    #elements
                  >
                  </ic-automatic-form-reference>
                  <ic-automatic-form-array
                    *ngIf="
                      formField.inputType === UserInputType.FORM_ARRAY &&
                      (!isEditUrl || (isEditUrl && formData)) &&
                      shouldShowContainer(formField, referencedFormFields[section.formId])
                    "
                    [field]="formField"
                    [control]="
                      $any(
                        referencedFormFields[section.formId].automaticForm.get(
                          formField.controlName
                        )
                      )
                    "
                    [formGroup]="referencedFormFields[section.formId].automaticForm"
                    [setData]="referencedFormData && referencedFormData[formField.controlName]"
                    (formArrayScores)="
                      addToReferencedForms(
                        $event,
                        formField.formArrayFields,
                        $any(
                          referencedFormFields[section.formId].automaticForm.get(
                            formField.controlName
                          )
                        ).value,
                        formField.controlName,
                        referencedFormFields[section.formId].automaticForm,
                        section.formId
                      )
                    "
                    (arrayHeight)="addToValidationMarker($event, formField.controlName)"
                    [id]="formField.controlName"
                    #elements
                  ></ic-automatic-form-array>
                  <ic-automatic-image-map
                    *ngIf="
                      (formField.inputType === UserInputType.BODY_MAP ||
                        formField.inputType === UserInputType.IMAGE_MAP) &&
                      (!isEditUrl || (isEditUrl && formData)) &&
                      shouldShowContainer(formField, referencedFormFields[section.formId])
                    "
                    [field]="formField"
                    [setData]="referencedFormData"
                    [id]="formField.controlName"
                    [formId]="section.formId"
                    #elements
                  ></ic-automatic-image-map>
                </ng-container>
                <button
                  *ngIf="!section.isFirstPage"
                  mat-raised-button
                  class="positive-button"
                  matStepperPrevious
                  data-cy="sectionBack"
                >
                  Back
                </button>
                <button
                  *ngIf="!section.isLastPage"
                  mat-raised-button
                  class="positive-button"
                  type="button"
                  data-cy="sectionNext"
                  (click)="
                    validateSection(
                      referencedFormFields[section.formId].automaticForm,
                      stepper,
                      referencedFormFields[section.formId].formFieldsOrderedArray
                    )
                  "
                  [ngStyle]="{
                    backgroundColor: referencedFormFields[section.formId].automaticForm.valid
                      ? '#EE7325'
                      : '#CFD4D4'
                  }"
                >
                  Next
                </button>
                <button
                  *ngIf="section.isLastPage"
                  mat-raised-button
                  class="positive-button"
                  type="submit"
                  data-cy="section-submit"
                  [disabled]="isSubmitting"
                  [ngStyle]="{
                    backgroundColor: referencedFormFields[section.formId].automaticForm.valid
                      ? '#1E5192'
                      : '#CFD4D4'
                  }"
                >
                  {{ isSubmitting ? 'Submitting...' : 'Submit' }}
                </button>
              </ng-template>
            </mat-step>
          </ng-container>
        </mat-horizontal-stepper>
      </ng-container>
      <br />
      <br />

      <button
        *ngIf="!this.form.hasSections"
        mat-raised-button
        class="positive-button"
        type="submit"
        data-cy="autoSubmit"
        [disabled]="isSubmitting"
        [ngStyle]="{ backgroundColor: automaticForm.valid ? '#EE7325' : '#CFD4D4' }"
        id="submit"
        icEventTracker="sf-submit-form"
      >
        {{ isSubmitting ? 'Submitting...' : 'Submit' }}
      </button>
      <p
        class="mat-error"
        *ngIf="validationMarkerArray.length"
        id="errorTitle"
        data-cy="form-error-title"
      >
        Some mandatory questions have not been answered. Please click below to respond to them
      </p>
      <ng-container *ngFor="let validationMarker of validationMarkerArray">
        <p
          *ngIf="validationMarker.fill"
          class="invalid-questions"
          id="errorMessage"
          (click)="scrollTo(validationMarker.controlName)"
        >
          {{ validationMarker.title }}
        </p>
      </ng-container>
      <div class="error-bar">
        <ng-container *ngFor="let validationMarker of validationMarkerArray; let i = index">
          <div
            [ngClass]="{
              'empty-validation': !validationMarker.fill,
              'full-validation': validationMarker.fill
            }"
            (click)="validationMarker.fill ? scrollTo(validationMarker.controlName) : null"
            [ngStyle]="{ height: (validationMarker.height / pageHeight) * 100 + '%' }"
          ></div>
        </ng-container>
      </div>
    </div>
  </form>
</ng-container>
