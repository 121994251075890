import * as libphonenumber from 'google-libphonenumber';

export function phoneInvalid(phoneNumber: string): boolean {
  if (!phoneNumber) return true;

  phoneNumber = phoneNumber.toString();
  // We have existing phone numbers starting 07 in the DB right now, which we don't want to block
  if (phoneNumber?.startsWith('07') && phoneNumber?.length === 11) {
    return false;
  } else {
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

    try {
      // Parse the phone number with 'ZZ' country code to get the country information
      const parsedPhoneNumber = phoneUtil.parse(phoneNumber, 'ZZ');

      // Check if the parsed phone number is valid
      return !phoneUtil.isValidNumber(parsedPhoneNumber);
    } catch (error) {
      // Error occurred while parsing the phone number
      return true;
    }
  }
}

/**
 * @description Check whether if phone number is landline
 * @param phoneNumber
 * @returns
 */
export function isLandlineNumber(phoneNumber: string): boolean {
  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

  try {
    // Parse the phone number with 'ZZ' country code to get the country information
    const parsedPhoneNumber = phoneUtil.parse(phoneNumber.toString(), 'ZZ');

    // Get the type of the phone number
    const numberType: libphonenumber.PhoneNumberType = phoneUtil.getNumberType(parsedPhoneNumber);

    return numberType === libphonenumber.PhoneNumberType.FIXED_LINE;
  } catch (error) {
    // Error occurred while parsing the phone number
    return false;
  }
}
