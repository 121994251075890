import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { LoggingService } from '@ic-monorepo/services';
import {
  CollectionResponse,
  CollectionResponseWithId,
  GroupedResponsesithTeamReference,
} from '@islacare/ic-types';
import firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TeamsService } from './teams/teams.service';

@Injectable({
  providedIn: 'root',
})
export class ResponsesService {
  constructor(
    private db: AngularFirestore,
    private auth: AngularFireAuth,
    private teamsService: TeamsService,
    private log: LoggingService
  ) {}

  myResponseTemplates$: Observable<GroupedResponsesithTeamReference[]> =
    this.teamsService.myTeams$.pipe(
      map((teams) =>
        teams
          .filter((team) => team?.responseTemplates?.length)
          .map((team) => ({
            teamId: team.id,
            teamName: team.name,
            responseTemplates: team.responseTemplates,
          }))
      )
    );

  async save(
    patientId: string,
    collectionId: string,
    comment: string,
    sendToPatient: boolean,
    recipient: string,
    contactMethod: string
  ) {
    const commentId = this.db.createId();
    const user = await this.auth.currentUser;

    const commentDoc = {
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: user.uid,
      createdByEmail: user.email,
      response: comment,
      sendToPatient: sendToPatient,
      recipient: recipient,
      contactMethod: contactMethod,
    };

    await this.db
      .doc<CollectionResponse>(
        `patients/${patientId}/collections/${collectionId}/collectionResponses/${commentId}`
      )
      .set(commentDoc)
      .catch((error) => {
        this.log.consoleError(error);
      });
  }

  getCollectionResponses(
    patientId: string,
    collectionId: string
  ): Observable<CollectionResponseWithId[]> {
    return this.db
      .collection<CollectionResponseWithId>(
        `patients/${patientId}/collections/${collectionId}/collectionResponses`
      )
      .valueChanges({ idField: 'id' });
  }

  update(
    patientId: string,
    collectionId: string,
    responseId: string,
    comment: string
  ) {
    return this.db
      .doc<CollectionResponse>(
        `patients/${patientId}/collections/${collectionId}/collectionResponses/${responseId}`
      )
      .update({ response: comment })
      .catch((error) => {
        this.log.consoleError(error);
      });
  }
}
