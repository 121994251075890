<form [formGroup]="dopplerForm">
  <div class="form-formatting">
    <mat-card>
      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Type of Doppler:</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="typeOfDoppler">
              <mat-option value="Hand Held">Hand Held</mat-option>
              <mat-option value="Automated">Automated</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div
          *ngIf="typeOfDoppler.value === 'Hand Held'"
          class="question-container"
        >
          <div class="half-width-field">
            <p>Megahertz used (Manual):</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="megahertzUsedManual">
              <mat-option value="5 megahertz">5 megahertz</mat-option>
              <mat-option value="8 megahertz">8 megahertz</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Date of measurement:</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <input
              matInput
              type="date"
              formControlName="dateOfMeasurement"
              value="date"
            />
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <br />

    <mat-card>
      <mat-card-title>Left</mat-card-title>

      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Left Brachial Pulse</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="number" formControlName="leftBrachialPulse" />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Left Dorsalis Pedis</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="number" formControlName="leftDorsalisPedis" />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Left Posterior Tibial</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input
              matInput
              type="number"
              formControlName="leftPosteriorTibial"
            />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Left Peroneal</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="number" formControlName="leftPeroneal" />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Left Other</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="number" formControlName="leftOther" />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Left Toe Pulse</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="number" formControlName="leftToePulse" />
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <br />

    <mat-card>
      <mat-card-title>Right</mat-card-title>

      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Right Brachial Pulse</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input
              matInput
              type="number"
              formControlName="rightBrachialPulse"
            />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Right Dorsalis Pedis</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input
              matInput
              type="number"
              formControlName="rightDorsalisPedis"
            />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Right Posterior Tibial</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input
              matInput
              type="number"
              formControlName="rightPosteriorTibial"
            />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Right Peroneal</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="number" formControlName="rightPeroneal" />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Right Other</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="number" formControlName="rightOther" />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Right Toe Pulse</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input matInput type="number" formControlName="rightToePulse" />
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <br />

    <mat-card>
      <mat-card-title>Next assessment</mat-card-title>

      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Next assessment due:</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="nextAssessmentDue">
              <mat-option value="3 months">3 months</mat-option>
              <mat-option value="6 months">6 months</mat-option>
              <mat-option value="9 months">9 months</mat-option>
              <mat-option value="12 months">12 months</mat-option>
              <mat-option value="No longer required"
                >No longer required</mat-option
              >
              <mat-option value="Booked">Booked</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <br />

    <mat-form-field class="full-width-field" appearance="fill">
      <mat-label>Any other comments?</mat-label>
      <textarea matInput formControlName="notes" type="notes"> </textarea>
    </mat-form-field>
    <br />

    <button
      mat-raised-button
      class="positive-button"
      type="submit"
      [disabled]="isSubmitting || !valid()"
      (click)="onSubmit()"
    >
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
