import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { FormField } from '@islacare/ic-types';
import firebase from 'firebase/compat/app';

@Component({
  selector: 'ic-automatic-slider',
  templateUrl: './automatic-slider.component.html',
  styleUrls: ['./automatic-slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AutomaticSliderComponent implements OnInit {
  @Input() field: FormField;
  @Input() control: UntypedFormControl;
  stepWidth;
  constructor(public formService: FormService) {}

  async ngOnInit(): Promise<void> {
    //margin should be the total steps, therefore max-min. Its was not working correct in case of scale starting with -1
    this.stepWidth = `${100 / (+this.field.max - +this.field.min)}%`;

    if (this.field.sliderImages) {
      await this.setImages();
    }
  }
  fontWeight() {
    if (this.field.boldTitle === true) {
      return 'bold';
    }
  }
  textAlign() {
    return this.field.alignment || 'center';
  }

  formatLabel = (value) => {
    if (value == -1 && this.field.offsetSlider) {
      return '';
    }

    return value;
  };

  async setImages() {
    for (const key in this.field.sliderImages) {
      const element = this.field.sliderImages[key];
      const storageRef = firebase.storage().ref(element.storageAddress);
      element.storageSrc = await storageRef.getDownloadURL();
    }
  }
}
