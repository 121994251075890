<ng-select
  [placeholder]="props?.placeholder || 'Please select'"
  [items]="props.options | formlySelectOptions : field | async"
  [formControl]="formControl"
  [formlyAttributes]="field"
  (onChange)="props.change && props.change(field, $event)"
  [clearable]="!props.required"
  bindLabel="label"
  bindValue="value"
  styleClass="w-full"
  [searchable]="true"
>
</ng-select>
