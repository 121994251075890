<mat-horizontal-stepper [linear]="isLinear" #stepper>
  <mat-step [stepControl]="dlqiForm" label="DLQI Form">
    <form [formGroup]="dlqiForm">
      <div class="form-formatting">
        <div class="full-width-field" style="margin: auto">
          <h3>DERMATOLOGY LIFE QUALITY INDEX (DLQI)</h3>
        </div>

        <div class="full-width-field" style="margin: auto">
          <h4>
            The aim of this questionnaire is to measure how much your skin problem has affected you
            life OVER THE LAST WEEK. Please an option from the dropdown lists.
          </h4>
        </div>

        <!--  QUESTION ONE -->
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Over the last week, how <strong>itchy, sore, painful</strong> or
              <strong>stinging</strong> has your skin been?
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="itchySorePainfulOrStingingSkin">
              <mat-option *ngFor="let option of dermatologyAnswerList" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="itchySorePainfulOrStingingSkin.touched">
              Please select an option
            </mat-error>
          </mat-form-field>
        </div>

        <!-- TODO: come back to deciding this? -->
        <!-- <mat-divider class="full-width-field"></mat-divider> -->

        <!--  QUESTION TWO -->
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Over the last week, how <strong>embarrassed</strong> or
              <strong>self conscious</strong> have you been because of your skin?
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="embarrassedOrSelfConscious">
              <mat-option *ngFor="let option of dermatologyAnswerList" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="embarrassedOrSelfConscious.touched">
              Please select an option
            </mat-error>
          </mat-form-field>
        </div>

        <!--  QUESTION THREE -->
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Over the last week, how much has your skin interfered with you going
              <strong>shopping</strong> or looking after your <strong>home</strong> or
              <strong>garden</strong>.
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="interferedWithShoppingHomeOrGarden">
              <mat-option *ngFor="let option of dermatologyAnswerList" [value]="option">
                {{ option }}
              </mat-option>
              <mat-option value="Not relevant - 0">Not relevant - 0</mat-option>
            </mat-select>
            <mat-error *ngIf="interferedWithShoppingHomeOrGarden.touched">
              Please select an option
            </mat-error>
          </mat-form-field>
        </div>

        <!--  QUESTION FOUR -->
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Over the last week, how much has your skin influenced the <strong>clothes</strong> you
              wear?
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="skinInfluencedClothesYouWear">
              <mat-option *ngFor="let option of dermatologyAnswerList" [value]="option">
                {{ option }}
              </mat-option>
              <mat-option value="Not relevant - 0">Not relevant - 0</mat-option>
            </mat-select>
            <mat-error *ngIf="skinInfluencedClothesYouWear.touched">
              Please select an option
            </mat-error>
          </mat-form-field>
        </div>

        <!--  QUESTION FIVE -->
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Over the last week, how much has your skin affected any <strong>social</strong> or
              <strong>leisure</strong> activities
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="skinAffectedSocialOrLeisureActivities">
              <mat-option *ngFor="let option of dermatologyAnswerList" [value]="option">
                {{ option }}
              </mat-option>
              <mat-option value="Not relevant - 0">Not relevant - 0</mat-option>
            </mat-select>
            <mat-error *ngIf="skinAffectedSocialOrLeisureActivities.touched">
              Please select an option
            </mat-error>
          </mat-form-field>
        </div>

        <!--  QUESTION SIX -->
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Over the last week, how much has your skin made it difficult for you to do any
              <strong>sport</strong>?
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="skinAffectedAnySport">
              <mat-option *ngFor="let option of dermatologyAnswerList" [value]="option">
                {{ option }}
              </mat-option>
              <mat-option value="Not relevant - 0">Not relevant - 0</mat-option>
            </mat-select>
            <mat-error *ngIf="skinAffectedAnySport.touched"> Please select an option </mat-error>
          </mat-form-field>
        </div>

        <!--  QUESTION SEVEN -->
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Over the last week, has your skin prevented you from <strong>working</strong> or
              <strong>studying</strong>?
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="skinPreventedWorkingOrStudyingYesNo">
              <mat-option value="Yes - 3">Yes</mat-option>
              <mat-option value="No - 0">No</mat-option>
              <mat-option value="Not relevant - 0">Not relevant</mat-option>
            </mat-select>
            <mat-error *ngIf="skinPreventedWorkingOrStudyingYesNo.touched">
              Please select an option
            </mat-error>
          </mat-form-field>
        </div>

        <!-- QUESTION SEVEN PT 2 -->
        <div
          class="question-container"
          *ngIf="dlqiForm.get('skinPreventedWorkingOrStudyingYesNo').value === 'No'"
        >
          <div class="half-width-field">
            <p>
              Over the last week, how much has your skin prevented you from
              <strong>working</strong> or <strong>studying</strong>?
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="skinPreventedWorkingOrStudying">
              <mat-option value="A lot - 2">A lot - 2</mat-option>
              <mat-option value="A little - 1">A little - 1</mat-option>
              <mat-option value="Not at all - 0">Not at all - 0</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!--  QUESTION EIGHT -->
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Over the last week, how much has your skin created problems with your
              <strong>partner</strong> or any of your <strong>close friends</strong> or
              <strong>relatives</strong>?
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="skinCreatedProblemsWithPartnerFriendsOrFamily">
              <mat-option *ngFor="let option of dermatologyAnswerList" [value]="option">
                {{ option }}
              </mat-option>
              <mat-option value="Not relevant - 0">Not relevant - 0</mat-option>
            </mat-select>
            <mat-error *ngIf="skinCreatedProblemsWithPartnerFriendsOrFamily.touched">
              Please select an option
            </mat-error>
          </mat-form-field>
        </div>

        <!--  QUESTION NINE -->
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Over the last week, how much has your skin cause any
              <strong>sexual difficulties</strong>?
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="skinCausedSexualDifficulties">
              <mat-option *ngFor="let option of dermatologyAnswerList" [value]="option">
                {{ option }}
              </mat-option>
              <mat-option value="Not relevant - 0">Not relevant - 0</mat-option>
            </mat-select>
            <mat-error *ngIf="skinCausedSexualDifficulties.touched">
              Please select an option
            </mat-error>
          </mat-form-field>
        </div>

        <!--  QUESTION TEN -->
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Over the last week, how much of a problem has the <strong>treatment</strong> for your
              skin been, for example by making your home messy, or by taking up time?
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="skinTreatmentBeenAProblem">
              <mat-option *ngFor="let option of dermatologyAnswerList" [value]="option">
                {{ option }}
              </mat-option>
              <mat-option value="Not relevant - 0">Not relevant - 0</mat-option>
            </mat-select>
            <mat-error *ngIf="skinTreatmentBeenAProblem.touched">
              Please select an option
            </mat-error>
          </mat-form-field>
        </div>

        <div>
          <button
            mat-button
            matStepperNext
            style="margin: 10px"
            mat-raised-button
            [disabled]="!dlqiFormValid()"
          >
            Next
          </button>
        </div>
      </div>
    </form>
  </mat-step>

  <mat-step [stepControl]="hadsForm" label="HADS Form">
    <form [formGroup]="hadsForm">
      <!-- (ngSubmit)="onSubmit()" -->
      <div class="form-formatting">
        <div class="full-width-field" style="margin: auto">
          <h3>HOSPITAL ANXIETY AND DEPRESSION SCALE (HADS)</h3>
        </div>
        <div class="full-width-field" style="margin: auto">
          <h4>Choose the option that is closest to how you have been feeling in the past week.</h4>
        </div>

        <!-- D -->
        <!-- QUESTION ONE -->
        <div class="question-container">
          <div class="half-width-field">
            <p>I still enjoy the things I used to enjoy:</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="stillEnjoyThings">
              <mat-option value="Definitely as much - 0">Definitely as much - 0</mat-option>
              <mat-option value="Not quite as much - 1">Not quite as much - 1</mat-option>
              <mat-option value="Only a little - 2">Only a little - 2</mat-option>
              <mat-option value="Hardly at all - 3">Hardly at all - 3</mat-option>
            </mat-select>
            <mat-error *ngIf="stillEnjoyThings.touched"> Please select an option </mat-error>
          </mat-form-field>
        </div>

        <!-- QUESTION TWO -->
        <div class="question-container">
          <div class="half-width-field">
            <p>I can laugh and see the funny side of things:</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="seeFunnySideOfThings">
              <mat-option value="As much as I always could - 0"
                >As much as I always could - 0</mat-option
              >
              <mat-option value="Not quite so much now - 1">Not quite so much now - 1</mat-option>
              <mat-option value="Definitely not so much now - 2"
                >Definitely not so much now - 2</mat-option
              >
              <mat-option value="Not at all - 3">Not at all - 3</mat-option>
            </mat-select>
            <mat-error *ngIf="seeFunnySideOfThings.touched"> Please select an option </mat-error>
          </mat-form-field>
        </div>

        <!-- QUESTION THREE -->
        <div class="question-container">
          <div class="half-width-field">
            <p>I feel cheerful:</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="feelCheerful">
              <mat-option value="Not at all - 3">Not at all - 3</mat-option>
              <mat-option value="Not often - 2">Not often - 2</mat-option>
              <mat-option value="Sometimes - 1">Sometimes - 1</mat-option>
              <mat-option value="Most of the time - 0">Most of the time - 0</mat-option>
            </mat-select>
            <mat-error *ngIf="feelCheerful.touched"> Please select an option </mat-error>
          </mat-form-field>
        </div>

        <!-- QUESTION FOUR -->
        <div class="question-container">
          <div class="half-width-field">
            <p>I feel as if I am slowed down:</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="feelSlowedDown">
              <mat-option value="Nearly all the time - 3">Nearly all the time - 3</mat-option>
              <mat-option value="Very often - 2">Very often - 2</mat-option>
              <mat-option value="Sometimes - 1">Sometimes - 1</mat-option>
              <mat-option value="Not at all - 0">Not at all - 0</mat-option>
            </mat-select>
            <mat-error *ngIf="feelSlowedDown.touched"> Please select an option </mat-error>
          </mat-form-field>
        </div>

        <!-- QUESTION FIVE -->
        <div class="question-container">
          <div class="half-width-field">
            <p>I have lost interest in my appearance:</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="lostInterestInAppearance">
              <mat-option value="Definitely - 3">Definitely - 3</mat-option>
              <mat-option value="I don't take as much care as I should - 2"
                >I don't take as much care as I should - 2</mat-option
              >
              <mat-option value="I may not take quite as much care - 1"
                >I may not take quite as much care - 1</mat-option
              >
              <mat-option value="I take just as much care as ever - 0"
                >I take just as much care as ever - 0</mat-option
              >
            </mat-select>
            <mat-error *ngIf="lostInterestInAppearance.touched">
              Please select an option
            </mat-error>
          </mat-form-field>
        </div>

        <!-- QUESTION SIX -->
        <div class="question-container">
          <div class="half-width-field">
            <p>I look forward with enjoyment to things:</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="lookForwardWithEnjoyment">
              <mat-option value="As much as I ever did - 0">As much as I ever did - 0</mat-option>
              <mat-option value="Rather less than I used to - 1"
                >Rather less than I used to - 1</mat-option
              >
              <mat-option value="Definitely less than I used to - 2"
                >Definitely less than I used to - 2</mat-option
              >
              <mat-option value="Hardly at all - 3">Hardly at all - 3</mat-option>
            </mat-select>
            <mat-error *ngIf="lookForwardWithEnjoyment.touched">
              Please select an option
            </mat-error>
          </mat-form-field>
        </div>

        <!-- QUESTION SEVEN -->
        <div class="question-container">
          <div class="half-width-field">
            <p>I can enjoy a good book or radio or TV program:</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="enjoyABookRadioOrTv">
              <mat-option value="Often - 0">Often - 0</mat-option>
              <mat-option value="Sometimes - 1">Sometimes - 1</mat-option>
              <mat-option value="Not often - 2">Not often - 2</mat-option>
              <mat-option value="Very seldom - 3">Very seldom - 3</mat-option>
            </mat-select>
            <mat-error *ngIf="enjoyABookRadioOrTv.touched"> Please select an option </mat-error>
          </mat-form-field>
        </div>

        <!-- A -->

        <!-- QUESTION ONE -->
        <div class="question-container">
          <div class="half-width-field">
            <p>I feel tense or 'wound up':</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="tenseOrWoundUp">
              <mat-option value="Most of the time - 3">Most of the time - 3</mat-option>
              <mat-option value="A lot of the time - 2">A lot of the time - 2</mat-option>
              <mat-option value="From time to time, occasionally - 1"
                >From time to time, occasionally - 1</mat-option
              >
              <mat-option value="Not at all - 0">Not at all - 0</mat-option>
            </mat-select>
            <mat-error *ngIf="tenseOrWoundUp.touched"> Please select an option </mat-error>
          </mat-form-field>
        </div>

        <!-- QUESTION TWO -->
        <div class="question-container">
          <div class="half-width-field">
            <p>I get a sort of frightened feeling as if something awful is about to happen:</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="frightenedFeelingAboutSomethingAwful">
              <mat-option value="Very definitely and quite badly - 3"
                >Very definitely and quite badly - 3</mat-option
              >
              <mat-option value="Yes, but not too badly - 2">Yes, but not too badly - 2</mat-option>
              <mat-option value="A little, but it doesn't worry me - 1"
                >A little, but it doesn't worry me - 1</mat-option
              >
              <mat-option value="Not at all - 0">Not at all - 0</mat-option>
            </mat-select>
            <mat-error *ngIf="frightenedFeelingAboutSomethingAwful.touched">
              Please select an option
            </mat-error>
          </mat-form-field>
        </div>

        <!-- QUESTION THREE -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Worrying thoughts go through my mind:</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="worryingThoughts">
              <mat-option value="A great deal of the time - 3"
                >A great deal of the time - 3</mat-option
              >
              <mat-option value="A lot of the time - 2">A lot of the time - 2</mat-option>
              <mat-option value="From time to time, but not too often - 1"
                >From time to time, but not too often - 1</mat-option
              >
              <mat-option value="Only occasionally - 0">Only occasionally - 0</mat-option>
            </mat-select>
            <mat-error *ngIf="worryingThoughts.touched"> Please select an option </mat-error>
          </mat-form-field>
        </div>

        <!-- QUESTION FOUR -->
        <div class="question-container">
          <div class="half-width-field">
            <p>I can sit at ease and feel relaxed:</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="sitAtEaseAndFeelRelaxed">
              <mat-option value="Definitely - 0">Definitely - 0</mat-option>
              <mat-option value="Usually - 1">Usually - 1</mat-option>
              <mat-option value="Not Often - 2">Not Often - 2</mat-option>
              <mat-option value="Not at all - 3">Not at all - 3</mat-option>
            </mat-select>
            <mat-error *ngIf="sitAtEaseAndFeelRelaxed.touched"> Please select an option </mat-error>
          </mat-form-field>
        </div>

        <!-- QUESTION FIVE -->
        <div class="question-container">
          <div class="half-width-field">
            <p>I get a sort of frightened feeling like 'butterflies' in the stomach:</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="butterfliesInStomachFeeling">
              <mat-option value="Not at all - 0">Not at all - 0</mat-option>
              <mat-option value="Occasionally - 1">Occasionally - 1</mat-option>
              <mat-option value="Quite Often - 2">Quite Often - 2</mat-option>
              <mat-option value="Very Often - 3">Very Often - 3</mat-option>
            </mat-select>
            <mat-error *ngIf="butterfliesInStomachFeeling.touched">
              Please select an option
            </mat-error>
          </mat-form-field>
        </div>

        <!-- QUESTION SIX -->
        <div class="question-container">
          <div class="half-width-field">
            <p>I feel restless as I have to be on the move:</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="feelingRestless">
              <mat-option value="Very much indeed - 3">Very much indeed - 3</mat-option>
              <mat-option value="Quite a lot - 2">Quite a lot - 2</mat-option>
              <mat-option value="Not very much - 1">Not very much - 1</mat-option>
              <mat-option value="Not at all - 0">Not at all - 0</mat-option>
            </mat-select>
            <mat-error *ngIf="feelingRestless.touched"> Please select an option </mat-error>
          </mat-form-field>
        </div>

        <!-- QUESTION SEVEN -->
        <div class="question-container">
          <div class="half-width-field">
            <p>I get sudden feelings of panic:</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="suddenFeelingOfPanic">
              <mat-option value="Very often indeed - 3">Very often indeed - 3</mat-option>
              <mat-option value="Quite often - 2">Quite often - 2</mat-option>
              <mat-option value="Not very often - 1">Not very often - 1</mat-option>
              <mat-option value="Not at all - 0">Not at all - 0</mat-option>
            </mat-select>
            <mat-error *ngIf="suddenFeelingOfPanic.touched"> Please select an option </mat-error>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div>
            <button mat-button matStepperPrevious mat-raised-button style="margin: 10px">
              Back
            </button>
          </div>
          <div>
            <button
              mat-button
              matStepperNext
              style="margin: 10px"
              mat-raised-button
              [disabled]="!hadsFormValid()"
            >
              Next
            </button>
          </div>
          <!-- <div class="button-group">
                        <button
                            class="positive-button"
                            mat-raised-button
                            color="accent"
                            type="submit"
                            [disabled]="!hadsFormValid()"
                        >
                        {{ isSubmitting ? 'Submitting...' : 'Submit' }}
                        </button>
                    </div> -->
        </div>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="additionalForm" label="Additional information">
    <form [formGroup]="additionalForm" (ngSubmit)="onSubmit()">
      <div class="form-formatting">
        <div class="full-width-field" style="margin: auto">
          <h3>Additional information</h3>
        </div>

        <!-- QUESTION ONE -->
        <div class="question-container">
          <div class="half-width-field">
            <p>If applicable, are you experiencing any side effects from your current treatment?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input
              matInput
              placeholder="e.g. skin redness or dry, flaky skin"
              type="text"
              formControlName="currentTreatmentSideEffects"
              autocomplete="off"
            />
          </mat-form-field>
        </div>

        <!-- QUESTION TWO -->
        <div class="question-container">
          <div class="half-width-field">
            <p>
              If applicable, are you experiencing any issues with the delivery of your medication?
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Please select an option</mat-label>
            <mat-select formControlName="deliveryIssueWithCurrentTreatment">
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div
          class="question-container"
          *ngIf="
            additionalForm.get('deliveryIssueWithCurrentTreatment').value.indexOf('Yes') !== -1
          "
        >
          <mat-form-field class="half-width-field" appearance="fill">
            <input
              matInput
              type="text"
              placeholder="e.g. delivery was late or not all medication delivered"
              formControlName="deliveryIssueWithCurrentTreatmentText"
              autocomplete="off"
            />
          </mat-form-field>
        </div>
        <!-- QUESTION THREE -->
        <div class="question-container">
          <div class="half-width-field">
            <p>What is your current weight?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input
              matInput
              placeholder="e.g. 11 stone or 73 kg"
              type="text"
              formControlName="currentWeight"
              autocomplete="off"
            />
          </mat-form-field>
        </div>

        <!-- QUESTION FOUR -->
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Have you developed any new significant medical issues since your last appointment?
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Please select an option</mat-label>
            <mat-select formControlName="newMedicalIssuesSinceLastAppointment">
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div
          *ngIf="
            additionalForm.get('newMedicalIssuesSinceLastAppointment').value.indexOf('Yes') !== -1
          "
        >
          <mat-form-field class="half-width-field">
            <input
              matInput
              type="text"
              autocomplete="off"
              formControlName="newMedicalIssuesSinceLastAppointmentText"
              placeholder="Please provide further information"
            />
          </mat-form-field>
        </div>

        <!-- QUESTION FIVE -->
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Are you up to date with your yearly influenza and five year pneumococcal vaccinations?
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Please select an option</mat-label>
            <mat-select formControlName="upToDateWithVaccinations">
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="half-width-field" appearance="fill">
            <input
              matInput
              type="text"
              placeholder="If relevant please provide further information"
              formControlName="upToDateWithVaccinationsText"
              autocomplete="off"
            />
          </mat-form-field>
        </div>

        <!-- QUESTION SIX -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Are you up to date with age appropriate cancer screening?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Please select an option</mat-label>
            <mat-select formControlName="ageAppropriateCancerScreening">
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="half-width-field" appearance="fill">
            <input
              matInput
              type="text"
              placeholder="If relevant please provide further information"
              formControlName="ageAppropriateCancerScreeningText"
              autocomplete="off"
            />
          </mat-form-field>
        </div>

        <!-- QUESTION SEVEN -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Are you planning on having children in the next year?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Please select an option</mat-label>
            <mat-select formControlName="planningOnHavingChildrenInTheNextYear">
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
              <mat-option value="Not applicable">Not applicable</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="half-width-field" appearance="fill">
            <input
              matInput
              type="text"
              placeholder="If relevant please provide further information"
              formControlName="planningOnHavingChildrenInTheNextYearText"
              autocomplete="off"
            />
          </mat-form-field>
        </div>

        <!-- QUESTION EIGHT -->
        <div class="question-container">
          <div class="half-width-field">
            <p>Are you female?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Please select an option</mat-label>
            <mat-select formControlName="areYouFemale">
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div
          *ngIf="additionalForm.get('areYouFemale').value.indexOf('Yes') !== -1"
          class="question-container"
        >
          <div class="half-width-field">
            <p>Are you sexually active?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Please select an option</mat-label>
            <mat-select formControlName="areYouSexuallyActive">
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div
          *ngIf="additionalForm.get('areYouSexuallyActive').value.indexOf('Yes') !== -1"
          class="question-container"
        >
          <div class="half-width-field">
            <p>What form of contraception are you currently using?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <input
              matInput
              type="text"
              placeholder="e.g. name of contraceptive pill, coil or condoms"
              autocomplete="off"
              formControlName="whatContraceptionAreYouUsing"
            />
          </mat-form-field>
        </div>

        <div class="question-container">
          <div>
            <button mat-button matStepperPrevious mat-raised-button style="margin: 10px">
              Back
            </button>
          </div>
          <div class="button-group">
            <button class="positive-button" mat-raised-button color="accent" type="submit">
              <!-- [disabled]="!hadsFormValid()" -->
              {{ isSubmitting ? 'Submitting...' : 'Submit' }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </mat-step>
</mat-horizontal-stepper>
