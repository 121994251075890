import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { FormWithId } from '@islacare/ic-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'ic-pad-paediatric-cardiac-wound',
  templateUrl: './pad-paediatric-cardiac-wound.component.html',
  styleUrls: ['./pad-paediatric-cardiac-wound.component.scss'],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class PadPaediatricCardiacWoundComponent implements OnInit {
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  entryForm: UntypedFormGroup;
  isSubmitting = false;
  ppad = true;
  nonAbsorbableSutures = false;
  surgicalClips = false;
  deepTensionSutures = false;
  removeSutures = false;
  drainSitesRemovalDate = false;
  selectedSuturesValue = '';
  todaysDate = new Date();
  daysDiff: number;
  isSensitive: boolean;
  form$: Observable<FormWithId>;

  ngOnInit(): void {
    this.form$ = this.form.getForm(this.data.teamId, this.data.formId);

    this.entryForm = this.fb.group({
      dateOfSurgery: [''],
      daysSinceOp: [''],
      ppad: [''],
      noPpadReason: [''],
      dateOfDischarge: [''],
      ward: ['', [Validators.required]],
      category: ['', [Validators.required]],
      sutureMaterial: ['', [Validators.required]],
      sutureMaterialRemovalDate: [''],
      sutureRemovalNeededBy: [''],
      surgicalWoundAssessment: ['', [Validators.required]],
      surgicalWoundAssessmentOther: [''],
      microbioResults: [''],
      drainSites: [''],
      drainSitesRemovalDate: [''],
      drainSitesRemovalNeededBy: [''],
      additionalResources: [''],
      responsibleSurgeon: [''],
      additionalComments: [''],
      cardiologyConsultant: [''],
      clinicalNurse: [''],
      staffProvidingPpad: ['', [Validators.required]],
      notes: [''],
      sensitiveImage: ['', [Validators.required]],
    });

    this.setConditionalValidation();

    this.form.retreive$.pipe(take(1)).subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.entryForm.controls[key])
          this.entryForm.controls[key].setValue(formData[key]);
      });
      const sensitive = this.entryForm.get('sensitiveImage').value;
      this.setSensitiveImage(sensitive);
    });
  }

  get dateOfSurgery() {
    return this.entryForm.get('dateOfSurgery');
  }
  get dateOfDischarge() {
    return this.entryForm.get('dateOfDischarge');
  }
  get ward() {
    return this.entryForm.get('ward');
  }
  get category() {
    return this.entryForm.get('category');
  }
  get sutureMaterial() {
    return this.entryForm.get('sutureMaterial');
  }
  get sutureMaterialRemovalDate() {
    return this.entryForm.get('sutureMaterialRemovalDate');
  }
  get surgicalWoundAssessment() {
    return this.entryForm.get('surgicalWoundAssessment');
  }
  get responsibleSurgeon() {
    return this.entryForm.get('responsibleSurgeon');
  }
  get clinicalNurse() {
    return this.entryForm.get('clinicalNurse');
  }
  get staffProvidingPpad() {
    return this.entryForm.get('staffProvidingPpad');
  }

  setPpad(ppadResponse) {
    this.ppad = ppadResponse;
  }

  daysSinceOp() {
    const surgeryDate = new Date(this.dateOfSurgery.value);
    const today = this.todaysDate;

    const timeDiff = today.getTime() - surgeryDate.getTime();

    this.daysDiff = Math.trunc(timeDiff / (1000 * 3600 * 24));
    this.entryForm.get('daysSinceOp').setValue(String(this.daysDiff));
  }
  setConditionalValidation() {
    // For suture material date validation
    const sutureMaterial = this.entryForm.get('sutureMaterial');
    const sutureMaterialRemovalDate = this.entryForm.get(
      'sutureMaterialRemovalDate'
    );

    const sutureMaterialCorrect =
      'Non-absorbable sutures (dark, thread-like appearance)' ||
      'Surgical clips' ||
      'Deep tension sutures';

    this.entryForm
      .get('sutureMaterial')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(() => {
        if (sutureMaterial.value === sutureMaterialCorrect) {
          sutureMaterialRemovalDate.setValidators([Validators.required]);
        } else if (sutureMaterial.value !== sutureMaterialCorrect) {
          sutureMaterialRemovalDate.clearValidators();
        }
        sutureMaterialRemovalDate.updateValueAndValidity();
      });
  }

  onSubmit() {
    if (!this.valid()) return;

    this.isSubmitting = true;
    const formResponse = {
      ...this.entryForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  setSensitiveImage(status: boolean) {
    if (typeof status === 'string') {
      this.form.setSensitiveImage(false);
      this.isSensitive = false;
    } else if (status || status === false) {
      this.form.setSensitiveImage(status);
      this.isSensitive = status;
    } else {
      this.isSensitive = !this.isSensitive;
      this.form.setSensitiveImage(this.isSensitive);
    }
  }

  valid(): boolean {
    return (
      this.entryForm.dirty &&
      !(this.entryForm.controls && this.entryForm.controls['ward'].errors) &&
      !(
        this.entryForm.controls && this.entryForm.controls['category'].errors
      ) &&
      !(
        this.entryForm.controls &&
        this.entryForm.controls['sutureMaterial'].errors
      ) &&
      !(
        this.entryForm.controls &&
        this.entryForm.controls['sutureMaterialRemovalDate'].errors
      ) &&
      !(
        this.entryForm.controls &&
        this.entryForm.controls['surgicalWoundAssessment'].errors
      ) &&
      !(
        this.entryForm.controls &&
        this.entryForm.controls['staffProvidingPpad'].errors
      ) &&
      !this.isSubmitting
    );
  }
}
