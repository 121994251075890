<form [formGroup]="phototherapyForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <div class="full-width-field" style="margin: auto">
      <h3>Phototherapy Assessment Form</h3>
    </div>

    <!-- QUESTION ONE -->
    <div class="question-container">
      <div class="half-width-field">
        <p>Skin condition</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <input
          matInput
          formControlName="skinCondition"
          type="notes"
          placeholder="Please enter a skin condition here"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <!-- QUESTION TWO -->
    <div class="question-container">
      <div class="half-width-field">
        <p>Affected areas</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <input
          matInput
          formControlName="affectedAreas"
          type="notes"
          placeholder="Please enter the affected areas here"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <!-- QUESTION THREE -->
    <div class="question-container">
      <div class="half-width-field">
        <p>Would you like to delay your light treatment?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="delayedLightTreatment">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      *ngIf="
        phototherapyForm.get('delayedLightTreatment').value.indexOf('Yes') !==
        -1
      "
      class="question-container"
    >
      <div class="half-width-field">
        <p>Please give us details why you would like to delay?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <input
          matInput
          formControlName="delayedLightTreatmentReason"
          type="notes"
          placeholder="Why do you wish to delay?"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <!-- QUESTION FOUR -->
    <div class="question-container">
      <div class="half-width-field">
        <p>Have you had phototherapy before?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="haveYouHadPhototherapyBefore">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      class="question-container"
      *ngIf="
        phototherapyForm
          .get('haveYouHadPhototherapyBefore')
          .value.indexOf('Yes') !== -1
      "
    >
      <div class="half-width-field">
        <p>
          Please give details of location, type of light, number of courses,
          number of treatments, cumulative dose, did it help?
        </p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <textarea
          matInput
          formControlName="previousPhototherapyDetails"
          type="notes"
          placeholder="Phototherapy details"
          autocomplete="off"
        ></textarea>
      </mat-form-field>
    </div>

    <!-- QUESTION FIVE -->
    <div class="question-container">
      <div class="half-width-field">
        <p>Have you previously been burnt in the sun or during treatment?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="burntInSunOrDuringTreatment">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      *ngIf="
        phototherapyForm
          .get('burntInSunOrDuringTreatment')
          .value.indexOf('Yes') !== -1
      "
      class="question-container"
    >
      <div class="half-width-field">
        <p>Please give details about your burn</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <input
          matInput
          formControlName="burntInSunOrDuringTreatmentDetails"
          type="notes"
          placeholder="Previous burn details"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <!-- QUESTION SIX -->
    <div class="question-container">
      <div class="half-width-field">
        <p>Does your skin condition get better or worse in the sun?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="skinConditionGetBetterOrWorseInSun">
          <mat-option value="Better">Better</mat-option>
          <mat-option value="Worse">Worse</mat-option>
          <mat-option value="Not changed">Not changed</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- QUESTION SEVEN -->
    <div class="question-container">
      <div class="half-width-field">
        <p>How long have you had the skin condition?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <input
          matInput
          formControlName="howLongHaveYouHadTheSkinCondition"
          type="notes"
          placeholder="Please tell us how long"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <!-- QUESTION EIGHT -->
    <div class="question-container">
      <div class="half-width-field">
        <p>Do you have any other medical problems or skin conditions?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="anyOtherMedicalProblemsOrSkinConditions">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      class="question-container"
      *ngIf="
        phototherapyForm
          .get('anyOtherMedicalProblemsOrSkinConditions')
          .value.indexOf('Yes') !== -1
      "
    >
      <div class="half-width-field">
        <p>
          Please give details about any other medical problems or skin
          conditions?
        </p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <input
          matInput
          formControlName="otherMedicalProblemsOrSkinConditionsDetails"
          type="notes"
          placeholder="Any other problems or conditions"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <!-- QUESTION NINE -->
    <div class="question-container">
      <div class="half-width-field">
        <p>
          Do you take any medication (tablets, injections, creams, lotions,
          ointments and other)?
        </p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="doYouTakeAnyMedication">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      *ngIf="
        phototherapyForm.get('doYouTakeAnyMedication').value.indexOf('Yes') !==
        -1
      "
      class="question-container"
    >
      <div class="half-width-field">
        <p>Please give us details about your medication?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <input
          matInput
          formControlName="medicationDetails"
          type="notes"
          placeholder="Please list all you take"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <!-- QUESTION TEN -->
    <div class="question-container">
      <div class="half-width-field">
        <p>Have you ever had a cold sore?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="haveYouEverHadAColdSore">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div
      *ngIf="
        phototherapyForm.get('haveYouEverHadAColdSore').value.indexOf('Yes') !==
        -1
      "
      class="question-container"
    >
      <div class="half-width-field">
        <p>Please give us details about your cold sore?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <input
          matInput
          formControlName="coldSoreDetails"
          type="notes"
          placeholder="Please enter details"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <!-- QUESTION ELEVEN -->
    <div class="question-container">
      <div class="half-width-field">
        <p>
          Have you ever had an infection which made your skin condition worse?
        </p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select
          formControlName="hadAnInfectionWhichMadeYourSkinConditionWorse"
        >
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div
      *ngIf="
        phototherapyForm
          .get('hadAnInfectionWhichMadeYourSkinConditionWorse')
          .value.indexOf('Yes') !== -1
      "
      class="question-container"
    >
      <div class="half-width-field">
        <p>Please give us details about your worsened skin condition?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <input
          matInput
          formControlName="worsenedSkinConditionDetails"
          type="notes"
          placeholder="Please give details"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <!-- QUESTION TWELVE -->
    <div class="question-container">
      <div class="half-width-field">
        <p>Do you smoke?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="doYouSmoke">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      *ngIf="phototherapyForm.get('doYouSmoke').value.indexOf('Yes') !== -1"
      class="question-container"
    >
      <div class="half-width-field">
        <p>Please tell us how much on average you smoke daily?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <input
          matInput
          formControlName="smokingDailyAmount"
          type="notes"
          placeholder="How many a day?"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <!-- QUESTION THIRTEEN -->
    <div class="question-container">
      <div class="half-width-field">
        <p>Do you drink alcohol? (1 pint of beer = 3 units)</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="doYouDrinkAlcohol">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      *ngIf="
        phototherapyForm.get('doYouDrinkAlcohol').value.indexOf('Yes') !== -1
      "
      class="question-container"
    >
      <div class="half-width-field">
        <p>Please tell us how much on average you drink weekly?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <input
          matInput
          formControlName="alcoholWeeklyAmount"
          type="notes"
          placeholder="How many units a week?"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <!-- QUESTION FOURTEEN -->
    <div class="question-container">
      <div class="half-width-field">
        <p>Do you live alone?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="doYouLiveAlone">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      *ngIf="phototherapyForm.get('doYouLiveAlone').value.indexOf('Yes') !== -1"
      class="question-container"
    >
      <div class="half-width-field">
        <p>Please give details about your living situation?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <input
          matInput
          formControlName="livingSituationDetails"
          type="notes"
          placeholder="Enter details here"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <!-- QUESTION FIFTEEN -->
    <div class="question-container">
      <div class="half-width-field">
        <p>What is your occupation?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <input
          matInput
          formControlName="whatIsYourOccupation"
          type="notes"
          placeholder="Occupation"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <!-- QUESTION SIXTEEN -->
    <div class="question-container">
      <div class="half-width-field">
        <p>Do you think your work could be making your skin condition worse?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="doYouThinkYouWorkMakesSkinConditionWorse">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- QUESTION SEVENTEEN -->
    <div class="question-container">
      <div class="half-width-field">
        <p>Does your skin condition affect your mood?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="doesYourSkinConditionAffectYourMood">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- QUESTION EIGHTTEEN -->
    <div
      class="question-container"
      *ngIf="
        phototherapyForm
          .get('doesYourSkinConditionAffectYourMood')
          .value.indexOf('Yes') !== -1
      "
    >
      <div class="half-width-field">
        <p>Would you like us to refer you for this?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="skinConditionAffectingMoodReferral">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- QUESTION NINETEEN -->
    <div class="question-container">
      <div class="half-width-field">
        <p>
          Do you have any disabilities we should be aware of or that may affect
          your treatment?
        </p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="disabilitiesThatMayAffectTreatment">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      class="question-container"
      *ngIf="
        phototherapyForm
          .get('disabilitiesThatMayAffectTreatment')
          .value.indexOf('Yes') !== -1
      "
    >
      <div class="half-width-field">
        <p>Please give us details about any disabilities?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <input
          matInput
          formControlName="disabilityDetails"
          type="notes"
          placeholder="Disability details"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <!-- QUESTION TWENNY -->
    <div class="question-container">
      <div class="half-width-field">
        <p>Do you need an interpreter for the face to face assessment?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select
          formControlName="doYouNeedAnInterpreterForFaceToFaceAssessment"
        >
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      class="question-container"
      *ngIf="
        phototherapyForm
          .get('doYouNeedAnInterpreterForFaceToFaceAssessment')
          .value.indexOf('Yes') !== -1
      "
    >
      <div class="half-width-field">
        <p>Which language?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <input
          matInput
          formControlName="interpreterLanguage"
          type="notes"
          placeholder="If yes, give details"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <!-- QUESTION TWENNYWUN -->
    <div class="question-container">
      <div class="half-width-field">
        <p>Would you like an information leaflet about light treatment?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="informationLeafletAboutLightTreatment">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="question-container">
      <div class="half-width-field">
        <p>Anything else you would like to tell us?</p>
      </div>
      <mat-form-field appearance="fill" class="half-width-field">
        <mat-label>Please select an option</mat-label>
        <mat-select formControlName="anymoreAdditionalComments">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      class="question-container"
      *ngIf="
        phototherapyForm
          .get('anymoreAdditionalComments')
          .value.indexOf('Yes') !== -1
      "
    >
      <div class="half-width-field">
        <p>Please give additional details?</p>
      </div>
      <mat-form-field appearance="fill" class="notes-field half-width-field">
        <input
          matInput
          formControlName="notes"
          type="notes"
          placeholder="Additional comments"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <br />

    <div class="button-group">
      <button
        class="positive-button"
        mat-raised-button
        color="accent"
        type="submit"
      >
        {{ isSubmitting ? 'Submitting...' : 'Submit' }}
      </button>
    </div>
  </div>
</form>
