<ng-container *ngIf="field && control">
  <div>
    <!-- prettier-ignore -->
    <p
      id="field-title"
      [ngStyle]="{
        'font-weight': fontWeight(),
        'text-align': textAlign(),
        color: control?.touched && control?.invalid ? '#ee7324' : null
      }"
    >{{field.title}} {{ field?.validation?.required ? ' *' : '' }}</p>
    <p id="field-subtitle" style="font-size: 14px">{{ field.subTitle }}</p>
    <div class="slider-container">
      <mat-slider
        class="full-width-field"
        [formControl]="control"
        thumbLabel
        tickInterval="1"
        [min]="field.min || '1'"
        [max]="field.max || '10'"
        [ngStyle]="{
          color: control?.touched && control?.invalid ? '#ee7324' : null
        }"
        [displayWith]="formatLabel"
      >
        <input
          [value]="+field.value"
          (valueChange)="control?.setValue($event)"
          data-cy="mat-slider-thumb"
          matSliderThumb
        />
      </mat-slider>
    </div>
    <div class="slider-label-container">
      <div
        class="slider-label min-label"
        *ngIf="
          field.sliderDescriptor &&
          field.sliderDescriptor[field.min] &&
          field.min !== '-1'
        "
      >
        <div class="text-align-left">
          {{ field.min }}
        </div>
        <div class="text-align-left">
          {{ field.sliderDescriptor[field.min] }}
        </div>
      </div>
      <div
        class="slider-label min-label"
        [ngStyle]="{ 'margin-left': stepWidth }"
        *ngIf="
          field.sliderDescriptor &&
          field.sliderDescriptor[+field.min + 1] &&
          field.min === '-1'
        "
      >
        <div class="text-align-left">
          {{ +field.min + 1 }}
        </div>
        <div class="text-align-left">
          {{ field.sliderDescriptor[+field.min + 1] }}
        </div>
      </div>
      <div
        class="slider-label"
        [ngClass]="{ 'max-label': !field?.startSliderMaxDescriptorFromLeft }"
        *ngIf="field.sliderDescriptor && field.sliderDescriptor[field.max]"
      >
        <div class="text-align-right">
          {{ field.max }}
        </div>
        <div class="text-align-right">
          {{ field.sliderDescriptor[field.max] }}
        </div>
      </div>
    </div>
    <div class="slider-label-container">
      <div
        class="slider-label min-label slider-images"
        *ngIf="
          field.sliderImages &&
          field.sliderImages[field.min] &&
          field.min !== '-1'
        "
      >
        <img
          [src]="field.sliderImages[field.min].storageSrc"
          class="images"
          [ngStyle]="{
            maxWidth: field?.sliderImages[field.min].maxImageWidth,
            float: 'left'
          }"
          alt="image"
        />
      </div>
      <div
        class="slider-label min-label slider-images"
        [ngStyle]="{ 'margin-left': stepWidth }"
        *ngIf="
          field.sliderImages &&
          field.sliderImages[+field.min + 1] &&
          field.min === '-1'
        "
      >
        <img
          [src]="field.sliderImages[+field.min + 1].storageSrc"
          class="images"
          [ngStyle]="{
            maxWidth: field?.sliderImages[+field.min + 1].maxImageWidth,
            float: 'left'
          }"
          alt="image"
        />
      </div>
      <div
        class="slider-label slider-images"
        [ngClass]="{ 'max-label': !field?.startSliderMaxDescriptorFromLeft }"
        *ngIf="field.sliderImages && field.sliderImages[field.max]"
      >
        <img
          [src]="field.sliderImages[field.max].storageSrc"
          class="images"
          [ngStyle]="{
            maxWidth: field?.sliderImages[field.max].maxImageWidth,
            float: 'right'
          }"
          alt="image"
        />
      </div>
    </div>
    <p class="pain-descriptor" *ngIf="field?.sliderDescriptor">
      {{ field?.sliderDescriptor[control?.value] }}
    </p>
    <p
      *ngIf="control?.touched && control?.invalid"
      class="mat-error"
      [ngStyle]="{ 'text-align': textAlign() }"
    >
      {{ formService?.getErrorMessage(control) }}
    </p>
  </div>
</ng-container>
