import { OrganisationFeatureFlags } from '@islacare/ic-types';

export const DEFAULT_ORGANISATION_FEATURE_FLAGS: OrganisationFeatureFlags = {
  patientLookUp: {
    pdsEnabled: true,
    hl7NhsEnabled: false,
    hl7MrnEnabled: false,
  },
  patientLookupOverrides: {
    backupManualEntryDisabled: false,
  },
  functionality: {
    emailEnabled: true,
    savePdfToEprEnabled: false,
    appointmentsLookUpEnabled: false,
    markPatientAsDeceasedEnabled: true,
    requestAccessToCollectionDataDisabled: false,
    soundRecordingEnabled: true,
    editAutomaticallyCreatedPatients: false,
    enhancedConsentRequestsEnabled: false,
    teamResourcesEnabled: true,
    hideAddPatient: false,
    hideChangeLog: false,
    isMedwiseEnabled: false,
  },
  securityAndCompliance: {
    twoFactorAuthEnabled: false,
    emailDomainValidation: {
      whitelistedDomains: [],
    },
  },
};
