<form [formGroup]="mskReferralForm">
  <div class="form-formatting">
    <mat-card>
      <mat-card-title>MSK Referral Form</mat-card-title>

      <mat-card-content>
        <div class="question-container">
          <div class="half-width-field">
            <p>Do they have a History of Ulceration/wound?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="historyOfUlcerationOrWound">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Do they have an existing wound / ulcer?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="existingWoundOrUlcer">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Do they have Diabetes or other co-morbidities that place them At Risk?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="riskFromDiabetesOrOtherComorbidities">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Do they have Vascular insufficiency / PVD / PAD?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="vascularInsufficiencyPvdOrPad">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Do they have Peripheral Neuropathy / Motor neuropathy?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="peripheralOrMotorNeuropathy">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Do they have Chronic Kidney disease?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="chronicKidney">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Have they had an Amputation?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="amputation">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no" (click)="resetAntomical()">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="mskReferralForm.get('amputation').value === 'yes'">
          <div class="question-container">
            <div class="half-width-field">
              <p>If so, anatomical location?</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <input matInput type="text" formControlName="anatomicalLocation" />
            </mat-form-field>
          </div>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Do they have a Foot deformity?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="footDeformity">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no" (click)="resetDeformityTypeRisk()">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="mskReferralForm.get('footDeformity').value === 'yes'">
          <div class="question-container">
            <div class="half-width-field">
              <p>If yes, please specify. E.g Charcot</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <input matInput type="text" formControlName="footDeformityType" />
            </mat-form-field>
          </div>

          <div class="question-container">
            <div class="half-width-field">
              <p>Can this deformity increase risk of ulceration?</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <mat-select formControlName="footDeformityUlcerationRisk">
                <mat-option value="yes">Yes</mat-option>
                <mat-option value="no">No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              Do they have existing callosity that render them at Increased risk? i.e. RA patient
              with gross deformity and callus build up
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-select formControlName="increasedRiskFromExistingCallosity">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <br />
    <mat-form-field class="full-width-field" appearance="fill">
      <mat-label>Any other comments?</mat-label>
      <textarea matInput formControlName="notes" type="notes"> </textarea>
    </mat-form-field>
    <br />

    <button
      mat-raised-button
      class="positive-button"
      type="submit"
      [disabled]="mskReferralForm.invalid || isSubmitting"
      (click)="onSubmit()"
    >
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
