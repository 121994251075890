import { Pipe, PipeTransform } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { FormIdToNameService } from '../../services/form-id-to-name/form-id-to-name.service';
@Pipe({
  name: 'formIdsToNames',
  standalone: true,
})
export class FormIdsToNamesPipe implements PipeTransform {
  constructor(private formIdToNameService: FormIdToNameService) {}
  transform(ids: string[]): Observable<string[]> {
    const observables = ids.filter(id => id !== 'default').map(id => this.formIdToNameService.getFormName$(id));
    return forkJoin(observables);
  }
}
