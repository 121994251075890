import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, repeat, skipWhile, switchMap, take } from 'rxjs';
import { UsersService } from '../../shared/services/users/users.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AutoLogoutService {
  constructor(
    private userService: UsersService,
    private router: Router,
  ) {}

  start(): void {
    this.userService.user$
      .pipe(
        skipWhile(user => !user),
        switchMap(() => this.userService.user$),
        filter(user => !user),
        take(1),
        repeat(),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.router.navigate(['/auth/login']);
      });
  }
}
