import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemCheckService } from '@ic-monorepo/shared-common';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'ic-clinical-form-stepper',
  templateUrl: './clinical-form-stepper.component.html',
  styleUrls: ['./clinical-form-stepper.component.scss'],
})
export class ClinicalFormStepperComponent extends FieldType implements OnInit {
  steps: {
    isValid: () => boolean;
    canClick: () => boolean;
    onClick: () => void;
  }[] = [];
  invalidFieldInCurrentSection: FormlyFieldConfig;
  activeStep$: Observable<number>;
  activeStep = 0;

  isMobile = this.systemCheck.isMobile;
  isCalcForm = false;

  @ViewChild('stepper') stepperEl: ElementRef;

  constructor(
    private systemCheck: SystemCheckService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.checkParams();
    this.steps = this.field?.fieldGroup?.map((formSection: FormlyFieldConfig, index: number) => ({
      isValid: () => !this.formState?.isEditing && this.isFormSectionValid(formSection),
      canClick: () => {
        for (let j = 0; j < index; j++) {
          if (!this.isFormSectionValid(this.field?.fieldGroup[j])) {
            return false;
          }
        }
        return true;
      },
      onClick: () => this.goToStep(index),
    }));

    this.activeStep$ = this.route.queryParamMap.pipe(map(params => Number(params.get('step'))));

    const firstFieldKey = this.getFirstField(this.field?.fieldGroup?.[this.activeStep]);
    this.setFocusOnField(firstFieldKey);
  }

  checkParams(): void {
    const queryParams = this.route.snapshot.queryParams;
    this.isCalcForm = queryParams['isCalcForm'] === 'true';
  }
  checkIfAnyFieldsVisibleInSection(stepIndex: number): boolean {
    const field = this.field?.fieldGroup?.[stepIndex];
    if (field) {
      return !field?.props?.hidden;
    }
    return false;
  }
  previousStep(): void {
    if (!this.activeStep) return;

    this.scrollToTop();
    this.activeStep--;
    if (!this.checkIfAnyFieldsVisibleInSection(this.activeStep)) {
      this.previousStep();
    } else {
      this.updateStepQueryParam();
      const firstFieldKey = this.getFirstField(this.field?.fieldGroup?.[this.activeStep]);
      this.setFocusOnField(firstFieldKey);
    }
  }

  nextStep(): void {
    if (!this.scrollAndFocusOnInvalidField()) return;

    this.scrollToTop();
    this.activeStep++;
    if (!this.checkIfAnyFieldsVisibleInSection(this.activeStep)) {
      this.nextStep();
    } else {
      this.updateStepQueryParam();
      const firstFieldKey = this.getFirstField(this.field?.fieldGroup?.[this.activeStep]);
      this.setFocusOnField(firstFieldKey);
    }
  }

  submit(event: MouseEvent): void {
    if (!this.scrollAndFocusOnInvalidField()) {
      event.preventDefault();
      return;
    }
  }

  goToStep(stepIndex: number): void {
    this.activeStep = stepIndex;
    this.updateStepQueryParam();
    const firstFieldKey = this.getFirstField(this.field?.fieldGroup?.[this.activeStep]);
    this.setFocusOnField(firstFieldKey);
  }

  isFormSectionValid(field: FormlyFieldConfig, isNextClicked?: boolean): boolean {
    if (field?.key) {
      const isValid = field?.formControl?.valid;
      if (isNextClicked && !isValid) {
        this.invalidFieldInCurrentSection = field;
      }
      return isValid;
    }

    if (field?.fieldGroup && !field?.hide) {
      return field?.fieldGroup.every(f => this.isFormSectionValid(f, isNextClicked));
    }

    return true;
  }

  get percentageProgress(): string {
    return `${(this.activeStep / (this.steps?.length - 1)) * 100}%`;
  }

  scrollAndFocusOnInvalidField(): boolean {
    const isFormValid = this.isFormSectionValid(this.field?.fieldGroup?.[this.activeStep], true);
    if (!isFormValid && typeof this.invalidFieldInCurrentSection.key === 'string') {
      const invalidElementRef = document.getElementById(this.invalidFieldInCurrentSection.key);
      if (invalidElementRef) {
        this.invalidFieldInCurrentSection.formControl.markAsTouched();
        invalidElementRef.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        setTimeout(() => {
          invalidElementRef.focus();
        }, 100);
      }
      return false;
    }
    return true;
  }

  getFirstField(field: FormlyFieldConfig): string {
    if (field?.key && typeof field.key === 'string') {
      return field.key;
    }

    if (field?.fieldGroup && !field?.hide) {
      return this.getFirstField(field?.fieldGroup[0]);
    }
  }

  setFocusOnField(key: string): void {
    const fieldElementRef = document.getElementById(key);
    if (fieldElementRef) {
      setTimeout(() => {
        fieldElementRef.focus();
      }, 100);
    }
  }

  private scrollToTop(): void {
    this.stepperEl.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  private updateStepQueryParam() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { step: this.activeStep },
      queryParamsHandling: 'merge',
    });
  }
}
