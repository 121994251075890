import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-maternity-form',
  templateUrl: './maternity-form.component.html',
  styleUrls: ['./maternity-form.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
})
export class MaternityFormComponent implements OnInit {
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  maternityForm: UntypedFormGroup;
  isSubmitting = false;
  bmi: any;
  weightKg: number;
  heightM: number;

  ngOnInit(): void {
    this.maternityForm = this.fb.group({
      dueDate: ['', []],
      maternityUnit: ['', []],
      intendedPlaceOfBirth: ['', []],
      teamName: ['', []],
      namedMidwife: ['', []],
      midwifeOrTeamContactDetails: ['', []],
      namedObstetricOrMidwifeConsultant: ['', []],
      knownMedicalConditionsOrAllergies: ['', []],
      birthSettingPreference: ['', []],
      birthPartner: ['', []],
      studentsPresent: ['', []],
      additionalRequirements: ['', []],
      additionalRequirementsFreeText: ['', []],
      birthMethodPreferenceDiscussionHasOccurred: ['', []],
      vaginalDelivery: ['', []],
      awareOfInductionOfLabour: ['', []],
      typesOfPainReliefConsidered: ['', []],
      otherOptionsDuringLabourConsidered: ['', []],
      fetalHeartRateMonitoringPreferences: ['', []],
      vaginalExaminationPreferences: ['', []],
      awareOfReasonsForIntervention: ['', []],
      awareOfReasonsForAssistedBirth: ['', []],
      awareOfEpisiotomy: ['', []],
      thirdStagePreferences: ['', []],
      cordCuttingPreferences: ['', []],
      skinToSkinPreferences: ['', []],
      thoughtsAroundFeeding: ['', []],
      vitaminKPreferences: ['', []],
      contraceptionPreferences: ['', []],
      notes: ['', []],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.maternityForm.controls[key])
          this.maternityForm.controls[key].setValue(formData[key]);
      });
    });

    if (this.maternityForm.value.vaginalDelivery === '') {
      this.maternityForm
        .get('vaginalDelivery')
        .setValue('I am having a vaginal delivery / I am not sure');
    }
  }

  get vaginalDelivery() {
    return this.maternityForm.get('vaginalDelivery');
  }

  onSubmit() {
    this.isSubmitting = true;
    const formResponse = {
      ...this.maternityForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  valid(): boolean {
    return (
      this.maternityForm.dirty && this.maternityForm.valid && !this.isSubmitting
    );
  }
}
