import {
  CollectionWithId,
  PatientWithId,
  UserWithId,
} from '@islacare/ic-types';
import { CollectionAuthWithPatientMessages } from 'apps/frontend/portal/src/app/services/collectionsAuths/collectionAuths.service';
import { EntryWithIdUrl } from 'apps/frontend/portal/src/app/services/entries/entries.service';

export interface PatientRecordState {
  user: UserWithId;
  patient: PatientWithId;
  currentCollectionId: string;
  collections: CollectionWithId[];
  collectionAuths: CollectionAuthWithPatientMessages[];
  entriesOrder: EntryOrder;
  entryId: string;
  formNames: FormNameAndId[];
  selectedEntries: EntryWithIdUrl[];
  error: string;
  loading: boolean;
  submitting: boolean;
}

export interface FormNameAndId {
  id: string;
  name: string;
}

export enum EntryOrder {
  CUSTOM_ORDER = '+customOrderImageIndex',
  DATE_ASC = '+createdAt.seconds',
  DATE_DSC = '-createdAt.seconds',
}
export interface OrderingItem {
  name: string;
  code: EntryOrder;
}
