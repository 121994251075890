import { Injectable } from '@angular/core';
import RecordRTC from 'recordrtc';
import { Observable, Subject } from 'rxjs';

interface RecordedAudioOutput {
  blob: Blob;
  title: string;
}

@Injectable({
  providedIn: 'root'
})
export class SoundRecordingService {
  private stream;
  private recorder;
  private interval;
  private startTime;
  private _recorded = new Subject<RecordedAudioOutput>();
  private _recordingTime = new Subject<string>();
  private _recordingFailed = new Subject<string>();

  getRecording$(): Observable<RecordedAudioOutput> {
    return this._recorded.asObservable();
  }

  getRecordingTime$(): Observable<string> {
    return this._recordingTime.asObservable();
  }

  recordingFailed$(): Observable<string> {
    return this._recordingFailed.asObservable();
  }

  startRecording() {
    if (this.recorder) {
      // It means recording is already started or it is already recording something
      return;
    }

    this._recordingTime.next('00:00');
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(s => {
        this.stream = s;
        this.record();
      })
      .catch(() => {
        this._recordingFailed.next(null);
      });
  }

  abortRecording() {
    this.stopMedia();
  }

  private record() {
    this.recorder = new RecordRTC.StereoAudioRecorder(this.stream, {
      type: 'audio',
      mimeType: 'audio/mp3'
    });

    this.recorder.record();
    this.startTime = new Date().getTime();
    this.interval = setInterval(() => {
      const timeDiff = Math.floor((new Date().getTime() - this.startTime) / 1000); //total time elapsed in seconds
      const m = Math.floor((timeDiff % 3600) / 60); //convert to minutes
      const s = Math.floor(timeDiff % 60); //converts to seconds

      const time = `${m < 10 ? '0' + m : m}:${s < 10 ? '0' + s : s}`;
      this._recordingTime.next(time);
    }, 500);
  }

  stopRecording() {
    if (this.recorder) {
      this.recorder.stop(
        blob => {
          if (this.startTime) {
            const mp3Name = encodeURIComponent('audio_' + new Date().getTime() + '.mp3');
            this.stopMedia();
            this._recorded.next({ blob: blob, title: mp3Name });
          }
        },
        () => {
          this.stopMedia();
          this._recordingFailed.next(null);
        }
      );
    }
  }

  private stopMedia() {
    if (this.recorder) {
      this.recorder = null;
      clearInterval(this.interval);
      this.startTime = null;
      if (this.stream) {
        this.stream.getAudioTracks().forEach(track => track.stop());
        this.stream = null;
      }
    }
  }
}
