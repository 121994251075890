<ng-container *ngTemplateOutlet="message"></ng-container>
<form *ngIf="responseForm" [formGroup]="responseForm">
  <div class="field">
    <label for="contactMethod">Contact method</label>
    <div>
      <p-selectButton
        [options]="contactMethods"
        (onChange)="updateContactMethod()"
        data-cy="contact-method-select-btn"
        formControlName="contactMethod"
        optionLabel="name"
        optionValue="method"
      ></p-selectButton>
    </div>
  </div>
  <div class="field">
    <ng-container *ngIf="responseForm.controls.contactMethod.value === MessageMedium.SMS">
      <p-message
        *ngIf="!recipientPhoneKeys || this.recipientPhoneKeys?.length === 0"
        [text]="noPhoneNumberMsg"
        styleClass="w-full mb-2"
        severity="error"
      ></p-message>
      <ng-container *ngIf="recipientPhoneKeys">
        <ng-container>
          <p-message
            *ngIf="recipientPhoneKeys?.length > 1"
            [text]="multiplePhoneNumbersMsg"
            styleClass="w-full mb-2"
            severity="info"
          ></p-message>

          <label for="recipient">Recipient</label>
          <div class="p-fluid">
            <p-dropdown
              [options]="recipientPhoneKeys"
              formControlName="recipient"
              placeholder="Select recipient"
              data-cy="team-list-dropdown"
            >
              <ng-template pTemplate="selectedItem">
                {{ responseForm.get('recipient')?.value }}
                ({{ data.additionalPhoneNumbers[responseForm.get('recipient')?.value] }})
              </ng-template>
              <ng-template let-option pTemplate="item">
                {{ option }} ({{ data.additionalPhoneNumbers[option] }})
              </ng-template>
            </p-dropdown>
            <small
              *ngIf="responseForm.get('recipient')?.touched && !responseForm.get('recipient')?.valid"
              class="p-error block"
            >
              Invalid phone number selected
            </small>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="responseForm.controls.contactMethod.value === MessageMedium.EMAIL">
      <p-message
        *ngIf="!data.patientEmail && this.recipientEmailKeys?.length === 0"
        [text]="noEmailMsg"
        styleClass="w-full mb-2"
        severity="error"
      ></p-message>
      <ng-container *ngIf="recipientEmailKeys">
        <ng-container>
          <p-message
            *ngIf="recipientEmailKeys?.length > 1"
            [text]="multipleEmailsMsg"
            styleClass="w-full mb-2"
            severity="info"
          ></p-message>

          <label for="recipient">Recipient</label>
          <div class="p-fluid">
            <p-dropdown [options]="recipientEmailKeys" formControlName="recipient" placeholder="Select recipient">
              <ng-template pTemplate="selectedItem">
                {{ responseForm.get('recipient')?.value }}
                ({{ data.additionalEmailAddresses[responseForm.get('recipient')?.value] }})
              </ng-template>
              <ng-template let-option pTemplate="item">
                {{ option }} ({{ data.additionalEmailAddresses[option] }})
              </ng-template>
            </p-dropdown>
            <small
              *ngIf="responseForm.get('recipient')?.touched && !responseForm.get('recipient')?.valid"
              class="p-error block"
            >
              Invalid email address selected
            </small>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div *ngIf="responses$ | async as responses" class="field">
    <label for="response">Message template</label>
    <div class="p-fluid">
      <p-dropdown
        [options]="responses"
        [group]="true"
        (onChange)="selectResponse($event.value)"
        placeholder="Select template"
        optionGroupChildren="responseTemplates"
        optionGroupLabel="teamName"
        name="response"
      >
      </p-dropdown>
    </div>
  </div>
  <div class="field">
    <button
      [label]="dialogExpanded ? 'See less' : 'See full message'"
      (click)="dialogExpanded ? minimizeDialog() : expandDialog()"
      [pTooltip]="dialogExpanded ? 'Minimize dialog' : 'Expand dialog'"
      [attr.aria-label]="dialogExpanded ? 'See less' : 'See full message'"
      [icon]="dialogExpanded ? 'pi pi-window-minimize' : 'pi pi-window-maximize'"
      iconPos="right"
      class="p-button-link underline"
      pButton
    ></button>
  </div>
  <div class="field">
    <label for="response">Message content</label>
    <div class="p-fluid">
      <textarea
        [ngClass]="{
          'ng-invalid ng-dirty': responseForm.get('response').touched && !responseForm.get('response').valid
        }"
        class="min-w-full"
        formControlName="response"
        rows="5"
        cols="30"
        pInputTextarea
      ></textarea>
      <small class="p-error" *ngIf="responseForm.get('response').errors?.minlength"
        >Message must have at least 10 characters</small
      >
    </div>
  </div>
  <ng-container *ngIf="showAddResources$ | async">
    <ng-container
      *ngIf="{
        list: resourcesArray$ | async,
        isTeamAdmin: isTeamAdmin$ | async
      } as resourceData"
    >
      <div class="text-center mb-2" *ngIf="selectedResource" [ngSwitch]="true">
        <ic-file-placeholder
          *ngSwitchCase="selectedResource.fileType === 'pdf' || selectedResource.fileType === 'application/pdf'"
          class="pdf-thumbnail"
          fileType="PDF"
        ></ic-file-placeholder>
        <video *ngSwitchCase="selectedResource?.isVideo" class="border-round-md h-auto" width="100%">
          <source
            [src]="selectedResource.publicUrl"
            type="video/webm"
            alt="Unfortunately, video cannot be displayed on this browser"
          />
          Sorry: Your browser does not support video playback
        </video>
        <img
          *ngSwitchDefault
          [src]="selectedResource.publicUrl"
          class="border-round-md"
          alt="Selected file"
          width="100%"
        />
        <div class="file-name-container">
          <span class="overflow-wrap mx-1 my-0" [pTooltip]="selectedResource.fileName">
            <strong>{{ selectedResource.fileName }}</strong>
          </span>
        </div>
      </div>

      <div *ngIf="!resourceData?.list?.length && resourceData.isTeamAdmin" class="no-resource-container">
        <mat-icon>error_outline</mat-icon>
        No resources available.
        <a [routerLink]="['/teams', data?.collectionTeamId, 'resources']">Add resources</a>
      </div>
    </ng-container>
  </ng-container>
</form>

<div class="flex flex-column gap-3">
  <div class="flex flex-wrap gap-1">
    <ng-container
      *ngIf="{
        list: resourcesArray$ | async,
        isTeamAdmin: isTeamAdmin$ | async
      } as resourceData"
    >
      <button
        *ngIf="resourceData?.list?.length"
        (click)="openResourceDialog(resourceData?.list)"
        [label]="uploadButtonText"
        [icon]="uploadButtonIcon"
        [attr.aria-label]="uploadButtonText"
        class="p-button-outlined flex flex-grow-1"
        type="button"
        pButton
      ></button>
    </ng-container>
    <ng-container *ngTemplateOutlet="medwiseButton"></ng-container>
  </div>
  <div class="flex flex-row justify-content-end gap-2">
    <button
      *ngIf="!isMobile"
      [label]="data.negativeButton"
      (click)="ref.close()"
      class="p-button-outlined"
      aria-label="Close"
      type="button"
      pButton
    ></button>
    <button
      [disabled]="responseFormInvalid()"
      [label]="data.positiveButton"
      (click)="responseSubmit(selectedResource)"
      data-cy="submission-dialog-positive-button-patient-response"
      aria-label="Submit"
      pButton
      icEventTracker="pr-send-patient-message"
    ></button>
  </div>
</div>

<ng-template #medwiseButton>
  <ng-container *ngIf="isMedwiseFeatureEnabled$ | async">
    <a
      [href]="medwise.url | safeUrl"
      target="_blank"
      class="p-button-outlined flex flex-grow-1"
      [attr.aria-label]="medwise.altAttr"
      pButton
    >
      <span>Find resource via</span>
      <img [src]="medwise.imgUrl" [alt]="medwise.altAttr" class="pl-2 pr-2 medwise-btn-img" />
      <i class="pi pi-external-link"></i>
    </a>
  </ng-container>
</ng-template>

<ng-template #message>
  <p class="m-0 pb-2">
    Use "Message patient" functionality to send messages or information to patients. Do not use for sending submission
    requests. Your message will be stored in the current folder.
  </p>
  <p class="m-0 pb-3">
    <strong>Note, the patient won't be able to reply to this message.</strong>
  </p>
</ng-template>
