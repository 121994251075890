<form [formGroup]="mfqForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <div class="full-width-field" style="margin: auto">
      <h3>MOOD AND FEELINGS QUESTIONNAIRE: Short Version</h3>
    </div>
    <div class="full-width-field" style="margin: auto">
      <p>
        This form is about how you might have been feeling or acting
        <strong>recently</strong>.
      </p>
      <p>
        For each question, please select how you have been feeling or acting
        <strong>in the past two weeks</strong>.
      </p>
    </div>

    <!-- QUESTION ONE -->
    <div class="question-container">
      <div class="half-width-field">
        <p>I felt miserable or unhappy.</p>
      </div>
      <mat-form-field class="half-width-field">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="feelingMiserableOrUnhappy">
          <mat-option [value]="score" *ngFor="let score of mfqScoreList">
            {{ score }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="feelingMiserableOrUnhappy.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <!-- QUESTION TWO -->
    <div class="question-container">
      <div class="half-width-field">
        <p>I didn't enjoy anything at all.</p>
      </div>
      <mat-form-field class="half-width-field">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="didntEnjoyAnything">
          <mat-option [value]="score" *ngFor="let score of mfqScoreList">
            {{ score }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="didntEnjoyAnything.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <!-- QUESTION THREE -->
    <div class="question-container">
      <div class="half-width-field">
        <p>I felt so tired I just sat around and did nothing.</p>
      </div>
      <mat-form-field class="half-width-field">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="feltSoTiredAndDoneNothing">
          <mat-option [value]="score" *ngFor="let score of mfqScoreList">
            {{ score }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="feltSoTiredAndDoneNothing.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <!-- QUESTION FOUR -->
    <div class="question-container">
      <div class="half-width-field">
        <p>I was very restless.</p>
      </div>
      <mat-form-field class="half-width-field">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="wasVeryRestless">
          <mat-option [value]="score" *ngFor="let score of mfqScoreList">
            {{ score }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="wasVeryRestless.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <!-- QUESTION FIVE -->
    <div class="question-container">
      <div class="half-width-field">
        <p>I felt I was no good anymore.</p>
      </div>
      <mat-form-field class="half-width-field">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="feltLikeIWasNoGoodAnymore">
          <mat-option [value]="score" *ngFor="let score of mfqScoreList">
            {{ score }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="feltLikeIWasNoGoodAnymore.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <!-- QUESTION SIX -->
    <div class="question-container">
      <div class="half-width-field">
        <p>I cried a lot.</p>
      </div>
      <mat-form-field class="half-width-field">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="criedAlot">
          <mat-option [value]="score" *ngFor="let score of mfqScoreList">
            {{ score }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="criedAlot.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <!-- QUESTION SEVEN -->
    <div class="question-container">
      <div class="half-width-field">
        <p>I found it hard to think properly or concentrate.</p>
      </div>
      <mat-form-field class="half-width-field">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="hardToThinkOrConcentrate">
          <mat-option [value]="score" *ngFor="let score of mfqScoreList">
            {{ score }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="hardToThinkOrConcentrate.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <!-- QUESTION EIGHT -->
    <div class="question-container">
      <div class="half-width-field">
        <p>I hated myself.</p>
      </div>
      <mat-form-field class="half-width-field">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="iHatedMyself">
          <mat-option [value]="score" *ngFor="let score of mfqScoreList">
            {{ score }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="iHatedMyself.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <!-- QUESTION NINE -->
    <div class="question-container">
      <div class="half-width-field">
        <p>I was a bad person.</p>
      </div>
      <mat-form-field class="half-width-field">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="iWasABadPerson">
          <mat-option [value]="score" *ngFor="let score of mfqScoreList">
            {{ score }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="iWasABadPerson.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <!-- QUESTION TEN -->
    <div class="question-container">
      <div class="half-width-field">
        <p>I felt lonely.</p>
      </div>
      <mat-form-field class="half-width-field">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="iFeltLonely">
          <mat-option [value]="score" *ngFor="let score of mfqScoreList">
            {{ score }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="iFeltLonely.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <!-- QUESTION ELEVEN -->
    <div class="question-container">
      <div class="half-width-field">
        <p>I thought nobody really loved me.</p>
      </div>
      <mat-form-field class="half-width-field">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="thoughtNobodyLovedMe">
          <mat-option [value]="score" *ngFor="let score of mfqScoreList">
            {{ score }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="thoughtNobodyLovedMe.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <!-- QUESTION TWELVE -->
    <div class="question-container">
      <div class="half-width-field">
        <p>I thought I could never be as good as other kids.</p>
      </div>
      <mat-form-field class="half-width-field">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="thoughICouldNeverBeAsGoodAsOtherKids">
          <mat-option [value]="score" *ngFor="let score of mfqScoreList">
            {{ score }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="thoughICouldNeverBeAsGoodAsOtherKids.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <!-- QUESTION THIRTEEN -->
    <div class="question-container">
      <div class="half-width-field">
        <p>I did everything wrong.</p>
      </div>
      <mat-form-field class="half-width-field">
        <mat-label> Please select an option </mat-label>
        <mat-select formControlName="iDidEverythingWrong">
          <mat-option [value]="score" *ngFor="let score of mfqScoreList">
            {{ score }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="iDidEverythingWrong.touched">
          Please select an option
        </mat-error>
      </mat-form-field>
    </div>

    <mat-form-field appearance="fill" class="notes-field half-width-field">
      <input
        matInput
        formControlName="notes"
        type="notes"
        placeholder="Additional comments"
        autocomplete="off"
      />
    </mat-form-field>

    <br />

    <div class="button-group">
      <button
        class="positive-button"
        mat-raised-button
        color="accent"
        type="submit"
        [disabled]="!valid()"
      >
        {{ isSubmitting ? 'Submitting...' : 'Submit' }}
      </button>
    </div>
  </div>
</form>
