export default [
  // bpiInterferenceScaleForm
  'generalActivitySlider',
  'moodSlider',
  'walkingAbilitySlider',
  'normalWorkSlider',
  'relationshipsWithOtherPeopleSlider',
  'sleepSlider',
  'enjoymentOfLifeSlider'
];
