import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-hads-form',
  templateUrl: './hads-form.component.html',
  styleUrls: ['./hads-form.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
})
export class HadsFormComponent implements OnInit {
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  hadsForm: UntypedFormGroup;
  isSubmitting = false;
  isSensitive: boolean;

  ngOnInit(): void {
    this.hadsForm = this.fb.group({
      stillEnjoyThings: ['', Validators.required],
      seeFunnySideOfThings: ['', Validators.required],
      feelCheerful: ['', Validators.required],
      feelSlowedDown: ['', Validators.required],
      lostInterestInAppearance: ['', Validators.required],
      lookForwardWithEnjoyment: ['', Validators.required],
      enjoyABookRadioOrTv: ['', Validators.required],
      tenseOrWoundUp: ['', Validators.required],
      frightenedFeelingAboutSomethingAwful: ['', Validators.required],
      worryingThoughts: ['', Validators.required],
      sitAtEaseAndFeelRelaxed: ['', Validators.required],
      butterfliesInStomachFeeling: ['', Validators.required],
      feelingRestless: ['', Validators.required],
      suddenFeelingOfPanic: ['', Validators.required],
      depressionTotalScore: [''],
      anxietyTotalScore: [''],
    });
    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.hadsForm.controls[key])
          this.hadsForm.controls[key].setValue(formData[key]);
      });
    });
  }

  onSubmit() {
    if (!this.hadsFormValid()) return;
    this.setHadsScore();
    this.isSubmitting = true;
    const formResponse = {
      ...this.hadsForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
      showOnChart: true,
    };
    this.form.submit(formResponse);
  }
  // HADS form
  // D
  get stillEnjoyThings() {
    return this.hadsForm.get('stillEnjoyThings');
  }
  get seeFunnySideOfThings() {
    return this.hadsForm.get('seeFunnySideOfThings');
  }
  get feelCheerful() {
    return this.hadsForm.get('feelCheerful');
  }
  get feelSlowedDown() {
    return this.hadsForm.get('feelSlowedDown');
  }
  get lostInterestInAppearance() {
    return this.hadsForm.get('lostInterestInAppearance');
  }
  get lookForwardWithEnjoyment() {
    return this.hadsForm.get('lookForwardWithEnjoyment');
  }
  get enjoyABookRadioOrTv() {
    return this.hadsForm.get('enjoyABookRadioOrTv');
  }

  // A
  get tenseOrWoundUp() {
    return this.hadsForm.get('tenseOrWoundUp');
  }
  get frightenedFeelingAboutSomethingAwful() {
    return this.hadsForm.get('frightenedFeelingAboutSomethingAwful');
  }
  get worryingThoughts() {
    return this.hadsForm.get('worryingThoughts');
  }
  get sitAtEaseAndFeelRelaxed() {
    return this.hadsForm.get('sitAtEaseAndFeelRelaxed');
  }
  get butterfliesInStomachFeeling() {
    return this.hadsForm.get('butterfliesInStomachFeeling');
  }
  get feelingRestless() {
    return this.hadsForm.get('feelingRestless');
  }
  get suddenFeelingOfPanic() {
    return this.hadsForm.get('suddenFeelingOfPanic');
  }

  hadsFormValid(): boolean {
    return (
      this.hadsForm.dirty &&
      !(
        this.hadsForm.controls && this.hadsForm.controls.stillEnjoyThings.errors
      ) &&
      !(
        this.hadsForm.controls &&
        this.hadsForm.controls.seeFunnySideOfThings.errors
      ) &&
      !(this.hadsForm.controls && this.hadsForm.controls.feelCheerful.errors) &&
      !(
        this.hadsForm.controls && this.hadsForm.controls.feelSlowedDown.errors
      ) &&
      !(
        this.hadsForm.controls &&
        this.hadsForm.controls.lostInterestInAppearance.errors
      ) &&
      !(
        this.hadsForm.controls &&
        this.hadsForm.controls.lookForwardWithEnjoyment.errors
      ) &&
      !(
        this.hadsForm.controls &&
        this.hadsForm.controls.enjoyABookRadioOrTv.errors
      ) &&
      !(
        this.hadsForm.controls && this.hadsForm.controls.tenseOrWoundUp.errors
      ) &&
      !(
        this.hadsForm.controls &&
        this.hadsForm.controls.frightenedFeelingAboutSomethingAwful.errors
      ) &&
      !(
        this.hadsForm.controls && this.hadsForm.controls.worryingThoughts.errors
      ) &&
      !(
        this.hadsForm.controls &&
        this.hadsForm.controls.sitAtEaseAndFeelRelaxed.errors
      ) &&
      !(
        this.hadsForm.controls &&
        this.hadsForm.controls.butterfliesInStomachFeeling.errors
      ) &&
      !(
        this.hadsForm.controls && this.hadsForm.controls.feelingRestless.errors
      ) &&
      !(
        this.hadsForm.controls &&
        this.hadsForm.controls.suddenFeelingOfPanic.errors
      )
    );
  }

  setHadsScore() {
    const dQuestion1 = this.hadsForm.get('stillEnjoyThings').value;
    const dQuestion2 = this.hadsForm.get('seeFunnySideOfThings').value;
    const dQuestion3 = this.hadsForm.get('feelCheerful').value;
    const dQuestion4 = this.hadsForm.get('feelSlowedDown').value;
    const dQuestion5 = this.hadsForm.get('lostInterestInAppearance').value;
    const dQuestion6 = this.hadsForm.get('lookForwardWithEnjoyment').value;
    const dQuestion7 = this.hadsForm.get('enjoyABookRadioOrTv').value;

    const depressionTotalScore =
      +dQuestion1.match(/\d+/g) +
      +dQuestion2.match(/\d+/g) +
      +dQuestion3.match(/\d+/g) +
      +dQuestion4.match(/\d+/g) +
      +dQuestion5.match(/\d+/g) +
      +dQuestion6.match(/\d+/g) +
      +dQuestion7.match(/\d+/g);
    this.hadsForm.get('depressionTotalScore').setValue(depressionTotalScore);

    const aQuestion1 = this.hadsForm.get('tenseOrWoundUp').value;
    const aQuestion2 = this.hadsForm.get(
      'frightenedFeelingAboutSomethingAwful'
    ).value;
    const aQuestion3 = this.hadsForm.get('worryingThoughts').value;
    const aQuestion4 = this.hadsForm.get('sitAtEaseAndFeelRelaxed').value;
    const aQuestion5 = this.hadsForm.get('butterfliesInStomachFeeling').value;
    const aQuestion6 = this.hadsForm.get('feelingRestless').value;
    const aQuestion7 = this.hadsForm.get('suddenFeelingOfPanic').value;

    const anxietyTotalScore =
      +aQuestion1.match(/\d+/g) +
      +aQuestion2.match(/\d+/g) +
      +aQuestion3.match(/\d+/g) +
      +aQuestion4.match(/\d+/g) +
      +aQuestion5.match(/\d+/g) +
      +aQuestion6.match(/\d+/g) +
      +aQuestion7.match(/\d+/g);
    this.hadsForm.get('anxietyTotalScore').setValue(anxietyTotalScore);
  }
}
