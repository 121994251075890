<span [class.hidden]="!formState?.user && field?.props?.showForLoggedInUsersOnly" class="relative">
  <button
    *ngIf="formState?.isEditing"
    #btn
    aria-label="Edit field"
    type="button"
    pButton
    label="Edit field"
    icon="pi pi-ellipsis-v"
    (click)="menu.toggle($event)"
    class="p-button-rounded p-button-text p-button-secondary absolute top-0 left-100 ml-4"
  ></button>
  <p-tieredMenu #menu [model]="items" [popup]="true" appendTo="body"></p-tieredMenu>

  <ng-container #fieldComponent></ng-container>
</span>
