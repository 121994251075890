<div class="h-full flex flex-column justify-content-between">
  <!-- Main content -->
  <div class="overflow-y-scroll">
    <!-- Switch entry buttons -->
    <div *ngIf="currentIndex !== -1">
      <button
        pButton
        type="button"
        icon="pi pi-chevron-left"
        class="p-button-rounded h-2rem w-2rem"
        *ngIf="currentIndex !== 0 && !isEntryLoading"
        (click)="gotoEntry(true)"
        pTooltip="Previous submission"
        tooltipPosition="right"
        style="float: left; padding: 0px !important"
      ></button>

      <button
        pButton
        type="button"
        icon="pi pi-chevron-right"
        class="p-button-rounded mr-1 h-2rem w-2rem"
        *ngIf="currentIndex !== allEntries?.length - 1 && !isEntryLoading"
        (click)="gotoEntry(false)"
        pTooltip="Next submission"
        tooltipPosition="left"
        style="float: right; padding: 0px !important"
      ></button>
    </div>

    <div class="grid w-full" *ngIf="!isEntryLoading">
      <div *ngFor="let entry of entries" class="col pb-0" [class.-mt-6]="currentIndex !== -1">
        <div class="flex justify-content-between py-0 mr-3" [ngClass]="{ 'flex-wrap': isMobile }">
          <div [ngClass]="entries.length > 1 ? 'mb-4' : 'mb-8'">
            <div class="flex flex-wrap line-height-2 py-0 px-5 mt-2">
              <p class="pr-3 m-0" *ngIf="multiCollectionFlag">
                <strong>Collection:</strong>
                {{ entry?.collectionName }}
              </p>
              <p class="pr-3 m-0" [ngClass]="{ 'ml-2': !isMobile && currentIndex !== -1 }">
                <strong>Submitted on:</strong>
                {{ entry?.createdAt | timestamp | nhsDate: true }}
              </p>
              <p class="pr-3 m-0"><strong>Taken by:</strong> {{ entry?.capturedByEmail }}</p>
              <div *ngIf="entry?.requestedBy">
                <strong>Requested by: </strong>
                <span class="mr-2"
                  ><a
                    [href]="
                      'mailto:' + entry?.requestedBy?.email + '?Subject=Visual%20Record%20Request'
                    "
                    target="_top"
                    [pTooltip]="entry?.requestedBy?.name | titlecase"
                    >{{ entry?.requestedBy?.email }}</a
                  ><span class="ml-2">{{
                    entry?.requestedBy?.createdAt | timestamp | nhsDate: true
                  }}</span>
                </span>
              </div>
              <div>
                <strong>{{ isViewAllReviewers ? 'R' : 'Last r' }}eviewed by: </strong>
                <span *ngIf="!entry?.reviewedBy; else reviewed">Not reviewed yet</span>
                <ng-template #reviewed>
                  <span *ngIf="entry?.reviewedBy?.length > 1">
                    <span *ngIf="isViewAllReviewers">
                      <span *ngFor="let review of entry?.reviewedBy">
                        <span class="py-0 px-2 border-round-lg mr-2 mb-1">
                          <a
                            [href]="'mailto:' + review?.email + '?Subject=Visual%20Record%20Review'"
                            target="_top"
                            [pTooltip]="review?.name | titlecase"
                            >{{ review?.email }}</a
                          >
                          <span class="ml-2">{{ review?.createdAt | nhsDate: true }}</span>
                        </span>
                      </span>
                    </span>
                  </span>
                  <span *ngIf="!isViewAllReviewers">
                    <span class="mr-2">
                      <a
                        [href]="
                          'mailto:' +
                          entry?.reviewedBy[0]?.email +
                          '?Subject=Visual%20Record%20Review'
                        "
                        target="_top"
                        [pTooltip]="entry.reviewedBy[0]?.name"
                        >{{ entry?.reviewedBy[0]?.email }}</a
                      >
                      <span class="ml-2">{{
                        entry?.reviewedBy[0]?.createdAt | nhsDate: true
                      }}</span>
                    </span>
                  </span>
                  <span
                    *ngIf="entry?.reviewedBy?.length > 1"
                    class="inline-block cursor-pointer"
                    (click)="toggleReviewersView()"
                    >View
                    {{
                      isViewAllReviewers ? 'less' : 'more (' + entry?.reviewedBy?.length + ')'
                    }}</span
                  >
                </ng-template>
              </div>
              <ng-container *ngIf="entry?.entryContainsText">
                <span [ngClass]="{ 'ml-2': !isMobile }"
                  ><strong>Contains:</strong> {{ entry?.entryContainsText }}</span
                >
              </ng-container>
            </div>
          </div>

          <div class="flex">
            <div
              *ngIf="entry.fileType === 'pdf' || entry.fileType === 'e2e'"
              class="w-max mr-3 p-2"
            >
              <button
                pButton
                type="button"
                (click)="downloadFile(entry)"
                data-cy="download-file-button"
                [pTooltip]="isFileDownloading ? 'Downloading' : 'Download file'"
                tooltipPosition="left"
              >
                <i class="pi pi-download pr-1"></i>
                <span>
                  {{ isFileDownloading ? 'Downloading' : 'Download file' }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid w-full" *ngIf="!isEntryLoading">
      <div *ngFor="let entry of entries" class="col" [class.-mt-6]="currentIndex !== -1">
        <div
          class="text-center w-full flex justify-content-center mb-3"
          *ngIf="!isEveryEntryFormOnly"
        >
          <div
            class="flex flex-column w-2rem"
            *ngIf="
              !collection.isReadOnly &&
              !entry.formOnly &&
              !entry.video &&
              entry.fileType !== 'pdf' &&
              entry.fileType !== 'e2e'
            "
          >
            <i
              class="pi pi-pencil cursor-pointer mr-2"
              (click)="editImage(entry); ref.close()"
              pTooltip="Click to edit image"
            ></i>

            <i
              (click)="measureImage(entry); ref.close()"
              *ngIf="entry.fileType !== 'e2e' && showMeasureImageButton()"
              class="pi pi-ellipsis-h cursor-pointer pt-1 mr-2"
              pTooltip="Measure wound"
            >
            </i>
          </div>

          <ng-container *ngIf="!entry?.formOnly">
            <ic-media-display
              [source]="entry?.urlLarge"
              [video]="entry?.video"
              [fileType]="entry?.fileType"
              [sensitiveImage]="entry?.formResponse.sensitiveImage || entry?.sensitiveImage"
              [enableZoom]="true"
              [entryId]="entry.id"
              class="w-full block"
              minHeight="55vh"
            >
            </ic-media-display>
          </ng-container>

          <div *ngIf="entry?.formOnly" style="min-height: 65vh"></div>
        </div>
        <div *ngIf="entry?.maState || entry?.imagePathUnedited" class="pb-2 ml-4">
          <span>(Image edited)</span>
          <span> | </span>
          <span (click)="openOriginalImageDialog(entry)" class="cursor-pointer">View original</span>
        </div>

        <div
          *ngFor="let form of entry.allFormResponses"
          id="form-response-container"
          class="flex mb-3"
        >
          <div class="icon-container">
            <i
              class="pi pi-pencil cursor-pointer mr-2"
              data-cy="editFormDetails"
              *ngIf="!collection?.isReadOnly"
              pTooltip="Edit form details"
              (click)="navigateToEntryEdit(entry, form.formId); ref.close()"
            ></i>
          </div>
          <p-card class="w-full">
            <ng-container *ngIf="form.formId !== 'default'; else defaultNotes">
              <ic-dynamic-column-response-display
                [formNames]="entry.formNames"
                [entryId]="entry.id"
                [collectionId]="entry.collectionId"
                [patientId]="entry.patientId"
                [formId]="form.formId"
                [multiEntriesFlag]="this.multiEntries"
              ></ic-dynamic-column-response-display>
            </ng-container>
            <ng-template #defaultNotes>
              <ic-form-response-display
                [formId]="form.formId"
                [formResponse]="form.formResponse"
                [teamId]="form.teamId"
                [formKeysMap]="form.formKeysMap"
                [formResponseKeys]="form.formResponseKeys"
                [bodyMapUrl]="form.bodyMapUrl"
                [imageMapObject]="form.imageMapObject"
              >
              </ic-form-response-display>
            </ng-template>
          </p-card>
        </div>
      </div>
    </div>

    <div *ngIf="isEntryLoading" class="loading-shade" style="text-align: center">
      <ic-spinner></ic-spinner>
    </div>
  </div>

  <!-- Bottom buttons -->
  <div
    class="flex justify-content-between pt-2 w-full"
    [ngClass]="{ 'flex-column-reverse': isMobile }"
  >
    <div>
      <button
        pButton
        data-cy="backToPatientRecordBtn"
        (click)="backToPatientRecord()"
        class="p-button-outlined"
        [ngClass]="{ 'w-full mt-3 justify-content-center': isMobile }"
      >
        <i class="pi pi-arrow-left pr-1"></i>
        <span> Back to patient record </span>
      </button>
    </div>

    <div class="flex flex-wrap">
      <div
        *ngIf="!isEveryEntryFormOnly"
        [ngClass]="{ 'w-full mb-1 justify-content-center': isMobile, 'mr-2': !isMobile }"
      >
        <button
          pButton
          ngxHideOnScroll
          type="button"
          (click)="scrollToResponses()"
          [ngClass]="{ 'w-full justify-content-center': isMobile }"
        >
          Show forms and notes
        </button>
      </div>
      <div [ngClass]="{ 'w-full justify-content-center': isMobile }">
        <button
          type="button"
          pButton
          (click)="menu.toggle($event)"
          [ngClass]="{ 'w-full justify-content-center': isMobile }"
        >
          <i class="pi pi-file-pdf pr-1"></i>
          <span class="pl-1">{{ isPDFDownloading ? 'Generating PDF' : 'Generate PDF' }}</span>
        </button>
        <p-menu #menu [popup]="true" [model]="pdfMenuItems" appendTo="body"></p-menu>
      </div>
    </div>
  </div>
</div>
