<form [formGroup]="entryForm" (ngSubmit)="onSubmit()">
  <div class="form-formatting">
    <mat-form-field class="full-width-field" appearance="fill">
      <mat-label>Select the following forms that apply</mat-label>
      <mat-select multiple formControlName="formSelectiors">
        <mat-option *ngFor="let formSelectior of formSelectiorsList" [value]="formSelectior">
          {{ formSelectior }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br />

    <div
      *ngIf="
        entryForm.get('formSelectiors').value.indexOf('Basic wound assessment') !== -1 && form$
          | async as form
      "
    >
      <h1>Basic wound assessment</h1>

      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Responsible clinician</mat-label>
        <input matInput formControlName="responsibleClinician" />
      </mat-form-field>

      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Staff name and designation</mat-label>
        <input matInput formControlName="staffName" />
      </mat-form-field>

      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Current location</mat-label>
        <mat-select formControlName="currentLocation">
          <mat-option *ngFor="let locations of form?.ward?.sort()" [value]="locations">
            {{ locations }}
          </mat-option>
          <mat-option value="Other">Other</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-form-field class="half-width-field" appearance="fill">
      <mat-label>Bed number</mat-label>
      <input matInput formControlName="bedNumber" />
    </mat-form-field>

    <mat-form-field class="half-width-field" appearance="fill">
      <mat-label>Consent</mat-label>
      <mat-select multiple formControlName="consentAndChaperone">
        <mat-option *ngFor="let option of consentAndChaperoneList" [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="half-width-field" appearance="fill">
      <mat-label>Relevant allergies/ sensitivities</mat-label>
      <input matInput formControlName="allergiesAndSensitivities" />
    </mat-form-field>

    <mat-form-field class="half-width-field" appearance="fill">
      <mat-label>Relevant medical history</mat-label>
      <mat-select multiple formControlName="medicalHistory">
        <mat-option *ngFor="let options of medicalHistoryOptionList" [value]="options">
          {{ options }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      class="half-width-field"
      appearance="fill"
      *ngIf="entryForm.get('medicalHistory').value.indexOf('Other') !== -1"
    >
      <mat-label>Please give details of other relevant medical history</mat-label>
      <input matInput formControlName="medicalHistoryOther" />
    </mat-form-field>

    <mat-form-field class="half-width-field" appearance="fill">
      <mat-label>Wound type </mat-label>
      <mat-select formControlName="woundType">
        <mat-option *ngFor="let wounds of woundList" [value]="wounds">
          {{ wounds }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      class="half-width-field"
      appearance="fill"
      *ngIf="
        entryForm.get('woundType').value.indexOf('Surgical wound') !== -1 ||
        entryForm.get('woundType').value.indexOf('Lower limb wound') !== -1 ||
        entryForm.get('woundType').value.indexOf('Pressure ulcer') !== -1 ||
        entryForm
          .get('woundType')
          .value.indexOf('Vascular access device site (cannula or line)') !== -1 ||
        entryForm.get('woundType').value.indexOf('Suture or Clip Removal') !== -1
      "
    >
      <mat-label>Wound Detail</mat-label>
      <mat-select formControlName="woundTypeDetail">
        <ng-container *ngIf="entryForm.get('woundType').value.indexOf('Surgical wound') !== -1">
          <mat-option *ngFor="let wound of surgicalWoundList" [value]="wound">
            {{ wound }}
          </mat-option>
        </ng-container>

        <ng-container *ngIf="entryForm.get('woundType').value.indexOf('Lower limb wound') !== -1">
          <mat-option *ngFor="let wound of lowerLimbWoundList" [value]="wound">
            {{ wound }}
          </mat-option>
        </ng-container>

        <ng-container *ngIf="entryForm.get('woundType').value.indexOf('Pressure ulcer') !== -1">
          <mat-option *ngFor="let wound of pressureUlcerList" [value]="wound">
            {{ wound }}
          </mat-option>
        </ng-container>

        <ng-container
          *ngIf="
            entryForm
              .get('woundType')
              .value.indexOf('Vascular access device site (cannula or line)') !== -1
          "
        >
          <mat-option *ngFor="let wound of vascularAccessList" [value]="wound">
            {{ wound }}
          </mat-option>
        </ng-container>

        <ng-container
          *ngIf="entryForm.get('woundType').value.indexOf('Suture or Clip Removal') !== -1"
        >
          <mat-option *ngFor="let wound of sutureList" [value]="wound">
            {{ wound }}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      class="half-width-field"
      appearance="fill"
      *ngIf="entryForm.get('woundTypeDetail').value.indexOf('Other') !== -1"
    >
      <input matInput type="text" placeholder="Please enter additional surgical wound comments" />
    </mat-form-field>
    <br />

    <mat-form-field
      class="half-width-field"
      appearance="fill"
      *ngIf="entryForm.get('woundType').value.indexOf('Pressure ulcer') !== -1"
    >
      <mat-label>Occurrence</mat-label>
      <mat-select formControlName="pressureUlcerOccurrence">
        <mat-option *ngFor="let options of occurrenceList" [value]="options">
          {{ options }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      class="half-width-field"
      appearance="fill"
      formControlName="miscellaneousWoundDetails"
      *ngIf="entryForm.get('woundType').value.indexOf('Miscellaneous Wound') !== -1"
    >
      <input matInput type="text" placeholder="Miscellaneous Wound details" />
    </mat-form-field>

    <mat-radio-group
      class="half-width-field"
      appearance="fill"
      formControlName="sutureMaterialsRemoved"
      *ngIf="entryForm.get('woundType').value.indexOf('Suture or Clip Removal') !== -1"
    >
      <mat-label>Wound closure materials removed?</mat-label> <br />
      <mat-radio-button value="Yes">Yes</mat-radio-button>
      <mat-radio-button value="No">No</mat-radio-button>
    </mat-radio-group>
    <br />
    <mat-form-field
      class="half-width-field"
      appearance="fill"
      formControlName="sutureMaterialsNotYetRemoved"
      *ngIf="entryForm.get('sutureMaterialsRemoved').value.indexOf('No') !== -1"
    >
      <mat-label>Plan for remaining wound closure materials</mat-label>
      <input matInput type="text" />
    </mat-form-field>

    <div>
      <p>Please mark on this body chart the location of the wound:</p>
    </div>
    <div class="question-container">
      <div class="body-map-holder">
        <div id="sheet2-container">
          <canvas id="sheet2" width="264" height="297"></canvas>
        </div>
        <br />
        <button mat-raised-button class="reset-button" (click)="clearMarking($event)">RESET</button>
      </div>
    </div>

    <mat-form-field class="half-width-field" appearance="fill">
      <mat-label>Body location </mat-label>
      <mat-select formControlName="bodyLocation">
        <mat-option *ngFor="let locations of bodyList" [value]="locations">
          {{ locations }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="bodyLocation.invalid && bodyLocation.dirty">
        The body location must be selected
      </mat-error>
    </mat-form-field>

    <!-- <ng-container *ngIf="entryForm.get('bodyLocation').value.in([])"> -->
    <mat-form-field
      class="half-width-field"
      appearance="fill"
      *ngIf="
        entryForm.get('bodyLocation').value.indexOf('Ankle') !== -1 ||
        entryForm.get('bodyLocation').value.indexOf('Arm') !== -1 ||
        entryForm.get('bodyLocation').value.indexOf('Breast') !== -1 ||
        entryForm.get('bodyLocation').value.indexOf('Buttock') !== -1 ||
        entryForm.get('bodyLocation').value.indexOf('Ear') !== -1 ||
        entryForm.get('bodyLocation').value.indexOf('Foot') !== -1 ||
        entryForm.get('bodyLocation').value.indexOf('Elbow') !== -1 ||
        entryForm.get('bodyLocation').value.indexOf('Forearm') !== -1 ||
        entryForm.get('bodyLocation').value.indexOf('Groin') !== -1 ||
        entryForm.get('bodyLocation').value.indexOf('Hand') !== -1 ||
        entryForm.get('bodyLocation').value.indexOf('Heel') !== -1 ||
        entryForm.get('bodyLocation').value.indexOf('Hip') !== -1 ||
        entryForm.get('bodyLocation').value.indexOf('Knee') !== -1 ||
        entryForm.get('bodyLocation').value.indexOf('Leg') !== -1 ||
        entryForm.get('bodyLocation').value.indexOf('Lower Leg') !== -1 ||
        entryForm.get('bodyLocation').value.indexOf('Thigh') !== -1 ||
        entryForm.get('bodyLocation').value.indexOf('Upper Arm') !== -1
      "
    >
      <mat-label>Body location side</mat-label>
      <mat-select formControlName="bodyLocationSide">
        <mat-option *ngFor="let side of sideList" [value]="side">
          {{ side }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- </ng-container> -->

    <mat-form-field
      class="half-width-field"
      appearance="fill"
      *ngIf="
        entryForm.get('bodyLocation').value.indexOf('Finger') !== -1 ||
        entryForm.get('bodyLocation').value.indexOf('Other') !== -1 ||
        entryForm.get('bodyLocation').value.indexOf('Toe') !== -1
      "
    >
      <mat-label>Please provide additional location detail</mat-label>
      <input matInput formControlName="bodyLocationDetail" placeholder="e.g. Forefinger" />
    </mat-form-field>

    <mat-form-field class="half-width-field" appearance="fill">
      <mat-label>How long has the patient had the wound for?</mat-label>
      <input matInput formControlName="woundDuration" placeholder="e.g. 2 weeks" />
    </mat-form-field>

    <mat-form-field class="half-width-field" appearance="fill">
      <mat-label>Reason for assessment </mat-label>
      <mat-select multiple formControlName="reasonForAssessment">
        <mat-option *ngFor="let reasons of reasonsList" [value]="reasons">
          {{ reasons }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      class="half-width-field"
      appearance="fill"
      *ngIf="entryForm.get('reasonForAssessment').value.indexOf('Other') !== -1"
    >
      <mat-label>Please provide a reason for assessment</mat-label>
      <input matInput formControlName="alternativeReasonForAssessment" />
    </mat-form-field>

    <mat-form-field class="half-width-field" appearance="fill">
      <mat-label>Wound description</mat-label>
      <mat-select multiple formControlName="woundDescription">
        <mat-option *ngFor="let description of descriptionsList" [value]="description">
          {{ description }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      class="half-width-field"
      appearance="fill"
      *ngIf="entryForm.get('woundDescription').value.indexOf('Dehiscence') !== -1"
    >
      <mat-label>Additional dehiscence detail</mat-label>
      <mat-select multiple formControlName="dehiscenceWoundDescription">
        <mat-option *ngFor="let description of dehiscenceList" [value]="description">
          {{ description }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="half-width-field" appearance="fill">
      <mat-label>Additional comments</mat-label>
      <input matInput formControlName="notes" />
    </mat-form-field>

    <mat-form-field class="half-width-field" appearance="fill">
      <mat-label>Exudate</mat-label>
      <mat-select multiple formControlName="exudate">
        <mat-option *ngFor="let option of exudateList" [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      class="half-width-field"
      appearance="fill"
      *ngIf="
        entryForm.get('exudate').value.indexOf('Serous') !== -1 ||
        entryForm.get('exudate').value.indexOf('Haemoserous') !== -1 ||
        entryForm.get('exudate').value.indexOf('Bleeding') !== -1 ||
        entryForm.get('exudate').value.indexOf('Pus') !== -1
      "
    >
      <mat-label>Exudate amount</mat-label>
      <mat-select multiple formControlName="exudateAmount">
        <mat-option *ngFor="let option of exudateAmountList" [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <h3>Wound measurements</h3>
    <mat-form-field class="single-width-field" appearance="fill">
      <mat-label>Length</mat-label>
      <input matInput formControlName="lengthMeasurement" />
    </mat-form-field>
    <mat-form-field class="single-width-field" appearance="fill">
      <mat-label>Width</mat-label>
      <input matInput formControlName="widthMeasurement" />
    </mat-form-field>
    <mat-form-field class="single-width-field" appearance="fill">
      <mat-label>Depth</mat-label>
      <input matInput formControlName="depthMeasurement" />
    </mat-form-field>

    <div *ngIf="entryForm.get('formSelectiors').value.indexOf('Dressings and Actions') !== -1">
      <h1>Dressings and Actions</h1>

      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Analgesia</mat-label>
        <mat-select formControlName="analgesia">
          <mat-option *ngFor="let option of analgesiaList" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Actions</mat-label>
        <mat-select multiple formControlName="actions">
          <mat-option *ngFor="let option of actionsList" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        class="half-width-field"
        appearance="fill"
        *ngIf="entryForm.get('actions').value.indexOf('Regular turning - Other') !== -1"
      >
        <mat-label>Turning frequency</mat-label>
        <mat-select multiple formControlName="actions">
          <input matInput formControlName="turningFrequency" />
        </mat-select>
      </mat-form-field>

      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Wound cleansing</mat-label>
        <mat-select multiple formControlName="woundCleansing">
          <mat-option *ngFor="let option of cleansingList" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        class="half-width-field"
        appearance="fill"
        *ngIf="entryForm.get('woundCleansing').value.indexOf('Other') !== -1"
      >
        <mat-label>Please give details</mat-label>
        <input matInput formControlName="woundCleansingDetail" />
      </mat-form-field>

      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Wound dressing</mat-label>
        <mat-select multiple formControlName="woundDressing">
          <mat-option *ngFor="let option of dressingList" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Please give details</mat-label>
        <input
          matInput
          formControlName="woundDressingDetail"
        />
      </mat-form-field> -->

      <mat-form-field
        class="half-width-field"
        appearance="fill"
        *ngIf="
          entryForm
            .get('woundDressing')
            .value.indexOf('Topical, incisional negative dressing (TOOL TIP: PICO, PREVENA)') !==
            -1 ||
          entryForm.get('woundDressing').value.indexOf('VAC (TOOL TIP: KCI)') !== -1 ||
          entryForm.get('woundDressing').value.indexOf('Nanova system') !== -1
        "
      >
        <mat-label>Status for negative pressure therapy</mat-label>
        <mat-select multiple formControlName="statusForNegativePressureTherapy">
          <mat-option *ngFor="let option of negativeTherapyList" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <ng-container
        *ngIf="
          entryForm.get('statusForNegativePressureTherapy').value.indexOf('New application') !==
            -1 ||
          entryForm.get('statusForNegativePressureTherapy').value.indexOf('Continuing therapy') !==
            -1
        "
      >
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>VAC ID/ Number</mat-label>
          <input matInput formControlName="VACId" />
        </mat-form-field>

        <!-- <h3>Mode and intensity of VAC</h3> -->
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Mode and intensity of VAC</mat-label>
          <input matInput formControlName="intensityOfVAC" />
        </mat-form-field>

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Status for negative pressure therapy</mat-label>
          <mat-select formControlName="modeOfVAC">
            <mat-option *ngFor="let option of statusList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>VAC Options</mat-label>
          <mat-select formControlName="VACOptions">
            <mat-option *ngFor="let option of vacOptionsList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>VAC Pressure</mat-label>
          <input matInput formControlName="VACPressure" />
        </mat-form-field>

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>VAC volume from previous 24hr </mat-label>
          <input matInput formControlName="VACVolumeForPrevious24Hours" />
        </mat-form-field>
      </ng-container>

      <mat-form-field
        class="half-width-field"
        appearance="fill"
        *ngIf="entryForm.get('woundDressing').value.indexOf('Other') !== -1"
      >
        <mat-label>Additional dressing information</mat-label>
        <input matInput formControlName="additionalDressingInformation" />
      </mat-form-field>
    </div>

    <div *ngIf="entryForm.get('formSelectiors').value.indexOf('Health promotion') !== -1">
      <h1>Health Promotion</h1>

      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Health promotion</mat-label>
        <mat-select multiple formControlName="healthPromotion">
          <mat-option *ngFor="let option of healthPromotionList" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        class="half-width-field"
        appearance="fill"
        *ngIf="entryForm.get('healthPromotion').value.indexOf('Chest support') !== -1"
      >
        <mat-label>Chest support details</mat-label>
        <mat-select formControlName="chestSupport">
          <mat-option *ngFor="let option of chestSupportList" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="entryForm.get('formSelectiors').value.indexOf('Actions & investigations') !== -1">
      <h1>Actions & investigations</h1>

      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Investigations</mat-label>
        <mat-select multiple formControlName="investigations">
          <mat-option *ngFor="let option of investigationsList" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <h3>Last blood results</h3>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Date</mat-label>
        <input matInput formControlName="mostRecentBloodTest" type="date" />
      </mat-form-field>

      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>White Blood Cell (WBC) count</mat-label>
        <input matInput formControlName="bloodResultWhiteBloodCellCount" />
      </mat-form-field>

      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>C-reactive protein (CRP)</mat-label>
        <input matInput formControlName="bloodResultCReactiveProtein" />
      </mat-form-field>

      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Haemoglobin (Hb)</mat-label>
        <input matInput formControlName="bloodResultHaemaglobin" />
      </mat-form-field>

      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Iron</mat-label>
        <input matInput formControlName="bloodResultIron" />
      </mat-form-field>

      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Notes</mat-label>
        <input matInput formControlName="bloodResultNotes" />
      </mat-form-field>

      <h3>Microbiology</h3>

      <mat-radio-group class="half-width-field" appearance="fill" formControlName="previousSwabs">
        <mat-label>Have swab(s)/ sample(s) been taken previously? </mat-label> <br />
        <mat-radio-button value="No">No</mat-radio-button>
        <mat-radio-button value="Yes">Yes</mat-radio-button>
      </mat-radio-group>

      <br />

      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Specimen type</mat-label>
        <mat-select formControlName="specimenType">
          <mat-option *ngFor="let option of specimenList" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <ng-container *ngIf="entryForm.get('previousSwabs').value.indexOf('Yes') !== -1">
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Date of most recent swab/sample</mat-label>
          <input matInput formControlName="mostRecentSwabDate" type="date" />
        </mat-form-field>

        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Previous swab result</mat-label>
          <mat-select formControlName="previousSwabResult">
            <mat-option *ngFor="let option of microbiologyList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
    </div>

    <div *ngIf="entryForm.get('formSelectiors').value.indexOf('Planning & referrals') !== -1">
      <h1>Planning & referrals</h1>

      <mat-form-field class="double-width-field" appearance="fill">
        <mat-label>Follow up plan</mat-label>
        <input matInput formControlName="followUpPlan" />
      </mat-form-field>

      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Date of next planned review</mat-label>
        <input matInput formControlName="dateOfNextReview" type="date" />
      </mat-form-field>

      <mat-form-field class="double-width-field" appearance="fill">
        <mat-label>Referrals</mat-label>
        <mat-select formControlName="referrals">
          <mat-option *ngFor="let option of referralsList" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="entryForm.get('formSelectiors').value.indexOf('Sepsis assessment') !== -1">
      <h1>Sepsis assessment</h1>
      <p>
        Is this Possibly SEPSIS? And/or does the patient look sick? OR has NEWS (or similar)
        triggered?
      </p>
      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Possibly Sepsis</mat-label>
        <mat-select multiple formControlName="isThisSepsis">
          <mat-option *ngFor="let option of sepsisList" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        class="half-width-field"
        appearance="fill"
        *ngIf="
          entryForm.get('isThisSepsis').value.indexOf('Yes') !== -1 ||
          entryForm.get('isThisSepsis').value.indexOf('Unsure') !== -1
        "
      >
        <mat-label>Is <strong>ONE</strong> Red Flag present? </mat-label>
        <mat-select multiple formControlName="oneRedFlagPresent">
          <mat-option *ngFor="let option of redFlagList" [value]="option">
            {{ option }}
          </mat-option>
          <mat-option value="N/A or No red flags present">N/A or No red flags present</mat-option>
        </mat-select>
      </mat-form-field>

      <br />
      <div
        appearance="fill"
        *ngIf="entryForm.get('oneRedFlagPresent').value.length >= 1"
        style="margin: 0 auto"
      >
        <!-- TODO: come back to this -->
        <mat-icon>warning</mat-icon>
        <p>
          Red Flag Sepsis! Start Sepsis 6 Pathway NOW.
          <strong>TIME IS CRITICAL, IMMEDIATE ACTION IS REQUIRED</strong>
        </p>
        <mat-icon>warning</mat-icon>
      </div>
      <br />

      <mat-form-field class="half-width-field" appearance="fill">
        <mat-label>Sepsis comments</mat-label>
        <input matInput formControlName="sepsisComments" />
      </mat-form-field>
    </div>

    <div *ngIf="isDetectingBlur">
      <p class="is-detecting-text">Analysing image quality...</p>
    </div>

    <button
      mat-raised-button
      class="positive-button"
      type="submit"
      [disabled]="isSubmitting || isDetectingBlur || !isValid()"
    >
      {{ isSubmitting ? 'Submitting...' : 'Submit' }}
    </button>
  </div>
</form>
