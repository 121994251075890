import { Injectable } from '@angular/core';
import { Datum } from '@islacare/ic-types';
import { environment } from 'apps/frontend/portal/src/environments/environment';
import firebase from 'firebase/compat/app';
import { TreeNode } from 'primeng/api';
import { from, Observable } from 'rxjs';
import { FormResponseTableRequest } from '../../models/table.interface';
import HttpsCallableResult = firebase.functions.HttpsCallableResult;

@Injectable({
  providedIn: 'root',
})
export class FormResponseService {
  formatDataForTables(sections: Datum[]): TreeNode[] {
    const formattedData: TreeNode[] = [];

    for (const section of sections) {
      const currentNode: TreeNode = section;
      currentNode.expanded = true;
      this.expandChildNodes(currentNode);

      formattedData.push(currentNode);
    }

    return formattedData;
  }

  // recursively expand all nodes with children
  private expandChildNodes(node: TreeNode): void {
    if (node.children) {
      node.children.forEach((item: TreeNode) => {
        item.expanded = true;
        this.expandChildNodes(item);
      });
    }
  }

  getTreeTableFormResponses$(
    requestData: FormResponseTableRequest
  ): Observable<HttpsCallableResult> {
    const getFormResponseAsTreeTableJson = firebase
      .app()
      .functions(environment.region)
      .httpsCallable('getFormResponseAsTreeTableJson');

    return from(getFormResponseAsTreeTableJson(requestData));
  }
}
