<ng-container
  *ngIf="{
    updateAvailable: updateAvailable$ | async,
    systemError: systemError$ | async,
    teams: teams$ | async,
  } as vm"
>
  <ng-container *ngTemplateOutlet="appMessages; context: { vm: vm }"></ng-container>
  <ng-content *ngIf="!isIE11; else unsupportedBrowser"></ng-content>
</ng-container>

<ic-release-notes-sidebar
  (updateStateOnClose)="closeReleaseNotes()"
  [sidebarIsVisible]="showReleaseNotes$ | async"
  class="release-notes-sidebar"
></ic-release-notes-sidebar>

<p-toast [preventOpenDuplicates]="true"></p-toast>
<p-toast position="top-center" key="chunkErrorToast">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column flex-1">
      <div class="flex flex-row">
        <span class="pi pi-exclamation-triangle p-toast-message-icon"></span>
        <div class="p-toast-message-text">
          <div class="p-toast-summary">{{message.summary}}</div>
          <div class="p-toast-detail mb-4">{{message.detail}}</div>
          <div class="flex justify-content-end">
            <button
              (click)="reloadPage()"
              class="p-button-sm"
              icon="pi pi-refresh"
              label="Reload Page"
              aria-label="Reload Page"
              pButton
            ></button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>

<ng-template #appMessages let-vm="vm">
  <p-messages *ngIf="vm.updateAvailable" severity="warn" summary="Warning" styleClass="-my-3">
    <ng-template pTemplate>
      <i class="pi pi-exclamation-circle text-2xl mr-2"></i>
      <span>
        There's an improved version of Isla available! Please
        <button
          (click)="reloadPage()"
          class="p-button-warning p-button-text p-0"
          label="Reload page"
          aria-label="Reload page"
          pButton
        ></button>
        for the latest functionality.
      </span>
    </ng-template>
  </p-messages>

  <p-messages *ngIf="vm.systemError" severity="error" summary="Error" styleClass="-my-3 ">
    <ng-template pTemplate>
      <i class="pi pi-exclamation-circle text-2xl mr-2"></i>
      <span>
        Unfortunately, something went wrong. Please
        <p-button
          (click)="reloadPage()"
          styleClass="p-button-danger p-button-text p-0"
          label="Reload page"
          aria-label="Reload page"
        ></p-button
        >.
      </span>
    </ng-template>
  </p-messages>
</ng-template>

<ng-template #unsupportedBrowser>
  <div class="flex flex-column align-items-center justify-content-center pt-5">
    <span class="text-2xl font-semibold"> Unsupported browser </span>
    <p>
      Your current browser is not supported. For the best experience, we suggest using
      <a href="https://www.google.com/chrome/">Google Chrome</a>,
      <a href="https://www.microsoft.com/edge">Microsoft Edge</a>, or
      <a href="https://www.mozilla.org/firefox/">Mozilla Firefox</a>.
    </p>
  </div>
</ng-template>
