import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import {
  ClinicalFormField,
  ContentInputType,
  Datum,
  UserInputType,
} from '@islacare/ic-types';
import { TreeNode } from 'primeng/api';
import {
  inputDateField,
  inputDateField2,
} from '../../../models/form-response.interface';
import { IslaTableColumns } from '../../../models/table.interface';
import { FormResponseService } from '../../../services/form-response/form-response.service';

@Component({
  selector: 'ic-form-response-table',
  templateUrl: './form-response-table.component.html',
  styleUrls: ['./form-response-table.component.scss'],
})
export class FormResponseTableComponent implements OnInit {
  formattedData: TreeNode[] = [];

  @Input() data: Datum[];
  @Input() columns: IslaTableColumns[] = [];
  @Input() tableTitle: string;
  @Input() showColumnHeaders: boolean;
  // Add template types here
  @Input() customBodyTemplate!: TemplateRef<any>;
  dateField: string = inputDateField;
  dateField2: string = inputDateField2;
  UserInputType = UserInputType;
  ContentInputType = ContentInputType;
  ClinicalFormInputType = ClinicalFormField;

  constructor(private readonly formResponseService: FormResponseService) {}

  ngOnInit(): void {
    this.formattedData = this.formResponseService.formatDataForTables(
      this.data
    );
  }
}
