import {Injectable} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MediaService} from '@ic-monorepo/shared-submissions';
import {CaptureType, ConsentedUser} from '@islacare/ic-types';
import firebase from 'firebase/compat/app';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Observable, Subject, from, tap} from 'rxjs';
import {ForwardRequestToEmailComponent} from '../../../feature-patient-submission/components/forward-request-to-email/forward-request-to-email.component';
import {
  SubmissionFlowMedia,
  SubmissionFlowState,
  SubmissionFlowStore
} from '../../../feature-patient-submission/store/submission-flow.store';
import {CollectionAuthsService} from '../../../services/collectionsAuths/collectionAuths.service';
import {FileTypeHelper} from '../../../utils/helpers/file-type/file-type.helper';

export interface EntryItemSnapshot {
  patientId: string;
  collectionId: string;
  formData: any[] | any;
  consentedUser: ConsentedUser;
  consentBestInterest: boolean;
  formOnly: boolean;
}
export interface EntryItem {
  media: SubmissionFlowMedia;
  snapshot: SubmissionFlowState | EntryItemSnapshot;
  formOnly: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class SubmissionFlowService extends FileTypeHelper {
  captureType$: Observable<CaptureType> = this.submissionFlowStore.captureType$;

  get uploader$(): Observable<boolean> {
    return this._uploader.asObservable();
  }

  get capture$(): Observable<boolean> {
    return this._capture.asObservable();
  }

  private _uploader = new Subject<boolean>();
  private _capture = new Subject<boolean>();

  constructor(
    private submissionFlowStore: SubmissionFlowStore,
    private collectionAuth: CollectionAuthsService,
    private dialogService: DialogService,
    private mediaService: MediaService,
    private sanitizer: DomSanitizer
  ) {
    super();
  }

  uploaderClick(): void {
    this._uploader.next(true);
  }
  captureClick(): void {
    this._capture.next(true);
  }

  getFileType(file: File): string {
    const type = file?.type?.split('/')[0];
    switch (type) {
      case 'image':
        return 'image';
      case 'audio':
        return 'audio';
      case 'video':
        return 'video';
      default:
        return 'unknown';
    }
  }

  handleCapturedImage(imageAsBase64: string): File {
    const byteCharacters = atob(imageAsBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new File([byteArray], `${Date.now()}.jpeg`, { type: 'image/png' });
  }

  handleFileSelect(event: Event): Observable<SubmissionFlowMedia> {
    return new Observable<SubmissionFlowMedia>(subscriber => {
      const maxFileSizeInBytes = 500 * 1024 * 1024; // 500 MB
      const media: SubmissionFlowMedia = {
        file: null,
        url: null,
        safeUrl: null,
        captureType: null,
        notes: '',
        sensitive: false
      };

      this.submissionFlowStore.setSubmitting(true);

      try {
        const fileInput = event.target as HTMLInputElement;
        if (!fileInput.files || fileInput.files.length === 0) {
          throw new Error('No file selected');
        }

        const file = fileInput.files[0];
        media.file = file;
        media.captureType = this.getCaptureTypeForFileTypePath(media.file.type);

        if (!media.captureType) {
          throw new Error(
            `Selected file type is not supported (${
              file.type || 'unknown file type'
            })`
          );
        }
        if (file.size >= maxFileSizeInBytes) {
          throw new Error('File size too large (must be below 500MB)');
        }

        media.url = URL.createObjectURL(file);
        media.safeUrl = this.sanitizer.bypassSecurityTrustUrl(media.url);

        this.submissionFlowStore.setMedia(media);
        subscriber.next(media);
        subscriber.complete();
      } catch (error) {
        this.submissionFlowStore.setError(error.message);
        subscriber.error(error);
      } finally {
        this.submissionFlowStore.setSubmitting(false);
      }
    });
  }

  forwardRequestToEmailDialog(snapshot: SubmissionFlowState): DynamicDialogRef {
    return this.dialogService.open(ForwardRequestToEmailComponent, {
      header: 'Forward Request to Email',
      styleClass: 'lg:w-6 w-11',
      data: { snapshot }
    });
  }

  submitEntry$(
    media: SubmissionFlowMedia,
    snapshot: SubmissionFlowState,
    formOnly: boolean
  ): Observable<string> {
    const entryFlow = snapshot.entryFlowDetails;
    const isFormOnly = entryFlow.captureType === CaptureType.FORM || formOnly;

    Object.keys(snapshot.formData).forEach(key => {
      if (snapshot.formData[key]?.formLastUpdated)
        snapshot.formData[key].formLastUpdated =
          firebase.firestore.FieldValue.serverTimestamp();
    });

    const {file, sensitive, captureType} = media ?? {};
    const isVideo = captureType === CaptureType.VIDEO;
    const hasImageAsDataUrl = media ? 'imageAsDataUrl' in media : false;

    return from(
      this.mediaService.storeMedia(
        entryFlow.patientId,
        entryFlow.collectionId,
        file,
        isFormOnly,
        isVideo,
        media ? sensitive : false,
        hasImageAsDataUrl,
        snapshot.formData as any,
        Object.keys(snapshot.formData),
        snapshot.consentedUser,
        snapshot.consentBestInterest,
        entryFlow.collectionAuthId,
        new Date(),
        entryFlow.teamId
      )
    ).pipe(
      tap({
        next: () =>
          this.collectionAuth.updateAsUsed(
            entryFlow.patientId,
            entryFlow.collectionId,
            entryFlow.collectionAuthId
          ),
        error: (error: Error) => error.message
      })
    );
  }
}
