import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClinicalFormEditorPageComponent } from './pages/clinical-form-editor-page/clinical-form-editor-page.component';

const routes: Routes = [
  { path: 'editor', component: ClinicalFormEditorPageComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FeatureClinicalFormsRoutingModule {}
