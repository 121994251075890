import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { filter, tap } from 'rxjs';
import { FormlyFieldEventType } from '../../../models/formly-field-event.enum';

@UntilDestroy()
@Component({
  selector: 'ic-clinical-form-field-calculation',
  templateUrl: './clinical-form-field-calculation.component.html',
  styleUrls: ['./clinical-form-field-calculation.component.scss']
})
export class ClinicalFormFieldCalculationComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  ngOnInit(): void {
    this.field.options.fieldChanges
      .pipe(
        filter(
          event => (event.type as FormlyFieldEventType) === FormlyFieldEventType.EXPRESSION_CHANGES
        ),
        tap(() => this.formControl.setValue(this.field?.props?.calculationValue)),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
