import { of, pipe, throwError } from 'rxjs';
import { delay, mergeMap, retryWhen } from 'rxjs/operators';

export function retryWithDelay(ms: number, count: number) {
  return pipe(
    retryWhen(errors =>
      errors.pipe(
        mergeMap((err, i) => {
          return i >= count ? throwError(err) : of(err);
        }),
        delay(ms)
      )
    )
  );
}
