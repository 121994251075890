import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FileTypeHelper } from '@utils/helpers';
import { SubmissionFlowMedia } from 'apps/frontend/portal/src/app/feature-patient-submission/store/submission-flow.store';
import { DividerModule } from 'primeng/divider';
import { ImageModule } from 'primeng/image';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MediaCardViewType } from '../captured-media-card/captured-media-card.component';

@Component({
  standalone: true,
  selector: 'ic-captured-media-tile',
  templateUrl: './captured-media-tile.component.html',
  imports: [
    InputSwitchModule,
    DividerModule,
    ImageModule,
    FormsModule,
    CommonModule,
  ],
})
export class CapturedMediaTileComponent extends FileTypeHelper {
  @Input() viewType: MediaCardViewType = MediaCardViewType.PATIENT;
  @Input() media: SubmissionFlowMedia;
  @Input() showDeleteButton = true;
  @Input() disabled = false;
  @Input() loading = false;

  @Output() remove = new EventEmitter();

  get mediaCardViewType(): typeof MediaCardViewType {
    return MediaCardViewType;
  }

  constructor() {
    super();
  }
}
