import {
  AfterViewInit,
  Component,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DialogData } from 'apps/frontend/portal/src/app/shared/components/dialogs/dialog-data';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RdOutcomeCreateComponent } from '../../components/rd-outcome-create/rd-outcome-create.component';
import { RdOutcomePreviousComponent } from '../../components/rd-outcome-previous/rd-outcome-previous.component';

interface DialogDataWithOutcomeMeasures extends DialogData {
  outcomeMeasureControl: any;
  patientId: string;
  collectionId: string;
  action: 'add' | 'view';
}

interface TabViewChangeEvent {
  originalEvent: Event;
  index: number;
}

@UntilDestroy()
@Component({
  selector: 'ic-rd-outcome-measures',
  templateUrl: './rd-outcome-measures.component.html',
})
export class RdOutcomeMeasuresComponent implements OnInit, AfterViewInit {
  @ViewChild('rdOutcomeCreateComponent')
  rdOutcomeCreateComponent: RdOutcomeCreateComponent;
  @ViewChild('rdOutcomePreviousComponent')
  rdOutcomePreviousComponent: RdOutcomePreviousComponent;
  @ViewChild('dialogFooter', { read: TemplateRef })
  myTemplate: TemplateRef<any>;

  formValid = false;
  tabIndex: number;

  dialogData: DialogDataWithOutcomeMeasures;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {
    this.dialogData = this.config.data;
  }

  ngOnInit(): void {
    if (this.dialogData) {
      this.setTabIndexBasedOnAction();
    }
  }

  ngAfterViewInit() {
    const footer = document.querySelector(
      '.rd-outcome-measures-dialog .p-dialog-footer'
    );
    if (footer) {
      Promise.resolve(null).then(() => {
        this.renderer.setProperty(footer, 'innerHTML', '');
        const embeddedView = this.viewContainerRef.createEmbeddedView(
          this.myTemplate
        );
        this.renderer.appendChild(footer, embeddedView.rootNodes[0]);
      });
    }
  }

  closeDialog(): void {
    this.ref.close();
  }

  updateFormValidity(isValid: boolean): void {
    this.formValid = isValid;
  }

  changeTab(event: TabViewChangeEvent): void {
    this.tabIndex = event.index;
  }

  submitOutcome(): void {
    const outcome = this.rdOutcomeCreateComponent.outcomeForm.value;
    this.ref.close(outcome);
  }

  resetOutcomeForm(): void {
    this.rdOutcomeCreateComponent.outcomeForm.reset();
  }

  private setTabIndexBasedOnAction(): void {
    const { action } = this.dialogData;
    this.tabIndex = action === 'add' ? 0 : 1;
  }
}
