<ng-container *ngIf="media" [ngSwitch]="media.captureType">
  <ng-container *ngSwitchCase="captureType.PHOTO">
    <img
      [src]="media.safeUrl"
      class="max-w-full border-round-sm object-fit-cover h-full"
      alt="Captured media tile image preview"
    />
  </ng-container>
  <ng-container *ngSwitchCase="captureType.VIDEO">
    <i class="pi pi-video"></i>
  </ng-container>
  <ng-container *ngSwitchCase="captureType.SOUND_RECORDING">
    <i class="pi pi-microphone"></i>
  </ng-container>
  <ng-container *ngSwitchCase="captureType.FILE_UPLOAD">
    <i class="pi pi-upload"></i>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <i class="pi pi-upload"></i>
  </ng-container>
</ng-container>
