import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-doppler-form',
  templateUrl: './doppler-form.component.html',
  styleUrls: ['./doppler-form.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
})
export class DopplerFormComponent implements OnInit {
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  dopplerForm: UntypedFormGroup;
  isSubmitting = false;

  ngOnInit(): void {
    this.dopplerForm = this.fb.group({
      typeOfDoppler: ['', [Validators.required]],
      megahertzUsedManual: ['', []],
      dateOfMeasurement: ['', []],

      leftBrachialPulse: [''],
      leftDorsalisPedis: [''],
      leftPosteriorTibial: [''],
      leftPeroneal: [''],
      leftOther: [''],
      leftToePulse: [''],
      leftHighestAnklePulse: [''],
      leftAbpiFoot: [''],
      leftAbpiToe: [''],

      rightBrachialPulse: [''],
      rightDorsalisPedis: [''],
      rightPosteriorTibial: [''],
      rightPeroneal: [''],
      rightOther: [''],
      rightToePulse: [''],
      rightHighestAnklePulse: [''],
      rightAbpiFoot: [''],
      rightAbpiToe: [''],

      highestBrachialPressure: [''],
      nextAssessmentDue: ['', []],

      notes: ['', []],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.dopplerForm.controls[key])
          this.dopplerForm.controls[key].setValue(formData[key]);
      });
    });
  }

  get typeOfDoppler() {
    return this.dopplerForm.get('typeOfDoppler');
  }

  setScores() {
    const leftBrachialPulse = this.dopplerForm.get('leftBrachialPulse').value;
    const rightBrachialPulse = this.dopplerForm.get('rightBrachialPulse').value;

    const leftDorsalisPedis = this.dopplerForm.get('leftDorsalisPedis').value;
    const leftPosteriorTibial = this.dopplerForm.get(
      'leftPosteriorTibial'
    ).value;
    const leftPeroneal = this.dopplerForm.get('leftPeroneal').value;
    const leftOther = this.dopplerForm.get('leftOther').value;

    const rightDorsalisPedis = this.dopplerForm.get('rightDorsalisPedis').value;
    const rightPosteriorTibial = this.dopplerForm.get(
      'rightPosteriorTibial'
    ).value;
    const rightPeroneal = this.dopplerForm.get('rightPeroneal').value;
    const rightOther = this.dopplerForm.get('rightOther').value;

    const leftToePulse = this.dopplerForm.get('leftToePulse').value;
    const rightToePulse = this.dopplerForm.get('rightToePulse').value;

    let highestBrachialPressure = Math.max(
      leftBrachialPulse,
      rightBrachialPulse
    );
    if (highestBrachialPressure === 0) highestBrachialPressure = NaN;
    this.dopplerForm
      .get('highestBrachialPressure')
      .setValue(highestBrachialPressure);

    let leftHighestAnklePulse = Math.max(
      leftDorsalisPedis,
      leftPosteriorTibial,
      leftPeroneal,
      leftOther
    );
    if (leftHighestAnklePulse === 0) leftHighestAnklePulse = NaN;
    this.dopplerForm
      .get('leftHighestAnklePulse')
      .setValue(leftHighestAnklePulse);

    let rightHighestAnklePulse = Math.max(
      rightDorsalisPedis,
      rightPosteriorTibial,
      rightPeroneal,
      rightOther
    );
    if (rightHighestAnklePulse === 0) rightHighestAnklePulse = NaN;
    this.dopplerForm
      .get('rightHighestAnklePulse')
      .setValue(rightHighestAnklePulse);

    const leftAbpiFoot = leftHighestAnklePulse / highestBrachialPressure;
    this.dopplerForm.get('leftAbpiFoot').setValue(leftAbpiFoot);

    const rightAbpiFoot = rightHighestAnklePulse / highestBrachialPressure;
    this.dopplerForm.get('rightAbpiFoot').setValue(rightAbpiFoot);

    const leftAbpiToe = leftToePulse / highestBrachialPressure;
    this.dopplerForm.get('leftAbpiToe').setValue(leftAbpiToe);

    const rightAbpiToe = rightToePulse / highestBrachialPressure;
    this.dopplerForm.get('rightAbpiToe').setValue(rightAbpiToe);
  }

  onSubmit() {
    this.isSubmitting = true;

    this.setScores();

    const formResponse = {
      ...this.dopplerForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  valid(): boolean {
    return (
      this.dopplerForm.dirty && this.dopplerForm.valid && !this.isSubmitting
    );
  }
}
