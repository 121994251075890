<p-sidebar #sidebarRef [visible]="showSidebar$ | async" (onHide)="layoutDefaultSidebarService.showSidebar = false">
  <ng-template pTemplate="headless">
    <div class="flex flex-column h-full">
      <div class="flex align-items-center justify-content-between p-2">
        <div class="flex align-items-center gap-1">
          <img
            [routerLink]="['/']"
            src="/assets/isla-logo-full.svg"
            alt="Isla logo"
            class="h-2rem cursor-pointer"
            pTooltip="Homepage"
          />
          <img *ngIf="orgLogoUrl$ | async as orgLogoUrl" [src]="orgLogoUrl" class="h-3rem" alt="Organisation logo" />
        </div>

        <p-button
          (click)="layoutDefaultSidebarService.showSidebar = false"
          [attr.aria-label]="'Close sidebar'"
          [rounded]="true"
          [text]="true"
          styleClass="text-main"
          icon="pi pi-times"
        ></p-button>
      </div>

      <div class="overflow-y-auto">
        <ul class="list-none p-0 m-0 overflow-hidden">
          <ng-container *ngIf="navItems$ | async as navItems">
            <ng-container *ngFor="let item of navItems">
              <li>
                <a
                  (click)="!!item.menuItems ? menu.toggle($event) : navigateByUrl(item.url)"
                  [class.el-bg-primary-subtle-alt]="isActiveRoute(item.url)"
                  [attr.data-cy]="item.url"
                  class="flex gap-2 align-items-center cursor-pointer text-secondary border-round hover:surface-100 transition-duration-150 transition-colors p-3 no-underline"
                >
                  <i *ngIf="item.icon" [class]="item.icon"></i>
                  <span class="font-semibold">{{ item.label }}</span>
                </a>
              </li>
              <p-menu #menu [model]="item.menuItems" [popup]="true"></p-menu>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="navToolItems$ | async as navToolItems">
            <p-divider></p-divider>

            <ng-container *ngFor="let item of navToolItems">
              <li>
                <a
                  (click)="navToolAction(item)"
                  class="flex gap-2 align-items-center cursor-pointer border-round hover:surface-100 transition-duration-150 transition-colors p-3 no-underline"
                >
                  <span class="font-semibold">{{ item.label }}</span>
                </a>
              </li>
            </ng-container>
          </ng-container>
        </ul>
      </div>
    </div>
  </ng-template>
</p-sidebar>
