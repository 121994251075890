<ng-container *ngIf="field">
  <div class="question-container">
    <div class="half-width-field">
      <p
        id="automatic-image-map-title"
        [ngStyle]="{ 'font-weight': fontWeight(), 'text-align': textAlign() }"
      >
        {{ field?.title }}
      </p>
      <p style="font-size: 14px">{{ field?.subTitle }}</p>
    </div>
    <div class="body-map-holder">
      <div id="sheet-container">
        <canvas id="canvas-{{ field?.controlName }}"></canvas>
      </div>
      <br />
      <button mat-raised-button class="reset-button" (click)="clearMarking($event)">RESET</button>
    </div>
  </div>
</ng-container>
