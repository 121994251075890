import { Injectable, TemplateRef } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

@Injectable({
  providedIn: 'root'
})
export class MainDrawerService {
  private _drawer: MatDrawer;
  get drawer(): MatDrawer {
    return this._drawer;
  }
  set drawer(drawer: MatDrawer) {
    this._drawer = drawer;
  }

  private _content: TemplateRef<any>;
  get content(): TemplateRef<any> {
    return this._content;
  }
  set content(content: TemplateRef<any>) {
    this._content = content;
    this.open();
  }

  setContentNoOpen(content: TemplateRef<any>) {
    this._content = content;
  }

  get opened(): boolean {
    return this._drawer.opened;
  }

  open() {
    return this._drawer.open();
  }

  close() {
    return this._drawer.close();
  }

  toggle(): void {
    this._drawer.toggle();
  }

  clearContent(): void {
    this._content = null;
    this.close();
  }
}
