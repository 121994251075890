import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  Collection,
  CollectionAuth,
  MessageMedium,
  MessageType
} from '@islacare/ic-types';
import { environment } from 'apps/frontend/portal/src/environments/environment';
import firebase from 'firebase/compat/app';
import { DialogService } from 'primeng/dynamicdialog';
import { AuditService } from '../../../services/audit/audit.service';
import { CollectionAuthsService } from '../../../services/collectionsAuths/collectionAuths.service';
import { PatientMessageCopyService } from '../../../services/patientMessage-copy/patientMessage-copy.service';
import { LoggingService } from '../../../shared/services/logging/logging.service';
import {
  ToastService,
  ToastType
} from '../../../shared/services/toast/toast.service';
import { SmsConfirmationDialogComponent } from '../../dialogs/sms-confirmation-dialog/sms-confirmation-dialog.component';

export interface PartialPatientDetails {
  patientId: string;
  patientFirstName: string;
  patientPhone: string;
  additionalPhoneNumbers: any;
  patientYearOfBirth: string;
}

@Injectable({
  providedIn: 'root'
})
export class SmsService {
  constructor(
    private collectionAuthsService: CollectionAuthsService,
    private patientMessageCopyService: PatientMessageCopyService,
    private audit: AuditService,
    private loggingService: LoggingService,
    private dialogService: DialogService,
    private db: AngularFirestore,
    private toastService: ToastService
  ) { }

  async openSmsDialog(
    collectionAuthUrl: string,
    collectionAuthId: string,
    requestDialogResult,
    collectionId: string,
    loadingRef,
    patientDetails: PartialPatientDetails,
    createdBy: string,
    createdForOrg: string,
    captureType: string,
    requestId: string,
    disableRecipient?: boolean,
    hideReminderCheckbox?: boolean,
    messageType?: MessageType,
    isMediaOptional?: boolean
  ): Promise<void> {
    const patientId = patientDetails.patientId;
    const patientFirstName = patientDetails.patientFirstName;
    const patientPhone = patientDetails.patientPhone;
    const smsIntro = this.patientMessageCopyService.getMessageIntro(
      patientDetails.additionalPhoneNumbers,
      patientFirstName,
      patientPhone,
      true,
      requestDialogResult.recipient !== 'Patient'
        ? requestDialogResult.recipient
        : undefined
    );
    const patientAdditionalPhoneNumbers = Object.keys(
      patientDetails.additionalPhoneNumbers ?? {}
    ).includes('Patient')
      ? patientDetails.additionalPhoneNumbers
      : { ...patientDetails.additionalPhoneNumbers, Patient: patientPhone };

    loadingRef?.close();
    const dialogRef = this.dialogService.open(SmsConfirmationDialogComponent, {
      width: '80%',
      header: 'SMS sending confirmation',
      data: {
        message: collectionAuthUrl,
        smsIntro: smsIntro,
        patientPhone,
        patientId,
        collectionId,
        additionalPhoneNumbers: patientAdditionalPhoneNumbers,
        patientFirstName: patientDetails.patientFirstName,
        disableRecipient,
        hideReminderCheckbox,
        preSetRecipient: requestDialogResult.recipient,
        messageType: messageType,
        keepLinkSevenDays: requestDialogResult.keepLinkSevenDays,
        isMediaOptional: isMediaOptional
      }
    });

    if (requestDialogResult?.messageType !== MessageType.MANUAL_REMINDER) {
      await this.createCollectionAuth({
        collectionAuthUrl,
        collectionAuthId,
        collectionId,
        requestDialogResult,
        patientDetails,
        createdBy,
        createdForOrg,
        captureType,
        requestId,
        isMediaOptional
      });
    }
    dialogRef.onClose.subscribe(async (smsDialogResult): Promise<void> => {
      if (smsDialogResult) {
        this.updateCollectionAuthAndSendPatientMessage(
          requestDialogResult,
          smsDialogResult,
          patientId,
          collectionId,
          collectionAuthId,
          createdBy,
          requestId
        );
      } else {
        if (requestDialogResult?.messageType !== MessageType.MANUAL_REMINDER)
          this.cancelSmsSubmission(collectionId, collectionAuthId, patientId);
        await this.audit.authTokenCancel(patientId);
      }
    });
  }

  async cancelSmsSubmission(
    collectionId: string,
    collectionAuthId: string,
    patientId: string
  ): Promise<void> {
    this.collectionAuthsService.cancelAuth(
      patientId,
      collectionId,
      collectionAuthId
    );
    const message = 'Request not sent';
    this.toastService.open(ToastType.Warning, message);
  }

  async createCollectionAuth({
    collectionAuthUrl,
    collectionAuthId,
    collectionId,
    requestDialogResult,
    patientDetails,
    createdBy,
    createdForOrg,
    captureType,
    requestId,
    isMediaOptional
  }): Promise<void> {
    await this.collectionAuthsService.createPatientCollectionAuth(
      collectionAuthUrl,
      collectionAuthId,
      collectionId,
      requestDialogResult,
      patientDetails,
      createdBy,
      createdForOrg,
      captureType,
      requestId,
      isMediaOptional
    );
  }

  async updateCollectionAuthAndSendPatientMessage(
    requestDialogResult,
    smsDialogResult,
    patientId,
    collectionId,
    collectionAuthId,
    createdBy,
    requestId?
  ): Promise<void> {
    const message = 'Sending SMS...';
    const successfulMessage = 'SMS successfully sent';
    const unsuccessfulMessage = 'Something went wrong. SMS was not sent.';
    this.toastService.open(ToastType.Info, message);
    if (requestDialogResult?.messageType !== MessageType.MANUAL_REMINDER) {
      try {
        await this.updateCollectionAuth(
          patientId,
          collectionId,
          collectionAuthId,
          smsDialogResult
        );
        const sendPatientMessageComplete = await this.sendPatientMessage(
          patientId,
          collectionId,
          collectionAuthId,
          smsDialogResult,
          requestDialogResult,
          createdBy
        );
        if (!sendPatientMessageComplete.data.success) {
          this.toastService.open(ToastType.Error, unsuccessfulMessage);
        } else {
          this.toastService.open(ToastType.Success, successfulMessage);
          await this.audit.authTokenCreate(patientId);
        }
      } catch (error) {
        this.loggingService.consoleError(
          'sendPatientMessage catch error',
          error
        );
        this.toastService.open(ToastType.Error, unsuccessfulMessage);
      }
    } else {
      try {
        const sendManualReminderComplete = await this.sendManualReminder(
          patientId,
          collectionId,
          collectionAuthId,
          smsDialogResult,
          requestId
        );
        if (!sendManualReminderComplete.data.success) {
          this.toastService.open(ToastType.Error, unsuccessfulMessage);
        } else {
          this.toastService.open(ToastType.Success, successfulMessage);
          await this.audit.authTokenCreate(patientId);
        }
      } catch (error) {
        this.loggingService.consoleError(
          'sendManualReminder catch error',
          error
        );
        this.toastService.open(ToastType.Error, unsuccessfulMessage);
      }
    }
  }

  async updateCollectionAuth(
    patientId,
    collectionId,
    collectionAuthId,
    smsDialogResult
  ): Promise<void> {
    const collectionAuthUpdates: Partial<CollectionAuth> = {
      smsText: smsDialogResult.smsFull,
      recipient: smsDialogResult.recipient,
      reminder: smsDialogResult.reminder,
      messageMedium: MessageMedium.SMS
    };
    await this.collectionAuthsService.updateCollectionAuth(
      patientId,
      collectionId,
      collectionAuthId,
      collectionAuthUpdates
    );
  }

  async sendPatientMessage(
    patientId,
    collectionId,
    collectionAuthId,
    smsDialogResult,
    requestDialogResult,
    createdBy
  ): Promise<any> {
    const sendRequest = firebase
      .app()
      .functions(environment.region)
      .httpsCallable('sendPatientMessage');
    return await sendRequest({
      patientId,
      collectionId,
      collectionAuthId,
      messageBody: smsDialogResult.smsFull,
      messageMedium: MessageMedium.SMS,
      formIds: requestDialogResult.formIds,
      reminder: requestDialogResult.reminder || false,
      keepLinkSevenDays: requestDialogResult.keepLinkSevenDays,
      createdBy
    }).catch(err => {
      this.loggingService.consoleError(err);
      throw err;
    });
  }

  async sendManualReminder(
    patientId,
    collectionId,
    collectionAuthId,
    smsDialogResult,
    requestId
  ): Promise<any> {
    const sendManualReminder = firebase
      .app()
      .functions(environment.region)
      .httpsCallable('sendPatientReminder');

    const sendManualReminderComplete = await sendManualReminder({
      patientId,
      collectionId,
      collectionAuthId,
      messageBody: smsDialogResult.smsFull,
      messageMedium: MessageMedium.SMS
    }).catch(err => {
      this.loggingService.consoleError(err);
      throw err;
    });

    const collection = (
      await this.db
        .doc<Collection>(`patients/${patientId}/collections/${collectionId}`)
        .ref.get()
    ).data();

    await this.audit.sendManualReminder(
      patientId,
      collectionId,
      requestId,
      collection.teamId
    );

    return sendManualReminderComplete;
  }

  async sendSmsRequest({
    patientId,
    collectionAuthId,
    collectionId,
    createdBy,
    unsuccessfulMessage,
    successfulMessage,
    requestId,
    teamId
  }): Promise<void> {
    const sendSmsRequest = firebase
      .app()
      .functions(environment.region)
      .httpsCallable('sendSmsRequest');
    const sendSmsRequestComplete = await sendSmsRequest({
      patientId,
      collectionAuthId,
      collectionId,
      createdBy
    });
    this.loggingService.consoleLog(
      'sendSmsRequestComplete',
      sendSmsRequestComplete
    );

    if (!sendSmsRequestComplete.data.success) {
      this.toastService.open(ToastType.Error, unsuccessfulMessage);
    } else {
      this.toastService.open(ToastType.Success, successfulMessage);
      await this.audit.authTokenCreate(patientId);
      await this.audit.sendManualReminder(
        patientId,
        collectionId,
        requestId,
        teamId
      );
    }
  }
}
