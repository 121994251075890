<mat-horizontal-stepper [linear]="isLinear" #stepper>
  <mat-step [stepControl]="cadiForm" label="CADI Form">
    <form [formGroup]="cadiForm">
      <div class="form-formatting">
        <div class="full-width-field" style="margin: auto">
          <h3>The Cardiff Acne Disability Index</h3>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>
              As a result of having acne, during the last month have you been aggressive, frustrated
              or embarrassed?
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="feelingAggressiveFrustratedOrEmbarrassed">
              <mat-option value="Very much indeed - 3">Very much indeed - 3</mat-option>
              <mat-option value="A lot - 2">A lot - 2</mat-option>
              <mat-option value="A little - 1">A little - 1</mat-option>
              <mat-option value="Not at all - 0">Not at all - 0</mat-option>
            </mat-select>
            <mat-error *ngIf="feelingAggressiveFrustratedOrEmbarrassed.touched">
              Please select an option
            </mat-error>
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>
              Do you think that having acne during the last month interfered with your daily social
              life, social events or personal or sexual relationships?
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="acneInterferedWithSocialLifeEventsOrRelationships">
              <mat-option value="Severely, affecting all activities - 3"
                >Severely, affecting all activities - 3</mat-option
              >
              <mat-option value="Moderately, in most activities - 2"
                >Moderately, in most activities - 2</mat-option
              >
              <mat-option value="Occasionally or in only some activities - 1"
                >Occasionally or in only some activities - 1</mat-option
              >
              <mat-option value="Not at all - 0">Not at all - 0</mat-option>
            </mat-select>
            <mat-error *ngIf="acneInterferedWithSocialLifeEventsOrRelationships.touched">
              Please select an option
            </mat-error>
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>
              During the last month have you avoided public changing facilities or wearing swimming
              costumes because of your acne?
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="avoidedPublicChangingFacilities">
              <mat-option value="All of the time - 3">All of the time - 3</mat-option>
              <mat-option value="Most of the time - 2">Most of the time - 2</mat-option>
              <mat-option value="Occasionally - 1">Occasionally - 1</mat-option>
              <mat-option value="Not at all - 0">Not at all - 0</mat-option>
            </mat-select>
            <mat-error *ngIf="avoidedPublicChangingFacilities.touched">
              Please select an option
            </mat-error>
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>
              How would you describe your feelings about the appearance of your skin over the past
              month?
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="feelingsAboutSkinAppearance">
              <mat-option value="Very depressed and miserable - 3"
                >Very depressed and miserable - 3</mat-option
              >
              <mat-option value="Usually concerned - 2">Usually concerned - 2</mat-option>
              <mat-option value="Occasionally concerned - 1">Occasionally concerned - 1</mat-option>
              <mat-option value="Not bothered - 0">Not bothered - 0</mat-option>
            </mat-select>
            <mat-error *ngIf="feelingsAboutSkinAppearance.touched">
              Please select an option
            </mat-error>
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Please indicate how bad you think your acne is now:</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="howBadIsYourAcneNow">
              <mat-option value="The worst it possibly could be - 3"
                >The worst it possibly could be - 3</mat-option
              >
              <mat-option value="A major problem - 2">A major problem - 2</mat-option>
              <mat-option value="A minor problem - 1">A minor problem - 1</mat-option>
              <mat-option value="Not a problem - 0">Not a problem - 0</mat-option>
            </mat-select>
            <mat-error *ngIf="howBadIsYourAcneNow.touched"> Please select an option </mat-error>
          </mat-form-field>
        </div>

        <div>
          <button
            mat-button
            matStepperNext
            style="margin: 10px"
            mat-raised-button
            [disabled]="!cadiFormValid()"
          >
            Next
          </button>
        </div>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="phqForm" label="PHQ Form">
    <form [formGroup]="phqForm">
      <div class="form-formatting">
        <div class="full-width-field" style="margin: auto">
          <h3>The Patient Health Questionnaire</h3>
        </div>
        <div class="full-width-field" style="margin: auto">
          <p>
            Over the last 2 weeks, how often have you been bothered by any of the following
            problems?
          </p>
        </div>

        <br />

        <div class="question-container">
          <div class="half-width-field">
            <p>Little interest or pleasure in doing things</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="littleInterestOrPleasure">
              <mat-option *ngFor="let option of answerList" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="littleInterestOrPleasure.touched">
              Please select an option
            </mat-error>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Feeling down, depressed, or hopeless</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="feelingDownDepressedOrHopeless">
              <mat-option *ngFor="let option of answerList" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="feelingDownDepressedOrHopeless.touched">
              Please select an option
            </mat-error>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Trouble falling or staying asleep, or sleeping too much</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="troubleSleeping">
              <mat-option *ngFor="let option of answerList" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="troubleSleeping.touched"> Please select an option </mat-error>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Feeling tired or having little energy</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="feelingTiredOrLittleEnergy">
              <mat-option *ngFor="let option of answerList" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="feelingTiredOrLittleEnergy.touched">
              Please select an option
            </mat-error>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Poor appetite or overeating</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="poorAppetiteOrOvereating">
              <mat-option *ngFor="let option of answerList" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="poorAppetiteOrOvereating.touched">
              Please select an option
            </mat-error>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              Feeling bad about yourself - or that you are a failure or have let yourself or your
              family down
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="feelingBadAboutYourself">
              <mat-option *ngFor="let option of answerList" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="feelingBadAboutYourself.touched"> Please select an option </mat-error>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              Trouble concentrating on things, such as reading the newspaper or watching television
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="troubleConcentratingOnThings">
              <mat-option *ngFor="let option of answerList" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="troubleConcentratingOnThings.touched">
              Please select an option
            </mat-error>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              Moving or speaking so slowly that other people could have noticed. Or the opposite -
              being so figety or restless that you have been moving around a lot more than usual
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="movingOrSpeakingSlowerOrFasterThanNormal">
              <mat-option *ngFor="let option of answerList" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="movingOrSpeakingSlowerOrFasterThanNormal.touched">
              Please select an option
            </mat-error>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>Thoughts that you would be better off dead, or of hurting yourself</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="negativeThoughtsTowardsYourself">
              <mat-option *ngFor="let option of answerList" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="negativeThoughtsTowardsYourself.touched">
              Please select an option
            </mat-error>
          </mat-form-field>
        </div>

        <div class="question-container">
          <div class="half-width-field">
            <p>
              If you checked off any problems, how difficult have these problems made it for you to
              do your work, take care of things at home, or get along with others?
            </p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label> Please select an option </mat-label>
            <mat-select formControlName="howDifficultHaveTheseProblemsMadeThings">
              <mat-option value="Not difficult at all">Not difficult at all</mat-option>
              <mat-option value="Somewhat difficult">Somewhat difficult</mat-option>
              <mat-option value="Very difficult">Very difficult</mat-option>
              <mat-option value="Extremely difficult">Extremely difficult</mat-option>
            </mat-select>
            <mat-error *ngIf="howDifficultHaveTheseProblemsMadeThings.touched">
              Please select an option
            </mat-error>
          </mat-form-field>
        </div>
        <div class="question-container">
          <div>
            <button mat-button matStepperPrevious mat-raised-button style="margin: 10px">
              Back
            </button>
          </div>
          <div>
            <button
              mat-button
              matStepperNext
              style="margin: 10px"
              mat-raised-button
              [disabled]="!phqFormValid()"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="isotretinoinForm" label="Isotretinoin">
    <form [formGroup]="isotretinoinForm" (ngSubmit)="onSubmit()">
      <div class="question-container">
        <div class="half-width-field">
          <p>Are you taking Isotretinoin (Roaccutane)?</p>
        </div>
        <mat-form-field class="half-width-field" appearance="fill">
          <mat-label>Please select an option</mat-label>
          <mat-select formControlName="areYouTakingIsotretinoin">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="isotretinoinForm.get('areYouTakingIsotretinoin').value.indexOf('Yes') !== -1">
        <div class="question-container">
          <div class="half-width-field">
            <p>When did you start your current treatment course?</p>
          </div>
          <mat-form-field class="half-width-field">
            <input
              matInput
              formControlName="startedCurrentTreatmentCourse"
              type="text"
              placeholder="e.g. 2 weeks ago"
            />
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>what dose are you currently taking?</p>
          </div>
          <mat-form-field class="half-width-field">
            <input
              matInput
              formControlName="doseCurrentlyTaking"
              type="text"
              placeholder="e.g. 1.0 mg per day"
            />
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Are you taking any other pills?</p>
          </div>
          <mat-form-field class="half-width-field">
            <input
              matInput
              formControlName="takingAnyOtherPills"
              type="text"
              placeholder="e.g. pills for other medical issues"
            />
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>What facial moisturiser have you been using?</p>
          </div>
          <mat-form-field class="half-width-field">
            <input
              matInput
              formControlName="facialMoisturiserBeingUsed"
              type="text"
              placeholder="e.g. Cetaphil moisturiser"
            />
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>What lip balm have you been using?</p>
          </div>
          <mat-form-field class="half-width-field">
            <input
              matInput
              formControlName="lipBalmBeingUsed"
              type="text"
              placeholder="e.g. vaseline, carmex"
            />
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Do you feel that your acne is improving?</p>
          </div>
          <mat-form-field class="half-width-field">
            <input
              matInput
              formControlName="feelLikeAcneImproving"
              type="text"
              placeholder="e.g. Yes theres visible signs of it improving"
            />
          </mat-form-field>
        </div>
        <div>
          <p>Since your last visit have you had any of the following:</p>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Dry facial skin?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Please select an option</mat-label>
            <mat-select formControlName="dryFacialSkin">
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Dry lips?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Please select an option</mat-label>
            <mat-select formControlName="dryLips">
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Dry eyes/irritation?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Please select an option</mat-label>
            <mat-select formControlName="dryOrIrritatedEyes">
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Rash on the body?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Please select an option</mat-label>
            <mat-select formControlName="rashOnTheBody">
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Nose bleeds?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Please select an option</mat-label>
            <mat-select formControlName="noseBleeds">
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Changes to your vision?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Please select an option</mat-label>
            <mat-select formControlName="changesToYourVision">
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Change to your bowel habit or blood in the stool?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Please select an option</mat-label>
            <mat-select formControlName="changeToYourBowelHabit">
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Changes to your mood?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Please select an option</mat-label>
            <mat-select formControlName="changesToYourMood">
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Muscle aches or pain?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Please select an option</mat-label>
            <mat-select formControlName="muscleAchesOrPain">
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Back pain?</p>
          </div>
          <mat-form-field class="half-width-field" appearance="fill">
            <mat-label>Please select an option</mat-label>
            <mat-select formControlName="backPain">
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="question-container">
          <div class="half-width-field">
            <p>Are you female?</p>
          </div>
          <mat-form-field appearance="fill" class="half-width-field">
            <mat-label>Please select an option</mat-label>
            <mat-select formControlName="areYouFemale">
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="isotretinoinForm.get('areYouFemale').value.indexOf('Yes') !== -1">
          <div class="question-container">
            <div class="half-width-field">
              <p>Are you sexually active?</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <mat-label>Please select an option</mat-label>
              <mat-select formControlName="areYouSexuallyActive">
                <mat-option value="Yes">Yes</mat-option>
                <mat-option value="No">No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="question-container">
            <div class="half-width-field">
              <p>Are you on contraception?</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <mat-label>Please select an option</mat-label>
              <mat-select formControlName="areYouOnContraception">
                <mat-option value="Yes">Yes</mat-option>
                <mat-option value="No">No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            class="question-container"
            *ngIf="isotretinoinForm.get('areYouOnContraception').value.indexOf('Yes') !== -1"
          >
            <div class="half-width-field">
              <p>Contraception details?</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <input
                matInput
                type="text"
                placeholder="e.g. name of contraceptive pill or coil?"
                formControlName="contraceptionDetails"
              />
            </mat-form-field>
          </div>
          <div class="question-container">
            <div class="half-width-field">
              <p>Do you use condoms?</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <mat-label>Please select an option</mat-label>
              <mat-select formControlName="doYouUseCondoms">
                <mat-option value="Yes">Yes</mat-option>
                <mat-option value="No">No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="isotretinoinForm.get('areYouFemale').value.indexOf('No') !== -1">
          <div class="question-container">
            <div class="half-width-field">
              <p>Erectile dysfunction?</p>
            </div>
            <mat-form-field class="half-width-field" appearance="fill">
              <mat-label>Please select an option</mat-label>
              <mat-select formControlName="erectileDysfunction">
                <mat-option value="Yes">Yes</mat-option>
                <mat-option value="No">No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="question-container">
        <div class="button-group">
          <button mat-button matStepperPrevious mat-raised-button style="margin: 10px">Back</button>
        </div>
        <div>
          <button class="positive-button" mat-raised-button color="accent" type="submit">
            {{ isSubmitting ? 'Submitting...' : 'Submit' }}
          </button>
        </div>
      </div>
    </form>
  </mat-step>
</mat-horizontal-stepper>
