<ng-container
  *ngIf="{
    invalidPatients: invalidPatients$ | async,
    deceasedOrConsentWithdrawnPatients: deceasedOrConsentWithdrawnPatients$ | async,
    validPatients: validPatients$ | async,
    patients: patients$ | async,
    forms: forms$ | async,
    templates: templates$ | async
  } as vm"
>
  <div class="field">
    <div>
      <label for="template-selector">Select a template for all folders:</label>
      <div class="p-fluid pt-1 pb-1">
        <p-dropdown
          name="template-selector"
          [options]="vm.templates"
          placeholder="Select a template"
          [group]="true"
          optionGroupChildren="templates"
          optionGroupLabel="teamName"
          optionLabel="name"
          (onChange)="setTemplate($event.value)"
        >
        </p-dropdown>
      </div>
    </div>

    <div class="w-full dialog-content">
      <ng-container *ngIf="selectedTemplate">
        <div class="w-full">
          <ng-container *ngIf="selectedTemplate?.scheduleDetails?.templateId">
            <ng-container *ngIf="!!vm?.deceasedOrConsentWithdrawnPatients?.length">
              <div class="pt-2 pb-2" *ngIf="!!deceasedPatients.length">
                <p-message severity="warn" [text]="hasDeceasedPatientsMessage"></p-message>
              </div>
              <div class="pt-2 pb-2" *ngIf="!!consentWithdrawnPatients.length">
                <p-message severity="warn" [text]="hasConsentWithdrawnPatientsMessage"></p-message>
              </div>
            </ng-container>
            <div *ngIf="!!vm?.validPatients?.length" class="pt-2 pb-2">
              <p-message
                severity="info"
                text="Bulk-creation of a schedule defaults to send sms to the patient's phone number. If you'd like to set up the schedule to be sent to an additional contact instead please do this via the patient's record."
              ></p-message>
            </div>
            <div *ngIf="!!vm?.invalidPatients?.length" class="pt-2 pb-2">
              <p-message
                severity="info"
                text="Bulk-creation of folders selects the patient's phone number as default
                recipient. The following patients have no phone number saved, and will not be texted
                in the event that a schedule has been set. Please update their phone numbers now.
                Invalid numbers will be greyed out."
              ></p-message>

              <table class="table" aria-label="This shows a list of patients with invalid or no numbers">
                <thead>
                  <tr class="table-headers">
                    <th>Patient</th>
                    <th>Additional contact</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let invalidPatient of vm.invalidPatients; let i = index">
                    <ng-container *ngIf="!invalidPatient.nowValid">
                      <td class="table-patient">
                        {{ invalidPatient.firstName | stringToCapitalisedCase }}
                        {{ invalidPatient.lastName | uppercase }}
                      </td>
                      <td>
                        <p-dropdown
                          [options]="invalidPatient.additionalPhoneNumbers | keyvalue"
                          optionLabel="value"
                          [showClear]="true"
                          placeholder="Select a recipient"
                          (onChange)="
                            invalidPatient.isEditing = true;
                            invalidPatient.selectedRecipient = getSelectedRecipient($event.value)
                          "
                          (onClear)="invalidPatient.isEditing = false"
                        >
                          <ng-template let-recipient pTemplate="selectedItem">
                            <div>{{ recipient.key }}: ({{ recipient.value }})</div>
                          </ng-template>
                          <ng-template let-recipient pTemplate="item">
                            <div>{{ recipient.key }}: ({{ recipient.value }})</div>
                          </ng-template>
                        </p-dropdown>
                      </td>

                      <td class="table-buttons">
                        <ng-container *ngIf="invalidPatient.isEditing; else isNotEditing">
                          <button
                            aria-label="Update"
                            class="pl-1"
                            (click)="
                              invalidPatient.nowValid = true;
                              vm.validPatients.push(invalidPatient);
                              vm.invalidPatients.splice(vm.invalidPatients.indexOf(invalidPatient), 1)
                            "
                            label="Update"
                            pButton
                          ></button>
                          <button
                            (click)="invalidPatient.isEditing = false"
                            class="p-button-outlined p-button-danger"
                            aria-label="Cancel"
                            label="Cancel"
                            type="button"
                            pButton
                          ></button>
                        </ng-container>

                        <ng-template #isNotEditing>
                          <button
                            (click)="openPatientDetails(invalidPatient.id)"
                            label="Edit contact details"
                            aria-label="Edit"
                            pButton
                          ></button>
                          <button
                            (click)="vm.invalidPatients.splice(vm.invalidPatients.indexOf(invalidPatient), 1)"
                            class="p-button-outlined p-button-danger"
                            aria-label="Remove"
                            label="Remove"
                            type="button"
                            pButton
                          ></button>
                        </ng-template>
                      </td>
                    </ng-container>
                  </tr>
                </tbody>
              </table>

              <p-divider></p-divider>
            </div>
          </ng-container>

          <div class="template-details-bg">
            <div class="flex justify-content-center">
              <h4>
                We will create a folder for the
                {{ vm?.validPatients?.length }} patient(s) selected with the following details:
              </h4>
            </div>
            <div class="flex flex-column">
              <ng-container *ngIf="selectedTemplate.submission">
                <div class="w-full p-2">
                  <p-card>
                    <div>
                      <h3>Submission settings</h3>
                    </div>
                    <div class="flex flex-row mt-2">
                      <div class="flex align-items-center justify-content-center mr-2">
                        <p-avatar
                          [icon]="getCaptureTypeIcon(selectedTemplate.submission.capture)"
                          styleClass="mr-2"
                          size="large"
                          shape="circle"
                        ></p-avatar>
                      </div>

                      <div class="flex-1">
                        <div class="flex flex-column">
                          <span class="mb-3">
                            <i aria-hidden="true" [ngClass]="PrimeIcons.PAPERCLIP"></i>
                            <span class="pl-1" *ngFor="let formId of selectedTemplate.submission.formIds; index as i">
                              <ng-container *ngFor="let form of vm.forms">
                                <ng-container *ngIf="form.id === formId">
                                  <span>{{ form.name | camelCaseToTitleCase }}</span>
                                </ng-container>
                              </ng-container>
                              <ng-container *ngIf="formId === 'default'">
                                <span>Notes form</span>
                              </ng-container>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="flex mt-2">
                      <span>
                        <strong>Alert email: </strong>
                        <a href="mailto:{{ selectedTemplate.submission.alertEmail }}">{{
                          selectedTemplate.submission.alertEmail
                        }}</a></span
                      >
                    </div>
                  </p-card>
                </div>
              </ng-container>
              <ng-container *ngIf="selectedTemplate.request">
                <div class="w-full p-2">
                  <p-card>
                    <div>
                      <h3>Request settings</h3>
                    </div>
                    <div class="flex flex-row mt-2">
                      <div class="flex align-items-center justify-content-center mr-2">
                        <p-avatar
                          [icon]="getCaptureTypeIcon(selectedTemplate.request.capture)"
                          styleClass="mr-2"
                          size="large"
                          shape="circle"
                        ></p-avatar>
                      </div>

                      <div class="flex-1">
                        <div class="flex flex-column">
                          <span class="mb-3">
                            <i aria-hidden="true" [ngClass]="PrimeIcons.PAPERCLIP"></i>
                            <span class="pl-1" *ngFor="let formId of selectedTemplate.request.formIds; index as i">
                              <ng-container *ngFor="let form of vm.forms">
                                <ng-container *ngIf="form.id === formId">
                                  <span>{{ form.name | camelCaseToTitleCase }}</span>
                                </ng-container>
                              </ng-container>
                              <ng-container *ngIf="formId === 'default'">
                                <span>Notes form</span>
                              </ng-container>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-wrap flex-column mt-2">
                      <span>
                        <strong>SMS message: </strong>
                        {{ selectedTemplate.request.smsBody }}
                      </span>
                      <span>
                        <strong>Capture:</strong>
                        {{ selectedTemplate.request.capture | camelCaseToTitleCase }}
                      </span>
                      <span>
                        <strong>Alert email: </strong>
                        <a href="mailto:{{ selectedTemplate.submission.alertEmail }}">{{
                          selectedTemplate.request.alertEmail
                        }}</a></span
                      >
                      <span>
                        <strong>Link available for seven days:</strong>
                        {{ selectedTemplate.request.keepLinkSevenDays | yesNo }}
                      </span>
                      <span>
                        <strong>Reminder:</strong>
                        {{ selectedTemplate.request.reminder | yesNo }}
                      </span>
                    </div>
                  </p-card>
                </div>
              </ng-container>
              <ng-container *ngIf="selectedTemplate?.scheduleDetails?.templateId">
                <div class="w-full p-2">
                  <p-card>
                    <div>
                      <h3>Schedule settings</h3>
                    </div>
                    <form [formGroup]="scheduleForm">
                      <ic-schedule-template
                        [teamId]="selectedTemplate.teamId"
                        [startDate]="scheduleForm.get('scheduleStartDate').value"
                        [scheduleTemplateId]="selectedTemplate?.scheduleDetails?.templateId"
                        (scheduleTemplateEvent)="setScheduleTemplateId($event)"
                        (clearSelectedScheduleTemplateEvent)="setScheduleTemplateId(null)"
                      ></ic-schedule-template>
                      <div *ngIf="selectedTemplate.scheduleDetails.templateId">
                        <div class="flex flex-column gap-2 mt-4">
                          <label for="scheduleStartDate" class="font-semibold">Starting on:</label>
                          <p-calendar
                            formControlName="scheduleStartDate"
                            id="scheduleStartDate"
                            [showIcon]="true"
                            class="w-14rem"
                            [minDate]="minScheduleDate"
                            appendTo="body"
                          ></p-calendar>
                        </div>
                      </div>
                    </form>
                  </p-card>
                </div>
              </ng-container>
            </div>
            <ng-container *ngIf="selectedTemplate.teamId">
              <div class="flex justify-content-end mr-2">
                <button
                  class="mb-2"
                  (click)="goToEditTemplate(selectedTemplate.teamId, selectedTemplate.id)"
                  label="Edit this template"
                  aria-label="Edit"
                  pButton
                ></button>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="w-full flex justify-content-between">
    <div>
      <button
        type="button"
        label="Close"
        (click)="closeDialog()"
        icEventTracker="pv-cancel-bulk-create-collections"
        aria-label="Close"
        pButton
      ></button>
    </div>
    <div>
      <button
        [disabled]="!!vm?.invalidPatients?.length || !vm?.validPatients?.length"
        (click)="onSubmit(selectedTemplate, vm.validPatients)"
        label="Confirm"
        icEventTracker="pv-confirm-bulk-create-collections"
        aria-label="Confirm"
        pButton
      ></button>
    </div>
  </div>
</ng-container>
