import { AbstractControl, UntypedFormControl, Validators } from '@angular/forms';
import { hasValidFormat } from '../../utils/custom-validators/email.validator';

/**
 * @description
 * Provides validation for emails within a form.
 * Contains extended functionality within the return to view a log of emails which are invalid.
 * @example
 * form.control.['control'].errors?.emailValidityLogs
 * @returns
 * { multipleEmailError: boolean, emailValidityLogs: Array<{ [email]: boolean }> } || null
 */

export function multipleEmailValidator(control: AbstractControl) {
  if (control.value) {
    const allEmails = control.value.split(',').map(email => email.trim());

    const emailValidityArray = allEmails.map(email => {
      return hasValidFormat(email);
    });

    const isError = allEmails.some(email => Validators.email(new UntypedFormControl(email)));

    return isError || emailValidityArray.includes(false)
      ? { multipleEmailError: true, emailValidityLogs: emailValidityLogs(allEmails) }
      : null;
  }
}

function emailValidityLogs(emails) {
  return emails.map(email => {
    return { [email]: hasValidFormat(email) };
  });
}
