import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-hari-assessment-form',
  templateUrl: './hari-assessment-form.component.html',
  styleUrls: ['./hari-assessment-form.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
})
export class HariAssessmentFormComponent implements OnInit {
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  hariForm: UntypedFormGroup;
  medicineForm: UntypedFormGroup;
  isSubmitting = false;

  fallsList = [
    'Are you on 4 or more medications per day?',
    'Do you have Parkinson’s disease?',
    'Have you ever had a stroke?',
    'Do you feel unsteady or have problems with balance?',
    'Do you have vision problems?',
    'Do you have hearing impairment?',
    'Do you ever Blackout / Faint?',
  ];
  generalMobilityList = [
    'Independent',
    'Indoor walking aid',
    'Outdoor walking aid',
    'Sit To Stand Independent',
  ];
  redFlagList = [
    'Weight loss',
    'Change in bowel habit',
    'Change in urine function',
    'Passing blood in urine',
    'Passing blood in stool',
    'Difficulty in swallowing /choking',
    'Persistent cough',
    'Persistent cough with blood present',
    'Hoarseness',
    'Uncontrolled Pain',
    'New Bladder / bowel incontinence with lower limb weakness / collapse (e.g cauda equine)',
    'Multiple fractures',
    'Sleep deprivation',
  ];

  skinConditionList = [
    'Pressure ulcers',
    'Wound',
    'Oedema',
    'Dryness',
    'Rashes',
  ];
  continenceAssessmentList = [
    'Urine',
    'Faeces',
    'Pads worn',
    'Colostomy',
    'Ileostomy',
    'Ileal Conduit',
  ];
  livesWithList = [
    'Lives alone',
    'Lives with spouse/ partner',
    'Lives with other family',
    'Lives with Carer',
    'Other',
  ];
  needsAssistanceWithList = [
    'Stairs mobility/Stair lift/Access',
    'Toileting',
    'Bathing /showering/strip wash',
    'Bed',
    'Chair/sofa',
    'Domestic ADL/ kitchen',
    'Personal ADL',
    'Upper limb function',
  ];
  informationProvidedList = [
    'Personal Alarm',
    'Age Concern',
    'London Fire Alarm',
    'Attendance Allowance',
    'Chiropody',
    'Dial-a-ride',
    'Wimbledon Guild Activity Booklet',
    'Heatwave',
    'Farm food catalogue',
    'Osteoporosis Information',
    'Taxicard',
    'Community Navigator',
    'Footwear catalogue',
    'Staying Steady Leaflet',
    'Day Centre',
    'Social service',
    'Equipment catalogue',
    'Keeping Warm in Winter',
    'Parkinson’s disease -Useful contact sheet (If applicable)',
    'FALLS PREVENTION INFORMATION PACK including Home Hazard Check List',
  ];

  ngOnInit(): void {
    this.medicineForm = this.fb.group({
      allergyMedicines: this.fb.array([]),
      medications: [''],
    });

    this.hariForm = this.fb.group({
      nextOfKinName: [''],
      nextOfKinRelationship: [''],
      nextOfKinContact: [''],
      emergencyContact: [''],
      ethnicity: [''],
      smoking: [''],
      alcohol: [''],
      presentingIssues: [''],
      patientPerceivedProblems: [''],
      pastMedicalHistory: [''],

      // allergies text field
      allergyMedicinesFreeText: [''],

      //falls
      fallsInPast12Months: [''],
      fallsIndicators: [''],
      fallsComments: [''],
      //General Mobility
      generalMobility: [''],
      generalMobilityComments: [''],
      reasonForPhysiotherapistReferral: [''],
      // Frailty
      rockwoodScore: [''],
      frailtycomments: [''],
      // Red flag
      redFlagAlerts: [''],
      redFlagComments: [''],
      // General skin condition
      generalSkinCondition: [''],
      generalSkinConditionComments: [''],
      // Level of Continence
      continenceAssessment: [''],
      continenceComments: [''],
      commnuityNursing: [''],
      //pain
      painDescription: [''],
      painLocation: [''],
      //social history
      livesWith: [''],
      support: [''],
      // function and transfers
      needsAssistanceWith: [''],
      assistanceComments: [''],
      needEquipment: [''],
      reasonForOTReferral: [''],
      doTheyLiveInMerton: [''],
      // Mental health and wellbeing
      cognition: [''],
      mood: [''],
      pastHistoryOf6CITorOther: [''],
      GDS: [''],
      // summary and action plan
      carePlanGiven: [''],
      admissionPreventionPlanGiven: [''],
      informationProvided: [''],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.hariForm.controls[key])
          this.hariForm.controls[key].setValue(formData[key]);
        if (this.medicineForm.controls[key] && key !== 'allergyMedicines') {
          this.medicineForm.controls[key].setValue(formData[key]);
        }
      });

      for (let i = 0; i < formData['allergyMedicines']?.length; i++) {
        this.addAllergyMedicine();
      }

      this.allergyMedicineFormCurrent.patchValue(formData['allergyMedicines']);
    });
  }

  onSubmit() {
    this.isSubmitting = true;
    const formResponse = {
      ...this.hariForm.value,
      ...this.medicineForm.value,
      formId: this.data.formId,
      index: this.data.index || 0,
    };
    this.form.submit(formResponse);
  }

  get allergyMedicineFormCurrent() {
    return this.medicineForm.get('allergyMedicines') as UntypedFormArray;
  }

  addAllergyMedicine() {
    const allergyMedicine = this.fb.group({
      medicineName: [''],
      allergyMedicineReaction: [''],
      allergyMedicineDate: [''],
    });
    this.allergyMedicineFormCurrent.push(allergyMedicine);
  }

  deleteAllergyMedicine(i) {
    this.allergyMedicineFormCurrent.removeAt(i);
  }
}
