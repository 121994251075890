import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { FormService } from '@ic-monorepo/services';
import { MaterialModule } from 'apps/frontend/portal/src/app/shared/modules/material.module';

@Component({
  standalone: true,
  selector: 'ic-uas7-form',
  templateUrl: './uas7-form.component.html',
  styleUrls: ['./uas7-form.component.scss'],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class Uas7FormComponent implements OnInit {
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private form: FormService) {}

  uas7Form: UntypedFormGroup;
  isSubmitting = false;

  ngOnInit(): void {
    this.uas7Form = this.fb.group({
      whealScore: ['0', []],
      itchingScore: ['0', []],
      notes: ['', []],
    });

    this.form.retreive$.subscribe((formData) => {
      Object.keys(formData).forEach((key) => {
        if (this.uas7Form.controls[key]) {
          this.uas7Form.controls[key].setValue(formData[key]);
        }
      });
    });
  }

  get whealScore() {
    return this.uas7Form.get('whealScore');
  }

  get itchingScore() {
    return this.uas7Form.get('itchingScore');
  }

  get totalScore() {
    return (
      parseInt(this.whealScore.value, 10) +
      parseInt(this.itchingScore.value, 10)
    );
  }

  onSubmit() {
    this.isSubmitting = true;
    const formResponse = {
      ...this.uas7Form.value,
      formId: this.data.formId,
      totalScore: this.totalScore,
      index: this.data.index || 0,
      showOnChart: true,
    };
    this.form.submit(formResponse);
  }

  valid(): boolean {
    return this.uas7Form.dirty && this.uas7Form.valid && !this.isSubmitting;
  }
}
